import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectIsFluffy, selectTableDataSettings } from '../../../redux/selectors/ui-state';
import { useStyles } from '../../components/useStyles';
import { TABLE_DATA_NAMES } from '../../../types/entity-data-settings';
import { selectScheduleEntities } from '../../../redux/selectors/entities';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const ScheduleCount: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const { classes } = useStyles();
  const isFluffy = useSelector(selectIsFluffy);
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showSchedules = columns.indexOf('scheduleCount') >= 0;
  const allSchedules = useSelector(selectScheduleEntities);

  const getScheduleCount = () => {
    let result = (item as ISource).scheduleCount;
    const sourceId = (item as ISource).id;

    if (!result && item) {
      let count = 0;
      // Check if we have the schedules by source id
      const _schedules = Object.keys(allSchedules).forEach(key => {
        const schedule = allSchedules[key];
        if (schedule?.sourceId === sourceId) {
          count++;
        }
      });

      result = count;
    }

    return result || '--';
  };

  const scheduleCount = getScheduleCount();

  return (
    <>
      {showSchedules && !isFluffy && (
        <Grid item data-test={'item-scheduleCount'} xs={xs}>
          <Typography data-test={'scheduleCount-count'} variant="body2" color="textSecondary">
            {scheduleCount}
          </Typography>
        </Grid>
      )}
      {showSchedules && isFluffy && (
        <Grid data-test={'item-scheduleCount-fluffy'} item container direction="row" xs={xs}>
          <Typography
            data-test={'scheduleCount-title'}
            className={classes.titleFluffy}
            variant="caption"
            color="textSecondary"
          >
            {TABLE_DATA_NAMES.scheduleCount?.()}:
          </Typography>
          <Typography data-test={'scheduleCount-count'} variant="caption" color="textPrimary">
            {scheduleCount}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default ScheduleCount;
