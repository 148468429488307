import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { useStyles } from '../../useStyles';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { EntityType } from '@aiware/js/interfaces';
import { ISource } from '../../../../types';
import { actions } from '../../../../redux/slices';
import { useDispatch } from 'react-redux';
import ContextMenuRowButton from '../../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../../types/entity-context-menus';
import GetRowItem from '../../../shared/GetRowItem';

const sourceContextMenu = ENTITY_CONTEXT_MENU[EntityType.Sources];

const SourcesTableRow: FunctionComponent<{
  item: ISource;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid
      data-test={`${EntityType.Sources}-row-${item.id}`}
      style={{ height: rowHeight }}
      container
      className={classes.root}
    >
      <Grid
        onClick={() =>
          dispatch(
            actions.viewStreams.addToSourceTreeBreadCrumbs({
              entity: EntityType.Sources,
              id: item.id,
            })
          )
        }
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
        item
        xs={11}
      >
        <GetRowItem rowName={'sourceTypeId'} xs={1} item={item} itemType={EntityType.Sources} />
        <GetRowItem rowName={'name'} item={item} itemType={EntityType.Sources} />

        <GetRowItem rowName={'createdDateTime'} item={item} xs={3} itemType={EntityType.Sources} />

        <GetRowItem rowName={'modifiedDateTime'} item={item} xs={3} itemType={EntityType.Sources} />

        <GetRowItem rowName={'scheduleCount'} item={item} xs={1} itemType={EntityType.Sources} />
      </Grid>
      <Grid xs={1} className={classes.padding} item container alignItems="center" justifyContent="flex-end">
        <ContextMenuRowButton
          contextMenu={sourceContextMenu}
          type={EAuthResourceType.Source}
          entityId={item.id}
        />
      </Grid>
    </Grid>
  );
};
export default SourcesTableRow;
