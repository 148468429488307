import { FormattedMessage } from 'react-intl';

const SHARED_TEXT = {
  buttonLink: () => (
    <FormattedMessage
      // al stands for account linking, oid stands for open id connector
      id="os-app-bar-panel.aaaaa1"
      defaultMessage="Link"
      description="label of link open id connector button on profile panel"
    />
  ),
  buttonUnlink: () => (
    <FormattedMessage
      id="os-app-bar-panel.L4gr2p"
      defaultMessage="Unlink"
      description="label of unlink open id connector button on profile panel"
    />
  ),
  basicInfoTabLabel: () => (
    <FormattedMessage
      id="os-app-bar-panel.lpZXMT"
      defaultMessage="Basic Info"
      description="Personal Profile: Basic info name, email etc"
    />
  ),
  accountLinkingTabLabel: () => (
    <FormattedMessage
      id="os-app-bar-panel.AgbO9g"
      defaultMessage="Account Linking"
      description="Personal Profile: Open ID Connector Account Linking"
    />
  ),
  settingsTabLabel: () => (
    <FormattedMessage id="os-app-bar-panel.oYYEos" defaultMessage="Settings" description="Profile Settings" />
  ),
  activityTabLabel: () => (
    <FormattedMessage
      id="os-app-bar-panel.profile-activity"
      defaultMessage="Activity"
      description="Profile Activity"
    />
  ),
  accountLinkingLabel: () => (
    <FormattedMessage
      id="os-app-bar-panel.JxMDBr"
      defaultMessage="Link your Account"
      description="label of manage basic infomation container"
    />
  ),
  accountLinkingDescription: () => (
    <FormattedMessage
      id="os-app-bar-panel.77MHtz"
      defaultMessage="View your accounts from other identify providers that are linked with this account."
      description="description of manage account linking"
    />
  ),
  unlinkDialogTitle: () => (
    <FormattedMessage
      id="os-app-bar-panel.naysir"
      defaultMessage="Unlink this Identity Provider?"
      description="title for unlink identity provider"
    />
  ),
  unlinkDialogContent: (name: string) => (
    <FormattedMessage
      id="os-app-bar-panel.dwZfY7"
      defaultMessage="Are you sure you want to unlink {name} from your account?"
      description="content for unlink identity provider"
      values={{
        name: name ? name : '',
      }}
    />
  ),
  unlinkDialogCancel: () => (
    <FormattedMessage
      id="os-app-bar-panel.SNjPPT"
      defaultMessage="Cancel"
      description="cancel for unlink identity provider"
    />
  ),
  unlinkDialogConfirm: () => (
    <FormattedMessage
      id="os-app-bar-panel.KJyVd9"
      defaultMessage="Yes, Unlink"
      description="confirm for unlink identity provider"
    />
  ),
  successDialogTitle: () => (
    <FormattedMessage
      id="os-app-bar-panel.ZaKL3e"
      defaultMessage="Account Successfully linked!"
      description="title for success identity provider"
    />
  ),
  successDialogContent: (name: string) => (
    <FormattedMessage
      id="os-app-bar-panel.IP+fpM"
      defaultMessage="You can now log into this account using {name}."
      description="content for success identity provider"
      values={{
        name: name ? name : '',
      }}
    />
  ),
  successDialogConfirm: () => (
    <FormattedMessage
      id="os-app-bar-panel.1DTtvD"
      defaultMessage="Okay"
      description="confirm for success identity provider"
    />
  ),
  nullState: () => (
    <FormattedMessage
      id="os-app-bar-panel.u6gP99"
      defaultMessage="Your account currently has no available account linking options"
      description="account linking null state"
    />
  ),
  errorState: () => (
    <FormattedMessage
      id="os-app-bar-panel.KG2JIz"
      defaultMessage="There was an error while loading the available account linking options"
      description="account linking error state"
    />
  ),
  retry: () => (
    <FormattedMessage
      id="os-app-bar-panel.IYUdD6"
      defaultMessage="Retry"
      description="account linking retry"
    />
  ),
  settingsHeader: () => (
    <FormattedMessage
      id="os-app-bar-panel.IIE3bM"
      defaultMessage="Application Preferences"
      description="account settings header"
    />
  ),
  settingsSubHeader: () => (
    <FormattedMessage
      id="os-app-bar-panel.9xcAYc"
      defaultMessage="Manage the settings of your account."
      description="account settings sub header"
    />
  ),
};

export default SHARED_TEXT;

export const translatableTextProps = (intl: any) => ({
  searchLabel: intl.formatMessage({
    id: `os-app-bar-panel.search.label`,
    defaultMessage: 'Search',
    description: 'Search label',
  }),
});
