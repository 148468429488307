import { combineReducers } from 'redux';

import { SLICE_NAME } from '../../types';
import * as uiState from './ui-state';
import * as filesSelectedState from './files-selected-state';
import * as rootFolderState from './root-folder-state';

export const namespace = SLICE_NAME;

export type DataCenterImporterState = {
  [namespace]: {
    [uiState.namespace]: uiState.State;
    [filesSelectedState.namespace]: filesSelectedState.State;
    [rootFolderState.namespace]: rootFolderState.State;
  };
};

export const initialState = {
  [uiState.namespace]: uiState.initialState,
  [filesSelectedState.namespace]: filesSelectedState.initialState,
  [rootFolderState.namespace]: rootFolderState.initialState,
};

export const actions = {
  [uiState.namespace]: uiState.actions,
  [filesSelectedState.namespace]: filesSelectedState.actions,
  [rootFolderState.namespace]: rootFolderState.actions,
};

export const rootReducer = combineReducers({
  [uiState.namespace]: uiState.default,
  [filesSelectedState.namespace]: filesSelectedState.default,
  [rootFolderState.namespace]: rootFolderState.default,
});
