import { Fragment } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { useStyles } from './contentTemplates.styles';
import { useState, useEffect } from 'react';
import { errorIconShield } from '@aiware/shared/assets';
import { ErrorOutlineOutlined, Delete } from '@mui/icons-material';
import { SHARED_TEXT } from '../helpers/shared-text';

interface IContentTemplateItem {
  data: any;
  currentTemplateItem: any;
  onChange: (e: any, id: string) => void;
  onSubmit: (e: any, id: string) => void;
  onError: (e: any, id: string) => void;
  onRemove: (templateId: string) => void;
  templateId: string;
}

export const ContentTemplateItem = ({
  data,
  currentTemplateItem,
  onChange,
  onSubmit,
  onError,
  onRemove,
  templateId,
}: IContentTemplateItem) => {
  const { classes } = useStyles({});

  const [isExpanded, setIsExpanded] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [isInvalidSchema, setIsInvalidSchema] = useState(false);
  const [invalidFields, setInvalidFields] = useState<
    { name: string | null | undefined; message: string | undefined; code: string | undefined }[]
  >([]);
  const [showInvalidDetails, setShowInvalidDetails] = useState(false);

  const FormLoader = () => (
    <div className={classes.formLoader}>
      <CircularProgress />
    </div>
  );

  const renderInvalidFields = () => {
    return (
      <div className={classes.invalidFieldsList}>
        {invalidFields.map((field, idx) => {
          return (
            <Fragment key={`${field}-${idx}`}>
              <div className={classes.invalidFieldRow}>
                <span className={classes.invalidFieldName}>
                  <ErrorOutlineOutlined sx={{ color: '#ef5350' }} /> {field.name}
                </span>
                <span className={classes.invalidFieldMessage}>{field.message}</span>
              </div>
              <pre data-test="content-template-invalid-field-code" className={classes.invalidFieldCode}>
                {field.code}
              </pre>
            </Fragment>
          );
        })}
      </div>
    );
  };

  const FormInvalidSchema = () => {
    const handleToggleDetails = () => setShowInvalidDetails(prev => !prev);
    return (
      <div className={classes.formLoader}>
        <img data-test="content-templates-error-image" alt="error" src={errorIconShield} />
        <Typography sx={{ marginTop: '10px' }} variant={'h2'}>
          {SHARED_TEXT.contentTemplatesInvalidSchema()}
        </Typography>
        {invalidFields.length > 0 && (
          <Button
            data-test="content-templates-show-invalid-fields"
            onClick={handleToggleDetails}
            sx={{ marginTop: '10px' }}
          >
            {showInvalidDetails ? 'Hide' : 'Show'} details
          </Button>
        )}
        {showInvalidDetails && renderInvalidFields()}
      </div>
    );
  };

  const isBadSchema = (schema: {
    [key: string]: {
      type: string;
      required: boolean;
    };
  }) => {
    const keys = Object.keys(schema);
    for (const key of keys) {
      const properties = Object.keys(schema[key]!);
      if (properties.length === 2 && properties.includes('type') && properties.includes('required')) {
        if (typeof schema[key]!['required'] === 'boolean') {
          return true;
        }
      }
    }
    return false;
  };

  const parseDOMForErrors = () => {
    try {
      // Test Schema Format
      if (isBadSchema(currentTemplateItem?.definition?.properties)) {
        setInvalidFields([
          {
            name: 'Schema Format',
            message: 'Incorrectly formatted JSON Form Schema',
            code: JSON.stringify(currentTemplateItem?.definition?.properties),
          },
        ]);
        throw new Error('Invalid Schema Format');
      }

      // Test invalid fields
      const form = document.getElementById(templateId);
      if (!form) return;

      const createBadFieldObj = (fieldEl: HTMLDivElement) => {
        return {
          name: fieldEl.querySelector('p > span > code')?.textContent,
          message: fieldEl.querySelector('p > em')?.textContent?.slice(2),
          code: fieldEl.querySelector('pre')?.textContent,
        };
      };

      // @ts-ignore
      const badFields = Array.from(form.querySelectorAll('.unsupported-field')).map(createBadFieldObj);

      if (badFields.length) {
        // @ts-ignore
        setInvalidFields(badFields);
        throw new Error('Invalid fields present inside schema');
      }
    } catch (e) {
      setIsInvalidSchema(true);
    }
  };

  useEffect(() => {
    if (!isExpanded) return;

    setIsChecking(true);
    parseDOMForErrors();
    const timer = setTimeout(() => {
      setIsChecking(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isExpanded]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={(e, expanded) => setIsExpanded(expanded)}
      key={templateId}
      id={templateId}
      data-test={'processing-center-current-templates-item-' + currentTemplateItem?.name}
      className={classes.accordionOverrides}
    >
      <AccordionSummary
        classes={{ content: classes.accordionSummaryContent, expanded: classes.accordionSummaryContent }}
        expandIcon={<ExpandMoreIcon />}
      >
        {isExpanded && (
          <IconButton
            sx={{ marginRight: '10px' }}
            id={`contentTemplate-item-delete-btn-container-${templateId}`}
            onClick={() => onRemove(templateId)}
            data-test={'remove-content-template-' + currentTemplateItem?.name}
            size="large"
          >
            <Delete />
          </IconButton>
        )}{' '}
        <Typography>{currentTemplateItem?.name}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionOverrides}>
        <div
          className={classes.formContainer}
          style={
            isChecking || isInvalidSchema
              ? {
                  overflow: 'hidden',
                  height: '450px',
                  position: 'relative',
                }
              : {}
          }
        >
          {!isChecking && isInvalidSchema && <FormInvalidSchema />}
          {isChecking && <FormLoader />}
          <Form
            id={templateId}
            disabled={isChecking || isInvalidSchema}
            className={classes.form}
            formData={data}
            schema={currentTemplateItem?.definition}
            liveValidate={true}
            showErrorList={false}
            noHtml5Validate={true}
            validator={validator}
            onChange={e => onChange(e, templateId)}
            onSubmit={e => onSubmit(e, templateId)}
            onError={e => onError(e, templateId)}
          >
            <Button
              data-test={'processing-center-content-templates-submit-' + currentTemplateItem?.name}
              type="submit"
            >
              {SHARED_TEXT.contentTemplatesSubmitTemplate()}
            </Button>
          </Form>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
