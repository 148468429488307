import createSvgIcon from '../createSvgIcon';

export const NoApps = createSvgIcon(
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80 149.333C118.292 149.333 149.333 118.292 149.333 80C149.333 41.7083 118.292 10.6667 80 10.6667C41.7083 10.6667 10.6667 41.7083 10.6667 80C10.6667 118.292 41.7083 149.333 80 149.333ZM80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
      fill="#F9F9F9"
    />
    <path
      d="M57 60.1878H76.4673V70.7488L90.2404 57L103.989 70.7488L90.2404 84.5219H100.801V103.989H81.3341V84.5219H90.2404L76.4673 70.7488V79.6551H57V60.1878ZM57 84.5219H76.4673V103.989H57V84.5219Z"
      fill="#FE8D00"
    />
    <path d="M90.2404 57L76.4673 70.7488L90.2404 84.5219L103.989 70.7488L90.2404 57Z" fill="#FED966" />
    <path d="M100.801 84.5215H90.2403H81.334V103.989H100.801V84.5215Z" fill="#FD9F00" />
    <path
      d="M78.4524 89C74.5413 87.4 67.2272 82.9327 70 76C71.9998 71 84.9998 64 79.9999 57.5C77.8186 54.6642 67.9998 53 67.9998 62.5C67.9998 72.5 79.1332 73 84.9999 69C90.8666 65 90.719 49.2667 81.119 45"
      stroke="#B9D1E1"
      strokeDasharray="2 2"
    />
    <path d="M76.4673 60.1875H57V79.6548H76.4673V70.7485V60.1875Z" fill="#FDC000" />
  </svg>,
  'NoApps'
);
