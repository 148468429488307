import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  isJsonValid,
  formatJson,
  splitInputAtCursor,
  calculatePotentialAddition,
  updateCursorPosition,
  POTENTIAL_PAIR_STUB,
  POTENTIAL_ITEM_STUB,
  removePotentialStubs,
  validateJson,
  JSONData,
} from './helpers';

const useStyles = makeStyles(() => ({
  textField: {
    '& .Sdk-MuiInput-underline::before': {
      borderBottom: 'none',
    },
    '& .Sdk-MuiInput-underline::after': {
      borderBottom: 'none',
    },
    '& .Sdk-MuiInput-underline.Sdk-Mui-focused::before': {
      borderBottom: 'none',
    },
    '& .Sdk-MuiInput-underline.Sdk-Mui-focused::after': {
      borderBottom: 'none',
    },
    '&.Sdk-MuiButtonBase-root:hover': {
      borderColor: 'red',
    },
  },
}));

interface JSONEditorProps {
  dataTestId?: string;
  jsonData: string;
  onChange: (data: string) => void;
  required?: boolean;
  label?: string;
  autoFocus?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  height?: string;
}

export const JSONEditor = ({
  jsonData,
  onChange,
  height,
  variant,
  label,
  dataTestId,
  required = false,
  autoFocus = true,
}: JSONEditorProps) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(isJsonValid(jsonData));
  }, [jsonData]);

  const handleJsonInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (formatting.current) {
      return;
    }

    onChange(event.target.value);
  };
  const formatting = useRef(false);

  const handleBlur = () => {
    const cleanup = formatJson(jsonData, true);
    if (cleanup) {
      onChange(cleanup);
    }
  };
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.key === 'Enter' && !formatting.current) {
      formatting.current = true;
      const target = event.target as HTMLTextAreaElement;
      const { pre, post } = splitInputAtCursor(target, jsonData);
      const newJson = calculatePotentialAddition(pre, post);
      if (!newJson) {
        updateCursorPosition(target, [pre.length + 1, target.selectionStart]);
        formatting.current = false;
        onChange(`${pre}\n${post}`);
      } else {
        updateCursorPosition(target, [
          newJson.indexOf(POTENTIAL_PAIR_STUB),
          newJson.indexOf(POTENTIAL_ITEM_STUB),
          target.selectionStart,
        ]);
        formatting.current = false;
        onChange(removePotentialStubs(newJson) || jsonData);
      }
    }
  };

  return (
    <TextField
      fullWidth
      multiline
      autoFocus={autoFocus}
      variant={variant ?? 'standard'}
      value={jsonData || ''}
      onChange={handleJsonInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      error={!isValid}
      label={label}
      required={required}
      InputLabelProps={{ shrink: true }}
      helperText={!isValid ? 'Invalid JSON' : ''}
      sx={{
        '& .Sdk-MuiOutlinedInput-root': {
          padding: '0 !important',
        },
      }}
      inputProps={{
        'data-testid': dataTestId ? dataTestId : 'json-editor',
        sx: {
          overflowY: 'auto !important',
        },
        style: {
          padding: '20px',
          fontFamily: 'Courier New',
          backgroundColor: variant === 'outlined' ? 'none' : '#F7F7F7',
          fontSize: '14px',
          lineHeight: '20px',
          height: height ? height : '100%',
        },
      }}
      className={classes.textField}
    />
  );
};

export { validateJson, type JSONData };
