import { sessionTokenSelector, rootApiSelector, userSelector } from '@aiware/shared/redux';
import { selectOpenIdConnectors } from './selector';
import * as actions from './action';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getAllOpenIdConnectorApi, getConnectedOpenIdConnectorApi } from './api';
import { IOpenIdResult } from '../../../types';

export function* getOpenIdConnectorSaga() {
  const token: string = yield select(sessionTokenSelector);
  const baseUrl: string = yield select(rootApiSelector);
  const { userName } = yield select(userSelector);

  if (!token || !baseUrl || !userName) {
    yield put(actions.getOpenIdConnectorError('Missing token,base url or username'));
    return;
  }
  let allOpenIds = {} as IOpenIdResult;
  let connectedOpenIds = {} as IOpenIdResult;
  allOpenIds.results = yield select(selectOpenIdConnectors);
  try {
    const allOpenIdApiCall = call(getAllOpenIdConnectorApi, baseUrl, userName, token);
    const connectedOpenIdCall = call(getConnectedOpenIdConnectorApi, baseUrl, userName, token);
    // see if we cached all open id connectors
    if (allOpenIds?.results.length === 0) {
      // fetch all and connected open id connectors
      [allOpenIds, connectedOpenIds] = yield all([allOpenIdApiCall, connectedOpenIdCall]);
    } else {
      // fetch only connected open id connectors
      connectedOpenIds = yield connectedOpenIdCall;
    }

    if (!allOpenIds || !connectedOpenIds) {
      yield put(actions.getOpenIdConnectorError('error on loading open ids'));
      return;
    }
    yield put(actions.getOpenIdConnectorSuccess(allOpenIds.results));
    yield put(actions.getConnectedOpenIdsSuccess(connectedOpenIds.results));
  } catch (e) {
    yield put(actions.getOpenIdConnectorError('error on loading open ids'));
  }
}

export default function* openIdRootSaga() {
  yield takeEvery(actions.GET_OPEN_ID_CONNECTORS, getOpenIdConnectorSaga);
}
