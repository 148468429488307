import createSvgIcon from '../createSvgIcon';
export const StationPlayout = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.726 8.32978C14.726 9.83357 13.505 11.0526 11.9988 11.0526C10.4925 11.0526 9.27148 9.83357 9.27148 8.32978C9.27148 6.82599 10.4925 5.60693 11.9988 5.60693C13.505 5.60693 14.726 6.82599 14.726 8.32978ZM12.9077 20.0924V12.8315H11.0895V20.0924C11.0895 20.3332 11.1853 20.564 11.3558 20.7342C11.5263 20.9044 11.7575 21.0001 11.9986 21.0001C12.2397 21.0001 12.4709 20.9044 12.6414 20.7342C12.8119 20.564 12.9077 20.3332 12.9077 20.0924Z"
    />
    <path d="M5.9 3.8C4.8 5 4.2 6.5 4.2 8C4.2 9.5 4.8 11 5.9 12.2L5.1 13C3.7 11.6 3 9.8 3 8C3 6.2 3.7 4.4 5.1 3L5.9 3.8Z" />
    <path d="M7.5 10.5L6.7 11.3C5.8 10.4 5.3 9.2 5.3 8C5.3 6.8 5.8 5.6 6.7 4.7L7.5 5.5C6.8 6.2 6.5 7.1 6.5 8C6.5 8.9 6.8 9.8 7.5 10.5Z" />
    <path d="M18.1 12.2C19.2 11 19.8 9.5 19.8 8C19.8 6.5 19.2 5 18.1 3.8L18.9 3C20.3 4.4 21 6.2 21 8C21 9.8 20.3 11.6 18.9 13L18.1 12.2Z" />
    <path d="M16.5 5.5L17.3 4.7C18.2 5.6 18.7 6.8 18.7 8C18.7 9.2 18.2 10.4 17.3 11.3L16.5 10.5C17.2 9.8 17.5 8.9 17.5 8C17.5 7.1 17.2 6.2 16.5 5.5Z" />
  </svg>,
  'StationPlayout'
);
