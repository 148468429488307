import { all } from 'redux-saga/effects';
import { fetchDataRegistriesWatcher, fetchSDOViewerDataRegistryWatcher } from './data-registries.watcher';
import { sagas as sdoSagas } from '../slices/schemaSdos.state';

export function* rootSaga() {
  yield all([
    fetchDataRegistriesWatcher(),
    fetchSDOViewerDataRegistryWatcher(),
    sdoSagas.watchCreate(),
    sdoSagas.watchUpdate(),
    sdoSagas.watchRead(),
    sdoSagas.watchDelete(),
  ]);
}
