import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import {
  betaFeaturesSelector,
  configSelector,
  selectIsFullscreenEnabled,
  selectIsOLPEnabled,
  userSelector,
} from '@aiware/shared/redux';
import { EntityType } from '@aiware/js/interfaces';
import { useStyles } from '../useStyles';
import { ITdoVm } from '../../../types';
import ContextMenuRowButton from '../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../types/entity-context-menus';
import { actions } from '../../../redux/slices';
import GetRowItem from '../../shared/GetRowItem';
import { mountPanel } from '@aiware/js/panel';
import { actions as permissionsActions, EAuthResourceType } from '@aiware/shared/permissions';
import { selectSelectedItems } from '../../../redux/selectors/view-my-files';
import { Checkbox, Tooltip } from '@mui/material';
import { SHARED_TEXT } from '../../../helpers/shared-text';

const tdoContextMenu = ENTITY_CONTEXT_MENU[EntityType.Tdos];
const PARENT_PANEL_ID = 'DATA_CENTER';
const DATA_CENTER_MDP_IFRAME_PANEL = 'DATA_CENTER_MDP_IFRAME_PANEL';
const DATA_CENTER_DATA_DETAILS_PANEL = 'DATA_CENTER_DATA_DETAILS_PANEL';

interface ChangeEvent<T = Element> extends SyntheticEvent<T> {
  target: EventTarget & T;
}

const FilesTableFluffyRow: FunctionComponent<{
  item: ITdoVm;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const { classes } = useStyles();
  const configs = useSelector(configSelector);
  const dispatch = useDispatch();
  const isOLPEnabled = useSelector(selectIsOLPEnabled);
  const [fetchedPermissions, setFetchedPermissions] = useState(false);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const mediaDetailsUrl = `${configs.cmsAppUrl}/embed/#/media-details/${item.id}`;
  const betaFeatures = useSelector(betaFeaturesSelector);
  const user = useSelector(userSelector);
  const selectedItems = useSelector(selectSelectedItems);
  const isSelectingFiles = selectedItems.length > 0;
  const isSelected = selectedItems?.includes(item.id);

  const isNewDataDetails = () => {
    // ONLY if the content type is application/json, AND...
    if (item?.primaryAsset?.contentType === 'application/json') {
      // ...beta features are on, use new panel
      if (betaFeatures) {
        return true;
      }

      // ...it's the Veritone org, use new panel
      if (user.organization?.organizationId === 7682 && user.organization.name === 'Veritone, Inc.') {
        return true;
      }
    }
    return false;
  };

  const fetchPermissions = () => {
    dispatch(
      permissionsActions.fetchObjectPermissionsStart({
        entityType: EAuthResourceType.TDO,
        entityId: item.id,
      })
    );
    setFetchedPermissions(true);
  };

  const handleOpenMediaDetailsPanel = () => {
    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'xlarge',
      parentPanelId: PARENT_PANEL_ID,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    if (isNewDataDetails()) {
      const microFrontend = {
        name: DATA_CENTER_DATA_DETAILS_PANEL,
        config: {
          tdoId: item.id,
          fileName: item.details?.filename || item.details?.fileName || item.name,
        },
      };

      dispatch(
        mountPanel({
          panelId: DATA_CENTER_DATA_DETAILS_PANEL,
          microFrontend: microFrontend,
          panelConfig: panelConfig,
        })
      );
    } else {
      const microFrontend = {
        name: DATA_CENTER_MDP_IFRAME_PANEL,
        config: {
          entityId: item.id,
          iframeUrl: mediaDetailsUrl,
          fileName: item.details?.filename || item.details?.fileName || item.name,
        },
      };

      dispatch(
        mountPanel({
          panelId: DATA_CENTER_MDP_IFRAME_PANEL,
          microFrontend: microFrontend,
          panelConfig: panelConfig,
        })
      );
    }
  };

  const handleRowClick = (e: any) => {
    if (e.target.type === 'checkbox') {
      return;
    }

    if (!fetchedPermissions && isOLPEnabled) {
      fetchPermissions();
    }

    if (isSelectingFiles) {
      selectFile();
    } else {
      handleOpenMediaDetailsPanel();
    }
  };

  const selectFile = () => {
    if (isSelected) {
      dispatch(actions.viewMyFiles.removeSelectedItem(item.id));
      dispatch(actions.viewMyFiles.toggleFileOrFolderSelected(item.id));
    } else {
      dispatch(actions.viewMyFiles.addSelectedItem(item.id));
      dispatch(actions.viewMyFiles.toggleFileOrFolderSelected(item.id));
      dispatch(
        permissionsActions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.TDO,
          entityId: item.id,
        })
      );
    }
  };

  const handleSelectFileOrFolderOnCheck = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!fetchedPermissions && isOLPEnabled) {
      fetchPermissions();
    }

    selectFile();
  };

  return (
    <Grid
      data-test={`${EntityType.Folders}-file-row-fluffy-${item.id}`}
      className={classes.row}
      style={{ height: rowHeight }}
      container
    >
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignContent="space-around"
        container
        item
        xs={11}
        onClick={handleRowClick}
      >
        <GetRowItem rowName={'thumbnail'} item={item} xs={2} itemType={EntityType.Folders} />
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="space-around"
          xs={10}
          className={classes.paddingLeft}
        >
          <Grid
            sx={{
              alignItems: 'center',
              '& [data-test="name"]': {
                color: isSelected ? '#1871E8!important' : '',
              },
            }}
            item
            container
            alignContent="center"
            xs={12}
          >
            <Tooltip
              disableInteractive
              title={isSelected ? SHARED_TEXT.unselectItemTooltip() : SHARED_TEXT.selectItemTooltip()}
            >
              <Checkbox
                size="small"
                value={item.id}
                checked={isSelected}
                onChange={handleSelectFileOrFolderOnCheck}
              />
            </Tooltip>
            <GetRowItem rowName={'name'} item={item} itemType={EntityType.Folders} />
            <GetRowItem rowName={'fileType'} item={item} xs={1} itemType={EntityType.Folders} />
          </Grid>

          <Grid item container direction="row" alignItems="center" justifyContent="flex-start" xs={12}>
            <GetRowItem rowName={'modifiedDateTime'} item={item} xs={6} itemType={EntityType.Folders} />
            <GetRowItem rowName={'engineIds'} item={item} xs={4} itemType={EntityType.Folders} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={1}
        className={classes.moreIcon}
        item
        container
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <ContextMenuRowButton
          disabled={isSelectingFiles}
          contextMenu={tdoContextMenu}
          entityId={item.id}
          type={EAuthResourceType.TDO}
        />
      </Grid>
    </Grid>
  );
};

export default FilesTableFluffyRow;
