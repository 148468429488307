import { INotificationType } from '../interfaces/notificationInterfaces';
import { automate, illuminate, redact } from '@aiware/shared/assets';

export const applicationNotificationSettingData = {
  byId: {
    'notification-app-automate': {
      name: 'Automate',
      iconUrl: automate,
      description: 'Chose which events you’d like to be notified about.',
      allow: false,
      allowType: [],
      action: {
        byId: {
          '1': {
            title: 'Completed Processes',
            allowType: [INotificationType.NOTIFICATION_CENTER],
            allow: true,
          },
          '2': {
            title: 'Processing Updated',
            allowType: [],
            allow: false,
          },
        },
        allIds: ['1', '2'],
      },
    },
    'notification-app-illuminate': {
      name: 'Illuminate',
      iconUrl: illuminate,
      description: 'Chose which events you’d like to be notified about.',
      allow: true,
      allowType: [
        INotificationType.EMAIL,
        INotificationType.NOTIFICATION_CENTER,
      ],
      action: {
        byId: {
          '1': {
            title: 'Error',
            allowType: [
              INotificationType.NOTIFICATION_CENTER,
              INotificationType.BANNER,
              INotificationType.EMAIL,
            ],
            allow: true,
          },
        },
        allIds: ['1'],
      },
    },
    'notification-app-redact': {
      name: 'Redact',
      iconUrl: redact,
      description: 'Chose which events you’d like to be notified about.',
      allow: true,
      allowType: [INotificationType.EMAIL],
      action: {
        byId: {
          '1': {
            title: 'Processing Updated',
            allowType: [],
            allow: false,
          },
          '2': {
            title: 'Error',
            allowType: [
              INotificationType.NOTIFICATION_CENTER,
              INotificationType.BANNER,
              INotificationType.EMAIL,
            ],
            allow: false,
          },
        },

        allIds: ['1', '2'],
      },
    },
  },
  allIds: [
    'notification-app-automate',
    'notification-app-illuminate',
    'notification-app-redact',
  ],
};
