import { useCallback, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Typography, SelectChangeEvent, CircularProgress } from '@mui/material';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { FormattedMessage } from 'react-intl';
import { mountPanel, unmountPanel } from '@aiware/js/panel';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { Filter } from '@aiware/ui';
import { IMicroFrontend, INotificationDetail, ISimplePanelConfig } from '@aiware/js/interfaces';
import { mailboxIdSelector } from '@aiware/os/notification-subscription';
import { getNotificationModule, INotificationModule } from '../../store';
import EmptyView from '../../components/EmptyView';
import {
  notificationSelector,
  applicationFilterSelector,
  notificationWithFilterSelector,
} from '../../store/selector';
import { getNotification, updateFilterValue } from '../../store/actions';
import { filters } from '../../store/constants';
import NotificationList from '../../components/NotificationList';
import { useStyles } from './styles';
import { updateNotificationReadRequest, markAllReadNotificationRequest } from '../../store/actions';
import { Button } from '@mui/material';

export interface NotificationPanelComponentProps extends ISimplePanelConfig {
  name?: string;
  dataId: string;
  hidePanel?: () => void;
}

type TProps = {
  setPanelIsOpenState?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationPanel = ({ setPanelIsOpenState }: TProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const applicationFilters = useSelector(applicationFilterSelector) || [];
  const [loadingMore, setLoadingMore] = useState(false);
  const mailBoxIds: string[] = useSelector(mailboxIdSelector);
  const notificationsWithoutFilter = useSelector(notificationWithFilterSelector);
  const { filter, status, notifications, availableMailBoxes, offset, limit } = useSelector(
    notificationSelector
  ) as INotificationModule;

  const getNotificationsPerMailbox = (availableMailBoxes: number) =>
    Math.max(Math.ceil(50 / availableMailBoxes), 5);

  useEffect(() => {
    const mailboxesCount = mailBoxIds?.length || 0;
    if (mailboxesCount > 0 && limit === 0) {
      const newLimit = getNotificationsPerMailbox(mailboxesCount);
      dispatch(getNotification(0, newLimit));
    }
  }, [dispatch, limit, mailBoxIds]);

  function handleChangeFilter(event: SelectChangeEvent<string>) {
    const { value } = event.target;
    dispatch(updateFilterValue(value as INotificationModule['filter']));
  }

  const handleGetNotification = useCallback(() => {
    if (availableMailBoxes > 0) {
      setLoadingMore(true);
      const newLimit = getNotificationsPerMailbox(availableMailBoxes);
      dispatch(
        getNotification(offset + limit, newLimit, () => {
          setLoadingMore(false);
        })
      );
    }
  }, [availableMailBoxes, dispatch, limit, offset]);

  function handleMarkAllRead() {
    dispatch(markAllReadNotificationRequest());
  }
  function handleOpenNotificationDetail(data: INotificationDetail) {
    const DETAIL_PANEL_ID = 'DETAIL_PANEL_ID';
    const notificationDetailMicroFrontend: IMicroFrontend<unknown> = {
      name: 'NOTIFICATION_DETAIL', // micro frontend element
      config: {
        mode: 'actionable',
        id: data.id,
        data: { ...data },
        actionIconButtons: data.actions ?? [],
        onClose: () => {
          console.log('onClose');
        },
      },
    };
    const notificationDetailConfig: unknown = {
      type: 'APP_BAR_PANEL_TEMPLATE', // wrapper of mirco frontend
      marginTop: 55,
      marginStart: 413,
      width: 420,
      header: {
        // divider: true,
        title: 'Notification detail',
      },
      dimmed: 0,
      siblingPanelId: 'NOTIFICATION_PANEL_ID',
      parentPanelId: 'NOTIFICATION_PANEL_ID',
    };
    dispatch(unmountPanel(DETAIL_PANEL_ID));
    dispatch(
      mountPanel({
        panelId: DETAIL_PANEL_ID,
        microFrontend: notificationDetailMicroFrontend,
        panelConfig: notificationDetailConfig,
      })
    );
    dispatch(updateNotificationReadRequest(data.id));
  }
  return (
    <AIWareIntlProvider>
      <DynamicModuleLoader modules={[getNotificationModule()]}>
        {notificationsWithoutFilter.length === 0 &&
        status !== 'success' &&
        status !== 'failure' &&
        mailBoxIds.length > 0 ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : notifications?.length > 0 ? (
          <>
            <div className={classes.filterContainer} id="notification-list-header">
              <Button
                onClick={handleMarkAllRead}
                className={classes.markAllReadButton}
                data-test="notification-mark-all-read-button"
                data-testid="notification-mark-all-read-button"
              >
                <Typography variant="caption" color="primary">
                  <FormattedMessage
                    id="os-notification-panel.PtPIns"
                    defaultMessage="Mark All Read"
                    description="label of Mark All Read button"
                  />
                </Typography>
              </Button>
              <Filter
                data={[...filters, ...applicationFilters]}
                filter={filter}
                onFilter={handleChangeFilter}
              />
            </div>
            <NotificationList
              setPanelIsOpenState={setPanelIsOpenState}
              title="All time"
              onOpenNotificationDetail={handleOpenNotificationDetail}
              data={notificationsWithoutFilter}
              loading={loadingMore}
              handleGetNotification={handleGetNotification}
            />
          </>
        ) : (
          <EmptyView />
        )}
      </DynamicModuleLoader>
    </AIWareIntlProvider>
  );
};

export default NotificationPanel;
