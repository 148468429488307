import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { formattedMessages } from '../helpers/formatted-messages';
import { dateFormat } from '../helpers/utils';
import { tabStyles } from './permission-set.details.styles';

type TProps = {
  name: string | null;
  description: string | null;
  createdAt: string | null;
};

const BasicTab = ({ name, description, createdAt }: TProps) => {
  const intl = useIntl();
  const { classes } = tabStyles();
  const { basicTabHeader, permissionSetNameLabel, createdAtLabel, permissionSetDescriptionLabel } =
    formattedMessages(intl);
  return (
    <div
      className={classes.root}
      data-testid="admin-center.permission-set-details.basic-tab"
      data-test="admin-center.permission-set-details.basic-tab"
    >
      <Typography variant="h2" className={classes.title}>
        {basicTabHeader}
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <div className={classes.gridItem}>
            <Typography variant="body2">{permissionSetNameLabel}</Typography>
            <span
              data-testid="admin-center.permission-set-details.basic-tab.set-name"
              data-test="admin-center.permission-set-details.basic-tab.set-name"
            >
              {name}
            </span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.gridItem}>
            <Typography variant="body2">{createdAtLabel}</Typography>
            <span
              data-testid="admin-center.permission-set-details.basic-tab.set-created-date"
              data-test="admin-center.permission-set-details.basic-tab.set-created-date"
            >
              {dateFormat(createdAt)}
            </span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.gridItem}>
            <Typography variant="body2">{permissionSetDescriptionLabel}</Typography>
            <span
              data-testid="admin-center.permission-set-details.basic-tab.set-description"
              data-test="admin-center.permission-set-details.basic-tab.set-description"
            >
              {description}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicTab;
