import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Tab,
  Tabs,
  tabsClasses,
  TabProps,
  StepProps,
  TabsProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const tabsStyle = {
  '& .Sdk-MuiButtonBase-root.Sdk-MuiTab-root.Sdk-MuiTab-textColorPrimary.Sdk-Mui-selected': {
    color: '#2A323C!important',
  },
  '& .Sdk-MuiTabs-indicator': {
    backgroundColor: '#2A323C',
  },
};

export interface IStep {
  completed: boolean;
  stepNumber: number;
  disabled: boolean;
  error: boolean;
}

export interface IStepLabel {
  stepNumber: number;
  stepLabel: JSX.Element | string;
}

export interface IPanelStepperTabsProps {
  activeStep: number;
  steps: IStep[];
  stepLabels: IStepLabel[];
  enableTabs?: boolean;
  onClickTab?: (stepNumber: number) => void;
  onClickStep?: (stepNumber: number) => void;
  TabsProps?: TabsProps;
  TabProps?: TabProps;
  StepProps?: StepProps;
  testId?: string;
  disableBorder?: boolean;
}

export const PanelStepperTabs: React.FC<IPanelStepperTabsProps> = ({
  activeStep,
  steps,
  stepLabels,
  enableTabs = false,
  onClickTab,
  onClickStep,
  TabProps = {},
  TabsProps = {},
  StepProps = {},
  testId = 'sdk-stepper',
  disableBorder = false,
}) => {
  const { classes: stepperClasses } = stepperStyles();

  const isStepDisabled = (stepNumber: number) => {
    return steps.find(step => step.stepNumber === stepNumber)?.disabled;
  };

  const handleTabChange = (_event: React.SyntheticEvent, newVal: number) => {
    if (isStepDisabled(newVal)) return;

    if (onClickTab) {
      onClickTab(newVal);
    }
  };

  const handleClickStep = (_event: React.SyntheticEvent, newVal: number) => {
    if (isStepDisabled(newVal)) return;

    if (onClickStep) {
      onClickStep(newVal);
    }
  };

  if (enableTabs) {
    return (
      <Box
        sx={{
          padding: theme => theme.spacing(2, 6, 0, 6),
          borderBottom: disableBorder ? undefined : theme => `0.5px solid ${theme.palette.divider}`,
        }}
      >
        <Tabs
          data-testid={`panel-stepper-tabs.tabs.container-${testId}`}
          value={activeStep}
          onChange={handleTabChange}
          variant="scrollable"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Sdk-Mui-disabled': { opacity: 0.3 },
            },
            ...tabsStyle,
          }}
          scrollButtons
          {...TabsProps}
        >
          {steps.map(step => {
            const { stepNumber, error, disabled } = step;
            const TabLabel = stepLabels.find(
              (stepLabel: IStepLabel) => stepLabel.stepNumber === stepNumber
            )?.stepLabel;

            return (
              <Tab
                disabled={disabled}
                sx={error ? { color: '#d32f2f' } : {}}
                key={stepNumber}
                label={<>{TabLabel}</>}
                value={stepNumber}
                data-testid={`panel-stepper-tabs.tab.${stepNumber}.${testId}`}
                {...TabProps}
              />
            );
          })}
        </Tabs>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: theme => theme.spacing(4, 6, 4, 6),
        borderBottom: disableBorder ? undefined : theme => `0.5px solid ${theme.palette.divider}`,
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map(step => {
          const { stepNumber, completed } = step;
          const label = stepLabels.find(
            (stepLabel: IStepLabel) => stepLabel.stepNumber === stepNumber
          )?.stepLabel;

          const isPreviousStep = stepNumber < activeStep;
          const isFutureStep = stepNumber > activeStep;

          const className = `${stepperClasses.step} ${isFutureStep ? stepperClasses.futureStep : ''} ${
            isPreviousStep ? stepperClasses.previousStep : ''
          } `;

          return (
            <Step
              sx={{ paddingLeft: stepNumber === 1 ? '0px' : undefined }}
              className={className}
              onClick={event => handleClickStep(event, stepNumber)}
              key={stepNumber}
              completed={completed}
              {...StepProps}
              data-testid={`panel-stepper-tabs.stepper.${stepNumber}.${testId}`}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export const stepperStyles = makeStyles()(_theme => ({
  step: {
    '& svg': {
      fontSize: '37px',
      fill: '#465364',
    },
    '& text': {
      fontSize: '10px',
      translate: '0px -1px',
    },
  },
  previousStep: {
    '& svg': {
      borderRadius: '50%',
    },
    '&:not(.is-first-step)': {
      // marginLeft: '-35px',
    },
  },
  futureStep: {
    '& svg': {
      fill: '#F2F5F9!important',
      borderRadius: '50%',
    },
    '& text': {
      fill: '#5C6269',
    },
    '&:not(.is-last-step)': {
      // marginRight: '-25px',
    },
  },
  hiddenStep: {
    paddingRight: '0',
    '& svg': {
      fill: '#F2F5F9!important',
      border: '2px solid white',
      color: '#5C6269',
      fontSize: '32px',
    },
    '& text': {
      fill: '#5C6269',
    },
    '&+.Sdk-MuiStepConnector-horizontal': {
      display: 'none',
    },
  },
}));
