import { FormControl, MenuItem, Tooltip, Typography, Stack } from '@mui/material/';
import ListSubheader from '@mui/material/ListSubheader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC, useCallback } from 'react';
import { filterStyles } from '../../helpers/shared-styles';
import SettingsApplications from '@mui/icons-material/SettingsApplications';
import { FormattedMessage, useIntl } from 'react-intl';
import { IEngine, IEnginesUsed } from '../types';
import DoneIcon from '@mui/icons-material/Done';

type EngineTypeFilterItem = {
  id: string;
  categoryName: string;
  engines: { engineId: string; engineName: string }[];
};

interface IEngineTypeFilter {
  entity?: 'jobs' | 'tasks';
  id: string;
  label: JSX.Element;
  items: EngineTypeFilterItem[];
  selectedValue: string[];
  onChange: (event: any) => void;
  engineList: Record<string, IEngine>;
  clearAndApply?: () => void;
  enginesUsed?: IEnginesUsed;
}

const muiSelectStyleOverrides = {
  height: '28px',
  fontSize: '0.8em',
  '& fieldset': {
    borderColor: '#D5DFE9',
    borderWidth: '2px',
    borderRadius: '6px',
  },
  '&:hover': {
    '&& fieldset': {
      borderColor: 'rgba(42,50,60,0.5)',
    },
  },
};

export const AiEngineFilter: FC<IEngineTypeFilter> = ({
  id,
  label,
  items,
  selectedValue,
  onChange,
  enginesUsed,
  engineList,
  entity = 'jobs',
  clearAndApply,
}) => {
  const { classes } = filterStyles();
  const handleClear = () => {
    const e = {
      target: {
        value: [],
      },
    };
    handleChange(e as unknown as SelectChangeEvent<string[]>);
    if (clearAndApply) {
      clearAndApply();
    }
  };

  const intl = useIntl();

  const labels = {
    engine: intl.formatMessage({
      id: 'reusable-utils.engine-label',
      defaultMessage: 'Engine',
      description: 'Engine label',
    }),
    engines: intl.formatMessage({
      id: 'reusable-utils.engines-label',
      defaultMessage: 'Engines',
      description: 'Engines label',
    }),
  };

  const handleChange = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = e;
      if (onChange) onChange(typeof value === 'string' ? value.split(',') : value);
    },
    [onChange]
  );

  const engines = (items: EngineTypeFilterItem, enginesUsed: IEnginesUsed) => {
    const engineItems = items.engines.map(engine => {
      return (
        <MenuItem
          className={classes.menuItemEngineFilter}
          key={engine.engineId}
          value={engine.engineId}
          data-testid={`processing-center.${entity}.engine-filter-option-${engine.engineId}`}
        >
          {enginesUsed && enginesUsed[engine.engineId] && (
            <DoneIcon data-test={`${entity}-engines-used-indicator-dot`} className={classes.dot} />
          )}
          {enginesUsed && !enginesUsed[engine.engineId] && (
            <span className={classes.dot} style={{ visibility: 'hidden' }} />
          )}
          {/* Empty span to reserve space */}
          <span>{engine.engineName}</span>
        </MenuItem>
      );
    });
    // There is a weird issue with select where you can't wrap in fragment and wrapping in div nullifies handleChange operation
    // so returning an array fixes the issue
    return [
      <ListSubheader className={classes.subheaderEngineFilter}>{items.categoryName}</ListSubheader>,
      engineItems,
    ];
  };

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return <div>All</div>;
    }
    const { length } = selected;
    return (
      <Tooltip title={<RenderedTooltip />}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <SettingsApplications
            sx={{
              fontSize: '16px',
              fill: '#555F7C',
              marginRight: '3px',
            }}
          />{' '}
          {length} {length > 1 ? labels.engines : labels.engine}
        </span>
      </Tooltip>
    );
  };

  const RenderedTooltip = () => {
    return (
      <ul className={classes.tooltipList}>
        {selectedValue.map(engine => {
          return <li>{engineList[engine]!.name}</li>;
        })}
      </ul>
    );
  };
  return (
    <FormControl size="small" className={classes.formControl}>
      <label htmlFor={id} className={classes.label}>
        {label}{' '}
        {selectedValue.length > 0 && (
          <span onClick={handleClear} className={classes.clearBtn}>
            <FormattedMessage
              id="shared-ai-search-results-filter-input-clear-button"
              defaultMessage="Clear"
              description="Search Filter input Clear butoon"
            />
          </span>
        )}
      </label>
      <Select
        id={id}
        data-test={`${entity}-engines-select`}
        sx={muiSelectStyleOverrides}
        variant="outlined"
        value={selectedValue}
        multiple
        displayEmpty
        className={classes.field}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={{
          sx: { maxHeight: '50vh' },
          className: 'aiware-el',
        }}
      >
        {enginesUsed && (
          <Stack
            sx={{
              position: 'sticky',
              top: '0', // Stick to the top of the container
              paddingTop: '5px',
              marginLeft: '10px',
              backgroundColor: 'white', // Ensure visibility over other content
              zIndex: '999', // Ensure visibility over other content
            }}
            direction={'row'}
            alignContent={'baseline'}
            alignItems={'baseline'}
          >
            <DoneIcon className={classes.dot} />
            <Typography data-test={`${entity}-engines-used-legend-description`} variant={'caption'}>
              <FormattedMessage
                id="shared-results-filter-engines-used-description"
                defaultMessage="Indicates whether the engine was used in recent jobs or tasks."
                description="description of legend at top of filter for engines used in recent jobs or tasks."
              />
            </Typography>
          </Stack>
        )}
        {items.map(item => engines(item, enginesUsed || {}))}
      </Select>
    </FormControl>
  );
};
