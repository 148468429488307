import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import DateFormatter from '../DateFormatter';
import { useSelector } from 'react-redux';
import { selectIsFluffy, selectTableDataSettings } from '../../../redux/selectors/ui-state';
import Typography from '@mui/material/Typography';
import { useStyles } from '../../components/useStyles';
import { TABLE_DATA_NAMES } from '../../../types/entity-data-settings';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const MediaStartTime: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showStart = columns.indexOf('mediaStartTime') >= 0;
  const isFluffy = useSelector(selectIsFluffy);
  const { classes } = useStyles();

  return (
    <>
      {showStart && !isFluffy && (
        <Grid data-test={'item-mediaStartTime'} item xs={xs}>
          <DateFormatter value={(item as ITdoVm).mediaStartTime} />
        </Grid>
      )}
      {showStart && isFluffy && (
        <Grid data-test={'item-mediaStartTime-fluffy'} item container direction="row" xs={xs}>
          <Typography
            data-test={'mediaStartTime-title'}
            className={classes.titleFluffy}
            variant="caption"
            color="textSecondary"
          >
            {TABLE_DATA_NAMES.mediaStartTime?.()}:
          </Typography>
          <Typography data-test={'mediaStartTime-date'} variant="caption" color="textSecondary">
            <DateFormatter value={(item as ITdoVm).mediaStartTime} isFluffy className={classes.timeFluffy} />
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default MediaStartTime;
