import createSvgIcon from '../createSvgIcon';

export const InstanceLocked = createSvgIcon(
  <svg width="19" height="19" viewBox="6 6 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#EAF1FA" />
    <path
      d="M29.5 24.3334H29.0833V23.5001C29.0833 22.3501 28.15 21.4167 27 21.4167C25.85 21.4167 24.9166 22.3501 24.9166 23.5001V24.3334H24.5C24.0416 24.3334 23.6666 24.7084 23.6666 25.1667V29.3334C23.6666 29.7917 24.0416 30.1667 24.5 30.1667H29.5C29.9583 30.1667 30.3333 29.7917 30.3333 29.3334V25.1667C30.3333 24.7084 29.9583 24.3334 29.5 24.3334ZM27 28.0834C26.5416 28.0834 26.1666 27.7084 26.1666 27.2501C26.1666 26.7917 26.5416 26.4167 27 26.4167C27.4583 26.4167 27.8333 26.7917 27.8333 27.2501C27.8333 27.7084 27.4583 28.0834 27 28.0834ZM28.2916 24.3334H25.7083V23.5001C25.7083 22.7876 26.2875 22.2084 27 22.2084C27.7125 22.2084 28.2916 22.7876 28.2916 23.5001V24.3334Z"
      fill="#555F7C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5412 24.4235H20.3176C20.5236 24.4235 20.7211 24.5053 20.8667 24.651C21.0123 24.7966 21.0941 24.9941 21.0941 25.2H22V26.7529H21.0941C21.0941 26.9589 21.0123 27.1564 20.8667 27.302C20.7211 27.4476 20.5236 27.5294 20.3176 27.5294H17.2118C17.0058 27.5294 16.8083 27.4476 16.6627 27.302C16.5171 27.1564 16.4353 26.9589 16.4353 26.7529H11V25.2H16.4353C16.4353 24.9941 16.5171 24.7966 16.6627 24.651C16.8083 24.5053 17.0058 24.4235 17.2118 24.4235H17.9882V22.8706H12.5529C12.347 22.8706 12.1495 22.7888 12.0039 22.6432C11.8583 22.4976 11.7765 22.3001 11.7765 22.0941V18.9882C11.7765 18.7823 11.8583 18.5848 12.0039 18.4392C12.1495 18.2936 12.347 18.2118 12.5529 18.2118H24.9765C25.1824 18.2118 25.3799 18.2936 25.5255 18.4392C25.6711 18.5848 25.7529 18.7823 25.7529 18.9882V19.5618C25.1699 19.6106 24.6355 19.8307 24.2 20.1715V19.7647H16.4353V21.3176H23.3146C23.1925 21.6005 23.1138 21.9064 23.0868 22.227C22.7764 22.3677 22.5116 22.5918 22.3211 22.8706H19.5412V24.4235ZM24.8877 22.4751C24.8877 21.9464 25.258 21.503 25.7529 21.3904V21.3904C25.3942 21.472 25.1009 21.7274 24.9667 22.0631C24.9157 22.1906 24.8877 22.3296 24.8877 22.4751V22.8706H24.8877V22.4751ZM12.5529 12H24.9765C25.1824 12 25.3799 12.0818 25.5255 12.2274C25.6711 12.373 25.7529 12.5705 25.7529 12.7765V15.8824C25.7529 16.0883 25.6711 16.2858 25.5255 16.4314C25.3799 16.577 25.1824 16.6588 24.9765 16.6588H12.5529C12.347 16.6588 12.1495 16.577 12.0039 16.4314C11.8583 16.2858 11.7765 16.0883 11.7765 15.8824V12.7765C11.7765 12.5705 11.8583 12.373 12.0039 12.2274C12.1495 12.0818 12.347 12 12.5529 12ZM16.4353 15.1059H24.2V13.5529H16.4353V15.1059ZM13.3294 13.5529V15.1059H14.8824V13.5529H13.3294ZM13.3294 19.7647V21.3176H14.8824V19.7647H13.3294Z"
      fill="#555F7C"
      stroke="#555F7C"
    />
  </svg>,
  'InstanceLocked'
);
