import { StandardIcons } from '../icons';
import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Tooltip, TextField, Typography } from '@mui/material';

const iconNames = Object.keys(StandardIcons);
const iconList = [...Object.values(StandardIcons).map(Icon => <Icon />)].map((icon, index) => {
  return { Icon: icon, name: iconNames[index] };
});

export const AiwareIconBrowser = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIcon, setActiveIcon] = useState(null);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClickIcon = (icon: any) => setActiveIcon(icon);

  return (
    <Box
      sx={{
        height: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        position: 'relative',
      }}
    >
      <Box sx={{ marginTop: '10px' }}>
        <TextField
          fullWidth
          label="Search aiWARE Shared Icons"
          type="text"
          placeholder="Enter Icon Name"
          value={searchTerm}
          onChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          overflowY: 'auto',
          maxHeight: '90%',
          overflowX: 'hidden',
          gap: '30px',
          justifyContent: 'space-between',
          padding: '10px',
          boxSizing: 'border-box',
          paddingTop: '30px',
          border: '1px solid #c4c4c4',
          borderRadius: '10px',
        }}
      >
        {iconList
          .filter(icon => icon.name?.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((icon: any, index) => {
            return <IconContainer key={index} onClick={() => handleClickIcon(icon)} icon={icon} />;
          })}
      </Box>
      {activeIcon && <IconModal icon={activeIcon} onClose={() => setActiveIcon(null)} />}
    </Box>
  );
};

export interface IconObj {
  Icon: JSX.Element;
  name: string;
}

export interface IIconContainerProps {
  icon: IconObj;
  onClick: (icon: any) => void;
}

const IconContainer: React.FC<IIconContainerProps> = ({ icon, onClick }) => {
  const { Icon, name } = icon;

  return (
    <Box
      onClick={onClick}
      sx={{
        width: '130px',
        height: '130px',
        borderRadius: '10px',
        background: '#f7f7f7',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '10px',
        '&:hover': {
          background: '#e1e1e1',
          cursor: 'pointer',
        },
      }}
    >
      {Icon}
      <Typography variant={'caption'} sx={{ width: '70%', textAlign: 'center' }} noWrap>
        {name}
      </Typography>
    </Box>
  );
};

const IconModal: React.FC<{ icon: IconObj; onClose: () => void }> = ({ icon, onClose }) => {
  const { Icon, name } = icon;
  const [copied, setCopied] = useState(false);
  const importString = `import { ${name} } from '@aiware/shared/icons';`;
  const componentString = `<${name} />`;

  const containerStyles = {
    position: 'absolute',
    width: '100%',
    height: '92%',
    background: 'rgb(194 194 194 / 35%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '0',
  };

  const modalStyles = {
    padding: '10px',
    background: 'rgb(255 255 255 / 70%)',
    borderRadius: '5px',
    width: '70%',
    height: '50%',
    backdropFilter: 'blur(8px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
  };

  const codeStyles: React.CSSProperties = {
    background: '#e5e5e5',
    color: '#673ab7',
    padding: '10px',
    borderRadius: '5px',
    pointerEvents: 'none',
  };

  const handleCopy = (value: string) => {
    setCopied(true);
    window.navigator.clipboard.writeText(value);
  };

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 500);
  }, [copied]);
  return (
    <Box onClick={onClose} sx={containerStyles}>
      <Box onClick={e => e.stopPropagation()} sx={modalStyles}>
        <Box sx={{ transform: 'scale(3.5)', marginBottom: '10px' }}>{Icon}</Box>
        <Typography>{icon.name}</Typography>
        <Typography>Component:</Typography>
        <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
          <span onClick={() => handleCopy(componentString)} style={{ cursor: 'pointer' }}>
            <code style={codeStyles}>{componentString}</code>
          </span>
        </Tooltip>
        <Typography>Import String:</Typography>
        <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
          <span onClick={() => handleCopy(importString)} style={{ cursor: 'pointer' }}>
            <code style={codeStyles}>{importString}</code>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
