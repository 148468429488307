import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { formattedMessages } from '../helpers/formatted-messages';
import { tabStyles } from './permission-set.details.styles';
import BasicTab from '../new-permission-set/basic-tab';
import { TPermissionSet } from '../../types';

type TProps = {
  permissionSet: Partial<TPermissionSet>;
  nameExistsCheck: (value: boolean) => void;
  setNewKeyValue: (payload: any) => void;
  isPermissionProtected?: boolean;
};

const GeneralTab = ({ permissionSet, nameExistsCheck, setNewKeyValue, isPermissionProtected }: TProps) => {
  const intl = useIntl();
  const { classes } = tabStyles();
  const { settingsTabHeader } = formattedMessages(intl);
  return (
    <div
      className={classes.root}
      data-testid="admin-center.permission-set-details.general-tab"
      data-test="admin-center.permission-set-details.general-tab"
    >
      <Typography variant="h2" className={classes.title}>
        {settingsTabHeader}
      </Typography>
      <BasicTab
        permissionSet={permissionSet}
        onNameChange={(name: string) => setNewKeyValue({ name })}
        onDescriptionChange={(description: string) => setNewKeyValue({ description })}
        nameExistsCheck={nameExistsCheck}
        isPermissionProtected={isPermissionProtected}
      />
    </div>
  );
};

export default GeneralTab;
