import { baseGraphQLApi, baseGraphQLApiWithError } from '@aiware/shared/redux';
import { LIMIT, ISchedule, SourceId } from '../types';
import { selectApiConfigs } from '../redux/selectors';
export async function getStreamingSchedulesBySourceId(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset = 0,
  mediaSourceId: SourceId
) {
  const searchLimit = LIMIT;
  const operationName = 'getSchedules';
  const name = 'scheduledJobs';
  const query = `
    query ${operationName}($offset: Int, $limit: Int, $primarySourceId: [ID!]) {
      ${name}(offset:$offset, limit:$limit, primarySourceId: $primarySourceId ) {
        count
        limit
        records{
          id
          name
          isActive
          modifiedDateTime
          createdDateTime
          details
          permission
          primarySourceId
          primarySourceTypeId
          startDateTime
          stopDateTime
          runMode
          allJobTemplates {
            records {
              jobConfig
            }
          }
          parts {
            repeatInterval
            repeatIntervalUnit
            startTime
            stopTime
            scheduleType
            scheduledDay: scheduledDayLocal
            durationSeconds
          }
          jobs (status:running) {
            records {
              id
            }
          }
        }
      }
    }
  `;
  const variables = {
    offset,
    limit: searchLimit,
    primarySourceId: [mediaSourceId],
  };
  let result;

  try {
    result = await baseGraphQLApiWithError<{
      [name]: {
        limit: number;
        count: number;
        records: [ISchedule];
      };
    }>({
      query,
      variables,
      operationName,
      ...apiConfigs,
    });

    const updatedRecords = result[name].records.map((schedule: ISchedule) => {
      return {
        id: schedule.id,
        name: schedule.name,
        sourceId: schedule.primarySourceId,
        modifiedDateTime: schedule.modifiedDateTime,
        createdDateTime: schedule.createdDateTime,
        imageUrl: schedule?.details?.programLiveImage ?? '',
        permission: schedule.permission,
        isActive: schedule.isActive,
        jobs: schedule.jobs,
        runMode: schedule.runMode,
        startDateTime: schedule.startDateTime,
        stopDateTime: schedule.stopDateTime,
        parts: schedule.parts,
        allJobTemplates: schedule.allJobTemplates,
      };
    });

    return {
      limit: result[name].limit,
      count: result[name].count,
      records: updatedRecords,
    };
  } catch (e) {
    return {};
  }
}

export async function updateScheduledJobStatus(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  id: string,
  newStatus: boolean
): Promise<string> {
  const operationName = 'updateScheduledJob';

  const query = `mutation ${operationName} {
      updateScheduledJob (input : {
        id : "${id}",
        isActive : ${newStatus}
      }) {
        id
        isActive
        name
      }
    }`;

  const graphEndpoint = apiConfigs.graphQLEndpoint;
  const token = apiConfigs.token;

  const result = await baseGraphQLApi<{
    id: string;
  }>({
    query,
    operationName,
    graphEndpoint,
    token,
  });

  if (result?.errors?.length) {
    throw new Error(result.errors[0].message);
  }

  return result.data.updateScheduledJob.id;
}
