import { useState, FC } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddImageIcon } from '../../components/AddImageIcon/AddImageIcon';
import { useStyles } from './AddImageBox.styles';

interface IAddImageBox {
  title: JSX.Element;
  backgroundImgUrl?: string;
  aspectRatio?: 'avatar' | 'cover';
  onClick: (() => void) | undefined;
  onDelete: () => void;
}

export const AddImageBox: FC<IAddImageBox> = ({
  title,
  backgroundImgUrl = '',
  aspectRatio = 'avatar',
  onClick,
  onDelete,
}) => {
  const { classes } = useStyles();

  const [hover, setHover] = useState(false);
  const isBackgroundVisible = !!backgroundImgUrl;
  const isShadowVisible = hover && backgroundImgUrl;
  const isDeleteIconVisible = hover && backgroundImgUrl;
  const isAddImageIconVisible = !backgroundImgUrl || (hover && backgroundImgUrl);
  const backgroundStyle = backgroundImgUrl
    ? { background: `url(${backgroundImgUrl}) center/contain no-repeat` }
    : {};

  return (
    <div className={classes.addImageBoxContainer}>
      <Typography
        id={`file-item-meta-${aspectRatio}-image-title`}
        variant="body2"
        className={classes.addImageBoxTitle}
        color="secondary"
        data-test={`file-item-meta-${aspectRatio}-image-title`}
      >
        {title}
      </Typography>
      <Button
        className={classes.iconButtonContainer}
        sx={{
          padding: 0,
        }}
        onClick={onClick}
      >
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`${classes.addImageBoxIconDiv} ${
            aspectRatio === 'avatar' ? classes.addImageBoxSizeAvatar : classes.addImageBoxSizeCover
          }`}
        >
          {isAddImageIconVisible && !isDeleteIconVisible && (
            <AddImageIcon
              id={`add-${aspectRatio}-img`}
              color={backgroundImgUrl ? '#fff' : '#2A323C'}
              size={45}
            />
          )}
          {isDeleteIconVisible && (
            <div className={classes.deleteIconContainer} onClick={onDelete}>
              <DeleteIcon style={{ fontSize: 42 }} className={classes.deleteIcon} color="inherit" />
            </div>
          )}
          {isShadowVisible && <div className={classes.addImageBoxShadow}></div>}
          {isBackgroundVisible && (
            <div className={classes.addImageBoxBackground} style={backgroundStyle}></div>
          )}
        </div>
      </Button>
    </div>
  );
};
