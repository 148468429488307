import { FormattedMessage } from 'react-intl';

// TODO consider breaking this up if too big

export const SHARED_TEXT = {
  closePanel: () => (
    <FormattedMessage
      id="os-data-center-browse.kOsSpC"
      defaultMessage="Close Panel"
      description="Close Panel"
    />
  ),
  toggleNavigationMenu: () => (
    <FormattedMessage
      id="os-data-center-browse.0+eN3J"
      defaultMessage="Toggle Navigation Menu"
      description="Toggle Navigation Menu"
    />
  ),
  exitFullScreen: () => (
    <FormattedMessage
      id="os-data-center-browse.awe7uYd"
      defaultMessage="Exit Fullscreen"
      description="Exit Fullscreen"
    />
  ),
  enterFullScreen: () => (
    <FormattedMessage
      id="os-data-center-browse.stiZt+a"
      defaultMessage="Enter Fullscreen"
      description="Enter Fullscreen"
    />
  ),
  toggleFullScreen: () => (
    <FormattedMessage
      id="os-data-center-browse.TUGpHT"
      defaultMessage="Toggle FullScreen"
      description="Toggle FullScreen"
    />
  ),
  toggleView: () => (
    <FormattedMessage
      id="os-data-center-browse.l+TYZe"
      defaultMessage="Toggle View"
      description="Toggle View"
    />
  ),
  toggleInfoPanel: () => (
    <FormattedMessage
      id="os-data-center-browse.8G5AuV"
      defaultMessage="Info"
      description="Toggle Info Pane"
    />
  ),
  delete: () => (
    <FormattedMessage id="os-data-center-browse.qviIFp" defaultMessage="Delete" description="Delete" />
  ),
  move: () => <FormattedMessage id="os-data-center-browse.qviIFx" defaultMessage="Move" description="Move" />,
  process: () => (
    <FormattedMessage
      id="os-data-center-browse.PrOZHm"
      defaultMessage="Process with AI"
      description="Process with AI"
    />
  ),
  viewFileInfo: () => (
    <FormattedMessage
      id="os-data-center-browse.context-menu.file-info"
      defaultMessage="View File Info"
      description="View File Info"
    />
  ),
  editMetadata: () => (
    <FormattedMessage
      id="os-data-center-browse.context-menu.edit-metadata"
      defaultMessage="Edit Metadata"
      description="Edit Metadata"
    />
  ),
  download: () => (
    <FormattedMessage
      id="os-data-center-browse.context-menu.download"
      defaultMessage="Download"
      description="Download"
    />
  ),
  confirmDelete: () => (
    <FormattedMessage
      id="os-data-center-browse.mPEQWb"
      defaultMessage="Confirm Delete"
      description="Confirm Delete"
    />
  ),
  cancel: () => (
    <FormattedMessage id="os-data-center-browse.6PdOcy" defaultMessage="Cancel" description="Cancel" />
  ),
  create: () => (
    <FormattedMessage id="os-data-center-browse.+9U45j" defaultMessage="Create" description="Create" />
  ),
  submit: () => (
    <FormattedMessage id="os-data-center-browse.lOLf4e" defaultMessage="Submit" description="Submit" />
  ),
  show: () => <FormattedMessage id="os-data-center-browse.HTVdQU" defaultMessage="Show" description="Show" />,
  hide: () => <FormattedMessage id="os-data-center-browse.sX3mXR" defaultMessage="Hide" description="Hide" />,

  myFiles: () => (
    <FormattedMessage id="os-data-center-browse.bmn0+Y" defaultMessage="My Files" description="My Files" />
  ),
  folderName: () => (
    <FormattedMessage
      id="os-data-center-browse.YlWfEv"
      defaultMessage="Folder Name"
      description="Folder Name"
    />
  ),
  newFolder: () => (
    <FormattedMessage
      id="os-data-center-browse.2EVawj"
      defaultMessage="New Folder"
      description="New Folder"
    />
  ),
  editFolder: () => (
    <FormattedMessage
      id="os-data-center-browse.wK+IFw"
      defaultMessage="Edit Folder"
      description="Edit Folder"
    />
  ),
  editSource: () => (
    <FormattedMessage
      id="os-data-center-browse.rqOmfY"
      defaultMessage="Edit Source"
      description="Edit Source"
    />
  ),
  viewInfo: () => (
    <FormattedMessage
      id="os-data-center-browse.view-info.da2mfY"
      defaultMessage="View Info"
      description="View info menu item"
    />
  ),
  editSchedule: () => (
    <FormattedMessage
      id="os-data-center-browse.atARIC"
      defaultMessage="Edit Schedule"
      description="Edit Schedule"
    />
  ),
  activateSchedule: () => (
    <FormattedMessage
      id="os-data-center-browse.activate-schedule"
      defaultMessage="Activate"
      description="Activate Schedule"
    />
  ),
  deactivateSchedule: () => (
    <FormattedMessage
      id="os-data-center-browse.deactivate-schedule"
      defaultMessage="Deactivate"
      description="Deactivate Schedule"
    />
  ),
  tableDataSettings: () => (
    <FormattedMessage
      id="os-data-center-browse.ysxB6G"
      defaultMessage="Table Data Settings"
      description="Table Data Settings"
    />
  ),
  addRemoveDataSettings: () => (
    <FormattedMessage
      id="os-data-center-browse.kN+jRA"
      defaultMessage="Add and remove data from your table view."
      description="Add and remove data from your table view."
    />
  ),
  tableHeaderStreams: () => (
    <FormattedMessage id="os-data-center-browse.onzSBI" defaultMessage="Sources" description="Sources" />
  ),
  tableHeaderSchedules: () => (
    <FormattedMessage id="os-data-center-browse.Reu+b6" defaultMessage="Schedules" description="Schedules" />
  ),
  tableHeaderTdos: () => (
    <FormattedMessage id="os-data-center-browse.bmn1+Y" defaultMessage="Files" description="Files" />
  ),

  emptyChildFolderTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.bmn2+Y"
      defaultMessage="No Files in this Folder"
      description="No Files in this Folder"
    />
  ),
  emptyChildFolderSubtitle: () => (
    <FormattedMessage
      id="os-data-center-browse.f0MN8j"
      defaultMessage="Choose this folder location during data import to fill it up"
      description="Choose this folder location during data import to fill it up"
    />
  ),
  emptyRootFolderTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.BMfRsg"
      defaultMessage="Data Center lets you access and organize all your files"
      description="Data Center lets you access and organize all your files"
    />
  ),
  emptyRootFolderSubtitle: () => (
    <FormattedMessage
      id="os-data-center-browse.tkXFWt"
      defaultMessage="Add data using the 'Add New' button"
      description="Add data using the 'Add New' button"
    />
  ),
  emptyTdosTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.bmn3+Y"
      defaultMessage="No Files Found"
      description="No Files Found"
    />
  ),
  emptyTdosSubtitle: () => (
    <FormattedMessage
      id="os-data-center-browse.BeK9HA"
      defaultMessage="This source has not imported any data"
      description="This source has not imported any data"
    />
  ),
  emptySchedulesTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.zfy6v9"
      defaultMessage="Data Center lets you access all your sources"
      description="Data Center lets you access all your sources"
    />
  ),
  emptySchedulesSubtitle: () => (
    <FormattedMessage
      id="os-data-center-browse.x1YB5K"
      defaultMessage="Add New Sources"
      description="Add New Sources"
    />
  ),
  emptySourcesTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.GxGu0g"
      defaultMessage="No Sources Found"
      description="No Sources Found"
    />
  ),
  emptySourcesSubtitle: () => (
    <FormattedMessage
      id="os-data-center-browse.9VMaxp"
      defaultMessage="Click the ‘Add New Source’ button below to add your first source."
      description="Click the ‘Add New Source’ button below to add your first source."
    />
  ),
  emptySourcesButton: () => (
    <FormattedMessage
      id="os-data-center-browse.BkUwMv"
      defaultMessage="Add New Source"
      description="Add New Source"
    />
  ),
  tableRowErrorButtonRetry: () => (
    <FormattedMessage
      id="os-data-center-browse.+Y7nhc"
      defaultMessage="There was an error loading the data - retry"
      description="There was an error loading the data - retry"
    />
  ),
  noEngineFound: () => (
    <FormattedMessage
      id="os-data-center-browse.f2zo7s"
      defaultMessage="No Engine Found"
      description="No Engine Found"
    />
  ),
  checkingPermissionsTooltip: () => (
    <FormattedMessage
      id="os-data-center-browse.checkingPermissionsTooltip"
      defaultMessage="Checking your permissions to this item"
      description="Checking your permissions to this item"
    />
  ),
  noPermissionsTooltip: () => (
    <FormattedMessage
      id="os-data-center-browse.noPermissionsTooltip"
      defaultMessage="You don't have access to this action"
      description="You don't have access to this action"
    />
  ),
  selectItemTooltip: () => (
    <FormattedMessage
      id="os-data-center-browse.select-item-tooltip"
      defaultMessage="Select this item"
      description="Tooltip, select this item"
    />
  ),
  unselectItemTooltip: () => (
    <FormattedMessage
      id="os-data-center-browse.unselect-item-tooltip"
      defaultMessage="Unselect this item"
      description="Tooltip, unselect this item"
    />
  ),
};
