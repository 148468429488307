import {
  hamburgerIcon,
  helpActive,
  notificationActive,
  setting,
  veritoneLogo,
  veritoneLogoActive,
  applicationTabIcon,
  dashboardIcon,
  notificationOutlined,
  helpCircle,
} from '@aiware/shared/assets';
import { Theme } from '@mui/material/styles';
import { MouseEventHandler } from 'react';

import { makeStyles } from 'tss-react/mui';

interface IAppBarStyle {
  titleColor?: string;
  logo?: string;
  activeColor?: string;
  onClickLogo?: MouseEventHandler<HTMLSpanElement>;
}

const useStyles = makeStyles<
  IAppBarStyle,
  'left' | 'right' | 'controllers' | 'searchBarHolder' | 'appLogo' | 'logo'
>()((theme: Theme, { titleColor, logo, activeColor, onClickLogo }, classes) => ({
  appBar: {
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    position: 'fixed',
    boxShadow: 'none',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    [`& .${classes.logo}`]: {
      cursor: 'pointer',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
        userSelect: 'none',
      },
    },
    backgroundColor: theme.palette.background.default,
  },
  menuButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    marginLeft: theme.spacing(5),
  },
  margin: {
    marginRight: theme.spacing(0.4),
    display: 'flex',
    alignItems: 'center',
  },
  barIcon: {
    padding: theme.spacing(0.8),
  },
  iconButton: {
    padding: 0,
  },
  hamburgerIcon: {
    padding: theme.spacing(1.4),
    width: 32,
    height: 32,
    WebkitMask: `url("${hamburgerIcon}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
  },
  appLogo: {
    display: 'inline-block',
    width: 150,
    height: 32,
    backgroundImage: `url("${logo}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    cursor: onClickLogo ? 'pointer' : 'default',
  },
  title: {
    fontStyle: 'normal !important',
    fontWeight: 600,
    fontSize: '18px !important',
    lineHeight: '22px !important',
    letterSpacing: '0.225px !important',
    mixBlendMode: 'normal',
    textTransform: 'uppercase',
    color: `${titleColor} important`,
    whiteSpace: 'nowrap',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  content: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 3.2),
    '& > div': {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
    },
    [`& .${classes.left}`]: {
      justifyContent: 'flex-start',
    },
    [`& .${classes.right}`]: {
      justifyContent: 'flex-end',
    },
    [`& .${classes.searchBarHolder}`]: {
      flexGrow: 1,
    },
    [`& .${classes.appLogo}`]: {
      userSelect: 'none',
    },
    [`& .${classes.controllers}`]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  iconGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconGroupIcon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rightActionLabel: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    textDecoration: 'none',
    color: 'white',
    cursor: 'pointer',
    display: 'block',
  },
  noSelect: {
    userSelect: 'none',
  },
  active: {
    opacity: 1,
  },
  smallOrgAvatar: {
    width: '30px !important',
    height: '30px !important',
    fontSize: '16px !important',
  },
  passive: {
    opacity: 0.8,
  },
  icon: {
    width: '40px !important',
    height: '40px !important',
    padding: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  logo: {},
  left: {},
  right: {},
  searchBarHolder: {},
  controllers: {},
  appIcon: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    marginLeft: theme.spacing(4.4),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  applicationIcon: {
    WebkitMask: `url("${applicationTabIcon}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
    '&:hover': {
      backgroundColor: titleColor,
    },
  },
  veritoneLogo: {
    height: '30px',
    width: '30px',
    WebkitMask: `url("${veritoneLogo}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
    '&:hover': {
      backgroundColor: titleColor,
    },
  },
  openPanel: {
    backgroundImage: `url("${veritoneLogoActive}") !important`,
  },

  notificationIcon: {
    WebkitMask: `url("${notificationOutlined}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
    '&:hover': {
      backgroundColor: titleColor,
    },
  },
  applicationSettingsIcon: {
    WebkitMask: `url("${setting}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
    '&:hover': {
      backgroundColor: titleColor,
    },
  },
  dashboardIcon: {
    WebkitMask: `url("${dashboardIcon}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
    '&:hover': {
      backgroundColor: titleColor,
    },
  },
  openNotificationPanel: {
    backgroundImage: `url(${notificationActive}) !important`,
  },
  orgIcon: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  logoIcon: {
    WebkitMask: `url("${logo}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
  },
  helpIcon: {
    WebkitMask: `url("${helpCircle}") no-repeat 50% 50%`,
    backgroundColor: titleColor,
    '&:hover': {
      backgroundColor: titleColor,
    },
  },
  openHelp: {
    backgroundImage: `url(${helpActive}) !important`,
  },
  orgButton: {
    padding: theme.spacing(2),
  },
  panelActive: {
    borderRadius: '50%',
    backgroundColor: activeColor,
  },
  notificationBadge: {
    '& > span': {
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  appBarSkeleton: {
    position: 'absolute',
    height: 55,
  },
  marginRightSkeleton: {
    marginRight: theme.spacing(4),
  },
  leftContainerSkeleton: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(5),
  },
}));

export { useStyles };
