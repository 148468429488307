import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme, _props, classes) => ({
  inviteRequestsRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  headerDescription: {
    padding: theme.spacing(2, 6, 4, 6),
    borderBottom: '.5px solid #D5DFE9',
    userSelect: 'none',
  },
  inviteRequestsListRoot: {
    padding: theme.spacing(0, 6),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  inviteRequestsListActions: {
    padding: theme.spacing(5, 0, 4, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  listItemRoot: {
    border: '0.5px solid #D5DFE9',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  userName: {
    color: '#2A323C',
    fontWeight: 400,
  },
  requestor: {
    color: '#2A323C',
    fontWeight: 600,
    marginTop: theme.spacing(3),
  },
  email: {
    color: '#5C6269',
    fontWeight: 400,
  },
  subheading: {
    fontWeight: 600,
    color: '#5C6269',
  },
  appListItem: {
    display: 'flex',
    alignItems: 'center',

    '&:not(:last-child)': {
      borderBottom: '0.5px solid #D5DFE9',
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  actionButtons: {
    borderTop: '0.5px solid #D5DFE9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2, 4),
  },
  actionButton: {
    color: '#2A323C',
    margin: theme.spacing(0, 4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  actionButtonApproved: {
    background: '#488F00',
    color: '#FFF',

    '&:hover': {
      background: '#488F00',
    },
  },
  actionButtonRejected: {
    background: '#CF1919',
    color: '#FFF',

    '&:hover': {
      background: '#CF1919',
    },
  },
}));

export default useStyles;
