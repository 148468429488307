export interface ISchemaProperties {
  type: string;
  path: string;
  searchPath: string;
  title: string | null;
  propertyDefinition: any;
  schema: {
    id: string;
    definition: { properties?: any };
    dataRegistry: {
      name: string;
      organization: {
        name: string;
      };
    };
    majorVersion: number;
  };
}

export interface IStructuredDataInput {
  searchPath: string;
  propertyDefinition: { type?: EValidJSONSchemaType; format?: string };
  operator: string;
  matchValueOne: string;
  matchValueTwo: string;
}

export enum EValidJSONSchemaType {
  string = 'string',
  number = 'number',
  integer = 'integer',
  boolean = 'boolean',
  array = 'array',
  object = 'object',
  null = 'null',
}

export enum EJSONSchemaStringFormat {
  dateTime = 'date-time',
  time = 'time',
  date = 'date',
  duration = 'duration',
  email = 'email',
  idnEmail = 'idn-email',
  hostname = 'hostname',
  ipv4 = 'ipv4',
  ipv6 = 'ipv6',
  uuid = 'uuid',
  uri = 'uri',
  uriReference = 'uri-reference',
  iri = 'iri',
  iriReference = 'iri-reference',
  uriTemplate = 'uri-template',
  jsonPointer = 'json-pointer',
  regex = 'regex',
}
export enum EStringOperatorType {
  'contains' = 'contains',
  'not_contains' = 'not_contains',
  'is' = 'is',
  'is_not' = 'is_not',
}

export enum ENumericOperatorType {
  'is' = 'is',
  'is_not' = 'is_not',
  'gt' = 'gt',
  'gte' = 'gte',
  'lt' = 'lt',
  'lte' = 'lte',
  'range' = 'range',
}

export enum EBooleanOperatorType {
  'is' = 'is',
}

export const SupportedJSONSchemaTypes = [
  EValidJSONSchemaType.string,
  EValidJSONSchemaType.number,
  EValidJSONSchemaType.integer,
  EValidJSONSchemaType.boolean,
  // The types below we do not intend to support on the UI at this time.
  //
  // EValidJSONSchemaType.array,
  // EValidJSONSchemaType.object,
  // EValidJSONSchemaType.null
];
