import { makeStyles } from 'tss-react/mui';

interface Props {
  width: number;
  right: number;
}

const useStyles = makeStyles<Props>()((theme, { width, right }) => ({
  content: {
    height: 'calc(100% - 56px)',
    background: '#FFFFFF',
    overflow: 'hidden',
    width,
  },
  drawerContainer: {},
  paperContainer: {
    top: 56,
    backgroundColor: 'unset !important',
    boxShadow: 'none',
  },
  paperAnchorRight: {
    right: right > 0 ? right - 1 : 0,
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 10px 12px 2px',
  },
}));
export default useStyles;
