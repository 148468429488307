import { Component, ErrorInfo, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

interface IErrorBoundaryProps {
  children?: ReactNode;
  fallbackUI?: ReactNode;
  onDidCatchError?: (error: Error, errorInfo: ErrorInfo) => void;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): IErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary - Uncaught error:', error, errorInfo);
    const { onDidCatchError } = this.props;
    if (onDidCatchError) {
      onDidCatchError(error, errorInfo);
    }
  }

  public render() {
    const { fallbackUI, children } = this.props;

    if (this.state.hasError) {
      return fallbackUI || DefaultFallbackUI();
    }
    return children;
  }
}

const DefaultFallbackUI = () => (
  <Stack
    sx={{
      padding: '30px',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    }}
  >
    <ErrorOutline
      sx={{
        color: '#55607c',
        fontSize: '30px',
      }}
    />
    <Typography variant={'h3'}>
      <FormattedMessage
        id="shared-reusable-utils.error-boundary.title"
        defaultMessage="Something went wrong loading this content."
        description="Error message title"
      />
    </Typography>
    <Typography variant={'body1'}>
      <FormattedMessage
        id="shared-reusable-utils.error-boundary.subtitle"
        defaultMessage="Please try again later."
        description="Error message subtitle"
      />
    </Typography>
  </Stack>
);
