import { getProcessingCenterContentTemplatesModule } from '@aiware/os/processing-center/content-templates';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareCacheProvider, AIWareThemeProvider } from '@aiware/shared/theme';
import { IFile } from '../../../types';
import { FileItemMetaContainer } from './FileItemMeta.container';

export const EditFileMetadata = (props: {
  dataId: string;
  panelRendered: boolean;
  hidePanel?: () => void;
  file: IFile;
}) => {
  return (
    <AIWareThemeProvider>
      <AIWareIntlProvider>
        <AIWareCacheProvider>
          <DynamicModuleLoader modules={[getProcessingCenterContentTemplatesModule()]}>
            <FileItemMetaContainer {...props} />
          </DynamicModuleLoader>
        </AIWareCacheProvider>
      </AIWareIntlProvider>
    </AIWareThemeProvider>
  );
};
