import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { Theme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { AlarmTwo } from '@aiware/shared/icons';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      marginTop: theme.spacing(6),
      padding: theme.spacing(4, 4),
      textAlign: 'center',
      border: '0.5px solid #D5DFE9',
      borderRadius: theme.spacing(2),
    },
    text: {
      marginTop: theme.spacing(3),
    },
    icon: {
      display: 'inline !important',
    },
  };
});

export interface IErrorStatePros {
  onRetry: () => void;
  errorMessage: JSX.Element;
}

const ErrorState = ({ onRetry, errorMessage }: IErrorStatePros) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Icon className={classes.icon}>
        <AlarmTwo fontSize="large" />
      </Icon>
      <Typography variant="body2" className={classes.text} data-test={`error-message`}>
        {errorMessage}
      </Typography>
      <Box mt={4} />
      <Button variant="text" size="large" data-test={`retry-btn`} onClick={onRetry}>
        <FormattedMessage
          id="ui.error-state.retry-button"
          defaultMessage="Retry"
          description="error state retry button"
        />
      </Button>
    </div>
  );
};
export { ErrorState };
export default ErrorState;
