import { useEffect, useRef, useState, memo } from 'react';
import { desktopIconsColor } from '../../app/app';
import { Desktop_0 } from '../../desktop-assets';
import { IconButton } from '@mui/material';
import { Settings } from '@aiware/shared/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWallpaper, selectLoadingStatus, selectUserWallpapers } from '../../redux/selectors';
import useOnClickOutside from 'use-onclickoutside';
import { defaultImagesMap } from './helper';
import { ContextMenu } from './context-menu';
import { BackgroundSelection } from './background-selection';
import { actions } from '../../redux/slices/aiware-desktop.state';
import CircularProgress from '@mui/material/CircularProgress';
import { selectApiConfigs, userSelector } from '@aiware/shared/redux';

/**
 * @param currentWallpaper - wallpaper in the state of the App component, NOT in the UserSetting in db
 * @param setCurrentWallpaper - wallpaper setter in the state of the App component, NOT in the UserSetting in db
 */

type TProps = {
  currentWallpaper: string;
  setCurrentWallpaper: (image: string) => void;
};

export const WallpaperManagement = memo(({ currentWallpaper, setCurrentWallpaper }: TProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showBackgroundSelection, setShowBackgroundSelection] = useState<boolean>(false);
  const dispatch = useDispatch();

  // wallpaperCustom_INDEX contain TDO ids
  // wallpaperCurrent: contains a signed URL to a cusom image OR one of the default images like Desktop_INDEX
  const userWallpapers: { key: string; value: string }[] = useSelector(selectUserWallpapers) || [];
  const userCurrentWallpaper = useSelector(selectCurrentWallpaper);
  const status = useSelector(selectLoadingStatus);
  const configs: ReturnType<typeof selectApiConfigs> = useSelector(selectApiConfigs);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (configs?.graphQLEndpoint && user?.userId) {
      dispatch(actions.fetchUserWallpapersStart(user?.userId));
    }
  }, [configs, user, dispatch]);

  useEffect(() => {
    if (userWallpapers && !userCurrentWallpaper && status === 'success') {
      const userCurrentWallpaperInSettings = userWallpapers.find(
        wallpaper => wallpaper.key === 'wallpaperCurrent'
      );
      if (/^\d+$/.test(userCurrentWallpaperInSettings?.value ?? '')) {
        dispatch(actions.fetchUserCurrentWallpaperTDOStart(userCurrentWallpaperInSettings?.value ?? ''));
      }
    }
  }, [userWallpapers, userCurrentWallpaper, status, dispatch]);

  useEffect(() => {
    if (userWallpapers) {
      const userCurrentWallpaperInSettings = userWallpapers.find(
        wallpaper => wallpaper.key === 'wallpaperCurrent'
      );
      // if wallpaperCurrent is set to either default image Desktop_INDEX
      if (
        userCurrentWallpaperInSettings &&
        defaultImagesMap[userCurrentWallpaperInSettings.value as keyof typeof defaultImagesMap]
      ) {
        setCurrentWallpaper(
          defaultImagesMap[userCurrentWallpaperInSettings.value as keyof typeof defaultImagesMap]
        );
      } else if (userCurrentWallpaper) {
        setCurrentWallpaper(userCurrentWallpaper);
      } else if (
        !userCurrentWallpaperInSettings ||
        userCurrentWallpaperInSettings.value === 'none' ||
        !userCurrentWallpaperInSettings.value
      ) {
        // if nothing is found or query fails - fall back to the first default image
        setCurrentWallpaper(Desktop_0);
      }
    } else if (status === 'failure') {
      setCurrentWallpaper(Desktop_0);
    }
  }, [userWallpapers, status, setCurrentWallpaper, userCurrentWallpaper]);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (showBackgroundSelection) {
      setShowBackgroundSelection(prev => !prev);
    }
    if (showMenu) {
      setShowMenu(prev => !prev);
    }
    return;
  });

  return (
    <>
      {showMenu && <ContextMenu refProp={ref} setShowBackgroundSelection={setShowBackgroundSelection} />}
      {showBackgroundSelection && (
        <BackgroundSelection
          refProp={ref}
          currentWallpaper={currentWallpaper}
          setCurrentWallpaper={setCurrentWallpaper}
        />
      )}
      {user?.userId && (
        <IconButton
          data-testid={`aiware-desktop.settings-btn`}
          ref={ref}
          sx={{ position: 'fixed', left: '50px', bottom: '50px', '& svg': { fill: desktopIconsColor } }}
          onClick={
            status !== 'pending'
              ? () => {
                  setShowMenu(prevVal => !prevVal);
                  if (showBackgroundSelection) {
                    setShowBackgroundSelection(prev => !prev);
                  }
                }
              : undefined
          }
        >
          {status === 'pending' ? <CircularProgress size={16} /> : <Settings />}
        </IconButton>
      )}
    </>
  );
});
