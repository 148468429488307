import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {
  adminAppIcon,
  cmsAppIcon,
  collectionAppIcon,
  developAppIcon,
  discoveryAppIcon,
  libraryAppIcon,
} from '@aiware/shared/assets';
import cx from 'classnames';
import useStyles from './app-icon.styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  SxProps,
} from '@mui/material';
import { removeAppFromGroup } from '../../../store/modules/app-groups';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { mountPanel } from '@aiware/js/panel';

interface IconMapper {
  [key: string]: string;
}

const styles: Record<string, SxProps> = {
  title: {
    my: 2,
  },
  content: {
    minWidth: 360,
    px: 0,
    pt: 3,
    pb: 0,
  },
  button: {
    fontSize: '12px',
  },
  actions: {
    px: 4,
    pt: 4,
    pb: 2,
  },
  cancelButton: {
    color: 'black',
    py: 2,
  },
  deleteButton: {
    py: 2,
  },
};

const iconMapper: IconMapper = {
  CMS: cmsAppIcon,
  Discovery: discoveryAppIcon,
  Collection: collectionAppIcon,
  Library: libraryAppIcon,
  Admin: adminAppIcon,
  Developer: developAppIcon,
};

export interface Props {
  id: string | number;
  selected?: boolean;
  icon?: string;
  type: 'list' | 'grid' | 'switcherGrid';
  iconUrl?: string;
  appName?: string;
  onClick: (id: string | number) => void;
  appListV2?: boolean;
  isAppGroup?: boolean;
  appGroupId?: string;
}

function AppIcon({
  id,
  icon,
  type,
  iconUrl,
  appName,
  onClick,
  appListV2 = false,
  isAppGroup,
  appGroupId,
}: Props) {
  const { classes } = useStyles({ appListV2, type });
  const [iconState, setIcon] = useState(
    icon || iconUrl || (appName && iconMapper[appName]) || developAppIcon
  );
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  function handleClickTabIcon() {
    onClick && onClick(id);
  }
  function handleErr() {
    setIcon(developAppIcon);
  }

  const handleClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    setAnchorEl(null);
    setConfirmationVisible(true);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleContextClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (ev: any) => {
    ev.stopPropagation();
    setAnchorEl(null);
    setConfirmationVisible(false);
  };
  const removeApp = (ev: any) => {
    ev.stopPropagation();
    dispatch(removeAppFromGroup({ source: appGroupId!, appId: id.toString() }));
    handleClose(ev);
  };

  const handleAppDetails = (ev: any) => {
    ev.stopPropagation();
    mountPanel({
      panelId: 'APPLICATION_DETAILS_PANEL',
      microFrontend: {
        name: 'APPLICATION_DETAILS_PANEL',
        config: {
          applicationId: id,
        },
      },
      panelConfig: {
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        marginStart: 80,
        size: 'xlarge',
      },
    });
    handleClose(ev);
  };

  return (
    <Tooltip title={appName || ''} PopperProps={{ disablePortal: true }}>
      <div
        onClick={handleClickTabIcon}
        className={cx({
          [classes.iconButton]: type === 'grid',
          [classes.listItem]: type === 'list',
          [classes.switcherGrid]: type === 'switcherGrid',
        })}
        data-test="dt-button-app-icon"
        data-testid="dt-button-app-icon"
        onContextMenu={isAppGroup ? handleContextClick : undefined}
      >
        <div className={classes.image}>
          <img alt="app-icon" onError={handleErr} src={iconState} draggable="false" className={classes.img} />
        </div>
        <Typography
          variant="body2"
          className={classes.appName}
          data-testid="os-app-bar-panel.app-switcher.app-name"
        >
          {appName}
        </Typography>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{ transform: 'translate(60px, -60px)' }}
        >
          {/*hide app details menu item*/}
          <MenuItem
            sx={{ display: 'none' }}
            onClick={handleAppDetails}
            data-testid={`os-app-bar-panel.app-switcher.options-app-detail.${appGroupId}.${id}`}
          >
            <FormattedMessage
              id="os-app-bar-panel.app-group.application-details"
              defaultMessage="Application Details"
              description="Dropdown menu item that opens application details panel"
            />
          </MenuItem>
          <MenuItem
            onClick={ev => handleClick(ev as unknown as MouseEvent)}
            data-testid={`os-app-bar-panel.app-switcher.options-remove-app.${appGroupId}.${id}`}
          >
            <FormattedMessage
              id="os-app-bar-panel.app-group.remove-from-group"
              defaultMessage="Remove from Group"
              description="Dropdown menu item that removes application from group"
            />
          </MenuItem>
        </Menu>
        <Dialog
          open={confirmationVisible}
          maxWidth="xs"
          onClose={handleClose}
          data-testid="os-app-bar-panel.delete-app-from-group.confirmation-dialog"
        >
          <DialogTitle>
            <div className={classes.dialogTitle}>
              <FormattedMessage
                id="os-app-bar-panel.delete-app-from-group.confirmation-title"
                defaultMessage="Delete Application"
                description="Delete App from Group confirmation title"
              />
            </div>
            <DialogContent sx={styles['content']}>
              <DialogContentText>
                <FormattedMessage
                  id="os-app-bar-panel.delete-app-from-group.confirmation-message"
                  defaultMessage="Are you sure you want to delete this application from the group?"
                  description="Delete Ap from Group confirmation message"
                />
              </DialogContentText>
              <DialogActions sx={styles['actions']}>
                <Button
                  sx={styles['cancelButton']}
                  onClick={handleClose}
                  data-testid="app-switcher.delete-app.cancel-btn"
                >
                  <FormattedMessage
                    id="os-app-bar-panel.delete-app-from-group.cancel-button"
                    defaultMessage="Cancel"
                    description="Delete App from Group cancel button"
                  />
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={styles['deleteButton']}
                  onClick={removeApp}
                  data-testid="app-switcher.delete-app.confirm-btn"
                >
                  <FormattedMessage
                    id="os-app-bar-panel.delete-app-from-group.confirm-button"
                    defaultMessage="Yes, Delete"
                    description="Delete App from Group confirmation button"
                  />
                </Button>
              </DialogActions>
            </DialogContent>
          </DialogTitle>
        </Dialog>
      </div>
    </Tooltip>
  );
}

export default AppIcon;
