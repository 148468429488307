import { call, put, select } from 'redux-saga/effects';
import { actions } from '../../slices';
import * as api from '../../../api';
import { hidePanel, panelsSelector } from '@aiware/js/panel';
import { getUrlBuckets } from '../common/common.saga';
import { MessageSeverity, showMessage, preferredLanguageSelector } from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';

export function* closeFileMetadataPanel() {
  //@ts-ignore
  const panels = yield select(panelsSelector);

  const metadataPanel = panels.find((p: any) => p.microFrontend.name === 'EDIT_FILE_METADATA');

  if (metadataPanel && metadataPanel.panelId) {
    const { panelId } = metadataPanel;

    yield put(hidePanel(panelId));
  }
}

//@ts-ignore
export function* addMetadataToFile(action: ReturnType<typeof actions.filesSelectedState.addMetadataAction>) {
  //@ts-ignore
  const locale = yield select(preferredLanguageSelector);
  const { payload } = action;
  const { id, meta, contentTemplates } = payload;

  const formatMessage = AIWareFormatMessage(locale);

  try {
    const [bucket] = yield call(getUrlBuckets, 1);

    yield put(actions.uiState.fileMetaSaveIsProcessing());
    // Add metadata thumbnail image to S3 if the user provided it
    if (meta.thumbnailImg) {
      yield call(handleMetaFileS3Upload, meta.thumbnailImg, bucket);
    }
    meta.thumbnailUrl = bucket.unsignedUrl;

    if (meta.runTime && meta.runDate) {
      meta.startDateTime = new Date(`${meta.runDate} ${meta.runTime}`).toISOString();
    }

    // Persist to state
    yield put(
      actions.filesSelectedState.addMetadataToSelectedFile({
        id: id,
        meta: meta,
        contentTemplates: contentTemplates,
      })
    );

    yield put(actions.uiState.fileMetaSaveSucceeded());
  } catch (err) {
    yield put(
      showMessage({
        content: formatMessage({
          id: 'os-data-center-importer.snackbar.fileMetaError',
          defaultMessage: 'Something went wrong while saving your changes.',
          description: 'The error message pops up when file meta save errors.',
        }),
        severity: MessageSeverity.Error,
      })
    );
  }
}

function* handleMetaFileS3Upload(file: any, bucket: any) {
  const fileToUpload = {
    ...file,
    getUrl: bucket.getUrl,
    expiresInSeconds: bucket.expiresInSeconds,
    key: bucket.key,
    unsignedUrl: bucket.unsignedUrl,
    url: bucket.url,
  };

  yield api.uploadFileToS3(bucket, fileToUpload, {});
}
