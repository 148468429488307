import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './aiSearchFilterPanel.styles';
import { FileTypeFilter } from './components/FileTypeFilter/FileTypeFilter';
import { actions } from '../../state/aiSearchFilter/aiSearchFilter.slice';
import { actions as searchActions } from '../../state/aiSearch/aiSearch.slice';
import {
  selectDateRangeType,
  selectSearchFilters,
  selectSelectedFilterValues,
} from '../../state/aiSearchFilter/aiSearchFilter.selectors';
import { selectEngineCategoryEntities, selectEngineEntities } from '../../../../redux/selectors/entities';
import { DateRangeFilter } from './components/DateRangeFilter/DateRangeFilter';
import { AiSearchText } from '../../helpers/aiSearch.text';
import { SourceScheduleFilter } from './components/SourceScheduleFilter/SourceScheduleFilter';
import { getInitialDataSet } from './components/SourceScheduleFilter/helpers';
import { TFilterSourceScheduleDataSet } from '../../types/aiSearch.types';
import { ISchedule, ISource } from '../../../../types';
import { enginesFilter, IEngine, IEngineCategory } from '@aiware/shared/reusable-utils';

interface IAiSearchFilterPanel {
  id: string;
  isVisible: boolean;
}

export const AiSearchFilterPanel: FC<IAiSearchFilterPanel> = ({ id, isVisible }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  // Redux State for the Engine Filter Dropdown
  const dateRangeType = useSelector(selectDateRangeType);
  const searchFilters = useSelector(selectSearchFilters);
  const engineCategories = useSelector(selectEngineCategoryEntities);
  const selectedValues = useSelector(selectSelectedFilterValues);
  const categoryList = Object.keys(engineCategories).map((key, _index) => {
    return key;
  });

  const engines = useSelector(selectEngineEntities);
  const engineList = Object.keys(engines).map((key, _index) => {
    return key;
  });

  // Local State for the Source/Schedule Filter Dropdown
  const [parentDataSet, setParentDataSet] = useState<TFilterSourceScheduleDataSet>(getInitialDataSet());

  // This clears the values when we clear search - because we already have the search filters in redux -
  // when the form is reset these values are then empty so we can set the parent set back to initial state
  // without having to save the parentDataSet in redux also
  useEffect(() => {
    if (searchFilters.mediaSourceId?.values.length === 0 && searchFilters.programId?.values.length === 0) {
      setParentDataSet(getInitialDataSet());
    }
  }, [searchFilters.mediaSourceId?.values, searchFilters.programId?.values]);

  const handleSaveSourceSchedule = (dataset: TFilterSourceScheduleDataSet) => {
    setParentDataSet(dataset);
    const scheduleIds: string[] = Object.values(dataset.SCHEDULES)
      .reduce((prev, current) => {
        return [...prev, ...current];
      }, [])
      .map((schedule: ISchedule) => schedule.id);
    const sourceIds: string[] = Object.values(dataset.SOURCES).map((source: ISource) => source.id);

    dispatch({
      type: actions.setSearchFilters,
      payload: { filterType: 'programId', values: scheduleIds },
    });
    dispatch({
      type: actions.setSelectedFilterValues,
      payload: { filterType: 'programId', value: scheduleIds },
    });

    dispatch({
      type: actions.setSearchFilters,
      payload: { filterType: 'mediaSourceId', values: sourceIds },
    });
    dispatch({
      type: actions.setSelectedFilterValues,
      payload: { filterType: 'mediaSourceId', value: sourceIds },
    });
    dispatch(searchActions.resetSearchResults());
    dispatch(searchActions.filesSearchStart());
  };

  const handleClearSourceSchedule = () => {
    const dataset = getInitialDataSet();
    handleSaveSourceSchedule(dataset);
  };

  // @ts-ignore
  return isVisible ? (
    <div id={id} className={classes.container}>
      {/*Uncomment the component below in master once API/Redux is finished: */}
      <SourceScheduleFilter
        id={'ai-search-source-schedule-filter'}
        label={AiSearchText.filterLabelSourceSchedule()}
        parentDataSet={parentDataSet}
        onSaveParentDataSet={handleSaveSourceSchedule}
        onClear={handleClearSourceSchedule}
      />
      <enginesFilter.jsx.AiEngineFilter
        id="ai-search-engine-type-filter"
        label={AiSearchText.filterLabelEngineType()}
        // @ts-ignore
        selectedValue={searchFilters['engineType'].values}
        items={enginesFilter.helpers.engineListItems(
          categoryList,
          engineCategories as unknown as Record<string, IEngineCategory>,
          engineList,
          engines as unknown as Record<string, IEngine>
        )}
        onChange={(values: string[]) => {
          dispatch({
            type: actions.setSearchFilters,
            payload: { filterType: 'engineType', values: values },
          });

          dispatch(searchActions.resetSearchResults());
          dispatch(searchActions.filesSearchStart());
        }}
        engineList={engines as unknown as Record<string, IEngine>}
      />
      <DateRangeFilter
        id="ai-search-date-range-filter"
        dateRangeType={dateRangeType}
        label={AiSearchText.filterLabelDate()}
        onChange={(
          filterObject: { field: string; gte: string; lte: string; operator: string },
          _value: string
        ) => {
          const { field, gte, lte, operator } = filterObject;
          dispatch({
            type: actions.setDateRangeType,
            payload: _value,
          });
          dispatch({
            type: actions.setSearchFilters,
            payload: { filterType: field, values: [gte, lte, operator] },
          });

          dispatch(searchActions.resetSearchResults());
          dispatch(searchActions.filesSearchStart());
        }}
      />
      <FileTypeFilter
        id="ai-search-file-type-filter"
        allowMultiple={true}
        label={AiSearchText.filterLabelFileType()}
        selectedValue={selectedValues['fileType']?.values || []}
        items={[
          { value: 'audio', label: 'Audio' },
          { value: 'video', label: 'Video' },
          { value: 'image', label: 'Image' },
          { value: 'text', label: 'Text' },
          { value: 'other', label: 'Other' },
        ]}
        onChange={(values: string) => {
          dispatch({
            type: actions.setFileTypeFilter,
            payload: values,
          });

          dispatch({
            type: actions.setSelectedFilterValues,
            payload: { filterType: 'fileType', value: values },
          });
          dispatch(searchActions.resetSearchResults());
          dispatch(searchActions.filesSearchStart());
        }}
      />
    </div>
  ) : null;
};
