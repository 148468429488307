import { selectApiConfigs, baseGraphQLApi, gqlIntrospection } from '@aiware/shared/redux';
import { EJSONSchemaStringFormat, ISchemaProperties } from '../../types/aiStructuredData.types';

interface IGetSchemaPropertiesParams {
  apiConfigs: ReturnType<typeof selectApiConfigs>;
  limit: number;
  offset: number;
  additionalSelectors?: { [key: string]: unknown };
}

export async function getSchemaProperties({
  apiConfigs,
  offset,
  limit,
  additionalSelectors,
}: IGetSchemaPropertiesParams): Promise<ISchemaProperties[]> {
  const inputParams: { [key: string]: any }[] = [
    { name: 'limit', type: 'Int', value: limit },
    { name: 'offset', type: 'Int', value: offset },
  ];

  if (additionalSelectors) {
    Object.keys(additionalSelectors).forEach(name => {
      const value = additionalSelectors[name];
      switch (name) {
        case 'dataRegistryVersion': {
          if (value) {
            inputParams.push({ name: 'dataRegistryVersion', type: '[DataRegistryVersion!]', value: [value] });
          }
          break;
        }

        case 'searchValue': {
          inputParams.push({ name: 'search', type: 'String', value });
          break;
        }
        default:
          break;
      }
    });
  }

  const queryInput = inputParams.map(param => `$${param['name']}: ${param['type']}`).join(' ');

  const operationInput = inputParams.map(param => `${param['name']}: $${param['name']}`).join(' ');
  const variables = inputParams.reduce((acc, param) => {
    acc[param['name']] = param['value'];
    return acc;
  }, {});
  const operationName = 'getSchemaProperties';
  const unsafeQuery = `query ${operationName} (${queryInput}) {
      schemaProperties(${operationInput}) {
        records {
          type
          path
          searchPath
          title
          schema {
            id
            definition
            dataRegistry {
              name
              organization {
                name
              }
            }
            majorVersion
          }
        }
      }
    }`;
  const { token, graphQLEndpoint } = apiConfigs;

  const processedQuery = await gqlIntrospection.queryParser(graphQLEndpoint, token, unsafeQuery);
  const query = processedQuery?.query || unsafeQuery;
  processedQuery?.unsupportedArgs?.forEach((unsupportedArg: string) => {
    delete variables[unsupportedArg];
  });
  try {
    const result = await baseGraphQLApi<{
      schemaProperties: {
        records: ISchemaProperties[];
      };
    }>({
      query,
      variables,
      operationName,
      graphEndpoint: graphQLEndpoint,
      token,
    });

    const records = result.data.schemaProperties.records as ISchemaProperties[];

    const revisedPayload: ISchemaProperties[] = records.map((item: ISchemaProperties) => {
      if (item) {
        item.propertyDefinition = {
          ...getDefaultType(item?.type),
        };
      }

      if (
        item &&
        item.schema.definition &&
        item.schema.definition.properties &&
        item.schema.definition.properties[item.path]
      ) {
        item.propertyDefinition = item.schema.definition.properties[item.path];
        item.propertyDefinition = {
          ...item.propertyDefinition,
          ...getDefaultType(item.schema.definition.properties[item.path]?.type || item.type),
        };

        // String Formats must conform to JSONSchema V6
        // This checks if an invalid string format is passed
        if (item.propertyDefinition?.format) {
          if (!Object.values(EJSONSchemaStringFormat).includes(item.propertyDefinition.format)) {
            delete item.propertyDefinition.format;
          }
        }
      }
      return item;
    });

    return revisedPayload;
  } catch (error) {
    // Handle the error or perform additional actions
    console.error('An error occurred:', error);
    throw error; // Re-throw the error to propagate it further if needed
  }
}

const getDefaultType = (propType: string) => {
  switch (propType.toLowerCase()) {
    case 'string': {
      return { type: 'string' };
    }
    case 'date-time':
    case 'datetime': {
      return {
        type: 'string',
        format: 'date-time',
      };
    }
    case 'date': {
      return {
        type: 'string',
        format: 'date',
      };
    }
    case 'time': {
      return {
        type: 'string',
        format: 'time',
      };
    }
    case 'number': {
      return {
        type: 'number',
      };
    }
    case 'integer': {
      return {
        type: 'integer',
      };
    }
    case 'boolean': {
      return {
        type: 'boolean',
      };
    }
    case 'object': {
      return {
        type: 'object',
      };
    }
    case 'array': {
      return {
        type: 'array',
      };
    }
    default: {
      return { type: propType };
    }
  }
};
