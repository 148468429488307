import { call, put, select } from 'redux-saga/effects';
import { selectApiConfigs } from '../aiAutocomplete/aiAutocomplete.selectors';
import { selectRootFolderId, selectMyFilesBreadCrumbs } from '../../../../redux/selectors/view-my-files';
import { actions } from './aiSearch.slice';
import { Breadcrumb, IFolder, ISource } from '../../../../types';
import type { TDataRegistry } from '@aiware/os/data-center/sdo';
import { getFiles } from '../../api/queries/getFiles.query';
import { getSourcesBySearchTerm } from '../../api/queries/getSourceSearch.query';
import { getDataRegistriesBySearchTerm } from '../../api/queries/getDataRegistries.query';
import { getFieldByCapabilityType } from '../../helpers/aiSearch.helpers';
import { ECapabilityTypes } from '../../types/aiAutocomplete.types';
import { selectFolderEntities } from '../../../../redux/selectors/entities';
import {
  selectSearchActiveCriteria,
  selectSearchOffset,
  selectSelectedCriteria,
  selectUniqueTdos,
} from './aiSearch.selectors';
import { selectSearchFilters } from '../aiSearchFilter/aiSearchFilter.selectors';
import { ICriterion } from '../../types/aiAutocomplete.types';
import { AiSearchFilterType } from '../aiSearchFilter/aiSearchFilter.slice';
import { selectStructuredDataInput } from '../aiStructuredData/aiStructuredData.selectors';
import { IStructuredDataInput } from '../../types/aiStructuredData.types';

export function* searchFilesSaga() {
  try {
    const defaultLimit = 100;
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const structuredData: IStructuredDataInput = yield select(selectStructuredDataInput);
    const folderEntities: IFolder = yield select(selectFolderEntities);
    const crumbs: Breadcrumb[] = yield select(selectMyFilesBreadCrumbs);
    const searchFolder = [...crumbs].pop()?.id || 'myFilesRoot';
    const isRootFolder = searchFolder === 'myFilesRoot';
    const rootFolderId: string = yield select(selectRootFolderId);
    const currentOffset: number = yield select(selectSearchOffset);
    const selectedCriteria: ICriterion[] = yield select(selectSelectedCriteria);
    let currentCriteria = selectedCriteria[0];
    if (currentOffset > 0) {
      currentCriteria = yield select(selectSearchActiveCriteria);
      if (currentCriteria) {
        currentCriteria = (currentCriteria as any)[0];
      }
    }

    if (!currentCriteria) {
      throw new Error('Must have a valid criteria');
    }

    // just test code
    const filters: AiSearchFilterType = yield select(selectSearchFilters);

    const currentUniqueTdos: string[] = yield select(selectUniqueTdos);
    const field = getFieldByCapabilityType(currentCriteria.capabilityType);
    const folderId = isRootFolder
      ? (folderEntities as any)[rootFolderId].treeObjectId
      : (folderEntities as any)[searchFolder].treeObjectId;
    // For phase 1 we are only searching with a single criteria.
    // For phase 2 we will add support for multiple criteria and will need to loop over them.
    const input = {
      endpoint: apiConfigs.apiRoot,
      token: apiConfigs.token,
      entityId: currentCriteria.entityId,
      text: currentCriteria.label,
      entity: field,
      searchFolder: folderId,
      isRootFolder,
      graphQLEndpoint: apiConfigs.graphQLEndpoint,
      capabilityType: currentCriteria?.capabilityType,
      offset: currentOffset,
      uniqueTdos: currentUniqueTdos,
      filters: filters,
      structuredData: structuredData,
    };

    // switch on capabilityType for calling search results
    switch (currentCriteria?.capabilityType) {
      case ECapabilityTypes.dataRegistry: {
        const dataRegistries: [TDataRegistry] = yield call(
          getDataRegistriesBySearchTerm,
          apiConfigs,
          currentOffset,
          defaultLimit,
          currentCriteria?.label ?? ''
        );

        const data = {
          files: dataRegistries,
          capability: currentCriteria?.capabilityType,
        };

        yield put(actions.filesSearchSucceeded({ data }));
        yield put(actions.setUniqueTdos([]));
        yield put(actions.setOffset(currentOffset + defaultLimit));
        yield put(actions.setHasMore(dataRegistries.length === Number(defaultLimit)));
        yield put(actions.setTotalResults(-1));
        yield put(
          actions.setLastColumn({
            enabled: true,
            value: 'Version',
          })
        );

        break;
      }
      case ECapabilityTypes.source: {
        const sources: [ISource] = yield call(
          getSourcesBySearchTerm,
          apiConfigs,
          currentOffset,
          defaultLimit,
          currentCriteria?.label ?? '',
          null
        );

        const data = {
          files: sources,
          capability: currentCriteria?.capabilityType,
        };

        yield put(actions.filesSearchSucceeded({ data }));
        yield put(actions.setUniqueTdos([]));
        yield put(actions.setOffset(currentOffset + defaultLimit));
        yield put(actions.setHasMore(sources.length === Number(defaultLimit)));
        yield put(actions.setTotalResults(-1));
        yield put(
          actions.setLastColumn({
            enabled: true,
            value: 'Type',
          })
        );

        break;
      }
      default: {
        // @ts-ignore
        const { foundFiles, hasMore, uniqueTdos, totalResults } = yield call(getFiles, input);

        const data = {
          files: foundFiles,
          capability: currentCriteria?.capabilityType,
        };

        yield put(actions.filesSearchSucceeded({ data }));
        yield put(actions.setUniqueTdos(uniqueTdos));
        yield put(actions.setOffset(currentOffset + 100));
        yield put(actions.setTotalResults(totalResults));
        yield put(actions.setHasMore(hasMore));
      }
    }
  } catch (err) {
    yield put(actions.filesSearchFailed(err as Error));
  }
}
