import { CSSProperties } from 'react';
import { Stack, StackProps } from '@mui/material';

export type TCenterTabContainerProps = {
  testId: string;
  height?: string;
  children?: React.ReactNode;
  sx?: CSSProperties;
  StackProps?: StackProps;
};

export const CenterTabContainer: React.FC<TCenterTabContainerProps> = ({
  children,
  testId,
  height,
  StackProps = {},
  sx = {},
}) => {
  const styles = {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    height: height || '100%',
  };

  return (
    <Stack
      component={'section'}
      sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]}
      {...StackProps}
      data-testid={`${testId}-center-tab-container`}
    >
      {children}
    </Stack>
  );
};
