import { all } from 'redux-saga/effects';
import {
  getOrganizationsWatcher,
  getCurrentOrgWatcher,
  fetchInviteeWatcher,
  switchToOrgWatcher,
  fetchUserOrganizaitonInvitesWatcher,
  setUserDefaultOrgWatcher,
} from './organizations';
import {
  createOrganizationInvitesWatcher,
  reRequestOrganizationInvitesWatcher,
  updateOrganizationInvitesWatcher,
  pendingOrganizationInvitesWatcher,
  updatePendingInviteStatusWatcher,
} from './organizationInvites';
import { fetchScimIdsWatcher } from './scimIds';
import { updateOrganizationInviteWatcher } from './ReviewRequest';

export function* rootSaga() {
  yield all([
    getOrganizationsWatcher(),
    getCurrentOrgWatcher(),
    fetchInviteeWatcher(),
    switchToOrgWatcher(),
    createOrganizationInvitesWatcher(),
    updateOrganizationInvitesWatcher(),
    reRequestOrganizationInvitesWatcher(),
    fetchUserOrganizaitonInvitesWatcher(),
    updateOrganizationInviteWatcher(),
    setUserDefaultOrgWatcher(),
    pendingOrganizationInvitesWatcher(),
    fetchScimIdsWatcher(),
    updatePendingInviteStatusWatcher(),
  ]);
}
