import { put, select } from 'redux-saga/effects';
import { scimIdsApi } from '../../../api';
import { selectApiConfigs, selectUserId } from '../../selectors';
import { actions } from '../../slices';

export function* fetchScimIdsSaga(action: ReturnType<typeof actions.scimIdsState.scimIdsFetchStart>) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const userId: ReturnType<typeof selectUserId> = yield select(selectUserId);
    const scimIds: string[] = yield scimIdsApi.fetchScimIds(apiConfigs, userId);
    yield put(actions.scimIdsState.scimIdsFetchSucceed(scimIds));
  } catch (e) {
    yield put(actions.scimIdsState.scimIdsFetchFailed());
    console.log(e);
  }
}
