import { Paper, CardContent, Stack, CardActionArea, Typography, Icon, Chip, Tooltip } from '@mui/material';
import { SyncAlt } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { userSelector } from '@aiware/shared/redux';
import { OrgAvatar } from './org-avatar';

export const OrgCard = ({ org, onClick = () => {} }) => {
  const orgName = org?.name ?? org?.organizationName ?? 'Unknown Organization';
  const user = useSelector(userSelector);
  const organization = user?.organization;
  const isActive = organization?.guid === org.guid;

  return (
    <Tooltip
      key={org.guid}
      title={isActive ? 'This is your current Organization' : 'Switch To Organization'}
      placement="top"
      enterDelay={0}
      enterNextDelay={0}
      PopperProps={{
        disablePortal: true,
      }}
    >
      <Paper
        sx={{
          backgroundColor: isActive ? 'rgba(46,125,50,0.05)' : '',
          transition: 'background-color 0.2s ease',
          '&:hover': {
            backgroundColor: isActive ? 'rgba(46,125,50,0.05)' : 'rgba(12,83,176,0.05)',
          },
          width: '180px',
        }}
        className="org-card"
      >
        <CardActionArea
          onClick={isActive ? undefined : onClick}
          sx={{
            height: '160px',
            position: 'relative',
            pointerEvents: isActive ? 'none' : 'auto',
          }}
        >
          <CardContent>
            <OrgAvatar
              org={org}
              sx={{
                mx: 'auto',
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                height: '48px',
              }}
            >
              {orgName}
            </Typography>
            {isActive && (
              <Stack sx={{ position: 'absolute', top: '10px', left: '10px' }}>
                <Chip color={'success'} label={'Active'} sx={{ mt: 1, opacity: isActive ? 1 : 0 }} />
              </Stack>
            )}
            <Icon
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                opacity: 0.3,
                m: '.5rem .5rem 0 0',
              }}
            >
              <SyncAlt />
            </Icon>
          </CardContent>
        </CardActionArea>
      </Paper>
    </Tooltip>
  );
};
