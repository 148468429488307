import { FunctionComponent } from 'react';
import { useStyles } from '../../useStyles';
import SourcesTableFluffyRow from './SourcesTableFluffyRow';
import { ISource, InfiniteWrapperItemType } from '../../../../types';
import SourcesTableRow from './SourcesTableRow';
import Divider from '@mui/material/Divider';

const SourcesRows: FunctionComponent<{
  index: number;
  items: InfiniteWrapperItemType;
  rowHeight: number;
  isFluffy: boolean;
}> = ({ index, items, rowHeight, isFluffy }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.row}>
      {isFluffy ? (
        <SourcesTableFluffyRow item={(items as ISource[])[index]!} rowHeight={rowHeight} />
      ) : (
        <SourcesTableRow item={(items as ISource[])[index]!} rowHeight={rowHeight} />
      )}
      <Divider className={classes.divider} />
    </div>
  );
};

export default SourcesRows;
