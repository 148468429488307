import { generateState, GeneratedState } from '@aiware/shared/reusable-utils';
import * as api from '../lib/api';
import { IAuditLog, SLICE_NAME } from '../types/auditLog.types';

export const namespace = 'auditLog';

export const { slice, selectors, sagas, initialState } = generateState<IAuditLog>({
  sliceName: SLICE_NAME,
  namespace,
  dedupeResponse: true,
  apiCalls: {
    read: api.queries.getAuditLogQuery,
    pageSize: 50,
  },
});

export type State = GeneratedState<IAuditLog>;
