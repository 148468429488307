import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  controlsContainer: {
    padding: '0 30px 25px 30px',
    display: 'flex',
    justifyContent: 'end',
    '& button': {
      height: '36px',
    },
  },
  backBtn: {
    color: 'black',
    marginRight: '30px',
  },
}));
