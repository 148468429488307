import { SLICE_NAME, TApplicationConfig } from '../../types';
import { namespace, IApplicationConfigState } from '../slices/application-config.state';

interface IState {
  [SLICE_NAME]: {
    [namespace]: IApplicationConfigState;
  };
}

export const selectApplicationConfig = (state: IState): TApplicationConfig[] =>
  state?.[SLICE_NAME]?.[namespace]?.applicationConfig;
