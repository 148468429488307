import { FC, useState, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { selectUIStateStatus } from '../../redux/selectors/ui-state';
import Header from './Header';
import MainTabPanel from './MainTabPanel';
import MainSkeleton from './SkeletonLoader';
import AddNewFolder from '../action-panels/AddNewFolder';
import EditFolder from '../action-panels/EditFolder';
import TableDataSettings from '../action-panels/TableDataSettings';
import { panelsSelector } from '@aiware/js/panel';
import { IPanel } from '@aiware/js/interfaces';
import { Stack, Typography } from '@mui/material';
import { FullscreenPortal } from '@aiware/shared/reusable-utils';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

// TODO: create error component

const appBarHeight = 55;
const headerHeight = 64;
const borderBottom = 5;

interface Panel {
  panelConfig: any;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: 1020,
    position: 'relative',
  },
  panel: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overlay: {
    backgroundColor: theme.palette.grey[50],
    opacity: `0.5 !important`,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

const actionPanels = [AddNewFolder, EditFolder, TableDataSettings];

const MainLayout: FC<{
  hidePanel?: () => void;
  dataId: string;
}> = ({ hidePanel, dataId, ...rest }) => {
  const { classes } = useStyles();
  const [navigationPanelExpanded, setNavigationPanelExpanded] = useState(true);
  const uiStateStatus = useSelector(selectUIStateStatus);
  const fullScreen = useSelector(selectIsFullscreenEnabled);
  const dispatch = useDispatch();
  const panels = (useSelector(panelsSelector) || []) as IPanel<unknown, unknown>[];

  const DataCenterPanel: Panel = panels.find(item => {
    return item.panelId === 'DATA_CENTER';
  }) || { panelConfig: { dimmed: 0 } };

  const dimmed = DataCenterPanel?.panelConfig?.dimmed > 0;

  const handleLeftNavigationToggle = () => {
    setNavigationPanelExpanded(!navigationPanelExpanded);
  };

  const handlePanelClose = () => {
    hidePanel && hidePanel();
  };

  const mainHeight = `calc(100vh - ${fullScreen ? 0 : appBarHeight + borderBottom}px - ${headerHeight}px)`;

  return (
    <Container dimmed={dimmed} classes={classes}>
      <Header
        height={headerHeight}
        isNavExpanded={navigationPanelExpanded}
        onLeftNavigationToggle={handleLeftNavigationToggle}
        onPanelClose={handlePanelClose}
      />
      <>
        {uiStateStatus === 'idle' && (
          <MainTabPanel height={mainHeight} isNavExpanded={navigationPanelExpanded} />
        )}

        {uiStateStatus === 'pending' && <MainSkeleton height={mainHeight} />}

        {uiStateStatus === 'failure' && (
          <Stack sx={{ display: 'grid', placeItems: 'center', height: '500px' }}>
            <Stack sx={{ display: 'grid', placeItems: 'center' }}>
              <Typography sx={{ fontWeight: 500, fontFamily: 'Nunito' }}>Something went wrong</Typography>
              <Typography sx={{ fontFamily: 'Nunito' }} variant="caption">
                Please try again later.
              </Typography>
            </Stack>
          </Stack>
        )}

        {actionPanels.map((Panel, idx) => (
          <Panel key={idx} className={classes.panel} />
        ))}
      </>
    </Container>
  );
};

const Container: FC<
  PropsWithChildren<{
    classes: Record<'fullScreen' | 'root' | 'panel' | 'overlay', string>;
    dimmed: boolean;
  }>
> = ({ classes, dimmed, children }) => {
  const fullScreen = useSelector(selectIsFullscreenEnabled);
  return fullScreen ? (
    <FullscreenPortal dimmed={dimmed} dataTest="dc-widget" panelId="DATA_CENTER">
      {children}
    </FullscreenPortal>
  ) : (
    <div role="presentation" data-test="dc-widget" className={classNames(classes.root, classes.panel)}>
      {children}
    </div>
  );
};

export default MainLayout;
