import { INotificationDetail, NotificationFlag } from '@aiware/js/interfaces';

export enum INotificationType {
  EMAIL = 'Email',
  NOTIFICATION_CENTER = 'Notification Center',
  BANNER = 'Banners',
}

//utility notification setting
export interface IUtilityAction {
  id: string;
  title: string;
  allow: boolean;
  allowType: INotificationType[];
}

export interface IUtilityNotificationSetting {
  id: string;
  type: 'finder' | 'importer_tool' | 'engine_toolkit';
  title: string;
  iconUrl: string;
  allow: boolean;
  allowType: INotificationType[];
  action: IUtilityAction[];
}

// application notification setting
export interface IApplicationAction {
  id: string;
  title: string;
  allowType: INotificationType[];
  allow: boolean;
}

export interface ICustomApplicationAction {
  id: string;
  allow: boolean;
  title: string;
  body: string; // string, json, html
  contentType: string; // Hint what/how the body is encoded
}

export interface IApplicationNotificationSetting {
  id: string;
  name: string;
  iconUrl: string;
  description: string;
  allow: boolean;
  allowType: INotificationType[];
  action: IApplicationAction[];
  customAction?: ICustomApplicationAction[];
}

export interface IAppNotificationSetting {
  [id: string]: {
    name: string;
    iconUrl: string;
    description: string;
    allow: boolean;
    allowType: INotificationType[];
    action: IGeneralInterface<IAppNotificationAction>;
    customAction?: IGeneralInterface<IAppNotificationCustomAction>;
  };
}

export interface IAppNotificationAction {
  [id: string]: {
    title: string;
    allowType: INotificationType[];
    allow: boolean;
  };
}

export interface IAppNotificationCustomAction {
  [id: string]: {
    title: string;
    allowType: INotificationType[];
    allow: boolean;
  };
}

export interface IGeneralInterface<T> {
  byId: T;
  allIds: string[];
}

// setting module
export interface ISettingModule {
  allow: boolean;
  allowType: INotificationType[];
  enableUtilityNotification: boolean;
  enableApplicationNotification: boolean;
  bannerStyle: string;
  utility: IUtilityNotificationSetting[];
  // application: IApplicationNotificationSetting[];
  application: IGeneralInterface<IAppNotificationSetting>;
}
// notification module interface
export interface INotificationModule {
  status: 'idle' | 'loading' | 'success' | 'failure';
  filter: 'all' | 'today' | 'this week' | 'this month' | 'this year';
  notifications: INotificationDetail[];
  failureMessage: string;
  availableMailBoxes: number;
  paused: boolean;
  settings: ISettingModule;
  limit: number;
  offset: number;
}

export interface INotificationAction {
  actionName: string;
  applicationId: string;
  eventName: string;
  eventType: string;
  icon: string;
  urlTemplate: string;
}
export interface INotificationFlag {
  data: {
    setNotificationFlag: {
      id: string;
      body: string;
      flags: NotificationFlag[];
    };
  };
  error?: any;
}
