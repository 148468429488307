export const jsonTableStyles = {
  button: {
    '&.Sdk-Mui-disabled': {
      backgroundColor: 'transparent!important',
    },
  },
  chip: {
    color: 'inherit',
    backgroundColor: 'rgb(0 0 0 / 4%)',
    '& svg': {
      opacity: 0.5,
    },
    '&:hover svg': {
      opacity: 1,
    },
  },
  paper: {
    overflow: 'hidden',
    boxShadow: 'none!important',
    border: '1px solid #f2f2f2',
  },
  tableStyles: {
    container: (isScrolled: boolean) => ({
      '&:hover': {
        overflow: 'auto',
      },
      '&::-webkit-scrollbar': {
        width: '8px !important',
        height: '8px !important',
      },
      '& .Sdk-MuiTableHead-root': {
        borderBottom: '1px solid #D5DFE9',
        position: 'sticky',
        top: '0',
        background: '#fff',
        boxShadow: isScrolled ? '0px -5px 20px rgb(0 0 0 / 45%)' : '0px -5px 20px transparent',
        transition: 'all 0.35s ease-in-out',
      },
      '& tr .Sdk-MuiChip-icon': {
        color: 'inherit',
      },
    }),
    body: {
      '& tr:nth-child(even)': {
        backgroundColor: '#F8F8F8',
      },
    },
    row: {
      '&:last-child td, &:last-child th': { border: 0 },
      '&:hover td': {
        color: '#0a0a0a!important',
      },
      '& td': {
        color: '#404040',
        transition: 'color 0.2s ease-in-out',
      },
    },
    cell: { border: '1px solid rgb(0 0 0 / 5%)' },
  },
};
