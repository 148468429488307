import { Stack, Tooltip, Typography } from '@mui/material';
import { useStyles } from './PropertyDropdown.styles';
import { useSelector } from 'react-redux';
import {
  EJSONSchemaStringFormat,
  EValidJSONSchemaType,
  ISchemaProperties,
  SupportedJSONSchemaTypes,
} from '../../../../../types/aiStructuredData.types';
import { selectSelectedProperty } from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';

interface IPropertyDropdownRowProps {
  item: ISchemaProperties;
  name: string;
  handleClickAddProperty: (item: ISchemaProperties) => void;
  path: string;
  orgName: string;
}

const TypeNames = {
  string: (
    <FormattedMessage
      id="os-dc-sds-property-type.text"
      defaultMessage="Text"
      description="Data Center structured data property type: text"
    />
  ),
  date: (
    <FormattedMessage
      id="os-dc-sds-property-type.date"
      defaultMessage="Date"
      description="Data Center structured data property type: date"
    />
  ),
  datetime: (
    <FormattedMessage
      id="os-dc-sds-property-type.date"
      defaultMessage="Date"
      description="Data Center structured data property type: date"
    />
  ),
  boolean: (
    <FormattedMessage
      id="os-dc-sds-property-type.t-f"
      defaultMessage="True or False"
      description="Data Center structured data property type: true or false"
    />
  ),
  integer: (
    <FormattedMessage
      id="os-dc-sds-property-type.number"
      defaultMessage="Number"
      description="Data Center structured data property type: number"
    />
  ),
  number: (
    <FormattedMessage
      id="os-dc-sds-property-type.number"
      defaultMessage="Number"
      description="Data Center structured data property type: number"
    />
  ),
  geoPoint: (
    <FormattedMessage
      id="os-dc-sds-property-type.Location"
      defaultMessage="Location"
      description="Data Center structured data property type: Location"
    />
  ),
  array: (
    <FormattedMessage
      id="os-dc-sds-property-type.Collection"
      defaultMessage="Collection"
      description="Data Center structured data property type: Collection"
    />
  ),
};

const getTypeName = (propertyDefinition: { type: string; format?: string }) => {
  if (propertyDefinition.type === 'string' && propertyDefinition?.format) {
    if (
      Object.values(EJSONSchemaStringFormat).includes(
        propertyDefinition.format as EJSONSchemaStringFormat.date
      )
    ) {
      return propertyDefinition.format.replace('-', ' ');
    }
  } else {
    return (TypeNames as any)[propertyDefinition.type.toLowerCase()];
  }
};

const isSupportedByUI = (propType: EValidJSONSchemaType) => {
  return SupportedJSONSchemaTypes.includes(propType);
};

export const PropertyDropdownRow: React.FC<IPropertyDropdownRowProps> = props => {
  const { classes } = useStyles();
  const selectedItem = useSelector(selectSelectedProperty);
  const isDisabled = !isSupportedByUI(props.item.propertyDefinition?.type);

  return (
    <Tooltip
      disableInteractive
      open={isDisabled ? undefined : false}
      title={
        <>
          <FormattedMessage
            id="dc-sds-property-dropdown.type-unsupported-tooltip"
            defaultMessage="This property type is not supported: "
            description="tooltip: unsupported property"
          />{' '}
          {props.item.propertyDefinition?.type}
        </>
      }
      placement="top-start"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -15],
            },
          },
        ],
      }}
    >
      <Stack
        sx={{
          borderBottom: '1px solid #f7f7f7',
          opacity: isDisabled ? 0.5 : undefined,
          '&:hover': {
            borderRadius: isDisabled ? undefined : '6px',
            backgroundColor: isDisabled ? undefined : 'rgba(228, 240, 252, 0.5)',
          },
          cursor: isDisabled ? 'auto' : 'pointer',
        }}
        data-testid={`structured-data-property-select-item-${props.path}`}
        className={
          props.item?.schema?.id === selectedItem?.schema?.id && props.item?.path === selectedItem?.path
            ? `${classes.selected} ${classes.property}`
            : classes.property
        }
        width="100%"
        direction="column"
        onClick={isDisabled ? undefined : () => props.handleClickAddProperty(props.item)}
      >
        <Typography
          sx={{
            marginBottom: '5px',
            pointerEvents: 'none',
          }}
          variant="h5"
          fontWeight="bold"
          data-test={`dc-browse-structured-data-properties-path-${props.path}`}
          noWrap
        >
          {props.path}{' '}
          {!isDisabled && (
            <Typography
              component="span"
              sx={{
                fontSize: '10px',
                marginLeft: '7px',
                textTransform: 'capitalize',
                backgroundColor: '#f2f7fe',
                borderRadius: '8px',
                padding: '2px 8px',
                fontWeight: 'bold',
                color: '#0b53b0',
                border: '1px solid rgb(11 83 176 / 20%)',
              }}
            >
              {getTypeName(props.item.propertyDefinition)}
            </Typography>
          )}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="caption"
            data-test={`dc-browse-structured-data-properties-name-${props.path}`}
            noWrap
          >
            {props.name}
          </Typography>
          <Typography
            variant="caption"
            data-test={`dc-browse-structured-data-properties-org-${props.path}`}
            noWrap
          >
            {props.orgName}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
};
