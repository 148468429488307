import createSvgIcon from '../createSvgIcon';
export const Organizations = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5002 13.75H14.6668V15.5833H16.5002V13.75ZM16.5002 10.0833H14.6668V11.9167H16.5002V10.0833ZM18.3335 17.4167H11.0002V15.5833H12.8335V13.75H11.0002V11.9167H12.8335V10.0833H11.0002V8.25H18.3335V17.4167ZM9.16683 6.41667H7.3335V4.58333H9.16683V6.41667ZM9.16683 10.0833H7.3335V8.25H9.16683V10.0833ZM9.16683 13.75H7.3335V11.9167H9.16683V13.75ZM9.16683 17.4167H7.3335V15.5833H9.16683V17.4167ZM5.50016 6.41667H3.66683V4.58333H5.50016V6.41667ZM5.50016 10.0833H3.66683V8.25H5.50016V10.0833ZM5.50016 13.75H3.66683V11.9167H5.50016V13.75ZM5.50016 17.4167H3.66683V15.5833H5.50016V17.4167ZM11.0002 6.41667V2.75H1.8335V19.25H20.1668V6.41667H11.0002Z"
      fill="#555F7C"
    />
  </svg>,
  'Organizations'
);
