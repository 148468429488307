import { takeEvery } from 'redux-saga/effects';
import {
  createUserGroupSaga,
  fetchOrganizationUserGroupsSaga,
  updateUserGroupsSaga,
  addMembersToUserGroupsSaga,
  removeMembersFromUserGroupsSaga,
  deleteUserGroupsSaga,
} from './admin-center-groups.saga';
import { actions } from '../slices';

export function* createUserGroupWatcher() {
  yield takeEvery(actions.adminCenterGroups.createUserGroupStart.type, createUserGroupSaga);
}

export function* getOrgUserGroupsWatcher() {
  yield takeEvery(actions.adminCenterGroups.fetchUserGroupsStart.type, fetchOrganizationUserGroupsSaga);
}

export function* updateUserGroupsWatcher() {
  yield takeEvery(actions.adminCenterGroups.updateUserGroupsStart.type, updateUserGroupsSaga);
}

export function* addMembersToUserGroupsWatcher() {
  yield takeEvery(actions.adminCenterGroups.addMembersToUserGroupStart.type, addMembersToUserGroupsSaga);
}

export function* removeMembersFromUserGroupsWatcher() {
  yield takeEvery(
    actions.adminCenterGroups.removeMembersFromUserGroupStart.type,
    removeMembersFromUserGroupsSaga
  );
}

export function* deleteUserGroupsWatcher() {
  yield takeEvery(actions.adminCenterGroups.deleteUserGroupStart.type, deleteUserGroupsSaga);
}
