import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ITdoGraphQL, ITdoVm } from '../../../types';
import Grid from '@mui/material/Grid';
import IconMimeHelper from '../IconMimeHelper';

import { useStyles } from '../../components/useStyles';
import { useSelector } from 'react-redux';
import { selectTableDataSettings } from '../../../redux/selectors/ui-state';

type RowItemTypes = ITdoVm | ITdoGraphQL;

const FileType: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const { classes } = useStyles();
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showFileType = columns.indexOf('fileType') >= 0;

  return (
    <>
      {showFileType && (
        <Grid data-test={`item-fileType`} className={classes.type} item xs={xs}>
          <IconMimeHelper
            className={classes.sourceIcon}
            value={(item as ITdoVm).fileType || (item as ITdoGraphQL)?.primaryAsset?.contentType}
          />
        </Grid>
      )}
    </>
  );
};

export default FileType;
