import { selectApiConfigs } from '../../../../redux/selectors';
import { TGraphQLPage } from '@aiware/js/interfaces';
import type { TDataRegistry } from '@aiware/os/data-center/sdo';
import { baseGraphQLApiWithError } from '@aiware/shared/redux';

export enum ESchemaOwnership {
  All = 'all',
  Mine = 'mine',
  Others = 'others',
}

export async function getDataRegistriesBySearchTerm(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset: number,
  limit: number,
  searchTerm: string,
  ids?: null,
  filterByOwnership: ESchemaOwnership = ESchemaOwnership.Mine
): Promise<TDataRegistry[]> {
  const operationName = 'getDataRegistries';

  const query = `
      query ${operationName}($ids: [ID!], $offset: Int, $name: String, $limit: Int, $filterByOwnership: SchemaOwnership){
        dataRegistries(
          ids: $ids,
          offset: $offset,
          limit: $limit,
          name: $name,
          filterByOwnership: $filterByOwnership,
          nameMatch: contains
          ) {
          count
          offset
          limit
          records {
            id
            name
            organizationId
            description
            source
            schemas {
              records {
                id
                definition
                majorVersion
                minorVersion
                status
                validActions
                organizationId
              }
            }
            publishedSchema {
              id
              definition
              majorVersion
              minorVersion
              status
              validActions
              organizationId
            }
            createdDateTime
            modifiedDateTime
          }
        }
      }
    `;

  const variables = {
    ids: ids,
    name: searchTerm,
    offset: offset,
    limit: limit,
    filterByOwnership: filterByOwnership,
  };

  const result = await baseGraphQLApiWithError<{
    dataRegistries?: TGraphQLPage<TDataRegistry>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result.dataRegistries?.records ?? [];
}
