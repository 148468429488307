import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  rootSelectedForm: {
    padding: '0 4px 0 0 !important',
  },
  rootSelectForm: {
    padding: '10px',
    '&:hover': {
      background: '#F7F7F7',
    },
  },
  iconStyle: {
    marginRight: '5px',
  },
  iconComponent: {
    top: '20px',
  },
  iconBackground: {
    display: 'inline-block',
    height: '32px',
    borderRadius: '32px',
    width: '32px',
    background: '#F2F5F9',
    marginRight: '15px',
    paddingLeft: '8px',
    paddingTop: '6px',
    marginLeft: '5px',
  },
  itemIcons: {
    verticalAlign: 'middle',
  },
  iconBackgroundSelected: {
    display: 'inline-block',
    height: '25px',
    borderRadius: '25px',
    width: '25px',
    minHeight: '25px',
    minWidth: '25px',
    background: theme.palette.primary.main,
    marginRight: '15px',
    paddingLeft: '7px',
    paddingTop: '7px',
    marginLeft: '15px',
  },
  helperDescription: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  menuItem: {
    width: '958px',
    padding: '10px',
    whiteSpace: 'initial',
    height: 'fit-content',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    borderRadius: '0px',
  },
  optionName: {
    marginLeft: '0px',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  summary: {
    fontSize: 11,
    textTransform: 'none',
    width: '900px',
  },
  selectForm: {
    width: '250px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  selectedForm: {
    background: '#FAFAFA',
    width: '960px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  selectedName: {
    textTransform: 'capitalize',
    background: '#EEF3F9',
    width: 'auto',
    height: '56px',
    lineHeight: '56px',
    fontWeight: 'bold',
  },
}));
export default useStyles;
