import { FunctionComponent, ChangeEvent, useRef, useEffect } from 'react';
import useStyles from './useStyles';

type UploadHandler = (event: ChangeEvent<HTMLInputElement>) => void;

interface IUploadButton {
  id: string;
  labelCss?: string;
  onUpload: UploadHandler;
  render: (event: { [x: string]: () => void }) => JSX.Element;
  imageOnly?: boolean;
  isUploadEnabled?: boolean;
}

const UploadButton: FunctionComponent<IUploadButton> = ({
  id,
  labelCss,
  onUpload,
  render,
  imageOnly = false,
  isUploadEnabled = true,
}) => {
  const fileInputRef = useRef<HTMLInputElement>();
  const { classes } = useStyles();
  const handleButtonClick = () => {
    if (isUploadEnabled) {
      fileInputRef.current!.click();
    }
  };
  useEffect(() => {
    if (!isUploadEnabled) {
      //@ts-ignore
      fileInputRef.current!.value = null;
    }
  }, [fileInputRef, isUploadEnabled]);

  return (
    <label
      htmlFor={`upload-file-input-${id}`}
      className={labelCss}
      onClick={event => {
        if (!isUploadEnabled) {
          event.preventDefault();
        }
      }}
    >
      {render && render({ handleButtonClick })}

      <input
        id={`upload-file-input-${id}`}
        data-test={`data-center-importer-local-upload-file-input-${id}`}
        type="file"
        multiple
        accept={imageOnly ? 'image/*' : ''}
        className={classes.hiddenFileButton}
        onChange={onUpload}
        ref={(el: HTMLInputElement) => (fileInputRef.current = el)}
      />
    </label>
  );
};

export default UploadButton;
