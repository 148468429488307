import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux/selectors';
import {
  TOrgInviteeInput,
  TApplicationInviteRole,
  TOrganizationInvite,
  TUpdateOrganizationInviteInput,
  TDeleteOrganizationInvite,
} from '../types';

export async function fetchOrganizationInvites(apiConfigs: {
  apiRoot: string;
  graphQLEndpoint: string;
  token: string;
}): Promise<TOrganizationInvite[]> {
  const operationName = 'getCurrentUserOrganizationInvites';
  const name = 'me';
  const query = `
    query ${operationName}{
      ${name}{
        organizationInvites(status:approved) {
          id
          createdBy {
            id
            name
            firstName
            lastName
            imageUrl
          }
          organization {
            id
            name
            imageUrl
          }
          message
          applicationRoles{
            application {
              id
              name
              iconUrl
            }
            role {
              id
              name
            }
          }
          status
          expirationDate
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError({
    query,
    operationName,
    ...apiConfigs,
  });

  return result[name]?.organizationInvites.map((invite: any) => {
    invite.organizationInviteId = invite.id;
    delete invite.id;
    return invite;
  });
}

export async function createOrganizationInvite(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  payload: {
    invitees: TOrgInviteeInput[];
    organizationId: string;
    message: string;
  }
): Promise<TOrganizationInvite> {
  try {
    const { invitees, organizationId, message } = payload;

    const querySegments = [];
    let iterator = 1;
    for (const invitee of invitees) {
      const appRoles: string[] = [];
      invitee.applicationRoles.forEach(appRole => {
        appRoles.push(`{ applicationId: "${appRole?.applicationId}", roleId: "${appRole?.roleId}" }`);
      });

      const op = `invitee_${iterator}: createOrganizationInvite(input: {
        organizationId: "${organizationId}",
        message: "${message}",
        email: "${invitee.email}",
        applicationRoles: [${appRoles.join('')}] }) {
          status
        }
      `;
      querySegments.push(op);
      iterator++;
    }

    const query = `
    mutation {
        ${querySegments.join('')}
      }
    `;

    return await baseGraphQLApiWithError({
      query,
      ...apiConfigs,
      rethrowOriginalError: true,
    });
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function updateOrganizationInvite(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: TUpdateOrganizationInviteInput
): Promise<TOrganizationInvite> {
  const variables = {
    input,
  };
  const operationName = 'updateOrganizationInvite';
  const name = 'updateOrganizationInvite';
  const query = `
    mutation ${operationName}($input: UpdateOrganizationInviteInput!){
      ${name}(input: $input){
        organization {
          id
        }
        applicationRoles {
          application {
            id,
            name
            iconUrl
          }
          role {
            id,
            name,
            description
            appName
          }
        }
        invitee {
          email
        }
        status
        expirationDate
        message
      }
    }
  `;

  const result: {
    [name]: TOrganizationInvite;
  } = await baseGraphQLApiWithError({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  const { organization, invitee, applicationRoles, status, expirationDate, message } = result[name]!;

  return {
    organizationInviteId: input.organizationInviteId,
    organizationId: organization!.id,
    invitee,
    email: invitee.email,
    status,
    expirationDate,
    message,
    applicationRoles: applicationRoles.map((applicationRole: TApplicationInviteRole) => ({
      application: applicationRole.application,
      role: applicationRole.role,
      applicationId: applicationRole.application!.id,
      roleId: applicationRole.role!.id,
    })),
  };
}
export async function deleteOrganizationInvite(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: TDeleteOrganizationInvite
): Promise<TDeleteOrganizationInvite> {
  const variables = {
    organizationInviteId: input.organizationInviteId,
  };

  const operationName = 'deleteOrganizationInvite';
  const name = 'deleteOrganizationInvite';
  const query = `
    mutation ${operationName}($organizationInviteId: ID!){
      ${name}(organizationInviteId: $organizationInviteId){
       id
       }
    }
  `;

  const result = await baseGraphQLApiWithError({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function fetchPendingOrganizationInvites(
  orgId: string | number,
  apiConfigs: {
    apiRoot: string;
    graphQLEndpoint: string;
    token: string;
  }
): Promise<TOrganizationInvite[]> {
  const variables = {
    id: orgId,
  };
  const operationName = 'getPendingOrganizationInvites';
  const name = 'organization';
  const query = `
    query ${operationName}($id: ID!){
      ${name}(id: $id){
        organizationInvites(status:submitted) {
          id
          createdBy {
            id
            name
            firstName
            lastName
            imageUrl
          }
          organization {
            id
            name
            imageUrl
          }
          message
          applicationRoles{
            application {
              id
              name
              iconUrl
            }
            role {
              id
              name
            }
          }
          invitee {
            email
            name
            imageUrl
          }
          status
          expirationDate
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name]?.organizationInvites.map((invite: any) => {
    invite.organizationInviteId = invite.id;
    delete invite.id;
    return invite;
  });
}
