import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Stack, TextField, IconButton, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { Settings, DeleteOutline, DragIndicator } from '@mui/icons-material';
import { ICON_FIELD_MAP, ICON_FONT_SIZE_SX } from '../constants';
import { TextFieldSettings, NumberFieldSettings, SelectFieldSettings } from './FieldSettings';
import { FormField } from '../types';

interface FormFieldComponentProps {
  field: FormField;
  index: number;
  onDelete: (index: number) => void;
  onFieldChange: (index: number, field: FormField) => void;
}

export const FormFieldComponent: React.FC<FormFieldComponentProps> = ({
  field,
  index,
  onDelete,
  onFieldChange,
}) => {
  const [showSettings, setShowSettings] = useState(false);

  const renderSettingsPanel = () => {
    const settingsMap = {
      text: <TextFieldSettings field={field} index={index} onFieldChange={onFieldChange} />,
      number: <NumberFieldSettings field={field} index={index} onFieldChange={onFieldChange} />,
      select: <SelectFieldSettings field={field} index={index} onFieldChange={onFieldChange} />,
    };

    return settingsMap?.[field.type as keyof typeof settingsMap] ?? null;
  };

  return (
    <Draggable draggableId={field.id} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Stack direction={'row'} gap={1}>
            <Stack
              gap={1}
              sx={{
                p: 2,
                position: 'relative',
                width: '100%',
                bgcolor: '#EAEAEA',
                border: '1px solid #BDBDBD',
                borderRadius: '4px',
                height: 'auto',
                // Transition the height for smooth
                transition: 'height 0.3s ease',
              }}
            >
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  {ICON_FIELD_MAP[field.type]}
                  <Typography variant="h5" textTransform={'capitalize'}>
                    {field.type} Field
                  </Typography>
                </Stack>

                {/* Required Checkbox */}
                <FormControlLabel
                  sx={{
                    mr: 0,
                  }}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      size="small"
                      checked={field.required}
                      onChange={e => {
                        const updatedField = {
                          ...field,
                          required: e.target.checked,
                        };
                        onFieldChange?.(index, updatedField);
                      }}
                    />
                  }
                  label="Required"
                />
              </Stack>

              {/* Default Title */}
              <TextField
                fullWidth
                label="Default Title"
                value={field.label}
                onChange={e => {
                  const updatedField = {
                    ...field,
                    label: e.target.value,
                  };
                  onFieldChange?.(index, updatedField);
                }}
                variant="outlined"
                sx={{
                  bgcolor: 'white',
                }}
              />

              {/* Settings Panel */}
              {showSettings && renderSettingsPanel?.() && (
                <Stack
                  gap={4}
                  sx={{ mt: 2, bgcolor: 'white', p: 2, borderRadius: '4px', border: '1px solid #BDBDBD' }}
                >
                  <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <Typography variant="h6">Settings</Typography>
                  </Stack>
                  {renderSettingsPanel()}
                </Stack>
              )}
            </Stack>

            <Stack gap={2} alignItems={'center'}>
              <IconButton
                disabled={!renderSettingsPanel?.()}
                onClick={() => setShowSettings(!showSettings)}
                sx={{
                  bgcolor: showSettings ? 'primary.main' : 'transparent',
                  color: showSettings ? 'white' : 'inherit',
                  '&:hover': {
                    bgcolor: showSettings ? 'primary.dark' : 'rgba(0, 0, 0, 0.04)',
                    color: showSettings ? 'white' : 'inherit',
                  },
                }}
              >
                <Settings sx={ICON_FONT_SIZE_SX} />
              </IconButton>
              <DragIndicator
                sx={{
                  ...ICON_FONT_SIZE_SX,
                  transform: 'rotate(90deg)',
                }}
              />
              <IconButton onClick={() => onDelete(index)}>
                <DeleteOutline sx={ICON_FONT_SIZE_SX} />
              </IconButton>
            </Stack>
          </Stack>
        </div>
      )}
    </Draggable>
  );
};
