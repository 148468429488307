import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { GraphQLPage, ILibraryList } from '../../types/getLibraryIds.types';
import { selectApiConfigs } from '../../state/aiAutocomplete/aiAutocomplete.selectors';

export async function getLibraryIdsQuery(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset: number,
  limit: number
) {
  const operationName = 'libraries';
  const query = `
    query ${operationName} {
      ${operationName}(offset: ${offset}, limit: ${limit}) {
        count
        offset
        limit
        records {
          id
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [operationName]: GraphQLPage<ILibraryList>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  return result[operationName];
}
