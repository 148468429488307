import { FunctionComponent } from 'react';
import { mountPanel } from '@aiware/js/panel';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { SHARED_TEXT } from '../../../helper/shared-text';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Hide } from '@aiware/shared/icons';
import { useStyles } from './useStyles';

const adminCenterAddMemberPanel = 'ADMIN_CENTER_ADD_MEMBER_PANEL';

const Index: FunctionComponent<{
  height: number;
  onPanelClose: () => void;
  parentPanelId: string;
  groupId: string;
  isProtected?: boolean;
}> = ({ height, onPanelClose, parentPanelId, groupId }) => {
  const { classes } = useStyles({ height });
  const dispatch = useDispatch();

  const handleAddMembers = () => {
    const microFrontend = {
      name: adminCenterAddMemberPanel,
      config: {
        name: 'Add Members',
        groupId: groupId,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: 80,
      size: 'medium',
      parentPanelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: adminCenterAddMemberPanel,
        microFrontend,
        panelConfig,
      })
    );
  };

  return (
    <header className={classes.header} data-testid="group-details-header" data-test="group-details-header">
      <Typography variant="h1" data-testid="group-details-title" className={classes.title}>
        {SHARED_TEXT.groupDetails}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Button
          className={classes.addBtn}
          onClick={handleAddMembers}
          color="primary"
          variant="contained"
          data-testid="group-details-add-members-btn"
          data-test="group-details-add-members-btn"
        >
          <AddIcon className={classes.addIcon} />
          {SHARED_TEXT.addMembersBtn}
        </Button>
        <Tooltip title={SHARED_TEXT.ClosePanelMessage}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="group-details-close-panel"
            data-testid="group-details-close-panel"
            data-test="group-details-close-panel"
            size="large"
          >
            <Hide />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default Index;
