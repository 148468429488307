import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ height: string | number }>()((theme, { height }) => ({
  header: {
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    height,
    padding: theme.spacing(2, 2, 2, 6),
    backgroundColor: '#FAFAFA',
  },
  headerNoBorder: {
    alignItems: 'center',
    borderBottom: `0.5px solid transparent`,
    display: 'flex',
    justifyContent: 'space-between',
    height,
    padding: theme.spacing(0, 0, 0, 6),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  navigationToggle: {
    marginRight: theme.spacing(1),
  },
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
  },
  verticalDivider: {
    borderRight: `.5px solid ${theme.palette.divider}`,
    height: '70%',
    margin: `0 ${theme.spacing(1)}`,
  },
  title: {
    userSelect: 'none',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: theme.spacing(5),
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
}));
