import { ErrorInfo, FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { panelsSelector } from '@aiware/js/panel';
import { IPanel } from '@aiware/js/interfaces';
import { Box, Portal, Stack } from '@mui/material';
import { getFullScreenBtnStyles, toggleFullScreen } from '../helpers';
import { ErrorBoundary } from '../../error-boundary/error-boundary';

export interface IPanelContainerProps {
  isFullScreen?: boolean;
  panelId: string;
  testId?: string;
  children?: React.ReactNode;
  isRenderedFullScreen?: boolean;
  withRenderFullScreenControl?: boolean;
  preventFullScreenRerender?: boolean;
  fallbackUI?: ReactNode;
  onDidCatchError?: (error: Error, errorInfo: ErrorInfo) => void;
}

export const PanelContainer: FC<PropsWithChildren<IPanelContainerProps>> = props => {
  const panels = (useSelector(panelsSelector) || []) as IPanel<unknown, unknown>[];
  const {
    isRenderedFullScreen: _isRenderedFullScreen = false,
    withRenderFullScreenControl = false,
    preventFullScreenRerender = false,
    fallbackUI,
    onDidCatchError,
  } = props;
  const isRenderedFullScreen = _isRenderedFullScreen && withRenderFullScreenControl;
  const fullScreenButtonStyles = getFullScreenBtnStyles(withRenderFullScreenControl, isRenderedFullScreen);

  const Panel: any = panels.find(panel => {
    return panel.panelId === props.panelId;
  }) || { panelConfig: { dimmed: 0 } };

  const isDimmed = Panel?.panelConfig?.dimmed > 0;

  useEffect(() => {
    if (preventFullScreenRerender) {
      toggleFullScreen(props.panelId, isRenderedFullScreen);
    }
  }, [isRenderedFullScreen, preventFullScreenRerender]);

  if (isRenderedFullScreen && !preventFullScreenRerender) {
    return (
      <Portal>
        <Stack
          role="presentation"
          data-testid={props?.testId ? props.testId : 'panel-container-testid'}
          sx={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            background: '#FFF',
            top: 0,
            left: 0,
            ...fullScreenButtonStyles,
          }}
        >
          {isDimmed && (
            <Box
              sx={{
                backgroundColor: theme => theme.palette.grey[50],
                opacity: `0.5 !important`,
                height: '100%',
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            />
          )}
          {props?.children}
        </Stack>
      </Portal>
    );
  }

  return (
    <Stack
      data-testid={props?.testId ? props.testId : 'panel-container-testid'}
      sx={{
        height: props?.isFullScreen ? 'calc(100vh - 5px)' : `calc(100vh - 60px)`,
        position: 'relative',
        ...fullScreenButtonStyles,
      }}
    >
      {isDimmed && (
        <Box
          sx={{
            backgroundColor: theme => theme.palette.grey[50],
            opacity: `0.5 !important`,
            height: '100%',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        />
      )}
      <ErrorBoundary fallbackUI={fallbackUI} onDidCatchError={onDidCatchError}>
        {props?.children}
      </ErrorBoundary>
    </Stack>
  );
};
