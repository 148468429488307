import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { useStyles } from '../../useStyles';
import { ITdoVm } from '../../../../types';
import ContextMenuRowButton from '../../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../../types/entity-context-menus';
import GetRowItem from '../../../shared/GetRowItem';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector, selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { EntityType } from '@aiware/js/interfaces';
import { mountPanel } from '@aiware/js/panel';
const tdoContextMenu = ENTITY_CONTEXT_MENU[EntityType.Tdos];
const PARENT_PANEL_ID = 'DATA_CENTER';
const DATA_CENTER_MDP_IFRAME_PANEL = 'DATA_CENTER_MDP_IFRAME_PANEL';

const TdoTableFluffyRow: FunctionComponent<{
  item: ITdoVm;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const configs = useSelector(configSelector);
  const { classes } = useStyles();
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const dispatch = useDispatch();
  const mediaDetailsUrl = `${configs.cmsAppUrl}/embed/#/media-details/${item.id}`;

  const handleOpenMediaDetailsPanel = () => {
    const microFrontend = {
      name: DATA_CENTER_MDP_IFRAME_PANEL,
      config: {
        entityId: item.id,
        iframeUrl: mediaDetailsUrl,
        fileName: item.details?.filename || item.details?.fileName || item.name,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'xlarge',
      parentPanelId: PARENT_PANEL_ID,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: DATA_CENTER_MDP_IFRAME_PANEL,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  return (
    <Grid data-test={`${EntityType.Tdos}-row-fluffy-${item.id}`} style={{ height: rowHeight }} container>
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignContent="space-around"
        container
        item
        xs={11}
        onClick={() => handleOpenMediaDetailsPanel()}
      >
        <GetRowItem rowName={'thumbnail'} item={item} xs={2} itemType={EntityType.Tdos} />
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="space-around"
          xs={10}
          className={classes.paddingLeft}
        >
          <Grid item container alignContent="center" xs={12}>
            <GetRowItem rowName={'name'} item={item} itemType={EntityType.Tdos} />
            <GetRowItem rowName={'fileType'} item={item} xs={1} itemType={EntityType.Tdos} />
          </Grid>

          <Grid item container direction="row" alignItems="center" justifyContent="flex-start" xs={12}>
            <GetRowItem rowName={'mediaStartTime'} item={item} xs={8} itemType={EntityType.Tdos} />
            <GetRowItem rowName={'engineIds'} item={item} xs={4} itemType={EntityType.Tdos} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={1}
        className={classes.moreIcon}
        item
        container
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <ContextMenuRowButton contextMenu={tdoContextMenu} entityId={item.id} type={EAuthResourceType.TDO} />
      </Grid>
    </Grid>
  );
};
export default TdoTableFluffyRow;
