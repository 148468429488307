import createSvgIcon from '../createSvgIcon';
export const MarketplaceFilled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.59138 2.81306C8.79409 2.46729 9.23301 2.34229 9.58747 2.52938C9.96503 2.72865 10.1 3.20265 9.8841 3.57094L6.70119 9.00002H6.79L8.84 9.01L12.5 9.00007L16 9L17.21 9.00002H17.6648L14.2965 3.65914C14.0696 3.29934 14.1873 2.82303 14.5557 2.61035C14.9042 2.40909 15.3496 2.51914 15.5643 2.8596L19.4368 9.00002H22C22.55 9.00002 23 9.45002 23 10L22.97 10.27L20.43 19.54C20.19 20.38 19.42 21 18.5 21H5.5C4.58 21 3.81 20.38 3.58 19.54L1.04 10.27C1.01 10.18 1 10.09 1 10C1 9.45002 1.45 9.00002 2 9.00002H4.96414L8.59138 2.81306ZM7 12.75C7 12.3358 7.33579 12 7.75 12C8.16421 12 8.5 12.3358 8.5 12.75V17.25C8.5 17.6642 8.16421 18 7.75 18C7.33579 18 7 17.6642 7 17.25V12.75ZM15.75 12C15.3358 12 15 12.3358 15 12.75V17.25C15 17.6642 15.3358 18 15.75 18C16.1642 18 16.5 17.6642 16.5 17.25V12.75C16.5 12.3358 16.1642 12 15.75 12ZM11 12.75C11 12.3358 11.3358 12 11.75 12C12.1642 12 12.5 12.3358 12.5 12.75V17.25C12.5 17.6642 12.1642 18 11.75 18C11.3358 18 11 17.6642 11 17.25V12.75Z"
    />
  </svg>,
  'MarketplaceFilled'
);
