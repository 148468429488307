import { call, put } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { actions } from '../../slices';
import * as api from '../../../api';
import { sdkEventManager, SdkError } from '@aiware/js/sdk';
import { SdkEvents } from '@aiware/js/interfaces';
import { FileItemField } from '../../slices/files-selected-state';
import { getUrlBuckets } from '../common/common.saga';

// Using any here because this is a strange situation
// eslint-disable-next-line
const root: any = typeof window !== 'undefined' ? window : global;

declare global {
  interface root {
    xhrInstances: {
      [key: string]: XMLHttpRequest;
    };
  }
}

root.xhrInstances = {};

export const fileUploadChannel = channel();

export function* filesSelected(action: ReturnType<typeof actions.filesSelectedState.setFilesSelected>) {
  const files = action.payload.filesSelected;
  const sdkFiles = action.payload.sdkFiles;
  try {
    yield put(actions.uiState.fileUploadStart());

    //@ts-ignore
    const buckets = yield call(getUrlBuckets, files.length);

    let i = 0;
    for (const bucket of buckets) {
      const file = files[i];
      i++;

      yield put(
        actions.filesSelectedState.updateFile({
          id: file!.id,
          status: 'pending',
          percentComplete: 0,
          expiresInSeconds: bucket.expiresInSeconds,
          getUrl: bucket.getUrl,
          key: bucket.key,
          unsignedUrl: bucket.unsignedUrl,
          url: bucket.url,
        })
      );

      const fileDataForEvent = {
        ...sdkFiles,
        expiresInSeconds: bucket.expiresInSeconds,
        getUrl: bucket.getUrl,
        key: bucket.key,
        unsignedUrl: bucket.unsignedUrl,
        url: bucket.url,
      };

      const updateFile = (values: FileItemField) => {
        fileUploadChannel.put(actions.filesSelectedState.updateFile({ id: file!.id, ...values }));
      };

      const onProgress = (progress: number) => {
        updateFile({
          percentComplete: progress,
        });

        sdkEventManager.dispatch(SdkEvents.fileUploadProgress, null, progress);
      };

      const onAbort = (msg: string) => {
        updateFile({ status: 'idle' });

        sdkEventManager.dispatch(SdkEvents.fileUpload, new SdkError(msg, 'abort'), fileDataForEvent);
      };

      const onError = (msg: string) => {
        updateFile({ status: 'failure' });

        sdkEventManager.dispatch(SdkEvents.fileUpload, new SdkError(msg), fileDataForEvent);
      };

      const onUpload = (msg: string) => {
        updateFile({ status: 'idle' });

        sdkEventManager.dispatch(SdkEvents.fileUpload, null, fileDataForEvent);
      };

      //@ts-ignore
      const xhrInstance = yield api.uploadFileToS3(bucket, file, {
        onProgress,
        onAbort,
        onError,
        onUpload,
      });

      root.xhrInstances[file!.id] = xhrInstance;

      yield put(actions.uiState.fileUploadSucceed());
    }
  } catch (error) {
    //@ts-ignore
    const message = error?.message || '';
    console.error(message);
    yield put(actions.uiState.fileUploadFailed());
  }
}

export function* abortFileUpload({ payload }: ReturnType<typeof actions.filesSelectedState.abortFileUpload>) {
  try {
    const id: string = payload?.id;
    const xhrInstanceToAbort = root.xhrInstances[id];

    xhrInstanceToAbort?.abort();

    yield put(actions.filesSelectedState.deleteFileSelected({ id: id }));
  } catch (error) {
    yield put(actions.uiState.fileUploadAbortFailed());
  }
}
