import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  sdoMenuItemLabel: () => (
    <FormattedMessage
      id="os-data-center-sdo.tab-name"
      defaultMessage="Data Registries"
      description="SDO registry menu button label"
    />
  ),
  dataRegistryBrowser: {
    emptyStateMessage: () => (
      <FormattedMessage
        id="os-data-center-sdo.empty-state-message"
        defaultMessage="No Data Registries Found"
        description="SDO empty state message"
      />
    ),
    errorStateMessage: () => (
      <FormattedMessage
        id="os-data-center-sdo.error-state-message"
        defaultMessage="Error loading Data Registries"
        description="SDO error state message"
      />
    ),
    tableHeader: {
      name: () => (
        <FormattedMessage
          id="os-data-center-sdo.table-header.name"
          defaultMessage="Name"
          description="SDO table header - name"
        />
      ),
      created: () => (
        <FormattedMessage
          id="os-data-center-sdo.table-header.created"
          defaultMessage="Created"
          description="SDO table header - created"
        />
      ),
      modified: () => (
        <FormattedMessage
          id="os-data-center-sdo.table-header.modified"
          defaultMessage="Modified"
          description="SDO table header - modified"
        />
      ),
    },
    contextMenu: {
      edit: () => (
        <FormattedMessage
          id="os-data-center-sdo.context-menu.edit"
          defaultMessage="Edit Data Registry"
          description="SDO context menu - edit"
        />
      ),
    },
  },
  editDataRegistry: {
    headerTitle: () => (
      <FormattedMessage
        id="os-data-center-sdo.edit-data-registry.header.title"
        defaultMessage="Edit Data Registry"
        description="SDO edit data registry header title"
      />
    ),
    headerDescription: () => (
      <FormattedMessage
        id="os-data-center-sdo.edit-data-registry.header.description"
        defaultMessage="Modify the data registry name and description."
        description="SDO edit data registry header description"
      />
    ),
    saveButton: () => (
      <FormattedMessage
        id="os-data-center-sdo.edit-data-registry.save-button"
        defaultMessage="Save"
        description="SDO edit data registry save button"
      />
    ),
  },
  sdoViewer: {
    sdoPanelTitle: () => (
      <FormattedMessage
        id="os-data-center-sdo.panel-viewer.title"
        defaultMessage="Structured Data Viewer"
        description="SDO viewer panel title"
      />
    ),
    headerExport: () => (
      <FormattedMessage
        id="os-data-center-sdo.panel-viewer.header.export"
        defaultMessage="Export Data"
        description="SDO viewer panel header - export icon"
      />
    ),
    headerHelp: () => (
      <FormattedMessage
        id="os-data-center-sdo.panel-viewer.header.help"
        defaultMessage="Info"
        description="SDO viewer panel header - help icon"
      />
    ),
  },
  sdoImporter: {},
  dataRegistryInfo: {
    title: () => (
      <FormattedMessage
        id="os-data-center-sdo.data-registry-info-header"
        defaultMessage="Data Registry Info"
        description="data registry info pabel header"
      />
    ),
    name: () => (
      <FormattedMessage
        id="os-data-center-sdo.data-registry-info-name-label"
        defaultMessage="Name"
        description="Name Label"
      />
    ),
    id: () => (
      <FormattedMessage
        id="os-data-center-sdo.data-registry-info-id-label"
        defaultMessage="ID"
        description="ID Label"
      />
    ),
    created: () => (
      <FormattedMessage
        id="os-data-center-sdo.data-registry-info-created-label"
        defaultMessage="Created Date"
        description="Created Date Label"
      />
    ),
    modified: () => (
      <FormattedMessage
        id="os-data-center-sdo.data-registry-info-modified-label"
        defaultMessage="Modified Date"
        description="Modified Date Label"
      />
    ),
  },
};
