import { LoadingStatus } from '@aiware/js/interfaces';
import { TabPanel, Tabs } from '@aiware/ui';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EMemberType, TAuthACE, TPermissionOptionSDK } from '../../../types';
import MemberCard from '../permissions-member-card';
import { useStyles } from './tabs.styles';
import { renderWhenReady } from '../../helpers/utils';

const PermissionPanelTabs = ({
  aces,
  permissionOptions = [],
  isMVP = false,
  isFactoryMode = false,
  onRevokeAccess,
  onChangeAccess,
  loadingStatus,
}: {
  aces: TAuthACE[];
  permissionOptions: TPermissionOptionSDK[];
  isMVP?: boolean;
  isFactoryMode?: boolean;
  onRevokeAccess: (id: string) => void;
  onChangeAccess: (newValue: string, memberId: string, memberType: EMemberType) => void;
  loadingStatus: LoadingStatus;
}) => {
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [userACEs, setUserACEs] = useState([] as (TAuthACE & { permissions: TPermissionOptionSDK })[]);
  const [groupACEs, setGroupACEs] = useState([] as (TAuthACE & { permissions: TPermissionOptionSDK })[]);
  const { classes } = useStyles();
  const membersGrouping = (aces: TAuthACE[]) => {
    const groupedAces: (TAuthACE & { permissions: TPermissionOptionSDK })[] = [];
    aces.forEach(ace => {
      const relevantPermission = permissionOptions.find(p => p.value === ace.permissionSet.permissionId);
      if (relevantPermission) {
        groupedAces.push({
          ...ace,
          permissions: relevantPermission,
        });
      }
    });
    return groupedAces;
  };

  useEffect(() => {
    const users: TAuthACE[] = [];
    const groups: TAuthACE[] = [];
    aces.forEach(ace => {
      if (ace.member?.memberType?.toLowerCase() === 'user') {
        users.push(ace);
      } else {
        groups.push(ace);
      }
    });
    setUserACEs(membersGrouping(users));
    setGroupACEs(membersGrouping(groups));
  }, [aces]);

  const tabsList = [
    {
      label: (
        <div>
          <FormattedMessage
            id="permissions-panel.tabs.group-label"
            defaultMessage="Groups"
            description="Group members of a permission set"
          />{' '}
          ({groupACEs.length})
        </div>
      ),
      disabled: groupACEs.length === 0,
      id: 'groups',
    },
  ];

  const peopleTab = {
    label: (
      <div>
        <FormattedMessage
          id="permissions-panel.tabs.people-label"
          defaultMessage="People"
          description="User members of a permission set"
        />{' '}
        ({userACEs.length})
      </div>
    ),
    disabled: userACEs.length === 0,
    id: 'people',
  };

  if (!isMVP) {
    tabsList.unshift(peopleTab);
  }

  const onChangeTab = (id: number) => {
    setSelectedTabId(id);
  };

  return (
    <div
      className={classes.tabsContainer}
      data-testid="permissions-panel-members-tabs"
      data-test="permissions-panel-members-tabs"
    >
      <div className={classes.tabsParent}>
        {renderWhenReady(
          loadingStatus,
          <Tabs selectedId={selectedTabId} onChangeTab={onChangeTab} tabsList={tabsList} />
        )}
      </div>
      <div className={classes.tabContent}>
        {renderWhenReady(
          isFactoryMode ? 'success' : loadingStatus,
          <>
            {!isMVP ? (
              <TabPanel value={selectedTabId} index={0}>
                {userACEs.map(userAce => (
                  <MemberCard
                    ace={userAce}
                    key={userAce.id}
                    permissionOptions={permissionOptions}
                    onRemove={onRevokeAccess}
                    onChangeAccess={onChangeAccess}
                    isFactoryMode={isFactoryMode}
                  />
                ))}
              </TabPanel>
            ) : null}
            <TabPanel value={selectedTabId} index={!isMVP ? 1 : 0}>
              {groupACEs.map(groupAce => (
                <MemberCard
                  ace={groupAce}
                  key={groupAce.id}
                  permissionOptions={permissionOptions}
                  onRemove={onRevokeAccess}
                  onChangeAccess={onChangeAccess}
                  isFactoryMode={isFactoryMode}
                />
              ))}
            </TabPanel>
          </>,
          true
        )}
      </div>
    </div>
  );
};

export default PermissionPanelTabs;
