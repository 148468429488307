import { AiwareFrameLink, EMessageTypes } from '.';
import { EFrameLinkEvents } from './constants';

export function init(this: AiwareFrameLink) {
  // If this is a child frame, request the context from the parent frame
  if (this.isChild) {
    this.postMessage(EMessageTypes.getInitContext);
    this.healthReporting();
  } else {
    if (!this.initialized) {
      setTimeout(() => {
        if (!this.initialized) {
          this.dispatch(EFrameLinkEvents.init, new Error('Cannot establish handshake'));
        } else {
          this.dispatch(EFrameLinkEvents.init, null, true);
        }
      }, this.heartbeatInterval);
    } else {
      this.healthMonitoring();
    }
  }
  // Listen for messages
  window.addEventListener('message', this.handleMessage.bind(this), false);
}
