import { Audio } from './Audio';
import { Biometrics } from './Biometrics';
import { Data } from './Data';
import { Speech } from './Speech';
import { Text } from './Text';
import { Transformation } from './Transformation';
import { Vision } from './Vision';
import { Pull } from './Pull';
import { Push } from './Push';
import { Generator } from './Generator';
import { AudioWhite } from './AudioWhite';
import { BiometricsWhite } from './BiometricsWhite';
import { DataWhite } from './DataWhite';
import { SpeechWhite } from './SpeechWhite';
import { TextWhite } from './TextWhite';
import { TransformationWhite } from './TransformationWhite';
import { VisionWhite } from './VisionWhite';
import { PullWhite } from './PullWhite';
import { PushWhite } from './PushWhite';
import { GeneratorWhite } from './GeneratorWhite';

export const EngineClassIcons = {
  Audio,
  Biometrics,
  Data,
  Speech,
  Text,
  Transformation,
  Vision,
  Pull,
  Push,
  Generator,
  AudioWhite,
  BiometricsWhite,
  DataWhite,
  SpeechWhite,
  TextWhite,
  TransformationWhite,
  VisionWhite,
  PullWhite,
  PushWhite,
  GeneratorWhite,
};
