import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Avatar,
} from '@mui/material';
import { FixedSizeList } from 'react-window';

import { Check } from '@mui/icons-material';
import { OrgFilled } from '@aiware/shared/icons';

import { panelComponents } from '@aiware/shared/reusable-utils';
import { SHARED_TEXT } from '../../helper/shared-text';

import { TOrgApplication } from '../../types';

const { modal: PanelModal } = panelComponents;

export interface IFirstLoadApplicationModalProps {
  id: string;
  isOpen: boolean;
  applications: TOrgApplication[];
  defaultLoadApplication?: string;
  onSelectedApplication: (application: TOrgApplication) => void;
  onClose: () => void;
}

export const FirstLoadApplicationModal: React.FC<IFirstLoadApplicationModalProps> = ({
  id,
  isOpen,
  applications,
  defaultLoadApplication,
  onSelectedApplication,
  onClose,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(defaultLoadApplication);

  useEffect(() => {
    setSelectedItemId(defaultLoadApplication);
  }, [defaultLoadApplication]);

  return (
    <PanelModal
      data-test={`first-application-modal-${id}`}
      sx={{
        maxHeight: '95vh',
      }}
      ContainerProps={{
        sx: {
          width: '100%',
          padding: '10px 30px 15px 30px',
          boxSizing: 'border-box',
          overflow: 'hidden',
        },
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={'First Load Application'}
      content={
        <Stack
          gap={2}
          sx={{
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="column" gap={4} sx={{ height: '90%', position: 'relative', overflow: 'auto' }}>
            <Typography variant="caption" sx={{ fontWeight: 400, fontSize: '14px' }}>
              {SHARED_TEXT.firstLoadApplicationModalDesc()}
            </Typography>
            <Box sx={{ width: '100%', height: 250, maxWidth: 550, bgcolor: 'background.paper' }}>
              <FixedSizeList
                height={250}
                width={'100%'}
                itemSize={60}
                itemCount={applications.length}
                overscanCount={5}
              >
                {({ index, style }) => {
                  const isItemSelected = applications[index]?.id === selectedItemId;

                  return (
                    <ListItem
                      style={style}
                      key={index}
                      component="div"
                      disablePadding
                      secondaryAction={
                        isItemSelected && (
                          <IconButton sx={{ marginRight: '10px' }}>
                            <Check
                              sx={{
                                color: 'rgba(56, 142, 60, 1)',
                              }}
                            />
                          </IconButton>
                        )
                      }
                    >
                      <Stack direction="row" justifyContent={'space-between'} sx={{ width: '100%' }}>
                        <ListItemButton
                          selected={isItemSelected}
                          onClick={() => {
                            onSelectedApplication(applications[index]!);
                          }}
                        >
                          <ListItemIcon sx={{ marginRight: '20px' }}>
                            <Avatar src={applications[index]?.signedIconUrl}>
                              <OrgFilled />
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText primary={applications[index]?.name} />
                        </ListItemButton>
                      </Stack>
                    </ListItem>
                  );
                }}
              </FixedSizeList>
            </Box>
          </Stack>
          <Footer onSave={onClose} isSaveDisabled={false} />
        </Stack>
      }
    />
  );
};

const Footer = ({ onSave, isSaveDisabled }: { onSave: () => void; isSaveDisabled: boolean }) => {
  return (
    <Stack
      direction="row"
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        height: '10%',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={onSave}
        disabled={isSaveDisabled}
        variant="contained"
        data-testid="admin-center-custom-login--save-btn"
      >
        {SHARED_TEXT.doneBtn()}
      </Button>
    </Stack>
  );
};
