import React from 'react';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { ErrorBoundary } from '../../error-boundary/error-boundary';
import { TJSONTableProps } from '../json-table';
import { jsonTableStyles } from '../helpers/json-table-styles';
import { jsonTableText } from '../helpers/json-table-text';

export type TJSONTablePaginationProps = {
  tableProps: TJSONTableProps;
  goToPage: (page: number) => void;
  pageSize: number;
  currentPage: number;
  lastPage: number;
  totalRows?: number;
  isOnLastPage: boolean;
  isNextLastPage: boolean;
  isLastPageItemCountOffset: boolean;
};

export const JSONTablePagination = (props: TJSONTablePaginationProps) => {
  return (
    <ErrorBoundary fallbackUI={<Typography>{jsonTableText.errors.paginationError}</Typography>}>
      <JSONTablePaginationRender {...props} />
    </ErrorBoundary>
  );
};

const JSONTablePaginationRender = (props: TJSONTablePaginationProps) => {
  const {
    goToPage,
    pageSize,
    currentPage,
    tableProps,
    isOnLastPage,
    totalRows,
    isNextLastPage,
    isLastPageItemCountOffset,
  } = props;
  const { isLoading, hasMore, fetchMore, testId } = tableProps;

  const startRow = currentPage === 1 ? 1 : pageSize * (currentPage - 1) + 1;

  const getEndRow = () => {
    if (currentPage === 1 && !hasMore) {
      return (totalRows ?? 0) > pageSize ? pageSize : totalRows;
    }

    if (currentPage === 1) {
      return pageSize;
    } else {
      return pageSize * currentPage;
    }
  };

  const endRow = getEndRow();

  // Button disabled states
  const isFirstDisabled = currentPage === 1 || isLoading;
  const isPrevDisabled = currentPage === 1 || isLoading;
  const isNextDisabled = (!hasMore && isOnLastPage) || isLoading;

  const handleGoToNextPage = () => {
    if (hasMore && isNextLastPage && isLastPageItemCountOffset) {
      fetchMore();
      goToPage(currentPage + 1);
      return;
    }

    if (hasMore && isOnLastPage) {
      fetchMore();
      goToPage(currentPage + 1);
      return;
    }

    if (hasMore && !isOnLastPage) {
      goToPage(currentPage + 1);
      return;
    }

    if (!hasMore && !isOnLastPage) {
      goToPage(currentPage + 1);
      return;
    }
  };

  const handleGoToPrevPage = () => {
    if (currentPage === 1) return;

    goToPage(currentPage - 1);
  };

  return (
    <Stack gap="10px" direction={'row'} alignItems={'center'}>
      <Button
        onClick={() => goToPage(1)}
        sx={jsonTableStyles.button}
        variant={'text'}
        disabled={isFirstDisabled}
        data-testid={`${testId}-pagination-control-first`}
      >
        {jsonTableText.pagination.first}
      </Button>
      <Button
        onClick={handleGoToPrevPage}
        sx={jsonTableStyles.button}
        startIcon={<ChevronLeft />}
        variant={'text'}
        disabled={isPrevDisabled}
        data-testid={`${testId}-pagination-control-prev`}
      >
        {jsonTableText.pagination.prev}
      </Button>
      <Typography
        variant={'caption'}
        fontWeight={'bold'}
        data-testid={`${testId}-pagination-control-page-summary`}
      >
        <RenderRowCount isLoading={isLoading} endRow={endRow} startRow={startRow} totalRows={totalRows} />
      </Typography>
      <Button
        onClick={handleGoToNextPage}
        sx={jsonTableStyles.button}
        variant={'text'}
        endIcon={<ChevronRight />}
        disabled={isNextDisabled}
        data-testid={`${testId}-pagination-control-next`}
      >
        {jsonTableText.pagination.next}
      </Button>
    </Stack>
  );
};

const RenderRowCount: React.FC<{
  isLoading: boolean;
  startRow: number;
  endRow?: number;
  totalRows?: number;
}> = ({ isLoading, startRow, endRow, totalRows }) => {
  if (isLoading) return <Skeleton variant="text" width={100} />;
  return (
    <>
      {jsonTableText.pagination.rows} {startRow} - {endRow} {jsonTableText.pagination.of}{' '}
      {totalRows ? totalRows : jsonTableText.pagination.many}
    </>
  );
};
