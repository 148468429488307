import axios from 'axios';
import { ECapabilityTypes, ISuggestion } from '../../types/aiAutocomplete.types';
import { v4 as uuidv4 } from 'uuid';
import { root } from '.';

const field = 'entityName';

type TFaceDoc = {
  created: number;
  entityId: string;
  entityName: string;
  identifierType: any[];
  libraryCoverImageUrl: string | null;
  libraryId: string;
  libraryName: string;
  modified: number;
  profileImageUrl: string | null;
};
type TFaceResponse = {
  doc: TFaceDoc;
  key: string;
  doc_count: number;
  docs: any[]; // TBD
};

export const getFaceSuggestionsQuery = async ({
  endpoint,
  token,
  text,
  libIds,
}: {
  endpoint: string;
  token: string;
  text: string;
  libIds: string[];
}): Promise<ISuggestion[]> => {
  const faceQueryAbortController = new AbortController();
  //store the abort controller in the root object so it can be abort later.
  root.abortControllers[ECapabilityTypes.facial] = faceQueryAbortController;

  const result = await axios
    .post(
      `${endpoint}/v1/search/search/autocomplete`,
      {
        aggregateFields: ['libraryId'],
        fields: [field],
        index: libIds,
        text,
        limit: 10,
        returnContext: true,
      },
      {
        signal: root.abortControllers[ECapabilityTypes.facial]?.signal,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response.data);

  if (!result?.fields) {
    throw Error('An error occurred while searching for face that match your search criteria.');
  }

  return result.fields[field].map((item: TFaceResponse) => {
    return {
      id: uuidv4(),
      entityId: item.doc.entityId,
      label: item.key || '',
      imageUrl: '',
      capabilityType: ECapabilityTypes.facial,
    };
  }) as ISuggestion[];
};
