import { AiwareFrameLink } from '.';

export function subscribe(this: AiwareFrameLink, cb: any) {
  this.subscribers.push(cb);
  const newSubscribersLength = this.subscribers.length;
  // dedupe
  this.subscribers = [...new Set(this.subscribers)];
  if (newSubscribersLength !== this.subscribers.length) {
    // TODO: inform user that they are pushing a duplicate subscriber function
  }
  return () => {
    this.subscribers = this.subscribers.filter(subscriber => subscriber !== cb);
  };
}
