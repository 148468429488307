import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Avatar, Skeleton, Typography } from '@mui/material';
import { OrganizationTabIcon } from '@aiware/shared/icons';

const useStyles = makeStyles<void, 'root'>()(theme => ({
  root: {},
  container: {
    padding: theme.spacing(4, 0, 4, 0),
  },
  flex: {
    display: 'flex',
  },
  textContainer: {
    marginLeft: '15px',
    minWidth: '80%',
  },
  textDiv: {
    minWidth: '100%',
  },
  subtitleText: {
    color: '#5C6269',
  },
  organizationLabel: {
    fontSize: '18px',
    width: '250px',
    wordWrap: 'break-word',
  },
}));

export const ReviewRequestOrganizationLabel: FunctionComponent<{
  organizationName: string;
  organizationLogo?: string;
}> = ({ organizationName, organizationLogo }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        {organizationName === null && <Skeleton variant="circular" width={48} height={48} />}
        {organizationName && (
          <Avatar
            sx={{ bgcolor: '#F2F5F9', padding: '11px', width: 48, height: 48 }}
            src={organizationLogo ? organizationLogo : undefined}
          >
            {!organizationLogo && <OrganizationTabIcon />}
          </Avatar>
        )}

        <div className={classes.textContainer}>
          <div className={classes.textDiv}>
            {organizationName === null && <Skeleton variant="text" />}
            {organizationName && (
              <Typography className={classes.subtitleText}>
                <FormattedMessage
                  id="os-organization-panel.review-request-panel.org-label.veritone-inviting-you"
                  defaultMessage="Veritone is inviting you to join the"
                  description="Review Request Panel organization label, veritone inviting you"
                />
              </Typography>
            )}
          </div>
          <div className={classes.textDiv}>
            {organizationName === null && <Skeleton variant="text" />}
            {organizationName && (
              <Typography className={classes.organizationLabel}>
                {organizationName}{' '}
                <FormattedMessage
                  id="os-organization-panel.review-request-panel.org-label.word-organization"
                  defaultMessage="Organization"
                  description="Review Request Panel organization label, organization word."
                />
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
