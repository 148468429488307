import { DateFormatter } from '@aiware/shared/reusable-utils';
import { DataRegistry } from '@aiware/shared/icons';
import { Avatar, Box, Grid, Typography, Stack } from '@mui/material';
import { DATA_REGISTRY_TABLE_ITEM_HEIGHT } from '../../../../../constants';
import { DataRegistryContextMenu } from './context-menu';
import { betaFeaturesSelector } from '@aiware/shared/redux';
import { useSelector } from 'react-redux';

const ItemIcon = () => (
  <Avatar
    sx={{
      width: '30px',
      height: '30px',
      border: '1px solid #bfbfbf',
      color: '#bfbfbf',
      backgroundColor: '#f7f7f7',
      '& > svg': {
        scale: '0.85',
        fontSize: '15px',
      },
    }}
  >
    <DataRegistry viewBox="0 0 20 19" />
  </Avatar>
);

export const DataRegistryTableRow = ({
  item,
  index: _index,
  onClick,
}: {
  item: any;
  index: number;
  onClick: () => void;
}) => {
  const isBetaFeaturesEnabled = useSelector(betaFeaturesSelector);

  return (
    <Box
      onClick={onClick}
      sx={{
        width: '100%',
        height: `${DATA_REGISTRY_TABLE_ITEM_HEIGHT}px`,
        borderBottom: '1px solid #D5DFE9',
        '&:hover': {
          backgroundColor: '#f2f5f9',
          cursor: 'pointer',
        },
      }}
      data-testid="os-dc-data-registry-table-row-click"
    >
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        item
        container
        xs={12}
        sx={{ pl: 3, height: '100%' }}
      >
        <Grid item xs={7}>
          <Stack sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }} direction={'row'}>
            <ItemIcon />
            <Stack sx={{ paddingRight: '20px', width: 'calc(100% - 50px)' }}>
              <Typography sx={{ fontWeight: 600 }} noWrap data-testid="os-dc-data-registry-table-row-name">
                {item?.name}
              </Typography>
              <Typography
                variant={'caption'}
                sx={{ opacity: 0.65 }}
                noWrap
                data-testid="os-dc-data-registry-table-row-description"
              >
                {item?.description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2} data-testid="os-dc-data-registry-table-row-created">
          <DateFormatter value={item?.createdDateTime} />
        </Grid>
        <Grid item xs={2} data-testid="os-dc-data-registry-table-row-modified">
          <DateFormatter value={item?.modifiedDateTime} />
        </Grid>
        <Grid item xs={1} data-testid="os-dc-data-registry-table-row-context-Menu">
          {isBetaFeaturesEnabled && <DataRegistryContextMenu dataRegistryId={item?.id} />}
        </Grid>
      </Grid>
    </Box>
  );
};
