import { baseGraphQLApiWithError, selectApiConfigs, baseGraphQLApi } from '@aiware/shared/redux';
import { GraphQLPage, TApplicationConfigSetConfigInput, TApplicationConfig } from '../types';

export async function fetchApplicationConfig(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { appId, userId, orgId }: { appId: string; userId: string; orgId: string }
): Promise<GraphQLPage<TApplicationConfig>> {
  const operationName = 'fetchApplicationConfig';
  const name = 'applicationConfig';
  const variables = {
    appId,
    userId,
    orgId,
  };

  const query = `
    query ${operationName} ($appId: ID! $userId: ID $orgId: ID) {
      ${name} (
        appId: $appId
        userId: $userId
        orgId: $orgId
      ) {
          records {
            applicationId
            userId
            organizationGuid
            configKey
            configType
            configLevel
            required
            secured
            description
            value
            valueJSON
            createdAt
            modifiedAt
            createdBy {
              id
              name
              firstName
              lastName
              email
              imageUrl
            }
            modifiedBy{
              id
              name
              firstName
              lastName
              email
              imageUrl
            }
            defaultValue
            defaultValueJSON
            defaultCreatedAt
            defaultModifiedAt
          }
        }
      }
    `;

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TApplicationConfig>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function updateApplicationConfig(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: TApplicationConfigSetConfigInput
): Promise<GraphQLPage<TApplicationConfig>> {
  const operationName = 'updateApplicationConfig';
  const name = 'applicationConfigSet';
  const variables = {
    input,
  };
  const query = `
  mutation ${operationName} ($input: ApplicationConfigSetConfigInput) {
    ${name} (input: $input) {
      records {
        configKey
        value
      }
    }
  }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TApplicationConfig>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });
  return result[name];
}

export async function getApplicationName(apiConfigs: ReturnType<typeof selectApiConfigs>, input: string) {
  const operationName = 'getApplicationName';
  const op = 'applications';
  const variables = {
    id: input,
  };

  const query = `
  query  ${operationName} ($id: ID!) {
    ${op} (id: $id) {
      records {
        id
        name
      }
    }
  }
  `;

  const { token, graphQLEndpoint: graphEndpoint } = apiConfigs;

  try {
    const result = await baseGraphQLApi({
      query,
      operationName,
      variables,
      graphEndpoint,
      token,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
}
