import createSvgIcon from '../createSvgIcon';
export const PodcastSource = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#9CA8B4" />
    <path
      d="M14.9167 15.6458V17.5417H9.08333V15.6458C9.08333 14.8408 10.39 14.1875 12 14.1875C13.61 14.1875 14.9167 14.8408 14.9167 15.6458ZM12 8.20833C13.0056 8.20833 13.97 8.60781 14.6811 9.31889C15.3922 10.03 15.7917 10.9944 15.7917 12C15.7917 12.7292 15.5875 13.4117 15.2317 13.9892L14.3333 13.19C14.52 12.8342 14.625 12.4258 14.625 12C14.625 10.5417 13.4583 9.375 12 9.375C10.5417 9.375 9.375 10.5417 9.375 12C9.375 12.4258 9.48 12.8342 9.66667 13.19L8.76833 13.9892C8.4125 13.4117 8.20833 12.7292 8.20833 12C8.20833 10.9944 8.60781 10.03 9.31889 9.31889C10.03 8.60781 10.9944 8.20833 12 8.20833ZM12 5.875C13.6245 5.875 15.1824 6.52031 16.331 7.66897C17.4797 8.81763 18.125 10.3755 18.125 12C18.125 13.33 17.6992 14.5608 16.9817 15.5642L16.1067 14.7767C16.6433 13.9833 16.9583 13.0267 16.9583 12C16.9583 10.685 16.4359 9.4238 15.5061 8.49393C14.5762 7.56406 13.315 7.04167 12 7.04167C10.685 7.04167 9.4238 7.56406 8.49393 8.49393C7.56406 9.4238 7.04167 10.685 7.04167 12C7.04167 13.0267 7.35667 13.9833 7.89333 14.7767L7.01833 15.5642C6.30083 14.5608 5.875 13.33 5.875 12C5.875 10.3755 6.52031 8.81763 7.66897 7.66897C8.81763 6.52031 10.3755 5.875 12 5.875ZM12 10.5417C12.3868 10.5417 12.7577 10.6953 13.0312 10.9688C13.3047 11.2423 13.4583 11.6132 13.4583 12C13.4583 12.3868 13.3047 12.7577 13.0312 13.0312C12.7577 13.3047 12.3868 13.4583 12 13.4583C11.6132 13.4583 11.2423 13.3047 10.9688 13.0312C10.6953 12.7577 10.5417 12.3868 10.5417 12C10.5417 11.6132 10.6953 11.2423 10.9688 10.9688C11.2423 10.6953 11.6132 10.5417 12 10.5417Z"
      fill="white"
    />
  </svg>,
  'PodcastSource'
);
