import { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { TJobStatus } from '../types';
import { filterLabels } from '../../helpers/formatted-messages';
import { filterStyles } from '../../helpers/shared-styles';
import { AnyAction } from 'redux';

type TProps = {
  entity: 'job' | 'task';
  status: TJobStatus[] | null;
  setFilterValue: (args: { filter: 'status'; value: TJobStatus[] }) => void;
  filterLabel?: string;
  defaultPlaceholder?: string;
  clearBtnLabel?: string;
  clearAndApply?: () => void;
};

export const StatusFilter = ({
  entity,
  status,
  setFilterValue,
  filterLabel,
  defaultPlaceholder,
  clearBtnLabel,
  clearAndApply,
}: TProps) => {
  const STATUSES: TJobStatus[] =
    entity === 'job'
      ? ['pending', 'running', 'complete', 'cancelled']
      : [
          'pending',
          'running',
          'complete',
          'queued',
          'accepted',
          'failed',
          'cancelled',
          'standby_pending',
          'waiting',
          'resuming',
          'aborted',
          'paused',
        ];
  const [selectedValues, setSelectedValues] = useState<TJobStatus[]>([]);
  const { classes } = filterStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { statusFilterLabel, allLabel, clearButtonLabel } = filterLabels(intl);

  useEffect(() => {
    setSelectedValues(previousValues => {
      if ((previousValues.length === 0 && status === null) || previousValues.length === status?.length) {
        return previousValues;
      }
      return status || [];
    });
  }, [status]);

  const handleChange = (event: SelectChangeEvent<TJobStatus[]>) => {
    setSelectedValues(event.target.value as TJobStatus[]);
  };

  const handleClose = () => {
    dispatch(
      setFilterValue({
        filter: 'status',
        value: selectedValues,
      }) as unknown as AnyAction
    );
  };

  const handleClear = () => {
    setSelectedValues([]);
    dispatch(
      setFilterValue({
        filter: 'status',
        value: [],
      }) as unknown as AnyAction
    );
    if (clearAndApply) {
      clearAndApply();
    }
  };

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return <div>{defaultPlaceholder ? defaultPlaceholder : allLabel}</div>;
    }
    const { length } = selected;
    return (
      <Tooltip title={selected.join(', ')} className={classes.title}>
        <span className={classes.title}>{length === 1 ? selected[0] : `${length} Statuses`}</span>
      </Tooltip>
    );
  };

  return (
    <FormControl size="small" className={classes.formControl}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <label className={classes.label}>{filterLabel ? filterLabel : statusFilterLabel}</label>
        {selectedValues.length > 0 && (
          <span onClick={handleClear} className={classes.clearButton}>
            {clearBtnLabel ? clearBtnLabel : clearButtonLabel}
          </span>
        )}
      </Box>
      <Select
        multiple
        displayEmpty
        value={selectedValues}
        onChange={handleChange}
        onClose={handleClose}
        variant="outlined"
        renderValue={renderValue}
        className={classes.selectField}
        data-test={`${entity}s-filter-status-input`}
      >
        {STATUSES.map(status => (
          <MenuItem
            key={status}
            value={status}
            data-test={`${entity}s-filter-status-input-option`}
            data-value={status}
          >
            {status.replace('_', ' ')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
