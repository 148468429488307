import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { FILE_INFO_TABS } from '../../helpers/shared-text';

const tabsList = [
  {
    label: FILE_INFO_TABS.fileInfo,
    disabled: false,
    id: 'File Info',
  },
];

const betaTabsList = [
  {
    label: FILE_INFO_TABS.contentTemplates,
    disabled: false,
    id: 'Content Templates',
  },
  {
    label: FILE_INFO_TABS.activity,
    disabled: false,
    id: 'Activity',
  },
];

const useStyles = makeStyles()(_theme => ({
  container: { borderBottom: '1px solid #D5DFE9', padding: '10px 25px 0px' },
  tabWrapper: {
    '& p': {
      margin: 0,
    },
  },
}));

const SlimTabs = styled(Tabs)({
  borderBottom: 'none',
  minHeight: '30px',
  '& .Sdk-MuiTabs-flexContainer': {
    justifyContent: 'space-between',
  },
  '& .Sdk-MuiTabs-indicator': {
    backgroundColor: '#000',
  },
});

const SlimTab = styled(Tab)({
  color: '#5C6269',
  fontWeight: 400,
  minHeight: '40px',
  padding: 0,
  '&.Sdk-Mui-selected': {
    color: '#2A323C',
  },
});

export const InfoTabs = ({
  onChange,
  selectedTabId,
  isBetaFeaturesEnabled,
}: {
  onChange: (_event: React.SyntheticEvent, newValue: number) => void;
  selectedTabId: number;
  isBetaFeaturesEnabled: boolean;
}) => {
  const { classes } = useStyles();
  const tabs = isBetaFeaturesEnabled ? [...tabsList, ...betaTabsList] : tabsList;

  return tabs.length > 1 ? (
    <div className={classes.container}>
      <SlimTabs value={selectedTabId} onChange={onChange} variant={'standard'} centered>
        {tabs.map(item => (
          <SlimTab key={item.id} className={classes.tabWrapper} label={item.label} />
        ))}
      </SlimTabs>
    </div>
  ) : null;
};
