import { FunctionComponent, useEffect, useRef, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ActionPanel from '../shared/ActionPanel';
import { actions } from '../../redux/slices';
import { selectEditFolder } from '../../redux/selectors/panel-edit-folder';
import { selectFolderEntities } from '../../redux/selectors/entities';
import { SHARED_TEXT } from '../../helpers/shared-text';

const useStyles = makeStyles()((theme: Theme) => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
}));

const EditFolder: FunctionComponent<{
  className: string;
}> = props => {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>();
  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const folderEntities = useSelector(selectFolderEntities);
  const editFolderPanel = useSelector(selectEditFolder);
  const { folderId, status } = editFolderPanel;

  useEffect(() => {
    if (folderId && isActive) {
      inputRef.current && inputRef.current.focus();

      if (status === 'failure') {
        setError('Error editing folder.  Please try again later.');
      }

      if (status === 'idle') {
        setName(folderEntities[folderId]!.name);
      }
    } else {
      setName('');
      setError('');
    }
  }, [folderEntities, folderId, status, isActive]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (name && editFolderPanel.folderId) {
      dispatch(
        actions.panelEditFolder.editFolderStart({
          id: editFolderPanel.folderId,
          name,
        })
      );
    } else {
      setError('Name required');
    }
  };

  const handleClose = () => {
    dispatch(actions.panelEditFolder.closeEditFolder());
  };

  return (
    <ActionPanel
      isOpen={!!folderId}
      panelTitle={SHARED_TEXT.editFolder()}
      onClose={handleClose}
      setIsActive={setIsActive}
      {...props}
    >
      <>
        {isActive && (
          <form
            autoComplete="off"
            noValidate
            className={classes.formRoot}
            data-test="dc-edit-folder-form"
            onSubmit={handleSubmit}
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              error={!!error}
              id="editFolderName"
              label={SHARED_TEXT.folderName()}
              variant="outlined"
              value={name}
              onChange={e => {
                setName(e.target.value);
                setError('');
              }}
              inputRef={inputRef}
              helperText={error}
            />

            <div className={classes.buttonGroup}>
              <Button onClick={handleClose}>{SHARED_TEXT.cancel()}</Button>
              <Button variant="contained" color="primary" type="submit" disabled={status === 'pending'}>
                {SHARED_TEXT.submit()}
              </Button>
            </div>
          </form>
        )}
      </>
    </ActionPanel>
  );
};

export default EditFolder;
