import { NewFolder } from '@aiware/shared/icons';
import { actions } from '../redux/slices';
import { EntityTypesWithActions, EntityAction } from '../types';
import { EntityType } from '@aiware/js/interfaces';
import { EPermissionAction } from '@aiware/shared/permissions';
import { SHARED_TEXT } from '../helpers/shared-text';

export const ENTITY_TOOLBAR_ACTIONS: Record<EntityTypesWithActions, EntityAction[]> = {
  [EntityType.Folders]: [
    {
      name: SHARED_TEXT.newFolder,
      testLabel: 'new-folder',
      description: 'Add New Folder',
      action: actions.panelAddFolder.openAddNewFolder,
      Icon: NewFolder,
      permissionRequired: EPermissionAction.Update,
    },
  ],
  [EntityType.Tdos]: [],
  [EntityType.Sources]: [],
  [EntityType.Schedules]: [],
};
