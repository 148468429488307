import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  appNoti: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4.4),
    overflowY: 'scroll',
    height: '100%',
  },
}));
