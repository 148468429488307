import { combineReducers } from 'redux';

import userReducer, { namespace as userNamespace } from './modules/user';
import applicationReducer, {
  namespace as applicationNamespace,
} from './modules/application';
import activityReducer, {
  namespace as activityNamespace,
} from './modules/activity';

const rootReducer = combineReducers({
  [userNamespace]: userReducer,
  [activityNamespace]: activityReducer,
  [applicationNamespace]: applicationReducer,
});
export const nameSpace = 'aiWareHeaderBarPanel';
// export default rootReducer;
