import axios from 'axios';
import { TFileSearchResponse, Entity, TFileSearchFaceResponse, IFilter } from '../../types/aiSearch.types';
import { getTdos } from '../../../../api/tdos';
import { ECapabilityTypes, CapabilityType } from '../../types/aiAutocomplete.types';
import { queryBuilder } from '../../helpers/aiSearch.helpers';
import { AiSearchFilterType } from '../../state/aiSearchFilter/aiSearchFilter.slice';
import { IStructuredDataInput } from '../../types/aiStructuredData.types';

interface IGetFilesProps {
  endpoint: string;
  token: string;
  text: string;
  entity: Entity;
  entityId?: string;
  searchFolder: string;
  isRootFolder: boolean;
  graphQLEndpoint: string;
  capabilityType: CapabilityType;
  offset: number;
  limit: number;
  uniqueTdos: string[];
  filters?: AiSearchFilterType;
  structuredData: IStructuredDataInput;
}

export const getFiles = async ({
  endpoint,
  token,
  text,
  entity,
  entityId,
  searchFolder,
  isRootFolder,
  graphQLEndpoint,
  capabilityType,
  offset = 0,
  limit = 100,
  uniqueTdos,
  structuredData,
  filters,
}: IGetFilesProps) => {
  if (!searchFolder) throw new Error('Search Error: location not specified');
  const payload = {
    offset,
    limit,
    index: ['mine'],
    aggregate: [],
    select: ['veritone-job', 'veritone-file'],
    query: queryBuilder(
      searchFolder,
      isRootFolder,
      entity,
      text,
      capabilityType,
      entityId,
      structuredData,
      filters
    ),
  };
  const isFaceQuery = capabilityType === ECapabilityTypes.facial;
  const endpointPath = `${isFaceQuery ? '' : '/file_search_authtoken'}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  let tdos: string[];
  let hasMore = false;
  let totalResults = 0;
  const updateHasMore = (value: boolean) => {
    hasMore = value;
  };

  /** The Response Type from the two API endpoints is different, hence
   * the need for a conditional check here. */
  if (isFaceQuery) {
    const result = await axios
      .post(`${endpoint}/api/search${endpointPath}`, payload, {
        headers,
      })
      .then(response => response.data as unknown as TFileSearchFaceResponse);

    if (!result.results.length) return {};

    totalResults = result.totalResults;

    tdos = [
      ...result.results
        .map(record => {
          return record?.recording?.recordingId;
        })
        .filter(record => !!record),
      ...uniqueTdos,
    ];
    tdos = [...new Set(tdos)];

    updateHasMore(result.results.length === limit);
  } else {
    const result = await axios
      .post(`${endpoint}/api/search${endpointPath}`, payload, {
        headers,
      })
      .then(response => response.data as unknown as TFileSearchResponse);

    if (!result.records.length) return {};

    tdos = result.records.map(record => record?.mediaId?.toString()).filter(record => !!record);

    totalResults = result.totalRecords;

    updateHasMore(result.records.length === limit);
  }

  const fileSearchResponse = await getTdos({ graphQLEndpoint, token, apiRoot: endpoint }, tdos);

  // TODO: figure out better error handling later
  // if (fileSearchResponse.errors && fileSearchResponse.errors.length) {
  //   throw new Error('File search failed');
  // }

  /* The max offset limit from GraphQL is 10,000,
  /* so we won't keep fetching after this */
  if (offset === 9900) {
    hasMore = false;
  }

  return { foundFiles: fileSearchResponse, hasMore, uniqueTdos: tdos, totalResults };
};
