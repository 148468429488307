import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  buttonPreview: {
    buttonPreview: () => (
      <FormattedMessage
        id="instance-admin-center.instance-login.button-preview-heading"
        defaultMessage="Button Preview"
        description="Button Preview heading"
      />
    ),
    buttonPreviewDescription: () => (
      <FormattedMessage
        id="instance-admin-center.instance-login.button-preview-text"
        defaultMessage="Set the look and feel of your login button for your users."
        description="Button Preview text description"
      />
    ),
    resetToDefault: () => (
      <FormattedMessage
        id="customize-button-reset-to-default"
        defaultMessage="Reset to Default"
        description="Customize Colors reset to default"
      />
    ),
    continueButton: () => (
      <FormattedMessage
        id="instance-admin-center.instance-login.show-me.modal-continue-button"
        defaultMessage="Continue"
        description="Instance Login show me modal continue button"
      />
    ),
  },
};
