import { ReactNode } from 'react';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import isNil from 'lodash/isNil';

import { renderErrorMessage } from '../utils/render-error-message';

interface InputSelectProps extends Omit<SelectProps, 'name' | 'onChange'> {
  // weird react hook form type quirk for name prop
  name: `${string}`;
  disabled?: boolean;
  rules?: ControllerProps['rules'];
  variant?: 'outlined' | 'standard' | 'filled';
  className?: string;
  children: ReactNode;
  onChange?: (event: SelectChangeEvent<HTMLSelectElement> | undefined) => void;
  callFieldOnChange?: boolean;
  fullWidth?: boolean;
}

const InputSelect = ({
  disabled,
  name,
  rules,
  variant = 'outlined',
  children,
  className,
  callFieldOnChange = true,
  onChange,
  fullWidth = false,
  ...inputAttrs
}: InputSelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          disabled={disabled}
          className={className}
          variant={variant}
          error={!isNil(error)}
          fullWidth={fullWidth}
        >
          <Select
            {...field}
            {...inputAttrs}
            onChange={event => {
              callFieldOnChange && field.onChange(event);
              onChange?.(event as any);
            }}
          >
            {children}
          </Select>
          {error && rules && <FormHelperText>{renderErrorMessage(error)}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default InputSelect;
