import { DataCenterState, namespace } from '../slices';
import { selectPanelByPanelName } from '@aiware/js/panel';

export const selectUIStateStatus = (state: DataCenterState) => state[namespace].uiState.status;

export const selectCurrentView = (state: DataCenterState) => state[namespace].uiState.currentView;

export const selectIsFluffy = (state: DataCenterState) => state[namespace].uiState.isFluffy;

export const selectIsInfoOpen = (state: DataCenterState) => state[namespace].uiState.isInfoOpen;

export const selectTableDataSettings = (state: DataCenterState) => state[namespace].uiState.tableDataSettings;

export const selectCurrentTableScrollPosition = (state: DataCenterState) =>
  state[namespace].uiState.currentTableScrollPosition;

export const selectPanelMode = (state: DataCenterState) => {
  return !!selectPanelByPanelName('DATA_CENTER_FOLDERS_AND_FILES')(state);
};

export const selectIsItemsDisabled = (state: DataCenterState) => {
  const isPanelMode = !!selectPanelByPanelName('DATA_CENTER_FOLDERS_AND_FILES')(state);

  if (!isPanelMode) return isPanelMode;

  return state[namespace]?.uiState?.panelAllowTdoSelection === 'none';
};

export const selectIsFilePickerMode = (state: DataCenterState) => {
  return state[namespace]?.uiState?.panelAllowTdoSelection !== 'none';
};

export const selectFilePickerFiles = (state: DataCenterState) => {
  return state[namespace]?.uiState?.filePickerFiles;
};

export const selectSearchValue = (state: DataCenterState) => state[namespace]?.uiState?.searchValue;

export const selectActiveTab = (state: DataCenterState) => state[namespace]?.uiState?.activeTab;
