import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { GeneratedSingleState, GeneratedState, ISlice, ISingleSlice, DeepPartial } from './types';

type SetActionType<T> = T extends Record<string, unknown> ? DeepPartial<T> : T;

export interface GeneratedSlice<T> {
  slice: ISlice<T>;
  initialState: GeneratedState<T>;
}

export interface GeneratedSingleSlice<T, A extends Record<string, unknown>> {
  slice: ISingleSlice<T, A>;
  initialState: GeneratedSingleState<T, A>;
}

export const generateSlice = <T, U = void>(props: {
  sliceName: string;
  namespace: string;
  dedupeResponse?: boolean;
  dedupeIdProp?: string;
  customReduxNamespace?: string;
}): GeneratedSlice<T> => {
  const { sliceName, namespace, dedupeResponse, customReduxNamespace } = props;
  const dedupeIdProp = props.dedupeIdProp || 'id';

  const initialState: GeneratedState<T> = {
    createStatus: 'idle',
    readStatus: 'idle',
    updateStatus: 'idle',
    deleteStatus: 'idle',
    currentOffset: 0,
    hasMore: true,
    items: [],
    filters: [],
    searchValue: '',
  };
  const slice = createSlice({
    name: (customReduxNamespace || `${sliceName}/${namespace}`) as string,
    initialState,
    reducers: {
      createStart(state, _action: PayloadAction<T>) {
        state.createStatus = 'pending';
      },
      createSucceeded(state, action: PayloadAction<T>) {
        state.items.push(action.payload as Draft<T>);
        state.createStatus = 'idle';
      },
      createFailed(state) {
        state.createStatus = 'failure';
      },
      readStart(state) {
        state.readStatus = 'pending';
      },
      readSucceeded(state, action: PayloadAction<T[]>) {
        action.payload.forEach(newItem => {
          if (dedupeResponse) {
            const existingIndex = state.items.findIndex(
              existingItem => (newItem as any)[dedupeIdProp] === (existingItem as any)[dedupeIdProp]
            );

            if (existingIndex === -1) {
              state.items.push(newItem as Draft<T>);
            } else {
              state.items[existingIndex] = newItem as Draft<T>;
            }
          } else {
            state.items.push(newItem as Draft<T>);
          }
        });
        state.readStatus = 'idle';
      },
      readFailed(state) {
        state.readStatus = 'failure';
      },
      updateStart(state, _action: PayloadAction<T>) {
        state.updateStatus = 'pending';
      },
      updateSucceeded(state, action: PayloadAction<T>) {
        // Try our best to replace the current item if we can find it
        const existingIndex = state.items.findIndex(
          existingItem => (action.payload as any)[dedupeIdProp] === (existingItem as any)[dedupeIdProp]
        );

        if (existingIndex !== -1) {
          state.items[existingIndex] = {
            ...state.items[existingIndex],
            ...action.payload,
          } as Draft<T>;
        } else {
          console.warn("Couldn't replace existing item with updated version in redux state", action.payload);
        }

        state.updateStatus = 'success';
      },
      updateFailed(state) {
        state.updateStatus = 'failure';
      },
      updateResetStatus(state) {
        state.updateStatus = 'idle';
      },
      deleteStart(state, _action: PayloadAction<T>) {
        state.deleteStatus = 'pending';
      },
      deleteSucceeded(state, action: PayloadAction<T>) {
        // Try our best to delete the current item if we can find it
        const existingIndex = state.items.findIndex(
          existingItem => (action.payload as any)[dedupeIdProp] === (existingItem as any)[dedupeIdProp]
        );

        if (existingIndex !== -1) {
          state.items.splice(existingIndex, 1);
        } else {
          console.warn("Couldn't remove deleted item from redux state", action.payload);
        }
        state.deleteStatus = 'success';
      },
      deleteFailed(state) {
        state.deleteStatus = 'failure';
      },
      deleteResetStatus(state) {
        state.deleteStatus = 'idle';
      },
      setHasMore(state, action: PayloadAction<boolean>) {
        state.hasMore = action.payload;
      },
      setOffset(state, action: PayloadAction<number>) {
        state.currentOffset = action.payload;
      },
      resetList(state) {
        state.items = [];
        state.currentOffset = 0;
        state.hasMore = true;
      },
      setFilter(state, action: PayloadAction<{ type: U; values: any[] }>) {
        // See if a filter exists
        const existingIndex = state.filters.findIndex(
          filter => filter['type' as keyof typeof filter] === action.payload.type
        );
        if (existingIndex === -1) {
          state.filters.push(action.payload as (typeof state.filters)[any]);
        } else {
          // see if we should clear the filter
          if (action.payload.values.length === 0) {
            // New value was empty array, clear the filter
            state.filters.splice(existingIndex, 1);
          } else {
            // Update filter value
            (state.filters[existingIndex] as any).values = action.payload.values;
            // type assertion
          }
        }
      },
      clearFilters(state) {
        state.filters = [];
      },
      setSearchValue(state, action: PayloadAction<string>) {
        state.searchValue = action.payload;
      },
      clearSearchValue(state) {
        state.searchValue = '';
      },
    },
  });

  return {
    slice,
    initialState,
  };
};

const createSingleInitialState = <T, A extends Record<string, unknown>>(
  additionalState: A
): GeneratedSingleState<T, A> => ({
  ...additionalState,
  createStatus: 'idle',
  readStatus: 'idle',
  updateStatus: 'idle',
  deleteStatus: 'idle',
  item: {} as T,
});

export const generateSingleSlice = <T = unknown, A extends Record<string, unknown> = Record<string, never>>({
  sliceName,
  namespace,
  customReduxNamespace,
  additionalState,
}: {
  sliceName: string;
  namespace: string;
  customReduxNamespace?: string;
  additionalState: A;
}): GeneratedSingleSlice<T, A> => {
  const initialState = createSingleInitialState<T, A>(additionalState);

  const generateDynamicReducers = (obj: Record<string, unknown>, prefix = ''): Record<string, any> => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const fullPath = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'object' && value !== null) {
        Object.assign(acc, generateDynamicReducers(value as Record<string, unknown>, fullPath));
      }

      const capitalizedPath = fullPath
        .split('.')
        .map((part, index) => (index === 0 ? part.charAt(0).toUpperCase() + part.slice(1) : part))
        .join('.');

      // @ts-ignore
      acc[`set${capitalizedPath}`] = (
        state: Draft<GeneratedSingleState<T, A>>,
        action: PayloadAction<SetActionType<typeof value>>
      ) => {
        updateNestedState(state, fullPath, action.payload);
      };

      acc[`reset${capitalizedPath}`] = (state: Draft<GeneratedSingleState<T, A>>) => {
        updateNestedState(state, fullPath, (additionalState as any)[fullPath]);
      };

      return acc;
    }, {} as Record<string, (state: Draft<GeneratedSingleState<T, A>>, action?: PayloadAction<any>) => void>);
  };

  const updateNestedState = (state: any, path: string, value: any) => {
    const pathParts = path.split('.');
    let current = state;
    for (let i = 0; i < pathParts.length - 1; i++) {
      if (!current[pathParts[i]!]) {
        current[pathParts[i]!] = {};
      }
      current = current[pathParts[i]!];
    }
    const lastPart = pathParts[pathParts.length - 1];
    if (typeof value === 'object' && value !== null) {
      current[lastPart!] = { ...current[lastPart!], ...value };
    } else {
      current[lastPart!] = value;
    }
  };

  const dynamicReducers = generateDynamicReducers(additionalState);

  const slice = createSlice({
    name: customReduxNamespace || `${sliceName}/${namespace}`,
    initialState,
    reducers: {
      createStart: state => {
        state.createStatus = 'pending';
      },
      createSucceeded: (state, action: PayloadAction<T>) => {
        state.item = action.payload as Draft<T>;
        state.createStatus = 'success';
      },
      createFailed: state => {
        state.createStatus = 'failure';
      },
      readStart: state => {
        state.readStatus = 'pending';
      },
      readSucceeded: (state, action: PayloadAction<T>) => {
        state.item = action.payload as Draft<T>;
        state.readStatus = 'success';
      },
      readFailed: state => {
        state.readStatus = 'failure';
      },
      readResetStatus: state => {
        state.readStatus = 'idle';
      },
      updateStart: state => {
        state.updateStatus = 'pending';
      },
      updateSucceeded: (state, action: PayloadAction<DeepPartial<T>>) => {
        state.item = { ...state.item, ...action.payload } as Draft<T>;
        state.updateStatus = 'success';
      },
      updateFailed: state => {
        state.updateStatus = 'failure';
      },
      updateResetStatus: state => {
        state.updateStatus = 'idle';
      },
      resetCreateStatus: state => {
        state.createStatus = 'idle';
      },
      deleteStart: state => {
        state.deleteStatus = 'pending';
      },
      deleteSucceeded: state => {
        state.item = {} as Draft<T>;
        state.deleteStatus = 'success';
      },
      deleteFailed: state => {
        state.deleteStatus = 'failure';
      },
      deleteResetStatus: state => {
        state.deleteStatus = 'idle';
      },
      resetItem: state => {
        state.item = {} as Draft<T>;
      },
      resetInitalState: state => {
        Object.assign(state, initialState);
      },
      ...dynamicReducers,
    },
  });

  return {
    slice: slice as unknown as ISingleSlice<T, A>,
    initialState,
  };
};

export default generateSlice;
