import createSvgIcon from '../createSvgIcon';
export const TopQueue = createSvgIcon(
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8335 14.4165H19.1668V12.5832H11.8335V14.4165ZM11.8335 9.37484H19.1668V7.5415H11.8335V9.37484ZM11.8335 4.33317H19.1668V2.49984H11.8335V4.33317ZM0.833496 8.45817C0.833496 5.1765 3.51016 2.49984 6.79183 2.49984H7.25016V0.666504L10.0002 3.4165L7.25016 6.1665V4.33317H6.79183C4.50016 4.33317 2.66683 6.1665 2.66683 8.45817C2.66683 10.7498 4.50016 12.5832 6.79183 12.5832H10.0002V14.4165H6.79183C3.51016 14.4165 0.833496 11.7398 0.833496 8.45817Z"
      fill="#48516A"
    />
  </svg>,
  'TopQueue'
);
