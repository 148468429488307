import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';

import { selectApiConfigs } from '../../redux/selectors';
import { selectUIStateStatus } from '../../redux/selectors/ui-state';
import { actions } from '../../redux/slices';
import { getLibraryModule } from '@aiware/ui';
import { getLibraryIdsActions } from '../Search/state/getLibraryIds/getLibraryIds.slice';
import { getAdminCenterPermissionsModule } from '@aiware/os/admin-center/permissions';

import MainLayout from './MainLayout';
import { getDataCenterBrowseModule } from '../../redux';

export const Content = (props: { dataId: string; panelRendered: boolean; hidePanel?: () => void }) => {
  const dispatch = useDispatch();
  const uiStateStatus = useSelector(selectUIStateStatus);
  const { apiRoot, graphQLEndpoint, token } = useSelector(selectApiConfigs);

  useEffect(() => {
    if (uiStateStatus === 'pending' && apiRoot && graphQLEndpoint && token) {
      dispatch(actions.uiState.init());
      dispatch(getLibraryIdsActions.getLibraryIdsStart());
    }
  }, [uiStateStatus, apiRoot, graphQLEndpoint, token, dispatch]);

  if (!apiRoot || !graphQLEndpoint || !token || !props.panelRendered) {
    return null;
  }

  return <MainLayout {...props} />;
};

export const DataCenterBrowse = (props: {
  dataId: string;
  panelRendered: boolean;
  hidePanel?: () => void;
}) => {
  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader
        modules={[getDataCenterBrowseModule(), getLibraryModule(), getAdminCenterPermissionsModule()]}
      >
        <AIWareIntlProvider>
          <Content {...props} />
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
};

export default DataCenterBrowse;
