import { TGraphQLPage, IUser } from '@aiware/js/interfaces';

type TSchemaAction = 'view' | 'edit' | 'publish' | 'deactivate' | 'delete';

type TSchemaStatus = 'published' | 'deleted' | 'draft' | 'inactive';

type TJson = {
  [key: string]: string | number | boolean | TJson;
};

export type TStructuredDataObject = {
  id: string;
  schemaId: string;
  schema: TSchema;
  data: TJson;
  dataString: string;
};

export type TSchema = {
  id: string;
  definition: {
    type: string;
    title: string;
    required: string[];
    properties: { email: { type: string }; userName: { type: string } };
    description: string;
  };
  majorVersion: number;
  minorVersion: number;
  status: string;
  validActions: string[];
  organizationId: string;
};

export type TOrganizationInfo = {
  id: string;
  name: string;
  guid: string;
};

export type TDataRegistry = {
  id: string;
  name: string;
  description: string;
  source: string;
  schemas?: TGraphQLPage<TSchema>;
  organizationId: string;
  organization?: TOrganizationInfo;
  createdBy: IUser;
  modifiedBy: IUser;
  createdDateTime: string;
  modifiedDateTime: string;
  publishedSchema?: TSchema;
  ingestionToken: string;
  isPublic: boolean;
};

export const PAGE_LIMIT = 60;

export type TDataRegistriesQueryInput = {
  id?: string;
  ids?: string[];
  name?: string;
  nameMatch?: 'startsWith' | 'endsWith' | 'contains' | 'exact';
  offset?: number;
  limit?: number;
  orderBy?: 'name' | 'source' | 'createdDateTime' | 'modifiedDateTime';
  orderDirection?: 'asc' | 'desc';
  filterByOwnership?: 'mine' | 'all' | 'others';
};

export const queryInputGqlTyping = {
  id: 'ID',
  ids: '[ID!]',
  name: 'String',
  nameMatch: 'StringMatch',
  offset: 'Int',
  limit: 'Int',
  orderBy: 'DataRegistryOrderBy',
  orderDirection: 'OrderDirection',
  filterByOwnership: 'SchemaOwnership',
  schemaId: 'String',
};

export type TSDOsPagination = {
  hasMore: boolean;
  limit: number;
  offset: number;
};
