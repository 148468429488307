import { CSSProperties } from 'react';
import { Stack, StackProps } from '@mui/material';

export type TCenterTabContentProps = {
  testId: string;
  height?: string;
  children?: React.ReactNode;
  sx?: CSSProperties;
  StackProps?: StackProps;
};

export const CenterTabContent: React.FC<TCenterTabContentProps> = ({
  children,
  testId,
  height,
  sx = {},
  StackProps = {},
}) => {
  const styles = {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    height: height || '100%',
  };

  return (
    <Stack
      sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]}
      {...StackProps}
      data-testid={`${testId}-center-tab-content`}
    >
      {children}
    </Stack>
  );
};
