import { makeStyles } from 'tss-react/mui';

export const useEmptySearchStyles = makeStyles<void>()((_theme, _props) => ({
  root: {
    paddingTop: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  emptyStateIcon: {},
}));
