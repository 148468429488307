import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { InfiniteWrapperItemType } from '../../types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDispatch, useSelector } from 'react-redux';
import FoldersAndFilesRowGen from '../components/my-files-view/FoldersAndFilesRowGen';
import SourcesSchedulesTdoRowGen from '../components/sources-view/SourcesSchedulesTdoRowGen';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { selectIsFluffy, selectPanelMode } from '../../redux/selectors/ui-state';
import { debounce } from 'lodash';
import { LoadingStatus, EntityType } from '@aiware/js/interfaces';

const useStyles = makeStyles()(theme => ({
  errorContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  row: {
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#EEF3F9',
      '& #engineIconContainer': {
        background: '#fff',
      },
    },
  },
  loader: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const InfiniteWrapper: FunctionComponent<{
  id: string;
  items: InfiniteWrapperItemType;
  hasNextPage: boolean | undefined;
  itemSize: number;
  loadAction: { payload: string | undefined; type: string };
  status: LoadingStatus | undefined;
  rowType: EntityType;
  parentEntityId?: string;
  heightAdjust?: number;
  dataCenterView?: boolean;
}> = ({
  id,
  items,
  hasNextPage,
  itemSize,
  loadAction,
  status,
  rowType,
  parentEntityId,
  heightAdjust = 0,
  dataCenterView = false,
}) => {
  const dispatch = useDispatch();
  const listRef = useRef<any>(null);
  const { classes } = useStyles();
  const [scrollIndex, setScrollIndex] = useState(0);

  const loadNextPage = () => {
    dispatch(loadAction);
  };
  const panelMode = useSelector(selectPanelMode);
  const isFluffy = useSelector(selectIsFluffy);

  const initialScrollOffset = 0;

  const handleScrollIndexChange = (value: number) => {
    setScrollIndex(value);
  };

  const debouncedHandleScrollIndexChange = debounce(handleScrollIndexChange, 500);

  const additionalOnItemsRendered = (index: number) => {
    if (!isNextPageLoading) {
      debouncedHandleScrollIndexChange(index);
    }
  };

  useEffect(() => {
    let newIndex = scrollIndex;
    let i = 0;
    let fluffyFolders = 0;
    let fluffyFolderRows = 0;
    if (scrollIndex !== 0) {
      if (rowType === EntityType.Folders && isFluffy && !panelMode) {
        while (Array.isArray(items[i])) {
          // @ts-ignore
          fluffyFolders = fluffyFolders + items[i].length;
          fluffyFolderRows++;
          i++;
        }
        newIndex = newIndex - (fluffyFolders - fluffyFolderRows);
        setScrollIndex(newIndex);
      }
      if (rowType === EntityType.Folders && !isFluffy && !panelMode) {
        // @ts-ignore
        fluffyFolders = items.filter(x => x.type === 'folder').length;
        fluffyFolderRows = Math.ceil(fluffyFolders / 6);
        newIndex = newIndex + (fluffyFolders - fluffyFolderRows);
        setScrollIndex(newIndex);
      }
    }
    setTimeout(() => {
      return listRef && listRef.current?._listRef.scrollToItem(newIndex, 'start');
    }, 0);
  }, [itemSize]);

  const isNextPageLoading = status === 'pending';

  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  const loadMoreItems = isNextPageLoading
    ? () => {
        /** */
      }
    : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Row: FunctionComponent<ListChildComponentProps> = ({ index, style }) => {
    let rowItem;

    if (!isItemLoaded(index) && status === 'failure') {
      rowItem = (
        <div data-test={'table-error-row'} className={classes.row}>
          <div className={classes.errorContainer}>
            <Button
              data-test={'table-error-row-button-retry'}
              onClick={loadNextPage}
              color="secondary"
              variant="contained"
            >
              {SHARED_TEXT.tableRowErrorButtonRetry()}
            </Button>
          </div>
        </div>
      );
    } else if (!isItemLoaded(index) && status !== 'failure') {
      rowItem = (
        <div data-test={'table-row-loader'} className={classes.loader}>
          {!panelMode && <CircularProgress size={36} />}
        </div>
      );
    } else {
      rowItem =
        rowType === EntityType.Folders ? (
          <FoldersAndFilesRowGen
            index={index}
            items={items}
            rowHeight={itemSize}
            dataCenterView={dataCenterView}
          />
        ) : (
          <SourcesSchedulesTdoRowGen index={index} items={items} rowHeight={itemSize} rowType={rowType} />
        );
    }

    return (
      <div key={index} style={style}>
        {rowItem}
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => {
        return (
          <InfiniteLoader
            ref={listRef}
            key={`${parentEntityId}_${id}`}
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <div>
                <List
                  height={height - 40 + heightAdjust}
                  itemCount={itemCount}
                  itemSize={itemSize}
                  initialScrollOffset={initialScrollOffset}
                  onItemsRendered={props => {
                    onItemsRendered(props);
                    additionalOnItemsRendered(props.visibleStartIndex);
                  }}
                  ref={ref}
                  width={width}
                >
                  {Row}
                </List>
              </div>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default InfiniteWrapper;
