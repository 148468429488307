import { EmptyStateIcon } from './emptyStateIcon';
import { useEmptySearchStyles } from './useStyles';

export const AiSearchResultsCleared = () => {
  const { classes } = useEmptySearchStyles();
  return (
    <div className={classes.root}>
      <EmptyStateIcon className={classes.emptyStateIcon} />
    </div>
  );
};
