import { put, select } from 'redux-saga/effects';
import { query } from '../../api';
import { ISchema, ISchemas, ISchemaVersion } from '../../types';
import { selectApiConfigs } from '../../redux';
import { actions } from '../../redux';

export function* fetchSchemasSaga(action: ReturnType<typeof actions.fetchSchemaStart>) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const results: [ISchema] = yield query.getSchemas(apiConfigs);

    const allSchemas: ISchemas = {};
    const currentSchema: any[] = [];

    results.forEach((schemaItem: ISchema) => {
      const name = schemaItem.name;
      // we want to also build a list of most current published version of each data registry item for the ui
      let currentVersionNumber = 0;
      let schemaID = '';

      schemaItem.schemas?.records?.forEach((schema: ISchemaVersion) => {
        // only add schema that is published
        if (schema.status === 'published') {
          const version = Number(schema.majorVersion + '.' + schema.minorVersion);
          // if the version is greater and published - then update
          if (version > currentVersionNumber) {
            currentVersionNumber = version;
            schemaID = schema.id;
          }

          allSchemas[schema.id] = { ...schema, ...{ name: name } };
        }
      });

      // push version to current list
      if (schemaID) {
        currentSchema.push({ id: schemaID, name: name });
      }
    });

    yield put(actions.fetchSchemaSuccess({ schemas: allSchemas, current: currentSchema }));
  } catch (e) {
    yield put(actions.fetchSchemaFailed);
    console.log('Problem fetching source', e);
  }
}
