import { generateState, GeneratedState } from '@aiware/shared/reusable-utils';
import * as api from '../api';
import { SLICE_NAME, ISchema } from '../../../types';
import * as aiStructuredData from './aiStructuredData/aiStructuredData.selectors';
export const namespace = 'schemaSearch';

export const { slice, selectors, sagas, initialState } = generateState<ISchema>({
  sliceName: SLICE_NAME,
  namespace,
  dedupeResponse: true,
  apiCalls: {
    read: api.query.getSchemas,
    pageSize: 50,
    getAdditionalSelectors: () => ({
      schemaSearchValue: aiStructuredData.selectSchemaSearchValue,
    }),
  },
});

export type State = GeneratedState<ISchema>;
