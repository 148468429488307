import { LoadingStatus } from '@aiware/js/interfaces';
import * as actions from './action';
import { IRole } from '../../../types';
import { ICurrentUser } from '../../../components/organisms/SettingPanel/PersonalProfile';

export interface UserState {
  currentUser: ICurrentUser;
  status: LoadingStatus;
  authStatus: actions.AuthStatus;
  hubRole?: IRole;
  loading?: string;
}

const initialState = {
  currentUser: {},
  status: 'idle',
  authStatus: actions.AuthStatus.AUTHENTICATED,
};

export function userReducer(state: UserState, action: { type: string; payload: unknown }) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  if (action.type === actions.GET_CURRENT_USER_SUCCESS) {
    return {
      ...state,
      currentUser: action.payload,
      status: 'success',
    };
  }
  if (action.type === actions.UPLOADING_AVATAR) {
    return {
      ...state,
      loading: action.payload,
    };
  }
  if (action.type === actions.LOGOUT_USER) {
    return {
      ...state,
      authStatus: actions.AuthStatus.LOGGING_OUT,
    };
  }
  if (action.type === actions.LOGOUT_SUCCESS) {
    return {
      ...state,
      authStatus: actions.AuthStatus.LOGGED_OUT,
    };
  }
  if (action.type === actions.LOGOUT_FAIL) {
    return {
      ...state,
      authStatus: actions.AuthStatus.AUTHENTICATED,
    };
  }
  if (action.type === actions.SET_HUB_ROLE) {
    return {
      ...state,
      hubRole: action.payload,
    };
  }
  return state;
}
