import { INotificationDetail, IMailbox } from '@aiware/js/interfaces';
import { all, put, takeEvery, takeLatest, call, select, take } from 'redux-saga/effects';
import { graphEndpointSelector, sessionTokenSelector } from '@aiware/shared/redux';
import { subscriberSuccess } from '@aiware/os/notification-subscription';
import {
  getNotification,
  getNotificationStart,
  updateUtilitySetting,
  getNotificationListByMailBoxRequest,
  getNotificationListByMailBoxSuccess,
  getNotificationListByMailBoxFail,
  updateNotificationList,
  updateNotificationReadRequest,
  updateNotificationReadSuccess,
  updateNotificationReadFail,
  getNotificationActionSuccess,
  getNotificationActionError,
  GET_NOTIFICATION_ACTION_REQUEST,
  markAllReadNotificationSuccess,
  markAllReadNotificationFail,
  markAllReadNotificationRequest,
} from './actions';
import {
  handleMarkAllReadNotification,
  handleNotificationListByMailBox,
  handleUpdateNotificationRead,
  handleGetNotificationAction,
} from './api';
import { mailBoxSelector, notificationDetailSelector } from './selector';
import { INotificationAction, INotificationFlag } from '../interfaces/notificationInterfaces';

type TDynamicObject = {
  [x: string]: any;
};

export function* refetchNotificationSetting() {
  yield put(getNotification(0, 30));
}

export function* getNotificationListByMailBoxSaga(action: {
  type: string;
  payload: {
    limit: number;
    offset: number;
    callback?: () => void;
  };
}) {
  const { status, mailBoxIds, mailbox: mailboxList } = yield select(mailBoxSelector);
  const { limit, offset, callback } = action.payload;
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const endSubscriber = status === 'success' || status === 'failure';
  if (Object.keys(mailboxList).length === 0 && !endSubscriber) {
    yield take(subscriberSuccess({}).type);
  } else {
    yield put(getNotificationStart(offset, limit));
    const data: TDynamicObject = yield call(
      handleNotificationListByMailBox,
      graphEndpoint,
      token,
      mailBoxIds,
      offset,
      limit
    );
    callback?.();
    if (data?.errors?.length > 0) {
      yield put(getNotificationListByMailBoxFail());
    } else {
      let availableMailBoxes = 0;
      const notifications = data?.data?.notificationMailboxes?.map((mailbox: any) => {
        if (mailbox?.notifications?.count > 0) {
          const notificationList = mailbox?.notifications?.records?.map(
            (notificationItem: INotificationDetail) => ({
              ...notificationItem,
              mailboxId: mailbox.id,
            })
          );
          availableMailBoxes++;
          return notificationList;
        }
        return [];
      });
      const notificationsFlatted = notifications?.flat() || [];
      const notificationsWithIcon = notificationsFlatted.map(
        (notification: INotificationDetail): INotificationDetail => {
          const mailboxApp: IMailbox = mailboxList.find(
            (mailbox: IMailbox) => mailbox.id === notification.mailboxId
          );
          return {
            ...notification,
            appId: mailboxApp?.application?.applicationId || '',
            appName: mailboxApp?.application?.name || '',
            eventNames: mailboxApp?.eventFilter?.eventNames || [],
            icon: mailboxApp?.application?.icon || '',
            title: notification.title || '',
          };
        }
      );
      yield put(
        getNotificationListByMailBoxSuccess({
          offset,
          limit,
          availableMailBoxes,
          data: notificationsWithIcon,
        })
      );
    }
  }
}

export function* listenToPushNotificationSaga(action: { type: string; payload: INotificationDetail }) {
  const { mailbox: mailboxList } = yield select(mailBoxSelector);
  const { payload } = action;
  const mailboxApp: IMailbox = mailboxList.find((mailbox: IMailbox) => mailbox.id === payload.mailboxId);
  yield put(
    updateNotificationList({
      ...payload,
      appId: mailboxApp?.application?.applicationId || '',
      appName: mailboxApp?.application?.name || '',
      icon: mailboxApp?.application?.icon || '',
    })
  );
}

export function* updateNotificationReadSaga(action: { type: string; payload: string }) {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);

  try {
    const data: INotificationFlag = yield call(
      handleUpdateNotificationRead,
      graphEndpoint,
      token,
      action.payload
    );
    if (!data?.error) {
      yield put(updateNotificationReadSuccess({ ...data }));
      return;
    } else {
      yield put(updateNotificationReadFail());
      return;
    }
  } catch (error) {
    yield put(updateNotificationReadFail());
  }
}

export function* updateMarkAllReadNotificationSaga() {
  const { mailBoxIds } = yield select(mailBoxSelector);
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);

  if (mailBoxIds?.length > 0) {
    try {
      const data: TDynamicObject = yield call(
        handleMarkAllReadNotification,
        mailBoxIds,
        graphEndpoint,
        token
      );
      if (!data.error) {
        yield put(markAllReadNotificationSuccess());
      } else {
        yield put(markAllReadNotificationFail());
      }
    } catch (error) {
      yield put(markAllReadNotificationFail());
    }
  }
}

export function* getNotificationActionSaga(action: { type: string; payload: string }) {
  const {
    eventType,
    appId: applicationId,
    eventName,
  } = yield select(notificationDetailSelector(action.payload));
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);

  try {
    const data: TDynamicObject = yield call(
      handleGetNotificationAction,
      eventName,
      eventType,
      applicationId,
      graphEndpoint,
      token
    );

    if (!data.error) {
      const notificationActions: INotificationAction[] = data?.data?.notificationActions?.records || [];
      yield put(getNotificationActionSuccess(action.payload, notificationActions));
    } else {
      yield put(getNotificationActionError());
    }
  } catch (error) {
    yield put(getNotificationActionError());
  }
}

export function* notificationSaga() {
  yield all([
    takeEvery(getNotification.type, getNotificationListByMailBoxSaga),
    takeLatest(updateUtilitySetting.type, refetchNotificationSetting),
    takeEvery(getNotificationListByMailBoxRequest.type, getNotificationListByMailBoxSaga),
    takeEvery('mailbox/PUSH_NOTIFICATION', listenToPushNotificationSaga),
    takeEvery(updateNotificationReadRequest.type, updateNotificationReadSaga),
    takeEvery(GET_NOTIFICATION_ACTION_REQUEST, getNotificationActionSaga),
    takeLatest(markAllReadNotificationRequest.type, updateMarkAllReadNotificationSaga),
  ]);
}
