import useStyles from './inviteRequestButtonStyles';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';

interface IInviteRequestButton {
  isExpired?: boolean;
  onClickRemoveExpiredInvite?: () => void;
  onClickRequestNewInvite?: () => void;
  onClickViewActiveRequest?: () => void;
}
export default function InviteRequestButton({
  isExpired,
  onClickRemoveExpiredInvite,
  onClickRequestNewInvite,
  onClickViewActiveRequest,
}: IInviteRequestButton) {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {isExpired && (
        <div>
          <Button
            sx={{ color: 'gray', fontWeight: 300 }}
            data-testid="os-organization-panel.invites.tab-remove-invite-button"
            onClick={onClickRemoveExpiredInvite}
          >
            <FormattedMessage
              id="os-organization-panel.invites.tab-remove-invite-button"
              defaultMessage="Remove"
              description="organization panel: invites tab, remove invite button"
            />
          </Button>{' '}
          <Button
            variant="outlined"
            data-testid="os-organization-panel.invites.tab-request-invite-button"
            onClick={onClickRequestNewInvite}
          >
            <FormattedMessage
              id="os-organization-panel.invites.tab-request-invite-button"
              defaultMessage="Request Invite"
              description="organization panel: invites tab, request invite button"
            />
          </Button>
        </div>
      )}
      {!isExpired && (
        <Button
          variant="outlined"
          data-testid={'os-organization-panel.invites.tab-view-active-request-invite-button'}
          onClick={onClickViewActiveRequest}
        >
          <FormattedMessage
            id="os-organization-panel.invites.tab-view-request-invite-button"
            defaultMessage="View Request"
            description="organization panel: invites tab, view request invite button"
          />
        </Button>
      )}
    </div>
  );
}
