import { getSortName } from '@aiware/js/function';
import { IUser } from '@aiware/js/interfaces';
import { headerBarSelector, IHeaderBarState } from '@aiware/os/app-bar';
import { TabPanel, Tabs } from '@aiware/ui';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { getAuthModule, userSelector, selectIsUserOrgAdmin } from '@aiware/shared/redux';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { Fragment, SyntheticEvent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import ListItems from '../../components/ListItems';
import getOrganizationPanelModule from '../../redux';
import { actions } from '../../redux/slices';
import { organizationSelectors, selectApiConfigs } from '../../redux/selectors';
import { mockOrganizations, mockPendingOrganizations } from './mockOrg';
import useStyles from './useStyles';
import InvitesTab from './InvitesTab/InvitesTab';
import { IMyOrganization, TOrganizationInvite } from '../../types';
import MyOrganizations from './MyOrganizationsTab';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import PanelHeader from './PanelHeader/PanelHeader';
import { daysBetweenDates } from '../../helpers/date-helper';
import { selectOrganizationInvites } from '../../redux/selectors/organizations.selector';
import { hidePanel } from '@aiware/js/panel';
import { usePrevious } from '../../helpers/use-previous';

export function OrganizationPanel() {
  const panelId = 'ORGANIZATION_PANEL';
  const { classes } = useStyles();
  const preventDefault = (event: SyntheticEvent) => event.preventDefault();
  const user = useSelector(userSelector) as IUser;
  const { organization: org } = user;
  const { guid } = org || {};

  const orgImageUrl = useSelector<IHeaderBarState, IHeaderBarState['aiWareHeaderBar']>(headerBarSelector)
    ?.currentUser?.organization?.imageUrl;
  const myOrganizations: IMyOrganization[] = useSelector(organizationSelectors.selectMyOrganizations);
  const uiStatus = useSelector(organizationSelectors.selectUIStatus);
  const inviteFetchStatus = useSelector(organizationSelectors.selectInviteFetchStatus);
  const organizationInvites = useSelector(selectOrganizationInvites);
  const [loadOrgImageSuccess, setLoadOrgImageSuccess] = useState(true);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const { apiRoot, graphQLEndpoint, token } = useSelector(selectApiConfigs) as ReturnType<
    typeof selectApiConfigs
  >;
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsUserOrgAdmin);
  const [currentOrg, setCurrentOrg] = useState(org);

  const prevGuid = usePrevious(guid);

  useEffect(() => {
    if (apiRoot && graphQLEndpoint && token) {
      dispatch(actions.organizationsState.organizationsFetchStart());
      dispatch(actions.organizationsState.fetchOrganizationInvitesStart());
      // when user switches orgs we update token, and then user.
      // this ensures user was updated before we attempt to fetch org invites
      const userUpdated = guid !== prevGuid;
      // if current user is admin, fetch pending org invites
      isAdmin &&
        userUpdated &&
        dispatch(
          actions.organizationInvitesState.fetchPendingInvitesStart(user?.organization?.organizationId)
        );
    }
  }, [apiRoot, graphQLEndpoint, token, isAdmin, dispatch, guid]);

  useEffect(() => {
    // find current org from gql
    const orgFromGql = myOrganizations?.find(org => org.guid === guid);
    if (orgFromGql) {
      setCurrentOrg(orgFromGql);
    }
  }, [guid, myOrganizations]);

  useEffect(() => {
    if (apiRoot && token && user.userId) {
      dispatch(actions.scimIdsState.scimIdsFetchStart());
    }
  }, [apiRoot, token, user.userId, dispatch]);

  const handleRetryOrg = () => {
    dispatch(actions.organizationsState.organizationsFetchStart());
  };
  const handleRetryInvitesTab = () => {
    dispatch(actions.organizationsState.fetchOrganizationInvitesStart());
  };
  const onChangeTab = (id: number) => {
    setSelectedTabId(id);
  };

  const handleSetUserDefaultOrg = (orgId: number) => {
    dispatch(actions.organizationsState.setUserDefaultOrg(orgId));
  };

  const { organization = {}, email } = user || {};
  const organizations = mockOrganizations;
  const pendingOrganizations = mockPendingOrganizations;
  const unexpiredInvites = organizationInvites?.filter(
    invite => daysBetweenDates(new Date(), new Date(invite.expirationDate)) >= 0
  );

  function handleLoadOrgImageError() {
    setLoadOrgImageSuccess(false);
  }

  const tabsList = [
    {
      label: (
        <FormattedMessage
          id="os-organization-panel.tabs.my.organizations"
          defaultMessage="My Organizations"
          description="user's organizations list"
        />
      ),
      disabled: false,
      id: 'myOrganizations',
    },
    {
      label: (
        <Badge
          badgeContent={unexpiredInvites?.length}
          color="error"
          sx={{
            paddingRight:
              unexpiredInvites?.length === 0
                ? '0'
                : // Make room for however many characters are in the badge
                  `${12 + Math.min(3, unexpiredInvites?.length.toString().length) * 4}px`,
            '& .Sdk-MuiBadge-badge': {
              top: '50%', // MUI Badges only support being anchored top and bottom, not center
            },
          }}
        >
          <FormattedMessage
            id="os-organization-panel.tabs.invites"
            defaultMessage="Invites"
            description="user's organization invites list"
          />
        </Badge>
      ),
      disabled: false,
      id: 'invites',
    },
  ];

  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[getAuthModule(), getOrganizationPanelModule()]}>
        <AIWareIntlProvider>
          <div className={classes.root} data-test="org-icon">
            <PanelHeader
              height={64}
              onPanelClose={() => dispatch(hidePanel(panelId))}
              parentPanelId={panelId}
            />
            <div className={classes.logoWrapper}>
              {orgImageUrl && loadOrgImageSuccess ? (
                <Avatar variant="square" className={classes.logo} data-test="app-bar-org-button">
                  <img
                    alt=""
                    src={currentOrg?.imageUrl || orgImageUrl}
                    onError={handleLoadOrgImageError}
                    className={classes.orgImage}
                  />
                </Avatar>
              ) : (
                <Avatar variant="circular" className={classes.sortName} data-test="app-bar-org-button">
                  {getSortName(currentOrg?.name || organization.organizationName || '')}
                </Avatar>
              )}
              <Typography data-test="org-name" variant="subtitle1">
                {currentOrg?.name || organization.organizationName || ''}
              </Typography>
              <div className={classes.loggedBox}>
                <Typography variant="body2" className={classes.loggedText}>
                  <FormattedMessage
                    id="os-organization-panel.0HWGhE"
                    defaultMessage="Currently logged in as: "
                    description="organization panel: the currently logged in user text"
                  />{' '}
                  <span
                    data-test="org-user-email"
                    onClick={preventDefault}
                    id="organization-panel-link-id"
                    className={classes.loginAs}
                  >
                    {email}
                  </span>
                </Typography>
              </div>
            </div>
            <div className={classes.tabsParent}>
              <Tabs selectedId={selectedTabId} onChangeTab={onChangeTab} tabsList={tabsList} />
            </div>

            <Divider className={classes.divider} />
            <div className={classes.listBox}>
              {organizations && organizations.length > 0 && <ListItems isNav items={organizations} />}
              {pendingOrganizations && pendingOrganizations.length > 0 && (
                <Fragment>
                  <Typography variant="subtitle1">
                    <FormattedMessage
                      id="os-organization-panel.WM8PzP"
                      defaultMessage="Organization Invitations (Pending)"
                      description="organization panel: the header of the pending organization invitations list"
                    />
                  </Typography>
                  <ListItems items={pendingOrganizations} />
                </Fragment>
              )}
            </div>
          </div>
          <div className={classes.secondBox}>
            <TabPanel value={selectedTabId} index={0}>
              <MyOrganizations
                uiStatus={uiStatus}
                organizations={myOrganizations}
                onRetry={handleRetryOrg}
                onSetUserDefaultOrg={handleSetUserDefaultOrg}
              />
            </TabPanel>
            <TabPanel value={selectedTabId} index={1}>
              <InvitesTab
                organizationInvites={organizationInvites}
                uiStatus={inviteFetchStatus}
                onRetry={handleRetryInvitesTab}
              />
            </TabPanel>
          </div>
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
}
