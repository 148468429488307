import { makeStyles } from 'tss-react/mui';

const inputFieldBorderColor = '#D5DFE9';

export const useStyles = makeStyles()(theme => ({
  tabsParent: {
    padding: theme.spacing(0, 3, 0, 3),
    '& div': {
      borderBottom: 'none',
    },
    '& div button': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      flex: '0 1 auto !important',
      minWidth: 'unset',
    },
  },
  editMetadataContainer: {
    padding: '5px 30px 0 30px',
  },
  tabPanelContainer: {
    padding: '20px 30px 70px 30px',
  },
  avatarImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '135px',
    height: '119px',
    marginRight: '10px',
  },
  coverImage: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '119px',
  },
  inputFieldBorder: {
    border: `1px solid ${inputFieldBorderColor}`,
  },
  fileName: {
    width: '100%',
    border: `1px solid ${inputFieldBorderColor}`,
    height: '52px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '15px',
  },
  tagInput: {
    flexGrow: 1,
  },
  tags: {
    paddingLeft: '0px',
    '& > li': {
      listStyleType: 'none',
      display: 'inline',
      marginRight: '5px',
    },
  },
  tag: {
    backgroundColor: '#D5DFE9',
    fontSize: '12px',
    marginBottom: '5px',
  },
  runDate: {
    width: '100%',
    '& input': {
      padding: '18.5px 10px !important',
    },
  },
  runTime: {
    width: '100%',
    '& input': {
      padding: '18.5px 10px !important',
    },
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px',
  },
  footer: {
    position: 'absolute',
    right: '30px',
    bottom: theme.spacing(6),
    backgroundColor: 'white',
    zIndex: 20,
  },
  topMargin: {
    marginTop: '15px',
  },
  flexFill: {
    flexGrow: 1,
  },
  heading: {
    color: '#2A323C',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  subHeading: {
    color: '#5C6269',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  closePanel: {
    transform: 'translateX(15px)',
  },
}));
