import { selectApiConfigs, baseGraphQLApi } from '@aiware/shared/redux';
import { ISchema } from '../../../../types';

type ReadSchemas = {
  apiConfigs: ReturnType<typeof selectApiConfigs>;
  offset: number;
  limit: number;
  additionalSelectors: { [name: string]: unknown };
};

export async function getSchemas({
  apiConfigs,
  offset,
  limit,
  additionalSelectors,
}: ReadSchemas): Promise<ISchema[]> {
  const { graphQLEndpoint, token } = apiConfigs;
  const operationName = 'getSchemas';
  const name = 'schemas';

  const variables: { offset: number; limit: number; status?: string; name?: string } = {
    offset,
    limit,
  };

  if (additionalSelectors['schemaSearchValue']) {
    variables.name = additionalSelectors['schemaSearchValue'] as string;
  }

  const query = `
      query ${operationName} ( $offset: Int, $limit: Int, $status: [SchemaStatus!], $name: String) {
        ${name}( offset: $offset, limit: $limit, status: $status, name: $name ) {
         records {
            id
            dataRegistryId
            dataRegistry {
              id
              name
              description
            }
            definition
            majorVersion
            minorVersion
            createdBy {
              organization {
                name
                id
              }
            }
            modifiedBy {
              organization {
                id
                name
              }
            }
            createdDateTime
            modifiedDateTime
            status
            organization {
              name
            }
          }
        }
      }
    `;

  const result = await baseGraphQLApi<{
    [name]: { records: ISchema[] };
  }>({
    query,
    operationName,
    graphEndpoint: graphQLEndpoint,
    token,
    variables,
  });

  return result.data.schemas.records;
}
