import { selectApiConfigs } from '../../../../redux/selectors';
import { ISource, GraphQLPage } from '../../../../types';
import { baseGraphQLApiWithError } from '@aiware/shared/redux';

export async function getSourcesBySearchTerm(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset: number,
  limit: number,
  searchTerm: string,
  sourceTypeIds: number[] | null = [1, 2, 3, 4]
): Promise<ISource[]> {
  const operationName = 'getSourcesSearch';

  const query = `
      query ${operationName}($sourceTypeIds: [ID!], $offset: Int, $name: String, $limit: Int){
        sources(
          sourceTypeIds: $sourceTypeIds,
          offset: $offset,
          limit: $limit,
          name: $name,
          includePublic: false,
          permission: owner,
          nameMatch: contains
          ) {
          count
          offset
          limit
          records {
            id
            name
            organizationId
            isPublic
            permission
            sourceTypeId
            sourceType {
              id
              name
            }
            thumbnailUrl
            createdDateTime
            modifiedDateTime
          }
        }
      }
    `;

  const variables = {
    sourceTypeIds: sourceTypeIds,
    name: searchTerm,
    offset: offset,
    limit: limit,
  };

  const result = await baseGraphQLApiWithError<{
    sources?: GraphQLPage<ISource>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result.sources?.records ?? [];
}
