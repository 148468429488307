import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

export * from './contentTemplates.selectors';
