import { SxProps, Theme } from '@mui/material';

export const formBuilderContainerStyles: SxProps<Theme> = {
  width: '170px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export const fieldTypesPanelStyles: SxProps<Theme> = {
  p: 3,
  bgcolor: '#F5F5F5',
  borderRadius: '4px',
  border: '1px solid #BDBDBD',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
};

export const formFieldsPanelStyles: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  p: 2,
  bgcolor: '#F5F5F5',
  border: '1px dashed #BDBDBD',
  borderRadius: '4px',
  overflowY: 'auto',
};
