import { SVGProps } from 'react';

export function PreferenceTabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={29} height={28} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.841 19.486a4.328 4.328 0 001.537 1.536c.368.215.767.4 1.198.491.337.093.706.123 1.106.123.368 0 .737-.061 1.106-.123.43-.092.829-.276 1.198-.491a4.328 4.328 0 001.536-1.536c.215-.369.399-.768.491-1.198.092-.338.123-.707.123-1.106 0-.369-.061-.737-.123-1.106a4.109 4.109 0 00-.491-1.198 4.33 4.33 0 00-1.536-1.536 4.105 4.105 0 00-1.198-.492 4.207 4.207 0 00-1.106-.123c-.37 0-.738.062-1.106.123-.43.092-.83.277-1.199.492a4.329 4.329 0 00-1.536 1.536c-.215.368-.399.768-.491 1.198a4.21 4.21 0 00-.123 1.106c0 .369.062.737.123 1.106.092.43.276.83.492 1.198z"
        fill="#555F7C"
      />
      <path
        d="M26.991 15.611h-.653a8.84 8.84 0 00-1.51-3.68l.451-.457c.563-.571.563-1.508 0-2.102a1.467 1.467 0 00-2.072 0l-.45.457a8.368 8.368 0 00-3.605-1.532v-.663c0-.822-.653-1.485-1.464-1.485-.428 0-.811.183-1.081.503a2.244 2.244 0 01-1.442 3.246c-.135.48-.315.936-.563 1.348.112.16.18.343.248.525a5.962 5.962 0 011.374-.548 6.272 6.272 0 011.464-.183c.495 0 .991.069 1.464.183a5.384 5.384 0 011.577.663c.856.503 1.554 1.234 2.072 2.103.293.502.518 1.028.654 1.6.112.48.18.982.18 1.485 0 .503-.068 1.006-.18 1.486a5.547 5.547 0 01-.654 1.6c-.495.869-1.216 1.577-2.072 2.103a5.652 5.652 0 01-1.577.663 6.273 6.273 0 01-1.464.183c-.496 0-.991-.069-1.464-.183a5.383 5.383 0 01-1.577-.663c-.856-.503-1.555-1.234-2.073-2.103a5.824 5.824 0 01-.653-1.6 6.54 6.54 0 01-.18-1.486c0-.503.068-1.005.18-1.485.09-.343.203-.686.338-1.006a2.259 2.259 0 01-.676-.297 9.714 9.714 0 01-1.329.571 2.166 2.166 0 01-2.14 1.737 2.22 2.22 0 01-1.013-.251c-.113.206-.18.457-.18.709 0 .822.653 1.485 1.464 1.485h.653a8.84 8.84 0 001.51 3.68l-.451.457c-.564.572-.564 1.509 0 2.103a1.42 1.42 0 001.036.434 1.42 1.42 0 001.036-.434l.45-.457a8.874 8.874 0 003.627 1.532v.662c0 .823.653 1.486 1.464 1.486.811 0 1.465-.663 1.465-1.486v-.662A8.576 8.576 0 0022.8 24.32l.45.457a1.42 1.42 0 001.037.434 1.42 1.42 0 001.036-.434 1.52 1.52 0 000-2.103l-.45-.457a9.147 9.147 0 001.509-3.68h.653c.811 0 1.464-.663 1.464-1.485-.045-.778-.698-1.44-1.509-1.44z"
        fill="#555F7C"
      />
      <path
        d="M7.055 14.4c0 .571.473 1.051 1.036 1.051.563 0 1.036-.48 1.036-1.051v-.457a5.995 5.995 0 002.568-1.075l.316.32c.203.206.473.298.72.298.271 0 .519-.092.722-.298a1.06 1.06 0 000-1.485l-.316-.32a6.18 6.18 0 001.059-2.606h.45c.563 0 1.037-.48 1.037-1.051 0-.572-.473-1.052-1.037-1.052h-.45a6.485 6.485 0 00-1.059-2.605l.316-.32a1.06 1.06 0 000-1.486 1.023 1.023 0 00-1.465 0l-.293.32a5.995 5.995 0 00-2.568-1.074V1.05C9.127.48 8.654 0 8.091 0c-.563 0-1.036.48-1.036 1.051v.458A6.328 6.328 0 004.51 2.583l-.316-.32a1.023 1.023 0 00-1.464 0 1.06 1.06 0 000 1.486l.315.32a6.181 6.181 0 00-1.058 2.605h-.45C.972 6.674.5 7.154.5 7.726c0 .571.473 1.051 1.036 1.051h.45c.158.96.519 1.852 1.06 2.606l-.316.32a1.06 1.06 0 000 1.486c.203.205.473.297.72.297.271 0 .542-.092.722-.298l.315-.32a5.995 5.995 0 002.568 1.075v.457zM6.38 10.65a3.455 3.455 0 01-1.171-1.188 2.973 2.973 0 01-.36-.914 3.446 3.446 0 01-.113-.823c0-.297.045-.572.113-.823.09-.32.202-.617.36-.914.293-.48.676-.892 1.171-1.189.27-.16.563-.297.901-.366.27-.068.541-.114.811-.114.293 0 .563.046.811.114.316.092.608.206.901.366.473.297.879.686 1.172 1.189.157.274.293.571.36.914.068.274.113.548.113.823 0 .297-.045.571-.113.823a4.53 4.53 0 01-.36.914c-.293.48-.676.891-1.172 1.188-.27.16-.563.297-.9.366-.271.069-.541.114-.812.114-.293 0-.563-.045-.81-.114-.316-.069-.609-.206-.902-.366z"
        fill="#555F7C"
      />
    </svg>
  );
}
