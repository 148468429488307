import { namespace } from './selector';

export const GET_CURRENT_USER = `os-app-bar/${namespace}/get current user`;
export const GET_CURRENT_USER_SUCCESS = `os-app-bar/${namespace}/get current user success`;
export const GET_CURRENT_USER_FAIL = `os-app-bar/${namespace}/get current user fail`;
export const GET_CURRENT_APP_ICON = `os-app-bar/${namespace}/get current app icon`;
export const GET_CURRENT_APP_ICON_SUCCESS = `os-app-bar/${namespace}/get app icon success`;
export const GET_CURRENT_APP_ICON_FAIL = `os-app-bar/${namespace}/get app icon fail`;

export const getCurrentUser = () => ({
  type: GET_CURRENT_USER,
});

export const getCurrentUserFail = () => ({
  type: GET_CURRENT_USER_FAIL,
});

export const getCurrentUserSuccess = (data: unknown) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: data,
});

export const getCurrentAppIcon = ({
  applicationId,
}: {
  applicationId: string;
}) => ({
  type: GET_CURRENT_APP_ICON,
  payload: {
    applicationId: applicationId,
  },
});

export const getCurrentAppIconFail = () => ({
  type: GET_CURRENT_APP_ICON_FAIL,
});

export const getCurrentAppIconSuccess = (data: {
  data: { application: { iconUrl: string } };
}) => ({
  type: GET_CURRENT_APP_ICON_SUCCESS,
  payload: data,
});
