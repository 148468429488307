import { SLICE_NAME } from '../../constants';
import { IOrganizationsState, namespace } from '../slices/organizations.state';
import { IMyOrganization, IOrgAppsAndRoles, TInvitee, TOrganizationInvite, TRole } from '../../types';

export const selectMyOrganizations = (state: IOrganizationsState): IMyOrganization[] =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.organizations;

export const myCurrentOrg = (state: IOrganizationsState): IOrgAppsAndRoles =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.currentOrg;

export const myCurrentRoles = (state: IOrganizationsState): TRole[] =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.currentOrg.roles;

export const selectUIStatus = (state: IOrganizationsState) =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.status;

export const selectInviteFetchStatus = (state: IOrganizationsState) =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.inviteFetchStatus;

export const invitees = (state: IOrganizationsState): TInvitee[] =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.invitees;

export const currentInvitee = (state: IOrganizationsState): string =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.currentInvitee;

export const selectOrganizationInvites = (state: IOrganizationsState): TOrganizationInvite[] =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.organizationInvites;

export const selectActiveOrganizationInviteId = (state: IOrganizationsState): string | null => {
  return ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.activeOrganizationInviteId;
};

export const selectActiveOrganizationInvite = (state: IOrganizationsState): TOrganizationInvite | null => {
  const activeId = ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)
    ?.activeOrganizationInviteId;
  if (!activeId) return null;
  return (
    ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationsState)?.organizationInvites.filter(
      invite => invite.organizationInviteId === activeId
    )[0] ?? null
  );
};
