import { namespace } from './selector';

export const GET_ACTIVITY = `os-app-bar-panel/${namespace}/get activity`;
export const GET_ACTIVITY_SUCCESS = `os-app-bar-panel/${namespace}/get activity success`;
export const GET_ACTIVITY_FAIL = `os-app-bar-panel/${namespace}/get activity fail`;

export const UPDATE_ACTIVITY_FILTER = `os-app-bar-panel/${namespace}/update activity filter`;

export function getActivity() {
  return {
    type: GET_ACTIVITY,
  };
}

export function updateActivityFilter(data: unknown) {
  return {
    type: UPDATE_ACTIVITY_FILTER,
    payload: data,
  };
}

export function getActivityFail() {
  return {
    type: GET_ACTIVITY_FAIL,
  };
}

export function getActivitySuccess(data: unknown) {
  return {
    type: GET_ACTIVITY_SUCCESS,
    payload: data,
  };
}
