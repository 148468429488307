import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { LoadingStatus, EntityType } from '@aiware/js/interfaces';
import { EPermissionAction } from '@aiware/shared/permissions';

export const SLICE_NAME = 'dataCenter';
export const LIMIT = 30;
export const STREAMING_SOURCE_TYPES = [
  1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
];

export type EngineCategoryId = string;
export type EngineId = string;
export type FolderId = string;
export type TdoId = string;
export type ScheduleId = string;
export type SourceId = string;
export type SourceTypeId = string;
export type OrganizationId = string;
export type AiPackageId = string;

export enum EAllowTDOSelection {
  none = 'none',
  single = 'single',
  multiple = 'multiple',
}

export type EntityMap = {
  [EntityType.EngineCategories]: Record<EngineCategoryId, IEngineCategory>;
  [EntityType.Engines]: Record<EngineId, IEngine>;
  [EntityType.Folders]: Record<FolderId, IFolder>;
  [EntityType.Schedules]: Record<ScheduleId, ISchedule>;
  [EntityType.Sources]: Record<SourceId, ISource>;
  [EntityType.SourceTypes]: Record<SourceTypeId, ISourceType>;
  [EntityType.Tdos]: Record<TdoId, ITdo>;
};

export type EntityTypesWithActions =
  | EntityType.Folders
  | EntityType.Tdos
  | EntityType.Sources
  | EntityType.Schedules;

export type EntityAction = {
  name: () => JSX.Element;
  testLabel: string;
  description: string;
  action: ActionCreatorWithPayload<string>;
  Icon: any;
  getRedirectUri?: (id: string) => void;
  permissionRequired?: EPermissionAction;
};

export interface EntityContextMenu {
  actions: EntityAction[];
  moveAction?: ActionCreatorWithPayload<string>;
  deleteAction?: ActionCreatorWithPayload<string>;
}

export interface IEntity<T = string> {
  id: T;
  name: string;
  pendingDeleteStatus?: LoadingStatus;
}

export interface IEngineCategory extends IEntity<EngineCategoryId> {
  description: string;
  iconName: string;
}

export interface IEngine extends IEntity<EngineId> {
  categoryId: EngineCategoryId;
  edgeVersion: number;
}

// aka Dag Template
export interface IAiPackage extends IEntity<AiPackageId> {
  mimeType: MimeType;
}

export interface ISourceType extends IEntity<SourceTypeId> {
  iconClass: string;
}

export interface ISource extends IEntity<SourceId> {
  id: string;
  name: string;
  modifiedDateTime: string;
  createdDateTime: string;
  sourceTypeId: SourceTypeId;
  scheduleCount?: number;
  thumbnailUrl: string;
  permission?: string;
  isPublic?: boolean;
  sourceType?: ISourceType;
}

export type SourceAggregationBucket = {
  key: string;
  sourceTypeId: {
    buckets: {
      key: string;
    }[];
  };
  scheduleCount: {
    value: number;
  };
};

export type SourceAggregations = {
  mediaSourcesTotal?: {
    value: number;
  };
  mediaSources?: {
    buckets: SourceAggregationBucket[];
  };
};

// aka Program
export interface ISchedule extends IEntity<ScheduleId> {
  scheduleType?: string;
  sourceId?: SourceId;
  primarySourceId?: SourceId;
  modifiedDateTime?: string;
  createdDateTime?: string;
  imageUrl?: string;
  isActive?: boolean;
  details?: {
    programLiveImage?: string;
  };
  permission?: string;
  jobs?: {
    records: [id: string];
  };
  startDateTime?: string;
  stopDateTime?: string;
  parts?: [ISchedulePart];
  runMode?: string;
  allJobTemplates: {
    records?: [
      {
        jobConfig: {
          createTDOInput: {
            parentFolderId?: string;
          };
        };
      }
    ];
  };
}

export interface ISchedulePart {
  repeatInterval: number;
  repeatIntervalUnit: string;
  startTime: string;
  stopTime: string;
  scheduleType: string;
  scheduledDay: string;
  durationSeconds: number;
}

export type ScheduleAggregationBucket = {
  key: string;
  fileCount: {
    value: number;
  };
};

export type ScheduleAggregations = {
  scheduleTotal?: {
    value: number;
  };
  schedules?: {
    buckets: ScheduleAggregationBucket[];
  };
};

export interface IFolder extends IEntity<FolderId> {
  modifiedDateTime: string;
  treeObjectId: string;
  ownerId?: string | null;
  parentFolderId?: FolderId | null;
}

// aka File
export interface ITdo extends IEntity<TdoId> {
  engineIds?: EngineId[];
  engineCategoryIds?: EngineCategoryId[];
  fileType?: MimeType;
  mediaStartTime?: string;
  modifiedDateTime?: string;
  sourceId?: SourceId;
  folders?: [
    {
      folderPath: [
        {
          name: string;
          id: string;
        }
      ];
    }
  ];
  sourceTypeId?: SourceTypeId;
  scheduleId?: ScheduleId;
  thumbnailUrl?: string;
  parentFolderId?: FolderId | null;
  details?: any; // Details is just json data and can be any format
  primaryAsset?: {
    contentType?: string;
    signedUri?: string;
  };
  startDateTime?: string;
  stopDateTime?: string;
  createdDateTime?: string;
  jobs?: {
    records: [
      {
        status: JobStatus;
      }
    ];
  };
}

export interface ITdoGraphQL extends IEntity<TdoId> {
  modifiedDateTime?: string;
  createdDateTime?: string;
  engineRuns?: GraphQLPage<{
    engine: {
      id: string;
      categoryId: string;
    };
  }>;
  folders?: [
    {
      folderPath: [
        {
          name: string;
          id: string;
        }
      ];
    }
  ];
  primaryAsset?: {
    contentType: MimeType;
    signedUri: string;
  };
  sourceData?: {
    source: {
      id: SourceId;
      sourceTypeId: SourceTypeId;
    };
    scheduledJobId: ScheduleId;
  };
  thumbnailUrl: string;
  details?: any;
  startDateTime?: string;
  stopDateTime?: string;
  jobs?: {
    records: [
      {
        status: JobStatus;
      }
    ];
  };
}

export interface ITdoSearchResult {
  fileType?: MimeType;
  mediaId: number;
  mediaSourceId: number;
  mediaSourceTypeId: number;
  mediaStartTime: string;
  programId: number;
  programLiveImage: string;
  metadata: {
    'veritone-job': {
      engineCategories: string[];
      engines: string[];
    };
    'veritone-file': {
      filename?: string;
      fileName?: string;
    };
  };
}

export type GraphQLPage<T> = {
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export type SearchPage<T> = {
  records: T[];
  returnRecords: number;
  totalRecords: number;
};

export interface IPageDetail<T = string> {
  ids: T[];
  hasNextPage: boolean;
  offset: number;
  status: LoadingStatus;

  // TODO handle sorting later
  // orderBy?: any;
}

export type TDODetails<T> = IPageDetail<T> & { parentScheduleId: string; tdos: IFileVm[] };

export const defaultPage: IPageDetail = {
  ids: [],
  offset: 0,
  hasNextPage: true,
  status: 'idle',
};

export enum RunMode {
  Continuous = 'Continuous',
  Recurring = 'Recurring',
  Once = 'Once',
  Now = 'Now',
}

export enum MimeType {
  VideoMp4 = 'video/mp4',
  AudioMp4 = 'audio/mp4',
}

// Note: "entity" refers to the entity selected
// Eg. If a user clicks on a Breadcrumb with entity type "Sources",
// the sourceTree view in the UI would show a table of "Schedules" for that sourceId
export type Breadcrumb = {
  isRoot?: boolean;
  entity?: EntityType;
  id?: string;
  name?: string;
  sourceId?: string;
};

export type TableScrollPosition = {
  tableEntityType: EntityType;
  isFluffy: boolean;
  parentId?: string;
  index: number;
};

export interface ICategoriesWithEngines extends IEngineCategory {
  engines: IEngine[];
}

export interface ITdoVm extends ITdo {
  categoriesWithEngines: (ICategoriesWithEngines | null)[];
}

export interface IFileVm {
  type: string;
  file: ITdoVm;
}

export interface IFolderVm {
  type: string;
  folder: IFolder;
}

export type IFileOrFolderVm = IFileVm | IFolderVm | IFolderVm[];

export type IFileOrFolder = ITdoVm | IFolder;

export type InfiniteWrapperItemType = IFileOrFolderVm[] | ISource[] | ISchedule[];

export type RowSelectHandler = (item: IFileOrFolder) => void;

export enum JobStatus {
  Pending = 'pending',
  Running = 'running',
  Complete = 'complete',
  Queued = 'queued',
  Accepted = 'accepted',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Standby_pending = 'standby_pending',
  Waiting = 'waiting',
  Resuming = 'resuming',
  Aborted = 'aborted',
  Paused = 'paused',
}

export interface ISchema {
  id: string;
  status: string;
  definition: any;
  majorVersion: number;
  minorVersion: number;
  dataRegistry: {
    id: string;
    name: string;
    description: string;
  };
}

export interface IFileToUpdate {
  folderId: string;
  tdoId: string;
  file: ITdoGraphQL;
}
