import { IInitialState, TUserAppGroup } from './type';
import { namespace } from './slice';

export const selectUserAppGroups = (state: IInitialState) => (state as any)[namespace]?.appGroups || [];

export const selectUserAppGroupsRootFolderId = (state: IInitialState) =>
  (state as any)[namespace]?.appGroupsRootFolderId || '';

export const selectUserAppGroupsUiStatus = (state: IInitialState) => (state as any)[namespace]?.uiStatus;

export const selectAppGroupsEditMode = (state: IInitialState) =>
  !!((state as any)[namespace]?.user?.appGroups || []).find((appGroup: TUserAppGroup) => appGroup.isEdited);
