import createSvgIcon from '../createSvgIcon';
export const EngineFilled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8246 13.1272V15.4377L17.1712 18.9153H7.19758C7.19758 18.9153 7.19758 17.9194 6.92284 17.1317C6.56906 16.1174 5.6131 15.4377 4.54046 15.4377V9.50281C5.61687 9.50281 6.58409 8.83176 6.95669 7.82084C7.09219 7.45539 7.19758 7.17575 7.19758 7.17575H14.8001C15.3195 8.57432 16.6519 9.50281 18.1423 9.50281H19.8246V11.813H20.6865V9.90176C20.6865 9.68121 20.8672 9.50281 21.0854 9.50281H21.6011C21.8231 9.50281 22 9.68121 22 9.90176C22 10.9827 22 13.9579 22 15.0388C22 15.2589 21.8231 15.4377 21.6011 15.4377C21.4392 15.4377 21.251 15.4377 21.0854 15.4377C20.8672 15.4377 20.6865 15.2589 20.6865 15.0388V13.1272H19.8246ZM3.67857 9.90176C3.67857 9.68121 3.5017 9.50281 3.27964 9.50281C3.01242 9.50281 2.66239 9.50281 2.39893 9.50281C2.17688 9.50281 2 9.68121 2 9.90176C2 10.9827 2 13.9579 2 15.0388C2 15.2589 2.17688 15.4377 2.39893 15.4377C2.66239 15.4377 3.01242 15.4377 3.27964 15.4377C3.5017 15.4377 3.67857 15.2589 3.67857 15.0388C3.67857 13.9579 3.67857 10.9827 3.67857 9.90176ZM14.8001 5.39893C14.8001 5.17838 14.6233 5 14.4012 5C13.0877 5 8.91003 5 7.59651 5C7.37446 5 7.19758 5.17838 7.19758 5.39893C7.19758 5.56265 7.19758 5.75158 7.19758 5.91493C7.19758 6.13548 7.37446 6.31426 7.59651 6.31426C8.91003 6.31426 13.0877 6.31426 14.4012 6.31426C14.6233 6.31426 14.8001 6.13548 14.8001 5.91493C14.8001 5.75158 14.8001 5.56265 14.8001 5.39893Z"
    />
  </svg>,
  'EngineFilled'
);
