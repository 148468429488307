import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ height: string | number }>()((theme, { height }) => ({
  header: {
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    height,
    padding: theme.spacing(0, 0, 0, 6),
    backgroundColor: '#FAFAFA',
  },
  addBtn: {
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  addIcon: {
    width: '15px',
    height: '15px',
    fill: '#fff',
    marginRight: theme.spacing(1),
  },
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
}));
