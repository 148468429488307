import createSvgIcon from '../createSvgIcon';
export const Undefined = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9538 6H6.04615C5.23826 6 4.46346 6.32093 3.8922 6.8922C3.32093 7.46346 3 8.23826 3 9.04615V15.4154C3 16.2233 3.32093 16.9981 3.8922 17.5693C4.46346 18.1406 5.23826 18.4615 6.04615 18.4615H17.9538C18.7617 18.4615 19.5365 18.1406 20.1078 17.5693C20.6791 16.9981 21 16.2233 21 15.4154V9.04615C21 8.23826 20.6791 7.46346 20.1078 6.8922C19.5365 6.32093 18.7617 6 17.9538 6ZM15.5252 12.3526L10.1252 15.1218C10.1042 15.1327 10.0807 15.1381 10.0571 15.1373C10.0334 15.1365 10.0103 15.1297 9.99006 15.1175C9.96978 15.1053 9.95297 15.088 9.94125 15.0675C9.92952 15.0469 9.92326 15.0237 9.92308 15V9.46154C9.92326 9.43786 9.92952 9.41463 9.94125 9.39406C9.95297 9.37349 9.96978 9.35627 9.99006 9.34405C10.0103 9.33182 10.0334 9.32501 10.0571 9.32424C10.0807 9.32348 10.1042 9.3288 10.1252 9.33969L15.5252 12.1089C15.5479 12.1205 15.5669 12.1382 15.5802 12.1599C15.5935 12.1817 15.6006 12.2067 15.6006 12.2322C15.6006 12.2576 15.5935 12.2826 15.5802 12.3044C15.5669 12.3261 15.5479 12.3438 15.5252 12.3554V12.3526Z"
      fill="#555F7C"
    />
    <circle cx="12" cy="12" r="12" fill="#2A323C" />
    <path
      d="M11.9963 17.8334C15.1908 17.8334 17.8333 15.1909 17.8333 12.0037C17.8333 8.80917 15.1835 6.16669 11.9963 6.16669C8.80178 6.16669 6.16666 8.80917 6.16666 12.0037C6.16666 15.1909 8.80913 17.8334 11.9963 17.8334ZM11.9006 13.2403C11.6283 13.2403 11.4737 13.0931 11.4737 12.8207V12.7103C11.4737 12.1362 11.7755 11.8123 12.2245 11.5105C12.7692 11.1278 13.0121 10.9143 13.0121 10.4948C13.0121 10.0237 12.6367 9.69245 12.0405 9.69245C11.5841 9.69245 11.2455 9.95008 11.091 10.3255C10.929 10.5316 10.8701 10.6935 10.5683 10.6935C10.3843 10.6935 10.2003 10.561 10.2003 10.3181C10.2003 10.2224 10.215 10.1341 10.2371 10.0384C10.3843 9.46427 11.0541 8.96374 12.0773 8.96374C13.0857 8.96374 13.9395 9.50108 13.9395 10.458C13.9395 11.1499 13.5494 11.4958 12.9606 11.9006C12.5263 12.1951 12.3275 12.4085 12.3275 12.7545V12.8575C12.3275 13.071 12.173 13.2403 11.9006 13.2403ZM11.9227 14.948C11.6062 14.948 11.3191 14.6903 11.3191 14.3591C11.3191 14.0279 11.6062 13.7776 11.9227 13.7776C12.2392 13.7776 12.5263 14.0205 12.5263 14.3591C12.5263 14.6977 12.2392 14.948 11.9227 14.948Z"
      fill="white"
    />
  </svg>,
  'Undefined'
);
