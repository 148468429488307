import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  tableCell: {
    padding: 0,
    '& > span': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tableRowCheckbox: {
    marginRight: theme.spacing(2),
  },
  rowCheckbox: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    height: '20px',
  },
  disabledCheckBox: {
    '& svg': {
      color: 'green',
    },
  },
  firstColumn: {
    paddingRight: theme.spacing(2),
  },
  secondColumn: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  thirdColumn: {
    paddingLeft: theme.spacing(2),
  },
}));
