import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';
import { IRootFolder, SLICE_NAME } from '../../types';

export const namespace = 'rootFolderState';

export type State = {
  rootFolder: IRootFolder | null;
  status: LoadingStatus;
};

export const initialState: State = {
  rootFolder: null,
  status: 'idle',
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    startRootFolderFetch(state) {
      state.status = 'pending';
    },
    rootFolderFetchSucceed(state, { payload }: PayloadAction<{ rootFolder: IRootFolder }>) {
      state.rootFolder = payload.rootFolder;
      state.status = 'idle';
    },
    rootFolderFetchFailed(state) {
      state.status = 'failure';
    },
    updateRootFolder(state, { payload }: PayloadAction<{ activeFolder: IRootFolder }>) {
      state.rootFolder = payload.activeFolder;
    },
  },
  extraReducers: builder => {
    //@ts-ignore
    builder.addCase(
      createAction('DATA_CENTER_FOLDERS_AND_FILES_RESPONSE'),
      (state, { payload }: PayloadAction<{ folder: IRootFolder }>) => {
        state.rootFolder = payload.folder;
      }
    );
    //@ts-ignore
    builder.addCase(
      createAction('INVESTIGATE_BROWSE_FOLDERS_AND_FILES_RESPONSE'),
      (state, { payload }: PayloadAction<{ folder: IRootFolder }>) => {
        state.rootFolder = payload.folder;
      }
    );
  },
});

export const actions = slice.actions;
export default slice.reducer;
