// @ts-ignore
import { errorIconShield } from '@aiware/shared/assets';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { TPermissionsEntity } from '@aiware/shared/permissions';
import { useSelector } from 'react-redux';
import { authSelector } from '@aiware/shared/redux';
import { devErrorLogger } from '@aiware/shared/reusable-utils';

const useStyles = makeStyles()(_theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    padding: '0px',
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessageContainer: {
    width: '100%',
    height: '100%',
    background: '#FFF',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    marginTop: '20px',
  },
}));

interface IProps {
  src: string;
  name: string;
  title: string;
  parentOnLoadMessage?: string;
  isOLPEnabled?: boolean | unknown;
  permissions?: undefined | TPermissionsEntity;
  passAuthToken?: boolean;
}

export const IframeContainer = ({
  src,
  name,
  title,
  parentOnLoadMessage,
  isOLPEnabled = false,
  permissions = undefined,
  passAuthToken = false,
}: IProps) => {
  const { classes } = useStyles();
  const auth = useSelector(authSelector) as { sessionToken: string };
  const [isLoading, setIsLoading] = useState(true);
  const [urlError, setUrlError] = useState(false);
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const checkIframeUrl = async (URL: string): Promise<unknown> => {
    try {
      const response = await fetch(URL, {
        method: 'GET',
        credentials: 'include',
        headers: auth?.sessionToken ? { Authorization: `Bearer ${auth.sessionToken}` } : {},
      });
      if (response.status >= 400) {
        setUrlError(true);
      } else {
        setUrlError(false);
      }
      return;
    } catch (error) {
      setUrlError(true);
    }
  };

  useEffect(() => {
    checkIframeUrl(getIframeURL()).catch(er => {
      devErrorLogger(er);
    });
    const timer = setTimeout(() => {
      if (!parentOnLoadMessage) {
        setIsLoading(false);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [src]);

  useEffect(() => {
    const handleIframeLoaded = (event: { data?: { message?: string } }) => {
      const { data } = event;
      if (data && parentOnLoadMessage) {
        if (data.message === parentOnLoadMessage) {
          setIsLoading(false);
        }
      }
    };
    window.addEventListener('message', handleIframeLoaded, false);
    return () => window.removeEventListener('message', handleIframeLoaded);
  }, []);

  const shouldIframeRender = () => {
    if (isOLPEnabled === false) return true;
    if (isOLPEnabled === true && permissions) {
      if (permissions.status === 'pending') return false;
      return true;
    }
    return false;
  };

  const getIframeURL = () => {
    const url = new URL(src);
    if (passAuthToken) {
      url.searchParams.set('authToken', auth.sessionToken);
    }
    if (isOLPEnabled === false || !permissions) return url.toString();

    // Force all 4 CRUD permission checks
    ['create', 'read', 'update', 'delete'].forEach(perm => {
      if (permissions.permissions) {
        if (!(permissions.permissions as any)[perm]) {
          url.searchParams.set(`${perm}Disabled`, 'true');
        }
      }
    });

    // Override with anything else set in redux
    for (const key in permissions.permissions) {
      if ((permissions.permissions as any)[key] === false) {
        url.searchParams.set(`${key}Disabled`, 'true');
      }
    }

    return url.toString();
  };

  return (
    <div className={classes.container}>
      {isLoading && !urlError && (
        <div data-test="data-center-iframe-loader" className={classes.loader}>
          <CircularProgress size={50} disableShrink />
        </div>
      )}
      {urlError ? (
        <div className={classes.errorMessageContainer}>
          <img data-test="data-center-iframe-error-image" alt="error" src={errorIconShield} />
          <Typography className={classes.errorMessage} variant="body1">
            <FormattedMessage
              id="os-data-center-browse.iframe.errorMessage"
              defaultMessage="Service not available. Please contact support."
              description="Error message for iframe not loading"
            />
          </Typography>
        </div>
      ) : (
        <>
          {shouldIframeRender() && (
            <iframe
              id="DataCenterIframe"
              ref={iFrameRef}
              name={name}
              title={title}
              className={classes.iframe}
              src={getIframeURL()}
            />
          )}
        </>
      )}
    </div>
  );
};
