import createSvgIcon from '../createSvgIcon';
export const Node = createSvgIcon(
  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666626 0.5V17.5H24.75V0.5H0.666626ZM21.9166 14.6667H3.49996V10.4167H21.9166V14.6667ZM3.49996 7.58333V3.33333H21.9166V7.58333H3.49996Z"
      fill="#0A499D"
    />
  </svg>,
  'Node'
);
