import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { EntityType } from '@aiware/js/interfaces';
import { useStyles } from '../../useStyles';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../redux/slices';
import { EntityContextMenu, ISchedule } from '../../../../types';
import ContextMenuRowButton from '../../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../../types/entity-context-menus';
import GetRowItem from '../../../shared/GetRowItem';

const scheduleContextMenu = ENTITY_CONTEXT_MENU[EntityType.Schedules];

const SchedulesTableRow: FunctionComponent<{
  item: ISchedule;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const contextMenu: EntityContextMenu = {
    ...scheduleContextMenu,
    actions: scheduleContextMenu.actions.filter(action => {
      if (item.isActive) {
        return action.description !== 'Activate Schedule'; // Exclude 'Activate Schedule' if already active
      }
      return action.description !== 'Deactivate Schedule'; // Exclude 'Deactivate Schedule' if not active
    }),
  };

  return (
    <Grid
      data-test={`${EntityType.Schedules}-row-${item.id}`}
      style={{ height: rowHeight }}
      container
      className={classes.root}
    >
      <Grid
        onClick={() =>
          dispatch(
            actions.viewStreams.addToSourceTreeBreadCrumbs({
              entity: EntityType.Schedules,
              id: item.id,
              sourceId: item.sourceId,
            })
          )
        }
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        item
        container
        xs={11}
      >
        <GetRowItem rowName={'scheduleType'} item={item} xs={1} itemType={EntityType.Schedules} />
        <GetRowItem rowName={'name'} item={item} itemType={EntityType.Schedules} />
        <GetRowItem rowName={'createdDateTime'} item={item} xs={2} itemType={EntityType.Schedules} />
        <GetRowItem rowName={'modifiedDateTime'} item={item} xs={2} itemType={EntityType.Schedules} />
        <GetRowItem rowName={'status'} item={item} xs={2} itemType={EntityType.Schedules} />
      </Grid>
      <Grid xs={1} className={classes.padding} item container alignItems="center" justifyContent="flex-end">
        <ContextMenuRowButton
          contextMenu={contextMenu}
          entityId={item.id}
          type={EAuthResourceType.Schedule}
        />
      </Grid>
    </Grid>
  );
};
export default SchedulesTableRow;
