import { FunctionComponent } from 'react';
import { hidePanel } from '@aiware/js/panel';
import { useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { Close } from '@aiware/shared/icons';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { useStyles } from '../OrganizationPanel/PanelHeader/PanelHeader';
import { actions } from '../../redux/slices';
import { AvailableComponents } from '@aiware/js/interfaces';

const Header: FunctionComponent<{
  height: number;
}> = ({ height }) => {
  const { classes } = useStyles({ height });
  const dispatch = useDispatch();

  const onPanelClose = () => {
    dispatch(actions.organizationsState.setCurrentInvitee(''));
    dispatch(hidePanel(AvailableComponents.ADD_APPLICATIONS));
  };

  return (
    <header className={classes.header} data-testid="multi-org-application-panel-header">
      <Typography variant="h1" data-testid="org-title" className={classes.title}>
        {SHARED_TEXT.addApplications}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Typography style={visuallyHidden} id="multi-org-application-panel-close">
          {SHARED_TEXT.closePanel}
        </Typography>
        <Tooltip title={SHARED_TEXT.closePanel}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="org-close-panel"
            data-testid="multi-org-application-panel-close"
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
