import { OrganizationTabIcon } from '@aiware/shared/icons';

import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  Chip,
  Typography,
  Divider,
} from '@mui/material';
import useStyles from './useStyles';

interface IOrganizationItem {
  organizationName: string;
  organizationLogo: string;
  subtitle?: JSX.Element;
  isDefault: boolean;
  showDivider: boolean;
  Action: JSX.Element | null;
  onClick?: () => void;
  guid?: string;
}
export default function OrganizationItem({
  organizationName,
  organizationLogo,
  subtitle,
  isDefault,
  showDivider = true,
  Action,
  onClick,
}: IOrganizationItem) {
  const { classes } = useStyles();
  return (
    <>
      <ListItem className={classes.container} disableGutters>
        <div
          onClick={onClick ? onClick : undefined}
          className={`${classes.orgContainer} ${onClick ? '' : classes.clickDisabled}`}
        >
          <ListItemAvatar>
            <Avatar
              src={organizationLogo ? organizationLogo : undefined}
              sx={{ width: 38, height: 38, padding: '9px', bgcolor: '#F2F5F9' }}
              data-testid={'organizations-panel-org-item-avatar'}
            >
              {!organizationLogo && <OrganizationTabIcon />}
            </Avatar>
          </ListItemAvatar>
          <span className={classes.textContainer}>
            <ListItemText
              primary={
                <>
                  <Typography
                    className={classes.organizationName}
                    variant="body1"
                    sx={{
                      fontSize: '13px!important',
                      fontWeight: 600,
                    }}
                    data-testid={'organizations-panel-org-item-organization-name'}
                  >
                    {organizationName}
                  </Typography>
                  {isDefault && (
                    <Chip
                      className={classes.defaultOrgBadge}
                      label={<Typography variant="caption">Default</Typography>}
                      data-test="organization-panel-default-badge"
                    />
                  )}
                </>
              }
              secondary={
                subtitle && (
                  <Typography
                    variant="subtitle2"
                    className={classes.organizationSubtitle}
                    sx={{
                      fontSize: '11px!important',
                      fontWeight: 400,
                    }}
                    data-testid={'organizations-panel-org-item-subtitle'}
                  >
                    {subtitle}
                  </Typography>
                )
              }
            />
          </span>
        </div>
        <ListItemSecondaryAction>{Action}</ListItemSecondaryAction>
      </ListItem>
      {showDivider && <Divider />}
    </>
  );
}
