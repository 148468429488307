import { ChangeEvent, useState } from 'react';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FormattedMessage } from 'react-intl';
import { useStyles } from '../NotificationAllowTable/useStyles';
import { INotificationType, IAppNotificationSetting } from '../../interfaces/notificationInterfaces';
import { useDispatch, useSelector } from 'react-redux';
import { notificationApplicationSelector } from '../../store/selector';
import { updateAppActionAllowNotification } from '../../store/actions';
import NotificationAllowCell from '../commons/NotificationAllowCell';

export interface INotificationAllowRow {
  allowNotificationTitle: string;
  checked: boolean;
  allowType: INotificationType[];
  appId: string;
  id: string;
  defaultEnableState?: boolean;
}

export default function NotificationAllowRow(props: INotificationAllowRow) {
  const { allowNotificationTitle, checked, allowType, appId, id } = props;

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notificationApplication = useSelector(
    notificationApplicationSelector(appId)
  ) as IAppNotificationSetting['id'];
  const [isLineEnable, setLineEnable] = useState(checked);
  const currentApplicationAllowType = notificationApplication?.allowType;

  const [allowedAppNotification, setAllowedAppNotification] = useState<INotificationType[]>(allowType);

  // Must be in order: email, notification-center, banners
  const cells = [
    { id: 'email', type: INotificationType.EMAIL },
    { id: 'notification-center', type: INotificationType.NOTIFICATION_CENTER },
    { id: 'banners', type: INotificationType.BANNER },
  ];

  const handleChangeLineCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked: boxChecked } = e.currentTarget;
    setLineEnable(boxChecked);
    dispatch(updateAppActionAllowNotification(appId, id, boxChecked, 'allow'));
  };

  const onChangeAppAction = (e: ChangeEvent<HTMLInputElement>, type: INotificationType) => {
    let currentAllowedAppNotification;
    if (e.currentTarget.checked) {
      currentAllowedAppNotification = [...allowedAppNotification, type];
    } else {
      currentAllowedAppNotification = allowedAppNotification.filter(item => item !== type);
    }
    setAllowedAppNotification(currentAllowedAppNotification);
    dispatch(updateAppActionAllowNotification(appId, id, currentAllowedAppNotification, 'allowType'));
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        <Box display="flex">
          <Checkbox
            data-test={`row-check-${id}`}
            data-testid={`row-check-${id}`}
            className={classes.rowCheckbox}
            checked={isLineEnable}
            onChange={handleChangeLineCheckbox}
          />
          <Typography
            variant="body2"
            children={
              <FormattedMessage
                id="os-notification-panel.tSG+Bv"
                defaultMessage="{text}"
                description="allow-notification-table-{text}"
                values={{
                  text: allowNotificationTitle,
                }}
              />
            }
          />
        </Box>
      </TableCell>
      {cells.map(cell => {
        return (
          <NotificationAllowCell
            key={cell.id}
            id={cell.id}
            type={cell.type}
            allowType={allowType}
            currentApplicationAllowType={currentApplicationAllowType}
            isLineEnable={isLineEnable}
            onChangeAppAction={onChangeAppAction}
          />
        );
      })}
    </TableRow>
  );
}
