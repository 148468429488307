import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  dividerSmallMargin: {
    marginTop: theme.spacing(3.6),
    marginBottom: theme.spacing(4),
  },
  utilityItem: {
    padding: 0,
    cursor: 'pointer',
  },
  utilityItemHover: {
    padding: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  utilityDetail: {
    fontSize: 12,
  },
}));
