import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { getDataCenterSDOModule } from '../../redux';
import { Typography } from '@mui/material';
import { DataRegistryTable } from './components/data-registry-table/data-registry-table';
import { DataCenterSharedContentLayout } from '@aiware/shared/reusable-utils';

export const MainLayout = () => {
  return (
    <DynamicModuleLoader modules={[getDataCenterSDOModule()]}>
      <DataCenterSharedContentLayout
        breadcrumbs={
          <Typography
            style={{ marginLeft: '10px' }}
            variant={'h3'}
            data-testid="os-dc-data-registry-title-name"
          >
            Data Registries
          </Typography>
        }
        table={<DataRegistryTable />}
        tableWrapperStyles={{ height: 'calc(100% - 60px)' }}
      />
    </DynamicModuleLoader>
  );
};
