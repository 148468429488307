import { LoadingStatus } from '@aiware/js/interfaces';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { ChangeEvent, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { IFile } from '../../../types';
import FileItem from '../FileItem/FileItem';
import { UploadButton, uploadButtonStyles } from '../shared';
import useStyles from './useStyles';

type UploadHandler = (files: File[]) => void;

interface Props {
  /** Enables file metadata modal */
  edit: boolean;
  onUpload: UploadHandler;
  fileItems: IFile[];
  onDelete?: (id: string) => void;
  onRetryFailedsClick: () => void;
  onRetry: (file: IFile) => void;
  onAbort: (file: IFile) => void;
  onEdit: (file: IFile) => void;
  uiStateStatus?: LoadingStatus;
}

const FileItemList: FunctionComponent<Props> = ({
  edit,
  onUpload,
  fileItems,
  onDelete,
  onRetryFailedsClick,
  onRetry,
  onAbort,
  onEdit,
  uiStateStatus,
}: Props) => {
  const { classes } = useStyles();
  const { classes: uploadButtonClasses } = uploadButtonStyles();

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files!);
    onUpload(files);
  };
  const showRetryButton = fileItems.some(fileItem => fileItem.status === 'failure');

  return (
    <Box mt={6} borderRadius="4px" border="0.5px solid #D5DFE9">
      <List
        disablePadding
        className={classes.fileItemListContainer}
        data-test="data-center-file-item-list-wrapper"
      >
        {fileItems?.map((fileItem, index) => (
          <FileItem
            key={index}
            item={fileItem}
            edit={edit}
            showDivider={index !== fileItems.length - 1}
            onDelete={onDelete}
            onRetry={onRetry}
            onAbort={onAbort}
            onEdit={onEdit}
            uiStateStatus={uiStateStatus}
          />
        ))}
      </List>

      <Grid
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        item
        container
        className={classes.footerWrapper}
      >
        <span className={classes.shadowGradient} />

        {showRetryButton && (
          <Button
            data-test="data-center-importer-local-retry-button"
            variant="outlined"
            className={classes.retryButton}
            onClick={onRetryFailedsClick}
          >
            <RefreshOutlined fontSize={'small'} className={classes.refreshIcon} />
            <FormattedMessage
              id="os-data-center-importer.nJrtcz"
              defaultMessage="Retry Failed Items"
              description="Text of the label `Retry Failed Items`"
            />
          </Button>
        )}

        <UploadButton
          onUpload={handleUpload}
          id="file-item-list-upload-button"
          render={({ handleButtonClick }) => (
            <Button
              id="file-item-list-upload-button"
              variant="outlined"
              className={uploadButtonClasses.fileButton}
              onClick={handleButtonClick}
            >
              <AddCircleOutline fontSize={'small'} className={classes.addFilesIcon} />
              <FormattedMessage
                id="os-data-center-importer.ydDseD"
                defaultMessage="Add Files"
                description="Text of the label `Add Files`"
              />
            </Button>
          )}
        ></UploadButton>
      </Grid>
    </Box>
  );
};

export default FileItemList;
