import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    marginTop: '50px',
  },
  emptyState: {
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    flexDirection: 'column',
    gap: '15px',
  },
}));
