import { baseGraphQLApi } from '@aiware/shared/redux';

export const getCurrentUserApi = async (
  graphEndpoint: string,
  token?: string
): Promise<unknown> => {
  const query = `{
      myRights{
        operations
      }
      me {
        id
        organization{
          id
          imageUrl
          name
        }
      }
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};
