import { select, put, takeEvery } from 'redux-saga/effects';
import { selectApiConfigs } from '../selectors';
import { actions } from '../slices';
import { devErrorLogger } from '@aiware/shared/reusable-utils';
import api from '../../api';
import { GraphQLPage, TAuthACE, EResourceType } from '../../types';

export function* fetchExistingPermissionsSaga(
  action: ReturnType<typeof actions.permissionsWidget.fetchEntriesStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    let offset = 0;
    const ACLs: TAuthACE[] = [];
    let result: GraphQLPage<TAuthACE>;
    do {
      result = yield api.permissionsAPI.getACLs(apiConfigs, {
        resourceType: EResourceType[action.payload.resourceType] as unknown as EResourceType,
        ids: [action.payload.resourceId],
        limit: 30,
        offset,
      });
      ACLs.push(...result.records);
      offset += 30;
    } while (result.count === 30);
    yield put(
      actions.permissionsWidget.fetchEntriesSuccess({
        ...action.payload,
        entries: ACLs,
      })
    );
  } catch (e) {
    yield put(actions.adminCenterPermissions.fetchResoucesACLFailure());
    devErrorLogger(e);
  }
}

export function* fetchExistingPermissionsWatcher() {
  yield takeEvery(actions.permissionsWidget.fetchEntriesStart.type, fetchExistingPermissionsSaga);
}
