import { selectApiConfigs } from '../redux/selectors';
import { IScimConnectIdsResponse } from '../types';

export async function fetchScimIds(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  userId: string
): Promise<string[]> {
  const { apiRoot, token } = apiConfigs;
  const response = await fetch(`${apiRoot}/v1/admin/users/${userId}/scim_connect_ids`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 200) {
    return ((await response.json()) as IScimConnectIdsResponse).scimConnectIds;
  } else {
    throw new Error('Error getting scim_connect_ids');
  }
}
