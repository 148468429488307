import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { hidePanel } from '@aiware/js/panel';

import styles from './styles';
import PanelHeader from './PanelHeader';
import PanelBody from './PanelBody';
import { EMemberType, TUserGroup, TAuthGroupMemberType } from '../../types';
import { actions } from '../../redux/slices';
import { filterDefinedMemberRecords } from '../../helper';

const PANEL_ID = 'ADMIN_CENTER_NEW_GROUP_PANEL';

export const NewGroupPanel = () => {
  const dispatch = useDispatch();

  const handlePanelClose = () => {
    dispatch(hidePanel(PANEL_ID));
  };

  const handleGroupSave = (group: Partial<TUserGroup>) => {
    const { name = '', description = '' } = group;
    dispatch(
      actions.adminCenterGroups.createUserGroupStart({
        name,
        description,
        members: filterDefinedMemberRecords(group).map(
          member =>
            ({
              id: member.id,
              memberType: EMemberType.User,
            } as TAuthGroupMemberType)
        ),
      })
    );
    dispatch(hidePanel(PANEL_ID));
  };

  const initGroup: Partial<TUserGroup> = {};

  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[]}>
        <AIWareIntlProvider>
          <Box data-test="admin-center-new-group-panel" sx={styles['root']}>
            <PanelHeader onPanelClose={handlePanelClose} />
            <PanelBody onCancel={handlePanelClose} initGroup={initGroup} onSave={handleGroupSave} />
          </Box>
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
};
