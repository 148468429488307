import { FunctionComponent } from 'react';
import useStyles from './useStyles';
import { FolderOpen } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import { SHARED_TEXT } from '../../../helpers/shared-text';
import { IRootFolder } from '../../../types';

interface Props {
  folder: IRootFolder;
  disabled?: boolean;
  locationFolderTitle?: string;
  locationFolderSubText?: string;
  locationFolderInputLabel?: string;
  onChangeBtnClick: () => void;
}

const LocationFolder: FunctionComponent<Props> = ({
  folder,
  disabled = false,
  locationFolderTitle,
  locationFolderSubText,
  locationFolderInputLabel,
  onChangeBtnClick,
}: Props) => {
  const { classes } = useStyles();
  if (!folder) {
    return null;
  }
  return (
    <>
      <Typography
        variant="h2"
        color="textPrimary"
        data-test="data-center-location-folder-title"
        className={classes.title}
      >
        {SHARED_TEXT.locationFolderTitle(locationFolderTitle)}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        data-test="data-center-location-folder-subheader"
        className={classes.subheader}
      >
        {SHARED_TEXT.locationFolderSubText(locationFolderSubText)}
      </Typography>

      <div
        data-test="data-center-location-folder"
        className={classnames(
          classes.locationFolderWrapper,
          disabled ? classes.locationFolderWrapperDisabled : null
        )}
      >
        <span className={classes.folderLabel} data-test="data-center-location-folder-label">
          {SHARED_TEXT.locationFolderLabel(locationFolderInputLabel)}
        </span>

        <FolderOpen className={classes.folderIcon} />
        <Typography variant="body1" color="textPrimary" data-test="data-center-location-folder-path">
          {folder?.isRoot ? 'Home' : folder?.name}
        </Typography>

        <span className={classes.spacer} />

        <span
          className={classes.folderInputLabel}
          onClick={onChangeBtnClick}
          role={'button'}
          data-test="data-center-location-select-btn"
        >
          {SHARED_TEXT.locationFolderReselect()}
        </span>
      </div>
    </>
  );
};

export default LocationFolder;
