import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  removePadding: {
    padding: 0,
  },
  dropdownContainer: {
    padding: '5px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #E1E6ED',
    borderRadius: '7px',
    padding: '8px',
    minWidth: '240px',
  },
  searchInput: {
    border: '0',
    outline: 'none',
    width: '88%',
    fontFamily: "'Nunito'",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
  divLoader: {
    width: '300px',
    height: '350px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowLoader: {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  source: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px',
  },
  sourceList: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  sourceScrollContainer: {
    maxHeight: '240px',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  scheduleScrollContainer: {
    maxHeight: '300px',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'all',
    background: 'rgba(255,255,255,0.5)',
  },
  scheduleList: {
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
  },
  schedule: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    margin: '5px',
    marginTop: '1px',
    marginBottom: '1px',
    width: '300px',
    borderRadius: '8px',
    padding: '5px',

    '&.active-schedule': {
      background: '#F2F7FE',
    },
    '&:not(.active-schedule):hover': {
      background: '#F7F7F7',
    },
  },
}));
