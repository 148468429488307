import { AIWareThemeProvider } from '@aiware/shared/theme';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { getDataCenterSDOModule } from '../../redux';
import { AIWareCacheProvider } from '@aiware/shared/theme';
import { MainLayout } from './components/main-layout';
import { TDataRegistry } from '../../types';

export type TDataRegistryInfoProps = {
  dataRegistry: TDataRegistry;
};

export const DataRegistryInfo: React.FC<TDataRegistryInfoProps> = props => {
  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[getDataCenterSDOModule()]}>
        <AIWareIntlProvider>
          <AIWareCacheProvider>
            <MainLayout {...props} />
          </AIWareCacheProvider>
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
};

export default DataRegistryInfo;
