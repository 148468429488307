import { EngineCategoryIcons, MoreHor } from '@aiware/shared/icons';
import { makeStyles } from 'tss-react/mui';
import { Box, Divider, Icon, Typography } from '@mui/material';

const useStyles = makeStyles<void, 'enginesList'>()((theme, _props, classes) => ({
  engineIconContainer: {
    background: '#EEF3F9',
    padding: '3px',
    borderRadius: '5px',
    marginRight: '3px',
    maxWidth: '20px',
    maxHeight: '20px',
    verticalAlign: 'middle',
    display: 'flex',
  },
  iconWrapper: {
    position: 'relative',
    [`&:hover > .${classes.enginesList}`]: {
      display: 'block !important',
    },
    display: 'flex',
    alignItems: 'center',
  },
  tooltipCatName: {
    color: 'white',
    paddingLeft: '5px',
    fontWeight: '900',
  },
  engineIcon: {
    width: '14px',
    height: '14px',
  },
  enginesList: {
    background: 'rgba(0,0,0,.75)',
    padding: theme.spacing(0.6),
    borderRadius: '5px',
    marginRight: theme.spacing(0.6),
    position: 'absolute',
    top: 20,
    left: 0,
    display: 'none',
  },
  tooltipDivider: {
    marginTop: '3px',
  },
  enginesUL: {
    padding: '0 7px 0 20px',
    margin: '3px 0 !important',
    color: 'white',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& *': {
      color: 'white',
    },
  },
  tooltipIcon: {
    fill: 'white',
    width: '12px',
    height: '12px',
  },
  tooltipCatIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3px',
  },
  moreDivider: {
    borderTop: '2px solid white',
    marginBottom: '9px',
    marginTop: '6px',
  },
  moreIcon: {
    width: '12px',
    height: '12px',
  },
}));

const engineIconHelper = (value: string | undefined) => {
  if (value === undefined || !value) {
    return null;
  }
  return EngineCategoryIcons[value as keyof typeof EngineCategoryIcons];
};

type TEngineCategory = {
  id: string;
  name: string;
};

type TTaskEngine = {
  id?: string;
  name?: string;
  category?: TEngineCategory;
};

type TTask = {
  engine?: TTaskEngine;
};

const getCat = (task: TTask) => task?.engine?.category?.name?.replace(/[^a-zA-Z]/g, '');

const getTooltip = (catValue: string, engineNames: string[], classes: Record<string, string>) => {
  if (engineNames.length) {
    return (
      <Box>
        <Box className={classes.tooltipCatIconWrapper}>
          <Icon component={engineIconHelper(catValue)!} className={classes.tooltipIcon} />
          <Typography variant="caption" className={classes.tooltipCatName}>
            {catValue}
          </Typography>
        </Box>
        <Divider className={classes.tooltipDivider} />
        <ul className={classes.enginesUL}>
          {engineNames.map(engineName => (
            <li key={engineName}>
              <Typography variant="caption">{engineName}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    );
  }
  return null;
};

export const EngineIconsRenderer = ({
  tasks,
  iconsToRender = 3,
  showMore = false,
}: {
  tasks?: TTask[];
  iconsToRender?: number;
  showMore?: boolean;
}) => {
  const { classes } = useStyles();
  if (!tasks || !tasks.length || tasks.find(task => !task.engine)) {
    return (
      <Typography variant="caption" sx={{ color: 'black' }}>
        N/A
      </Typography>
    );
  }
  const filteredTasks = tasks.filter(task => {
    const categoryNameValue = getCat(task);
    return engineIconHelper(categoryNameValue);
  });

  const categoriesHashMap: { [key: string]: string[] } = {};
  const moreCategories: { [key: string]: string[] } = {};
  filteredTasks &&
    filteredTasks.forEach(filteredTask => {
      const categoryNameValue = getCat(filteredTask);
      if (Object.keys(categoriesHashMap).length < iconsToRender) {
        if (categoryNameValue && !categoriesHashMap[categoryNameValue]) {
          categoriesHashMap[categoryNameValue] = [];
        }
        if (categoryNameValue && filteredTask?.engine?.name) {
          categoriesHashMap[categoryNameValue]!.push(filteredTask?.engine?.name);
        }
      } else if (showMore) {
        if (categoryNameValue && !moreCategories[categoryNameValue]) {
          moreCategories[categoryNameValue] = [];
        }
        if (categoryNameValue && filteredTask?.engine?.name) {
          moreCategories[categoryNameValue]!.push(filteredTask?.engine?.name);
        }
      }
    });

  return (
    <>
      {Object.keys(categoriesHashMap).map((catValue: string) => {
        return (
          <Box className={classes.engineIconContainer} key={catValue}>
            <Box className={classes.iconWrapper}>
              <Icon key={catValue} component={engineIconHelper(catValue)!} className={classes.engineIcon} />
              <Box className={classes.enginesList}>
                {getTooltip(catValue, categoriesHashMap[catValue]!, classes)}
              </Box>
            </Box>
          </Box>
        );
      })}
      {Object.keys(moreCategories).length > 0 && (
        <Box className={classes.iconWrapper}>
          <MoreHor className={classes.moreIcon} />
          <Box className={classes.enginesList}>
            {Object.keys(moreCategories).map((catValue: string, index: number) => (
              <Box key={`${catValue}-${index}`}>
                {getTooltip(catValue, moreCategories[catValue]!, classes)}
                {index < Object.keys(moreCategories).length - 1 && (
                  <Divider className={classes.moreDivider} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
