import { FC, FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { AiAutocompleteProvider } from './AiAutocomplete.context';
import { AiSearchBar, IAiSearchBar } from './components/AiSearchBar/AiSearchBar';
import { AiSuggestions } from './components/AiSuggestions/AiSuggestions';
import { AiStructuredData } from './components/AiStructuredData/AiStructuredData';
import { CapabilityType, ICriterion, ISuggestion, ISuggestionResult } from '../../types/aiAutocomplete.types';
import { actions } from '../../../../redux/slices';
import { DataCenterView } from '@aiware/shared/redux';
import { useDispatch } from 'react-redux';

interface IAutocompleteComposition {
  AiSearchBar: FunctionComponent<IAiSearchBar>;
  AiSuggestions: FunctionComponent;
  AiStructuredData: FunctionComponent;
}

interface IAiAutocompleteProps {
  suggestionsResult: ISuggestionResult;
  isLoading: boolean;
  isOpen: boolean;
  searchTerm: string;
  shouldClearSearchBar: boolean;
  onResetShouldClearSearchBar: () => void;
  onChange: (value: string) => void;
  onSearchCallback: (criteria: ICriterion[]) => void;
  children: ReactNode | ReactNode[];
}

const AiAutocomplete: FC<IAiAutocompleteProps> & IAutocompleteComposition = ({
  suggestionsResult,
  isLoading,
  isOpen,
  searchTerm,
  shouldClearSearchBar,
  onResetShouldClearSearchBar,
  onChange,
  onSearchCallback,
  children,
}) => {
  const [criteria, setCriteria] = useState<ICriterion[]>([]);
  const [shouldClearSearchTerm, setShouldClearSearchTerm] = useState(false);
  const dispatch = useDispatch();

  const performSearchByCriteriaCallback = useCallback((): void => {
    if (criteria.length > 0) {
      if (onSearchCallback) {
        onSearchCallback(criteria);

        // Below updates the DC uiState to show the Search Results view
        dispatch(actions.uiState.setCurrentView(DataCenterView.search));
      }
    }
  }, [criteria]);

  // Clear out the search bar
  useEffect(() => {
    if (shouldClearSearchBar) {
      setShouldClearSearchTerm(true);
      setCriteria([]);

      onResetShouldClearSearchBar();
    }
  }, [shouldClearSearchBar, onResetShouldClearSearchBar]);

  // Perform search by criteria when user selects a suggestion
  useEffect(() => {
    performSearchByCriteriaCallback();
  }, [criteria]);

  return (
    <AiAutocompleteProvider
      value={{
        isOpen: isOpen,
        isLoading: isLoading,
        searchTerm: searchTerm,
        suggestionResult: suggestionsResult,
        criteria: criteria,
        shouldClearSearchTerm: shouldClearSearchTerm,
        actions: {
          onChange: onChange,
          onOutsideClick: handleOnOutsideClick,
          onSuggestionClick: handleOnSuggestionClick,
          onDeleteCriterionClick: handleOnDeleteCriterionClick,
          onTextBasedCapabilityClick: handleOnTextBasedCapabilityClick,
          onEnterClick: handleOnEnterClick,
          onResetShouldClearSearchTerm: handleOnResetShouldClearSearchTerm,
        },
      }}
    >
      {children}
    </AiAutocompleteProvider>
  );

  function handleOnSuggestionClick(suggestion: ISuggestion): void {
    const { id, entityId, label, capabilityType } = suggestion;

    addCriteria(id, entityId || '', label || '', capabilityType);
  }

  function handleOnTextBasedCapabilityClick(
    id: string,
    entityId: string,
    label: string,
    capabilityType: CapabilityType
  ): void {
    addCriteria(id, entityId, label, capabilityType);
  }

  function addCriteria(id: string, entityId: string, label: string, capabilityType: CapabilityType): void {
    const criterion: ICriterion = {
      id,
      entityId,
      label,
      capabilityType,
    };

    // We don't want duplicates
    if (criteria.find(criterion => criterion.id === id)) return;

    // TODO: Remove this check for phase 2. For phase 1 we only support one criterion
    if (criteria.length === 1) return;

    setCriteria([...criteria, criterion]);
    dispatch(actions.uiState.setActiveTab(null));
  }

  function handleOnDeleteCriterionClick(criterion: ICriterion): void {
    setCriteria(criteria.filter(c => c.id !== criterion.id));
  }

  function handleOnEnterClick(): void {
    performSearchByCriteriaCallback();
  }

  function handleOnOutsideClick(): void {
    setShouldClearSearchTerm(true);
    setCriteria([]);
  }

  function handleOnResetShouldClearSearchTerm(): void {
    setShouldClearSearchTerm(false);
  }
};

AiAutocomplete.AiSearchBar = AiSearchBar;
AiAutocomplete.AiSuggestions = AiSuggestions;
AiAutocomplete.AiStructuredData = AiStructuredData;

export { AiAutocomplete };
