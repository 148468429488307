import { ISagaModule } from 'redux-dynamic-modules-saga';
import { SLICE_NAME } from '../types/auditLog.types';
import { rootReducer } from './slices';
import { rootSaga } from './sagas';
import * as auditLogItems from './auditLog.redux';

export function getAuditLogModule(): ISagaModule<typeof rootReducer> {
  return {
    id: SLICE_NAME,
    reducerMap: {
      [SLICE_NAME]: rootReducer,
    },
    sagas: [rootSaga],
    initialActions: [],
  };
}

export * from './slices';
export { auditLogItems };
