import { LoadingStatus } from '@aiware/js/interfaces';
import { EResourceType, SLICE_NAME, TAuthACE } from '../../types';
import { TPermissionsWidgetState, namespace } from '../slices/permissions-widget.state';

type TState = {
  [SLICE_NAME]: {
    [namespace]: TPermissionsWidgetState;
  };
};

export const selectExistingPermissionsByResource =
  (resource?: { resourceType: EResourceType; resourceId: string }) =>
  (state: TState): { status: LoadingStatus; entries?: TAuthACE[] } | undefined => {
    if (!resource) {
      return undefined;
    }
    return state[SLICE_NAME][namespace].existingEntriesByResource[resource.resourceType]?.[
      resource.resourceId
    ];
  };
