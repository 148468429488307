import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useStyles } from '../useStyles';
import { selectIsFluffy } from '../../../redux/selectors/ui-state';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import GetRowItemHeader from '../../shared/GetRowItemHeader';
import { EntityType } from '@aiware/js/interfaces';
import { Box } from '@mui/material';

const FoldersAndFilesTableHeader: FunctionComponent = () => {
  const { classes } = useStyles();
  const isFluffy = useSelector(selectIsFluffy);

  return (
    <>
      {!isFluffy && (
        <>
          <Grid container className={classes.root}>
            <Grid
              data-test={'folders-table-header'}
              className={classNames(classes.header, classes.root)}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              container
              item
              xs={11}
            >
              <Box width={'40px'}></Box>
              <GetRowItemHeader item={'fileType'} xs={1} itemType={EntityType.Folders} />
              <GetRowItemHeader item={'name'} itemType={EntityType.Folders} />
              <GetRowItemHeader item={'modifiedDateTime'} xs={3} itemType={EntityType.Folders} />
              <GetRowItemHeader item={'engineIds'} xs={3} itemType={EntityType.Folders} />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
    </>
  );
};

export default FoldersAndFilesTableHeader;
