import { all } from 'redux-saga/effects';
import {
  fetchUserWallpapersWatcher,
  setCurrentWallpaperWatcher,
  removeCustomWallpaperWatcher,
  getTDOAssetsWatcher,
  handleSetCustomWallpaperWatcher,
  fetchUserCurrentWallpaperTDOWatcher,
} from './watchers';

export function* rootSaga() {
  yield all([
    fetchUserWallpapersWatcher(),
    setCurrentWallpaperWatcher(),
    removeCustomWallpaperWatcher(),
    getTDOAssetsWatcher(),
    handleSetCustomWallpaperWatcher(),
    fetchUserCurrentWallpaperTDOWatcher(),
  ]);
}
