import { Stack, InputLabel, MenuItem, FormControl, Select, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/slices';
import { TSchema } from '../../../types';
import {
  selectViewerPanelDataRegistrySchemas,
  selectViewerPanelActiveSchema,
} from '../../../redux/selectors';

export const SchemaDropdown = () => {
  const dispatch = useDispatch();
  const activeSchema = useSelector(selectViewerPanelActiveSchema);
  const schemas = useSelector(selectViewerPanelDataRegistrySchemas) as TSchema[];

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(actions.dataRegistry.setActiveSchemaId(event.target.value as string));
  };

  const label = `${schemas.length} Schema${schemas.length > 1 ? 's' : ''}`;
  const renderMenuItems = () => {
    if (!schemas?.length) return null;

    return schemas.map((schema, index) => {
      const name = schema.definition.title || 'Untilted Schema';
      const description = schema.definition.description || null;
      return (
        <MenuItem data-testid={`schema-dropdown-select-item-${index + 1}`} key={schema.id} value={schema.id}>
          <Stack>
            <Typography variant={'body2'} data-testid="schema-dropdown-select-item-option">
              {name} {schema.majorVersion}.{schema.minorVersion}
            </Typography>
            {description && <Typography variant={'caption'}>{description}</Typography>}
          </Stack>
        </MenuItem>
      );
    });
  };

  if (schemas?.length === 1) return null;

  return (
    <Stack alignItems={'flex-end'} data-testid="dc-sdo-schema-dropdown-stack">
      <FormControl size={'small'}>
        <InputLabel id="dc-sdo-schema-dropdown-label" data-testid="dc-sdo-schema-dropdown-label">
          {label}
        </InputLabel>
        <Select
          data-testid={`dc-sdo-schema-dropdown-select`}
          labelId="dc-sdo-schema-dropdown-label"
          id="dc-sdo-schema-dropdown"
          value={activeSchema || ''}
          label={label}
          onChange={handleChange}
          size={'small'}
        >
          {renderMenuItems()}
        </Select>
      </FormControl>
    </Stack>
  );
};
