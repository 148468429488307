import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  backButton: {
    background: 'none',
    color: 'black',
    marginRight: '20px',
    ':hover': {
      background: '#D3D3D3',
    },
  },
  root: {
    width: 1020,
    position: 'relative',
  },
  panel: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  expand: {
    marginTop: '-20px',
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  fullScreenButton: {
    marginTop: '-50px !important',
  },
  tabs: {
    '& .Sdk-MuiTabs-indicator': {
      backgroundColor: 'black',
      color: 'black',
    },
    '& .Sdk-Mui-selected': {
      color: 'black',
    },
  },
  bottomButtons: {
    marginTop: '20px',
  },
  markdown: {
    height: '100% !important',
    background: '#303841',
  },
  previewMarkdown: {
    height: '100% !important',
    position: 'relative',
    padding: '20px !important',
    background: '#303841',
  },
  previewMarkdownContent: {
    background: 'white',
    padding: '15px',
    borderRadius: '3px',
  },
}));

export default useStyles;
