import useStyles from './useStyles';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

interface ITabHeader {
  tab: 'invites' | 'my-organizations';
}
export default function TabHeader({ tab }: ITabHeader) {
  const { classes } = useStyles();
  const SHARED_TEXT = {
    invites: {
      header: (
        <FormattedMessage
          id="os-organization-panel.invites.tab-header"
          defaultMessage="Invites"
          description="organization panel: invites tab, invites header label"
        />
      ),
      description: (
        <FormattedMessage
          id="os-organization-panel.invites.tab-description"
          defaultMessage="Review invitations to other organizations."
          description="organization panel: invites tab, invites header description"
        />
      ),
    },
    'my-organizations': {
      header: (
        <FormattedMessage
          id="os-organization-panel.organizations.tab-header"
          defaultMessage="Organizations"
          description="organization panel: organizations tab, header label"
        />
      ),
      description: (
        <FormattedMessage
          id="os-organization-panel.organizations.tab-description"
          defaultMessage="Switch between other organizations you have access to."
          description="organization panel: Organizations tab, header description"
        />
      ),
    },
  };
  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        className={classes.header}
        sx={{ fontSize: '14px!important' }}
        data-testid={`organizations-panel-tab-header-${tab}`}
      >
        {SHARED_TEXT[tab].header}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.description}
        sx={{ fontSize: '14px!important' }}
        data-testid={`organizations-panel-tab-header-${tab}`}
      >
        {SHARED_TEXT[tab].description}
      </Typography>
    </div>
  );
}
