import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import { Time } from '@aiware/shared/icons';
import { useStyles } from '../../components/useStyles';
import { useSelector } from 'react-redux';
import { selectTableDataSettings } from '../../../redux/selectors/ui-state';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const ScheduleType: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const { classes } = useStyles();
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showScheduleType = columns.indexOf('scheduleType') >= 0;

  return (
    <>
      {showScheduleType && (
        <Grid data-test={'item-scheduleType'} className={classes.type} item xs={xs}>
          <Icon
            data-test={'scheduleType-icon'}
            className={classes.sourceIcon}
            component={Time}
            color="secondary"
          />
        </Grid>
      )}
    </>
  );
};

export default ScheduleType;
