import { FullyNetworkIsolated } from './FullyNetworkIsolated';
import { FullyNetworkIsolatedWhite } from './FullyNetworkIsolatedWhite';
import { MostlyNetworkIsolated } from './MostlyNetworkIsolated';
import { MostlyNetworkIsolatedWhite } from './MostlyNetworkIsolatedWhite';
import { NonNetworkIsolated } from './NonNetworkIsolated';
import { NonNetworkIsolatedWhite } from './NonNetworkIsolatedWhite';
import { HumanReview } from './HumanReview';
import { HumanReviewWhite } from './HumanReviewWhite';

export const DeploymentModelIcons = {
  FullyNetworkIsolated,
  FullyNetworkIsolatedWhite,
  MostlyNetworkIsolated,
  MostlyNetworkIsolatedWhite,
  NonNetworkIsolated,
  NonNetworkIsolatedWhite,
  HumanReview,
  HumanReviewWhite,
};
