import createSvgIcon from '../createSvgIcon';
export const List = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.74643 8.9884C7.99539 8.9884 6.24435 8.9884 4.48952 8.9884C4.24695 8.9884 4.00059 8.9884 3.75803 8.9884C4.01196 9.24234 4.26211 9.49249 4.51605 9.74643C4.51605 7.99539 4.51605 6.24435 4.51605 4.48952C4.51605 4.24695 4.51605 4.00059 4.51605 3.75803C4.26211 4.01196 4.01196 4.26211 3.75803 4.51605C5.50906 4.51605 7.2601 4.51605 9.01493 4.51605C9.2575 4.51605 9.50386 4.51605 9.74643 4.51605C9.49249 4.26211 9.24234 4.01196 8.9884 3.75803C8.9884 5.50906 8.9884 7.2601 8.9884 9.01493C8.9884 9.2575 8.9884 9.50386 8.9884 9.74643C8.9884 10.1444 9.33709 10.5234 9.74643 10.5045C10.1558 10.4855 10.5045 10.1709 10.5045 9.74643C10.5045 7.99539 10.5045 6.24435 10.5045 4.48952C10.5045 4.24695 10.5045 4.00059 10.5045 3.75803C10.5045 3.34869 10.1558 3 9.74643 3C7.99539 3 6.24435 3 4.48952 3C4.24695 3 4.00059 3 3.75803 3C3.34869 3 3 3.34869 3 3.75803C3 5.50906 3 7.2601 3 9.01493C3 9.2575 3 9.50386 3 9.74643C3 10.1558 3.34869 10.5045 3.75803 10.5045C5.50906 10.5045 7.2601 10.5045 9.01493 10.5045C9.2575 10.5045 9.50386 10.5045 9.74643 10.5045C10.1444 10.5045 10.5234 10.1558 10.5045 9.74643C10.4893 9.3333 10.1747 8.9884 9.74643 8.9884Z" />
    <path d="M11.9447 6.4415C12.8809 6.4415 13.817 6.4415 14.7532 6.4415C16.2389 6.4415 17.7246 6.4415 19.2104 6.4415C19.5553 6.4415 19.8964 6.4415 20.2413 6.4415C20.6392 6.4415 21.0183 6.09281 20.9993 5.68347C20.9804 5.27414 20.6658 4.92545 20.2413 4.92545C19.3051 4.92545 18.369 4.92545 17.4328 4.92545C15.9471 4.92545 14.4613 4.92545 12.9756 4.92545C12.6307 4.92545 12.2896 4.92545 11.9447 4.92545C11.5467 4.92545 11.1677 5.27414 11.1867 5.68347C11.2018 6.09281 11.5164 6.4415 11.9447 6.4415Z" />
    <path d="M11.9447 8.57954C13.4304 8.57954 14.9199 8.57954 16.4057 8.57954C16.6179 8.57954 16.8264 8.57954 17.0386 8.57954C17.4366 8.57954 17.8156 8.23084 17.7967 7.82151C17.7777 7.41218 17.4631 7.06348 17.0386 7.06348C15.5529 7.06348 14.0634 7.06348 12.5776 7.06348C12.3654 7.06348 12.1569 7.06348 11.9447 7.06348C11.5467 7.06348 11.1677 7.41218 11.1867 7.82151C11.2018 8.23084 11.5164 8.57954 11.9447 8.57954Z" />
    <path d="M9.74643 18.9132C7.99539 18.9132 6.24435 18.9132 4.48952 18.9132C4.24695 18.9132 4.00059 18.9132 3.75803 18.9132C4.01196 19.1671 4.26211 19.4173 4.51605 19.6712C4.51605 17.9202 4.51605 16.1692 4.51605 14.4143C4.51605 14.1718 4.51605 13.9254 4.51605 13.6828C4.26211 13.9368 4.01196 14.1869 3.75803 14.4409C5.50906 14.4409 7.2601 14.4409 9.01493 14.4409C9.2575 14.4409 9.50386 14.4409 9.74643 14.4409C9.49249 14.1869 9.24234 13.9368 8.9884 13.6828C8.9884 15.4339 8.9884 17.1849 8.9884 18.9397C8.9884 19.1823 8.9884 19.4287 8.9884 19.6712C8.9884 20.0692 9.33709 20.4482 9.74643 20.4293C10.1558 20.4103 10.5045 20.0957 10.5045 19.6712C10.5045 17.9202 10.5045 16.1692 10.5045 14.4143C10.5045 14.1718 10.5045 13.9254 10.5045 13.6828C10.5045 13.2735 10.1558 12.9248 9.74643 12.9248C7.99539 12.9248 6.24435 12.9248 4.48952 12.9248C4.24695 12.9248 4.00059 12.9248 3.75803 12.9248C3.34869 12.9248 3 13.2735 3 13.6828C3 15.4339 3 17.1849 3 18.9397C3 19.1823 3 19.4287 3 19.6712C3 20.0806 3.34869 20.4293 3.75803 20.4293C5.50906 20.4293 7.2601 20.4293 9.01493 20.4293C9.2575 20.4293 9.50386 20.4293 9.74643 20.4293C10.1444 20.4293 10.5234 20.0806 10.5045 19.6712C10.4893 19.2619 10.1747 18.9132 9.74643 18.9132Z" />
    <path d="M11.9447 16.3663C12.8809 16.3663 13.817 16.3663 14.7532 16.3663C16.2389 16.3663 17.7246 16.3663 19.2104 16.3663C19.5553 16.3663 19.8964 16.3663 20.2413 16.3663C20.6392 16.3663 21.0183 16.0176 20.9993 15.6083C20.9804 15.1989 20.6658 14.8503 20.2413 14.8503C19.3051 14.8503 18.369 14.8503 17.4328 14.8503C15.9471 14.8503 14.4613 14.8503 12.9756 14.8503C12.6307 14.8503 12.2896 14.8503 11.9447 14.8503C11.5467 14.8503 11.1677 15.1989 11.1867 15.6083C11.2018 16.0214 11.5164 16.3663 11.9447 16.3663Z" />
    <path d="M11.9447 18.5043C13.4304 18.5043 14.9199 18.5043 16.4057 18.5043C16.6179 18.5043 16.8264 18.5043 17.0386 18.5043C17.4366 18.5043 17.8156 18.1556 17.7967 17.7463C17.7777 17.337 17.4631 16.9883 17.0386 16.9883C15.5529 16.9883 14.0634 16.9883 12.5776 16.9883C12.3654 16.9883 12.1569 16.9883 11.9447 16.9883C11.5467 16.9883 11.1677 17.337 11.1867 17.7463C11.2018 18.1556 11.5164 18.5043 11.9447 18.5043Z" />
  </svg>,
  'List'
);
