import React from 'react';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { getDataCenterSDOModule } from '../../../../redux';
import { EditDataRegistryMainLayout } from './main-layout';

export type IEditDataRegistryProps = {
  dataRegistryId: string;
};

export const EditDataRegistryPanel: React.FC<IEditDataRegistryProps> = props => {
  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[getDataCenterSDOModule()]}>
        <AIWareIntlProvider>
          <EditDataRegistryMainLayout {...props} />
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
};

export default EditDataRegistryPanel;
