import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import { selectIsFluffy } from '../../../../redux/selectors/ui-state';
import { selectCurrentTdoTableDetails } from '../../../../redux/selectors/view-streams';
import { actions } from '../../../../redux/slices';
import { IFileVm, ScheduleId } from '../../../../types';
import TdoTableHeader from './TdoTableHeader';
import rowHeight from '../../../../helpers/rowHeight';
import keyHelper from '../../../../helpers/keyHelper';
import InfiniteWrapper from '../../../shared/InfiniteWrapper';

const TdoTable: FunctionComponent<{
  parentScheduleId: ScheduleId;
  items: IFileVm[];
}> = ({ parentScheduleId, items }) => {
  const isFluffy = useSelector(selectIsFluffy);
  const tdoPage = useSelector(selectCurrentTdoTableDetails);
  const hasNextPage = tdoPage?.hasNextPage;

  if (!tdoPage) {
    return null;
  }

  return (
    <>
      <TdoTableHeader />
      <InfiniteWrapper
        id={keyHelper(isFluffy) + EntityType.Tdos}
        items={items}
        hasNextPage={hasNextPage}
        itemSize={rowHeight(isFluffy)}
        loadAction={actions.viewStreams.tdosFetchStart(parentScheduleId)}
        status={tdoPage?.status}
        rowType={EntityType.Tdos}
        parentEntityId={parentScheduleId}
      />
    </>
  );
};
export default TdoTable;
