import { ECapabilityTypes, CapabilityType } from '../types/aiAutocomplete.types';
import { removeTimeStringSeconds } from '../components/AiSearch/helpers/sharedFunctions';
import { Entity, TCondition, TFileSearchQuery } from '../types/aiSearch.types';
import { AiSearchFilterType } from '../state/aiSearchFilter/aiSearchFilter.slice';
import {
  EStringOperatorType,
  EValidJSONSchemaType,
  IStructuredDataInput,
  EJSONSchemaStringFormat,
  ENumericOperatorType,
} from '../types/aiStructuredData.types';

export const getFieldByCapabilityType = (capability: CapabilityType) => {
  switch (capability) {
    case ECapabilityTypes.tags:
      return 'tags.displayName';
    case ECapabilityTypes.logo:
      return 'logo-recognition.series.found';
    case ECapabilityTypes.object:
      return 'objects.displayName';
    case ECapabilityTypes.facial:
      return 'entityName';
    case ECapabilityTypes.recognizedText:
      return 'text-recognition.series.ocrtext';
    case ECapabilityTypes.keyword:
      return 'transcript.transcript';
    case ECapabilityTypes.fileName:
      return 'veritone-file.filename';
    case ECapabilityTypes.dataRegistry:
    case ECapabilityTypes.source:
      return capability;
    default:
      return '';
  }
};

export function getFileType(fileItem: any) {
  return fileItem?.primaryAsset?.contentType;
}

export function getId(fileItem: any) {
  return fileItem?.id;
}

export function getFileName(fileItem: any) {
  return (
    fileItem?.details?.veritoneFile?.filename || fileItem?.details?.veritoneFile?.fileName || fileItem?.name
  );
}

export function getCreatedDate(fileItem: any) {
  return new Date(fileItem?.modifiedDateTime).toLocaleDateString('en-US');
}

export function getCreatedTime(fileItem: any) {
  return removeTimeStringSeconds(new Date(fileItem?.modifiedDateTime).toLocaleTimeString('en-US'));
}

export const getConditions = (
  entity: Entity,
  entityId: string | undefined,
  text: string,
  type: CapabilityType,
  structuredData: IStructuredDataInput
) => {
  switch (type) {
    case 'FacialDetection':
      return [
        {
          field: 'face-recognition.series.entityId',
          not: false,
          operator: 'term',
          value: entityId,
        },
      ];
    case 'KeywordExtraction':
      return [
        {
          field: 'transcript.transcript',
          operator: 'query_string',
          value: text.toLowerCase(),
        },
      ];
    case 'RecognizedText':
      return [
        {
          field: 'text-recognition.series.ocrtext',
          highlight: true,
          operator: 'query_string',
          value: text.toLowerCase(),
        },
      ];
    case 'LogoRecognition':
      return [
        {
          field: 'logo-recognition.series.found',
          not: false,
          operator: 'term',
          value: text.toLowerCase(),
        },
      ];
    case 'ObjectDetection':
      return [
        {
          field: 'object-recognition.series.found',
          not: false,
          operator: 'term',
          value: text.toLowerCase(),
        },
      ];
    case 'Tags':
      return [
        {
          operator: 'query_object',
          field: 'tags',
          not: false,
          query: {
            operator: 'term',
            field: 'tags.value',
            value: text.toLowerCase(),
            dotNotation: true,
          },
        },
      ];
    case 'AiStructuredData':
      return getStructeredDataConditions(structuredData);
    case 'FileName':
      return [
        {
          field: 'veritone-file.filename',
          operator: 'query_string',
          value: text.toLowerCase(),
        },
      ];
    default:
      return [];
  }
};
const getStructeredDataConditions = (structuredData: IStructuredDataInput): TCondition[] => {
  const structuredDataConditions: TCondition[] = [];
  if (
    structuredData.propertyDefinition.type === EValidJSONSchemaType.string &&
    (!structuredData.propertyDefinition.format ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.email ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.idnEmail ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.hostname ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.ipv4 ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.ipv6 ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.iri ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.iriReference ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.uriTemplate ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.jsonPointer ||
      structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.regex)
  ) {
    switch (structuredData.operator) {
      case EStringOperatorType.is: {
        structuredDataConditions.push({
          field: `${structuredData.searchPath}.string`,
          operator: 'term',
          value: structuredData.matchValueOne,
        });
        break;
      }
      case EStringOperatorType.is_not: {
        structuredDataConditions.push({
          field: `${structuredData.searchPath}.string`,
          operator: 'term',
          value: structuredData.matchValueOne,
          not: true,
        });
        break;
      }
      case EStringOperatorType.contains: {
        structuredDataConditions.push({
          field: `${structuredData.searchPath}.fulltext`,
          operator: 'query_string',
          value: `*${structuredData.matchValueOne}*`,
        });
        break;
      }
      case EStringOperatorType.not_contains: {
        structuredDataConditions.push({
          field: `${structuredData.searchPath}.fulltext`,
          operator: 'query_string',
          value: `*${structuredData.matchValueOne}*`,
          not: true,
        });
        break;
      }
    }
  }
  if (structuredData.propertyDefinition.type === EValidJSONSchemaType.boolean) {
    structuredDataConditions.push({
      field: structuredData.searchPath,
      operator: 'term',
      value: `${structuredData.matchValueOne}`,
    });
  }

  if (
    (structuredData.propertyDefinition.type === EValidJSONSchemaType.string &&
      (structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.date ||
        structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.time ||
        structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.dateTime ||
        structuredData.propertyDefinition?.format === EJSONSchemaStringFormat.duration)) ||
    structuredData.propertyDefinition.type === EValidJSONSchemaType.integer ||
    structuredData.propertyDefinition.type === EValidJSONSchemaType.number
  ) {
    switch (structuredData.operator) {
      case ENumericOperatorType.is: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'query_string',
          value: structuredData.matchValueOne,
        });
        break;
      }
      case ENumericOperatorType.is_not: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'query_string',
          value: structuredData.matchValueOne,
          not: true,
        });
        break;
      }
      case ENumericOperatorType.gt: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'range',
          gt: structuredData.matchValueOne,
        });
        break;
      }
      case ENumericOperatorType.gte: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'range',
          gte: structuredData.matchValueOne,
        });
        break;
      }
      case ENumericOperatorType.lt: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'range',
          lt: structuredData.matchValueOne,
        });
        break;
      }
      case ENumericOperatorType.lte: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'range',
          lte: structuredData.matchValueOne,
        });
        break;
      }
      case ENumericOperatorType.range: {
        structuredDataConditions.push({
          field: structuredData.searchPath,
          operator: 'range',
          gte: structuredData.matchValueOne,
          lte: structuredData.matchValueTwo,
        });
        break;
      }
    }
  }

  return structuredDataConditions;
};

export const getFilterConditions = (filters: AiSearchFilterType): TCondition[] => {
  const filterConditions: TCondition[] = [];

  for (const key in filters) {
    const currentObj = filters[key];

    if (currentObj?.values.length) {
      const filterType = currentObj?.filterType;

      switch (filterType) {
        case 'fileType': {
          filterConditions.push({
            operator: 'terms',
            field: currentObj.filterType,
            values: currentObj.values,
          });
          break;
        }

        case 'engineType': {
          filterConditions.push({
            operator: 'terms',
            field: 'veritone-job.engines',
            values: currentObj.values,
          });
          break;
        }
        case 'absoluteStartTimeMs': {
          const [gte, lte, operator] = currentObj.values;
          if (gte && lte) {
            filterConditions.push({
              operator: operator ?? '',
              field: currentObj.filterType,
              gte,
              lte,
            });
          }

          break;
        }
        case 'mediaSourceId': {
          filterConditions.push({
            operator: 'terms',
            field: 'mediaSourceId',
            values: currentObj.values,
          });
          break;
        }
        case 'programId': {
          filterConditions.push({
            operator: 'terms',
            field: 'programId',
            values: currentObj.values,
          });
          break;
        }

        default: {
          filterConditions.push({
            operator: 'term',
            field: currentObj.filterType,
            values: currentObj.values,
          });
          break;
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filterConditions;
};

export const queryBuilder = (
  _searchFolder: string,
  _isRootFolder: boolean,
  entity: Entity,
  text: string,
  capabilityType: CapabilityType,
  entityId: string | undefined,
  structuredData: IStructuredDataInput,
  filters?: AiSearchFilterType
) => {
  const query: TFileSearchQuery = {
    operator: 'and',
    conditions: getConditions(entity, entityId, text, capabilityType, structuredData),
  };

  // TODO: keep this code in case we ever want to add a filter condition that searches by specific folder
  // const folderConditions: TCondition = {
  //   operator: 'or',
  //   conditions: [],
  // };
  //
  // if (_isRootFolder && folderConditions.conditions) {
  //   folderConditions.conditions.push({
  //     operator: 'terms',
  //     field: 'parentTreeObjectIds',
  //     values: [_searchFolder],
  //   });
  //   folderConditions.conditions.push({
  //     operator: 'exists',
  //     name: 'time-slice.parentTreeObjectIds',
  //     not: true,
  //   });
  // } else if (!_isRootFolder && folderConditions.conditions) {
  //   folderConditions.conditions.push({
  //     operator: 'terms',
  //     field: 'parentTreeObjectIds',
  //     values: [_searchFolder],
  //   });
  // }

  //query.conditions.push(folderConditions);

  // TODO: Work in progress
  if (filters) {
    const filterConditions: TCondition[] = getFilterConditions(filters);

    if (filterConditions?.length > 0) {
      query.conditions = [...query.conditions, ...filterConditions];
    }
  }

  return query;
};
