import createSvgIcon from '../createSvgIcon';
export const CheckmarkFilled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM7 12.2656L10.5714 16.4642L17 8.90665L15.9929 7.71423L10.5714 14.0878L8.00714 11.0816L7 12.2656Z"
    />
  </svg>,
  'CheckmarkFilled'
);
