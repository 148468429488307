import { generateSagas, generateSingleSagas } from './saga';
import { generateSelectors, generateSingleSelectors } from './selectors';
import generateSlice, { generateSingleSlice } from './slice';
import {
  GeneratedState,
  GeneratedSingleState,
  IApiCalls,
  IApiSingleCalls,
  IGlobalState,
  IGlobalSingleState,
  IToastMessages,
  DeepPartialToast,
} from './types';

export interface IProps<T> {
  sliceName: string;
  namespace: string;
  customReduxNamespace?: string;
  overrideSelectorBase?: (state: IGlobalState<T>) => GeneratedState<T, void>;
  dedupeResponse: boolean;
  dedupeIdProp?: string;
  apiCalls: IApiCalls<T>;
  /** Toast Messages */
  toast?: DeepPartialToast<IToastMessages> | true;
}

export interface ISingleProps<T, A extends Record<string, unknown>> {
  sliceName: string;
  namespace: string;
  customReduxNamespace?: string;
  overrideSelectorBase?: (state: IGlobalSingleState<T, A>) => GeneratedSingleState<T, A>;
  apiCalls: IApiSingleCalls<T>;
  additionalState?: A;
  /** Toast Messages */
  toast?: DeepPartialToast<IToastMessages> | true;
}

const DEFAULT_TOAST_MESSAGES: IToastMessages = {
  delete: {
    success: 'Successfully deleted',
    error: 'Failed to delete',
  },
  create: {
    success: 'Successfully created',
    error: 'Failed to create',
  },
  update: {
    success: 'Successfully updated',
    error: 'Failed to update',
  },
};

export const generateState = <T, U = void>(props: IProps<T>) => {
  const {
    sliceName,
    namespace,
    dedupeResponse,
    dedupeIdProp,
    apiCalls,
    customReduxNamespace,
    overrideSelectorBase,
  } = props;

  const toast =
    typeof props.toast === 'boolean'
      ? DEFAULT_TOAST_MESSAGES
      : typeof props.toast === 'object'
      ? {
          delete: {
            ...DEFAULT_TOAST_MESSAGES.delete,
            ...(props.toast?.delete ?? {}),
          },
          create: {
            ...DEFAULT_TOAST_MESSAGES.create,
            ...(props.toast?.create ?? {}),
          },
          update: {
            ...DEFAULT_TOAST_MESSAGES.update,
            ...(props.toast?.update ?? {}),
          },
        }
      : undefined;

  const { slice, initialState } = generateSlice<T, U>({
    sliceName,
    namespace,
    dedupeResponse,
    dedupeIdProp,
    customReduxNamespace,
  });
  const selectors = generateSelectors<T>({
    namespace,
    sliceName,
    customReduxNamespace,
    overrideSelectorBase,
  });
  const sagas = generateSagas<T>({ slice, selectors, apiCalls, toast });
  return {
    slice,
    selectors,
    sagas,
    initialState,
  };
};

// eslint-ignore-next-line
export const generateSingleState = <T, A extends Record<string, unknown> = Record<string, never>, _U = void>(
  props: ISingleProps<T, A>
) => {
  const {
    sliceName,
    namespace,
    apiCalls,
    customReduxNamespace,
    overrideSelectorBase,
    additionalState = {} as A,
  } = props;

  const toast =
    typeof props.toast === 'boolean'
      ? DEFAULT_TOAST_MESSAGES
      : typeof props.toast === 'object'
      ? {
          delete: {
            ...DEFAULT_TOAST_MESSAGES.delete,
            ...(props.toast?.delete ?? {}),
          },
          create: {
            ...DEFAULT_TOAST_MESSAGES.create,
            ...(props.toast?.create ?? {}),
          },
          update: {
            ...DEFAULT_TOAST_MESSAGES.update,
            ...(props.toast?.update ?? {}),
          },
        }
      : undefined;

  const { slice, initialState } = generateSingleSlice<T, A>({
    sliceName,
    namespace,
    customReduxNamespace,
    additionalState,
  });
  const selectors = generateSingleSelectors<T, A>({
    namespace,
    sliceName,
    customReduxNamespace,
    overrideSelectorBase,
    additionalState,
  });

  const sagas = generateSingleSagas<T, A>({ slice, selectors, apiCalls, toast });

  return {
    slice,
    selectors,
    sagas,
    initialState,
  };
};

export default generateState;
