import createSvgIcon from '../createSvgIcon';
export const SortArrow = createSvgIcon(
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" />
    <path
      d="M19.5 21.75L16.5 18.75H18.75V11.25H16.5L19.5 8.25L22.5 11.25H20.25V18.75H22.5M7.5 20.25V18.75H15V20.25M7.5 15.75V14.25H12.75V15.75M7.5 11.25V9.75H10.5V11.25H7.5Z"
      fill="#555F7C"
    />
  </svg>,
  'SortArrow'
);
