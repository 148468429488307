import { isNil } from 'lodash';
import React, { useState } from 'react';

// For accessibility so that people who use keyboard navigation don't get trapped in the editor.
export enum TAB_CAPTURE_MODE {
  CAPTURING_MODE,
  NON_CAPTURE_MODE,
}

interface Params {
  inputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | undefined | null>;
  onChange: React.EventHandler<
    React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLTextAreaElement> | React.KeyboardEvent
  >;
}

// Enables tab capturing in input elements for things like code editors so that a user can use the tab key to enter a tab character and still maintain toggling to a non capturing mode with ctrl+m or escape for keyboard page navigation in browser.
export function useTabCapturing({
  inputRef,
  onChange,
}: Params): [
  React.KeyboardEventHandler<HTMLTextAreaElement> | React.KeyboardEventHandler<HTMLInputElement>,
  React.MouseEventHandler<HTMLDivElement | HTMLTextAreaElement | HTMLInputElement>
] {
  const [tabCapturingMode, setTabCapturingMode] = useState(TAB_CAPTURE_MODE.NON_CAPTURE_MODE);
  const handleKeyDown:
    | React.KeyboardEventHandler<HTMLTextAreaElement>
    | React.KeyboardEventHandler<HTMLInputElement> = (event: React.KeyboardEvent) => {
    const { key } = event;
    if (inputRef && !isNil(inputRef.current)) {
      const { value, selectionStart, selectionEnd } = inputRef.current;

      switch (key) {
        case 'Tab':
          if (tabCapturingMode === TAB_CAPTURE_MODE.CAPTURING_MODE) {
            event.preventDefault();
            if (!isNil(selectionStart) && !isNil(selectionEnd)) {
              inputRef.current.value = value.slice(0, selectionStart) + '\t' + value.slice(selectionEnd);
              inputRef.current.setSelectionRange(selectionStart + 1, selectionStart + 1);
            }
            onChange(event);
          }
          return;
        case 'Escape':
          setTabCapturingMode(TAB_CAPTURE_MODE.NON_CAPTURE_MODE);
          return;
        case 'm':
          if (event.ctrlKey) {
            setTabCapturingMode(
              tabCapturingMode === TAB_CAPTURE_MODE.CAPTURING_MODE
                ? TAB_CAPTURE_MODE.NON_CAPTURE_MODE
                : TAB_CAPTURE_MODE.CAPTURING_MODE
            );
          }
          return;
        case 'Shift':
          return;
        default:
          setTabCapturingMode(TAB_CAPTURE_MODE.CAPTURING_MODE);
      }
    }
  };

  const handleClick = (_event: React.SyntheticEvent) => {
    setTabCapturingMode(TAB_CAPTURE_MODE.CAPTURING_MODE);
  };

  return [handleKeyDown, handleClick];
}
