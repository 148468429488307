import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { DEFAULT_STATUS_COLOR, EStatusPillType, STATUS_COLORS } from './status-colors';

export interface IStatusPillProps {
  statusType: EStatusPillType | string;
  status: string | null | undefined;
  testId?: string;
  ContainerProps?: Partial<BoxProps>;
  TypographyProps?: Partial<TypographyProps>;
}

export const StatusPill: React.FC<IStatusPillProps> = ({
  statusType,
  status,
  testId = 'shared-status-pill',
  ContainerProps = {},
  TypographyProps = {},
}) => {
  const getColor = () => {
    // @ts-ignore
    const color = STATUS_COLORS[statusType][status?.toLowerCase()];
    // @ts-ignore
    return color || STATUS_COLORS[statusType]?._default || DEFAULT_STATUS_COLOR;
  };

  const { background, text: color } = getColor();

  return (
    <Box
      {...ContainerProps}
      sx={{
        ...(ContainerProps?.sx ?? {}),
        width: 'fit-content',
        height: '18px',
        left: '264px',
        top: '244px',
        borderRadius: '30px',
        textAlign: 'center',
        letterSpacing: '-1.30385e-09px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '3px 10px',
        gap: '10px',
        background,
      }}
      data-testid={testId}
    >
      <Typography
        data-test="status-pill"
        variant="caption"
        sx={{
          color,
          fontFamily: "'Nunito'",
          fontStyle: 'normal',
          fontWeight: 800,
          fontSize: '9px',
          lineHeight: '12px',
          textTransform: 'uppercase',
        }}
        {...TypographyProps}
      >
        {status}
      </Typography>
    </Box>
  );
};
