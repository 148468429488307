import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { EntityType } from '@aiware/js/interfaces';
import { useStyles } from '../../useStyles';
import { ISource } from '../../../../types';
import { actions } from '../../../../redux/slices';
import { useDispatch } from 'react-redux';
import ContextMenuRowButton from '../../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../../types/entity-context-menus';
import GetRowItem from '../../../shared/GetRowItem';
const sourceContextMenu = ENTITY_CONTEXT_MENU[EntityType.Sources];

const SourcesTableFluffyRow: FunctionComponent<{
  item: ISource;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid data-test={`${EntityType.Sources}-row-fluffy-${item.id}`} style={{ height: rowHeight }} container>
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignContent="space-around"
        container
        item
        xs={11}
        onClick={() =>
          dispatch(
            actions.viewStreams.addToSourceTreeBreadCrumbs({
              entity: EntityType.Sources,
              id: item.id,
            })
          )
        }
      >
        <GetRowItem rowName={'thumbnail'} item={item} xs={2} itemType={EntityType.Sources} />
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="space-around"
          xs={10}
          className={classes.paddingLeft}
        >
          <Grid item container alignContent="center" xs={12}>
            <GetRowItem rowName={'name'} item={item} itemType={EntityType.Sources} />
            <GetRowItem rowName={'sourceTypeId'} item={item} itemType={EntityType.Sources} />
          </Grid>
          <Grid item container alignContent="center" xs={12}>
            <GetRowItem rowName={'createdDateTime'} item={item} xs={6} itemType={EntityType.Sources} />
            <GetRowItem rowName={'modifiedDateTime'} item={item} xs={6} itemType={EntityType.Sources} />
            <GetRowItem rowName={'scheduleCount'} item={item} xs={6} itemType={EntityType.Sources} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={1}
        className={classes.moreIcon}
        item
        container
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <ContextMenuRowButton
          contextMenu={sourceContextMenu}
          type={EAuthResourceType.Source}
          entityId={item.id}
        />
      </Grid>
    </Grid>
  );
};
export default SourcesTableFluffyRow;
