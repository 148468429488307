import { Component, ErrorInfo, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  fallbackUI?: ReactNode;
}
interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): IState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { fallbackUI, children } = this.props;

    if (this.state.hasError) {
      return fallbackUI;
    }
    return children;
  }
}
