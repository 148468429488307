import { FormattedMessage } from 'react-intl';

export const jsonTableText = {
  pagination: {
    first: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.pagination.first"
        defaultMessage="First"
        description="JSON Table Pagination - First"
      />
    ),
    prev: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.pagination.prev"
        defaultMessage="Prev"
        description="JSON Table Pagination - Prev"
      />
    ),
    next: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.pagination.next"
        defaultMessage="Next"
        description="JSON Table Pagination - Next"
      />
    ),
    rows: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.pagination.rows"
        defaultMessage="Rows"
        description="JSON Table Pagination - Rows"
      />
    ),
    of: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.pagination.of"
        defaultMessage="of"
        description="JSON Table Pagination - of"
      />
    ),
    many: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.pagination.many"
        defaultMessage="Many"
        description="JSON Table Pagination - Many"
      />
    ),
  },
  errors: {
    tableErrorTitle: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.errors.tableTitleError"
        defaultMessage="An Error occurred when displaying this data."
        description="JSON Table Error Title Message"
      />
    ),
    tableErrorSubTitle: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.errors.tableTitleSubError"
        defaultMessage="Please try again later"
        description="JSON Table Error Sub Title Message"
      />
    ),
    headerError: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.errors.headerError"
        defaultMessage="A data error occurred when attempting to display this table header."
        description="JSON Table Header Error Message"
      />
    ),
    cellError: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.errors.cellError"
        defaultMessage="Error loading cell data."
        description="JSON Table Cell Error Message"
      />
    ),
    paginationError: (
      <FormattedMessage
        id="shared-reusable-utils.json-table.errors.paginationError"
        defaultMessage="Error displaying table pagination."
        description="JSON Table Pagination Error Message"
      />
    ),
  },
};
