declare global {
  interface root {
    abortControllers: {
      [key: string]: AbortController;
    };
  }
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const root: any = typeof window !== 'undefined' ? window : global;

// Object to store abort controllers so that they can be aborted from redux-saga.
root.abortControllers = root.abortControllers || {};

export { getTagsSuggestionsQuery } from './getTagSuggestions.query';
export { getObjectSuggestionsQuery } from './getObjectSuggestions.query';
export { getLogoSuggestionsQuery } from './getLogoSuggestions.query';
export { getFaceSuggestionsQuery } from './getFaceSuggestions.query';
export { getLibraryIdsQuery } from './getLibraryIds.query';
export { getFiles } from './getFiles.query';
export { getRecognizedTextQuery } from './getRecognizedText.query';
export { getSchemaProperties } from './getSchemaProperties.query';
export { getSchemas } from './schemas';
