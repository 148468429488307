import createSvgIcon from '../createSvgIcon';

export const AppBarArticleIcon = createSvgIcon(
  <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 14.25V3.75H3.75V14.25H14.25ZM3.75 2.25H14.25C15.075 2.25 15.75 2.925 15.75 3.75V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V3.75C2.25 2.925 2.925 2.25 3.75 2.25ZM5.25 12.75H10.5V11.25H5.25V12.75ZM5.25 9.75H12.75V8.25H5.25V9.75ZM5.25 6.75H12.75V5.25H5.25V6.75Z"
      fill="#555F7C"
    />
  </svg>,
  'AppBarArticleIcon'
);
