import { Typography } from '@mui/material';
import { panelComponents } from '@aiware/shared/reusable-utils';
import { useDispatch } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import React from 'react';
import { TSourceInfoProps } from './index';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { TooltipOnTextOverflow } from '../../shared/TooltipOnTextOverflow';
import IdCopy from '../../shared/IdCopy';
import { formatFileInfoDate } from '../../../helpers/fileInfoHelpers';

export const DATA_CENTER_SOURCE_INFO_PANEL = 'DATA_CENTER_SOURCE_INFO_PANEL';
export const SHARED_TEXT = {
  sourceInfo: {
    title: () => (
      <FormattedMessage
        id="os-data-center.source-info-header"
        defaultMessage="Source Info"
        description="data source info pabel header"
      />
    ),
    name: () => (
      <FormattedMessage
        id="os-data-center.source-info-name-label"
        defaultMessage="Name"
        description="Name Label"
      />
    ),
    id: () => (
      <FormattedMessage id="os-data-center.source-info-id-label" defaultMessage="ID" description="ID Label" />
    ),
    created: () => (
      <FormattedMessage
        id="os-data-center.source-info-created-label"
        defaultMessage="Created Date"
        description="Created Date Label"
      />
    ),
    modified: () => (
      <FormattedMessage
        id="os-data-center.source-info-modified-label"
        defaultMessage="Modified Date"
        description="Modified Date Label"
      />
    ),
    scheduledCount: () => (
      <FormattedMessage
        id="os-data-center.source-info-scheduled-count-label"
        defaultMessage="Schedule Count"
        description="Schedule Count Label"
      />
    ),
  },
};

const { header: PanelHeader, container: PanelContainer, content: PanelContent } = panelComponents;

export const MainLayout: React.FC<TSourceInfoProps> = ({ source }) => {
  const dispatch = useDispatch();

  const { classes } = makeStyles()(_theme => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    childItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      marginBottom: '20px',
    },
    labelFont: {
      fontWeight: '400',
      color: '#5C6269',
    },
  }))();

  if (!source) return null;
  const handleClose = () => {
    dispatch(hidePanel(DATA_CENTER_SOURCE_INFO_PANEL));
  };

  return (
    <PanelContainer panelId={DATA_CENTER_SOURCE_INFO_PANEL}>
      <PanelHeader
        title={SHARED_TEXT.sourceInfo.title()}
        testId={'source-info-viewer-panel-header'}
        onPanelClose={handleClose}
        PanelActions={<></>}
      />
      <PanelContent style={{ padding: '30px', overflowY: 'hidden' }}>
        <div className={classes.container}>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-source-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.sourceInfo.name()}
            </Typography>
            <span>
              {' '}
              <TooltipOnTextOverflow title={source?.name} maxCharLength={18}>
                <Typography data-test={`dc-source-panel-info-panel-item-name`} noWrap={true} variant="body2">
                  {source?.name}
                </Typography>
              </TooltipOnTextOverflow>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-source-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.sourceInfo.id()}
            </Typography>
            <span>
              {' '}
              <TooltipOnTextOverflow title={source?.id} maxCharLength={18}>
                <Typography data-test={`dc-source-panel-info-panel-item-id}`} noWrap={true} variant="body2">
                  <IdCopy id={source?.id} />
                </Typography>
              </TooltipOnTextOverflow>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-source-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.sourceInfo.created()}
            </Typography>
            <span>
              {' '}
              <Typography
                data-test={`dc-source-panel-info-panel-item-created-time`}
                noWrap={true}
                variant="body2"
              >
                {formatFileInfoDate(new Date(source?.createdDateTime))}
              </Typography>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-source-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.sourceInfo.modified()}
            </Typography>
            <span>
              {' '}
              <Typography
                data-test={`dc-source-panel-info-panel-item-modifiedTime`}
                noWrap={true}
                variant="body2"
              >
                {formatFileInfoDate(new Date(source?.modifiedDateTime))}
              </Typography>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-source-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.sourceInfo.scheduledCount()}
            </Typography>
            <span>
              {' '}
              <Typography
                data-test={`dc-source-panel-info-panel-item-scheduledCount`}
                noWrap={true}
                variant="body2"
              >
                {source?.scheduleCount}
              </Typography>
            </span>
          </div>
        </div>
      </PanelContent>
    </PanelContainer>
  );
};
