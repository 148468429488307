import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { selectIsFluffy } from '../../../../redux/selectors/ui-state';
import { selectPageSources } from '../../../../redux/selectors/view-streams';
import { ISource } from '../../../../types';
import { actions } from '../../../../redux/slices';
import { useSelector } from 'react-redux';

import rowHeight from '../../../../helpers/rowHeight';
import keyHelper from '../../../../helpers/keyHelper';
import InfiniteWrapper from '../../../shared/InfiniteWrapper';
import SourcesTableHeader from './SourcesTableHeader';

const SourcesTable: FunctionComponent<{
  items: ISource[];
}> = ({ items }) => {
  const sourcePage = useSelector(selectPageSources);
  const isFluffy = useSelector(selectIsFluffy);
  const hasNextPage = sourcePage.hasNextPage;

  return (
    <>
      <SourcesTableHeader />
      <InfiniteWrapper
        id={keyHelper(isFluffy) + EntityType.Sources}
        items={items}
        hasNextPage={hasNextPage}
        itemSize={rowHeight(isFluffy)}
        loadAction={actions.viewStreams.sourcesFetchStart()}
        status={sourcePage.status}
        rowType={EntityType.Sources}
      />
    </>
  );
};

export default SourcesTable;
