import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  container: {
    height: '60px',
    borderBottom: '0.5px solid #D5DFE9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
  },
  headerLabel: {
    marginLeft: '5px',
  },
  actionsContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  verticalDivider: {
    borderRight: `.5px solid ${theme.palette.divider}`,
    height: '70%',
    margin: `0 ${theme.spacing(1)}`,
  },
}));
