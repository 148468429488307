import { FC } from 'react';
import { useSecureMenuContext } from './SecureMenu.context';
import MenuItem from '@mui/material/MenuItem';
import { useStyles } from './secureMenu.styles';
import { hasPermission, EPermissionAction } from '@aiware/shared/permissions';

interface ISecureMenuItem {
  id: string;
  text: string | React.ReactNode;
  permissionRequired: EPermissionAction | undefined;
  visible?: boolean;
  Icon?: any;
  iconSize?: 'large' | 'small';
  action: (e: any) => void;
}

export const SecureMenuItem: FC<ISecureMenuItem> = ({
  id,
  permissionRequired,
  visible = true,
  Icon,
  action,
  text,
  iconSize = 'small',
}) => {
  const { classes } = useStyles();
  const { permissions, isLoadingPermissions, disabled } = useSecureMenuContext();

  const hasAccess = hasPermission(permissions, permissionRequired || EPermissionAction.Read);

  const isMenuItemDisabled = !hasAccess || isLoadingPermissions || disabled;

  if (!visible) {
    return null;
  }

  if (!hasPermission(permissions, EPermissionAction.Read)) {
    return null;
  }

  return (
    <MenuItem
      id={id}
      disabled={isMenuItemDisabled}
      onClick={action}
      className={classes.menuItem}
      sx={{
        width: 186,
        padding: 7.5,
      }}
    >
      {Icon && <Icon fontSize={iconSize} />}

      {text}
    </MenuItem>
  );
};
