import createSvgIcon from '../createSvgIcon';

export const HumanReview = createSvgIcon(
  <svg width="20" height="20" viewBox="3 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0033 13.7087C14.0033 14.5013 14.1964 15.1435 13.1045 15.1435H10.9485C9.85717 15.1435 10.0503 14.5013 10.0503 13.7087V7.97016C10.0503 7.17816 10.446 7.06812 11.5376 7.06812H12.5154C13.6067 7.06812 14.003 7.17816 14.003 7.97016V13.7087H14.0033Z"
      fill="#555F7C"
    />
    <path
      d="M7.36445 12.4646C7.00391 12.8492 6.47596 12.9433 6.18534 12.6748C5.89503 12.406 5.95194 11.8762 6.31279 11.4916L10.1111 7.44143C10.4719 7.05656 11.0002 7.31966 11.2905 7.58757V7.23096C11.5811 7.50007 11.5239 8.02957 11.1627 8.41414L7.36445 12.4646Z"
      fill="#555F7C"
    />
    <path
      d="M10.0375 20.2897C9.88797 20.7942 9.41178 21.0991 8.97283 20.971C8.53418 20.8429 8.29987 20.3294 8.44942 19.8251L10.0248 14.5157C10.1743 14.0112 10.6511 13.7066 11.0895 13.8347C11.5281 13.9631 11.7624 14.476 11.6129 14.9803L10.0375 20.2897Z"
      fill="#555F7C"
    />
    <path
      d="M13.9977 20.2897C14.1473 20.7942 14.6235 21.0991 15.0621 20.971C15.5007 20.8429 15.7351 20.3294 15.5855 19.8251L14.0104 14.5157C13.8609 14.0112 13.3841 13.7066 12.9458 13.8347C12.5068 13.9631 12.2722 14.476 12.4223 14.9803L13.9977 20.2897Z"
      fill="#555F7C"
    />
    <path
      d="M16.6349 12.4241C16.9954 12.809 17.5237 12.9031 17.8143 12.6346C18.1043 12.3655 18.0477 11.8357 17.6865 11.4508L13.8879 7.40066C13.5274 7.01579 12.9991 7.27889 12.7085 7.5474V7.19019C12.4185 7.4593 12.4754 7.98849 12.8363 8.37367L16.6349 12.4241Z"
      fill="#555F7C"
    />
    <path
      d="M11.9999 6.46566C12.9634 6.46566 13.7445 5.68984 13.7445 4.73283C13.7445 3.77581 12.9634 3 11.9999 3C11.0364 3 10.2553 3.77581 10.2553 4.73283C10.2553 5.68984 11.0364 6.46566 11.9999 6.46566Z"
      fill="#555F7C"
    />
  </svg>,
  'HumanReview'
);
