import { AIWareIntlProvider } from '@aiware/shared/intl';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CustomWorkFlow from '../../components/commons/CustomWorkFlow';
import NotificationSettingItem from '../../components/commons/NotifcationSettingItem';
import NotificationType from '../../components/commons/NotificationType';
import { NotificationUtilityActionItem } from '../../components/NotificationUtilityActionItem';
import {
  INotificationType,
  IUtilityAction,
  IUtilityNotificationSetting,
} from '../../interfaces/notificationInterfaces';
import { notificationSelector } from '../../store/selector';

import { useStyles } from './useStyles';

interface INotificationUtilityPanel {
  dataId: string;
}

export function NotificationUtilityPanel(props: INotificationUtilityPanel) {
  const { dataId } = props;
  const { classes } = useStyles();
  const notificationSetting = useSelector(notificationSelector).settings;
  const notificationUtilities = notificationSetting.utility;
  const notificationUtility = notificationUtilities?.find(item => item.id === dataId);
  const [utilitySetting, setUtilitySetting] = useState(
    notificationUtility || ({} as IUtilityNotificationSetting)
  );

  const disabledTypes: INotificationType[] = [];
  const defaultAllowTypes = [
    INotificationType.EMAIL,
    INotificationType.NOTIFICATION_CENTER,
    INotificationType.BANNER,
  ];
  defaultAllowTypes.forEach(item => {
    if (notificationSetting.allowType.findIndex(type => type === item) === -1) {
      disabledTypes.push(item);
    }
  });

  function handleChangeAllowType(isAllow: boolean) {
    setUtilitySetting(preState => {
      const allowType = isAllow ? [...preState.allowType] : [];
      const action = isAllow
        ? [...preState.action]
        : [...preState.action].map(item => {
            return {
              ...item,
              allow: false,
            };
          });
      return {
        ...preState,
        allow: isAllow,
        allowType,
        action,
      };
    });
  }

  function handleSelectNotificationType(type: INotificationType, isSelected: boolean) {
    setUtilitySetting(preState => {
      const allowType = [...preState.allowType];
      const index = allowType.indexOf(type);
      if (index !== -1 && !isSelected) {
        allowType.splice(index, 1);
      } else {
        allowType.push(type);
      }
      return {
        ...preState,
        allowType,
      };
    });
  }

  function handleSelectAction(utilityItem: IUtilityAction) {
    setUtilitySetting(preState => {
      const action = [...preState.action];
      const index = action.findIndex(item => item.id === utilityItem.id);
      action[index] = utilityItem;
      return {
        ...preState,
        action,
      };
    });
  }

  return (
    <AIWareIntlProvider>
      <div className={classes.finderNotification}>
        <NotificationType
          allowTypes={utilitySetting?.allowType}
          allowNotification={utilitySetting?.allow}
          onChangeAllowType={handleChangeAllowType}
          onChangeNotificationType={handleSelectNotificationType}
          disabledTypes={disabledTypes}
          children={
            <NotificationSettingItem
              imgSrc={utilitySetting?.iconUrl || ''}
              primaryText={
                <FormattedMessage
                  id="os-notification-panel.ubNxKs"
                  defaultMessage="{utilityTitle}"
                  description="title of notification utility: {utilityTitle}"
                  values={{
                    id: utilitySetting?.id,
                    utilityTitle: utilitySetting?.title,
                  }}
                />
              }
              secondaryText={
                <FormattedMessage
                  id="os-notification-panel.XkH621"
                  defaultMessage="Chose which events you’d like to be notified about."
                  description="content of notification utility description"
                />
              }
              bigSize
            />
          }
          description={
            <Typography variant="caption" children={'Banner previews are being set at the parent level'} />
          }
        />
        <NotificationUtilityActionItem
          events={utilitySetting?.action}
          onSelectAction={handleSelectAction}
          disabled={!utilitySetting.allow}
        />
        <Divider style={{ marginBottom: 20, marginTop: 15 }} />
        <CustomWorkFlow />
      </div>
    </AIWareIntlProvider>
  );
}

export default NotificationUtilityPanel;
