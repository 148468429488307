import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ msg?: boolean }>()((theme: Theme, { msg }) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  noData: {
    paddingTop: 66,
    display: 'flex',
    justifyContent: 'center',
  },
  iconNoData: {
    width: 235,
    height: 235,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '20px',
    border: '20px solid #FFFF',
  },
  noDataDescription: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 66,
  },
  noDataDescriptionText: {
    /* You don’t have any notifications! */
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    width: msg ? '250px' : '',
  },
}));

export default useStyles;
