import { all, put, select, takeLatest } from 'redux-saga/effects';
import { fetchLibraries } from './api';
import { GraphQLPage, ILibrary } from './type';
import { selectApiConfigs } from './selectors';
import { actions } from './slice';

export function* getLibrariesSaga() {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

    const limit = 100;
    let offset = 0;
    let libraries = [] as ILibrary[];

    // until res count is less than limit
    // increase offset by limit and fetch libraries
    while (true) {
      const res: GraphQLPage<ILibrary> = yield fetchLibraries(apiConfigs, offset, limit);
      libraries = [...libraries, ...(res?.records ?? [])];
      if (res.count !== limit) {
        break;
      }
      offset += limit;
    }

    yield put(actions.fetchLibrariesSuccess({ libraries }));
  } catch (e) {
    yield put(actions.fetchLibrariesFailed());
    console.log(e);
  }
}

export function* getLibrariesWatcher() {
  yield takeLatest(actions.fetchLibrariesStart, getLibrariesSaga);
}
//root Saga
export default function* rootSaga() {
  yield all([getLibrariesWatcher()]);
}
