import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => {
  return {
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxHeight: '100%',
      overflowY: 'scroll',
      position: 'relative',
      textAlign: 'center',
    },
    tableRowContainer: {
      width: '100%',
      position: 'relative',
    },
    resultItemPadding: {
      display: 'flex',
      width: '100%',
      padding: '0px 10px',
      cursor: 'pointer',
      boxSizing: 'border-box',
      position: 'relative',
      borderBottom: '0.5px solid #D5DFE9',

      '&:hover': {
        backgroundColor: '#EEF3F9',
      },
    },
    resultItemClickArea: {},
    resultItemContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      userSelect: 'none',
      boxSizing: 'border-box',
      paddingBottom: '5px',
      paddingTop: '5px',
    },
    fluffyItemContainer: {
      padding: '10px',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      position: 'relative',
    },
    fluffyImageContainer: {
      border: '.5px solid #D5DFE9',
      height: '70px',
      width: '115px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '3px',
      backgroundSize: 'cover',
      position: 'relative',
      transition: 'background-image 1s ease-in-out',
      overflow: 'hidden',
    },
    fluffyImageSkeletonContainer: {
      opacity: 1,
      backgroundColor: 'white',
      transition: 'all 1s ease-in-out',
    },
    fluffyImageSkeleton: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      transform: 'scale(1.15)',
      transformOrigin: 'center',
    },
    fluffyImageLoaderElement: {
      position: 'absolute',
      width: '100px',
      opacity: 0,
    },
    fluffyAudioIcon: {
      position: 'absolute',
      top: '0',
      left: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      fontSize: '32px',
    },
    fluffyDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px',
      width: '90%',
    },
    fluffyDataRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '5px',
      width: '100%',
    },
    fluffyFilename: {
      fontWeight: 500,
      textOverflow: 'ellipsis',
      textAlign: 'left',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '500px',
    },
    fluffyMimeTypeIcon: {
      paddingLeft: '12px',
      display: 'flex',
      alignItems: 'center',
    },
    fluffyDataWithLabel: {
      display: 'flex',
      fontSize: '12px',
    },
    fluffyDataLabel: {
      fontWeight: 600,
      marginRight: '5px',
    },
    fluffyDate: {
      marginRight: '6px',
    },
    fluffyTime: {
      opacity: 0.65,
    },
    fluffySourceSchedule: {
      opacity: '0.8',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    fluffyMatchContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    fluffyMatchNameLabel: {
      marginLeft: '4px',
      fontWeight: 600,
    },
    fluffyVertMenuContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      paddingTop: '5px',
      paddingRight: '5px',
    },
  };
});
