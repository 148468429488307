import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import useStyles from './useStyles';
import { IFile } from '../../../types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { ConfirmationModal } from '@aiware/ui';
import { LoadingStatus } from '@aiware/js/interfaces';

interface IProps {
  fileItems: IFile[];
  onClosePanel: () => void;
  onImportFiles: () => void;
  onIgnoreAndContinue: () => void;
  onRetry: () => void;
  uiStateStatus: LoadingStatus;
}

const Footer = ({
  fileItems,
  onClosePanel,
  onImportFiles,
  onIgnoreAndContinue,
  onRetry,
  uiStateStatus,
}: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { classes } = useStyles();

  const isUploadInProgress = fileItems.some(file => file.status === 'pending');

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    closeModal();
  }, [onRetry, onIgnoreAndContinue]);

  const handleImportFiles = () => {
    const isAnyFileFailed = fileItems.some(file => file.status === 'failure');
    if (isAnyFileFailed) {
      openModal();
      return;
    }
    onImportFiles();
  };

  const handleClosePanel = () => {
    if (fileItems.length) {
      setShowConfirmationModal(true);
      return;
    }
    onClosePanel();
  };

  const disableImportButton =
    fileItems.length === 0 || isUploadInProgress || ['pending', 'failure'].includes(uiStateStatus);

  return (
    <div className={classes.footer}>
      <Button
        onClick={handleClosePanel}
        variant="text"
        size="large"
        className={classes.closeButton}
        data-test="data-center-importer-cancel-btn"
      >
        <FormattedMessage
          id="os-data-center-importer.0EGcHs"
          defaultMessage="Close"
          description="Close Panel"
        />
      </Button>
      <Button
        onClick={handleImportFiles}
        disabled={disableImportButton}
        size="large"
        variant="contained"
        color="primary"
        data-test="data-center-importer-import-btn"
      >
        <FormattedMessage
          id="os-data-center-importer.pcbRZH"
          defaultMessage="Import"
          description="Import uploaded files"
        />
      </Button>
      <Dialog
        open={showModal}
        maxWidth="xs"
        onClose={closeModal}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle
          id="dialog-title"
          data-test="data-center-importer-dialog-title"
          className={classes.dialogTitle}
        >
          <Typography variant="h2" className={classes.dialogTitleText}>
            <FormattedMessage
              id="os-data-center-importer.cl4DGP"
              defaultMessage="Errors Detected"
              description="title for Error Detected Dialog"
            />
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="dialog-description">
            <FormattedMessage
              id="os-data-center-importer.N9Drr+"
              defaultMessage="Some of your files have errors, would you like to fix these errors or continue?"
              description="description for Error Detected Dialog"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={onRetry}
            variant="text"
            size="large"
            data-test="data-center-importer-dialog-cancel-btn"
          >
            <FormattedMessage
              id="os-data-center-importer.7+A+C3"
              defaultMessage="Retry Errors"
              description="button text for retry failed files"
            />
          </Button>
          <Button
            onClick={onIgnoreAndContinue}
            size="large"
            variant="contained"
            color="primary"
            data-test="data-center-importer-dialog-confirm-btn"
          >
            <FormattedMessage
              id="os-data-center-importer.E9hwYl"
              defaultMessage="Ignore Errors and Continue"
              description="button text for ignore failed files and kick of the importing progress"
            />
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        showConfirmationModal={showConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        onClosePanel={onClosePanel}
        classes={classes}
      />
    </div>
  );
};

export default Footer;
