import React from 'react';
import { TextField, Box, Chip } from '@mui/material';
import { FieldSettingsProps } from '../types';

export const TextFieldSettings: React.FC<FieldSettingsProps> = ({ field, index, onFieldChange }) => (
  <TextField
    fullWidth
    size="small"
    label="Placeholder"
    value={field.placeholder || ''}
    onChange={e => {
      onFieldChange(index, {
        ...field,
        placeholder: e.target.value,
      });
    }}
  />
);

export const NumberFieldSettings: React.FC<FieldSettingsProps> = ({ field, index, onFieldChange }) => (
  <>
    <TextField
      fullWidth
      size="small"
      type="number"
      label="Minimum Value"
      value={field.min || ''}
      onChange={e => {
        onFieldChange(index, {
          ...field,
          min: Number(e.target.value),
        });
      }}
    />
    <TextField
      fullWidth
      size="small"
      type="number"
      label="Maximum Value"
      value={field.max || ''}
      onChange={e => {
        onFieldChange(index, {
          ...field,
          max: Number(e.target.value),
        });
      }}
    />
  </>
);

export const SelectFieldSettings: React.FC<FieldSettingsProps> = ({ field, index, onFieldChange }) => (
  <Box>
    <TextField
      fullWidth
      size="small"
      label="Add select options"
      helperText="Hit enter/return to add"
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const value = (e.target as HTMLInputElement).value.trim();
          if (value) {
            onFieldChange(index, {
              ...field,
              options: [...(field.options || []), value],
            });
            (e.target as HTMLInputElement).value = '';
          }
        }
      }}
    />
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
      {field.options?.map((option, optionIndex) => (
        <Chip
          key={optionIndex}
          label={option}
          onDelete={() => {
            onFieldChange(index, {
              ...field,
              options: field.options?.filter((_, i) => i !== optionIndex),
            });
          }}
          size="small"
        />
      ))}
    </Box>
  </Box>
);
