import { takeEvery } from 'redux-saga/effects';
import {
  fetchResoucesACLSaga,
  addACLsToResoucesSaga,
  removeACLsFromResoucesSaga,
  lookupMembersSaga,
  getOrgFunctionalPermissionsSaga,
  changeACLsToResoucesSaga,
  fetchOrgPermissionSetsSaga,
  createPermissionSetSaga,
  fetchOrgAccessControlSaga,
  updatePermissionSetSaga,
  fetchFunctionalPermissionsSaga,
} from './admin-center-permissions.saga';
import { actions } from '../slices';

export function* fetchResoucesACLWatcher() {
  yield takeEvery(actions.adminCenterPermissions.fetchResoucesACLStart.type, fetchResoucesACLSaga);
}

export function* addACLsToResoucesWatcher() {
  yield takeEvery(actions.adminCenterPermissions.addACLsToResoucesStart.type, addACLsToResoucesSaga);
}

export function* removeACLsFromResoucesWatcher() {
  yield takeEvery(
    actions.adminCenterPermissions.removeACLsFromResoucesStart.type,
    removeACLsFromResoucesSaga
  );
}

export function* lookupMembersWatcher() {
  yield takeEvery(actions.adminCenterPermissions.lookupMembersStart.type, lookupMembersSaga);
}

export function* getOrgFunctionalPermissionsWatcher() {
  yield takeEvery(
    actions.adminCenterPermissions.fetchOrgFuncPermissionsStart.type,
    getOrgFunctionalPermissionsSaga
  );
}

export function* changeACLsToResoucesWatcher() {
  yield takeEvery(actions.adminCenterPermissions.changeACLsOnResoucesStart.type, changeACLsToResoucesSaga);
}

export function* fetchOrgPermissionSetsWatcher() {
  yield takeEvery(
    actions.adminCenterPermissions.fetchOrgPermissionSetsStart.type,
    fetchOrgPermissionSetsSaga
  );
}
export function* fetchOrgAccessControlsWatcher() {
  yield takeEvery(actions.adminCenterPermissions.fetchOrgAccessControlStart.type, fetchOrgAccessControlSaga);
}

export function* createPermissionSetWatcher() {
  yield takeEvery(actions.adminCenterPermissions.createPermissionSetStart.type, createPermissionSetSaga);
}

export function* updatePermissionSetWatcher() {
  yield takeEvery(actions.adminCenterPermissions.updatePermissionSetStart.type, updatePermissionSetSaga);
}

export function* fetchFunctionalPermissionsWacher() {
  yield takeEvery(
    actions.adminCenterPermissions.fetchFunctionalPermissionsStart.type,
    fetchFunctionalPermissionsSaga
  );
}
