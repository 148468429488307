import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import Header from '../components/header';
import { formattedMessages } from '../helpers/formatted-messages';
import { Divider, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { TabPanel } from '@aiware/ui';
import useStyles from './permission-set.details.styles';
import BasicTab from './basic-tab';
import PermissionsTab from './permissions-tab';
import GeneralTab from './general-tab';
import Controls from './controls';
import { selectPermissionSetById } from '../../redux';
import { TAuthPermissionSetUpdateInput, TPermissionSet } from '../../types';
import isEqual from 'lodash/isEqual';
import { actions } from '../../redux/slices';

const PANEL_ID = 'PERMISSION_SET_DETAILS_PANEL';

export const PermissionSetDetailsPanel = ({ id }: { id: string }) => {
  const permissionSet = useSelector(selectPermissionSetById(id)) || ({} as TPermissionSet);
  const isPermissionProtected = permissionSet?.isProtected || false;
  const { name = '', description = '', createdAt = '', permissions = [] } = permissionSet;
  const { classes } = useStyles();
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [nameError, setNameError] = useState(false);
  const [updatedSet, setUpdatedSet] = useState({} as Partial<TPermissionSet>);
  const [originalSetSaved, setOriginalSetSaved] = useState(false);

  useEffect(() => {
    if (permissionSet && !originalSetSaved) {
      setOriginalSetSaved(true);
      setUpdatedSet(permissionSet);
    }
  }, [permissionSet]);

  const setNewKeyValue = (val: { [key: string]: any }) => {
    setUpdatedSet({ ...updatedSet, ...val });
  };

  const intl = useIntl();
  const {
    permissionSetDetailsPanelHeader,
    permissionsPanelClose,
    permissionSetDetailsPanelDescription,
    tabTitle_basic,
    tabTitle_permissions,
    tabTitle_settings,
  } = formattedMessages(intl);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hidePanel(PANEL_ID));
  };

  const nameExistsCheck = (value: boolean) => {
    setNameError(value);
  };

  const tabsList = [
    {
      label: tabTitle_basic,
      disabled: false,
      id: 'basic',
      component: BasicTab({ name, description, createdAt }),
    },
    {
      label: tabTitle_permissions,
      disabled: false,
      id: 'permissions',
      component: PermissionsTab({ permissions, setNewKeyValue, isPermissionProtected }),
    },
    {
      label: tabTitle_settings,
      disabled: false,
      id: 'settings',
      component: GeneralTab({ permissionSet, nameExistsCheck, setNewKeyValue, isPermissionProtected }),
    },
  ];

  const onChangeTab = (_event: SyntheticEvent<Element, Event>, value: any) => {
    setSelectedTabId(value);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const disableSave = () => {
    let disable = true;
    Object.entries(permissionSet).forEach(entry => {
      const updatedSetEntry = updatedSet[entry[0] as keyof TPermissionSet];
      if (updatedSetEntry) {
        if (typeof updatedSetEntry === 'string' && !isEqual(updatedSetEntry, entry[1])) {
          disable = false;
        } else if (
          Array.isArray(updatedSetEntry) &&
          Array.isArray(entry[1]) &&
          !isEqual([...updatedSetEntry].sort(), [...entry[1]].sort())
        ) {
          disable = false;
        }
      }
    });
    if (nameError) {
      disable = true;
    }
    return disable;
  };

  const onSave = () => {
    const { createdAt, authClass, ...payload } = updatedSet;
    dispatch(
      actions.adminCenterPermissions.updatePermissionSetStart(
        payload as unknown as TAuthPermissionSetUpdateInput
      )
    );
    handleClose();
  };

  if (!id) return null;
  return (
    <AIWareIntlProvider>
      <div
        data-testid="admin-center-permission-set-details-panel"
        data-test="admin-center-permission-set-details-panel"
        className={classes.root}
      >
        <div>
          <Header
            panelId={PANEL_ID}
            permissionsPanelHeader={permissionSetDetailsPanelHeader}
            permissionsPanelClose={permissionsPanelClose}
            dataTestIdPrefix="permission-set-details"
            iconType="close"
            handleClose={handleClose}
            unstyledHeader={true}
          />
          <div className={classes.panelTitle}>
            <Typography variant="body2">{permissionSetDetailsPanelDescription}</Typography>
          </div>
          <div
            className={classes.tabsContainer}
            data-testid="permission-set-details-tabs"
            data-test="permission-set-details-tabs"
          >
            <div className={classes.tabsParent}>
              <Tabs onChange={onChangeTab} value={selectedTabId}>
                {tabsList.map((tab, index) => (
                  <Tab key={tab.id} label={tab.label} {...a11yProps(index)} data-testid={`${tab.id}-tab`} />
                ))}
              </Tabs>
            </div>
            <Divider className={classes.divider} />
            <div>
              {tabsList.map((tab, index) => (
                <div key={tab.id} className={classes.secondBox}>
                  <TabPanel value={selectedTabId} index={index}>
                    {tab.component}
                  </TabPanel>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.controlsContainer}>
          {selectedTabId !== 0 && <Controls disabled={disableSave()} onSave={onSave} />}
        </div>
      </div>
    </AIWareIntlProvider>
  );
};
