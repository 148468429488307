import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  settingsList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    height: 'calc(100% - 115px)',
  },
  subtitle: {
    fontWeight: 400,
    margin: '0 30px 15px',
  },
  footerBtnContainer: {
    margin: '0 30px 30px 30px',
    position: 'absolute',
    right: 0,
    bottom: 0,
    '& button': {
      flex: 1,
      height: '36px',
      marginLeft: '20px',
    },
  },
}));
