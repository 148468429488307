import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import isNil from 'lodash/isNil';
import useStyles from './useStyles';

import HelpDocs from './HelpDocs';

interface Props {
  title: React.ReactElement;
  helpLink?: string;
  description: React.ReactElement;
  testName?: string;
}

const Heading: FunctionComponent<Props> = ({ testName, title, description, helpLink }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      <Typography data-test={`${testName}-title`} style={{ fontWeight: 'bold' }} variant="h5">
        {title}
        {!isNil(helpLink) && <HelpDocs className={classes.icon} helpLink={helpLink} />}
      </Typography>
      <Typography data-test={`${testName}-description`} className={classes.helperDescription}>
        {description}
      </Typography>
    </>
  );
};

export default Heading;
