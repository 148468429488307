import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '720px',
    height: 'auto',
  },
  configurationContainer: {
    minHeight: '650px',
  },
  mainContainer: {
    margin: theme.spacing(6),
    height: '65vh',
    overflowY: 'auto',
  },
  spacer: {
    marginTop: theme.spacing(6),
  },
  header: {
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    backgroundColor: '#FAFAFA !important',
    display: 'flex',
    height: 64,
    padding: theme.spacing(2),
  },
  headerTitle: {
    paddingLeft: theme.spacing(4),
  },
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
  },
  alert: {
    color: theme.palette.error.main,
    userSelect: 'none',
    '& svg, & svg:hover': {
      color: theme.palette.error.main,
    },
  },
  permissionsContainer: {
    marginTop: '30px',
  },
  description: {
    fontWeight: 400,
    marginBlockStart: '16px',
    userSelect: 'none',
  },
}));
export default useStyles;
