import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '@aiware/shared/redux';
import { IUser } from '@aiware/js/interfaces';

import { EAuthResourceType, EAuthSubResourceType, EPermissionAction } from '../types';
import { actions, selectors, TPermissionsEntity } from '../redux';
import { hasPermission, isPermissionLoading } from './permissionsHelper';

export const useOrganizationPermissions = (permissionType?: EAuthResourceType | EAuthSubResourceType) => {
  const currentOrg = useRef<number>();
  const dispatch = useDispatch();
  const orgId = (useSelector(userSelector) as IUser).organization?.organizationId;
  const permissions: TPermissionsEntity | undefined = useSelector(
    selectors.selectPermissionsByEntity(EAuthResourceType.Organization, String(orgId), permissionType)
  );

  useEffect(() => {
    if (
      orgId &&
      (!permissions ||
        permissions.status === 'failure' ||
        permissions.status === 'idle' ||
        currentOrg.current !== orgId)
    ) {
      currentOrg.current = orgId;
      dispatch(
        actions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.Organization,
          entityId: String(orgId),
        })
      );
    }
  }, [dispatch, orgId, permissions]);

  const canDo = useCallback(
    (action: EPermissionAction) => {
      const isLoading = isPermissionLoading(permissions);
      return !isLoading && hasPermission(permissions, action);
    },
    [permissions]
  );

  return {
    permissions,
    hasPermission: canDo,
  };
};
