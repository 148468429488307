import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  customColorInput: {
    colorPickerTitle: () => (
      <FormattedMessage
        id="customize-button-title"
        defaultMessage="Customize Colors"
        description="Customize Colors title"
      />
    ),
    colorPickerDescription: () => (
      <FormattedMessage
        id="customize-button-description"
        defaultMessage="Change the primary and secondary color of the login and sign up pages to match your brand."
        description="Customize Colors description"
      />
    ),
    resetToDefault: () => (
      <FormattedMessage
        id="customize-button-reset-to-default"
        defaultMessage="Reset to Default"
        description="Customize Colors reset to default"
      />
    ),
  },
};
