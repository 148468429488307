import createSvgIcon from '../createSvgIcon';
export const Activity = createSvgIcon(
  <svg width="26" height="34" viewBox="-3 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12H14V14H8V12ZM6 4H4V6H6V4ZM8 10H14V8H8V10ZM8 6H14V4H8V6ZM6 8H4V10H6V8ZM18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2ZM16 2H2V16H16V2ZM6 12H4V14H6V12Z"
      fill="#555F7C"
    />
  </svg>,
  'Activity'
);
