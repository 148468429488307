import { Paper, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '@aiware/shared/redux';
import { useState } from 'react';
import { OrgList } from './org-list';
import { OrgHeader } from './org-header';
import { REDUX_ACTIONS } from '../helpers';

export const OrgPanel = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const organization = user?.organization;
  const [searchValue, setSearchValue] = useState('');

  const handleOrgChange = org => {
    // TODO: HACK For some reason the spinner and org switch doesn't show
    // until the drawer gets opened. For the sake of time this is a temporary
    // fix. This should be fixed in the future.
    const el: HTMLButtonElement = document.querySelector('[data-test="app-bar-setting-button"]');
    el && el.click();

    dispatch({
      type: REDUX_ACTIONS.changeOrg,
      payload: {
        organizationGuid: org.guid,
        userName: user.email,
      },
    });
  };

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: '50px',
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <OrgHeader organization={organization} />
      </Stack>
      <Paper
        sx={{
          padding: '10px',
          width: '70vw',
          height: '70vh',
          color: '#2A323C',
          backgroundColor: 'rgb(255 255 255 / 50%)',
          backdropFilter: 'blur(20px) saturate(1.5)',
        }}
      >
        <Stack sx={{ height: '100%', overflow: 'hidden', gap: '10px' }}>
          <TextField
            placeholder={'Search My Organizations'}
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
          <OrgList searchValue={searchValue} onOrgChange={handleOrgChange} />
        </Stack>
      </Paper>
    </Stack>
  );
};
