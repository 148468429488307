import { useState, FC } from 'react';
import { Box, Stepper, StepConnector, Step, StepLabel, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import BasicInfo from '../../components/BasicInfo';
import GroupMembers from '../../components/GroupMembers';
import { TUserGroup } from '../../../types';
import useStepData, { BASIC_INFO_STEP, GROUP_MEMBERS_STEP } from '../helpers/useStepData';
import styles from './styles';

const steps: string[] = ['Basic Info', 'Members'];

interface StepButtonsProps {
  step: number;
  isValid: boolean;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
}

const StepButtons: FC<StepButtonsProps> = ({ step, isValid, onCancel, onBack, onNext, onFinish }) => (
  <Box sx={styles['stepButtonsContainer']} data-test="os-admin-center.groups.new-group-panel">
    <Button
      color="inherit"
      onClick={() => {
        step === 0 ? onCancel() : onBack();
      }}
      sx={styles['stepButton']}
      data-test="os-admin-center.groups.new-group-panel.step-btn"
    >
      {step === 0 ? (
        <FormattedMessage
          id="os-admin-panel-groups.new-group-cancel-button"
          defaultMessage="Cancel"
          description="Cancel button in New Group panel"
        />
      ) : (
        <FormattedMessage
          id="os-admin-panel-groups.new-group-back-button"
          defaultMessage="Back"
          description="Back button to previous step in New Group panel"
        />
      )}
    </Button>
    <Button
      color="primary"
      variant="contained"
      disabled={!isValid}
      data-testid={step === 0 ? 'new-user-group-next-btn' : 'new-user-group-create-btn'}
      data-test={step === 0 ? 'new-user-group-next-btn' : 'new-user-group-create-btn'}
      onClick={() => {
        step === 0 ? onNext() : onFinish();
      }}
      sx={styles['stepButton']}
    >
      {step === 0 ? (
        <FormattedMessage
          id="os-admin-panel-groups.new-group-next-button"
          defaultMessage="Next"
          description="Next button in New Group panel"
        />
      ) : (
        <FormattedMessage
          id="os-admin-panel-groups.new-group-save-button"
          defaultMessage="Create Group"
          description="Create Group button in New Group panel"
        />
      )}
    </Button>
  </Box>
);

interface PanelBodyProps {
  onCancel: () => void;
  onSave: (group: Partial<TUserGroup>) => void;
  initGroup: Partial<TUserGroup>;
}

export const PanelBody: FC<PanelBodyProps> = ({ onCancel, onSave, initGroup }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { group, setName, setDescription, validateStep, addMember, removeMember } = useStepData(initGroup);
  const [nameError, setNameError] = useState(false);

  const nameExistsCheck = (value: boolean) => {
    setNameError(value);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleFinish = () => {
    onSave(group);
  };

  return (
    <Box sx={styles['stepBodyContainer']}>
      <Stepper
        activeStep={activeStep}
        connector={<StepConnector sx={styles['stepConnector']} />}
        sx={styles['stepper']}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label} sx={styles['step']}>
              <StepLabel
                StepIconProps={
                  index <= activeStep ? { sx: styles['infoLabel'] } : { sx: styles['memberLabel'] }
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={styles['stepBody']}>
        {activeStep === BASIC_INFO_STEP && (
          <BasicInfo
            group={group}
            onNameChange={setName}
            onDescriptionChange={setDescription}
            nameExistsCheck={nameExistsCheck}
          />
        )}
        {activeStep === GROUP_MEMBERS_STEP && (
          <GroupMembers group={group} onAddMember={addMember} onRemoveMember={removeMember} />
        )}
      </Box>
      <StepButtons
        step={activeStep}
        isValid={validateStep(activeStep) && !nameError}
        onCancel={onCancel}
        onBack={handleBack}
        onNext={handleNext}
        onFinish={handleFinish}
      />
    </Box>
  );
};

export default PanelBody;
