import { Desktop_0, Desktop_1, Desktop_2, Desktop_3, Desktop_4, Desktop_5 } from '../../desktop-assets';

export const defaultImages = [
  { key: 'Desktop_0', value: Desktop_0 },
  { key: 'Desktop_1', value: Desktop_1 },
  { key: 'Desktop_2', value: Desktop_2 },
  { key: 'Desktop_3', value: Desktop_3 },
  { key: 'Desktop_4', value: Desktop_4 },
  { key: 'Desktop_5', value: Desktop_5 },
];
export const defaultImagesMap = {
  Desktop_0,
  Desktop_1,
  Desktop_2,
  Desktop_3,
  Desktop_4,
  Desktop_5,
};
