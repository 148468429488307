import { ISubscriptionModule } from '@aiware/os/notification-subscription';
import { IMailbox, INotificationDetail } from '@aiware/js/interfaces';

import { INotificationModule } from '../interfaces/notificationInterfaces';
import { createSelector } from '@reduxjs/toolkit';

export const nameSpace = 'osNotificationPanel';
export const mailbox = 'mailbox';

export interface IState {
  [nameSpace]: INotificationModule;
  [mailbox]: ISubscriptionModule;
}

interface IFilter {
  value: string;
  title: string;
}

export const mailBoxSelector = (state: IState) =>
  state?.[mailbox] ? state?.[mailbox] : {};

export const notificationSelector = (state: IState) =>
  state?.[nameSpace] ? state[nameSpace] : ({} as INotificationModule);

export const notificationSettingSelector = (state: IState) =>
  state?.[nameSpace]?.settings ? state?.[nameSpace]?.settings : {};

export const notificationUtilitiesSelector = (state: IState) =>
  state?.[nameSpace]?.settings?.utility
    ? state?.[nameSpace]?.settings?.utility
    : [];

export const notificationApplicationsSelector = (state: IState) =>
  state?.[nameSpace]?.settings?.application
    ? state?.[nameSpace]?.settings?.application
    : {};

export const notificationApplicationSelector =
  (appId: string) => (state: IState) =>
    state?.[nameSpace]?.settings?.application.byId[appId]
      ? state?.[nameSpace]?.settings?.application.byId[appId]
      : {};

export const notificationListSelector = (state: IState) =>
  state?.[nameSpace]?.notifications || [];
export const filterSelector = (state: IState) => state?.[nameSpace]?.filter;

export const applicationFilterSelector = (state: IState) => {
  const mailboxList = state?.mailbox?.mailbox || [];
  return mailboxList
    .map((mailboxItem: IMailbox) => ({
      value: mailboxItem?.application?.applicationId || '',
      title: mailboxItem?.application?.name || '',
    }))
    .filter((filterItem: IFilter, index: number, self: IFilter[]) => {
      const realIndex = self.findIndex(
        (item: IFilter) => item.value === filterItem.value
      );
      return index === realIndex;
    });
};

export const notificationDetailSelector = (id: string) => (state: IState) => {
  const notifications = state?.[nameSpace]
    ?.notifications as INotificationModule['notifications'];
  const notification = notifications.find(
    (notification: INotificationDetail) => notification.id === id
  );
  return notification;
};

export const notificationWithFilterSelector: (state: IState) => INotificationDetail[] = createSelector(
  filterSelector,
  notificationListSelector,
  (filter, notifications) => {
    return notifications.filter((item: INotificationDetail) => filter === 'all' || item.appId === filter);
  }
);

export const notificationStatusSelector = (state: IState) => {
  return state?.[nameSpace]?.status || 'idle';
};
