import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux/selectors';
import { IFile, IS3Bucket } from '../types';

type UploadEvents = {
  onProgress?: (progress: number) => void;
  onError?: (message: string) => void;
  onAbort?: (message: string) => void;
  onUpload?: (message: string) => void;
};

export async function getSignedWritableUrls(apiConfigs: ReturnType<typeof selectApiConfigs>, count: number) {
  const operationName = 'getSignedWritableUrls';
  const query = `query ${operationName}($count: Int!) {
    ${operationName}(number: $count) {
      bucket,
      expiresInSeconds,
      getUrl,
      key,
      unsignedUrl,
      url,
    }
  }`;
  const variables = {
    count,
  };

  const result = await baseGraphQLApiWithError<{
    [operationName]: {
      bucket: string;
      expiresInSeconds: number;
      getUrl: string;
      key: string;
      unsignedUrl: string;
      url: string;
    }[];
  }>({
    query,
    variables,
    operationName,
    ...apiConfigs,
  });

  return result[operationName];
}

export async function uploadFileToS3(
  bucket: IS3Bucket,
  file: IFile,
  { onProgress, onError, onAbort, onUpload }: UploadEvents
) {
  const xhr = new XMLHttpRequest();

  // listen for `upload.load` event
  xhr.upload.onload = () => {
    if (!onUpload) {
      return;
    }

    onUpload(`The upload is completed: ${xhr.status} ${xhr.response}`);
  };

  // listen for `upload.error` event
  xhr.upload.onerror = () => {
    if (!onError) {
      return;
    }

    onError('Upload failed.');
  };

  // listen for `upload.abort` event
  xhr.upload.onabort = () => {
    if (!onAbort) {
      return;
    }

    onAbort('Upload cancelled.');
  };

  // listen for `progress` event
  xhr.upload.onprogress = event => {
    if (!onProgress) {
      return;
    }

    // event.loaded returns how many bytes are downloaded
    // event.total returns the total number of bytes
    // event.total is only available if server sends `Content-Length` header
    onProgress((event.loaded / event.total) * 100);
  };

  // open request
  xhr.open('PUT', bucket.url, true);

  // set headers
  xhr.setRequestHeader('Content-Type', file.type);
  const res = await fetch(file.fileUrl);
  const blob = await res.blob();

  // send request
  xhr.send(blob);

  return xhr;
}
