import { INotificationDetail, IPanelActionButton, NotificationFlag } from '@aiware/js/interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { INotificationModule, INotificationType } from '../interfaces/notificationInterfaces';
import {
  getNotificationError,
  getNotificationStart,
  getNotificationSuccess,
  updateFilterValue,
  updateBannerStyle,
  updateAppActionAllowNotification,
  updateNotificationSetting,
  updateUtilitySetting,
  updateApplicationSetting,
  updateAppAllowNotification,
  getNotificationListByMailBoxFail,
  getNotificationListByMailBoxSuccess,
  updateNotificationList,
  updateNotificationReadRequest,
  updateNotificationReadSuccess,
  updateNotificationReadFail,
  getNotificationAction,
  getNotificationActionSuccess,
  markAllReadNotificationFail,
  markAllReadNotificationRequest,
  markAllReadNotificationSuccess,
} from './actions';
import { filterAll } from './constants';
import { utilityNotificationSettingData } from '../mockData/mockData';
import { applicationNotificationSettingData } from '../mockData/settingApplicationMockData';

// allow notification type
// notification module
export const initialState: INotificationModule = {
  notifications: [] as INotificationDetail[],
  availableMailBoxes: 0,
  limit: 0,
  offset: 0,
  status: 'idle',
  filter: filterAll,
  failureMessage: '',
  paused: false,
  settings: {
    allow: true,
    allowType: [
      INotificationType.EMAIL,
      INotificationType.NOTIFICATION_CENTER,
      // INotificationType.BANNER,
    ],
    bannerStyle: 'temporary',
    enableUtilityNotification: true,
    enableApplicationNotification: true,
    utility: utilityNotificationSettingData,
    application: applicationNotificationSettingData,
  },
};

export const notificationReducer = createReducer(initialState, builder => {
  builder
    .addCase(getNotificationStart, (state, action) => {
      const { limit, offset } = action.payload;
      return {
        ...state,
        status: offset === 0 ? 'loading' : state.status,
        limit,
        offset,
      };
    })
    .addCase(getNotificationSuccess, (state, action) => ({
      ...state,
      status: 'success',
      notifications: action.payload,
    }))
    .addCase(getNotificationError, (state, action) => ({
      ...state,
      failureMessage: action.payload,
      status: 'failure',
    }))
    .addCase(updateFilterValue, (state, action) => ({
      ...state,
      filter: action.payload,
    }))
    .addCase(updateAppAllowNotification, (state, action) => {
      const { appId, isAllowed } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          application: {
            ...state.settings.application,
            byId: {
              ...state.settings.application.byId,
              [appId]: {
                ...state.settings.application.byId[appId]!,
                allow: isAllowed,
              },
            },
          },
        },
      };
    })
    .addCase(updateBannerStyle, (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          bannerStyle: action.payload,
        },
      };
    })
    .addCase(updateUtilitySetting, (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        utility: action.payload,
      },
    }))
    .addCase(updateNotificationSetting, (state, action) => ({
      ...state,
      settings: action.payload,
    }))
    .addCase(updateAppActionAllowNotification, (state, action) => {
      const { appId, id, value, key } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          application: {
            ...state.settings.application,
            byId: {
              ...state.settings.application.byId,
              [appId]: {
                ...state.settings.application.byId[appId]!,
                action: {
                  ...state.settings.application.byId[appId]!.action,
                  byId: {
                    ...state.settings.application.byId[appId]!.action.byId,
                    [id]: {
                      ...state.settings.application.byId[appId]!.action.byId[id]!,
                      [key]: value,
                    },
                  },
                },
              },
            },
          },
        },
      };
    })
    .addCase(updateApplicationSetting, (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          application: action.payload,
        },
      };
    })
    .addCase(getNotificationListByMailBoxSuccess, (state, action) => {
      const { data, limit, offset, availableMailBoxes } = action.payload;

      const softByCreateTime = (a: INotificationDetail, b: INotificationDetail) => {
        const aTimestamp = new Date(a.createdDateTime).getTime();
        const bTimestamp = new Date(b.createdDateTime).getTime();
        return bTimestamp - aTimestamp;
      };

      return {
        ...state,
        status: 'success',
        notifications:
          [...data, ...state.notifications].sort(softByCreateTime) || ([] as INotificationDetail[]),
        offset,
        limit,
        availableMailBoxes,
      };
    })
    .addCase(getNotificationListByMailBoxFail, (state, _action) => {
      return {
        ...state,
        status: 'failure',
      };
    })
    .addCase(updateNotificationList, (state, action) => {
      const { payload } = action;
      return {
        ...state,
        notifications: [payload, ...(state.notifications as INotificationDetail[])],
      };
    })
    .addCase(updateNotificationReadRequest, (state, _action) => {
      return {
        ...state,
      };
    })
    .addCase(updateNotificationReadSuccess, (state, action) => {
      const { id, flags } = action.payload?.data?.setNotificationFlag ?? {};
      const notificationsVal: INotificationDetail[] = state.notifications.map(
        (notification: INotificationDetail) =>
          notification.id === id ? { ...notification, flags } : notification
      );
      return {
        ...state,
        notifications: notificationsVal,
      };
    })
    .addCase(updateNotificationReadFail, (state, _action) => {
      return {
        ...state,
      };
    })
    .addCase(markAllReadNotificationRequest, (state, _action) => {
      return {
        ...state,
      };
    })
    .addCase(markAllReadNotificationSuccess, (state, _action) => {
      return {
        ...state,
        notifications: state.notifications.map(item => ({
          ...item,
          flags: item.flags?.map((flag: NotificationFlag) =>
            flag === NotificationFlag.unread ? NotificationFlag.read : flag
          ),
        })),
      };
    })
    .addCase(markAllReadNotificationFail, (state, _action) => {
      return {
        ...state,
      };
    })
    .addCase(getNotificationAction, (state, _action) => {
      return {
        ...state,
      };
    })
    .addCase(getNotificationActionSuccess, (state, action) => {
      const { notificationId, notificationActions } = action.payload;
      const actionArr = notificationActions.map(
        item =>
          ({
            iconUrl: item.icon,
            label: item.actionName,
            variant: 'contained',
            size: 'medium',
            color: 'default',
            url: item.urlTemplate,
          } as IPanelActionButton)
      );

      return {
        ...state,
        notifications: state.notifications.map(item => {
          if (item.id !== notificationId) {
            return item;
          }

          return {
            ...item,
            actions: actionArr,
          };
        }),
      };
    });
});
