import { IOrganization, IUser } from '@aiware/js/interfaces';

export const SLICE_NAME = 'adminCenter';
export const PARENT_PANEL_ID = 'ADMIN_CENTER';
export const ADMIN_CENTER_NEW_GROUP_PANEL = 'ADMIN_CENTER_NEW_GROUP_PANEL';
export const ADMIN_CENTER_GROUP_DETAILS_PANEL = 'ADMIN_CENTER_GROUP_DETAILS_PANEL';
export const TEST_ID = 'os-admin-center-panel.groups';

export type TUser = IUser & { createdDateTime?: string };

export type TAuthGroupMemberEntry = TUser | TUserGroup;

export interface TAuthGroupMember {
  member?: TAuthGroupMemberEntry;
  createdAt?: string;
  modifiedAt?: string;
}

export type TAuthRole = {
  id: string;
  name: string;
  description: string;
  permissions: string[];
  hasPermissions: boolean;
  createdAt: string;
  modifiedAt: string;
};

export type TUserGroup = {
  id: string;
  name: string;
  description: string;
  organization: IOrganization;
  parentGroups: TUserGroup[];
  memberCount: number;
  members: GraphQLPage<TAuthGroupMember>;
  isProtected: boolean;
  createdAt: string;
  modifiedAt: string;
};

export const PAGE_LIMIT = 30;

export type GraphQLPage<T> = {
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export enum EMemberType {
  User = 'User',
  Group = 'Group',
}

export type TAuthGroupMemberType = {
  id: string;
  memberType: EMemberType;
};

export type TCreateAuthGroupInput = {
  name: string;
  description: string;
  ownerOrganization?: string;
  members?: TAuthGroupMemberType[];
};
