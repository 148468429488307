import createSvgIcon from '../createSvgIcon';

export const FlowTemplate = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3431 15.794H15.3627V14.647H10.9706V12.5881H11.951V13.6666H16.3431V15.794Z" fill="#55607D" />
    <path
      d="M19.0686 19.9217H12.6373V15.4707H19.0686V19.9217ZM14.598 17.9609H17.1078V17.4315H14.598V17.9609Z"
      fill="#55607D"
    />
    <path
      d="M14.6765 12.794H8.2451V8.34302H14.6765V12.794ZM10.2059 10.8332H12.7157V10.3038H10.2059V10.8332Z"
      fill="#55607D"
    />
    <path
      d="M3.33333 17.696H1.37254V1.69604H15.8529V4.73526H13.8922V3.65683H3.33333V17.696Z"
      fill="#55607D"
    />
    <path
      d="M21.951 22.5099H5.29411V5.86279H21.951V22.5099ZM7.2647 20.5491H19.9902V7.82358H7.2549V20.5491H7.2647Z"
      fill="#55607D"
    />
  </svg>,
  'FlowTemplate'
);
