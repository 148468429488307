import { FC, PropsWithChildren } from 'react';
import { Menu } from '@mui/material';
import { SecureMenuProvider } from './SecureMenu.context';
import { convertEntityToAuthResource, selectors, TPermissionsEntity } from '@aiware/shared/permissions';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';

interface ISecureMenu {
  id: string;
  anchorEl: HTMLElement | null;
  anchorOriginX?: 'right' | 'left';
  anchorOriginY?: 'top' | 'bottom';
  transformOriginX?: 'right' | 'left';
  transformOriginY?: 'top' | 'bottom';
  entityId: string;
  disabled?: boolean;
  onClose: () => void;
}

export const SecureMenu: FC<PropsWithChildren<ISecureMenu>> = ({
  id,
  anchorEl,
  anchorOriginX = 'left',
  anchorOriginY = 'bottom',
  transformOriginX = 'right',
  transformOriginY = 'top',
  entityId,
  disabled = false,
  onClose,
  children,
}) => {
  const open = Boolean(anchorEl);

  const authResourceType = convertEntityToAuthResource(EntityType.Tdos);

  const entityPermissions: TPermissionsEntity | undefined = useSelector(
    selectors.selectPermissionsByEntity(authResourceType, entityId)
  );

  const isLoading =
    entityId &&
    (!entityPermissions || entityPermissions.status === 'pending' || entityPermissions.status === 'idle');

  return (
    <SecureMenuProvider
      value={{
        permissions: entityPermissions,
        isLoadingPermissions: !!isLoading,
        disabled: disabled,
      }}
    >
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: anchorOriginY,
          horizontal: anchorOriginX,
        }}
        transformOrigin={{
          vertical: transformOriginY,
          horizontal: transformOriginX,
        }}
      >
        {children}
      </Menu>
    </SecureMenuProvider>
  );
};
