import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISchemaProperties } from '../../types/aiStructuredData.types';

export const namespace = 'aiStructuredData';

export const initialState: State = {
  isAllSchemasActive: false,
  schemaSearchValue: '',
  selectedSchema: {
    dataRegistryId: null,
    name: null,
    majorVersion: null,
    minorVersion: null,
  },
  propertiesSearchValue: '',
  selectedProperty: {
    type: '',
    path: '',
    searchPath: '',
    title: '',
    propertyDefinition: {
      type: '',
    },
    schema: {
      id: '',
      definition: {},
      dataRegistry: {
        name: '',
        organization: {
          name: '',
        },
      },
      majorVersion: 1,
    },
  },
  operator: null,
  matchValueOne: null,
  matchValueTwo: null,
};

export interface ISelectedSchema {
  dataRegistryId: string | null;
  name: string | null;
  majorVersion: string | null;
  minorVersion: string | null;
}

export interface State {
  isAllSchemasActive: boolean;
  schemaSearchValue: string;
  selectedSchema: ISelectedSchema;
  propertiesSearchValue: string;
  selectedProperty: ISchemaProperties;
  operator: any;
  matchValueOne: string | number | boolean | null;
  matchValueTwo: string | number | boolean | null;
}

export const aiSearchFilterSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset: state => {
      state.isAllSchemasActive = initialState.isAllSchemasActive;
      state.schemaSearchValue = initialState.schemaSearchValue;
      state.selectedSchema = initialState.selectedSchema;
      state.propertiesSearchValue = initialState.propertiesSearchValue;
      state.selectedProperty = initialState.selectedProperty;
      state.operator = initialState.operator;
      state.matchValueOne = initialState.matchValueOne;
      state.matchValueTwo = initialState.matchValueTwo;
    },
    setIsAllSchemasActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isAllSchemasActive = payload;
    },
    setSchemaSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.schemaSearchValue = payload;
    },
    setSelectedSchema: (state, { payload }: PayloadAction<ISelectedSchema>) => {
      state.selectedSchema = payload;
    },
    setPropertiesSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.propertiesSearchValue = payload;
    },
    setSelectedProperty: (state, { payload }: PayloadAction<any>) => {
      state.selectedProperty = payload;
    },
    setOperator: (state, { payload }: PayloadAction<any>) => {
      state.operator = payload;
    },
    setMatchValue: (
      state,
      { payload }: PayloadAction<{ valueId: number; value: string | number | boolean | null }>
    ) => {
      const { valueId, value } = payload;

      if (valueId === 1) {
        state.matchValueOne = value;
      } else {
        state.matchValueTwo = value;
      }
    },
  },
});

export const actions = aiSearchFilterSlice.actions;
export default aiSearchFilterSlice.reducer;
