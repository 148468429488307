import { call, put, select } from 'redux-saga/effects';
import { selectApiConfigs } from '../selectors';
import { actions } from '../slices/edit-metadata';
import { ITdo } from '../../types';
import * as api from '../../api';
import { selectors } from '../..';

export function* fetchTdo(action: ReturnType<typeof actions.fetchTdoStart>) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const tdo: ITdo = yield call(api.tdos.getTdo, apiConfigs, action.payload);
    yield put(actions.fetchTdoSucceeded(tdo));
  } catch (e) {
    console.log('Failed to fetch TDO for editing', e);
    yield put(actions.fetchTdoFailed());
  }
}

export function* saveMetadata(action: ReturnType<typeof actions.saveMetadataStart>) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const tdo: ITdo | undefined = yield select(selectors.editMetadata.selectTdo);
    if (!tdo) {
      throw new Error('No TDO was loaded when trying to save metadata');
    }

    // Get updated data from redux
    const updatedFilename: string = yield select(selectors.editMetadata.selectFilename);
    const updatedImage: string = yield select(selectors.editMetadata.selectImageUnsigned);
    const updatedTags: string[] = yield select(selectors.editMetadata.selectTags);

    // Create copy with new data
    const tdoDetails = {
      ...(tdo.details || {}),
      name: updatedFilename,
      veritoneFile: {
        ...(tdo.details.veritoneFile || {}),
        filename: updatedFilename,
        fileName: updatedFilename,
      },
      tags: updatedTags.map(tag => ({ value: tag })),
    };

    // save to gql
    const newTdoDetails: ITdo = yield call(
      api.tdos.updateTdoMetadata,
      apiConfigs,
      tdo.id,
      updatedFilename,
      tdoDetails,
      updatedImage
    );

    // Refresh the data
    yield put(actions.fetchTdoSucceeded(newTdoDetails));
    yield put(actions.saveMetadataSucceeded());
  } catch (e) {
    console.log('Failed to save TDO metadata', e);
    yield put(actions.saveMetadataFailed());
  }
}
