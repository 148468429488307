import Box from '@mui/material/Box';
import InputUnstyled from '@mui/material/InputBase';
import { isNil } from 'lodash';
import React, { ChangeEvent, useRef } from 'react';
import { useTabCapturing } from './useTabCapturing';
import { useLineNumbers } from './useLineNumbers';
import useStyles from './InputTypeMarkdownStyles';

interface Props {
  value: string;
  name?: string;
  onChange: React.EventHandler<ChangeEvent<HTMLTextAreaElement>>;
}

export const InputTypeMarkdown = ({ value, name, onChange }: Props) => {
  const lineCounterRef = useRef<HTMLDivElement>();
  const codeEditorRef = useRef<HTMLDivElement>();
  const codeEditorInputRef = useRef<HTMLTextAreaElement>(null);
  const { classes } = useStyles();

  const scrollSync = (e: React.SyntheticEvent<HTMLDivElement>) => {
    if (!isNil(lineCounterRef.current)) {
      lineCounterRef.current.scrollTop = e.currentTarget.scrollTop;
      lineCounterRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }
  };

  const lineNumbers = useLineNumbers(value);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
  };

  const [handleKeyDown, handleClick] = useTabCapturing({
    inputRef: codeEditorInputRef,
    onChange,
  });

  return (
    <Box position={'relative'} padding={0} margin={0} width="100%">
      <InputUnstyled
        data-test="input-type-markdown-code-editor"
        name={name}
        ref={codeEditorRef}
        inputRef={codeEditorInputRef}
        className={classes.codeEditor}
        onScroll={scrollSync}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={handleClick}
        spellCheck={false}
        value={value}
        multiline
      />
      <InputUnstyled
        data-test="input-type-markdown-line-numbers"
        ref={lineCounterRef}
        className={classes.lineCounter}
        spellCheck={false}
        value={lineNumbers}
        multiline
        readOnly
      />
    </Box>
  );
};

export default InputTypeMarkdown;
