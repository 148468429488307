//
import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'root'>()(theme => ({
  root: {},
  container: {
    padding: theme.spacing(5, 3, 5, 3),
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#EEF3F9',
    flexDirection: 'column',
    borderRadius: '4px',
    marginBottom: '25px',
  },
  messageLabel: {
    marginBottom: '10px',
    color: '#5C6269',
  },
}));

export const ReviewRequestMessage: FunctionComponent<{
  message?: string | null;
}> = ({ message }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant={'subtitle2'} className={classes.messageLabel} component={'label'}>
        <FormattedMessage
          id="os-organization-panel.review-request-panel.author-message.word-message"
          defaultMessage="Message"
          description="Review Request Panel author message, the word message"
        />
        {': '}
      </Typography>
      <Typography>{message}</Typography>
    </div>
  );
};
