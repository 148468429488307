import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

export const tableStyles = makeStyles()((theme: Theme) => ({
  nullState: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  nullStateTitle: {
    fontWeight: 400,
    margin: '40px 0 15px 0',
  },
  nullStateButton: {
    margin: '30px 0',
  },
  loadingState: {
    padding: 20,
    display: 'block',
    textAlign: 'center',
  },
}));
