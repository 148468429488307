import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(4, 4),
      textAlign: 'center',
      border: '0.5px solid #D5DFE9',
      borderRadius: theme.spacing(2),
    },
    text: {
      marginTop: theme.spacing(3),
    },
    icon: {
      display: 'inline !important',
    },
  };
});
export default useStyles;
