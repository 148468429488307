import { FormattedMessage } from 'react-intl';

export default {
  FILE_ITEM_METADATA: {
    PANEL_TITLE: () => (
      <FormattedMessage
        id="os-data-center-importer.title.2W5MUI"
        defaultMessage="Edit Metadata"
        description="OS Data Center Local Importer (File Item Metadata): Edit Metadata for the file"
      />
    ),
    TAB: {
      FILE_METADATA: () => (
        <FormattedMessage
          id="os-data-center-importer.file.QE38X5"
          defaultMessage="File Metadata"
          description="OS Data Center Local Importer (File Item Metadata): File Metadata Tab"
        />
      ),
      CONTENT_TEMPLATES: () => (
        <FormattedMessage
          id="os-data-center-importer.content-template.40ASJF"
          defaultMessage="Content Templates"
          description="OS Data Center Local Importer (File Item Metadata): Content Templates Tab"
        />
      ),
    },
    CANCEL: () => (
      <FormattedMessage
        id="os-data-center-importer.6PdOcy"
        defaultMessage="Cancel"
        description="OS Data Center Local Importer (File Item Metadata): Cancel Button"
      />
    ),
    SAVE: () => (
      <FormattedMessage
        id="os-data-center-importer.kxBKrN"
        defaultMessage="Save Changes"
        description="OS Data Center Local Importer (File Item Metadata): Save Changes Button"
      />
    ),
  },
};
