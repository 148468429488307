import { ISagaModule } from 'redux-dynamic-modules-saga';
import { openIdConReducer } from './reducer';
import openIdRootSaga from './saga';
import { namespace } from './selector';

export function getOpenIdModule(): ISagaModule<unknown> {
  return {
    id: 'open-id',
    reducerMap: {
      [namespace]: openIdConReducer,
    },
    sagas: [openIdRootSaga],
    initialActions: [],
  };
}
