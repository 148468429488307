import { useSelector } from 'react-redux';
import { selectFuncPermissions } from '../../redux';
import Permission from '../new-permission-set/permission';

type TProps = {
  permissions: string[];
  setNewKeyValue: (payload: any) => void;
  isPermissionProtected: boolean;
};

const PermissionsTab = ({ permissions, setNewKeyValue, isPermissionProtected }: TProps) => {
  const funcPermissions = useSelector(selectFuncPermissions);
  return (
    <Permission
      onPermissionChange={permissions =>
        setNewKeyValue({
          permissions: Object.entries(funcPermissions)
            .filter(([_key, value]) => permissions.includes(value))
            .map(([key]) => key),
        })
      }
      permissionSetExistingPerm={permissions}
      deductedHeight={'45'}
      isPermissionProtected={isPermissionProtected}
    />
  );
};

export default PermissionsTab;
