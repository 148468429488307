import { useState, useEffect, FC, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Button,
  FormControl,
  FormGroup,
  Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

import { renderSelectAllText } from '../helpers/shared-text';
import { useStyles } from '../useStyles';
import { userSelector, selectIsSuperAdmin } from '@aiware/shared/redux';
import { IUser } from '@aiware/js/interfaces';

type TProps = {
  onPermissionChange: (value: string[]) => void;
  funcPermissions: { [key: string]: string };
  permissionSetExistingPerm?: string[] | null;
  isPermissionProtected?: boolean;
  setUpdatePermissionChange?: (value: boolean) => void;
  dataTestPrefix?: string;
  isUsedForApiTokens?: boolean;
};

const PermissionsList: FC<TProps> = ({
  onPermissionChange,
  funcPermissions,
  permissionSetExistingPerm,
  isPermissionProtected,
  setUpdatePermissionChange,
  dataTestPrefix,
  isUsedForApiTokens = false,
}: TProps) => {
  const { classes } = useStyles();
  const myRights: string[] = (useSelector(userSelector) as IUser)?.myRights ?? [];
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const transformedRights: string[] = myRights.map((right: string) => {
    const capitalizeWord = right.charAt(0).toUpperCase() + right.slice(1);
    return capitalizeWord.replace(/\./g, ':');
  });

  const permissionListArray = Object.values(funcPermissions).reduce(
    (permissionsList: string[][], permission: string) => {
      const lastPermissionSet = permissionsList.length ? permissionsList[permissionsList.length - 1] : [];

      if (
        permissionsList.length &&
        lastPermissionSet![0]!.slice(0, lastPermissionSet![0]!.lastIndexOf(':')) ===
          permission.slice(0, permission.lastIndexOf(':'))
      ) {
        lastPermissionSet!.push(permission);
      } else {
        permissionsList.push([permission]);
      }

      return permissionsList;
    },
    []
  );

  const [isChecked, setIsChecked] = useState(() => permissionListArray.map(set => set.map(() => false)));
  const [functionalPermissions, setFunctionalPermissions] = useState<string[]>([]);
  const [selectGroup, setSelectGroup] = useState(() => permissionListArray.map(() => false));

  useEffect(() => {
    if (isChecked.length <= 0) {
      setIsChecked(() => permissionListArray.map(set => set.map(() => false)));
    }
    if (selectGroup.length <= 0) {
      setSelectGroup(() => permissionListArray.map(() => false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funcPermissions]);

  useEffect(() => {
    if (!permissionSetExistingPerm) return;

    const permissionsInExistingSet: string[] = [];
    Object.entries(funcPermissions).forEach(entry => {
      if (permissionSetExistingPerm?.find(eP => eP === entry[0])) {
        permissionsInExistingSet.push(entry[1]);
      }
    });
    if (permissionsInExistingSet.length <= 0) return;

    setFunctionalPermissions(uniq(permissionsInExistingSet));
    const newSelectGroup = [...selectGroup];
    setIsChecked(
      permissionListArray.map((group, index) => {
        const newGroup = group.map((item: string) => {
          if (permissionsInExistingSet.indexOf(item) !== -1) return true;
          return false;
        });
        if (newGroup.find((set: boolean) => set === false) === undefined) {
          newSelectGroup[index] = true;
        }

        setSelectGroup(newSelectGroup);

        return newGroup;
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionSetExistingPerm]);

  const isCheckboxChecked = (setIndex: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const value = event.target.value;
    if (checked) {
      setFunctionalPermissions(uniq([...functionalPermissions, value]));
    } else {
      setFunctionalPermissions(current => uniq(current.filter(element => element !== value)));
    }

    setIsChecked(isChecked => {
      return isChecked.map((set, i) => {
        if (i !== setIndex) return set;

        const newSet = set.map((item: boolean, i: number) => {
          if (i === index) {
            return checked;
          }
          return item;
        });
        // if everything is checked in a set OR if everything is unchecked
        if (
          newSet.find((item: boolean) => !item) === undefined ||
          (newSet.find((item: boolean) => item) === undefined && selectGroup[setIndex])
        ) {
          setSelectGroup(selectGroup => {
            return selectGroup.map((currentSet, i) => {
              if (i === setIndex) {
                return !selectGroup[i];
              }
              return currentSet;
            });
          });
        }
        return newSet;
      });
    });

    setUpdatePermissionChange?.(true);
  };

  const permissionGroupSelect = (setIndex: number, permissions: string[]) => {
    setSelectGroup(selectGroup => {
      return selectGroup.map((currentSet, i) => {
        if (i !== setIndex) return currentSet;
        return !selectGroup[i];
      });
    });

    setIsChecked(isChecked => {
      return isChecked.map((set, i) => {
        if (i !== setIndex) return set;

        if (selectGroup[setIndex]) {
          setFunctionalPermissions(current => uniq(current.filter(value => !permissions.includes(value))));
          return set.map(() => {
            return false;
          });
        } else {
          if (isUsedForApiTokens) {
            const permissionsInMyRights: string[] = permissions.filter(permission => {
              //checking permissions are not supposed to be in the functionalPermissions first
              return (
                !functionalPermissions.includes(permission) &&
                (isSuperAdmin || transformedRights.includes(permission))
              );
            });
            setFunctionalPermissions([...functionalPermissions, ...permissionsInMyRights]);
          } else {
            setFunctionalPermissions(uniq([...functionalPermissions, ...permissions]));
          }
          return set.map(() => {
            return true;
          });
        }
      });
    });
    if (setUpdatePermissionChange) {
      setUpdatePermissionChange(true);
    } // Update token button will be enabled
  };

  useEffect(() => {
    onPermissionChange(functionalPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionalPermissions]);

  return (
    <>
      {permissionListArray.map((item, setIndex) => (
        <FormControl
          sx={{ m: 0, width: '100%' }}
          component="fieldset"
          variant="standard"
          key={item[0]}
          disabled={isPermissionProtected}
        >
          <FormLabel component="legend" sx={{ width: '100%' }}>
            <Box className={classes.titleContainer}>
              <Typography
                variant={'body2'}
                className={classes.mainTitle}
                data-testid={`${dataTestPrefix}-permissions.functional-permissions.group-title`}
                data-test={`${dataTestPrefix}-permissions.functional-permissions.group-title`}
              >
                {item[0]?.includes(':') ? item[0].slice(0, item[0].lastIndexOf(':')) : item[0]}
              </Typography>
              {!isPermissionProtected && (
                <Button
                  className={classes.selectBtn}
                  onClick={() => permissionGroupSelect(setIndex, item)}
                  color="primary"
                  variant="text"
                  data-test={`${dataTestPrefix}-permissions.functional-permissions.save-btn`}
                >
                  {selectGroup[setIndex]
                    ? renderSelectAllText('Clear', dataTestPrefix)
                    : renderSelectAllText('Select', dataTestPrefix)}
                </Button>
              )}
            </Box>
          </FormLabel>
          {item.length &&
            item.map((element: string, index: number) => (
              <FormGroup
                className={classes.permissionsRow}
                key={element}
                data-testid={`${dataTestPrefix}-permission-form-control-checkbox`}
                data-test={`${dataTestPrefix}-permission-form-control-checkbox`}
              >
                <FormControlLabel
                  className={classes.formLabelStyle}
                  labelPlacement="start"
                  data-testid={`${dataTestPrefix}-permission-form-control-label`}
                  data-test={`${dataTestPrefix}-permission-form-control-label`}
                  control={
                    <Tooltip
                      className="aiware-el"
                      title={
                        isUsedForApiTokens &&
                        !isSuperAdmin &&
                        !transformedRights.some(
                          transformedRight => element.toLowerCase() === transformedRight.toLowerCase()
                        ) && (
                          <FormattedMessage
                            id="admin-center-new-permission-permission-not-available-message"
                            defaultMessage="This permission is not {br} available in your Organization"
                            description="Permission not available message"
                            values={{ br: <br /> }}
                          />
                        )
                      }
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: '12px',
                            fontFamily: 'Nunito',
                            fontStyle: 'Normal',
                            lineHeight: '16px',
                            letterSpacing: '-1.30385e-09px',
                            fontWeight: 400,
                          },
                        },
                      }}
                      data-testid="admin-center-new-permission-permission-not-available-message-tool-tip"
                      data-test="admin-center-new-permission-permission-not-available-message-tool-tip"
                    >
                      <Checkbox
                        name={element}
                        value={element}
                        id={element}
                        size="small"
                        checked={Boolean(isChecked?.[setIndex]?.[index])}
                        color="primary"
                        onChange={e => isCheckboxChecked(setIndex, index, e)}
                        data-testid={`${dataTestPrefix}-permissions.functional-permissions.checkbox`}
                        data-test={`${dataTestPrefix}-permissions.functional-permissions.checkbox`}
                        disabled={
                          (isUsedForApiTokens &&
                            !isSuperAdmin &&
                            !transformedRights.some(
                              transformedRight => element.toLowerCase() === transformedRight.toLowerCase()
                            )) ||
                          isPermissionProtected
                        }
                      />
                    </Tooltip>
                  }
                  label={
                    <Typography
                      variant={'body2'}
                      className={classes.checkboxLabel}
                      data-testid={`${dataTestPrefix}-permissions.functional-permissions.checkbox-label`}
                      data-test={`${dataTestPrefix}-permissions.functional-permissions.checkbox-label`}
                    >
                      {element}
                    </Typography>
                  }
                />
              </FormGroup>
            ))}
        </FormControl>
      ))}
    </>
  );
};

export default PermissionsList;
