import { useStyles } from './EngineItem.styles';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { IEngineAdapter } from '../../../../../redux/slices/panel-select-import-source';
import { Source } from '@aiware/shared/icons';

interface IProps {
  engineImportItem: IEngineAdapter;
  onClick: () => void;
  isActive: boolean;
}

export const EngineItem: React.FC<IProps> = ({ engineImportItem, onClick, isActive }) => {
  const { classes } = useStyles();

  const { displayName, name, description, signedLogoPath, signedIconPath } = engineImportItem;
  const [imageSrc, setImageSrc] = useState(signedIconPath);
  const [isImageError, setIsImageError] = useState(false);

  const handleIconError = () => {
    if (signedLogoPath && imageSrc !== signedLogoPath) {
      setImageSrc(signedLogoPath);
      return;
    } else {
      setIsImageError(true);
    }
  };

  return (
    <div
      data-test-id={`engine-item-id-${name}`}
      className={`${classes.container} ${isActive ? classes.isActive : ''}`}
      onClick={onClick}
    >
      <div className={classes.iconContainer}>
        {imageSrc && !isImageError && <img onError={handleIconError} src={imageSrc} alt={'Engine Icon'} />}
        {(!imageSrc || isImageError) && <Source sx={{ color: '#555F7C' }} />}
      </div>
      <div data-test-id={`engine-item-id-label-container-${name}`} className={classes.labelContainer}>
        {displayName && <Typography id={`engine-item-id-label-${displayName}`}>{displayName}</Typography>}
        {!displayName && <Typography id={`engine-item-id-label-${name}`}>{name}</Typography>}
        <Typography variant={'caption'}>{description}</Typography>
      </div>
    </div>
  );
};
