import { useState, useEffect, useRef, FunctionComponent, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { makeStyles } from 'tss-react/mui';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import { ExpandMore, MoreHor } from '@aiware/shared/icons';
import ContextMenu from './ContextMenu';
import { Breadcrumb, EntityTypesWithActions } from '../../types';
import { ENTITY_CONTEXT_MENU } from '../../types/entity-context-menus';
import { selectPanelMode } from '../../redux/selectors/ui-state';
import { EAuthResourceType } from '@aiware/shared/permissions';

const useStyles = makeStyles<{ panelMode: boolean }, 'crumbText'>()((theme, { panelMode }, classes) => ({
  root: {
    width: panelMode ? 300 : 400,
  },
  breadcrumbs: {
    '& ol': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
  },
  // to measure width
  hiddenBreadcrumb: {
    visibility: 'hidden',
    height: 0,
    display: 'block',
    position: 'absolute',
  },
  hiddenPathsMenu: {
    padding: theme.spacing(1.5),
  },
  crumbText: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  lastCrumb: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  crumbWithMenu: {
    [`& ${classes.crumbText}`]: {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const BreadCrumbItem: FunctionComponent<{
  className?: string;
  text?: string;
  icon?: JSX.Element | null;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}> = ({ text, icon, onClick, ...props }) => {
  const { classes } = useStyles({ panelMode: false });
  return (
    <Button onClick={onClick} disableRipple {...props}>
      <span className={classes.crumbText}>{text}</span>
      {icon}
    </Button>
  );
};

export const PathPicker: FunctionComponent<{
  pathList: Breadcrumb[];
  goToCrumbActionCreator?: ActionCreatorWithPayload<Breadcrumb>;
}> = ({ pathList = [], goToCrumbActionCreator }) => {
  const dispatch = useDispatch();
  const panelMode = useSelector(selectPanelMode);
  const { classes } = useStyles({ panelMode });
  const [collapseEl, setCollapseEl] = useState<HTMLButtonElement | null>(null);
  const [contextMenuEl, setContextMenuEl] = useState<HTMLButtonElement | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const breadcrumbRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (containerRef?.current && breadcrumbRef?.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const breadcrumbWidth = breadcrumbRef.current.offsetWidth;

      if (breadcrumbWidth > containerWidth) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    }
  }, [pathList, isCollapsed, containerRef, breadcrumbRef]);

  const collapsedCrumbMenuId = collapseEl ? 'collapsedCrumbMenuId' : undefined;
  const contextMenuId = contextMenuEl ? 'contextMenuId' : undefined;
  const rootPath = pathList[0]!;
  const hiddenPaths = pathList.slice(1, pathList.length - 1);
  const lastPath = pathList[pathList.length - 1];
  const contextMenu = lastPath?.entity && ENTITY_CONTEXT_MENU[lastPath?.entity as EntityTypesWithActions];
  const shouldRenderContextMenu =
    !!contextMenu && (contextMenu.actions.length || !!contextMenu.deleteAction) && panelMode === false;

  const handleCrumbClick = (crumb: Breadcrumb) => {
    collapseEl && setCollapseEl(null);
    goToCrumbActionCreator && dispatch(goToCrumbActionCreator(crumb));
  };

  const handleContextMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    shouldRenderContextMenu && setContextMenuEl(e.currentTarget);
  };

  const renderCollapsedViewCrumbs = () => {
    return [
      <BreadCrumbItem
        key="collapsed-root"
        onClick={() => handleCrumbClick(rootPath)}
        text={rootPath.name}
      ></BreadCrumbItem>,
      <IconButton
        key="collapsed-more"
        onClick={e => setCollapseEl(e.currentTarget)}
        aria-describedby={collapsedCrumbMenuId}
        disableRipple
        size="large"
        data-testid={'collapsed-more-button'}
      >
        <MoreHor />
      </IconButton>,
      <BreadCrumbItem
        key="collapsed-last"
        aria-describedby={contextMenuId}
        className={shouldRenderContextMenu ? classes.crumbWithMenu : classes.lastCrumb}
        text={lastPath?.name}
        icon={shouldRenderContextMenu ? <ExpandMore fontSize="small" /> : null}
        onClick={handleContextMenuOpen}
      />,
    ];
  };

  const renderRegularCrumbs = () => {
    if (pathList.length === 1) {
      return (
        <div key={rootPath?.id}>
          <BreadCrumbItem onClick={() => handleCrumbClick(rootPath)} text={rootPath?.name} />
        </div>
      );
    }

    return pathList.map((path, index) => (
      <div key={path.id}>
        {index === pathList.length - 1 ? (
          <BreadCrumbItem
            className={shouldRenderContextMenu ? classes.crumbWithMenu : classes.lastCrumb}
            aria-describedby={contextMenuId}
            text={path.name}
            icon={shouldRenderContextMenu ? <ExpandMore fontSize="small" /> : null}
            onClick={handleContextMenuOpen}
          />
        ) : (
          <BreadCrumbItem onClick={() => handleCrumbClick(path)} text={path.name} />
        )}
      </div>
    ));
  };

  return (
    <Grid container direction="row" alignItems="center" className={classes.root} ref={containerRef}>
      <div className={classes.hiddenBreadcrumb} hidden>
        <Breadcrumbs className={classes.breadcrumbs} ref={breadcrumbRef}>
          {renderRegularCrumbs()}
        </Breadcrumbs>
      </div>

      <Breadcrumbs aria-label="breadcrumb" data-test="breadcrumbs" className={classes.breadcrumbs}>
        {isCollapsed && pathList.length > 2 ? renderCollapsedViewCrumbs() : renderRegularCrumbs()}
      </Breadcrumbs>

      <Popover
        id={collapsedCrumbMenuId}
        open={!!collapseEl}
        anchorEl={collapseEl}
        onClose={() => setCollapseEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList role="menu" className={classes.hiddenPathsMenu}>
          {hiddenPaths.map(path => (
            <MenuItem key={path.id} onClick={() => handleCrumbClick(path)}>
              {path.name}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>

      {contextMenu && (
        <ContextMenu
          anchorEl={contextMenuEl}
          anchorOriginX="right"
          id={contextMenuId}
          open={!!contextMenuEl}
          onClose={() => setContextMenuEl(null)}
          contextMenu={contextMenu}
          entityId={(lastPath?.sourceId ?? lastPath?.id) as string}
          type={EAuthResourceType.Folder}
        />
      )}
    </Grid>
  );
};

export default PathPicker;
