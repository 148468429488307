import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Stack, Typography } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import { ICON_FIELD_MAP, ICON_FONT_SIZE_SX } from '../constants';

export const FieldTypes: React.FC<{ type: string; index: number }> = ({ type, index }) => {
  return (
    <Draggable draggableId={`type-${type}`} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems="center"
            sx={{
              p: 1,
              bgcolor: snapshot.isDragging ? '#FAFAFA' : 'white',
              border: '1px solid #BDBDBD',
              borderRadius: '4px',
            }}
          >
            <Stack gap={1} direction={'row'} alignItems="center">
              {ICON_FIELD_MAP[type as keyof typeof ICON_FIELD_MAP] || ICON_FIELD_MAP['default']}
              <Typography textTransform={'capitalize'} fontSize="12px">
                {type}
              </Typography>
            </Stack>
            <DragIndicator sx={ICON_FONT_SIZE_SX} />
          </Stack>
        </div>
      )}
    </Draggable>
  );
};

export default FieldTypes;
