import { Portal } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { panelsSelector } from '@aiware/js/panel';
import { IPanel, IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';

export type FullscreePortalProps = React.PropsWithChildren<{
  dataTest: string;
  panelId: string;
  dimmed: boolean;
}>;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: 1280,
    position: 'relative',
  },
  panel: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    height: '100%',
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overlay: {
    backgroundColor: theme.palette.grey[50],
    opacity: `0.5 !important`,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

export const FullscreenPortal: React.FC<FullscreePortalProps> = ({ dataTest, panelId, dimmed, children }) => {
  const { classes } = useStyles();
  const panels = (useSelector(panelsSelector) || []) as IPanel<unknown, IVeritoneAppbarPanelConfig>[];
  const thisPanel = panels.find(panel => panel.panelId === panelId);
  const zIndex = thisPanel?.panelConfig.zIndex || thisPanel?.panelConfig.autoZIndex || 1099; // default to a high number

  return (
    <Portal>
      <div
        role="presentation"
        data-test={dataTest}
        className={classNames(classes.panel, classes.fullScreen)}
        style={{ zIndex }}
      >
        {dimmed && <div className={classes.overlay}></div>}
        {children}
      </div>
    </Portal>
  );
};
