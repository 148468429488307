import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { mountPanel } from '@aiware/js/panel';
import { ErrorState } from '@aiware/ui';
import {
  actions,
  getAdminCenterPermissionsModule,
  selectPermissionSetsLoadingStatus,
  selectPermissionSetsPagination,
} from '../../redux';
import { navSectionStyles } from '@aiware/os/admin-center/shared';
import { Add } from '@aiware/shared/icons';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { Skeleton } from '@mui/material';
import PermissionSetsTable from './permission-sets-table';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

export const newPermissionSetPanel = 'NEW_PERMISSION_SET_PANEL';

export const PermissionSets = () => {
  const { classes } = navSectionStyles();
  const loadingStatus = useSelector(selectPermissionSetsLoadingStatus);
  const { hasMore } = useSelector(selectPermissionSetsPagination);
  const dispatch = useDispatch();
  const isPanelFullScreen = useSelector(selectIsFullscreenEnabled);

  const openNewPermissionSetPanel = () => {
    const microFrontend = {
      name: newPermissionSetPanel,
      config: {
        name: 'New Permission set',
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isPanelFullScreen ? 0 : 55,
      marginStart: isPanelFullScreen ? 0 : 80,
      size: 'medium',
      parentPanelId: 'ADMIN_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: newPermissionSetPanel,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  useEffect(() => {
    if (hasMore) {
      dispatch(actions.adminCenterPermissions.fetchOrgPermissionSetsStart());
    }

    dispatch(actions.adminCenterPermissions.fetchFunctionalPermissionsStart());

    return () => {
      dispatch(actions.adminCenterPermissions.fetchOrgPermissionSetsReset());
    };
  }, []);

  const skltn = (
    <>
      {[1, 2, 3].map(el => {
        return (
          <div key={el}>
            <br />
            <Skeleton variant="rectangular" />
          </div>
        );
      })}
    </>
  );

  const loadPermissionSets = () => {
    dispatch(actions.adminCenterPermissions.fetchOrgPermissionSetsStart());
  };

  const renderContent = () => {
    switch (loadingStatus) {
      case 'loading':
        return skltn;
      case 'success':
        return <PermissionSetsTable loadPermissionSets={loadPermissionSets} />;
      case 'failure':
        return (
          <ErrorState
            onRetry={loadPermissionSets}
            errorMessage={
              <FormattedMessage
                id="os-admin-center-permission-sets.error-message"
                defaultMessage="Something went wrong while loading your permission sets."
                description="organizations permission sets error state"
              />
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <DynamicModuleLoader modules={[getAdminCenterPermissionsModule()]}>
      <section className={classes.root}>
        <header className={classes.header}>
          <div className={classes.toolbar}>
            <div>
              <Typography variant="h5">
                <FormattedMessage
                  id="os-admin-center-panel.permission-sets.table.header"
                  defaultMessage="Permission Sets"
                  description="Permission Sets Header"
                />
              </Typography>
              <Typography variant="body2" sx={{ maxWidth: '630px' }}>
                <FormattedMessage
                  id="os-admin-center-panel.permission-sets.table.description"
                  defaultMessage="A permission set, is a series of functional permissions that a user can perform on objects within their account."
                  description="Permission Sets Description"
                />
              </Typography>
            </div>
          </div>
          <div className={classes.toolbar}>
            <Button color="primary" variant="contained" onClick={openNewPermissionSetPanel}>
              <Add />
              <FormattedMessage
                id="os-admin-center-panel.permission-sets.create.button"
                defaultMessage="Create New"
                description="Create New Permission Set"
              />
            </Button>
          </div>
        </header>
        <div className={classes.tableWrapper}>{renderContent()}</div>
      </section>
    </DynamicModuleLoader>
  );
};
