import { generateState, GeneratedState } from '@aiware/shared/reusable-utils';
import * as api from '../../api';
import { ISchemaProperties } from '../../types/aiStructuredData.types';
import { SLICE_NAME } from '../../../../types';
import { selectSchemaPropertiesSearchValue } from './schemaProperties.selectors';
import { selectSelectedSchemaPropertyApiPayload } from './aiStructuredData.selectors';

export const namespace = 'schemaProperties';

export const { slice, selectors, sagas, initialState } = generateState<ISchemaProperties>({
  sliceName: SLICE_NAME,
  namespace,
  dedupeResponse: false,
  apiCalls: {
    read: api.query.getSchemaProperties,
    pageSize: 300,
    getAdditionalSelectors: () => ({
      searchValue: selectSchemaPropertiesSearchValue,
      dataRegistryVersion: selectSelectedSchemaPropertyApiPayload,
    }),
  },
});

export type SchemaPropertiesState = GeneratedState<ISchemaProperties>;
