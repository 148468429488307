import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  tag: {
    fontWeight: 600,
    fontSize: '12px',
  },
  tagNoLogo: {
    backgroundColor: '#F2F7FE',
    borderRadius: '50%',
    padding: '2px',
  },
  tagLogoContainer: {
    width: '35px',
    height: '26px',
    marginLeft: '0',
    position: 'relative',
    left: '-5px',
  },
}));
