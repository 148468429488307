import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareCacheProvider } from '@aiware/shared/theme';
import { MainLayout } from './main-layout';
import { ISchedule, ISource } from '../../../types';

export type TScheduleInfoProps = {
  schedule: ISchedule;
  source: ISource;
};

export const ScheduleInfoPanel: React.FC<TScheduleInfoProps> = props => {
  return (
    <AIWareThemeProvider>
      <AIWareIntlProvider>
        <AIWareCacheProvider>
          <MainLayout {...props} />
        </AIWareCacheProvider>
      </AIWareIntlProvider>
    </AIWareThemeProvider>
  );
};

export default ScheduleInfoPanel;
