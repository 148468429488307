import createSvgIcon from '../createSvgIcon';
export const Checkmark = createSvgIcon(
  <svg width="14" height="24" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9089 0.5L4.86127 8.19533L2.09141 5.18259L0.583496 6.82909L4.86127 11.5L13.4168 2.15817L11.9089 0.5Z"
      fill="currentColor"
    />
  </svg>,
  'Checkmark'
);
