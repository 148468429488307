import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import useStyles from './useStyles';
import { LocalSee } from '@mui/icons-material';

interface Props {
  onEditAvatarClick: () => void;
  avatar?: string | null;
  sortName?: string;
  loadingStatus?: string;
}

const AvatarProfile = ({ avatar, sortName, onEditAvatarClick, loadingStatus }: Props) => {
  const { classes } = useStyles();
  const onUploadClick = () => {
    onEditAvatarClick();
  };
  return (
    <div>
      <div className={classes.root}>
        <Badge
          data-test="dt-button-upload-avatar-icon"
          overlap="circular"
          data-testid="dt-button-upload-avatar-icon"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <IconButton
              color="primary"
              aria-label="upload picture"
              className={classes.changeAvatarIcon}
              onClick={onUploadClick}
              data-test="app-bar-update-avatar"
              size="large"
              data-testid="app-bar-update-avatar"
              sx={{
                '& svg': {
                  color: '#8b8b8b',
                },
                '&:hover svg': {
                  color: '#0C53B0',
                },
              }}
            >
              <LocalSee sx={{ fontSize: '16px' }} />
            </IconButton>
          }
        >
          {loadingStatus === 'loading' ? (
            <CircularProgress />
          ) : avatar ? (
            <Avatar data-test="dt-user-avatar-image" src={avatar} className={classes.avatarLarge} />
          ) : (
            <Avatar className={classes.avatarLarge}>{sortName?.toUpperCase()}</Avatar>
          )}
        </Badge>
      </div>
    </div>
  );
};

export default AvatarProfile;
