import { Box, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { Close, Hide } from '@aiware/shared/icons';
import { EnterFullScreenButton, ExitFullScreenButton, SHARED_TEXT } from '../helpers';

export interface IPanelHeaderProps {
  height?: number;
  title: React.ReactElement | string;
  description?: React.ReactElement | string;
  PanelActions?: React.ReactNode;
  PanelCloseIconType?: 'close' | 'hide';
  testId: string;
  onPanelClose?: () => void;
  disableBorder?: boolean;
  onSetFullScreen?: (value: boolean) => void;
}

export const PanelHeader: React.FC<IPanelHeaderProps> = ({
  title,
  description,
  height = 64,
  PanelActions,
  testId,
  onPanelClose,
  disableBorder,
  onSetFullScreen,
  PanelCloseIconType = 'close',
}) => {
  const isFullScreenBtnsRendered = !!onSetFullScreen;
  const handleEnterFullScreen = () => onSetFullScreen && onSetFullScreen(true);
  const handleExitFullScreen = () => onSetFullScreen && onSetFullScreen(false);

  const panelCloseIconMap: {
    [key in 'close' | 'hide']: React.ReactNode;
  } = {
    close: <Close />,
    hide: <Hide />,
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        borderBottom: disableBorder ? undefined : theme => `0.5px solid ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'space-between',
        height,
        padding: theme => theme.spacing(2, 2, 2, 6),
        flexShrink: 0,
      }}
    >
      <Stack direction="column" spacing={0}>
        <Typography
          sx={{
            userSelect: 'none',
            fontStyle: 'normal',
            fontSize: '18px',
            lineHeight: theme => theme.spacing(5),
            letterSpacing: '0.25px',
            textTransform: 'uppercase',
            color: theme => theme.palette.text.secondary,
          }}
          variant="h1"
          data-testid={`panel-header-${testId}`}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{
              userSelect: 'none',
              fontStyle: 'normal',
              fontSize: '14px',
              lineHeight: theme => theme.spacing(5),
              letterSpacing: '0.25px',
              color: theme => theme.palette.text.secondary,
            }}
            variant="h2"
          >
            {description}
          </Typography>
        )}
      </Stack>

      <Stack sx={PanelActions ? { gap: 0 } : {}} direction="row" alignItems="center">
        {PanelActions}
        {isFullScreenBtnsRendered && (
          <>
            <EnterFullScreenButton onClick={handleEnterFullScreen} testId={testId} />
            <ExitFullScreenButton onClick={handleExitFullScreen} testId={testId} />
          </>
        )}
        {onPanelClose && (
          <>
            <Typography style={visuallyHidden} id={`panel-header-close-${testId}`}>
              {SHARED_TEXT.closePanel()}
            </Typography>
            <Tooltip title={SHARED_TEXT.closePanel()}>
              <IconButton
                onClick={onPanelClose}
                aria-labelledby={`panel-header-close-${testId}`}
                data-testid={`panel-header-close-${testId}`}
                size="medium"
              >
                {panelCloseIconMap[PanelCloseIconType]}
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </Box>
  );
};
