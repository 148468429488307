import { baseGraphQLApi } from '@aiware/shared/redux';
import { THelperResponse, WallpaperApplicationConfig } from '../types';
import { devErrorLogger } from '@aiware/shared/reusable-utils';
export const TDOhelper = async (
  id: string,
  graphEndpoint: string,
  token: string
): Promise<THelperResponse | undefined> => {
  if (!id) {
    return { error: true };
  }
  const operationName = 'fetchWallpaperTDO';
  const query = `query ${operationName} {
        temporalDataObject(id: "${id}") {
          assets {
            records {        
              description 
              signedUri
            }
          }
        }
      }`;
  try {
    const { data, errors } = await baseGraphQLApi({
      query,
      operationName,
      variables: undefined,
      extraHeaders: undefined,
      graphEndpoint,
      token,
    });
    if (errors) {
      // process when TDO is not there
      if (errors.find((error: Error) => error.message.includes('The requested TDO was not found'))) {
        return { error: true };
      }
    }
    return { records: data?.temporalDataObject?.assets?.records };
  } catch (err) {
    devErrorLogger(JSON.stringify(err));
  }
};

export const createWallpaperApplicationConfig = async (
  key: string,
  graphEndpoint: string,
  token: string,
  appId = 'e4739d44-53d2-4153-b55f-5e246fc989b1' // FIXME: this has to be Desktop's application id
) => {
  const operationName = 'createAppConfigDefinition';
  const query = `mutation ${operationName} {
    applicationConfigDefinitionCreate(
      input: {
        appId: "${appId}"
        configKey: "${key}"
        configType: String
        configLevel: User
        required: false
        secured: false
        description: "wallpaper for aiWARE Desktop"
      }
    ) {
      records {
        key: configKey
        defaultValue        
      }
    }
  }`;
  try {
    const { data, errors } = await baseGraphQLApi({
      query,
      graphEndpoint,
      token,
      operationName,
      variables: undefined,
      extraHeaders: undefined,
    });
    if (
      errors &&
      errors.find((error: Error) => error.message.includes('duplicate key value violates unique constraint'))
    ) {
      return false;
    }
    if (data && data?.applicationConfigDefinitionCreate) {
      return true;
    }
  } catch (error) {
    devErrorLogger(JSON.stringify(error));
    return null;
  }
};

export const setWallpaperApplicationConfig = async (
  key: string,
  value: string,
  graphEndpoint: string,
  token: string,
  appId = 'e4739d44-53d2-4153-b55f-5e246fc989b1' // FIXME: this has to be Desktop's application id
): Promise<WallpaperApplicationConfig | undefined> => {
  let retryCounter = 0;
  const operationName = 'applicationConfigSet';
  const query = `mutation ${operationName} {
    applicationConfigSet(
      input: {
        appId: "${appId}"
        configs: [
          {
            configKey: "${key}"
            configValue: "${value}"           
          }
        ]
      }
    ) {
      records {
        key: configKey
        value        
      }
    }
  }`;
  try {
    const { data, errors } = await baseGraphQLApi({
      query,
      graphEndpoint,
      token,
      operationName,
      variables: undefined,
      extraHeaders: undefined,
    });
    if (
      errors &&
      !retryCounter &&
      errors?.find((error: Error) => error.message.includes('violates foreign key constraint'))
    ) {
      // key does not exist, try creating and then set
      retryCounter += 1;
      await createWallpaperApplicationConfig(key, graphEndpoint, token);
      return setWallpaperApplicationConfig(key, value, graphEndpoint, token);
    } else if (errors && errors.length) {
      for (const error of errors) {
        throw new Error(error.message);
      }
    }
    if (data && data?.applicationConfigSet) {
      return data.applicationConfigSet.records[0];
    }
  } catch (error) {
    devErrorLogger(JSON.stringify(error));
    return null;
  }
};

export const getWallpapersApplicationConfig = async (
  userId: string,
  graphEndpoint: string,
  token: string,
  specificKey?: string,
  appId = 'e4739d44-53d2-4153-b55f-5e246fc989b1' // FIXME: this has to be Desktop's application id
) => {
  const operationName = 'getApplicationConfig';
  const query = `query ${operationName} {
    applicationConfig(
      appId: "${appId}"
      userId: "${userId}"
      configKeyRegexp: ${specificKey ? `"${specificKey}"` : `"wallpaper"`}
    ) {
      records {
        key: configKey
        value       
      }
    }
  }`;

  try {
    const { data } = await baseGraphQLApi({
      query,
      graphEndpoint,
      token,
      operationName,
      variables: undefined,
      extraHeaders: undefined,
    });
    if (data && data?.applicationConfig) {
      return data.applicationConfig.records;
    }
  } catch (error) {
    devErrorLogger(JSON.stringify(error));
    return null;
  }
};
