import { Fragment, FunctionComponent } from 'react';
import map from 'lodash/map';
import slice from 'lodash/slice';
import Box from '@mui/material/Box';
import ProgressStep from './ProgressStep';
import StepConnector from '@mui/material/StepConnector';

interface Props {
  steps: Array<string>;
  activeStep: number;
  maxVisibleFutureSteps: number;
  stepIconSize: number;
  visited: Array<boolean>;
  totalSteps: number;
  onClick: (index: number) => void;
  nonLinear?: boolean;
  idPrefix?: string;
}

const VisibleSteps: FunctionComponent<Props> = ({
  steps,
  activeStep,
  maxVisibleFutureSteps,
  stepIconSize,
  visited,
  totalSteps,
  onClick,
  nonLinear,
  idPrefix,
}: Props) => {
  return (
    <Fragment>
      {map(slice(steps, activeStep, activeStep + maxVisibleFutureSteps + 1), (label, offset) => {
        const index = activeStep + offset;

        return (
          <Box key={label} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <ProgressStep
              step={index + 1}
              size={stepIconSize}
              active={index === activeStep}
              visited={visited[index]}
              alwaysShowLabel
              label={label}
              onClick={onClick}
              nonLinear={nonLinear}
              idPrefix={idPrefix}
            />
            {index < activeStep + maxVisibleFutureSteps && index < totalSteps - 1 && (
              <StepConnector sx={{ width: 45, marginRight: '10px' }} />
            )}
          </Box>
        );
      })}
    </Fragment>
  );
};

export default VisibleSteps;
