import createSvgIcon from '../createSvgIcon';

export const OrgSettings = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5H10V7H12V5ZM14 7H16V5H14V7ZM16 9H14V11H16V9ZM12 9H10V11H12V9ZM2 23H13.11C11.81 21.73 11 19.96 11 18C11 17.83 11 17.67 11.03 17.5H10V21H4V3H18V11C18.7 11 19.37 11.11 20 11.29V1H2V23ZM6 15H8V13H6V15ZM6 11H8V9H6V11ZM6 7H8V5H6V7ZM8 17H6V19H8V17ZM12 13H10V15H11.68C11.78 14.8 11.89 14.6 12 14.41V13ZM16.55 21.5L16.85 23H18.85L19.15 21.5C19.35 21.4167 19.5375 21.3292 19.7125 21.2375C19.8875 21.1458 20.0667 21.0333 20.25 20.9L21.7 21.35L22.7 19.65L21.55 18.65C21.5833 18.45 21.6 18.2333 21.6 18C21.6 17.7667 21.5833 17.55 21.55 17.35L22.7 16.35L21.7 14.65L20.25 15.1C20.0667 14.9667 19.8875 14.8542 19.7125 14.7625C19.5375 14.6708 19.35 14.5833 19.15 14.5L18.85 13H16.85L16.55 14.5C16.35 14.5833 16.1625 14.6708 15.9875 14.7625C15.8125 14.8542 15.6333 14.9667 15.45 15.1L14 14.65L13 16.35L14.15 17.35C14.1167 17.55 14.1 17.7667 14.1 18C14.1 18.2333 14.1167 18.45 14.15 18.65L13 19.65L14 21.35L15.45 20.9C15.6333 21.0333 15.8125 21.1458 15.9875 21.2375C16.1625 21.3292 16.35 21.4167 16.55 21.5ZM19.2625 19.4125C18.8708 19.8042 18.4 20 17.85 20C17.3 20 16.8292 19.8042 16.4375 19.4125C16.0458 19.0208 15.85 18.55 15.85 18C15.85 17.45 16.0458 16.9792 16.4375 16.5875C16.8292 16.1958 17.3 16 17.85 16C18.4 16 18.8708 16.1958 19.2625 16.5875C19.6542 16.9792 19.85 17.45 19.85 18C19.85 18.55 19.6542 19.0208 19.2625 19.4125Z"
    />
  </svg>,
  'OrgSettings'
);
