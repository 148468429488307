import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 64px - 55px - 5px)',
    overflowY: 'scroll',
    borderRight: '15px solid #F9F9F9',
    boxShadow: '0px 1px 1px #809cb7',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  appTile: {
    overflow: 'hidden',
  },
  title: {
    fontWeight: 700,
    padding: '15px 15px 0 15px',
  },
  groupTitle: {
    fontWeight: 700,
    padding: '15px',
  },
  helperText: {
    paddingLeft: '15px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  noApps: {
    textAlign: 'center',
    marginTop: '30px',
    '& svg': {
      width: '160px',
      height: '160px',
      marginBottom: '20px',
    },
    marginBottom: '20px',
  },
  appGroup: {
    borderTop: '1px solid #D5DFE9',
  },
  currentLanding: {
    position: 'relative',
    minHeight: '155px !important',
  },
  idleLanding: {
    minHeight: '10px',
  },
  runway: {
    minHeight: '110px',
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.8)',
    width: '94%',
    height: '100%',
    border: '1px dashed #CAD7E3',
    borderRadius: '4px',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  landingText: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
    color: 'rgba(124, 132, 141, 1)',
  },
  airborneGroup: {
    height: '50px !important',
    background: 'rgba(26, 140, 255, 0.9)',
    color: 'white',
    '& div': {
      display: 'none',
    },
  },
  groupHandler: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      background: 'rgba(242, 242, 242, 0.5)',
    },
  },
}));
