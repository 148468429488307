import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from './type';
import { Status } from '@aiware/shared/redux';
import { TUserAppGroup } from './type';
export const namespace = 'user-app-group';

const initialState: IInitialState = {
  appGroups: [],
  uiStatus: Status.idle,
  appGroupsRootFolderId: '',
};

const appGroupSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    fetchUserAppGroupsStart(state: IInitialState) {
      state.uiStatus = Status.loading;
    },
    fetchUserAppGroupSuccess(state: IInitialState, action: PayloadAction<TUserAppGroup[]>) {
      state.uiStatus = Status.success;
      state.appGroups = action.payload;
    },
    fetchUserAppGroupsFailed(state: IInitialState) {
      state.uiStatus = Status.failure;
    },
    addAppToGroup(state: IInitialState, action: PayloadAction<{ destination: string; appId: string }>) {
      const { destination, appId } = action.payload;
      const destinationIndex = (state.appGroups || []).findIndex(
        (group: TUserAppGroup) => group.id === destination
      );
      const appIsAlreadyInGroup = (((state.appGroups || [])[destinationIndex] || {}).apps || []).find(
        (id: string) => id === appId
      );
      if (destinationIndex !== -1 && !appIsAlreadyInGroup) {
        (state.appGroups || [])[destinationIndex]!.apps.push(appId);
      }
    },
    removeAppFromGroup(state: IInitialState, action: PayloadAction<{ source: string; appId: string }>) {
      const { source, appId } = action.payload;
      const sourceGroupIndex = (state.appGroups || []).findIndex(
        (group: TUserAppGroup) => group.id === source
      );
      if (sourceGroupIndex !== -1) {
        (state.appGroups || [])[sourceGroupIndex]!.apps = (state.appGroups || [])[
          sourceGroupIndex
        ]!.apps.filter((id: string) => id !== appId);
      }
    },
    updateGroupPriority(
      state: IInitialState,
      action: PayloadAction<{ groupId: string; newPriority: number; prevPriority: number }>
    ) {
      const oldGroups = [...(state.appGroups || [])];
      const { groupId, newPriority } = action.payload;
      const oldGroup = oldGroups.find((group: TUserAppGroup) => group.id === groupId);
      if (!oldGroup) return;
      if (newPriority > oldGroup.priority) {
        oldGroups.forEach((oG: TUserAppGroup) => {
          if (oG.priority <= newPriority && oG.priority > oldGroup.priority) {
            oG.priority -= 1;
          }
        });
        oldGroup.priority = newPriority;
      } else if (newPriority < oldGroup.priority) {
        oldGroups.forEach((oG: TUserAppGroup) => {
          if (oG.priority >= newPriority && oG.priority < oldGroup.priority) {
            oG.priority += 1;
          }
        });
        oldGroup.priority = newPriority;
      }
      oldGroups.sort((a: TUserAppGroup, b: TUserAppGroup) => a.priority - b.priority);
      state.appGroups = oldGroups;
    },
    createNewAppGroupPlaceholder(state: IInitialState) {
      // check if a new group placeholder is getting created
      if (state.appGroups.find((appGroup: TUserAppGroup) => appGroup.isNew)) {
        // do nothing
        return;
      }
      const newGroups = [...state.appGroups];
      newGroups.forEach(newGroup => (newGroup.priority += 1));
      const newGroupPlaceholder: TUserAppGroup = {
        groupName: '',
        id: 'new-group-placeholder',
        treeObjectId: '',
        isNew: true,
        apps: [],
        priority: 0,
        expanded: true,
        isEdited: true,
      };
      newGroups.unshift(newGroupPlaceholder);
      state.appGroups = newGroups;
    },
    removeNewAppGroupPlaceholder(state: IInitialState) {
      state.appGroups = (state.appGroups || []).filter((appGroup: TUserAppGroup) => {
        if (!appGroup.isNew) {
          appGroup.priority -= 1;
          return true;
        }
        return false;
      });
    },
    createNewAppGroupStart(state: IInitialState, action: PayloadAction<string>) {
      return state;
    },
    editAppGroup(
      state: IInitialState,
      action: PayloadAction<{ edit: boolean; groupId: string; newGroupName?: string }>
    ) {
      const { edit, groupId, newGroupName } = action.payload;
      // prevent from editing two groups at a time
      if (edit) {
        const editedGroup = (state.appGroups || []).find((appGroup: TUserAppGroup) => appGroup.isEdited);
        if (editedGroup) return;
      }
      // otherwise set the edit mode
      const groupIndex = (state.appGroups || []).findIndex(
        (appGroup: TUserAppGroup) => appGroup.id === groupId
      );
      if (groupIndex !== -1) {
        const newGroups = [...(state.appGroups || [])];
        newGroups[groupIndex]!.isEdited = edit;
        if (!edit && newGroupName) {
          newGroups[groupIndex]!.groupName = newGroupName;
        }
        state.appGroups = newGroups;
      }
    },
    deleteAppGroup(state: IInitialState, action: PayloadAction<string>) {
      return state;
    },
    expandCollapse(state: IInitialState, action: PayloadAction<string>) {
      const groupIndex = (state.appGroups || []).findIndex(
        (appGroup: TUserAppGroup) => appGroup.id === action.payload
      );
      if (groupIndex !== -1) {
        const isExpanded = (state.appGroups || [])[groupIndex]!.isExpanded;
        (state.appGroups || [])[groupIndex]!.isExpanded =
          isExpanded || isExpanded === undefined ? false : true;
      }
    },

    fetchUserAppGroupsRootFolderIdSuccess(state: IInitialState, action: PayloadAction<string>) {
      state.appGroupsRootFolderId = action.payload;
    },
  },
});
export const {
  addAppToGroup,
  removeAppFromGroup,
  updateGroupPriority,
  removeNewAppGroupPlaceholder,
  createNewAppGroupStart,
  editAppGroup,
  deleteAppGroup,
  expandCollapse,
  fetchUserAppGroupsStart,
  fetchUserAppGroupSuccess,
  fetchUserAppGroupsFailed,
  fetchUserAppGroupsRootFolderIdSuccess,
} = appGroupSlice.actions;

const appGroupReducer = appGroupSlice.reducer;
export default appGroupReducer;
