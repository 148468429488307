import { FC } from 'react';
import { useStyles } from './sourcePanel.styles';

interface ISourcePanel {
  headerContent?: () => JSX.Element;
  mainContent: () => JSX.Element;
  buttonContent?: () => JSX.Element;
}

export const SourcePanel: FC<ISourcePanel> = ({ headerContent, mainContent, buttonContent }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.panelContainer}>
      {headerContent && <header className={classes.panelHeaderContainer}>{headerContent()}</header>}

      <main className={classes.panelMainContentContainer}>{mainContent()}</main>

      {buttonContent && (
        <footer className={classes.panelFooterContainer}>
          <div className={classes.panelButtons}>{buttonContent()}</div>
        </footer>
      )}
    </div>
  );
};
