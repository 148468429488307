import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import { selectIsFluffy } from '../../../../redux/selectors/ui-state';
import { selectPageSchedulesBySourceId } from '../../../../redux/selectors/view-streams';
import { actions, DataCenterState } from '../../../../redux/slices';
import { ISchedule, SourceId } from '../../../../types';
import rowHeight from '../../../../helpers/rowHeight';
import keyHelper from '../../../../helpers/keyHelper';
import InfiniteWrapper from '../../../shared/InfiniteWrapper';
import SchedulesTableHeader from './SchedulesTableHeader';

const SchedulesTable: FunctionComponent<{
  parentSourceId: SourceId;
  items: ISchedule[];
}> = ({ parentSourceId, items }) => {
  const schedulesPage = useSelector(state =>
    selectPageSchedulesBySourceId(state as DataCenterState, parentSourceId)
  );
  const isFluffy = useSelector(selectIsFluffy);
  const hasNextPage = schedulesPage?.hasNextPage;

  return (
    <>
      <SchedulesTableHeader />
      <InfiniteWrapper
        id={keyHelper(isFluffy) + EntityType.Schedules}
        items={items}
        hasNextPage={hasNextPage}
        itemSize={rowHeight(isFluffy)}
        loadAction={actions.viewStreams.schedulesFetchStart(parentSourceId)}
        status={schedulesPage?.status}
        rowType={EntityType.Schedules}
        parentEntityId={parentSourceId}
      />
    </>
  );
};

export default SchedulesTable;
