import { FunctionComponent } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useStyles } from './useStyles';
import times from 'lodash/times';

interface Props {
  numColumns: number;
}

const EmptyTableRow: FunctionComponent<Props> = ({ numColumns }: Props) => {
  const { classes } = useStyles();
  return (
    <TableRow className={classes.tableRowEmpty}>
      {times(numColumns, i => (
        <TableCell key={i} />
      ))}
    </TableRow>
  );
};

export default EmptyTableRow;
