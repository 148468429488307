import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme: Theme) => ({
  settingsHeader: {
    fontWeight: 'normal',
    cursor: 'default',
  },
  topMargin: {
    marginTop: '30px',
  },
  fullWidth: {
    width: '100%',
  },
  inputLabel: {
    fontFamily: 'Nunito',
    fontWeight: 300,
    display: 'block',
    fontSize: 14,
    color: '#5C6269',
    marginBottom: 6,
  },
  disclaimerText: {
    fontWeight: 400,
    display: 'block',
    fontSize: 12,
    color: '#9CA8B4',
    marginTop: 12,
  },
}));
