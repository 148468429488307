import createSvgIcon from '../createSvgIcon';

export const MarketplaceWhite = createSvgIcon(
  <svg width="40" height="40" viewBox="7 7 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="1870E8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5914 10.8131C16.7941 10.4673 17.233 10.3423 17.5875 10.5294C17.965 10.7286 18.1 11.2026 17.8841 11.5709L14.7012 17H14.79L16.84 17.01L20.5 17.0001L24 17L25.21 17H25.6648L22.2965 11.6591C22.0696 11.2993 22.1873 10.823 22.5557 10.6103C22.9042 10.4091 23.3496 10.5191 23.5643 10.8596L27.4368 17H30C30.55 17 31 17.45 31 18L30.97 18.27L28.43 27.54C28.19 28.38 27.42 29 26.5 29H13.5C12.58 29 11.81 28.38 11.58 27.54L9.04 18.27C9.01 18.18 9 18.09 9 18C9 17.45 9.45 17 10 17H12.9641L16.5914 10.8131ZM15 20.75C15 20.3358 15.3358 20 15.75 20C16.1642 20 16.5 20.3358 16.5 20.75V25.25C16.5 25.6642 16.1642 26 15.75 26C15.3358 26 15 25.6642 15 25.25V20.75ZM23.75 20C23.3358 20 23 20.3358 23 20.75V25.25C23 25.6642 23.3358 26 23.75 26C24.1642 26 24.5 25.6642 24.5 25.25V20.75C24.5 20.3358 24.1642 20 23.75 20ZM19 20.75C19 20.3358 19.3358 20 19.75 20C20.1642 20 20.5 20.3358 20.5 20.75V25.25C20.5 25.6642 20.1642 26 19.75 26C19.3358 26 19 25.6642 19 25.25V20.75Z"
      fill="white"
      stroke="white"
    />
  </svg>,
  'MarketplaceWhite'
);
