import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    height: 'calc(100% - 30px)',
  },
  tabs: {
    margin: theme.spacing(6),
  },
  tabPanels: {
    width: '100%',
    height: 'calc(100% - 80px)',
  },
  // START Log tab
  logRoot: {
    padding: theme.spacing(0, 6),
  },
  logHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logTitleText: {
    paddingTop: '4px',
  },
  logContent: {
    marginTop: '12px',
  },
  // END Log tab

  // START Log tab
  overviewRoot: {
    padding: theme.spacing(0, 6),
    height: '100%',
  },
  overviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  overviewTitleText: {
    fontWeight: 600,
  },
  overviewContent: {
    width: '100%',
    height: 'calc(100% - 50px)',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  overviewLineChart: {
    padding: theme.spacing(6, 0),
    flex: 1,
  },
  overviewPieChart: {
    marginTop: 0,
  },
  // END Log tab
}));

export default useStyles;
