import createSvgIcon from '../createSvgIcon';
export const Palette = createSvgIcon(
  <svg width="30" height="30" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.125 21.5L3.75 22.25V11L0.749998 18.25C0.249998 19.625 0.874998 21 2.125 21.5ZM18 3L24.25 18L15.125 21.75L8.875 6.875V6.75L18 3ZM18.125 0.5C17.75 0.5 17.5 0.5 17.125 0.625L7.875 4.5C7 4.875 6.375 5.75 6.375 6.75C6.375 7 6.375 7.375 6.5 7.75L12.75 22.625C13.125 23.625 14 24.125 15 24.125C15.375 24.125 15.625 24.125 16 24L25.25 20.125C26.5 19.625 27.125 18.125 26.625 16.875L20.375 2C20 1 19 0.5 18.125 0.5ZM12.125 9.375C11.375 9.375 10.875 8.875 10.875 8.125C10.875 7.375 11.375 6.875 12.125 6.875C12.875 6.875 13.375 7.5 13.375 8.125C13.375 8.75 12.875 9.375 12.125 9.375ZM6.375 21.75C6.375 23.125 7.5 24.25 8.875 24.25H10.625L6.375 13.875V21.75Z"
      fill="#0C53B0"
    />
  </svg>,
  'Palette'
);
