import { useStyles } from '../../sourceScheduleFilter.styles';
import { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { sourceScheduleFilterText } from '../../../../../../helpers/aiSearch.text';
import { ScheduleItem } from './ScheduleItem';
import { LoadingStateRow, LoadingStateList } from '../../helpers';
import { TFilterSourceScheduleDataSet } from '../../../../../../types/aiSearch.types';
import { ISchedule } from '../../../../../../../../types';

export const ScheduleInfiniteList = ({
  sourceId,
  onSave,
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  selectedDataSet,
}: {
  sourceId: string | number;
  onSave: (schedules: ISchedule[]) => void;
  hasNextPage: any;
  isNextPageLoading: any;
  items: ISchedule[];
  loadNextPage: any;
  selectedDataSet: TFilterSourceScheduleDataSet;
}) => {
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  // Get the initial values
  const initialVal: ISchedule[] = selectedDataSet['SCHEDULES'][sourceId]
    ? [...selectedDataSet['SCHEDULES'][sourceId]!]
    : [];
  const [activeSchedules, setActiveSchedules] = useState<ISchedule[]>(initialVal);

  useEffect(() => {
    // TODO: Replace fake loader with real loading status
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleAddSchedule = (schedule: ISchedule, isActive: boolean) => {
    let newActives;
    if (isActive) {
      newActives = [...activeSchedules, schedule];
    } else {
      newActives = [...activeSchedules].filter(a => a.id !== schedule.id);
    }
    setActiveSchedules(newActives);
  };

  const handleSaveScheduleSelection = () => {
    onSave(activeSchedules);
  };

  // INFINITE LOADING
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading
    ? () => {
        // ts-ignore
      }
    : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  /** This function will check the active schedules and return if a schedule is active, this
   * is necessary because the Infinite List will render/destroy the elements while scrolling through the list. **/
  const checkIsActive = (activeId: string) => {
    for (const { id } of activeSchedules) {
      if (activeId === id) return true;
    }
    return false;
  };

  /** Infinite List Item Component - required to inject the styles to position the element. This
   * component wraps the Schedule and Loading Indicator and will conditionally render based on
   * the isItemLoaded() return value. **/
  const Item = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = <LoadingStateRow />;
    } else {
      const schedule = items[index]!;
      content = (
        <ScheduleItem
          isActive={checkIsActive(schedule.id)}
          schedule={schedule}
          key={index}
          onClick={(isActive: boolean) => handleAddSchedule(schedule, isActive)}
        />
      );
    }

    return <div style={style}>{content}</div>;
  };

  const getButtonText = () => {
    if (activeSchedules.length === 0) return sourceScheduleFilterText.selectNoSchedules;
    if (activeSchedules.length > 1) return sourceScheduleFilterText.selectXSchedules(activeSchedules.length);
    if (activeSchedules.length === 1) return sourceScheduleFilterText.selectOneSchedule;
  };

  return (
    <>
      {isLoading && <LoadingStateList text={sourceScheduleFilterText.loadingSchedules} />}
      {!isLoading && (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <List
              className={classes.scheduleScrollContainer}
              height={300}
              itemCount={itemCount}
              itemSize={55}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={350}
            >
              {Item}
            </List>
          )}
        </InfiniteLoader>
      )}
      {!isLoading && (
        <MenuItem
          onClick={handleSaveScheduleSelection}
          sx={{ justifyContent: 'center', marginTop: 'auto', marginLeft: '5px' }}
        >
          {getButtonText()}
        </MenuItem>
      )}
    </>
  );
};
