import { fetchTranslationJson } from '@aiware/os/helpers';
import { preferredLanguageSelector } from '@aiware/shared/redux';
import { useEffect, useState, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

export type AIWareIntlProviderProps = PropsWithChildren<{
  messages?: Record<string, string>;
  locale?: string;
  defaultLocale?: 'en-us';
  style?: { height?: string };
  fullHeight?: boolean;
}>;

export const AIWareIntlProvider = (props: AIWareIntlProviderProps) => {
  const lang: string = useSelector(preferredLanguageSelector) || 'en';
  const style = props.fullHeight ? { height: '100%' } : props.style;
  const [messages, setMessages] = useState<{
    [key: string]: Record<string, string>;
  }>({});

  const handleLangChange = async () => {
    try {
      if (messages[lang]) {
        return;
      }

      const translations = await fetchTranslationJson(lang);
      setMessages(prevState => ({
        ...prevState,
        [lang]: translations,
      }));
    } catch (e) {
      console.log(e);
      setMessages(prevState => ({
        ...prevState,
        [lang]: {},
      }));
    }
  };

  useEffect(() => {
    handleLangChange();
  }, [lang, messages]);

  return (
    <IntlProvider messages={messages[lang]} locale={lang} defaultLocale={'en'}>
      <div style={style}>{props.children}</div>
    </IntlProvider>
  );
};

export default AIWareIntlProvider;
