import { Divider, Typography, Switch, FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { ChangeEvent, ReactElement } from 'react';
import { formatLabel } from '../../helpers/utils';
import { EApplicationConfigType } from '@aiware/os/admin-center/shared';
import { TApplicationConfig, FieldValue } from '../../../types';
import { useStyles } from './component-loader.styles';
import { TxtField } from '../fields';
import { getFieldErrorMessage } from '../../helpers/utils';

export const ComponentLoader = ({
  fields,
  onChange,
  toggleSaveButton,
}: {
  fields: TApplicationConfig[];
  onChange: (field: FieldValue) => void;
  toggleSaveButton: (toggle: boolean) => void;
}) => {
  const { classes } = useStyles();

  return <>{renderComponents(fields)}</>;

  function renderComponents(fields: TApplicationConfig[]) {
    return fields?.map((field, idx) => {
      const label = formatLabel(`${field?.configKey}` || '');
      const fieldId = `configuration-input--${field?.applicationId}-idx-${idx}`;
      const hasErrors = !!getFieldErrorMessage(field);

      const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (field?.configType === EApplicationConfigType.Boolean) {
          onChange({
            fieldName: field?.configKey,
            fieldValue: String(event.target.checked),
          });
          toggleSaveButton(false);
        } else {
          onChange({
            fieldName: field?.configKey,
            fieldValue: event.target.value,
          });

          let valid = true;
          valid =
            field?.required && !event.target.value ? true : getFieldErrorMessage(field) === '' ? false : true;

          toggleSaveButton(valid);
        }
      };
      let inputComp: ReactElement;

      switch (field?.configType) {
        case EApplicationConfigType.String:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              type="text"
              value={field?.value || ''}
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EApplicationConfigType.Boolean:
          inputComp = (
            <Switch
              id={fieldId}
              required={field?.required}
              onChange={handleInputOnChange}
              checked={field?.value?.toLowerCase() === 'true' ? true : false}
            />
          );
          break;
        case EApplicationConfigType.Date:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              type="date"
              value={field?.value?.substring(0, 10) || ''}
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EApplicationConfigType.Integer:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              value={field?.value || ''}
              type="number"
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EApplicationConfigType.JSON:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              value={field?.value || ''}
              multiline={true}
              type="text"
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EApplicationConfigType.Float:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              value={field?.value || ''}
              type="number"
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        default:
          return null;
      }

      return (
        <>
          <div className={classes.outerContainer}>
            <FormControl
              error={hasErrors}
              size="small"
              className={classes.formControlWrapper}
              key={`${field?.applicationId}-idx-${idx}`}
            >
              <label
                htmlFor={fieldId}
                className={`${classes.inputLabel} ${hasErrors && classes.inputLabelError}`}
                data-test="os-admin-center-user-application-settings-config-label"
              >
                {label}
                {field?.required ? '(Required)' : ''}
              </label>
              <Typography
                variant="body2"
                className={classes.description}
                data-testid="os-admin-center-user-application-settings-config-description"
                data-test="os-admin-center-user-application-settings-config-description"
              >
                {field?.description}
              </Typography>
              {inputComp}
              {hasErrors && <FormHelperText>{getFieldErrorMessage(field)}</FormHelperText>}
            </FormControl>
          </div>
          <Divider sx={{ opacity: 0.9, mt: 4 }} />
        </>
      );
    });
  }
};
