import { combineReducers } from 'redux';
import { SLICE_NAME } from '../../types';
import * as adminCenterPermissionsState from './admin-center-permissions.state';
import * as permissionsWidgetState from './permissions-widget.state';

export const namespace = SLICE_NAME;

export const initialState = {
  [adminCenterPermissionsState.namespace]: adminCenterPermissionsState.initialState,
  [permissionsWidgetState.namespace]: permissionsWidgetState.initialState,
};

export const actions = {
  [adminCenterPermissionsState.namespace]: adminCenterPermissionsState.actions,
  [permissionsWidgetState.namespace]: permissionsWidgetState.actions,
};

export const rootReducer = combineReducers({
  [adminCenterPermissionsState.namespace]: adminCenterPermissionsState.default,
  [permissionsWidgetState.namespace]: permissionsWidgetState.default,
});
