import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'logo' | 'titleLogo'>()((theme: Theme, _props, classes) => ({
  root: {
    userSelect: 'none',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '200px',
    [`& ${classes.logo}`]: {
      width: '83px',
      height: '83px',
      marginBottom: theme.spacing(3),
    },
    [`& ${classes.titleLogo}`]: {
      color: theme.palette.secondary.main,
      opacity: 0.9,
      '& > a': {
        marginLeft: '7px',
      },
    },
  },
  titleLogo: {},
  sortName: {
    borderRadius: '50%',
    width: 83,
    height: 83,
    fontSize: 33,
    marginBottom: theme.spacing(3),
  },
  logo: {
    borderRadius: '50%',
  },
  orgImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    marginBottom: 0,
    borderRadius: '50%',
  },
  divider: {
    opacity: 0.9,
  },
  listBox: {
    margin: theme.spacing(0, 3),
  },
  loggedBox: {
    paddingTop: theme.spacing(2),
  },
  loggedText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    cursor: 'default',
  },
  loginAs: {
    color: theme.palette.primary.main,
    cursor: 'default',
  },
  tabsParent: {
    '& div': {
      padding: theme.spacing(0, 0, 0, 1.6),
      borderBottom: 'none',
    },
    '& div button': {
      flex: '0 1 auto !important',
    },
  },
  secondBox: {
    padding: theme.spacing(8, 6, 0, 6),
  },
}));

export default useStyles;
