import { DataCenterState } from '../../../../redux/slices';
import { AiSearchFilterType } from './aiSearchFilter.slice';

export const selectIsSearchFilterPanelVisible = (state: DataCenterState) => {
  const isVisible = state.dataCenter?.aiSearchFilter?.isVisible || false;

  return isVisible;
};

export const selectSearchFilters = (state: DataCenterState): AiSearchFilterType => {
  const filters = state.dataCenter?.aiSearchFilter?.filters || {};

  return filters;
};
export const selectDateRangeType = (state: DataCenterState): string => {
  return state.dataCenter?.aiSearchFilter?.dateRangeType;
};
export const selectSelectedFilterValues = (state: DataCenterState) => {
  const selectedValues = state.dataCenter?.aiSearchFilter?.selectedValues || [];

  return selectedValues;
};

export const selectSearchTerm = (state: DataCenterState) => {
  return state.dataCenter?.aiSearchFilter?.sourceSearchTerm || '';
};

export const selectOffset = (state: DataCenterState) => {
  return state.dataCenter?.aiSearchFilter?.offset;
};

export const selectError = (state: DataCenterState) => {
  return state.dataCenter?.aiSearchFilter?.error;
};

export const selectIsLoading = (state: DataCenterState) => {
  return state.dataCenter?.aiSearchFilter?.isLoading;
};

export const selectHasMore = (state: DataCenterState) => {
  return state.dataCenter?.aiSearchFilter?.hasMore;
};

export const selectSearchedSources = (state: DataCenterState) => {
  return state.dataCenter?.aiSearchFilter?.searchedSources || [];
};
