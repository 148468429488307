import { FunctionComponent, useState } from 'react';
import { useStyles } from '../useStyles';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@aiware/shared/icons';
import { EAuthResourceType, actions } from '@aiware/shared/permissions';
import ContextMenu from '../../../../../../components/ContextMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectPanelMode } from '../../../../../../../redux/selectors/ui-state';
import { EntityType } from '@aiware/js/interfaces';
import { ENTITY_CONTEXT_MENU } from '../../../../../../../types/entity-context-menus';
import { EResultsViewStyle } from '../../../AISearchResultsView';

export const AiSearchResultItemContextMenu: FunctionComponent<{
  viewStyle: EResultsViewStyle;
  entityId: string;
}> = ({ entityId, viewStyle }) => {
  const { classes } = useStyles();
  const className = viewStyle === EResultsViewStyle.COMPRESSED ? `` : classes.fluffyVertMenuContainer;
  const contextMenu = ENTITY_CONTEXT_MENU[EntityType.Tdos];
  const [contextMenuEl, setContextMenuEl] = useState<HTMLButtonElement | null>(null);
  const contextMenuId = contextMenuEl ? `contextMenuId-${entityId}` : undefined;
  const panelMode = useSelector(selectPanelMode);
  const dispatch = useDispatch();
  const fetchPermissions = () => {
    if (entityId) {
      dispatch(
        actions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.TDO,
          entityId,
        })
      );
    }
  };
  if (!panelMode) {
    return (
      <div className={className}>
        <IconButton
          className="context-menu-row-button"
          data-test={`context-menu-row-button-${entityId}`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setContextMenuEl(e.currentTarget);
          }}
          onMouseEnter={fetchPermissions}
          size="small"
        >
          <MoreVert fontSize="small" />
        </IconButton>

        <ContextMenu
          anchorEl={contextMenuEl}
          id={contextMenuId}
          open={!!contextMenuEl}
          onClose={() => setContextMenuEl(null)}
          contextMenu={contextMenu}
          entityId={entityId}
          type={EAuthResourceType.TDO}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default AiSearchResultItemContextMenu;
