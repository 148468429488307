import { Checkmark, NotInterested } from '@aiware/shared/icons';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useStyles } from './app-group-placeholder.styles';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { IApplication } from '../../../types';
import AppIcon from '../../atoms/AppIcon';
import { useDispatch, useSelector } from 'react-redux';
import { MessageSeverity, preferredLanguageSelector, showMessage } from '@aiware/shared/redux';
import { createNewAppGroupStart, removeNewAppGroupPlaceholder } from '../../../store/modules/app-groups';
import { Runway } from './index';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import isEqual from 'lodash/isEqual';

type TProps = {
  apps: IApplication[];
  classes: any;
};

export const AppGroupPlaceholder = ({ apps, classes: runwayClasses }: TProps) => {
  const locale: string = useSelector(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [newGroupName, setNewGroupName] = useState('');

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setNewGroupName(ev.target.value);
  };

  const handleCancel = () => {
    dispatch(removeNewAppGroupPlaceholder());
  };

  const missingNameError = isEqual(process.env.NODE_ENV, 'development')
    ? 'Please provide a name for your new App group'
    : formatMessage({
        id: 'os-app-bar-panel.new-app-group.missing-name',
        defaultMessage: 'Please provide a name for your new App group',
        description: 'Error message when user is trying to create a nameless app group',
      });

  const handleSave = () => {
    if (newGroupName) {
      dispatch(createNewAppGroupStart(newGroupName));
    } else {
      const message: any = {
        content: missingNameError,
        severity: MessageSeverity.Error,
      };
      dispatch(showMessage(message));
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        value={newGroupName}
        onChange={handleChange}
        data-testid="os-app-bar.new-app-group.name-input-container"
        className={classes.groupNameInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div
                className={classes.confirm}
                onClick={handleSave}
                data-testid="os-app-bar.new-app-group.save-btn"
              >
                <Checkmark />
              </div>
              <div
                className={classes.notInterested}
                onClick={handleCancel}
                data-testid="os-app-bar.new-app-group.cancel-btn"
              >
                <NotInterested />
              </div>
            </InputAdornment>
          ),
        }}
        inputProps={{ 'data-testid': 'os-app-bar.new-app-group.name-input' }}
      />
      <Droppable droppableId="new-group-placeholder">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.relativeContainer}
            style={{ width: '100%' }}
          >
            {apps.length ? (
              <Grid container sx={{ position: 'relative', marginBottom: '15px' }}>
                {apps.map((app: any, index: number) => {
                  const { id = index, iconUrl, name } = app;
                  return (
                    <Grid
                      item
                      xs={4}
                      key={id}
                      sx={{ padding: 0, overflow: 'hidden' }}
                      data-testid="app-switcher.new-app-group.app-tile"
                    >
                      <AppIcon
                        type="switcherGrid"
                        selected={false}
                        onClick={() => {
                          // do nothing until the new group is saved
                        }}
                        iconUrl={iconUrl}
                        id={id}
                        appName={name}
                        appListV2={true}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <div className={classes.newGroupApps}>
                <Typography variant="body2" className={classes.landingText}>
                  <FormattedMessage
                    id="os-app-bar-panel.new-app-group.apps-landing"
                    defaultMessage="Drag Applications Here"
                    description="helper text to tell users where to drag apps for the new app group"
                  />
                </Typography>
              </div>
            )}
            {snapshot.isDraggingOver && apps.length ? (
              <Runway classes={runwayClasses} rootClassName={classes.fullWidthRunway} />
            ) : null}
          </div>
        )}
      </Droppable>
    </div>
  );
};
