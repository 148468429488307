import { UserState } from './reducer';

export const namespace = 'users';

export type TState = Record<string, Record<typeof namespace, UserState>>;

export const userSelector = (state: TState) => state?.aiWareHeaderBarPanel?.[namespace];

export const currentUserSelector = (state: TState) => state?.aiWareHeaderBarPanel?.[namespace]?.currentUser;

export const avatarHistorySelector = (state: TState) =>
  state?.aiWareHeaderBarPanel?.[namespace]?.currentUser?.avatarHistory;

export const userAuthStatusSelector = (state: TState) => state?.aiWareHeaderBarPanel?.[namespace]?.authStatus;

export const hubRoleSelector = (state: TState) => state?.aiWareHeaderBarPanel?.[namespace]?.hubRole;
