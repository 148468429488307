import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  progress: {
    width: '117px',
    height: '10px',
    borderRadius: 8,
    backgroundColor: '#F2F7FE',
    display: 'inline-block',
  },
  listItem: {
    padding: theme.spacing(3, 1),
  },
  avatar: {
    backgroundColor: '#FAFAFA',
    color: theme.palette.secondary.main,
  },
  errorIcon: {
    backgroundColor: '#FEF2F2',

    '& svg:hover': {
      color: theme.palette.error.main,
    },
  },
  secondaryAction: {
    right: '0',
  },
  filename: {
    width: '330px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    userSelect: 'none',
  },
  caption: {
    userSelect: 'none',
  },
}));
export default useStyles;
