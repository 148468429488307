import { TGraphQLPage } from '@aiware/js/interfaces';
import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { IEngineCategory, IEngine } from '../types';
import { selectApiConfigs } from '@aiware/shared/redux';

export async function getEngines(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  edgeVersion: number,
  state?: string[]
) {
  const operationName = 'getEngines';
  const name = 'engines';
  const query = `
      query ${operationName} ($state: [EngineState], $edgeVersion: Int) {
        ${name}(
          offset: 0,
          limit: 1000,
          state: $state,
          edgeVersion: $edgeVersion
        ) {
          count
          records {
            id
            name
            categoryId
            edgeVersion
          }
        }
      }
    `;
  const variables: { edgeVersion: number; state?: string[] } = {
    edgeVersion,
  };
  if (state) variables['state'] = state;

  const result = await baseGraphQLApiWithError<{
    [name]: TGraphQLPage<IEngine>;
  }>({
    query,
    variables,
    operationName,
    ...apiConfigs,
  });
  return result[name];
}

export async function getEngineCategories(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'getEngineCategories';
  const name = 'engineCategories';
  const query = `
      query ${operationName} {
        ${name}(offset: 0, limit: 200) {
          count
          records {
            id
            name
            description
          }
        }
      }
    `;
  const result = await baseGraphQLApiWithError<{
    [name]: TGraphQLPage<IEngineCategory>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  result[name].records = result[name].records.map((category: IEngineCategory) => ({
    ...category,
    iconName: category.name.replace(/[^a-zA-Z]/g, ''),
  }));
  return result[name];
}
