import { EngineCategoryIcons } from '@aiware/shared/icons';

const IconEngineHelper = (value: string | undefined) => {
  if (value === undefined || !value) {
    return null;
  }
  return (EngineCategoryIcons as any)[value];
};

export default IconEngineHelper;
