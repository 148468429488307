import { LoadingStatus } from '@aiware/js/interfaces';
import { TDataRegistry, TSchema, TSDOsPagination } from '../../types';
import { namespace, UIState } from '../slices/sdos.state';
import { SLICE_NAME } from '../../constants';

import { selectors } from '../slices/schemaSdos.state';

export const sdoTableSelectors = selectors;

export const selectDataRegistriesPagination = (state: UIState): TSDOsPagination => {
  const sdoState = state?.[SLICE_NAME]?.[namespace];
  if (sdoState) {
    const { hasMore, offset, limit } = sdoState;
    return { hasMore, offset, limit };
  } else {
    return { hasMore: false, offset: 0, limit: 30 };
  }
};

export const selectDataRegistries = (state: UIState): TDataRegistry[] => {
  return state?.[SLICE_NAME]?.[namespace].SDOs;
};

export const selectStatus = (state: UIState): LoadingStatus => state?.[SLICE_NAME]?.[namespace].status;

export const selectViewerPanelDataRegistryStatus = (state: UIState): LoadingStatus =>
  state?.[SLICE_NAME]?.[namespace].sdoViewerPanel.dataRegistry.status;

export const _selectViewerPanelDataRegistry = (state: UIState): TDataRegistry =>
  state?.[SLICE_NAME]?.[namespace].sdoViewerPanel.dataRegistry.dataRegistryObj;

export const selectViewerPanelDataRegistrySchemas = (state: UIState): TSchema[] =>
  state?.[SLICE_NAME]?.[namespace].sdoViewerPanel.dataRegistry.dataRegistryObj?.schemas?.records || [];

export const selectViewerPanelActiveSchema = (state: UIState): string =>
  state?.[SLICE_NAME]?.[namespace].sdoViewerPanel.activeSchemaId;
