import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { userSelector } from '@aiware/shared/redux';
import { IUser } from '@aiware/js/interfaces';
import Header from './invitation-panel-header';
import UserLookup from './user-lookup';
import { makeStyles } from 'tss-react/mui';
import { actions } from '../../redux/slices';
export * from './user-card';
export * from './user-lookup';
export * from './user-status-badge';

const useStyles = makeStyles<void, 'root'>()(() => ({
  root: {
    userSelect: 'none',
    height: 'calc(100vh - 55px - 0px - 0px - 5px)',
  },
}));

interface IProps {
  organizationId?: number;
  organizationGuid?: string;
}

export const InviteUsersPanel: FC<IProps> = ({ organizationId, organizationGuid }) => {
  const user: IUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  useEffect(() => {
    if (organizationId && organizationGuid) {
      dispatch(
        actions.organizationInvitesState.setOrganization({
          id: organizationId.toString(),
          guid: organizationGuid,
        })
      );
    } else {
      dispatch(
        actions.organizationInvitesState.setOrganization({
          id: user.organization!.organizationId!.toString(),
          guid: user.organization!.organizationGuid!,
        })
      );
    }
  }, [organizationId, user, dispatch]);

  return (
    <AIWareThemeProvider>
      <div className={classes.root} data-test="org-icon">
        <Header height={64} />
        <UserLookup />
      </div>
    </AIWareThemeProvider>
  );
};
