import { useEffect, createElement } from 'react';
import { FormattedRelativeTime, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { INotificationDetail, IPanelActionButton } from '@aiware/js/interfaces';
import { AIWareIntlProvider } from '@aiware/shared/intl';

import { Notifications } from '@aiware/shared/icons';
import { notificationDetailSelector } from '../../store/selector';
import { convertHTMLEntities, getRelativeValue } from '../../store/utils';
import { useStyles } from './styles';
import { getNotificationAction, onNotificationActionClicked } from '../../store/actions';

export interface INotificationDetailProps {
  id: string;
  mode: string;
}

const MODE = {
  ACTION: 'actionable',
  INFO: 'informational',
};

function NotificationDetailPanel({ id, mode }: INotificationDetailProps) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const notification = useSelector(notificationDetailSelector(id)) as INotificationDetail;

  useEffect(() => {
    dispatch(getNotificationAction(id));
  }, [dispatch, id]);
  const handleOnClickButton = (btnAction: IPanelActionButton) => (event: unknown) => {
    if (btnAction?.url && btnAction?.url !== '') {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = btnAction.url;
      a.click();
    } else {
      btnAction?.onClick?.(event);
      dispatch(onNotificationActionClicked(btnAction));
    }
  };

  return (
    <AIWareIntlProvider>
      <div className={classes.notiDetailContainer} data-testid="notification-detail-panel">
        <div className={classes.root}>
          <div className={classes.actiondiv}>
            {notification?.icon ? (
              <img className={classes.iconImg} src={notification?.icon} draggable="false" alt="icon" />
            ) : (
              <Notifications />
            )}
            <div className={classes.appName}>
              <Typography variant="body2" className={classes.appNameTitle}>
                {notification?.appName || ''}
              </Typography>
            </div>
            <div>
              <Typography variant="caption">
                <FormattedRelativeTime
                  value={getRelativeValue(notification.createdDateTime)}
                  updateIntervalInSeconds={60}
                  unit="minute"
                  children={(formattedDate: string) => {
                    if (formattedDate.includes('seconds')) {
                      return (
                        <FormattedMessage
                          id="os-notification-panel.mvPoLJ"
                          defaultMessage="Just now"
                          description="the time label of notification that received on 1 minute"
                        />
                      );
                    }
                    return createElement('span', {}, formattedDate);
                  }}
                />
              </Typography>
            </div>
          </div>
          <div className={classes.header}>
            <div>
              <Typography
                variant="body1"
                className={classes.textTitle}
                data-test="notification-title"
                data-testid="notification-title"
              >
                {convertHTMLEntities(notification?.title)}
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <Typography variant="body2">{convertHTMLEntities(notification?.body)}</Typography>
          </div>
          {mode === MODE.ACTION && notification?.actions && notification?.actions?.length > 0 && (
            <div className={classes.actionableBtnContainer}>
              {notification?.actions?.map((btn: IPanelActionButton) => (
                <Button
                  key={btn.label}
                  startIcon={<img src={btn.iconUrl} alt="button icon" width="18px" height="18px" />}
                  className={classes.actionableBtn}
                  variant={btn.variant}
                  size={btn.size}
                  // color={actionButton.color} FIXME: mui5
                  onClick={handleOnClickButton(btn)}
                >
                  {btn.label}
                </Button>
              ))}
            </div>
          )}
          {/* <div className={classes.gradient} /> */}
        </div>
      </div>
    </AIWareIntlProvider>
  );
}

export { NotificationDetailPanel };
