import { EAuthResourceType, EAuthSubResourceType, EPermissionAction, TPermissions } from '../types';
import { baseGraphQLApi, baseGraphQLApiWithError, selectApiConfigs } from '@aiware/shared/redux';
import { getPermissionTypesToQuery } from '../lib/permissionsHelper';

export async function getObjectPermissions(
  graphEndpoint: string,
  token: string,
  payload: {
    entityType: EAuthResourceType;
    entityId: string;
    permissionTypeToFetch?: EAuthResourceType;
  }
): Promise<TPermissions> {
  const { entityType, entityId } = payload;
  const operationName = 'getPermissions';
  const permissionsToFetch = getPermissionTypesToQuery(entityType);
  let finished = false;
  let excludedActions: string[] = [];
  let result: {
    data?: {
      [key: string]: [{ hasPermission: boolean }];
    };
  } & {
    errors?: { message: string }[];
  } = {};
  // loop while finding unsupported permissions to exclude from the query
  while (!finished) {
    // eslint-disable-next-line no-loop-func
    const queryParts = permissionsToFetch.reduce((parts: string[], current) => {
      const { targetObjectType, targetActions } = current;
      targetActions.forEach(action => {
        const key = action.resource
          ? `${targetObjectType}__${action.resource}__${action.targetAction}`
          : `${targetObjectType}__${action.targetAction}`;
        if (!excludedActions.includes(action.serverName)) {
          parts.push(`
          ${key}: hasPermissions(resourceType: $entityType, ids: [$entityId], permissions: [${action.serverName}]) {
            hasPermission
        }
        `);
        }
      });
      return parts;
    }, []);
    const query = `
    query ${operationName}($entityType: AuthResourceType!, $entityId: ID!) {
        ${queryParts.join('\r\n')}
    }
  `;
    const variables = {
      entityId,
      entityType,
    };
    try {
      result = await baseGraphQLApi({
        query,
        operationName,
        variables,
        graphEndpoint,
        token,
      });
    } catch (err) {
      finished = true;
    }
    if (!result.errors) {
      finished = true;
    } else {
      excludedActions = result.errors
        .filter(({ message }) => message.includes(' does not exist in "AuthPermissionType" enum.'))
        .map(({ message }) => {
          // extract the unsupported permissions from the message of this format:
          // Value "{permission_name}" does not exist in "AuthPermissionType" enum.
          const prefix = 'Value "';
          const end = message.substring(prefix.length).indexOf('"');
          return message.substring(prefix.length, prefix.length + end);
        });
      if (excludedActions.length === 0) {
        finished = true;
        result = {};
        throw new Error((result.errors || [])[0]?.message || 'Error');
      }
    }
  }
  const data = result.data || {};
  const permissions = Object.keys(data).map(key => {
    const keyParts = key.split('__');
    if (keyParts.length === 3) {
      return {
        fetchedTargetEntityType: keyParts[0] as EAuthResourceType,
        fetchedTargetGroupType: keyParts[1] as EAuthSubResourceType,
        fetchedTargetAction: keyParts[2] as EPermissionAction,
        hasPermission: data[key]![0].hasPermission,
      };
    } else {
      return {
        fetchedTargetEntityType: keyParts[0] as EAuthResourceType,
        fetchedTargetAction: keyParts[1] as EPermissionAction,
        hasPermission: data[key]![0].hasPermission,
      };
    }
  });
  return permissions.reduce((acc, current) => {
    const key = current.fetchedTargetGroupType || current.fetchedTargetEntityType;
    if (!current.hasPermission) {
      return acc;
    }
    if (!(acc as any)[key]) {
      (acc as any)[key] = {};
    }
    (acc as any)[key][current.fetchedTargetAction] = current.hasPermission;
    return acc;
  }, {});
}

export async function getMyRights(apiConfigs: ReturnType<typeof selectApiConfigs>): Promise<string[]> {
  const operationName = 'getMyRights';
  const query = `
    query ${operationName} {
      myRights {
        operations
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    myRights: {
      operations: string[];
    };
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  return result.myRights.operations;
}
