import { getAdminCenterPermissionsModule } from '@aiware/os/admin-center/permissions';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareCacheProvider, AIWareThemeProvider } from '@aiware/shared/theme';
import { getDataCenterImporterModule } from '../../redux/';
import MainLayout, { DataCenterImporterConfigProps } from './MainLayout';

export const DataCenterImporter = (props: DataCenterImporterConfigProps) => {
  return (
    <AIWareCacheProvider>
      <AIWareThemeProvider>
        <DynamicModuleLoader modules={[getDataCenterImporterModule(), getAdminCenterPermissionsModule()]}>
          <AIWareIntlProvider>
            <MainLayout {...props} />
          </AIWareIntlProvider>
        </DynamicModuleLoader>
      </AIWareThemeProvider>
    </AIWareCacheProvider>
  );
};

export default DataCenterImporter;
