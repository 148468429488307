import createSvgIcon from '../createSvgIcon';
export const Information = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.63636C7.93317 4.63636 4.63636 7.93317 4.63636 12C4.63636 16.0668 7.93317 19.3636 12 19.3636C16.0668 19.3636 19.3636 16.0668 19.3636 12C19.3636 7.93317 16.0668 4.63636 12 4.63636ZM3 12C3 7.02943 7.02943 3 12 3C16.9705 3 21 7.02943 21 12C21 16.9705 16.9705 21 12 21C7.02943 21 3 16.9705 3 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8601 16.0717L13.7195 16.647C13.2976 16.8132 12.9609 16.94 12.7095 17.0273C12.4581 17.1147 12.1662 17.1584 11.8338 17.1584C11.3224 17.1584 10.9251 17.0337 10.6417 16.7844C10.3583 16.5351 10.2166 16.2187 10.2166 15.8352C10.2166 15.6861 10.2273 15.5337 10.2486 15.3782C10.2699 15.2226 10.304 15.0469 10.3509 14.8508L10.875 12.9844C10.9219 12.8054 10.9613 12.636 10.9933 12.4762C11.0252 12.3164 11.0412 12.1704 11.0412 12.0383C11.0412 11.7997 10.9922 11.6335 10.8942 11.5398C10.7962 11.446 10.6087 11.3991 10.3317 11.3991C10.1953 11.3991 10.0558 11.4204 9.91301 11.4631C9.77025 11.5057 9.64774 11.5462 9.54546 11.5845L9.68609 11.0092C10.0313 10.8686 10.3615 10.7482 10.6769 10.6481C10.9922 10.5479 11.2905 10.4979 11.5717 10.4979C12.0788 10.4979 12.4698 10.6204 12.7447 10.8654C13.0195 11.1104 13.157 11.429 13.157 11.821C13.157 11.902 13.1474 12.0447 13.1282 12.2493C13.109 12.4538 13.0739 12.6413 13.0227 12.8118L12.4986 14.6719C12.456 14.821 12.4176 14.9915 12.3835 15.1832C12.3494 15.375 12.3324 15.5199 12.3324 15.6179C12.3324 15.865 12.3878 16.0334 12.4986 16.1229C12.6094 16.2123 12.8011 16.2571 13.0739 16.2571C13.2017 16.2571 13.3466 16.2347 13.5085 16.19C13.6705 16.1452 13.7876 16.1058 13.8601 16.0717ZM13.9943 8.26705C13.9943 8.59091 13.8718 8.86683 13.6268 9.09481C13.3817 9.3228 13.0867 9.43679 12.7415 9.43679C12.3963 9.43679 12.1001 9.3228 11.853 9.09481C11.6058 8.86683 11.4822 8.59091 11.4822 8.26705C11.4822 7.94318 11.6058 7.6662 11.853 7.43608C12.1001 7.20597 12.3963 7.09091 12.7415 7.09091C13.0867 7.09091 13.3817 7.20597 13.6268 7.43608C13.8718 7.6662 13.9943 7.94318 13.9943 8.26705Z"
    />
  </svg>,
  'Information'
);
