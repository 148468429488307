import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { IUser } from '@aiware/js/interfaces';
import { GraphQLPage, IMemberType } from '../types';
import { selectApiConfigs } from '../redux/selectors';
import { TUserGroup } from '@aiware/os/admin-center/groups';

export async function fetchPermissionSetMembers(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  name: string,
  orgId?: string
): Promise<(TUserGroup & IUser & IMemberType)[]> {
  const operationName = 'fetchPermissionSetMembers';
  const op1 = 'users';
  const op2 = 'authGroups';
  const variables = {
    name,
    nameRegex: name,
  };

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';

  // TODO: remove after MVP release
  const isMVP = true;

  const userLookup = `${op1} (name: $name){
    records {
      id
      email: name
      firstName
      lastName
      imageUrl
      memberType: __typename
    }
  }
  `;

  const groupLookup = `${op2} (nameRegex: $nameRegex ${orgFilter}){
    records {
      id
      name
      isProtected
      memberCount
      members {
        count
        records {
          member {
            ... on User {
              id
              email: name
              firstName
              lastName
              imageUrl
            }
            ... on AuthGroup {
              id
              name
              description
              members {
                count
                records {
                  member {
                    ... on User {
                      id
                      email: name
                      firstName
                      lastName
                    }
                  }
                  createdAt
                }
              }
            }
          }
          createdAt
        }
      }
      memberType: __typename
    }
  }
  `;

  const params = isMVP ? `$nameRegex: String` : `$name: String, $nameRegex: String`;
  const query = `
    query ${operationName} (
      ${params}
    ){
      ${groupLookup}
      ${isMVP ? '' : userLookup}
    }
  `;

  const response = await baseGraphQLApiWithError<{
    // [op1]: GraphQLPage<IUser & IMemberType>; // TODO: enable post-MVP
    [op2]: GraphQLPage<TUserGroup & IMemberType>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  const result = [
    // ...response[op1].records, // TODO: enable post-MVP
    ...response[op2].records,
  ];

  return result;
}
