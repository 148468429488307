import { PanelHeader } from '../PanelHeader/PanelHeader';
import { hidePanel, mountPanel } from '@aiware/js/panel';
import { useDispatch, useSelector } from 'react-redux';
import { PullPushTabs } from '../PullPushTabs/PullPushTabs';
import { useEffect, useState } from 'react';
import { useStyles } from '../../helpers/useStyles';
import { EngineItemList } from '../EngineItemList/EngineItemList';
import { importSourcePanelSelectors } from '../../../../../redux/selectors/panel-select-import-source';
import { actions, IEngineAdapter } from '../../../../../redux/slices/panel-select-import-source';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

export const PanelContainer: React.FC = () => {
  const panelId = 'DATA_CENTER_SELECT_IMPORT_SOURCE';
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const fetchEnginesStatus = useSelector(importSourcePanelSelectors.selectFetchEnginesStatus);
  const isLoading = fetchEnginesStatus === 'pending';
  const isError = fetchEnginesStatus === 'failure';
  const engines = useSelector(importSourcePanelSelectors.selectEngines);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);

  const handleClosePanel = () => dispatch(hidePanel(panelId));

  const handleRetryFetchEngines = () => {
    dispatch(actions.fetchEngineAdapterStart());
  };
  const handleOpenNextPanel = (activeEngine: IEngineAdapter) => {
    // TODO: Add the panel id of the new Ingestion Wizard here:
    const panelId = 'PROCESSING_CENTER_INGESTION';

    const microFrontend = {
      name: 'PROCESSING_CENTER_INGESTION',
      config: {
        name: 'New Ingestion',
        activeEngine,
        isFullScreen: isFullScreen,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      size: 'large',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };

    panelId &&
      dispatch(
        mountPanel({
          panelId,
          microFrontend: microFrontend,
          panelConfig: panelConfig,
        })
      );
  };

  const handleClickEngine = (engine: IEngineAdapter) => {
    dispatch(actions.setActiveEngineId(engine.id));

    setTimeout(() => {
      handleClosePanel();
      handleOpenNextPanel(engine);
    }, 250);
  };

  const cleanup = () => {
    dispatch(actions.resetState());
  };

  useEffect(() => {
    // Fetch Engine Adapters on first mount
    if (!engines.pull.length && !engines.push.length && fetchEnginesStatus === 'idle') {
      dispatch(actions.fetchEngineAdapterStart());
    }

    return cleanup;
  }, []);
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PanelHeader height={64} onPanelClose={handleClosePanel} />
      <PullPushTabs activeTab={activeTab} onChange={setActiveTab} />
      <TabPanel index={0} value={activeTab}>
        <EngineItemList
          isLoading={isLoading}
          isError={isError}
          engines={engines.pull.filter(item => item.supportedSourceTypes)}
          onClickEngine={handleClickEngine}
          onRetry={handleRetryFetchEngines}
          activeTab={activeTab}
        />
      </TabPanel>
      <TabPanel index={1} value={activeTab}>
        <EngineItemList
          isLoading={isLoading}
          isError={isError}
          engines={engines.push.filter(item => item.supportedSourceTypes)}
          onClickEngine={handleClickEngine}
          onRetry={handleRetryFetchEngines}
          activeTab={activeTab}
        />
      </TabPanel>
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;
  const { classes } = useStyles();

  return (
    <div
      className={`${classes.tabPanelContainer} tab-panel`}
      role="tabpanel"
      hidden={value !== index}
      id={`pull-push-tabpanel-${index}`}
      aria-labelledby={`pull-push-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
