import createSvgIcon from '../createSvgIcon';

export const GeneratorWhite = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="47" height="47" viewBox="6 6 50 50">
    <path
      d="M22 16.8175V12.25C22 10.5925 19.315 9.25 16 9.25C12.685 9.25 10 10.5925 10 12.25V19.75C10 21.4075 12.6925 22.75 16 22.75C16.345 22.75 16.675 22.75 16.9975 22.705C16.84 22.2475 16.75 21.76 16.75 21.25V21.2125C16.51 21.25 16.2625 21.25 16 21.25C13.0975 21.25 11.5 20.125 11.5 19.75V18.0775C12.7075 18.6625 14.29 19 16 19C16.4875 19 16.9525 18.97 17.41 18.9175C18.1975 17.62 19.6225 16.75 21.25 16.75C21.505 16.75 21.7525 16.78 22 16.8175ZM20.5 16.3375C19.525 17.05 17.815 17.5 16 17.5C14.185 17.5 12.475 17.05 11.5 16.3375V14.23C12.6025 14.8525 14.2075 15.25 16 15.25C17.7925 15.25 19.3975 14.8525 20.5 14.23V16.3375ZM16 13.75C13.0975 13.75 11.5 12.625 11.5 12.25C11.5 11.875 13.0975 10.75 16 10.75C18.9025 10.75 20.5 11.875 20.5 12.25C20.5 12.625 18.9025 13.75 16 13.75Z"
      fill="white"
    />
    <path d="M21.25 20.125H22.75L20.5 23.5V21.25H19L21.25 18.25V20.125Z" fill="white" />
  </svg>,
  'GeneratorWhite'
);
