import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { selectSelectedProperty } from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { OperatorMenu } from './OperatorMenu';
import { ValueInputRender } from './ValueInputRender';

export enum EPropertyType {
  'string' = 'string',
  'number' = 'number',
  'integer' = 'integer',
  'dateTime' = 'dateTime',
  'datetime' = 'datetime',
  'date' = 'date',
  'boolean' = 'boolean',
}

export const MatchValueContainer = () => {
  const selectedProperty = useSelector(selectSelectedProperty);

  if (!selectedProperty) {
    return null;
  }
  return (
    <Box>
      <Stack direction={'row'} minHeight={'44px'} justifyContent={'space-between'} alignItems={'center'}>
        <OperatorMenu propertyType={selectedProperty.propertyDefinition.type} />
        <ValueInputRender propertyDefinition={selectedProperty.propertyDefinition} />
      </Stack>
    </Box>
  );
};
