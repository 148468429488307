export type TAssetRecord = { description?: string | null; signedUri?: string };

export type TPArtialTDO = {
  temporalDataObject?: {
    assets: {
      records: TAssetRecord[];
    };
  };
};

export type TTdoResponse = {
  data?: TPArtialTDO;
  errors?: { message?: string }[];
};

export type THelperResponse = {
  records?: TAssetRecord[];
  error?: boolean;
};

export type TWallpaper = { key: string; value: string; highRes?: string; thumb?: string };

export const SLICE_NAME = 'AiWareDesktop';
export type TState = {
  status: any; // LoadingStatus;
  wallpapers: TWallpaper[] | null;
  currentWallpaper: string | null;
};

export type TWallpaperAppConfig = {
  key: string;
  value: string;
};

export type TBaseGraphResponse<T> = {
  data?: T;
  errors?: {
    message?: string;
  };
};

export type WallpaperApplicationConfig = {
  key: string;
  value: string;
} | null;
