import createSvgIcon from '../createSvgIcon';

export const AutomateNode = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#F3F3F3" />
    <path
      d="M8.11111 8.08084C8.11111 7.52855 8.55883 7.08084 9.11111 7.08084H14.8889C15.4412 7.08084 15.8889 7.52855 15.8889 8.08084V10.5253C15.8889 11.0776 15.4412 11.5253 14.8889 11.5253H9.11111C8.55883 11.5253 8.11111 11.0776 8.11111 10.5253V8.08084ZM15.8889 12.6364C16.1836 12.6364 16.4662 12.5193 16.6746 12.311C16.8829 12.1026 17 11.82 17 11.5253V7.08084C17 6.46417 16.5 5.96973 15.8889 5.96973H8.11111C7.49444 5.96973 7 6.46417 7 7.08084V11.5253C7 11.82 7.11706 12.1026 7.32544 12.311C7.53381 12.5193 7.81643 12.6364 8.11111 12.6364H15.8889Z"
      fill="#555F7C"
    />
    <path
      d="M9.33328 16.0603C9.33328 15.508 9.78099 15.0603 10.3333 15.0603H13.3889C13.9412 15.0603 14.3889 15.508 14.3889 16.0603V16.2826C14.3889 16.8348 13.9412 17.2826 13.3889 17.2826H10.3333C9.781 17.2826 9.33328 16.8348 9.33328 16.2826V16.0603ZM14.3889 18.3937C14.6836 18.3937 14.9662 18.2766 15.1746 18.0682C15.3829 17.8599 15.5 17.5772 15.5 17.2826V15.0603C15.5 14.4437 15 13.9492 14.3889 13.9492H9.33328C8.71661 13.9492 8.22217 14.4437 8.22217 15.0603V17.2826C8.22217 17.5772 8.33923 17.8599 8.5476 18.0682C8.75598 18.2766 9.03859 18.3937 9.33328 18.3937H14.3889Z"
      fill="#555F7C"
    />
    <path d="M12.5 12H11.5V14H12.5V12Z" fill="#555F7C" />
  </svg>,
  'AutomateNode'
);
