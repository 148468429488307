import { forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';

interface Props {
  component: ReactNode;
  idPrefix?: string;
  step: number;
}

const TransitionWrapper = forwardRef(({ component, idPrefix, step }: Props, ref) => {
  return (
    <Box
      ref={ref}
      display={'inline-block'}
      width={'inherit'}
      minWidth={'100%'}
      height={'100%'}
      data-test={`${idPrefix}-progress-stepper-content-step-${step}`}
    >
      {component}
    </Box>
  );
});

export default TransitionWrapper;
