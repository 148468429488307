import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import { useStyles } from '../../components/useStyles';
import nameHelper from '../../../helpers/nameHelper';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectIsFluffy, selectTableDataSettings } from '../../../redux/selectors/ui-state';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const Name: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const { classes } = useStyles();
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showName = columns.indexOf('name') >= 0;
  const isFluffy = useSelector(selectIsFluffy);
  let name = item.name ? item.name : nameHelper(item.id, item.modifiedDateTime);

  if (itemType === EntityType.Tdos) {
    name = (item as ITdoVm).name
      ? (item as ITdoVm).name
      : nameHelper((item as ITdoVm).id, (item as ITdoVm).mediaStartTime);
  }

  return (
    <>
      {showName && !isFluffy && (
        <Grid data-test={'item-name'} xs item className={classes.gridName}>
          <Typography
            data-test={'name'}
            noWrap
            variant="body2"
            color="textPrimary"
            className={classNames(classes.name, classes.padding)}
          >
            {name}
          </Typography>
        </Grid>
      )}

      {showName && isFluffy && (
        <Grid data-test={'item-name-fluffy'} className={classes.fluffyNameContainer} item>
          <Typography data-test={'name'} noWrap className={classes.name} variant="body1" color="textPrimary">
            {name}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Name;
