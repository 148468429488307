import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { GraphQLPage, IAiPackage } from '../types';
import { selectApiConfigs } from '../redux/selectors';

export async function getAiPackages(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'getAiPackages';
  const name = 'dagTemplates';
  const query = `
    query ${operationName} {
      ${name}(offset: 0, limit: 100) {
        count
        records {
          id
          name
          mimeType
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<IAiPackage>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  return result[name];
}

export async function getEngineAdapters(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const ADAPTER_FRAGMENT = `
      fragment adapterFields on Engine {
        id
        categoryId
        name
        logoPath
        signedLogoPath
        signedIconPath
        description
        dependency {
          assetType
        }
        displayName
        deploymentModel
        fields {
          name
          options {
            key
            value
          }
          type
          max
          min
          step
          info
          defaultValue
        }
        createdBy
        ownerOrganizationId
        iconPath
        createsTDO
        price
        deployedVersion
        preferredInputFormat
        runtimeType
        supportedInputFormats
        supportedSourceTypes
        supportedScheduleTypes
        outputFormats
        hasScanPhase
        edgeVersion
        oauth
      }
    `;
  const operationName = 'getEngineAdapters';
  const name = 'engines';
  const query = `
        ${ADAPTER_FRAGMENT}
        query ${operationName} {
          pull:${name}(filter: {type: Ingestion, category:"Pull"}, owned: false, state: active) {
            records {
              ...adapterFields
            }
          }

          push:${name}(filter: {type: Ingestion, category:"Push"}, owned: false, state: active) {
            records {
              ...adapterFields
            }
          }

        }
      `;
  const result = await baseGraphQLApiWithError<{
    ['pull']: GraphQLPage<IAiPackage>;
    ['push']: GraphQLPage<IAiPackage>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  return result;
}
