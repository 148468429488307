import { IOrganization } from '@aiware/js/interfaces';

export interface IHeaderBarState {
  aiWareHeaderBar: {
    currentUser: {
      organization: IOrganization;
    };
    appIcon: string;
  };
}

export const headerBarSelector = (state: IHeaderBarState) =>
  state?.aiWareHeaderBar;
