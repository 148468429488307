import { AiwareFrameLink, EMessageTypes } from '.';
import { Utilities } from './constants';

export function handleMessage(this: AiwareFrameLink, event: MessageEvent) {
  // Only process messages from the correct origin
  // child apps don't have means to validate the message sender
  if (!this.isChild && event.origin !== this.origin) {
    return;
  }

  if (event.data && event.data?.type?.includes('aiware:')) {
    if (!this.isChild) {
      this.heartbeatReceived = new Date().getTime();
    }
    const messageType = event.data.type.split(':')[1];
    switch (messageType) {
      // INITIAL CONTEXT REQUEST
      case EMessageTypes.getInitContext: {
        const { auth, configs } = this.store?.getState() as {
          auth: Record<string, unknown> & {
            user: Record<string, unknown> & { organization: Record<string, string> };
          };
          configs: { initConfig: Record<string, string> };
        };
        const payload = {
          authToken: auth?.['sessionToken'],
          baseUrl: configs?.initConfig?.['baseUrl'],
          userId: auth?.['userId'],
          organizationId: auth?.user?.organization?.['organizationId'],
          language: auth?.user?.['preferredLanguage'],
        };
        this.postMessage(EMessageTypes.initContext, payload);
        this.initialized = true;
        break;
      }

      // INITIAL CONTEXT
      case EMessageTypes.initContext:
        if (!this.initialized) {
          Object.assign(this.context, event.data.payload);
          // If a 'context' message is received, update the context and resolve the promise
          this.contextReceivedResolve && this.contextReceivedResolve();
          this.subscribers.forEach(cb => {
            cb(this.context);
          });
        }
        break;

      // RESPONSE
      case EMessageTypes.response: {
        // New: If a 'response' message is received, store the data in the responses map
        const { requestId, data, error } = event.data.payload;
        this.responses[requestId] = { data, error };
        break;
      }

      // ACTIVITY
      case EMessageTypes.reportActivity:
        // TODO: future implementation when activity details are flushed out
        break;

      // UTILITY
      case EMessageTypes.utility: {
        // utility message payload should have the following shape
        const { utility, requestId } = event.data.payload as {
          utility: keyof typeof Utilities;
          requestId: string;
        }; // data is also available for destructuring
        Utilities[utility](requestId, this);
        break;
      }

      // ERROR
      case EMessageTypes.error: {
        const { requestId, error } = event.data.payload;
        // make sure child no longer waits for the response
        requestId && (this.responses[requestId] = { error, data: null });
        // handle error with the message passed in
        this.errorAction && this.intl && this.errorAction(this.intl.formatMessage(error));
        break;
      }

      default:
      // TODO: unsupported event
    }
  }
}
