import { FC, useState, useCallback } from 'react';
import { Filter, FilterRemove } from '@aiware/shared/icons';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { AiSearchText } from '../../../Search/helpers/aiSearch.text';

interface IFilterIcon {
  id: string;
  onFilterClick: (isActive: boolean) => void;
}

export const FilterIcon: FC<IFilterIcon> = ({ id, onFilterClick }) => {
  const [isActive, setIsActive] = useState(false);

  const handleOnClick = useCallback(() => {
    setIsActive(!isActive);

    if (onFilterClick) {
      onFilterClick(!isActive);
    }
  }, [isActive, onFilterClick]);

  return (
    <>
      <Typography style={visuallyHidden} id="dc-ai-search-results-filter-icon-tooltip">
        {isActive ? AiSearchText.hideFilterPanelText() : AiSearchText.showFilterPanelText()}
      </Typography>
      <Tooltip title={isActive ? AiSearchText.hideFilterPanelText() : AiSearchText.showFilterPanelText()}>
        <IconButton id={id} onClick={handleOnClick} aria-labelledby="filter-icon-btn" size="large">
          {isActive ? (
            <FilterRemove data-test-id={`filter-remove-icon-${id}`} />
          ) : (
            <Filter data-test-id={`filter-icon-${id}`} />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};
