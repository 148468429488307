import createSvgIcon from '../createSvgIcon';

export const DAG = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.00002 0.900391C8.25444 0.900391 7.65002 1.50481 7.65002 2.25039V2.77539H4.50002C2.92601 2.77539 1.65002 4.05138 1.65002 5.62539V6.75039C1.65002 8.3244 2.92601 9.60039 4.50002 9.60039H6.83091C7.09348 10.5517 7.96517 11.2504 9.00002 11.2504C10.0349 11.2504 10.9066 10.5517 11.1691 9.60039H13.5C14.4113 9.60039 15.15 10.3391 15.15 11.2504V12.0004C15.15 12.9117 14.4113 13.6504 13.5 13.6504H10.35V13.5004C10.35 12.7548 9.74561 12.1504 9.00002 12.1504H3.00002C2.25444 12.1504 1.65002 12.7548 1.65002 13.5004V15.7504C1.65002 16.496 2.25444 17.1004 3.00002 17.1004H9.00002C9.74561 17.1004 10.35 16.496 10.35 15.7504V14.8504H13.5C15.074 14.8504 16.35 13.5744 16.35 12.0004V11.2504C16.35 9.67638 15.074 8.40039 13.5 8.40039H11.1691C10.9066 7.44903 10.0349 6.75039 9.00002 6.75039C7.96517 6.75039 7.09348 7.44903 6.83091 8.40039H4.50002C3.58875 8.40039 2.85002 7.66166 2.85002 6.75039V5.62539C2.85002 4.71412 3.58875 3.97539 4.50002 3.97539H7.65002V4.50039C7.65002 5.24598 8.25444 5.85039 9.00002 5.85039H15C15.7456 5.85039 16.35 5.24597 16.35 4.50039V2.25039C16.35 1.50481 15.7456 0.900391 15 0.900391H9.00002ZM8.85002 2.25039C8.85002 2.16755 8.91718 2.10039 9.00002 2.10039H15C15.0829 2.10039 15.15 2.16755 15.15 2.25039V4.50039C15.15 4.58323 15.0829 4.65039 15 4.65039H9.00002C8.91718 4.65039 8.85002 4.58323 8.85002 4.50039V2.25039ZM2.85002 13.5004C2.85002 13.4175 2.91718 13.3504 3.00002 13.3504H9.00002C9.08287 13.3504 9.15002 13.4175 9.15002 13.5004V15.7504C9.15002 15.8332 9.08287 15.9004 9.00002 15.9004H3.00002C2.91718 15.9004 2.85002 15.8332 2.85002 15.7504V13.5004Z"
      fill="#212121"
    />
  </svg>,
  'DAG'
);
