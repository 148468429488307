import { useState } from 'react';
import { Filter } from '@aiware/ui';
import { Box, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import useStyles from '../useStyles';
import MuiTable, { IColumnType } from '../../../../molecules/MuiTable';
export const data = [1, 2, 3, 4, 5, 6, 7, 8].map(item => [
  {
    name: `LogoGrab - V3F - ${item}`,
    engineId: `0006676754${item}`,
    jobId: '20104106_6WnM4rGlXj',
    status: 'Pending',
    duration: '1s',
    appName: 'Attribute',
  },
]);

export const columns: IColumnType[] = [
  {
    value: 'name',
    label: 'Engine Name',
    align: 'left',
  },
  {
    value: 'engineId',
    label: 'Engine ID',
    align: 'left',
  },
  {
    value: 'jobId',
    label: 'Job ID',
    align: 'left',
  },
  {
    value: 'status',
    label: 'Status',
    align: 'left',
  },
  {
    value: 'duration',
    label: 'Time',
    align: 'left',
  },
  {
    value: 'appName',
    label: 'App Name',
    align: 'left',
  },
];

const filters = [
  {
    value: 'all',
    title: 'View All Applications',
  },
  {
    value: 'no data',
    title: 'Active Applications',
  },
];

function PerformanceLogs() {
  const { classes } = useStyles();
  const [filter, setFilter] = useState('all');
  const onFilter = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as string);
  };

  return (
    <Box className={classes.logRoot} data-testid="performance-monitor-logs">
      <Box className={classes.logHeader}>
        <Filter data={filters} filter={filter} onFilter={onFilter} />
        <Typography variant="body2" className={classes.logTitleText}>
          Last 24 Hours
        </Typography>
      </Box>
      <Box className={classes.logContent}>
        <MuiTable columns={columns} data={data} />
      </Box>
    </Box>
  );
}

export default PerformanceLogs;
