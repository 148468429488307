import { guid } from '@aiware/js/function';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectSelectedFiles, selectUploadedFilesTdoIds } from '@aiware/os/data-center/importer';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { selectors } from '@aiware/os/data-center/browse';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TWallpaper } from '../../types';
import { actions as aiWareActions } from '../../redux/slices/aiware-desktop.state';
import { selectLoadingStatus, selectUserWallpapers } from '../../redux/selectors';

type TProps = {
  id: number;
  currentWallpaper: any;
  setCurrentWallpaper: (image: any) => void;
  uploadNumber: number | null;
  setUploadNumber: Dispatch<SetStateAction<number | null>>;
  wallpaper: TWallpaper;
};

export const WallpaperUploader = ({
  id,
  currentWallpaper,
  setCurrentWallpaper,
  uploadNumber,
  setUploadNumber,
  wallpaper,
}: TProps) => {
  const [fileSelected, setFileSelected] = useState<File>();
  const [wallpaperId, setWallpaperId] = useState<string>();
  const [disableImportButton, setDisableImportButton] = useState<boolean>(false);
  const dispatch = useDispatch();
  const filesSelected = useSelector(selectSelectedFiles);
  const uploadedTDO = useSelector(selectUploadedFilesTdoIds);
  const rootFolderSet = useSelector(selectors.viewMyFiles.selectRootFolderId);
  const status = useSelector(selectLoadingStatus);
  const userWallpapers = useSelector(selectUserWallpapers);

  const inputFileRef = useRef(null);

  useEffect(() => {
    // wallpaper has a TDO
    if (
      wallpaper &&
      wallpaper.value !== 'none' &&
      !wallpaper.thumb &&
      !wallpaper.highRes &&
      /^\d+$/.test(wallpaper.value)
    ) {
      dispatch(aiWareActions.getTDOAssetsStart(wallpaper.value));
    }
  }, [wallpaper]);

  useEffect(() => {
    setDisableImportButton(filesSelected.some(file => file.status === 'pending'));
    if (fileSelected && filesSelected.length && !filesSelected.some(file => file.status === 'pending')) {
      onImport();
    }
  }, [filesSelected]);

  useEffect(() => {
    if (!rootFolderSet) {
      dispatch(actions.rootFolderState.startRootFolderFetch());
    }
  }, [rootFolderSet]);

  useEffect(() => {
    if (uploadedTDO && uploadedTDO[0] && uploadNumber === id) {
      dispatch(
        aiWareActions.setCustomWallpaperStart({
          wallpaperId: id,
          tdoID: uploadedTDO[0],
          create: !wallpaper,
        })
      );
      clearSelectionInState();
    }
  }, [uploadedTDO]);

  const handleUpload = (files: File[]) => {
    setFileSelected(files[0]);
    const id = guid();
    setWallpaperId(id);
    const uploadedFile = {
      id,
      fileUrl: URL.createObjectURL(files[0]!),
      name: files[0]?.name ?? '',
      size: files[0]?.size ?? 0,
      type: files[0]?.type ?? '',
      percentComplete: 0,
    };

    dispatch(
      actions.filesSelectedState.setFilesSelected({
        filesSelected: [uploadedFile],
        sdkFiles: [files[0]!],
      })
    );
  };

  const deleteFile = () => {
    dispatch(
      actions.filesSelectedState.deleteFileSelected({
        id: wallpaperId!,
      })
    );
    setWallpaperId('');
  };

  const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    setDisableImportButton(true);
    const files = Array.from(event.target.files!);
    setUploadNumber(id);
    handleUpload(files);
    setDisableImportButton(false);
  };

  const clearSelection = (ev: MouseEvent) => {
    ev.stopPropagation();
    setFileSelected(undefined);
    deleteFile();
    setUploadNumber(null);
    dispatch(
      aiWareActions.removeCustomWallpaperStart({
        id,
        isCurrent: !!hasMain() && wallpaper?.highRes === currentWallpaper,
      })
    );
  };

  const clearSelectionInState = () => {
    deleteFile();
    setUploadNumber(null);
  };

  const onImport = () => {
    dispatch(
      actions.uiState.tdoCreateStart({
        filesSelected,
        useDefaultRootFolder: true,
      })
    );
  };

  const openImporter = (ev: MouseEvent) => {
    ev.stopPropagation();
    if (inputFileRef && inputFileRef.current) {
      (inputFileRef.current as HTMLInputElement).click();
    }
  };

  const hasThumb = () => wallpaper?.thumb;
  const hasMain = () => wallpaper?.highRes;
  const isCurrent = () =>
    (userWallpapers || []).find(wp => wp.key === 'wallpaperCurrent')?.value === wallpaper?.value;

  const backgroundImageGetter = () => {
    if (fileSelected && !disableImportButton) return `url(${URL.createObjectURL(fileSelected)})`;
    if (hasThumb()) return `url(${wallpaper?.thumb})`;
    if (hasMain()) return `url(${wallpaper?.highRes})`;
    return undefined;
  };
  const emptyPreview = (key: number) => (
    <Box
      data-testid={`aiware-desktop.settings.empty-preview-${key}`}
      key={key}
      sx={{
        border: !isCurrent() ? '1px solid #DADFE8' : '2px solid #43A047',
        backgroundImage: backgroundImageGetter(),
        backgroundSize:
          (fileSelected && !disableImportButton) || hasThumb() || hasMain() ? 'cover' : undefined,
        borderRadius: '4px',
        mb: '10px',
        width: '140px',
        height: '80px',
        background: (fileSelected && !disableImportButton) || hasThumb() || hasMain() ? undefined : '#F0F0F0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        cursor: (hasMain() || hasThumb()) && !isCurrent() ? 'pointer' : undefined,
      }}
      onClick={
        (hasMain() || hasThumb()) && !isCurrent()
          ? () => {
              setCurrentWallpaper(wallpaper?.highRes);
              dispatch(aiWareActions.setCurrentWallpaperStart(wallpaper?.value));
            }
          : undefined
      }
    >
      {status !== 'pending' && !fileSelected && !hasThumb() && !hasMain() && (
        <IconButton
          data-testid={`aiware-desktop.settings.importer-btn`}
          onClick={ev => openImporter(ev as unknown as MouseEvent)}
          disabled={!!uploadNumber || disableImportButton}
        >
          <AddIcon fontSize="medium" />
        </IconButton>
      )}
      {((fileSelected && disableImportButton) || status == 'pending') && <CircularProgress />}
      {((fileSelected && !disableImportButton) || hasThumb() || hasMain()) && (
        <IconButton
          data-testid={`aiware-desktop.settings.remove-btn`}
          onClick={ev => clearSelection(ev as unknown as MouseEvent)}
          sx={{ position: 'absolute', right: 0, bottom: 0 }}
        >
          <DeleteIcon fontSize="medium" sx={{ fill: 'white' }} />
        </IconButton>
      )}
    </Box>
  );

  return (
    <>
      <input
        data-testid={`aiware-desktop.settings.hidden-input`}
        ref={inputFileRef}
        style={{ display: 'none' }}
        disabled={!!uploadNumber}
        type="file"
        onChange={onSelectFile}
        accept="image/png, image/jpeg, image/jpg"
      />
      {emptyPreview(id)}
    </>
  );
};
