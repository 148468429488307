import { IEngine, IEngineCategory } from '../types';

export const engineListItems = (
  categoryList: string[],
  engineCategories: Record<string, IEngineCategory>,
  engineList: string[],
  engines: Record<string, IEngine>
) => {
  return categoryList.map((category, _index) => {
    return {
      categoryName: engineCategories[category]!.name,
      id: engineCategories[category]!.id,
      engines: engineList
        .filter((engine, _index) => {
          if (engines[engine]!.categoryId === category) {
            return engine;
          }
        })
        .map((engineId, _index) => {
          return {
            engineId: engines[engineId]!.id,
            engineName: engines[engineId]!.name,
          };
        }),
    };
  });
};
