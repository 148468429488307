import { FC } from 'react';
import { injectIntl, WithIntlProps, WrappedComponentProps } from 'react-intl';
import { formattedMessages } from '../../helpers/formatted-messages';
import { useStyles } from './controls.styles';
import { Button } from '@mui/material';

type TControlsPropTypes = {
  newMembers: unknown[];
  onGrantAccess: () => void;
  onBack: () => void;
  onDone: () => void;
  isFactoryMode: boolean;
  isPrefilled: boolean;
} & WrappedComponentProps;

const Controls: FC<TControlsPropTypes> = ({
  intl,
  newMembers,
  onGrantAccess,
  onBack,
  onDone,
  isFactoryMode = false,
  isPrefilled = false,
}) => {
  const { classes } = useStyles();
  const {
    panelControlsBackBtn: Back,
    panelControlsSendBtn: Send,
    panelControlsDoneBtn: Done,
    panelControlsSaveBtn: Save,
    panelControlsUpdateBtn: Update,
  } = formattedMessages(intl);

  const getBtnText = () => {
    if (isFactoryMode) {
      if (isPrefilled) return Update;
      return Save;
    } else {
      return Send;
    }
  };

  return (
    <div className={classes.controlsContainer}>
      {newMembers.length ? (
        <>
          {!isPrefilled && (
            <Button
              variant="text"
              className={classes.backBtn}
              onClick={onBack}
              data-testid="permissions-panel.controls.back-btn"
              data-test="permissions-panel.controls.back-btn"
            >
              {Back}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={onGrantAccess}
            data-testid="permissions-panel.controls.send-btn"
            data-test="permissions-panel.controls.send-btn"
          >
            {getBtnText()}
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          onClick={onDone}
          data-testid="permissions-panel.controls.done-btn"
          data-test="permissions-panel.controls.done-btn"
        >
          {Done}
        </Button>
      )}
    </div>
  );
};

export default injectIntl(Controls) as FC<WithIntlProps<TControlsPropTypes>>;
