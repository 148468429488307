import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  tableCell: {
    padding: 0,
    '& > span': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  itemCell: {
    padding: 0,
    '& > span': {
      padding: theme.spacing(0, 2),
    },
  },
  eventNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRowCheckbox: {
    marginRight: theme.spacing(2),
  },
  rowCheckbox: {
    paddingLeft: 0,
  },
  customLineHeight: {
    lineHeight: theme.spacing(8),
  },
  disabledCheckBox: {
    '& svg': {
      color: 'green',
    },
  },
}));
