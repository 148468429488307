import { makeStyles } from 'tss-react/mui';

// Shared classes for the html elements
export const sharedStyles = makeStyles()(_theme => ({
  formControl: {
    marginRight: '15px',
  },
  label: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: 14,
    color: '#2A323C',
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  field: {
    width: 150,
  },
  clearBtn: { color: '#0C53B0', cursor: 'pointer', fontWeight: 400 },
}));

// Overrides for the Select Component's sx prop
export const muiSelectStyleOverrides = {
  height: '28px',
  fontSize: '0.8em',
  '& fieldset': {
    borderColor: '#D5DFE9',
    borderWidth: '2px',
    borderRadius: '6px',
  },
  '&:hover': {
    '&& fieldset': {
      borderColor: 'rgba(42,50,60,0.5)',
    },
  },
};
