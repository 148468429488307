// @ts-ignore
import { Fullscreen, FullscreenExit, Hamburger, Hide } from '@aiware/shared/icons';
import { FunctionComponent } from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { FormattedMessage } from 'react-intl';
import { AiAutocomplete } from '../Search/components/AIAutocomplete';
import { AddNewMenu } from './AddNewMenu';
import { HelpOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectIsFullscreenEnabled, setIsFullscreenEnabled } from '@aiware/shared/redux';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles<{ height: string | number }>()((theme, { height }) => ({
  header: {
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    display: 'flex',
    height,
    padding: theme.spacing(2),
    position: 'relative',
  },
  title: {
    minWidth: '126px',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  navigationToggle: {
    marginRight: theme.spacing(1),
  },
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
  },
  verticalDivider: {
    borderRight: `.5px solid ${theme.palette.divider}`,
    height: '70%',
    margin: `0 ${theme.spacing(1)}`,
  },
  searchContainer: {
    alignItems: 'center',
    border: '1px solid transparent',
    borderRadius: 4,
    display: 'flex',
    flexGrow: 1,
    height: 44,
  },
}));

const Header: FunctionComponent<{
  height: number;
  isNavExpanded: boolean;
  onLeftNavigationToggle: () => void;
  onPanelClose: () => void;
}> = ({ height, isNavExpanded, onLeftNavigationToggle, onPanelClose }) => {
  const { classes } = useStyles({ height });
  const dispatch = useDispatch();
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const onFullScreenToggle = () => {
    dispatch(setIsFullscreenEnabled(!isFullScreen));
  };

  return (
    <header className={classes.header} data-test="dc-header">
      <Typography style={visuallyHidden} id="dc-header-toggle-nav">
        {SHARED_TEXT.toggleNavigationMenu()}
      </Typography>
      <Tooltip title={SHARED_TEXT.toggleNavigationMenu()}>
        <IconButton
          className={classes.navigationToggle}
          onClick={onLeftNavigationToggle}
          aria-labelledby="dc-header-toggle-nav"
          aria-expanded={isNavExpanded}
          data-test="dc-header-toggle-nav"
          size="large"
        >
          <Hamburger />
        </IconButton>
      </Tooltip>

      <Typography variant="h1" className={classes.title} data-test="dc-title">
        <FormattedMessage
          id="os-data-center-browse.header.title"
          defaultMessage="Data Center"
          description="Panel header of the data center"
        />
      </Typography>

      <div className={classes.searchContainer}>
        <AiAutocomplete />
      </div>

      <div className={classes.headerActionContainer}>
        <AddNewMenu />
        <div className={classes.verticalDivider}></div>

        {/* <Tooltip
          title={
            <FormattedMessage
              id="os-data-center-browse.open.help-center.tooltip"
              defaultMessage="View Help"
              description="Help icon"
            />
          }
        >
          <IconButton data-test="data-center-help-icon" size="large">
            <HelpOutline />
          </IconButton>
        </Tooltip> */}

        <Typography style={visuallyHidden} id="dc-toggle-fullscreen">
          {SHARED_TEXT.toggleFullScreen()}
        </Typography>
        <Tooltip title={isFullScreen ? SHARED_TEXT.exitFullScreen() : SHARED_TEXT.enterFullScreen()}>
          <IconButton
            onClick={onFullScreenToggle}
            aria-labelledby="dc-toggle-fullscreen"
            data-test="dc-fullscreen-toggle"
            size="large"
          >
            {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
        </Tooltip>

        <Typography style={visuallyHidden} id="dc-close-panel">
          {SHARED_TEXT.closePanel()}
        </Typography>
        <Tooltip title={SHARED_TEXT.closePanel()}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="dc-close-panel"
            data-test-id="dc-close-panel"
            size="large"
          >
            <Hide />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
