import React, { useState, useCallback, SyntheticEvent, useMemo } from 'react';
import Menu from '@mui/material/Menu';
import { PopoverOrigin } from '@mui/material';
import { UseMenuProps } from './types';

/**
 * `useMenu` is a custom hook that provides functionality for opening, closing, and toggling a Material UI `Menu` component.
 * It also returns a `MenuElement` that is a JSX.Element and can be used in your React components.
 *
 * @returns A tuple where the first element is an object containing the following properties:
 * - `toggleMenu`: A function that toggles the open state of the menu.
 * - `openMenu`: A function that sets the open state of the menu to true.
 * - `closeMenu`: A function that sets the open state of the menu to false.
 *
 * The second element is a boolean that represents the open state of the menu.
 *
 * The third element is a `MenuElement` which is a JSX.Element that renders a Material UI `Menu`. This component accepts all props that a `Menu` would accept, with the exception of the `open` prop. Instead, the open state of the `MenuElement` is controlled by the `toggleMenu`, `openMenu`, and `closeMenu` functions that are returned by the `useMenu` hook.
 *
 * @example
 * const [{ toggleMenu, openMenu, closeMenu }, isOpen, MenuElement] = useMenu();
 *
 * // In your component render method:
 * <>
 *   <button onClick={toggleMenu}>Toggle Menu</button>
 *   {MenuElement}
 * </>
 */
function useMenu(): [
  {
    toggleMenu: React.EventHandler<SyntheticEvent>;
    openMenu: React.EventHandler<SyntheticEvent>;
    closeMenu: React.EventHandler<SyntheticEvent>;
  },
  boolean,
  (props: React.PropsWithChildren<UseMenuProps>) => JSX.Element | null
] {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const MenuElement = useMemo(() => {
    const defaultAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'center' };
    const defaultTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'center' };
    const submenuAnchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
    const submenuTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };

    return React.forwardRef(
      (
        {
          children,
          anchorRef,
          anchorPosition,
          anchorOrigin,
          transformOrigin,
          submenu,
          ...props
        }: React.PropsWithChildren<UseMenuProps>,
        ref: React.ForwardedRef<HTMLDivElement> | undefined
      ) => {
        const finalAnchorOrigin = submenu
          ? anchorOrigin ?? submenuAnchorOrigin
          : anchorOrigin ?? defaultAnchorOrigin;
        const finalTransformOrigin = submenu
          ? transformOrigin ?? submenuTransformOrigin
          : transformOrigin ?? defaultTransformOrigin;
        return (
          <Menu
            {...props}
            ref={ref}
            anchorEl={anchorRef.current}
            open={isOpen}
            onClose={closeMenu}
            anchorPosition={anchorPosition}
            anchorOrigin={finalAnchorOrigin}
            transformOrigin={finalTransformOrigin}
            style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
          >
            {children}
          </Menu>
        );
      }
    );
  }, [isOpen, closeMenu]);

  return [{ toggleMenu, openMenu, closeMenu }, isOpen, MenuElement as any];
}

export { useMenu, type UseMenuProps };
