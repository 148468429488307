import * as characterCountTextFieldJsx from './character-count-text-field/components/character-count-text-field';
import * as enginesFilterApi from './engines-filter/api';
import * as enginesFilterJsx from './engines-filter/components/ai-engines-filter';
import * as enginesFilterHelpers from './engines-filter/helpers';
import type { IEngine, IEngineCategory } from './engines-filter/types';
import { IEnginesUsed } from './engines-filter/types';
import * as imageUploadJsx from './image-uploader/components/image-upload';
import * as imageUploadApi from './image-uploader/api';
import type {
  IImageUploadProps,
  IImageUrlsType,
  IImageGetUrlReturnType,
  IImageFileReturnType,
} from './image-uploader/types';
import * as MarkdownJsx from './markdown/components/Markdown';

import * as distributionTypeJsx from './distribution-type/components/distribution-types';

import * as statusFilterJsx from './status-filter/components/status-filter';
import type { TJobStatus } from './status-filter/types';

import * as dateRangeFilterJsx from './date-time-filter/components/date-time-filter';
import type { TDateTimeFilter, TDateTimeFilterField } from './date-time-filter/types';

import * as tableInfiniteScrollJsx from './table-infinite-scroll/components/table-infinite-scroll';
import {
  ITableRowComponentProps,
  ITableInfiniteScrollProps,
  TTableHeaderItem,
} from './table-infinite-scroll/types';

import { TableLoadingState } from './table-infinite-scroll/components/table-row-loader';

import { UserFilter as userFilterJsx, DropdownFilter } from './user-filter/components/user-filter';

import type {
  GeneratedState,
  GeneratedSingleState,
  IGlobalState,
  IGlobalSingleState,
} from './redux-generator/types';

import * as Utils from './helpers/utils';
import {
  PanelHeader,
  PanelContainer,
  PanelContent,
  PanelStepperTabs,
  PanelErrorState,
  PanelModal,
  SkeletonLoadingStates,
  FullscreenPortal,
} from './panel-components';
import { CenterTabHeading, CenterTabContainer, CenterTabContent } from './center-components';
import { StatusPill } from './status-pill/status-pill';
import { STATUS_COLORS } from './status-pill/status-colors';
import { TableSearchBannerChip } from './table-search-components/components/table-search-banner-chip';
import { TableSearchBanner } from './table-search-components/components/table-search-banner';
import { TableSearchInputButton } from './table-search-components/components/table-search-input-button';

import PermissionsList from './permissions-list/components/permissions-list';
import { JSONTable } from './json-table/json-table';
import { ChipComponents } from './chips';
import { ContextMenu, TContextMenuItem, TContextMenuProps } from './context-menu/context-menu';
import { CustomLoginPreviewModal } from './custom-login-helpers/custom-login-preview-modal';
import { CustomLoginTableRow } from './custom-login-helpers/custom-login-table-row';
import { EmptyState } from './empty-state/empty-state';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog';
import { CustomLoginTableHeader } from './custom-login-helpers/custom-login-table-header';

export const characterCountTextField = { jsx: characterCountTextFieldJsx };
export const imageUpload = { api: imageUploadApi, jsx: imageUploadJsx };
export const enginesFilter = { api: enginesFilterApi, jsx: enginesFilterJsx, helpers: enginesFilterHelpers };
export const statusFilter = { jsx: statusFilterJsx };
export const dateRangeFilter = { jsx: dateRangeFilterJsx };
export const userFilter = { jsx: userFilterJsx };
export const dropdownFilter = { jsx: DropdownFilter };
export const tableInfiniteScroll = { jsx: tableInfiniteScrollJsx };
export type { ITableInfiniteScrollProps, ITableRowComponentProps, TTableHeaderItem };
export const panelComponents = {
  header: PanelHeader,
  container: PanelContainer,
  content: PanelContent,
  stepper: PanelStepperTabs,
  loader: SkeletonLoadingStates,
  error: PanelErrorState,
  modal: PanelModal,
};
export const centerComponents = {
  CenterTabContainer,
  CenterTabHeading,
  CenterTabContent,
};

const ErrorState = PanelErrorState;
export { EmptyState, ErrorState, ConfirmDialog };

export const tableSearchComponents = {
  TableSearchBannerChip: TableSearchBannerChip,
  TableSearchBanner: TableSearchBanner,
  TableSearchInputButton: TableSearchInputButton,
};

export { ContextMenu, type TContextMenuItem, type TContextMenuProps };
export { CustomLoginPreviewModal, CustomLoginTableRow, CustomLoginTableHeader };

export const statusPill = {
  jsx: StatusPill,
  colors: STATUS_COLORS,
};

export { StatusPill, STATUS_COLORS };

export const jsonTable = {
  table: JSONTable,
};

export { ChipComponents };

export { TableLoadingState };

export { IEngine, IEngineCategory, type IEnginesUsed, TJobStatus, TDateTimeFilter, TDateTimeFilterField };

export { EngineIconsRenderer } from './engine-icons-helper';

export { generateState, generateSingleState } from './redux-generator';
export { GeneratedState, GeneratedSingleState, IGlobalState, IGlobalSingleState };
export { IImageUploadProps, IImageUrlsType, IImageGetUrlReturnType, IImageFileReturnType };
export { Utils };

export const markdown = { jsx: MarkdownJsx };
export { devErrorLogger } from './dev-error-logger';

export const distributionTypes = { jsx: distributionTypeJsx };
export type { DistributionType, selectionShape } from './distribution-type/types';

export {
  EngineComponentLoader,
  EngineFieldType,
  type FieldType,
  type EngineFieldPicklistOptionType,
  type IEngineFieldValue,
} from './engineComponentLoader';

export { PermissionsList };
export { truncatedString } from './helpers/utils';
export { DateFormatter, DataCenterSharedContentLayout, mockHelper } from './helpers/utils';
export { PlaceholderElement } from './placeholder-element/placeholder-element';
export { FullscreenPortal };

export { CustomColorInput } from './customColorInput';
export { ButtonPreview } from './buttonPreview';
export { useMenu, type UseMenuProps } from './use-menu';
export { useConfirmClose } from './use-confirm-close';

export { JSONEditor, validateJson, type JSONData } from './json-editor/json-editor';

export * from './virtual-list';
export * from './text';
export * from './application-config';

/** Form Builder/Generator */
export * from './form-builder';
export * from './form-generator';
/** End of Form Builder/Generator */

export * from './event-bus';
