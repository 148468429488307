import useStyles from './useStyles';
import OrganizationItem from '../OrganizationItem/OrganizationItem';
import { List } from '@mui/material';
import TabHeader from '../TabHeader/TabHeader';
import EmptyStateInvites from './EmptyStateInvites';
import InviteRequestButton from './InviteRequestButton';
import { FormattedMessage } from 'react-intl';
import { daysBetweenDates } from '../../../helpers/date-helper';
import { mountPanel } from '@aiware/js/panel';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/slices';
import {
  EOrganizationInviteAction,
  EOrganizationInviteStatus,
  TOrganizationInvite,
  TUpdateOrganizationInviteInput,
} from '../../../types';
import { LoadingStatus } from '@aiware/js/interfaces';
import ErrorState from '../shared/ErrorState';
import OrganizationItemLoaderSkeleton from '../shared/OrganizationItemLoaderSkeleton';

export const reviewRequestPanel = 'REVIEW_ORG_INVITE_PANEL';

interface IInvitesTabProps {
  organizationInvites: TOrganizationInvite[];
  uiStatus: LoadingStatus;
  onRetry: () => void;
}
export default function InvitesTab({ organizationInvites, uiStatus, onRetry }: IInvitesTabProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const actionableOrganizationInvites = organizationInvites.filter(
    invite => invite.status !== EOrganizationInviteStatus.deleted
  );

  const generateExpireNotice = (expirationDate: string) => {
    const daysRemaining = daysBetweenDates(new Date(), new Date(expirationDate));
    if (daysRemaining < 0) {
      return (
        <FormattedMessage
          id="os-organization-panel.invites.tab-invite-status-expired"
          defaultMessage="Expired"
          description="organization panel: invites tab, invite expiration status, is expired"
        />
      );
    }
    switch (daysRemaining) {
      case 0:
        return (
          <FormattedMessage
            id="os-organization-panel.invites.tab-invite-status-expires-today"
            defaultMessage="Expires today"
            description="organization panel: invites tab, invite expiration status, is expiring today"
          />
        );
      case 1:
        return (
          <FormattedMessage
            id="os-organization-panel.invites.tab-invite-status-expires-tomorrow"
            defaultMessage="Expires tomorrow"
            description="organization panel: invites tab, invite expiration status, is expiring tomorrow"
          />
        );
      default:
        return (
          <FormattedMessage
            id="os-organization-panel.invites.tab-invite-status-expiration"
            defaultMessage="Expires in {days} days"
            description="organization panel: invites tab, invite expiration status, is expired"
            values={{
              days: daysRemaining,
            }}
          />
        );
    }
  };
  if (uiStatus === 'success' && actionableOrganizationInvites.length === 0) {
    return (
      <>
        <TabHeader tab="invites" />
        <EmptyStateInvites type="invites" />
      </>
    );
  } else if (uiStatus === 'pending') {
    return (
      <>
        <TabHeader tab="invites" />
        <OrganizationItemLoaderSkeleton />
      </>
    );
  } else if (uiStatus === 'failure') {
    return (
      <>
        <TabHeader tab="invites" />
        <ErrorState
          onRetry={onRetry}
          errorMessage={
            <FormattedMessage
              id="os-organization-panel.invites-tab.error-message"
              defaultMessage="Something went wrong while loading your organization invites."
              description="my organizations error state"
            />
          }
        />
      </>
    );
  }
  return (
    <div className={classes.container}>
      <TabHeader tab="invites" />
      <List>
        {actionableOrganizationInvites.map((invite: TOrganizationInvite, index) => {
          const { expirationDate, organization, organizationInviteId } = invite;

          const organizationName = organization!.name;
          const organizationLogo = organization!.imageUrl!;

          const isNotLastItem = index !== actionableOrganizationInvites.length - 1;
          const isInviteExpired = daysBetweenDates(new Date(), new Date(expirationDate)) < 0;
          // The click mounts the review request panel
          const handleClickViewRequest = () => {
            dispatch(actions.organizationsState.setActiveOrganizationInviteId(organizationInviteId));
            const microFrontend = {
              name: reviewRequestPanel,
              config: {
                name: 'Review Request',
              },
            };

            const panelConfig = {
              type: 'APP_BAR_PANEL_TEMPLATE',
              marginTop: 55,
              marginStart: 640,
              size: 'small',
              parentPanelId: 'ORGANIZATION_PANEL',
              dimmed: 0,
              dimmedStatus: 'dimParent',
            };
            dispatch(
              mountPanel({
                panelId: reviewRequestPanel,
                microFrontend,
                panelConfig,
              })
            );
          };
          const handleClickRemoveExpiredInvite = () => {
            const request: TUpdateOrganizationInviteInput = {
              organizationInviteId: invite.organizationInviteId,
              applicationRoles: invite.applicationRoles,
              action: EOrganizationInviteAction.delete,
            };

            dispatch(actions.organizationInvitesState.updateOrganizationInviteStart(request));
          };
          const handleClickRequestNewInvite = () => {
            dispatch(actions.organizationInvitesState.reRequestOrganizationInviteStart(invite));
          };

          return (
            <OrganizationItem
              organizationLogo={organizationLogo}
              organizationName={organizationName}
              subtitle={generateExpireNotice(expirationDate)}
              Action={
                <InviteRequestButton
                  isExpired={isInviteExpired}
                  onClickViewActiveRequest={isInviteExpired ? undefined : handleClickViewRequest}
                  onClickRemoveExpiredInvite={isInviteExpired ? handleClickRemoveExpiredInvite : undefined}
                  onClickRequestNewInvite={isInviteExpired ? handleClickRequestNewInvite : undefined}
                  key={invite.organizationInviteId}
                />
              }
              isDefault={false}
              showDivider={isNotLastItem}
              key={invite.organizationInviteId}
            />
          );
        })}
      </List>
    </div>
  );
}
