import { ISagaModule } from 'redux-dynamic-modules-saga';
import { store } from '@aiware/shared/store';

import userReducer, { nameSpace } from './reducer';
import rootSaga from './rootSaga';
export function getAppbarModule(): ISagaModule<unknown> {
  return {
    id: 'os-app-bar',
    reducerMap: {
      [nameSpace]: userReducer,
    },
    sagas: [rootSaga],
    initialActions: [],
  };
}

export default store;
