import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type TProps = {
  refProp: React.MutableRefObject<any>;
  setShowBackgroundSelection: (value: React.SetStateAction<boolean>) => void;
};

export const ContextMenu = ({ refProp, setShowBackgroundSelection }: TProps) => {
  return (
    <Box
      data-testid={`aiware-desktop.settings.context-menu.change-background-option`}
      ref={refProp}
      sx={{
        position: 'fixed',
        left: '50px',
        bottom: '100px',
        background: 'rgba(255, 255, 255, 0.85)',
        border: '1px solid #DADFE8',
        boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '18px 20px',
        fontFamily: 'Nunito',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => setShowBackgroundSelection(prev => !prev)}
    >
      <Typography variant="body2">
        <FormattedMessage
          id="aiware-desktop.settings.change-background"
          defaultMessage="Change Background"
          description="Change Background label"
        />
      </Typography>
      <ArrowForwardIosIcon fontSize="small" sx={{ ml: '19px' }} />
    </Box>
  );
};
