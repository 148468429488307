import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  addNewUsersText: (
    <FormattedMessage
      id="os-organization-panel.add-new-users-text"
      defaultMessage="Add new users to this organization and control the applications that they have access to."
      description="Multi-Org invite users instructions text"
    />
  ),
  addButtonText: (
    <FormattedMessage
      id="os-organization-panel.add-button"
      defaultMessage="Add"
      description="Multi-Org add user"
    />
  ),
  appText: (
    <FormattedMessage
      id="os-organization-panel.tooltip.app"
      defaultMessage="App"
      description="application on tooltip"
    />
  ),
  roleText: (
    <FormattedMessage
      id="os-organization-panel.tooltip.role"
      defaultMessage="Role"
      description="role on tooltip"
    />
  ),
  editButtonText: (
    <FormattedMessage
      id="os-organization-panel.edit-button"
      defaultMessage="Edit"
      description="Multi-Org add user"
    />
  ),
  sendInvitationButtonText: (
    <FormattedMessage
      id="os-organization-panel.send-invitations"
      defaultMessage="Send Invitation"
      description="Multi-Org send invitation"
    />
  ),
  noApplicationsText: (
    <FormattedMessage
      id="os-organization-panel.no-applications-yet"
      defaultMessage="You have not added any applications yet"
      description="Multi-Org no applications"
    />
  ),
  accessTo: (
    <FormattedMessage
      id="os-organization-panel.access-to"
      defaultMessage="Access to"
      description="Multi-Org access to"
    />
  ),
  application: (
    <FormattedMessage
      id="os-organization-panel.application-singular"
      defaultMessage="Application"
      description="Multi-Org application"
    />
  ),
  applications: (
    <FormattedMessage
      id="os-organization-panel.application-plural"
      defaultMessage="Applications"
      description="Multi-Org applications"
    />
  ),
  removeUserButton: (
    <FormattedMessage
      id="os-organization-panel.remove-user"
      defaultMessage="Remove User"
      description="Multi-Org remove user"
    />
  ),
  hasPendingInvite: (
    <FormattedMessage
      id="os-organization-panel.pending-invite"
      defaultMessage="This user has a pending invitation for this organization already."
      description="Multi-Org has pending invite"
    />
  ),
  closePanel: (
    <FormattedMessage
      id="os-organization-panel.close-panel-btn"
      defaultMessage="Close Panel"
      description="Close Panel"
    />
  ),
  headerTitle: (
    <FormattedMessage
      id="os-organization-panel.header.title"
      defaultMessage="Organizations"
      description="Panel header of the organizations"
    />
  ),
  inviteUsers: (
    <FormattedMessage
      id="os-organization-panel.header.button.invite-users"
      defaultMessage="Invite Users"
      description="Invite Users header button"
    />
  ),
  addApplications: (
    <FormattedMessage
      id="os-organization-panel.header.button.add-applications"
      defaultMessage="Add Applications"
      description="Add Applications title"
    />
  ),
  adminApprovalRequired: (
    <FormattedMessage
      id="os-organization-panel.non-admin-disclaimer.title"
      defaultMessage="Admin Approval Required"
      description="Invite Users - non admin user disclaimer"
    />
  ),
  adminApprovalRequiredMessage: (
    <FormattedMessage
      id="os-organization-panel.non-admin-disclaimer.message"
      defaultMessage="All Invitations must be approved by your Organization Admin. You will receive a notification once this request has been reviewed. Please note your admin may change application access for this invitee if necessary."
      description="Invite Users - non admin user disclaimer message"
    />
  ),
  viewRequests: (
    <FormattedMessage
      id="os-organization-panel.header.button.view-requests"
      defaultMessage="View Requests"
      description="View Requests header button"
    />
  ),
  inviteRequests: (
    <FormattedMessage
      id="os-organization-panel.header.button.invite-requests"
      defaultMessage="Invite Requests"
      description="Invite Requests header title"
    />
  ),
  inviteRequestsDescription: (
    <FormattedMessage
      id="os-organization-panel.header.button.invite-requests-description"
      defaultMessage="Review and manage all invite requests sent from users of your organization. Once you are finished, click confirm to send approved request to new users."
      description="Invite Requests header description"
    />
  ),
  approveAll: (
    <FormattedMessage
      id="os-organization-panel.header.button.invite-requests-approve-all"
      defaultMessage="Approve All"
      description="Invite Requests approve all button"
    />
  ),
  rejectAll: (
    <FormattedMessage
      id="os-organization-panel.actionItems.invite-requests-reject-all"
      defaultMessage="Reject All"
      description="Invite Requests reject all button"
    />
  ),
  requestor: (
    <FormattedMessage
      id="os-organization-panel.body.invite-requests-requestor"
      defaultMessage="Requestor"
      description="Invite Requests requestor label"
    />
  ),
  accessRequest: (
    <FormattedMessage
      id="os-organization-panel.table.invite-requests-requestor"
      defaultMessage="Access Request"
      description="Invite Requests requsted apps name"
    />
  ),
  role: (
    <FormattedMessage
      id="os-organization-panel.table.role"
      defaultMessage="Role"
      description="Invite Requests requsted apps role"
    />
  ),
  approveRequest: (
    <FormattedMessage
      id="os-organization-panel.cardActions.approveRequest"
      defaultMessage="Approve Request"
      description="Invite Requests approve request button text"
    />
  ),
  rejectRequest: (
    <FormattedMessage
      id="os-organization-panel.cardActions.rejectRequest"
      defaultMessage="Reject Request"
      description="Invite Requests reject request button text"
    />
  ),
  confirm: (
    <FormattedMessage
      id="os-organization-panel.cardActions.confirm"
      defaultMessage="Confirm"
      description="Invite Requests confirm button text"
    />
  ),
  reviewRequest: (
    <FormattedMessage
      id="os-organization-panel.review-request-panel.header.review-request"
      defaultMessage="Review Request"
      description="Review Request Panel header text"
    />
  ),
  saveBtn: (
    <FormattedMessage
      id="os-organization-panel.app-access.save-button"
      defaultMessage="Save"
      description="Invite Users - application role save button"
    />
  ),
  makeDefault: (
    <FormattedMessage
      id="os-organization-panel.make-default-org"
      defaultMessage="Make Default"
      description="Multi-Org: set default org"
    />
  ),
};
