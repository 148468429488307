import { emptyFolders } from '@aiware/shared/assets';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles<void>()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px',
    marginTop: '15px',
  },
  image: {
    maxWidth: '50%',
    marginBottom: '40px',
    marginTop: '25px',
    userSelect: 'none',
    pointerEvents: 'none',
    userDrag: 'none',
  },
  header: {
    fontWeight: 600,
    marginBottom: '12px',
    cursor: 'default',
  },
  description: {
    opacity: 0.8,
  },
}));

export interface INullStateProps {
  image?: string;
  header: JSX.Element;
  description?: JSX.Element;
}

export default function NullState({ header, description, image }: INullStateProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.container} data-testid={'ui-null-state-container'}>
      <img
        src={image || emptyFolders}
        className={classes.image}
        alt="no data"
        data-testid={'ui-null-state-image'}
      />
      <Typography variant="body2" className={classes.header} data-testid={'ui-null-state-header'}>
        {header}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.description}
        data-testid={'admin-center-groups-empty-state-description'}
      >
        {description}
      </Typography>
    </div>
  );
}
export { NullState };
