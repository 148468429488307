import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import SearchVirtualTable from './SearchVirtualTable';
import { EResultsViewStyle } from '../../AISearchResultsView';
import CircularProgress from '@mui/material/CircularProgress';
import { selectSearchFilesState, selectSearchHasMore } from '../../../../state/aiSearch/aiSearch.selectors';
import { actions as searchActions } from '../../../../state/aiSearch/aiSearch.slice';

const useStyles = makeStyles()((_theme: Theme) => ({
  tableWrapper: {
    flexGrow: 1,
    overflowY: 'hidden',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px',
  },
}));

export const SearchVirtualTableContainer = ({
  viewStyle,
  isLoading,
}: {
  viewStyle: EResultsViewStyle;
  isLoading: boolean;
}) => {
  const { classes } = useStyles();

  // TODO: Implement pagination
  const hasMore = useSelector(selectSearchHasMore);
  const dispatch = useDispatch();
  const results = useSelector(selectSearchFilesState);

  const loadResults = () => {
    dispatch(searchActions.filesSearchStart());
  };

  return (
    <div className={classes.tableWrapper}>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress size={36} />
        </div>
      )}
      {!isLoading && (
        <SearchVirtualTable
          results={results}
          hasMore={hasMore}
          loadResults={loadResults}
          viewStyle={viewStyle}
        />
      )}
    </div>
  );
};

export default SearchVirtualTableContainer;
