import { combineReducers } from 'redux';

import { SLICE_NAME } from '../../types';
import * as entities from './entities';
import * as uiState from './ui-state';
import * as viewMyFiles from './view-my-files';
import * as viewStreams from './view-streams';
import * as panelAddFolder from './panel-add-folder';
import * as panelEditFolder from './panel-edit-folder';
import * as panelSelectImportSource from './panel-select-import-source';
import * as tdoActions from './common-actions/tdos';
import * as sourceActions from './common-actions/sources';
import * as scheduleActions from './common-actions/schedules';
import * as aiAutocomplete from '../../lib/Search/state/aiAutocomplete/aiAutocomplete.slice';
import * as aiSearch from '../../lib/Search/state/aiSearch/aiSearch.slice';
import * as aiSearchFilter from '../../lib/Search/state/aiSearchFilter/aiSearchFilter.slice';
import * as aiStructuredData from '../../lib/Search/state/aiStructuredData/aiStructuredData.slice';
import * as getLibraryIds from '../../lib/Search/state/getLibraryIds/getLibraryIds.slice';
import * as fileInfo from './file-info';
import * as editMetadata from './edit-metadata';

import * as schemaProperties from '../../lib/Search/state/aiStructuredData/schemaProperties.redux';

import * as schemaSearch from '../../lib/Search/state/schemaSearch.redux';

export const namespace = SLICE_NAME;

export type DataCenterState = {
  [namespace]: {
    [entities.namespace]: entities.State;
    [uiState.namespace]: uiState.State;
    [viewMyFiles.namespace]: viewMyFiles.State;
    [viewStreams.namespace]: viewStreams.State;
    [panelAddFolder.namespace]: panelAddFolder.State;
    [panelEditFolder.namespace]: panelEditFolder.State;
    [panelSelectImportSource.namespace]: panelSelectImportSource.State;
    [aiAutocomplete.namespace]: aiAutocomplete.State;
    [aiSearch.namespace]: aiSearch.State;
    [aiSearchFilter.namespace]: aiSearchFilter.State;
    [aiStructuredData.namespace]: aiStructuredData.State;
    [getLibraryIds.namespace]: getLibraryIds.State;
    [fileInfo.namespace]: fileInfo.State;
    [schemaProperties.namespace]: schemaProperties.SchemaPropertiesState;
    [schemaSearch.namespace]: schemaSearch.State;
    [editMetadata.namespace]: editMetadata.State;
  };
};

export const initialState = {
  [entities.namespace]: entities.initialState,
  [uiState.namespace]: uiState.initialState,
  [viewMyFiles.namespace]: viewMyFiles.initialState,
  [viewStreams.namespace]: viewStreams.initialState,
  [panelAddFolder.namespace]: panelAddFolder.initialState,
  [panelEditFolder.namespace]: panelEditFolder.initialState,
  [panelSelectImportSource.namespace]: panelSelectImportSource.initialState,
  [aiAutocomplete.namespace]: aiAutocomplete.initialState,
  [aiSearch.namespace]: aiSearch.initialState,
  [aiSearchFilter.namespace]: aiSearchFilter.initialState,
  [aiStructuredData.namespace]: aiStructuredData.initialState,
  [getLibraryIds.namespace]: getLibraryIds.initialState,
  [fileInfo.namespace]: fileInfo.initialState,
  [schemaProperties.namespace]: schemaProperties.initialState,
  [schemaSearch.namespace]: schemaSearch.initialState,
  [editMetadata.namespace]: editMetadata.initialState,
};

export const actions = {
  [entities.namespace]: entities.actions,
  [uiState.namespace]: uiState.actions,
  [viewMyFiles.namespace]: viewMyFiles.actions,
  [viewStreams.namespace]: viewStreams.actions,
  [panelAddFolder.namespace]: panelAddFolder.actions,
  [panelEditFolder.namespace]: panelEditFolder.actions,
  [panelSelectImportSource.namespace]: panelSelectImportSource.actions,
  [tdoActions.namespace]: tdoActions.actions,
  [sourceActions.namespace]: sourceActions.actions,
  [scheduleActions.namespace]: scheduleActions.actions,
  [aiAutocomplete.namespace]: aiAutocomplete.actions,
  [aiSearch.namespace]: aiSearch.actions,
  [aiSearchFilter.namespace]: aiSearchFilter.actions,
  [aiStructuredData.namespace]: aiStructuredData.actions,
  [getLibraryIds.namespace]: getLibraryIds.getLibraryIdsActions,
  [fileInfo.namespace]: fileInfo.actions,
  [schemaProperties.namespace]: schemaProperties.slice.actions,
  [schemaSearch.namespace]: schemaSearch.slice.actions,
  [editMetadata.namespace]: editMetadata.actions,
};

export const rootReducer = combineReducers({
  [entities.namespace]: entities.default,
  [uiState.namespace]: uiState.default,
  [viewMyFiles.namespace]: viewMyFiles.default,
  [viewStreams.namespace]: viewStreams.default,
  [panelAddFolder.namespace]: panelAddFolder.default,
  [panelEditFolder.namespace]: panelEditFolder.default,
  [panelSelectImportSource.namespace]: panelSelectImportSource.default,
  [aiAutocomplete.namespace]: aiAutocomplete.default,
  [aiSearch.namespace]: aiSearch.default,
  [aiSearchFilter.namespace]: aiSearchFilter.default,
  [aiStructuredData.namespace]: aiStructuredData.default,
  [getLibraryIds.namespace]: getLibraryIds.default,
  [fileInfo.namespace]: fileInfo.default,
  [schemaProperties.namespace]: schemaProperties.slice.reducer,
  [schemaSearch.namespace]: schemaSearch.slice.reducer,
  [editMetadata.namespace]: editMetadata.default,
});
