import { TJSONTablePaginationProps } from '../components/json-table-pagination';
import { validate } from 'uuid';

export enum EJSONTableCellType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  URL = 'URL',
  JSON = 'JSON',
  UUID = 'UUID',
  OBJECT = 'OBJECT',
  DEFAULT = 'DEFAULT',
  NULL = 'NULL',
}

export const getTableRowValues = (data: any) => {
  if (!data || !data[0]) return [];
  return data.map((item: any, index: number) => ({ index, values: Object.values(item) }));
};

export const getTableHeaderCellLabels = (data: any) => {
  if (!data || !data[0]) return [];
  return Object.keys(data[0]);
};

export const getTablePaginationRows = (allRows: any[], paginationProps?: TJSONTablePaginationProps) => {
  if (!paginationProps) return allRows;

  const { currentPage, pageSize } = paginationProps;
  const start = (currentPage - 1) * pageSize;

  let end = start + pageSize;

  if (end >= allRows.length) {
    end = allRows.length;
  }

  return allRows.slice(start, end);
};

export const typeCheckHelper = {
  isValidURL: (value: any) => {
    try {
      new URL(value);
      return true;
    } catch (_error) {
      return false;
    }
  },
  isUUID: (value: any) => {
    return validate(value);
  },
  isBoolean: (value: any) => {
    return typeof value === 'boolean';
  },
  isNumber: (value: any) => {
    return isNaN(Number(value)) === false;
  },
  isObject: (value: any) => {
    return value && typeof value === 'object' && value.constructor === Object;
  },
  isDate: (date: any) => {
    return date instanceof Date && !isNaN(date?.getTime());
  },
  isDateString: (value: any) => {
    const date = new Date(value);
    return !isNaN(date.getTime());
  },
};

export const getCellType = (value: any): EJSONTableCellType => {
  if (value === undefined || value === null || value === '') {
    return EJSONTableCellType.NULL;
  }

  if (typeCheckHelper.isObject(value)) {
    return EJSONTableCellType.OBJECT;
  }

  if (typeCheckHelper.isBoolean(value)) {
    return EJSONTableCellType.BOOLEAN;
  }

  if (typeCheckHelper.isNumber(value) && `${value}`.length > 5) {
    return EJSONTableCellType.NUMBER;
  }

  if (typeCheckHelper.isDateString(value)) {
    return EJSONTableCellType.DATE;
  }

  if (typeCheckHelper.isValidURL(value)) {
    return EJSONTableCellType.URL;
  }

  if (typeCheckHelper.isUUID(value)) {
    return EJSONTableCellType.UUID;
  }

  return EJSONTableCellType.STRING;
};
export const JSON_TABLE_DEFAULT_PAGE_SIZES = [20, 50, 100];
