import { helpCircle } from '@aiware/shared/assets';
import { VeritoneTheme } from '@aiware/shared/theme';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'helpItemText' | 'versionWrapper' | 'versionText'>()(
  (theme: VeritoneTheme, _props, classes) => ({
    popover: {
      marginTop: 18,
      [`& ${classes.helpItemText}`]: {
        padding: theme.spacing(0),
        fontSize: 15,
      },
      [`& ${classes.versionWrapper}`]: {
        padding: theme.spacing(3.2, 0),
        [`& ${classes.versionText}`]: {
          fontSize: 12,
          color: '#757575',
        },
      },
    },
    helpItemText: {},
    versionWrapper: {},
    versionText: {},
    help: {},
    barIcon: {
      padding: theme.spacing(0.8),
    },
    icon: {
      width: '40px !important',
      height: '40px !important',
      padding: theme.spacing(0),
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    helpIcon: {
      WebkitMask: `url("${helpCircle}") no-repeat 50% 50%`,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    list: {
      backgroundColor: theme.palette.background.default,
    },
    popoverColor: {
      backgroundColor: theme.palette.background.default,
    },
  })
);
export { useStyles };
