import { ChangeEvent, Fragment } from 'react';
import { Filter } from '@aiware/ui';
import { DateTime } from 'luxon';
import { Box, SelectChangeEvent, Typography } from '@mui/material';

import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import ActivityCard from './ActivityCard';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  header: {
    opacity: 0.9,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 6),
    position: 'relative',
  },
  title: {
    opacity: 0.9,
    padding: theme.spacing(4, 6, 2, 6),
  },
  titleText: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
  },
  scrollContent: {
    overflowY: 'auto',
    height: 'calc(100% - 65px)',
  },
}));

const filters = [
  {
    value: 'all',
    title: 'All Activities',
  },
  {
    value: 'today',
    title: 'Activities today',
  },
];

export interface IActivityDetail {
  createdDateTime?: string;
}
interface Props {
  data: IActivityDetail[];
  filter: string;
  onChangeFilter: (value: string) => void;
}
const today = DateTime.local().startOf('day');
const yesterday = DateTime.local().plus({ days: -1 }).startOf('day');

const ActivityPanel = ({ data, filter, onChangeFilter }: Props) => {
  const { classes } = useStyles();
  const onFilter = (event: SelectChangeEvent<string>) => {
    onChangeFilter(event?.target?.value as string);
  };

  const todayData = data?.filter(item => DateTime.fromISO(item.createdDateTime || '').hasSame(today, 'day'));

  const yesterdayData = data?.filter(item =>
    DateTime.fromISO(item.createdDateTime || '').hasSame(yesterday, 'day')
  );
  const olderData = data?.filter(
    item => DateTime.fromISO(item.createdDateTime || '').startOf('day') < yesterday
  );
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="body2" className={classes.titleText}>
          Today
        </Typography>
        <Filter data={filters} filter={filter} onFilter={onFilter} />
      </div>
      <Box className={classes.scrollContent}>
        <Box>
          {todayData?.map((item, index) => (
            <ActivityCard longFormatTime={false} key={index} data={item} />
          ))}
        </Box>
        {filter === 'all' && (
          <Fragment>
            {yesterdayData?.length > 0 && (
              <Fragment>
                <Box className={classes.title} data-testid="filter-all-box">
                  <Typography variant="body2" className={classes.titleText}>
                    Yesterday
                  </Typography>
                </Box>
                <Box>
                  {yesterdayData.map((item, index) => (
                    <ActivityCard longFormatTime={false} key={index} data={item} />
                  ))}
                </Box>
              </Fragment>
            )}
            {olderData?.length > 0 && (
              <Fragment>
                <Box className={classes.title}>
                  <Typography variant="body2" className={classes.titleText}>
                    Older
                  </Typography>
                </Box>
                <Box>
                  {olderData.map((item, index) => (
                    <ActivityCard longFormatTime={true} key={index} data={item} />
                  ))}
                </Box>
              </Fragment>
            )}
          </Fragment>
        )}
      </Box>
    </div>
  );
};

export default ActivityPanel;
