import createSvgIcon from '../createSvgIcon';

export const Stream = createSvgIcon(
  <svg width="19" height="19" viewBox="2 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3190_26231)">
      <path
        d="M18.4037 17.7143C21.9804 14.2913 21.8981 8.66292 18.2198 5.14282"
        stroke="#555F7C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5272 15.2289C17.559 13.2844 17.5122 10.0871 15.4227 8.0874"
        stroke="#555F7C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.61629 17.7143C2.03964 14.2913 2.12196 8.66292 5.80017 5.14282"
        stroke="#555F7C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.3815 15.1452C6.3497 13.2007 6.39647 10.0033 8.48596 8.00366"
        stroke="#555F7C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1139 13.6498C13.3255 13.6498 14.3076 12.7099 14.3076 11.5504C14.3076 10.3909 13.3255 9.45093 12.1139 9.45093C10.9023 9.45093 9.92017 10.3909 9.92017 11.5504C9.92017 12.7099 10.9023 13.6498 12.1139 13.6498Z"
        fill="#555F7C"
      />
    </g>
    <defs>
      <clipPath id="clip0_3190_26231">
        <rect width="20" height="16" fill="white" transform="translate(2 4)" />
      </clipPath>
    </defs>
  </svg>,
  'Stream'
);
