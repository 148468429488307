import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { getDataCenterBrowseModule } from '../../../../../redux';
import { PanelContent } from './PanelContent';
import { useSelector } from 'react-redux';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

export const PanelContainer = (props: {
  dataId: string;
  panelRendered: boolean;
  appId: string;
  connectorId: string;
  entityId: string;
  iframeUrl?: string;
  fileName: string;
}) => {
  const isFullscreen = useSelector(selectIsFullscreenEnabled);
  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[getDataCenterBrowseModule()]}>
        <AIWareIntlProvider
          style={{
            height: `calc(100vh - ${isFullscreen ? '0' : '60'}px)`,
          }}
        >
          <PanelContent {...props} />
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
};
