import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import dateHelper from '../../helpers/dateHelper';

const DateFormatter: FunctionComponent<{
  value: string | undefined;
  isFluffy?: boolean;
  className?: string;
}> = ({ value, isFluffy, className }) => {
  const date = dateHelper(value);
  const time = date.time === 'N/A' ? '' : date.time;
  const day = date.day;
  return (
    <>
      {isFluffy ? (
        <>
          <Typography data-test={'day'} variant="caption" color="textPrimary">
            {day}
          </Typography>
          <Typography data-test={'time'} className={className} variant="caption" color="textSecondary">
            {time}
          </Typography>
        </>
      ) : (
        <>
          <Typography data-test={'day'} variant="body2" color="textPrimary">
            {day}
          </Typography>
          <Typography data-test={'time'} variant="caption" color="textSecondary">
            {time}
          </Typography>
        </>
      )}
    </>
  );
};
export default DateFormatter;
