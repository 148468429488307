import { SLICE_NAME } from '../../constants';
import { IScimIdsState, namespace } from '../slices/scimIds.state';

export const selectScimIds = (state: IScimIdsState): string[] =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IScimIdsState)?.scimIds;
/**
 *
 * @param state
 * @returns boolean if the call is complete, or undefined if the call is still in progress
 */
export const selectIsScimUser = (state: IScimIdsState): boolean | undefined =>
  ((state as any)?.[SLICE_NAME]?.[namespace] as IScimIdsState)?.status !== 'success'
    ? undefined
    : ((state as any)?.[SLICE_NAME]?.[namespace] as IScimIdsState)?.scimIds?.length > 0;
