import { useState } from 'react';
import Box from '@mui/material/Box';

import { Tabs, TabPanel } from '@aiware/ui';
import OverviewTab from './Tabs/Overview';
import LogsTab from './Tabs/PerformanceLogs';
import ResourcesTab from './Tabs/Resources';
import ErrorsTab from './Tabs/PerformanceErrors';
import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';

const tabsList = [
  {
    label: (
      <FormattedMessage
        id="os-app-bar-panel.xgDIjO"
        defaultMessage="Overview"
        description="tab label: Overview"
      />
    ),
    disabled: false,
  },
  {
    label: (
      <FormattedMessage id="os-app-bar-panel.NXAxMt" defaultMessage="Logs" description="tab label: Logs" />
    ),
    disabled: false,
  },
  {
    label: (
      <FormattedMessage
        id="os-app-bar-panel.Mxysi8"
        defaultMessage="Resources"
        description="tab label: Resources"
      />
    ),
    disabled: true,
  },
  {
    label: (
      <FormattedMessage
        id="os-app-bar-panel.Qj95LF"
        defaultMessage="Errors"
        description="tab label: Errors"
      />
    ),
    disabled: true,
  },
];

function PerformanceMonitor() {
  const { classes } = useStyles();
  const [selectedId, setSelectedId] = useState(0);

  const onChangeTab = (id: unknown) => {
    setSelectedId(Number(id));
  };

  return (
    <Box className={classes.root} data-testid="performance-monitor">
      <Box className={classes.tabs}>
        <Tabs selectedId={selectedId} onChangeTab={onChangeTab} tabsList={tabsList} />
      </Box>
      <Box className={classes.tabPanels}>
        <TabPanel value={selectedId} index={0}>
          <OverviewTab />
        </TabPanel>
        <TabPanel value={selectedId} index={1}>
          <LogsTab />
        </TabPanel>
        <TabPanel value={selectedId} index={2}>
          <ResourcesTab />
        </TabPanel>
        <TabPanel value={selectedId} index={3}>
          <ErrorsTab />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default PerformanceMonitor;
