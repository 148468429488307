import { TextIcon, Calendar, Email, ExpandMore } from '@aiware/shared/icons';

export const FIELDS = {
  basic_fields: ['text', 'number', 'date', 'select', 'email'],
};

export const ICON_FONT_SIZE_SX = { fontSize: '18px' };

export const ICON_FIELD_MAP = {
  text: <TextIcon sx={ICON_FONT_SIZE_SX} />,
  number: <TextIcon sx={ICON_FONT_SIZE_SX} />,
  date: <Calendar sx={ICON_FONT_SIZE_SX} />,
  email: <Email sx={ICON_FONT_SIZE_SX} />,
  select: <ExpandMore sx={ICON_FONT_SIZE_SX} />,

  default: <TextIcon sx={ICON_FONT_SIZE_SX} />,
};
