import { FieldError } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';

export const renderErrorMessage = ({ message }: FieldError) => {
  let errorMessage = 'This field is required';
  if (!isEmpty(message)) {
    errorMessage = message as string;
  }

  return (
    <FormattedMessage
      id="os-engine-center-new.errorMessage"
      defaultMessage="{errorMessage}"
      description="Error message"
      values={{ errorMessage }}
    />
  );
};
