import { baseGraphQLApiWithError, baseGraphQLApi } from '@aiware/shared/redux';
import { GraphQLPage, TUserGroup, TCreateAuthGroupInput } from '../types';
import { selectApiConfigs } from '../redux/selectors';
import { actions } from '../redux';

export async function createUserGroup(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: TCreateAuthGroupInput,
  orgGuid?: string
): Promise<GraphQLPage<TUserGroup>> {
  const operationName = 'createNewUserGroup';
  const name = 'authGroupCreate';
  const variables = {
    input,
  };

  if (orgGuid) {
    variables.input.ownerOrganization = `${orgGuid}`;
  }

  const query = `
    mutation ${operationName} ($input: AuthGroupCreateInput! ){
      ${name} (input: $input){
        id
        name
        description
        createdAt
        memberCount
        members (limit: 1000){
           count
           records {
            member {
              ... on User {
                id
                name
                email
                firstName
                lastName
                imageUrl
                createdDateTime
              }
              ... on AuthGroup {
                id
                description
              }
            }
            createdAt
          }
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TUserGroup>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function fetchUserGroups(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset: number,
  limit: number,
  orgGuid?: string // Why is this guid? IDK why :/ TODO: Change this to id on the backend
): Promise<GraphQLPage<TUserGroup>> {
  const operationName = 'fetchUserGroups';
  const name = 'authGroups';

  const orgFilter = orgGuid ? `ownerOrganization: "${orgGuid}"` : '';

  const query = `
    query ${operationName} {
      ${name} (
        offset: ${offset}
        limit: ${limit}
        ${orgFilter}
      ) {
        records {
          id
          name
          description
          createdAt
          isProtected
          memberCount
          members {
            count
            records {
              member {
                ... on User {
                  id
                  name
                  email
                  firstName
                  lastName
                  imageUrl
                  createdDateTime
                }
                ... on AuthGroup {
                  id
                  description
                }
              }
            }
          }
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TUserGroup>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  return result[name];
}

export async function updateUserGroup(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  action: ReturnType<typeof actions.adminCenterGroups.updateUserGroupsStart>,
  orgGuid?: string
): Promise<TUserGroup> {
  const operationName = 'updateUserGroup';
  const op = 'authGroupUpdate';
  const { id, name, description } = action.payload;
  type TFlexInput = {
    [x: string]: string;
  };
  const input: TFlexInput = {
    id,
  };
  if (name) {
    input['name'] = name;
  }
  if (description) {
    input['description'] = description;
  }
  if (orgGuid) {
    input['ownerOrganization'] = orgGuid;
  }

  const variables = {
    input,
  };
  const query = `
    mutation ${operationName} ($input:AuthGroupUpdateInput!) {
      ${op} (input:$input) {
          id
          name
          description
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [op]: TUserGroup;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[op];
}

export async function addMembersToUserGroup(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  action: ReturnType<typeof actions.adminCenterGroups.addMembersToUserGroupStart>,
  orgGuid?: string
): Promise<TUserGroup> {
  const operationName = 'addUserGroupMembers';
  const op = 'authGroupAddMembers';
  const { id, members } = action.payload;
  const variables = {
    id,
    members,
  };

  const orgFilter = orgGuid ? `ownerOrganization: "${orgGuid}"` : '';

  const query = `
    mutation ${operationName} ($id:ID! $members:[AuthGroupMemberInput!]!) {
      ${op} (
        id: $id
        members: $members
        ${orgFilter}
      ) {
          id
          name
          description
          memberCount
          members (limit: 1000){
            count
            records {
              member {
                ... on User {
                  id
                  name
                  email
                  firstName
                  lastName
                  imageUrl
                  createdDateTime
                }
                ... on AuthGroup {
                  id
                  description
                }
              }
              createdAt
            }
          }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [op]: TUserGroup;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[op];
}

export async function removeMembersFromUserGroup(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  action: ReturnType<typeof actions.adminCenterGroups.removeMembersFromUserGroupStart>,
  orgGuid?: string
): Promise<TUserGroup> {
  const operationName = 'removeUserGroupMembers';
  const op = 'authGroupRemoveMembers';
  const { id, memberIds } = action.payload;
  const variables = {
    id,
    memberIds,
  };

  const orgFilter = orgGuid ? `ownerOrganization: "${orgGuid}"` : '';

  const query = `
    mutation ${operationName} ($id:ID! $memberIds:[ID!]!) {
      ${op} (
        id: $id
        memberIds: $memberIds
        ${orgFilter}
      ) {
       id
       name
        description
        memberCount
        members (limit: 1000){
          count
          records {
            member {
              ... on User {
                id
                name
                email
                firstName
                lastName
                imageUrl
                createdDateTime
              }
              ... on AuthGroup {
                id
                description
              }
            }
            createdAt
          }
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [op]: TUserGroup;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
    rethrowOriginalError: true,
  });

  return result[op];
}

export async function deleteUserGroup(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  action: ReturnType<typeof actions.adminCenterGroups.deleteUserGroupStart>,
  orgGuid?: string
): Promise<string> {
  const operationName = 'deleteUserGroup';
  const op = 'authGroupDelete';
  const id = action.payload;
  const variables = {
    id,
  };

  const orgFilter = orgGuid ? `ownerOrganization: "${orgGuid}"` : '';

  const query = `
    mutation ${operationName} ($id:ID!) {
      ${op} (
        id: $id
        ${orgFilter}
      ) {
        id
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [op]: string;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[op];
}

export async function checkAuthGroupName(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: string,
  orgGuid?: string
) {
  const operationName = 'authGroup';
  const name = 'authGroup';
  const variables = {
    groupName: input,
  };

  const orgFilter = orgGuid ? `ownerOrganization: "${orgGuid}"` : '';

  const query = `
    query ${operationName} ($groupName: String){
      ${name} (
        groupName: $groupName
        ${orgFilter}
      ) {
        name
      }
    }
  `;

  const { token, graphQLEndpoint: graphEndpoint } = apiConfigs;

  try {
    return await baseGraphQLApi({
      query,
      operationName,
      variables,
      graphEndpoint,
      token,
    });
  } catch (e) {
    console.log(e);
  }
}

export async function checkOrgUserName(apiConfigs: ReturnType<typeof selectApiConfigs>, input: string) {
  const operationName = 'orgUser';
  const op = 'users';
  const variables = {
    name: input,
  };
  const query = `
    query ${operationName} ($name: String){
      ${op} (name: $name status: active) {
        records{
          id
          firstName
          lastName
          email
          imageUrl
        }
      }
    }
  `;

  const token = apiConfigs.token;
  const graphEndpoint = apiConfigs.graphQLEndpoint;
  try {
    const result = await baseGraphQLApi({
      query,
      operationName,
      variables,
      graphEndpoint,
      token,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
}

export async function getGroupById(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: string,
  orgGuid?: string
) {
  const operationName = 'getGroupById';
  const name = 'authGroup';
  const variables = {
    id: input,
  };

  const orgFilter = orgGuid ? `ownerOrganization: "${orgGuid}"` : '';

  const query = `
    query ${operationName} ($id: ID){
      ${name} (
        id: $id
        ${orgFilter}
      ) {
        id
        name
        description
        memberCount
        members {
          count
          records {
            member {
              ... on User {
                id
                email: name
                firstName
                lastName
                imageUrl
              }
              ... on AuthGroup {
                id
                name
                description
                memberCount
                members {
                  count
                }
              }
            }
            createdAt
          }
        }
      }
    }
  `;

  const token = apiConfigs.token;
  const graphEndpoint = apiConfigs.graphQLEndpoint;
  try {
    return await baseGraphQLApi({
      query,
      operationName,
      variables,
      graphEndpoint,
      token,
    });
  } catch (e) {
    console.log(e);
  }
}
