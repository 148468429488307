import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const useStyles = makeStyles()(_theme => ({
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '0.5px solid #E53935',
    borderRadius: '50px',
    width: '570px',
    alignItems: 'center',
    padding: '7px',
  },
  errorMessage: {
    color: '#E53935',
    fontWeight: 400,
  },
  warningIcon: {
    color: '#E53935',
    margin: '0 10px',
  },
}));

export default () => {
  const { classes } = useStyles();

  return (
    <div
      id="dc-search-fallback-error-container"
      className={classes.errorContainer}
      data-testid="dc-search-fallback-error-container"
    >
      <WarningAmberIcon fontSize={'small'} className={classes.warningIcon} />
      <Typography className={classes.errorMessage} variant="body2">
        <FormattedMessage
          id="dc-search-fallback-error-container-text"
          defaultMessage="Something went wrong while searching, please refresh your browser"
          description="Error boundary fallback UI message for the search bar."
        />
      </Typography>
    </div>
  );
};
