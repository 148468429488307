import Box from '@mui/material/Box';
import SHARED_TEXT from '../../../helper/shared-text';
import useStyles from './useStyles';
import { NoLink } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

const NullState = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Icon className={classes.icon}>
        <NoLink fontSize="large" />
      </Icon>
      <Typography variant="body2" className={classes.text}>
        {SHARED_TEXT.nullState()}
      </Typography>
      <Box mb={9} />
    </div>
  );
};

export default NullState;
