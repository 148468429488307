import createSvgIcon from '../createSvgIcon';

export const Text = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 12.75H3V14.25H10.5V12.75ZM15 6.75H3V8.25H15V6.75ZM3 11.25H15V9.75H3V11.25ZM3 3.75V5.25H15V3.75H3Z"
      fill="#555F7C"
    />
  </svg>,
  'Text'
);
