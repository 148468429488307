import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void, 'root'>()(_theme => ({
  root: {},
  container: {
    width: '570px',
    background: 'transparent',
  },
  searchInputContainer: {
    width: '100%',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    fontWeight: 400,
    position: 'relative',
  },
  searchInputContainerDefault: {
    backgroundColor: '#F7F7F7CC',
    border: '0.5px solid #D5DFE9',
  },
  searchInputContainerHover: {
    border: '0.5px solid #A8B2BC',
    '& input::placeholder': {
      opacity: '1 !important',
      color: '#2A323C !important',
    },
  },
  searchInputContainerActive: {
    border: '0.5px solid #0D62D2',
  },
  searchInput: {
    border: '0',
    outline: 'none',
    background: 'transparent',
    width: '83%',
    fontSize: '15px',
    '&::placeholder': {
      opacity: '0.4',
    },
  },
  searchIconContainer: {
    padding: '0px 7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  criteria: {
    marginRight: '10px',
    transform: 'scale(0.8)',
    '&:last-child': {
      marginRight: '0px',
    },
  },
}));
