import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../../useStyles';
import { ITdoVm } from '../../../../types';
import ContextMenuRowButton from '../../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../../types/entity-context-menus';
import GetRowItem from '../../../shared/GetRowItem';
import { EntityType } from '@aiware/js/interfaces';
import { configSelector, selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { mountPanel } from '@aiware/js/panel';
const tdoContextMenu = ENTITY_CONTEXT_MENU[EntityType.Tdos];
const PARENT_PANEL_ID = 'DATA_CENTER';
const DATA_CENTER_MDP_IFRAME_PANEL = 'DATA_CENTER_MDP_IFRAME_PANEL';

const TdoTableRow: FunctionComponent<{
  item: ITdoVm;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const { classes } = useStyles();
  const configs = useSelector(configSelector);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const dispatch = useDispatch();
  const mediaDetailsUrl = `${configs.cmsAppUrl}/embed/#/media-details/${item.id}`;

  const handleOpenMediaDetailsPanel = () => {
    const microFrontend = {
      name: DATA_CENTER_MDP_IFRAME_PANEL,
      config: {
        entityId: item.id,
        iframeUrl: mediaDetailsUrl,
        fileName: item.details?.filename || item.details?.fileName || item.name,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'xlarge',
      parentPanelId: PARENT_PANEL_ID,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: DATA_CENTER_MDP_IFRAME_PANEL,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  return (
    <Grid
      data-test={`${EntityType.Tdos}-row-${item.id}`}
      style={{ height: rowHeight }}
      container
      className={classes.root}
    >
      <Grid
        onClick={() => handleOpenMediaDetailsPanel()}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        item
        container
        xs={11}
      >
        <GetRowItem rowName={'fileType'} item={item} xs={1} itemType={EntityType.Tdos} />
        <GetRowItem rowName={'name'} item={item} itemType={EntityType.Tdos} />
        <GetRowItem rowName={'mediaStartTime'} item={item} xs={3} itemType={EntityType.Tdos} />
        <GetRowItem rowName={'engineIds'} item={item} xs={3} itemType={EntityType.Tdos} />
      </Grid>
      <Grid xs={1} className={classes.padding} item container alignItems="center" justifyContent="flex-end">
        <ContextMenuRowButton contextMenu={tdoContextMenu} entityId={item.id} type={EAuthResourceType.TDO} />
      </Grid>
    </Grid>
  );
};
export default TdoTableRow;
