import { SxProps } from '@mui/system';

export const styles: Record<string, SxProps> = {
  root: {
    userSelect: 'none',
    height: `calc(100vh - 55px - 5px)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
};

export default styles;
