import { combineReducers } from 'redux';
import { SLICE_NAME } from '../../constants';
import * as organizationsState from './organizations.state';
import * as organizationInvitesState from './organizationInvites.state';
import * as reviewRequestState from './reviewRequest.state';
import * as scimIdsState from './scimIds.state';

export const namespace = SLICE_NAME;

export const initialState = {
  [organizationsState.namespace]: organizationsState.initialState,
  [organizationInvitesState.namespace]: organizationInvitesState.initialState,
  [reviewRequestState.namespace]: reviewRequestState.initialState,
  [scimIdsState.namespace]: scimIdsState.initialState,
};

export const actions = {
  [organizationsState.namespace]: organizationsState.actions,
  [organizationInvitesState.namespace]: organizationInvitesState.actions,
  [reviewRequestState.namespace]: reviewRequestState.actions,
  [scimIdsState.namespace]: scimIdsState.actions,
};

export const rootReducer = combineReducers({
  [organizationsState.namespace]: organizationsState.default,
  [organizationInvitesState.namespace]: organizationInvitesState.default,
  [reviewRequestState.namespace]: reviewRequestState.default,
  [scimIdsState.namespace]: scimIdsState.default,
});
