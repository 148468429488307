import { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import useStyles from './useStyles';
import { SHARED_TEXT } from '../../helpers/shared-text';

const InviteRequestsListActions: FunctionComponent<{
  onApproveAll: () => void;
  onRejectAll: () => void;
}> = ({ onApproveAll, onRejectAll }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.inviteRequestsListActions}>
      <Button
        sx={{ mr: 2, color: '#0C53B0' }}
        onClick={onApproveAll}
        data-testid="org-invite-requests-approve-all"
      >
        {SHARED_TEXT.approveAll}
      </Button>

      <Button sx={{ color: '#0C53B0' }} onClick={onRejectAll} data-testid="org-invite-requests-reject-all">
        {SHARED_TEXT.rejectAll}
      </Button>
    </div>
  );
};

export default InviteRequestsListActions;
