import { AiwareFrameLinkContext } from './frame-link-context';
import { type Callback, EFrameLinkEvents, errors } from './constants';
import { type IntlShape } from 'react-intl';
import { EMessageTypes, type TContext, type TResponse, Utilities } from './constants';
import { handleMessage } from './handle-message';
import { postMessage } from './post-message';
import { healthMonitoring, healthReporting } from './health';
import { reportActivity } from './activity';
import { subscribe } from './subscribe';
import { init } from './init';
import { useUtility } from './use-utility';
import { type IModuleStore } from 'redux-dynamic-modules-core';
import { isIFrame } from './helpers';

class AiwareFrameLink {
  isChild: boolean;
  origin: string | undefined;
  context: TContext;
  contextReceivedResolve: null | ((value: void | PromiseLike<void>) => void);
  contextReceived: Promise<void>;
  initialized: boolean;
  // subscribers need to be passed in into the static init to make sure they run when the context is received by the child frame
  subscribers: ((ct: TContext) => any)[];
  responses: { [key: string]: TResponse };
  // map of messages that either frame type (child/parent) can send
  canSendMap: { parent: EMessageTypes[]; child: EMessageTypes[]; common: EMessageTypes[] };
  /* this function can be set by parent and is used to inform the user about the error that happened either in the parent or in the child app */
  errorAction?: (x: any) => void;
  // intl is passed from the parent frame to translate error messages
  intl?: IntlShape;
  store?: IModuleStore<unknown>;

  // healthcheck interval in ms
  heartbeatInterval: number;
  // timestamp when the last heartbeat was received
  heartbeatReceived: number;
  // TODO: interval should be passed with the context
  interval: any;
  events: Map<EFrameLinkEvents, Callback>;

  constructor(origin?: string, heartbeatInterval?: number) {
    this.initialized = false;
    this.canSendMap = {
      parent: [EMessageTypes.initContext, EMessageTypes.updateContext],
      child: [
        EMessageTypes.getInitContext,
        EMessageTypes.reportActivity,
        EMessageTypes.utility,
        EMessageTypes.heartbeat,
      ],
      common: [EMessageTypes.error, EMessageTypes.response],
    };
    // If no origin is given, assume this instance is for a child frame
    this.isChild = !origin;
    // The origin of the window to communicate with
    this.origin = origin;
    // Store initial context data. This is updated when a 'context' message is received
    this.context = {
      // environment: null, // TODO: this requires careful assessment of how we can derive an environment from the variety of URLs we can be seeing
      baseUrl: null,
      authToken: null,
      // theme: null, // TODO: there's no such thing as global theme support in SDK at the moment
      language: null,
      userId: null,
      organizationId: null,
    };
    // This promise resolves when the 'context' message is received
    this.contextReceivedResolve = null;
    this.contextReceived = new Promise(resolve => {
      this.contextReceivedResolve = resolve;
    });
    this.subscribers = [];

    //  Map to store response data by request ID
    this.responses = {};

    this.heartbeatInterval = heartbeatInterval || 5000;
    this.heartbeatReceived = new Date().getTime();
    this.events = new Map();
    // Initialize the instance
    this.init();
  }

  on(event: EFrameLinkEvents, cb: Callback) {
    if (!EFrameLinkEvents[event]) {
      throw new Error(`Unknown event type: \`${event}\``);
    }

    this.events.set(event, function (error?: Error | null, ...args: unknown[]) {
      cb(error, ...args);
    });
  }

  dispatch(event: EFrameLinkEvents, error?: Error | null, ...args: unknown[]) {
    if (!this.events.has(event)) {
      return;
    } else {
      this.events.get(event)?.(error, ...args);
    }
  }

  static async init({
    origin,
    subscribers,
    errorAction,
    intl,
    store,
  }: {
    origin?: string;
    subscribers?: ((ct: TContext) => any)[];
    errorAction?: (x: any) => void;
    intl?: IntlShape;
    store?: IModuleStore<unknown>;
  }) {
    // Create an instance and wait for the 'context' message to be received
    const aiwareBus = new AiwareFrameLink(origin);
    errorAction && (aiwareBus.errorAction = errorAction);
    store && (aiwareBus.store = store);
    if (subscribers && subscribers.length) {
      for (const subscriber of subscribers) {
        aiwareBus.subscribe(subscriber);
      }
    }

    // context is only required when initiating a child
    if (!origin) {
      await aiwareBus.contextReceived;
      aiwareBus.initialized = true;
    } else {
      intl && (aiwareBus.intl = intl);
    }
    // Once the 'context' message is received, return the instance
    return aiwareBus;
  }

  init = init.bind(this);

  handleMessage = handleMessage.bind(this);
  postMessage = postMessage.bind(this);

  healthReporting = healthReporting.bind(this);
  healthMonitoring = healthMonitoring.bind(this);

  subscribe = subscribe.bind(this);
  reportActivity = reportActivity.bind(this);

  useUtility = useUtility.bind(this);
}

export {
  AiwareFrameLink,
  type TContext,
  EMessageTypes,
  Utilities,
  AiwareFrameLinkContext,
  errors,
  EFrameLinkEvents,
  isIFrame,
};
