import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  container: {
    padding: '12px 20px',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start', //'space-evenly',
    borderBottom: '0.5px solid #D5DFE9',
    opacity: 0.9,
  },
}));
