import { FormattedMessage } from 'react-intl';

export const AiSearchText = {
  showFilterPanelText: () => (
    <FormattedMessage
      id="dc-ai-search-results-show-filter-icon-tooltip-text"
      defaultMessage="Show filters"
      description="Show filter"
    />
  ),
  hideFilterPanelText: () => (
    <FormattedMessage
      id="dc-ai-search-results-hide-filter-icon-tooltip-text"
      defaultMessage="Clear filters"
      description="Clear filters"
    />
  ),
  clearLabelText: () => (
    <FormattedMessage
      id="dc-ai-search-results-filter-input-clear-button"
      defaultMessage="Clear"
      description="Search Filter input Clear butoon"
    />
  ),
  filterLabelDate: () => (
    <FormattedMessage
      id="dc-ai-search-results-filter-label-text-date"
      defaultMessage="Start Date"
      description="Search Filter input label Date"
    />
  ),
  filterLabelEngineType: () => (
    <FormattedMessage
      id="dc-ai-search-results-filter-label-text-engine-type"
      defaultMessage="AI Engine Type"
      description="Search Filter input label Engine Type"
    />
  ),
  filterLabelFileType: () => (
    <FormattedMessage
      id="dc-ai-search-results-filter-label-text-file-type"
      defaultMessage="File Type"
      description="Search Filter input label File Type"
    />
  ),
  filterLabelSourceSchedule: () => (
    <FormattedMessage
      id="dc-ai-search-results-filter-label-text-source-schedule"
      defaultMessage="Source/Schedule"
      description="Search Filter input label Source Schedule"
    />
  ),
};

export const dateMenuItemIntl = {
  any: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.modified-date-selection.any"
      defaultMessage="Any Time"
      description="Search Filter by Date, any time"
    />
  ),
  today: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.modified-date-selection.today"
      defaultMessage="Today"
      description="Search Filter by Date, today"
    />
  ),
  '24hour': (
    <FormattedMessage
      id="os-data-center-browse.search-filter.modified-date-selection.last24hour"
      defaultMessage="Last 24 Hours"
      description="Search Filter by Date, last 24 hours"
    />
  ),
  '7day': (
    <FormattedMessage
      id="os-data-center-browse.search-filter.modified-date-selection.last7day"
      defaultMessage="Last 7 Days"
      description="Search Filter by Date, last 7 days"
    />
  ),
  '30day': (
    <FormattedMessage
      id="os-data-center-browse.search-filter.modified-date-selection.last30day"
      defaultMessage="Last 30 Days"
      description="Search Filter by Date, last 30 days"
    />
  ),
};

export const sourceScheduleFilterText = {
  saveSelection: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.save-selection"
      defaultMessage="Save Selection"
      description="Search Filter by Source Schedule, button Save Selection"
    />
  ),
  selectNoSchedules: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.select-no-schedules"
      defaultMessage="Select No Schedules"
      description="Search Filter by Source Schedule, button Select No Schedules"
    />
  ),
  selectOneSchedule: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.select-one-schedule"
      defaultMessage="Select 1 Schedule"
      description="Search Filter by Source Schedule, button Select 1 Schedule"
    />
  ),
  selectXSchedules: (scheduleCount: number) => (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.select-x-schedules"
      defaultMessage="Select {scheduleCount} Schedules"
      description="Search Filter by Source Schedule, button Select X number of Schedules"
      values={{
        scheduleCount,
      }}
    />
  ),
  removeSchedules: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.remove-schedules"
      defaultMessage="Remove Schedules"
      description="Search Filter by Source Schedule, tooltip message Remove Schedules"
    />
  ),
  selectAllSchedules: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.select-all-schedules"
      defaultMessage="Select All Schedules"
      description="Search Filter by Source Schedule, tooltip message Select All Schedules"
    />
  ),
  hideSchedules: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.hide-schedules"
      defaultMessage="Hide Schedules"
      description="Search Filter by Source Schedule, tooltip message Hide Schedule"
    />
  ),
  showSchedules: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.show-schedules"
      defaultMessage="Show Schedules"
      description="Search Filter by Source Schedule, tooltip message Show Schedules"
    />
  ),
  loadingSources: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.loading-sources"
      defaultMessage="Loading Sources"
      description="Search Filter by Source Schedule, loader message Loading Sources"
    />
  ),
  loadingSchedules: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.loading-schedules"
      defaultMessage="Loading Schedules"
      description="Search Filter by Source Schedule, loader message Loading Schedules"
    />
  ),
  retryButtonText: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.retry-button"
      defaultMessage="Retry"
      description="text for button to allow retry of failed api call"
    />
  ),
  schedulesErrorMessageText: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.schedules-error"
      defaultMessage="There was an error loading the schedules"
      description="text for error with schedule fetching"
    />
  ),
  sourcesErrorMessageText: (
    <FormattedMessage
      id="os-data-center-browse.search-filter.source-schedule-selection.sources-error"
      defaultMessage="There was an error loading the sources"
      description="text for error with source fetching"
    />
  ),
};
