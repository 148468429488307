import { EUserStatus } from '../lib/invite-panel';

export const lads = {
  Mert: 'https://avatars.githubusercontent.com/u/51537954',
  Tony: 'https://avatars.githubusercontent.com/u/51537954',
};

const commonProps = {
  removeUser: () => ({}),
  applicationsAction: () => ({}),
};

export const existingUserProps = {
  firstName: 'Tony',
  lastName: 'Gaglio',
  email: 'tony@veritone.com',
  avatar: lads.Tony,
  userStatus: EUserStatus.existing,
  applications: ['someApp'],
  ...commonProps,
};

export const existingUserNoAvatarProps = {
  firstName: 'Richard',
  lastName: 'Vance',
  email: 'richard@veritone.com',
  avatar: '',
  userStatus: EUserStatus.existing,
  applications: ['someApp', 'anotherApp'],
  ...commonProps,
};

export const newUserProps = {
  firstName: '',
  lastName: '',
  email: 'john@veritone.com',
  avatar: lads.Tony,
  userStatus: EUserStatus.new,
  ...commonProps,
};

export const pendingUserProps = {
  firstName: 'Mert',
  lastName: 'Hotdog',
  email: 'mert@veritone.com',
  avatar: lads.Mert,
  userStatus: EUserStatus.pending,
  ...commonProps,
};
export const applicationMock = {
  name: 'Data Center',
  iconUrl: 'https://www.filepicker.io/api/file/U3eW5m20QBCCG1GJh1RE',
};

export const roleMock = {
  role: 'Editor',
};

// TODO: adjust fields appropriately with the API contract
export const INVITE_REQUEST_ITEMS: Array<any> = [
  {
    id: '1',
    avatar: 'https://avatars.githubusercontent.com/u/20645060',
    name: 'Hamit Tokay',
    email: 'htokay@veritone.com',
    userStatus: EUserStatus.existing,
    requestor: 'Lance Gorji',
    requestedApps: [{ application: applicationMock, role: roleMock }],
  },
  {
    id: '2',
    avatar: 'https://avatars.githubusercontent.com/u/51537954',
    name: 'Mert Nacakgedigi',
    email: 'mnacakgedigi@veritone.com',
    userStatus: EUserStatus.new,
    requestor: 'Lance Gorji',
    requestedApps: [{ application: applicationMock, role: roleMock }],
  },
  {
    id: '3',
    avatar: 'https://avatars.githubusercontent.com/u/74932289',
    name: 'Alex Oleksiiuk',
    email: 'aoleksiiuk@veritone.com',
    userStatus: EUserStatus.pending,
    requestor: 'Lance Gorji',
    requestedApps: [{ application: applicationMock, role: roleMock }],
  },
];
