import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

export const navSectionStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableWrapper: {
    flexGrow: 1,
    overflowY: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    '& button svg': {
      marginRight: '5px',
      height: '14px',
    },
  },
  startToolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    '& button svg': {
      marginRight: '5px',
    },
  },
}));
