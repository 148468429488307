import { createAction } from '@reduxjs/toolkit';
import { FolderId, ScheduleId, TdoId, SLICE_NAME, ITdo } from '../../../types';

export const namespace = 'tdos';

export const actions = {
  deleteTdoStart: createAction<TdoId>(`${SLICE_NAME}/${namespace}/deleteTdoStart`),
  moveTdoStart: createAction<TdoId>(`${SLICE_NAME}/${namespace}/moveTdoStart`),
  moveTdoNewFolder: createAction<{
    tdo: ITdo;
    selectedFolderId?: FolderId | null;
  }>(`${SLICE_NAME}/${namespace}/moveTdoNewFolder`),
  moveMultipleTDOsNewFolder: createAction<{
    tdos: ITdo[];
    selectedFolderId?: FolderId | null;
  }>(`${SLICE_NAME}/${namespace}/moveMultipleTdosNewFolder`),
  moveTdoNewFolderSucceeded: createAction<{
    tdoId: TdoId;
    newParentFolderId?: FolderId | null;
  }>(`${SLICE_NAME}/${namespace}/moveTdoNewFolderSucceeded`),
  process: createAction<TdoId>(`${SLICE_NAME}/${namespace}/process`),
  viewInfo: createAction<TdoId>(`${SLICE_NAME}/${namespace}/viewInfo`),
  editMetadata: createAction<TdoId>(`${SLICE_NAME}/${namespace}/editMetadata`),
  deleteTdoSucceeded: createAction<{
    tdoId: TdoId;
    parentFolderId?: FolderId | null;
    parentScheduleId?: ScheduleId;
  }>(`${SLICE_NAME}/${namespace}/deleteTdoSucceeded`),
  deleteTdoFailed: createAction<TdoId>(`${SLICE_NAME}/${namespace}/deleteTdoFailed`),
  download: createAction<TdoId>(`${SLICE_NAME}/${namespace}/download`),
};
