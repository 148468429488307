import { baseGraphQLApi, gqlIntrospection } from '@aiware/shared/redux';

export const getMailboxApi = async (graphEndpoint: string, token?: string): Promise<unknown> => {
  const query = `
    {
      notificationMailboxes {
        id
        name
        unseenCount
        totalCount
        eventFilter{
          applicationId
          eventNames
          eventType
        }
      }
  }`;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getNotificationByIdApi = async (
  appId: string,
  graphEndpoint: string,
  token?: string
): Promise<unknown> => {
  const unsafeQuery = `
  {
    applications(accessScope: [granted, owned], id:"${appId}"){
      records {
        id
        name
        description
        iconUrl
        iconSvg
      }
    }
  }`;

  let query;
  const processedQuery = await gqlIntrospection.queryParser(graphEndpoint, token!, unsafeQuery);
  if (!processedQuery || !processedQuery?.query) {
    query = unsafeQuery;
  } else {
    query = processedQuery.query;
  }

  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    console.error(error);
  }
  return;
};

export const handleMarkAllNotificationsSeen = async (
  mailBoxIds: string[],
  graphEndpoint: string,
  token?: string
) => {
  const query = `
    mutation markAllNotificationsSeen($mailBoxIds: [ID]!) {
      markAllNotificationsSeen(mailboxIds: $mailBoxIds) {
        totalCount
        unseenCount
      }
    }
    `;

  const variables = {
    mailBoxIds,
  };
  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const pushDefaultMailboxNotification = async (
  userId: string,
  actionType: string,
  graphEndpoint: string,
  token: string
): Promise<unknown> => {
  const query = `
  mutation createNotification {
    notificationPost(
      input: {
        mailboxIds: "${userId}"
        body: "${userId}"
        title: "${actionType}"
        contentType: "action"
        flags: [unread, unseen]
      }
    ) {
      id
    }
  }`;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    console.error(error);
  }
  return;
};

export const getUnseenCountApi = async (graphEndpoint: string, token?: string): Promise<unknown> => {
  const query = `
    {
      notificationMailboxes {
        id
        unseenCount
      }
  }`;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    return {
      status: 'failure',
    };
  }
};
