import { TUserAppGroup } from '../../../store/modules/app-groups';
import { Grid } from '@mui/material';
import { Runway } from '.';
import AppIcon from '../../atoms/AppIcon';
import { IApplication } from '../../../types';

type TProps = {
  groupApps: IApplication[];
  appGroup: TUserAppGroup;
  classes: any;
  snapshot: any;
  handleSelectApplication: (id: string | number) => void;
  currentSelected: string | number | null;
};

export const GroupApps = ({
  groupApps,
  appGroup,
  classes,
  snapshot,
  handleSelectApplication,
  currentSelected,
}: TProps) => {
  return (
    <Grid
      container
      sx={{ paddingLeft: '10px', position: 'relative', marginBottom: '15px' }}
      data-testid={`app-switcher.app-group.${appGroup.id}`}
    >
      {snapshot.isDraggingOver ? <Runway classes={classes} /> : null}
      {groupApps.map((app: IApplication, index: number) => {
        const { id = index, iconUrl, name } = app;
        return (
          <Grid
            item
            xs={4}
            className={classes.appTile}
            key={id}
            sx={{ padding: 0 }}
            data-testid={`app-switcher.app-group.app-tile.${id}`}
          >
            <AppIcon
              type="switcherGrid"
              selected={id === currentSelected}
              onClick={handleSelectApplication}
              iconUrl={iconUrl}
              id={id}
              appName={name}
              appListV2={true}
              isAppGroup={true}
              appGroupId={appGroup.id}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
