import { FunctionComponent } from 'react';
import Icon from '@mui/material/Icon';
import { RadioSource, PodcastSource, TVSource, YoutubeSource, Undefined } from '@aiware/shared/icons';

const IconSourceHelper: FunctionComponent<{
  sourceTypeId: string;
  className?: string;
}> = ({ sourceTypeId, className }) => {
  let sourceIcon;
  switch (sourceTypeId) {
    case '1':
      sourceIcon = RadioSource;
      break;
    case '2':
      sourceIcon = TVSource;
      break;
    case '3':
      sourceIcon = YoutubeSource;
      break;
    case '4':
      sourceIcon = PodcastSource;
      break;
    default:
      sourceIcon = Undefined;
  }

  return <Icon data-test={'sourceTypeId-icon'} className={className} component={sourceIcon} />;
};

export default IconSourceHelper;
