import { takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from '../../slices';

import { addMetadataToFile, closeFileMetadataPanel } from './fileItemMeta.saga';

export function* watchSetFileMetadata() {
  yield takeLatest(actions.filesSelectedState.addMetadataAction.type, addMetadataToFile);
}

export function* watchFileMetadataSuccess() {
  yield takeEvery(actions.uiState.fileMetaSaveSucceeded.type, closeFileMetadataPanel);
}
