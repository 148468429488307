import { useState, useRef, ChangeEvent } from 'react';
import { tableSearchComponents } from '@aiware/shared/reusable-utils';
import debounce from 'lodash/debounce';

export interface ApplicationSearchProps {
  onChange: (value: string) => void;
}

export const ApplicationSearch = ({ onChange }: ApplicationSearchProps) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedSearch = useRef(
    debounce((debounceSearchValue: string) => {
      onChange(debounceSearchValue);
    }, 1000)
  ).current;
  const [searchValue] = useState('');
  const [isSearching] = useState(false);
  const { TableSearchInputButton } = tableSearchComponents;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (event.target.value) {
      // dispatch(actions.setIsSearching(true));
      debouncedSearch(event.target.value);
    } else {
      debouncedSearch('');
    }
  };

  const handleClear = () => {
    setInputValue('');
    debouncedSearch('');
  };

  return (
    <TableSearchInputButton
      onChange={handleChange}
      onClear={handleClear}
      testId="add-application-search"
      placeholderText="Search by application name"
      searchValue={searchValue}
      inputValue={inputValue}
      isSearchVisible={true}
      isSearching={isSearching}
      searchItemTooltipText="Application Name"
    />
  );
};

export default ApplicationSearch;
