import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import useStyles from './useStyles';
import SHARED_TEXT from '../../../../../helper/shared-text';

interface IOpenIdConnectorButtonProps {
  isConnected: boolean;
  onLink: () => void;
  onUnlink: () => void;
}

const OpenIdConnectorButton = ({ isConnected, onLink, onUnlink }: IOpenIdConnectorButtonProps) => {
  const { classes } = useStyles();
  return (
    <div>
      {isConnected ? (
        <Button
          // we'll implement unlinking later, hide for now
          className={`${classes.linkBtn} ${classes.displayNone}`}
          variant="text"
          data-test="account-linking-unlink-btn"
          onClick={onUnlink}
        >
          {SHARED_TEXT.buttonUnlink()}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className={classes.linkBtn}
          data-test="account-linking-link-btn"
          onClick={onLink}
        >
          <LinkIcon className={classes.linkIcon} />
          {SHARED_TEXT.buttonLink()}
        </Button>
      )}
    </div>
  );
};

export default OpenIdConnectorButton;
