import { useMemo, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { Box, Grid } from '@mui/material';

import { SelectField, DateField, TextField } from './components/FormFields';
import { DefaultSubmitButton } from './SubmitButton';
import { organizeFieldsIntoRows } from './utils/grid';
import { useFormGenerator } from './hooks/useFormGenerator';

import type { FormGeneratorFieldRenderProps, FormGeneratorFieldWithKey, FormGeneratorProps } from './types';

export const FormGenerator: React.FC<FormGeneratorProps> = ({
  schema,
  additionalFields = [],
  fieldsPerRow = 2,
  onSubmit,
  loading = false,
  submitText = 'Submit',
  renderSubmitButton,
  defaultValues,
  formOptions,
  sx = {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useFormGenerator({ formOptions, defaultValues });

  const requiredFields = useMemo(() => {
    const required = schema?.required ?? [];

    return [...required, ...additionalFields.filter(field => field.required).map(field => field.fieldKey)];
  }, [schema?.required, additionalFields]);

  const sortedFields = useMemo(() => {
    // Convert schema properties to array of fields
    const schemaFields = Object.entries(schema?.properties ?? {}).map(
      ([key, value]): FormGeneratorFieldWithKey => ({
        fieldKey: key,
        ...value,
      })
    );

    // Merge additional fields with schema fields
    const allFields = [
      ...additionalFields.map((field, index) => ({
        ...field,
        sequence: field.sequence ?? -1000 + index,
      })),
      ...schemaFields,
    ] as FormGeneratorFieldWithKey[];

    // Sort all fields by sequence
    return allFields.sort((a, b) => a.sequence - b.sequence);
  }, [schema?.properties, additionalFields]);

  const rows = useMemo(
    () => organizeFieldsIntoRows(sortedFields, fieldsPerRow),
    [sortedFields, fieldsPerRow]
  );

  const renderFormField = (field: FormGeneratorFieldWithKey) => {
    const name = camelCase(field.title);
    const fieldProps: FormGeneratorFieldRenderProps = {
      name,
      field,
      required: requiredFields,
      control,
      errors,
    };

    const fieldComponents = {
      text: TextField,
      email: TextField,
      number: TextField,
      select: SelectField,
      date: DateField,
    };

    const FieldComponent = fieldComponents[field.type];
    return FieldComponent ? <FieldComponent {...fieldProps} /> : null;
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={sx}>
      {rows.map((row, rowIndex) => (
        <Grid container spacing={2} key={rowIndex} sx={{ mb: 3 }}>
          {row.map(field => (
            <Grid item xs={12} sm={row.length === 1 ? 12 : 12 / fieldsPerRow} key={field.fieldKey}>
              {renderFormField(field)}
            </Grid>
          ))}
        </Grid>
      ))}

      {renderSubmitButton ? (
        renderSubmitButton({ isSubmitting, isDirty, isValid })
      ) : (
        <DefaultSubmitButton
          isSubmitting={isSubmitting}
          loading={loading}
          isDirty={isDirty}
          isValid={isValid}
          submitText={submitText}
        />
      )}
    </Box>
  );
};

export * from './types';
