import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';
import { IFilesSelected, ImporterView, SLICE_NAME } from '../../types';
import isEqual from 'lodash/isEqual';
import { BrowseModule } from '@aiware/shared/redux';

export const namespace = 'uiState';

export type State = {
  status: LoadingStatus;
  currentView: ImporterView;
  browseComponent: BrowseModule;
};

export const initialState: State = {
  status: 'idle',
  currentView: ImporterView.browseFiles,
  browseComponent: BrowseModule.DataCenter,
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    setBrowseComponent(state, { payload }: PayloadAction<BrowseModule>) {
      state.browseComponent = payload;
    },
    setCurrentView(state, { payload }: PayloadAction<ImporterView>) {
      state.currentView = payload;
    },
    fileUploadFailed(state) {
      state.status = 'failure';
    },
    fileUploadSucceed(state) {
      state.status = 'idle';
    },
    fileUploadStart(state) {
      state.status = 'pending';
    },
    fileUploadAbortFailed(state) {
      state.status = 'failure';
    },
    tdoCreateStart(
      state,
      {
        payload,
      }: PayloadAction<{
        filesSelected: IFilesSelected;
        useDefaultRootFolder?: boolean;
        skipThumbnail?: boolean;
      }>
    ) {
      state.status = 'pending';
      isEqual(process.env.NODE_ENV, 'development') &&
        console.log('Starting to create TDOs for: ', payload.filesSelected.map(file => file.id).join('\n'));
    },
    tdoCreateSucceed(state) {
      state.status = 'idle';
    },
    tdoCreateFailed(state) {
      state.status = 'failure';
    },
    retryTDOCreateForFiles(state, { payload }: PayloadAction<{ filesSelected: IFilesSelected }>) {
      state.status = 'pending';
      isEqual(process.env.NODE_ENV, 'development') &&
        console.log('Retrying to create TDOs for: ', payload.filesSelected.map(file => file.id).join('\n'));
    },
    fileMetaSaveSucceeded(state) {
      state.status = 'idle';
    },
    fileMetaSaveFailed(state) {
      state.status = 'failure';
    },
    fileMetaSaveIsProcessing(state) {
      state.status = 'pending';
    },
    tdoPermissionsSucceeded(state) {
      state.status = 'idle';
    },
    tdoPermissionsFailed(state) {
      state.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
