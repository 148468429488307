import { put, take, takeEvery } from 'redux-saga/effects';
import { actions } from '../../slices';
import { abortFileUpload, filesSelected, fileUploadChannel } from './localFileUpload.saga';

export function* watchFilesSelected() {
  yield takeEvery(actions.filesSelectedState.setFilesSelected.type, filesSelected);
}

export function* watchRetryFileUpload() {
  yield takeEvery(actions.filesSelectedState.retryFileUpload.type, filesSelected);
}

export function* watchProgressChannel() {
  while (true) {
    //@ts-ignore
    const action = yield take(fileUploadChannel);
    yield put(action);
  }
}

export function* watchFileAbort() {
  yield takeEvery(actions.filesSelectedState.abortFileUpload.type, abortFileUpload);
}
