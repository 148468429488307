import { FunctionComponent, useEffect, useState } from 'react';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { makeStyles } from 'tss-react/mui';
import { ReviewRequestPanelHeader } from './ReviewRequestPanelHeader';
import { ReviewRequestOrganizationLabel } from './ReviewRequestOrganizationLabel';
import { IInviteAuthor, ReviewRequestInviteAuthor } from './ReviewRequestInviteAuthor';
import { ReviewRequestMessage } from './ReviewRequestMessage';
import { ReviewRequestActionFooter } from './ReviewRequestActionFooter';
import { Typography, Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/slices';
import { TUpdateOrganizationInviteInput, EOrganizationInviteAction } from '../../types';
import { selectActiveOrganizationInvite } from '../../redux/selectors/organizations.selector';

const useStyles = makeStyles<void, 'root'>()(theme => ({
  root: {},
  panelContent: {
    padding: theme.spacing(0, 6, 0, 6),
  },
  divider: {
    borderBottom: '0.5px solid #D5DFE9',
    height: '1px',
    opacity: 0.5,
  },
  disclaimerText: {
    color: '#2A323C',
    marginTop: '25px',
  },
  skeletonContainer: {
    width: '90%',
    marginTop: '25px',
  },
}));

const initialInviteState = {
  organizationName: undefined,
  organizationLogo: undefined,
};
const initialInviteAuthor = {
  name: undefined,
  email: undefined,
  photo: undefined,
};

export const ReviewRequestPanel: FunctionComponent = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [{ organizationName, organizationLogo }, setOrganizationInvite] = useState<
    | {
        organizationName: string;
        organizationLogo: string;
      }
    | {
        organizationName: undefined;
        organizationLogo: undefined;
      }
  >(initialInviteState);
  const [inviteAuthor, setInviteAuthor] = useState<IInviteAuthor>(initialInviteAuthor);
  const [inviteMessage, setInviteMessage] = useState<string | null>(null);
  const activeInvite = useSelector(selectActiveOrganizationInvite)!;

  const handleRejectInvite = () => {
    const orgInfo: TUpdateOrganizationInviteInput = {
      organizationInviteId: activeInvite.organizationInviteId,
      applicationRoles: activeInvite.applicationRoles,
      action: EOrganizationInviteAction.reject,
    };

    dispatch(actions.reviewRequestState.updateOrganizationInviteStart(orgInfo));
  };

  const handleAcceptInvite = () => {
    const orgInfo: TUpdateOrganizationInviteInput = {
      organizationInviteId: activeInvite.organizationInviteId,
      applicationRoles: activeInvite.applicationRoles.map(appRole => ({
        applicationId: appRole.application!.id,
        roleId: appRole.role!.id,
      })),
      action: EOrganizationInviteAction.complete,
    };
    dispatch(actions.reviewRequestState.updateOrganizationInviteStart(orgInfo));
  };

  useEffect(() => {
    if (!activeInvite) return;
    const { message, organization, createdBy } = activeInvite;
    const { name: activeInviteOrganizationName, imageUrl: activeInviteOrganizationLogo } = organization!;
    const { name: email, firstName, lastName } = createdBy!;
    const { imageUrl: authorPhoto } = createdBy!;

    // Organization Invite Object
    setOrganizationInvite({
      // TODO: Replace this object with backend data
      organizationName: activeInviteOrganizationName!,
      organizationLogo: activeInviteOrganizationLogo!,
    });
    const inviteAuthorName = !firstName && !lastName ? email : `${firstName} ${lastName}`;
    // Author of the invitation
    setInviteAuthor({
      name: inviteAuthorName,
      email,
      photo: authorPhoto!,
    });

    // Invite Author's Message to the invitee
    setInviteMessage(message!);
  }, [activeInvite]);
  return (
    <AIWareThemeProvider>
      <div className={classes.root} data-test="org-icon">
        <ReviewRequestPanelHeader height={64} />
        <div className={classes.panelContent}>
          <ReviewRequestOrganizationLabel
            organizationName={organizationName!}
            organizationLogo={organizationLogo!}
          />
          <div className={classes.divider}></div>
          <ReviewRequestInviteAuthor author={inviteAuthor} organizationName={organizationName!} />
          {inviteMessage && <ReviewRequestMessage message={inviteMessage} />}
          <div className={classes.divider}></div>
          {organizationName === null && (
            <div className={classes.skeletonContainer}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
          )}
          {organizationName !== null && (
            <div className={classes.disclaimerText}>
              <Typography variant="subtitle1">
                <FormattedMessage
                  id="os-organization-panel.review-request-panel.disclaimer-text"
                  defaultMessage="If you choose to accept this invitation, you will be granted access to specific applications and data available inside this Organization. You can switch between Organizations at anytime by using your Organization setting tool."
                  description="Review Request Panel disclaimer text"
                />
              </Typography>
            </div>
          )}
          <ReviewRequestActionFooter
            onReject={handleRejectInvite}
            onAccept={handleAcceptInvite}
            isDisabled={organizationName === null}
          />
        </div>
      </div>
    </AIWareThemeProvider>
  );
};
