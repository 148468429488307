import { ChangeEvent, FunctionComponent, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Tabs, Tab, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Search, Source, User, DataRegistry } from '@aiware/shared/icons';
import { betaFeaturesSelector, DataCenterView } from '@aiware/shared/redux';
import MyFilesView from './my-files-view/';
import SourcesView from './sources-view/';
import { AISearchResultsView } from '../Search/components/AiSearch';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTab, selectCurrentView } from '../../redux/selectors/ui-state';
import { actions } from '../../redux/slices';
import { selectIsOpen } from '../Search/state/aiAutocomplete/aiAutocomplete.selectors';
import { EResultsViewAction } from '../Search/components/AiSearch/AISearchResultsView';
import { DataRegistryView } from '@aiware/os/data-center/sdo';

interface INavListTab {
  id: DataCenterView;
  label: JSX.Element;
  testLabel: string;
  icon: JSX.Element;
  content: JSX.Element;
  hide: boolean;
  isBehindBeta: boolean;
}

const navList: INavListTab[] = [
  {
    id: DataCenterView.myFiles,
    label: (
      <FormattedMessage
        id="os-data-center-browse.tabItem.myFiles"
        defaultMessage="My Files"
        description="The label of my files tab button."
      />
    ),
    testLabel: 'my-files',
    icon: <User />,
    content: <MyFilesView />,
    hide: false,
    isBehindBeta: false,
  },
  {
    id: DataCenterView.sdo,
    label: (
      <FormattedMessage
        id="os-data-center-browse.tabItem.data-registry"
        defaultMessage="Data Registries"
        description="The label of data registries tab button."
      />
    ),
    testLabel: 'data-registry',
    icon: <DataRegistry />,
    content: <DataRegistryView />,
    hide: false,
    isBehindBeta: false,
  },
  {
    id: DataCenterView.sourceTree,
    label: (
      <FormattedMessage
        id="os-data-center-browse.tabItem.streams"
        defaultMessage="Sources"
        description="The label of source tab button."
      />
    ),
    testLabel: 'sources',
    icon: <Source />,
    content: <SourcesView />,
    hide: false,
    isBehindBeta: false,
  },
  {
    id: DataCenterView.folders,
    label: (
      <FormattedMessage
        id="os-data-center-browse.tabItem.folders"
        defaultMessage="My Folders"
        description="The label of my folders tab button."
      />
    ),
    testLabel: 'my-folders',
    icon: <User />,
    content: <MyFilesView />,
    hide: true,
    isBehindBeta: false,
  },
  {
    id: DataCenterView.search,
    label: (
      <FormattedMessage
        id="os-data-center-browse.tabItem.search"
        defaultMessage="Search"
        description="The label of search tab button."
      />
    ),
    testLabel: 'search',
    icon: <Search />,
    content: <AISearchResultsView hideHeaderActions={[EResultsViewAction.SORT, EResultsViewAction.INFO]} />,
    hide: true, // This prevents the "Search" button from appearing inside DataCenter's Tabs
    isBehindBeta: false,
  },
];

const filterOutTab = (tab: INavListTab, isBetaEnabled: boolean) => {
  let result = true;

  // Remove Tabs behind beta or hidden tabs
  if ((!isBetaEnabled && tab.isBehindBeta) || tab.hide) {
    result = false;
  }

  return result;
};

const tabWidth = 187;

const useStyles = makeStyles<{ height: string | number }, 'tabRoot' | 'tabWrapper'>()(
  (theme: Theme, { height }, classes) => ({
    root: {
      display: 'flex',
      flexGrow: 1,
      height: height,
      position: 'relative',
    },
    tabs: {
      borderRight: `.5px solid ${theme.palette.divider}`,
      transition: '.2s ease',
      width: theme.spacing(14),
      minWidth: theme.spacing(14),
      padding: `${theme.spacing(1.6)} ${theme.spacing(2.5)}`,
      boxSizing: 'border-box',
    },
    tabsExpanded: {
      width: tabWidth,
      minWidth: tabWidth,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      [`& ${classes.tabRoot}`]: {
        width: `calc(${tabWidth}px - ${theme.spacing(3)})`,
        flexDirection: 'row',
        textTransform: 'capitalize',
        '& svg': {
          marginRight: theme.spacing(2),
          marginBottom: '0',
        },
      },
    },
    tabsContainer: {
      alignItems: 'center',
    },
    tabIndicator: {
      background: 'transparent',
    },
    tabRoot: {
      opacity: 1,
      borderRadius: 4,
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      minHeight: theme.spacing(9),
      minWidth: theme.spacing(9),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
      '&:hover': {
        background: '#F2F5F9',
      },
    },
    tabWrapper: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        marginBottom: '0 !important',
        marginRight: '0',
      },
    },
    tabExpandedWrapper: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'flex-start',
      '& svg': {
        marginBottom: '0 !important',
        marginRight: theme.spacing(2),
      },
    },
    tabSelected: {
      background: '#F2F5F9',
      color: theme.palette.primary.main,
      '& svg': {
        marginBottom: '0 !important',
        marginRight: '0',
        color: theme.palette.primary.main,
      },
    },
    tabExpandedSelected: {
      background: '#F2F5F9',
      color: theme.palette.primary.main,
      '& svg': {
        marginBottom: '0 !important',
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main,
      },
    },
    contentPanel: {
      flexGrow: 1,
    },
    autocompleteDimmedOverlay: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'white',
      opacity: 0.5,
    },
  })
);

const MainTabPanel: FunctionComponent<{
  height: string;
  isNavExpanded: boolean;
}> = ({ height, isNavExpanded }) => {
  const { classes } = useStyles({ height });
  const currentView = useSelector(selectCurrentView);
  const dispatch = useDispatch();
  const isAiSearchAutoCompleteOpen = useSelector(selectIsOpen);
  const activeTab = useSelector(selectActiveTab);
  const isBetaFeaturesEnabled = useSelector(betaFeaturesSelector);

  useEffect(() => {
    if (currentView === DataCenterView.folders) {
      dispatch(actions.uiState.setCurrentView(DataCenterView.myFiles));
    }
  }, [currentView, dispatch]);

  const handleChange = (_: ChangeEvent<unknown>, newActiveTabIndex: number) => {
    dispatch(actions.uiState.setActiveTab(newActiveTabIndex));
    // Get the view from the array
    const filteredTabs = navList.filter(tab => filterOutTab(tab, isBetaFeaturesEnabled as boolean));
    const newView = filteredTabs[newActiveTabIndex as DataCenterView]!.id;

    dispatch(actions.uiState.setCurrentView(newView));
  };

  return (
    <div className={classes.root}>
      {isAiSearchAutoCompleteOpen && <div className={classes.autocompleteDimmedOverlay}></div>}
      <Tabs
        id="dataCenterViewTabs"
        orientation="vertical"
        value={activeTab}
        onChange={handleChange}
        className={classNames(classes.tabs, {
          [classes.tabsExpanded]: isNavExpanded,
        })}
        classes={{
          flexContainer: classes.tabsContainer,
          indicator: classes.tabIndicator,
        }}
        aria-label="data center tabs"
        data-test={`dc-nav-tabs-container`}
      >
        {navList
          .filter(tab => filterOutTab(tab, isBetaFeaturesEnabled as boolean))
          .map(tab => {
            return (
              <Tooltip
                key={tab.id}
                placement="right"
                title={isNavExpanded ? '' : tab.label}
                data-test={`dc-nav-tab-${tab.testLabel}`}
              >
                <Tab
                  wrapped
                  {...a11yProps(tab.id)}
                  icon={tab.icon}
                  label={isNavExpanded ? tab.label : ''}
                  classes={{
                    root: classes.tabRoot,
                    wrapped: isNavExpanded ? classes.tabExpandedWrapper : classes.tabWrapper,
                    selected: isNavExpanded ? classes.tabExpandedSelected : classes.tabSelected,
                  }}
                />
              </Tooltip>
            );
          })}
      </Tabs>
      {navList.map(tab => {
        return (
          <ContentPanel key={tab.id} value={currentView} index={tab.id} className={classes.contentPanel}>
            {tab.content}
          </ContentPanel>
        );
      })}
    </div>
  );
};

const ContentPanel: FunctionComponent<{
  children?: ReactNode;
  index: DataCenterView;
  value: DataCenterView;
  className: string;
}> = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`data-center-tabpanel-${index}`}
      aria-labelledby={`data-center-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function a11yProps(index: DataCenterView) {
  return {
    id: `data-center-tab-${index}`,
    'aria-controls': `data-center-tabpanel-${index}`,
  };
}

export default MainTabPanel;
