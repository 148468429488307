import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../constants';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'scimIdsState';

export interface IScimIdsState {
  status: LoadingStatus;
  scimIds: string[];
}

export const initialState: IScimIdsState = {
  status: 'idle',
  scimIds: [],
};

export const organizationsSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    scimIdsFetchStart(state) {
      state.status = 'pending';
    },
    scimIdsFetchSucceed(state, action: PayloadAction<string[]>) {
      state.status = 'success';
      state.scimIds = action.payload;
    },
    scimIdsFetchFailed(state) {
      state.status = 'failure';
    },
  },
});

export const actions = organizationsSlice.actions;
export default organizationsSlice.reducer;
