import { Hide } from '@aiware/shared/icons';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { SHARED_TEXT } from '../helpers/shared-text';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';

const useStyles = makeStyles()(theme => ({
  panelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2, 2, 6),
    backgroundColor: '#FAFAFA',
  },
}));

export const Header = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  return (
    <header className={classes.panelHeader}>
      <Typography variant="h1" data-testid="dc.metadata-panel-header">
        {SHARED_TEXT.panelTitle()}
      </Typography>
      <Tooltip title={SHARED_TEXT.closePanel()}>
        <IconButton
          onClick={() => dispatch(hidePanel('DATA_CENTER_EDIT_METADATA_PANEL'))}
          aria-labelledby="dc-edit-metadata-panel-close-panel"
          data-testid="dc-edit-metadata-panel-close-panel"
          size="large"
        >
          <Hide />
        </IconButton>
      </Tooltip>
    </header>
  );
};
