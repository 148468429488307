import Typography from '@mui/material/Typography';
import { Mailbox } from '@aiware/shared/assets';
import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';

interface IProps {
  msg?: boolean;
}

const NotificationContent = ({ msg }: IProps) => {
  const { classes } = useStyles({ msg });
  return (
    <div className={classes.root} data-testid="notification-content">
      <div className={classes.noData}>
        <div className={classes.iconNoData}>
          <img src={Mailbox} draggable="false" alt="noNotification" />
        </div>
      </div>
      <div className={classes.noDataDescription}>
        <Typography variant="h1" data-test="no-notification" className={classes.noDataDescriptionText}>
          {msg ? (
            <FormattedMessage
              id="os-notification-panel.select-product"
              defaultMessage="No notifications for the selected product"
              description="Notification panel: no notification message for select product"
            />
          ) : (
            <FormattedMessage
              id="os-notification-panel.pBrbyj"
              defaultMessage="You don’t have any notifications"
              description="Notification panel: no notification message"
            />
          )}
        </Typography>
      </div>
    </div>
  );
};
export default NotificationContent;
