import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, type ButtonProps, Stack, type StackProps, Typography } from '@mui/material';

export type TEmptyStateProps = {
  title: string | JSX.Element | ReactNode;
  description: string | JSX.Element | ReactNode;
  onClick?: () => void;
  image?: JSX.Element | string;
  buttonLabel?: string | JSX.Element;
  buttonStyle?: boolean;
  ButtonProps?: Omit<ButtonProps, 'onClick' | 'variant'>;
  ContainerProps?: Omit<StackProps, 'children'>;
};

export const EmptyState: React.FC<TEmptyStateProps> = ({
  title,
  description,
  onClick,
  image,
  buttonLabel,
  buttonStyle,
  ButtonProps = {},
  ContainerProps = {},
}) => {
  const imageRender = () => {
    if (typeof image === 'string') {
      return <img src={image} alt="empty-state" />;
    }
    return image || <DefaultEmptyStateImage />;
  };

  const label = buttonLabel || (
    <FormattedMessage
      id="shared-reusable-utils.emptyState.create-button"
      defaultMessage="Create"
      description="Shared Reusable Utils Empty State Create Button Label"
    />
  );

  return (
    <Stack
      {...ContainerProps}
      sx={{
        marginTop: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        ...(ContainerProps?.sx ?? {}),
      }}
      alignItems={'center'}
    >
      {imageRender()}
      <Typography variant={'h4'} fontWeight={'bold'} data-testid="shared-reusable-utils-empty-state-title">
        {title}
      </Typography>
      <Typography variant={'body1'} data-testid="shared-reusable-utils-empty-state-description">
        {description}
      </Typography>
      {onClick && (
        <Button
          {...ButtonProps}
          variant={buttonStyle ? 'outlined' : 'contained'}
          data-testid="shared-reusable-utils-login-create-button"
          onClick={onClick}
        >
          {label}
        </Button>
      )}
    </Stack>
  );
};

function DefaultEmptyStateImage() {
  return (
    <svg width="164" height="144" viewBox="0 0 164 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M81.9533 128.571C117.531 128.571 146.372 99.7897 146.372 64.2857C146.372 28.7817 117.531 0 81.9533 0C46.3756 0 17.5342 28.7817 17.5342 64.2857C17.5342 99.7897 46.3756 128.571 81.9533 128.571Z"
        fill="#EAEEF9"
      />
      <g filter="url(#filter0_d_62587_13624)">
        <path
          d="M141.051 26.3796V104.557C141.051 107.313 138.776 109.583 136.014 109.583H28.5639C25.8019 109.583 23.5273 107.313 23.5273 104.557V26.3796C23.5273 23.6234 25.8019 21.3535 28.5639 21.3535H136.014C138.776 21.3535 141.051 23.6234 141.051 26.3796Z"
          fill="url(#paint0_linear_62587_13624)"
        />
      </g>
      <path
        d="M141.051 26.3796V30.0277H23.5273V26.3796C23.5273 23.6234 25.8019 21.3535 28.5639 21.3535H136.014C138.776 21.3535 141.051 23.6234 141.051 26.3796Z"
        fill="url(#paint1_linear_62587_13624)"
      />
      <path
        d="M28.4722 27.2719C29.3247 27.2719 30.0156 26.5823 30.0156 25.7316C30.0156 24.881 29.3247 24.1914 28.4722 24.1914C27.6198 24.1914 26.9287 24.881 26.9287 25.7316C26.9287 26.5823 27.6198 27.2719 28.4722 27.2719Z"
        fill="#EAEEF9"
      />
      <path
        opacity="0.6"
        d="M33.2642 27.2719C34.1165 27.2719 34.8076 26.5823 34.8076 25.7316C34.8076 24.881 34.1165 24.1914 33.2642 24.1914C32.4118 24.1914 31.7207 24.881 31.7207 25.7316C31.7207 26.5823 32.4118 27.2719 33.2642 27.2719Z"
        fill="#EAEEF9"
      />
      <path
        opacity="0.4"
        d="M38.1382 27.2719C38.9906 27.2719 39.6816 26.5823 39.6816 25.7316C39.6816 24.881 38.9906 24.1914 38.1382 24.1914C37.2858 24.1914 36.5947 24.881 36.5947 25.7316C36.5947 26.5823 37.2858 27.2719 38.1382 27.2719Z"
        fill="#EAEEF9"
      />
      <path
        d="M54.093 37.874H35.1672C34.5975 37.874 34.1357 38.3345 34.1357 38.9026V41.8288C34.1357 42.3969 34.5975 42.8574 35.1672 42.8574H54.093C54.6626 42.8574 55.1244 42.3969 55.1244 41.8288V38.9026C55.1244 38.3345 54.6626 37.874 54.093 37.874Z"
        fill="#C2C8D6"
      />
      <path
        d="M114.061 37.874H104.13C103.56 37.874 103.099 38.3345 103.099 38.9026V39.8355C103.099 40.4036 103.56 40.8641 104.13 40.8641H114.061C114.63 40.8641 115.092 40.4036 115.092 39.8355V38.9026C115.092 38.3345 114.63 37.874 114.061 37.874Z"
        fill="#C2C8D6"
      />
      <path
        d="M130.052 37.874H120.121C119.552 37.874 119.09 38.3345 119.09 38.9026V39.8355C119.09 40.4036 119.552 40.8641 120.121 40.8641H130.052C130.622 40.8641 131.083 40.4036 131.083 39.8355V38.9026C131.083 38.3345 130.622 37.874 130.052 37.874Z"
        fill="#C2C8D6"
      />
      <path
        d="M129.021 49.834H36.1986C35.0593 49.834 34.1357 50.755 34.1357 51.8911V73.6905C34.1357 74.8266 35.0593 75.7476 36.1986 75.7476H129.021C130.16 75.7476 131.084 74.8266 131.084 73.6905V51.8911C131.084 50.755 130.16 49.834 129.021 49.834Z"
        fill="#D5DDEA"
      />
      <path
        d="M85.0005 59.8456C85.5891 59.8456 86.0664 59.3428 86.0664 58.7226C86.0664 58.1024 85.5891 57.5996 85.0005 57.5996C84.4117 57.5996 83.9346 58.1024 83.9346 58.7226C83.9346 59.3428 84.4117 59.8456 85.0005 59.8456Z"
        fill="#989FB0"
      />
      <path
        d="M85.0636 67.1376H76.9315C76.407 67.1376 76.1009 66.4961 76.407 66.0837L80.4729 60.1275C80.7352 59.7609 81.2599 59.7609 81.5222 60.1275L85.5883 66.0837C85.8943 66.542 85.5883 67.1376 85.0636 67.1376Z"
        fill="#989FB0"
      />
      <path
        opacity="0.7"
        d="M87.8944 67.0156H83.8922C83.2843 67.0156 82.9803 66.3496 83.2843 65.8886L85.2601 63.071C85.5641 62.6612 86.1214 62.6612 86.4253 63.071L88.4011 65.8886C88.8571 66.3496 88.5024 67.0156 87.8944 67.0156Z"
        fill="#989FB0"
      />
      <path
        d="M62.0568 80.7305H36.1986C35.0593 80.7305 34.1357 81.6515 34.1357 82.7876V97.6102C34.1357 98.7464 35.0593 99.6674 36.1986 99.6674H62.0568C63.1961 99.6674 64.1197 98.7464 64.1197 97.6102V82.7876C64.1197 81.6515 63.1961 80.7305 62.0568 80.7305Z"
        fill="#D5DDEA"
      />
      <path
        d="M95.0388 80.7305H70.1801C69.0408 80.7305 68.1172 81.6515 68.1172 82.7876V97.6102C68.1172 98.7464 69.0408 99.6674 70.1801 99.6674H95.0388C96.1781 99.6674 97.1017 98.7464 97.1017 97.6102V82.7876C97.1017 81.6515 96.1781 80.7305 95.0388 80.7305Z"
        fill="#D5DDEA"
      />
      <path
        d="M129.021 80.7305H103.163C102.023 80.7305 101.1 81.6515 101.1 82.7876V97.6102C101.1 98.7464 102.023 99.6674 103.163 99.6674H129.021C130.16 99.6674 131.084 98.7464 131.084 97.6102V82.7876C131.084 81.6515 130.16 80.7305 129.021 80.7305Z"
        fill="#D5DDEA"
      />
      <path
        d="M117.027 22.9238H48.1918C47.0525 22.9238 46.1289 23.8448 46.1289 24.981V25.8501C46.1289 26.9862 47.0525 27.9072 48.1918 27.9072H117.027C118.166 27.9072 119.09 26.9862 119.09 25.8501V24.981C119.09 23.8448 118.166 22.9238 117.027 22.9238Z"
        fill="#EAEEF9"
      />
      <path
        d="M143.296 105.089C143.098 105.385 142.999 105.581 142.604 105.777L138.06 107.646C137.369 107.941 136.678 108.334 136.183 108.826C135.689 109.318 135.294 109.809 135.195 110.498L133.318 115.021C133.121 115.709 132.33 116.005 131.837 115.709C131.639 115.513 131.343 115.414 131.343 115.218L122.551 95.9438C122.353 95.6488 122.353 95.2555 122.551 95.0587C122.748 94.7637 123.242 94.5671 123.736 94.7637L143.098 103.516C143.296 103.91 143.493 104.598 143.296 105.089Z"
        fill="#989FB0"
      />
      <path
        d="M146.655 116.889C146.458 116.889 146.359 116.889 146.162 116.693L139.839 110.301C139.543 110.006 139.543 109.416 139.839 109.318C140.135 109.023 140.728 109.023 140.827 109.318L147.149 115.612C147.446 115.906 147.446 116.496 147.149 116.595C147.149 116.889 146.853 116.889 146.655 116.889Z"
        fill="#989FB0"
      />
      <path
        d="M140.235 116.889C140.037 116.889 139.938 116.889 139.741 116.693L136.875 113.841C136.579 113.546 136.579 112.956 136.875 112.858C137.172 112.563 137.764 112.563 137.863 112.858L140.729 115.709C141.025 116.005 141.025 116.595 140.729 116.693C140.729 116.889 140.63 116.889 140.235 116.889Z"
        fill="#989FB0"
      />
      <defs>
        <filter
          id="filter0_d_62587_13624"
          x="1.52734"
          y="10.3535"
          width="161.523"
          height="132.229"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_62587_13624" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_62587_13624" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_62587_13624"
          x1="82.2506"
          y1="19.3127"
          x2="82.2506"
          y2="110.534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_62587_13624"
          x1="136.389"
          y1="21.3535"
          x2="27.3523"
          y2="26.497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
}
