import { TabId } from '@aiware/js/interfaces';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/modules/user';
import { IApplication } from '../../../types';
import ActivityPanel, { IActivityDetail } from './ActivityPanel';
import ApplicationPanelDnD from './ApplicationPanel/application-panel-dnd';
import PerformaceMonitorPanel from './PerformanceMonitor';
import PersonalProfile, { FormStateInterface, ICurrentUser } from './PersonalProfile';
import useStyles from './ApplicationPanel/application-panel.styles';
import { gqlIntrospection, selectHasUserCreateRootFolderPermission } from '@aiware/shared/redux';

export interface ISettingComponentProps {
  currentUser: ICurrentUser;
  activities?: IActivityDetail[];
  activityFilter: string;
  onChangeActivityFilter: (value: string) => void;
  handleResetBiometric: () => void;
  handleLogout: () => void;
  handleResetPassword: (email: string) => void;
  handleUpdateProfile: (formData: FormStateInterface) => void;
  // handleOpenPost: any;
  organizationRole: {
    role: string;
    organizationId: string;
  };
  recentApp: IApplication[];
  applications: IApplication[];
  onClickApp: (id: string | number) => void;
  handleUploadAvatar: (file: File) => void;
  avatarHistory: string[];
  tabId: string | number;
}

function SettingPanel({
  currentUser,
  activities,
  activityFilter,
  onChangeActivityFilter,
  handleResetBiometric,
  handleResetPassword,
  handleLogout,
  handleUpdateProfile,
  tabId,
  recentApp,
  applications,
  onClickApp,
  handleUploadAvatar,
  avatarHistory,
}: ISettingComponentProps) {
  const currentUserState = useSelector(userSelector)!;
  const [loadingStatus, setLoadingStatus] = useState('');
  const { classes } = useStyles();
  useEffect(() => {
    setLoadingStatus(currentUserState.loading || '');
  }, [currentUserState]);
  const hasUserCreateRootFolderPermission = useSelector(selectHasUserCreateRootFolderPermission);
  const showAppGroups =
    gqlIntrospection.isOperationValid('fileApplication', 'mutation') && hasUserCreateRootFolderPermission;

  return (
    <div className={classes.tabsBox}>
      {tabId === TabId.PERSONAL_PANEL && (
        <PersonalProfile
          currentUser={currentUser}
          handleLogout={handleLogout}
          handleResetBiometric={handleResetBiometric}
          handleResetPassword={handleResetPassword}
          handleUpdateProfile={handleUpdateProfile}
          handleUploadAvatar={handleUploadAvatar}
          avatarHistory={avatarHistory}
          loadingStatus={loadingStatus}
        />
      )}
      {tabId === TabId.APP_PANEL && (
        <ApplicationPanelDnD
          recentApplist={recentApp}
          applications={applications}
          onClickApp={onClickApp}
          showAppGroups={showAppGroups}
        />
      )}
      {tabId === TabId.ACTIVITY_PANEL && (
        <ActivityPanel
          filter={activityFilter}
          onChangeFilter={onChangeActivityFilter}
          data={activities || []}
        />
      )}
      {tabId === TabId.PERFORMANCE_MONITOR && <PerformaceMonitorPanel />}
    </div>
  );
}

export default SettingPanel;
export { ActivityPanel, PersonalProfile };
