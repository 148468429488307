import { useCallback, useEffect, useRef } from 'react';
import { EventBus } from './event-bus';

import type { EventMap } from './types';

interface UseEventBusOptions {
  targetWindow?: Window | null;
  targetOrigin?: string;
}

export function useEventBus<K extends keyof EventMap>(
  event: K,
  callback?: (data: EventMap[K]) => void,
  options: UseEventBusOptions = {}
) {
  const { targetWindow, targetOrigin = '*' } = options;

  const eventBusRef = useRef<EventBus>(EventBus.getInstance());
  const memoizedCallback = useCallback(
    (data: EventMap[K]) => {
      callback?.(data);
    },
    [callback]
  );

  // Initialize connection
  useEffect(() => {
    const eventBus = eventBusRef.current;
    eventBus.connect(targetWindow, targetOrigin);

    return () => {
      // Cleanup connection when component unmounts
      eventBus.disconnect();
    };
  }, [targetWindow, targetOrigin]);

  // Subscribe to events
  useEffect(() => {
    const eventBus = eventBusRef.current;
    const unsubscribe = eventBus.subscribe(event, memoizedCallback);

    return () => {
      unsubscribe();
    };
  }, [event, memoizedCallback]);

  // Return publish method
  const publish = useCallback(
    (data: EventMap[K]) => {
      eventBusRef.current.publish(event, data);
    },
    [event]
  );

  return { publish };
}
