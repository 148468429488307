import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { FILE_INFO_LABEL_TEXT } from '../../helpers/shared-text';
import { FolderOpen } from '@aiware/shared/icons';
import { TooltipOnTextOverflow } from '../../../../shared/TooltipOnTextOverflow';
import {
  formatDuration,
  formatFileInfoDate,
  formatFileSize,
  getFileExtension,
} from '../../../../../helpers/fileInfoHelpers';
import { mapTdoListToViewModels } from '../../../../../redux/selectors/helpers';
import { ITdo, ITdoVm, JobStatus } from '../../../../../types';
import {
  selectEngineCategoryEntities,
  selectEngineEntities,
  selectTdoEntities,
} from '../../../../../redux/selectors/entities';
import EngineIcons from '../../../../shared/EngineIcons';
import IdCopy from '../../../../shared/IdCopy';

const enum EFileStatus {
  NEW = 'NEW',
  QUEUED = 'QUEUED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  ACTIVE = 'ACTIVE',
  RUNNING = 'RUNNING',
  SCHEDULED = 'SCHEDULED',
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
}

const FileStatusBadge = ({ status }: { status: EFileStatus }) => {
  const { classes } = makeStyles()(_theme => ({
    statusContainer: {
      display: 'flex',
      width: 'fit-content',
      borderRadius: '3px',
      fontWeight: 800,
      fontSize: '11px',
      padding: '0px 15px',
      pointerEvents: 'none',
      userSelect: 'none',
      marginTop: '3px',
      transform: 'scale(0.85)',
      transformOrigin: 'left',
    },
    [`status-${EFileStatus.NEW}`]: {
      backgroundColor: '#B9E0BB',
      color: '#16961B',
    },
    [`status-${EFileStatus.COMPLETE}`]: {
      color: '#1B5E20',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    [`status-${EFileStatus.QUEUED}`]: {
      color: 'rgba(74, 20, 140, 1)',
      background: 'rgba(74, 20, 140, 0.2)',
    },
    [`status-${EFileStatus.PENDING}`]: {
      backgroundColor: '#FFE49D',
      color: '#CA9200',
    },
    [`status-${EFileStatus.RUNNING}`]: {
      color: 'rgba(13, 98, 210, 1)',
      background: 'rgba(13, 98, 210, 0.2)',
    },
    [`status-${EFileStatus.ERROR}`]: {
      color: 'rgba(183, 28, 28, 1)',
      background: 'rgba(183, 28, 28, 0.2)',
    },
  }))();

  const badgeClassName = (classes as any)[`status-${status}`];
  return (
    <div
      data-test="dc-file-info-panel-status-badge"
      className={`${classes.statusContainer} ${badgeClassName}`}
    >
      {status.toUpperCase()}
    </div>
  );
};

const FolderLocation = ({ folderName }: { folderName: string }) => {
  const { classes } = makeStyles()(_theme => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: '5px',
    },
    folderLabel: {
      marginLeft: '5px',
    },
  }))();

  return (
    <div className={classes.container}>
      <FolderOpen sx={{ width: 18, height: 18 }} />{' '}
      <TooltipOnTextOverflow title={folderName} maxCharLength={14}>
        <Typography
          data-test={`dc-file-info-panel-item-location`}
          className={classes.folderLabel}
          noWrap={true}
          variant="body2"
        >
          {folderName}
        </Typography>
      </TooltipOnTextOverflow>
    </div>
  );
};

const DataItem = ({ label, value }: { label: string; value: string | JSX.Element }) => {
  const { classes } = makeStyles()(_theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      marginBottom: '20px',
    },
    label: {},
    value: {},
  }))();

  let content: string | JSX.Element = value;

  if (typeof value === 'string') {
    content = (
      <TooltipOnTextOverflow title={value} maxCharLength={18}>
        <Typography data-test={`dc-file-info-panel-item-${label}`} noWrap={true} variant="body2">
          {value}
        </Typography>
      </TooltipOnTextOverflow>
    );
  }

  if (value === null) {
    content = <Typography>N/A</Typography>;
  }

  return (
    <div className={classes.container}>
      <Typography
        data-test={`dc-file-info-panel-${label}`}
        className={classes.label}
        variant="caption"
        sx={{ fontWeight: '400', color: '#5C6269' }}
      >
        {(FILE_INFO_LABEL_TEXT as any)[label]}
      </Typography>
      <span className={classes.value}>{content}</span>
    </div>
  );
};

const Tags = ({ tags }: { tags: string[] }) => {
  const { classes } = makeStyles()(_theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    tagList: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '8px',
    },
    tag: {
      border: '0.5px solid #D5DFE9',
      padding: '0px 10px',
      borderRadius: '20px',
      display: 'flex',
      marginRight: '10px',
      marginBottom: '10px',
    },
  }))();
  return (
    <div className={classes.container}>
      <Typography
        data-test="dc-file-info-panel-tags"
        variant="caption"
        sx={{ fontWeight: '400', color: '#5C6269' }}
      >
        {FILE_INFO_LABEL_TEXT.tags}
      </Typography>
      <div className={classes.tagList}>
        {tags.map((tag, index) => (
          <Typography
            data-test="dc-file-info-panel-tag-item"
            key={index}
            className={classes.tag}
            noWrap={true}
            variant="body2"
          >
            {tag}
          </Typography>
        ))}
      </div>
    </div>
  );
};

const flattenFileInfo = (tdo?: ITdo) => {
  if (!tdo) {
    return {};
  }

  const returnValue: any = {};
  returnValue.name =
    tdo.details?.veritoneFile?.filename ||
    tdo.details?.veritoneFile?.fileName ||
    tdo.details?.name ||
    tdo.name ||
    tdo.id;
  if (!tdo.folders) {
    returnValue.location = 'Home';
  } else if (tdo.folders.length < 1) {
    returnValue.location = 'Home';
  } else {
    if (tdo.folders[0].folderPath.length <= 1) {
      returnValue.location = 'Home';
    } else {
      returnValue.location = tdo.folders[0].folderPath[0].name;
    }
  }
  if (tdo.id) {
    returnValue.id = tdo.id;
  }
  if (tdo.primaryAsset?.signedUri) {
    returnValue.url = tdo.primaryAsset.signedUri;
  }
  if (tdo.details?.veritoneFile?.mimetype || tdo.primaryAsset?.contentType) {
    returnValue.fileType = tdo.details?.veritoneFile?.mimetype || tdo.primaryAsset?.contentType;
  }
  if (tdo.details?.veritoneFile?.size) {
    returnValue.fileSize = tdo.details?.veritoneFile?.size;
  }
  if (tdo.startDateTime && tdo.stopDateTime && tdo.startDateTime !== tdo.stopDateTime) {
    returnValue.duration = Math.floor(
      (new Date(tdo.stopDateTime).getTime() - new Date(tdo.startDateTime).getTime()) / 1000
    );
  } else {
    returnValue.duration = null;
  }
  if (tdo.modifiedDateTime) {
    returnValue.dateUploaded = tdo.modifiedDateTime;
  }
  if (tdo.createdDateTime) {
    returnValue.dateCreated = tdo.createdDateTime;
  }
  if (tdo.jobs && tdo.jobs.records.length > 0) {
    if (
      tdo.jobs.records.find(
        job =>
          job.status === JobStatus.Failed ||
          job.status === JobStatus.Aborted ||
          job.status === JobStatus.Cancelled
      )
    ) {
      returnValue.fileStatus = EFileStatus.ERROR;
    } else if (tdo.jobs.records.find(job => job.status === JobStatus.Pending)) {
      returnValue.fileStatus = EFileStatus.PENDING;
    } else if (tdo.jobs.records.find(job => job.status === JobStatus.Running)) {
      returnValue.fileStatus = EFileStatus.RUNNING;
    } else if (
      tdo.jobs.records.every(job => job.status === JobStatus.Queued || job.status === JobStatus.Paused)
    ) {
      returnValue.fileStatus = EFileStatus.QUEUED;
    } else if (tdo.jobs.records.every(job => job.status === JobStatus.Complete)) {
      returnValue.fileStatus = EFileStatus.COMPLETE;
    } else {
      returnValue.fileStatus = EFileStatus.NEW;
    }
  } else {
    returnValue.fileStatus = EFileStatus.NEW;
  }

  if (tdo.details && Array.isArray(tdo.details?.tags)) {
    returnValue.tags = (tdo.details?.tags || [])
      .map((tag: any) => {
        if (typeof tag === 'string') {
          return tag;
        } else if (typeof tag === 'object' && tag.value) {
          return String(tag.value);
        }
      })
      .filter((tag: any) => !!tag);
  } else {
    returnValue.tags = [];
  }

  return returnValue;
};

export const FileInfo = ({ fileInfo }: { fileInfo?: ITdo }) => {
  const { classes } = makeStyles()(_theme => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    engineIcons: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      marginBottom: '20px',
    },
  }))();

  const parsedFileInfo = flattenFileInfo(fileInfo);
  const engineCategories = useSelector(selectEngineCategoryEntities);
  const engines = useSelector(selectEngineEntities);
  const allTdos = useSelector(selectTdoEntities);
  const TDOwithEngineData =
    fileInfo && fileInfo.id
      ? mapTdoListToViewModels([fileInfo.id], allTdos, engines, engineCategories)[0]!.file
      : [];

  const FileInfoData = () => {
    return (
      <>
        {parsedFileInfo &&
          Object.keys(parsedFileInfo).map(dataType => {
            const data = parsedFileInfo[dataType];

            switch (dataType) {
              case 'dateUploaded':
                return (
                  <DataItem key={dataType} label={dataType} value={formatFileInfoDate(new Date(data))} />
                );
              case 'url':
                return null;
              case 'id':
                return <DataItem key={dataType} label={dataType} value={<IdCopy id={data} />} />;
              case 'dateCreated':
                return (
                  <DataItem key={dataType} label={dataType} value={formatFileInfoDate(new Date(data))} />
                );
              case 'fileStatus':
                return <DataItem key={dataType} label={dataType} value={<FileStatusBadge status={data} />} />;
              case 'fileSize':
                // file size comes from gql in bytes
                return (
                  <DataItem key={dataType} label={dataType} value={formatFileSize(Math.ceil(data / 1024))} />
                );
              case 'fileType':
                return (
                  <DataItem key={dataType} label={dataType} value={getFileExtension(data)!.toUpperCase()} />
                );
              case 'location':
                return (
                  <DataItem key={dataType} label={dataType} value={<FolderLocation folderName={data} />} />
                );
              case 'tags':
                return null;
              case 'duration':
                return <DataItem key={dataType} label={dataType} value={formatDuration(data)} />;
              default:
                return <DataItem key={dataType} label={dataType} value={data} />;
            }
          })}
        <div data-test="dc-file-info-panel-ai-engines-container" className={classes.engineIcons}>
          <Typography
            data-test="dc-file-info-panel-ai-engines"
            variant="caption"
            sx={{ fontWeight: '400', color: '#5C6269', marginBottom: '3px' }}
          >
            {FILE_INFO_LABEL_TEXT.aiEngines}
          </Typography>
          <EngineIcons item={TDOwithEngineData as ITdoVm} iconsToShow={4} />
        </div>
        <div data-test="dc-file-info-panel-other-engines-container" className={classes.engineIcons}>
          <Typography
            data-test="dc-file-info-panel-other-engines"
            variant="caption"
            sx={{ fontWeight: '400', color: '#5C6269', marginBottom: '3px' }}
          >
            {FILE_INFO_LABEL_TEXT.otherEngines}
          </Typography>
          <EngineIcons item={TDOwithEngineData as ITdoVm} iconsToShow={4} showOtherEngines={true} />
        </div>
        <Tags tags={parsedFileInfo?.tags || []} />
      </>
    );
  };

  return (
    <div className={classes.container}>
      <FileInfoData />
    </div>
  );
};
