import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { enUS, fr, es } from 'date-fns/locale';
import { Locale } from 'date-fns';

import { filterLabels } from '../../helpers/formatted-messages';
import { filterStyles } from '../../helpers/shared-styles';
import { Box } from '@mui/material';
import { TDateTimeFilter } from '../types';
import { AnyAction } from 'redux';

const locales: Record<string, Locale> = {
  en: enUS,
  fr,
  es,
};

interface IDateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

const MAX_NUMBER_OF_DAYS = 90;
const DAY_IN_MS = 24 * 60 * 60 * 1000; // 1 day in ms

const toDate = (value: string | undefined): Date | undefined => {
  if (typeof value === 'undefined') {
    return value;
  }
  return new Date(value);
};

/**
 * @dateRangeLimit - number of days
 */

type TProps = {
  entity?: 'jobs' | 'tasks';
  dateTimeFilter: TDateTimeFilter;
  setFilterValue: (args: { filter: 'dateTimeFilter'; value: TDateTimeFilter }) => void;
  locale: string;
  dateRangeLimit?: number;
  clearAndApply?: () => void;
  shouldDispatchActions?: boolean;
};

export const DateRangeFilter = ({
  entity = 'jobs',
  dateTimeFilter,
  setFilterValue,
  locale = 'en',
  dateRangeLimit = MAX_NUMBER_OF_DAYS,
  clearAndApply,
  shouldDispatchActions = true,
}: TProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dateTimeRange, setDateTimeRange] = useState<IDateRange>({
    startDate: toDate(dateTimeFilter.fromDateTime),
    endDate: toDate(dateTimeFilter.toDateTime),
  });
  const intl = useIntl();
  const dispatch = useDispatch();
  const { classes } = filterStyles();
  const fullDays = Math.floor(dateRangeLimit);
  const { okButtonLabel, clearButtonLabel, dateRangeFilterDefaultValueLabel } = filterLabels(intl, fullDays);
  const handleClose = () => {
    const action = setFilterValue({
      filter: 'dateTimeFilter',
      value: {
        fromDateTime: dateTimeRange.startDate?.toISOString(),
        toDateTime: dateTimeRange.endDate?.toISOString(),
        field: 'createdDateTime',
      },
    }) as unknown as AnyAction;
    if (shouldDispatchActions) {
      dispatch(action);
    }
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setDateTimeRange({
      startDate: toDate(dateTimeFilter.fromDateTime),
      endDate: toDate(dateTimeFilter.toDateTime),
    });
    setOpenDialog(true);
  };

  const handleSetStartTime = (date: Date | null) => {
    const newDate = date;
    // set beginning of the day
    newDate?.setHours(0, 0, 0, 0);
    setDateTimeRange(({ endDate }) => {
      const newEndDate = endDate || new Date();
      if (newDate && newEndDate.getTime() > newDate.getTime() + fullDays * DAY_IN_MS) {
        newEndDate.setTime(newDate.getTime() + fullDays * DAY_IN_MS);
      }
      return {
        startDate: newDate || undefined,
        endDate: newEndDate,
      };
    });
  };

  const handleSetEndTime = (date: Date | null) => {
    const newDate = date;
    // end of the day
    newDate?.setHours(23, 59, 59);
    setDateTimeRange(previousValue => ({
      ...previousValue,
      endDate: newDate || undefined,
    }));
  };

  const handleClear = () => {
    const action = setFilterValue({
      filter: 'dateTimeFilter',
      value: {
        field: 'createdDateTime',
      },
    }) as unknown as AnyAction;
    if (shouldDispatchActions) {
      dispatch(action);
    }

    if (clearAndApply) {
      clearAndApply();
    }
    setDateTimeRange({} as IDateRange);
    setOpenDialog(false);
  };

  const displayDate =
    dateTimeFilter.fromDateTime || dateTimeFilter.toDateTime
      ? `${intl.formatDate(dateTimeFilter.fromDateTime)} - ${intl.formatDate(dateTimeFilter.toDateTime)}`
      : dateRangeFilterDefaultValueLabel;

  return (
    <FormControl size="small" className={classes.formControl}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <label htmlFor={`${entity}-filter-date-range`} className={classes.label}>
          <FormattedMessage
            id="reusable-utils.filter-range-label"
            defaultMessage="Date/time"
            description="label for the jobs filter date range picker"
          />
        </label>
        {(dateTimeFilter.fromDateTime || dateTimeFilter.toDateTime) && (
          <span
            className={classes.clearButton}
            onClick={handleClear}
            data-testid={'date-time-filter.clear-btn'}
          >
            {clearButtonLabel}
          </span>
        )}
      </Box>
      <div
        id={`${entity}-filter-date-range`}
        data-test={`${entity}-filter-date-range-display`}
        className={classes.dateRangeDisplay}
        onClick={handleOpen}
      >
        {displayDate}
      </div>
      <Dialog open={openDialog} disableEscapeKeyDown onClose={handleClose} maxWidth="md">
        <DialogContent data-test={`${entity}-filter-date-range-dialog-content`}>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[locale]}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} data-test={`${entity}-filter-date-range-start-calendar`}>
                  <CalendarPicker
                    date={dateTimeRange.startDate || null}
                    onChange={handleSetStartTime}
                    maxDate={dateTimeRange.endDate || new Date()}
                    defaultCalendarMonth={dateTimeRange.startDate}
                  />
                </Grid>
                <Grid item xs={12} md={6} data-test={`${entity}-filter-date-range-end-calendar`}>
                  <CalendarPicker
                    date={dateTimeRange.endDate || null}
                    onChange={handleSetEndTime}
                    maxDate={new Date()}
                    disabled={!dateTimeRange.startDate}
                    defaultCalendarMonth={dateTimeRange.endDate}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </FormControl>
        </DialogContent>
        <DialogActions data-test={`${entity}-filter-date-range-dialog-actions`}>
          <Button data-test={`${entity}-filter-date-range-dialog-clear-button`} onClick={handleClear}>
            {clearButtonLabel}
          </Button>
          <Button data-test={`${entity}-filter-date-range-dialog-ok-button`} onClick={handleClose}>
            {okButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};
