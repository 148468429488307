import { Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  paper: {
    padding: '20px',
  },
  spacer: {
    height: '5px',
    width: '100%',
  },
}));

export const DefaultThemesTypography = () => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h1">
        H1 HEADING typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <Typography variant="h1" color="textSecondary">
        H1 hEADING typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
        in laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="h2">
        H2 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
        out print, graphic or web designs.
      </Typography>
      <Typography variant="h2" color="textSecondary">
        H2 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="h3">
        H3 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
        out print, graphic or web designs.
      </Typography>
      <Typography variant="h3" color="textSecondary">
        H3 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="h4">
        H4 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
        out print, graphic or web designs.
      </Typography>
      <Typography variant="h4" color="textSecondary">
        H4 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="h5">
        H5 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
        out print, graphic or web designs.
      </Typography>
      <Typography variant="h5" color="textSecondary">
        H5 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="h6">
        H6 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
        out print, graphic or web designs.
      </Typography>
      <Typography variant="h6" color="textSecondary">
        H6 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="subtitle1">
        Subtitle 1 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Subtitle 1 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
        in laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="subtitle2">
        Subtitle 2 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        Subtitle 2 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
        in laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="body1">
        Body1 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Body1 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="body2">
        Body2 typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Body2 typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <div className={classes.spacer} />
      <Typography variant="caption">
        caption typography - default Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <br />
      <Typography variant="caption" color="textPrimary">
        caption typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <br />
      <Typography variant="caption" color="textSecondary">
        caption typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <br />
      <div className={classes.spacer} />
      <Typography variant="overline">
        Overline typography - primary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
      <br />
      <Typography variant="overline" color="textSecondary">
        Overline typography - secondary Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs.
      </Typography>
    </Paper>
  );
};
