import { Public } from './Public';
import { PublicWhite } from './PublicWhite';
import { InstanceLocked } from './InstanceLocked';
import { InstanceLockedWhite } from './InstanceLockedWhite';
import { OrgLocked } from './OrgLocked';
import { OrgLockedWhite } from './OrgLockedWhite';
import { Private } from './Private';
import { PrivateWhite } from './PrivateWhite';
import { Sharable } from './Sharable';
import { SharableWhite } from './SharableWhite';
import { Marketplace } from './Marketplace';
import { MarketplaceWhite } from './MarketplaceWhite';

export const EngineFlagIcons = {
  Public,
  PublicWhite,
  InstanceLocked,
  InstanceLockedWhite,
  OrgLocked,
  OrgLockedWhite,
  Private,
  PrivateWhite,
  Sharable,
  SharableWhite,
  Marketplace,
  MarketplaceWhite,
};
