import { Avatar, SxProps } from '@mui/material';
import { IMyOrganization } from '@aiware/os/organization-panel';

export const OrgAvatar = ({ org, sx }: { org: IMyOrganization; sx?: SxProps }) => {
  const name = org?.name ?? 'Unknown Organization';
  const imageUrl = org?.imageUrl ?? null;

  return (
    <Avatar
      src={imageUrl}
      alt={name}
      sx={{
        ...(sx || {}),
        ...(org.imageUrl ? {} : { bgcolor: stringToColor(name) }),
      }}
      children={stringAvatar(name)}
    />
  );
};

function stringToColor(str = '') {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name = '') {
  const split = name.split(' ');
  if (!split.length) {
    return '--';
  }
  if (split.length === 1) {
    return `${name[0]?.toUpperCase()}`;
  }

  return `${split[0][0].toUpperCase()}${split[1][0].toUpperCase()}`;
}
