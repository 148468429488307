import { useEffect, useState } from 'react';
import { useStyles } from '../../app/useStyles';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { getAiWareDesktopModule } from '../../redux';
import { WallpaperManagement } from '../wallpaper-management';
import { bootstrapSdk, mountWidget } from '@aiware/js/sdk';
import { AvailableWidgets, SdkEvents } from '@aiware/js/interfaces';
import { desktopAiwareLogo } from '@aiware/shared/assets';
import { desktopIconsColor } from '../../app/app';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationInStore } from '@aiware/os/app-bar-panel';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  authStatusSelector,
  fetchTokenFromSession,
  MessageSeverity,
  showMessage,
  stashTokenInSession,
  userSelector,
} from '@aiware/shared/redux';
import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { OrgPanel } from './components/org-panel';
import { getOrganizationPanelModule } from '@aiware/os/organization-panel';
import { REDUX_ACTIONS } from './helpers';
import { Loader } from './components/loader';

// lazy load the registry and then bootstrap
const loadSdkTools = async () => {
  const { registry, registryLookup } = await import('@aiware/js/registry');
  return bootstrapSdk({
    registry,
    registryLookup,
  });
};

type TProps = {
  baseUrl?: string;
};

export const OrganizationSwitcher = ({ baseUrl }: TProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const [currentWallpaper, setCurrentWallpaper] = useState<string>();
  const { classes } = useStyles({ currentWallpaper });

  const [sdkError, setSdkError] = useState<string>();
  const [initted, setInitted] = useState(false);
  const user = useSelector(userSelector);
  const authStatus = useSelector(authStatusSelector);

  const goHome = () => {
    dispatch(setApplicationInStore(null));
    navigate('/');
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const authToken = fetchTokenFromSession() || query.get('authToken');
    const port = window.location.port ? `:${window.location.port}` : '';
    const oAuthRedirectUri = `${window.location.protocol}//${window.location.hostname}${port}`;

    loadSdkTools().then(({ init, aiwareEvents }) => {
      init(
        {
          applicationId: 'e4739d44-53d2-4153-b55f-5e246fc989b1',
          baseUrl: baseUrl ?? '',
          authToken: authToken ?? '',
          handleAuth: false,
          disableAppUnavailableModal: true,
          oAuthRedirectUri,
          knowledgeBaseUrl: 'https://support.veritone.com/s/topic/0TO4U000000bwFLWAY/',
          logoutRedirectUrl: '/ui/auth/login',
        },
        err => {
          if (err) {
            setSdkError(err as string);
          } else {
            // Fetch my orgs
            dispatch({ type: REDUX_ACTIONS.fetchMyOrgs });
          }
          setInitted(true);

          document.title = ` My Orgs | aiWARE`;

          aiwareEvents.on(SdkEvents.openSupport, async () => {
            try {
              const { initChatWithSupport, chatWithSupport } = await import(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                /* webpackIgnore: true */ 'https://get.aiware.com/veritone-support/latest/2/index.js'
              );
              await initChatWithSupport();
              await chatWithSupport();
            } catch (err) {
              console.log(err);
              Sentry.captureException(err);
              Sentry.captureMessage('Our chat service is currently unavailable');
              dispatch(
                showMessage({
                  content: isEqual(process.env.NODE_ENV, 'development')
                    ? 'Oops! Our chat service is currently unavailable. Please contact us at support@veritone.com for assistance. Thank you for your understanding!'
                    : intl.formatMessage({
                        id: 'desktop-chat-service-error-part1',
                        defaultMessage:
                          'Oops! Our chat service is currently unavailable. Please contact us at support@veritone.com for assistance. Thank you for your understanding! ',
                        description: 'Chat service error message',
                      }),
                  severity: MessageSeverity.Error,
                })
              );
            }
          });
        }
      );
    });

    // stash the oauth session token
    const stashFunc = () => {
      if (authToken) {
        stashTokenInSession(authToken);
      }
    };
    window.addEventListener('beforeunload', stashFunc);

    return () => {
      stashFunc();
      window.removeEventListener('beforeunload', stashFunc);
    };
  }, []);

  useEffect(() => {
    // if auth fails, redirect to login page
    if (authStatus === 'failure') {
      navigate('/ui/auth/login');
      // only loads app bar if auth is successful
    } else if (authStatus === 'success') {
      loadSdkTools().then(({ aiwareEvents }) => {
        mountWidget(
          {
            name: AvailableWidgets.APP_BAR,
            elementId: 'app-bar',
            config: {
              logoSrc: desktopAiwareLogo,
              onClickLogo: goHome,
              backgroundColor: 'white',
              iconColor: desktopIconsColor,
              help: true,
              showDashboardIcon: false,
              disableGoHome: true,
            },
          },
          () => {
            aiwareEvents.on(SdkEvents.tokenUpdated, tokenErr => {
              if (tokenErr) {
                setSdkError(tokenErr.message);
              }
            });
          }
        );
      });
    }
  }, [authStatus]);
  useEffect(() => {
    const userId = user?.id || user?.userId;
    if (typeof window !== 'undefined' && userId) {
      const visitor = {
        id: userId,
        email: user?.email || (user as any)?.userName || '',
        name: `${user?.firstName || (user as any)?.kvp?.firstName} ${
          user?.lastName || (user as any)?.kvp?.lastName
        }`,
      };

      const account = {
        id: user?.organization?.organizationId || '',
        name: user?.organization?.name || user?.organization?.organizationName || '',
      };

      if (visitor.id && account.id) {
        (window as Window)['analytics']?.identify?.(visitor.id, {
          id: visitor.id,
          name: visitor.name,
          email: visitor.email,
        });

        (window as Window)['analytics']?.group?.(account.id, {
          id: account.id,
          name: account.name,
          organizationName: account.name,
        });
      }

      Sentry.configureScope(scope => {
        scope.setUser({
          id: visitor.id,
          email: visitor.email,
          organizationId: account?.id,
          organization: account?.name,
        });
      });
    }
  }, [user]);

  return (
    <DynamicModuleLoader modules={[getAiWareDesktopModule(), getOrganizationPanelModule()]}>
      <div className={`${classes.logoContainer} app`} data-testid="aiware-desktop.main-container">
        {sdkError && <p>Something went wrong</p>}
        {!initted && <Loader />}
        {initted && <OrgPanel />}
        <style>
          {`
          body {
            margin: 0;
          }
          `}
        </style>
        <WallpaperManagement setCurrentWallpaper={setCurrentWallpaper} currentWallpaper={currentWallpaper!} />
      </div>
    </DynamicModuleLoader>
  );
};
