import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

interface IProps {
  onPanelClose: () => void;
  height: number;
}

export const PanelHeader: React.FC<IProps> = ({ onPanelClose, height }) => {
  const { classes } = makeStyles<{ height: string | number }>()((theme, { height }) => ({
    header: {
      alignItems: 'center',
      borderBottom: `0.5px solid transparent`,
      display: 'flex',
      justifyContent: 'space-between',
      height,
      padding: theme.spacing(2, 2, 2, 6),
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
    navigationToggle: {
      marginRight: theme.spacing(1),
    },
    headerActionContainer: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      height: '100%',
      justifyContent: 'flex-end',
    },
    verticalDivider: {
      borderRight: `.5px solid ${theme.palette.divider}`,
      height: '70%',
      margin: `0 ${theme.spacing(1)}`,
    },
    title: {
      userSelect: 'none',
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: theme.spacing(5),
      letterSpacing: '0.25px',
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
    },
  }))({ height });

  return (
    <header className={classes.header} data-testid="dc-ingestion-creation-iframe-header">
      <Typography variant="h1" data-testid="dc-ingestion-creation-iframe-title" className={classes.title}>
        {SHARED_TEXT.panelHeaderTitle()}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Typography style={visuallyHidden} id="dc-ingestion-creation-iframe-close-panel">
          {SHARED_TEXT.closePanel()}
        </Typography>
        <Tooltip title={SHARED_TEXT.closePanel()}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="dc-ingestion-creation-iframe-close-panel"
            data-testid="dc-media-details-iframe-close-panel"
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};
