/** Returns a filesize of a file in either Kilobytes, Megabytes, or Gigabytes.
 *
 * Example: 42069 => "41.08 MB" */
export const formatFileSize = (kilobytes: number) => {
  if (kilobytes < 1024) return `${kilobytes} KB`;
  if (kilobytes / 1024 < 1024) {
    return `${(kilobytes / 1024).toFixed(2)} MB`;
  }
  return `${(kilobytes / (1024 * 1024)).toFixed(2)} GB`;
};

/** Returns a Date as a string in the MM/DD/YY H:MM AM/PM Format.
 *
 * Example: date => "12/20/22 1:23 PM" */
export const formatFileInfoDate = (date: Date) => {
  const [month, day, year] = date.toLocaleDateString('en-us').split('/');
  const [time, AMPM] = date.toLocaleTimeString('en-us').split(' ');

  return `${month}/${day}/${year?.slice(2)} ${time?.slice(0, -3)} ${AMPM}`;
};

/** Returns a file format based on the mime type */
export const getFileExtension = (mimeType: string) => {
  const [_contentType, ext] = mimeType.split('/');

  return ext;
};

const addLeadingZeros = (num: number, minDigits: number) => {
  const digits = String(num).split('');
  while (digits.length < minDigits) {
    digits.unshift('0');
  }
  return digits.join('');
};

export const formatDuration = (seconds?: number) => {
  if (!seconds) {
    return 'N/A';
  }
  if (seconds < 60) {
    return `${seconds} seconds`;
  }
  // Use nn:nn or nn:nn:nn format
  const time = [];
  time.unshift(addLeadingZeros(seconds % 60, 2));
  if (seconds < 60 * 60) {
    const mins = Math.floor(seconds / 60);
    time.unshift(seconds < 10 * 60 ? mins : addLeadingZeros(mins, 2));
  } else {
    time.unshift(addLeadingZeros(Math.floor(seconds % (60 * 60)), 2));
    time.unshift(addLeadingZeros(Math.floor(seconds / (60 * 60 * 24)), 2));
  }
  return time.join(':');
};
