import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const styles: Record<string, SxProps<Theme>> = {
  header: {
    alignItems: 'center',
    borderBottom: (theme: Theme) => `0.5px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: '64px',
    padding: (theme: Theme) => theme.spacing(0, 0, 0, 6),
  },
  title: {
    userSelect: 'none',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: (theme: Theme) => theme.spacing(5),
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
    color: 'text.secondary',
  },
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
  },
};

export default styles;
