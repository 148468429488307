import { all, call, cancel, fork, put, select, take, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import { ApolloClient, gql, NormalizedCacheObject, ObservableSubscription } from '@apollo/client';
import {
  INotificationDetail,
  IMailbox,
  IPanel,
  IMicroFrontendConfig,
  IVeritoneAppbarPanelConfig,
} from '@aiware/js/interfaces';
import { eventChannel } from 'redux-saga';
import {
  sessionTokenSelector,
  configSelector,
  graphEndpointSelector,
  userSelector,
  createApolloClient,
  createApolloWs,
  fetchUserSuccess,
} from '@aiware/shared/redux';
import {
  getMailboxApi,
  getNotificationByIdApi,
  getUnseenCountApi,
  handleMarkAllNotificationsSeen,
  pushDefaultMailboxNotification,
} from '../helper/mailboxApi';
import {
  requestSubscriber,
  setMailboxData,
  subscribing,
  pushNotification,
  subscriberSuccess,
  subscriberError,
  updateUnseenCount,
  MARK_ALL_NOTIFICATIONS_SEEN,
  markAllNotificationsSeenSuccess,
  markAllNotificationsSeenFail,
  markAllNotificationsSeenRequest,
  subscribeDefaultMailboxRequest,
  pushDefaultMailBox,
} from './action';
import { mailboxIdSelector, panelSelector } from './selector';
let subscriptionTask: any;
let mailboxSubscription: any;

const notificationPanelId = 'NOTIFICATION_PANEL_ID';

export function* subscriberSaga(): Generator {
  const graphEndpoint = (yield select(graphEndpointSelector)) as ReturnType<typeof graphEndpointSelector>;
  const token = (yield select(sessionTokenSelector)) as ReturnType<typeof sessionTokenSelector>;
  const mailBoxData = (yield call(getMailboxApi, graphEndpoint, token)) as Awaited<
    ReturnType<typeof getMailboxApi>
  >;
  const mailboxDataRaw = (mailBoxData as any)?.data?.notificationMailboxes as IMailbox[];
  const defaultSystem = {
    applicationId: 'system',
    icon: null,
    name: 'System',
    description: 'Notification from System Event',
  };
  if (mailboxDataRaw?.length > 0) {
    const appsIds = mailboxDataRaw.reduce((acc, mailbox) => {
      const appId = mailbox?.eventFilter?.applicationId;
      if (appId && appId !== defaultSystem.applicationId && !acc.includes(appId)) {
        acc.push(appId);
        return acc;
      }
      return acc;
    }, [] as string[]);

    const appData = (yield all(
      appsIds.map(item => {
        return call(getNotificationByIdApi, item, graphEndpoint, token);
      })
    )) as Array<Awaited<ReturnType<typeof getNotificationByIdApi>>>;

    const appInfo = appData
      .map(item => {
        const app = (item as any)?.data?.applications?.records?.[0] || {};
        return {
          ...app,
          applicationId: app.id,
          icon: app.iconUrl || app.iconSvg,
        };
      })
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.applicationId]: {
            ...item,
          },
        }),
        {} as { [key: string]: unknown }
      );
    const mailboxes = mailboxDataRaw.map((mailbox: IMailbox) => {
      const appId = mailbox?.eventFilter?.applicationId || 'system';
      return {
        ...mailbox,
        application: {
          ...(appInfo[appId] || { ...defaultSystem }),
        },
      };
    }) as IMailbox[];
    yield put(setMailboxData(mailboxes));
    yield take(subscribing().type);
    yield put(subscriberSuccess({}));
    return;
  } else {
    yield put(subscriberError((mailBoxData as any)?.errors?.[0]?.message || 'Something wrong'));
  }
}

export function* websocketInitChannel(): Generator {
  const mailBoxIds = (yield select(mailboxIdSelector)) as ReturnType<typeof mailboxIdSelector>;
  const sessionToken = (yield select(sessionTokenSelector)) as ReturnType<typeof sessionTokenSelector>;
  const { graphEndpointWS } = (yield select(configSelector)) as ReturnType<typeof configSelector>;
  yield put(subscribing());
  // open saga channel to get the notification from socket
  return eventChannel((emitter: (arg0: { type: string; payload: unknown }) => void) => {
    // init the connection here
    const clients: ApolloClient<NormalizedCacheObject>[] = [];
    const subscriptions: ObservableSubscription[] = [];
    mailBoxIds.forEach((mailboxId: string) => {
      const query = gql`
          subscription {
            notificationPosted(notificationMailboxId:"${mailboxId}") {
              id
              body
              title
              contentType
              flags
              createdDateTime
              readDateTime
              updatedDateTime
              eventType
              eventName
            }
          }`;
      const link = createApolloWs(graphEndpointWS, sessionToken, mailboxId);
      const client = createApolloClient(link);
      const subscription = client
        .subscribe({
          query,
        })
        .subscribe({
          next(data: { data: { notificationPosted: INotificationDetail } }) {
            // ignore json notifications
            if (data?.data?.notificationPosted?.contentType === 'json') {
              return;
            }

            emitter(pushNotification({ ...data.data.notificationPosted, mailboxId }));
          },
        });

      clients.push(client);
      subscriptions.push(subscription);
    });
    // unsubscribe function
    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
      clients.forEach(client => client.stop());
      // do whatever to interrupt the socket communication here
    };
  });
}
export function* defaultMailboxChannel(): Generator {
  yield take(fetchUserSuccess.type);
  const userId = ((yield select(userSelector)) as ReturnType<typeof userSelector>).userId!;
  const sessionToken = (yield select(sessionTokenSelector)) as ReturnType<typeof sessionTokenSelector>;
  const { graphEndpointWS } = (yield select(configSelector)) as ReturnType<typeof configSelector>;
  // open saga channel to get the notification from socket
  return eventChannel((emitter: (arg0: { type: string; payload: unknown }) => void) => {
    const query = gql`
          subscription {
            notificationPosted(notificationMailboxId:"${userId}") {
              id
              body
              title
              contentType
            }
          }`;
    const link = createApolloWs(graphEndpointWS, sessionToken, userId);
    const client = createApolloClient(link);
    const subscription: ObservableSubscription = client
      .subscribe({
        query,
      })
      .subscribe({
        next(data: { data: { notificationPosted: INotificationDetail } }) {
          // ignore json notifications
          if (data?.data?.notificationPosted?.contentType === 'json') {
            return;
          }

          const { title, body } = data.data.notificationPosted;
          if (title === MARK_ALL_NOTIFICATIONS_SEEN && body === userId) {
            emitter(markAllNotificationsSeenSuccess());
          } else {
            emitter(pushDefaultMailBox(data.data.notificationPosted));
          }
        },
      });

    // unsubscribe function
    return () => {
      subscription.unsubscribe();
      client.stop();
    };
  });
}
export function* websocketSagas(): Generator {
  if (subscriptionTask) {
    yield cancel(subscriptionTask);
  }
  subscriptionTask = yield fork(function* (): Generator {
    const channel = yield call(websocketInitChannel);
    while (true) {
      const action = yield take(channel as any);
      yield put(action as any);
    }
  });
}

export function* defaultMailboxSagas(): Generator {
  if (mailboxSubscription) {
    yield cancel(mailboxSubscription);
  }
  mailboxSubscription = yield fork(function* (): Generator {
    const channel = yield call(defaultMailboxChannel);
    while (true) {
      const action = yield take(channel as any);
      yield put(action as any);
    }
  });
}

export function* updateMailboxSetting() {
  yield websocketSagas();
}

export function* updateUnseenCountSaga(action: any): Generator {
  const graphEndpoint = (yield select(graphEndpointSelector)) as ReturnType<typeof graphEndpointSelector>;
  const token = (yield select(sessionTokenSelector)) as ReturnType<typeof sessionTokenSelector>;
  const panels = (yield select(panelSelector)) as ReturnType<typeof panelSelector>;
  const showNotificationPanel = panels.find(
    (panel: IPanel<IMicroFrontendConfig, IVeritoneAppbarPanelConfig>) => panel.panelId === notificationPanelId
  );
  try {
    if (showNotificationPanel) {
      yield delay(1000);
      yield markAllNotificationsSeenSaga();
    }
    yield delay(2000);
    const data = (yield call(getUnseenCountApi, graphEndpoint, token)) as Awaited<
      ReturnType<typeof getUnseenCountApi>
    >;
    const { mailboxId } = action.payload;
    yield put(
      updateUnseenCount({
        notificationMailboxes: (data as any)?.data?.notificationMailboxes,
        mailboxId,
      })
    );
  } catch (error) {
    yield put(markAllNotificationsSeenFail());
  }
}

export function* markAllNotificationsSeenSaga(): Generator {
  const mailBoxIds = (yield select(mailboxIdSelector)) as ReturnType<typeof mailboxIdSelector>;
  const userId = ((yield select(userSelector)) as ReturnType<typeof userSelector>).userId!;
  const graphEndpoint = (yield select(graphEndpointSelector)) as ReturnType<typeof graphEndpointSelector>;
  const token = (yield select(sessionTokenSelector)) as ReturnType<typeof sessionTokenSelector>;

  if (mailBoxIds?.length > 0) {
    try {
      const data = (yield call(handleMarkAllNotificationsSeen, mailBoxIds, graphEndpoint, token)) as Awaited<
        ReturnType<typeof handleMarkAllNotificationsSeen>
      >;
      if (!data.error) {
        yield put(markAllNotificationsSeenSuccess());
        yield call(pushDefaultMailboxNotification, userId, MARK_ALL_NOTIFICATIONS_SEEN, graphEndpoint, token);
      } else {
        yield put(markAllNotificationsSeenFail());
      }
    } catch (error) {
      yield put(markAllNotificationsSeenFail());
    }
  }
}

export function* mailboxSaga(): Generator {
  yield all([
    takeLatest(requestSubscriber.type, subscriberSaga), // for handling initial actions of module
    takeLatest(setMailboxData.type, websocketSagas), // for opening the socket after get the mailbox data
    takeLatest('UPDATE_MAILBOX_SETTING', updateMailboxSetting), // for re-connect socket after change the notification setting
    takeEvery(pushNotification.type, updateUnseenCountSaga),
    takeEvery(subscribeDefaultMailboxRequest.type, defaultMailboxSagas),
    takeLatest(markAllNotificationsSeenRequest.type, markAllNotificationsSeenSaga),
  ]);
}
