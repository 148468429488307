import { Tooltip } from '@mui/material';

/** This React Element will render a tooltip if the title content exceeds the maximum character length, otherwise
 * the original child is rendered without a tooltip. */
export const TooltipOnTextOverflow = ({
  title,
  maxCharLength,
  children,
}: {
  title: string;
  maxCharLength: number;
  children: JSX.Element;
}): JSX.Element => {
  if (title.length > maxCharLength) {
    return <Tooltip title={title}>{children}</Tooltip>;
  } else {
    return children;
  }
};
