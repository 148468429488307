import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../constants';
import { TUpdateOrganizationInviteInput } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'reviewRequestState';

export interface IReviewRequestState {
  status: LoadingStatus;
}

export const initialState: IReviewRequestState = {
  status: 'idle',
};

export const reviewRequestSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    updateOrganizationInviteStart(state, action: PayloadAction<TUpdateOrganizationInviteInput>) {
      state.status = 'pending';
    },
    updateOrganizationInviteSucceed(state) {
      state.status = 'success';
    },
  },
});

export const actions = reviewRequestSlice.actions;
export default reviewRequestSlice.reducer;
