import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  root: {
    padding: '15px',
  },
  groupNameInput: {
    height: '36px',
    '& input': {
      width: '100% !important',
      height: '36px',
      padding: '0 0 0 15px',
      color: 'rgba(92, 98, 105, 1)',
      borderRadius: '4px',
      border: 'none',
    },
    marginBottom: '10px',
    width: '100% !important',
    background: '#F5FDFF',
  },
  notInterested: {
    height: '18px',
    cursor: 'pointer',
  },
  confirm: {
    cursor: 'pointer',
  },
  groupHandler: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: 'rgba(242, 242, 242, 0.5)',
    },
  },
  invisibleHandler: {
    display: 'none',
  },
  groupTitle: {
    fontWeight: 700,
    padding: '15px',
  },
  handlerMenu: {
    height: '40px',
    width: '40px',
    marginRight: '10px',
  },
}));
