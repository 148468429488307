import HelpIcon from '@mui/icons-material/Help';
import { SxProps } from '@mui/system';
import has from 'lodash/has';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, MessageSeverity, preferredLanguageSelector } from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';

type Props = {
  className?: string;
  helpLink: string;
  sx?: SxProps;
};

declare global {
  interface Window {
    aiware: any; // eslint-disable-line
  }
}

const HelpDocs = ({ className, helpLink, sx }: Props) => {
  const dispatch = useDispatch();
  const locale = useSelector(preferredLanguageSelector);

  const formatMessage = AIWareFormatMessage(locale);

  const handleHelpIconClick = () => {
    if (!has(window, 'aiware.helpCenter.openDocsPanel')) {
      dispatch(
        showMessage({
          content: formatMessage({
            id: 'os-engine-center-new.snackbar.helpCenterUnavailable',
            defaultMessage: 'Help Center is not currently available.',
            description: 'Error message stating help center not available',
          })!,
          severity: MessageSeverity.Warning,
        })
      );
      return;
    }

    window.aiware.helpCenter.openDocsPanel(helpLink, false);
    setTimeout(() => {
      window.aiware.helpCenter.openDocsPanel(helpLink, false);
    }, 0);
  };

  return (
    <HelpIcon
      className={className}
      onClick={handleHelpIconClick}
      sx={sx}
      style={{ cursor: 'pointer' }}
      color="secondary"
    />
  );
};

export default HelpDocs;
