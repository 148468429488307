import { SVGProps } from 'react';

export function AdminTabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.667 13a5.333 5.333 0 100 10.667 5.333 5.333 0 000-10.667zM31 23.667c-.173 0-.32.12-.347.28l-.253 1.76c-.4.173-.787.386-1.133.626l-1.654-.666c-.146 0-.32 0-.413.173l-1.333 2.307a.33.33 0 00.08.426l1.413 1.094a5.582 5.582 0 000 1.333l-1.413 1.093a.346.346 0 00-.08.427l1.333 2.307c.08.173.253.173.413.173l1.654-.667c.346.24.72.467 1.133.627l.253 1.76c.027.16.16.28.347.28h2.667a.34.34 0 00.32-.28l.253-1.76c.4-.173.76-.387 1.12-.627L37 35c.173 0 .347 0 .44-.173l1.333-2.307a.346.346 0 00-.08-.427L37.267 31c.026-.227.053-.44.053-.667 0-.226-.013-.44-.053-.666l1.413-1.094c.12-.106.16-.28.08-.426l-1.333-2.307c-.08-.173-.254-.173-.427-.173l-1.64.666c-.36-.24-.72-.466-1.133-.626l-.254-1.76a.315.315 0 00-.306-.28H31zm-9.333 2.666C15.773 26.333 11 28.72 11 31.667v2.666h12.907a9.333 9.333 0 01-.907-4 9.333 9.333 0 01.853-3.88 19.49 19.49 0 00-2.186-.12zm10.666 2c1.107 0 2 .894 2 2 0 1.107-.893 2-2 2-1.12 0-2-.893-2-2 0-1.106.894-2 2-2z"
        fill="#555F7C"
      />
    </svg>
  );
}

export default AdminTabIcon;
