import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  title: {
    paddingTop: theme.spacing(5.5),
  },
  contentText: {
    fontSize: 14,
  },
  dialogActions: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(5),
  },
  signOutButton: {
    height: 34,
    marginRight: theme.spacing(1),
  },
  staySignedInButton: {
    height: 34,
  },
}));

export default useStyles;
