import { FC, ChangeEvent, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { selectApiConfigs } from '../../../redux/selectors';
import { checkPermissionSetName } from '../../../api/permissions.api';
import { TPermissionSet } from '../../../types';
import { useStyles } from './basic-tab.styles';
import { selectAdminCenterOrgParameter } from '@aiware/os/admin-center/shared';

export type TProps = {
  permissionSet: Partial<TPermissionSet>;
  onNameChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  nameExistsCheck: (value: boolean) => void;
  isPermissionProtected?: boolean;
};

const BasicTab: FC<TProps> = ({
  permissionSet,
  onNameChange,
  onDescriptionChange,
  nameExistsCheck,
  isPermissionProtected,
}) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const messageMaxChars = 300;
  const [nameExists, setNameExists] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const apiConfigs = useSelector(selectApiConfigs);
  const adminOrgId = useSelector(selectAdminCenterOrgParameter);

  const newNameMessage = intl.formatMessage({
    id: 'admin-center-permission-basic-name-label',
    defaultMessage: 'Name',
    description: 'Name placeholder',
  });

  const newDescriptionMessage = intl.formatMessage({
    id: 'admin-center-permission-basic-description-label',
    defaultMessage: 'Description',
    description: 'Description placeholder',
  });

  const newNamePlaceholder = intl.formatMessage({
    id: 'admin-center-permission-basic-name-placeholder',
    defaultMessage: 'Permission Set Name',
    description: 'Name placeholder',
  });

  const newDescriptionPlaceholder = intl.formatMessage({
    id: 'admin-center-permission-basic-description-placeholder',
    defaultMessage: 'This is the short Description for this Permission set',
    description: 'Description placeholder',
  });

  const descriptionErrorMsg = intl.formatMessage({
    id: 'admin-center-permission-basic-name-error',
    defaultMessage: 'Name already exists',
    description: 'Name error',
  });

  const descriptionLabelErrorMsg = intl.formatMessage({
    id: 'admin-center-permission-basic-description-error-label',
    defaultMessage: 'Please add a description',
    description: 'Name placeholder',
  });

  const nameLabel = nameExists ? descriptionErrorMsg : newNameMessage;
  const descriptionLabel = descriptionError ? descriptionLabelErrorMsg : newDescriptionMessage;

  const handleNameChange = async (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.value;
    onNameChange(name);
    const result = await checkPermissionSetName(apiConfigs, name, adminOrgId);
    const index = result?.data.authPermissionSets.records.findIndex(
      (object: TPermissionSet) => object.name === name
    );

    if (index !== -1) {
      setNameExists(true);
      nameExistsCheck(true);
    } else {
      setNameExists(false);
      nameExistsCheck(false);
      onNameChange(name);
    }
  };

  const handleDescriptionChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const desc = ev.target.value;
    if (desc) {
      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }
    onDescriptionChange(desc);
  };

  const debouncedNameChange = debounce(handleNameChange, 300);

  return (
    <div data-testid="admin-center.permission-set.basic-tab">
      <TextField
        id="admin-center-permission-basic-name"
        data-testid="admin-center.permission-set.basic-tab.name"
        InputLabelProps={{
          shrink: true,
        }}
        label={nameLabel}
        defaultValue={permissionSet.name || ''}
        placeholder={newNamePlaceholder}
        className={classes.newName}
        onChange={debouncedNameChange}
        error={nameExists}
        disabled={isPermissionProtected}
      />
      <div className={classes.descriptionContainer}>
        <TextField
          id="admin-center-permission-basic-description"
          data-testid="admin-center.permission-set.basic-tab.description"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={4}
          defaultValue={permissionSet.description || ''}
          label={descriptionLabel}
          placeholder={newDescriptionPlaceholder}
          inputProps={{ maxLength: messageMaxChars }}
          onChange={handleDescriptionChange}
          error={descriptionError}
          className={classes.basicDescription}
          disabled={isPermissionProtected}
        />
        <div
          className={classes.descriptionCharCount}
          data-testid="admin-center-permission-basic-description-char-count"
        >
          {permissionSet.description?.length || 0}/{messageMaxChars}
        </div>
      </div>
    </div>
  );
};

export default BasicTab;
