import React from 'react';
import { Utils, DateFormatter } from '@aiware/shared/reusable-utils';
import { TUserGroup } from '../../../../types';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Lock, MoreVert } from '@aiware/shared/icons';
import { SHARED_TEXT } from '../../../../helper/shared-text';

const DESCRIPTION_LENGTH = 160;
const NAME_LENGTH = 50;
export const ITEM_HEIGHT = 80;

export const GroupsTableRow = ({
  item,
  index: _index,
  onClick,
}: {
  item: TUserGroup;
  index: number;
  onClick: () => void;
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: `${ITEM_HEIGHT - 1}px`,
        borderBottom: '1px solid #D5DFE9',
        '&:hover': {
          backgroundColor: '#f2f5f9',
          cursor: 'pointer',
        },
      }}
    >
      <Grid
        style={{
          height: '100%',
          flexGrow: 1,
        }}
        container
      >
        <Grid
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          container
          xs={12}
          sx={{ pl: 3, cursor: 'pointer' }}
          onClick={onClick}
          data-test="os-admin-center-groups-table-row"
        >
          <Grid item xs={7} sx={{ pr: 3 }}>
            <Typography
              variant="body1"
              color="textPrimary"
              data-testid="admin-center.group-row.name"
              data-test="admin-center.group-row.name"
            >
              {Utils.truncatedString(item.name, NAME_LENGTH)}
              {item?.isProtected && <ProtectedIcon />}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              data-testid="admin-center.group-row.description"
              data-test="admin-center.group-row.description"
            >
              {Utils.truncatedString(item?.description, DESCRIPTION_LENGTH)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <DateFormatter value={item?.createdAt} />
          </Grid>
          <Grid display="flex" justifyContent="space-between" alignItems="center" item xs={3}>
            <Typography
              variant="body2"
              color="textSecondary"
              data-testid="admin-center.group-row.members-count"
              data-test="admin-center.group-row.members-count"
            >
              {item?.members?.count}
            </Typography>
            <IconButton sx={{ mr: 2 }} size="small">
              <MoreVert fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ProtectedIcon = () => {
  return (
    <Tooltip title={SHARED_TEXT.groupsTable.rowItem.protected}>
      <Lock
        sx={{ ml: 1, transform: 'translate(-2px, 4px) scale(0.8)' }}
        fontSize="small"
        data-test="os-admin-center-protected-group"
      />
    </Tooltip>
  );
};
