import { selectionShape, DistributionType } from '../types';

const distributionTypesData = (commonName: string): Record<string, selectionShape> => ({
  private: {
    formName: 'distributionType',
    name: 'Private',
    formattedName: 'Private',
    iconName: 'Private',
    selectedIconName: 'PrivateWhite',
    shortDescription: `Your ${commonName} will not be distributed and is private to your instance.`,
    description: `Your ${commonName} will not be distributed and is private to your instance.`,
    value: DistributionType.Private,
    link: '',
  },

  org_locked: {
    formName: 'distributionType',
    name: 'Org Locked',
    formattedName: 'Org Locked',
    iconName: 'OrgLocked',
    selectedIconName: 'OrgLockedWhite',
    shortDescription: `Your ${commonName} will only be distributed to instances within your organization.`,
    description: `Your ${commonName} will only be distributed to instances within your organization.`,
    value: DistributionType.OrgLocked,
    link: '',
  },

  sharable: {
    formName: 'distributionType',
    name: 'Sharable',
    formattedName: 'Sharable',
    iconName: 'Sharable',
    selectedIconName: 'SharableWhite',
    shortDescription: `Your ${commonName} can be distributed to other organizations on any instance.`,
    description: `Your ${commonName} can be distributed to other organizations on any instance.`,
    value: DistributionType.Sharable,
    link: '',
  },

  public: {
    formName: 'distributionType',
    name: 'Public',
    formattedName: 'Public',
    iconName: 'Public',
    selectedIconName: 'PublicWhite',
    shortDescription: `Your ${commonName} will be distributed to all instances in and outside of your organization.`,
    description: `Your ${commonName} will be distributed to all instances in and outside of your organization.`,
    value: DistributionType.Public,
    link: '',
  },

  instance_locked: {
    formName: 'distributionType',
    name: 'Instance locked',
    formattedName: 'Instance locked',
    iconName: 'InstanceLocked',
    selectedIconName: 'InstanceLockedWhite',
    shortDescription: `Your ${commonName} will be sharable to other organizations on your instance only.`,
    description: `Your ${commonName} will be sharable to other organizations on your instance only.`,
    value: 'instance_locked',
    link: '',
  },

  marketplace: {
    formName: 'distributionType',
    name: 'Marketplace',
    formattedName: 'Marketplace',
    iconName: 'Marketplace',
    selectedIconName: 'MarketplaceWhite',
    shortDescription: 'Coming soon...',
    description: 'Coming soon...',
    value: DistributionType.Marketplace,
    link: '',
  },
});

export default distributionTypesData;
