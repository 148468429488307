import createSvgIcon from '../createSvgIcon';
export const PersonalProfile = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0002 1.83325C9.79638 1.83325 8.60438 2.07035 7.49223 2.53102C6.38008 2.99169 5.36955 3.6669 4.51835 4.51811C2.79927 6.23719 1.8335 8.56877 1.8335 10.9999C1.8335 13.4311 2.79927 15.7626 4.51835 17.4817C5.36955 18.3329 6.38008 19.0081 7.49223 19.4688C8.60438 19.9295 9.79638 20.1666 11.0002 20.1666C13.4313 20.1666 15.7629 19.2008 17.482 17.4817C19.2011 15.7626 20.1668 13.4311 20.1668 10.9999C20.1668 9.79613 19.9297 8.60414 19.4691 7.49199C19.0084 6.37984 18.3332 5.36931 17.482 4.51811C16.6308 3.6669 15.6202 2.99169 14.5081 2.53102C13.3959 2.07035 12.2039 1.83325 11.0002 1.83325V1.83325ZM6.481 16.7566C6.87516 15.9316 9.27683 15.1249 11.0002 15.1249C12.7235 15.1249 15.1252 15.9316 15.5193 16.7566C14.2727 17.7466 12.7052 18.3333 11.0002 18.3333C9.29516 18.3333 7.72766 17.7466 6.481 16.7566ZM16.8302 15.4274C15.5193 13.8324 12.3385 13.2916 11.0002 13.2916C9.66183 13.2916 6.481 13.8324 5.17016 15.4274C4.23516 14.2083 3.66683 12.6683 3.66683 10.9999C3.66683 6.95742 6.95766 3.66659 11.0002 3.66659C15.0427 3.66659 18.3335 6.95742 18.3335 10.9999C18.3335 12.6683 17.7652 14.2083 16.8302 15.4274ZM11.0002 5.49992C9.22183 5.49992 7.79183 6.92992 7.79183 8.70825C7.79183 10.4866 9.22183 11.9166 11.0002 11.9166C12.7785 11.9166 14.2085 10.4866 14.2085 8.70825C14.2085 6.92992 12.7785 5.49992 11.0002 5.49992ZM11.0002 10.0833C10.6355 10.0833 10.2858 9.93839 10.0279 9.68052C9.77003 9.42266 9.62516 9.07292 9.62516 8.70825C9.62516 8.34358 9.77003 7.99384 10.0279 7.73598C10.2858 7.47812 10.6355 7.33325 11.0002 7.33325C11.3648 7.33325 11.7146 7.47812 11.9724 7.73598C12.2303 7.99384 12.3752 8.34358 12.3752 8.70825C12.3752 9.07292 12.2303 9.42266 11.9724 9.68052C11.7146 9.93839 11.3648 10.0833 11.0002 10.0833Z"
      fill="#555F7C"
    />
  </svg>,
  'PersonalProfile'
);
