import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSchemaSearchValue,
  selectSelectedSchema,
  selectIsAllSchemasActive,
} from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { actions } from '../../../../../state/aiStructuredData/aiStructuredData.slice';
import { ChangeEvent, MouseEvent, useCallback, useRef, useState } from 'react';
import { Search } from '@mui/icons-material';
import { tableInfiniteScroll } from '@aiware/shared/reusable-utils';
import { schemaSearch as schemaSelectors } from '../../../../../../../redux/selectors';
import { actions as browseActions } from '../../../../../../../redux/slices/';
import { ISchema } from '../../../../../../../types';
import { debounce } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import translations from './translations';
import { FormattedMessage } from 'react-intl';

const {
  jsx: { TableInfiniteScroll },
} = tableInfiniteScroll;

const rowHeight = 30;

interface ISelectableSchema {
  name: string | JSX.Element;
  dataRegistryId: string;
  majorVersion?: string;
  minorVersion?: string;
  onSelect: () => void;
}

export const useStyles = makeStyles()(_theme => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #E1E6ED',
    borderRadius: '7px',
    margin: '0px',
    padding: '8px',
    width: '100%',
  },
  searchInput: {
    border: '0',
    outline: 'none',
    width: 'calc(100% - 30px)',
    fontFamily: "'Nunito'",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
}));

const Row = (props: { item: ISelectableSchema }) => {
  const name = props.item.name || 'Unnamed Schema';
  const version =
    props.item.majorVersion && props.item.minorVersion
      ? `v${props.item.majorVersion}.${props.item.minorVersion}`
      : '';
  return (
    <Tooltip
      title={`${name} ${version}`}
      disableInteractive
      placement="top-start"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -15],
            },
          },
        ],
      }}
    >
      <MenuItem
        data-testid={`dc-structured-data-schema-item-${name}`}
        sx={{ height: `${rowHeight}px`, maxWidth: '528px' }}
        onClick={props.item.onSelect}
      >
        <Box overflow="hidden" textOverflow="ellipsis">
          {name}
          <Typography variant="caption" marginLeft={theme => theme.spacing(1)}>
            {version}
          </Typography>
        </Box>
      </MenuItem>
    </Tooltip>
  );
};

export const SchemaDropdown = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const searchValue = useSelector(selectSchemaSearchValue);
  const selectedSchema = useSelector(selectSelectedSchema);
  const isAllSchemasActive = useSelector(selectIsAllSchemasActive);
  const loadingStatus = useSelector(schemaSelectors.selectReadStatus);
  const hasMore = useSelector(schemaSelectors.selectHasMore);
  const fetchMore = () => dispatch(browseActions.schemaSearch['readStart']!(null));
  const selectRef = useRef(null);
  const items: ISelectableSchema[] = useSelector(schemaSelectors.selectItems).map(schema => ({
    dataRegistryId: schema.dataRegistry.id,
    name: schema.dataRegistry.name,
    majorVersion: String(schema.majorVersion),
    minorVersion: String(schema.minorVersion),
    onSelect: () => handleSelectSchema(schema),
  }));
  const [shouldAppendAllSchemas, setShouldAppendAllSchemas] = useState(true);
  if (shouldAppendAllSchemas && items.length > 0 && loadingStatus !== 'pending') {
    items.unshift({
      dataRegistryId: 'all-schemas',
      name: translations.allSchemas(),
      onSelect: () => {
        dispatch(
          actions.setSelectedSchema({
            dataRegistryId: null,
            name: null,
            majorVersion: null,
            minorVersion: null,
          })
        );
        dispatch(actions.setIsAllSchemasActive(true));
        setIsOpen(false);
      },
    });
  }

  const performSearch = useCallback(
    debounce(allSchemas => {
      dispatch(browseActions.schemaSearch['resetList']!(null));
      dispatch(browseActions.schemaSearch['readStart']!(null));
      setShouldAppendAllSchemas(allSchemas);
    }, 500),
    [dispatch]
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setSchemaSearchValue(e.target.value));
    performSearch.cancel();
    performSearch(e.target.value === '');
  };
  const handleSearchClick = (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelectSchema = (schema: ISchema) => {
    dispatch(actions.setIsAllSchemasActive(false));
    dispatch(
      actions.setSelectedSchema({
        dataRegistryId: schema.dataRegistry.id,
        name: schema.dataRegistry.name,
        majorVersion: String(schema.majorVersion || 1),
        minorVersion: String(schema.minorVersion || 0),
      })
    );
    dispatch(actions.setSelectedProperty(null));
    setIsOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleOpen();
  };

  const tableMaxHeight = Math.min(350, rowHeight * items.length) + 1;

  return (
    <FormControl
      sx={{
        '& .Sdk-MuiFormLabel-root.Sdk-MuiInputLabel-root': {
          padding: '0px',
        },
      }}
      size="small"
    >
      <InputLabel
        id="dc-structured-data-schema-select-label"
        shrink
        sx={{
          background: theme => theme.palette.background.default,
        }}
      >
        {translations.schema()}
      </InputLabel>
      <Select
        labelId="dc-structured-data-schema-select-label"
        id="dc-structured-data-schema-select"
        variant="outlined"
        multiple={false}
        displayEmpty
        onClick={handleSelectClick}
        open={isOpen}
        ref={selectRef}
        renderValue={() => {
          if (selectedSchema.name) {
            return selectedSchema.name;
          }
          if (isAllSchemasActive) {
            return translations.allSchemas();
          }
          return translations.chooseASchema();
        }}
        MenuProps={{
          container: selectRef.current,
          PaperProps: {
            sx: {
              padding: 0,
            },
          },
        }}
      >
        <MenuItem
          disableRipple
          focusRipple={false}
          sx={{
            backgroundColor: 'transparent !important',
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
            paddingBottom: '10px',
          }}
          dense
          onKeyDown={e => e.stopPropagation()}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className={classes.searchContainer}>
            <Search />
            <FormattedMessage
              id="dc-structured-data-search-schemas-search-schemas"
              defaultMessage="Search Schemas"
              description="Search Schemas"
            >
              {placeholder => (
                <input
                  data-test="dc-structured-data-search-schema-search input"
                  className={classes.searchInput}
                  onChange={handleSearchChange}
                  onClick={handleSearchClick}
                  value={searchValue}
                  placeholder={String(placeholder)}
                />
              )}
            </FormattedMessage>
          </div>
          <Divider />
        </MenuItem>
        <Box
          flexGrow={1}
          padding="0"
          minHeight={rowHeight}
          height={tableMaxHeight}
          borderTop="1px solid"
          borderColor={theme => theme.palette.divider}
        >
          <TableInfiniteScroll
            offset={0}
            hasMore={hasMore}
            fetchMore={fetchMore}
            fetchOnMount={items.length === 0}
            items={items}
            itemHeight={rowHeight}
            isLoading={loadingStatus === 'pending'}
            error={loadingStatus === 'failure'}
            TableRowComponent={Row}
            MessageEmptyState={translations.noSchemasFound()}
            MessageErrorState={translations.errorLoadingSchemas()}
          />
        </Box>
      </Select>
    </FormControl>
  );
};
