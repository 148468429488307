import { EventMap } from './types';

type EventCallback<T> = (data: T) => void;

export class EventBus {
  private targetOrigin = '*';
  private targetWindow: Window | null = null;

  private static instance: EventBus;
  private subscribers: Map<keyof EventMap, Array<EventCallback<EventMap[keyof EventMap]>>> = new Map();

  private constructor() {
    // Initialize message listener
    if (typeof window !== 'undefined') {
      window.addEventListener('message', this.handleIncomingMessage.bind(this));
    }
  }

  public static getInstance(): EventBus {
    if (!EventBus.instance) {
      EventBus.instance = new EventBus();
    }
    return EventBus.instance;
  }

  // Initialize connection with target website
  public connect(targetWindowRef?: Window | null, targetOrigin = '*'): void {
    if (typeof window === 'undefined') return;

    this.targetOrigin = targetOrigin;
    this.targetWindow = targetWindowRef || window.parent;
  }

  private handleIncomingMessage(event: MessageEvent): void {
    // Verify origin for security
    if (this.targetOrigin !== '*' && event.origin !== this.targetOrigin) {
      console.warn(`Received message from unauthorized origin: ${event.origin}`);
      return;
    }

    try {
      const { type, payload } = event.data;
      if (type && this.subscribers.has(type as keyof EventMap)) {
        const callbacks = this.subscribers.get(type as keyof EventMap) || [];
        callbacks.forEach(callback => callback(payload));
      }
    } catch (error) {
      console.error('Error processing message:', error);
    }
  }

  public subscribe<K extends keyof EventMap>(event: K, callback: EventCallback<EventMap[K]>): () => void {
    const callbacks = this.subscribers.get(event) || [];
    callbacks.push(callback as EventCallback<EventMap[keyof EventMap]>);
    this.subscribers.set(event, callbacks);

    // Return unsubscribe function
    return () => this.unsubscribe(event, callback);
  }

  public unsubscribe<K extends keyof EventMap>(event: K, callback: EventCallback<EventMap[K]>): void {
    const callbacks = this.subscribers.get(event) || [];
    this.subscribers.set(
      event,
      callbacks.filter(cb => cb !== callback)
    );
  }

  public publish<K extends keyof EventMap>(event: K, data: EventMap[K]): void {
    if (!this.targetWindow || !this.targetOrigin) {
      console.error('EventBus not properly initialized. Call connect() first.');
      return;
    }

    if (!data) return;

    try {
      this.targetWindow.postMessage(
        {
          type: event,
          payload: data,
        },
        this.targetOrigin
      );
    } catch (error) {
      console.error('Error publishing message:', error);
    }
  }

  // Cleanup method
  public disconnect(): void {
    if (typeof window === 'undefined') return;

    window.removeEventListener('message', this.handleIncomingMessage);
    this.subscribers.clear();
    this.targetWindow = null;
  }
}
