import { all } from 'redux-saga/effects';
import {
  createUserGroupWatcher,
  getOrgUserGroupsWatcher,
  updateUserGroupsWatcher,
  addMembersToUserGroupsWatcher,
  removeMembersFromUserGroupsWatcher,
  deleteUserGroupsWatcher,
} from './admin-center-groups.watcher';

export function* rootSaga() {
  yield all([
    createUserGroupWatcher(),
    getOrgUserGroupsWatcher(),
    updateUserGroupsWatcher(),
    addMembersToUserGroupsWatcher(),
    removeMembersFromUserGroupsWatcher(),
    deleteUserGroupsWatcher(),
  ]);
}
