import { MouseEvent, ReactElement } from 'react';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  primaryColor: {
    color: '#1871E8',
  },
  secondaryColor: {
    color: '#8D0707',
  },
  checked: {},
}));

interface ColorButtonProps extends ButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  children?: ReactElement;
  colorButton?: string;
}

function ColorButton({ children, onClick, colorButton, ...props }: ColorButtonProps) {
  const { classes } = useStyles();
  const handleOnClick = (e: MouseEvent<HTMLElement>) => {
    e && e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      className={colorButton === 'primary' ? classes.primaryColor : classes.secondaryColor}
      {...props}
      onClick={handleOnClick}
      data-test="dt-button-color-icon"
      data-testid="dt-button-color-icon"
    >
      {children}
    </Button>
  );
}

export default ColorButton;
