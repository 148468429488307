import { MessageDescriptor } from 'react-intl';
import isEqual from 'lodash/isEqual';

export type TFormatter = (message: MessageDescriptor, values?: { [key: string]: string }) => string;

export const safeFormatMessage = (formatter: TFormatter): TFormatter => {
  if (isEqual(process.env.NODE_ENV, 'development')) {
    return message => {
      if (Array.isArray(message.defaultMessage)) {
        return (message.defaultMessage[0] as { value: string }).value;
      }
      return message.defaultMessage as string;
    };
  } else {
    return formatter;
  }
};
