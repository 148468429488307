import { LoadingButton } from '@mui/lab';

interface DefaultSubmitButtonProps {
  isSubmitting: boolean;
  loading: boolean;
  isDirty: boolean;
  isValid: boolean;
  submitText: string;
}

export const DefaultSubmitButton: React.FC<DefaultSubmitButtonProps> = ({
  isSubmitting,
  loading,
  isDirty,
  isValid,
  submitText,
}) => (
  <LoadingButton
    type="submit"
    variant="contained"
    color="primary"
    fullWidth
    size="large"
    loading={isSubmitting || loading}
    disabled={!isDirty || !isValid}
  >
    {submitText}
  </LoadingButton>
);
