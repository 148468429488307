import { Chip, Stack, Tooltip, ChipProps } from '@mui/material';
import { Launch, Link } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { SxProps } from '@mui/system';

export type TURLChipProps = {
  value: string;
  ChipProps?: ChipProps;
  sx?: SxProps;
  testId?: string;
};

const isURL = (value: any) => {
  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
};

export const URLChip: React.FC<TURLChipProps> = ({
  value,
  testId = 'shared-url-chip',
  ChipProps = {},
  sx = {},
}) => {
  const _ChipProps = {
    target: '_blank',
    ...ChipProps,
    component: 'a',
    href: value,
  };
  let label = value;

  if (isURL(value)) {
    const url = new URL(value);
    label = url.hostname;
  }

  return (
    <Tooltip
      disableInteractive
      title={
        <Stack direction={'row'} alignItems={'center'} gap={'5px'}>
          <FormattedMessage
            id="shared-reusable-utils.url-chip.chip-title"
            defaultMessage="Visit Link "
            description="URL Chip tooltip label"
          />
          <Launch
            sx={{
              fontSize: '14px',
            }}
          />
        </Stack>
      }
    >
      <Chip
        sx={sx}
        label={label}
        icon={
          <Link
            sx={{
              color: 'inherit',
            }}
            fontSize={'small'}
          />
        }
        clickable
        {..._ChipProps}
        data-testid={testId}
      />
    </Tooltip>
  );
};
