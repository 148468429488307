import createSvgIcon from '../createSvgIcon';

export const ImpersonateIcon = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 11.7104C17.9868 12.8231 17.8746 13.9566 17.703 15.0832C17.3663 17.2465 15.7292 18.0897 14.0856 17.9791C12.7257 17.8893 11.6564 17.1912 10.7916 16.1614C9.83447 15.0279 8.21721 15.0348 7.26665 16.1821C6.36231 17.281 5.21372 17.986 3.80109 17.9998C2.19043 18.0137 0.698583 17.1635 0.361928 15.1385C0.177098 13.9912 0.058278 12.8369 0.00546944 11.6758C-0.0407381 10.6944 0.216704 9.76133 0.368529 8.80755C0.639173 7.0935 0.857009 5.37253 1.00223 3.63775C1.08145 2.69779 1.17386 1.75782 1.20686 0.817861C1.21347 0.700365 1.23327 0.589782 1.25307 0.479198C1.28608 0.285676 1.31908 0.0575954 1.53032 0.00921497C1.72175 -0.032254 1.84717 0.147445 1.95279 0.292587C2.68551 1.28784 3.37202 2.31074 4.01232 3.37511C4.28957 3.83127 4.55361 4.29434 4.81765 4.76432C4.93647 4.97858 5.06189 5.04078 5.29953 4.95785C6.40851 4.55698 7.5571 4.34272 8.72549 4.28743C9.80807 4.23214 10.8708 4.45331 11.9138 4.73668C12.1779 4.80579 12.4485 4.87491 12.7059 4.97858C12.9436 5.07534 13.0558 5.00623 13.1746 4.78506C13.9535 3.31291 14.8711 1.93061 15.8216 0.575958C15.9273 0.430817 16.0263 0.278763 16.1517 0.147445C16.3563 -0.073723 16.528 -0.0460761 16.6666 0.230384C16.7524 0.396259 16.7854 0.575957 16.7986 0.762567C16.9108 2.15869 17.0032 3.56172 17.1419 4.95785C17.3201 6.81704 17.6171 8.65549 17.9406 10.487C18.0132 10.8741 17.9802 11.275 18 11.7104ZM4.4942 14.4888C5.20712 14.475 5.88043 14.2815 6.54053 14.0119C6.80458 13.9013 6.99601 13.7009 7.12803 13.4382C7.24685 13.2033 7.21384 13.0374 6.9762 12.8992C6.06526 12.3808 5.1213 11.9385 4.11134 11.7104C3.60966 11.5929 3.10137 11.4961 2.57989 11.5514C2.38846 11.5721 2.20363 11.6067 2.21023 11.8832C2.23663 13.2171 2.89674 14.5303 4.4942 14.4888ZM13.4915 14.4819C14.6995 14.4819 15.5774 13.6871 15.7754 12.4084C15.9141 11.5376 15.8414 11.4616 14.9899 11.5445C13.6103 11.6758 12.3495 12.1942 11.1481 12.8853C10.7982 13.0858 10.7586 13.3346 11.0161 13.6594C11.1415 13.8184 11.2867 13.9704 11.4781 14.0395C12.1316 14.2745 12.7918 14.4888 13.4915 14.4819Z"
      fill="white"
    />
  </svg>,
  'ImpersonateIcon'
);
