import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { ChipComponents } from '../chips';
import { StatusPill } from '../status-pill/status-pill';
import { ContextMenu, TContextMenuItem } from '../context-menu/context-menu';

export const itemHeight = 60;

const { ColorSwatchChip } = ChipComponents;

export const CustomLoginTableRow = ({
  index,
  onEdit,
  onPreview,
  onDelete,
  buttonColor,
  buttonTextColor,
  logoUrl,
  name,
  slug,
  enabled,
  testId,
}: {
  index: number;
  onEdit: () => void;
  onDelete: () => void;
  onPreview: () => void;
  buttonColor: string;
  buttonTextColor: string;
  logoUrl: string;
  name: string;
  slug: string;
  enabled: boolean;
  testId: string;
}) => {
  const minHeight = `${itemHeight - 1}px`;
  const renderedSlug = `/${slug}`;

  return (
    <Box
      sx={{
        width: '100%',
        minHeight,
        borderBottom: '1px solid #D5DFE9',
      }}
      data-testid={`${testId}-table-row-${index}`}
    >
      <Grid
        data-testid={`${testId}-custom-login-item`}
        sx={{ width: '100%', height: '100%', paddingLeft: '15px' }}
        alignItems="center"
        display="flex"
        container
      >
        <Grid item xs={2}>
          <Typography data-testid="shared-utils-custom-login-table-row-name">{name}</Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography
            sx={{
              fontWeight: 'bold',
              padding: '2px 5px',
              fontSize: 'small',
              wordBreak: 'break-all',
            }}
          >
            <span data-testid="shared-utils-custom-login-table-row-slug">{renderedSlug}</span>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Stack gap={'5px'}>
            <ColorSwatchChip
              color={buttonColor}
              tooltipLabel={'Button Color'}
              testId={'shared-utils-custom-login-table-row-button-color'}
            />
            <ColorSwatchChip
              color={buttonTextColor}
              tooltipLabel={'Button Text Color'}
              testId={'shared-utils-custom-login-table-row-button-text-color'}
            />
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <LogoThumbnail src={logoUrl} />
        </Grid>
        <Grid item xs={2}>
          <StatusPill statusType={'enabledStatus'} status={enabled ? 'enabled' : 'disabled'} />
        </Grid>
        <Grid item xs={2}>
          <CustomLoginContextMenu onEdit={onEdit} onPreview={onPreview} onDelete={onDelete} testId={testId} />
        </Grid>
      </Grid>
    </Box>
  );
};

const LogoThumbnail = ({ src }: { src: string }) => (
  <Box
    sx={{
      width: '80px',
      height: src ? '50px' : '',
      overflow: 'hidden',
    }}
    data-testid="login-table-row-logo-container"
  >
    {src ? (
      <img
        draggable={false}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={src}
        alt="logo"
        data-testid="login-table-row-logo-thumbnail"
      />
    ) : (
      '--'
    )}
  </Box>
);

type TCustomLoginContextMenuProps = {
  onEdit: () => void;
  onDelete: () => void;
  onPreview: () => void;
  testId: string;
};

export const CustomLoginContextMenu: React.FC<TCustomLoginContextMenuProps> = ({
  onEdit,
  onPreview,
  onDelete,
  testId,
}) => {
  const menuItems: TContextMenuItem[] = [
    {
      label: (
        <FormattedMessage
          id="shared-utils-custom-login-table-row-context-menu-edit"
          defaultMessage="Edit"
          description="Admin Center Custom Login table row context menu edit"
        />
      ),
      onClick: onEdit,
      testId: 'edit',
      icon: <EditOutlined fontSize={'small'} />,
    },
    {
      label: (
        <FormattedMessage
          id="shared-utils-custom-login-table-row-context-menu-preview"
          defaultMessage="Preview"
          description="Admin Center Custom Login table row context menu preview"
        />
      ),
      onClick: onPreview,
      testId: 'preview',
      icon: <VisibilityOutlined fontSize={'small'} />,
    },
    {
      label: (
        <FormattedMessage
          id="shared-utils-custom-login-table-row-context-menu-delete"
          defaultMessage="Delete"
          description="Admin Center Custom Login table row context menu delete"
        />
      ),
      onClick: onDelete,
      testId: 'delete',
      icon: <DeleteOutline fontSize={'small'} />,
    },
  ];

  return <ContextMenu menuItems={menuItems} testId={`${testId}-custom-login-menu`} />;
};
