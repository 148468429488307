import { Typography } from '@mui/material';
import { TabPanel, Tabs } from '@aiware/ui';
import { FormattedMessage, injectIntl, WithIntlProps } from 'react-intl';
import { TIntlProps } from '../../types';
import Header from '../components/header';
import { formattedMessages } from '../helpers/formatted-messages';
import { GroupMember } from './group-member';
import { useStyles as tabStyles } from '../components/tabs/tabs.styles';
import { userGroupsApi as groupsAPI } from '@aiware/os/admin-center/groups';
import { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { selectApiConfigs } from '@aiware/shared/redux';
import { renderWhenReady } from '../helpers/utils';
import { LoadingStatus } from '@aiware/js/interfaces';
import { hidePanel } from '@aiware/js/panel';
import { useDispatch } from 'react-redux';

const GROUP_INFO_PANEL = 'GROUP_INFO_PANEL';

type TProps = TIntlProps & { groupId: string };

export const GroupInfoPanel: FC<WithIntlProps<TProps>> = injectIntl(({ intl, groupId }: TProps) => {
  const [loadingStatus, setLoadingStatus] = useState('loading' as LoadingStatus);
  const [group, setGroup] = useState({} as any);
  const apiConfigs = useSelector(selectApiConfigs);
  const {
    default: { userGroupsApi },
  } = groupsAPI;

  const userGroup = async () => {
    const {
      data: { authGroup: fetchedGroup },
    } = await userGroupsApi.getGroupById(apiConfigs, groupId);
    setGroup(fetchedGroup);
    setLoadingStatus('success' as LoadingStatus);
  };
  useEffect(() => {
    userGroup().catch(err => console.log(err));
  }, []);
  const { classes } = tabStyles();
  const { groupInfoPanelHeader, permissionsPanelClose } = formattedMessages(intl);
  const tabsList = [
    {
      label: (
        <div>
          <FormattedMessage
            id="group-info-panel.tabs.members-label"
            defaultMessage="Members"
            description="Group members"
          />{' '}
          ({group?.members?.count})
        </div>
      ),
      disabled: false,
      id: 'members',
    },
  ];
  const dispatch = useDispatch();
  const handleClose = (panelId: string) => {
    dispatch(hidePanel(panelId));
  };
  return (
    <div data-testid="group-info-panel" data-test="group-info-panel">
      <Header
        panelId={GROUP_INFO_PANEL}
        permissionsPanelHeader={groupInfoPanelHeader}
        permissionsPanelClose={permissionsPanelClose}
        iconType="close"
        dataTestIdPrefix="group-info"
        handleClose={handleClose}
      />
      <div className={classes.groupInfoContainer}>
        {renderWhenReady(
          loadingStatus,
          <>
            <Typography variant="h2">{group?.name}</Typography>
            <Typography variant="body2">{group?.description}</Typography>
          </>,
          true
        )}
      </div>
      <div className={classes.tabsContainer}>
        {renderWhenReady(
          loadingStatus,
          <div className={classes.tabsParent}>
            <Tabs selectedId={0} onChangeTab={() => ({})} tabsList={tabsList} />
          </div>
        )}
        {renderWhenReady(
          loadingStatus,
          <TabPanel value={0} index={0}>
            <div className={classes.groupMemberCardsContainer}>
              {group
                ? group?.members?.records.map(({ member }: { member: any }) => (
                    <GroupMember member={member} key={member.id} />
                  ))
                : null}
            </div>
          </TabPanel>,
          true
        )}
      </div>
    </div>
  );
});
