import React, { useState } from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { devErrorLogger } from '@aiware/shared/reusable-utils';
import { CheckCircle, ContentCopy } from '@mui/icons-material';

const IdCopy = ({ id }: { id: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();

  const iconSx = {
    marginLeft: '5px',
    fontSize: '18px!important',
    verticalAlign: 'middle',
    '&:active': {
      color: theme.palette.action.active,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={id}>
        <Typography style={{ maxWidth: '90px' }} data-test={'copy-id'} noWrap variant="body2">
          {id}
        </Typography>
      </Tooltip>
      <Tooltip
        title={
          isCopied ? (
            <FormattedMessage
              id="os-data-center-browse.shared.copied.id"
              defaultMessage="Copied!"
              description="Copied - tooltip"
            />
          ) : (
            <FormattedMessage
              id="os-data-center-browse.shared.copy.id"
              defaultMessage="Copy ID "
              description="Copy ID - tooltip"
            />
          )
        }
        disableInteractive
      >
        <span
          onMouseLeave={() => setTimeout(() => setIsCopied(false), 300)}
          onClick={e => {
            setIsCopied(true);
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(id).catch(error => {
              devErrorLogger(`Error writing text to clipboard: ${JSON.stringify(error)}`);
            });
          }}
        >
          {!isCopied && <ContentCopy sx={iconSx} />}
          {isCopied && <CheckCircle sx={iconSx} />}
        </span>
      </Tooltip>
    </div>
  );
};

export default IdCopy;
