import { FormattedMessage } from "react-intl";

export const translations = {
    schema: () => (
        <FormattedMessage
            id="dc-structured-data-search-schemas-schema"
            defaultMessage="Schema"
            description="Schema"
        />
    ),
    chooseASchema: () => (
        <FormattedMessage
            id="dc-structured-data-search-schemas-choose-schema"
            defaultMessage="Choose a Schema"
            description="Choose a Schema"
        />
    ),
    allSchemas: () => (
        <FormattedMessage
            id="dc-structured-data-search-schemas-all-schemas"
            defaultMessage="All Schemas"
            description="All Schemas"
        />
    ),
    noSchemasFound: () => (
        <FormattedMessage
            id="dc-structured-data-search-schemas-no-schemas"
            defaultMessage="No schemas found"
            description="No schemas found"
        />
    ),
    errorLoadingSchemas: () => (
        <FormattedMessage
            id="dc-structured-data-search-schemas-error-loading-schemas"
            defaultMessage="Error loading schemas"
            description="Error loading schemas"
        />
    ),

}

export default translations;