import { ISagaModule } from 'redux-dynamic-modules-saga';
import { namespace } from './type';
import reducer, { actions as libraryActions } from './slice';
import rootSaga from './saga';
import * as libraryDropdownSelectors from './selectors';

export function getLibraryModule(): ISagaModule<typeof reducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: reducer,
    },
    initialActions: [],
    sagas: [rootSaga],
  };
}

export { libraryDropdownSelectors };
export { libraryActions };
