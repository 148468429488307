import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { selectIsSuperAdmin } from '@aiware/shared/redux';
import { selectAdminCenterOrgId } from '@aiware/os/admin-center/shared';
import Header from '../components/header';
import { formattedMessages } from '../helpers/formatted-messages';
import PanelBody from './panel-body';
import { TPermissionSet } from '../../types';
import { actions } from '../../redux/slices';
import { selectFuncPermissions } from '../../redux';
import Permission from './permission';
export const PANEL_ID = 'NEW_PERMISSION_SET_PANEL';

export const NewPermissionSetPanel = () => {
  const intl = useIntl();
  const { newPermissionsPanelHeader, permissionsPanelClose } = formattedMessages(intl);
  const dispatch = useDispatch();
  const orgId: number = useSelector(selectAdminCenterOrgId);
  const superAdmin = useSelector(selectIsSuperAdmin);
  const funcPermissions = useSelector(selectFuncPermissions);
  const handleClose = () => {
    dispatch(hidePanel(PANEL_ID));
  };

  const handleSave = (permissionSet: Partial<TPermissionSet>) => {
    const { name = '', description = '', permissions = [] } = permissionSet;

    const permissionsKey = Object.entries(funcPermissions)
      .filter(([_key, value]) => permissions.includes(value))
      .map(([key]) => key);

    if (superAdmin) {
      dispatch(
        actions.adminCenterPermissions.createPermissionSetStart({
          name,
          description,
          permissions: permissionsKey,
          organizationID: `${orgId}`,
        })
      );
    } else {
      dispatch(
        actions.adminCenterPermissions.createPermissionSetStart({
          name,
          description,
          permissions: permissionsKey,
        })
      );
    }
    dispatch(hidePanel(PANEL_ID));
  };

  const initPermissionSet: Partial<TPermissionSet> = {};

  return (
    <DynamicModuleLoader modules={[]}>
      <AIWareIntlProvider>
        <div>
          <Header
            panelId={PANEL_ID}
            permissionsPanelHeader={newPermissionsPanelHeader}
            permissionsPanelClose={permissionsPanelClose}
            iconType="close"
            handleClose={handleClose}
            unstyledHeader={true}
          />
          <PanelBody onCancel={handleClose} initPermissionSet={initPermissionSet} onSave={handleSave} />
        </div>
      </AIWareIntlProvider>
    </DynamicModuleLoader>
  );
};

export { Permission };
