import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonBase,
  Fade,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  type TooltipProps,
  Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { SHARED_TEXT } from './helpers/sharedText';
import { maxWidth } from '@mui/system';

const COLOR_BLOCKS = [
  '#FB8C00',
  '#FFB300',
  '#00897B',
  '#43A047',
  '#039BE5',
  '#1E88E5',
  '#ADB8C2',
  '#E53935',
  '#D81B60',
  '#8E24AA',
];

interface ICustomColorInputProps {
  buttonColor: string;
  buttonTextColor: string;
  isButtonColorRequired?: boolean;
  onChangeButtonColor: (value: string) => void;
  onChangeTextColor: (value: string) => void;
  onReset: () => void;
  onValidationCheck?: (isValid: boolean) => void;
}

export const CustomColorInput: React.FC<ICustomColorInputProps> = ({
  buttonColor,
  buttonTextColor,
  isButtonColorRequired = false,
  onChangeButtonColor,
  onChangeTextColor,
  onValidationCheck,
  onReset,
}) => {
  const [buttonColorErrorText, setButtonColorErrorText] = useState<string>('');
  const [buttonTextColorErrorText, setButtonTextColorErrorText] = useState<string>('');
  const [focusedInput, setFocusedInput] = useState<'button' | 'text' | null>(null);

  useEffect(() => {
    //check validity of button hex colors
    const buttonColorValid = /^#([0-9A-Fa-f]{3}){1,2}$/.test(buttonColor);
    const buttonTextColorValid = /^#[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?$/.test(buttonTextColor);

    setButtonColorErrorText(buttonColorValid || buttonColor.length === 0 ? '' : 'Invalid hex color format');
    setButtonTextColorErrorText(
      buttonTextColorValid || buttonTextColor.length === 0 ? '' : 'Invalid hex color format'
    );

    const areColorsValid = buttonTextColorValid && buttonColorValid;
    const areColorsRequiredValid = isButtonColorRequired ? buttonColorValid && buttonTextColorValid : true;

    onValidationCheck && onValidationCheck(areColorsValid && areColorsRequiredValid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonTextColor, buttonColor]);

  const handleColorBlockClick = useCallback(
    (color: string) => {
      if (focusedInput === 'button') {
        onChangeButtonColor(color);
      } else if (focusedInput === 'text') {
        onChangeTextColor(color);
      }
      setFocusedInput(null);
    },
    [onChangeButtonColor, onChangeTextColor, focusedInput]
  );

  const handleInputBlur = (e: React.FocusEvent) => {
    if (!e.relatedTarget?.getAttribute('id')?.includes('#')) {
      setFocusedInput(null);
    }
  };

  const colorBoxPreview = (color: string, focused: 'button' | 'text' | null) => {
    return (
      <ColorBlock
        id={`shared-reusable-utils-login-preview-${focused ?? 'none'}`}
        color={color}
        isActive={false}
        onClick={() => {
          setFocusedInput(focused);
        }}
        sx={{
          maxHeight: '24px',
          maxWidth: '24px',
        }}
      />
    );
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ marginTop: '22px', visibility: focusedInput === null ? 'hidden' : 'visible' }}
      >
        {COLOR_BLOCKS.map(color => (
          <ColorBlock
            id={color}
            key={color}
            color={color}
            isActive={
              (focusedInput === 'button' && buttonColor === color) ||
              (focusedInput === 'text' && buttonTextColor === color)
            }
            onClick={() => handleColorBlockClick(color)}
          />
        ))}
      </Stack>
      <Stack direction="row" spacing={2} sx={{ marginTop: '18px' }}>
        <TextField
          required
          id="shared-reusable-utils-login-btnColor"
          data-testid="shared-reusable-utils-login-btnColor"
          autoComplete="off"
          label="Button Color"
          variant="outlined"
          value={buttonColor}
          onChange={e => {
            const colorInput = e.target.value;
            onChangeButtonColor(colorInput);
          }}
          onFocus={() => setFocusedInput(null)}
          onBlur={handleInputBlur}
          error={buttonColorErrorText.length > 0}
          helperText={buttonColorErrorText}
          size="medium"
          InputProps={{
            endAdornment: colorBoxPreview(buttonColor, 'button'),
          }}
          sx={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            width: '190px',
          }}
        />

        <TextField
          required
          id="shared-reusable-utils-login-login-btnTextColor"
          data-testid="shared-reusable-utils-login-input-btnTextColor"
          autoComplete="off"
          label="Button Text Color"
          variant="outlined"
          value={buttonTextColor}
          onChange={e => {
            const colorInput = e.target.value;
            onChangeTextColor(colorInput);
          }}
          onFocus={() => setFocusedInput(null)}
          onBlur={handleInputBlur}
          error={buttonTextColorErrorText.length > 0}
          helperText={buttonTextColorErrorText}
          size="medium"
          InputProps={{
            endAdornment: colorBoxPreview(buttonTextColor, 'text'),
          }}
          sx={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            width: '190px',
          }}
        />
      </Stack>

      <Button
        data-testid="shared-reusable-utils-login-reset-color-button"
        variant="text"
        size="small"
        sx={{ marginLeft: 0, marginTop: '5px', width: '130px' }}
        onClick={() => {
          onReset();
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
          {SHARED_TEXT.customColorInput.resetToDefault()}
        </Typography>
      </Button>
    </Stack>
  );
};

export type TColorBlockProps = {
  id: string;
  color: string;
  isActive: boolean;
  onClick: () => void;
};

const ColorBlock: React.FC<TColorBlockProps & { sx?: SxProps }> = ({
  id,
  color,
  isActive,
  onClick,
  sx = {},
}) => {
  return (
    <Tooltip
      TransitionComponent={Fade as TooltipProps['TransitionComponent']}
      title={color}
      placement={'top'}
    >
      <ButtonBase
        id={id}
        data-testid={id}
        sx={{
          '&:hover': {
            transform: isActive ? 'scale(1)' : 'scale(0.9)',
          },
          transition: 'transform 0.2s ease-in-out',
          backgroundColor: color,
          width: '30px',
          height: '30px',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...sx,
        }}
        onClick={onClick}
      >
        {isActive && <Check sx={{ color: 'white' }} />}
      </ButtonBase>
    </Tooltip>
  );
};
