import createSvgIcon from '../createSvgIcon';

export const Data = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 6C14.6625 6 14.055 7.08 14.3025 7.8825L11.64 10.5525C11.415 10.485 11.085 10.485 10.86 10.5525L8.9475 8.64C9.2025 7.8375 8.595 6.75 7.5 6.75C6.4125 6.75 5.7975 7.83 6.0525 8.64L2.6325 12.0525C1.83 11.805 0.75 12.4125 0.75 13.5C0.75 14.325 1.425 15 2.25 15C3.3375 15 3.945 13.92 3.6975 13.1175L7.11 9.6975C7.335 9.765 7.665 9.765 7.89 9.6975L9.8025 11.61C9.5475 12.4125 10.155 13.5 11.25 13.5C12.3375 13.5 12.9525 12.42 12.6975 11.61L15.3675 8.9475C16.17 9.195 17.25 8.5875 17.25 7.5C17.25 6.675 16.575 6 15.75 6Z"
      fill="#555F7C"
    />
    <path
      d="M11.25 6.75L11.955 5.1975L13.5 4.5L11.955 3.8025L11.25 2.25L10.56 3.8025L9 4.5L10.56 5.1975L11.25 6.75Z"
      fill="#555F7C"
    />
    <path
      d="M2.625 8.25L3 6.75L4.5 6.375L3 6L2.625 4.5L2.25 6L0.75 6.375L2.25 6.75L2.625 8.25Z"
      fill="#555F7C"
    />
  </svg>,
  'Data'
);
