import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const FileInfoLoadingState = () => {
  const { classes } = makeStyles()(_theme => ({
    loading: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }))();
  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
};
