import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';
import { namespace, ILibrary } from './type';

export type State = {
  status: LoadingStatus;
  libraries: ILibrary[];
};

export const initialState = {
  status: 'idle',
  libraries: [],
} as State;

const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    fetchLibrariesStart(state) {
      state.status = 'pending';
    },
    fetchLibrariesSuccess(
      state,
      action: PayloadAction<{
        libraries: ILibrary[];
      }>
    ) {
      const { libraries } = action.payload;
      state.libraries = libraries;
      state.status = 'idle';
    },
    fetchLibrariesFailed(state) {
      state.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
