import createSvgIcon from '../createSvgIcon';
export const DataCenter = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3335 16.5001H3.66683V7.33341H18.3335V16.5001ZM18.3335 5.50008H11.0002L9.16683 3.66675H3.66683C2.64933 3.66675 1.8335 4.48258 1.8335 5.50008V16.5001C1.8335 16.9863 2.02665 17.4526 2.37047 17.7964C2.71428 18.1403 3.1806 18.3334 3.66683 18.3334H18.3335C18.8197 18.3334 19.286 18.1403 19.6299 17.7964C19.9737 17.4526 20.1668 16.9863 20.1668 16.5001V7.33341C20.1668 6.31591 19.3418 5.50008 18.3335 5.50008Z"
      fill="#555F7C"
    />
  </svg>,
  'DataCenter'
);
