import createSvgIcon from '../createSvgIcon';

export const VeritoneLogo = createSvgIcon(
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.9"
      d="M14.8442 0.361084L6.05347 15.3555H11.9462L17.3333 6.12755L14.8442 0.361084Z"
      fill="#555F7C"
    />
    <path
      opacity="0.36"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5538 4.26849L10.2693 0.361084H14.8446L12.5538 4.26849ZM0.666626 6.12978L3.1566 0.361088L9.00474 10.3362L6.05572 15.3611L0.666626 6.12978Z"
      fill="#555F7C"
    />
  </svg>,
  'VeritoneLogo'
);
