import createSvgIcon from '../createSvgIcon';
export const Resources = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M19 6V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H15C14.4696 3 13.9609 3.21071 13.5858 3.58579C13.2107 3.96086 13 4.46957 13 5V6H11V5C11 4.46957 10.7893 3.96086 10.4142 3.58579C10.0391 3.21071 9.53043 3 9 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V6H3V20H21V6M19 18H5V8H19V18Z"
      fill="#555F7C"
    />
  </svg>,
  'Resources'
);
