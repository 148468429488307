import { useDispatch, useSelector } from 'react-redux';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { tableInfiniteScroll, TTableHeaderItem } from '@aiware/shared/reusable-utils';
import { IAuditLog, TEST_ID_WIDGET } from '../../../types/auditLog.types';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { actions, auditLogItems } from '../../../redux';
import { ITEM_HEIGHT, TableRow } from './table-row';
import { ErrorState, NullState } from '@aiware/ui';
import { emptySearch } from '@aiware/shared/assets';

const { selectItems, selectOffset, selectReadStatus, selectHasMore } = auditLogItems.selectors;

const {
  jsx: { TableInfiniteScroll },
} = tableInfiniteScroll;

const { state, header } = SHARED_TEXT.table;

const HEADER_ITEMS: TTableHeaderItem[] = [
  {
    id: 'user',
    order: 1,
    size: 3,
    label: header.user,
  },
  {
    id: 'event-type',
    order: 2,
    size: 3,
    label: header.eventType,
  },
  {
    id: 'details',
    order: 3,
    size: 3,
    label: header.details,
  },
  {
    id: 'date-time',
    order: 4,
    size: 3,
    label: header.dateTime,
  },
];

const AuditLogsTable = ({ currentOrgId }: { currentOrgId?: string }) => {
  const dispatch = useDispatch();
  const _isFullScreen = useSelector(selectIsFullscreenEnabled);

  const handleFetchItems = () => {
    dispatch(actions.auditLog['readStart']!({ organizationId: currentOrgId }));
  };

  // Required props
  const items: IAuditLog[] = useSelector(selectItems);
  const hasMore = useSelector(selectHasMore);
  const offset = useSelector(selectOffset);
  const loadingStatus = useSelector(selectReadStatus);

  // Empty & Error State messages
  const MessageEmptyState = state.emptyMessage;
  const MessageErrorState = state.errorMessage;
  const DescriptionEmptyState = state.emptyDescription;
  const DescriptionErrorState = state.errorDescription;

  const handleClickAuditLog = () => () => {
    /* Do something  */
  };

  const Row = ({ item, index }: { item: IAuditLog; index: number }) => (
    <TableRow item={item} index={index} onClick={handleClickAuditLog} />
  );

  return (
    <TableInfiniteScroll
      testId={TEST_ID_WIDGET}
      items={items}
      isLoading={loadingStatus === 'pending'}
      error={loadingStatus === 'failure'}
      offset={offset}
      hasMore={hasMore}
      fetchMore={handleFetchItems}
      fetchOnMount={items.length === 0}
      itemHeight={ITEM_HEIGHT}
      headerItems={HEADER_ITEMS}
      TableRowComponent={Row}
      TableEmptyStateComponent={
        <NullState header={MessageEmptyState} description={DescriptionEmptyState} image={emptySearch} />
      }
      TableErrorStateComponent={<ErrorState onRetry={handleFetchItems} errorMessage={MessageErrorState} />}
    />
  );
};
export default AuditLogsTable;
