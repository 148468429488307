import createSvgIcon from '../createSvgIcon';

export const Flows = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F2F7FE" />
    <path
      d="M11 10V12H16V16H11V18H16C17.11 18 18 17.11 18 16V15H23V25H18V24C18 22.89 17.11 22 16 22H11V24H16V28H11V30H16C17.11 30 18 29.11 18 28V27H23C24.11 27 25 26.11 25 25V21H31V19H25V15C25 13.89 24.11 13 23 13H18V12C18 10.89 17.11 10 16 10H11Z"
      fill="#0D47A1"
    />
  </svg>,
  'Flows'
);
