import { DataCenterState, namespace } from '../slices';
import { EntityType } from '@aiware/js/interfaces';

export const selectEngineCategoryEntities = (state: DataCenterState) =>
  state[namespace].entities[EntityType.EngineCategories];

export const selectEngineEntities = (state: DataCenterState) => state[namespace].entities[EntityType.Engines];

export const selectFolderEntity = (folderId: string | null) => (state: DataCenterState) =>
  folderId ? selectFolderEntities(state)[folderId] : null;

export const selectFolderEntities = (state: DataCenterState) => state[namespace].entities[EntityType.Folders];

export const selectSourceEntities = (state: DataCenterState) => state[namespace].entities[EntityType.Sources];

export const selectScheduleEntities = (state: DataCenterState) =>
  state[namespace].entities[EntityType.Schedules];

export const selectTdoEntities = (state: DataCenterState) => state[namespace].entities[EntityType.Tdos];
export const selectTdoEntityById = (tdoId: string) => (state: DataCenterState) =>
  selectTdoEntities(state)[tdoId];
