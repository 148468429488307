import createSvgIcon from '../createSvgIcon';

export const EngineBuild = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F2F7FE" />
    <path
      d="M28.1092 25.2434L20.9209 18.0472C21.6572 16.1947 21.293 14.0097 19.7809 12.5055C17.9601 10.6847 15.1259 10.5184 13.1072 11.9909L16.1472 15.0388L15.023 16.1392L11.9909 13.123C10.5184 15.1338 10.6847 17.9759 12.5055 19.7888C13.978 21.2613 16.1234 21.6492 17.9601 20.9605L25.1722 28.1726C25.4809 28.4813 25.9797 28.4813 26.2884 28.1726L28.1092 26.3517C28.418 26.0351 28.418 25.5522 28.1092 25.2434ZM25.7342 26.5022L18.2451 19.013C17.7622 19.3692 17.2238 19.583 16.6617 19.6622C15.5851 19.8205 14.453 19.4959 13.6297 18.6726C12.8776 17.9284 12.5292 16.9309 12.5847 15.9492L15.0309 18.3955L18.3876 15.0388L15.9413 12.5767C16.9388 12.5372 17.9126 12.8855 18.6647 13.6297C19.5197 14.4847 19.8442 15.6642 19.6463 16.7647C19.5513 17.3267 19.3138 17.8492 18.9497 18.3163L26.4388 25.7976L25.7342 26.5022Z"
      fill="#0D47A1"
    />
  </svg>,
  'EngineBuild'
);
