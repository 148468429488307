export interface ISuggestionResult {
  [key: string]: {
    key: string;
    label: string;
    suggestions: ISuggestion[];
    isLoading: boolean;
    error?: SuggestionResultError | null;
  };
}

export interface ISuggestion {
  id: string;
  entityId: string | undefined;
  label: string | undefined;
  imageUrl: string;
  capabilityType: CapabilityType;
}

export type SuggestionResultError = {
  title: string;
  errorMessage: string;
  friendlyMessage: string;
};

export interface ICriterion {
  id: string;
  entityId?: string;
  label?: string;
  capabilityType: CapabilityType;
}

export const ECapabilityTypes = {
  object: 'ObjectDetection',
  logo: 'LogoRecognition',
  facial: 'FacialDetection',
  keyword: 'KeywordExtraction',
  tags: 'Tags',
  recognizedText: 'RecognizedText',
  fileName: 'FileName',
  dataRegistry: 'DataRegistry',
  source: 'Source',
  aiStructuredData: 'AiStructuredData',
} as const;

export type CapabilityType = (typeof ECapabilityTypes)[keyof typeof ECapabilityTypes];

export enum EActiveView {
  suggestions = 'suggestions',
  structuredData = 'structuredData',
}
