import { baseGraphQLApi } from '@aiware/shared/redux';

// todo [fix:baseGraphQLApi] fix tests of 2nd parameter
export const handleNotificationListByMailBox = async (
  graphEndpoint: string,
  token: string,
  mailBoxIds: string[],
  offset: number,
  limit: number
) => {
  const query = `
  query notificationMailboxes($mailBoxIds: [ID!],$offset: Int, $limit: Int){
    notificationMailboxes(ids: $mailBoxIds){
      id
      notifications(
        orderBy:createdDateTime
        orderDirection: desc
        limit: $limit
        offset: $offset
      ){
        count
        records {
          id
          applicationId
          body
          contentType
          flags
          eventName
          eventType
          createdDateTime
          readDateTime
          createdDateTime
          title
        }
      }
    }
  }
  `;

  const variables = {
    mailBoxIds,
    offset,
    limit,
  };

  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};
export const handleGetNotificationAction = async (
  eventName: string,
  eventType: string,
  applicationId: string,
  graphEndpoint: string,
  token?: string
) => {
  const query = `
  query notificationActions {
    notificationActions(
      eventName: "${eventName}"
      eventType: "${eventType}"
      application: "${applicationId}"
    ) {
      records {
        icon
        eventName
        eventType
        applicationId
        actionName
        urlTemplate
      }
    }
  }  
  `;

  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const handleUpdateNotificationRead = async (
  graphEndpoint: string,
  token: string,
  notificationId: string
) => {
  const query = `
  mutation {
    setNotificationFlag(input: {
      notificationId: "${notificationId}"
      setFlags:[read]
      unsetFlags: [unread]
    }) {
      id
      body
      flags
    }
  }
  `;

  const variables = {};

  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const handleMarkAllReadNotification = async (
  mailBoxIds: string[],
  graphEndpoint: string,
  token?: string
) => {
  const query = `
    mutation markAllNotificationsRead($mailBoxIds: [ID]!) {
      markAllNotificationsRead(mailboxIds: $mailBoxIds) {
        totalCount
        unreadCount
      }
    }
    `;

  const variables = {
    mailBoxIds,
  };
  try {
    return await baseGraphQLApi({ query, variables, graphEndpoint, token });
  } catch (error) {
    return {
      success: false,
    };
  }
};
