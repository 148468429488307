import { get } from '@aiware/js/function';
import { graphEndpointSelector, sessionTokenSelector, getConfigSuccess } from '@aiware/shared/redux';
import { all, call, put, select, takeEvery, take } from 'redux-saga/effects';
import { getApplicationInfo } from '../../../lib/apiApplication';
import { getCurrentUserApi } from '../../../lib/apiUsers';
import * as actions from './action';

type TDynamicObject = {
  [x: string]: any;
};

export function* getCurrentUserSaga() {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  if (!graphEndpoint) {
    yield take(getConfigSuccess.type);
    yield put(actions.getCurrentUser());
  } else {
    const data: TDynamicObject = yield call(getCurrentUserApi, graphEndpoint, token);
    if (data?.errors && data?.errors.length > 0 && !data.errors[0].path?.includes('userSettings')) {
      console.error('getCurrentUser error');
      yield put(actions.getCurrentUserFail());
    } else {
      const currentUser = {
        ...data?.data?.me,
        organizationRole: {
          organizationId: get(data, 'data.me.organization.id', ''),
        },
        organization: { ...data?.data?.me?.organization },
      };
      yield put(actions.getCurrentUserSuccess(currentUser));
    }
  }
}

export function* getCurrentApplicationInfoSaga(action: { type: string; payload: { applicationId: string } }) {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const { applicationId } = action.payload;
  const data: TDynamicObject = yield call(getApplicationInfo, applicationId, graphEndpoint, token);
  if (data?.errors && data?.errors.length > 0) {
    console.error('getCurrentApplicationInfo error');
  } else {
    yield put(actions.getCurrentAppIconSuccess(data?.data?.application?.iconUrl));
  }
}

export default function* userSaga() {
  yield all([takeEvery(actions.GET_CURRENT_USER, getCurrentUserSaga)]);
  yield all([takeEvery(actions.GET_CURRENT_APP_ICON, getCurrentApplicationInfoSaga)]);
}
