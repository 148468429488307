import { put, select } from 'redux-saga/effects';
import { organizationInviteApi } from '../../../api';
import { selectApiConfigs } from '../../selectors';
import { actions } from '../../slices';
import { MessageSeverity, preferredLanguageSelector, showMessage } from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { EOrganizationInviteAction } from '../../../types';

export function* updateOrganizationInviteSaga(
  action: ReturnType<typeof actions.reviewRequestState.updateOrganizationInviteStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    if (action.payload.action === EOrganizationInviteAction.reject) {
      yield organizationInviteApi.deleteOrganizationInvite(apiConfigs, action.payload);
    } else {
      yield organizationInviteApi.updateOrganizationInvite(apiConfigs, action.payload);
    }

    yield put(actions.reviewRequestState.updateOrganizationInviteSucceed());

    /**
     * Refreshing the user invites and list of orgs since this is called from accept invite
     */
    yield put(actions.organizationsState.fetchOrganizationInvitesStart());
    yield put(actions.organizationsState.organizationsFetchStart());
  } catch (e) {
    yield put(
      showMessage({
        content: formatMessage({
          id: 'organization-panel.snackbar.updatingOrganizationInviteError',
          defaultMessage: 'Something went wrong while updating the invite.',
          description:
            'The error message pops up when something goes wrong when updating the organization invite',
        }),
        severity: MessageSeverity.Error,
      })
    );

    console.log(e);
  }
}
