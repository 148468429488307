import { Grid, Typography } from '@mui/material';
import { SHARED_TEXT } from '../../../../../helpers/shared-text';

export const DataRegistryTableHeader = () => (
  <Grid
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    item
    container
    xs={12}
    sx={{ pl: 3, height: '39px', borderBottom: '1px solid #D5DFE9' }}
  >
    <Grid item xs={7}>
      <Typography variant="caption" color="textSecondary" data-testid="os-dc-data-registry-table-header-name">
        {SHARED_TEXT.dataRegistryBrowser.tableHeader.name()}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography
        variant="caption"
        color="textSecondary"
        data-testid="os-dc-data-registry-table-header-created"
      >
        {SHARED_TEXT.dataRegistryBrowser.tableHeader.created()}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography
        variant="caption"
        color="textSecondary"
        data-testid="os-dc-data-registry-table-header-modified"
      >
        {SHARED_TEXT.dataRegistryBrowser.tableHeader.modified()}
      </Typography>
    </Grid>
  </Grid>
);
