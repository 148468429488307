import { EAuthResourceType, EAuthSubResourceType } from '../../types';
import { PermissionsState, namespace } from '../slices';

export const selectPermissionsByEntity =
  (
    entityType: EAuthResourceType | undefined,
    entityId: string | undefined,
    permissionTypeToGet?: EAuthResourceType | EAuthSubResourceType
  ) =>
  (state: PermissionsState) => {
    if (!entityType || !entityId) {
      return undefined;
    }
    const permissionsForEntityType = state[namespace].permissionsByObject[entityType];
    return permissionsForEntityType && permissionsForEntityType[entityId]
      ? (permissionsForEntityType as any)[entityId][permissionTypeToGet || entityType]
      : undefined;
  };

export const selectMyRights = (state: PermissionsState) => state[namespace].rights;
