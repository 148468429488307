import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import { useStyles } from '../../components/useStyles';
import ThumbnailHelper from '../ThumbnailHelper';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const Thumbnail: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType?: EntityType;
}> = ({ item, xs, itemType }) => {
  const { classes } = useStyles();
  let image;
  if (itemType === EntityType.Schedules) {
    image = (item as ISchedule).imageUrl;
  } else {
    image = (item as ISource | ITdoVm).thumbnailUrl;
  }

  return (
    <Grid data-test={'item-thumbnail'} className={classes.thumbnailContainer} item container xs={xs}>
      <Grid item xs>
        <ThumbnailHelper image={image} className={classes.thumbnail} />
      </Grid>
    </Grid>
  );
};

export default Thumbnail;
