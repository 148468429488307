import { Fragment, useEffect, useState } from 'react';
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  ListItemButton,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AppBarArticleIcon, AppBarInAppGuidance, Chat } from '@aiware/shared/icons';
import { useStyles } from './useStyles';
import classNames from 'classnames';
import { sdkEventManager } from '@aiware/js/sdk';
import { SdkEvents } from '@aiware/js/interfaces';

declare global {
  interface Window {
    Intercom: (update: string, params?: { hide_default_launcher: boolean }) => void;
  }
}

interface HelpPropTypes {
  tooltipTitle: string;
  helpDocLabel: string;
  helpDocLink?: string;
  chatWithSupportLabel: string;
  supportLabel: string;
  helpDocCallback?: () => void;
  supportCallback?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  titleColor: string;
  //salesforce
  displayChatWithSupport: boolean;
  //intercom
  displaySupportChat: boolean;
  desktopIconStyle?: { background: string | undefined };
}

const Help = ({
  onOpen,
  onClose,
  helpDocLink,
  helpDocCallback,
  supportCallback,
  displaySupportChat,
  displayChatWithSupport,
  desktopIconStyle,
}: HelpPropTypes) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | undefined | Element | ((element: Element) => Element)>(
    null
  );
  const [isInAppGuidanceSupported, setIsInAppGuidanceSupported] = useState<boolean>(false);
  const [isChatWithSupportSupported, setIsChatWithSupportSupported] = useState<boolean>(false);

  const showHelpWindow = (event: any) => {
    setAnchorEl(event.currentTarget as Element);
    onOpen?.();
  };

  const hideHelpWindow = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const openHelpDoc = () => {
    hideHelpWindow();
    helpDocCallback?.();
    helpDocLink && window.open(helpDocLink, '_blank');
  };

  const openChatWithSupportWindow = () => {
    // todo remove me, hotfix to fix chat
    window['document'].getElementById?.('embeddedMessagingConversationButton')?.click?.();

    supportCallback?.();
    sdkEventManager.dispatch(SdkEvents.openSupport);
    setAnchorEl(null);
  };

  const openChatWindow = () => {
    // we used to do intercom but now pendo targets this
    // hideHelpWindow();
    // supportCallback?.();
    // window.Intercom?.('show');
    supportCallback?.();
    setAnchorEl(null);
  };

  const hasHelpDoc = helpDocLink || helpDocCallback;
  // use pendo instead of intercom
  // const hasSupportChat = supportCallback && window.Intercom;
  // this is for intercom
  const hasSupportChat = displaySupportChat;
  const appVersion = (window as any)['config']?.appVersion;
  //this is for the salesforce
  const hasChatWithSupport = displayChatWithSupport;

  useEffect(() => {
    setTimeout(() => {
      // Check if the salesforce chat is available
      // @ts-ignore
      if (window?.chatWithSupport) {
        setIsChatWithSupportSupported(true);
      }

      // Check if Pendo is available
      // @ts-ignore
      if (window?.pendo) {
        setIsInAppGuidanceSupported(true);
      }
    }, 2000);
  }, []);

  return (
    <div className={classes.help}>
      <Tooltip
        title={
          <FormattedMessage
            id="os-app-bar.KrqRIg"
            defaultMessage="Help Center"
            description="appbar tooltip: help-center button text"
          />
        }
        aria-label="help"
      >
        <IconButton
          className={classes.barIcon}
          onClick={showHelpWindow}
          data-veritone-element="help-button"
          data-testid="help-button"
          aria-label="help"
          size="large"
        >
          <div className={classNames(classes.icon, classes.helpIcon)} style={desktopIconStyle} />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={hideHelpWindow}
        className={classes.popover}
        classes={{
          paper: classes.popoverColor,
        }}
        disableScrollLock
      >
        <List disablePadding className={classes.list}>
          {
            //Show Help Doc Link
            hasHelpDoc && (
              <ListItemButton
                onClick={openHelpDoc}
                data-veritone-element="show-help-doc-button"
                data-testid="show-help-doc-button"
              >
                <ListItemIcon>
                  <AppBarArticleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="os-app-bar.MSM3Vh"
                      defaultMessage="View Help Docs"
                      description="help tooltip: text for documentation"
                    />
                  }
                  classes={{ root: classes.helpItemText }}
                />
              </ListItemButton>
            )
          }
          {
            //Show salesforce Chat support window
            hasChatWithSupport && isChatWithSupportSupported && (
              <ListItemButton
                onClick={openChatWithSupportWindow}
                data-veritone-element="open-chat-with-support-button"
                data-testid="app-bar-show-chat-with-support-button"
              >
                <ListItemIcon>
                  <Chat fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="os-app-bar-chat-with-support.MBM3Vh2"
                      defaultMessage="Chat with Support"
                      description="help tooltip: salesforce chat with support"
                    />
                  }
                  classes={{ root: classes.helpItemText }}
                />
              </ListItemButton>
            )
          }
          {
            //Show Chat With Support
            hasSupportChat && isInAppGuidanceSupported && (
              <ListItemButton
                onClick={openChatWindow}
                data-veritone-element="open-support-chat-button"
                data-testid="support-chat-button"
              >
                <ListItemIcon>
                  <AppBarInAppGuidance />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="os-app-bar.8aLpuV"
                      defaultMessage="In-App Guidance"
                      description="help tooltip: text for documentation"
                    />
                  }
                  classes={{ root: classes.helpItemText }}
                />
              </ListItemButton>
            )
          }
          {
            //Show App Version
            appVersion && (
              <Fragment>
                <Divider />
                <ListItem className={classes.versionWrapper}>
                  <ListItemText primary={appVersion} classes={{ primary: classes.versionText }} />
                </ListItem>
              </Fragment>
            )
          }
        </List>
      </Popover>
    </div>
  );
};

export default Help;
