import { isEmpty, startCase } from 'lodash';
import { EApplicationConfigType } from '@aiware/os/admin-center/shared';
import { TApplicationConfig } from '../../types';
import { FormattedMessage } from 'react-intl';

export const formatLabel = (label = '') => startCase(label);

export const getFieldErrorMessage = (field: TApplicationConfig): JSX.Element | any => {
  const { value, required, configType } = field;

  if (required && isEmpty(value)) {
    return (
      <FormattedMessage
        id="os-admin-center-user-application-settings-empty-error-message"
        defaultMessage="Please enter value"
        description="Empty error message"
      />
    );
  }

  if (configType === EApplicationConfigType.Integer) {
    if (Number(value) - Math.floor(Number(value)) !== 0) {
      return (
        <FormattedMessage
          id="os-admin-center-user-application-settings-integer-vaue-error-message"
          defaultMessage="The value has to to be Integer'"
          description="Integer value error message"
        />
      );
    }
  }

  return '';
};
