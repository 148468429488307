import FormControl from '@mui/material/FormControl';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';

interface Option {
  value: string;
  title: string;
}

interface Props {
  data: Array<Option>;
  filter: string;
  onFilter: (event: SelectChangeEvent<string>) => void;
}

const Filter = ({ data = [], filter, onFilter }: Props) => {
  const { classes } = useStyles();
  const menuProps = {
    classes: { paper: classes.menuPaper },
  };

  return (
    <FormControl className={classes.formControl} variant="standard">
      <Select
        value={filter}
        onChange={onFilter}
        style={{ fontSize: 12 }}
        inputProps={{ 'aria-label': 'age' }}
        className={classes.selectMenu}
        MenuProps={menuProps}
      >
        {data.map(item => (
          <MenuItem key={item.value} value={item.value} className={classes.selectMenuItem}>
            <FormattedMessage
              id="ui.ipFqG+"
              defaultMessage="{title}"
              description="label of select item: {title}"
              values={{
                title: item.title,
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { Filter };

export default Filter;
