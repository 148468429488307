import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME, TAssetRecord, TState, TWallpaper } from '../../types';

export const namespace = 'wallpapers';

export const initialState: TState = {
  status: 'idle',
  wallpapers: null,
  currentWallpaper: null,
};

function getAllIndexes(arr: TWallpaper[], val: string) {
  const flat = arr.map(el => el.value);
  const indexes = [];
  let i = -1;
  while ((i = flat.indexOf(val, i + 1)) !== -1) {
    indexes.push(i);
  }
  return indexes;
}

export const aiWareDesktopSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchUserWallpapersStart(state: TState, _action: PayloadAction<string>) {
      state.status = 'pending';
    },
    fetchUserWallpapersSuccess(state: TState, action: PayloadAction<{ key: string; value: string }[]>) {
      state.status = 'success';
      state.wallpapers = action.payload;
    },
    fetchUserWallpapersFailure(state: TState) {
      state.status = 'failure';
      state.wallpapers = null;
    },
    fetchUserCurrentWallpaperTDOStart(state: TState, _action: PayloadAction<string>) {
      state.status = 'pending';
    },
    fetchUserCurrentWallpaperTDOSuccess(state: TState, action: PayloadAction<string>) {
      state.status = 'success';
      state.currentWallpaper = action.payload;
    },
    fetchUserCurrentWallpaperTDOFailure(state: TState) {
      state.status = 'failure';
      state.currentWallpaper = null;
    },
    getTDOAssetsStart(state: TState, _action: PayloadAction<string>) {
      state.status = 'pending';
    },
    getTDOAssetsSuccess(
      state: TState,
      action: PayloadAction<{ records: TAssetRecord[]; tdoToFetch: string }>
    ) {
      state.status = 'success';
      const highRes = action.payload.records.find(
        record =>
          record['description'] !== 'Media Thumbnail' || !(record['signedUri'] || '').includes('.thumb.')
      );
      const oldWallpapers = [...(state?.wallpapers ?? []).map(wp => ({ ...wp }))];
      const indexes = getAllIndexes(oldWallpapers, action.payload.tdoToFetch);

      const thumb = action.payload.records.find(
        record =>
          record['description'] === 'Media Thumbnail' || (record['signedUri'] || '').includes('.thumb.')
      );
      for (const wallpaperIndex of indexes) {
        if (highRes) {
          oldWallpapers[wallpaperIndex]!.highRes = highRes.signedUri;
        }
        if (thumb) {
          oldWallpapers[wallpaperIndex]!.thumb = thumb.signedUri;
        }
      }
      state.wallpapers = oldWallpapers;
    },
    getTDOAssetsFailure(state: TState) {
      state.status = 'failure';
    },
    setCurrentWallpaperStart(state: TState, _action: PayloadAction<string>) {
      state.status = 'pending';
      state.currentWallpaper = null;
    },
    setCurrentWallpaperSuccess(state: TState, action: PayloadAction<string>) {
      state.status = 'success';
      state.currentWallpaper = action.payload;
      const newWallpapers = [...(state.wallpapers ?? [])];
      const currentIndex = newWallpapers.findIndex(wallpaper => wallpaper.key === 'wallpaperCurrent');
      if (currentIndex !== -1) {
        newWallpapers[currentIndex]!.value = action.payload;
        state.wallpapers = newWallpapers;
      }
    },
    setCurrentWallpaperFailure(state: TState) {
      state.status = 'failure';
    },
    removeCustomWallpaperStart(state: TState, action: PayloadAction<{ id: number; isCurrent?: boolean }>) {
      state.status = 'pending';
      const newWallpapers = [...(state?.wallpapers ?? [])];
      const removedIndex = newWallpapers.findIndex(
        wallpaper => wallpaper.key === `wallpaperCustom_${action.payload.id}`
      );
      delete newWallpapers[removedIndex]!.highRes;
      delete newWallpapers[removedIndex]!.thumb;
      newWallpapers[removedIndex]!.value = 'none';
    },
    removeCustomWallpaperSuccess(state: TState) {
      state.status = 'success';
    },
    removeCustomWallpaperFailure(state: TState) {
      state.status = 'failure';
    },
    setCustomWallpaperStart(
      state: TState,
      _action: PayloadAction<{ wallpaperId: number; tdoID: string; create: boolean }>
    ) {
      state.status = 'pending';
    },
    setCustomWallpaperSuccess(state: TState, action: PayloadAction<{ key: string; value: string }>) {
      state.status = 'success';
      const index = (state.wallpapers ?? []).findIndex(wallpaper => wallpaper.key === action.payload.key);
      if (index !== -1) {
        const newWallpapers = [...(state.wallpapers ?? [])];
        newWallpapers[index] = action.payload;
        state.wallpapers = newWallpapers;
      } else {
        state.wallpapers = [...(state.wallpapers ?? []), action.payload];
      }
    },
    setCustomWallpaperFailure(state: TState) {
      state.status = 'failure';
    },
  },
});

export const actions = aiWareDesktopSlice.actions;
export default aiWareDesktopSlice.reducer;
