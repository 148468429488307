import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux/selectors';
import { ErrorResponseType, IRootFolder } from '../types';

export async function getOrgCmsRootFolder(apiConfigs: ReturnType<typeof selectApiConfigs>): Promise<{
  orgRootFolder: IRootFolder;
  errors: ErrorResponseType;
}> {
  const operationName = 'getOrgCmsRootFolder';
  const name = 'createRootFolders';
  const query = `mutation ${operationName}{
      ${name}(rootFolderType: cms) {
        id
        name
        modifiedDateTime
        treeObjectId
        ownerId
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: {
      id: string;
      name: string;
      modifiedDateTime: string;
      treeObjectId: string;
      ownerId: string;
    }[];
    errors?: ErrorResponseType;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const orgRootFolder = result[name]?.find(folder => !folder.ownerId)!;

  return {
    orgRootFolder,
    errors: result.errors || null,
  };
}

export async function getCmsRootFolder(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'getOrgCmsRootFolder';
  const name = 'rootFolders';
  const query = `
    query ${operationName} {
      ${name}(type: cms) {
        id
        name
        modifiedDateTime
        treeObjectId
        ownerId
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: IRootFolder[];
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  const orgRootFolder = result[name]?.find(folder => !folder.ownerId);

  return orgRootFolder;
}
