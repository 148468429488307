import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../types';
import FileType from './rowItems/FileType';
import SourceTypeId from './rowItems/SourceTypeId';
import Name from './rowItems/Name';
import MediaStartTime from './rowItems/MediaStartTime';
import CreatedDateTime from './rowItems/CreatedDateTime';
import ModifiedDateTime from './rowItems/ModifiedDateTime';
import ScheduleCount from './rowItems/ScheduleCount';
import ScheduleType from './rowItems/ScheduleType';
import Engines from './rowItems/Engines';
import Thumbnail from './rowItems/Thumbnail';
import StatusBadge from './rowItems/StatusBadge';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const GetRowItem: FunctionComponent<{
  rowName: string;
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ rowName, item, xs, itemType }) => {
  let rowItem;
  switch (rowName) {
    case 'thumbnail':
      rowItem = <Thumbnail item={item} xs={xs} itemType={itemType} />;
      break;
    case 'fileType':
      rowItem = <FileType item={item as ITdoVm} xs={xs} itemType={itemType} />;
      break;
    case 'scheduleType':
      rowItem = <ScheduleType item={item} xs={xs} itemType={itemType} />;
      break;
    case 'sourceTypeId':
      rowItem = <SourceTypeId item={item} xs={xs} itemType={itemType} />;
      break;
    case 'name':
      rowItem = <Name item={item} xs={xs} itemType={itemType} />;
      break;
    case 'mediaStartTime':
      rowItem = <MediaStartTime item={item} xs={xs} itemType={itemType} />;
      break;

    case 'createdDateTime':
      rowItem = <CreatedDateTime item={item} xs={xs} itemType={itemType} />;
      break;
    case 'modifiedDateTime':
      rowItem = <ModifiedDateTime item={item} xs={xs} itemType={itemType} />;
      break;
    case 'scheduleCount':
      rowItem = <ScheduleCount item={item} xs={xs} itemType={itemType} />;
      break;
    case 'engineIds':
      rowItem = <Engines item={item as ITdoVm} xs={xs} itemType={itemType} />;
      break;
    case 'status':
      rowItem = <StatusBadge item={item as ISchedule} xs={xs} itemType={itemType} />;
      break;
    default:
      return <div />;
  }

  return rowItem;
};

export default GetRowItem;
