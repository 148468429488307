import { Snackbar, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Portal from '@mui/material/Portal';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  snackbarContent: {
    display: 'flex',
    gap: '10px',
    padding: '16px',
    backgroundColor: '#2b373e',
    color: '#ffffff',
    borderRadius: '4px',
    width: '510px',
    flexDirection: 'column',
  },
  linearProgress: {
    width: '100%',
    marginTop: '4px',
  },
  closeButton: {
    marginLeft: 'auto',
    color: '#fff',
  },
});

const ExportLogsSnackbar = ({ open }: { open: boolean }) => {
  const classes = useStyles();

  return (
    <Portal>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbar-root': {
            zIndex: 99999,
          },
        }}
      >
        <div className={classes.snackbarContent}>
          <Typography variant="body2">
            <FormattedMessage
              id="audit-log.exportLogsSnackbar.message"
              defaultMessage="Your download is in progress, please do not close or navigate away from this page."
              description="Audit Log Export Logs Snackbar Message"
            />
          </Typography>
          <LinearProgress color="primary" className={classes.linearProgress} />
        </div>
      </Snackbar>
    </Portal>
  );
};

export default ExportLogsSnackbar;
