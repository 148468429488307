import { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import { ITdoVm, ICategoriesWithEngines, IEngine, EngineCategoryId } from '../../types';
import IconEngineHelper from './IconEngineHelper';
import { makeStyles } from 'tss-react/mui';
import { SHARED_TEXT } from '../../helpers/shared-text';

const useStyles = makeStyles()(theme => ({
  iconPopover: {
    pointerEvents: 'none',
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  paper: {
    backgroundColor: 'transparent',
  },
  enginePopoverText: {
    color: '#F2F9FF',
    fontWeight: 600,
    maxWidth: '300px',
    paddingRight: theme.spacing(1),
  },
  popoverContainer: {
    marginTop: theme.spacing(1),
    background: theme.palette.text.primary,
    opacity: 0.8,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: '4px',
  },
  hiddenIconRow: {
    paddingTop: theme.spacing(1),
  },
  hiddenIconPopover: {
    backgroundColor: theme.palette.background.default,
  },
  hiddenNumber: {
    verticalAlign: 'middle',
    fontWeight: 600,
  },
  engineRows: {
    paddingLeft: theme.spacing(0.5),
    width: '300px',
    maxWidth: '300px',
  },
  engineColumnRow: {
    paddingLeft: theme.spacing(0.5),
  },
  engineColumns: {
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    width: '600px',
    maxWidth: '600px',
  },
  engineIconContainer: {
    background: '#EEF3F9',
    padding: theme.spacing(0.6),
    borderRadius: '5px',
    marginRight: theme.spacing(0.6),
    maxWidth: '25px',
    maxHeight: '20px !important',
  },
  hiddenEngCount: {
    maxWidth: '25px',
    maxHeight: '20px',
    verticalAlign: 'middle',
  },
  hiddenIcon: {
    fill: '#F2F9FF !important',
    marginRight: theme.spacing(2),
  },
  engineIcons: {
    width: '14px !important',
    height: '14px !important',
    verticalAlign: 'middle',
  },
}));
const EngineIcons: FunctionComponent<{
  item: ITdoVm;
  iconsToShow: number;
  showOtherEngines?: boolean;
}> = ({ item, iconsToShow, showOtherEngines }) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!item || !item.categoriesWithEngines) {
    return null;
  }

  const engineCategoriesToShow = item.categoriesWithEngines.filter(item => {
    if (item) {
      if (
        //show all non push / pull engines
        !showOtherEngines &&
        //id's for push and pull categories of engines
        item.id !== '4b150c85-82d0-4a18-b7fb-63e4a58dfcce' &&
        item.id !== '0b10da6b-3485-496c-a2cb-aabf59a6352d'
      ) {
        return item;
      } else {
        //show only push / pull engines
        if (
          showOtherEngines &&
          (item.id === '4b150c85-82d0-4a18-b7fb-63e4a58dfcce' ||
            item.id === '0b10da6b-3485-496c-a2cb-aabf59a6352d')
        ) {
          return item;
        }
      }
    }
  });

  const hidden = engineCategoriesToShow.length > iconsToShow;

  const handleHiddenPopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHiddenPopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const engineRowHidden = (engineList: ICategoriesWithEngines[]) => {
    return engineList.map((engine, index) => {
      return (
        <>
          <Grid
            data-test={`hidden-engine-category-${engine.id}`}
            className={classNames(classes.hiddenIconRow)}
            key={index}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
            container
          >
            <Icon
              data-test={`hidden-engine-category-icon-${engine.iconName}`}
              className={classNames(classes.engineIcons, classes.hiddenIcon)}
              component={IconEngineHelper(engine.iconName)}
              color="secondary"
              fontSize="small"
            />
            <Typography
              data-test={'hidden-engine-category-name'}
              noWrap
              className={classes.enginePopoverText}
              variant="caption"
            >
              {engine.name}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <UsedEngines categoryId={engine.id} engines={engine.engines} useColumns={true} />
        </>
      );
    });
  };

  const engineRow = (engineList: ICategoriesWithEngines[]) => {
    return engineList.map((engine, index) => {
      return <RenderedIcons key={engine.id} engine={engine} />;
    });
  };

  const engineIcons = () => {
    let renderedEngines = [];

    if (hidden) {
      const showEngines = engineCategoriesToShow.slice(0, iconsToShow) as ICategoriesWithEngines[];
      const hiddenEngines = engineCategoriesToShow.slice(iconsToShow) as ICategoriesWithEngines[];

      renderedEngines = engineRow(showEngines as []);

      const hiddenCount = (
        <>
          <Grid
            data-test={'hidden-engine-categories'}
            className={classes.hiddenEngCount}
            key={'count'}
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            container
            xs={12}
            aria-owns={open ? 'hidden-engines' : undefined}
            aria-haspopup="true"
            onMouseEnter={handleHiddenPopoverOpen}
            onMouseLeave={handleHiddenPopoverClose}
          >
            <Typography
              data-test={'hidden-engine-categories-count'}
              className={classes.hiddenNumber}
              variant="overline"
              align="center"
            >
              +{hiddenEngines.length}
            </Typography>
          </Grid>
          <Popover
            data-test={'popover-hidden-engines'}
            id="hidden-engines"
            elevation={0}
            classes={{ paper: classes.paper }}
            className={classes.iconPopover}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleHiddenPopoverClose}
            disableRestoreFocus
          >
            <Grid
              className={classes.popoverContainer}
              justifyContent="center"
              direction="column"
              alignContent="flex-start"
              container
              item
              xs={12}
            >
              {engineRowHidden(hiddenEngines)}
            </Grid>
          </Popover>
        </>
      );
      renderedEngines.push(hiddenCount);
    } else {
      renderedEngines = engineRow(engineCategoriesToShow as ICategoriesWithEngines[]);
    }

    return renderedEngines;
  };

  return (
    <Grid
      data-test={'rendered-engine-categories'}
      justifyContent="flex-start"
      direction="row"
      alignContent="flex-start"
      container
      item
      xs={12}
    >
      {engineIcons()}
    </Grid>
  );
};
export default EngineIcons;

const RenderedIcons: FunctionComponent<{
  engine: ICategoriesWithEngines;
}> = ({ engine }) => {
  const { classes } = useStyles();
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);

  const handleRenderedPopoverOpen = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleRenderedPopoverClose = () => {
    setAnchorEl2(null);
  };

  const openRendered = Boolean(anchorEl2);

  return (
    <>
      <Grid
        data-test={`engine-category-${engine.id}`}
        id="engineIconContainer"
        className={classes.engineIconContainer}
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        container
        xs={2}
        aria-owns={openRendered ? 'rendered-engines' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleRenderedPopoverOpen}
        onMouseLeave={handleRenderedPopoverClose}
      >
        <Icon
          data-test={`engine-category-icon-${engine.iconName}`}
          className={classes.engineIcons}
          component={IconEngineHelper(engine.iconName)}
          color="secondary"
          fontSize="small"
        />
      </Grid>
      <Popover
        data-test={'popover-rendered-engines'}
        id="rendered-engines"
        elevation={0}
        style={{ pointerEvents: 'none' }}
        classes={{ paper: classes.paper }}
        className={classes.iconPopover}
        open={openRendered}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleRenderedPopoverClose}
        disableRestoreFocus
      >
        <div data-test={`rendered-engine-category-${engine.id}`} className={classes.popoverContainer}>
          <Icon
            data-test={`engine-category-icon-${engine.iconName}`}
            className={classNames(classes.engineIcons, classes.hiddenIcon)}
            component={IconEngineHelper(engine.iconName)}
            color="secondary"
            fontSize="small"
          />
          <Typography
            data-test={`engine-category-name`}
            noWrap
            className={classes.enginePopoverText}
            variant="caption"
          >
            {engine.name}
          </Typography>
          <Divider className={classes.divider} />
          <UsedEngines categoryId={engine.id} engines={engine.engines} />
        </div>
      </Popover>
    </>
  );
};

const UsedEngines: FunctionComponent<{
  engines: IEngine[];
  categoryId: EngineCategoryId;
  useColumns?: boolean;
}> = ({ engines, useColumns, categoryId }) => {
  const { classes } = useStyles();
  if (!engines) {
    return null;
  }
  const rows = () => {
    if (engines.length === 0) {
      return (
        <Grid item xs={useColumns ? 6 : 12} className={classes.engineRows}>
          <Typography noWrap className={classes.enginePopoverText} variant="caption">
            • {SHARED_TEXT.noEngineFound()}
          </Typography>
        </Grid>
      );
    }
    return engines.map(engine => {
      return (
        <Grid
          key={engine.id}
          data-test={`used-engine-${engine.id}`}
          item
          container
          xs={useColumns ? 6 : 12}
          className={useColumns ? classes.engineColumnRow : classes.engineRows}
        >
          <Typography
            data-test={'used-engine-name'}
            noWrap
            className={classes.enginePopoverText}
            variant="caption"
          >
            • {engine.name}
          </Typography>
        </Grid>
      );
    });
  };

  return (
    <Grid
      data-test={`used-engines-categoryId-${categoryId}`}
      className={useColumns ? classes.engineColumns : classes.engineRows}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      container
      item
      xs={12}
    >
      {rows()}
    </Grid>
  );
};
