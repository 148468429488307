import { map, size, split } from 'lodash';
import { useMemo } from 'react';

export function useLineNumbers(value: string) {
  return useMemo(() => {
    const lineCount = size(split(value, '\n'));
    const outarr = map(new Array(lineCount), (_, i) => i + 1);
    return outarr.join('\n');
  }, [value]);
}
