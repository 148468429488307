import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  fetchOrganizationsSaga,
  fetchCurrentOrgSaga,
  fetchInviteeSaga,
  switchOrgSaga,
  fetchUserOrganizationInvitesSaga,
  setUserDefaultOrgSaga,
} from './';
import { actions } from '../../slices';

export function* getOrganizationsWatcher() {
  yield takeLatest(actions.organizationsState.organizationsFetchStart.type, fetchOrganizationsSaga);
}

export function* getCurrentOrgWatcher() {
  yield takeEvery(actions.organizationsState.currentOrganizationFetchStart.type, fetchCurrentOrgSaga);
}

export function* fetchInviteeWatcher() {
  yield takeEvery(actions.organizationsState.fetchInviteeStart.type, fetchInviteeSaga);
}

export function* switchToOrgWatcher() {
  yield takeEvery(actions.organizationsState.switchUserToOrganizationStart.type, switchOrgSaga);
}

export function* fetchUserOrganizaitonInvitesWatcher() {
  yield takeEvery(
    actions.organizationsState.fetchOrganizationInvitesStart.type,
    fetchUserOrganizationInvitesSaga
  );
}

export function* setUserDefaultOrgWatcher() {
  yield takeLatest(actions.organizationsState.setUserDefaultOrg.type, setUserDefaultOrgSaga);
}
