import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { ErrorState, NullState } from '@aiware/ui';
import {
  selectOrgAccessControlLoadingStatus,
  selectOrgAccessControls,
  selectOrgAccessControlPagination,
} from '../../redux';
import AccessControlTableContent from './AccessControlTableContent';

function AccessControlTable({ loadAccessControls }: { loadAccessControls: () => void }) {
  const accessControls = useSelector(selectOrgAccessControls);
  const { hasMore } = useSelector(selectOrgAccessControlPagination);
  const uiStatus = useSelector(selectOrgAccessControlLoadingStatus);

  const renderTableContent = () => {
    if (uiStatus === 'failure') {
      return (
        <ErrorState
          onRetry={loadAccessControls}
          errorMessage={
            <FormattedMessage
              id="os-admin-center-access-controls.error-message"
              defaultMessage="Something went wrong while loading your organization access controls."
              description="organization access control error state"
            />
          }
        />
      );
    } else if (uiStatus === 'success' && accessControls.length === 0) {
      return (
        <NullState
          header={
            <FormattedMessage
              id="os-admin-center-access-controls.null-message"
              defaultMessage="You have no group permissions to the Organization"
              description="organization access control no data state"
            />
          }
        />
      );
    } else {
      return (
        <AccessControlTableContent
          accessControls={accessControls}
          hasMore={hasMore}
          loadOrgAccessControls={loadAccessControls}
        />
      );
    }
  };

  return (
    <>
      <Grid
        data-test={'ac-access-control-table-header'}
        sx={{ height: 32, pl: 3 }}
        alignItems="center"
        display="flex"
        container
      >
        <Grid item xs={6} data-testid="org-access-control.table-header">
          <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
            <FormattedMessage
              id="os-ac-access-control-table-header-name"
              defaultMessage="Group Name"
              description="Admin center Org Acccess control table group name"
            />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
            <FormattedMessage
              id="os-ac-access-control-table-header-permission.set"
              defaultMessage="Permission Set"
              description="Admin center Org Access Control table permission set"
            />
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ opacity: 0.9 }} />
      {renderTableContent()}
    </>
  );
}

export default AccessControlTable;
