import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  table: {
    border: 'none!important' as 'none',
  },
  tableHead: {
    '& th': {
      padding: '11px',
    },
  },
  tableRowEmpty: {
    background: '#FAFAFA',
    height: '50px',
  },
  sortIcon: {
    opacity: '1!important' as unknown as 1,
  },
  borderBottomLineHeader: {
    opacity: '0.9!important',
    borderBottom: '0.5px solid #D5DFE9',
  },
  borderBottomLineHeaderAction: {
    opacity: '0.9!important',
    borderBottom: '0.5px solid #D5DFE9',
    width: '30px',
  },
}));
