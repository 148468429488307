import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '30px',
  },
}));
