import * as api from '../../api';
import { suggestionResultKey } from '../../components/AIAutocomplete/aiAutocomplete.constants';

/**
 * Main configiguration for the aiAutocompleteSagas.
 * Add configurations for the other capabilities (face, logo, tags, objects).
 */
export const aiAutocompleteSagaConfig = {
  [suggestionResultKey.tags]: {
    label: 'Tags',
    query: api.query.getTagsSuggestionsQuery,
    error: {
      title: 'An error has occurred!',
      friendlyMessage: 'An error occurred while fetching tags suggestions',
    },
  },
  [suggestionResultKey.objects]: {
    label: 'Objects',
    query: api.query.getObjectSuggestionsQuery,
    error: {
      title: 'An error has occurred!',
      friendlyMessage: 'An error occurred while fetching objects suggestions',
    },
  },
  [suggestionResultKey.logos]: {
    label: 'Logos',
    query: api.query.getLogoSuggestionsQuery,
    error: {
      title: 'An error has occurred!',
      friendlyMessage: 'An error occurred while fetching logo suggestions',
    },
  },
  [suggestionResultKey.facial]: {
    label: 'Face',
    query: api.query.getFaceSuggestionsQuery,
    error: {
      title: 'An error has occurred!',
      friendlyMessage: 'An error occurred while fetching facial suggestions',
    },
  },
};
