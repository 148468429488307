import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  changeAvatarIcon: {
    color: '#121212 !important',
    backgroundColor: '#ffffff !important',
    padding: `${theme.spacing(1.2)} !important`,
    '&:hover': {
      backgroundColor: '#ffffff !important',
    },
  },

  avatarLarge: {
    color: theme.palette.secondary.main,
    backgroundColor: '#ffffff !important',
    fontSize: '30px !important',
    width: '84px !important',
    height: '84px !important',
    border: '5px solid #E8E8E8',
  },
}));
export default useStyles;
