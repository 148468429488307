import { useEffect, useReducer, useState, useRef, useCallback, ChangeEvent } from 'react';
import Header from './header';
import ApplicationCard from './application-card';
import ApplicationSearch from './application-search';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/slices';
import { organizationInvitesSelectors } from '../../redux/selectors';
import {
  currentInvitee,
  invitees,
  myCurrentOrg,
  myCurrentRoles,
} from '../../redux/selectors/organizations.selector';
import { IOrgAppsAndRoles, TApplicationInviteRole, TRecord, TRole } from '../../types';
import { useApplicationPanelStyles } from './useStyles';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { hidePanel } from '@aiware/js/panel';
import { AvailableComponents } from '@aiware/js/interfaces';

export enum reducerActions {
  SET_ROLE,
  REMOVE_ROLE,
}

type OrgReducerAction = {
  type: reducerActions;
  payload: TApplicationInviteRole;
};

const orgReducer = (state: TApplicationInviteRole[], action: OrgReducerAction) => {
  switch (action.type) {
    case reducerActions.SET_ROLE: {
      const { applicationId, role, roleId } = action.payload;
      const newState = [...state];
      if (applicationId && roleId && role) {
        const appIndex = state.findIndex(app => app.applicationId === applicationId);
        if (appIndex === -1) {
          newState.push(action.payload);
        } else {
          newState[appIndex] = action.payload;
        }
      }
      return newState;
    }
    case reducerActions.REMOVE_ROLE: {
      const { applicationId } = action.payload;
      const newState = [...state];
      return newState.filter(app => app.applicationId !== applicationId);
    }
    default:
      return state;
  }
};

function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

function usePrevious<T>(value: T): T {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current!;
}

export const AddApplicationsPanel = () => {
  const [inviteeRoles, dispatchReducerAction] = useReducer(orgReducer, []);
  const [isLoading, setIsLoading] = useState(true);
  const previousRoles = usePrevious(inviteeRoles);
  const [orgApps, setOrgApps] = useState<TRecord[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [orgAppsWithRoles, setOrgAppsWithRoles] = useState<TRecord[]>([]);
  const orgId: string = useSelector(organizationInvitesSelectors.selectOrganizationId);
  const myInvitees = useSelector(invitees);
  const myCurrentInvitee = useSelector(currentInvitee);

  const currentOrg: IOrgAppsAndRoles = useSelector(myCurrentOrg);
  const currentRole: TRole[] = useSelector(myCurrentRoles);

  const { classes } = useApplicationPanelStyles();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted() && myInvitees && myCurrentInvitee && currentOrg) {
      const inviteeApps = myInvitees.find(i => i.email === myCurrentInvitee)?.applicationRoles || [];
      const organizationApps = [...currentOrg.applications.records];
      for (const inviteeApp of inviteeApps) {
        dispatchReducerAction({
          type: reducerActions.SET_ROLE,
          payload: {
            applicationId: inviteeApp?.application?.id as string,
            role: inviteeApp?.role?.id as any,
          } as TApplicationInviteRole,
        });
        const appIndex = organizationApps.findIndex(
          orgApp => orgApp.applicationId === inviteeApp?.applicationId
        );
        if (appIndex !== -1) {
          const newApp = {
            ...organizationApps[appIndex]!,
            providedRole: inviteeApp?.roleId,
          };
          organizationApps[appIndex] = newApp;
        }
      }

      setOrgApps(organizationApps);
    }
  }, [myInvitees, myCurrentInvitee, currentOrg]);

  useEffect(() => {
    if (isMounted()) {
      const currentOrgApps = orgApps
        .sort((a, b) => a.applicationName.localeCompare(b.applicationName))
        .filter(e => {
          return currentRole?.some(item => item.appName === e.applicationKey) || false;
        })
        .filter(e => e.applicationName.toLowerCase().includes(searchTerm.toLowerCase()));

      setOrgAppsWithRoles(currentOrgApps);

      if (orgApps?.length > 0) setIsLoading(false);
    }
  }, [searchTerm, orgApps]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.organizationsState.currentOrganizationFetchStart(orgId));
  }, [dispatch, orgId]);

  const disableSave = !inviteeRoles.length && (previousRoles || []).length === inviteeRoles.length;

  const onSave = () => {
    dispatch(actions.organizationsState.addInviteeRoles(inviteeRoles));
    dispatch(hidePanel(AvailableComponents.ADD_APPLICATIONS));
  };

  return (
    <div className={classes.root}>
      <div>
        <Header height={64} />
        <div className={classes.appSearchContainer}>
          <ApplicationSearch onChange={v => setSearchTerm(v)} />
        </div>
        <div className={classes.appCardsContainer}>
          {isLoading && <AddApplicationItemLoaderSkeleton />}
          {!isLoading &&
            orgAppsWithRoles.map(app => (
              <ApplicationCard
                data-testid={`multi-org-application-card-${app.applicationId}`}
                key={app.applicationId}
                card={app}
                roles={currentOrg.roles}
                manageRoles={dispatchReducerAction}
              />
            ))}
        </div>
      </div>
      <div>
        <Button
          data-testid="multi-org-applications-save"
          variant="contained"
          className={classes.saveButton}
          disabled={disableSave}
          onClick={onSave}
        >
          {SHARED_TEXT.saveBtn}
        </Button>
      </div>
    </div>
  );
};

const LoaderSkeletonItem = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular" width={30} height={30} />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton variant="text" width={330} height={50} />
        <Skeleton variant="text" width={400} height={25} />
      </ListItemText>
      <ListItemSecondaryAction>
        <Skeleton variant="rectangular" width={40} height={30} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const AddApplicationItemLoaderSkeleton = () => {
  const array = Array.from({ length: 15 }, (_, i) => i);
  return (
    <List>
      {array.map((_, index) => (
        <>
          <LoaderSkeletonItem key={index} />
          {index !== array.length - 1 && <Divider />}
        </>
      ))}
    </List>
  );
};
