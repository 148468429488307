import Box from '@mui/material/Box';
import useStyles from '../MyOrganizationsTab/useStyles';
import { AlarmTwo } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { FormattedMessage } from 'react-intl';

export interface IErrorStatePros {
  onRetry: () => void;
  errorMessage: JSX.Element;
}

const ErrorState = ({
  onRetry,
  errorMessage = (
    <FormattedMessage
      id="os-organization-panel.error-state-message"
      defaultMessage="Something went wrong"
      description="organizations error state message"
    />
  ),
}: IErrorStatePros) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Icon className={classes.icon}>
        <AlarmTwo fontSize="large" />
      </Icon>
      <Typography variant="body2" className={classes.text} data-test="my-organizations-error-message">
        {errorMessage}
      </Typography>
      <Box mt={4} />
      <Button variant="text" size="large" data-test="my-organizations-retry-btn" onClick={onRetry}>
        <FormattedMessage
          id="os-organization-panel.IZUdD9"
          defaultMessage="Retry"
          description="my organization retry button"
        />
      </Button>
    </div>
  );
};

export default ErrorState;
