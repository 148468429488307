import TextField from '@mui/material/TextField';
import { ChangeEvent, FC } from 'react';

interface ITxtField {
  id: string;
  label: string;
  required: boolean;
  type?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}

export const TxtField: FC<ITxtField> = ({ id, required, type, value, onChange, error = false }) => {
  return (
    <TextField
      id={id}
      sx={{
        width: '100%',
      }}
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
      required={required}
      value={value}
      type={type || ''}
      onChange={onChange}
      size="small"
      error={error}
    />
  );
};
