import { emptyOrgInvites, emptyOrgRequests } from '@aiware/shared/assets';
import useStyles from './emptyStateStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

export default function EmptyStateInvites({ type }: { type: 'invites' | 'inviteRequest' }) {
  const { classes } = useStyles();
  return (
    <div className={classes.container} data-testid={'organizations-panel-invites-empty-state-container'}>
      <img
        src={type === 'invites' ? emptyOrgInvites : emptyOrgRequests}
        alt={'No organization invites'}
        className={classes.image}
        data-testid={'organizations-panel-invites-empty-state-image'}
      />
      <Typography
        variant="body2"
        className={classes.header}
        data-testid={'organizations-panel-invites-empty-state-header'}
      >
        {type === 'invites' ? (
          <FormattedMessage
            id="os-organization-panel.invites.tab-empty-state-header"
            defaultMessage="You have no Invites"
            description="organization panel: invites tab, no invites header label"
          />
        ) : (
          <FormattedMessage
            id="os-organization-panel.invites.request.tab-empty-state-header"
            defaultMessage="You have no invite requests"
            description="organization panel: invites request panel, no invite request header label"
          />
        )}
      </Typography>

      <Typography
        variant="subtitle1"
        className={classes.description}
        data-testid={'organizations-panel-invites-empty-state-description'}
      >
        {type === 'invites' ? (
          <FormattedMessage
            id="os-organization-panel.invites.tab-empty-state-description"
            defaultMessage="Invitations to other Organizations can be found here."
            description="organization panel: invites tab, no invites description label"
          />
        ) : (
          <FormattedMessage
            id="os-organization-panel.invites.request.tab-empty-state-description"
            defaultMessage="Invitation requests sent from users of your organizations can be found here."
            description="organization panel: invite request panel, no invite request description label"
          />
        )}
      </Typography>
    </div>
  );
}
