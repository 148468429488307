import { FormattedMessage } from 'react-intl';
import { useStyles } from './recent-apps-dnd.styles';
import { IApplication } from '../../../types';
import { Grid, Typography, Collapse } from '@mui/material';
import { useState } from 'react';
import AppIcon from '../../atoms/AppIcon';
import { NoApps } from '@aiware/shared/icons';
import {
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  fetchUserAppGroupsStart,
  selectUserAppGroups,
  selectUserAppGroupsUiStatus,
  TUserAppGroup,
} from '../../../store/modules/app-groups';
import { useDispatch, useSelector } from 'react-redux';
import { AppGroupPlaceholder } from './app-group-placeholder';
import { GroupDraggingPlaceholder } from './group-dragging-placeholder';
import { GroupHandler } from './group-handler';
import { GroupApps } from './group-apps';
import { Status } from '@aiware/shared/redux';
import { ErrorState } from '@aiware/ui';
import LoadingSkeleton from './loading-skeleton';

type TRecentAppsProps = {
  apps: IApplication[];
  allApps: IApplication[];
  onSelectApplication: (id: string | number) => void;
  selectedAppId: string | number | null;
  showAppGroups?: boolean;
};

export const Runway = ({ classes, rootClassName }: { classes: any; rootClassName?: string }) => (
  <div className={`${classes.runway} ${rootClassName}`}>
    <Typography variant="body2" className={classes.landingText}>
      <FormattedMessage
        id="os-app-bar-panel.app-switcher.app-landing"
        defaultMessage="Drop Application Here"
        description="Helper text to tell users where apps should be dropped"
      />
    </Typography>
  </div>
);

const RecentAppsDnD = ({
  apps = [],
  allApps,
  onSelectApplication,
  selectedAppId,
  showAppGroups,
}: TRecentAppsProps) => {
  const { classes } = useStyles();
  const [currentSelected, setSelected] = useState(selectedAppId);
  const dispatch = useDispatch();
  function handleSelectApplication(id: string | number) {
    setSelected(id);
    onSelectApplication(id);
  }
  const uiStatus = useSelector(selectUserAppGroupsUiStatus);

  const userAppGroups = useSelector(selectUserAppGroups);

  const renderContent = () => {
    if (uiStatus === Status.loading) {
      return <LoadingSkeleton />;
    } else if (uiStatus === Status.failure) {
      return (
        <ErrorState
          onRetry={() => dispatch(fetchUserAppGroupsStart())}
          errorMessage={
            <FormattedMessage
              id="os-app-bar-panel.app-groups.error-message"
              defaultMessage="Something went wrong while loading your application groups."
              description="application groups error state"
            />
          }
        />
      );
    } else if (uiStatus === Status.success && userAppGroups.length) {
      return (
        <AppGroups
          apps={apps}
          allApps={allApps}
          userAppGroups={userAppGroups}
          handleSelectApplication={handleSelectApplication}
          currentSelected={currentSelected}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classes.root} data-testid="app-switcher.recent-apps.container">
      <Typography variant="body2" className={classes.title}>
        <FormattedMessage
          id="app-bar.app-switcher.recent-apps"
          defaultMessage="Recent Applications"
          description="Recent Application container title"
        />
      </Typography>
      {apps.length ? (
        <Grid container sx={{ paddingLeft: '10px' }}>
          {apps.map((app, index) => {
            const { id = index, iconUrl, name } = app;
            return (
              <Grid
                item
                xs={4}
                className={classes.appTile}
                key={app.id}
                sx={{ padding: 0 }}
                data-testid="app-switcher.recent-apps.app-tile"
              >
                <AppIcon
                  type="switcherGrid"
                  selected={id === currentSelected}
                  onClick={handleSelectApplication}
                  iconUrl={iconUrl}
                  id={id}
                  appName={name}
                  appListV2={true}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div className={classes.noApps}>
          <NoApps fontSize="large" />
          <Typography variant="body2" className={classes.helperText}>
            <FormattedMessage
              id="app-bar.app-switcher.no-recent-apps"
              defaultMessage="You haven’t viewed any applications yet."
              description="No Recent Application title"
            />
          </Typography>
        </div>
      )}
      {showAppGroups && renderContent()}
    </div>
  );
};

export type IAppGroupsProps = {
  userAppGroups: TUserAppGroup[];
  apps: IApplication[];
  allApps: IApplication[];
  handleSelectApplication: (id: string | number) => void;
  currentSelected: string | number | null;
};

const AppGroups = ({
  userAppGroups,
  allApps,
  apps,
  handleSelectApplication,
  currentSelected,
}: IAppGroupsProps) => {
  const { classes } = useStyles();

  return (
    <Droppable droppableId="group-list" direction="vertical" type="group-list">
      {(providedGroupList: DroppableProvided) => (
        <div ref={providedGroupList.innerRef} {...providedGroupList.droppableProps}>
          {userAppGroups.map((appGroup: TUserAppGroup, index: number) => {
            const groupApps: IApplication[] = [];
            appGroup.apps.forEach((appId: string) => {
              const app = [...allApps, ...apps].find(app => app.id === appId);
              if (app) {
                groupApps.push(app);
              }
            });
            if (appGroup.isNew) {
              return <AppGroupPlaceholder apps={groupApps} classes={classes} key={appGroup.id} />;
            }
            return (
              <Draggable draggableId={appGroup.id} index={index} key={appGroup.id}>
                {(
                  providedDraggableAppGroup: DraggableProvided,
                  snapshotDraggableAppGroup: DraggableStateSnapshot
                ) => (
                  <div
                    key={appGroup.id}
                    className={
                      snapshotDraggableAppGroup.isDragging ? classes.airborneGroup : classes.appGroup
                    }
                    ref={providedDraggableAppGroup.innerRef}
                    {...providedDraggableAppGroup.draggableProps}
                  >
                    <GroupDraggingPlaceholder
                      isDragging={snapshotDraggableAppGroup.isDragging}
                      className={classes.groupTitle}
                      dragHandleProps={providedDraggableAppGroup.dragHandleProps}
                      groupName={appGroup.groupName}
                    />
                    <Droppable droppableId={appGroup.id}>
                      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={snapshot.isDraggingOver ? classes.currentLanding : classes.idleLanding}
                        >
                          <GroupHandler
                            groupName={appGroup.groupName}
                            dragHandleProps={providedDraggableAppGroup.dragHandleProps}
                            groupId={appGroup.id}
                            isExpanded={appGroup.isExpanded}
                          />
                          {snapshot.isDraggingOver && !groupApps.length ? (
                            <Grid
                              container
                              sx={{
                                paddingLeft: '10px',
                                position: 'relative',
                                marginBottom: '15px',
                                minHeight: '110px',
                              }}
                            >
                              <Runway classes={classes} />
                            </Grid>
                          ) : null}
                          <Collapse
                            in={
                              (appGroup.isExpanded || appGroup.isExpanded === undefined) &&
                              groupApps.length > 0
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <GroupApps
                              groupApps={groupApps}
                              classes={classes}
                              snapshot={snapshot}
                              handleSelectApplication={handleSelectApplication}
                              currentSelected={currentSelected}
                              appGroup={appGroup}
                            />
                          </Collapse>
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            );
          })}
          {providedGroupList.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default RecentAppsDnD;
