import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataCenterView } from '@aiware/shared/redux';
import {
  Breadcrumb,
  defaultPage,
  FolderId,
  GraphQLPage,
  IFolder,
  IPageDetail,
  ITdo,
  LIMIT,
  SLICE_NAME,
  TdoId,
} from '../../types';
import { actions as panelAddFolderActions } from './panel-add-folder';
import { actions as tdoActions } from './common-actions/tdos';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'viewMyFiles';

export type State = {
  breadCrumbs: Breadcrumb[];
  status: LoadingStatus;
  rootFolderId: FolderId | null;
  foldersAndFiles: Record<
    FolderId,
    {
      status: LoadingStatus;
      hasMore: boolean;
      folders: IPageDetail<FolderId>;
      files: IPageDetail<TdoId>;
    }
  >;
  selectedItemId: FolderId | TdoId | null;
  selectedFolderId: FolderId | null;
  selectedItems: string[];
};

const getDefaultFilesPage = (viewType: DataCenterView): IPageDetail<string> =>
  viewType === DataCenterView.folders
    ? {
        ...defaultPage,
        hasNextPage: false,
      }
    : { ...defaultPage };

export const initialState: State = {
  breadCrumbs: [{ isRoot: true, id: 'myFilesRoot', name: 'Home' }],
  status: 'pending',
  rootFolderId: null,
  foldersAndFiles: {},
  selectedItemId: null,
  selectedFolderId: null,
  selectedItems: [],
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    addToMyFilesBreadCrumbs(
      state,
      { payload }: PayloadAction<{ breadcrumb: Breadcrumb; viewType: DataCenterView }>
    ) {
      const { breadcrumb, viewType } = payload;
      state.breadCrumbs.push(breadcrumb);

      if (breadcrumb.id && !state.foldersAndFiles[breadcrumb.id]) {
        state.foldersAndFiles[breadcrumb.id] = {
          status: 'idle',
          hasMore: true,
          folders: {
            ...defaultPage,
          },
          files: getDefaultFilesPage(viewType),
        };
        if (breadcrumb.id) {
          state.selectedItemId = breadcrumb.id;
        }
      }
    },
    goToMyFilesBreadCrumb(state, { payload }: PayloadAction<Breadcrumb>) {
      const breadCrumbs = state.breadCrumbs;

      const targetIdx = breadCrumbs.findIndex(breadCrumb => {
        if (payload.isRoot && breadCrumb.isRoot) {
          state.selectedItemId = null;
        } else if (payload.id) {
          state.selectedItemId = payload.id;
        } else {
          if (breadCrumb.id) state.selectedItemId = breadCrumb.id;
        }

        return (payload.isRoot && breadCrumb.isRoot) || payload.id === breadCrumb.id;
      });

      if (targetIdx !== -1) {
        state.breadCrumbs.splice(targetIdx + 1);
      }
      // if we are passing in a crumb that isn't in path - we need to add it
      if (targetIdx === -1 && payload.id) {
        state.breadCrumbs.push(payload);
      }

      // Reset the selected folder ID
      if (state.breadCrumbs.length === 1) {
        state.selectedFolderId = null;
      } else {
        const containingFolderId = state.breadCrumbs[state.breadCrumbs.length - 1]?.id;
        state.selectedFolderId = `${containingFolderId}`;
      }
    },
    rootFolderFetchStart(state) {
      state.status = 'pending';
    },
    rootFolderFetchSucceeded(
      state,
      { payload }: PayloadAction<{ rootFolder: IFolder; viewType: DataCenterView }>
    ) {
      const { rootFolder, viewType } = payload;

      state.rootFolderId = rootFolder.id;
      state.status = 'idle';
      state.foldersAndFiles[rootFolder.id] = {
        status: 'idle',
        hasMore: true,
        folders: {
          ...defaultPage,
        },
        files: getDefaultFilesPage(viewType),
      };
    },
    rootFolderFetchFailed(state) {
      state.status = 'failure';
    },
    foldersOrFilesFetchStart(state, { payload }: PayloadAction<FolderId>) {
      const parentFolderId = payload;

      if (!state.foldersAndFiles[parentFolderId]) {
        state.foldersAndFiles[parentFolderId] = {
          status: 'pending',
          hasMore: true,
          folders: {
            ...defaultPage,
          },
          files: {
            ...defaultPage,
          },
        };
      } else if (state.foldersAndFiles[parentFolderId]?.hasMore) {
        state.foldersAndFiles[parentFolderId]!.status = 'pending';
      }
    },
    foldersOrFilesFetchSucceeded(
      state,
      {
        payload,
      }: PayloadAction<{
        parentFolderId: FolderId;
        folders?: GraphQLPage<IFolder>;
        files?: GraphQLPage<ITdo>;
      }>
    ) {
      const { parentFolderId, folders, files } = payload;
      const { foldersAndFiles } = state;

      let folderIds: FolderId[] = [];
      if (folders) {
        folderIds = folders.records.map(folder => folder.id);
      }

      let fileIds: TdoId[] = [];
      if (files) {
        fileIds = files.records.map(file => file.id);
      }

      let hasMoreFolders = !!foldersAndFiles[parentFolderId]?.folders.hasNextPage;
      let hasMoreFiles = !!foldersAndFiles[parentFolderId]?.files.hasNextPage;
      if (folders) {
        hasMoreFolders = folders.count === LIMIT;
        foldersAndFiles[parentFolderId]!.folders = {
          ...foldersAndFiles[parentFolderId]!.folders,
          offset: foldersAndFiles[parentFolderId]!.folders.offset + LIMIT,
          hasNextPage: hasMoreFolders,
          ids: [...foldersAndFiles[parentFolderId]!.folders.ids, ...folderIds],
        };
      }
      if (files) {
        const ids = [...foldersAndFiles[parentFolderId]!.files.ids, ...fileIds];
        const newIds = [...new Set(ids)];
        hasMoreFiles = files.count === LIMIT;
        foldersAndFiles[parentFolderId]!.files = {
          ...foldersAndFiles[parentFolderId]!.files,
          offset: foldersAndFiles[parentFolderId]!.files.offset + LIMIT,
          hasNextPage: hasMoreFiles,
          ids: [...newIds],
        };
      }
      foldersAndFiles[parentFolderId]!.status = 'idle';
      foldersAndFiles[parentFolderId]!.hasMore = hasMoreFolders || hasMoreFiles;
    },
    foldersOrFilesFetchFailed(state, { payload }: PayloadAction<FolderId>) {
      const parentFolderId = payload;

      state.foldersAndFiles[parentFolderId]!.status = 'failure';
    },
    panelResultSelected(state, { payload }: PayloadAction<FolderId>) {
      return state;
    },
    refreshFiles(state, { payload }: PayloadAction<FolderId>) {
      const parentFolderId = payload;

      if (state.foldersAndFiles[parentFolderId]) {
        state.foldersAndFiles[parentFolderId] = {
          status: 'pending',
          hasMore: true,
          files: {
            ...defaultPage,
          },
          folders: {
            ...state.foldersAndFiles[parentFolderId]!.folders,
          },
        };
      }
    },
    setSelectedFolder(state, { payload: newFolderId }: PayloadAction<FolderId | null>) {
      // Handle de-selecting the active folder, reset to the current breadcrumb
      if (state.selectedFolderId === newFolderId) {
        if (state.breadCrumbs.length === 1) {
          state.selectedFolderId = null;
          return;
        }
        const containingFolderId = state.breadCrumbs[state.breadCrumbs.length - 1]?.id;
        state.selectedFolderId = `${containingFolderId}`;
      } else {
        state.selectedFolderId = newFolderId;
      }
    },
    toggleFileOrFolderSelected(state, { payload }: PayloadAction<FolderId | TdoId | null>) {
      if (payload === state.selectedItemId) {
        state.selectedItemId = null;
      } else {
        state.selectedItemId = payload;
      }
    },
    removeTdoFolder(state, { payload }: PayloadAction<{ folderId: FolderId | null; tdoId: TdoId }>) {
      if (payload.folderId) {
        if (state.foldersAndFiles[payload.folderId]) {
          const newFileIds = state.foldersAndFiles[payload.folderId]!.files.ids.filter(
            id => id !== payload.tdoId
          );
          state.foldersAndFiles[payload.folderId]!.files.ids = newFileIds;
        }
      }
    },
    addTdoFolder(state, { payload }: PayloadAction<{ folderId: FolderId | null; tdoId: TdoId }>) {
      if (payload.folderId) {
        if (state.foldersAndFiles[payload.folderId]) {
          state.foldersAndFiles[payload.folderId]!.files.ids = [
            payload.tdoId,
            ...state.foldersAndFiles[payload.folderId]!.files.ids,
          ];
        }
      }
    },
    moveTDOsToNewLocation(
      state,
      {
        payload,
      }: PayloadAction<{
        newFolderId: FolderId | null;
        tdoIds: TdoId[];
        parentFolderTDOLocations: { id: TdoId; parentFolderId: FolderId; success: boolean }[];
      }>
    ) {
      const failedTDOs: TdoId[] = [];

      // Remove each TDO from the previous parent folder
      payload.parentFolderTDOLocations.forEach(tdo => {
        const { id, parentFolderId, success } = tdo;
        if (!success) {
          failedTDOs.push(id);
          return;
        }
        if (state.foldersAndFiles[parentFolderId]) {
          // Remove the TDO from the folder
          const newIds = state.foldersAndFiles[parentFolderId]!.files.ids.filter(tdoId => id !== tdoId);
          state.foldersAndFiles[parentFolderId]!.files.ids = newIds;
        }
      });

      // Add the TDOs to the new folder
      if (payload.newFolderId) {
        if (state.foldersAndFiles[payload.newFolderId]) {
          state.foldersAndFiles[payload.newFolderId]!.files.ids = [
            ...payload.tdoIds.filter(tdoId => !failedTDOs.includes(tdoId)),
            ...state.foldersAndFiles[payload.newFolderId]!.files.ids,
          ];
        }
      }
    },
    addSelectedItem: (state, action: PayloadAction<string>) => {
      state.selectedItems.push(action.payload);
    },
    removeSelectedItem: (state, action: PayloadAction<string>) => {
      state.selectedItems = state.selectedItems.filter(id => id !== action.payload);
    },
    resetSelectedItems: state => {
      state.selectedItems = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(panelAddFolderActions.createNewFolderSucceeded, (state, { payload }) => {
      const { parentFolderId, newFolder } = payload;

      state.foldersAndFiles[parentFolderId]!.folders.ids = [
        newFolder.id,
        ...state.foldersAndFiles[parentFolderId]!.folders.ids,
      ];
    });
    builder.addCase(tdoActions.deleteTdoSucceeded, (state, { payload }) => {
      const { parentFolderId, tdoId } = payload;

      if (parentFolderId && tdoId && state.foldersAndFiles[parentFolderId]) {
        const newFileIds = state.foldersAndFiles[parentFolderId]!.files.ids.filter(id => id !== tdoId);
        state.foldersAndFiles[parentFolderId]!.files.ids = newFileIds;
      }
    });
  },
});

export const actions = slice.actions;
export default slice.reducer;
