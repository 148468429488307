import { MouseEvent, useState } from 'react';
import { Box, ButtonBase, Stack, Tooltip, Typography, TooltipProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const ColorSwatchChip = ({
  color,
  tooltipLabel,
  TooltipProps,
  testId = 'shared-color-swatch-chip',
}: {
  color: string;
  tooltipLabel?: string;
  TooltipProps?: TooltipProps;
  testId?: string;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    navigator.clipboard.writeText(color.toUpperCase()).then(_e => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  const getTooltipLabel = () => {
    if (isCopied) {
      return (
        <FormattedMessage
          id="shared-util-color-swatch-chip-copied"
          defaultMessage="Copied!"
          description="Shared Util Color Swatch Chip Click to copied tooltip label"
        />
      );
    }
    return (
      tooltipLabel || (
        <FormattedMessage
          id="shared-util-color-swatch-chip-click-to-copy"
          defaultMessage="Click to copy"
          description="Shared Util Color Swatch Chip Click to copy tooltip label"
        />
      )
    );
  };
  return (
    <Tooltip placement={'right'} {...(TooltipProps ? TooltipProps : {})} title={getTooltipLabel()}>
      <ButtonBase
        onClick={handleClick}
        sx={{
          backgroundColor: '#ededed',
          borderRadius: '5px',
          padding: '2px 5px',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: '#d2d2d2',
          },
        }}
        data-testid={testId}
      >
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', gap: '5px' }} direction={'row'}>
          <Box
            sx={{
              width: '15px',
              height: '15px',
              borderRadius: '3px',
              border: '2px solid rgb(85, 95, 124)',
              backgroundColor: color,
            }}
          ></Box>
          <Typography
            sx={{
              color: 'rgb(85, 95, 124)',
              lineHeight: '10px',
              fontSize: '12px',
              fontWeight: 600,
            }}
          >
            {color.toUpperCase()}
          </Typography>
        </Stack>
      </ButtonBase>
    </Tooltip>
  );
};
