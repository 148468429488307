import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { SHARED_TEXT } from './helpers/sharedText';

export interface IButtonPrevewProps {
  buttonColor: string;
  buttonTextColor: string;
}

export const ButtonPreview: React.FC<IButtonPrevewProps> = ({ buttonColor, buttonTextColor }) => {
  return (
    <>
      <Stack>
        <Typography variant="h3" sx={{ fontWeight: 600, fontSize: '16px', marginBottom: '5px' }}>
          {SHARED_TEXT.buttonPreview.buttonPreview()}
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: 400, fontSize: '14px' }}>
          {SHARED_TEXT.buttonPreview.buttonPreviewDescription()}
        </Typography>
      </Stack>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disableTouchRipple
        sx={{
          background: `${buttonColor}!important`,
          backgroundColor: `${buttonColor}!important`,
          color: `${buttonTextColor}!important`,
          '&.Sdk-MuiButton-root:not(:disabled):not(:hover)': {
            background: `${buttonColor}!important`,
            backgroundColor: `${buttonColor}!important`,
            color: `${buttonTextColor}!important`,
          },
          border: `1px solid ${buttonColor}`,
          height: '56px',
          width: '368px',
          fontSize: '18px',
          fontWeight: 600,
          flexShrink: 0,
          marginTop: '20px',
        }}
      >
        {SHARED_TEXT.buttonPreview.continueButton()}
      </Button>
    </>
  );
};
