import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../constants';
import { TOrganizationInvite, TOrgInviteeInput, TUpdateOrganizationInviteInput } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'organizationInvitesState';

export interface IOrganizationInvitesState {
  organizationId: string;
  organizationGuid: string;
  createStatus: LoadingStatus;
  updateStatus: LoadingStatus;
  reRequestStatus: LoadingStatus;
  pendingInviteStatus: LoadingStatus;
  organizationInvites: TOrganizationInvite[];
  pendingInvites: TOrganizationInvite[];
}

export const initialState: IOrganizationInvitesState = {
  organizationId: '',
  organizationGuid: '',
  createStatus: 'idle',
  updateStatus: 'idle',
  reRequestStatus: 'idle',
  pendingInviteStatus: 'idle',
  organizationInvites: [],
  pendingInvites: [],
};

export const organizationInvitesSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    setOrganization(state, action: PayloadAction<{ id: string; guid: string }>) {
      state.organizationId = action.payload.id;
      state.organizationGuid = action.payload.guid;
    },
    createOrganizationInviteStart(
      state,
      _action: PayloadAction<{
        invitees: TOrgInviteeInput[];
        organizationId: string;
        message: string;
      }>
    ) {
      state.createStatus = 'pending';
    },
    createOrganizationInviteSucceed(state) {
      state.createStatus = 'success';
    },
    createOrganizationInviteFailed(state) {
      state.createStatus = 'failure';
    },

    updateOrganizationInviteStart(state, _action: PayloadAction<TUpdateOrganizationInviteInput>) {
      state.updateStatus = 'pending';
    },
    updateOrganizationInviteSucceed(state, _action: PayloadAction<TOrganizationInvite>) {
      state.updateStatus = 'success';
    },
    updateOrganizationInviteFailed(state) {
      state.updateStatus = 'failure';
    },
    reRequestOrganizationInviteStart(state, _action: PayloadAction<TOrganizationInvite>) {
      state.reRequestStatus = 'pending';
    },
    reRequestOrganizationInviteSucceed(state, _action: PayloadAction<TOrganizationInvite>) {
      state.reRequestStatus = 'success';
    },
    reRequestOrganizationInviteFailed(state) {
      state.reRequestStatus = 'failure';
    },
    fetchPendingInvitesStart(state, _action: PayloadAction<string | number | undefined>) {
      state.pendingInviteStatus = 'pending';
    },
    fetchPendingInvitesSucceed(state, action: PayloadAction<TOrganizationInvite[]>) {
      state.pendingInviteStatus = 'success';
      state.pendingInvites = action.payload;
    },
    fetchPendingInvitesFailed(state) {
      state.pendingInviteStatus = 'failure';
    },
    updatePendingInviteStatus(state, _action: PayloadAction<TUpdateOrganizationInviteInput[]>) {
      return state;
    },
  },
});

export const actions = organizationInvitesSlice.actions;
export default organizationInvitesSlice.reducer;
