import { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import Grid from '@mui/material/Grid';
import { selectPageSources } from '../../../../redux/selectors/view-streams';
import { selectSourceEntities } from '../../../../redux/selectors/entities';
import { EntityType } from '@aiware/js/interfaces';
import { useSelector } from 'react-redux';
import EmptyState from '../../../shared/EmptyState';
import SourcesTable from './SourcesTable';
import { SHARED_TEXT } from '../../../../helpers/shared-text';

const Sources: FunctionComponent = () => {
  const sourceEntities = useSelector(selectSourceEntities);
  const sourcePage = useSelector(selectPageSources);

  const items = sourcePage.ids.map(sourceId => sourceEntities[sourceId]!) || [];

  if (isEmpty(items) && !sourcePage.hasNextPage) {
    // TODO add action for add new stream clicked
    return (
      <Grid container justifyContent="center">
        <EmptyState
          emptyType={EntityType.Sources}
          title={SHARED_TEXT.emptySourcesTitle()}
          subtitle={SHARED_TEXT.emptySourcesSubtitle()}
        />
      </Grid>
    );
  }

  return <SourcesTable items={items} />;
};

export default Sources;
