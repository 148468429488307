import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareCacheProvider } from '@aiware/shared/theme';
import { AuditLogWidgetContainer } from './AuditLogWidget.container';
import { getAuditLogModule } from '../redux';

/* eslint-disable-next-line */
export interface WidgetProps {
  isSingleUserMode?: boolean;
}

export function AuditLogWidget(props: WidgetProps) {
  return (
    <AIWareCacheProvider>
      <AIWareThemeProvider fullHeight>
        <DynamicModuleLoader modules={[getAuditLogModule()]}>
          <AIWareIntlProvider fullHeight>
            <AuditLogWidgetContainer {...props} />
          </AIWareIntlProvider>
        </DynamicModuleLoader>
      </AIWareThemeProvider>
    </AIWareCacheProvider>
  );
}

export default AuditLogWidget;
