import { put, select } from 'redux-saga/effects';
import api from '../../api';
import { actions } from '../slices';
import {
  showMessage,
  MessageSeverity,
  preferredLanguageSelector,
  selectApiConfigs,
  userSelector,
} from '@aiware/shared/redux';
import { IUser } from '@aiware/js/interfaces';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { EApplicationConfigLevel, selectAdminCenterOrgIdString } from '@aiware/os/admin-center/shared';
import { GraphQLPage, TApplicationConfig } from '../../types';

const { applicationConfigAPI } = api;

export function* fetchApplicationConfigSaga(
  action: ReturnType<typeof actions.userApplicationSettings.fetchApplicationConfigStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const user: IUser = yield select(userSelector);
    const orgId: string = yield select(selectAdminCenterOrgIdString);
    const userApiConfigs: GraphQLPage<TApplicationConfig> = yield applicationConfigAPI.fetchApplicationConfig(
      apiConfigs,
      {
        userId: user.userId || '',
        orgId,
        ...action.payload,
      }
    );
    yield put(
      actions.userApplicationSettings.fetchApplicationConfigSuccess(
        userApiConfigs.records.filter(config => config.configLevel === (EApplicationConfigLevel as any).User)
      )
    );
  } catch (e) {
    yield put(actions.userApplicationSettings.fetchApplicationConfigFailure());
    console.log(e);
  }
}

export function* updateApplicationConfigSaga(
  action: ReturnType<typeof actions.userApplicationSettings.updateApplicationConfigStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const updatedUserConfigs: GraphQLPage<TApplicationConfig> =
      yield applicationConfigAPI.updateApplicationConfig(apiConfigs, action.payload);
    yield put(actions.userApplicationSettings.updateApplicationConfigSuccess(updatedUserConfigs.records));
    const message: any = {
      content: formatMessage({
        id: 'os-admin-center-user-application-settings.update-configs-success-message',
        defaultMessage: 'The configs was successfully updated',
        description: 'Confirmation message when a configs was successfully updated',
      })!,
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  } catch (e) {
    yield put(actions.userApplicationSettings.updateApplicationConfigFailure());
    const message: any = {
      content: formatMessage({
        id: 'os-admin-center-user-application-settings.update-configs-error-message',
        defaultMessage: 'Something went wrong while updating configs',
        description: 'Confirmation message when a configs is not successfully updated',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
    console.log(e);
  }
}
