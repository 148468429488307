import { useStyles } from '../../sourceScheduleFilter.styles';
import { Checkbox, Typography } from '@mui/material';
import { TooltipOnTextOverflow } from '../../../../../../../shared/TooltipOnTextOverflow';
import { ISchedule } from '../../../../../../../../types';

export const ScheduleItem = ({
  schedule,
  onClick,
  isActive,
}: {
  schedule: ISchedule;
  onClick: (isActive: boolean) => void;
  isActive: boolean;
}) => {
  const { classes } = useStyles();

  const handleToggle = () => {
    if (!isActive) {
      onClick(true);
    } else {
      onClick(false);
    }
  };

  return (
    <div className={`${classes.schedule} ${isActive ? 'active-schedule' : ''}`} onClick={handleToggle}>
      <Checkbox
        checked={isActive}
        sx={{
          '&.Sdk-Mui-checked': {
            color: `#1D6BBA`,
          },
        }}
      />
      <Typography noWrap={true}>
        <TooltipOnTextOverflow title={schedule?.name ? schedule.name : schedule?.id} maxCharLength={28}>
          <span>{schedule?.name ? schedule.name : schedule?.id}</span>
        </TooltipOnTextOverflow>
      </Typography>
    </div>
  );
};
