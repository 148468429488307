import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  suggestionContainer: {
    display: 'flex',
    marginLeft: 0,
    padding: '5px',
    borderRadius: '5px',
    marginBottom: '5px',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: '#F2F7FE',
    },
  },
  suggestionTitle: {
    fontWeight: 600,
    color: '#5C6269',
    margin: '0px',
    position: 'sticky',
    top: 0,
    background: '#FFF',
    paddingBottom: '5px',
  },
  suggestionItem: {
    marginLeft: 0,
    marginBottom: '10px',
    cursor: 'pointer',
  },
}));
