import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  outerContainer: {
    margin: '0 30px',
  },
  formControlWrapper: {
    marginTop: 15,
    width: '100%',
  },
  inputLabel: {
    fontFamily: 'Nunito',
    fontWeight: 600,
    display: 'block',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#2A323C',
    marginBottom: 4,
  },
  inputLabelError: {
    color: theme.palette.error.main,
  },
  description: {
    fontWeight: 400,
    marginBottom: 9,
  },
}));
