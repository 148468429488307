import { FunctionComponent, ElementType, ReactElement } from 'react';
import { UpDown } from '@aiware/shared/icons';
import type { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import { selectionShape } from '../types';
import useStyles from '../useStyles';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import keys from 'lodash/keys';
import InputSelect from './input-select';
import { useFormContext } from 'react-hook-form';

interface Props {
  handleSelectFormValue?: (selectedDropdownValue: selectionShape) => void;
  selectionItems: Record<selectionShape['name'], selectionShape>;
  iconType: Record<string, ElementType>;
  selectIcon: ElementType;
  selectPlaceholder: ReactElement;
  dataTestPrefix: string;
  selectedItem?: selectionShape | null;
  selectFormName: string;
}

const Dropdown: FunctionComponent<Props> = ({
  dataTestPrefix,
  handleSelectFormValue,
  selectionItems,
  iconType,
  selectPlaceholder,
  selectIcon,
  selectedItem,
  selectFormName,
}: Props) => {
  const { classes } = useStyles();
  const methods = useFormContext();

  const getDefaultSelectValue = () => {
    const defaultSelectValue = isEmpty(selectedItem) ? '' : selectedItem;

    methods.setValue(selectFormName, defaultSelectValue);

    return defaultSelectValue;
  };

  const handleSelectFormValueChange = (event: SelectChangeEvent<HTMLSelectElement> | undefined) => {
    const value = event?.target.value as string;
    const selectedValue = selectionItems[value]!;

    methods.setValue(selectFormName, selectedValue);
    methods.trigger(selectFormName);
    handleSelectFormValue?.(selectedValue);
  };

  return (
    <div className={selectedItem ? classes.selectedForm : classes.selectForm}>
      <FormControl fullWidth variant="outlined">
        <InputSelect
          name={selectFormName}
          rules={{ required: true }}
          displayEmpty
          data-test={`${dataTestPrefix}-select-form`}
          IconComponent={() => <UpDown className={classes.iconStyle} />}
          classes={
            !selectedItem
              ? { select: classes.rootSelectForm }
              : {
                  select: classes.rootSelectedForm,
                  icon: classes.iconComponent,
                }
          }
          defaultValue={getDefaultSelectValue()}
          onChange={handleSelectFormValueChange}
          onClick={() => methods.trigger(selectFormName)}
          renderValue={selected => {
            if (!selected) {
              return (
                <Typography variant="h6">
                  <Icon
                    classes={{ root: classes.iconBackground }}
                    className={classes.itemIcons}
                    component={selectIcon}
                  />
                  {selectPlaceholder}
                </Typography>
              );
            }
            return (
              <div className={classes.selectedName}>
                <Icon
                  classes={{ root: classes.iconBackgroundSelected }}
                  className={classes.itemIcons}
                  component={
                    (selectedItem?.selectedIconName && iconType?.[selectedItem.selectedIconName]) || 'span'
                  }
                />
                {get(selectedItem, 'formattedName') || get(selectedItem, 'name')}
              </div>
            );
          }}
        >
          {selectionItems &&
            map(keys(selectionItems), item => (
              <MenuItem
                disabled={item === 'marketplace'}
                className={classes.menuItem}
                data-test={selectionItems[item]?.value || item}
                value={selectionItems[item]?.value || item}
                key={selectionItems[item]?.value || item}
              >
                <Icon
                  classes={{ root: classes.iconBackground }}
                  className={classes.itemIcons}
                  component={
                    (selectionItems?.[item]?.iconName && iconType[selectionItems[item]?.iconName || '']) ||
                    'span'
                  }
                />
                <Grid className={classes.optionName} xs={2}>
                  <Typography variant="h6">
                    {selectionItems[item]?.formattedName || selectionItems[item]?.name}
                  </Typography>
                  <Typography className={classes.summary}>{selectionItems[item]?.description}</Typography>
                </Grid>
              </MenuItem>
            ))}
        </InputSelect>
      </FormControl>
    </div>
  );
};

export default Dropdown;
