import { Skeleton } from '@mui/material';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
} from '@mui/material';
import useStyles from './useStyles';

const LoaderSkeletonItem = () => {
  const { classes } = useStyles();
  return (
    <ListItem className={classes.openIdItem}>
      <ListItemAvatar className={classes.listAvatar}>
        <Skeleton variant="circular" width={25} height={25} />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton variant="text" width={150} height={21} />
      </ListItemText>
      <ListItemSecondaryAction className={classes.secondaryAction}>
        <Skeleton variant="rectangular" width={87} height={35} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const LoaderSkeleton = () => {
  return (
    <List>
      <LoaderSkeletonItem />
      <Divider />
      <LoaderSkeletonItem />
      <Divider />
      <LoaderSkeletonItem />
    </List>
  );
};

export default LoaderSkeleton;
