import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITdo, SLICE_NAME } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'fileInfo';

export type State = {
  loadingStatus: LoadingStatus;
  fileInfo?: ITdo;
};

export const initialState = {
  loadingStatus: 'idle',
} as State;

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchFileInfoStart(
      state,
      action: PayloadAction<{
        entityId: string;
      }>
    ) {
      state.loadingStatus = 'pending';
    },
    fetchFileInfoSucceeded(state, action: PayloadAction<{ fileInfo: ITdo }>) {
      state.loadingStatus = 'success';
      state.fileInfo = action.payload.fileInfo;
    },
    fetchFileInfoFailed(state) {
      state.loadingStatus = 'failure';
      delete state.fileInfo;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
