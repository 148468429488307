import { makeStyles } from 'tss-react/mui';

export const useApplicationCardStyles = makeStyles<void, 'optionText'>()((theme, _props, classes) => ({
  root: {
    marginTop: '22px',
  },
  appInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarCol: {
    width: '30px',
    marginRight: '14px',
  },
  avatar: {
    width: '30px',
    height: '30px',
    fontSize: '12px',
  },
  toggleCol: {
    width: '35px',
    marginRight: '12px',
    marginTop: '-12px',
  },
  toggleOff: {
    [`& .Sdk-MuiSwitch-thumb`]: {
      background: 'rgb(158,167,179)',
    },
    [`& .Sdk-MuiSwitch-track`]: {
      background: 'rgb(218,222,225)',
    },
    [`& .Sdk-Mui-disabled+.Sdk-MuiSwitch-track`]: {
      opacity: 0.8,
    },
  },
  toggleOn: {
    [`& .Sdk-MuiSwitch-thumb`]: {
      background: '#2F80ED',
    },
    [`& .Sdk-MuiSwitch-track`]: {
      background: 'rgb(182,206,244)',
    },
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
  },
  cardText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  select: {
    width: '270px',
    position: 'relative',
    [`& > .Sdk-MuiSelect-select > :nth-child(2)`]: {
      display: 'none !important',
    },
  },
  menuItem: {
    width: '436px',
    display: 'block',
    [`& < ul < div`]: {
      left: '150px !important',
    },
  },
  optionTitle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
  },
  optionText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'break-spaces',
  },
  selectCol: {
    margin: '28px 0 28px 44px',
  },
}));

export const useApplicationPanelStyles = makeStyles<void>()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appSearchContainer: {
    padding: '20px 30px 0',
  },
  appCardsContainer: {
    padding: '10px 30px',
    height: 'calc(100vh - 260px)',
    overflowY: 'scroll',
  },
  saveButton: {
    float: 'right',
    margin: '25px 44px',
  },
}));
