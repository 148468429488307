import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOperator,
  selectSelectedProperty,
} from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { actions } from '../../../../../state/aiStructuredData/aiStructuredData.slice';
import { EPropertyType } from './MatchValueContainer';
import {
  EJSONSchemaStringFormat,
  EStringOperatorType,
  ENumericOperatorType,
  EBooleanOperatorType,
} from '../../../../../types/aiStructuredData.types';
import { FormattedMessage } from 'react-intl';

interface IOperatorSelectProps {
  propertyType: string;
}

const OPERATOR_LABELS = {
  is: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is"
      defaultMessage="Is"
      description="Data Center structured data operator menu item: is"
    />
  ),
  is_not: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is_not"
      defaultMessage="Is not"
      description="Data Center structured data operator menu item: is not"
    />
  ),
  gte: (
    <FormattedMessage
      id="os-dc-sds-operator-type.gte"
      defaultMessage="Greater than or equal to >="
      description="Data Center structured data operator menu item: gte"
    />
  ),
  lte: (
    <FormattedMessage
      id="os-dc-sds-operator-type.lte"
      defaultMessage="Less than or equal to <="
      description="Data Center structured data operator menu item: lte"
    />
  ),
  gt: (
    <FormattedMessage
      id="os-dc-sds-operator-type.gt"
      defaultMessage="Greater than >"
      description="Data Center structured data operator menu item: gt"
    />
  ),
  lt: (
    <FormattedMessage
      id="os-dc-sds-operator-type.lt"
      defaultMessage="Less than <"
      description="Data Center structured data operator menu item: lt"
    />
  ),
  contains: (
    <FormattedMessage
      id="os-dc-sds-operator-type.contains"
      defaultMessage="Contains"
      description="Data Center structured data operator menu item: contains"
    />
  ),
  not_contains: (
    <FormattedMessage
      id="os-dc-sds-operator-type.not-contains"
      defaultMessage="Does not contain"
      description="Data Center structured data operator menu item: does not contain"
    />
  ),
  within: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is_within"
      defaultMessage="Is within"
      description="Data Center structured data operator menu item: is within"
    />
  ),
  range: (
    <FormattedMessage
      id="os-dc-sds-operator-type.between"
      defaultMessage="Between"
      description="Data Center structured data operator menu item: is between"
    />
  ),
};

const OPERATOR_DATE_LABELS = {
  is: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is"
      defaultMessage="Is"
      description="Data Center structured data operator menu item: is"
    />
  ),
  is_not: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is_not"
      defaultMessage="Is not"
      description="Data Center structured data operator menu item: is not"
    />
  ),
  gte: (
    <FormattedMessage
      id="os-dc-sds-operator-type.after-or-on"
      defaultMessage="After or occurring on date"
      description="Data Center structured data operator menu item: After or occurring on date"
    />
  ),
  lte: (
    <FormattedMessage
      id="os-dc-sds-operator-type.before-or-on"
      defaultMessage="Before or occurring on date"
      description="Data Center structured data operator menu item: before or occurring on date"
    />
  ),
  gt: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is-after-date"
      defaultMessage="After date"
      description="Data Center structured data operator menu item: After date"
    />
  ),
  lt: (
    <FormattedMessage
      id="os-dc-sds-operator-type.before-date"
      defaultMessage="Before date"
      description="Data Center structured data operator menu item: is Before date"
    />
  ),
  contains: (
    <FormattedMessage
      id="os-dc-sds-operator-type.contains"
      defaultMessage="Contains"
      description="Data Center structured data operator menu item: Contains"
    />
  ),
  not_contains: (
    <FormattedMessage
      id="os-dc-sds-operator-type.not_contains"
      defaultMessage="Does not contain"
      description="Data Center structured data operator menu item: Does not contain"
    />
  ),
  within: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is-within"
      defaultMessage="Is within"
      description="Data Center structured data operator menu item: is within"
    />
  ),
  range: (
    <FormattedMessage
      id="os-dc-sds-operator-type.is-between-dates"
      defaultMessage="Between dates"
      description="Data Center structured data operator menu item: is Between dates"
    />
  ),
};

export const OperatorMenu: React.FC<IOperatorSelectProps> = props => {
  const dispatch = useDispatch();
  const propertyDefinition = useSelector(selectSelectedProperty).propertyDefinition;
  const operator = useSelector(selectOperator);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const container = useRef(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: string) => {
    dispatch(actions.setOperator(value));
    handleClose();
  };

  const renderValue = () => {
    if (!operator)
      return (
        <FormattedMessage
          id="os-dc-sds-operator-type.select-condition"
          defaultMessage="Select a match condition"
          description="Data Center structured data operator menu item: Select a match condition"
        />
      );

    return getOperatorLabels(operator, propertyDefinition);
  };

  const getOperators = () => {
    if (propertyDefinition.type === 'string' && propertyDefinition?.format) {
      switch (propertyDefinition.format) {
        case EJSONSchemaStringFormat.date:
        case EJSONSchemaStringFormat.dateTime:
        case EJSONSchemaStringFormat.time: {
          return ENumericOperatorType;
        }
        default: {
          return EStringOperatorType;
        }
      }
    } else {
      switch (propertyDefinition.type) {
        case EPropertyType.string: {
          return EStringOperatorType;
        }
        case EPropertyType.integer:
        case EPropertyType.number: {
          return ENumericOperatorType;
        }
        case EPropertyType.boolean: {
          return EBooleanOperatorType;
        }
        default: {
          return EStringOperatorType;
        }
      }
    }
  };

  useEffect(() => {
    let operator = null;
    if (Object.keys(getOperators()).length === 1) {
      Object.keys(getOperators()).forEach(key => {
        operator = key;
      });
    }

    dispatch(actions.setOperator(operator));
  }, [props.propertyType, propertyDefinition]);

  return (
    <div ref={container}>
      <Button
        id="sds-operator-menu-button"
        aria-controls={open ? 'sds-operator-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid={'dc-sds-operator-menu-button'}
      >
        {renderValue()}
      </Button>
      <Menu
        data-testid={'dc-sds-operator-menu'}
        id="sds-operator-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sds-operator-menu-button',
        }}
        container={container.current}
      >
        {Object.keys(getOperators()).map(operatorType => (
          <MenuItem
            data-testid={`dc-sds-operator-menu-item-${operatorType}`}
            onClick={() => handleChange(operatorType)}
            key={operatorType}
            value={operatorType}
          >
            {getOperatorLabels(operatorType, propertyDefinition)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const getOperatorLabels = (
  operatorType: string,
  propertyDefinition: { type: EPropertyType; format?: EJSONSchemaStringFormat }
) => {
  if (propertyDefinition.type === 'string' && propertyDefinition?.format) {
    switch (propertyDefinition.format) {
      case EJSONSchemaStringFormat.date:
      case EJSONSchemaStringFormat.dateTime:
      case EJSONSchemaStringFormat.time:
        return (OPERATOR_DATE_LABELS as any)[operatorType];
      default: {
        return (OPERATOR_DATE_LABELS as any)[operatorType];
      }
    }
  }

  return (OPERATOR_DATE_LABELS as any)[operatorType];
};
