import { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { UploadButton } from '../shared';
import { useStyles } from './fileItemMeta.styles';
import { IFile, IFileMeta, ITag } from '../../../types';
import { guid } from '@aiware/js/function';
import { AddImageBox } from './components/AddImageBox/AddImageBox';
import { useSelector } from 'react-redux';
import { preferredLanguageSelector } from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';

interface IFileItemMeta {
  file: IFile;
  metaData: IFile['meta'];
  onCommit: (meta: IFileMeta) => void;
}

export const FileItemMeta = forwardRef(function ({ file, metaData, onCommit }: IFileItemMeta, ref) {
  const { classes } = useStyles();
  const locale = useSelector(preferredLanguageSelector);
  const [thumbnailImg, setThumbnailImg] = useState<IFile | null>(null);
  const [fileName, setFileName] = useState('');
  const [tagText, setTagText] = useState('');
  const getCurrentDate = () => {
    const [month, day, year] = new Date()
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split('/');
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const [hour, min] = new Date()
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .split(':');
    return `${hour}:${min!.slice(0, 2)}`;
  };
  const [runDate, setRunDate] = useState(getCurrentDate());
  const [runTime, setRunTime] = useState(getCurrentTime());
  const [tags, setTags] = useState<ITag[]>([]);

  const formatMessage = AIWareFormatMessage(locale);

  useImperativeHandle(ref, () => ({
    resetForm() {
      setThumbnailImg(null);
      setFileName('');
      setRunDate('2021-12-22');
      setRunTime('12:00');
      setTags([]);
    },
  }));

  // On component load
  useEffect(() => {
    if (metaData) {
      const meta = metaData;

      setThumbnailImg(meta?.thumbnailImg);
      setFileName(meta?.fileName ? meta.fileName : file.name);
      meta?.runDate && setRunDate(meta?.runDate);
      meta?.runTime && setRunTime(meta?.runTime);
      setTags(meta?.tags || []);
    }
  }, []);

  useEffect(() => {
    onCommit({
      fileId: file?.id,
      fileName,
      //@ts-ignore
      thumbnailImg,
      runDate,
      runTime,
      tags,
    });
  }, [fileName, runDate, runTime, tags, thumbnailImg, file]);

  const handleAddTags = useCallback(() => {
    if (!tagText) return;

    function formatTags(tagText: string) {
      const addedTags = tagText.includes(',') ? tagText.split(',') : [tagText];
      const existingTagLabels = tags.map(tag => tag.value);
      const newTags: ITag[] = [];

      addedTags?.forEach(tag => {
        const trimmedTag = tag.trim();

        if (trimmedTag === '') return;
        if (!existingTagLabels.includes(trimmedTag)) {
          newTags.push({ value: trimmedTag });
          existingTagLabels.push(trimmedTag);
        }
        return;
      });

      return newTags;
    }

    const newTags = formatTags(tagText);

    setTags(tags => [...tags, ...newTags]);
    setTagText('');
  }, [tagText, tags]);

  const handleDeleteThumbnail = () => {
    setThumbnailImg(null);
  };

  return (
    <>
      <section>
        <UploadButton
          id="file-item-meta-avatar-image-upload"
          imageOnly={true}
          onUpload={e => {
            setThumbnailImg(getFileFromUpload(e.target.files![0] as File));
          }}
          isUploadEnabled={!thumbnailImg}
          render={({ handleButtonClick }) => (
            <AddImageBox
              title={
                <FormattedMessage
                  id="os-data-center-importer.uK8+3P"
                  defaultMessage="Thumbnail Image"
                  description="Thumbnail Image"
                />
              }
              backgroundImgUrl={thumbnailImg ? thumbnailImg?.fileUrl : ''}
              aspectRatio="cover"
              onClick={handleButtonClick}
              onDelete={handleDeleteThumbnail}
            />
          )}
        />
      </section>
      <section className={classes.topMargin}>
        <TextField
          id="file-item-meta-file-name"
          data-test="file-item-meta-file-name-input"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          label={formatMessage({
            id: 'os-data-center-importer.label.fileName',
            defaultMessage: 'File name',
            description: 'The input label for the File name',
          })}
          placeholder={formatMessage({
            id: 'os-data-center-importer.placeholder.fileName',
            defaultMessage: 'File Name',
            description: 'The placeholder for the File name',
          })}
          value={fileName}
          style={{ width: '100%' }}
          onChange={e => {
            setFileName(e.target.value);
          }}
        />
      </section>
      <section className={classes.topMargin}>
        <Typography
          data-test={`file-item-meta-display-time-heading`}
          variant="subtitle1"
          color="textSecondary"
          className={classes.heading}
        >
          <FormattedMessage
            id="os-data-center-importer.8aqtJF"
            defaultMessage="Media Display Time"
            description="Media Display Time heading"
          />
        </Typography>

        <Typography
          data-test={`file-item-meta-display-time-sub-heading`}
          variant="subtitle2"
          color="textSecondary"
          className={classes.subHeading}
        >
          <FormattedMessage
            id="os-data-center-importer.mhUvxM"
            defaultMessage="The actual time the content occurred."
            description="Media Display Time sub heading"
          />
        </Typography>

        <Stack direction={'row'} gap={3} className={`${classes.topMargin}`}>
          <TextField
            id="file-item-meta-run-date"
            label={formatMessage({
              id: 'os-data-center-importer.label.runDate',
              defaultMessage: 'Run Date',
              description: 'The input label for the Run Date',
            })}
            type="date"
            value={runDate}
            className={classes.runDate}
            onChange={e => setRunDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="file-item-meta-run-time"
            label={formatMessage({
              id: 'os-data-center-importer.label.runTime',
              defaultMessage: 'Run Time',
              description: 'The input label for the Run Time',
            })}
            type="time"
            value={runTime}
            onChange={e => setRunTime(e.target.value)}
            className={classes.runTime}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Stack>
      </section>
      <section className={classes.topMargin}>
        <Typography
          data-test={`file-item-meta-tags-heading`}
          variant="subtitle1"
          color="textSecondary"
          className={classes.heading}
        >
          <FormattedMessage
            id="os-data-center-importer.fXL811"
            defaultMessage="Tags"
            description="Tags heading"
          />
        </Typography>

        <Typography
          data-test={`file-item-meta-tags-sub-heading`}
          variant="subtitle2"
          color="textSecondary"
          className={classes.subHeading}
        >
          <FormattedMessage
            id="os-data-center-importer.WFj+x5"
            defaultMessage="Add searchable tags to better organize this data. Separate each tag with a comma."
            description="Tags sub heading"
          />
        </Typography>

        <Stack direction={'row'} gap={3} className={`${classes.topMargin}`}>
          <TextField
            id="file-item-meta-tags"
            autoComplete="off"
            value={tagText}
            className={classes.tagInput}
            onChange={e => {
              setTagText(e.target.value);
            }}
            size="medium"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleAddTags();
              }
            }}
          />

          <Button
            id="file-item-meta-tags-addBtn"
            variant="outlined"
            color="primary"
            disabled={!tagText}
            onClick={handleAddTags}
          >
            <FormattedMessage
              id="os-data-center-importer.rntBSe"
              defaultMessage="Add"
              description="Add tags to metadata"
            />
          </Button>
        </Stack>
        <ul id="tags" className={`${classes.tags} ${classes.topMargin}`}>
          {tags?.map(tag => {
            return (
              <li key={tag.value}>
                <Chip
                  id={guid()}
                  label={tag.value}
                  size="small"
                  className={classes.tag}
                  onDelete={() => {
                    setTags(tags.filter(x => x.value !== tag.value));
                  }}
                />
              </li>
            );
          })}
        </ul>
      </section>
    </>
  );

  function getFileFromUpload(file: File) {
    const url = URL.createObjectURL(file);

    const img = {
      id: guid(),
      fileUrl: url,
      name: file.name,
      size: file.size,
      type: file.type,
      percentComplete: 0,
    };

    return img;
  }
});
