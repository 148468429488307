// import { getActivityApi } from '../../../lib/apiActivity';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from './action';
import { showMessage, MessageSeverity, preferredLanguageSelector } from '@aiware/shared/redux';
import * as apiActivity from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';

// const Events = {
//   login_succeeded: 'Login success',
//   logout_succeeded: 'Logout success',
//   add_user_succeeded: 'Add user success',
//   org_updated: 'Org updated',
// };

interface IFormatActivityDataPayload {
  userName: string;
  organizationName: string;
}

type TDynamicObject = {
  [x: string]: any;
};

interface IFormatActivityData<T extends IFormatActivityDataPayload> {
  id: string;
  event: string;
  userId: string;
  userName: string;
  payload: T;
  createdDateTime: string;
}

export const formatActivityData = <T extends IFormatActivityDataPayload>(item: IFormatActivityData<T>) => {
  const { id, event, userId, userName, payload, createdDateTime } = item;
  if (event === 'login_succeeded') {
    return {
      id,
      userId,
      createdDateTime,
      event: 'Login succeeded',
      description: `${userName} logged in to Veritone`,
    };
  }

  if (event === 'logout_succeeded') {
    return {
      id,
      userId,
      createdDateTime,
      event: 'Logout succeeded',
      description: `${userName} successfully logged out`,
    };
  }
  if (event === 'add_user_succeeded') {
    const { userName: newUserName } = payload;
    return {
      id,
      userId,
      createdDateTime,
      event: 'Add user succeeded',
      description: `${newUserName} successfully added`,
    };
  }
  if (event === 'org_updated') {
    const { organizationName } = payload;
    return {
      id,
      userId,
      createdDateTime,
      event: 'Org Name Changed',
      description: `${userName} updated org "${organizationName}"`,
    };
  }

  return {
    id,
    userId,
    createdDateTime,
    event: 'Unknown activity',
    description: `Unknown activity`,
  };
};

export function* getActivitySaga() {
  const data: TDynamicObject = yield call(apiActivity.getActivityApi);

  if (!data.success) {
    const locale: string = yield select(preferredLanguageSelector);
    const formatMessage = AIWareFormatMessage(locale);

    const message: any = {
      content: formatMessage({
        id: 'os-app-bar-panel.snackbar.activityError',
        defaultMessage: 'Something went wrong!',
        description: 'The error message pops up when activity fetch fails.',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  } else {
    const { records } = data.data;
    const result = records.map(formatActivityData);

    yield put(actions.getActivitySuccess(result));
  }
}

export default function* activitySaga() {
  yield takeEvery(actions.GET_ACTIVITY, getActivitySaga);
}
