import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material';
import { SourcePanel } from './sourcePanel';
import { useStyles } from './contentTemplates.styles';
import { FormattedMessage } from 'react-intl';
import { emptyContentTemplates } from '@aiware/shared/assets';
import { ContentTemplateItem } from './ContentTemplateItem';
import { v4 as uuidv4 } from 'uuid';
import {
  selectAllSchemas,
  selectContentTemplatesToSave,
  selectCurrentSchemaList,
  selectFetchSchemaStatus,
} from '../redux/selectors';
import { actions } from '../redux';
import { cloneDeep } from 'lodash';
import { SHARED_TEXT } from '../helpers/shared-text';
import { Utils } from '@aiware/shared/reusable-utils';

interface ContentTemplates {
  existingTemplates: any[];
}

interface IAutocompleteOption {
  label: string;
  value: string;
}

export const ContentTemplates: FC<ContentTemplates> = ({ existingTemplates }) => {
  const [contentTemplates, setContentTemplates] = useState<any[]>([]);
  const isEmpty = contentTemplates.length === 0;
  const { classes } = useStyles({ isEmpty });

  const dispatch = useDispatch();

  const templatesToSave = useSelector(selectContentTemplatesToSave) || {};
  const allSchemas = useSelector(selectAllSchemas);
  const schemaList = useSelector(selectCurrentSchemaList);
  const fetchSchemaStatus = useSelector(selectFetchSchemaStatus);

  // Autocomplete State
  const options = [...schemaList].map(t => {
    return { label: t?.name ?? '', value: t?.id ?? '' };
  });
  const [value, setValue] = useState<IAutocompleteOption | undefined>({ label: '', value: '' });
  const [inputValue, setInputValue] = useState<string | undefined>('');

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} size="small" label="Select a Content Template" />
  );

  const renderOption = (props: HTMLAttributes<any>, option: IAutocompleteOption) => (
    <div {...props} key={option.value} data-test={`processing-center-content-templates-item-${option.label}`}>
      <Typography noWrap={true}>{Utils.truncatedString(option.label, 40)}</Typography>
    </div>
  );

  const onInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const onChangeAutocomplete = (event: React.SyntheticEvent, newValue: IAutocompleteOption) => {
    setValue(newValue);
    setInputValue(newValue?.label);

    const selectedSchemaId = newValue.value;
    const id = uuidv4();
    const contentTemplate = {
      id: id,
    };
    const newData = {
      [id]: {
        schemaId: selectedSchemaId,
        data: {},
        touched: true,
        submitted: false,
        errors: false,
      },
    };
    const savedData = { ...templatesToSave, ...newData };
    if (newValue?.value) {
      dispatch(actions.setContentTemplatesToSave(savedData));
      setContentTemplates([...contentTemplates, contentTemplate]);
    }
    setValue({ label: '', value: '' });
    setInputValue('');
  };

  useEffect(() => {
    dispatch(actions.fetchSchemaStart());
  }, [dispatch]);

  useEffect(() => {
    if (fetchSchemaStatus === 'success') {
      const templates: any = [];

      loadExistingTemplates(templates);

      loadTemplatesFromTabChange(templates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSchemaStatus]);

  return (
    <SourcePanel
      headerContent={() => {
        return (
          <section className={classes.contentTemplateSelectionContainer}>
            <Typography variant="h5" className={classes.title}>
              <FormattedMessage
                id="content-template.information"
                defaultMessage="Attach structured metadata to your data by selecting a Content Template."
                description="Content Template information"
              />
            </Typography>

            <Autocomplete
              data-test={'processing-center-content-templates-dropdown'}
              value={value}
              onChange={onChangeAutocomplete}
              inputValue={inputValue}
              onInputChange={onInputChange}
              disablePortal
              id="content-templates-autocomplete"
              options={options}
              sx={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
              renderInput={renderInput}
              renderOption={renderOption}
              blurOnSelect
              disableClearable
              clearOnBlur
              noOptionsText={<ContentTemplatesEmptyState />}
              getOptionLabel={(option: IAutocompleteOption) => option.label}
            />
          </section>
        );
      }}
      mainContent={() => {
        return (
          <div className={classes.contentTemplateContainer}>
            {!isEmpty ? (
              contentTemplates?.map((contentTemplate, index) => {
                const currentTemplateItem = allSchemas[templatesToSave[contentTemplate.id]!.schemaId];

                return (
                  <ContentTemplateItem
                    data={templatesToSave[contentTemplate.id]!.data}
                    key={contentTemplate.id}
                    currentTemplateItem={currentTemplateItem}
                    onChange={handleFormChange}
                    onSubmit={onSubmit}
                    onError={onError}
                    onRemove={handleRemoveContentTemplate}
                    templateId={contentTemplate.id}
                  />
                );
              })
            ) : (
              <section className={classes.emptyStateContainer} data-test={`empty-state-container`}>
                <img
                  data-test={`empty-state-image`}
                  alt={'No Content Templates'}
                  className={classes.emptyImage}
                  src={emptyContentTemplates}
                />
                <Typography
                  data-test={`empty-state-title`}
                  className={classes.message}
                  variant="h2"
                  color="textPrimary"
                >
                  {SHARED_TEXT.contentTemplatesEmpty()}
                </Typography>
              </section>
            )}
          </div>
        );
      }}
    />
  );

  function loadExistingTemplates(templates: any[]) {
    // also we are using redux for this because this component may be extracted to be reusable
    // check if existing templates were loaded already - if not load them

    if (existingTemplates.length > 0 && Object.keys(templatesToSave).length === 0) {
      let savedData = {};

      existingTemplates.map(template => {
        const id = uuidv4();
        const contentTemplate = {
          id: id,
        };

        const newData = {
          [id]: {
            schemaId: template.schemaId,
            data: template.data,
            touched: false,
            submitted: true,
            errors: false,
          },
        };

        templates.push(contentTemplate);
        savedData = { ...savedData, ...newData };
      });

      dispatch(actions.setContentTemplatesToSave(savedData));

      setContentTemplates(templates);
    }
  }

  function loadTemplatesFromTabChange(templates: any[]) {
    // if we left this component and come back - ie.. switching tabs - we want to load data from store if already there
    if (Object.keys(templatesToSave).length > 0) {
      for (const key in templatesToSave) {
        const contentTemplate = { id: key };

        templates.push(contentTemplate);
      }

      setContentTemplates(templates);
    }
  }

  function handleRemoveContentTemplate(contentTemplateId: string) {
    const remainingContentTemplates = contentTemplates.filter(item => item.id !== contentTemplateId);
    setContentTemplates(remainingContentTemplates);
    const savedData = cloneDeep(templatesToSave);
    delete savedData[contentTemplateId];
    dispatch(actions.setContentTemplatesToSave(savedData));
  }

  function onError(e: any, id: string) {
    // on error does not return formData - so we leave it as it was ie.. data: existing data
    const schemaId = templatesToSave[id]!.schemaId;
    const newData = {
      [id]: {
        schemaId: schemaId,
        data: templatesToSave[id]!.data,
        touched: true,
        submitted: true,
        errors: true,
      },
    };

    const savedData = { ...templatesToSave, ...newData };

    dispatch(actions.setContentTemplatesToSave(savedData));
  }

  function onSubmit(e: any, id: string) {
    const schemaId = templatesToSave[id]!.schemaId;
    const newData = {
      [id]: {
        schemaId: schemaId,
        data: e.formData,
        touched: true,
        submitted: true,
        errors: false,
      },
    };

    const savedData = { ...templatesToSave, ...newData };

    dispatch(actions.setContentTemplatesToSave(savedData));
  }

  function handleFormChange(e: any, id: any) {
    const schemaId = templatesToSave[id]!.schemaId;
    const newData = {
      [id]: {
        schemaId: schemaId,
        data: e.formData,
        touched: true,
        submitted: false,
        errors: e.errors.length > 0,
      },
    };

    const savedData = { ...templatesToSave, ...newData };

    dispatch(actions.setContentTemplatesToSave(savedData));
  }
};

const ContentTemplatesEmptyState = () => {
  const { classes } = useStyles({ isEmpty: true });

  return (
    <div className={classes.searchEmptyStateContainer}>
      <img src={emptyContentTemplates} alt={'Empty state'} />
      <Typography>
        <FormattedMessage
          id="os-processing-center.sources.content-templates.empty-state-text"
          defaultMessage="No Content Templates Found"
          description="Processing Center Sources Empty State Text"
        />
      </Typography>
    </div>
  );
};
