import { combineReducers } from 'redux';
import * as AiWareDesktopState from './aiware-desktop.state';

export const initialState = {
  [AiWareDesktopState.namespace]: AiWareDesktopState.initialState,
};

export const actions = {
  [AiWareDesktopState.namespace]: AiWareDesktopState.actions,
};

export const rootReducer = combineReducers({
  [AiWareDesktopState.namespace]: AiWareDesktopState.default,
});
