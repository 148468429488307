import { takeEvery } from 'redux-saga/effects';
import { actions } from '../slices';
import { fetchDataRegistriesSaga, fetchSDOViewerDataRegistrySaga } from './data-registries.saga';

export function* fetchDataRegistriesWatcher() {
  yield takeEvery(actions.dataRegistry.fetchSDOsStart.type, fetchDataRegistriesSaga);
}

export function* fetchSDOViewerDataRegistryWatcher() {
  yield takeEvery(actions.dataRegistry.fetchDataRegistryStart.type, fetchSDOViewerDataRegistrySaga);
}
