import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  menuItem: {
    borderRadius: 4,
    padding: theme.spacing(1),
    '& > svg': {
      marginRight: theme.spacing(2),
    },
    textTransform: 'none',
  },
  divider: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
}));
