import { AiwareFrameLink } from '.';

interface CustomWindow extends Window {
  aiware: {
    mountPanel: (config: any) => void;
  };
}

export const filePicker = (requestId: string, frameLink: AiwareFrameLink) =>
  (window as unknown as CustomWindow)['aiware'].mountPanel({
    panelId: 'DATA_CENTER_IMPORTER',
    microFrontend: {
      name: 'DATA_CENTER_IMPORTER',
      config: {
        requestId,
        frameLink,
      },
    },
    panelConfig: {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: document.querySelector('#VERITONE_PANEL_ID') ? 80 : 0,
      size: 'large',
      zIndex: 1000,
    },
  });
