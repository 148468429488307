import { useState, ChangeEvent, SyntheticEvent } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  Paper,
  Switch,
  Checkbox,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

const useStyles = makeStyles()(() => ({
  paper: {
    padding: '20px',
  },
  spacer: {
    height: '10px',
    width: '100%',
  },
  radioRow: {
    flexDirection: 'row',
  },
}));

export const DefaultThemesCheckTextRadio = () => {
  const { classes } = useStyles();
  const [value, setValue] = useState('female');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const preventDefault = (event: SyntheticEvent) => event.preventDefault();
  return (
    <Paper className={classes.paper}>
      <Typography variant="h1">Link</Typography>
      <div className={classes.spacer} />
      <Link href="#" onClick={preventDefault}>
        Text link to something
      </Link>
      <div className={classes.spacer} />
      <Typography variant="h1">Checkboxes</Typography>
      <Checkbox defaultChecked color="primary" />
      <Checkbox color="primary" />
      <Checkbox disabled color="primary" />
      <Typography variant="h1">Switches</Typography>
      <Switch />
      <Typography variant="h1">Radio</Typography>
      <div className={classes.spacer} />
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
        className={classes.radioRow}
      >
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
        <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
      </RadioGroup>
      <div className={classes.spacer} />
      <Typography variant="h1">Text Fields</Typography>
      <div className={classes.spacer} />
      <TextField id="outlined-helperText" label="Helper text" helperText="Some important text" />
      <div className={classes.spacer} />
      <TextField
        error
        id="outlined-helperText2"
        label="Helper text"
        defaultValue="Default Textfield error"
        helperText="Some important text"
      />

      <div className={classes.spacer} />
      <TextField
        id="outlined-nohelperText"
        defaultValue="No Label TextField"
        helperText="Some important text"
      />
    </Paper>
  );
};
