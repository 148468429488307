import { Stack, Typography } from '@mui/material';
import { SchemaDropdown } from './SchemaDropdown/SchemaDropdown';
import { PropertyDropdown } from './PropertyDropdown/PropertyDropdown';
import { MatchValueContainer } from './MatchValueContainer/MatchValueContainer';
import { SearchActions } from './SearchActions/SearchActions';
import { structuredDataText } from './AiStructuredData.text';

export const AiStructuredData: React.FC = () => {
  return (
    <Stack sx={{ padding: theme => theme.spacing(3, 4, 3, 4) }}>
      <Stack>
        <Typography data-test="dc-search-structured-data-title" variant="body1" fontWeight={500}>
          {structuredDataText.title()}
        </Typography>
        <Typography data-test="dc-search-structured-data-subtitle" variant="caption">
          {structuredDataText.subtitle()}
        </Typography>
      </Stack>
      <Stack
        sx={{
          gap: '15px',
          marginTop: '20px',
          // Delete these classes below when this feature is complete
          '& > .placeholder-remove-this-classname': {
            padding: '10px 15px',
            background: '#f4f4f4',
            borderRadius: '10px',
          },
          '& >.placeholder-remove-this-classname:last-of-type': {
            width: '50%',
            marginLeft: 'auto',
          },
        }}
      >
        <SchemaDropdown />
        <PropertyDropdown />
        <MatchValueContainer />
        <SearchActions />
      </Stack>
    </Stack>
  );
};
