import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    minWidth: 112,
  },
  selectMenu: {
    '> .Sdk-MuiSelect-standard:focus': {
      backgroundColor: 'unset',
    },
  },
  selectMenuItem: {
    '&.Sdk-Mui-selected': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  menuPaper: {
    backgroundColor: theme.palette.background.default,
    right: '30px !important',
    left: 'unset !important',
  },
}));
export default useStyles;
