import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  memberCardContainer: {
    width: '500px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
    borderBottom: '1px solid rgb(219, 226, 234)',
  },
  accessControlsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      border: '1px solid #D5DFE9',
      height: '36px',
    },
  },
  permissionsSelect: {
    color: 'black',
    fontWeight: 400,
    fontSize: '12px',
    maxHeight: '36px',
    paddingTop: '1px',
    transform: 'translateY(1px)',
    marginRight: '14px',
    '& fieldset': {
      border: '1px solid #D5DFE9',
    },
  },
  memberAvatar: {
    marginRight: '18px',
  },
  avatar: {
    color: 'black',
    fontSize: '14px',
    background: 'rgb(248,248,248)',
    '& svg': {
      fill: 'rgb(87,95,122)',
    },
  },
  flexContainer: {
    display: 'flex',
  },
  membersCountContainer: {
    cursor: 'pointer',
    color: 'rgb(36,82,170)',
  },
  userEmailContainer: {
    color: 'rgb(36,82,170)',
  },
  groupMemberContainer: {
    padding: '20px 0',
  },
}));
