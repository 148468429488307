import merge from 'lodash/merge';
import { ExpandMore } from '@aiware/shared/icons';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { EmptyState as EmptyStateComp } from '../empty-state/empty-state';
import EmptyFormBuilderSvg from './empty-form-builder-svg';

import FieldTypes from './components/FieldTypes';
import { FormFieldComponent } from './components/FormField';
import { convertFormFieldsToJsonSchema, convertJsonSchemaToFormFields } from './utils';
import { fieldTypesPanelStyles, formBuilderContainerStyles, formFieldsPanelStyles } from './styles';

import { FIELDS } from './constants';
import { FormBuilderProps, FormField } from './types';

const FieldCategories: React.FC = () => {
  const categories = Object.entries(FIELDS);
  const hasMultipleCategories = categories.length > 1;

  return (
    <>
      {categories.map(([category, fields], categoryIndex) => (
        <Accordion
          key={category}
          defaultExpanded
          disableGutters
          sx={{
            bgcolor: 'transparent',
            boxShadow: 'none',
            border: 'none',
          }}
        >
          <AccordionSummary
            expandIcon={hasMultipleCategories ? <ExpandMore /> : undefined}
            sx={{
              p: 0,
              flexDirection: 'row-reverse',
            }}
          >
            <Typography textTransform="capitalize" variant="h5" fontSize={'14px'} ml={1} noWrap>
              {category.replace('_', ' ')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              p: 0,
            }}
          >
            <Droppable droppableId={`field-types-${category}`} isDropDisabled={true}>
              {provided => (
                <Stack ref={provided.innerRef} {...provided.droppableProps} gap={1}>
                  {fields.map((type, index) => (
                    <FieldTypes key={type} type={type} index={categoryIndex * fields.length + index} />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export const FormBuilder: React.FC<FormBuilderProps> = ({
  initialSchema,
  onSchemaChange,
  EmptyState,
  ...props
}) => {
  const intl = useIntl();
  const testId = props?.testId ?? 'form-builder';
  const [fields, setFields] = useState<FormField[]>(
    initialSchema ? convertJsonSchemaToFormFields(initialSchema) : []
  );

  const emptyStateTitle = intl.formatMessage(
    {
      id: '{testId}.empty-state-title',
      defaultMessage: '{title}',
      description: 'Title for the empty state of the form builder',
    },
    { title: EmptyState?.title ?? "Let's get started!", testId }
  );

  const emptyStateDesc = intl.formatMessage(
    {
      id: '{testId}.empty-state-desc',
      defaultMessage: '{desc}',
      description: 'Desccription for the empty state of the form builder',
    },
    { desc: EmptyState?.description ?? 'Drag in Building Blocks from the left to craft your form.', testId }
  );

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // If dragging within the form fields list
    if (source.droppableId === 'form-fields' && destination.droppableId === 'form-fields') {
      const items = Array.from(fields);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem!);

      setFields(items);
      onSchemaChange(merge(initialSchema ?? {}, convertFormFieldsToJsonSchema(items)));
    }
    // If dragging from field types to form fields
    else if (source.droppableId.startsWith('field-types-') && destination.droppableId === 'form-fields') {
      // Find the category and type being dragged
      const categoryName = source.droppableId.replace('field-types-', '');
      const categoryFields = FIELDS[categoryName as keyof typeof FIELDS];
      const type = categoryFields[source.index] as string;

      const newField: FormField = {
        id: `field-${Date.now()}`,
        type: type as FormField['type'],
        // Generate a unique title for the field
        label: `${type.charAt(0).toUpperCase()}${type.slice(1)} Field`,
        required: false,
      };

      const newFields = [...fields];
      newFields.splice(destination.index, 0, newField);
      setFields(newFields);
      onSchemaChange(merge(initialSchema ?? {}, convertFormFieldsToJsonSchema(newFields)));
    }
  };

  const handleFieldChange = (index: number, updatedField: FormField) => {
    const newFields = [...fields];
    newFields[index] = updatedField;
    setFields(newFields);
    onSchemaChange(merge(initialSchema ?? {}, convertFormFieldsToJsonSchema(newFields)));
  };

  const handleDeleteField = (index: number) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
    onSchemaChange(convertFormFieldsToJsonSchema(newFields));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Stack
        gap={2}
        direction={'row'}
        sx={{
          height: '100%',
          minHeight: '600px', // fallback minimum height
          flex: 1,
          display: 'flex',
        }}
      >
        <Stack sx={formBuilderContainerStyles}>
          <Box sx={fieldTypesPanelStyles}>
            <FieldCategories />
          </Box>
        </Stack>

        <Stack
          sx={{
            flex: 1,
            height: '100%',
          }}
        >
          <Box sx={formFieldsPanelStyles}>
            <Droppable droppableId="form-fields">
              {(provided, snapshot) => (
                <Stack
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  gap={2}
                  style={{
                    overflowY: 'auto',
                    backgroundColor: snapshot.isDraggingOver ? '#e0e0e0' : '#f5f5f5',
                    padding: '8px',
                    borderRadius: '4px',
                    minHeight: '100%',
                  }}
                >
                  {fields.map((field, index) => (
                    <FormFieldComponent
                      key={field.id}
                      field={field}
                      index={index}
                      onDelete={handleDeleteField}
                      onFieldChange={handleFieldChange}
                    />
                  ))}
                  {provided.placeholder}
                  {fields.length === 0 && !snapshot.isDraggingOver && (
                    <EmptyStateComp
                      title={emptyStateTitle}
                      description={emptyStateDesc}
                      image={<EmptyFormBuilderSvg />}
                      ContainerProps={{
                        sx: {
                          textAlign: 'center',
                        },
                      }}
                    />
                  )}
                </Stack>
              )}
            </Droppable>
          </Box>
        </Stack>
      </Stack>
    </DragDropContext>
  );
};

export * from './types';

export default FormBuilder;
