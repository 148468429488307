import {
  IMailbox,
  IMicroFrontendConfig,
  IPanel,
  IVeritoneAppbarPanelConfig,
} from '@aiware/js/interfaces';
import { ISubscriptionModule } from './interface';

export const nameSpace = 'mailbox';

interface IState {
  [nameSpace]: ISubscriptionModule;
  panels: IPanel<IMicroFrontendConfig, IVeritoneAppbarPanelConfig>[];
}

export const selector = (state: IState) => state[nameSpace];

export const mailboxIdSelector = (state: IState) =>
  state[nameSpace]?.mailBoxIds;

export const unseenNotificationSelector = (state: IState) => {
  const mailbox = state?.[nameSpace]?.mailbox;
  return mailbox?.reduce((accumulate: number, item: IMailbox) => {
    return accumulate + item.unseenCount;
  }, 0);
};

export const panelSelector = (state: IState) =>
  state.panels ||
  ([] as IPanel<IMicroFrontendConfig, IVeritoneAppbarPanelConfig>[]);
