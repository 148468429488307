import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  SdkTableCellData: {
    fontSize: 12,
    color: '#000',
  },
  SdkTableCellHeader: {
    fontSize: 12,
    color: '#9CA8B4',
    borderBottom: 'none',
    paddingBottom: 0,
  },
  SdkTableCellAvatar: {
    width: '32px',
    padding: theme.spacing(3, 0, 3, 1),
  },
  smallAvatar: {
    width: 32,
    height: 32,
  },
  SdkTableCellStatus: {
    color: '#219653',
    textTransform: 'capitalize',
  },
  SdkTableCellStatusDeactived: {
    color: '#8D0707',
    textTransform: 'capitalize',
  },
}));
export default useStyles;
