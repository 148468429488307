import { namespace } from './selector';

export const HANDLE_CLICK_APP = `aiware/${namespace}/get handle click app`;

export const REQUEST_GET_APPLICATION_V1 = `aiware/${namespace}/request get app`;

export const GET_APPLICATION_V1_START = `aiware/${namespace}/get app list start`;

export const GET_APPLICATION_V1_SUCCESS = `aiware/${namespace}/get app list success`;

export const GET_APPLICATION_V1_ERROR = `aiware/${namespace}/get app list error`;

export const SET_APPLICATION_IN_STORE = `aiware/${namespace}/set application`;

export function setApplicationInStore(data: any) {
  return {
    type: SET_APPLICATION_IN_STORE,
    payload: { data },
  };
}

export function requestGetApplications() {
  return {
    type: REQUEST_GET_APPLICATION_V1,
    payload: {},
  };
}

export function getApplicationStart() {
  return {
    type: GET_APPLICATION_V1_START,
  };
}

export function getApplicationSuccess(data: unknown) {
  return {
    type: GET_APPLICATION_V1_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getApplicationError(message: string) {
  return {
    type: GET_APPLICATION_V1_ERROR,
    payload: {
      message,
    },
  };
}

export function handleClickApp(applicationId: string) {
  return {
    type: HANDLE_CLICK_APP,
    payload: applicationId,
  };
}
