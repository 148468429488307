export enum EPermissionAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  File = 'file',
  CustomerService = 'customerService',
  Access = 'access',
}

export enum EAuthResourceType {
  // These should match (excluding case) the graphql AuthResourceType type
  Folder = 'Folder',
  TDO = 'TDO',
  Organization = 'Organization',
  Source = 'Source',
  Schedule = 'Schedule',
  Job = 'Job',
}

export enum EAuthSubResourceType {
  Group = 'group',
  Media = 'media',
  Package = 'package',
  User = 'user',
  Workflow = 'workflow',
  ProcessingCenter = 'processingCenter',
  ResourceCenter = 'resourceCenter',
}

export type TPermissions = Partial<Record<EAuthResourceType | EAuthSubResourceType, EPermissionAction>>;
