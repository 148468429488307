import {
  FormControl,
  FormControlProps as FormControlPropsMui,
  FormHelperText,
  FormLabel,
  Switch,
  TextField,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { ChangeEvent, ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { JSONEditor } from '../json-editor/json-editor';

enum EApplicationConfigLevel {
  Organization = 'Organization',
  User = 'User',
}

enum EApplicationConfigType {
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  Integer = 'Integer',
  JSON = 'JSON',
  Float = 'Float',
}

interface AppConfigInputProps {
  label?: boolean;
  FormControlProps?: Omit<FormControlPropsMui, 'error'>;
  onChange: (field: { name: string; value: string }) => void;
  isFormValid: (toggle: boolean) => void;
  disabled?: boolean;

  field: {
    applicationId: string;
    configKey: string;
    configType: EApplicationConfigType | `${EApplicationConfigType}`;
    configLevel: EApplicationConfigLevel | `${EApplicationConfigLevel}`;
    description?: string;
    required: boolean;
    secured: boolean;
    value?: string;
    valueJSON?: {
      [key: string]: any;
    };
    defaultValue?: string;
    defaultValueJSON?: {
      [key: string]: any;
    };

    [key: string]: unknown;
  };
}

export function AppConfigInput({
  field,
  FormControlProps = {},
  isFormValid,
  onChange,
  label = false,
  disabled = false,
}: AppConfigInputProps) {
  let inputComp: ReactNode = null;

  const hasErrors = !!getFieldErrorMessage(field);

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (field.configType === 'Boolean') {
      onChange({
        name: field.configKey,
        value: String(event.target.checked),
      });
    } else {
      onChange({
        name: field.configKey,
        value: event.target.value,
      });
    }
  };

  const defaultProps: {
    id: string;
    required: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    size?: 'small' | 'medium';
    disabled?: boolean;
    [key: string]: unknown;
  } = {
    id: `app-configuration-input-${field.configType}-${field.applicationId}`,
    'data-test': `app-configuration-input`,
    'data-test-id': `app-configuration-input-${field.configType}-${field.applicationId}`,
    required: field?.required || false,
    onChange: handleInputOnChange,
    disabled,
  };

  const value = field?.value || field?.defaultValue;

  // Switch Component
  if (field.configType === 'Boolean') {
    if (value === 'enabled') {
      defaultProps['checked'] = true;
    } else if (value === 'disabled') {
      defaultProps['checked'] = false;
    } else if (typeof value === 'boolean') {
      defaultProps['checked'] = field.value;
    } else if (typeof value === 'string') {
      defaultProps['checked'] = field?.value?.toLowerCase() === 'true' ? true : false;
    }

    inputComp = <Switch {...defaultProps} />;
  } else {
    // Text Field Component
    defaultProps['type'] = 'text';
    defaultProps['value'] = value || '';
    defaultProps['variant'] = 'outlined';
    defaultProps['error'] = hasErrors;
    defaultProps['placeholder'] = startCase(`${field?.configKey}` || '');

    if (label) {
      defaultProps['label'] = startCase(`${field?.configKey}` || '');
    }

    // Type Date
    if (field.configType === 'Date') {
      defaultProps['type'] = 'date';
      defaultProps['value'] = value?.substring(0, 10) || '';
    }

    // Type JSON
    if (field.configType === 'JSON') {
      inputComp = (
        <JSONEditor
          height="200px"
          jsonData={defaultProps['value'] as string}
          onChange={json => {
            handleInputOnChange({
              target: {
                value: json,
              },
            } as ChangeEvent<HTMLInputElement>);
          }}
        />
      );
    } else {
      inputComp = <TextField size="small" fullWidth {...defaultProps} />;
    }
  }

  useEffect(() => {
    isFormValid(hasErrors);
  }, [hasErrors]);

  const renderFormLabel = () => {
    const condition = label && defaultProps?.['label'];
    const labelText = `${String(defaultProps['label'])}${field?.required ? ' (Required)' : ''}`;
    const htmlFor = defaultProps.id as string;

    if (condition) {
      return <FormLabel htmlFor={htmlFor}>{labelText}</FormLabel>;
    }
    return null;
  };

  return (
    // @ts-ignore
    <FormControl {...FormControlProps} error={hasErrors}>
      {renderFormLabel()}
      {inputComp}

      {hasErrors && <FormHelperText sx={{ ml: 0 }}>{getFieldErrorMessage(field)}</FormHelperText>}
    </FormControl>
  );
}

export const getFieldErrorMessage = (field: AppConfigInputProps['field']) => {
  const { value, required, configType } = field;

  if (required && isEmpty(value)) {
    return (
      <FormattedMessage
        id="os-admin-center-user-application-settings-empty-error-message"
        defaultMessage="Required please enter value"
        description="Empty error message"
      />
    );
  }

  if (configType === EApplicationConfigType.Integer) {
    if (Number(value) - Math.floor(Number(value)) !== 0) {
      return (
        <FormattedMessage
          id="os-admin-center-user-application-settings-integer-vaue-error-message"
          defaultMessage="The value has to to be Integer'"
          description="Integer value error message"
        />
      );
    }
  }

  return '';
};
