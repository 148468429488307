import { Typography } from '@mui/material';
import { IImageUrlsType, imageUpload } from '@aiware/shared/reusable-utils';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../redux/slices';
import { selectImageSigned, selectImageUnsigned } from '../../../../redux/selectors/edit-metadata';
import { SHARED_TEXT } from '../helpers/shared-text';

const {
  jsx: { ImageUpload },
} = imageUpload;

export const Thumbnail = () => {
  const dispatch = useDispatch();
  const imageUnsignedUrl = useSelector(selectImageUnsigned);
  const imageSignedUrl = useSelector(selectImageSigned);
  const handleImageUpload = (value: unknown) => {
    const typedValue = value as IImageUrlsType;
    if (typedValue.unsignedUrl) {
      dispatch(actions.editMetadata.setImageUnsignedUrl(typedValue.unsignedUrl));
      dispatch(actions.editMetadata.setImageSignedUrl(typedValue.getUrl));
    } else {
      dispatch(actions.editMetadata.setImageUnsignedUrl(''));
      dispatch(actions.editMetadata.setImageUnsignedUrl(''));
    }
  };
  return (
    <>
      <Typography variant="body2">{SHARED_TEXT.fields.thumbnail.label()}</Typography>
      <ImageUpload
        image={imageSignedUrl || imageUnsignedUrl}
        getUrl={true}
        handleChange={handleImageUpload}
        testId="dc-edit-metadata-image"
      />
    </>
  );
};
