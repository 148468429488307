// @ts-ignore
import { Hide } from '@aiware/shared/icons';
import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { useStyles } from './useStyles';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { FileInfoHeaderMenu } from './FileInfoHeaderMenu';

export { useStyles };

const PanelHeader: FunctionComponent<{
  height: number;
  onPanelClose: () => void;
  onDownloadAsset: () => void;
  onEditAttributes: () => void;
  onMoveAsset: () => void;
  onDeleteAsset: () => void;
  onProcessAi: () => void;
  entityId: string;
  isError: boolean;
  isLoading: boolean;
  isBetaFeaturesEnabled: boolean;
}> = ({
  height,
  onPanelClose,
  entityId,
  onDownloadAsset,
  onEditAttributes,
  onMoveAsset,
  onDeleteAsset,
  onProcessAi,
  isError,
  isLoading,
  isBetaFeaturesEnabled,
}) => {
  const { classes } = useStyles({ height });

  return (
    <header className={classes.header} data-testid="dc-file-info-panel-header">
      <Typography variant="h1" data-testid="dc-file-info-panel-title" className={classes.title}>
        {SHARED_TEXT.panelHeaderTitle()}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Typography style={visuallyHidden} id="dc-file-info-panel-close-panel">
          {SHARED_TEXT.closePanel()}
        </Typography>
        <FileInfoHeaderMenu
          entityId={entityId}
          onDownloadAsset={onDownloadAsset}
          onEditAttributes={onEditAttributes}
          onDeleteAsset={onDeleteAsset}
          onProcessAi={onProcessAi}
          onMoveAsset={onMoveAsset}
          isError={isError}
          isLoading={isLoading}
          isBetaFeaturesEnabled={isBetaFeaturesEnabled}
        />
        <Tooltip title={SHARED_TEXT.closePanel()}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="dc-file-info-panel-close-panel"
            data-testid="dc-file-info-panel-close-panel"
            size="large"
          >
            <Hide />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default PanelHeader;
