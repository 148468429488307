import { FunctionComponent, useEffect, useState } from 'react';
import { mountPanel } from '@aiware/js/panel';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { visuallyHidden } from '@mui/utils';
import { Hide } from '@aiware/shared/icons';
import { selectIsUserOrgAdmin } from '@aiware/shared/redux';
import { organizationInvitesSelectors } from '../../../redux/selectors';
import { SHARED_TEXT } from '../../../helpers/shared-text';
import { useStyles } from './useStyles';
import { selectIsScimUser } from '../../../redux/selectors/scimIds.selector';

export const inviteUsersPanel = 'INVITE_USERS_PANEL';
export const inviteRequestsPanel = 'INVITE_REQUESTS_PANEL';

export { useStyles };

const PanelHeader: FunctionComponent<{
  height: number;
  onPanelClose: () => void;
  parentPanelId: string;
}> = ({ height, onPanelClose, parentPanelId }) => {
  const { classes } = useStyles({ height });
  const dispatch = useDispatch();
  const isScimUser = useSelector(selectIsScimUser);
  const isAdmin = useSelector(selectIsUserOrgAdmin);
  const pendingOrgInvites = useSelector(organizationInvitesSelectors.selectPendingOrgInvites);

  const handleClickInviteUsers = () => {
    const microFrontend = {
      name: inviteUsersPanel,
      config: {
        name: 'Invite Users',
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: 80,
      size: 'medium',
      parentPanelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: inviteUsersPanel,
        microFrontend,
        panelConfig,
      })
    );
  };

  const handleViewRequests = () => {
    const microFrontend = {
      name: inviteRequestsPanel,
      config: {
        name: 'Invite Requests',
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: 80,
      size: 'medium',
      parentPanelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
      header: {
        title: SHARED_TEXT.inviteRequests,
      },
    };
    dispatch(
      mountPanel({
        panelId: inviteRequestsPanel,
        microFrontend,
        panelConfig,
      })
    );
  };

  return (
    <header className={classes.header} data-testid="org-header">
      <Typography variant="h1" data-testid="org-title" className={classes.title}>
        {SHARED_TEXT.headerTitle}
      </Typography>
      <div className={classes.headerActionContainer}>
        {isAdmin && (
          <Button sx={{ mr: 2 }} onClick={handleViewRequests} color="primary" data-testid="org-view-requests">
            {SHARED_TEXT.viewRequests}
            <Badge className={classes.viewRequestsBadge} badgeContent={pendingOrgInvites?.length} />
          </Button>
        )}
        {isScimUser === false && (
          <Button
            className={classes.marginRight}
            onClick={handleClickInviteUsers}
            color="primary"
            variant="contained"
            data-test="org-invite-users-btn"
          >
            {SHARED_TEXT.inviteUsers}
          </Button>
        )}
        <div className={classes.verticalDivider}></div>
        <Typography style={visuallyHidden} id="org-close-panel">
          {SHARED_TEXT.closePanel}
        </Typography>
        <Tooltip title={SHARED_TEXT.closePanel}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="org-close-panel"
            data-testid="org-close-panel"
            size="large"
          >
            <Hide />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default PanelHeader;
