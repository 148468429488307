import { FunctionComponent } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import ContentLayout from './ContentLayout';

const useStyles = makeStyles<{ height: string | number }>()((theme, { height }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height,
  },
  nav: {
    width: 187,
    padding: theme.spacing(1.5),
    borderRight: `.5px solid ${theme.palette.divider}`,
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
  },
}));

export const BreadCrumbSkeleton: FunctionComponent = () => {
  return <Skeleton variant="rectangular" width={100} height={30} />;
};

export const ActionBarSkeleton: FunctionComponent = () => {
  return (
    <Box display="flex" flexDirection="row">
      {new Array(3).fill(null).map((_, idx) => (
        <Box marginLeft={1} key={idx}>
          <Skeleton variant="circular" width={36} height={36} />
        </Box>
      ))}
    </Box>
  );
};

export const TableSkeleton: FunctionComponent = () => {
  return (
    <>
      {new Array(10).fill(null).map((_, idx) => (
        <Box marginBottom={1} key={idx}>
          <Skeleton variant="rectangular" width={'100%'} height={45} />
        </Box>
      ))}
    </>
  );
};

export const MainSkeleton: FunctionComponent<{ height: string }> = ({ height }) => {
  const { classes } = useStyles({ height });

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        {new Array(4).fill(null).map((__, idx) => (
          <Skeleton key={idx} variant="rectangular" width={'100%'} height={48} />
        ))}
      </div>
      <div className={classes.content}>
        <ContentLayout
          breadcrumbs={<BreadCrumbSkeleton />}
          actions={<ActionBarSkeleton />}
          table={<TableSkeleton />}
        />
      </div>
    </div>
  );
};

export default MainSkeleton;
