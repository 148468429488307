import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { TUserAppGroup, TUserAppGroupApiResponse } from './type';

export const fetchUserAppGroupsRootFolderIdApi = async (
  token: string,
  graphQLEndpoint: string
): Promise<{ id: string }[]> => {
  const name = 'createRootFolders';
  const query = `
    mutation createApplicationGroupRootFolder {
      createRootFolders(rootFolderType:application) {
        id
      }
    }
  `;
  const result: any = await baseGraphQLApiWithError<{
    [name]: { id: string }[];
  }>({
    query,
    token,
    graphQLEndpoint,
  });

  return result[name];
};

export const fetchUserAppGroupsApi = async (
  token: string,
  graphQLEndpoint: string,
  appGroupRootFolderId: string
): Promise<TUserAppGroupApiResponse> => {
  const operationName = 'getUserAppGroups';
  const name = 'folder';
  const query = `
   query ${operationName} {
    ${name}(id: "${appGroupRootFolderId}") {
      childFolders(limit:50,offset:0) {
        records {
          name
          id
          treeObjectId
          orderIndex
          parent {
            treeObjectId
          }
          childApplications(limit:50, offset:0) {
            records {
              id
              name
            }
          }
        }
      }
    }
  }`;
  const result: any = await baseGraphQLApiWithError<{
    [name]: TUserAppGroupApiResponse;
  }>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name];
};

export const createUserGroupApi = async (
  token: string,
  graphQLEndpoint: string,
  groupName: string,
  parentFolderId: string
): Promise<string> => {
  const operationName = 'createApplicationGroup';
  const name = 'createFolder';
  const query = `
    mutation ${operationName} {
      ${name}(
        input : {
        parentId: "${parentFolderId}",
          name: "${groupName}",
          description: "",
          rootFolderType: application
      }
    ) {
        id
      }
    }
  `;

  const result: any = await baseGraphQLApiWithError<string>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name]?.id;
};

export const addAppToUserGroupApi = async (
  token: string,
  graphQLEndpoint: string,
  appId: string,
  folderId: string
): Promise<string> => {
  const operationName = 'addApplicationToGroup';
  const name = 'fileApplication';
  const query = `
    mutation ${operationName} {
      ${name}(
        input: {
          appId: "${appId}"
          folderId: "${folderId}"
        }
      ) {
        id
      }
    }
  `;

  const result: any = await baseGraphQLApiWithError<string>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name]?.id;
};

export const removeAppFromGroupApi = async (
  token: string,
  graphQLEndpoint: string,
  appId: string,
  folderId: string
): Promise<string> => {
  const operationName = 'removeApplicationFromGroup';
  const name = 'unfileApplication';
  const query = `
    mutation ${operationName} {
      ${name}(
        input: {
          appId: "${appId}"
          folderId: "${folderId}"
        }
      ) {
        id
      }
    }
  `;

  const result: any = await baseGraphQLApiWithError<string>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name]?.id;
};

export const deleteAppGroupApi = async (
  token: string,
  graphQLEndpoint: string,
  folderId: string,
  orderIndex: number
): Promise<string> => {
  const operationName = 'deleteAppGroup';
  const name = 'deleteFolder';
  const query = `
    mutation ${operationName} {
      ${name}(
        input: {
          id: "${folderId}"
          orderIndex: ${orderIndex}
        }
      ) {
        id
      }
    }
  `;

  const result: any = await baseGraphQLApiWithError<string>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name]?.id;
};

export const editAppGroupApi = async (
  token: string,
  graphQLEndpoint: string,
  folderId: string,
  newName: string
): Promise<string> => {
  const operationName = 'editAppGroupName';
  const name = 'updateFolder';
  const query = `
    mutation ${operationName} {
      ${name}(
        input: {
          id: "${folderId}"
          name:  "${newName}"
        }
      ) {
        id
      }
    }
  `;

  const result: any = await baseGraphQLApiWithError<string>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name]?.id;
};

export const updateGroupPriorityApi = async (
  token: string,
  graphQLEndpoint: string,
  newOrderIndex: number,
  prevOrderIndex: number,
  userGroup: TUserAppGroup
): Promise<string> => {
  const operationName = 'updateGroupPriority';
  const name = 'moveFolder';
  const query = `
    mutation ${operationName} {
      ${name} (
        input : {
          newOrderIndex: ${newOrderIndex}
          newParentTreeObjectId: "${userGroup.parentTreeObjectId}"
          prevOrderIndex: ${prevOrderIndex}
          prevParentTreeObjectId: "${userGroup.parentTreeObjectId}"
          rootFolderType: application
          treeObjectId: "${userGroup.treeObjectId}"
        }
      ) {
        id
      }
    }
  `;

  const result: any = await baseGraphQLApiWithError<string>({
    query,
    operationName,
    token,
    graphQLEndpoint,
  });

  return result[name]?.id;
};
