import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';
import { TSortOrder } from '.';

export const useStyles = makeStyles<{ sortOrder: TSortOrder; hasApps: boolean; menuIsOpen: boolean }>()(
  (_theme: Theme, { sortOrder, hasApps, menuIsOpen }) => ({
    root: {},
    header: {
      height: '60px',
      position: 'sticky',
    },
    groupContainer: {
      padding: '0 15px',
    },
    title: {
      fontWeight: 700,
      padding: '15px',
    },
    groupTitle: {
      background: '#F9F9F9',
      paddingLeft: '5px',
      position: 'relative',
      height: '16px',
      '& .Sdk-MuiTypography-root': {
        fontSize: '10px',
        fontWeight: 700,
        lineHeight: '10px',
        position: 'absolute',
        margin: '3px',
      },
    },
    filterIcon: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& svg': {
        transform: sortOrder === 'asc' ? 'scaleY(-1)' : 'scaleY(1)',
        cursor: hasApps ? 'pointer' : 'not-allowed',
      },
    },
    filterIconWrapper: {
      width: '24px',
      height: '24px',
      borderRadius: '24px',
      background: menuIsOpen ? 'rgba(228, 240, 252, 0.8)' : 'unset',
      '& path': {
        fill: menuIsOpen ? '#0C53B0' : '#555F7C',
      },
      '&:hover': {
        background: 'rgba(228, 240, 252, 0.8)',
        '& path': {
          fill: '#0C53B0',
        },
      },
    },
    searchBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .Sdk-MuiButtonBase-root': {
        padding: 0,
      },
      '& svg': {
        width: '14px',
      },
      '& input': {
        '&::placeholder': {
          color: '#5C6269 !important',
          fontWeight: 600,
          opacity: 1,
        },
      },
    },
    searchField: {
      borderRadius: '1em',
      height: '30px',
    },
    searchOutline: {
      borderColor: '#D5DFE9',
    },
    searchInput: {
      fontSize: '11px',
      '&::placeholder': {
        color: '#5C6269 !important',
      },
    },
    sortMenu: {
      width: '143px',
    },
    airborne: {
      position: 'absolute',
      background: 'rgba(26, 140, 255, 0.9)',
      transform: 'rotate(3.02deg)',
      color: 'white',
      '& *': {
        fontWeight: '900 !important',
      },
      '& img': {
        background: 'white',
        borderRadius: '50%',
      },
    },
    applicationList: {
      overflow: 'hidden',
      height: 'calc(100vh - 64px - 55px - 60px - 5px)',
      overflowY: 'scroll',
    },
  })
);
