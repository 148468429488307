import { takeLatest } from 'redux-saga/effects';
import {
  getLogoSuggestions,
  getObjectSuggestions,
  getTagsSuggestions,
  getFaceSuggestions,
  abortQueries,
} from './aiAutocomplete.saga';
import { actions } from './aiAutocomplete.slice';

export function* getTagsSuggestionsWatcher() {
  yield takeLatest(actions.setSearchTerm.type, getTagsSuggestions);
}

export function* getObjectSuggestionsWatcher() {
  yield takeLatest(actions.setSearchTerm.type, getObjectSuggestions);
}

export function* getLogoSuggestionsWatcher() {
  yield takeLatest(actions.setSearchTerm.type, getLogoSuggestions);
}

export function* getFaceSuggestionsWatcher() {
  yield takeLatest(actions.setSearchTerm.type, getFaceSuggestions);
}

export function* abortQueriesWatcher() {
  yield takeLatest(actions.abortQueries.type, abortQueries);
}
