import { VideoCamera, Sound, Image, Description, UnknownDocument } from '@aiware/shared/icons';

const icons = {
  video: <VideoCamera />,
  image: <Image />,
  audio: <Sound />,
  text: <Description />,
  application: <Description />,
};

interface Props {
  filetype: string;
}

const FileItemIcon = ({ filetype }: Props) => {
  // eg filetypes: image/jpeg, application/pdf
  const [type] = filetype.split('/');

  //@ts-ignore
  if (type && icons[type]) {
    //@ts-ignore
    return icons[type];
  }

  return <UnknownDocument />;
};

export default FileItemIcon;
