import { FunctionComponent } from 'react';
import { Avatar, Typography, Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'root'>()(theme => ({
  root: {},
  container: {
    padding: theme.spacing(4, 0, 4, 0),
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
  },
  textContainer: {
    marginLeft: '15px',
    width: '250px',
    wordWrap: 'break-word',
  },
  skeletonTextContainer: {
    width: '80%',
    marginLeft: '15px',
  },
}));
export interface IInviteAuthor {
  name?: string;
  email?: string;
  photo?: string;
}
export const ReviewRequestInviteAuthor: FunctionComponent<{
  author: IInviteAuthor;
  organizationName: string;
}> = ({ author, organizationName }) => {
  const { classes } = useStyles();
  const { name, photo } = author;

  if (!name) {
    return <div className={classes.container}></div>;
  }
  return (
    <div className={classes.container}>
      {name === null && <Skeleton variant="circular" width={48} height={48} />}
      {name && <Avatar src={photo} sx={{ bgcolor: '#F2F5F9' }} />}
      {name === null && (
        <div className={classes.skeletonTextContainer}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </div>
      )}

      {name && (
        <Typography className={classes.textContainer}>
          <strong>{name}</strong>{' '}
          <FormattedMessage
            id="os-organization-panel.review-request-panel.invite-author.request-message"
            defaultMessage="has requested that you join the"
            description="Review Request Panel invite author, inviting you description text"
          />{' '}
          <strong>{organizationName}</strong>{' '}
          <FormattedMessage
            id="os-organization-panel.review-request-panel.invite-author.organization-word"
            defaultMessage="organization."
            description="Review Request Panel invite author, organization word"
          />
        </Typography>
      )}
    </div>
  );
};
