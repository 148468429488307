import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ deductedHeight: string }>()((_theme, { deductedHeight }) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  permissionSubtitle: {
    fontWeight: 400,
    color: '#555F7C',
    margin: '20px 10px 30px 0',
  },
  funcPermissionsContainer: {
    height: `calc(100vh - 330px - ${deductedHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: '10px',
  },
}));
