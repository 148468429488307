import Header from '../components/header';
import MemberLookup from '../components/member-lookup';
import Tabs from '../components/tabs';
import PanelControls from '../components/controls';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import {
  getAdminCenterPermissionsModule,
  selectCurrentResource,
  selectFoundMembers,
  selectLoadingStatus,
  selectPermissionSets,
} from '../../redux';
import { actions } from '../../redux/slices';
import {
  EMemberType,
  EResourceType,
  IGroupMemberGroup,
  IGroupMemberUser,
  TACEMember,
  TAddACLsInput,
  TEntry,
  TIntlProps,
  TPermissionOptionSDK,
  TPermissionSet,
} from '../../types';
import { formattedMessages } from '../helpers/formatted-messages';
import { FormattedMessage, injectIntl, WithIntlProps } from 'react-intl';
import { hidePanel } from '@aiware/js/panel';
import { cloneDeep } from 'lodash';
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { userSelector } from '@aiware/shared/redux';

export const PERMISSIONS_PANEL = 'PERMISSIONS_PANEL';

enum EPermissionType {
  FOLDER = 'Folder',
  TDO = 'TDO',
  Organization = 'Organization',
}

export type TFactoryMember = TACEMember;

type TProps = TIntlProps & {
  permissionType: EPermissionType;
  refreshAndLoadAccessControls?: () => void;
  onGeneratePermissionsObject?:
    | undefined
    | ((
        payload: TAddACLsInput,
        newMembers: TFactoryMember[],
        permissionSets: TPermissionOptionSDK[]
      ) => void);
  prefillMembers?: TFactoryMember[];
  prefillPayload?: TAddACLsInput;
};

export const PermissionsPanel: FC<WithIntlProps<TProps>> = injectIntl(
  ({
    intl,
    permissionType,
    refreshAndLoadAccessControls,
    onGeneratePermissionsObject,
    prefillMembers,
    prefillPayload,
  }: TProps) => {
    const [isMounted, setIsMounted] = useState(false);
    const [newMembers, setNewMembers] = useState([] as TFactoryMember[]);
    const [fetchedMemberSuggestions, setFetchedMemberSuggestions] = useState([] as TFactoryMember[]);
    const memberSuggestions = useSelector(selectFoundMembers);
    const selectedResource = useSelector(selectCurrentResource);
    const loadingStatus = useSelector(selectLoadingStatus);
    const { permissionsPanelHeader, permissionsPanelClose } = formattedMessages(intl);
    const isFactoryMode = Boolean(onGeneratePermissionsObject);
    const [factoryAces, setFactoryAces] = useState<any>([]);
    const [factoryMembers, setFactoryMembers] = useState<TFactoryMember[]>(
      prefillMembers ? prefillMembers : []
    );
    const [factoryPayload, setFactoryPayload] = useState<TAddACLsInput | null>(
      prefillPayload ? prefillPayload : null
    );
    const isPrefilled = !!prefillMembers?.length;
    const currentUser = useSelector(userSelector);

    let permissionSets: TPermissionSet[] = [];
    if (isFactoryMode) {
      permissionSets = useSelector(selectPermissionSets) || [];
    } else {
      permissionSets = (useSelector(selectPermissionSets) || []).filter(pS => {
        if (permissionType === EPermissionType.Organization) {
          return pS.permissions;
        }
        return pS.permissions.find(p => p.includes(permissionType?.toUpperCase()));
      });
    }

    // construct options for Select element
    const permissionOptions: TPermissionOptionSDK[] = permissionSets.map(pS => ({
      value: pS.id,
      label: pS.name,
      permissions: pS.permissions,
    }));

    const createFactoryAceObjs = (payload: any, member: any) => {
      const amount = member.length;

      const aces = [];
      for (let i = 0; i < amount; i++) {
        let permissionSetId = '';
        let memberType = '';

        for (const entry of payload?.entries ?? []) {
          if (entry?.member?.id === member[i]?.id) {
            permissionSetId = entry?.permissionSetID;
            memberType = entry?.member?.memberType;
          }
        }

        const permissionSet = permissionSets.find(
          (permSet: TPermissionSet) => permSet?.id === permissionSetId
        );

        const localAce = {
          id: member[i]?.id,
          objectID: 'null',
          member: {
            members: {
              count: member[i]?.members?.count,
            },
            name: member[i]?.name,
            memberType,
            id: member[i]?.id,
          },
          createdAt: new Date().toISOString(),
          permissionSet: {
            permissionId: permissionSetId,
            permissionName: permissionSet?.name,
            permissionDescription: permissionSet?.description,
          },
        };
        aces.push(localAce);
      }

      return aces;
    };

    useEffect(() => {
      setIsMounted(true);
      if (isFactoryMode) {
        const createdAces = createFactoryAceObjs(prefillPayload, prefillMembers);
        setFactoryAces(createdAces);
      }
      return () => setIsMounted(false);
    }, []);

    useEffect(() => {
      if (isMounted && selectedResource && !selectedResource.records) {
        const { type, id } = selectedResource;
        dispatch(actions.adminCenterPermissions.fetchResoucesACLStart({ resourceType: type!, ids: [id!] }));
      }
    }, [selectedResource, isMounted]);

    useEffect(() => {
      if (isMounted) {
        // remove options that user already selected for the new ACE
        const filteredMembers = newMembers.length
          ? memberSuggestions.filter(
              member =>
                !newMembers.find((newMember: any) => {
                  return newMember?.id === member?.id;
                })
            )
          : memberSuggestions;

        // adds an indicator about member already being among object's ACEs
        const filteredAndStamped: any[] = [];
        filteredMembers.forEach(member => {
          if (
            selectedResource?.records?.find((ace: any) => ace?.member?.id === member?.id) ||
            factoryMembers.find((existingMember: any) => {
              return existingMember?.id === member?.id;
            }) ||
            prefillMembers?.find((existingMember: any) => {
              return existingMember?.id === member?.id;
            })
          ) {
            filteredAndStamped.push({ ...member, current: true });
          } else {
            filteredAndStamped.push(member);
          }
        });
        setFetchedMemberSuggestions(filteredAndStamped);
      }
    }, [memberSuggestions]);

    const { classes } = useStyles();
    const dispatch = useDispatch();

    const handleChange = (_ev: any, newValue: any[]) => {
      setNewMembers(newValue);
      dispatch(actions.adminCenterPermissions.lookupMembersSuccess([]));
    };

    const searchMember = (searchParam: string) => {
      dispatch(actions.adminCenterPermissions.lookupMembersStart(searchParam));
    };

    const handleClose = (panelId: string) => {
      permissionType === EPermissionType.Organization &&
        refreshAndLoadAccessControls &&
        refreshAndLoadAccessControls();
      dispatch(hidePanel(panelId));
      dispatch(actions.adminCenterPermissions.setSelectedResource(null));
    };

    const handleGeneratePermissionsObj = (isFactory: boolean, payloadObj?: TAddACLsInput) => {
      const payload = payloadObj ||
        factoryPayload || {
          type: isFactory ? EResourceType.TDO : selectedResource!.type!,
          ids: isFactory ? [] : [selectedResource!.id!],
          entries: [],
        };

      if (isFactory) {
        setFactoryPayload(payload);
        return;
      }

      return payload;
    };

    const handleClickDone = () => {
      handleGeneratePermissionsObj(isFactoryMode);
      if (isFactoryMode) {
        onGeneratePermissionsObject &&
          onGeneratePermissionsObject(
            factoryPayload || ({} as TAddACLsInput),
            factoryMembers,
            permissionOptions
          );
      }
      handleClose(PERMISSIONS_PANEL);
    };

    const onGrantAccess = () => {
      const entries: TEntry[] = [];
      newMembers.forEach(newMember => {
        const entry = {
          member: {
            memberType: EMemberType.Group,
            id: newMember.id || '',
          },
          permissionSetID: newMembersPermission?.value ?? '',
        };
        entries.push(entry);
      });
      const payload = {
        type: isFactoryMode ? EResourceType.TDO : selectedResource!.type!,
        ids: isFactoryMode ? [] : [selectedResource!.id!],
        entries:
          isFactoryMode && factoryPayload?.entries?.length
            ? [...(factoryPayload?.entries || []), ...entries]
            : entries,
      };

      if (isFactoryMode) {
        handleGeneratePermissionsObj(isFactoryMode, payload);
        const createdAces = createFactoryAceObjs(payload, newMembers);
        setFactoryAces([...factoryAces, ...createdAces]);
        setFactoryMembers([...factoryMembers, ...newMembers]);
        setNewMembers([]);
        return;
      }
      dispatch(actions.adminCenterPermissions.addACLsToResoucesStart(payload));
      setNewMembers([]);
    };

    const getIDsToRemove = (id: string) => {
      const ids: string[] = [];
      selectedResource!.records!.forEach(ace => {
        if (ace.member.id === id) {
          ids.push(ace.id);
        }
      });
      return ids;
    };

    const onRevokeAccess = (id: string) => {
      if (isFactoryMode) {
        // Remove from factoryPayload
        const updatedPayload = cloneDeep(factoryPayload) || ({} as TAddACLsInput);
        updatedPayload.entries =
          factoryPayload?.entries?.filter((entry: any) => entry?.member?.id !== id) || [];
        setFactoryPayload(updatedPayload);

        // Remove from factoryMembers
        const updatedMembers = factoryMembers.filter((member: any) => member?.id !== id);
        setFactoryMembers(updatedMembers);

        // Remove from newMembers
        const updatedNewMembers = newMembers.filter((member: any) => member?.id !== id);
        setNewMembers(updatedNewMembers);

        // Remove from factoryAces
        const updatedAces = factoryAces.filter((ace: any) => ace?.id !== id);
        setFactoryAces(updatedAces);

        return;
      }

      dispatch(
        actions.adminCenterPermissions.removeACLsFromResoucesStart({
          ids: getIDsToRemove(id),
          type: permissionType as unknown as EResourceType,
        })
      );
    };

    const onChangeAccess = (value: string, id: string, memberType: EMemberType) => {
      if (isFactoryMode) {
        // Change permission in factoryPayload
        const updatedPayload = cloneDeep(factoryPayload) || ({} as TAddACLsInput);
        updatedPayload.entries =
          factoryPayload?.entries?.map((entry: TEntry) => {
            if (entry?.member?.id === id) {
              return {
                ...entry,
                permissionSetID: value,
              };
            }
            return entry;
          }) || [];
        setFactoryPayload(updatedPayload);

        // Change permission in factoryAces
        const updatedAces = factoryAces.map((ace: any) => {
          if (ace.id === id) {
            ace.permissionSet.permissionId = value;
          }
          return ace;
        });
        setFactoryAces(updatedAces);
        return;
      }

      const removePayload = {
        ids: getIDsToRemove(id),
      };

      const entries: TEntry[] = [
        {
          member: {
            memberType,
            id,
          },
          permissionSetID: value,
        },
      ];

      const addPayload = {
        type: selectedResource!.type!,
        ids: [selectedResource!.id!],
        entries,
      };
      dispatch(
        actions.adminCenterPermissions.changeACLsOnResoucesStart({ remove: removePayload, add: addPayload })
      );
    };

    const [newMembersPermission, setNewMembersPermission] = useState(permissionOptions[0]!);

    const shouldTabsHide = () => {
      if (isFactoryMode) {
        return false;
      }
      return !!newMembers.length;
    };
    const isUserInFactorMembers = factoryMembers.find((group: any) =>
      group?.members?.records?.find(({ member }: any) => member?.id === currentUser?.userId)
    );

    return (
      <DynamicModuleLoader modules={[getAdminCenterPermissionsModule()]}>
        <AIWareIntlProvider style={{ height: '100%' }}>
          <div className={classes.permissionsPanelContainer}>
            <div>
              <Header
                panelId={PERMISSIONS_PANEL}
                permissionsPanelHeader={permissionsPanelHeader}
                permissionsPanelClose={permissionsPanelClose}
                handleClose={handleClose}
                iconType="hide"
                dataTestIdPrefix="permissions-panel"
              />
              <MemberLookup
                members={fetchedMemberSuggestions as (IGroupMemberUser | IGroupMemberGroup)[]}
                newMembers={newMembers as (IGroupMemberUser | IGroupMemberGroup)[]}
                handleChange={handleChange}
                searchMember={searchMember}
                permissionOptions={permissionOptions}
                newMembersPermission={newMembersPermission!}
                setNewMembersPermission={
                  setNewMembersPermission as (permission?: TPermissionOptionSDK) => void
                }
                isFactoryMode={isFactoryMode}
              />
              {shouldTabsHide() ? null : (
                <Tabs
                  isMVP={true}
                  isFactoryMode={isFactoryMode}
                  permissionOptions={permissionOptions}
                  aces={isFactoryMode ? factoryAces : selectedResource?.records || []}
                  onRevokeAccess={onRevokeAccess}
                  onChangeAccess={onChangeAccess}
                  loadingStatus={loadingStatus}
                />
              )}
            </div>
            <div>
              {isFactoryMode && !isUserInFactorMembers && (
                <Box
                  sx={{
                    mx: 6,
                    mb: 3,
                    backgroundColor: '#FFF9C4',
                    px: 4,
                    py: 1,
                    display: 'flex',
                    height: '67px',
                  }}
                >
                  <Box sx={{ mr: 3, mt: 1 }}>
                    <InfoOutlinedIcon sx={{ color: '#555F7C' }} />
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 600 }} variant="caption">
                      <FormattedMessage
                        id="os-admin-center-permissions-warning-title"
                        defaultMessage="No Current Access"
                        description="Permission settings warning title for removing the user's own access"
                      />
                    </Typography>
                    <Typography sx={{ fontWeight: 400, display: 'block' }} variant="caption">
                      <FormattedMessage
                        id="os-admin-center-permissions-warning-description"
                        defaultMessage="You will lose access to this data if you do not add a group you are a current member of."
                        description="Permission settings warning description for removing the user's own access"
                      />
                    </Typography>
                  </Box>
                </Box>
              )}

              <PanelControls
                isFactoryMode={isFactoryMode}
                isPrefilled={isPrefilled}
                newMembers={newMembers}
                onGrantAccess={onGrantAccess}
                onBack={() => setNewMembers([])}
                onDone={handleClickDone}
              />
            </div>
          </div>
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    );
  }
);
