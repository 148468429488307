import { get } from '@aiware/js/function';
import {
  getCurrentUserGraphQLApi,
  graphEndpointSelector,
  resetBiometricApi,
  resetPasswordApi,
  rootApiSelector,
  showMessage,
  updateUserAvatarApi,
  updateUserProfileApi,
  uploadUserAvatarApi,
  loginUrlSelector,
  logoutRedirectUrlSelector,
  logoutUrlSelector,
  logoutUserApi,
  postNotificationApi,
  authSelector,
  aiwareEvent,
  notificationCommands,
  sessionTokenSelector,
  MessageSeverity,
  preferredLanguageSelector,
  setIsIdle,
  extendTokenRequest,
} from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';

import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './action';
import { IRole } from '../../../types';

async function delay(time = 1000) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function goToPage(page: string) {
  window.location.href = page;
}

export const uploadingStatus = {
  LOADING: 'loading',
  DONE: 'done',
};

type TDynamicObject = {
  [x: string]: any;
};

export function* getCurrentUserSaga() {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const data: TDynamicObject = yield call(getCurrentUserGraphQLApi, graphEndpoint, token);
  if (data?.errors && data?.errors.length > 0 && !data.errors[0].path?.includes('userSettings')) {
    console.error('getCurrentUser error');
    yield put(actions.getCurrentUserFail());
  } else {
    const avatarHistory = data?.data?.me?.recentImageUrls || [];
    const operations = data?.data?.myRights?.operations || [];
    const currentUser = {
      ...(data?.data?.me || {}),
      organizationRole: {
        organizationId: get(data, 'data.me.organization.id', ''),
        role: operations.includes('veritone.superadmin')
          ? 'superadmin'
          : operations.includes('admin.access')
          ? 'admin'
          : 'user',
      },
      organization: { ...(data?.data?.me?.organization || {}) },
      roles: (data?.data?.me?.roles || []).map((item: IRole) => item.appName),
      avatarHistory,
    };
    yield put(actions.getCurrentUserSuccess(currentUser));
    const hubRole = (data?.data?.me?.roles || []).find((item: IRole) => item.appName === 'hubcentral');
    yield put(actions.setHubRole(hubRole));
  }
}
export function* resetBiometricSaga() {
  const baseAPI: string = yield select(rootApiSelector);
  const token: string = yield select(sessionTokenSelector);
  yield call(resetBiometricApi, baseAPI, token);
}

export function* resetPasswordSaga(action: { type: string; payload: { email: string } }) {
  const email = action?.payload?.email || '';
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const data: TDynamicObject = yield call(resetPasswordApi, email, graphEndpoint);
  if (data?.success === false) {
    yield put(actions.resetPasswordFail());
  } else {
    const message: any = {
      content: data?.data?.createPasswordResetRequest?.message || '',
      severity: MessageSeverity.Info,
    };
    yield put(showMessage(message));
  }
}

export function* updateProfileSaga(action: {
  type: string;
  payload: { firstName?: string; lastName?: string; phoneNumber?: string };
}) {
  const actionData = action?.payload;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const locale: string = yield select(preferredLanguageSelector);
  const data: TDynamicObject = yield call(() => updateUserProfileApi(actionData, graphEndpoint, token));
  const formatMessage = AIWareFormatMessage(locale);

  if (data?.success === false) {
    yield put(actions.updateProfileFail());
  } else {
    yield put(actions.getCurrentUser());
    const message: any = {
      content: formatMessage({
        id: 'os-app-bar-panel.snackbar.updateProfileSuccess',
        defaultMessage: 'Success',
        description: 'The success message pops up when profile updates.',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  }
}

export function* uploadAvatarSaga(action: { type: string; payload: File | Blob }) {
  const actionData = action?.payload;
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const data: TDynamicObject = yield call(uploadUserAvatarApi, actionData, graphEndpoint, token);
  yield put(actions.uploadingAvatar(uploadingStatus.LOADING));
  if (data) {
    yield put(actions.updateAvatar(data));
  }
}

export function* updateAvatarSaga(action: { type: string; payload: string }) {
  const actionData = action?.payload;
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const locale: string = yield select(preferredLanguageSelector);
  const data: TDynamicObject = yield call(updateUserAvatarApi, actionData, graphEndpoint, token);
  const formatMessage = AIWareFormatMessage(locale);

  if (data.errors) {
    yield put(actions.updateProfileFail());
  } else {
    // const user = yield select(userSelector);
    const message: any = {
      content: formatMessage({
        id: 'os-app-bar-panel.snackbar.updateAvatarSuccess',
        defaultMessage: 'Update avatar successfully',
        description: 'The success message pops up when profile avatar updates.',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
    yield put(actions.getCurrentUser());
    yield put(actions.uploadingAvatar(uploadingStatus.DONE));
  }
}

function* hardLogout() {
  const logoutUrl: string = yield select(logoutUrlSelector);
  goToPage(logoutUrl);
}

function* handleLogoutError() {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  const message: any = {
    content: formatMessage({
      id: 'os-app-bar-panel.snackbar.logoutError',
      defaultMessage: 'Could not log out current user',
      description: 'The error message pops up when logout fails.',
    }),
    severity: MessageSeverity.Error,
  };

  yield put(showMessage(message));
}

// if our logout fails and logoutRedirectUrl exists in the init config,
// then try to log the user out with this url
function* redirectToLogoutOrError() {
  const logoutRedirectUrl: string = yield select(logoutRedirectUrlSelector);
  if (logoutRedirectUrl) {
    goToPage(logoutRedirectUrl);
  } else {
    // if there's no logoutRedirectUrl, then show error message
    yield call(handleLogoutError);
  }
}

export function* updateActivityStatus() {
  try {
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const user: TDynamicObject = yield select(authSelector);
    const token: string = yield select(sessionTokenSelector);

    yield put(extendTokenRequest({ token }));
    yield put(setIsIdle(false));
    yield call(
      postNotificationApi,
      graphEndpoint,
      user?.userId,
      {
        eventType: aiwareEvent,
        command: notificationCommands.setCallResetIdleTimer,
        value: window.navigator.userAgent,
      },
      true,
      'json',
      aiwareEvent,
      token
    );

    yield delay(500);
  } catch (err) {
    console.log(err);
  }
}

export function* logoutUser() {
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const user: TDynamicObject = yield select(authSelector);
  const token: string = yield select(sessionTokenSelector);
  const loginUrl: string = yield select(loginUrlSelector);
  const logoutRedirectUrl: string = yield select(logoutRedirectUrlSelector);

  try {
    // if no token use the hard logout
    if (!token) {
      yield put(actions.logoutUserSuccess());
      yield call(hardLogout);
      return;
    }

    (window as any)['__logout_requested'] = true;

    // logout from all open tabs and windows
    yield call(
      postNotificationApi,
      graphEndpoint,
      user?.userId,
      {
        eventType: aiwareEvent,
        command: notificationCommands?.goToLoginPage,
        value: window.navigator.userAgent,
      },
      true,
      'json',
      aiwareEvent,
      token
    );

    yield delay(500);

    const loggedOut: TDynamicObject = yield call(logoutUserApi, token, graphEndpoint);

    // if error logging out user - don't redirect - just return out
    if (loggedOut?.errors || loggedOut?.success === false) {
      yield call(redirectToLogoutOrError);
      yield put(actions.logoutUserFail());
      return;
    }

    yield put(actions.logoutUserSuccess());
    if (logoutRedirectUrl) {
      goToPage(logoutRedirectUrl);
    } else {
      goToPage(loginUrl);
    }
  } catch (e) {
    yield call(handleLogoutError);
    yield put(actions.logoutUserFail());
    console.log(e);
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(actions.GET_CURRENT_USER, getCurrentUserSaga),
    takeLatest(actions.RESET_BIOMETRIC, resetBiometricSaga),
    takeLatest(actions.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(actions.UPDATE_PROFILE, updateProfileSaga),
    takeLatest(actions.UPLOAD__AVATAR, uploadAvatarSaga),
    takeLatest(actions.UPDATE__AVATAR, updateAvatarSaga),
    takeLatest(actions.LOGOUT_USER, logoutUser),
    takeLatest(actions.UPDATE_ACTIVITY, updateActivityStatus),
  ]);
}
