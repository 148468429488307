import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { injectIntl, WithIntlProps } from 'react-intl';
import { TIntlProps } from '../../../types';
import { formattedMessages } from '../../helpers/formatted-messages';
import { useStyles } from './modal.styles';

type TProps = TIntlProps & { open: boolean; onClose: () => void; onConfirm: () => void };

export const ConfirmationModal: FC<WithIntlProps<TProps>> = injectIntl(
  ({ intl, open, onClose, onConfirm }: TProps) => {
    const { removeGroupAccessModalTitle, removeGroupAccessModalMessage, cancelBtn, confirmBtn } =
      formattedMessages(intl);
    const { classes } = useStyles();
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="revoke-access-confirmation-modal"
        aria-describedby="revoke-access-confirmation-modal"
        data-testid="permissions-panel.confirmation-modal"
        data-test="permissions-panel.confirmation-modal"
        className={classes.modalContainer}
      >
        <DialogTitle
          data-testid="alert-dialog-title"
          data-test="alert-dialog-title"
          className={classes.modalTitle}
        >
          <Typography variant="h2">{removeGroupAccessModalTitle}</Typography>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <DialogContentText data-testid="alert-dialog-description" data-test="alert-dialog-description">
            <Typography variant="body2">{removeGroupAccessModalMessage}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            onClick={onClose}
            data-testid="permission-panel.confirmation-modal.cancel"
            data-test="permission-panel.confirmation-modal.cancel"
          >
            <Typography variant="body2" className={classes.cancelButton}>
              {cancelBtn}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm();
              onClose();
            }}
            data-testid="permission-panel.confirmation-modal.confirm"
            data-test="permission-panel.confirmation-modal.confirm"
          >
            <Typography variant="body2">{confirmBtn}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
