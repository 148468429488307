import { createAction } from '@reduxjs/toolkit';
import { ScheduleId, SLICE_NAME } from '../../../types';

export const namespace = 'schedules';

export const actions = {
  editSchedule: createAction<ScheduleId>(`${SLICE_NAME}/${namespace}/editSchedule`),
  viewSchedule: createAction<ScheduleId>(`${SLICE_NAME}/${namespace}/viewSchedule`),
  activateSchedule: createAction<ScheduleId>(`${SLICE_NAME}/${namespace}/activateSchedule`),
  deactivateSchedule: createAction<ScheduleId>(`${SLICE_NAME}/${namespace}/deactivateSchedule`),
};
