import createSvgIcon from '../createSvgIcon';

export const AudioWhite = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.9775V12.015C13.11 11.4675 13.875 10.3275 13.875 9C13.875 7.6725 13.11 6.5325 12 5.9775ZM3.75 6.75V11.25H6.75L10.5 15V3L6.75 6.75H3.75ZM9 6.6225V11.3775L7.3725 9.75H5.25V8.25H7.3725L9 6.6225Z"
      fill="white"
    />
  </svg>,
  'AudioWhite'
);
