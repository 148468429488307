import { SVGProps } from 'react';

export function OrganizationTabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={29} height={27} viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.165 17.38c.004.005.01.007.015.01a.422.422 0 00.282.11.416.416 0 00.282-.11c.004-.004.01-.005.015-.01l1.648-1.598a.398.398 0 000-.575.43.43 0 00-.594 0l-.931.903v-4.487h7.976c.232 0 .42-.183.42-.408V7.914h.802v8.195l-.931-.903a.43.43 0 00-.594 0 .4.4 0 000 .576l1.648 1.598a.42.42 0 00.297.12.433.433 0 00.297-.12l1.648-1.598a.399.399 0 000-.576.43.43 0 00-.594 0l-.931.903V7.914h.801v3.301c0 .225.188.408.42.408h7.979v4.487l-.932-.903a.428.428 0 00-.593 0 .399.399 0 000 .575l1.649 1.598a.434.434 0 00.595 0l1.648-1.598a.4.4 0 000-.575.429.429 0 00-.594 0l-.931.903v-4.895a.414.414 0 00-.42-.407h-7.98V7.914h1.9V.23h-7.925v7.684h1.901v2.894H4.462a.414.414 0 00-.42.407v4.895l-.932-.903a.43.43 0 00-.593 0 .398.398 0 000 .575l1.648 1.599zM18.462 18.394h-7.924v7.684h7.924v-7.684zM28.5 18.394h-7.925v7.684H28.5v-7.684zM8.424 18.394H.5v7.684h7.924v-7.684z"
        fill="#555F7C"
      />
    </svg>
  );
}
