import { FC, useEffect, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/600.css';
import '@fontsource/dosis/600.css';

import { store } from '@aiware/shared/store';
import { lightTheme } from '@aiware/shared/theme';
import { getInitConfig, getConfigModule, getAuthModule, getSnackbarModule } from '@aiware/shared/redux';
import { AIWareCacheProvider } from '@aiware/shared/theme';

import { AIWareIntlProvider } from '@aiware/shared/intl';

store.addModules([getConfigModule(), getAuthModule(), getSnackbarModule()]);

const urlParams: Record<string, string> = window.location.search
  .substr(1)
  .split('&')
  .reduce((accum, pair) => {
    const [key, value] = pair.split('=');
    return {
      ...accum,
      [key!]: value!,
    };
  }, {});

const authToken = urlParams?.authToken || '';

const initConfigs = {
  applicationId: 'some_id',
  baseUrl: 'https://api.stage.us-1.veritone.com/v3/graphql',
  betaFeatures: true,
  authToken,
};

const StorybookBaseWrapper: FC<
  PropsWithChildren<{
    applicationId?: string;
    baseUrl?: string;
    authToken?: string;
    theme?: Theme;
  }>
> = ({ children, theme = lightTheme, ...props }) => {
  useEffect(() => {
    store.dispatch(
      getInitConfig({
        ...initConfigs,
        ...props,
      })
    );
  }, [props]);
  return (
    <Provider store={store}>
      <AIWareIntlProvider>
        <AIWareCacheProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div style={{ height: 55 }} />
              {children}
            </ThemeProvider>
          </StyledEngineProvider>
        </AIWareCacheProvider>
      </AIWareIntlProvider>
    </Provider>
  );
};

export { StorybookBaseWrapper };

export default StorybookBaseWrapper;
