import {
  ISelectors,
  ISingleSelectors,
  IGlobalState,
  IGlobalSingleState,
  GeneratedState,
  GeneratedSingleState,
} from './types';
import { LoadingStatus } from '@aiware/js/interfaces';

export const generateSelectors = <T>({
  namespace,
  sliceName,
  customReduxNamespace,
  overrideSelectorBase,
}: {
  namespace: string;
  sliceName: string;
  customReduxNamespace?: string;
  overrideSelectorBase?: (state: IGlobalState<T>) => GeneratedState<T, void>;
}): ISelectors<T> => {
  const selectNamespacedState = (state: IGlobalState<T>) => {
    if (overrideSelectorBase) {
      return overrideSelectorBase(state);
    }
    return customReduxNamespace ? state[customReduxNamespace]! : state[sliceName]![namespace]!;
  };
  const selectCreateStatus = (state: IGlobalState<T>): LoadingStatus => {
    return selectNamespacedState(state).createStatus as LoadingStatus;
  };
  const selectReadStatus = (state: IGlobalState<T>): LoadingStatus => {
    return selectNamespacedState(state).readStatus as LoadingStatus;
  };
  const selectUpdateStatus = (state: IGlobalState<T>): LoadingStatus => {
    return selectNamespacedState(state).updateStatus as LoadingStatus;
  };
  const selectDeleteStatus = (state: IGlobalState<T>): LoadingStatus => {
    return selectNamespacedState(state).deleteStatus as LoadingStatus;
  };

  const selectItems = (state: IGlobalState<T>): T[] => {
    return selectNamespacedState(state).items as T[];
  };

  const selectOffset = (state: IGlobalState<T>): number => {
    return selectNamespacedState(state).currentOffset as number;
  };

  const selectHasMore = (state: IGlobalState<T>): boolean => {
    return selectNamespacedState(state).hasMore as boolean;
  };

  const selectSearchValue = (state: IGlobalState<T>): string => {
    return selectNamespacedState(state).searchValue as string;
  };

  const selectFilters = (state: IGlobalState<T>) => {
    return selectNamespacedState(state).filters as any[];
  };

  return {
    selectHasMore,
    selectItems,
    selectOffset,
    selectCreateStatus,
    selectReadStatus,
    selectUpdateStatus,
    selectDeleteStatus,
    selectSearchValue,
    selectFilters,
  };
};

export const generateSingleSelectors = <T, A extends Record<string, unknown>>({
  namespace,
  sliceName,
  customReduxNamespace,
  overrideSelectorBase,
  additionalState,
}: {
  namespace: string;
  sliceName: string;
  customReduxNamespace?: string;
  overrideSelectorBase?: (state: IGlobalSingleState<T, A>) => GeneratedSingleState<T, A>;
  additionalState?: A;
}): ISingleSelectors<T, A> => {
  const selectNamespacedState = (state: IGlobalSingleState<T, A>) => {
    if (overrideSelectorBase) {
      return overrideSelectorBase(state);
    }
    return customReduxNamespace ? state[customReduxNamespace]! : state[sliceName]![namespace]!;
  };

  const selectCreateStatus = (state: IGlobalSingleState<T, A>): LoadingStatus => {
    return selectNamespacedState(state).createStatus as LoadingStatus;
  };

  const selectReadStatus = (state: IGlobalSingleState<T, A>): LoadingStatus => {
    return selectNamespacedState(state).readStatus as LoadingStatus;
  };

  const selectUpdateStatus = (state: IGlobalSingleState<T, A>): LoadingStatus => {
    return selectNamespacedState(state).updateStatus as LoadingStatus;
  };

  const selectDeleteStatus = (state: IGlobalSingleState<T, A>): LoadingStatus => {
    return selectNamespacedState(state).deleteStatus as LoadingStatus;
  };

  const selectItem = (state: IGlobalSingleState<T, A>): T => {
    return selectNamespacedState(state).item as T;
  };

  const baseSelectors = {
    selectItem,
    selectCreateStatus,
    selectReadStatus,
    selectUpdateStatus,
    selectDeleteStatus,
  };

  const generateNestedSelectors = (
    obj: Record<string, unknown>,
    prefix = ''
  ): Record<string, (state: IGlobalSingleState<T, A>) => unknown> => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      const fullPath = prefix ? `${prefix}.${key}` : key;

      acc[`select${capitalizedKey}`] = (state: IGlobalSingleState<T, A>) => {
        const namespaced = selectNamespacedState(state);
        return fullPath.split('.').reduce((obj, part) => (obj as any)[part], namespaced);
      };

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        const nestedSelectors = generateNestedSelectors(value as Record<string, unknown>, fullPath);
        acc = { ...acc, ...nestedSelectors };
      }

      return acc;
    }, {} as Record<string, (state: IGlobalSingleState<T, A>) => unknown>);
  };

  const additionalSelectors = additionalState ? generateNestedSelectors(additionalState) : {};

  return {
    ...baseSelectors,
    ...additionalSelectors,
  } as ISingleSelectors<T, A>;
};
