import React from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { PanelModal } from '../panel-components';
import { FormattedMessage } from 'react-intl';

export type TCustomLoginPreviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  logoUrl: string;
  buttonColor: string;
  buttonTextColor: string;
};

export const CustomLoginPreviewModal: React.FC<TCustomLoginPreviewModalProps> = ({
  isOpen,
  onClose,
  logoUrl,
  buttonColor,
  buttonTextColor,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <PanelModal
      sx={{
        maxHeight: '95vh',
      }}
      ContainerProps={{
        sx: {
          width: '100%',
          padding: '15px 30px',
          boxSizing: 'border-box',
          overflow: 'hidden',
        },
      }}
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <FormattedMessage
          id="shared.custom-login.preview.modal-title"
          defaultMessage="PREVIEW"
          description="Custom Login preview modal title"
        />
      }
      content={
        <Stack sx={{ gap: '10px' }}>
          <Stack
            sx={{
              paddingBottom: '30px',
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: 600, fontSize: '16px', marginBottom: 0 }}>
              <FormattedMessage
                id="shared.custom-login.preview.modal-description"
                defaultMessage="This is how your login page will look."
                description="Custom Login preview modal description"
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="shared.custom-login.preview.text"
                defaultMessage="Your custom logo will be placed here."
                description="Custom Login preview modal text"
              />
            </Typography>
          </Stack>
          <Divider sx={{ marginBottom: '20px' }} />
          {logoUrl && (
            <Stack
              sx={{
                height: '200px',
                width: '300px',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
                border: '2px dashed rgb(213 223 233 / 69%)',
                borderRadius: '10px',
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '10px',
                },
              }}
            >
              <img
                data-testid="shared-custom-login-preview-login-modal-logo-preview"
                style={{ pointerEvents: 'none' }}
                draggable={false}
                src={logoUrl}
                alt={'logo-preview'}
              />
            </Stack>
          )}

          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              paddingBottom: '50px',
            }}
          >
            <Box
              sx={{
                width: '300px',
                height: '40px',
                background: '#e4e4e4',
                borderRadius: '10px',
              }}
            />
            <Button
              onClick={handleClose}
              data-testid="shared-custom-login-preview-login-modal-button-preview"
              variant="contained"
              sx={{
                width: '300px',
                height: '40px',
                borderRadius: '10px',
                background: `${buttonColor}!important`,
                backgroundColor: `${buttonColor}!important`,
                color: `${buttonTextColor}!important`,
                '&.Sdk-MuiButton-root:not(:disabled):not(:hover)': {
                  background: `${buttonColor}!important`,
                  backgroundColor: `${buttonColor}!important`,
                  color: `${buttonTextColor}!important`,
                },
              }}
            >
              <FormattedMessage
                id="shared.custom-login.preview.modal-continue-button"
                defaultMessage="Continue"
                description="Custom Login preview modal continue button"
              />
            </Button>
          </Stack>
        </Stack>
      }
      labeledBy={'shared-custom-login-preview-login.show-me.modal-title'}
      describedBy={'shared-custom-login-preview-login.show-me.modal-description'}
    />
  );
};
