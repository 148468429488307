import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  tabsBox: {
    height: '100%',
  },
  fullHeight: { height: '100%' },
  listContainer: {
    overflow: 'hidden',
    height: 'calc(100vh - 64px - 55px - 5px)',
    overflowY: 'scroll',
  },
  skeletonContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
}));

export default useStyles;
