import { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Theme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';

const StyledTabs = withStyles(Tabs, () => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    height: '3px',
    backgroundColor: '#1B1D1F',
  },
}));

const StyledTab = withStyles(
  (props: ITabProps) => {
    return (
      <Tab
        disableRipple
        {...props}
        data-test={`${props?.id || props?.label?.props?.defaultMessage[0]}-tab`}
      />
    );
  },
  (theme: Theme) => ({
    root: {
      fontFamily: 'Nunito',
      fontSize: theme.typography.fontSize,
      lineHeight: '18px',
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      flex: 1,
      '&:disabled': {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
      },
    },
  })
);

export interface ITabProps {
  label?: ReactElement;
  id?: string;
  disabled: boolean;
}

interface CustomTabsProps {
  selectedId: number;
  onChangeTab: (value: number) => void;
  tabsList: ITabProps[];
}

interface ITabPanel {
  className?: string;
  children?: JSX.Element[] | JSX.Element;
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
  keepChildrenInDomWhenHidden?: boolean;
  value: unknown;
  index: number;
  tabsList?: ITabProps[];
}

function CustomTabs({ selectedId, onChangeTab, tabsList = [] }: CustomTabsProps) {
  const [currentSelected, setSelected] = useState(selectedId);
  useEffect(() => {
    if (selectedId !== currentSelected) {
      setSelected(selectedId);
    }
  }, [selectedId, currentSelected]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    setSelected(value);
    onChangeTab(value);
  };

  return (
    <StyledTabs value={currentSelected} onChange={handleChange}>
      {tabsList.map((item, index) => (
        <StyledTab key={index} label={item.label} disabled={item.disabled} id={item.id} />
      ))}
    </StyledTabs>
  );
}

function TabPanel(props: ITabPanel) {
  const { children, value, index, keepChildrenInDomWhenHidden = false, style = {}, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: '100%', height: '100%', ...style }}
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {(value === index || keepChildrenInDomWhenHidden) && children}
    </div>
  );
}

export { TabPanel, CustomTabs as Tabs };
