import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  finderNotification: {
    padding: theme.spacing(4, 6),
    maxHeight: '100%',
    overflow: 'auto',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(8),
  },
  buttonLeft: {
    marginRight: theme.spacing(4),
  },
  settingItem: {
    marginBottom: theme.spacing(6),
  },
  sharedItem: {
    padding: 0,
  },
}));
