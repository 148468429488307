import { useStyles } from './useStyles';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  Avatar,
  Chip,
  TextField,
  Box,
  Skeleton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ChangeEvent, SyntheticEvent, useEffect, useState, FC } from 'react';
import { IUser, LoadingStatus } from '@aiware/js/interfaces';
import { debounce } from 'lodash';
import { checkOrgUserName } from '../../../api/user-groups.api';
import { useSelector } from 'react-redux';
import { selectApiConfigs, selectOrgUserGroups } from '../../../redux/selectors';
import { injectIntl, FormattedMessage, WrappedComponentProps, WithIntlProps } from 'react-intl';
import { TUserGroup, TUser } from '../../../types';
import { filterDefinedMemberRecords } from '../../../helper';

export type TProps = {
  group: Partial<TUserGroup>;
  onAddMember: (value: IUser) => void;
  onRemoveMember: (value: IUser) => void;
  groupId?: string;
} & WrappedComponentProps;

const AutoComplete: FC<TProps> = ({ group, intl, onAddMember, onRemoveMember, groupId }) => {
  const { classes } = useStyles();
  const apiConfigs = useSelector(selectApiConfigs);
  const [memberName, setMemberName] = useState('');
  const [userRecords, setUserRecords] = useState<TUser[]>([]);
  const [memberExists, setMemberExists] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('success' as LoadingStatus);
  const userGroups = useSelector(selectOrgUserGroups) || [];

  // We get existingMemberGroup only for existing groups
  const existingMemberGroup: TUserGroup = userGroups.find(item => item.id === groupId)!;

  const isAlreadyMember = (id: string | undefined) => {
    const existingMemberArray: TUser[] = userRecords.filter(record =>
      filterDefinedMemberRecords(existingMemberGroup).find(member => record.id === member.id)
    );
    const existingMemberIndex = existingMemberArray.findIndex(member => member.id === id);
    const searchNameIndex = filterDefinedMemberRecords(group).findIndex(member => member.id === id);
    return existingMemberIndex !== -1 || searchNameIndex !== -1;
  };

  const formattedName = (value: IUser) => {
    return `${value.firstName?.charAt(0)?.toUpperCase()}${value.lastName?.charAt(0)?.toUpperCase()}`;
  };

  const addNamesMessage = intl.formatMessage({
    id: `add-members-name-placeholder`,
    defaultMessage: 'Add names of members',
    description: 'Add names: members placeholder',
  });

  const errorLabel = intl.formatMessage({
    id: `add-members-name-error-label`,
    defaultMessage: 'Please add names',
    description: 'Add name: error label',
  });

  const addMemberLabel = intl.formatMessage({
    id: `add-members-name-label`,
    defaultMessage: 'Add members',
    description: 'Add names: members label',
  });

  const memberLabel = memberExists ? errorLabel : addMemberLabel;

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setMemberName(ev.target.value.trim());
  };

  const debounceNameChange = debounce(handleNameChange, 300);

  useEffect(() => {
    if (memberName.length >= 3) {
      const fetchData = async () => {
        const result = await checkOrgUserName(apiConfigs, memberName);
        if (result && result.data.users.records.length) {
          setUserRecords(result.data.users.records);
        } else {
          setMemberExists(true);
          setLoadingStatus('failure' as LoadingStatus);
        }
      };
      fetchData().catch(console.error);
    } else {
      setUserRecords([]);
      setLoadingStatus('success' as LoadingStatus);
    }
  }, [memberName]);

  const isSelectReason = (reason: string, value: (string | IUser)[]): value is IUser[] => {
    return reason === 'selectOption';
  };

  const isRemoveReason = (
    reason: string,
    details: AutocompleteChangeDetails<IUser> | undefined
  ): details is AutocompleteChangeDetails<IUser> => {
    return reason === 'removeOption';
  };

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: (string | IUser)[],
    reason: string,
    details?: AutocompleteChangeDetails<IUser>
  ) => {
    if (isSelectReason(reason, value)) {
      value.forEach((user: IUser) => onAddMember(user));
    } else if (isRemoveReason(reason, details)) {
      onRemoveMember(details.option);
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      openOnFocus
      disableClearable
      loading={memberName.length >= 3}
      filterOptions={(options, state) => options.filter(option => {
        // escape regex special chars (+, ., etc) from input
        const matcher = new RegExp((state.inputValue || '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        // match against optionLabel (firstName lastName) or email
        return state.getOptionLabel(option).match(matcher) || option?.email?.match(matcher);
      })}
      loadingText={
        loadingStatus === 'success' ? (
          <Skeleton
            variant="rectangular"
            height={35}
            data-testid="os-admin-center-groups-autocomplete-skeleton-loading"
            data-test="os-admin-center-groups-autocomplete-skeleton-loading"
          />
        ) : (
          <Typography
            variant="body2"
            data-testid="os-admin-center-groups-autocomplete-no-records-were-found"
            data-test="os-admin-center-groups-autocomplete-no-records-were-found"
          >
            <FormattedMessage
              id="os-admin-center-groups-autocomplete-no-records-were-found-message"
              defaultMessage="No records were found"
              description="No records were found message"
            />
          </Typography>
        )
      }
      id="tags-outlined"
      options={userRecords}
      getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
      onChange={handleChange}
      renderTags={(values: IUser[], getTagProps) =>
        values.map((value, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              variant="outlined"
              key={value.id}
              data-testid="user-group-member-lookup-chip"
              data-test="user-group-member-lookup-chip"
              label={
                <div className={classes.renderMember}>
                  {value.imageUrl ? (
                    <Avatar
                      variant="circular"
                      alt={value.firstName}
                      src={value.imageUrl}
                      className={classes.renderAvatar}
                    />
                  ) : (
                    <Avatar variant="circular" className={classes.renderAvatar}>
                      {formattedName(value)}
                    </Avatar>
                  )}
                  {value.firstName} {value.lastName}
                </div>
              }
              deleteIcon={<CloseIcon />}
            />
          );
        })
      }
      renderOption={(props, option: IUser) => (
        <li
          {...props}
          onClick={
            !isAlreadyMember(option.id)
              ? props.onClick
              : () => {
                  // if the user's already member no action
                }
          }
          key={option.email}
          className={classes.memberInfoContainer}
          style={{ cursor: !isAlreadyMember(option.id) ? 'pointer' : 'default' }}
          data-testid="user-group-member-lookup-option"
          data-test="user-group-member-lookup-option"
        >
          <Box className={classes.member}>
            {option.imageUrl ? (
              <Avatar
                alt={option.firstName}
                src={option.imageUrl}
                className={classes.memberAvatar}
                sx={{ cursor: 'pointer' }}
                data-test="admin-center-group-suggestion-member-avatar"
              />
            ) : (
              <Avatar
                className={classes.memberAvatar}
                data-test="admin-center-group-suggestion-member-avatar"
              >
                {formattedName(option)}
              </Avatar>
            )}
            <Box>
              <Typography
                sx={{ cursor: !isAlreadyMember(option.id) ? 'pointer' : 'default' }}
                variant={'h3'}
                data-testid="admin-center-group-suggestion-member-name"
                data-test="admin-center-group-suggestion-member-name"
              >
                {`${option.firstName} ${option.lastName}`}
              </Typography>
              <Typography
                variant={'body2'}
                data-testid="admin-center-group-suggestion-member-email"
                data-test="admin-center-group-suggestion-member-email"
              >
                {option.email}
              </Typography>
            </Box>
          </Box>
          {isAlreadyMember(option?.id) && (
            <Typography
              className={classes.currentMember}
              variant="subtitle2"
              data-testid="user-group-member-lookup-current-member"
              data-test="user-group-member-lookup-current-member"
            >
              <FormattedMessage
                id="os-admin-group-center-current-member-label"
                defaultMessage="Current Member"
                description="current member label for group"
              />
            </Typography>
          )}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={memberLabel}
          id="group-members-description"
          data-testid="group-members-description"
          data-test="group-members-description"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            },
          }}
          placeholder={addNamesMessage}
          className={classes.groupMembersName}
          onChange={debounceNameChange}
        />
      )}
      className={classes.root}
    />
  );
};

export default injectIntl(AutoComplete) as FC<WithIntlProps<TProps>>;
