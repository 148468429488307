import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EActiveView,
  ISuggestion,
  ISuggestionResult,
  SuggestionResultError,
} from '../../types/aiAutocomplete.types';

export const namespace = 'aiAutocomplete';

export const initialState: State = {
  suggestionResult: {},
  searchTerm: '',
  rawSearchTerm: '', // Storing the non-debounced string
  activeView: EActiveView.suggestions,
  isOpen: false,
  isTyping: false,
};

export interface State {
  suggestionResult: ISuggestionResult;
  searchTerm: string;
  rawSearchTerm: string;
  activeView: EActiveView;
  isOpen: boolean;
  isTyping: boolean;
}

export interface ISuggestionResultAction {
  key: string;
  label: string;
  isLoading: boolean;
  suggestions: ISuggestion[];
  searchTerm?: string;
}

export const aiAutocompleteSlice = createSlice({
  name: 'aiAutocomplete',
  initialState,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
      state.isTyping = false;
    },
    setRawSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.rawSearchTerm = payload;
      state.suggestionResult = {};
      state.activeView = EActiveView.suggestions;
      if (payload) {
        state.isTyping = true;
        state.isOpen = true;
      } else {
        state.isTyping = false;
        state.isOpen = false;
      }
    },
    setSuggestionResult: (state, { payload }: PayloadAction<ISuggestionResultAction>) => {
      // @ts-ignore
      state.suggestionResult[payload?.key] = state.suggestionResult[payload?.key] || {};
      state.suggestionResult[payload?.key]!.isLoading = payload?.isLoading || false;
      state.suggestionResult[payload?.key]!.label = payload?.label || '';
      state.suggestionResult[payload?.key]!.suggestions = payload?.suggestions || [];
    },
    setIsLoading: (state, { payload }: PayloadAction<{ key: string; isLoading: boolean }>) => {
      // @ts-ignore
      state.suggestionResult[payload?.key] = state.suggestionResult[payload?.key] || {};
      state.suggestionResult[payload?.key]!.isLoading = payload?.isLoading || false;
    },
    setError: (state, { payload }: PayloadAction<{ key: string; error: SuggestionResultError | null }>) => {
      // @ts-ignore
      state.suggestionResult[payload?.key] = state.suggestionResult[payload?.key] || {};
      // @ts-ignore
      state.suggestionResult[payload?.key].error = payload?.error || {};
    },
    clearSearchTerm: state => {
      state.searchTerm = '';
      state.rawSearchTerm = '';
      state.isOpen = false;
      state.isTyping = false;
    },
    clearSuggestionResult: state => {
      state.suggestionResult = {};
    },
    abortQueries: state => {
      state.suggestionResult = {};
    },
    setActiveView: (state, { payload }: PayloadAction<EActiveView>) => {
      state.activeView = payload;
    },
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
  },
});

export const actions = aiAutocompleteSlice.actions;
export default aiAutocompleteSlice.reducer;
