import { combineReducers } from 'redux';

import { SLICE_NAME } from '../../types/';

import * as templates from './contentTemplates.slice';

export const namespace = SLICE_NAME;

export type ProcessingCenterContentTemplatesState = {
  [namespace]: {
    [templates.namespace]: templates.State;
  };
};

export const initialState = templates.initialState;

export const actions = templates.actions;

export const rootReducer = combineReducers({
  [templates.namespace]: templates.default,
});
