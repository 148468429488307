import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  notificationTypeBox: {
    paddingBottom: theme.spacing(0),
  },
  notificationTypeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  notificationList: {
    display: 'inline-grid',
    gridColumnGap: theme.spacing(2.8),
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    marginBottom: theme.spacing(4.4),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4.4),
  },
  dividerWithoutDescription: {
    marginBottom: theme.spacing(3),
  },
}));

export default useStyles;
