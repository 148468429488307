/**
 * Veritone Api Client
 */
export function veritoneApiClient<T>({
  apiRoot,
  token,
  uri,
  method = 'POST',
  headers = {},
  body,
}: {
  apiRoot: string;
  token: string;
  uri: string;
  method?: 'GET' | 'POST';
  headers?: HeadersInit;
  body?: BodyInit;
}): Promise<T> {
  return fetch(`${apiRoot}/${uri}`, {
    method,
    headers: {
      ...headers,
      Authorization: `bearer ${token}`,
    },
    ...(body ? { body } : {}),
  }).then((res): Promise<T> => res.json());
}
