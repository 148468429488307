import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@mui/material';

export const CustomLoginTableHeader: React.FC = () => {
  return (
    <Grid
      data-testid={'custom-login-table-header'}
      sx={{ height: 32, pl: 3 }}
      alignItems="center"
      display="flex"
      container
    >
      <Grid item xs={2}>
        <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
          <FormattedMessage
            id="shared-custom-login-table-header-column-name"
            defaultMessage="Name"
            description="Shared Custom Login Table header column name"
          />
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
          <FormattedMessage
            id="shared-custom-login-table-header-column-slug"
            defaultMessage="Slug"
            description="Shared Custom Login Table header column slug"
          />
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
          <FormattedMessage
            id="shared-custom-login-table-header-column-colors"
            defaultMessage="Colors"
            description="Shared Custom Login Table header column colors"
          />
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
          <FormattedMessage
            id="shared-custom-login-table-header-column-logo"
            defaultMessage="Logo"
            description="Shared Custom Login Table header column logo"
          />
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
          <FormattedMessage
            id="shared-custom-login-table-header-column-status"
            defaultMessage="Status"
            description="Shared Custom Login Table header column status"
          />
        </Typography>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
};
