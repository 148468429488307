export const validateMimeTypes = (files: File[], allowedMimeTypes: string[]): Array<string> => {
  const invalidFiles = new Set<string>();

  files?.forEach(file => {
    if (file.type) {
      if (!allowedMimeTypes.includes(file.type)) {
        invalidFiles.add(file.type);
      }
    } else {
      invalidFiles.add(file.name.split('.').pop()!);
    }
  });

  return Array.from(invalidFiles.values());
};
