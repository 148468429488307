import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void>()((theme: Theme, _props, classes) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 55px - 5px)',
    alignItems: 'stretch',
  },
  groupName: {
    padding: theme.spacing(6),
  },
  title: {
    color: '#2A323C',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '-1.30385e-09px',
  },
  subTitle: {
    color: '#2A323C',
    fontWeight: 600,
  },
  titleDescription: {
    color: '#5C6269',
    fontWeight: 400,
    maxWidth: '372px',
    marginTop: theme.spacing(1),
  },
  divider: {
    opacity: 0.9,
  },
  tabsParent: {
    '& div': {
      padding: theme.spacing(0, 0, 0, 1.6),
      borderBottom: 'none',
    },
    '& div button': {
      flex: '0 1 auto !important',
    },
  },
  secondBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 6, 6, 8),
    flexGrow: 1,
  },
}));

export default useStyles;
