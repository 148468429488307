import { makeStyles } from 'tss-react/mui';

const flowStyles = makeStyles()(theme => ({
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px!important',
    lineHeight: '16px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#5C6269!important' as '#5C6269',
  },
  borderBottomLineHeader: {
    opacity: '0.9!important',
    borderBottom: '0.5px solid #D5DFE9',
    paddingLeft: '16px!important',
    paddingBottom: '8px!important',
  },
  firstTitle: {
    paddingLeft: '28px!important',
  },
  firstColumn: {
    paddingLeft: '12px!important',
  },
  borderBottomLine: {
    padding: '0px!important',
    paddingLeft: '16px!important',
  },
  borderBottomLineRow: {
    borderBottom: '0.5px solid #D5DFE9!important',
    opacity: '0.9!important',
    paddingLeft: '12px!important',
  },
  flowName: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '0px!important',
    marginBottom: '0px!important',
  },
  flowVersion: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px!important',
    lineHeight: '16px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#5C6269!important' as '##5C6269',
    marginLeft: '8px!important',
    marginTop: '0px!important',
    marginBottom: '0px!important',
  },
  flowMode: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '0px!important',
    marginBottom: '0px!important',
  },
  flowModeToken: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '0px!important',
    marginBottom: '0px!important',
  },
  status: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '8px!important',
    lineHeight: '11px!important',
    textTransform: 'uppercase!important' as 'uppercase',
  },
  dateTime: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px!important',
    lineHeight: '20px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#5C6269!important' as '#5C6269',
  },
  dateTimeSmall: {},
  text: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '33px!important',
    paddingLeft: '12px!important',
  },
  boxCell: {
    marginTop: '20px!important',
    marginBottom: '20px!important',
  },
  boxCellIds: {
    marginTop: '20px!important',
    marginBottom: '20px!important',
    maxWidth: '150px',
    display: 'block',
  },
  table: {
    border: 'none!important' as 'none',
  },
  tableRow: {
    '& td:nth-child(5)': {
      paddingLeft: '0px!important',
      paddingRight: '20px!important',
      textAlign: 'right',
    },
    '& td:nth-child(5) > button': {
      opacity: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#EEF3F9!important' as '#EEF3F9',
      '& td:nth-child(5) > button': {
        opacity: 1,
      },
      '& td': {
        backgroundColor: '#EEF3F9!important' as '#EEF3F9',
      },
    },
  },
  runningListTableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableRowSelected: {
    backgroundColor: '#EEF3F9!important' as '#EEF3F9',
  },
  moreActionsBtn: {
    backgroundColor: '#fff!important',
  },
  tableRowClass: {
    backgroundColor: '#F2F5F9!important' as '#F2F5F9',
  },
  flowStatus: {
    width: 'fit-content!important',
    borderRadius: '2px!important',
    fontFamily: 'Nunito',
    fontStyle: 'normal!important',
    fontWeight: 800,
    textAlign: 'center',
    fontSize: '9px !important',
    lineHeight: '12px !important',
    letterSpacing: '-1.30385e-09px !important',
    textTransform: 'uppercase!important' as 'uppercase',
    padding: '3px 10px !important',
  },
  sortIcon: {
    opacity: '1!important' as unknown as 1,
  },
  paperContainer: {
    display: 'flex!important',
    flexDirection: 'column',
    marginTop: `-${theme.spacing(1.375)}!important`,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)!important',
    border: '0.5px solid #D5DFE9!important',
    minWidth: '168px!important',
  },
  popperBtns: {
    padding: '10px 16px !important',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    fontSize: '16px !important',
    lineHeight: '22px !important',
    letterSpacing: '-1.30385e-09px !important',
    color: '#1B1D1F!important' as '#1B1D1F',
    margin: theme.spacing(0.5) + 'px!important',
    opacity: '0.9!important' as unknown as 0.9,
    textTransform: 'capitalize!important' as 'capitalize',
    justifyContent: 'left!important',
    '&:hover': {
      background: '#EEF3F9!important' as '#EEF3F9',
    },
  },
  popperIcon: {
    marginRight: theme.spacing(1.8) + 'px!important',
    width: theme.spacing(2.25) + 'px!important',
    height: theme.spacing(2.25) + 'px!important',
  },
  popperHr: {
    margin: '0px!important',
    border: '1px solid #D9E2EB!important',
    width: `calc(100% - ${theme.spacing(0.571)})!important`,
  },
  boxSubtitle: {
    display: 'flex',
    alignItems: 'center',
  },
  schedulerMainView: {
    marginTop: '30px!important',
  },
  schedulerButtonGroup: {
    marginTop: '30px!important',
    marginLeft: '10px',
  },
  checkbox: {
    marginTop: '10px!important',
    marginBottom: '10px!important',
    marginLeft: '10px',
  },
  checkBoxLabelText: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '10px!important',
    marginBottom: '10px!important',
    paddingLeft: '12px!important',
  },
  cancelButton: {
    fontFamily: 'Nunito',
    fontStyle: 'normal!important',
    fontWeight: 600,
    fontSize: '14px!important',
    lineHeight: '19px!important',
    padding: '8px 15px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    textTransform: 'none',
  },
  terminateButton: {
    textTransform: 'none!important' as 'none',
    justifyContent: 'start!important' as 'start',
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'Nunito',
    fontStyle: 'normal!important',
    fontWeight: 600,
    fontSize: '14px!important',
    lineHeight: '20px!important',
    letterSpacing: '-1.30385e-09px!important',
    background: '#d20d56!important' as '#d20d56',
    color: theme.palette.background.paper,
    padding: '8px 15px!important',
    marginLeft: '22px!important',
  },
  confirmationButton: {
    textTransform: 'none!important' as 'none',
    justifyContent: 'start!important' as 'start',
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'Nunito',
    fontStyle: 'normal!important',
    fontWeight: 600,
    fontSize: '14px!important',
    lineHeight: '20px!important',
    letterSpacing: '-1.30385e-09px!important',
    background: '#0D62D2!important' as '#0D62D2',
    color: theme.palette.background.paper,
    padding: '8px 15px!important',
    marginLeft: '22px!important',
  },
  disabledButton: {
    background: '#efefef4d!important' as '#efefef4d',
    color: '#00000042',
    textTransform: 'none!important' as 'none',
    justifyContent: 'start!important' as 'start',
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'Nunito',
    fontStyle: 'normal!important',
    fontWeight: 600,
    fontSize: '14px!important',
    lineHeight: '20px!important',
    letterSpacing: '-1.30385e-09px!important',
    padding: '8px 15px!important',
    marginLeft: '22px!important',
  },
  labelText: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '33px!important',
    marginBottom: '33px!important',
    paddingLeft: '12px!important',
  },
  textLabel: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '20px!important',
    marginBottom: '20px!important',
    paddingLeft: '12px!important',
  },
  cronPreviewLabel: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '20px!important',
    marginBottom: '5px!important',
    paddingLeft: '12px!important',
  },
  cronTextField: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '20px!important',
    marginBottom: '5px!important',
    paddingLeft: '12px!important',
  },
  statusSelect: {
    minWidth: '120px!important',
  },
  restartCheckbox: {
    marginTop: '10px!important',
    marginLeft: '10px',
  },
  restartLabelText: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px!important',
    lineHeight: '22px!important',
    letterSpacing: '-1.30385e-09px!important',
    color: '#2A323C!important' as '#2A323C',
    marginTop: '10px!important',
    paddingLeft: '12px!important',
  },
  closeIcon: {
    width: '25px!important',
    height: '25px!important',
  },
}));

export { flowStyles };

export default flowStyles;
