import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { useStyles } from './func-permission.styles';
import { useSelector } from 'react-redux';
import { selectFuncPermissions } from '../../../redux';
import { PermissionsList } from '@aiware/shared/reusable-utils';

export type TProps = {
  onPermissionChange: (value: string[]) => void;
  deductedHeight?: string;
  permissionSetExistingPerm?: string[] | null;
  isPermissionProtected?: boolean;
  customTitle?: JSX.Element;
  isUsedForApiTokens?: boolean;
  setUpdatePermissionChange?: (value: boolean) => void;
};

const Permission: FC<TProps> = ({
  onPermissionChange,
  deductedHeight = '0',
  permissionSetExistingPerm = null,
  isPermissionProtected,
  customTitle,
  isUsedForApiTokens,
  setUpdatePermissionChange, //to keep track of the permission change
}) => {
  const { classes } = useStyles({ deductedHeight });
  const funcPermissions = { ...useSelector(selectFuncPermissions) };

  if (isUsedForApiTokens) {
    // nobody is going to create API tokens with no access
    delete funcPermissions['NO_ACCESS'];
  }

  return (
    <Box
      className={classes.container}
      data-testid="admin-center.permission-set.functional-permissions"
      data-test="admin-center.permission-set.functional-permissions"
    >
      {customTitle ? (
        customTitle
      ) : (
        <Typography
          variant={'caption'}
          className={classes.permissionSubtitle}
          data-testid="admin-center.permission-set.functional-permissions.subtitle"
          data-test="admin-center.permission-set.functional-permissions.subtitle"
        >
          <FormattedMessage
            id="admin-center-new-permission-permission-subtitle-message"
            defaultMessage="Select the permissions for this permission set."
            description="Permission tab subtitle"
          />
        </Typography>
      )}

      <div
        className={classes.funcPermissionsContainer}
        data-testid="admin-center.permission-set.functional-permissions.groups-container"
        data-test="admin-center.permission-set.functional-permissions.groups-container"
      >
        <PermissionsList
          onPermissionChange={onPermissionChange}
          funcPermissions={funcPermissions}
          permissionSetExistingPerm={permissionSetExistingPerm}
          isPermissionProtected={isPermissionProtected}
          setUpdatePermissionChange={setUpdatePermissionChange}
          dataTestPrefix="admin-center"
          isUsedForApiTokens={isUsedForApiTokens}
        />
      </div>
    </Box>
  );
};

export default Permission;
