import { INotificationDetail } from '@aiware/js/interfaces';
import {
  infoIcon,
  notiIcon,
  offIcon,
  reminderIcon,
  reprocess,
  share,
  view,
} from '@aiware/shared/assets';
// import { View, Share, Reprocess } from '@aiware/shared/icons';
import { DateTime } from 'luxon';

export function convertHTMLEntities(inputStr?: string): string {
  if (!inputStr) return '';
  const reservedCharsArr = ['&gt;', '&lt;', '&amp;', '&#x27;', '&#x22;'];
  const reservedChars = {
    '&gt;': '>',
    '&lt;': '<',
    '&amp;': '&',
    '&#x27;': "'",
    '&#x22;': '"',
  };

  return reservedCharsArr.reduce((acc, item) => {
    if (inputStr.includes(item)) {
      const regexpStr = new RegExp(item, 'g');
      acc = acc.replace(regexpStr, (reservedChars as any)[item]);
    }
    return acc;
  }, inputStr);
}

function generateEpochTime(daysAgo: number): string {
  const now = DateTime.local();
  const currMillis = now.toMillis();
  return DateTime.fromMillis(currMillis - daysAgo * 24 * 3600 * 1000).toISO();
}

export function getRelativeValue(isoString: string): number {
  const timeNow = new Date().getTime();
  const notificationCreateDateTime = isoString
    ? new Date(isoString).getTime()
    : new Date().getTime();
  return (notificationCreateDateTime - timeNow) / 1000 / 60;
}

export const notificationInTimeRange = (
  notificationISOTime: string
): string => {
  const nowMillis = DateTime.local().toMillis();
  const oneDayMillis = 24 * 3600 * 1000;
  const oneWeekMillis = oneDayMillis * 7;
  const oneMonthMillis = oneDayMillis * 30; // assume one month has 30 days
  const notificationMillis = DateTime.fromISO(notificationISOTime).toMillis();

  const currentDay = DateTime.local().toISODate();
  const startDayMillis = DateTime.fromISO(currentDay).valueOf();
  const timeRangeFromStartDay = nowMillis - startDayMillis;

  const timeRange = nowMillis - notificationMillis;
  if (timeRange <= oneDayMillis && timeRange <= timeRangeFromStartDay) {
    return 'today';
  } else if (timeRange <= oneWeekMillis) {
    return 'thisWeek';
  } else if (timeRange <= oneMonthMillis) {
    return 'thisMonth';
  } else {
    return 'all';
  }
};

export function convertNotificationList(noti: INotificationDetail) {
  return {
    ...noti,
    apps: 'Automate Studio',
    icon: offIcon,
    actions: [
      {
        iconUrl: reprocess,
        label: 'Re-process',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: share,
        label: 'Share',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: view,
        label: 'View',
        variant: 'text',
        color: 'default',
      },
    ],
  };
}

export const notifications = [
  {
    id: '1',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: offIcon,
    flags: 0,
    createdDateTime: DateTime.local().toISO(),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
    contentType: 'string',
    actions: [
      {
        iconUrl: reprocess,
        label: 'Re-process',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: share,
        label: 'Share',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: view,
        label: 'View',
        variant: 'text',
        color: 'default',
      },
    ],
  },
  {
    id: '2',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: infoIcon,
    contentType: 'string',
    createdDateTime: generateEpochTime(1),
    flags: 0,
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
    actions: [
      {
        iconUrl: reprocess,
        label: 'Re-process',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: share,
        label: 'Share',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: view,
        label: 'View',
        variant: 'text',
        color: 'default',
      },
    ],
  },
  {
    id: '3',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: notiIcon,
    contentType: 'string',
    createdDateTime: generateEpochTime(3),
    flags: 0,
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '4',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    contentType: 'string',
    icon: notiIcon,
    createdDateTime: generateEpochTime(4),
    flags: 0,
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '5',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: notiIcon,
    contentType: 'string',
    createdDateTime: generateEpochTime(4),
    flags: 0,
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '6',
    apps: 'Discover',
    value: 'notification-app-redact',
    icon: reminderIcon,
    contentType: 'string',
    createdDateTime: generateEpochTime(5),
    flags: 0,
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '7',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(0.25),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '8',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(0.25),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '9',
    apps: 'Illuminate',
    value: 'notification-app-illuminate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(2),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '10',
    apps: 'Illuminate',
    value: 'notification-app-illuminate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(0.5),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '11',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(3),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '12',
    apps: 'Redact',
    value: 'notification-app-redact',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(0.2),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '13',
    apps: 'Redact',
    value: 'notification-app-redact',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(10),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
    actions: [
      {
        iconUrl: reprocess,
        label: 'Re-process',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: view,
        label: 'View',
        variant: 'text',
        color: 'default',
      },
    ],
  },
  {
    id: '14',
    apps: 'Automate Studio',
    value: 'notification-app-automate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(18),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
  },
  {
    id: '15',
    apps: 'Illuminate',
    value: 'notification-app-illuminate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(22),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
    actions: [
      {
        iconUrl: share,
        label: 'Share',
        variant: 'text',
        color: 'default',
      },
      {
        iconUrl: view,
        label: 'View',
        variant: 'text',
        color: 'default',
      },
    ],
  },
  {
    id: '16',
    apps: 'Illuminate',
    value: 'notification-app-illuminate',
    icon: notiIcon,
    contentType: 'string',
    flags: 0,
    createdDateTime: generateEpochTime(40),
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum iaculis ac est in convallis. Vestibulum vitae nisl vel elit fermentum iaculis nec vitae tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam commodo erat vel nisi pharetra luctus. Integer aliquet dui mi, a porttitor mauris placerat eu. Vestibulum blandit, leo non semper dignissim, nisl metus lobortis elit, vitae malesuada leo quam ac magna. Morbi pellentesque sem eu sollicitudin rhoncus.',
    actions: [
      {
        iconUrl: reprocess,
        label: 'Re-process',
        variant: 'text',
        color: 'default',
      },
    ],
  },
];
