import { FC } from 'react';
import Badge from '@mui/material/Badge';
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
import { EngineCategoryIcons } from '@aiware/shared/icons';
import { useStyles } from './aiAvatar.styles';
import { CapabilityType } from '../../types/aiAutocomplete.types';
import { SxProps } from '@mui/system';

interface IAiAvatar {
  id: string;
  avatarUrl?: string;
  capabilityType?: CapabilityType;
  sx?: SxProps;
  [x: string]: unknown;
}

export const AiAvatar: FC<IAiAvatar> = ({ id, avatarUrl, capabilityType, sx = {}, ...rest }) => {
  const { classes } = useStyles();

  let avatarCapabilityType = `${capabilityType}`;

  if (capabilityType && capabilityType === 'KeywordExtraction') {
    avatarCapabilityType = 'KeywordRecognition';
  }
  if (capabilityType && capabilityType === 'FileName') {
    avatarCapabilityType = 'FileFilled';
  }

  const hasIcon =
    capabilityType &&
    Object.keys(EngineCategoryIcons).findIndex(iconName => iconName === avatarCapabilityType) !== -1;
  return hasIcon ? (
    <>
      {avatarUrl && (
        <Badge
          id={id}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Icon
              className={classes.avatarLogoBadge}
              sx={sx}
              component={(EngineCategoryIcons as any)[avatarCapabilityType]}
              color="secondary"
              fontSize="small"
            />
          }
        >
          <Icon>
            <Avatar className={classes.avatarLogo} src={avatarUrl} />
          </Icon>
        </Badge>
      )}

      {!avatarUrl && (
        <Icon
          className={classes.avatarNoLogo}
          sx={sx}
          component={(EngineCategoryIcons as any)[avatarCapabilityType || '']}
        />
      )}
    </>
  ) : (
    <Avatar className={classes.avatarLogo} sx={sx} src={avatarUrl} {...rest} />
  );
};
