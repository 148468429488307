import { Batch } from './Batch';
import { BatchWhite } from './BatchWhite';
import { Chunk } from './Chunk';
import { ChunkWhite } from './ChunkWhite';
import { Stream } from './Stream';
import { StreamWhite } from './StreamWhite';

export const EngineModeIcons = {
  Batch,
  BatchWhite,
  Chunk,
  ChunkWhite,
  Stream,
  StreamWhite,
};
