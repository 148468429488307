import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setConfirmCloseLabel, setIsConfirmCloseEnabled } from '@aiware/shared/redux';

export const useConfirmClose = (customMessage?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsConfirmCloseEnabled(true));
    if (customMessage) {
      dispatch(setConfirmCloseLabel(customMessage));
    }

    return () => {
      dispatch(setIsConfirmCloseEnabled(false));
    };
  }, []);

  return null;
};
