import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  DialogProps as MuiDialogProps,
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material';

export type TConfirmDialogProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  testId: string;
  DialogActions?: React.ReactElement;
  confirmLabel?: React.ReactNode;
  cancelLabel?: React.ReactNode;
  DialogProps?: Omit<MuiDialogProps, 'open' | 'onClose'>;
  DialogTitleProps?: Omit<MuiDialogTitleProps, 'id'>;
};

export const ConfirmDialog: React.FC<TConfirmDialogProps> = ({
  title,
  description,
  onConfirm,
  isOpen,
  onClose,
  testId,
  DialogActions: DialogActionComponent,
  cancelLabel,
  confirmLabel,
  DialogProps = {},
  DialogTitleProps = {},
}) => {
  const handleConfirm = () => {
    onClose();
    onConfirm();
  };

  const renderedCancelLabel = cancelLabel ? (
    cancelLabel
  ) : (
    <FormattedMessage
      id="confirm-dialog-button-cancel"
      defaultMessage="Cancel"
      description="Confirm Dialog button cancel"
    />
  );

  const renderedConfirmLabel = confirmLabel ? (
    confirmLabel
  ) : (
    <FormattedMessage
      id="confirm-dialog-button-confirm"
      defaultMessage="Confirm"
      description="Confirm dialog button confirm"
    />
  );

  const renderedActions = DialogActionComponent ? (
    DialogActionComponent
  ) : (
    <>
      <Button onClick={onClose} data-testid={`${testId}-confirm-dialog-button-go-back`}>
        {renderedCancelLabel}
      </Button>
      <Button
        autoFocus
        variant={'contained'}
        onClick={handleConfirm}
        data-testid={`${testId}-confirm-dialog-button-confirm-delete`}
      >
        {renderedConfirmLabel}
      </Button>
    </>
  );

  return (
    <Dialog
      {...DialogProps}
      open={isOpen}
      onClose={onClose}
      aria-labelledby={`${testId}-confirm-dialog-title`}
      aria-describedby={`${testId}-confirm-dialog-description`}
      data-testid={`${testId}-confirm-dialog`}
    >
      <DialogTitle {...DialogTitleProps} id={`${testId}-confirm-dialog-title`}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${testId}-confirm-dialog-description`}>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>{renderedActions}</DialogActions>
    </Dialog>
  );
};
