import { FC } from 'react';
import { useStyles } from '../useStyles';
import { MicNone } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { AiAvatar } from '../../../../AIAvatar';
import {
  isFileTypeVideoOrImage,
  removeTimeStringSeconds,
  renderFileTypeIcon,
} from '../../../helpers/sharedFunctions';
import { useSelector } from 'react-redux';
import { selectSearchActiveCriteria } from '../../../../../state/aiSearch/aiSearch.selectors';
import { AiSearchResultItemContextMenu } from '../ContextMenu/AiSearchResultItemContextMenu';
import { EResultsViewStyle } from '../../../AISearchResultsView';

interface IAiSearchRowItem {
  [x: string]: any;
}

export const AiSearchRowItemFluffy: FC<IAiSearchRowItem> = ({ item, onClick }) => {
  const { classes } = useStyles();

  const createdDate = new Date(item?.modifiedDateTime).toLocaleDateString('en-US');
  const createdTime = removeTimeStringSeconds(new Date(item?.modifiedDateTime).toLocaleTimeString('en-US'));
  const filename = item?.name;

  const isFileVideoOrImage = isFileTypeVideoOrImage(item?.fileType || item?.details?.veritoneFile?.mimetype);
  const criteria: any[] = useSelector(selectSearchActiveCriteria);

  const renderMatches = (): JSX.Element => {
    return (
      <>
        {criteria.map(criterion => (
          <Tooltip title={criterion?.label} key={`${criterion?.label}-avatar-container`}>
            <span className={classes.fluffyMatchContainer}>
              <span
                style={{ transform: 'scale(0.8)', display: 'flex', alignItems: 'center' }}
                key={`${criterion?.label}-avatar`}
              >
                <AiAvatar
                  id={criterion?.id}
                  key={criterion?.label}
                  avatarUrl={criterion.imageUrl}
                  showEngine={true}
                  capabilityType={criterion.capabilityType}
                  sx={criterion?.imageUrl ? {} : { borderRadius: '6px!important', minWidth: '30px' }}
                />
              </span>
              <span className={classes.fluffyMatchNameLabel} key={`${criterion?.label}-label`}>
                <Typography variant="body2">{criterion?.label}</Typography>
              </span>
            </span>
          </Tooltip>
        ))}
      </>
    );
  };
  const DateTime = () => {
    return (
      <>
        <Typography variant="body2" className={classes.fluffyDate}>
          {createdDate}
        </Typography>{' '}
        <Typography variant="body2" className={classes.fluffyTime}>
          {createdTime}
        </Typography>
      </>
    );
  };

  const Matches = () => {
    return <>{renderMatches()}</>;
  };
  const DataWithLabel = ({
    label,
    data,
    width = null,
    testId,
  }: {
    label: string;
    data: JSX.Element | string;
    width?: null | string;
    testId: string;
  }) => {
    return (
      <div className={classes.fluffyDataWithLabel} style={{ width: width ? width : '' }} data-testid={testId}>
        <Typography variant="body2" className={classes.fluffyDataLabel}>
          {label}:
        </Typography>

        {data}
      </div>
    );
  };
  const FluffyImage = () => {
    return (
      <div
        className={classes.fluffyImageContainer}
        style={{
          backgroundImage: isFileVideoOrImage ? `url('${item?.thumbnailUrl}')` : '',
          backgroundColor: isFileVideoOrImage ? '' : '#f7f7f7',
        }}
        data-testid="dc-search-results-view-search-item-dataset-image-container"
        onClick={onClick}
      >
        <img
          className={classes.fluffyImageLoaderElement}
          src={item?.thumbnailUrl}
          alt=""
          data-testid="dc-search-results-view-search-item-fluffy-image-loader-element"
        />
        {!isFileVideoOrImage && (
          <div
            className={classes.fluffyAudioIcon}
            data-testid="dc-search-results-view-search-item-fluffy-audio-large-icon"
          >
            <MicNone style={{ fontSize: 'inherit' }} />
          </div>
        )}
      </div>
    );
  };
  const FluffyFilename = () => {
    return (
      <div className={classes.fluffyDataRow}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 400 }}
          className={classes.fluffyFilename}
          data-testid="dc-search-results-view-search-item-dataset-filename"
        >
          {filename}
        </Typography>

        <span
          className={classes.fluffyMimeTypeIcon}
          data-testid="dc-search-results-view-search-item-dataset-file-icon"
        >
          {renderFileTypeIcon(item?.fileType || item?.details?.veritoneFile?.mimetype)}
        </span>
      </div>
    );
  };
  const FluffyCreatedTime = () => {
    return (
      <DataWithLabel
        label="Created"
        data={<DateTime />}
        width="40%"
        testId="dc-search-results-view-search-item-dataset-created-time"
      />
    );
  };

  const FluffyMatches = () => {
    return (
      <DataWithLabel
        label="Matches"
        data={<Matches />}
        testId="dc-search-results-view-search-item-dataset-matches"
      />
    );
  };

  return (
    <div
      className={classes.resultItemPadding}
      data-testid="dc-search-results-view-search-item-fluffy-container"
    >
      <div className={classes.resultItemContainer}>
        <div className={classes.fluffyItemContainer}>
          <FluffyImage />
          <div className={classes.fluffyDataContainer} onClick={onClick}>
            <FluffyFilename />
            <div className={classes.fluffyDataRow}>
              <FluffyCreatedTime />
              {/*<FluffySourceSchedule />*/}
            </div>
            <div className={classes.fluffyDataRow}>
              <FluffyMatches />
            </div>
          </div>
          <AiSearchResultItemContextMenu viewStyle={EResultsViewStyle.FLUFFY} entityId={item.id} />
        </div>
      </div>
    </div>
  );
};
