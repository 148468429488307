import { Grid, Typography } from '@mui/material';
import { TTableHeaderItem } from '../types';

export const DEFAULT_HEADER_HEIGHT = 32;

export type TTableHeaderProps = {
  items: TTableHeaderItem[];
  height?: number;
  testId: string;
};

export const TableHeader: React.FC<TTableHeaderProps> = ({
  items,
  height = DEFAULT_HEADER_HEIGHT,
  testId,
}) => {
  const itemsToRender = items.sort((a, b) => a.order - b.order);

  return (
    <Grid
      data-testid={`${testId}-table-header`}
      sx={{ height, pl: 3, borderBottom: '0.5px solid #D5DFE9' }}
      alignItems="center"
      display="flex"
      container
    >
      {itemsToRender.map((item, index) => (
        <Grid item xs={item.size} key={`${item.id}_${index}`} data-testid={`table-header-item-${index + 1}`}>
          <Typography
            variant="caption"
            color="textSecondary"
            fontWeight={'bold'}
            sx={{ cursor: 'default' }}
            data-testid={`table-header-item-${item.id}`}
          >
            {item.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
