import { ColorSwatchChip } from './color-swatch-chip/color-swatch-chip';
import { URLChip } from './url-chip/url-chip';
import { JSONChip } from './json-chip/json-chip';
import { CopyChip } from './copy-chip/copy-chip';

export const ChipComponents = {
  ColorSwatchChip,
  URLChip,
  JSONChip,
  CopyChip,
};
