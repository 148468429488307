import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

import {
  EntityMap,
  IEngineCategory,
  IEngine,
  ISourceType,
  ISchedule,
  SLICE_NAME,
  ITdoGraphQL,
} from '../../types';
import { EntityType } from '@aiware/js/interfaces';
import { actions as viewMyFilesActions } from './view-my-files';
import { actions as viewStreamsActions } from './view-streams';
import { actions as panelAddFolderActions } from './panel-add-folder';
import { actions as panelEditFolderActions } from './panel-edit-folder';
import { actions as tdoActions } from './common-actions/tdos';

export const namespace = 'entities';

export type State = EntityMap;

export const initialState: State = {
  [EntityType.EngineCategories]: {},
  [EntityType.Engines]: {},
  [EntityType.Folders]: {},
  [EntityType.Schedules]: {},
  [EntityType.Sources]: {},
  [EntityType.SourceTypes]: {},
  [EntityType.Tdos]: {},
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    engineCategoriesFetchSucceeded(state, action: PayloadAction<IEngineCategory[]>) {
      const engineCategories = action.payload;
      const entities = keyBy(engineCategories, 'id');

      state[EntityType.EngineCategories] = {
        ...state[EntityType.EngineCategories],
        ...entities,
      };
    },
    addTdoImported(state, action: PayloadAction<{ file: ITdoGraphQL }>) {
      const file = action.payload;
      const entities = keyBy(file, 'id');

      state[EntityType.Tdos] = {
        ...state[EntityType.Tdos],
        ...entities,
      };
    },
    enginesFetchSucceeded(state, action: PayloadAction<IEngine[]>) {
      const engines = action.payload;
      const entities = keyBy(engines, 'id');

      state[EntityType.Engines] = {
        ...state[EntityType.Engines],
        ...entities,
      };
    },
    sourceTypesFetchSucceeded(state, action: PayloadAction<ISourceType[]>) {
      const sourceTypes = action.payload;
      const entities = keyBy(sourceTypes, 'id');

      state[EntityType.SourceTypes] = {
        ...state[EntityType.SourceTypes],
        ...entities,
      };
    },
    addSchedule(state, action: PayloadAction<{ schedule: ISchedule }>) {
      const { schedule } = action.payload;

      state[EntityType.Schedules][schedule.id] = schedule;
    },
    toggleScheduleStatus(state, action: PayloadAction<{ scheduleId: string }>) {
      // toggle schedule status
      // copy schedule object
      const schedule = state[EntityType.Schedules][action.payload.scheduleId]!;
      state[EntityType.Schedules][action.payload.scheduleId] = {
        ...schedule,
        isActive: !schedule?.isActive,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(viewMyFilesActions.rootFolderFetchSucceeded, (state, { payload }) => {
      const { rootFolder } = payload;

      state[EntityType.Folders][rootFolder.id] = rootFolder;
    });
    builder.addCase(viewMyFilesActions.foldersOrFilesFetchSucceeded, (state, { payload }) => {
      const { folders, files } = payload;

      if (folders) {
        const foldersById = keyBy(folders.records, 'id');
        state[EntityType.Folders] = {
          ...state[EntityType.Folders],
          ...foldersById,
        };
      }

      if (files) {
        const filesById = keyBy(files.records, 'id');
        state[EntityType.Tdos] = {
          ...state[EntityType.Tdos],
          ...filesById,
        };
      }
    });
    builder.addCase(viewStreamsActions.sourcesFetchSucceeded, (state, { payload }) => {
      const { records } = payload;

      const sourcesById = keyBy(records, 'id');
      state[EntityType.Sources] = {
        ...state[EntityType.Sources],
        ...sourcesById,
      };
    });
    builder.addCase(viewStreamsActions.schedulesFetchSucceeded, (state, { payload }) => {
      const {
        data: { records },
      } = payload;

      const schedulesById = keyBy(records, 'id');
      state[EntityType.Schedules] = {
        ...state[EntityType.Schedules],
        ...schedulesById,
      };
    });
    builder.addCase(viewStreamsActions.tdosFetchSucceeded, (state, { payload }) => {
      payload.data.records.forEach(tdo => {
        if (state[EntityType.Tdos][tdo.id]) {
          state[EntityType.Tdos][tdo.id] = {
            ...state[EntityType.Tdos][tdo.id],
            ...tdo,
          };
        } else {
          state[EntityType.Tdos][tdo.id] = tdo;
        }
      });
    });
    builder.addCase(tdoActions.deleteTdoStart, (state, { payload }) => {
      state[EntityType.Tdos][payload]!.pendingDeleteStatus = 'pending';
    });
    builder.addCase(tdoActions.deleteTdoSucceeded, (state, { payload }) => {
      delete state[EntityType.Tdos][payload.tdoId];
    });
    builder.addCase(tdoActions.deleteTdoFailed, (state, { payload }) => {
      state[EntityType.Tdos][payload]!.pendingDeleteStatus = 'failure';
    });
    builder.addCase(tdoActions.moveTdoNewFolderSucceeded, (state, { payload }) => {
      state[EntityType.Tdos][payload.tdoId]!.parentFolderId = payload.newParentFolderId;
    });
    builder.addCase(panelAddFolderActions.createNewFolderSucceeded, (state, { payload }) => {
      const { newFolder } = payload;

      state[EntityType.Folders][newFolder.id] = newFolder;
    });
    builder.addCase(panelEditFolderActions.editFolderSucceeded, (state, { payload }) => {
      state[EntityType.Folders][payload.id] = payload;
    });
  },
});

export const actions = slice.actions;
export default slice.reducer;
