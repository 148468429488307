import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TAuthACE, TACEMember, TMemberType } from '../../types';
//@ts-ignore
import { TUserGroup } from '@aiware/os/admin-center/groups';
import { selectOrgAccessControlLoadingStatus } from '../../redux/selectors';
import { Utils } from '@aiware/shared/reusable-utils';

const useStyles = makeStyles()(theme => ({
  row: {
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#EEF3F9',
    },
  },
  loader: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
  divider: {
    opacity: 0.9,
  },
}));

const isGroup = (member: TACEMember): member is TUserGroup & TMemberType => {
  return 'members' in member;
};

const getMemberDescription = (member: TACEMember | undefined): string => {
  if (member && isGroup(member)) {
    return member.description;
  }
  return '';
};

const getMemberName = (member: TACEMember | undefined): string => {
  let name = '';
  if (!member) {
    return name;
  }

  if (isGroup(member)) {
    name = member.name;
  } else {
    // if not group then it's a user
    //@ts-ignore
    name = `${member.firstName} ${member.lastName}`;
  }
  return name;
};

interface IProps {
  accessControls: TAuthACE[];
  hasMore: boolean;
  loadOrgAccessControls: () => void;
}

const DESCRIPTION_LENGTH = 140;
const NAME_LENGTH = 45;

const AccessControlGroupContent = ({ accessControls, hasMore, loadOrgAccessControls }: IProps) => {
  const status = useSelector(selectOrgAccessControlLoadingStatus);

  const loadMoreAccessControls =
    status === 'loading'
      ? () => {
          /** */
        }
      : loadOrgAccessControls;
  const itemCount = hasMore ? accessControls.length + 1 : accessControls.length;
  const isItemLoaded = (index: number) => !hasMore || index < accessControls.length;
  const rowHeight =
    accessControls &&
    accessControls.map(item => {
      return getMemberDescription(item?.member).length <= 50
        ? 60
        : getMemberDescription(item?.member).length <= 120
        ? 80
        : 90;
    });
  const getItemSize = (index: number) => rowHeight[index] || 60;

  const Row: FunctionComponent<ListChildComponentProps> = ({ index, style }) => {
    let rowItem;
    const { classes } = useStyles();

    if (!isItemLoaded(index)) {
      rowItem = (
        <div data-test={'table-row-loader'} className={classes.loader}>
          <CircularProgress size={36} />
        </div>
      );
    } else {
      //@ts-ignore
      const descriptionLength = getMemberDescription(accessControls?.[index]?.member).length;
      rowItem = (
        <>
          <Grid
            style={{
              height: descriptionLength <= 50 ? '60px' : descriptionLength <= 120 ? '80px' : '90px',
              flexGrow: 1,
            }}
            container
          >
            <Grid
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              item
              container
              xs={12}
              sx={{ pl: 3, cursor: 'pointer' }}
            >
              <Grid item xs={6} sx={{ pr: 3 }}>
                <Typography variant="body1" color="textPrimary">
                  {/* @ts-ignore */}
                  {Utils.truncatedString(getMemberName(accessControls?.[index]?.member), NAME_LENGTH)}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {Utils.truncatedString(
                    //@ts-ignore
                    getMemberDescription(accessControls?.[index]?.member),
                    DESCRIPTION_LENGTH
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1" color="textPrimary">
                  {accessControls[index]?.permissionSet?.permissionName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      );
    }

    return (
      <div key={index} style={{ ...style }}>
        {rowItem}
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => {
        return (
          <InfiniteLoader
            key={Math.random()}
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreAccessControls}
          >
            {({ onItemsRendered, ref }) => (
              <div>
                <List
                  height={height - 40}
                  itemCount={itemCount}
                  itemSize={getItemSize}
                  initialScrollOffset={0}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {Row}
                </List>
              </div>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default AccessControlGroupContent;
