import { combineReducers } from 'redux';
import { SLICE_NAME } from '../../types';
import * as userApplicationSettingsState from './application-config.state';

export const namespace = SLICE_NAME;

export const initialState = {
  [userApplicationSettingsState.namespace]: userApplicationSettingsState.initialState,
};

export const actions = {
  [userApplicationSettingsState.namespace]: userApplicationSettingsState.actions,
};

export const rootReducer = combineReducers({
  [userApplicationSettingsState.namespace]: userApplicationSettingsState.default,
});
