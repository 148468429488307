export const SLICE_NAME = 'auditLog';
export const TEST_ID_WIDGET = 'audit-logs-widget';

export enum EAuditEventName {
  LoginSucceeded = 'LoginSucceeded',
  LoginFailed = 'LoginFailed',
  LoginAttemptsExceeded = 'LoginAttemptsExceeded',
  Impersonated = 'Impersonated',
  PasswordChange = 'PasswordChange',
  Logout = 'Logout',
  PasswordToken = 'PasswordToken',
  LoginVerifyMFAToken = 'LoginVerifyMFAToken',
  RegisterMFA = 'RegisterMFA',
  RegisterMFAVerifyMFAToken = 'RegisterMFAVerifyMFAToken',
  UnregisterMFA = 'UnregisterMFA',
  AuditConfigChange = 'AuditConfigChange',
  EngineBuildSubmit = 'EngineBuildSubmit',
  EngineBuildApprove = 'EngineBuildApprove',
  EngineBuildDisapprove = 'EngineBuildDisapprove',
  EngineBuildCreate = 'EngineBuildCreate',
  EngineBuildUpload = 'EngineBuildUpload',
  EngineBuildInvalidate = 'EngineBuildInvalidate',
  EngineBuildPause = 'EngineBuildPause',
  EngineBuildUnpause = 'EngineBuildUnpause',
  EngineBuildDelete = 'EngineBuildDelete',
  EngineBuildUpdate = 'EngineBuildUpdate',
  EngineCreate = 'EngineCreate',
  EngineUpdate = 'EngineUpdate',
  EngineDisable = 'EngineDisable',
  EngineEnable = 'EngineEnable',
  IntegrationSettings = 'IntegrationSettings',
  OrganizationCreate = 'OrganizationCreate',
  OrganizationUpdate = 'OrganizationUpdate',
  OrganizationDelete = 'OrganizationDelete',
  OrganizationInvitation = 'OrganizationInvitation',
  OrganizationRequest = 'OrganizationRequest',
  OrganizationRequestRejected = 'OrganizationRequestRejected',
  OrganizationRequestApproved = 'OrganizationRequestApproved',
  OrganizationInvitationRejected = 'OrganizationInvitationRejected',
  OrganizationInvitationAccepted = 'OrganizationInvitationAccepted',
  TrialSignUp = 'TrialSignUp',
  BenchmarkSignUp = 'BenchmarkSignUp',
  VoiceSignUp = 'VoiceSignUp',
  SportxSignUp = 'SportxSignUp',
  VerisafeSignUp = 'VerisafeSignUp',
  DeveloperTrialSignUp = 'DeveloperTrialSignUp',
  AutomateStudioSignUp = 'AutomateStudioSignUp',
  AwsReferralSignUp = 'AwsReferralSignUp',
  RedactSelfServiceSignUp = 'RedactSelfServiceSignUp',
  UserCreate = 'UserCreate',
  UserUpdate = 'UserUpdate',
  UserDelete = 'UserDelete',
  Unknown = 'Unknown',
  RecordingDeleted = 'RecordingDeleted',
  RecordingCreated = 'RecordingCreated',
  RecordingInserted = 'RecordingInserted',
  RecordingInsertFailed = 'RecordingInsertFailed',
  WatchListUpdated = 'WatchListUpdated',
  UserCreated = 'UserCreated',
  UserDeleted = 'UserDeleted',
  AssetUploaded = 'AssetUploaded',
  AssetMetadataUpdated = 'AssetMetadataUpdated',
  NewVersionAvailable = 'NewVersionAvailable',
  NewVersionInstalled = 'NewVersionInstalled',
  PackageCreated = 'PackageCreated',
  PackageDeleted = 'PackageDeleted',
  PackageApproved = 'PackageApproved',
  PackageRejected = 'PackageRejected',
  PackageInstalled = 'PackageInstalled',
  PackageGrantSet = 'PackageGrantSet',
  PackageGrantRemoved = 'PackageGrantRemoved',
  AccessMedia = 'AccessMedia',
  ApplicationCreate = 'ApplicationCreate',
  ApplicationUpdate = 'ApplicationUpdate',
  ApplicationDelete = 'ApplicationDelete',
  ClusterCreate = 'ClusterCreate',
  ClusterDelete = 'ClusterDelete',
  ClusterUpdate = 'ClusterUpdate',
  FolderCreate = 'FolderCreate',
  FolderUpdate = 'FolderUpdate',
  FolderDelete = 'FolderDelete',
  MediaSourceCreate = 'MediaSourceCreate',
  MediaSourceUpdate = 'MediaSourceUpdate',
  MediaSourceDelete = 'MediaSourceDelete',
  LibraryTrainingComplete = 'LibraryTrainingComplete',
  StructuredDataCreate = 'StructuredDataCreate',
  StructuredDataDelete = 'StructuredDataDelete',
  StructuredDataRegistryCreate = 'StructuredDataRegistryCreate',
  StructuredDataRegistryUpdate = 'StructuredDataRegistryUpdate',
  StructuredDataRegistryDelete = 'StructuredDataRegistryDelete',
  JobCreated = 'JobCreated',
}

export enum EAuditTargetType {
  tt_TDO = 'tt_TDO',
  tt_Job = 'tt_Job',
  tt_Asset = 'tt_Asset',
  tt_User = 'tt_User',
  tt_Organization = 'tt_Organization',
  tt_Application = 'tt_Application',
  tt_Engine = 'tt_Engine',
  tt_Cluster = 'tt_Cluster',
  tt_Library = 'tt_Library',
  tt_Source = 'tt_Source',
  tt_SDO = 'tt_SDO',
  tt_Watchlist = 'tt_Watchlist',
  tt_Folder = 'tt_Folder',
  tt_ScheduledJob = 'tt_ScheduledJob',
  tt_Authentication = 'tt_Authentication',
  tt_Platform = 'tt_Platform',
  tt_Package = 'tt_Package',
  tt_Task = 'tt_Task',
  tt_Build = 'tt_Build',
  tt_Media = 'tt_Media',
}

export enum EAuditActionResult {
  success = 'success',
  failure = 'failure',
}

export enum EAuditActionName {
  login = 'login',
  impersonate = 'impersonate',
  forbiddenAction = 'forbiddenAction',
  passwordChange = 'passwordChange',
  logout = 'logout',
  password = 'password',
  loginVerifyMFA = 'loginVerifyMFA',
  registerMFA = 'registerMFA',
  registerMFAVerifyMFA = 'registerMFAVerifyMFA',
  unregisterMFA = 'unregisterMFA',
  integrationSettings = 'integrationSettings',
  create = 'create',
  update = 'update',
  delete = 'delete',
  invite = 'invite',
  request = 'request',
  rejected = 'rejected',
  approved = 'approved',
  accepted = 'accepted',
  signUp = 'signUp',
  unknown = 'unknown',
  insert = 'insert',
  complete = 'complete',
  processed = 'processed',
  check = 'check',
  checked = 'checked',
  run = 'run',
  reportDone = 'reportDone',
  submit = 'submit',
  deploy = 'deploy',
  deploySuccess = 'deploySuccess',
  deployFail = 'deployFail',
  approve = 'approve',
  disapprove = 'disapprove',
  upload = 'upload',
  invalidate = 'invalidate',
  pause = 'pause',
  unpause = 'unpause',
  disable = 'disable',
  enable = 'enable',
  access = 'access',
  queue = 'queue',
  available = 'available',
  install = 'install',
  reject = 'reject',
  grant = 'grant',
  remove = 'remove',
}

export interface IAuditLog {
  id: string;
  eventId: string;
  organizationId: string;
  organizationGuid: string;
  organizationName: string;
  userId: string;
  userName: string;
  userAvatar?: string;
  clientIpAddress: string;
  clientUserAgent: string;
  description: string;
  createdDateTime: string;
  eventName: string;
  targetType: EAuditTargetType;
  objectId: string;
  actionResult: EAuditActionResult;
  actionName: EAuditActionName;
  originatorApplication: string;
  originatorService: string;
  impersonatorUserId: string;
  impersonatorUserName: string;
}

export enum EAuditLogFilterType {
  User = 'User',
  EventName = 'EventName',
  DateTime = 'DateTime',
}

export interface IAuditLogQueryInput {
  id?: string[];
  userName?: string;
  userId?: string;
  eventName?: string;
  toDateTime?: string;
  fromDateTime?: string;
  offset?: number;
  limit?: number;
  orderBy?: {
    field: any;
    direction: 'asc' | 'desc';
  }[];
  organizationId?: string;
}
