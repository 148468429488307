import { Autocomplete, Avatar, TextField, Typography, Chip, Select, MenuItem } from '@mui/material';
import { injectIntl, WithIntlProps } from 'react-intl';
import { IGroupMemberGroup, IGroupMemberUser, TIntlProps, TPermissionOptionSDK } from '../../../types';
import { useStyles } from './member-lookup.styles';
import { debounce } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { Group, Close } from '@aiware/shared/icons';
import { formattedMessages } from '../../helpers/formatted-messages';
import { Utils } from '@aiware/shared/reusable-utils';

type TMemberLookupProps = {
  members: (IGroupMemberUser | IGroupMemberGroup)[];
  newMembers: (IGroupMemberUser | IGroupMemberGroup)[];
  permissionOptions: TPermissionOptionSDK[];
  handleChange: (ev: any, newValue: any) => void;
  searchMember: (searchParam: string) => void;
  newMembersPermission: TPermissionOptionSDK;
  setNewMembersPermission: (permission?: TPermissionOptionSDK) => void;
  isFactoryMode: boolean;
};

// This function is used to convert a string to a case-insensitive regex string
// example: 'abc)' -> '[aA][bB][cC]\\)'
function toCaseInsensitiveRegexString(str: string) {
  // Special characters in regex that should be escaped
  const specialChars = /[-[\]{}()*+?.,\\^$|#\s]/g;

  // Escape special characters and convert each character
  return str
    .replace(specialChars, '\\$&')
    .split('')
    .map(char => {
      // If it's an alphabetic character, return a case-insensitive version
      if (char.match(/[a-z]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`;
      }
      // Else, return the character as is (it's either a digit or an escaped special character)
      return char;
    })
    .join('');
}

const MemberLookup: FC<TIntlProps & TMemberLookupProps> = ({
  intl,
  members,
  handleChange,
  searchMember,
  newMembers,
  permissionOptions,
  newMembersPermission,
  setNewMembersPermission,
  isFactoryMode,
}) => {
  const { classes } = useStyles();
  const { membersLabel, memberLabel, lookupTitleMVP, lookupLabelMVP, currentLabel } = formattedMessages(intl);

  const handleNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value?.length >= 3) {
      searchMember(toCaseInsensitiveRegexString(ev.target.value));
    }
  };

  const debounceNameChange = debounce(handleNameChange, 300);

  enum MemberType {
    User,
    AuthGroup,
  }

  type TOptionConstructorInput = {
    id?: string;
    type: MemberType;
    key: string;
    props: any;
    name: string;
    initials?: string;
    imageUrl?: string | null;
    details?: string; // 'email' for User or 'count' for Group
    current?: boolean;
  };

  const optionConstructor = ({
    props,
    type,
    key,
    imageUrl,
    name,
    initials,
    details,
    current,
  }: TOptionConstructorInput) => {
    return (
      <li
        {...props}
        key={key}
        data-testid={`permission-member-suggestion-${key}`}
        data-test={`permission-member-suggestion-${key}`}
      >
        <div className={classes.member}>
          {imageUrl ? (
            <Avatar
              alt={name}
              src={imageUrl}
              className={classes.memberAvatar}
              data-test={`permission-member-suggestion-avatar`}
            />
          ) : (
            <Avatar className={classes.memberAvatar} data-test={`permission-member-suggestion-avatar`}>
              {type === MemberType.User ? initials : <Group />}
            </Avatar>
          )}
          <div>
            <Typography
              variant={'h3'}
              data-testid="admin-center-permissions.member-lookup.name-suggestion"
              data-test="admin-center-permissions.member-lookup.name-suggestion"
            >
              {name}
            </Typography>
            <Typography
              variant={'body2'}
              data-testid="admin-center-permissions.member-lookup.email-count-suggestion"
              data-test="admin-center-permissions.member-lookup.email-count-suggestion"
            >
              {details}
            </Typography>
          </div>
        </div>
        {current ? <Typography variant="caption">{currentLabel}</Typography> : null}
      </li>
    );
  };

  const initialsConstructor = (first?: string, last?: string) => {
    return first?.charAt(0).toUpperCase() + ' ' + last?.charAt(0)?.toUpperCase();
  };

  const renderOption = (member: IGroupMemberGroup | IGroupMemberUser, props: any) => {
    const baselinePayload = {
      type: MemberType.User,
      key: member.id,
      current: member.current,
      props: {
        ...props,
        onClick: member.current ? () => ({}) : props.onClick,
        className: `${classes.memberInfoContainer} ${
          member.current ? classes.currentMemberInfoContainer : ''
        }`,
      },
    };
    let userPayload, groupPayload;
    let count;
    switch (member.memberType) {
      case 'User':
        userPayload = {
          ...baselinePayload,
          imageUrl: (member as IGroupMemberUser).imageUrl,
          name: `${(member as IGroupMemberUser).firstName} ${(member as IGroupMemberUser).lastName}`,
          initials: initialsConstructor(
            (member as IGroupMemberUser).firstName,
            (member as IGroupMemberUser).lastName
          ),
          details: (member as IGroupMemberUser).email,
        };
        return optionConstructor(userPayload);
      case 'AuthGroup':
        count = (member as IGroupMemberGroup).memberCount?.toString();
        count += count === '1' ? ` ${memberLabel}` : ` ${membersLabel}`;
        groupPayload = {
          ...baselinePayload,
          name: (member as IGroupMemberGroup).name,
          details: count,
        };
        return optionConstructor(groupPayload);
      default:
        return null;
    }
  };

  const generateLabel = (member: any) => {
    switch (member.memberType) {
      case 'User':
        return `${member.firstName} ${member.lastName}`;
      case 'AuthGroup':
        return member.name;
      default:
        return null;
    }
  };

  const constructChip = ({
    id,
    type,
    name,
    initials,
    imageUrl,
  }: Pick<TOptionConstructorInput, 'id' | 'type' | 'name' | 'initials' | 'imageUrl'>) => {
    return (
      <div className={classes.chipMember}>
        {imageUrl ? (
          <Avatar
            variant="circular"
            alt={id}
            src={imageUrl}
            className={classes.chipAvatar}
            sx={{ cursor: 'pointer' }}
          />
        ) : (
          <Avatar variant="circular" className={classes.chipAvatar}>
            {type === MemberType.User ? initials : <Group />}
          </Avatar>
        )}
        {name}
      </div>
    );
  };

  const renderChip = (member: any) => {
    let payload;
    switch (member.memberType) {
      case 'User':
        payload = {
          id: member.id,
          type: MemberType.User,
          imageUrl: member.imageUrl,
          name: Utils.truncatedString(`${member.firstName} ${member.lastName}`, 40) as any,
          initials: initialsConstructor(member.firstName, member.lastName),
        };
        return constructChip(payload);
      case 'AuthGroup':
        payload = {
          id: member.id,
          type: MemberType.AuthGroup,
          name: Utils.truncatedString(member.name, 40) as any,
        };
        return constructChip(payload);
      default:
        return null;
    }
  };

  const renderTags = (options: (IGroupMemberGroup | IGroupMemberUser)[], getTagProps: any) =>
    options.map((member: IGroupMemberGroup | IGroupMemberUser, index: number) => {
      return (
        <Chip
          {...getTagProps({ index })}
          className={classes.chipContainer}
          variant="outlined"
          key={member.id}
          label={renderChip(member)}
          deleteIcon={<Close />}
        />
      );
    });

  return (
    <div className={classes.memberLookupContainer}>
      <Typography variant="body1">{lookupTitleMVP}</Typography>
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          data-testid="permissions-member-lookup-input"
          className={classes.autoComplete}
          multiple
          freeSolo
          openOnFocus
          disableClearable
          id="permissions-panel.member-lookup"
          options={members}
          getOptionLabel={generateLabel}
          value={newMembers}
          onChange={handleChange}
          renderOption={(props, member: IGroupMemberGroup | IGroupMemberUser) => {
            return renderOption(member, props);
          }}
          renderTags={renderTags}
          renderInput={params => {
            return (
              <TextField
                {...params}
                id="group-members-description"
                data-testid="group-members-description"
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                }}
                label={lookupLabelMVP}
                className={classes.membersName}
                onChange={debounceNameChange}
              />
            );
          }}
        />
        {newMembers.length ? (
          <div className={classes.permissionSelectContainer}>
            <Select
              value={newMembersPermission?.value}
              className={classes.permissionsSelect}
              inputProps={{ 'data-testid': 'permission-set-selection' }}
              onChange={
                isFactoryMode
                  ? (ev: any) => {
                      const permissionOption = permissionOptions.find(
                        permissionOption => permissionOption.value === ev.target.value
                      );
                      if (permissionOption?.value) {
                        setNewMembersPermission(permissionOption);
                      }
                    }
                  : (ev: any) =>
                      setNewMembersPermission(
                        permissionOptions.find(permissionOption => permissionOption.value === ev.target.value)
                      )
              }
            >
              {permissionOptions?.map(permission => {
                return (
                  <MenuItem
                    value={permission?.value}
                    key={permission?.value}
                    data-testid="permission-set-option"
                  >
                    {Utils.truncatedString(permission.label, 22)}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default injectIntl(MemberLookup) as FC<WithIntlProps<TIntlProps & TMemberLookupProps>>;
