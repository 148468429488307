import React from 'react';
import { centerComponents } from '@aiware/shared/reusable-utils';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Export, Filter } from '@aiware/shared/icons';
import { SHARED_TEXT } from '../helpers/shared-text';

const { CenterTabHeading } = centerComponents;

interface IAuditLogWidgetHeaderProps {
  setFiltersSegmentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filterSegmentOpen: boolean;
  isSingleUserMode?: boolean;
  onClickDownload?: () => void;
}

export const AuditLogWidgetHeader: React.FC<IAuditLogWidgetHeaderProps> = ({
  isSingleUserMode = false,
  filterSegmentOpen,
  setFiltersSegmentOpen,
  onClickDownload,
}) => {
  const handleClickDownload = () => {
    onClickDownload?.();
  };

  const handleClickFilter = () => {
    setFiltersSegmentOpen(!filterSegmentOpen);
  };

  return (
    <CenterTabHeading
      sx={{ padding: '20px' }}
      title={isSingleUserMode ? SHARED_TEXT.view.userProfileActivityTitle : SHARED_TEXT.view.title}
      subtitle={isSingleUserMode ? SHARED_TEXT.view.userProfileActivitySubTitle : SHARED_TEXT.view.subtitle}
      removeSidePadding={true}
      HeadingActions={
        <HeaderActions onClickDownload={handleClickDownload} onClickFilter={handleClickFilter} />
      }
      testId={'audit-logs-widget-header'}
    />
  );
};

interface IHeaderActionsProps {
  onClickDownload: () => void;
  onClickFilter: () => void;
}

const HeaderActions: React.FC<IHeaderActionsProps> = ({ onClickDownload, onClickFilter }) => {
  return (
    <Stack gap={'10px'} direction={'row'}>
      <Tooltip title={SHARED_TEXT.view.downloadLogs}>
        <IconButton onClick={onClickDownload}>
          <Export />
        </IconButton>
      </Tooltip>
      <Tooltip title={SHARED_TEXT.view.filter}>
        <IconButton onClick={onClickFilter} data-test="audit-log-open-filters-button">
          <Filter />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
