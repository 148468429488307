import { EApplicationConfigLevel, EApplicationConfigType } from '@aiware/os/admin-center/shared';

export type FieldPicklistOption = {
  __typename: string;
  key: string;
  value: string;
};

export interface FieldValue {
  fieldName: string;
  fieldValue: string | any;
}

export const SLICE_NAME = 'userApplicationSettings';

export type GraphQLPage<T> = {
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export type TApplicationConfigDefinition = {
  applicationId: string;
  organizationGuid: string;
  configKey: string;
  configType: EApplicationConfigType;
  configLevel: EApplicationConfigLevel;
  required: boolean;
  secured: boolean;
  description: string;
  defaultValue: string;
  defaultValueJSON: any;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  modifiedBy: string;
};

export type TApplicationConfig = {
  applicationId: string;
  userId?: string;
  organizationGuid?: string;
  configKey: string;
  configType: EApplicationConfigType;
  configLevel?: EApplicationConfigLevel;
  required?: boolean;
  secured?: boolean;
  description?: string;
  value?: string;
  valueJSON?: any;
  createdAt?: string;
  modifiedAt?: string;
  createdBy?: string;
  modifiedBy?: string;
  defaultValue?: string;
  defaultValueJSON?: any;
  defaultCreatedAt?: string;
  defaultModifiedAt?: string;
  defaultCreatedBy?: string;
  defaultModifiedBy?: string;
  configValue?: string;
};

export type TApplicationConfigList = {
  records: TApplicationConfig[];
  offset: number;
  limit: number;
  count?: number;
};

export type TApplicationConfigDefinitionList = {
  records: TApplicationConfigDefinition[];
  offset: number;
  limit: number;
  count?: number;
};

export type OperationResult = {
  success: boolean;
  code?: string;
  msg?: string;
};

export type TApplicationConfigValueInput = {
  configKey: string;
  configValue?: string;
  configJSON?: any;
};

export type TApplicationConfigSetConfigInput = {
  orgId?: string;
  appId: string;
  configs: TApplicationConfigValueInput[];
};
