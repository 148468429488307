import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ row: number }>()((theme: Theme, { row }) => {
  return {
    tabsContainer: {
      width: '100%',
    },
    gridContainer: {
      display: 'flex',
    },
    listContainer: {},
    gridItem: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(1.6),
      '&:hover': {
        backgroundColor: '#EEF3F9',
        borderRadius: '4px',
      },
    },
    padding: {
      padding: theme.spacing(0, 6, 3, 6),
    },
    moreAppContainer: {
      height: `calc(100vh - ${
        row === 0 ? 175 : row === 1 ? 275 : row === 2 ? 395 : 500 // the height of recent app container: (0,1,2,3 row)
      }px - 66px - 10px - 55px)`, // the height of appbar, header of panel, header of detail panel
      minHeight: row === 3 ? 300 : 0,
      overflow: 'overlay',
      overflowX: 'hidden',
    },
    relativeParent: {
      position: 'relative',
    },
    noMoreApps: {
      padding: theme.spacing(0, 9, 3, 9),
    },
    noSelect: {
      userSelect: 'none',
      paddingTop: '16px',
    },
    gridTitle: {
      padding: '24px 30px 32px 30px',
      margin: '-5px 0 -5px',
      '& > $noSelect': {
        marginBottom: 15,
      },
    },
    appItemDivider: {
      margin: theme.spacing(0, 6),
    },
  };
});
export default useStyles;
