import { FunctionComponent } from 'react';
import { EngineFlagIcons, Bookmark } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import Dropdown from './dropdown';
import useStyles from '../useStyles';

import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import distributionTypesData from '../data/distributionTypesData';
import { selectionShape } from '../types';

interface Props {
  handleSelectType: (selectedDistributionType: selectionShape) => void;
  selectedType: string;
  description?: string;
  idPrefix?: string;
  inputName?: string;
  commonName?: string;
}

export const DistributionTypes: FunctionComponent<Props> = ({
  handleSelectType,
  selectedType,
  description,
  idPrefix,
  inputName,
  commonName = 'engine',
}: Props) => {
  const { classes } = useStyles();
  const distributionTypes = distributionTypesData(commonName);

  const getSelectedDistributionType = () =>
    isEmpty(selectedType) ? null : find(distributionTypes, { value: selectedType });
  return (
    <>
      <Typography data-test={`${idPrefix}-title`} style={{ fontWeight: 'bold' }} variant="h5">
        <FormattedMessage
          id="{idPrefix}.distribution-type-generic-select-title"
          defaultMessage="Distribution Types"
          description="Form label for distribution types"
          values={{ idPrefix }}
        />
      </Typography>
      <Typography data-test={`${idPrefix}-description`} className={classes.helperDescription}>
        <FormattedMessage
          id="{idPrefix}.distribution-type-generic-select-description"
          defaultMessage="{description}"
          description="Add Distribution Types for Engines | Hub"
          values={{
            idPrefix,
            description: description ? description : 'Add Distribution Types for Engines | Hub.',
          }}
        />
      </Typography>
      <Dropdown
        selectFormName={inputName || 'engineFlag'}
        selectionItems={distributionTypes}
        iconType={EngineFlagIcons}
        selectIcon={Bookmark}
        selectPlaceholder={
          <FormattedMessage
            id="{idPrefix}.distribution-type-generic-select-placeholder"
            defaultMessage="Instance Locked"
            description="Placeholder for distribution type"
            values={{ idPrefix }}
          />
        }
        dataTestPrefix={`${idPrefix}.distribution-type`}
        handleSelectFormValue={handleSelectType}
        selectedItem={getSelectedDistributionType()}
      />
    </>
  );
};
