import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isEmpty?: boolean }>()((theme, { isEmpty }) => ({
  contentTemplateContainer: {
    paddingTop: '15px',
    paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: isEmpty ? '100%' : 'auto',
  },
  contentTemplateSelectionContainer: {
    borderBottom: '0.5px solid #D5DFE9',
    marginTop: '15px',
    width: '100%',
  },
  saveButtonEnabled: {
    color: `${theme.palette.primary.contrastText} !important`,
  },
  formControlWrapper: {
    marginTop: 15,
  },
  title: {
    marginBottom: '4px',
  },
  inputLabel: {
    fontFamily: 'Nunito',
    fontWeight: 300,
    display: 'block',
    fontSize: 14,
    color: '#5C6269',
    marginBottom: 6,
  },
  inputLabelError: {
    color: theme.palette.error.main,
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  emptyImage: {
    display: 'flex',
    textAlign: 'center',
    width: '235px',
    height: '235px',
    marginBottom: theme.spacing(2),
  },
  message: {
    userSelect: 'none',
    paddingBottom: theme.spacing(2),
  },
  deleteTemplateButtonContainer: {
    backgroundColor: 'rgb(153 153 153 / 9%)',
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accordionOverrides: {
    marginRight: '5px!important',
    marginLeft: '5px!important',
  },
  accordionSummaryContent: {
    margin: '0px!important',
    alignItems: 'center',
  },
  formContainer: {},
  formLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    background: 'rgba(255,255,255,1)',
    '& img': {
      width: '180px',
    },
  },
  invalidFieldsList: {
    height: '110px',
    overflowY: 'scroll',
  },
  invalidFieldRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#f7f7f7',
    padding: '5px',
    borderRadius: '8px 8px 0 0',
    borderBottom: '1px solid #dedede',
  },
  invalidFieldCode: {
    whiteSpace: 'break-spaces',
    fontSize: '9px',
    padding: '12px',
    background: '#f4f4f4',
    borderRadius: '0 0 8px 8px',
    paddingTop: '0px',
    marginTop: 0,
  },
  invalidFieldName: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  invalidFieldMessage: {},
  form: {
    '& .unsupported-field': {
      background: 'rgba(255,0,0,0.25)',
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #E1E6ED',
    borderRadius: '7px',
    margin: '9px',
    padding: '8px',
    minWidth: '240px',
    width: '100%',
    pointerEvents: 'all',
    position: 'relative',
  },
  searchInput: {
    border: '0',
    outline: 'none',
    width: '92%',
    fontFamily: "'Nunito'",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    pointerEvents: 'all',
  },
  searchIndicator: {
    position: 'absolute',
    top: '0',
    right: '0',
    height: '100%',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchEmptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '30px',
    boxSizing: 'border-box',
    width: '100%',
    '& img': {
      maxWidth: '100%',
      width: '120px',
      marginBottom: '10px',
    },
  },
}));
