import { ISagaModule } from 'redux-dynamic-modules-saga';
import { notificationReducer } from './reducer';
import { getNotification, updateFilterValue, initNotificationAction } from './actions';
import { nameSpace } from './selector';
import type { IState } from './selector';
import { notificationSaga } from './saga';
import type { INotificationModule } from '../interfaces/notificationInterfaces';

export function getNotificationModule(): ISagaModule<unknown> {
  return {
    id: 'os-notification-panel',
    reducerMap: {
      [nameSpace]: notificationReducer,
    },
    sagas: [notificationSaga],
    initialActions: [],
    retained: true,
  };
}
export { getNotification, updateFilterValue, initNotificationAction, INotificationModule, IState };
