import { FormattedMessage } from 'react-intl';
import * as React from 'react';

export const SHARED_TEXT = {
  contentTemplatesEmpty: () => {
    return (
      <FormattedMessage
        id="processing-panel-sources.source.content-templates.empty"
        defaultMessage="No Content Templates"
        description="message for no templates"
      />
    );
  },
  contentTemplatesInvalidSchema: () => {
    return (
      <FormattedMessage
        id="processing-panel-sources.source.content-templates.invalid.schema"
        defaultMessage="Invalid Template Schema Detected"
        description="message for invalid schema"
      />
    );
  },
  contentTemplatesSubmitTemplate: () => {
    return (
      <FormattedMessage
        id="processing-panel-sources.source.content-templates.submit.template"
        defaultMessage="Submit Template"
        description="text for submit template button"
      />
    );
  },
  contentTemplatesDropdownMessage: () => {
    return (
      <FormattedMessage
        id="processing-panel-sources.source.content-templates.dropdown.message"
        defaultMessage="Select a new content template"
        description="text for select content template"
      />
    );
  },
};
