import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  addImageBoxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 0,
  },
  addImageBoxTitle: {
    padding: '0px',
    margin: '0px',
    color: 'rgb(92, 98, 105)',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left',
    paddingLeft: '5px',
    paddingBottom: '3px',
  },
  addImageBoxSizeAvatar: {
    width: '135px',
  },
  addImageBoxSizeCover: {
    width: '100%',
    height: '180px',
  },
  addImageBoxIconDiv: {
    height: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D5DFE9',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    borderRadius: '4px',
  },
  addImageBoxBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
  },
  addImageBoxShadow: {
    background: 'rgba(0,0,0,0.35)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  iconButtonContainer: {
    width: '100%',
    padding: 0,
  },
  deleteIconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteIcon: {
    color: 'white',
    fontSize: '24px',
  },
}));
