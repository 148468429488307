import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FolderId, IFolder, SLICE_NAME } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'panelEditFolder';

export type State = {
  status: LoadingStatus;
  folderId: FolderId | null;
};

export const initialState = {
  status: 'idle',
  folderId: null,
} as State;

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    openEditFolder(state, { payload }: PayloadAction<FolderId>) {
      state.status = 'idle';
      state.folderId = payload;
    },
    closeEditFolder(state) {
      state.status = 'idle';
      state.folderId = null;
    },
    editFolderStart(
      state,
      action: PayloadAction<{
        id: FolderId;
        name: string;
      }>
    ) {
      state.status = 'pending';
    },
    editFolderSucceeded(state, { payload }: PayloadAction<IFolder>) {
      state.status = 'idle';
    },
    editFolderFailed(state) {
      state.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
