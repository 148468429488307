import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ unstyledHeader?: boolean }>()((theme: Theme, { unstyledHeader }) => ({
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
    paddingRight: unstyledHeader ? '10px' : '',
  },
  header: {
    background: unstyledHeader ? '' : '#FAFAFA',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height: '55px',
    padding: theme.spacing(6, 3, 6, 6),
    borderBottom: unstyledHeader ? '' : '1px solid rgb(219, 226, 234)',
  },
  title: {
    color: '#2A323C',
  },
}));
