import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { EUserStatus } from '../lib/invite-panel';
import { selectApiConfigs } from '../redux/selectors';
import {
  GraphQLPage,
  IMyOrganizationResponse,
  IOrgAppsAndRoles,
  TInvitee,
  TInviteesResponse,
  TOrgSwitchPayload,
  TOrgSwitchResponse,
} from '../types';

export async function fetchMyOrganizations(
  apiConfigs: ReturnType<typeof selectApiConfigs>
): Promise<GraphQLPage<IMyOrganizationResponse>> {
  const operationName = 'myOrganizations';
  const name = 'myOrganizations';
  const query = `
    query ${operationName}{
      ${name} (limit:1000) {
        records {
          id
          guid
          name
          imageUrl
          guid
          priority
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<IMyOrganizationResponse>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  return result[name];
}

export async function fetchOrgAppsAndRoles(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  payload: string
): Promise<IOrgAppsAndRoles> {
  const variables = {
    id: payload,
  };

  const operationName = 'fetchOrgAppsAndRoles';
  const name = 'organization';
  const query = `
    query ${operationName}($id: ID!){
      ${name}(id: $id){
        roles(isAppEventRole: false) {
          id
          name
          appName
          description
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: Pick<IOrgAppsAndRoles, 'roles'>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return {
    ...result[name],
    applications: { records: [] },
  };
}

export async function fetchOrgAppsAndRolesForNonAdmin(
  apiConfigs: ReturnType<typeof selectApiConfigs>
): Promise<IOrgAppsAndRoles> {
  const operationName = 'fetchOrgAppsAndRoles';
  const name = 'me';
  const query = `
    query ${operationName}{
      ${name}{
        roles {
          id
          name
          appName
          description
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: Pick<IOrgAppsAndRoles, 'roles'>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  return {
    ...result[name],
    applications: { records: [] },
  };
}

export async function fetchInvitee(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  payload: {
    email: string;
    userOrgGuid: string;
  }
): Promise<TInvitee> {
  const variables = {
    name: payload.email,
  };

  const operationName = 'lookupUsersByEmail';
  const name = 'users';
  const query = `
    query ${operationName}($name: String){
      ${name}(name: $name includeAllOrgUsers:true){
        records {
          email: name
          firstName
          lastName
          status
          organizationGuids
          organizationInvites {
            status
            organization {
              guid
            }
          }
        }
      }
    }
  `;

  const result: {
    [name]: TInviteesResponse;
  } = await baseGraphQLApiWithError({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  const updatedInvitee: TInvitee = {
    email: payload.email,
    firstName: '',
    lastName: '',
    applicationRoles: [],
    status: EUserStatus.new,
    organizationGuids: [],
  };

  const { userOrgGuid } = payload;

  if (result[name] && result[name].records.length) {
    const invite = result[name].records[0]!;
    updatedInvitee.firstName = invite.firstName;
    updatedInvitee.lastName = invite.lastName;
    updatedInvitee.organizationGuids = result[name].records[0]!.organizationGuids;
    const existingInvite = invite.organizationInvites?.find(
      org => (org['organization'] as any)?.['guid'] === userOrgGuid
    );
    if (existingInvite) {
      updatedInvitee.status = ['submitted', 'approved'].includes(existingInvite.status)
        ? EUserStatus.pending
        : EUserStatus.existing;
    }
  }

  return updatedInvitee;
}

export async function switchUserToOrganization(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { userName, organizationGuid }: TOrgSwitchPayload
): Promise<{ sessionToken: string; orgName: string; orgId: string }> {
  const { token } = apiConfigs;

  const variables = {
    token,
    userName,
    organizationGuid,
  };

  const operationName = 'switchUserToOrganization';
  const name = 'switchUserToOrganization';
  const query = `
    mutation ${operationName}($token: String!, $userName: String!, $organizationGuid: ID!){
      ${name}(token: $token, userName: $userName, organizationGuid: $organizationGuid){
        token
        organization {
          id
          name
          guid
        }
      }
    }
  `;

  const {
    [name]: {
      token: sessionToken,
      organization: { name: orgName, id: orgId },
    },
  } = await baseGraphQLApiWithError<{
    [name]: TOrgSwitchResponse;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return { sessionToken, orgName, orgId };
}

export async function setUserDefaultOrganizationApi(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  organizationId: number
) {
  const variables = {
    organizationId,
  };
  const operationName = 'setUserDefaultOrganization';
  const name = 'setUserDefaultOrganization';
  const query = `
  mutation ${operationName}($organizationId: ID!) {
    ${name}(defaultOrganizationId: $organizationId) {
      id
      guid
      priority
    }
  }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: {
      id: string;
      guid: string;
      priority: number;
    };
  }>({
    query,
    variables,
    operationName,
    ...apiConfigs,
  });

  return result[name];
}
