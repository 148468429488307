import React, { useState } from 'react';
import {
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  MenuItemProps,
  TooltipProps,
  MenuProps,
  ListItemIcon,
  Menu,
  Stack,
} from '@mui/material';
import { MoreVert } from '@aiware/shared/icons';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  menuItemDivider: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}));

export type TContextMenuItem = {
  label: React.ReactNode;
  onClick: () => void;
  testId: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  disabledMessage?: string;
  divider?: boolean;
  keepMenuOpenOnClick?: boolean;
  tooltipMessage?: React.ReactNode;
  MenuItemProps?: Partial<MenuItemProps>;
  TooltipProps?: Partial<TooltipProps>;
};

export type TContextMenuProps = {
  menuItems: TContextMenuItem[];
  testId: string;
  tooltipMessage?: React.ReactNode;
  disableDividers?: boolean;
  disabled?: boolean;
  Icon?: React.ReactNode;
  AnchorElement?: React.ReactNode;
  TooltipProps?: Partial<TooltipProps>;
  MenuProps?: Partial<Omit<MenuProps, 'anchorEl' | 'id' | 'open' | 'onClose' | 'onClick'>>;
};

export const ContextMenu: React.FC<TContextMenuProps> = ({
  menuItems,
  testId,
  disabled = false,
  disableDividers,
  tooltipMessage = '',
  AnchorElement,
  Icon,
  TooltipProps = {},
  MenuProps = {},
}) => {
  const { classes } = useStyles();

  const [contextMenuEl, setContextMenuEl] = useState<HTMLButtonElement | null>(null);
  const contextMenuId = contextMenuEl ? `contextMenuId-${testId}` : undefined;
  const isOpen = !!contextMenuEl;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setContextMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setContextMenuEl(null);
  };

  return (
    <>
      <Tooltip {...TooltipProps} title={tooltipMessage}>
        <span>
          {AnchorElement ? (
            <span onClick={handleOpen}>{AnchorElement}</span>
          ) : (
            <IconButton
              disabled={disabled}
              onClick={handleOpen}
              data-testid={`${testId}-context-menu-button`}
            >
              {Icon ? Icon : <MoreVert fontSize={'small'} />}
            </IconButton>
          )}
        </span>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...MenuProps}
        role="menu"
        data-testid={`${testId}-context-menu`}
        id={contextMenuId}
        open={isOpen}
        anchorEl={contextMenuEl}
        onClose={handleClose}
        onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
      >
        {menuItems.map((item, index) => {
          const {
            label,
            testId: itemTestId,
            onClick,
            icon,
            tooltipMessage,
            isDisabled: _isDisabled,
            disabledMessage,
            divider,
            keepMenuOpenOnClick,
            MenuItemProps = {},
            TooltipProps = {},
          } = item;

          const isFirstItem = index === 0;
          const isDisabled = _isDisabled || false;

          let tooltipTitle = tooltipMessage || '';
          if (isDisabled && disabledMessage) {
            tooltipTitle = disabledMessage;
          }

          const handleItemClick = () => {
            if (!keepMenuOpenOnClick) {
              handleClose();
            }
            onClick();
          };

          return (
            <Stack key={`${testId}-menu-item-${index}`}>
              {isFirstItem || disableDividers || !divider ? null : (
                <Divider className={classes.menuItemDivider} />
              )}
              <Tooltip title={tooltipTitle} placement={'left'} {...TooltipProps}>
                <MenuItem
                  data-testid={`${testId}-context-menu-list-item-${itemTestId}`}
                  {...MenuItemProps}
                  disabled={isDisabled}
                  onClick={handleItemClick}
                >
                  {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                  <Typography
                    variant="inherit"
                    noWrap
                    data-testid={`${testId}-context-menu-list-item-label-${itemTestId}`}
                  >
                    {label}
                  </Typography>
                </MenuItem>
              </Tooltip>
            </Stack>
          );
        })}
      </Menu>
    </>
  );
};
