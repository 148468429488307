import { EngineCategoryId, EngineId, IEngine, IEngineCategory, ITdo, TdoId } from '../../types';
import IconEngineHelper from '../../lib/shared/IconEngineHelper';

export function mapTdoListToViewModels(
  tdoList: TdoId[],
  tdoEntities: Record<TdoId, ITdo>,
  engineEntities: Record<EngineId, IEngine>,
  engineCategoryEntities: Record<EngineCategoryId, IEngineCategory>
) {
  return tdoList.map(id => {
    const file = tdoEntities[id] || <ITdo>{};

    const enginesByCategoryId = (file.engineIds || [])
      .map(id => engineEntities[id])
      .filter(Boolean)
      .reduce<Record<string, IEngine[]>>((group, curEng) => {
        const { categoryId } = curEng!;
        if (group[categoryId]) {
          group[categoryId]!.push(curEng!);
        } else {
          group[categoryId] = [curEng!];
        }
        return group;
      }, {});

    const uniqueCategories = new Set<string>();
    (file.engineCategoryIds || []).forEach(id => uniqueCategories.add(id));
    const categoriesWithEngines = Array.from(uniqueCategories)
      .map(id => {
        if (!engineCategoryEntities[id]) {
          return null;
        }
        if (!IconEngineHelper(engineCategoryEntities[id]!['iconName'])) {
          return null;
        }
        return {
          ...engineCategoryEntities[id]!,
          engines: enginesByCategoryId[id]! || [],
        };
      })
      .filter(Boolean);

    return {
      type: 'file',
      file: {
        ...file,
        categoriesWithEngines,
      },
    };
  });
}
