import React from 'react';
import { getTableHeaderCellLabels } from '../helpers/json-table-helpers';
import { Skeleton, Stack, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { TJSONTableProps } from '../json-table';
import { ErrorBoundary } from '../../error-boundary/error-boundary';
import { jsonTableText } from '../helpers/json-table-text';

export type TJSONTableHeaderProps = {
  tableProps: TJSONTableProps;
};

const RenderErrorHeader = () => (
  <TableHead data-testid={'error-table-header'}>
    <TableRow>
      <Stack sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Typography noWrap>{jsonTableText.errors.headerError}</Typography>
      </Stack>
    </TableRow>
  </TableHead>
);

export const JSONTableHeader: React.FC<TJSONTableHeaderProps> = props => {
  const {
    tableProps: { data, testId },
  } = props;

  if (!data || !data[0]) return <></>;

  const isDataLoaded = data && data[0];

  const headerCellLabels = getTableHeaderCellLabels(data);

  return (
    <ErrorBoundary fallbackUI={<RenderErrorHeader />}>
      <TableHead data-testid={`${testId}-table-header`}>
        <TableRow>
          {!isDataLoaded && <LoadingHeader testId={testId} />}
          {isDataLoaded && <ItemHeaderGenerator headerCellLabels={headerCellLabels} testId={testId} />}
        </TableRow>
      </TableHead>
    </ErrorBoundary>
  );
};

const ItemHeaderGenerator: React.FC<{ headerCellLabels: string[]; testId: string }> = ({
  headerCellLabels,
  testId,
}) => {
  return (
    <>
      {headerCellLabels.map((label, index) => {
        return (
          <TableCell
            sx={{ userSelect: 'none', pointerEvents: 'none' }}
            data-testid={`${testId}-table-header-cell-${index + 1}`}
            key={index}
            align="left"
          >
            {label}
          </TableCell>
        );
      })}
    </>
  );
};

const LoadingHeader: React.FC<{ testId: string }> = ({ testId }) => {
  return (
    <TableCell data-testid={`${testId}-table-header-row-loader`} align="left" colSpan={1000}>
      <Skeleton sx={{ transform: 'none', height: '25px' }} />
    </TableCell>
  );
};
