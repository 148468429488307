import { FormattedMessage } from 'react-intl';
import React from 'react';

export const SHARED_TEXT = {
  view: {
    title: (
      <FormattedMessage
        id="os.audit-log.widget.title"
        defaultMessage="Audit Logs"
        description="Audit Logs title"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="os.audit-log.widget.description"
        defaultMessage="Track, review, and export historical activity for this Organization and its users."
        description="Audit Logs description"
      />
    ),
    userProfileActivityTitle: (
      <FormattedMessage
        id="os.audit-log.widget.user-profile-activity.title"
        defaultMessage="Activity"
        description="Audit Logs user profile activity title"
      />
    ),
    userProfileActivitySubTitle: (
      <FormattedMessage
        id="os.audit-log.widget.user-profile-activity.subtitle"
        defaultMessage="View a historical list of all activity on your aiWARE account."
        description="Audit Logs description"
      />
    ),
    downloadLogs: (
      <FormattedMessage
        id="os.audit-log.widget.header-actions.download-audit-logs"
        defaultMessage="Download Audit Logs"
        description="Audit Logs header action download"
      />
    ),
    filter: (
      <FormattedMessage
        id="os.audit-log.widget.header-actions.filter"
        defaultMessage="Open Filters"
        description="Audit Logs header action filter"
      />
    ),
  },
  table: {
    state: {
      emptyMessage: (
        <FormattedMessage
          id="os.audit-log.widget.table.state.empty.message"
          defaultMessage="There are no logs to display."
          description="Audit logs table state empty message"
        />
      ),
      emptyDescription: (
        <FormattedMessage
          id="os.audit-log.widget.table.state.empty.description"
          defaultMessage="When logs become available, they will appear here."
          description="Audit logs table state empty description"
        />
      ),
      errorMessage: (
        <FormattedMessage
          id="os.audit-log.widget.table.state.error.message"
          defaultMessage="Something when wrong fetching Audit Logs."
          description="Audit logs table state error message"
        />
      ),
      errorDescription: (
        <FormattedMessage
          id="os.audit-log.widget.table.state.error.description"
          defaultMessage="Refresh or check back again later."
          description="Audit logs table state error description"
        />
      ),
    },
    header: {
      user: (
        <FormattedMessage
          id="os.audit-log.widget.table.header.user"
          defaultMessage="User"
          description="Audit Logs table header user"
        />
      ),
      eventType: (
        <FormattedMessage
          id="os.audit-log.widget.table.header.event-type"
          defaultMessage="Event Type"
          description="Audit Logs table header event type"
        />
      ),
      details: (
        <FormattedMessage
          id="os.audit-log.widget.table.header.details"
          defaultMessage="Details"
          description="Audit Logs table header details"
        />
      ),
      dateTime: (
        <FormattedMessage
          id="os.audit-log.widget.table.header.date-time"
          defaultMessage="Date and Time"
          description="Audit Logs table header date time"
        />
      ),
    },
  },
  filters: {
    apply: (
      <FormattedMessage
        id="os.audit-log.widget.filters.apply"
        defaultMessage="Apply Filters"
        description="Audit Logs button to apply filters"
      />
    ),
    clear: (
      <FormattedMessage
        id="os.audit-log.widget.filters.clear"
        defaultMessage="Clear All"
        description="Audit Logs button to clear filters"
      />
    ),
  },
};
