import createSvgIcon from '../createSvgIcon';
export const YoutubeSource = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9538 6H6.04615C5.23826 6 4.46346 6.32093 3.8922 6.8922C3.32093 7.46346 3 8.23826 3 9.04615V15.4154C3 16.2233 3.32093 16.9981 3.8922 17.5693C4.46346 18.1406 5.23826 18.4615 6.04615 18.4615H17.9538C18.7617 18.4615 19.5365 18.1406 20.1078 17.5693C20.6791 16.9981 21 16.2233 21 15.4154V9.04615C21 8.23826 20.6791 7.46346 20.1078 6.8922C19.5365 6.32093 18.7617 6 17.9538 6ZM15.5252 12.3526L10.1252 15.1218C10.1042 15.1327 10.0807 15.1381 10.0571 15.1373C10.0334 15.1365 10.0103 15.1297 9.99006 15.1175C9.96978 15.1053 9.95297 15.088 9.94125 15.0675C9.92952 15.0469 9.92326 15.0237 9.92308 15V9.46154C9.92326 9.43786 9.92952 9.41463 9.94125 9.39406C9.95297 9.37349 9.96978 9.35627 9.99006 9.34405C10.0103 9.33182 10.0334 9.32501 10.0571 9.32424C10.0807 9.32348 10.1042 9.3288 10.1252 9.33969L15.5252 12.1089C15.5479 12.1205 15.5669 12.1382 15.5802 12.1599C15.5935 12.1817 15.6006 12.2067 15.6006 12.2322C15.6006 12.2576 15.5935 12.2826 15.5802 12.3044C15.5669 12.3261 15.5479 12.3438 15.5252 12.3554V12.3526Z"
      fill="#555F7C"
    />
    <circle cx="12" cy="12" r="12" fill="#D84848" />
    <path
      d="M15.4731 8.5H8.52692C8.05565 8.5 7.60369 8.68721 7.27045 9.02045C6.93721 9.35369 6.75 9.80565 6.75 10.2769V13.9923C6.75 14.4636 6.93721 14.9155 7.27045 15.2488C7.60369 15.582 8.05565 15.7692 8.52692 15.7692H15.4731C15.9443 15.7692 16.3963 15.582 16.7296 15.2488C17.0628 14.9155 17.25 14.4636 17.25 13.9923V10.2769C17.25 9.80565 17.0628 9.35369 16.7296 9.02045C16.3963 8.68721 15.9443 8.5 15.4731 8.5ZM14.0564 12.2057L10.9064 13.8211C10.8941 13.8274 10.8804 13.8305 10.8666 13.8301C10.8528 13.8296 10.8394 13.8257 10.8275 13.8185C10.8157 13.8114 10.8059 13.8014 10.7991 13.7894C10.7922 13.7774 10.7886 13.7638 10.7885 13.75V10.5192C10.7886 10.5054 10.7922 10.4919 10.7991 10.4799C10.8059 10.4679 10.8157 10.4578 10.8275 10.4507C10.8394 10.4436 10.8528 10.4396 10.8666 10.4391C10.8804 10.4387 10.8941 10.4418 10.9064 10.4482L14.0564 12.0635C14.0696 12.0703 14.0807 12.0806 14.0885 12.0933C14.0962 12.106 14.1003 12.1206 14.1003 12.1354C14.1003 12.1503 14.0962 12.1649 14.0885 12.1775C14.0807 12.1902 14.0696 12.2005 14.0564 12.2073V12.2057Z"
      fill="white"
    />
  </svg>,
  'YoutubeSource'
);
