import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EAllowTDOSelection, ITdoVm, SLICE_NAME, TableScrollPosition } from '../../types';
import { DataCenterView } from '@aiware/shared/redux';
import { initialTableDataSettings, TableDataSettingsState } from '../../types/entity-data-settings';
import { EntityType, LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'uiState';

export enum EBulkActionType {
  move = 'move',
  delete = 'delete',
}

export interface IBulkActionItemStatus {
  id: string;
  status: LoadingStatus;
}

export type State = {
  status: LoadingStatus;
  currentView: DataCenterView;
  activeTab: number | null;
  isFluffy: boolean;
  isInfoOpen: boolean;
  tableDataSettings: TableDataSettingsState;
  currentTableScrollPosition: TableScrollPosition | null;
  panelMode: boolean;
  panelAllowTdoSelection: EAllowTDOSelection;
  searchValue: string;
  filePickerFiles: ITdoVm[];
  bulkActionState: {
    actionType: EBulkActionType;
    itemStatus: IBulkActionItemStatus[];
  };
};

export const initialState: State = {
  status: 'pending',
  currentView: DataCenterView.myFiles,
  activeTab: 0,
  isFluffy: false,
  isInfoOpen: false,
  tableDataSettings: initialTableDataSettings,
  currentTableScrollPosition: null,
  panelMode: false,
  panelAllowTdoSelection: EAllowTDOSelection.none,
  searchValue: '',
  filePickerFiles: [],
  bulkActionState: {
    actionType: EBulkActionType.move,
    itemStatus: [],
  },
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    init(state) {
      state.status = 'pending';
    },
    initDone(state) {
      state.status = 'idle';
    },
    initFailed(state) {
      state.status = 'failure';
    },
    toggleFluffyView(state) {
      state.isFluffy = !state.isFluffy;
    },
    toggleInfo(state) {
      state.isInfoOpen = !state.isInfoOpen;
    },
    setCurrentView(state, { payload }: PayloadAction<DataCenterView>) {
      state.currentView = payload;
    },
    setActiveTab(state, { payload }: PayloadAction<number | null>) {
      state.activeTab = payload;
    },
    toggleTableDataSettings(state, { payload }: PayloadAction<EntityType | null>) {
      if (payload) {
        state.tableDataSettings.isOpen = true;
        state.tableDataSettings.entityType = payload;
      } else {
        state.tableDataSettings.isOpen = false;
        delete state.tableDataSettings.entityType;
      }
    },
    setTableDataSetting(state, { payload }: PayloadAction<{ entityType: EntityType; keys: string[] }>) {
      (state.tableDataSettings.settings as any)[payload.entityType] = payload.keys || [];
    },
    setCurrentTableScrollPosition(state, { payload }: PayloadAction<TableScrollPosition>) {
      state.currentTableScrollPosition = payload;
    },
    setPanelView(state, { payload }: PayloadAction<{ view: DataCenterView; panelMode: boolean }>) {
      const { view, panelMode } = payload;
      state.currentView = view;
      state.panelMode = panelMode;
    },
    updateSearchValue(state, { payload }: PayloadAction<string>) {
      state.searchValue = payload;
    },
    setPanelAllowTdoSelection(state, { payload }: PayloadAction<EAllowTDOSelection>) {
      state.panelAllowTdoSelection = payload;
    },
    toggleFileInPickerArray(state, { payload }: PayloadAction<ITdoVm>) {
      const isAlreadySelected = state.filePickerFiles.find(item => item.id === payload.id);

      if (isAlreadySelected) {
        state.filePickerFiles = state.filePickerFiles.filter(item => item.id != payload.id);
      } else {
        state.filePickerFiles.push(payload);
      }
    },
    clearFileInPickerArray(state) {
      state.filePickerFiles = [];
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
