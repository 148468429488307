import createSvgIcon from '../createSvgIcon';

export const CurlyBraces = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <path d="M30.709,25.342h1.28c0.332,0,0.601-0.269,0.601-0.601v-4.141c0-0.332-0.269-0.601-0.601-0.601h-1.506  c-7.366,0-11.59,3.813-11.59,10.462c0,2.079,0.309,4.242,0.609,6.335c0.293,2.05,0.595,4.17,0.595,6.234  c0,2.457-0.653,4.97-5.496,4.97c-0.332,0-0.601,0.269-0.601,0.601v3.839c0,0.332,0.269,0.601,0.601,0.601  c4.927,0,5.496,2.88,5.496,5.045c0,1.486-0.177,3.012-0.365,4.63c-0.054,0.463-0.108,0.931-0.152,1.358  c-0.475,2.565-0.687,4.502-0.687,6.281c0,7.037,4.09,10.912,11.515,10.912h1.581c0.332,0,0.601-0.269,0.601-0.601v-4.14  c0-0.332-0.269-0.601-0.601-0.601h-1.269c-3.757-0.067-5.432-1.901-5.432-5.948c0-1.405,0.193-2.855,0.38-4.257l0.141-1.079  c0.299-1.87,0.608-3.803,0.608-5.871c0.063-4.08-1.495-6.97-4.425-8.297c3.002-1.357,4.489-4.109,4.425-8.197  c0-2.007-0.28-3.758-0.578-5.613l-0.175-1.301c-0.194-1.449-0.376-2.816-0.376-4.152C25.288,27.162,26.912,25.406,30.709,25.342z"></path>
    <path d="M85.056,48.002c-4.844,0-5.497-2.513-5.497-4.97c0-2.06,0.302-4.176,0.598-6.251c0.298-2.089,0.606-4.249,0.606-6.319  C80.764,23.813,76.539,20,69.173,20h-1.505c-0.332,0-0.601,0.269-0.601,0.601v4.141c0,0.332,0.269,0.601,0.601,0.601h1.269  c3.807,0.065,5.432,1.82,5.432,5.872c0,1.336-0.182,2.704-0.376,4.152L73.82,36.65c-0.298,1.859-0.58,3.616-0.58,5.621  c-0.065,4.096,1.423,6.849,4.425,8.206c-2.931,1.327-4.489,4.216-4.425,8.287c0,2.084,0.31,4.02,0.607,5.87l0.142,1.09  c0.187,1.402,0.38,2.852,0.38,4.257c0,4.047-1.676,5.881-5.422,5.948h-1.279c-0.332,0-0.601,0.269-0.601,0.601v4.14  c0,0.332,0.269,0.601,0.601,0.601h1.581c7.426,0,11.515-3.876,11.515-10.912c0-2.149-0.338-4.429-0.608-6.257l-0.034-0.237  c-0.289-2.023-0.562-3.935-0.562-5.776c0-2.165,0.57-5.045,5.497-5.045c0.332,0,0.601-0.269,0.601-0.601v-3.839  C85.656,48.27,85.388,48.002,85.056,48.002z"></path>
    <path d="M38.361,54.1c-1.87,0-3.19-1.485-3.19-3.465c0-1.98,1.375-3.465,3.3-3.465c1.925,0,3.245,1.43,3.245,3.465  c0,1.98-1.32,3.465-3.3,3.465H38.361z"></path>
    <path d="M49.746,54.1c-1.87,0-3.19-1.485-3.19-3.465c0-1.98,1.375-3.465,3.3-3.465c1.925,0,3.246,1.43,3.246,3.465  c0,1.98-1.32,3.465-3.3,3.465H49.746z"></path>
    <path d="M61.13,54.1c-1.87,0-3.19-1.485-3.19-3.465c0-1.98,1.375-3.465,3.3-3.465c1.925,0,3.246,1.43,3.246,3.465  c0,1.98-1.32,3.465-3.3,3.465H61.13z"></path>
  </svg>,
  'CurlyBraces'
);
