import { Tab, Tabs } from '@mui/material';
import { useStyles } from '../../helpers/useStyles';
import { SHARED_TEXT } from '../../helpers/shared-text';

interface IProps {
  activeTab: number;
  onChange: (tabId: any) => void;
}

function a11yProps(index: number) {
  return {
    id: `pull-push-tab-${index}`,
    'aria-controls': `pull-push-tab-${index}`,
  };
}

export const PullPushTabs: React.FC<IProps> = ({ activeTab, onChange }) => {
  const { classes } = useStyles();

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return (
    <div className={classes.tabContainer}>
      <Tabs value={activeTab} onChange={handleChangeTab}>
        <Tab label={SHARED_TEXT.tabs.pull()} value={0} {...a11yProps(0)} />
        <Tab label={SHARED_TEXT.tabs.push()} value={1} {...a11yProps(1)} />
      </Tabs>
    </div>
  );
};
