import { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { reviewRequestPanel } from '../OrganizationPanel/InvitesTab/InvitesTab';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import { actions } from '../../redux/slices/organizations.state';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
    position: 'absolute',
    right: '25px',
    bottom: '25px',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
  actionButton: {
    textTransform: 'none',
  },
}));

export const ReviewRequestActionFooter: FunctionComponent<{
  onReject: () => void;
  onAccept: () => void;
  isDisabled: boolean;
}> = ({ onReject, onAccept, isDisabled }) => {
  const { classes } = useStyles();
  const [isActionClicked, setIsActionClicked] = useState(false);
  const dispatch = useDispatch();

  const closeReviewRequestPanel = () => {
    dispatch(hidePanel(reviewRequestPanel));
  };

  const handleRejectClick = () => {
    onReject();
    closeReviewRequestPanel();
    dispatch(actions.clearActiveOrganizationInviteId());
  };
  const handleAcceptClick = () => {
    setIsActionClicked(true);
    setTimeout(() => {
      onAccept();
      closeReviewRequestPanel();
      dispatch(actions.clearActiveOrganizationInviteId());
    }, 1000);
  };

  return (
    <div className={classes.root} data-testid="os-organization-panel-review-request-panel-action-footer">
      <Button
        onClick={handleRejectClick}
        disabled={isActionClicked || isDisabled}
        data-testid={`os-organization-panel-review-request-panel-action-footer-cancel-button`}
        className={classes.cancelButton}
        sx={{ color: '#2A323C' }}
      >
        <FormattedMessage
          id="os-organization-panel.review-request-panel.action-footer.reject-button"
          defaultMessage="Reject Invitation"
          description="Review Request Panel action footer, reject invitation button"
        />
      </Button>
      <LoadingButton
        loading={isActionClicked}
        variant="contained"
        color="primary"
        onClick={handleAcceptClick}
        disabled={isDisabled}
        data-testid={`os-organization-panel-review-request-panel-action-footer-accept-button`}
        className={classes.actionButton}
      >
        <FormattedMessage
          id="os-organization-panel.review-request-panel.action-footer.accept-button"
          defaultMessage="Accept Invitation"
          description="Review Request Panel action footer, reject invitation button"
        />
      </LoadingButton>
    </div>
  );
};
