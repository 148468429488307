import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME, TAuthGroupMemberType, TUserGroup, TCreateAuthGroupInput } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'adminCenterGroups';

export interface IAdminCenterState {
  userGroups: TUserGroup[];
  // this is for inf loader for groups
  userGroupPagination: {
    hasMore: boolean;
    offset: number;
  };
  editedGroup: TUserGroup | null;
  status: LoadingStatus;
}

export const initialState: IAdminCenterState = {
  userGroups: [],
  userGroupPagination: {
    hasMore: true,
    offset: 0,
  },
  editedGroup: null,
  status: 'idle',
};

const alterGroupSuccess = (state: IAdminCenterState, action: PayloadAction<TUserGroup>) => {
  const updatedGroup = action.payload;
  const index = state.userGroups.findIndex(group => group.id === updatedGroup.id);
  if (index !== -1) {
    state.userGroups[index] = { ...state.userGroups[index], ...updatedGroup };
  }
  state.status = 'success';
};

export const adminCenterSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    createUserGroupStart(state, action: PayloadAction<TCreateAuthGroupInput>) {
      state.status = 'loading';
    },
    createUserGroupSuccess(state, action: PayloadAction<TUserGroup>) {
      state.status = 'success';
      state.userGroups.push(action.payload);
    },
    createUserGroupFailure(state) {
      state.status = 'failure';
    },
    fetchUserGroupsStart(state) {
      state.status = 'loading';
    },
    fetchUserGroupsSuccess(
      state,
      action: PayloadAction<{
        userGroups: TUserGroup[];
        hasMore: boolean;
        nextOffset: number;
      }>
    ) {
      const { hasMore, userGroups, nextOffset } = action.payload;
      state.status = 'success';
      state.userGroupPagination.hasMore = hasMore;
      state.userGroupPagination.offset = nextOffset;
      state.userGroups = [...state.userGroups, ...userGroups];
    },
    fetchUserGroupsFailure(state) {
      state.status = 'failure';
    },
    updateUserGroupsStart(state, action: PayloadAction<{ id: string; name: string; description: string }>) {
      state.status = 'loading';
    },
    updateUserGroupsSuccess(state, action: PayloadAction<TUserGroup>) {
      alterGroupSuccess(state, action);
    },
    updateUserGroupsFailure(state) {
      state.status = 'failure';
    },
    addMembersToUserGroupStart(
      state,
      action: PayloadAction<{ id: string; members: TAuthGroupMemberType[] }>
    ) {
      state.status = 'loading';
    },
    addMembersToUserGroupSuccess(state, action: PayloadAction<TUserGroup>) {
      alterGroupSuccess(state, action);
    },
    addMembersToUserGroupFailure(state) {
      state.status = 'failure';
    },
    removeMembersFromUserGroupStart(state, action: PayloadAction<{ id: string; memberIds: string[] }>) {
      state.status = 'loading';
    },
    removeMembersFromUserGroupSuccess(state, action: PayloadAction<TUserGroup>) {
      alterGroupSuccess(state, action);
    },
    removeMembersFromUserGroupFailure(state) {
      state.status = 'failure';
    },
    deleteUserGroupStart(state, action: PayloadAction<string>) {
      state.status = 'loading';
    },
    deleteUserGroupSuccess(state, action: PayloadAction<{ id: string }>) {
      state.userGroups = state.userGroups.filter(g => g.id !== action.payload.id);
      state.status = 'success';
    },
    deleteUserGroupFailure(state) {
      state.status = 'failure';
    },
    clearUserGroups(state) {
      state.userGroups = [];
    },
  },
});

export const actions = adminCenterSlice.actions;
export default adminCenterSlice.reducer;
