import { EMessageTypes, AiwareFrameLink, Utilities } from '.';
import { errors } from './constants';

// Updated: useUtility now returns a Promise that resolves with the response data
export async function useUtility(this: AiwareFrameLink, utility: keyof typeof Utilities, data?: any) {
  if (Utilities[utility]) {
    // Generate a unique ID for this request
    const requestId = `${utility}-${Date.now()}`;
    // Send the utility
    this.postMessage(EMessageTypes.utility, { utility, data, requestId });
    // Wait for the response
    const response = await new Promise(resolve => {
      const intervalId = setInterval(() => {
        if (this.responses[requestId]) {
          resolve(this.responses[requestId]);
          delete this.responses[requestId];
          clearInterval(intervalId);
        }
      }, 50);
    });
    return response;
  } else {
    // show error toast if unsupported utility was requested
    this.errorAction && this.errorAction(this.intl?.formatMessage(errors.unsupportedUtility));
  }
}
