import { makeStyles } from 'tss-react/mui';
import { noSearchResultsImg } from '@aiware/shared/assets';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const SHARED_MESSAGES = {
  noSearchResultsFound: () => (
    <FormattedMessage
      id="flow-center-search-results"
      defaultMessage="No results found"
      description="No results found"
    />
  ),
  noSearchResultsFoundSubtitle: () => (
    <FormattedMessage
      id="flow-center-search-results-subtitle"
      defaultMessage="Sorry, there are no results for this search. Please try another search."
      description="Sorry, there are no results for this search. Please try another search."
    />
  ),
};

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    flexDirection: 'column',
  },
  icon: {},
  textContainer: {
    marginTop: 68,
    maxWidth: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Nunito!important' as 'Nunito',
    fontStyle: 'normal!important' as 'normal',
    fontWeight: 400,
    fontSize: '18px!important',
    lineHeight: '24px!important',
    color: '#1B1D1F!important' as '#1B1D1F',
  },
  subtitle: {
    marginTop: 12,
    fontFamily: 'Nunito!important' as 'Nunito',
    fontStyle: 'normal!important' as 'normal',
    fontWeight: 600,
    fontSize: '16px!important',
    lineHeight: '22px!important',
    color: '#5C6269!important' as '#5C6269',
    textAlign: 'center',
  },
}));

function NoResults() {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <img className={classes.icon} src={noSearchResultsImg} alt="noSearchResultsImg" />
      <div className={classes.textContainer}>
        <Typography className={classes.title}>{SHARED_MESSAGES.noSearchResultsFound()}</Typography>
        <Typography className={classes.subtitle}>{SHARED_MESSAGES.noSearchResultsFoundSubtitle()}</Typography>
      </div>
    </div>
  );
}

export { NoResults };

export default NoResults;
