import { ISagaModule } from 'redux-dynamic-modules-saga';
import { mailboxReducer } from './reducer';
import { mailboxSaga } from './saga';
import { requestSubscriber, subscribeDefaultMailboxRequest } from './action';
import {
  nameSpace,
  unseenNotificationSelector,
  mailboxIdSelector,
} from './selector';

export function getNotificationModule(): ISagaModule<unknown> {
  return {
    id: 'mailbox-subscription',
    reducerMap: {
      [nameSpace]: mailboxReducer,
    },
    sagas: [mailboxSaga],
    initialActions: [requestSubscriber(), subscribeDefaultMailboxRequest()],
  };
}

export { mailboxIdSelector, unseenNotificationSelector };
export * from './action';
export * from './interface';
