import { SVGProps } from 'react';

export function CmsTabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M37.818 11H12.213C10.98 11 10 11.943 10 13.13v20.025c0 1.187 1.012 2.13 2.213 2.13h5.848a6.744 6.744 0 01-.158-1.369c0-.456.034-.558.097-.984h-5c-.281 0-.5-.385-.5-.477V17.3h25v15.155c0 .092-.22.477-.5.477h-5c.095.426.128.528.128.984 0 .487-.063.944-.158 1.37h5.816c1.233 0 2.213-.974 2.213-2.13V13.13c.032-1.187-.98-2.13-2.18-2.13zm-24.151 4.017c-.537 0-.948-.395-.948-.913 0-.517.41-.913.948-.913.537 0 .948.396.948.913 0 .518-.41.913-.948.913zm2.908 0c-.506 0-.948-.395-.948-.913 0-.517.442-.913.948-.913.538 0 .98.396.98.913 0 .518-.442.913-.98.913zm2.908 0c-.537 0-.948-.395-.948-.913 0-.517.411-.913.948-.913.506 0 .949.396.949.913 0 .518-.411.913-.949.913z"
        fill="#555F7C"
      />
      <path
        d="M25.015 28.805c-2.94 0-5.31 2.282-5.31 5.113 0 2.83 2.37 5.082 5.31 5.082 2.94 0 5.31-2.282 5.31-5.113 0-2.83-2.37-5.082-5.31-5.082zm1.992 5.387l-3.035 1.826a.35.35 0 01-.538-.305v-3.652c0-.274.317-.426.538-.304l3.035 1.826c.22.183.22.487 0 .609zM16.985 26.188h16.09c.506 0 .917-.396.917-.883s-.411-.882-.917-.882h-16.09c-.506 0-.917.395-.917.882s.411.883.917.883zM16.985 21.957h16.09c.506 0 .917-.396.917-.883s-.411-.882-.917-.882h-16.09c-.506 0-.917.395-.917.882s.411.883.917.883z"
        fill="#555F7C"
      />
    </svg>
  );
}
