import type { FormGeneratorFieldWithKey } from '../types';

export const organizeFieldsIntoRows = (sortedFields: FormGeneratorFieldWithKey[], fieldsPerRow: number) => {
  let currentRow: FormGeneratorFieldWithKey[] = [];
  const rows: FormGeneratorFieldWithKey[][] = [];
  let isOddRow = true;

  sortedFields.forEach(field => {
    currentRow.push(field);
    const currentRowLimit = isOddRow ? fieldsPerRow : 1;

    if (currentRow.length === currentRowLimit) {
      rows.push([...currentRow]);
      currentRow = [];
      isOddRow = !isOddRow;
    }
  });

  if (currentRow.length > 0) {
    rows.push(currentRow);
  }

  return rows;
};
