import { IOpenIdConnector } from '../../../types';
import * as actions from './action';
import { LoadingStatus } from '@aiware/js/interfaces';
export interface IOpenIdConnectorState {
  openIdConnectors: IOpenIdConnector[];
  connectedOpenIds: IOpenIdConnector[];
  messages: string;
  status: LoadingStatus;
}

const initialState = {
  openIdConnectors: [],
  connectedOpenIds: [],
  message: '',
  status: 'idle',
};
interface IOpenIdConnectorPayload {
  data: unknown;
  message: string;
}

export function openIdConReducer<T extends IOpenIdConnectorPayload>(
  state: IOpenIdConnectorState,
  action: { type: string; payload: T }
) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actions.GET_OPEN_ID_CONNECTORS: {
      return {
        ...state,
        status: state.openIdConnectors.length === 0 && 'pending',
      };
    }
    case actions.GET_OPEN_ID_SUCCESS: {
      return {
        ...state,
        openIdConnectors: action.payload.data,
        status: 'idle',
      };
    }
    case actions.GET_CONNECTED_OPEN_ID_SUCCESS: {
      return {
        ...state,
        connectedOpenIds: action.payload.data,
      };
    }
    case actions.GET_OPEN_ID_ERROR: {
      return {
        ...state,
        message: action.payload.message,
        status: 'failure',
      };
    }
    default:
      return state;
  }
}
