import { useState, useCallback } from 'react';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Divider } from '@mui/material';
import { TabPanel, Tabs } from '@aiware/ui';
import { hidePanel } from '@aiware/js/panel';
import { getAdminCenterGroupsModule } from '../../redux';
import { SHARED_TEXT } from '../../helper/shared-text';
import PanelHeader from './PanelHeader';
import MembersTab from './MembersTab';
import SettingsTab from './SettingsTab';
import styles from './styles';
import { selectOrgUserGroups } from '../../redux/selectors';
import { TUserGroup } from '../../types';
import { FormattedMessage } from 'react-intl';

interface TProps {
  id: string;
  isProtected?: boolean;
}

export const GroupDetailsPanel = ({ id, isProtected }: TProps) => {
  const panelId = 'ADMIN_CENTER_GROUP_DETAILS_PANEL';
  const dispatch = useDispatch();
  const { classes } = styles();
  const [selectedTabId, setSelectedTabId] = useState(0);
  const userGroups = useSelector(selectOrgUserGroups);
  const group: TUserGroup = userGroups.find(item => item.id === id)!;
  const membersCount = group?.members.count;

  const onChangeTab = (id: number) => {
    setSelectedTabId(id);
  };

  const handleClose = useCallback(() => {
    dispatch(hidePanel(panelId));
  }, [dispatch]);

  const tabsList = [
    {
      label: (
        <>
          <FormattedMessage
            id="admin-center-group-details-panel.tabs.members"
            defaultMessage="Members"
            description="user's group list"
          />{' '}
          ({membersCount})
        </>
      ),
      disabled: false,
      id: 'members',
    },
    {
      label: SHARED_TEXT.settingsTab,
      disabled: false,
      id: 'settings',
    },
  ];

  return group ? (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[getAdminCenterGroupsModule()]}>
        <AIWareIntlProvider>
          <Box data-test="admin-center-group-details-panel" className={classes.root}>
            <PanelHeader
              height={64}
              onPanelClose={handleClose}
              parentPanelId={panelId}
              groupId={id}
              isProtected={isProtected}
            />
            <Box className={classes.groupName} data-test="admin-center-group-details-data-editors">
              <Typography
                className={classes.title}
                data-testid="admin-center-group-details-name"
                data-test="admin-center-group-details-name"
              >
                {group.name}
              </Typography>
              {/*TODO need to show this when we have SCIM support*/}
              {/*<Typography*/}
              {/*  variant="body2"*/}
              {/*  className={classes.subTitle}*/}
              {/*  data-testid="admin-center-group-details-subtitle"*/}
              {/*>*/}
              {/*  {SHARED_TEXT.subTitle}*/}
              {/*</Typography>*/}
              <Typography
                data-testid="admin-center-group-details-description"
                data-test="admin-center-group-details-description"
                variant="body2"
                className={classes.titleDescription}
              >
                {group.description}
              </Typography>
            </Box>
            <div className={classes.tabsParent}>
              <Tabs selectedId={selectedTabId} onChangeTab={onChangeTab} tabsList={tabsList} />
            </div>
            <Divider className={classes.divider} />
            <div className={classes.secondBox}>
              <TabPanel value={selectedTabId} index={0}>
                <MembersTab peopleList={group.members} groupId={id} />
              </TabPanel>
              <TabPanel value={selectedTabId} index={1}>
                <SettingsTab group={group} onClose={handleClose} />
              </TabPanel>
            </div>
          </Box>
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  ) : null;
};

export default GroupDetailsPanel;
