import { injectIntl, WithIntlProps } from 'react-intl';
import {
  TIntlProps,
  IGroupMemberGroup,
  IGroupMemberUser,
  TAuthACE,
  EMemberType,
  TPermissionOptionSDK,
} from '../../../types';
import { useStyles } from './member-card.styles';
import { Avatar, Button, MenuItem, Select, Typography } from '@mui/material';
import { Group } from '@aiware/shared/icons';
import { formattedMessages } from '../../helpers/formatted-messages';
import { mountPanel } from '@aiware/js/panel';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from '../modal';
import { useState, FC } from 'react';
import { Utils } from '@aiware/shared/reusable-utils';
import { useSelector } from 'react-redux';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

type TProps = TIntlProps & {
  ace: Pick<TAuthACE, 'id' | 'member' | 'createdAt' | 'permissionSet'> & {
    permissions: TPermissionOptionSDK;
  };
} & {
  permissionOptions: { value: string; label: string }[];
  onRemove: (id: string) => void;
  onChangeAccess: (permissionId: string, memberId: string, memberType: EMemberType) => void;
  isFactoryMode?: boolean;
};

const MemberCard = ({
  intl,
  ace: { member, createdAt, permissions },
  permissionOptions,
  onRemove,
  onChangeAccess,
  isFactoryMode = false,
}: TProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { membersLabel, memberLabel, addedLabel, removeAccess, removeBtn } = formattedMessages(intl);
  const [open, setOpen] = useState(false);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const accessControls = () => {
    return (
      <div
        className={classes.accessControlsContainer}
        data-testid="permissions-panel-member-card-select-access"
        data-test="permissions-panel-member-card-select-access"
      >
        <Select
          className={classes.permissionsSelect}
          value={permissions.value}
          inputProps={{ 'data-testid': 'permissions-panel-member-card-access-input' }}
          onChange={ev =>
            onChangeAccess(
              ev.target.value,
              member.id!,
              member.memberType === 'AuthGroup' ? EMemberType.Group : EMemberType.User
            )
          }
        >
          {permissionOptions?.map(permission => {
            return (
              <MenuItem
                key={permission.value}
                value={permission.value}
                data-test={`permissions-panel.permissions-menu.item-${permission.value}`}
              >
                {Utils.truncatedString(permission.label, 22)}
              </MenuItem>
            );
          })}
        </Select>
        <Button
          variant="outlined"
          onClick={isFactoryMode ? () => onRemove(member.id!) : () => setOpen(true)}
          data-testid="permissions-panel-member-card-revoke-access"
          data-test="permissions-panel-member-card-revoke-access"
        >
          <Typography component={'span'} variant="caption" sx={{ color: 'black', fontWeight: 400 }}>
            {isFactoryMode ? removeBtn : removeAccess}
          </Typography>
        </Button>

        <ConfirmationModal
          onConfirm={() => onRemove(member.id!)}
          onClose={() => setOpen(false)}
          open={open}
        />
      </div>
    );
  };

  const memberAvatar = (type?: string, image?: string | null, initials?: string) => {
    return (
      <div className={classes.memberAvatar}>
        <Avatar src={image || ''} className={classes.avatar} data-test="permissions-panel-member-card.avatar">
          {type === 'user' ? initials : <Group />}
        </Avatar>
      </div>
    );
  };

  const mountGroupInfoPanel = () => {
    const microFrontend = {
      name: 'GROUP_INFO_PANEL',
      config: {
        name: 'Group Info',
        groupId: member.id,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 560 : 640,
      size: 'small',
      siblingPanelId: 'PERMISSIONS_PANEL',
      parentPanelId: 'PERMISSIONS_PANEL',
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: 'GROUP_INFO_PANEL',
        microFrontend,
        panelConfig,
      })
    );
  };

  const memberDetails = (
    type: string,
    createdAt: string,
    member: Partial<IGroupMemberUser & IGroupMemberGroup>
  ) => {
    return (
      <div data-test="permissions-panel-member-card.member-details">
        <div>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            {type === 'user' ? `${member?.firstName} ${member?.lastName}` : member?.name}
          </Typography>
        </div>
        <div className={classes.membersCountContainer}>
          <Typography variant="body2">
            {type === 'user' ? (
              member.email
            ) : (
              <span onClick={mountGroupInfoPanel}>
                {member?.memberCount} {member?.memberCount === 1 ? memberLabel : membersLabel}
              </span>
            )}
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            {addedLabel} {formatDate(createdAt)}
          </Typography>
        </div>
      </div>
    );
  };

  const formatDate = (createdAt: string) => {
    return `${new Date(createdAt).getMonth() + 1}/${new Date(createdAt).getDay()}/${new Date(
      createdAt
    ).getFullYear()}`;
  };

  const renderMemberCard = (member: IGroupMemberGroup & IGroupMemberUser, createdAt: string) => {
    const initials = member?.firstName?.substring(0, 1) + member?.lastName?.substring(0, 1);
    return (
      <div
        className={classes.flexContainer}
        data-testid={`permissions-panel-${member.memberType}-member-card`}
        data-test={`permissions-panel-${member.memberType}-member-card`}
      >
        {member.memberType === 'User' ? memberAvatar('user', member?.imageUrl, initials) : memberAvatar()}
        {memberDetails(
          member.memberType.toLowerCase(),
          createdAt,
          member as Partial<IGroupMemberUser & IGroupMemberGroup>
        )}
      </div>
    );
  };
  return (
    <div className={classes.memberCardContainer} data-testid={`permissions-panel.member-container`}>
      <div>{renderMemberCard(member as IGroupMemberUser & IGroupMemberGroup, createdAt)}</div>
      {accessControls()}
    </div>
  );
};

export default injectIntl(MemberCard) as FC<WithIntlProps<TProps>>;
