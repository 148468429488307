import axios from 'axios';
import { ECapabilityTypes, ISuggestion } from '../../types/aiAutocomplete.types';
import { v4 as uuidv4 } from 'uuid';
import { root } from '.';

type TLogoResponse = {
  key: string;
  doc_count: number;
  docs: any[];
};

const field = 'logo-recognition.series.found';

export const getLogoSuggestionsQuery = async ({
  endpoint,
  token,
  text,
}: {
  endpoint: string;
  token: string;
  text: string;
}): Promise<ISuggestion[]> => {
  const logoQueryAbortController = new AbortController();

  // Store the abort controller in the root object so it can be aborted later.
  root.abortControllers[ECapabilityTypes.logo] = logoQueryAbortController;

  const result = await axios
    .post(
      `${endpoint}/v1/search/search/autocomplete`,
      {
        index: ['mine', 'global'],
        fields: [field],
        text,
        limit: 10,
        returnContext: false,
      },
      {
        signal: root.abortControllers[ECapabilityTypes.logo]?.signal,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response.data);

  if (!result?.fields) {
    throw Error('An error occurred while searching for logos that match your search criteria.');
  }

  return result.fields[field].map((item: TLogoResponse) => ({
    id: uuidv4(),
    label: item.key || '',
    imageUrl: '',
    capabilityType: ECapabilityTypes.logo,
  })) as ISuggestion[];
};
