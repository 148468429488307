import { DataCenterImporterState, namespace } from '../slices';

export const selectSelectedFiles = (state: DataCenterImporterState) =>
  state[namespace].filesSelectedState.files;

export const selectUploadedFilesTdoIds = (state: DataCenterImporterState): string[] => {
  const todoIds: string[] = state[namespace]?.filesSelectedState?.tdoIds;

  return todoIds;
};

export const selectPermissions = (state: DataCenterImporterState) => {
  const permissions = state[namespace]?.filesSelectedState?.permissions;

  return permissions;
};
