// typescript hates infinite depth, linters hate any, so let's just define json as having a max depth of 3
type TJSONLeafValues = string | number | boolean | null;
type TJSONDepth2Values = TJSONLeafValues | TJSONLeafValues[] | { [key: string]: TJSONLeafValues };
type TJSONDepth1Values = TJSONDepth2Values | TJSONDepth2Values[] | { [key: string]: TJSONDepth2Values };
type TJsonValues = TJSONDepth1Values | TJSONDepth1Values[] | { [key: string]: TJSONDepth1Values };
export type JSONData = Record<string, TJsonValues>;

export const POTENTIAL_PAIR_STUB = '"_POTENTIAL_PAIR_KEY_": "_POTENTIAL_PAIR_VALUE_"';
export const POTENTIAL_ITEM_STUB = '"_POTENTIAL_ITEM_"';

export const validateJson = (json: string): JSONData | null => {
  try {
    return JSON.parse(json) as JSONData;
  } catch (e) {
    return null;
  }
};

export const isJsonValid = (json: string): boolean => (json.length > 0 ? validateJson(json) !== null : true);

export const formatJson = (json: string, removeTrailingComma: boolean): string | null => {
  const value = removeTrailingComma ? json.replace(/,\s*([\]}])/g, '$1') : json;
  try {
    return JSON.stringify(JSON.parse(value), null, 2);
  } catch (e) {
    return null;
  }
};

// add the addition to the json string from pre and post and test for validity
const tryJsonAddition = (pre: string, post: string, addition: string) => {
  return formatJson(`${pre}${addition}${post}`, false) || formatJson(`${pre}${addition}${post}`, true);
};

// update the editor cursor position with the first viable option from the array
export const updateCursorPosition = (target: HTMLTextAreaElement, positionOptions: number[]) => {
  const position = positionOptions.reduce((accum, item) => {
    if (accum === -1) {
      return item;
    }
    return accum;
  }, -1);
  if (position === -1) {
    return;
  }
  setTimeout(() => {
    target.focus();
    target.selectionStart = position;
    target.selectionEnd = position;
    target.setSelectionRange(position, position);
  }, 0);
};

export const splitInputAtCursor = (target: HTMLTextAreaElement, value: string) => {
  const pre = value.substring(0, target.selectionStart);
  const post = value.substring(target.selectionStart);
  return { pre, post };
};

// try a regex replacement on the current line and test for validity
const tryJsonReplacement = (
  pre: string,
  post: string,
  testRegex: RegExp,
  replacement: string
): string | null => {
  const preLines = pre.split('\n');
  if (preLines.length === 0) {
    return null;
  }
  const lastLine = preLines[preLines.length - 1]!;
  if (!testRegex.test(lastLine)) {
    return null;
  }
  return tryJsonAddition(
    pre.substring(0, pre.length - lastLine.length),
    post,
    lastLine.replace(testRegex, replacement)
  );
};

// try a series of additions at the current position and return the first working calculation
export const calculatePotentialAddition = (pre: string, post: string): string | null => {
  const testNakedKey = /^\s*([{]?)([a-z0-9_-]+)(.*)$/;
  const testNakedPair = /^\s*([a-z0-9_-]+)\s*:\s*([a-z0-9_-]+[,]?)$/;
  const testNakedItem = /^\s*([a-z0-9_-]+)([,]?)$/;
  return (
    tryJsonAddition(pre, post, `\n${POTENTIAL_PAIR_STUB}}`) || // add pair and closing brace
    tryJsonAddition(pre, post, `\n${POTENTIAL_ITEM_STUB}]`) || // add item and closing bracket
    tryJsonAddition(pre, post, `\n${POTENTIAL_PAIR_STUB}`) || // add pair only
    tryJsonAddition(pre, post, `\n${POTENTIAL_ITEM_STUB}`) || // add item only
    tryJsonAddition(pre, post, '\n}') || // add closing brace
    tryJsonAddition(pre, post, '\n]') || // add closing bracket
    tryJsonAddition(pre, post, `\n,${POTENTIAL_ITEM_STUB}`) || // add comma and item
    tryJsonAddition(pre, post, `,${POTENTIAL_PAIR_STUB}`) || // add comma and pair
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3,${POTENTIAL_PAIR_STUB}`) || // add quotes to key, comma, and pair
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3${POTENTIAL_PAIR_STUB}`) || // add quotes to key and pair
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3${POTENTIAL_PAIR_STUB}}`) || // add quotes, pair, and closing brace
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3{${POTENTIAL_PAIR_STUB}}`) || // add quotes to key and brackets around pair
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3: {${POTENTIAL_PAIR_STUB}}`) || // add quotes to key and brackets around pair
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3${POTENTIAL_ITEM_STUB}]`) || // add quotes, item, and closing bracket
    tryJsonReplacement(pre, post, testNakedPair, `"$1": $2,${POTENTIAL_PAIR_STUB}`) || // add quotes, comma, and pair
    tryJsonReplacement(pre, post, testNakedPair, `"$1": $2${POTENTIAL_PAIR_STUB}`) || // add quotes to key and pair
    tryJsonReplacement(pre, post, testNakedPair, `"$1": "$2",${POTENTIAL_PAIR_STUB}`) || // add quotes to both sides, comma, and pair
    tryJsonReplacement(pre, post, testNakedPair, `"$1": "$2"${POTENTIAL_PAIR_STUB}`) || // add quotes to both sides and pair
    tryJsonReplacement(pre, post, testNakedItem, `"$1"$2,${POTENTIAL_ITEM_STUB}`) || // add quotes, comma, and item
    tryJsonReplacement(pre, post, testNakedItem, `"$1"$2${POTENTIAL_ITEM_STUB}`) || // add quotes and item
    tryJsonReplacement(pre, post, testNakedItem, '"$1"$2') || // add quotes
    tryJsonReplacement(pre, post, testNakedKey, `$1"$2"$3`) || // add quotes to key
    tryJsonAddition(pre, post, '\n')
  );
};

export const removePotentialStubs = (value: string): string =>
  value.replace(POTENTIAL_PAIR_STUB, '').replace(POTENTIAL_ITEM_STUB, '');
