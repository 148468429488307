// @ts-ignore
import { EditAttributes, Download, Delete, Process } from '@aiware/shared/icons';
import { EPermissionAction } from '@aiware/shared/permissions';
import { useDispatch } from 'react-redux';
import { ENTITY_CONTEXT_MENU } from '../../../../../types/entity-context-menus';
import { EntityType } from '@aiware/js/interfaces';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { FILE_INFO_MENU_TEXT } from '../../helpers/shared-text';
import { SecureMenu, SecureMenuItem, SecureMenuItemDivider } from '../../../SecureMenu';

export const FileInfoHeaderMenu = ({
  entityId,
  onDownloadAsset,
  onEditAttributes,
  onMoveAsset,
  onDeleteAsset,
  onProcessAi,
  isError,
  isLoading,
  isBetaFeaturesEnabled,
}: {
  entityId: string;
  onDownloadAsset: () => void;
  onEditAttributes: () => void;
  onMoveAsset: () => void;
  onDeleteAsset: () => void;
  onProcessAi: () => void;
  isError: boolean;
  isLoading: boolean;
  isBetaFeaturesEnabled: boolean;
}) => {
  const tdoContextMenu = ENTITY_CONTEXT_MENU[EntityType.Tdos];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const isDisabled = isError || isLoading;
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMenuClick = (_event: React.MouseEvent<HTMLElement>, callback?: () => void) => {
    callback && callback();
    handleCloseMenu();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-labelledby="dc-file-info-panel-header-menu"
        data-testid="dc-file-info-panel-header-menu"
        size="medium"
      >
        <MoreVertIcon />
      </IconButton>

      <SecureMenu
        id="dc-file-info-panel-header-context-menu"
        entityId={entityId}
        anchorEl={anchorEl}
        anchorOriginX="left"
        anchorOriginY="bottom"
        transformOriginX="right"
        transformOriginY="top"
        disabled={isDisabled}
        onClose={handleCloseMenu}
      >
        <SecureMenuItem
          id={'dc-file-info-panel-header-menu-context-menu-option-edit-attributes'}
          text={FILE_INFO_MENU_TEXT.editAttributes}
          visible={false}
          permissionRequired={EPermissionAction.Update}
          Icon={EditAttributes}
          iconSize="small"
          action={e => handleMenuClick(e, onEditAttributes)}
        />
        <>
          {[...tdoContextMenu.actions]
            .filter(({ testLabel }) => testLabel !== 'view-file-info' && testLabel != 'process-tdo')
            .map(({ name, action, Icon, permissionRequired }, idx) => {
              return (
                <SecureMenuItem
                  key={idx}
                  text={name()}
                  Icon={Icon}
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    dispatch(action(entityId));
                    handleCloseMenu();
                    event.stopPropagation();
                  }}
                  id="dc-file-info-panel-header-menu-context-menu-option"
                  permissionRequired={permissionRequired}
                />
              );
            })}
        </>
        <SecureMenuItem
          id={'dc-file-info-panel-header-menu-context-menu-option-process-with-ai'}
          text={FILE_INFO_MENU_TEXT.process}
          permissionRequired={EPermissionAction.Update}
          Icon={Process}
          iconSize="small"
          action={e => handleMenuClick(e, onProcessAi)}
        />

        <SecureMenuItemDivider visible={false} />

        <SecureMenuItem
          id={'dc-file-info-panel-header-menu-context-menu-option-delete'}
          text={FILE_INFO_MENU_TEXT.delete}
          visible={false}
          permissionRequired={EPermissionAction.Delete}
          Icon={Delete}
          iconSize="small"
          action={e => handleMenuClick(e, onDeleteAsset)}
        />
      </SecureMenu>
    </>
  );
};
