import { all } from 'redux-saga/effects';
import { watchFetchRootFolder } from './locationFolder';
import { watchFileMetadataSuccess, watchSetFileMetadata } from './fileItemMeta';
import { watchRetryTDOCreation, watchTDOCreateStart, watchTdoCreateSuccess } from './common';
import {
  watchFileAbort,
  watchFilesSelected,
  watchProgressChannel,
  watchRetryFileUpload,
} from './localFileUpload';

export function* rootSaga() {
  yield all([
    watchFilesSelected(),
    watchProgressChannel(),
    watchRetryFileUpload(),
    watchFileAbort(),
    watchFetchRootFolder(),
    watchTDOCreateStart(),
    watchRetryTDOCreation(),
    watchTdoCreateSuccess(),
    watchSetFileMetadata(),
    watchFileMetadataSuccess(),
  ]);
}
