import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useInterval } from '@aiware/js/function';
import NullState from '../../../../molecules/AccountLinking/NullState';
import ErrorState from '../../../../molecules/AccountLinking/ErrorState';
import useStyles from './useStyles';
import OpenIdConnectors from './OpenIdConnectors';
import LoaderSkeleton from './LoaderSkeleton';
import { IOpenIdConnector } from '../../../../../types';
import SHARED_TEXT from '../../../../../helper/shared-text';
import * as actions from '../../../../../store/modules/openId/action';
import { selectConnectedOpenIds, selectLoadingStatus } from '../../../../../store/modules/openId/selector';

const AccountLinking = ({ openIdConnectors }: { openIdConnectors: IOpenIdConnector[] }) => {
  const dispatch = useDispatch();
  const [openUnlinkModal, setOpenUnlinkModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [delay, setDelay] = useState<number | null>(null);
  const initialState = {} as IOpenIdConnector;
  const [selectedOpenId, setSelectedOpenId] = useState<IOpenIdConnector>(initialState);
  const [linkingTab, setLinkingTab] = useState<Window | null>();
  const loadingStatus = useSelector(selectLoadingStatus) || '';
  const connectedOpenIds = useSelector(selectConnectedOpenIds) || [];

  const { classes } = useStyles();

  const handleOpenUnlinkModal = (openId: IOpenIdConnector) => {
    setSelectedOpenId(openId);
    setOpenUnlinkModal(true);
  };

  const handleCloseUnlinkModal = () => {
    setOpenUnlinkModal(false);
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleRetry = () => {
    dispatch(actions.getOpenIdConnectors());
  };

  useInterval(() => {
    dispatch(actions.getOpenIdConnectors());
  }, delay);

  const handleClickOpenIdUrl = (openId: IOpenIdConnector) => {
    setSelectedOpenId(openId);
    // open separate tab with identity provider
    const tab = window.open(openId.loginUrl);
    setLinkingTab(tab);
    // fetch openId connectors every two seconds
    setDelay(2000);
  };

  const isOpenIdConnected = useCallback(
    (id: string): boolean => {
      return connectedOpenIds.some(openId => {
        return openId.connectId === id;
      });
    },
    [connectedOpenIds]
  );

  useEffect(() => {
    // if there is no selected openId or no interval is set yet then return
    if (selectedOpenId.connectId === '' || delay === null) {
      return;
    }

    // see if this selected openId is connected. If it's then show a success message,
    // clear the interval and close the OAuth tab
    if (isOpenIdConnected(selectedOpenId.connectId)) {
      setDelay(null);
      linkingTab?.close();
      setOpenSuccessModal(true);
    }
  }, [isOpenIdConnected, dispatch]);

  const getContent = () => {
    if (loadingStatus === 'pending') {
      return <LoaderSkeleton />;
    } else if (loadingStatus === 'failure') {
      return <ErrorState onRetry={handleRetry} />;
    } else if (openIdConnectors.length === 0) {
      return <NullState />;
    }

    return (
      <OpenIdConnectors
        openIdItems={openIdConnectors}
        onOpenUnlinkModal={handleOpenUnlinkModal}
        onClickOpenIdUrl={handleClickOpenIdUrl}
        isOpenIdConnected={isOpenIdConnected}
      />
    );
  };

  return (
    <div>
      <Typography variant="body1" className={classes.accountLinkingLabel} data-test="account-linking-label">
        {SHARED_TEXT.accountLinkingLabel()}
      </Typography>
      <Typography variant="body2">{SHARED_TEXT.accountLinkingDescription()}</Typography>
      {getContent()}
      <Dialog
        open={openUnlinkModal}
        maxWidth="xs"
        onClose={handleCloseUnlinkModal}
        aria-labelledby="unlink-dialog-title"
        aria-describedby="unlink-dialog-description"
      >
        <DialogTitle
          id="unlink-dialog-title"
          data-test="account-linking-unlink-dialog-title"
          className={classes.dialogTitle}
        >
          <Typography variant="h2" className={classes.dialogTitleText}>
            {SHARED_TEXT.unlinkDialogTitle()}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="unlink-dialog-description">
            {SHARED_TEXT.unlinkDialogContent(selectedOpenId.name)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleCloseUnlinkModal}
            variant="text"
            size="large"
            className={classes.linkBtn}
            data-test="account-linking-unlink-dialog-cancel-btn"
          >
            {SHARED_TEXT.unlinkDialogCancel()}
          </Button>
          <Button
            onClick={handleCloseUnlinkModal}
            size="large"
            variant="contained"
            color="primary"
            className={classes.linkBtn}
            data-test="account-linking-unlink-dialog-confirm-btn"
          >
            {SHARED_TEXT.unlinkDialogConfirm()}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSuccessModal}
        maxWidth="xs"
        onClose={handleCloseSuccessModal}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle
          id="success-dialog-title"
          className={classes.dialogTitle}
          data-test="account-linking-success-dialog-title"
        >
          <Typography variant="h2" className={classes.dialogTitleText}>
            {SHARED_TEXT.successDialogTitle()}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="success-dialog-description">
            {SHARED_TEXT.successDialogContent(selectedOpenId.name)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleCloseSuccessModal}
            size="large"
            variant="contained"
            color="primary"
            className={classes.linkBtn}
            data-test="account-linking-success-dialog-confirm-btn"
          >
            {SHARED_TEXT.successDialogConfirm()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountLinking;
