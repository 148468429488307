import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import cx from 'classnames';
import { Fragment, MouseEvent } from 'react';

import { FormattedMessage } from 'react-intl';
import ColorButton from '../ColorButton';
import useStyles from './useStyles';

export interface ItemProps {
  srcImg: string;
  title?: string;
  onClick?: MouseEvent<HTMLElement>;
}

export interface ListItemsProps {
  items: Array<ItemProps>;
  isNav?: boolean;
}

function ListItems({ items, isNav = false }: ListItemsProps) {
  const { classes } = useStyles();

  return (
    <List component={isNav ? 'nav' : 'ul'} className={classes.root}>
      {items.map(({ srcImg, title }: ItemProps, index: number) => {
        const node = (
          <Fragment>
            {srcImg && (
              <ListItemAvatar>
                <Avatar
                  className={cx(classes.avatar, {
                    [classes.avatarSmall]: isNav,
                  })}
                  alt={title}
                  src={srcImg}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              className={classes.textItem}
              primary={title}
              secondaryTypographyProps={{
                component: 'div',
              }}
              secondary={
                !isNav ? (
                  <div className={classes.buttonWrapper}>
                    <ColorButton
                      className={classes.button}
                      variant="outlined"
                      size="small"
                      colorButton="primary"
                    >
                      <FormattedMessage
                        id="os-organization-panel.0vD7aG"
                        defaultMessage="Accept"
                        description="The label of accept button"
                      />
                    </ColorButton>
                    <ColorButton
                      className={classes.button}
                      variant="outlined"
                      size="small"
                      colorButton="secondary"
                    >
                      <FormattedMessage
                        id="os-organization-panel.9fESfq"
                        defaultMessage="Reject"
                        description="The label of reject button"
                      />
                    </ColorButton>
                  </div>
                ) : null
              }
            />
          </Fragment>
        );
        return (
          <Fragment key={title}>
            {isNav ? (
              <ListItem button key={title} className={classes.item}>
                {node}
              </ListItem>
            ) : (
              <ListItem key={title} className={classes.item}>
                {node}
              </ListItem>
            )}

            {index !== items.length - 1 && (
              <Divider className={classes.divider} variant="inset" component="li" />
            )}
          </Fragment>
        );
      })}
    </List>
  );
}

export default ListItems;
