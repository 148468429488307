import { Checkmark, MoreHor, NotInterested } from '@aiware/shared/icons';
import {
  editAppGroup,
  selectAppGroupsEditMode,
  deleteAppGroup,
  expandCollapse,
} from '../../../store/modules/app-groups';
import { IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './group-handler.styles';

type TProps = {
  dragHandleProps: any;
  groupName: string;
  groupId: string;
  isExpanded?: boolean;
};

export const GroupHandler = ({ dragHandleProps, groupName, groupId, isExpanded = true }: TProps) => {
  const editModeIsOn = useSelector(selectAppGroupsEditMode);
  const dispatch = useDispatch();
  const [isEdited, setIsEdited] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { classes } = useStyles();

  useEffect(() => {
    setNewGroupName(groupName);
  }, []);

  const startEdit = () => {
    dispatch(editAppGroup({ edit: true, groupId }));
    setIsEdited(true);
  };

  const handleClick = (e: any) => {
    if (e.detail === 2 && !editModeIsOn) {
      // handle double click
      startEdit();
    }
  };

  const handleChange = (ev: any) => {
    setNewGroupName(ev.target.value);
  };

  const handleCancel = () => {
    handleClose();
    dispatch(editAppGroup({ edit: false, groupId }));
    setIsEdited(false);
  };

  const handleUpdate = () => {
    handleClose();
    dispatch(editAppGroup({ edit: false, groupId, newGroupName }));
    setIsEdited(false);
  };

  const handleDeleteGroup = () => {
    dispatch(deleteAppGroup(groupId));
  };

  const handleExpandCollapse = () => {
    handleClose();
    // timeout is to beat mui's menu hide animation time
    setTimeout(() => {
      dispatch(expandCollapse(groupId));
    }, 100);
  };

  return (
    <div className={isEdited ? classes.root : classes.groupHandler}>
      {isEdited ? (
        <>
          <TextField
            value={newGroupName}
            onChange={handleChange}
            data-testid={`os-app-bar.app-group.${groupId}.name-input-container`}
            className={classes.groupNameInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Checkmark
                    className={classes.confirm}
                    onClick={handleUpdate}
                    data-testid={`os-app-bar.app-group.${groupId}.save-btn`}
                  />
                  <NotInterested
                    className={classes.notInterested}
                    onClick={handleCancel}
                    data-testid={`os-app-bar.app-group.${groupId}.cancel-btn`}
                  />
                </InputAdornment>
              ),
            }}
            inputProps={{ 'data-testid': `os-app-bar.app-group.${groupId}.name-input` }}
          />
          <div {...dragHandleProps}></div>
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            className={classes.groupTitle}
            {...dragHandleProps}
            onClick={handleClick}
            data-testid={`os-app-bar-panel.app-switcher.group-name.${groupId}`}
          >
            {groupName}
          </Typography>
          <IconButton
            onClick={openMenu}
            size="small"
            className={classes.handlerMenu}
            data-testid={`os-app-bar-panel.app-switcher.options-btn.${groupId}`}
          >
            <MoreHor fontSize="small" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ transform: 'translate(-95px, 0)' }}
          >
            <MenuItem
              onClick={handleExpandCollapse}
              data-testid={`os-app-bar-panel.app-switcher.options-toggle.${groupId}`}
            >
              {isExpanded ? (
                <FormattedMessage
                  id="os-app-bar-panel.app-group.menu.hide"
                  defaultMessage="Hide Group"
                  description="Hide Group dropdown menu item"
                />
              ) : (
                <FormattedMessage
                  id="os-app-bar-panel.app-group.menu.expand"
                  defaultMessage="Expand"
                  description="Expand dropdown menu item"
                />
              )}
            </MenuItem>
            <MenuItem
              onClick={startEdit}
              data-testid={`os-app-bar-panel.app-switcher.options-rename.${groupId}`}
            >
              <FormattedMessage
                id="os-app-bar-panel.app-group.menu.rename"
                defaultMessage="Rename"
                description="Rename dropdown menu item"
              />
            </MenuItem>
            <MenuItem
              onClick={handleDeleteGroup}
              data-testid={`os-app-bar-panel.app-switcher.options-delete.${groupId}`}
            >
              <FormattedMessage
                id="os-app-bar-panel.app-group.menu.delete"
                defaultMessage="Delete"
                description="Delete dropdown menu item"
              />
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};
