import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

import { selectLastColumn } from '../../../../state/aiSearch/aiSearch.selectors';

const SearchVirtualTableHeader = ({ scrollBarOffset }: { scrollBarOffset: boolean }) => {
  const lastColumn = useSelector(selectLastColumn);

  return (
    <>
      <Grid
        data-testid={'dc-search-results-table-header'}
        sx={{ height: 40, pl: 3 }}
        alignItems="center"
        display="flex"
        container
        style={scrollBarOffset ? { width: `calc(100% - 10px)` } : {}}
      >
        <Grid item xs={1}>
          <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
            <FormattedMessage
              id="os-dc-search-results-table-header-created.date"
              defaultMessage="Type"
              description="Data Center Search Results table header file type"
            />
          </Typography>
        </Grid>
        <Grid item xs={lastColumn.enabled ? 6 : 9}>
          <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
            <FormattedMessage
              id="os-dc-search-results-table-header-name"
              defaultMessage="Name"
              description="Data Center Search Results table header file name"
            />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
            <FormattedMessage
              id="os-dc-search-results-table-header-modified"
              defaultMessage="Date"
              description="Data Center Search Results table header modified date"
            />
          </Typography>
        </Grid>

        {lastColumn.enabled && (
          <Grid item xs={3}>
            <Typography variant="caption" color="textSecondary" sx={{ cursor: 'default' }}>
              <FormattedMessage
                id="os-dc-search-results-table-header-last-column"
                defaultMessage="{lastColumnName}"
                description="Data Center Search Results table header last column"
                values={{ lastColumnName: lastColumn.value }}
              />
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ opacity: 0.9 }} />
    </>
  );
};

export default SearchVirtualTableHeader;
