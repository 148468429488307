import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IContentTemplatesToSaveState,
  ICurrentSchemaListState,
  ISchemasState,
  ISchemas,
  SLICE_NAME,
} from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'contentTemplates';

export type State = {
  schemaStatus: LoadingStatus;
  currentSchemaList: ICurrentSchemaListState[];
  schemas: ISchemasState;
  contentTemplatesToSave: IContentTemplatesToSaveState;
};

export const initialState: State = {
  schemaStatus: 'pending',
  currentSchemaList: [],
  schemas: {},
  contentTemplatesToSave: {},
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchSchemaStart(state) {
      state.schemaStatus = 'pending';
    },
    fetchSchemaSuccess(
      state,
      action: PayloadAction<{ schemas: ISchemas; current: ICurrentSchemaListState[] }>
    ) {
      state.schemaStatus = 'success';
      state.schemas = action.payload.schemas;
      state.currentSchemaList = action.payload.current;
    },
    fetchSchemaFailed(state) {
      state.schemaStatus = 'failure';
    },
    setContentTemplatesToSave(state, action: PayloadAction<IContentTemplatesToSaveState>) {
      state.contentTemplatesToSave = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
