import { Stack, Typography, Box, Grid, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AlarmTwo } from '@aiware/shared/icons';

export type TTableErrorStateProps = {
  ErrorStateComponent?: JSX.Element;
  CustomMessage?: JSX.Element | string;
  CustomDescription?: JSX.Element | string;
  onRetry?: () => void;
  hideRetryButton?: boolean;
};

export const TableErrorState: React.FC<TTableErrorStateProps> = ({
  ErrorStateComponent,
  CustomMessage,
  CustomDescription,
  onRetry = () => {
    /* ts-ignore */
  },
  hideRetryButton = false,
}) => {
  const style = {
    overflow: 'hidden',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    marginTop: '80px',
  };

  if (ErrorStateComponent) {
    return <>{ErrorStateComponent}</>;
  }

  const ErrorState = () => {
    const message = CustomMessage ? (
      <>{CustomMessage}</>
    ) : (
      <FormattedMessage
        id="shared-utils.table-infinite-scroll.error-state-message"
        defaultMessage="Error Loading Items"
        description="Table error state, unable to load items message"
      />
    );

    return (
      <Stack
        spacing={2}
        display={'flex'}
        alignItems={'center'}
        data-testid={'shared-reusable-utils-error-state'}
      >
        <AlarmTwo fontSize="large" />
        <Typography fontWeight={'strong'} data-testid={'shared-reusable-utils-error-state-message'}>
          {message}
        </Typography>
        {CustomDescription && (
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'caption'}
            data-testid={'shared-reusable-utils-error-state-description'}
          >
            {CustomDescription}
          </Typography>
        )}
        {!hideRetryButton && (
          <Button
            onClick={onRetry}
            variant={'contained'}
            data-testid={'shared-reusable-utils-error-state-retry-button'}
          >
            <FormattedMessage
              id="shared-utils.table-infinite-scroll.error-state-button"
              defaultMessage="Retry"
              description="Table error state, retry button"
            />
          </Button>
        )}
      </Stack>
    );
  };

  return (
    <Grid sx={{ position: 'relative', height: '99%', overflow: 'hidden' }}>
      <Box sx={{ ...style }}>
        <ErrorState />
      </Box>
    </Grid>
  );
};
