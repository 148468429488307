import { CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { IMyOrganization, organizationSelectors } from '@aiware/os/organization-panel';
import { useSelector } from 'react-redux';
import { OrgCard } from './org-card';
import { useMemo } from 'react';

const checkOrgName = (org: IMyOrganization, searchValue: string) => {
  const name = (org?.name ?? 'N/A').toLowerCase();
  return name.includes(searchValue.toLowerCase());
};

export const OrgList = ({ searchValue = '', onOrgChange }) => {
  const uiStatus = useSelector(organizationSelectors.selectUIStatus);
  const isLoading = uiStatus === 'pending';

  const myOrganizations: IMyOrganization[] = useSelector(organizationSelectors.selectMyOrganizations);

  const filteredOrgs = useMemo(
    () => myOrganizations.filter(org => checkOrgName(org, searchValue)),
    [myOrganizations, searchValue]
  );

  return (
    <Stack sx={{ height: '100%', overflow: 'auto' }}>
      {isLoading && (
        <Stack
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <Stack
          sx={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'flex-start',
            justifyContent: filteredOrgs.length > 10 ? 'flex-start' : 'center',
            padding: '10px',
            paddingTop: '30px',
            overflow: 'auto',
          }}
        >
          {filteredOrgs.map((org, index) => (
            <Stack key={org.guid} sx={{ paddingTop: '10px' }}>
              <OrgCard onClick={() => onOrgChange(org)} org={org} />
            </Stack>
          ))}
          {filteredOrgs.length === 0 && (
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Typography>No organizations found</Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
/*
organizationPanel/organizationsState/switchUserToOrganizationStart
 */
