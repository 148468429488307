import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useStyles } from '../../useStyles';
import { selectIsFluffy } from '../../../../redux/selectors/ui-state';
import { useSelector } from 'react-redux';
import GetRowItemHeader from '../../../shared/GetRowItemHeader';
import { EntityType } from '@aiware/js/interfaces';
import { SHARED_TEXT } from '../../../../helpers/shared-text';

const TdoTableHeader: FunctionComponent = () => {
  const { classes } = useStyles();
  const isFluffy = useSelector(selectIsFluffy);
  return (
    <>
      {isFluffy ? (
        <Grid
          data-test={'tdos-table-header-fluffy'}
          className={classes.header}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          container
        >
          <Grid item xs={11}>
            <Typography data-test={`tdos-th-item-fluffy`} variant="caption" color="textSecondary">
              {SHARED_TEXT.tableHeaderTdos()}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.root}>
          <Grid
            data-test={'tdos-table-header'}
            className={classes.header}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            container
            item
            xs={11}
          >
            <GetRowItemHeader item={'fileType'} xs={1} itemType={EntityType.Tdos} />
            <GetRowItemHeader item={'name'} itemType={EntityType.Tdos} />
            <GetRowItemHeader item={'mediaStartTime'} xs={3} itemType={EntityType.Tdos} />
            <GetRowItemHeader item={'engineIds'} xs={3} itemType={EntityType.Tdos} />
          </Grid>
        </Grid>
      )}
      <Divider className={classes.divider} />
    </>
  );
};

export default TdoTableHeader;
