import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme: Theme) => ({
  personalProfileContainer: {
    // height: 'calc(100vh - 55px - 62px)',
    overflowY: 'auto',
  },
  firstBox: {
    paddingTop: theme.spacing(6),
    textAlign: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },
  loggedBox: {
    fontWeight: 400,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
  email: {
    color: theme.palette.primary.main,
  },
  logoutBox: {
    paddingBottom: theme.spacing(8),
  },
  btnLogin: {
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '19px',
    padding: theme.spacing(1, 7),
  },
  secondBox: {
    padding: theme.spacing(5, 6),
    '& > div': {
      height: 'auto !important',
    },
  },
  headerBox: {
    display: 'flex',
  },
  editIconBox: {
    margin: theme.spacing(0, -2.4, 0, 0),
  },
  descBox: {
    fontSize: '14px',
    color: theme.palette.secondary.main,
  },
  formBox: {
    marginTop: theme.spacing(4),
  },
  formItem: {
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(4),
  },
  formItemPw: {
    marginBottom: `${theme.spacing(4)} !important`,
  },
  titleItem: {
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
    cursor: 'default',
  },
  valueItem: {
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  disabledText: {
    opacity: 0.6,
  },
  lastUpdate: {
    color: '#9CA8B4',
  },
  reset: {
    color: theme.palette.primary.main,
    opacity: 0.9,
    marginTop: '35px',
    cursor: 'pointer',
  },
  passwordBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconResetPw: {
    margin: '-18px -12px 0 0',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '30px',
    width: '100%',
  },
  actionForm: {
    padding: 0,
    marginBottom: theme.spacing(4),
  },
  inputBase: {
    padding: theme.spacing(3.4, 2.8),
  },
  notchedOutline: {
    '& legend': {
      height: '16px',
    },
  },
  btnAction: {
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '19px',
  },
  btnSave: {
    marginLeft: theme.spacing(1.6),
  },
  btnActionCancel: {
    color: theme.palette.secondary.main,
  },
  pwResetTitle: {
    color: '#D43060 !important',
  },
  pwResetDes1: {
    marginBottom: '20px',
  },

  mainTitleText: {
    color: theme.palette.text.secondary,
    userSelect: 'none',
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  contentUserView: {
    height: '100%',
    textAlign: 'left',
    position: 'relative',
  },
  headerDrawer: {
    padding: theme.spacing(3, 6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },
  contentEditAvatar: {
    padding: theme.spacing(6),
  },
  areaUpload: {
    background: theme.palette.background.default,
    textAlign: 'center',
    height: '300px',
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: theme.spacing(10),
  },
  descDrag: {
    fontSize: '16px',
    color: '#828282',
    marginBottom: theme.spacing(4),
  },
  descDrag2: {
    color: '#828282',
    margin: theme.spacing(4, 0),
  },
  btnSelectPhoto: {
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '19px',
  },
  recentlyBox: {
    marginTop: theme.spacing(4),
  },
  recentlyText: {
    margin: theme.spacing(2, 0),
  },
  imgRecently: {
    width: '113px',
    height: '113px',
    background: '#C4C4C4',
    borderRadius: '4px',
  },
  imgRecentlyRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  resetBiometric: {
    marginTop: theme.spacing(7),
  },
  actionButton: {},
  inputFile: {
    display: 'none !important',
  },
  imageGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  dragging: {
    filter: 'blur(2px)',
  },
  currentlyLoggedIn: {
    fontWeight: 'normal',
    color: theme.palette.info.main,
    lineHeight: theme.spacing(3.8),
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
  },
  manageBasicInfoLabel: {
    fontWeight: 'normal',
    cursor: 'default',
  },
  tabsParent: {
    '& div': {
      padding: theme.spacing(0, 0, 0, 1.6),
      borderBottom: 'none',
    },
    '& .Sdk-MuiTab-root.Sdk-Mui-selected': {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  },
  editProfilePictureContainer: {
    height: 'calc(100vh - 60px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  editProfilePictureFooterButtons: {
    right: `30px`,
    bottom: `30px`,
    position: `absolute`,
  },
}));
