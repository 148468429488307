import { takeLatest } from 'redux-saga/effects';
import { fetchTdo, saveMetadata } from './edit-metadata.saga';
import { actions } from '../slices';

export function* watchEditMetadataFetchTdo() {
  yield takeLatest(actions.editMetadata.fetchTdoStart.type, fetchTdo);
}

export function* watchEditMetadataSave() {
  yield takeLatest(actions.editMetadata.saveMetadataStart.type, saveMetadata);
}
