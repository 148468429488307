import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Fullscreen, FullscreenExit } from '@aiware/shared/icons';

export const SHARED_TEXT = {
  closePanel: () => (
    <FormattedMessage
      id="reusable-utils.panel-components.header.close-panel"
      defaultMessage="Close"
      description="Close panel icon for a panel header"
    />
  ),
  enterFullScreen: () => (
    <FormattedMessage
      id="reusable-utils.panel-components.header.enter-fullscreen"
      defaultMessage="Enter Full Screen"
      description="Enter Full Screen panel icon for a panel header"
    />
  ),
  exitFullScreen: () => (
    <FormattedMessage
      id="reusable-utils.panel-components.header.leave-fullscreen"
      defaultMessage="Exit Full Screen"
      description="Exit Full Screen panel icon for a panel header"
    />
  ),
};

export const PANEL_FULLSCREEN_BUTTON_ID = {
  ENTER: 'sdk-ui-toggle-fullscreen-enter',
  EXIT: 'sdk-ui-toggle-fullscreen-exit',
};

const cssRuleEnterFullScreen = {
  [`& .${PANEL_FULLSCREEN_BUTTON_ID.EXIT}`]: {
    display: 'none',
  },
};

const cssRuleExitFullScreen = {
  [`& .${PANEL_FULLSCREEN_BUTTON_ID.ENTER}`]: {
    display: 'none',
  },
};

export const getFullScreenBtnStyles = (enabled: boolean | undefined, isFullScreen: boolean | undefined) => {
  if (!enabled) return {};
  if (isFullScreen) return cssRuleExitFullScreen;
  return cssRuleEnterFullScreen;
};

export const toggleFullScreen = (panelId: string, isFullScreen: boolean) => {
  const panelElement = document.querySelector(`#${panelId}-root #${panelId}`);
  if (!panelElement) return;

  const defaultStyles = `
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;`;

  const fullScreenContainerStyles = `
    top: 0;
    height: 100vh;
    width: 100vw;
    right: 0;
`;

  try {
    if (isFullScreen) {
      panelElement.setAttribute('style', defaultStyles + fullScreenContainerStyles);
    } else {
      panelElement.setAttribute('style', defaultStyles);
    }
  } catch (e) {
    // TS Ignore
  }
};

export type TFullScreenButtonProps = {
  onClick: () => void;
  testId?: string;
};

export const EnterFullScreenButton: React.FC<TFullScreenButtonProps> = ({ onClick, testId = '' }) => (
  <>
    <Typography
      style={visuallyHidden}
      className={PANEL_FULLSCREEN_BUTTON_ID.ENTER}
      id={`panel-header-enter-fullscreen-${testId}`}
    >
      {SHARED_TEXT.enterFullScreen()}
    </Typography>
    <Tooltip title={SHARED_TEXT.enterFullScreen()}>
      <IconButton
        className={PANEL_FULLSCREEN_BUTTON_ID.ENTER}
        onClick={onClick}
        aria-labelledby={`panel-header-enter-fullscreen-${testId}`}
        data-testid={`panel-header-enter-fullscreen-${testId}`}
        size="medium"
      >
        <Fullscreen />
      </IconButton>
    </Tooltip>
  </>
);

export const ExitFullScreenButton: React.FC<TFullScreenButtonProps> = ({ onClick, testId = '' }) => (
  <>
    <Typography
      style={visuallyHidden}
      className={PANEL_FULLSCREEN_BUTTON_ID.EXIT}
      id={`panel-header-exit-fullscreen-${testId}`}
    >
      {SHARED_TEXT.exitFullScreen()}
    </Typography>
    <Tooltip title={SHARED_TEXT.exitFullScreen()}>
      <IconButton
        className={PANEL_FULLSCREEN_BUTTON_ID.EXIT}
        onClick={onClick}
        aria-labelledby={`panel-header-exit-fullscreen-${testId}`}
        data-testid={`panel-header-exit-fullscreen-${testId}`}
        size="medium"
      >
        <FullscreenExit />
      </IconButton>
    </Tooltip>
  </>
);
