import { FormField, JSONSchema, NumberField, SchemaField, SelectField, TextField } from '../types';

export const convertJsonSchemaToFormFields = (schema: JSONSchema): FormField[] => {
  if (!schema.properties) return [];

  return Object.entries(schema.properties).map(([key, value]) => ({
    id: key,
    type: value.type as FormField['type'],
    label: value.title,
    required: schema.required?.includes(key) || false,
    options: value.type === 'select' ? value.enum : undefined,
    min: value.type === 'number' ? value.min : undefined,
    max: value.type === 'number' ? value.max : undefined,
  }));
};

export const convertFormFieldsToJsonSchema = (fields: FormField[]): JSONSchema => {
  const properties: JSONSchema['properties'] = {};

  fields.forEach((field, idx) => {
    properties[field.id] = {
      type: field.type,
      title: field.label,
      sequence: idx + 1,
    } as SchemaField;

    if (field.type === 'select' && field.options) {
      (properties[field.id] as SelectField).enum = field.options;
    } else if (field.type === 'number' && (field.min || field.max)) {
      if (field.min) (properties[field.id] as NumberField).min = field.min;
      if (field.max) (properties[field.id] as NumberField).max = field.max;
    } else if (field.type === 'text' && field.placeholder) {
      (properties[field.id] as TextField).placeholder = field.placeholder;
    }
  });

  return {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties,
    required: fields.filter(field => field.required).map(field => field.id),
  };
};
