import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { LIMIT, GraphQLPage, IFolder, FolderId } from '../types';
import { selectApiConfigs } from '../redux/selectors';

export async function getOrgCmsRootFolder(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'getOrgCmsRootFolder';
  const name = 'rootFolders';
  const query = `
    query ${operationName} {
      ${name}(type: cms) {
        id
        name
        modifiedDateTime
        treeObjectId
        ownerId
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: IFolder[];
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  const orgRootFolder = result[name]?.find(folder => !folder.ownerId);

  return orgRootFolder;
}

export async function createOrgCmsRootFolder(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'createOrgCmsRootFolder';
  const name = 'createRootFolders';
  const query = `
    mutation ${operationName} {
      ${name}(rootFolderType: cms) {
        id
        name
        modifiedDateTime
        treeObjectId
        ownerId
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: IFolder[];
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  const orgRootFolder = result[name]?.find(folder => !folder.ownerId);

  return orgRootFolder;
}

export async function getChildFoldersByParentFolderId(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset = 0,
  parentFolderId: FolderId
) {
  const operationName = 'getChildFoldersByParentFolderId';
  const name = 'folder';
  const property = 'childFolders';
  const query = `
    query ${operationName} ($id: ID!, $limit: Int, $offset: Int) {
      ${name}(id: $id) {
        ${property}(limit: $limit, offset: $offset, orderBy: { field: name, direction: asc }) {
          count
          records {
            id
            name
            modifiedDateTime
            treeObjectId
          }
        }
      }
    }
  `;
  const variables = {
    id: parentFolderId,
    limit: LIMIT,
    offset,
  };
  const result = await baseGraphQLApiWithError<{
    [name]: {
      [property]: GraphQLPage<IFolder>;
    };
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  result[name][property].records = result[name][property].records.map(folder => ({
    ...folder,
    parentFolderId,
  }));

  return result[name]?.[property];
}

export async function createCmsFolder(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  payload: {
    parentFolderId: FolderId;
    parentTreeObjectId: string;
    name: string;
  }
) {
  const operationName = 'createTdoFolder';
  const name = 'createFolder';
  const query = `
    mutation ${operationName} ($parentId: ID!, $name: String!){
      ${name}(input: {
        name: $name,
        description: "",
        parentId: $parentId,
        rootFolderType: cms
      }) {
        id
        treeObjectId
        name
        modifiedDateTime
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: IFolder;
  }>({
    query,
    operationName,
    variables: {
      parentId: payload.parentTreeObjectId,
      name: payload.name,
    },
    ...apiConfigs,
  });

  return {
    ...result[name],
    parentFolderId: payload.parentFolderId,
  };
}

export async function updateCmsFolder(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  payload: {
    id: FolderId;
    name: string;
  }
) {
  const operationName = 'updateCmsFolder';
  const name = 'updateFolder';
  const query = `
    mutation ${operationName} ($input: UpdateFolder){
      ${name}(input: $input) {
        id
        treeObjectId
        name
        modifiedDateTime
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: IFolder;
  }>({
    query,
    operationName,
    variables: {
      input: payload,
    },
    ...apiConfigs,
  });

  return {
    ...result[name],
  };
}

export async function getFolder(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  id: string
): Promise<IFolder> {
  const operationName = 'getFolder';

  const query = `
      query ${operationName}($id: ID!){
        folder(
          id: $id,
          ) {
          id
          name
        }
      }
    `;
  const variables = {
    id: id,
  };
  const result = await baseGraphQLApiWithError<{
    folder: IFolder;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result.folder;
}
