import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  dateRangeDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
    border: '1px solid #D5DFE9',
    borderRadius: '5px',
    padding: '0px 7px',
    boxSizing: 'border-box',
    minWidth: '120px',
    minHeight: '28px',
    cursor: 'pointer',
  },
  menuItem: {
    marginLeft: '25px',
  },
  menuItemEngineFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  subheader: {
    fontWeight: 'bold',
  },
  subheaderEngineFilter: { paddingTop: '5px', fontWeight: 'bold' },
  tooltipList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  formControl: {
    marginRight: '12px',
  },
  label: {
    fontWeight: 700,
    fontSize: 14,
    color: '#2A323C',
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectField: {
    width: 150,
    height: 28,
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  title: {
    textTransform: 'capitalize',
  },
  clearButton: {
    color: '#0C53B0',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '14px',
  },
  field: {
    width: 150,
  },
  clearBtn: { color: '#0C53B0', cursor: 'pointer', fontWeight: 400 },
  dot: {
    width: '8px',
    height: '8px',
    backgroundColor: _theme.palette.success.main,
    borderRadius: '50%',
    marginLeft: _theme.spacing(1),
    marginRight: _theme.spacing(3),
  },
  menuPaper: {
    maxHeight: '280px', // Set height for the dropdown
  },
}));
