import { FunctionComponent, useState } from 'react';
import { defaultVeritoneImage } from '@aiware/shared/assets';
import { Skeleton } from '@mui/material';

const ThumbnailHelper: FunctionComponent<{
  image: string | undefined;
  className: string;
}> = ({ image, className }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <img
        data-test={'thumbnailImage'}
        className={className}
        style={{ display: loading ? 'flex' : 'none' }}
        alt={'thumbnail'}
        src={image ? image : defaultVeritoneImage}
        onLoad={() => setLoading(true)}
        onError={e => {
          e.currentTarget.onerror = null;
          e.currentTarget.src = `${defaultVeritoneImage}`;
        }}
      />
      {!loading && (
        <Skeleton
          data-test={'thumbnail-skeleton'}
          className={className}
          variant="rectangular"
          animation="wave"
          width={115}
          height={70}
        />
      )}
    </>
  );
};

export default ThumbnailHelper;
