import { Stack, Paper, CircularProgress, Typography } from '@mui/material';
import { OrgAvatar } from './org-avatar';

export const OrgHeader = ({ organization }) => {
  if (!organization) {
    return (
      <Stack
        sx={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
          }}
        >
          <CircularProgress />
        </Paper>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: '30px',
        gap: '10px',
      }}
    >
      <OrgAvatar org={organization} />
      <Typography variant="h1">{organization?.organizationName ?? '--'}</Typography>
      <Typography variant={'caption'} sx={{ opacity: 0.5, fontStyle: 'italic' }}>
        Current Organization
      </Typography>
    </Stack>
  );
};
