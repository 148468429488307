export enum EApplicationConfigLevel {
  Organization = 'Organization',
  User = 'User',
}

export enum EApplicationConfigType {
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  Integer = 'Integer',
  JSON = 'JSON',
  Float = 'Float',
}

export type TOrgApplication = {
  id: string;
  name: string;
  iconUrl?: string;
  signedIconUrl?: string;
  description: string;
};

export enum EUserTableContextActions {
  showDeleted = 'showDeleted',
}
