import { useStyles } from '../../sourceScheduleFilter.styles';
import { Search } from '@mui/icons-material';

export const SourceSearchInput = ({ onChange, value }: { onChange: any; value: string }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.searchContainer}>
      <Search />
      <input
        className={classes.searchInput}
        onChange={onChange}
        value={value}
        placeholder={'Search Source name..'}
      />
    </div>
  );
};
