import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOLPEnabled } from '@aiware/shared/redux';
import { FolderFilled, SpecialFolder } from '@aiware/shared/icons';
import { useDoubleClick } from '@aiware/js/function';
import { EntityType } from '@aiware/js/interfaces';
import { useStyles } from '../useStyles';
import { actions } from '../../../redux/slices';
import { EntityContextMenu, IFolder } from '../../../types';
import ContextMenuRowButton from '../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../types/entity-context-menus';
import GetRowItem from '../../shared/GetRowItem';
import { selectCurrentView, selectPanelMode } from '../../../redux/selectors/ui-state';
import { Skeleton, Tooltip, Grid, Icon, Box } from '@mui/material';
import { SHARED_TEXT as DC_TEXT } from '../../../helpers/shared-text';
import {
  selectors,
  actions as permissionsActions,
  EAuthResourceType,
  TPermissionsEntity,
} from '@aiware/shared/permissions';
import { selectSelectedItems } from '../../../redux/selectors/view-my-files';

const panelModeContextMenu: EntityContextMenu = {
  actions: [
    {
      Icon: SpecialFolder,
      name: () => (
        <FormattedMessage
          id="os-data-center-browse.O2l1lh"
          defaultMessage="Select"
          description="Choose this folder"
        />
      ),
      testLabel: 'select-folder',
      description: 'Select Folder',
      action: actions.viewMyFiles.panelResultSelected,
    },
  ],
};

const FoldersTableRow: FunctionComponent<{
  item: IFolder;
  rowHeight: number;
  dataCenterView?: boolean;
}> = ({ item, rowHeight, dataCenterView }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentView = useSelector(selectCurrentView);
  const panelMode = useSelector(selectPanelMode);
  const isOLPEnabled = useSelector(selectIsOLPEnabled);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const selectedItems = useSelector(selectSelectedItems);
  const isSelectingFiles = selectedItems.length > 0;

  const tdoPermissionsInFolder: TPermissionsEntity = useSelector(
    selectors.selectPermissionsByEntity(EAuthResourceType.Folder, item.id, EAuthResourceType.Folder)
  );

  // Move TDO Panel check for permissions.
  useEffect(() => {
    if (!panelMode) return;
    if (!isOLPEnabled) {
      setIsPermissionsLoading(false);
      return;
    }

    // OLP is enabled, and we have the permissions, this checks that
    // user has FILE permissions inside that folder.
    if (tdoPermissionsInFolder && tdoPermissionsInFolder.status === 'success') {
      setIsPermissionsLoading(false);

      if (!tdoPermissionsInFolder?.permissions?.file) {
        setIsReadOnly(true);
        return;
      }

      return;
    }

    // Permissions initially are undefined, so we kick off the
    // fetch of the permissions for this folder.
    if (
      !tdoPermissionsInFolder ||
      tdoPermissionsInFolder.status === 'idle' ||
      tdoPermissionsInFolder.status === 'failure'
    ) {
      setIsPermissionsLoading(true);
      dispatch(
        permissionsActions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.Folder,
          entityId: item.id,
        })
      );
    }
  }, [isOLPEnabled, tdoPermissionsInFolder]);

  const triggerClick = useDoubleClick((isDoubleClick: boolean) => {
    if (isOLPEnabled && panelMode && isPermissionsLoading) return;
    if (isOLPEnabled && panelMode && isReadOnly) return;

    if (isDoubleClick) {
      dispatch(
        actions.viewMyFiles.addToMyFilesBreadCrumbs({
          breadcrumb: {
            entity: EntityType.Folders,
            id: item.id,
          },
          viewType: currentView,
        })
      );
      dispatch(actions.viewMyFiles.setSelectedFolder(item.id));
    } else {
      dispatch(actions.viewMyFiles.setSelectedFolder(item.id));
      dispatch(actions.viewMyFiles.toggleFileOrFolderSelected(item.id));
    }
  });

  const folderContextMenu = panelMode ? panelModeContextMenu : ENTITY_CONTEXT_MENU[EntityType.Folders];

  const tooltipTitle = () => {
    if (!isOLPEnabled || !panelMode || !isReadOnly) return '';
    return <>{DC_TEXT.noPermissionsTooltip()}</>;
  };

  return (
    <Tooltip title={tooltipTitle()}>
      <Grid
        data-test={`${EntityType.Folders}-folder-row-${item.id}`}
        style={{ height: rowHeight, position: 'relative' }}
        container
        className={classes.root}
        sx={panelMode && isOLPEnabled && isReadOnly ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
      >
        {isOLPEnabled && panelMode && isPermissionsLoading && (
          <div className={classes.rowLoading}>
            <Skeleton variant="rectangular" animation="wave" width={'100%'} height={'100%'} />
          </div>
        )}
        <Grid
          onClick={triggerClick}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          item
          container
          xs={11}
          className={classes.unselectable}
        >
          <Box className={'folder-table-header-whitespace'} width={'40px'}></Box>
          <Grid data-test={'item-folderIcon'} className={classes.type} item xs={1}>
            <Icon className={classes.sourceIcon} component={FolderFilled} color="primary" />
          </Grid>
          <GetRowItem rowName={'name'} item={item} itemType={EntityType.Folders} />
          {(!panelMode || dataCenterView) && (
            <>
              <GetRowItem rowName={'modifiedDateTime'} item={item} xs={3} itemType={EntityType.Folders} />
              <GetRowItem rowName={'engineIds'} item={item} xs={3} itemType={EntityType.Folders} />
            </>
          )}
        </Grid>
        <Grid xs={1} className={classes.padding} item container alignItems="center" justifyContent="flex-end">
          <ContextMenuRowButton
            disabled={isSelectingFiles}
            contextMenu={folderContextMenu}
            entityId={item.id}
            type={EAuthResourceType.Folder}
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
};
export default FoldersTableRow;
