import { useState, useEffect, FunctionComponent, ReactNode } from 'react';
import { isEmpty, forEach, isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { veritoneLogo } from '@aiware/shared/assets';
import { Divider } from '@mui/material';

interface UnknownSession {
  [key: string]: unknown;
}

interface Props {
  sessionKey: string;
  onResumeSession?: (session: UnknownSession | null) => void;
  entityId?: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 8,
  borderRadius: '10px',
};

const ResumeSessionModal: FunctionComponent<Props> = ({ sessionKey, onResumeSession, entityId }: Props) => {
  const [open, setOpen] = useState(false);
  const [availableSession, setAvailableSession] = useState<UnknownSession | null>(null);

  useEffect(() => {
    const sessionFromStorage = JSON.parse(localStorage.getItem(sessionKey) || '{}');
    if (!isEmpty(sessionFromStorage) && entityId === sessionFromStorage.id) {
      setAvailableSession(sessionFromStorage);
      setOpen(true);
    }
  }, []);

  const handleResumeSession = () => {
    onResumeSession?.(availableSession);
    setOpen(false);
  };

  const handleClearSession = () => {
    localStorage.removeItem(sessionKey);
    setAvailableSession(null);
    setOpen(false);
  };

  const getSignedUriPath = (entity: UnknownSession | null): string | null => {
    if (isEmpty(entity)) return null;
    const uriFields = ['signedIconPath', 'signedLogoPath', 'iconPath', 'logoPath'];
    let uri = null;

    forEach(uriFields, field => {
      if (!isNil(entity[field])) {
        uri = entity[field];
        return false;
      }
    });
    return uri;
  };

  return (
    <Modal
      data-test="shared-components.resume-session-modal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClearSession}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h3" component="h2" sx={{ fontSize: 22, mb: 4 }}>
            <FormattedMessage
              id="shared-components.resume-session-modal.title"
              defaultMessage="Previous Session Found"
              description="Resume Previous session modal title"
            />
          </Typography>
          {isEmpty(availableSession?.name) ? (
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <FormattedMessage
                id="shared-components.resume-session-modal.description"
                defaultMessage="A previous session was found. Would you like to resume it?"
                description="Resume session message"
              />
            </Typography>
          ) : (
            <>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <FormattedMessage
                  id="shared-components.resume-session-modal.title-2"
                  defaultMessage="A previous session was found for the entity below. Would you like to resume it?"
                  description="Resume session modal title"
                />
              </Typography>
              <Card
                sx={{ display: 'flex', my: 8, mx: 1, alignItems: 'center', gap: 3, maxHeight: '100px' }}
                data-test="shared-components.resume-session-modal.entity-card"
              >
                <img
                  src={getSignedUriPath(availableSession) || veritoneLogo}
                  alt={`${availableSession?.name} logo`}
                  width="100px"
                />
                <Typography variant="h3">{availableSession?.name as ReactNode}</Typography>
              </Card>
            </>
          )}

          <Divider />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '20px',
              padding: '10px',
            }}
          >
            <Button
              onClick={handleResumeSession}
              variant="contained"
              data-test="shared-components.resume-session-modal.resume-button"
            >
              <FormattedMessage
                id="shared-components.resume-session-modal.resume-button"
                defaultMessage="Resume Session"
                description="Resume session resume button"
              />
            </Button>
            <Button
              onClick={handleClearSession}
              variant="outlined"
              data-test="shared-components.resume-session-modal.clear-button"
            >
              <FormattedMessage
                id="shared-components.resume-session-modal.clear-button"
                defaultMessage="Clear Session"
                description="Resume session modal clear button"
              />
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ResumeSessionModal;
