import { put, select, call, all } from 'redux-saga/effects';
import { showMessage, MessageSeverity, preferredLanguageSelector, userSelector } from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { hidePanel, panelsSelector } from '@aiware/js/panel';
import { myOrganizationsApis, organizationInviteApi } from '../../../api';
import {
  EOrganizationInviteAction,
  TOrganizationInvite,
  TUpdateOrganizationInviteInput,
  TOrgInviteeInput,
  TInvitee,
} from '../../../types';
import type { IUser } from '@aiware/js/interfaces';
import { selectApiConfigs, organizationInvitesSelectors } from '../../selectors';
import { actions } from '../../slices';
import { EUserStatus } from '../../../lib/invite-panel/user-lookup';
import isEqual from 'lodash/isEqual';

export function* createOrganizationInvitesSaga(
  action: ReturnType<typeof actions.organizationInvitesState.createOrganizationInviteStart>
): Generator {
  const locale = (yield select(preferredLanguageSelector)) as ReturnType<typeof preferredLanguageSelector>;
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const result = (yield organizationInviteApi.createOrganizationInvite(
      apiConfigs,
      action.payload
    )) as Awaited<ReturnType<typeof organizationInviteApi.createOrganizationInvite>> as any;

    if (result?.errors?.length) {
      // Todo: Identify pending invites by error code
      const pendingInvitationError = result.errors.some((error: any) =>
        error.message?.match(/has already been invited/)
      );
      if (pendingInvitationError) {
        yield put(
          showMessage({
            content: isEqual(process.env.NODE_ENV, 'development')
              ? 'This email already has a pending invitation, and must be approved or rejected before a new invite can be sent'
              : formatMessage({
                  id: 'os-organization-panel.snackbar.error.create-organization-pending-invite',
                  defaultMessage:
                    'This email already has a pending invitation, and must be approved or rejected before a new invite can be sent',
                  description: 'Warning message when invite creation fails because of a pending invite',
                }),
            severity: MessageSeverity.Error,
          })
        );
      }
      yield put(actions.organizationInvitesState.createOrganizationInviteFailed());
      return;
    }

    yield put(actions.organizationInvitesState.createOrganizationInviteSucceed());
    yield put(
      showMessage({
        content: isEqual(process.env.NODE_ENV, 'development')
          ? 'Your Invitation has been successfully sent'
          : formatMessage({
              id: 'os-organization-panel.snackbar.success.create-organization-pending-invite',
              defaultMessage: 'Your Invitation has been successfully sent',
              description: 'Confirmation message when invite creation is successful',
            }),
        severity: MessageSeverity.Success,
      })
    );

    yield put(actions.organizationsState.clearInvitees());
  } catch (e) {
    yield put(actions.organizationInvitesState.createOrganizationInviteFailed());

    // eslint-disable-next-line
    if ((e as Error).message?.includes(`null value in column \"user_id\"`)) {
      yield put(
        showMessage({
          content: isEqual(process.env.NODE_ENV, 'development')
            ? "You may only invite current users of the aiWARE platform to your Organization'"
            : formatMessage({
                id: 'os-organization-panel.snackbar.warning.invite-new-user-failed',
                defaultMessage:
                  'You may only invite current users of the aiWARE platform to your Organization',
                description: 'Warning message when invite creation for a new user fails',
              }),
          severity: MessageSeverity.Error,
        })
      );
    } else {
      yield put(
        showMessage({
          content: isEqual(process.env.NODE_ENV, 'development')
            ? 'There was a problem creating your invitation'
            : formatMessage({
                id: 'os-organization-panel.snackbar.warning.create-organization-invite-failed',
                defaultMessage: 'There was a problem creating your invitation',
                description: 'Warning message when invite creation fails',
              }),
          severity: MessageSeverity.Error,
        })
      );
    }
  }
}

export function* updateOrganizationInvitesSaga(
  action: ReturnType<typeof actions.organizationInvitesState.updateOrganizationInviteStart>
): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const response = (yield organizationInviteApi.updateOrganizationInvite(
      apiConfigs,
      action.payload
    )) as Awaited<ReturnType<typeof organizationInviteApi.updateOrganizationInvite>>;

    yield put(actions.organizationInvitesState.updateOrganizationInviteSucceed(response));
    /**
     * Refreshing the org invites only, since this is called from admin panel
     */
    yield put(actions.organizationInvitesState.fetchPendingInvitesStart());
  } catch (e) {
    yield put(actions.organizationInvitesState.updateOrganizationInviteFailed());
    console.log(e);
  }
}

export function* reRequestOrganizationInvitesSaga(
  action: ReturnType<typeof actions.organizationInvitesState.reRequestOrganizationInviteStart>
): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const deletePayload: TUpdateOrganizationInviteInput = {
      organizationInviteId: action.payload.organizationInviteId,
      applicationRoles: action.payload.applicationRoles,
      action: EOrganizationInviteAction.delete,
    };
    yield organizationInviteApi.updateOrganizationInvite(apiConfigs, deletePayload);

    const createPayload: {
      invitees: TOrgInviteeInput[];
      organizationId: string;
      message: string;
    } = {
      organizationId: action.payload.organizationId,
      message: action.payload.message!,
      invitees: [
        {
          email: action.payload.email,
          applicationRoles: action.payload.applicationRoles,
        },
      ],
    };
    const newOrganizationInvite = (yield organizationInviteApi.createOrganizationInvite(
      apiConfigs,
      createPayload
    )) as Awaited<ReturnType<typeof organizationInviteApi.createOrganizationInvite>>;

    yield put(actions.organizationInvitesState.reRequestOrganizationInviteSucceed(newOrganizationInvite));
    /**
     * Refreshing the list of invites below
     */
    yield put(actions.organizationsState.fetchOrganizationInvitesStart());
  } catch (e) {
    yield put(actions.organizationInvitesState.reRequestOrganizationInviteFailed());
    console.log(e);
  }
}

export function* fetchPendingOrganizationInvitesSaga(
  action: ReturnType<typeof actions.organizationInvitesState.fetchPendingInvitesStart>
): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const user = (yield select(userSelector)) as ReturnType<typeof userSelector>;
    const userOrgGuid = user.organization?.organizationGuid;
    const panelOrgId = (yield select(organizationInvitesSelectors.selectOrganizationId)) as ReturnType<
      typeof organizationInvitesSelectors.selectOrganizationId
    >;
    const panelOrgGuid = (yield select(organizationInvitesSelectors.selectOrganizationGuid)) as ReturnType<
      typeof organizationInvitesSelectors.selectOrganizationGuid
    >;

    const orgId = action.payload || panelOrgId;
    const orgGuid = action.payload ? userOrgGuid : panelOrgGuid;

    // fetch pending invites
    const pendingInvites = (yield organizationInviteApi.fetchPendingOrganizationInvites(
      orgId,
      apiConfigs
    )) as Awaited<ReturnType<typeof organizationInviteApi.fetchPendingOrganizationInvites>>;

    if (pendingInvites && pendingInvites.length > 0) {
      // fetch the users for each invite
      const invitees = (yield all(
        pendingInvites.map(invite =>
          call(myOrganizationsApis.fetchInvitee, apiConfigs, {
            email: invite?.invitee?.email as unknown as string,
            userOrgGuid: orgGuid as string,
          })
        )
      )) as Array<Awaited<ReturnType<typeof myOrganizationsApis.fetchInvitee>>>;
      // see if the user is a new user or existing user
      pendingInvites.forEach(invite => {
        invite.invitee.status = invitees.find(invitee => invitee?.email === invite?.invitee?.email)
          ? EUserStatus.existing
          : EUserStatus.new;
      });
    }

    yield put(actions.organizationInvitesState.fetchPendingInvitesSucceed(pendingInvites));
  } catch (e) {
    yield put(actions.organizationInvitesState.fetchPendingInvitesFailed());
    console.log(e);
  }
}

export function* updatePendingInviteStatusSaga(
  action: ReturnType<typeof actions.organizationInvitesState.updatePendingInviteStatus>
): Generator {
  const locale = (yield select(preferredLanguageSelector)) as ReturnType<typeof preferredLanguageSelector>;
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    // update org invites
    const res = (yield all(
      action.payload.map(invite => call(organizationInviteApi.updateOrganizationInvite, apiConfigs, invite))
    )) as Array<Awaited<ReturnType<typeof organizationInviteApi.updateOrganizationInvite>>>;
    // reset the state
    if (res && res.length > 0) {
      yield put(actions.organizationInvitesState.fetchPendingInvitesStart(res[0]!.organizationId));
      yield closeInviteRequestPanel();
    }
  } catch (e) {
    yield put(
      showMessage({
        content: formatMessage({
          id: 'os-organization-panel.invite.request.update.error',
          defaultMessage: 'Something went wrong while saving your changes.',
          description: 'Error message if something goes wrong when org admin reject or approve invite',
        }),
        severity: MessageSeverity.Error,
      })
    );
  }
}

export function* closeInviteRequestPanel(): Generator {
  const panels = (yield select(panelsSelector)) as ReturnType<typeof panelsSelector>;
  const importerPanel = panels.find(p => p.microFrontend.name === 'INVITE_REQUESTS_PANEL');
  if (importerPanel && importerPanel.panelId) {
    const { panelId } = importerPanel;
    yield put(hidePanel(panelId));
  }
}
