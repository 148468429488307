import { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { FormattedMessage } from 'react-intl';
import { SxProps } from '@mui/system';

const styles: Record<string, SxProps> = {
  title: {
    my: 2,
  },
  content: {
    minWidth: 360,
    px: 0,
    pt: 3,
    pb: 0,
  },
  button: {
    fontSize: '12px',
  },
  actions: {
    px: 4,
    pt: 4,
    pb: 2,
  },
  cancelButton: {
    color: 'black',
    py: 2,
  },
  deleteButton: {
    py: 2,
  },
};

interface IProps {
  onDelete: () => void;
}

export const DeleteButton: FC<IProps> = ({ onDelete }) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const handleClick = () => {
    setConfirmationVisible(true);
  };

  const handleClose = () => {
    setConfirmationVisible(false);
  };

  return (
    <Box>
      <Typography variant="h2" sx={styles['title']}>
        <FormattedMessage
          id="os-admin-center-groups.delete-group-title"
          defaultMessage="Delete Group"
          description="Delete Group title in the Group Details panel"
        />
      </Typography>
      <Button
        data-testid="os-admin-center-groups.delete-group-btn"
        data-test="os-admin-center-groups.delete-group-btn"
        color="error"
        variant="outlined"
        startIcon={<DeleteIcon />}
        sx={styles['button']}
        onClick={handleClick}
      >
        <FormattedMessage
          id="os-admin-center-groups.delete-group-button"
          defaultMessage="Delete Group"
          description="Delete Group button in the Group Details panel"
        />
      </Button>
      <Dialog
        open={confirmationVisible}
        onClose={handleClose}
        data-testid="user-group.delete-group-confirmation-dialog"
        data-test="user-group.delete-group-confirmation-dialog"
      >
        <DialogTitle>
          <Typography variant="h2">
            <FormattedMessage
              id="os-admin-center-groups.delete-confirmation-title"
              defaultMessage="Delete Group?"
              description="Delete Group confirmation title in the Group Details panel"
            />
          </Typography>
          <DialogContent sx={styles['content']}>
            <DialogContentText>
              <FormattedMessage
                id="os-admin-center-groups.delete-confirmation-message"
                defaultMessage="Are you sure you want to delete this group?"
                description="Delete Group confirmation message in the Group Details panel"
              />
            </DialogContentText>
            <DialogActions sx={styles['actions']}>
              <Button
                sx={styles['cancelButton']}
                onClick={handleClose}
                data-testid="user-group.delete-group-confirmation-dialog.cancel"
              >
                <FormattedMessage
                  id="os-admin-center-groups.delete-group-cancel-button"
                  defaultMessage="Cancel"
                  description="Delete Group cancel button in the Group Details panel"
                />
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={styles['deleteButton']}
                onClick={onDelete}
                data-testid="user-group.delete-group-confirmation-dialog.confirm"
                data-test="user-group.delete-group-confirmation-dialog.confirm"
              >
                <FormattedMessage
                  id="os-admin-center-groups.delete-group-confirm-button"
                  defaultMessage="Yes, Delete"
                  description="Delete Group confirmation button in the Group Details panel"
                />
              </Button>
            </DialogActions>
          </DialogContent>
        </DialogTitle>
      </Dialog>
    </Box>
  );
};

export default DeleteButton;
