import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { getSortName, isEqual } from '@aiware/js/function';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useStyles } from './appbar-styles';
import Help from '../../molecules/Help';
import { IOrganization, IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/modules/user/selector';
import SessionTimeout from '../../SessionTimeout';
import { IApplication } from '@aiware/os/app-bar-panel';
import { appIdSelector, betaFeaturesSelector } from '@aiware/shared/redux';
import { ToolsDrawer } from '@aiware/shared/icons';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tinycolor from 'tinycolor2';

export const appBarHeight = 55;
export const VERITONE_PANEL_ID = 'VERITONE_PANEL_ID';
export const ORGANIZATION_PANEL_ID = 'ORGANIZATION_PANEL_ID';
export const NOTIFICATION_PANEL_ID = 'NOTIFICATION_PANEL_ID';
export const APPLICATION_PANEL_ID = 'APPLICATION_PANEL_ID';
export const HELP_PANEL_ID = 'HELP_PANEL_ID';
export const USER_APPLICATION_SETTINGS_PANEL = 'USER_APPLICATION_SETTINGS_PANEL';
const NOTIFICATION_BADGE_MAX_COUNT = 20;
const HUB_APP_ID = '9B774B0E-A5B1-452C-A0AA-4E2E64EAD2B3';

const getUserInfo = ({
  appIcon = '',
  currentUser,
}: { appIcon?: string; currentUser?: { organization?: unknown } } & unknown) => ({
  appIcon,
  currentUser: currentUser?.organization ? currentUser : undefined,
});

interface Props {
  isEnableOrgPanel: boolean;
  leftNav?: boolean;
  onClickAppbarMenu?: () => void;
  logoSrc?: string;
  backgroundColor?: string;
  iconColor?: string;
  title?: string;
  textColor?: string;
  searchBar?: boolean;
  help?: boolean;
  notification?: boolean;
  userApplicationSettingsIcon?: boolean;
  onClickOrgIcon?: () => void;
  closeButton?: boolean;
  onClose?: () => void;
  onOpenSetting?: () => void;
  onOpenHelpCenter?: () => void;
  onCloseSetting: () => void;
  onOpenNotification?: () => void;
  onOpenUserApplicationSettings?: () => void;
  openSetting?: {
    openVeritonePanel?: boolean;
    openHelpPanel?: boolean;
    openNotificationPanel?: boolean;
    openAppSwitchPanel?: boolean;
    openUserApplicationSettingsPanel?: boolean;
    openOrgSwitchPanel?: boolean;
  };
  organization?: IOrganization;
  panels?: IVeritoneAppbarPanelConfig[];
  appIcon?: boolean;
  searchBarMountId?: string;
  rightActions?: {
    label: string;
    isActive: boolean;
    onClick: (event: any) => void;
  }[];
  unseenCount: number;
  applications: IApplication[];
  onClickAppSwitchIcon: () => void;
  //intercom
  displaySupportChat?: boolean;
  onDashboardClick: () => void;
  showDashboardIcon?: boolean;
  //salesforce
  displayChatWithSupport?: boolean;
  disableGoHome?: boolean;
  onClickLogo?: () => void;
  renderRightActionsOnly?: boolean;
}

function NewAppBar({
  isEnableOrgPanel,
  leftNav,
  onClickAppbarMenu,
  logoSrc,
  backgroundColor = 'rgb(32.9, 43.1, 47.8)',
  iconColor,
  title,
  help,
  notification = true,
  userApplicationSettingsIcon,
  onClickOrgIcon,
  closeButton,
  onCloseSetting,
  onClose,
  onOpenSetting,
  onOpenHelpCenter,
  onOpenNotification,
  onOpenUserApplicationSettings,
  openSetting,
  organization = {},
  panels = [],
  unseenCount,
  searchBarMountId,
  textColor,
  rightActions = [],
  applications = [],
  onClickAppSwitchIcon,
  displaySupportChat = true,
  onDashboardClick,
  showDashboardIcon = false,
  displayChatWithSupport = true,
  disableGoHome,
  onClickLogo,
  renderRightActionsOnly = false,
}: Props) {
  const isDarkBackground = tinycolor(backgroundColor).isDark();
  const titleColor = textColor ? textColor : isDarkBackground ? '#fff' : '#000';
  const activeColor = isDarkBackground ? 'rgba(255,255,255,0.1)' : 'rgb(243,245,249)';
  const { classes } = useStyles({ titleColor, logo: logoSrc, activeColor, onClickLogo });
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const userState = useSelector(userSelector) || {};
  const [loadOrgImageSuccess, setLoadOrgImageSuccess] = useState(true);
  const { appIcon: applicationIcon, currentUser } = getUserInfo(userState);
  const betaFeatures = useSelector(betaFeaturesSelector);
  const currentApplicationId = useSelector(appIdSelector);
  const isCurrentAppHub = currentApplicationId === HUB_APP_ID;

  function goHome() {
    if (onClickLogo) {
      onClickLogo();
    } else {
      window.location.href = '/';
    }
  }

  const helpDocCallback = () => {
    onOpenHelpCenter?.();
  };

  function handleOrgImageError() {
    setLoadOrgImageSuccess(false);
  }

  useEffect(() => {
    localStorage.getItem('DEBUG') === 'true' && console.log('AppBar mounted');
    return () => {
      localStorage.getItem('DEBUG') === 'true' && console.log('AppBar unmounted');
    };
  }, []);

  useEffect(() => {
    const panelIds = panels.map(panel => panel.panelId);
    if (!panelIds.length && !activePanels.length) return;
    if (!isEqual(panelIds, activePanels)) {
      setActivePanels(panelIds);
    }
  }, [panels, activePanels]);

  const desktopIconStyle = { background: iconColor ? iconColor : undefined };
  const RightActions = () => (
    <div className={classes.controllers} id={'veritone-sdk-header-actions'}>
      {rightActions.length > 0 && (
        <div
          id={'veritone-sdk-generated-actions'}
          className={classNames(classes.iconGroup, classes.noSelect)}
        >
          {rightActions.map(({ label, onClick, isActive = true }, i) => (
            <div className={classes.iconGroupIcon} key={`label-${i}`}>
              <span
                onClick={e => (onClick ? onClick(e) : null)}
                className={classNames(classes.rightActionLabel, isActive ? classes.active : classes.passive)}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            </div>
          ))}
        </div>
      )}
      {/* dashboard icon */}
      {showDashboardIcon && (
        <Tooltip
          title={
            <FormattedMessage
              id="os-app-bar.dashboard-button"
              defaultMessage="aiWARE Dashboard"
              description="aiWARE Dashboard label"
            />
          }
          aria-label="dashboard"
        >
          <IconButton
            id={'veritone-sdk-dashboard-button'}
            data-test="app-bar-dashboard-button"
            className={classes.barIcon}
            onClick={onDashboardClick}
            size="large"
          >
            <div className={classNames(classes.icon, classes.dashboardIcon)} style={desktopIconStyle} />
          </IconButton>
        </Tooltip>
      )}
      {help && (
        <div
          id={'veritone-sdk-help-button'}
          data-test="app-bar-help-container"
          data-testid="app-bar-help-container"
          className={classNames(classes.margin, {
            [classes.panelActive]: activePanels.includes(HELP_PANEL_ID),
          })}
        >
          <Help
            tooltipTitle=""
            helpDocLabel="View Help Docs"
            chatWithSupportLabel="Chat with Support"
            supportLabel="In-App Guidance"
            helpDocCallback={helpDocCallback}
            supportCallback={onCloseSetting}
            titleColor={titleColor}
            displaySupportChat={displaySupportChat}
            displayChatWithSupport={displayChatWithSupport}
            desktopIconStyle={desktopIconStyle}
          />
        </div>
      )}
      {notification && (
        <div
          id={'veritone-sdk-notification-button'}
          className={classNames(classes.margin, {
            [classes.panelActive]: activePanels.includes(NOTIFICATION_PANEL_ID),
          })}
        >
          <Tooltip
            title={
              <FormattedMessage
                id="os-app-bar.KFXxNr"
                defaultMessage="Notifications"
                description="appbar tooltip: notification button text"
              />
            }
            aria-label="notifications"
          >
            <IconButton
              className={classes.barIcon}
              onClick={onOpenNotification}
              data-test="app-bar-notification-button"
              size="large"
              data-testid="app-bar-notification-button"
            >
              <Badge
                hidden={unseenCount === 0}
                badgeContent={unseenCount}
                max={NOTIFICATION_BADGE_MAX_COUNT}
                color="primary"
                data-test="app-bar-notification-badge"
                className={classes.notificationBadge}
              >
                <div
                  className={classNames(classes.icon, classes.notificationIcon, {
                    [classes.openNotificationPanel]: openSetting?.openNotificationPanel,
                  })}
                  style={desktopIconStyle}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </div>
      )}
      {betaFeatures && userApplicationSettingsIcon && (
        <div
          id={'veritone-sdk-application-settings-button'}
          className={classNames(classes.margin, {
            [classes.panelActive]: activePanels.includes(USER_APPLICATION_SETTINGS_PANEL),
          })}
        >
          <Tooltip
            title={
              <FormattedMessage
                id="os-app-bar.application-settings-button"
                defaultMessage="Application Settings"
                description="appbar tooltip: application settings button text"
              />
            }
            aria-label="application-settings"
          >
            <IconButton
              className={classes.barIcon}
              onClick={onOpenUserApplicationSettings}
              size="large"
              data-testid="app-bar-user-application-settings-button"
            >
              <div className={classNames(classes.icon, classes.applicationSettingsIcon)} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {/* app switch icon */}
      {applications.length > 1 && (
        <div
          id={'veritone-sdk-app-switcher-button'}
          className={classNames({
            [classes.panelActive]: activePanels.includes(APPLICATION_PANEL_ID),
          })}
        >
          <Tooltip
            title={
              <FormattedMessage
                id="os-app-bar.3v499g"
                defaultMessage="Applications"
                description="applications tooltip: text for applications button"
              />
            }
            aria-label="applications"
          >
            <IconButton
              data-test="app-bar-switch-app-button"
              className={classes.barIcon}
              onClick={onClickAppSwitchIcon}
              size="large"
            >
              <div
                className={classNames(classes.icon, classes.applicationIcon, {
                  [classes.openPanel]: openSetting?.openAppSwitchPanel,
                })}
                style={desktopIconStyle}
              />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {/* veritone switch org */}
      {(organization.imageUrl || organization.name) && isEnableOrgPanel && (
        <div
          id={'veritone-sdk-organizations-button'}
          className={classNames(classes.margin, {
            [classes.panelActive]: activePanels.includes(ORGANIZATION_PANEL_ID),
          })}
        >
          <Tooltip
            title={
              <FormattedMessage
                id="os-app-bar.7qOwBg"
                defaultMessage="Organization"
                description="organization tooltip: text for documentation"
              />
            }
            aria-label="organization"
          >
            <IconButton
              data-test="app-bar-org-button"
              className={`${classes.barIcon} ${classes.orgButton}`}
              onClick={onClickOrgIcon}
              size="large"
            >
              {organization.imageUrl && loadOrgImageSuccess ? (
                <img
                  src={organization.imageUrl}
                  className={classNames(classes.orgIcon)}
                  onError={handleOrgImageError}
                  alt="org_icon"
                />
              ) : (
                <Avatar className={classes.smallOrgAvatar} variant="circular" data-testid="app-bar-avatar">
                  {getSortName(organization.name || '')}
                </Avatar>
              )}
            </IconButton>
          </Tooltip>
        </div>
      )}
      {/* veritone function */}
      {/* hide if it's current app hub*/}
      {currentUser && currentUser?.organization && !isCurrentAppHub && (
        <div
          id={'veritone-sdk-utilities-button'}
          className={classNames({
            [classes.panelActive]: activePanels.includes(VERITONE_PANEL_ID),
          })}
        >
          <Tooltip
            title={
              <FormattedMessage
                id="os-app-bar.eOxvvM"
                defaultMessage="Utilities"
                description="utilities tooltip: text for documentation"
              />
            }
            aria-label="utilities"
          >
            <IconButton
              data-test="app-bar-setting-button"
              data-testid="app-bar-setting-button"
              onClick={onOpenSetting}
              size="large"
            >
              <ToolsDrawer sx={{ color: titleColor }} />
            </IconButton>
          </Tooltip>
        </div>
      )}

      {
        //Close Button
        closeButton && onClose && (
          <div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                onClick={onClose}
                data-test="app-bar-close-button"
                size="large"
                data-testid="app-bar-close-button"
              >
                <CloseIcon htmlColor={titleColor} />
              </IconButton>
            </div>
          </div>
        )
      }
    </div>
  );

  if (renderRightActionsOnly) {
    return (
      <>
        <SessionTimeout />
        <RightActions />
      </>
    );
  }
  return (
    <>
      <SessionTimeout />
      <div style={{ height: appBarHeight }} />
      <div
        data-test="appbar"
        className={classes.appBar}
        style={{
          height: appBarHeight,
          background: backgroundColor,
        }}
      >
        {leftNav && (
          <div className={classes.menuButton}>
            <IconButton
              data-test="app-bar-menu"
              data-testid="app-bar-menu"
              className={classes.iconButton}
              onClick={onClickAppbarMenu}
              size="large"
            >
              <div className={classNames(classes.icon, classes.hamburgerIcon)} />
            </IconButton>
          </div>
        )}
        {!!applicationIcon && applicationIcon !== '' && (
          <div className={classes.margin}>
            <img className={classes.appIcon} src={applicationIcon} alt="appIcon" />
          </div>
        )}
        <div className={classes.content} style={{ color: titleColor }}>
          <div
            className={classNames(classes.left, classes.noSelect)}
            onClick={disableGoHome && !onClickLogo ? undefined : goHome}
            data-test="appbarTitle"
            data-testid="appbarTitle"
          >
            {logoSrc ? (
              <span className={classes.appLogo} />
            ) : (
              <Typography variant="h1" className={classes.title}>
                {title}
              </Typography>
            )}
          </div>
          <div
            data-test="appbarSearch"
            id={searchBarMountId || 'search-bar-id'}
            className={classes.searchBarHolder}
          />
          <div className={classes.right}>
            <RightActions />
          </div>
        </div>
      </div>
    </>
  );
}

export default NewAppBar;
