import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { jsonTable } from '@aiware/shared/reusable-utils';
import { actions } from '../../../redux/slices';
import { sdoTableSelectors, selectViewerPanelActiveSchema } from '../../../redux/selectors';
import { TStructuredDataObject } from '../../../types';

const { table: JSONTable } = jsonTable;

export const SDOTable: React.FC = () => {
  const dispatch = useDispatch();
  const status = useSelector(sdoTableSelectors.selectReadStatus);
  const items = useSelector(sdoTableSelectors.selectItems) as TStructuredDataObject[];
  const activeSchema = useSelector(selectViewerPanelActiveSchema);
  const hasMore = useSelector(sdoTableSelectors.selectHasMore);
  const isErrored = status === 'failure';
  const isLoading = status === 'pending';
  const hideRetryButton = true;
  const errorMessage = (
    <FormattedMessage
      id="os-data-center-sdo-structured-data-viewer-sdo-table-error"
      defaultMessage="An error occurred while fetching objects. Please try again."
      description="Data Center SDO Viewer SDO Table Error Message"
    />
  );

  const handleFetchSDOs = () => {
    dispatch(actions.structuredDataObjects['readStart']!(null));
  };

  useEffect(() => {
    // Reset the SDO list whenever the schema changes
    dispatch(actions.structuredDataObjects['resetList']!(null));
    handleFetchSDOs();
  }, [activeSchema]);

  return (
    <JSONTable
      data={items.map((item, index) => ({ '#': index + 1, ...item?.data }))}
      testId={'dc-sdo-viewer-rendered-table'}
      withPagination
      isLoading={isLoading}
      isErrored={isErrored}
      hasMore={hasMore}
      fetchMore={handleFetchSDOs}
      fetchOnMount={false}
      TableErrorStateProps={{
        hideRetryButton,
        CustomMessage: errorMessage,
      }}
      TableEmptyStateProps={{
        CustomMessage: (
          <FormattedMessage
            id="os-data-center-sdo-structured-data-viewer-sdo-table-empty-state"
            defaultMessage="No SDOs found."
            description="Data Center SDO Viewer SDO Table Empty state Message"
          />
        ),
      }}
    />
  );
};
