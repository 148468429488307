import { createAction } from '@reduxjs/toolkit';

import {
  INotificationDetail,
  IMailbox,
  ICountObj,
} from '@aiware/js/interfaces';

import { nameSpace } from './selector';

// subscriber
export const SUBSCRIBER_MAILBOX = `${nameSpace}/SUBSCRIBER_MAILBOX`;
export const SUBSCRIBER_MAILBOX_START = `${nameSpace}/SUBSCRIBER_MAILBOX_START`;
export const UPDATE_MAILBOX_DATA = `${nameSpace}/UPDATE_MAILBOX_DATA`;
export const SUBSCRIBING_MAILBOX = `${nameSpace}/SUBSCRIBING_MAILBOX`;
export const SUBSCRIBER_MAILBOX_SUCCESS = `${nameSpace}/SUBSCRIBER_MAILBOX_SUCCESS`;
export const SUBSCRIBER_MAILBOX_ERROR = `${nameSpace}/SUBSCRIBER_MAILBOX_ERROR`;
export const UPDATE_UNSEEN_COUNT = `${nameSpace}/UPDATE_UNSEEN_COUNT`;

export const MARK_ALL_NOTIFICATIONS_SEEN = `${nameSpace}/MARK_ALL_NOTIFICATIONS_SEEN`;
export const MARK_ALL_NOTIFICATIONS_SEEN_REQUEST = `${nameSpace}/MARK_ALL_NOTIFICATIONS_SEEN_REQUEST`;
export const MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS = `${nameSpace}/MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS`;
export const MARK_ALL_NOTIFICATIONS_SEEN_FAIL = `${nameSpace}/MARK_ALL_NOTIFICATIONS_SEEN_FAIL`;

export const SUBSCRIBE_DEFAULT_MAILBOX_REQUEST = `${nameSpace}/SUBSCRIBE_DEFAULT_MAILBOX_REQUEST`;
export const SUBSCRIBE_DEFAULT_MAILBOX_SUCCESS = `${nameSpace}/SUBSCRIBE_DEFAULT_MAILBOX_SUCCESS`;
export const SUBSCRIBE_DEFAULT_MAILBOX_FAIL = `${nameSpace}/SUBSCRIBE_DEFAULT_MAILBOX_FAIL`;

//push notification action
export const PUSH_NOTIFICATION = `${nameSpace}/PUSH_NOTIFICATION`;
export const PUSH_DEFAULT_MAILBOX = `${nameSpace}/PUSH_DEFAULT_MAILBOX`;

// subscriber action
export const requestSubscriber = createAction(SUBSCRIBER_MAILBOX);

export const subscriberStart = createAction(SUBSCRIBER_MAILBOX_START);

export const setMailboxData = createAction(
  UPDATE_MAILBOX_DATA,
  function prepare(data: IMailbox[]) {
    return {
      payload: data,
    };
  }
);

export const subscriberSuccess = createAction(
  SUBSCRIBER_MAILBOX_SUCCESS,
  function prepare(data: unknown) {
    return {
      payload: data,
    };
  }
);

export const subscriberError = createAction(
  SUBSCRIBER_MAILBOX_ERROR,
  function prepare(message: string) {
    return {
      payload: message,
    };
  }
);

export const subscribing = createAction(SUBSCRIBING_MAILBOX);

export const pushNotification = createAction(
  PUSH_NOTIFICATION,
  function prepare(notification: INotificationDetail) {
    return {
      payload: notification,
    };
  }
);
export const pushDefaultMailBox = createAction(
  PUSH_DEFAULT_MAILBOX,
  function prepare(notification: INotificationDetail) {
    return {
      payload: notification,
    };
  }
);

export const updateUnseenCount = createAction(
  UPDATE_UNSEEN_COUNT,
  function prepare(countObj: ICountObj) {
    return {
      payload: countObj,
    };
  }
);

export const markAllNotificationsSeenRequest = createAction(
  MARK_ALL_NOTIFICATIONS_SEEN_REQUEST
);
export const markAllNotificationsSeenSuccess = createAction(
  MARK_ALL_NOTIFICATIONS_SEEN_SUCCESS
);
export const markAllNotificationsSeenFail = createAction(
  MARK_ALL_NOTIFICATIONS_SEEN_FAIL
);

export const subscribeDefaultMailboxRequest = createAction(
  SUBSCRIBE_DEFAULT_MAILBOX_REQUEST
);
