import { FC } from 'react';
import Chip from '@mui/material/Chip';
import { EngineCategoryIcons, DataRegistry, Source, FileFilled } from '@aiware/shared/icons';
import Icon from '@mui/material/Icon';
import { useStyles } from './aiTag.styles';
import { AiAvatar } from '../AIAvatar';
import { ECapabilityTypes, CapabilityType, ICriterion } from '../../types/aiAutocomplete.types';
import { Tooltip } from '@mui/material';

interface IAiTag {
  id: string;
  label: string;
  avatarUrl?: string;
  capabilityType?: CapabilityType;
  onDelete?: (criterion: ICriterion) => void;
}

export const AiTag: FC<IAiTag> = ({ id, label, avatarUrl, capabilityType, onDelete }) => {
  const { classes } = useStyles();

  const MAX_LENGTH = 25;
  const isTooLong = label.length > MAX_LENGTH;
  const chipText = isTooLong ? label.slice(0, MAX_LENGTH) + '...' : label;

  const getIconForCapabilityType = (capabilityType: any) => {
    switch (capabilityType) {
      case ECapabilityTypes.dataRegistry:
        return DataRegistry;
      case ECapabilityTypes.source:
        return Source;
      case ECapabilityTypes.fileName:
        return FileFilled;
      default:
        return (EngineCategoryIcons as any)[capabilityType] || '';
    }
  };

  return (
    <Tooltip title={isTooLong ? label : ''}>
      <Chip
        id={id}
        label={chipText}
        className={classes.tag}
        variant="outlined"
        onDelete={onDelete}
        sx={{
          '& .Sdk-MuiChip-icon': {
            marginRight: avatarUrl && capabilityType ? '0px' : '-6px',
          },
          '& .Sdk-MuiChip-label': {
            paddingLeft: avatarUrl ? '0px' : '12px',
            textTransform: 'capitalize',
          },
        }}
        icon={
          avatarUrl ? (
            <Icon
              className={classes.tagLogoContainer}
              style={{ left: `${avatarUrl && !capabilityType ? '0px' : '-5x'}` }}
            >
              <AiAvatar id={id} avatarUrl={avatarUrl} capabilityType={capabilityType} />
            </Icon>
          ) : (
            <Icon className={classes.tagNoLogo} component={getIconForCapabilityType(capabilityType)} />
          )
        }
      />
    </Tooltip>
  );
};
