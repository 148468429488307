import { Typography } from '@mui/material';
import React, { ErrorInfo } from 'react';
import { CopyChip } from '../../chips/copy-chip/copy-chip';
import { jsonTableStyles } from '../helpers/json-table-styles';
import { URLChip } from '../../chips/url-chip/url-chip';
import { JSONChip } from '../../chips/json-chip/json-chip';
import { ErrorBoundary } from '../../error-boundary/error-boundary';
import { EJSONTableCellType, getCellType } from '../helpers/json-table-helpers';
import { jsonTableText } from '../helpers/json-table-text';

export const JSONTableCell: React.FC<{ value: any }> = ({ value }) => {
  const handleCatchCellError = (error: Error, errorInfo: ErrorInfo) => {
    console.warn(`Error occurred when attempting to render cell data:`, error, errorInfo);
    console.log('Value to render: ', value);
    console.log('Error: ', error);
    console.log('Error Info: ', errorInfo);
  };
  return (
    <ErrorBoundary
      fallbackUI={<Typography noWrap>{jsonTableText.errors.cellError}</Typography>}
      onDidCatchError={handleCatchCellError}
    >
      <JSONTableCellValue value={value} />
    </ErrorBoundary>
  );
};

const JSONTableCellValue: React.FC<{ value: any }> = ({ value }) => {
  let outputValue = value;
  const cellType = getCellType(value);

  switch (cellType) {
    case EJSONTableCellType.NULL: {
      outputValue = '--';
      return (
        <Typography align={'center'} width={'100%'}>
          {outputValue}
        </Typography>
      );
    }
    case EJSONTableCellType.OBJECT: {
      return (
        <JSONChip
          value={outputValue}
          sx={jsonTableStyles.chip}
          ChipProps={{
            size: 'small',
          }}
        />
      );
    }
    case EJSONTableCellType.BOOLEAN: {
      return (
        <Typography
          align={'center'}
          sx={{
            fontFamily: 'monospace',
            letterSpacing: '1px',
            width: '100%',
          }}
          fontSize={'small'}
          noWrap
        >
          {outputValue ? 'true' : 'false'}
        </Typography>
      );
    }
    case EJSONTableCellType.NUMBER:
    case EJSONTableCellType.UUID: {
      return (
        <CopyChip value={outputValue.toString()} ChipProps={{ size: 'small' }} sx={jsonTableStyles.chip} />
      );
    }
    case EJSONTableCellType.URL: {
      return (
        <URLChip
          value={value}
          ChipProps={{
            size: 'small',
          }}
          sx={jsonTableStyles.chip}
        />
      );
    }
    case EJSONTableCellType.DATE:
    case EJSONTableCellType.STRING:
    default: {
      if (typeof outputValue !== 'string') {
        outputValue = JSON.stringify(outputValue);
      }
      return (
        <Typography fontSize={'small'} noWrap>
          {outputValue}
        </Typography>
      );
    }
  }
};
