import { hidePanel, mountPanel } from '@aiware/js/panel';
import { betaFeaturesSelector, selectApiConfigs, selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { EAuthResourceType, actions as permissionsActions } from '@aiware/shared/permissions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './useStyles';
import PanelHeader from '../PanelHeader/PanelHeader';
import { InfoTabs } from '../InfoTabs/InfoTabs';
import { FileInfo } from '../FileInfo/FileInfo';
import { downloadTdoByEntityId } from '../../../../../helpers/sharedFunctions';
import { FileInfoErrorState } from '../PanelState/FileInfoErrorState';
import { FileInfoLoadingState } from '../PanelState/FileInfoLoadingState';
import { actions } from '../../../../../redux/slices';
import { selectFileInfo } from '../../../../../redux/selectors/file-info';

export const PanelContent = ({
  entityId,
  fileName,
}: {
  dataId: string;
  panelRendered: boolean;
  appId: string;
  connectorId: string;
  entityId: string;
  fileName: string;
}) => {
  const [selectedTabId, setSelectedTabId] = useState(0);
  const panelId = 'DATA_CENTER_FILE_INFO_PANEL';
  const dispatch = useDispatch();
  const apiConfigs = useSelector(selectApiConfigs);
  const fileInfoRedux = useSelector(selectFileInfo);
  const isBetaFeaturesEnabled: boolean = useSelector(betaFeaturesSelector);
  const isDataCenterFullScreen = useSelector(selectIsFullscreenEnabled);
  const { loadingStatus, fileInfo } = fileInfoRedux;
  const isError = loadingStatus === 'failure';

  useEffect(() => {
    dispatch(actions.fileInfo.fetchFileInfoStart({ entityId }));
    dispatch(permissionsActions.fetchObjectPermissionsStart({ entityType: EAuthResourceType.TDO, entityId }));
  }, [dispatch, entityId]);

  const { classes } = useStyles();

  const TabContent = () => {
    switch (selectedTabId) {
      case 0:
        return <FileInfo fileInfo={fileInfo} />;
      case 1:
        // TODO: Implement content templates tab
        return <div>Content Templates</div>;
      case 2:
        // TODO: Implement Activity tab
        return <div>Activity</div>;
      default:
        return null;
    }
  };

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => setSelectedTabId(newValue);

  const handleDownloadAsset = () => {
    // @ts-ignore
    downloadTdoByEntityId(apiConfigs.apiRoot, entityId, fileName);
  };

  const handleProcessAi = () => {
    const addMarginStart = 380;
    const microFrontend = {
      name: 'DATA_CENTER_PROCESS_AI',
      config: {
        name: 'DATA CENTER PROCESS AI',
        tdoId: entityId,
        parentPanelId: panelId,
        marginStart: addMarginStart,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isDataCenterFullScreen ? 0 : 55,
      marginStart: (isDataCenterFullScreen ? 0 : 80) + addMarginStart,
      size: 'small',
      siblingPanelId: panelId,
      parentPanelId: panelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: 'DATA_CENTER_PROCESS_AI',
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  const handleEditAttributes = () => {
    // TODO: Implement edit info panel
  };

  const handleMoveAsset = () => {
    // TODO: Implement moving asset
  };

  const handleDeleteAsset = () => {
    // TODO: Implement deleting asset
  };

  const isLoading = loadingStatus === 'pending';
  const shouldContentRender = loadingStatus === 'success' && !isError;

  return (
    <div className={classes.container}>
      <PanelHeader
        height={64}
        onPanelClose={() => dispatch(hidePanel(panelId))}
        onEditAttributes={handleEditAttributes}
        onProcessAi={handleProcessAi}
        onMoveAsset={handleMoveAsset}
        onDownloadAsset={handleDownloadAsset}
        onDeleteAsset={handleDeleteAsset}
        entityId={entityId}
        isError={isError}
        isLoading={isLoading}
        isBetaFeaturesEnabled={isBetaFeaturesEnabled}
      />
      {isError && <FileInfoErrorState />}
      {isLoading && <FileInfoLoadingState />}
      {shouldContentRender && (
        <>
          <InfoTabs
            selectedTabId={selectedTabId}
            onChange={handleChangeTab}
            isBetaFeaturesEnabled={isBetaFeaturesEnabled}
          />
          <div className={classes.content}>
            <TabContent />
          </div>
        </>
      )}
    </div>
  );
};
