import { useState, forwardRef, MouseEvent, createElement } from 'react';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FormattedRelativeTime, FormattedMessage } from 'react-intl';
import { Notifications } from '@aiware/shared/icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Icon from '@mui/material/Icon';

import { INotificationDetail, NotificationFlag } from '@aiware/js/interfaces';
import { useDispatch } from 'react-redux';
import { updateNotificationReadRequest } from '../../store/actions';
import useStyles from './useStyles';
import { convertHTMLEntities, getRelativeValue } from '../../store/utils';

interface Props {
  data: INotificationDetail;
  onViewDetail: () => void;
}

const NotificationCard = forwardRef<HTMLDivElement, Props>(({ data, onViewDetail }, ref) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [imgError, setImgError] = useState(false);
  function handleImgError() {
    setImgError(true);
  }

  function handleChangeReadStatus(event: MouseEvent<HTMLElement>) {
    event.stopPropagation();
    dispatch(updateNotificationReadRequest(id));
  }
  const { createdDateTime, icon, title, appName, body, id, flags } = data;
  return (
    <div ref={ref} className={classes.cardContainer}>
      <div
        onClick={onViewDetail}
        className={!flags?.includes(NotificationFlag.unread) ? classes.rootCard : classes.unreadCard}
      >
        {flags?.includes(NotificationFlag.unread) && (
          <Icon
            data-test="notification-unread-icon"
            data-testid="notification-unread-icon"
            className={classes.unread}
            children={<FiberManualRecordIcon className={classes.unreadIcon} />}
            onClick={handleChangeReadStatus}
          />
        )}
        <div className={classes.iconCard}>
          {icon && !imgError ? (
            <img
              src={icon}
              className={classes.notificationIcon}
              draggable="false"
              alt="notification-card-icon"
              onError={handleImgError}
            />
          ) : (
            <Notifications />
          )}
        </div>
        <div className={classes.leftContent}>
          <div className={classes.titleCard}>
            <Typography variant="body2" className={classes.appName}>
              {flags?.includes(NotificationFlag.unread) ? <strong>{appName}</strong> : appName}
            </Typography>
            <Typography variant="body2" className={classes.timestampCard}>
              <FormattedRelativeTime
                value={getRelativeValue(createdDateTime)}
                unit="minute"
                updateIntervalInSeconds={60}
                children={(formattedDate: string) => {
                  if (formattedDate.includes('seconds')) {
                    return (
                      <FormattedMessage
                        id="os-notification-panel.mvPoLJ"
                        defaultMessage="Just now"
                        description="the time label of notification that received on 1 minute"
                      />
                    );
                  }
                  return createElement('span', {}, formattedDate);
                }}
              />
            </Typography>
          </div>
          <div>
            <Typography variant="body2" className={classes.contentCard}>
              {convertHTMLEntities(title || body)}
            </Typography>
          </div>
        </div>
      </div>
      <Divider className={classes.dividerCard} />
    </div>
  );
});

export default NotificationCard;
