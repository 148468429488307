import React, { useState } from 'react';
import { Chip, ChipProps } from '@mui/material';
import { DataObject } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { PanelModal } from '../../panel-components';
import type { SxProps } from '@mui/system';

export type TJSONChipProps = {
  value: string;
  ChipProps?: ChipProps;
  sx?: SxProps;
  testId?: string;
};

export const JSONChip: React.FC<TJSONChipProps> = ({
  value,
  testId = 'shared-json-chip',
  ChipProps = {},
  sx = {},
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Chip
        sx={sx}
        onClick={handleOpen}
        label={
          <FormattedMessage
            id="shared-reusable-utils.json-chip.chip-title"
            defaultMessage="View JSON"
            description="JSON Chip label"
          />
        }
        icon={
          <DataObject
            sx={{
              color: 'inherit',
            }}
            fontSize={'small'}
          />
        }
        clickable
        {...ChipProps}
        data-testid={testId}
      />
      <PanelModal
        isOpen={open}
        onClose={handleClose}
        title={
          <FormattedMessage
            id="shared-reusable-utils.json-chip.modal-title"
            defaultMessage="JSON OBJECT"
            description="JSON Chip Modal Title - JSON OBJECT"
          />
        }
        content={
          <code
            style={{
              whiteSpace: 'break-spaces',
              background: 'rgb(247, 247, 247)',
              display: 'flex',
              padding: '10px 15px',
              boxSizing: 'border-box',
              borderRadius: '5px',
              maxHeight: '100%',
              overflow: 'auto',
              boxShadow: '0px 4px 5px #e4e4e4',
              border: '1px solid #e1e1e1',
            }}
          >{`${JSON.stringify(value, null, 4)}`}</code>
        }
        labeledBy={'json-table-view-json-obj-modal-modal-title'}
        describedBy={'json-table-view-json-obj-modal-modal-description'}
      />
    </>
  );
};
