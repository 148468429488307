import { useStyles } from '../sourceScheduleFilter.styles';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { TFilterSourceScheduleDataSet } from '../../../../../types/aiSearch.types';
import { ISchedule, ISource } from '../../../../../../../types';

/** This function returns and empty data object for the Source / Schedule Filter
 * that is used to set the initial state and clear out the filter. **/
export const getInitialDataSet: () => TFilterSourceScheduleDataSet = () => {
  return { SOURCES: [], SCHEDULES: {} };
};

/** This function generates the React Element used to display the selected
 *  dropdown value. In this case, we are displaying a custom string based
 *  on the content of the dataset. **/
export const renderSourceScheduleValue = (parentDataSet: TFilterSourceScheduleDataSet) => {
  let textContent = '';
  const hasSources = !!parentDataSet['SOURCES']?.length;
  const hasSchedules = Object.keys(parentDataSet['SCHEDULES']).length !== 0;
  let schedules: ISchedule[] = [];

  const scheduleCount = (() => {
    let count = 0;
    for (const sourceKey of Object.keys(parentDataSet['SCHEDULES'])) {
      count += parentDataSet?.['SCHEDULES']?.[sourceKey]?.length ?? 0;
      schedules = [...schedules, ...parentDataSet['SCHEDULES'][sourceKey]!];
    }
    return count;
  })();

  if (hasSources) {
    textContent += `${parentDataSet['SOURCES']?.length} Source${
      parentDataSet['SOURCES']?.length === 1 ? '' : 's'
    }`;
  }
  if (hasSchedules) {
    const scheduleAmtString = `${scheduleCount} Schedule${scheduleCount === 1 ? '' : 's'}`;
    if (!hasSources) {
      textContent = scheduleAmtString;
    } else {
      textContent += `, ${scheduleAmtString}`;
    }
  }
  if (!hasSources && !hasSchedules) {
    textContent = 'All';
    return <div>{textContent}</div>;
  }

  const TooltipContent = () => {
    return (
      <div>
        {hasSources && (
          <div>
            <div style={{ textDecoration: 'underline' }}>Sources:</div>
            {parentDataSet['SOURCES'].map((source: ISource, index: number) => (
              <div key={index}>- {source?.name ? source.name : source.id}</div>
            ))}
          </div>
        )}
        {hasSchedules && (
          <div>
            <div style={{ textDecoration: 'underline' }}>Schedules:</div>
            {schedules.map((schedule: ISchedule, index: number) => (
              <div key={index}>- {schedule.name ? schedule.name : schedule.id}</div>
            ))}
          </div>
        )}
      </div>
    );
  };
  return (
    <Tooltip title={<TooltipContent />}>
      <div>{textContent}</div>
    </Tooltip>
  );
};

export const LoadingStateList = ({
  style = {},
  text,
}: {
  style?: React.CSSProperties;
  text: string | JSX.Element;
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.divLoader} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <CircularProgress />
        <Typography align={'center'} variant={'caption'} sx={{ marginTop: '15px' }}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

export const LoadingStateRow = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.rowLoader}>
      <CircularProgress />
    </div>
  );
};
