import { FunctionComponent, useEffect, useRef, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { makeStyles } from 'tss-react/mui';

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import ActionPanel from '../shared/ActionPanel';
import { actions } from '../../redux/slices';
import { selectTableDataSettings } from '../../redux/selectors/ui-state';
import { TABLE_DATA_NAMES, TABLE_DATA_SETTINGS } from '../../types/entity-data-settings';
import { SHARED_TEXT } from '../../helpers/shared-text';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  iconColor: {
    fill: '#555F7C',
  },
  divider: {
    margin: `${theme.spacing(2)} 0`,
  },
  listItem: {
    padding: `0 ${theme.spacing(12)} 0 0`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  listItemText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    borderRadius: 4,
    background: '#F2F5F9',
    transition: '.2s ease',
    '&.inactive': {
      opacity: 0.5,
    },
  },
}));

const TableDataSettings: FunctionComponent<{
  className: string;
}> = props => {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isActive, setIsActive] = useState(false);
  const [tableData, setTableData] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { isOpen, entityType, settings } = useSelector(selectTableDataSettings);

  useEffect(() => {
    if (entityType) {
      inputRef.current && inputRef.current.focus();
      setTableData((settings as any)[entityType]);
    } else {
      setTableData([]);
    }
  }, [entityType, isActive, settings]);

  const { required = [], canToggle = [] } = entityType ? (TABLE_DATA_SETTINGS as any)[entityType] : {};

  const handleToggle = (key: string) => {
    if (tableData.includes(key)) {
      setTableData(prev => prev.filter(data => data !== key));
    } else {
      setTableData(prev => [...prev, key]);
    }
  };

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (entityType) {
      const keys = new Set([...required, ...tableData]);

      dispatch(
        actions.uiState.setTableDataSetting({
          entityType,
          keys: Array.from(keys),
        })
      );
      dispatch(actions.uiState.toggleTableDataSettings(null));
    }
  };

  const handleClose = () => {
    dispatch(actions.uiState.toggleTableDataSettings(null));
  };

  return (
    <ActionPanel
      isOpen={!!(isOpen && entityType)}
      panelTitle={SHARED_TEXT.tableDataSettings()}
      panelTitleSecondary={SHARED_TEXT.addRemoveDataSettings()}
      onClose={handleClose}
      setIsActive={setIsActive}
      {...props}
    >
      <>
        {isActive && (
          <form
            autoComplete="off"
            noValidate
            className={classes.root}
            onSubmit={handleSave}
            data-test="dc-table-data-settings-form"
          >
            <List>
              {required.map((key: string) => (
                <ListItem key={key} className={classes.listItem}>
                  <ListItemText
                    primary={(TABLE_DATA_NAMES as any)[key]?.()}
                    className={classes.listItemText}
                  />
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              ))}

              <Divider className={classes.divider} />

              {canToggle.map((key: string, idx: number) => {
                const labelId = `table-data-${key}`;
                return (
                  <ListItem key={key} className={classes.listItem}>
                    <ListItemText
                      id={labelId}
                      primary={(TABLE_DATA_NAMES as any)[key]?.()}
                      className={cx(classes.listItemText, {
                        inactive: !tableData.includes(key),
                      })}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title={tableData.includes(key) ? SHARED_TEXT.hide() : SHARED_TEXT.show()}>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(key)}
                          checked={tableData.includes(key)}
                          inputProps={{ 'aria-labelledby': labelId }}
                          checkedIcon={<VisibilityOutlinedIcon className={classes.iconColor} />}
                          icon={<VisibilityOffIcon className={classes.iconColor} />}
                          inputRef={idx === 0 ? inputRef : null}
                        />
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            <div className={classes.buttonGroup}>
              <Button onClick={handleClose}>
                <FormattedMessage
                  id="os-data-center-browse.footerActions.cancel"
                  defaultMessage="Cancel"
                  description="cancel"
                />
              </Button>
              <Button variant="contained" color="primary" type="submit">
                <FormattedMessage
                  id="os-data-center-browse.footerActions.save"
                  defaultMessage="Save"
                  description="save"
                />
              </Button>
            </div>
          </form>
        )}
      </>
    </ActionPanel>
  );
};

export default TableDataSettings;
