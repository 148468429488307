import { VideoCamera, Description, Image, Voice, UnknownDocument } from '@aiware/shared/icons';
import { FunctionComponent } from 'react';
import Icon from '@mui/material/Icon';

const IconMimeHelper: FunctionComponent<{
  value: string | undefined;
  className?: string;
}> = ({ value, className }) => {
  let mimeIcon = UnknownDocument;
  if (value === undefined) {
    return <Icon className={className} component={mimeIcon} />;
  }
  if (value.includes('audio')) {
    mimeIcon = Voice;
  }
  if (value.includes('video')) {
    mimeIcon = VideoCamera;
  }
  if (value.includes('text')) {
    mimeIcon = Description;
  }
  if (value.includes('image')) {
    mimeIcon = Image;
  }
  if (value.includes('application')) {
    mimeIcon = Description;
  }

  return <Icon data-test={'fileType-icon'} className={className} component={mimeIcon} />;
};

export default IconMimeHelper;
