import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { hidePanel } from '@aiware/js/panel';
import { getAdminCenterGroupsModule } from '../../redux';
import { EMemberType, TAuthGroupMemberType, TUserGroup } from '../../types';
import { actions } from '../../redux/slices';
import AutoComplete from '../components/GroupMembers/AutoComplete';
import useStepData from '../NewGroupPanel/helpers/useStepData';
import { SHARED_TEXT } from '../../helper/shared-text';
import Header from './header';
import { styles } from './styles';
import { filterDefinedMemberRecords } from '../../helper';

interface MemberPanelProps {
  initGroup: Partial<TUserGroup>;
  groupId: string;
}

export const AddMemberPanel: FC<MemberPanelProps> = ({ initGroup, groupId }) => {
  const panelId = 'ADMIN_CENTER_ADD_MEMBER_PANEL';
  const { classes } = styles();
  const { group, addMember, removeMember } = useStepData(initGroup);
  const dispatch = useDispatch();

  const handleAddMembers = () => {
    const id = groupId;
    dispatch(
      actions.adminCenterGroups.addMembersToUserGroupStart({
        id,
        members: filterDefinedMemberRecords(group).map(
          member =>
            ({
              id: member.id,
              memberType: EMemberType.User,
            } as TAuthGroupMemberType)
        ),
      })
    );
    dispatch(hidePanel(panelId));
  };

  return (
    <AIWareThemeProvider>
      <DynamicModuleLoader modules={[getAdminCenterGroupsModule()]}>
        <AIWareIntlProvider>
          <Box data-test="admin-center-add-member-panel" className={classes.root}>
            <Header height={54} panelId={panelId} />
            <Box className={classes.addMembers} data-test="admin-center-add-members">
              <Typography
                data-testid="add-member-description"
                data-test="add-member-description"
                variant={'body2'}
                className={classes.titleDescription}
              >
                {SHARED_TEXT.titleDescription}
              </Typography>
              <Typography
                variant={'h3'}
                className={classes.groupMembersTitle}
                data-testid="admin-center-add-member-title"
                data-test="admin-center-add-member-title"
              >
                {SHARED_TEXT.groupMembersTitle}
              </Typography>
              <Typography
                variant={'h3'}
                className={classes.addMembersSubTitle}
                data-testid="admin-center-add-member-sub-title"
                data-test="admin-center-add-member-sub-title"
              >
                {SHARED_TEXT.addMembersSubTitle}
              </Typography>
            </Box>
          </Box>
          <div className={classes.secondBox}>
            <AutoComplete
              group={group}
              onAddMember={addMember}
              onRemoveMember={removeMember}
              groupId={groupId}
            />
          </div>
          <Box className={classes.btnContainer}>
            <Button color="inherit" onClick={() => dispatch(hidePanel(panelId))} className={classes.buttons}>
              {SHARED_TEXT.cancelButton}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleAddMembers}
              className={classes.buttons}
              data-testid="user-group-details-add-member-btn"
              data-test="user-group-details-add-member-btn"
            >
              {SHARED_TEXT.addMembersBtn}
            </Button>
          </Box>
        </AIWareIntlProvider>
      </DynamicModuleLoader>
    </AIWareThemeProvider>
  );
};

export default AddMemberPanel;
