import { SVGProps } from 'react';

export function PersonalProfileTabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={28} height={24} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.496 14.44c-.053.55-.086 1.1-.163 1.646-.421 3.023-1.656 5.631-3.686 7.83a.905.905 0 01-.083.071c-1.434-4.513-4.393-7.061-8.967-7.645 1.75-.267 3.121-1.118 4.056-2.647.943-1.543 1.13-3.21.587-4.944-.829-2.644-3.289-4.227-5.996-3.895-2.47.303-4.534 2.574-4.76 5.156-.272 3.091 1.869 6 4.866 6.336-4.515.567-7.472 3.116-8.924 7.652-.147-.162-.303-.323-.448-.496C1.853 20.992.694 18.067.54 14.712a2.697 2.697 0 00-.04-.275v-.674c.018-.09.032-.182.04-.275.121-2.839.972-5.42 2.594-7.697C5.389 2.62 8.404.743 12.15.165c.505-.078 1.017-.11 1.526-.165h.648c.079.019.158.032.238.04 2.496.11 4.798.866 6.865 2.326 3.34 2.358 5.31 5.625 5.915 9.803.076.526.105 1.06.158 1.59v.677l-.004.003z"
        fill="#555F7C"
      />
    </svg>
  );
}
