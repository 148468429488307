import { SLICE_NAME, TApplicationConfigSetConfigInput, TApplicationConfig } from '../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'userApplicationSettings';

export interface IApplicationConfigState {
  applicationConfig: TApplicationConfig[];
  status: LoadingStatus;
}

export const initialState: IApplicationConfigState = {
  applicationConfig: [],
  status: 'idle',
};

export const userApplicationSettingsSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchApplicationConfigStart(state, action: PayloadAction<{ appId: string }>) {
      state.status = 'loading';
    },
    fetchApplicationConfigSuccess(state, action: PayloadAction<TApplicationConfig[]>) {
      state.status = 'success';
      state.applicationConfig = action.payload;
    },
    fetchApplicationConfigFailure(state) {
      state.status = 'failure';
    },
    updateApplicationConfigStart(state, action: PayloadAction<TApplicationConfigSetConfigInput>) {
      state.status = 'loading';
    },
    updateApplicationConfigSuccess(state, action: PayloadAction<TApplicationConfig[]>) {
      state.status = 'success';
      const result = state.applicationConfig.map(item =>
        action.payload.some(({ configKey }) => configKey === item.configKey)
          ? {
              ...item,
              ...action.payload.find(({ configKey }) => configKey === item.configKey),
            }
          : item
      );
      state.applicationConfig = result;
    },
    updateApplicationConfigFailure(state) {
      state.status = 'failure';
    },
  },
});

export const actions = userApplicationSettingsSlice.actions;
export default userApplicationSettingsSlice.reducer;
