import PanelHeader from '../PanelHeader/PanelHeader';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel, mountPanel } from '@aiware/js/panel';
import { FullScreenContainer } from '@aiware/ui';
import { IframeContainer } from '../../../../shared/IframeContainer';
import { useStyles } from './useStyles';
import { useEffect, useState } from 'react';
import {
  configSelector,
  selectApiConfigs,
  selectIsFullscreenEnabled,
  selectIsOLPEnabled,
} from '@aiware/shared/redux';
import { downloadTdoByEntityId } from '../../../../../helpers/sharedFunctions';
import { selectors, EAuthResourceType } from '@aiware/shared/permissions';

export const PanelContent = ({
  entityId,
  iframeUrl,
  fileName,
}: {
  dataId: string;
  panelRendered: boolean;
  appId: string;
  connectorId: string;
  entityId: string;
  iframeUrl?: string;
  fileName: string;
}) => {
  const panelId = 'DATA_CENTER_MDP_IFRAME_PANEL';
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [isDimmed, setIsDimmed] = useState(false);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const apiConfigs = useSelector(selectApiConfigs);
  const entityPermissions = useSelector(selectors.selectPermissionsByEntity(EAuthResourceType.TDO, entityId));
  const isOLPEnabled = useSelector(selectIsOLPEnabled);
  const configs = useSelector(configSelector);
  const mediaDetailsUrl = iframeUrl || `${configs.cmsAppUrl}/embed/#/media-details/${entityId}`;

  const isPermissionsLoading = !isOLPEnabled
    ? false
    : !entityPermissions || entityPermissions.status === 'pending' || entityPermissions.status === 'idle';

  useEffect(() => {
    setIsDimmed(false);
  }, []);

  const handleProcessAi = () => {
    const microFrontend = {
      name: 'DATA_CENTER_PROCESS_AI',
      config: {
        name: 'DATA CENTER PROCESS AI',
        tdoId: entityId,
        parentPanelId: panelId,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'small',
      parentPanelId: panelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: 'DATA_CENTER_PROCESS_AI',
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };
  const handleViewInfo = () => {
    const infoPanelId = 'DATA_CENTER_FILE_INFO_PANEL';
    const microFrontend = {
      name: infoPanelId,
      config: {
        name: 'DATA CENTER FILE INFO PANEL',
        entityId: entityId,
        parentPanelId: panelId,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'small',
      parentPanelId: panelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: infoPanelId,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };
  const handleDownloadAsset = () => {
    // @ts-ignore
    downloadTdoByEntityId(apiConfigs.apiRoot, entityId, fileName);
  };

  return (
    <FullScreenContainer
      fullScreen={isFullScreen}
      preventRerender={true}
      containerClassName={classes.container}
      dimmed={isDimmed}
      dataTest={'dc-media-details-panel-iframe'}
    >
      <PanelHeader
        entityId={entityId}
        height={64}
        onProcessAi={handleProcessAi}
        onDownloadAsset={handleDownloadAsset}
        onViewInfo={handleViewInfo}
        onPanelClose={() => dispatch(hidePanel(panelId))}
        entityPermissions={entityPermissions}
        isOLPEnabled={isOLPEnabled}
        isPermissionsLoading={isPermissionsLoading}
      />
      <IframeContainer
        isOLPEnabled={isOLPEnabled}
        permissions={entityPermissions}
        src={mediaDetailsUrl}
        name={'media-details-iframe-embed'}
        title={'media-details-iframe-embed'}
        passAuthToken={true}
      />
    </FullScreenContainer>
  );
};
