import { LoadingStatus } from '@aiware/js/interfaces';
import { Skeleton, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

export const renderWhenReady = (loadingStatus: LoadingStatus, component: ReactNode, skeleton?: boolean) => {
  if (loadingStatus === 'success' || loadingStatus === 'idle' || loadingStatus === 'failure')
    return component;
  if (skeleton) {
    return (
      <>
        {[1, 2, 3].map(el => {
          return (
            <div key={el}>
              <br />
              <Skeleton variant="rectangular" />
            </div>
          );
        })}
      </>
    );
  }
  return null;
};

// mm/dd/yyyy
export const dateFormat = (dateString: string | null) => {
  const date = new Date(dateString || '');
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  );
};
