import { actions } from '../redux/slices';
import { SHARED_TEXT } from '../helpers/shared-text';
import { EntityType } from '@aiware/js/interfaces';
import { Edit, Information, Process, Download } from '@aiware/shared/icons';
import { EntityContextMenu, EntityTypesWithActions } from './index';
import { EPermissionAction } from '@aiware/shared/permissions';
import { EditOutlined, ToggleOnOutlined, ToggleOffOutlined } from '@mui/icons-material';
export const ENTITY_CONTEXT_MENU: Record<EntityTypesWithActions, EntityContextMenu> = {
  [EntityType.Folders]: {
    actions: [
      {
        Icon: Edit,
        name: SHARED_TEXT.editFolder,
        testLabel: 'edit-folder',
        description: 'Edit Folder',
        action: actions.panelEditFolder.openEditFolder,
        permissionRequired: EPermissionAction.Update,
      },
    ],
  },
  [EntityType.Tdos]: {
    actions: [
      {
        Icon: Process,
        name: SHARED_TEXT.process,
        description: 'Process Tdo',
        testLabel: 'process-tdo',
        action: actions.tdos.process,
        permissionRequired: EPermissionAction.Update,
      },
      {
        Icon: Information,
        name: SHARED_TEXT.viewFileInfo,
        description: 'View File Info',
        testLabel: 'view-file-info',
        action: actions.tdos.viewInfo,
        permissionRequired: EPermissionAction.Read,
      },
      {
        Icon: EditOutlined,
        name: SHARED_TEXT.editMetadata,
        description: 'Edit Metadata',
        testLabel: 'edit-metadata',
        action: actions.tdos.editMetadata,
        permissionRequired: EPermissionAction.Update,
      },
      {
        Icon: Download,
        name: SHARED_TEXT.download,
        description: 'Download',
        testLabel: 'download-file',
        action: actions.tdos.download,
        permissionRequired: EPermissionAction.Read,
      },
    ],
    moveAction: actions.tdos.moveTdoStart,
    deleteAction: actions.tdos.deleteTdoStart,
  },
  [EntityType.Sources]: {
    actions: [
      {
        Icon: Information,
        name: SHARED_TEXT.viewInfo,
        description: 'View Info',
        testLabel: 'view-info-source',
        action: actions.sources.viewSource,
      },
      {
        Icon: Edit,
        name: SHARED_TEXT.editSource,
        description: 'Edit Source',
        testLabel: 'edit-source',
        action: actions.sources.editSource,
      },
    ],
  },
  [EntityType.Schedules]: {
    actions: [
      {
        Icon: Information,
        name: SHARED_TEXT.viewInfo,
        description: 'View Info',
        testLabel: 'view-info-schedule',
        action: actions.schedules.viewSchedule,
      },
      {
        Icon: Edit,
        name: SHARED_TEXT.editSchedule,
        description: 'Edit Schedule',
        testLabel: 'edit-schedule',
        action: actions.schedules.editSchedule,
        permissionRequired: EPermissionAction.Update,
      },
      {
        Icon: ToggleOnOutlined,
        name: SHARED_TEXT.activateSchedule,
        description: 'Activate Schedule',
        testLabel: 'activate-schedule',
        action: actions.schedules.activateSchedule,
        permissionRequired: EPermissionAction.Update,
      },
      {
        Icon: ToggleOffOutlined,
        name: SHARED_TEXT.deactivateSchedule,
        description: 'Deactivate Schedule',
        testLabel: 'deactivate-schedule',
        action: actions.schedules.deactivateSchedule,
        permissionRequired: EPermissionAction.Update,
      },
    ],
  },
};
