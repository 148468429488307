import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILibrary } from '../../types/getLibraryIds.types';
import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'libraries';

export type State = {
  status: LoadingStatus;
  libraryIds: ILibrary[];
};

export const initialState = {
  status: 'pending',
  libraryIds: [],
} as State;

const getLibraryIdsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getLibraryIdsStart(state) {
      state.status = 'pending';
    },
    getLibraryIdsSuccess(
      state,
      action: PayloadAction<{
        libraryIds: ILibrary[];
      }>
    ) {
      const { libraryIds } = action.payload;
      state.libraryIds = libraryIds;
      state.status = 'idle';
    },
    getLibraryIdsFailed(state) {
      state.status = 'failure';
    },
  },
});

export const getLibraryIdsActions = getLibraryIdsSlice.actions;
export default getLibraryIdsSlice.reducer;
