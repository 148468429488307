import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

interface IState {
  aiWareHeaderBarPanel: {
    activities: {
      records: Array<{ createdDateTime: string }>;
      filter: string;
    };
  };
}

export const namespace = 'activities';
export const allActivitySeletor = (state: IState) => state?.aiWareHeaderBarPanel?.[namespace] || [];

const today = DateTime.local().startOf('day');

export const activitySelector: (state: IState) => Array<{ createdDateTime: string }> = createSelector(
  [allActivitySeletor],
  activity => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { records, filter } = activity;
    if (filter === 'today') {
      return records.filter((item: { createdDateTime: string }) =>
        DateTime.fromISO(item.createdDateTime).hasSame(today, 'day')
      );
    }
    return records;
  }
);
