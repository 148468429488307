import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import { Badge, Divider, IconButton, Typography, Tooltip, MenuItem, Menu, Stack } from '@mui/material';
import { WidgetsOutlined, Folder, FolderOff, CancelOutlined } from '@mui/icons-material';
import { selectRootFolderId, selectSelectedItems } from '../../redux/selectors/view-my-files';
import { selectFolderEntities, selectTdoEntities } from '../../redux/selectors/entities';
import { ITdo, ITdoGraphQL, ITdoVm } from '../../types';
import { actions } from '../../redux/slices';
import IconMimeHelper from '../shared/IconMimeHelper';

export const SelectedItemsActionButton = () => {
  const dispatch = useDispatch();
  // UI State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = 'selected-items-menu';
  const handleClick = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // TDO & Folder State
  const selectedItems = useSelector(selectSelectedItems);
  const allTDOs = useSelector(selectTdoEntities);
  const allFolders = useSelector(selectFolderEntities);
  const rootFolderId = useSelector(selectRootFolderId);
  const getTDOsById = (ids: string[]) => {
    const result: ITdo[] = [];
    ids.forEach(id => {
      allTDOs[id] && result.push(allTDOs[id]!);
    });
    return result;
  };
  const selectedTDOs = getTDOsById(selectedItems);
  // Build a collection of TDOs in their folders
  const folderObject = {};
  selectedTDOs.forEach(tdo => {
    let { parentFolderId } = tdo;
    if (!parentFolderId && rootFolderId) {
      parentFolderId = rootFolderId;
    }
    if (!parentFolderId) {
      parentFolderId = 'undefined';
    }
    if ((folderObject as any)[parentFolderId]) {
      (folderObject as any)[parentFolderId].tdos.push(tdo);
    } else {
      const name = allFolders[parentFolderId]?.name;
      (folderObject as any)[parentFolderId] = {
        name: name ?? 'Unnamed Folder',
        tdos: [tdo],
      };
    }
  });
  const isMultipleOriginFolders = Object.keys(folderObject).length > 1;

  const handleClearSelected = () => {
    handleClose();
    setTimeout(() => dispatch(actions.viewMyFiles.resetSelectedItems()), 300);
  };

  const handleDeselectTDO = (id: string) => {
    const deselect = () => {
      dispatch(actions.viewMyFiles.removeSelectedItem(id));
      dispatch(actions.viewMyFiles.toggleFileOrFolderSelected(id));
    };

    setTimeout(deselect, 300);
  };

  const renderArrayTDOs = (tdos: ITdo[]) => {
    return (
      <>
        {tdos.map((tdo, index) => {
          const name = tdo.name || tdo.id;
          const maxLen = 32;
          const isTruncated = name.length > maxLen;

          return (
            <MenuItem
              sx={{
                '&:active': {
                  opacity: 0.25,
                },
                transition: 'all 0.2s ease-in-out',
                textTransform: 'initial',
                paddingRight: '4px',
                '& .selected-item-remove-cross': {
                  opacity: 0.15,
                },
                '&:hover .selected-item-remove-cross': {
                  opacity: 1,
                },
                '&:hover .selected-item-tdo-icon': {
                  color: '#1871E8',
                },
                '&:hover .selected-item-tdo-name': {
                  color: '#1871E8',
                },
              }}
              key={index}
            >
              <Stack
                sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                direction={'row'}
              >
                <Stack gap={'10px'} direction={'row'}>
                  <IconMimeHelper
                    className={'selected-item-tdo-icon'}
                    value={(tdo as ITdoVm).fileType || (tdo as ITdoGraphQL)?.primaryAsset?.contentType}
                  />
                  <Tooltip arrow title={isTruncated ? name : ''} disableInteractive>
                    <Typography className={'selected-item-tdo-name'}>
                      {truncateString(name, maxLen)}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Tooltip title={'Deselect Item'}>
                  <IconButton
                    sx={{ marginLeft: '10px' }}
                    className={'selected-item-remove-cross'}
                    size={'small'}
                    onClick={() => handleDeselectTDO(tdo?.id)}
                  >
                    <CancelOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
            </MenuItem>
          );
        })}
      </>
    );
  };

  const renderFilesList = () => {
    if (!isMultipleOriginFolders) return renderArrayTDOs(selectedTDOs);
    return (
      <>
        {Object.keys(folderObject).map(folderId => {
          const folder = (folderObject as any)[folderId];
          if (!folder) return null;

          const handleDeleteAll = () =>
            setTimeout(
              folder.tdos.forEach((tdo: ITdo) => handleDeselectTDO(tdo?.id)),
              300
            );

          return (
            <Stack>
              <Stack
                sx={{
                  alignItems: 'center',
                  gap: '5px',
                  borderBottom: '1px solid #DADFE8',
                  width: '100%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
                direction={'row'}
              >
                <Tooltip title={'Clear all in this Folder'}>
                  <IconButton
                    onClick={handleDeleteAll}
                    sx={{
                      color: '#555F7C',
                      '& .clear-all-icon': {
                        display: 'none',
                      },
                      '&:hover .folder-icon': {
                        display: 'none',
                      },
                      '&:hover .clear-all-icon': {
                        display: 'inline',
                      },
                    }}
                    size={'small'}
                  >
                    <Folder className={'folder-icon'} />
                    <FolderOff className={'clear-all-icon'} />
                  </IconButton>
                </Tooltip>
                <Typography fontWeight={'bold'} variant={'caption'}>
                  {folder.name}
                </Typography>
              </Stack>
              <Stack>{renderArrayTDOs(folder.tdos)}</Stack>
            </Stack>
          );
        })}
      </>
    );
  };
  return (
    <>
      <Tooltip disableInteractive title={'View Selected Files'}>
        <span>
          <Typography style={visuallyHidden} id={id}>
            View Selected Files
          </Typography>
          <IconButton
            id="selected-items-action-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            aria-labelledby={id}
            data-test={`dc-action-btn-moveTdo-${id}`}
            size="large"
          >
            <Badge
              badgeContent={
                <Typography
                  variant={'caption'}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#1871E8',
                    color: 'white',
                    borderRadius: '50px',
                    padding: '1px 5px',
                  }}
                >
                  {selectedItems.length}
                </Typography>
              }
            >
              <WidgetsOutlined />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="selected-items-action-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: {
            paddingLeft: '15px',
            paddingRight: '15px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          sx={{
            marginBottom: 0,
          }}
          onClick={handleClearSelected}
        >
          Clear All Selected {selectedItems.length >= 5 ? `(${selectedItems.length})` : ''}
        </MenuItem>
        <Divider
          sx={{
            marginBottom: '0px!important',
          }}
        />
        <Stack
          sx={{
            maxHeight: '25vh',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              backgroundColor: 'transparent',
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#c4c4c4',
              borderRadius: '20px',
            },
          }}
        >
          {renderFilesList()}
        </Stack>
      </Menu>
    </>
  );
};

function truncateString(str: string, maxLen: number): string {
  if (str.length <= maxLen) {
    return str;
  }

  const ellipsis = '...';
  const remainingLength = maxLen - ellipsis.length;

  const leftHalfLength = Math.ceil(remainingLength / 2);
  const rightHalfLength = Math.floor(remainingLength / 2);

  const leftHalf = str.slice(0, leftHalfLength);
  const rightHalf = str.slice(-rightHalfLength);

  return leftHalf + ellipsis + rightHalf;
}
