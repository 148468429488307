import { createSelector } from '@reduxjs/toolkit';

const BROWSE_SLICE_NAME = 'adminCenterBrowse';
const BROWSE_NAMESPACE = 'browseDetail';

interface IBrowseState {
  organization: {
    id: number;
    guid: string;
    isUsersCurrentOrg: boolean;
  };
}

interface IState {
  [BROWSE_SLICE_NAME]: {
    [BROWSE_NAMESPACE]: IBrowseState;
  };
}

export const selectAdminCenterOrgId = (state: IState): number =>
  state?.[BROWSE_SLICE_NAME]?.[BROWSE_NAMESPACE]?.organization?.id || 0;

export const selectAdminCenterOrgGuid = (state: IState): string =>
  state?.[BROWSE_SLICE_NAME]?.[BROWSE_NAMESPACE]?.organization?.guid || '';

export const selectAdminCenterOrgIdString: (state: IState) => string = createSelector(
  [selectAdminCenterOrgId],
  orgId => orgId.toString()
);

export const selectOrgIsUsersCurrentOrg = (state: IState): boolean =>
  state?.[BROWSE_SLICE_NAME]?.[BROWSE_NAMESPACE]?.organization?.isUsersCurrentOrg || false;

// selector used to determine if the org parameter should be included in queries and mutations
export const selectAdminCenterOrgParameter: (state: IState) => string | undefined = createSelector(
  [selectAdminCenterOrgIdString, selectOrgIsUsersCurrentOrg],
  (orgId, isUsersCurrentOrg) => {
    return isUsersCurrentOrg ? undefined : orgId === '0' ? undefined : orgId;
  }
);

// Same as above but for GUID
export const selectAdminCenterOrgGuidParameter: (state: IState) => string | undefined = createSelector(
  [selectAdminCenterOrgGuid, selectOrgIsUsersCurrentOrg],
  (orgGuid, isUsersCurrentOrg) => {
    return isUsersCurrentOrg ? undefined : orgGuid;
  }
);
