import { mountPanel, mountPanelForResponse } from '@aiware/js/panel';
import { ScheduleJob, ScheduleBulk, Source, Upload } from '@aiware/shared/icons';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveFolder } from '../../redux/selectors/view-my-files';
import { selectIsFullscreenEnabled, betaFeaturesSelector } from '@aiware/shared/redux';
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { actions as BrowseActions } from '../../redux/slices';
import {
  selectors,
  actions,
  hasPermission,
  isPermissionLoading,
  EAuthResourceType,
  EPermissionAction,
  TPermissionsEntity,
} from '@aiware/shared/permissions';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { userSelector } from '@aiware/shared/redux';
import { store } from '@aiware/shared/store';
import { ISelfClosingPanelConfig } from '@aiware/js/interfaces';
import { IFileToUpdate } from '../../types';
import { AvailableComponents, AvailablePanels } from '@aiware/js/interfaces';

export const AddNewMenu = () => {
  const { classes } = makeStyles()(theme => ({
    marginRight: {
      marginRight: theme.spacing(2),
    },
  }))();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const activeFolder = useSelector(selectActiveFolder);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const orgId = useSelector(userSelector).organization?.organizationId;
  const betaFeaturesEnabled = useSelector(betaFeaturesSelector);
  const activeFolderPermissions = useSelector(
    selectors.selectPermissionsByEntity(EAuthResourceType.Folder, activeFolder?.id, EAuthResourceType.Folder)
  );
  const tdoPermissionsInOrg = useSelector(
    selectors.selectPermissionsByEntity(EAuthResourceType.Organization, String(orgId), EAuthResourceType.TDO)
  );
  const sourcePermissionsInOrg = useSelector(
    selectors.selectPermissionsByEntity(
      EAuthResourceType.Organization,
      String(orgId),
      EAuthResourceType.Source
    )
  );

  const jobPermissionsInOrg = useSelector(
    selectors.selectPermissionsByEntity(EAuthResourceType.Organization, String(orgId), EAuthResourceType.Job)
  );

  useEffect(() => {
    if (
      orgId &&
      (!sourcePermissionsInOrg ||
        sourcePermissionsInOrg.status === 'idle' ||
        sourcePermissionsInOrg.status === 'failure' ||
        !jobPermissionsInOrg ||
        jobPermissionsInOrg.status === 'idle' ||
        jobPermissionsInOrg.status === 'failure' ||
        !tdoPermissionsInOrg ||
        tdoPermissionsInOrg.status === 'idle' ||
        tdoPermissionsInOrg.status === 'failure')
    ) {
      dispatch(
        actions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.Organization,
          entityId: String(orgId),
        })
      );
    }
    if (
      activeFolder?.id &&
      (!activeFolderPermissions ||
        activeFolderPermissions.status === 'idle' ||
        activeFolderPermissions.status === 'failure')
    ) {
      dispatch(
        actions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.Folder,
          entityId: activeFolder.id,
        })
      );
    }
  }, [
    activeFolder?.id,
    dispatch,
    jobPermissionsInOrg,
    orgId,
    sourcePermissionsInOrg,
    tdoPermissionsInOrg,
    activeFolderPermissions,
  ]);

  const handleClickAddFile = async () => {
    handleClose();
    const microFrontend = {
      name: AvailableComponents.DATA_CENTER_IMPORTER,
      config: {
        name: 'Local Importer',
        activeFolder,
      },
    };

    const panelConfig: ISelfClosingPanelConfig = {
      panelId: AvailableComponents.DATA_CENTER_IMPORTER,
      type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'large',
      parentPanelId: AvailableComponents.DATA_CENTER,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };

    const newFiles: [IFileToUpdate] = await mountPanelForResponse(
      {
        panelId: AvailableComponents.DATA_CENTER_IMPORTER,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      },
      store
    );

    if (newFiles.length > 0) {
      newFiles.forEach((file: IFileToUpdate) => {
        dispatch(
          BrowseActions.viewMyFiles.addTdoFolder({
            folderId: file.folderId,
            tdoId: file.tdoId,
          })
        );
        dispatch(
          BrowseActions.entities.addTdoImported({
            file: file.file,
          })
        );
      });
    }
  };

  const handleClickAddSource = () => {
    handleClose();
    const microFrontend = {
      name: AvailableComponents.PROCESSING_CENTER_SOURCES,
      config: {},
    };

    const panelConfig = {
      type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'large',
      parentPanelId: AvailableComponents.DATA_CENTER,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };

    dispatch(
      mountPanel({
        panelId: AvailableComponents.PROCESSING_CENTER_SOURCES,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  const handleClickAddIngestion = () => {
    handleClose();
    const microFrontend = {
      name: AvailableComponents.DATA_CENTER_SELECT_IMPORT_SOURCE,
      config: {
        name: 'Select Import Source',
      },
    };

    const panelConfig = {
      type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'small',
      parentPanelId: AvailableComponents.DATA_CENTER,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };

    dispatch(
      mountPanel({
        panelId: AvailableComponents.DATA_CENTER_SELECT_IMPORT_SOURCE,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
    return;
  };

  const handleClickBulkScheduler = async () => {
    handleClose();
    const microFrontend = {
      name: AvailableComponents.PROCESSING_CENTER_BULK_PROVISIONING_PANEL,
      config: {},
    };

    const panelConfig: ISelfClosingPanelConfig = {
      header: {
        divider: true,
        title: 'Bulk Schedule Creator',
      },
      panelId: AvailableComponents.PROCESSING_CENTER_BULK_PROVISIONING_PANEL,
      type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'medium',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      disableHide: true,
    };

    const bulkProvisioning: boolean = await mountPanelForResponse(
      {
        panelId: AvailableComponents.PROCESSING_CENTER_BULK_PROVISIONING_PANEL,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      },
      store
    );

    if (bulkProvisioning) {
      dispatch(BrowseActions.viewStreams.refreshSourcesSchedulesTdos());
    }

    return;
  };

  const renderMenuItemWithPermissions = (menuItem: {
    onClick: () => void;
    icon: React.ComponentType<any>;
    testId: string;
    text: any;
    permissions: {
      operator: 'and' | 'or';
      entries: {
        permissionRequired: EPermissionAction;
        relevantPermissions: TPermissionsEntity | undefined;
      }[];
    };
  }) => {
    const { onClick, icon, testId, text, permissions } = menuItem;
    const Icon = icon; // so we can use it in JSX form
    const processedPermissions = permissions.entries.map(entry => {
      const { relevantPermissions, permissionRequired } = entry;
      return {
        hasPermission: hasPermission(relevantPermissions, permissionRequired),
        isLoading: isPermissionLoading(relevantPermissions),
      };
    });
    const isAnyPermissionStillLoading = processedPermissions.some(perm => perm.isLoading === true);
    const permissionCheckFunction = permissions.operator === 'and' ? 'every' : 'some';
    const isAllowed = processedPermissions[permissionCheckFunction](perm => {
      return perm.hasPermission === true;
    });
    let tooltip;

    if (isAnyPermissionStillLoading) {
      tooltip = SHARED_TEXT.checkingPermissionsTooltip();
    } else if (!isAllowed) {
      tooltip = SHARED_TEXT.noPermissionsTooltip();
    }
    const toRender = (
      <MenuItem data-testid={testId} onClick={onClick} disabled={isAnyPermissionStillLoading || !isAllowed}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </MenuItem>
    );

    return tooltip ? (
      <Tooltip title={tooltip}>
        <span>{toRender}</span>
      </Tooltip>
    ) : (
      toRender
    );
  };

  return (
    <>
      <Button
        className={classes.marginRight}
        onClick={handleClick}
        color="primary"
        variant="contained"
        disableRipple
        data-testid="dc-add-new-header-button"
        id="dc-add-new-header-button"
      >
        <FormattedMessage
          id="os-data-center-browse.header.add-new-btn"
          defaultMessage="New"
          description="Label of the button that allows user to open the add new menu"
        />
      </Button>
      <Menu
        id="dc-add-new-menu"
        data-testid="dc-add-new-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dc-add-new-header-button',
        }}
      >
        {renderMenuItemWithPermissions({
          testId: 'dc-add-new-file-button',
          onClick: handleClickAddFile,
          icon: Upload,
          permissions: {
            operator: 'and',
            entries: [
              {
                permissionRequired: EPermissionAction.Create,
                relevantPermissions: tdoPermissionsInOrg,
              },
              {
                permissionRequired: EPermissionAction.File,
                relevantPermissions: activeFolderPermissions,
              },
            ],
          },
          text: (
            <FormattedMessage
              id="os-data-center-browse.header.add-new-btn.files"
              defaultMessage="File Import"
              description="Label of a button that allows user to upload a file from their local computer"
            />
          ),
        })}
        <Divider />
        {renderMenuItemWithPermissions({
          testId: 'dc-add-new-source-button',
          onClick: handleClickAddSource,
          icon: Source,
          permissions: {
            operator: 'and',
            entries: [
              {
                permissionRequired: EPermissionAction.Create,
                relevantPermissions: sourcePermissionsInOrg,
              },
            ],
          },
          text: (
            <FormattedMessage
              id="os-data-center-browse.header.add-new-btn.sources"
              defaultMessage="Source"
              description="Label of a button that allows user to add a new source"
            />
          ),
        })}
        {renderMenuItemWithPermissions({
          testId: 'dc-add-new-job-button',
          onClick: handleClickAddIngestion,
          icon: ScheduleJob,
          permissions: {
            operator: 'and',
            entries: [
              {
                permissionRequired: EPermissionAction.Create,
                relevantPermissions: jobPermissionsInOrg,
              },
            ],
          },
          text: (
            <FormattedMessage
              id="os-data-center-browse.header.add-new-btn.jobs"
              defaultMessage="Schedule Job"
              description="Label of a button that allows user to add new Job"
            />
          ),
        })}
        {betaFeaturesEnabled &&
          renderMenuItemWithPermissions({
            testId: 'dc-add-new-bulk-job-button',
            onClick: handleClickBulkScheduler,
            icon: ScheduleBulk,
            permissions: {
              operator: 'and',
              entries: [
                {
                  permissionRequired: EPermissionAction.Create,
                  relevantPermissions: sourcePermissionsInOrg,
                },
                {
                  permissionRequired: EPermissionAction.Create,
                  relevantPermissions: jobPermissionsInOrg,
                },
              ],
            },
            text: (
              <FormattedMessage
                id="os-data-center-browse.header.add-new-btn.bulk-scheduler"
                defaultMessage="Bulk Scheduler"
                description="Label of a button that allows user to bulk provision jobs"
              />
            ),
          })}
      </Menu>
    </>
  );
};
