import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { OrgFilled } from '@aiware/shared/icons';
import useStyles from './useStyles';
import { IOpenIdConnector } from '../../../../../types';
import OpenIdConnectorButton from './OpenIdConnectorButton';

export interface IOpenIdConnectorsProp {
  openIdItems: IOpenIdConnector[];
  onOpenUnlinkModal: (openId: IOpenIdConnector) => void;
  onClickOpenIdUrl: (openId: IOpenIdConnector) => void;
  isOpenIdConnected: (id: string) => boolean;
}

function OpenIdConnectors({
  openIdItems,
  onOpenUnlinkModal,
  onClickOpenIdUrl,
  isOpenIdConnected,
}: IOpenIdConnectorsProp) {
  const { classes } = useStyles();
  return (
    <List>
      {openIdItems.map((openId: IOpenIdConnector, index: number) => {
        const { name, loginButtonStyle } = openId;
        const btnLogo = loginButtonStyle?.btnLogo;
        return (
          <Fragment>
            <ListItem className={classes.openIdItem}>
              <ListItemAvatar className={classes.listAvatar}>
                <Avatar className={classes.openIdLogo} src={btnLogo}>
                  <OrgFilled />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Tooltip
                    // empty title disables tooltip
                    title={name.length > 25 ? name : ''}
                    placement="bottom-end"
                  >
                    <Typography variant="body1" className={classes.accountLinkingLabel}>
                      {name.length > 25 ? name.substring(0, 25) + '...' : name}
                    </Typography>
                  </Tooltip>
                }
              />
              <ListItemSecondaryAction className={classes.secondaryAction}>
                {
                  <OpenIdConnectorButton
                    isConnected={isOpenIdConnected(openId.connectId)}
                    onLink={() => onClickOpenIdUrl(openId)}
                    onUnlink={() => onOpenUnlinkModal(openId)}
                  />
                }
              </ListItemSecondaryAction>
            </ListItem>
            {index !== openIdItems.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </List>
  );
}

export default OpenIdConnectors;
