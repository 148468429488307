import { useState, useCallback } from 'react';
import { TPermissionSet } from '../../../types';

type permissionSet = Partial<TPermissionSet> & Pick<TPermissionSet, 'name' | 'description'>;

export const BASIC_STEP = 0;
export const PERMISSION_STEP = 1;

export const useStepData = (initPermissionSet: Partial<TPermissionSet> | undefined) => {
  const [permissionSet, setPermissionSet] = useState<permissionSet>({
    name: '',
    description: '',
    ...initPermissionSet,
  });

  const setFuncPermissions = useCallback((value: string[]) => {
    setPermissionSet(previousPermissionSet => ({
      ...previousPermissionSet,
      permissions: value,
    }));
  }, []);

  const setName = useCallback((value: string) => {
    setPermissionSet(previousPermissionSet => ({
      ...previousPermissionSet,
      name: value,
    }));
  }, []);

  const setDescription = useCallback((value: string) => {
    setPermissionSet(previousPermissionSet => ({
      ...previousPermissionSet,
      description: value,
    }));
  }, []);

  const validateStep = useCallback(
    (index: number) => {
      if (index === BASIC_STEP) {
        return !!(permissionSet.name?.length && permissionSet.description?.length);
      }

      if (index === PERMISSION_STEP) {
        return !!permissionSet.permissions?.length;
      }

      return false;
    },
    [permissionSet]
  );

  return {
    permissionSet: {
      ...permissionSet,
    },
    setName,
    setDescription,
    setFuncPermissions,
    validateStep,
  };
};

export default useStepData;
