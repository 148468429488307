import { Tooltip, Typography, useTheme } from '@mui/material';
import { panelComponents, devErrorLogger } from '@aiware/shared/reusable-utils';
import { useDispatch } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import React, { useState } from 'react';
import { SHARED_TEXT } from '../../../helpers/shared-text';
import { DATA_CENTER_DATA_REGISTRY_INFO_ID } from '../../../constants';
import { TDataRegistryInfoProps } from '../index';
import { makeStyles } from 'tss-react/mui';
import { CheckCircle, ContentCopy } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

export const TooltipOnTextOverflow = ({
  title,
  maxCharLength,
  children,
}: {
  title: string;
  maxCharLength: number;
  children: JSX.Element;
}): JSX.Element => {
  if (title.length > maxCharLength) {
    return <Tooltip title={title}>{children}</Tooltip>;
  } else {
    return children;
  }
};

interface IProps {
  id: string;
}
export const IdCopy = ({ id }: IProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();

  const iconSx = {
    marginLeft: '5px',
    fontSize: '18px!important',
    verticalAlign: 'middle',
    '&:active': {
      color: theme.palette.action.active,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={id}>
        <Typography style={{ maxWidth: '90px' }} data-test={'data-detail-tdo-id'} noWrap variant="body2">
          {id}
        </Typography>
      </Tooltip>
      <Tooltip
        title={
          isCopied ? (
            <FormattedMessage
              id="os-data-center-browse.data-registry-panel.data-registry-data-type.copied.tdo"
              defaultMessage="Copied!"
              description="Data Registry Panel, Copied - tooltip"
            />
          ) : (
            <FormattedMessage
              id="os-data-center-browse.data-registry-panel.data-registry-data-type.copy.tdo"
              defaultMessage="Copy TDO ID "
              description="Data Registry Panel, Copy Tdo id - tooltip"
            />
          )
        }
        disableInteractive
      >
        <span
          onMouseLeave={() => setTimeout(() => setIsCopied(false), 300)}
          onClick={e => {
            setIsCopied(true);
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(id).catch(error => {
              devErrorLogger(`Error writing text to clipboard: ${JSON.stringify(error)}`);
            });
          }}
        >
          {!isCopied && <ContentCopy sx={iconSx} />}
          {isCopied && <CheckCircle sx={iconSx} />}
        </span>
      </Tooltip>
    </div>
  );
};

export const formatFileInfoDate = (date: Date) => {
  const [month, day, year] = date.toLocaleDateString('en-us').split('/') as [string, string, string];
  const [time, AMPM] = date.toLocaleTimeString('en-us').split(' ') as [string, string];

  return `${month}/${day}/${year.slice(2)} ${time.slice(0, -3)} ${AMPM}`;
};

const { header: PanelHeader, container: PanelContainer, content: PanelContent } = panelComponents;

export const MainLayout: React.FC<TDataRegistryInfoProps> = ({ dataRegistry }) => {
  const dispatch = useDispatch();

  const { classes } = makeStyles()(_theme => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    childItem: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      marginBottom: '20px',
    },
    labelFont: {
      fontWeight: '400',
      color: '#5C6269',
    },
  }))();

  if (!dataRegistry) return null;
  const handleClose = () => {
    dispatch(hidePanel(DATA_CENTER_DATA_REGISTRY_INFO_ID));
  };

  return (
    <PanelContainer panelId={DATA_CENTER_DATA_REGISTRY_INFO_ID}>
      <PanelHeader
        title={SHARED_TEXT.dataRegistryInfo.title()}
        testId={'sdo-viewer-panel-panel'}
        onPanelClose={handleClose}
        PanelActions={<></>}
      />
      <PanelContent style={{ padding: '30px', overflowY: 'hidden' }}>
        <div className={classes.container}>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-registry-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.dataRegistryInfo.name()}
            </Typography>
            <span>
              {' '}
              <TooltipOnTextOverflow title={dataRegistry?.name} maxCharLength={18}>
                <Typography
                  data-test={`dc-registry-panel-info-panel-item-name`}
                  noWrap={true}
                  variant="body2"
                >
                  {dataRegistry?.name}
                </Typography>
              </TooltipOnTextOverflow>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-registry-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.dataRegistryInfo.id()}
            </Typography>
            <span>
              {' '}
              <TooltipOnTextOverflow title={dataRegistry?.id} maxCharLength={18}>
                <Typography data-test={`dc-registry-panel-info-panel-item-id}`} noWrap={true} variant="body2">
                  <IdCopy id={dataRegistry?.id} />
                </Typography>
              </TooltipOnTextOverflow>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-registry-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.dataRegistryInfo.created()}
            </Typography>
            <span>
              {' '}
              <Typography
                data-test={`dc-registry-panel-info-panel-item-created-time`}
                noWrap={true}
                variant="body2"
              >
                {formatFileInfoDate(new Date(dataRegistry?.createdDateTime))}
              </Typography>
            </span>
          </div>
          <div className={classes.childItem}>
            <Typography
              data-test={`dc-registry-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.dataRegistryInfo.modified()}
            </Typography>
            <span>
              {' '}
              <Typography
                data-test={`dc-registry-panel-info-panel-item-modifiedTime`}
                noWrap={true}
                variant="body2"
              >
                {formatFileInfoDate(new Date(dataRegistry?.modifiedDateTime))}
              </Typography>
            </span>
          </div>
        </div>
      </PanelContent>
    </PanelContainer>
  );
};
