import createSvgIcon from '../createSvgIcon';
export const Bookmark = createSvgIcon(
  <svg width="15" height="20" viewBox="-4 1 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 0.5H1.5C0.675 0.5 0 1.175 0 2V14C0 14.825 0.675 15.5 1.5 15.5H10.5C11.325 15.5 12 14.825 12 14V2C12 1.175 11.325 0.5 10.5 0.5ZM3.75 2H5.25V5.75L4.5 5.1875L3.75 5.75V2ZM10.5 14H1.5V2H2.25V8.75L4.5 7.0625L6.75 8.75V2H10.5V14Z"
      fill="#555F7C"
    />
  </svg>,
  'Bookmark'
);
