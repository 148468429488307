import { useState } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { Box, Typography, Button, Stack } from '@mui/material';
import useStyles from '../useStyles';
import { imageUpload } from '@aiware/shared/reusable-utils';

const {
  jsx: { ImageUpload },
} = imageUpload;

export interface IEditProfileProp {
  onClose: () => void;
  onSaveAvatar: (file: File) => void;
  avatarHistory: string[];
  children?: unknown;
}

function EditProFilePicture({ onClose, onSaveAvatar, avatarHistory }: IEditProfileProp) {
  const { classes } = useStyles();
  const [file, setFile] = useState<File | null>(null);

  const handleSaveCroppedImage = () => {
    if (file) {
      onSaveAvatar(file);
      return;
    }
  };

  const handleClosePanel = () => {
    onClose?.();
  };

  const handleImageUpload = (value: unknown) => {
    setFile(value as File);
  };

  return (
    <AIWareIntlProvider>
      <div className={classes.editProfilePictureContainer}>
        <Box className={classes.contentUserView}>
          <Box className={classes.headerDrawer}>
            <Typography
              variant="h1"
              className={classes.mainTitleText}
              data-test="edit-profile-picture-header"
            >
              <FormattedMessage
                id="os-app-bar-panel.npW7Np"
                defaultMessage="Edit Profile Picture"
                description="The title of edit profile picture panel"
              />
            </Typography>
          </Box>
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
              height: '450px',
            }}
          >
            <ImageUpload
              outerWidth={'80%'}
              outerHeight={'80%'}
              innerWidth={'95%'}
              innerHeight={'95%'}
              uploadIconWidthHeight={'80px'}
              handleChange={handleImageUpload}
              testId="profile-edit-profile-image"
            />
          </Stack>
        </Box>
        <Box className={`${classes.actionButton} ${classes.editProfilePictureFooterButtons}`}>
          <Button
            color="primary"
            onClick={handleClosePanel}
            className={cx(classes.btnAction, classes.btnActionCancel)}
            data-test="edit-profile-picture-cancel-button"
            data-testid="edit-profile-picture-cancel-button"
          >
            <FormattedMessage
              id="os-app-bar-panel.hdbMmi"
              defaultMessage="Cancel"
              description="the label of cancel button"
            />
          </Button>

          <Button
            color="primary"
            variant="contained"
            id="button-save-cropped-image"
            className={cx(classes.btnAction, classes.btnSave)}
            onClick={handleSaveCroppedImage}
            data-test="edit-profile-picture-save-button"
            disabled={!file}
          >
            <FormattedMessage
              id="os-app-bar-panel.tcZkqy"
              defaultMessage="Save"
              description="label of save button in edit profile picture panel"
            />
          </Button>
        </Box>
      </div>
    </AIWareIntlProvider>
  );
}

export { EditProFilePicture };
