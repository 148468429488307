import { errors } from './constants';
import { AiwareFrameLink, EMessageTypes } from '.';

export function healthReporting(this: AiwareFrameLink) {
  setInterval(() => {
    this.postMessage(EMessageTypes.heartbeat);
  }, this.heartbeatInterval);
}

export function healthMonitoring(this: AiwareFrameLink) {
  if (!this.interval) {
    this.interval = setInterval(() => {
      if (
        new Date().getTime() - new Date(this.heartbeatReceived).getTime() >
        this.heartbeatInterval + 500 // half a second grace period
      ) {
        this.errorAction &&
          this.intl &&
          this.errorAction(this.intl.formatMessage(errors.childAppNotResponding));

        clearInterval(this.interval);
      }
    }, this.heartbeatInterval + 500); // half a second grace period
  }
}
