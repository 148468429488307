import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ITdoVm } from '../../../types';
import Grid from '@mui/material/Grid';
import EngineIcons from '../EngineIcons';
import { useSelector } from 'react-redux';
import { selectTableDataSettings } from '../../../redux/selectors/ui-state';

type RowItemTypes = ITdoVm;

const Engines: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showEngines = columns.indexOf('engineIds') >= 0;

  if (itemType === EntityType.Folders && !item.engineIds) {
    return <>{showEngines && <Grid item container xs={xs}></Grid>}</>;
  }

  return (
    <>
      {showEngines && (
        <Grid item container data-test={`item-engineIds`} xs={xs}>
          <EngineIcons item={item as ITdoVm} iconsToShow={4} />
        </Grid>
      )}
    </>
  );
};

export default Engines;
