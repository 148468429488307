import { useState, ChangeEvent } from 'react';
import { Filter } from '@aiware/ui';
import { Box, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';

import useStyles from '../useStyles';

const filters = [
  {
    value: 'all',
    title: 'View Current Week',
  },
  {
    value: 'no data',
    title: 'View Current Month',
  },
];

function Overview() {
  const { classes } = useStyles();
  const [filter, setFilter] = useState('all');
  const onFilter = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as string);
  };
  return (
    <Box className={classes.overviewRoot} data-testid="performance-monitor-overview">
      <Box className={classes.overviewHeader}>
        <Typography variant="subtitle1" className={classes.overviewTitleText}>
          CPU Load
        </Typography>
        <Filter data={filters} filter={filter} onFilter={onFilter} />
      </Box>
      <Box className={classes.overviewContent}></Box>
    </Box>
  );
}

export default Overview;
