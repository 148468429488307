import { combineReducers } from 'redux';
import { SLICE_NAME } from '../../constants';
import * as sdoSlice from './sdos.state';
import * as structuredDataObjects from './schemaSdos.state';

export const namespace = SLICE_NAME;

export const initialState = {
  [sdoSlice.namespace]: sdoSlice.initialState,
  [structuredDataObjects.sdoNamespace]: structuredDataObjects.initialState,
};

export const actions = {
  [sdoSlice.namespace]: sdoSlice.actions,
  [structuredDataObjects.sdoNamespace]: structuredDataObjects.slice.actions,
};

export const rootReducer = combineReducers({
  [sdoSlice.namespace]: sdoSlice.default,
  [structuredDataObjects.sdoNamespace]: structuredDataObjects.slice.reducer,
});
