import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useIntl } from 'react-intl';

import { filterStyles } from '../../helpers/shared-styles';
import { filterLabels } from '../../helpers/formatted-messages';

export const UserFilter = () => {
  const { classes } = filterStyles();
  const intl = useIntl();
  const { userFilterLabel, allLabel } = filterLabels(intl);

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return <div>{allLabel}</div>;
    }
    const { length } = selected;
    return (
      <Tooltip title="">
        <span>{length} Users</span>
      </Tooltip>
    );
  };

  return (
    <FormControl size="small" className={classes.formControl}>
      <label className={classes.label}>{userFilterLabel}</label>
      <Select
        multiple
        displayEmpty
        value={[]}
        variant="outlined"
        renderValue={renderValue}
        className={classes.selectField}
      />
    </FormControl>
  );
};

export const DropdownFilter = (
  activeFilterSelection: string,
  field: string,
  label: string,
  options: string[],
  handleSelectFilter: (value: string, field: string) => void
) => {
  const { classes } = filterStyles();

  return (
    <FormControl data-test={`${field}`} key={field} size="small" className={classes.formControl}>
      <label className={classes.label}>{label}</label>
      <Select
        variant="outlined"
        className={classes.selectField}
        disabled={options.length <= 1}
        value={activeFilterSelection}
        data-test={`select.dropdown-filter-${field}`}
        onChange={event => handleSelectFilter(event.target.value, field)}
        MenuProps={{
          classes: { paper: classes.menuPaper },
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option}
            className={classes.selectField}
            data-test={`menu-item.dropdown-filter-${option}`}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
