import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonContainer: {
    position: 'relative',
    margin: '16px',
  },
  fileButton: {
    border: '1px solid #1871E8!important',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    fontSize: '12px!important',
    background: theme.palette.background.paper,
    color: '#1871E8!important' as '#1871E8',
    padding: '6px 15px!important',
  },
  hiddenFileButton: {
    visibility: 'hidden',
    position: 'absolute',
    left: 0,
    width: '0%',
    height: '0%',
  },
}));

export default useStyles;
