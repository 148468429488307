import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import {
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Box,
  FormHelperText,
  CircularProgress,
  Typography,
  Icon,
  FormControl,
} from '@mui/material';
import { AlarmTwo } from '@aiware/shared/icons';
import { userSelector } from '@aiware/shared/redux';
import { IUser } from '@aiware/js/interfaces';
import { selectApiConfigs, selectLoadingStatus, selectLibraries } from './selectors';
import { actions } from './slice';
import { ILibrary } from './type';
import { LoadingStatus } from '@aiware/js/interfaces';

const useStyles = makeStyles()((theme: Theme) => ({
  fullWidth: {
    width: '100%',
  },
  menu: {
    boxShadow: '0px 0px 3px rgb(0 0 0 / 25%)',
    maxHeight: '200px',
  },
  menuItem: {
    display: 'flex !important',
    justifyContent: 'flex-start !important',
    padding: '6px 16px !important',
  },
  loaderIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorWrapper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4, 4),
    textAlign: 'center',
    border: '0.5px solid #D5DFE9',
    borderRadius: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(3),
  },
  icon: {
    display: 'inline !important',
  },
  select: {
    maxHeight: '200px',
  },
  disclaimer: {
    backgroundColor: '#F7F7F7',
    padding: theme.spacing(2),
    position: 'absolute',
    top: '258px',
    boxShadow: '0px 0px 3px rgb(0 0 0 / 25%)',
    whiteSpace: 'nowrap',
  },
  subheader: {
    fontWeight: 600,
    opacity: '1 !important',
  },
  disclaimerText: {
    marginLeft: '0 !important',
  },
}));

const LibraryFetchFailure = () => (
  <FormattedMessage
    id="ui.HFcrTw"
    defaultMessage="An error happened while fetching the libraries."
    description="Error message for fetching libraries"
  />
);
const MyLibraries = () => (
  <FormattedMessage id="ui.JuebBL" defaultMessage="My Libraries" description="Indicates user's libraries" />
);

const SharedLibraries = () => (
  <FormattedMessage
    id="ui.vbfuEa"
    defaultMessage="Shared Libraries"
    description="Indicates user's shared libraries"
  />
);

const RetryButtonText = () => (
  <FormattedMessage id="ui.AHzvcj" defaultMessage="Retry" description="Retry button" />
);

const Disclaimer = () => (
  <FormattedMessage
    id="ui.hnCAgN"
    defaultMessage="Showing Libraries that have trained this engine"
    description="disclaimer of library dropdown that shows only libraries that have trained the engined"
  />
);

const NullState = () => (
  <FormattedMessage
    id="ui.r+n9Tl"
    defaultMessage="This engine has not been trained by any libraries."
    description="null state for libraries"
  />
);

export interface IProps {
  engineId: string;
  onSelectedLibraryId: (id: string) => void;
  defaultSelected?: string;
}

const LibraryDropdown = ({ engineId, onSelectedLibraryId, defaultSelected }: IProps) => {
  const { classes } = useStyles();
  const [selectedLibraryId, setSelectedLibraryId] = useState('');
  const [myLibraries, setMyLibraries] = useState<ILibrary[]>([]);
  const [sharedLibraries, setSharedLibraries] = useState<ILibrary[]>([]);

  const { apiRoot, graphQLEndpoint, token } = useSelector(selectApiConfigs);
  const user = useSelector(userSelector) as IUser;
  const orgId = user?.organization?.organizationId;

  const libraries = useSelector(selectLibraries);
  const loadingStatus = useSelector(selectLoadingStatus);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const id = event.target.value as string;
    setSelectedLibraryId(id);
    onSelectedLibraryId(id);
  };

  const dispatch = useDispatch();

  const fetchLibraries = () => {
    dispatch(actions.fetchLibrariesStart());
  };

  useEffect(() => {
    if (apiRoot && graphQLEndpoint && token && loadingStatus !== 'pending' && !libraries?.length) {
      fetchLibraries();
    }
  }, [apiRoot, graphQLEndpoint, token, dispatch]);

  useEffect(() => {
    setSelectedLibraryId(defaultSelected || '');
  }, [defaultSelected]);

  useEffect(() => {
    const tempMyLibraries = [] as ILibrary[];
    const tempSharedLibraries = [] as ILibrary[];
    libraries?.forEach(library => {
      // check if library has trained engine with this engineId
      const hasTrainedEngine = library.engineModels.records.some(
        engineModel => engineModel.engineId === engineId && engineModel.trainStatus === 'complete'
      );
      if (hasTrainedEngine) {
        // type casting here because libraries api returns org id as string
        // /current-user api returns org id as number
        if (+library.organizationId === orgId) {
          tempMyLibraries.push(library);
        } else {
          tempSharedLibraries.push(library);
        }
      }
    });
    setMyLibraries(tempMyLibraries);
    setSharedLibraries(tempSharedLibraries);
  }, [libraries, engineId]);

  return (
    <FormControl size="small" className={classes.fullWidth}>
      {loadingStatus === 'pending' && (
        <div className={classes.loaderIcon}>
          <CircularProgress />
        </div>
      )}
      {loadingStatus === 'failure' && (
        <div className={classes.errorWrapper}>
          <Icon className={classes.icon}>
            <AlarmTwo fontSize="large" />
          </Icon>
          <Typography variant="body2" className={classes.text} data-test="account-linking-error-message">
            <LibraryFetchFailure />
          </Typography>
          <Box mt={2} />
          <Button variant="text" size="large" data-test="account-linking-retry-btn" onClick={fetchLibraries}>
            <RetryButtonText />
          </Button>
        </div>
      )}
      {loadingStatus === 'idle' && (
        <>
          <Select
            id={`ui-library-dropdown-${engineId}`}
            labelId={`ui-library-dropdown-${engineId}`}
            value={selectedLibraryId}
            variant="outlined"
            onChange={handleChange}
            className={classes.fullWidth}
            disabled={!myLibraries?.length && !sharedLibraries?.length}
            MenuProps={{ classes: { paper: classes.menu } }}
          >
            {myLibraries?.length && (
              <MenuItem disabled className={`${classes.menuItem} ${classes.subheader}`}>
                <Typography variant="h6">
                  <MyLibraries />
                </Typography>
              </MenuItem>
            )}
            {myLibraries?.map(library => {
              return (
                <MenuItem value={library.id} className={classes.menuItem}>
                  {library.name.length > 30 ? library.name.substring(0, 30) + '...' : library.name}
                </MenuItem>
              );
            })}
            {sharedLibraries?.length && (
              <MenuItem disabled className={`${classes.menuItem} ${classes.subheader}`}>
                <Typography variant="h6">
                  <SharedLibraries />
                </Typography>
              </MenuItem>
            )}
            {sharedLibraries?.map(library => {
              return (
                <MenuItem value={library.id} className={classes.menuItem}>
                  {library.name.length > 30 ? library.name.substring(0, 30) + '...' : library.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText className={classes.disclaimerText}>
            {!myLibraries?.length && !sharedLibraries?.length ? <NullState /> : <Disclaimer />}
          </FormHelperText>
        </>
      )}
    </FormControl>
  );
};

export { LibraryDropdown };

export default LibraryDropdown;
