import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme: Theme) => ({
  accountLinkingLabel: {
    fontWeight: 'normal',
    userSelect: 'none',
  },
  openIdLogo: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: '#ffffff',
    color: '#555F7C',
  },
  listAvatar: {
    minWidth: '45px',
  },
  openIdContainer: {
    marginTop: theme.spacing(3),
  },
  openIdItem: {
    padding: theme.spacing(3) + 'px 0px',
  },
  openIdTextContainer: {
    marginLeft: theme.spacing(3.5),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  secondaryAction: {
    right: '0',
  },
  linkBtn: {
    textTransform: 'capitalize',
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogTitle: {
    padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(1)}px !important `,
  },
  dialogContent: {
    padding: `${theme.spacing(1)} ${theme.spacing(6)} !important `,
  },
  dialogTitleText: {
    fontWeight: 600,
  },
  dialogAction: {
    padding: theme.spacing(4),
  },
  displayNone: {
    display: 'none',
  },
  displayInlineBlock: {
    display: 'inline-block',
  },
}));
