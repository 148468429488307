import { Skeleton, Stack, StackProps, SxProps, Typography, TypographyProps } from '@mui/material';

export const TableSearchBanner: React.FC<{
  titleText: string | JSX.Element;
  isLoading: boolean;
  hide: boolean;
  content?: JSX.Element;
  sx?: SxProps;
  ContainerProps?: StackProps;
  TitleProps?: TypographyProps;
  LoaderComponent?: string | JSX.Element;
}> = ({
  sx = {},
  titleText,
  isLoading,
  hide,
  content,
  ContainerProps = {},
  TitleProps = {},
  LoaderComponent,
}) => {
  const style = {
    padding: '10px 30px',
    background: '#f2f5f9',
    borderBottom: '1px solid #d5dfe9',
    gap: '10px',
    ...sx,
  };

  if (hide) return null;

  const Loader = () => {
    if (LoaderComponent) {
      return <>{LoaderComponent}</>;
    } else {
      return <Skeleton sx={{ transform: 'none' }} width="100px" height="32px" />;
    }
  };

  return (
    <Stack sx={style} direction="row" alignItems="center" {...ContainerProps}>
      <Typography variant="body2" {...TitleProps}>
        {titleText}
      </Typography>
      {isLoading ? <Loader /> : <>{content}</>}
    </Stack>
  );
};
