import React, { CSSProperties } from 'react';
import { Box, Stack, Typography, StackProps } from '@mui/material';

export type TCenterTabHeadingProps = {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  testId: string;
  HeadingActions?: JSX.Element;
  sx?: CSSProperties;
  StackProps?: StackProps;
  hideDivider?: boolean;
  removeSidePadding?: boolean;
};

export const CenterTabHeading: React.FC<TCenterTabHeadingProps> = ({
  title,
  subtitle,
  testId,
  HeadingActions,
  sx = {},
  StackProps = {},
  hideDivider = false,
  removeSidePadding = false,
}) => {
  return (
    <Stack
      component={'header'}
      direction="row"
      spacing={2}
      sx={[
        {
          borderBottom: hideDivider ? '' : '0.5px solid #D5DFE9',
          flexDirection: 'column',
          padding: removeSidePadding ? '20px 0 20px 0' : '20px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...StackProps}
      data-testid={`${testId}-center-tab-header`}
    >
      <Stack
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        direction={'row'}
      >
        <Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'baseline',
              '& button svg': {
                marginRight: '5px',
              },
            }}
          >
            <Typography
              variant="h5"
              data-testid={`${testId}-center-tab-title`}
              sx={{ fontWeight: '600', mb: 1 }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'baseline',
              '& button svg': {
                marginRight: '5px',
              },
            }}
          >
            <Typography variant="caption" data-testid={`${testId}-center-tab-subtitle`}>
              {subtitle}
            </Typography>
          </Box>
        </Stack>
        {!!HeadingActions && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            gap={'10px'}
            data-testid={`${testId}-center-tab-heading-actions`}
          >
            {HeadingActions}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
