import { Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { tableInfiniteScroll } from '@aiware/shared/reusable-utils';
import {
  selectSelectedProperty,
  selectSelectedSchema,
} from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { selectors, slice } from '../../../../../state/aiStructuredData/schemaProperties.redux';
import { actions as aiStructeredActions } from '../../../../../state/aiStructuredData/aiStructuredData.slice';
import { ISchemaProperties } from '../../../../../types/aiStructuredData.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStyles } from './PropertyDropdown.styles';
import { Search } from '@mui/icons-material';
import { PropertyDropdownRow } from './PropertyDropdownRow';

const {
  jsx: { TableInfiniteScroll },
} = tableInfiniteScroll;

const { selectItems, selectHasMore, selectReadStatus, selectOffset } = selectors;

export const PropertyDropdown = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const selectRef = useRef(null);
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const schemaSelected = useSelector(selectSelectedSchema);
  const schemaProperty = useSelector(selectSelectedProperty);
  const schemaItems: ISchemaProperties[] = useSelector(selectItems);
  const items = schemaItems.filter((item: ISchemaProperties) => item !== null && item !== undefined);
  const itemHeight = 55;
  const hasMore: boolean = useSelector(selectHasMore);
  const offset: number = useSelector(selectOffset);
  const loadingStatus = useSelector(selectReadStatus);
  const intl = useIntl();

  useEffect(() => {
    if (open) {
      setSearchValue('');
      dispatch(slice.actions['resetList']!([]));
      dispatch(slice.actions['readStart']!(null));
    }
  }, [open]);

  const handleFetchItems = () => {
    dispatch(slice.actions['readStart']!(null));
  };

  const handleChangeSearch = (value: string) => {
    dispatch(slice.actions['resetList']!([]));
    dispatch(slice.actions['setSearchValue']!(value));
    dispatch(slice.actions['readStart']!(null));
  };

  const debouncedHandleChangeSearch = useCallback(debounce(handleChangeSearch, 300), []);

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    debouncedHandleChangeSearch(value);
  };

  useEffect(() => {
    dispatch(aiStructeredActions.setSelectedProperty(null));
  }, [schemaSelected]);

  const handleChange = () => {
    //when closing reset the search
    if (open) {
      dispatch(slice.actions['resetList']!([]));
      dispatch(slice.actions['setSearchValue']!(''));
    }
    setOpen(!open);
  };

  const handleClickAddProperty = (item: ISchemaProperties) => {
    dispatch(aiStructeredActions.setSelectedProperty(item));
    setOpen(false);
  };

  const emptyPropertyMessage = (
    <FormattedMessage
      id="dc-browse-structured-data.properties.empty"
      defaultMessage="No Properties Found"
      description="Empty Property search"
    />
  );

  const defaultSelectPlaceholder = (
    <FormattedMessage
      id="dc-browse-structured-data.properties.default.select.placeholder"
      defaultMessage="Choose a Property"
      description="Default Select Placeholder"
    />
  );

  const defaultSearchPlaceholder = intl.formatMessage({
    id: 'dc-browse-structured-data.properties.default.search.placeholder',
    defaultMessage: 'Select A Property...',
    description: 'Default Search Placeholder',
  });

  const TableRow = ({ item, index: _index }: { item: ISchemaProperties; index: number }) => {
    return (
      <PropertyDropdownRow
        item={item}
        name={item.schema.dataRegistry.name}
        path={item.path}
        orgName={item.schema.dataRegistry.organization.name}
        handleClickAddProperty={item => handleClickAddProperty(item)}
      />
    );
  };

  return (
    <FormControl
      sx={{
        '& .Sdk-MuiFormLabel-root.Sdk-MuiInputLabel-root': {
          padding: '0px 7px',
        },
      }}
      onClick={event => event.stopPropagation()}
      size="small"
      className={classes.formControl}
    >
      <InputLabel
        id="dc-structured-data-schema-select-label"
        shrink
        sx={{
          background: theme => theme.palette.background.default,
        }}
      >
        {'Property'}
      </InputLabel>
      <Select
        id="dc-structured-data-property-select"
        variant="outlined"
        multiple={false}
        displayEmpty
        open={open}
        ref={selectRef}
        renderValue={() => {
          if (!schemaProperty) {
            return <div>{defaultSelectPlaceholder}</div>;
          }
          return <Typography data-test="structured-data-property-selected">{schemaProperty.path}</Typography>;
        }}
        className={classes.field}
        onClick={handleChange}
        MenuProps={{
          container: selectRef.current,
          sx: {
            padding: 0,
            width: '100%',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          classes: {
            list: classes.removePadding,
            paper: classes.removePadding,
          },
        }}
      >
        {[
          <div
            onClick={e => e.stopPropagation()}
            key="schemaPropertyMenu"
            id="propertyMenu"
            className={classes.dropdownContainer}
            ref={menuRef}
          >
            <div className={classes.propertyList}>
              <MenuItem
                sx={{
                  backgroundColor: 'transparent !important',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  padding: '0px',
                }}
                disableRipple
                focusRipple={false}
                dense
                onKeyDown={e => e.stopPropagation()}
              >
                {schemaSelected.dataRegistryId ? (
                  <Typography data-test="dc-selected-property-title" variant="h5">
                    {schemaSelected.name}
                  </Typography>
                ) : (
                  <div className={classes.searchContainer}>
                    <Search />
                    <input
                      data-test={'structured-data-property-search-input'}
                      onClick={e => e.stopPropagation()}
                      className={classes.searchInput}
                      onChange={handleChangeInput}
                      value={searchValue}
                      placeholder={defaultSearchPlaceholder}
                    />
                  </div>
                )}
              </MenuItem>
              <div style={{ width: '100%' }}>
                <Divider />
              </div>

              <div style={{ height: '500px', width: '100%' }}>
                {open && (
                  <TableInfiniteScroll
                    testId={'structured-data-property-infinite-table'}
                    hasMore={hasMore}
                    fetchMore={handleFetchItems}
                    offset={offset}
                    items={items}
                    itemHeight={itemHeight}
                    isLoading={loadingStatus === 'pending'}
                    error={loadingStatus === 'failure'}
                    TableRowComponent={TableRow}
                    MessageEmptyState={emptyPropertyMessage}
                  />
                )}
              </div>
            </div>
          </div>,
        ]}
      </Select>
    </FormControl>
  );
};
