import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { FormattedMessage } from 'react-intl';
import { INotificationType, IUtilityAction } from '../../interfaces/notificationInterfaces';
import { emailIcon, notificationBannerIcon, notificationCenterIcon } from '@aiware/shared/assets';
import { CheckCircle } from '@mui/icons-material';
import { useStyles } from './useStyles';
import classNames from 'classnames';

interface INotificationUtilityActionItem {
  events?: IUtilityAction[];
  onSelectAction: (utilityItem: IUtilityAction) => void;
  disabled: boolean;
}

export function NotificationUtilityActionItem(props: INotificationUtilityActionItem) {
  const { events, disabled } = props;
  const { classes } = useStyles();

  const tableCells = [
    {
      type: INotificationType.EMAIL,
      iconUrl: emailIcon,
    },
    {
      type: INotificationType.NOTIFICATION_CENTER,
      iconUrl: notificationCenterIcon,
    },
    {
      type: INotificationType.BANNER,
      iconUrl: notificationBannerIcon,
    },
  ];

  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Typography
                variant="h3"
                children={
                  <FormattedMessage
                    id="os-notification-panel.KVIpaj"
                    defaultMessage="Events"
                    description="allow-notification"
                  />
                }
              />
            </TableCell>
            {tableCells.map(item => {
              return (
                <TableCell align="center" key={item.type} className={classes.itemCell}>
                  <img src={item.iconUrl} alt={item.type} />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {events?.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell className={classNames(classes.eventNameContainer, classes.tableCell)}>
                  <Checkbox id={item.id} disabled={disabled} className={classes.tableRowCheckbox} />
                  <ListItemText>
                    <Typography
                      variant="body2"
                      children={
                        <FormattedMessage
                          id="os-notification-panel.VNVmyk"
                          defaultMessage="{defaultMessage}"
                          description="Finder notifications: {defaultMessage}"
                          values={{
                            defaultMessage: item.title,
                            id: item.id,
                          }}
                        />
                      }
                    />
                  </ListItemText>
                </TableCell>
                {tableCells.map(cell => {
                  return (
                    <TableCell className={classes.itemCell} key={`${cell.type}-type`}>
                      <Checkbox
                        id={`checkbox-${item.id}`}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircle />}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NotificationUtilityActionItem;
