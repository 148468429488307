import React, { useState } from 'react';
import { Chip, Tooltip, ChipProps } from '@mui/material';
import { CheckCircle, CopyAll } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import type { SxProps } from '@mui/system';

export type TCopyChipProps = {
  value: string;
  testId?: string;
  ChipProps?: Partial<ChipProps>;
  sx?: SxProps;
};

export const CopyChip: React.FC<TCopyChipProps> = ({
  value,
  testId = 'shared-copy-chip',
  ChipProps = {},
  sx = {},
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value.toString()).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      });
    } catch (error) {
      // ts-ignore
    }
  };

  const tooltipMsg = {
    copied: (
      <FormattedMessage
        id="shared-reusable-utils.copy-chip.chip-title-copied"
        defaultMessage="Copied!"
        description="Copy Chip tooltip label - copied"
      />
    ),
    clickToCopy: (
      <FormattedMessage
        id="shared-reusable-utils.copy-chip.chip-title-click-to-copy"
        defaultMessage="Click to Copy"
        description="Copy Chip tooltip label - click to copy"
      />
    ),
  };

  return (
    <Tooltip disableInteractive title={isCopied ? tooltipMsg.copied : tooltipMsg.clickToCopy}>
      <Chip
        sx={sx}
        icon={
          isCopied ? (
            <CheckCircle
              sx={{
                color: 'inherit',
              }}
            />
          ) : (
            <CopyAll
              sx={{
                color: 'inherit',
              }}
            />
          )
        }
        onClick={handleCopy}
        label={value}
        {...ChipProps}
        data-testid={testId}
      />
    </Tooltip>
  );
};
