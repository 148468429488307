import { ChangeEvent } from 'react';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

import { CheckCircle } from '@mui/icons-material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { INotificationType } from '../../../interfaces/notificationInterfaces';
import { useStyles } from '../../NotificationAllowTable/useStyles';

interface INotificationAllowCell {
  id: string;
  type: INotificationType;
  allowType: INotificationType[];
  currentApplicationAllowType: INotificationType[];
  isLineEnable: boolean;
  onChangeAppAction: (e: ChangeEvent<HTMLInputElement>, type: INotificationType) => void;
}

export default function NotificationAllowCell(props: INotificationAllowCell) {
  const { id, type, allowType, currentApplicationAllowType, isLineEnable, onChangeAppAction } = props;
  const { classes } = useStyles();

  const checkIncludeType = (allowTypeArray: INotificationType[], currentId: string) => {
    switch (currentId) {
      case 'email':
        return allowTypeArray.includes(INotificationType.EMAIL);
      case 'banners':
        return allowTypeArray.includes(INotificationType.BANNER);
      case 'notification-center':
        return allowTypeArray.includes(INotificationType.NOTIFICATION_CENTER);
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeAppAction(e, type);
  };

  const getColumnClass = () => {
    switch (id) {
      case 'email':
        return classes.firstColumn;
      case 'notification-center':
        return classes.secondColumn;
      case 'banners':
        return classes.thirdColumn;
      default:
        return '';
    }
  };

  return (
    <TableCell align="center" className={`${classes.tableCell} ${getColumnClass()}`}>
      <Checkbox
        id={`checkbox-${id}`}
        checked={checkIncludeType(allowType, id)}
        disabled={!checkIncludeType(currentApplicationAllowType, id) || !isLineEnable}
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={
          <CheckCircle
            color={
              !checkIncludeType(currentApplicationAllowType, id) || !isLineEnable ? 'disabled' : 'primary'
            }
          />
        }
        onChange={handleCheckboxChange}
      />
    </TableCell>
  );
}
