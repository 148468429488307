import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'button'>()((theme: Theme, _props, classes) => ({
  root: {
    width: '100%',
  },
  item: {
    alignItems: 'flex-start',
  },
  avatar: {
    width: '34px',
    height: '34px',
  },
  avatarSmall: {
    width: '34px',
    height: '34px',
  },

  textItem: {
    // color: theme.palette.text.hint, FIXME: mui5
    '& > span': {
      fontSize: '14px',
    },
    [`& ${classes.button}`]: {
      marginRight: '10px',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  divider: {
    opacity: 0.9,
    height: '0.5px',
    border: '0 solid #D5DFE9',
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
  },
  button: {
    fontSize: '10px',
    textTransform: 'unset',
    borderRadius: '2px',
  },
}));

export default useStyles;
