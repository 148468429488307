export type FieldType = {
  id?: string;
  name: string;
  type: EngineFieldType;
  defaultValue?: string;
  defaultValues?: string[];
  value?: string;
  values?: string[];
  required: boolean;
  max?: number;
  min?: number;
  options?: EngineFieldPicklistOptionType[];
};

export type EngineFieldPicklistOptionType = {
  __typename: string;
  key: string;
  value: string;
};

export interface IEngineFieldValue {
  fieldName: string;
  fieldValue: string;
}

export enum EngineFieldType {
  Number = 'Number',
  Picklist = 'Picklist',
  MultiPicklist = 'MultiPicklist',
  Text = 'Text',
  SchemaSelection = 'SchemaSelection',
}
