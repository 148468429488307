import { useState, useEffect } from 'react';
import { IApplication } from '../../../../types';
import AppListDnD from '../../../molecules/AppListDnD';
import useStyles from './application-panel.styles';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { preferredLanguageSelector, showMessage, MessageSeverity } from '@aiware/shared/redux';

import {
  addAppToGroup,
  selectUserAppGroups,
  TUserAppGroup,
  updateGroupPriority,
  fetchUserAppGroupsStart,
  getAppGroupModule,
} from '../../../../store/modules/app-groups';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import RecentAppsDnD from '../../../molecules/RecentAppsDnD';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import isEqual from 'lodash/isEqual';

interface Props {
  selectedAppId?: string | number;
  onChangeTab?: (id: string | number) => void;
  recentApplist: IApplication[];
  applications: IApplication[];
  onClickApp: (id: string | number) => void;
  showAppGroups?: boolean;
}

function ApplicationPanelDnD({
  selectedAppId,
  onChangeTab,
  recentApplist = [],
  applications = [],
  onClickApp,
  showAppGroups = false,
}: Props) {
  const { classes } = useStyles();
  const locale = useSelector(preferredLanguageSelector) as string;
  const userAppGroups: TUserAppGroup[] = useSelector(selectUserAppGroups) || [];
  const formatMessage = AIWareFormatMessage(locale);
  const dispatch = useDispatch();
  const [row, setRow] = useState<number>(recentApplist.length === 0 ? 0 : recentApplist.length < 4 ? 1 : 2);

  useEffect(() => {
    const newRow = recentApplist.length === 0 ? 0 : recentApplist.length < 4 ? 1 : 2;
    setRow(newRow);
  }, [recentApplist]);
  const [currentSelected, setSelected] = useState(selectedAppId);

  useEffect(() => {
    if (selectedAppId !== currentSelected) {
      setSelected(selectedAppId);
    }
  }, [selectedAppId, currentSelected]);

  function handleSelectApplication(id: string | number) {
    setSelected(id);
    onChangeTab?.(id);
    onClickApp?.(id);
  }

  const duplicateAppMessage = isEqual(process.env.NODE_ENV, 'development')
    ? 'This application has already been added to the group'
    : formatMessage({
        id: 'os-app-bar-panel.user-app-groups.duplicate-app-error',
        defaultMessage: 'This application has already been added to the group',
        description: 'Error message when user tries adding a duplicate app to an app group',
      });

  useEffect(() => {
    if (showAppGroups) {
      dispatch(fetchUserAppGroupsStart());
    }
  }, [dispatch]);

  const handleDragEnd = (ev: DropResult) => {
    if (!showAppGroups) {
      return;
    }
    const { destination, draggableId, type } = ev;
    if (type === 'group-list') {
      const appGroup = userAppGroups.find(group => group.id === draggableId);
      if (appGroup && destination) {
        dispatch(
          updateGroupPriority({
            groupId: draggableId,
            newPriority: destination.index,
            prevPriority: appGroup.priority,
          })
        );
      }
    } else {
      const destinationInUserGroups = userAppGroups.find(
        appGroup => appGroup.id === destination?.droppableId
      ) as unknown as TUserAppGroup;
      const destinationContainsApp = (destinationInUserGroups?.apps || []).find(
        (id: string) => id === draggableId
      );
      if (destinationContainsApp) {
        const message: any = {
          content: duplicateAppMessage,
          severity: MessageSeverity.Error,
        };

        dispatch(showMessage(message));
      } else {
        destination && dispatch(addAppToGroup({ destination: destination?.droppableId, appId: draggableId }));
      }
    }
  };

  return (
    <DynamicModuleLoader modules={[getAppGroupModule()]}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={classes.fullHeight}>
          <Grid container className={classes.fullHeight}>
            <Grid item xs={6} className={classes.fullHeight}>
              <RecentAppsDnD
                apps={recentApplist}
                allApps={applications}
                onSelectApplication={handleSelectApplication}
                selectedAppId={null}
                showAppGroups={showAppGroups}
              />
            </Grid>
            <Grid item xs={6} className={classes.fullHeight}>
              <div>
                <AppListDnD
                  title={
                    formatMessage({
                      id: 'os-app-bar-panel.name.moreApps',
                      defaultMessage: 'More Applications',
                      description: 'Header title of more apps',
                    })!
                  }
                  type="More"
                  applications={applications}
                  onSelectApplication={handleSelectApplication}
                  selectedAppId={null}
                  row={row}
                  showAppGroups={showAppGroups}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </DragDropContext>
    </DynamicModuleLoader>
  );
}

export default ApplicationPanelDnD;
