import { Avatar, Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { DateFormatter } from '@aiware/shared/reusable-utils';
import { IAuditLog, TEST_ID_WIDGET } from '../../../types/auditLog.types';
import { toTitleCase } from '../../helpers';

export const ITEM_HEIGHT = 60;

export const TableRow = ({
  item,
  index: _index,
  onClick,
}: {
  item: IAuditLog;
  index: number;
  onClick: () => void;
}) => {
  const height = `${ITEM_HEIGHT}px`;

  return (
    <Box
      sx={{
        width: '100%',
        height,
        '&:hover': {
          backgroundColor: '#f2f5f9',
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      <Grid
        data-testid={`${TEST_ID_WIDGET}-table-row-${_index}`}
        sx={{ height, pl: 3, borderBottom: '1px solid #D5DFE9' }}
        alignItems="center"
        display="flex"
        container
      >
        <Grid item xs={3} data-testid={`${TEST_ID_WIDGET}-row-name`}>
          <Tooltip title={item.userName}>
            <Stack direction={'row'} sx={{ alignItems: 'center', gap: '10px' }}>
              <Avatar
                sx={{ width: 34, height: 34, backgroundColor: '#e9f3fc', border: '1px solid #d8d9e0' }}
                src={item.userAvatar}
              />
              <Typography
                variant="body2"
                fontWeight={500}
                overflow="hidden"
                textOverflow="ellipsis"
                marginRight={theme => theme.spacing(1)}
              >
                {item.userName}
              </Typography>
            </Stack>
          </Tooltip>
        </Grid>
        <Grid item xs={3} data-testid={`${TEST_ID_WIDGET}-row-event-type`}>
          <Typography variant="body2">{toTitleCase(item.eventName)}</Typography>
        </Grid>
        <Grid item xs={3} data-testid={`${TEST_ID_WIDGET}-row-details`}>
          <Typography variant="body2">{item.description || '--'}</Typography>
        </Grid>
        <Grid item xs={3} data-testid={`${TEST_ID_WIDGET}-row-date`}>
          <DateFormatter value={item.createdDateTime} orientation={'horizontal'} />
        </Grid>
      </Grid>
    </Box>
  );
};
