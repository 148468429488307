import { combineReducers } from 'redux';
import { SLICE_NAME } from '../../types/auditLog.types';
import * as auditLog from '../auditLog.redux';

export const namespace = SLICE_NAME;

export type AuditLogState = {
  [auditLog.namespace]: auditLog.State;
};

export const initialState = {
  [auditLog.namespace]: auditLog.initialState,
};

export const actions = {
  [auditLog.namespace]: auditLog.slice.actions,
};

export const rootReducer = combineReducers({
  [auditLog.namespace]: auditLog.slice.reducer,
});