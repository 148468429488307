import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { FormattedMessage } from 'react-intl';

export function CustomWorkFlow() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        data-testid="custom-workflow-box"
      >
        <Typography
          variant="h3"
          children={
            <FormattedMessage
              id="os-notification-panel.1+8+OX"
              defaultMessage="Custom Workflows"
              description="custom-workflows"
            />
          }
        />
        <Button
          variant="outlined"
          children={
            <FormattedMessage
              id="os-notification-panel.gAFAOA"
              defaultMessage="Create New"
              description="creat-new-btn"
            />
          }
          color="primary"
        />
      </Box>
      <Typography
        style={{ fontSize: 14, marginTop: 20 }}
        children={
          <FormattedMessage
            id="os-notification-panel.9+Qhjr"
            defaultMessage="You don’t have any custom workflows yet. Get started by clicking the Create New button above!"
            description="text"
          />
        }
      />
    </>
  );
}

export default CustomWorkFlow;
