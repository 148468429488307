import { AIWareThemeProvider } from '@aiware/shared/theme';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { getDataCenterBrowseModule } from '../../../../../redux';
import { PanelContent } from './PanelContent';

export const PanelContainer = (props: {
  dataId: string;
  panelRendered: boolean;
  appId: string;
  connectorId: string;
  entityId: string;
  fileName: string;
}) => (
  <AIWareThemeProvider>
    <DynamicModuleLoader modules={[getDataCenterBrowseModule()]}>
      <AIWareIntlProvider>
        <PanelContent {...props} />
      </AIWareIntlProvider>
    </DynamicModuleLoader>
  </AIWareThemeProvider>
);
