import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  idPrefix: string;
}

const CancelModal: FunctionComponent<Props> = ({ open, handleConfirm, handleClose, idPrefix }: Props) => {
  return (
    <Dialog open={open} maxWidth="xs" data-test={`${idPrefix}-progress-stepper-modal-dialog`}>
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id="progress-stepper-modal-title-confirmation-title"
          defaultMessage="Confirm Cancel"
          description="Title of the delete engine confirmation modal"
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="progress-stepper-modal-title"
            defaultMessage="Are you sure you want to cancel? This action can not be undone."
            description="This message displays modal when a user clicks on a cancel button"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          data-test={`${idPrefix}-progress-stepper-modal-close-button`}
        >
          <FormattedMessage
            id="progress-stepper-modal-button-close"
            defaultMessage="Continue form session"
            description="Close button text"
          />
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          data-test={`${idPrefix}-progress-stepper-modal-confirm-button`}
        >
          <FormattedMessage
            id="progress-stepper-modal-button-confirm-cancellation"
            defaultMessage="Delete all changes"
            description="Confirm cancellation button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelModal;
