import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_NAME } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'panelSelectImportSource';

export interface IEngineList {
  pull: IEngineAdapter[];
  push: IEngineAdapter[];
}

export interface IEngineAdapter {
  id: string;
  categoryId: string;
  name: string;
  logoPath: string | null;
  signedLogoPath: string | null;
  signedIconPath: string | null;
  description: string | null;
  dependency: string | null;
  displayName: string | null;
  deploymentModel: string;
  fields: {
    createdBy: string | null;
  };
  ownerOrganizationId: string;
  iconPath: string | null;
  createsTDO: boolean;
  price: number | null;
  deployedVersion: number;
  preferredInputFormat: string;
  runtimeType: string;
  supportedSourceTypes: number[] | null;
  supportedScheduleTypes: number[] | null;
  outputFormats: string[] | null;
  hasScanPhase: boolean | null;
  edgeVersion: number;
  oauth: string | null;
}

export type State = {
  fetchEnginesStatus: LoadingStatus;
  engineList: IEngineList;
  activeEngineId: string | null;
};

export const initialState = {
  fetchEnginesStatus: 'idle',
  engineList: {
    pull: [],
    push: [],
  },
  activeEngineId: null,
} as State;

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    resetState(state) {
      state.fetchEnginesStatus = 'idle';
      state.activeEngineId = null;
    },
    setActiveEngineId(state, { payload }: PayloadAction<string>) {
      state.activeEngineId = payload;
    },
    setEngineList(state, { payload }: PayloadAction<IEngineList>) {
      state.engineList = payload;
    },
    fetchEngineAdapterStart(state) {
      state.fetchEnginesStatus = 'pending';
    },
    fetchEngineAdapterSucceeded(state, { payload }: PayloadAction<{ engineAdapters: IEngineList }>) {
      state.fetchEnginesStatus = 'success';
      state.engineList = payload.engineAdapters;
    },
    fetchEngineAdapterFailed(state) {
      state.fetchEnginesStatus = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
