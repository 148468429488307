import { errors } from './constants';
import { AiwareFrameLink, EMessageTypes } from '.';

export function postMessage(this: AiwareFrameLink, type: EMessageTypes, payload = {}) {
  // make sure certain messages are reserved to parent or child
  let canSend = !!this.canSendMap.common.find(mType => mType === type);
  if (!canSend) {
    if (this.isChild) {
      canSend = !!this.canSendMap.child.find(mType => mType === type);
    } else {
      canSend = !!this.canSendMap.parent.find(mType => mType === type);
    }
  }
  if (canSend) {
    // Send a message to the correct window
    // If this is a child frame, send the message to the parent frame
    // If this is a parent frame, send the message to the child frame
    (this.isChild ? window.parent : document.querySelector('iframe')!['contentWindow']!).postMessage(
      { type: `aiware:${type}`, payload }, // all message bus events needs to be unique, hence the prefix
      this.isChild ? document.referrer : this.origin!
    );
  } else {
    // inform user about an error
    if (this.isChild) {
      // if child then communicate the error to parent
      this.postMessage(EMessageTypes.error, errors.childCantSend);
    } else {
      // if parent then show
      this.errorAction && this.intl && this.errorAction(this.intl.formatMessage(errors.parentCantSend));
    }
  }
}
