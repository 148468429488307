import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  permissionsPanelContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
