import { baseGraphQLApi } from '@aiware/shared/redux';

export const getApplicationInfo = async (
  applicationId: string,
  graphEndpoint: string,
  token?: string
) => {
  const query = `
    query {
      application(id:"${applicationId}"){
        iconUrl
      }
    }
  `;
  try {
    return await baseGraphQLApi({ query, graphEndpoint, token });
  } catch (error) {
    console.error(error);
  }
  return;
};
