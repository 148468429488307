import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterContainer: {
    position: 'absolute',
    top: theme.spacing(12.4),
    right: 0,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.default,
  },
  markAllReadButton: {
    cursor: 'pointer',
    marginRight: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    textTransform: 'capitalize',
  },
}));

export { useStyles };
