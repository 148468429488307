import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import Content from './Content';
import { IFileTypeItem } from './FileTypeItem';
import { AIWareCacheProvider } from '@aiware/shared/theme';

export const SupportedFileTypes = (props: {
  dataId: string;
  panelRendered: boolean;
  hidePanel?: () => void;
  fileTypes?: IFileTypeItem[];
}) => {
  return (
    <AIWareThemeProvider>
      <AIWareIntlProvider>
        <AIWareCacheProvider>
          <Content hidePanel={props.hidePanel} fileTypes={props.fileTypes!} />
        </AIWareCacheProvider>
      </AIWareIntlProvider>
    </AIWareThemeProvider>
  );
};

export default SupportedFileTypes;
