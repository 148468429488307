import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void>()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  header: {
    margin: 0,
    marginBottom: '6px',
    fontWeight: 600,
    cursor: 'default',
  },
  description: {
    opacity: 0.8,
    cursor: 'text',
  },
}));

export default useStyles;
