import createSvgIcon from '../createSvgIcon';

export const PublicWhite = createSvgIcon(
  <svg width="19" height="19" viewBox="7 7 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="1870E8" />
    <path
      d="M12.6667 20.9167C13.675 20.9167 14.5 20.0917 14.5 19.0833C14.5 18.075 13.675 17.25 12.6667 17.25C11.6583 17.25 10.8333 18.075 10.8333 19.0833C10.8333 20.0917 11.6583 20.9167 12.6667 20.9167ZM13.7025 21.925C13.3633 21.87 13.0242 21.8333 12.6667 21.8333C11.7592 21.8333 10.8975 22.0258 10.1183 22.365C9.44 22.6583 9 23.3183 9 24.0608V25.5H13.125V24.0242C13.125 23.2633 13.3358 22.5483 13.7025 21.925ZM27.3333 20.9167C28.3417 20.9167 29.1667 20.0917 29.1667 19.0833C29.1667 18.075 28.3417 17.25 27.3333 17.25C26.325 17.25 25.5 18.075 25.5 19.0833C25.5 20.0917 26.325 20.9167 27.3333 20.9167ZM31 24.0608C31 23.3183 30.56 22.6583 29.8817 22.365C29.1025 22.0258 28.2408 21.8333 27.3333 21.8333C26.9758 21.8333 26.6367 21.87 26.2975 21.925C26.6642 22.5483 26.875 23.2633 26.875 24.0242V25.5H31V24.0608ZM23.8867 21.5125C22.8142 21.0358 21.4942 20.6875 20 20.6875C18.5058 20.6875 17.1858 21.045 16.1133 21.5125C15.1233 21.9525 14.5 22.9425 14.5 24.0242V25.5H25.5V24.0242C25.5 22.9425 24.8767 21.9525 23.8867 21.5125ZM16.3975 23.6667C16.48 23.4558 16.5167 23.3092 17.2317 23.0342C18.1208 22.6858 19.0558 22.5208 20 22.5208C20.9442 22.5208 21.8792 22.6858 22.7683 23.0342C23.4742 23.3092 23.5108 23.4558 23.6025 23.6667H16.3975ZM20 16.3333C20.5042 16.3333 20.9167 16.7458 20.9167 17.25C20.9167 17.7542 20.5042 18.1667 20 18.1667C19.4958 18.1667 19.0833 17.7542 19.0833 17.25C19.0833 16.7458 19.4958 16.3333 20 16.3333ZM20 14.5C18.4783 14.5 17.25 15.7283 17.25 17.25C17.25 18.7717 18.4783 20 20 20C21.5217 20 22.75 18.7717 22.75 17.25C22.75 15.7283 21.5217 14.5 20 14.5Z"
      fill="white"
      stroke="white"
    />
  </svg>,
  'PublicWhite'
);
