import React from 'react';
import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getTablePaginationRows, getTableRowValues } from '../helpers/json-table-helpers';
import { JSONTableHeader } from './json-table-header';
import { JSONTableCell } from './json-table-cell';
import { TJSONTableProps } from '../json-table';
import { TJSONTablePaginationProps } from './json-table-pagination';
import { jsonTableStyles } from '../helpers/json-table-styles';

export type TJSONTableContentProps = {
  tableProps: TJSONTableProps;
  paginationProps?: TJSONTablePaginationProps;
};

export const JSONTableContent = (props: TJSONTableContentProps) => {
  const { tableProps, paginationProps } = props;
  const { data, testId, isLoading, withPagination: isPaginationEnabled } = tableProps;
  const pageSize = paginationProps?.pageSize || 20;

  const allRows = getTableRowValues(data);

  const renderedRows = isPaginationEnabled ? getTablePaginationRows(allRows, paginationProps) : allRows;
  const pageSizeDifference = isLoading || renderedRows.length === 0 ? 0 : pageSize - renderedRows.length;

  return (
    <Table size="small" data-testid={`${testId}-table`}>
      <JSONTableHeader tableProps={tableProps} />
      <TableBody data-testid={`${testId}-table-body`} sx={jsonTableStyles.tableStyles.body}>
        {isLoading && <LoadingRowGenerator pageSize={pageSize} testId={testId} />}
        {!isLoading && <ItemRowGenerator rows={renderedRows} testId={testId} />}
        {isPaginationEnabled && (
          <EmptyRowGenerator
            rowsToGenerate={pageSizeDifference}
            cellsToGenerate={renderedRows[0]?.values.length || 1}
            testId={testId}
          />
        )}
      </TableBody>
    </Table>
  );
};

const ItemCellGenerator: React.FC<{ cells: any[]; testId: string }> = ({ cells, testId }) => {
  return (
    <>
      {cells.map((cellData: any, index: number) => {
        return (
          <TableCell
            key={index}
            sx={jsonTableStyles.tableStyles.cell}
            data-testid={`${testId}-table-cell-${index + 1}`}
          >
            <JSONTableCell value={cellData} />
          </TableCell>
        );
      })}
    </>
  );
};

const ItemRowGenerator: React.FC<{ rows: any[]; testId: string }> = ({ rows, testId }) => {
  return (
    <>
      {rows.map((row: any, index: number) => {
        return (
          <TableRow
            key={index}
            sx={jsonTableStyles.tableStyles.row}
            data-testid={`${testId}-table-row-${index + 1}`}
          >
            <ItemCellGenerator cells={row?.values || []} testId={testId} />
          </TableRow>
        );
      })}
    </>
  );
};

const LoadingRowGenerator: React.FC<{ pageSize: number; testId: string }> = ({ pageSize, testId }) => {
  const rows = new Array(pageSize).fill(0);

  return (
    <>
      {rows.map((_, index) => {
        return (
          <TableRow data-testid={`${testId}-table-row-loader-${index + 1}`} key={index}>
            <TableCell
              key={index}
              sx={{ border: '1px solid rgb(0 0 0 / 5%)' }}
              colSpan={1000}
              data-testid={`${testId}-table-column-${index + 1}`}
            >
              <Skeleton sx={{ transform: 'none', height: '25px' }} />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

const EmptyRowGenerator: React.FC<{
  rowsToGenerate: number;
  cellsToGenerate: number;
  testId: string;
}> = ({ rowsToGenerate, cellsToGenerate, testId }) => {
  if (rowsToGenerate < 0) {
    return null;
  }

  const rows = new Array(rowsToGenerate).fill(0);
  const cells = new Array(cellsToGenerate).fill(0);

  return (
    <>
      {rows.map((_, index) => {
        return (
          <TableRow key={index} sx={{ height: 33 }} data-testid={`${testId}-table-row-spacer-${index + 1}`}>
            {cells.map((_: any, index: number) => {
              return (
                <TableCell
                  key={index}
                  sx={{ border: '1px solid rgb(0 0 0 / 5%)' }}
                  data-testid={`${testId}-table-column-spacer-${index + 1}`}
                ></TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
};
