import { Button } from '@mui/material';
import { SHARED_TEXT } from '../helpers/shared-text';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import { actions } from '../../../../redux/slices';
import { useCallback, useEffect, useState } from 'react';
import * as selectors from '../../../../redux/selectors/edit-metadata';
import { ITdo } from '../../../../types';

const useStyles = makeStyles()(theme => ({
  panelControls: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 7, 4, 7),
  },
}));

export const Controls = ({ onSave }: { onSave?: (tdo: ITdo) => void }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [pendingClose, setPendingClose] = useState(false);
  const saveStatus = useSelector(selectors.selectSaveStatus);
  const tdo = useSelector(selectors.selectTdo);

  const closePanel = useCallback(() => dispatch(hidePanel('DATA_CENTER_EDIT_METADATA_PANEL')), [dispatch]);

  useEffect(() => {
    if (pendingClose && saveStatus === 'success') {
      setPendingClose(false);
      if (onSave && tdo) {
        onSave(tdo);
      }
      closePanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingClose, saveStatus]);

  const handleSave = () => {
    setPendingClose(true);
    dispatch(actions.editMetadata.saveMetadataStart());
  };

  return (
    <div className={classes.panelControls}>
      <Button onClick={closePanel}>{SHARED_TEXT.cancel()}</Button>
      <Button data-testid="dc.metadata-panel-save-btn" variant="contained" onClick={handleSave}>
        {SHARED_TEXT.saveChanges()}
      </Button>
    </div>
  );
};
