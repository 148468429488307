import * as actions from './action';

export interface ActivityState {
  record: Array<unknown>;
  filter: string;
  fetched: boolean;
  fetching: boolean;
  processing: boolean;
}

const initialState = {
  records: [],
  filter: 'all',
  fetched: false,
  fetching: false,
  processing: false,
};

interface IActivityPayload {
  filter: unknown;
}

export function activityReducer<T extends IActivityPayload>(
  state: ActivityState,
  action: { type: string; payload: T }
) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actions.GET_ACTIVITY_SUCCESS: {
      return {
        ...state,
        records: action.payload,
      };
    }
    case actions.UPDATE_ACTIVITY_FILTER: {
      const { filter } = action.payload;
      return {
        ...state,
        filter,
      };
    }
    default:
      return state;
  }
}
