import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import DateFormatter from '../DateFormatter';
import { useSelector } from 'react-redux';
import { selectIsFluffy, selectTableDataSettings } from '../../../redux/selectors/ui-state';
import { useStyles } from '../../components/useStyles';
import Typography from '@mui/material/Typography';
import { TABLE_DATA_NAMES } from '../../../types/entity-data-settings';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const ModifiedDateTime: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showModified = columns.indexOf('modifiedDateTime') >= 0;
  const isFluffy = useSelector(selectIsFluffy);
  const { classes } = useStyles();

  return (
    <>
      {showModified && !isFluffy && (
        <Grid data-test={'item-modifiedDateTime'} item xs={xs}>
          <DateFormatter value={item.modifiedDateTime} />
        </Grid>
      )}
      {showModified && isFluffy && (
        <Grid data-test={'item-modifiedDateTime-fluffy'} item container direction="row" xs={xs}>
          <Typography
            data-test={'modifiedDateTime-title'}
            className={classes.titleFluffy}
            variant="caption"
            color="textSecondary"
          >
            {TABLE_DATA_NAMES.modifiedDateTime?.()}:
          </Typography>
          <Typography data-test={'modifiedDateTime-date'} variant="caption" color="textSecondary">
            <DateFormatter value={item.modifiedDateTime} isFluffy className={classes.timeFluffy} />
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default ModifiedDateTime;
