import { Alteryx } from './Alteryx';
import { AiStructuredData } from './AiStructuredData';
import { AnomalyDetection } from './AnomalyDetection';
import { AudioDetection } from './AudioDetection';
import { Automation } from './Automation';
import { CognitionUtility } from './CognitionUtility';
import { ContentClassification } from './ContentClassification';
import { Correlation } from './Correlation';
import { DeviceTrust } from './DeviceTrust';
import { EntityExtraction } from './EntityExtraction';
import { FacialDetection } from './FacialDetection';
import { Fingerprint } from './Fingerprint';
import { Generator } from './Generator';
import { Geolocation } from './Geolocation';
import { Human } from './Human';
import { Intracategory } from './Intracategory';
import { Intercategory } from './Intercategory';
import { KeywordExtraction } from './KeywordExtraction';
import { LanguageIdentification } from './LanguageIdentification';
import { LicensePlateALPR } from './LicensePlateALPR';
import { Liveness } from './Liveness';
import { LogoRecognition } from './LogoRecognition';
import { MusicDetection } from './MusicDetection';
import { ObjectDetection } from './ObjectDetection';
import { Pull } from './Pull';
import { Push } from './Push';
import { Reduction } from './Reduction';
import { Sentiment } from './Sentiment';
import { SpeakerDetection } from './SpeakerDetection';
import { StationPlayout } from './StationPlayout';
import { Summarization } from './Summarization';
import { TextExtraction } from './TextExtraction';
import { TextRecognition } from './TextRecognition';
import { TexttoSpeech } from './TextToSpeech';
import { ThreatAnalysis } from './ThreatAnalysis';
import { Transcode } from './Transcode';
import { Transcription } from './Transcription';
import { Translate } from './Translate';
import { VoiceRecognition } from './VoiceRecognition';
import { KeywordRecognition } from './KeywordRecognition';
import { Tags } from './Tags';
import { RecognizedText } from './RecognizedText';
import { Search } from '../icons/Search';
import Ingestion from '@mui/icons-material/Input';
import { FileFilled } from '../icons/FileFilled';

export const EngineCategoryIcons = {
  Alteryx,
  AiStructuredData,
  AnomalyDetection,
  AudioDetection,
  Automation,
  CognitionUtility,
  ContentClassification,
  Correlation,
  DeviceTrust,
  EntityExtraction,
  FacialDetection,
  Fingerprint,
  Generator,
  Geolocation,
  Human,
  Intracategory,
  Intercategory,
  Ingestion,
  KeywordExtraction,
  KeywordRecognition,
  LanguageIdentification,
  LicensePlateALPR,
  Liveness,
  LogoRecognition,
  MusicDetection,
  ObjectDetection,
  Pull,
  Push,
  Reduction,
  RecognizedText,
  Search,
  Sentiment,
  SpeakerDetection,
  StationPlayout,
  Summarization,
  Tags,
  TextExtraction,
  TextRecognition,
  TexttoSpeech,
  ThreatAnalysis,
  Transcode,
  Transcription,
  Translate,
  VoiceRecognition,
  FileFilled,
};
