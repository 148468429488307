import { DataCenterState, namespace } from '../slices';

export const selectImportSourcePanel = (state: DataCenterState) => {
  return state[namespace].panelSelectImportSource;
};

const selectEngines = (state: DataCenterState) => {
  return state[namespace].panelSelectImportSource.engineList;
};

const selectActiveEngineId = (state: DataCenterState) => {
  return state[namespace].panelSelectImportSource.activeEngineId;
};

const selectFetchEnginesStatus = (state: DataCenterState) => {
  return state[namespace].panelSelectImportSource.fetchEnginesStatus;
};

export const importSourcePanelSelectors = {
  selectEngines,
  selectActiveEngineId,
  selectFetchEnginesStatus,
};
