import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Grid, Typography, Divider, Avatar, Box, Button } from '@mui/material';
import { actions } from '../../../redux/slices';
import { filterUserRecords } from '../../../helper';
import { TUser, GraphQLPage, TAuthGroupMember } from '../../../types';
import { SHARED_TEXT } from '../../../helper/shared-text';
import useStyles from './useStyles';
import { Utils, DateFormatter } from '@aiware/shared/reusable-utils';

interface IProps {
  peopleList: GraphQLPage<TAuthGroupMember>;
  groupId: string;
}

export default function MembersTab({ peopleList, groupId }: IProps) {
  const dispatch = useDispatch();

  const formattedName = (value: TUser) => {
    return `${value.firstName?.charAt(0)?.toUpperCase()}${value.lastName?.charAt(0)?.toUpperCase()}`;
  };

  const handleRemoveMembers = (memberId: string | undefined) => {
    if (!memberId) return;
    const id = groupId;
    dispatch(
      actions.adminCenterGroups.removeMembersFromUserGroupStart({
        id,
        memberIds: [memberId],
      })
    );
  };

  const newMemberList = filterUserRecords(peopleList);

  const Row: FC<ListChildComponentProps> = ({ index, style }) => {
    const { classes } = useStyles();

    const rowItem = (
      <div
        data-testid="user-group-details.group-member-card"
        data-test="user-group-details.group-member-card"
        key={newMemberList[index]?.id}
      >
        <Grid container>
          <Grid
            direction="row"
            alignItems="center"
            container
            xs={12}
            sx={{ margin: '20px auto 0' }}
            key={newMemberList[index]?.email}
          >
            <Grid item xs={6}>
              <Typography variant="body1" color="textPrimary">
                <Box className={classes.member}>
                  {newMemberList[index]?.imageUrl ? (
                    <Avatar
                      alt={newMemberList[index]!.firstName}
                      src={newMemberList[index]!.imageUrl!}
                      className={classes.memberAvatar}
                      data-test="user-group-details.group-member-card.avatar"
                    />
                  ) : (
                    <Avatar
                      className={classes.memberAvatar}
                      data-test="user-group-details.group-member-card.avatar"
                    >
                      {formattedName(newMemberList[index]!)}
                    </Avatar>
                  )}
                  <Box>
                    <Typography
                      variant={'h3'}
                      data-testid="admin-center-group-suggestion-member-tab-name"
                      data-test="admin-center-group-suggestion-member-tab-name"
                    >
                      {`${newMemberList[index]?.firstName} ${newMemberList[index]?.lastName}`}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      data-testid="admin-center-group-suggestion-member-email"
                      data-test="admin-center-group-suggestion-member-email"
                      className={classes.membersEmail}
                    >
                      {Utils.truncatedString(newMemberList[index]?.email as string, 30)}
                    </Typography>
                  </Box>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="center">
              <Box>
                <Typography variant="caption" color="textSecondary" className={classes.memberSince}>
                  {SHARED_TEXT.memberSince}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <DateFormatter
                    value={newMemberList[index]?.createdDateTime}
                    removeTime={true}
                    className={classes.createdDate}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid display="flex" justifyContent="flex-end" alignItems="center" item xs={4}>
              <Button
                data-testid="user-group-details.remove-member-btn"
                data-test="user-group-details.remove-member-btn"
                color="primary"
                variant="outlined"
                onClick={() => handleRemoveMembers(newMemberList[index]!.id)}
                className={classes.buttons}
              >
                {SHARED_TEXT.removeMember}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </div>
    );

    return (
      <div key={index} style={{ ...style }}>
        {rowItem}
      </div>
    );
  };

  return (
    <div style={{ height: 'calc(100vh - 375px)' }}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List height={height} itemCount={newMemberList.length} itemSize={82} width={width}>
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}
