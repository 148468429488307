import { makeStyles } from 'tss-react/mui';

export const contextMenuStyles = makeStyles<{ width: number }>()((theme, config) => ({
  menuList: {
    width: theme.spacing(config.width),
    padding: theme.spacing(1.5),
  },
  menuItem: {
    borderRadius: 4,
    padding: theme.spacing(1),
    '& > svg': {
      marginRight: theme.spacing(2),
    },
    textTransform: 'none',
  },
}));
