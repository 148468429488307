import { Theme } from '@mui/material/styles';
import { cloneDeep, merge } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react/types';

const editorStyles: CSSObject = {
  margin: '0',
  padding: '10px 0 !important',
  borderRadius: '0',
  resize: 'none',
  fontSize: '1em !important',
  fontFamily: 'Nunito, lucida console, courier new, courier, monospace',
  lineHeight: '1.6em !important',
  outline: 'none',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  '&:focus-visible': {
    outline: 'none',
  },
  '& textarea': {
    lineHeight: '2em !important',
    whiteSpace: 'pre',
  },
};

export const useStyles = makeStyles()((_theme: Theme) => ({
  codeEditor: merge(cloneDeep(editorStyles), {
    paddingLeft: 'calc(3rem + 10px) !important',
    width: '100%',
    backgroundColor: '#303841',
    borderColor: '#303841',
    color: '#ffffff !important',
    caretColor: '#FFBB0A',
  }),
  lineCounter: merge(cloneDeep(editorStyles), {
    display: 'block',
    borderColor: '#4F565E',
    overflowY: 'hidden',
    boxShadow: 'none',
    color: '#9CA8B4 !important',
    backgroundColor: '#4F565E',
    position: 'absolute !important',
    width: '3rem !important',
    paddingRight: '.625rem !important',
    top: 0,
    left: 0,
    bottom: 0,
    minHeight: '100%',
    pointerEvents: 'none',
    '& textarea': {
      textAlign: 'right',
      fontWeight: '600 !important',
    },
  }),
}));

export default useStyles;
