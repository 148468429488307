import { FC } from 'react';
import { useStyles } from './searchResultsHeader.styles';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Hamburger, Information, List } from '@aiware/shared/icons';
import { EResultsViewAction, EResultsViewStyle } from '../../AISearchResultsView';
import { FormattedMessage } from 'react-intl';
import { AiSearchHeaderSortMenu } from './HeaderActionMenus/AiSearchHeaderSortMenu';
import { FilterIcon } from '../../../../../Filter/components/FilterIcon';
import { actions } from '../../../../state/aiSearchFilter/aiSearchFilter.slice';
import { actions as searchActions } from '../../../../state/aiSearch/aiSearch.slice';
import { useDispatch } from 'react-redux';

interface IHeaderActions {
  viewStyle: EResultsViewStyle;
  onViewStyleChange: () => void;
  onSortMethodChange: () => void;
  hideHeaderActions: EResultsViewAction[];
  [x: string]: unknown;
}

interface IHeaderActionButton {
  id: string;
  icon: JSX.Element;
  onClick: (() => void) | ((event: any) => void);
  label: string | JSX.Element;
}

const ACTIONS_TEXT = {
  CHANGE_SORT: () => {
    return (
      <FormattedMessage
        id="os-data-center-browse.search-results-view-actions.change-sort"
        defaultMessage="Change Sort"
        description="Change the sort order of the search results"
      />
    );
  },
  FILTER: () => {
    return (
      <FormattedMessage
        id="os-data-center-browse.search-results-view-actions.filter-results"
        defaultMessage="Filter Results"
        description="Open the filter panel of the search results view"
      />
    );
  },
  TOGGLE_VIEW: () => {
    return (
      <FormattedMessage
        id="os-data-center-browse.search-results-view-actions.toggle-view"
        defaultMessage="Toggle View"
        description="Toggle the view of the search results between compact and fluffy"
      />
    );
  },
  INFORMATION: () => {
    return (
      <FormattedMessage
        id="os-data-center-browse.search-results-view-actions.info"
        defaultMessage="View Information"
        description="Info button on the search results view header"
      />
    );
  },
};

export const HeaderActions: FC<IHeaderActions> = ({
  onViewStyleChange,
  onSortMethodChange,
  viewStyle,
  hideHeaderActions,
}) => {
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const VerticalDivider = (): JSX.Element => {
    return <div className={classes.verticalDivider}></div>;
  };

  const isFluffyView = viewStyle === EResultsViewStyle.FLUFFY;
  const handleClickChangeView = () => {
    onViewStyleChange();
  };

  const Action = ({ icon, onClick, label, id }: IHeaderActionButton) => {
    return (
      <>
        <Typography id={id} style={visuallyHidden}>
          {label}
        </Typography>
        <Tooltip title={label}>
          <IconButton aria-labelledby={id} onClick={onClick} size="large">
            {icon}
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const isNotAllowed = (action: EResultsViewAction): boolean => {
    return hideHeaderActions.includes(action);
  };

  const Sort = () => {
    if (isNotAllowed(EResultsViewAction.SORT)) return null;

    return (
      <AiSearchHeaderSortMenu label={ACTIONS_TEXT.CHANGE_SORT()} onSortMethodChange={onSortMethodChange} />
    );
  };

  const Info = () => {
    if (isNotAllowed(EResultsViewAction.INFO)) return null;

    return (
      <Action
        id="search-results-view-action-info"
        icon={<Information />}
        onClick={() => console.log('clicked info')}
        label={ACTIONS_TEXT.INFORMATION()}
      />
    );
  };

  return (
    <div id={'dc-search-header-action-items-container'} className={classes.actionsContainer}>
      <VerticalDivider />
      <div>
        <Sort />

        {!isNotAllowed(EResultsViewAction.FILTER) && (
          <FilterIcon id="dc-ai-search-results-filter-icon" onFilterClick={handleOnFilterClick} />
        )}

        {!isNotAllowed(EResultsViewAction.VIEW) && (
          <>
            <Typography id="search-results-view-action-toggle-compact-fluffy" style={visuallyHidden}>
              {ACTIONS_TEXT.TOGGLE_VIEW()}
            </Typography>
            <Tooltip title={ACTIONS_TEXT.TOGGLE_VIEW()}>
              <IconButton
                aria-labelledby="search-results-view-action-toggle-compact-fluffy"
                onClick={handleClickChangeView}
                size="large"
              >
                {isFluffyView ? <Hamburger /> : <List />}
              </IconButton>
            </Tooltip>
          </>
        )}
        <Info />
      </div>
    </div>
  );

  function handleOnFilterClick(isActive: boolean) {
    if (isActive) {
      dispatch(actions.setAiSearchFilterPanelIsVisible(true));
    } else {
      dispatch(actions.setAiSearchFilterPanelIsVisible(false));
      dispatch(actions.resetSearchFilters());
      dispatch(searchActions.resetSearchResults());
      dispatch(searchActions.filesSearchStart());
    }
  }
};
