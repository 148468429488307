import { keyBy } from 'lodash';
import { all, call, put, select } from 'redux-saga/effects';
import {
  MessageSeverity,
  preferredLanguageSelector,
  selectIsFullscreenEnabled,
  showMessage,
  DataCenterView,
} from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { mountPanel, EPanelSize } from '@aiware/js/panel';
import { EntityType, AvailableComponents, AvailablePanels, AvailableWidgets } from '@aiware/js/interfaces';
import {
  GraphQLPage,
  IEngine,
  IEngineCategory,
  IFolder,
  IFolderVm,
  ISchedule,
  ISource,
  ISourceType,
  ITdo,
  LIMIT,
  TdoId,
} from '../../types';
import * as api from '../../api';
import { actions } from '../slices';
import * as selectors from '../selectors';
import { selectCurrentView } from '../selectors/ui-state';
import { selectRootFolderId, selectSelectedFolderId } from '../selectors/view-my-files';
import { TAddACLsInput, setACLsOnResources } from '@aiware/os/admin-center/permissions';
import { enginesFilter } from '@aiware/shared/reusable-utils';
import { downloadTdoByEntityId } from '../../helpers/sharedFunctions';
import { channel } from 'redux-saga';
import { Action } from 'redux';
import { mapTdoToGraphQL } from '../../helpers/tdoHelper';

const { selectApiConfigs } = selectors;

type TDynamicObject = {
  [x: string]: any;
};

export function* initDataCenter() {
  const shouldInit: string = yield select(selectors.uiState.selectUIStateStatus);

  if (shouldInit !== 'pending') {
    return;
  }

  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

  try {
    // Fetch all supporting entities needed by data center
    const [engineCategories, enginesV2, enginesV3, sourceTypes]: [
      GraphQLPage<IEngineCategory>,
      GraphQLPage<IEngine>,
      GraphQLPage<IEngine>,
      GraphQLPage<ISourceType>
    ] = yield all([
      call(enginesFilter.api.getEngineCategories, apiConfigs),
      call(enginesFilter.api.getEngines, apiConfigs, 2),
      call(enginesFilter.api.getEngines, apiConfigs, 3, ['active']),
      call(api.sources.getSourceTypes, apiConfigs),
    ]);

    yield put(actions.entities.engineCategoriesFetchSucceeded(engineCategories.records));
    yield put(actions.entities.enginesFetchSucceeded([...enginesV2.records, ...enginesV3.records]));
    yield put(actions.entities.sourceTypesFetchSucceeded(sourceTypes.records));
    yield put(actions.uiState.initDone());
  } catch (error) {
    // const errorMessage = error?.message;
    yield put(actions.uiState.initFailed());
  }
}

export function* fetchSources() {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const sourcePage: ReturnType<typeof selectors.viewStreams.selectPageSources> = yield select(
    selectors.viewStreams.selectPageSources
  );

  if (!sourcePage.hasNextPage) {
    return;
  }

  try {
    const data: GraphQLPage<ISource> = yield call(
      api.sources.getStreamingSources,
      apiConfigs,
      sourcePage.offset
    );

    const sources: ISource[] = yield all(
      data.records.map(s => s.id).map(id => call(api.sources.getStreamingSource, apiConfigs, id))
    );
    const revisedSources = sources.map((source: any) => {
      return source.records[0] || {};
    });

    const sourceEntities = keyBy(revisedSources, 'id');

    data.records = data.records.map(schedule => {
      return {
        ...(sourceEntities[schedule.id] || {}),
        ...schedule,
      };
    });

    yield put(actions.viewStreams.sourcesFetchSucceeded(data));
  } catch (error) {
    // const errorMessage = error?.message || '';
    yield put(actions.viewStreams.sourcesFetchFailed());
  }
}

export function* fetchSchedules(action: ReturnType<typeof actions.viewStreams.schedulesFetchStart>) {
  const sourceId = action.payload;
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

  const schedulesPage: ReturnType<typeof selectors.viewStreams.selectPageSchedulesBySourceId> = yield select(
    selectors.viewStreams.selectPageSchedulesBySourceId,
    sourceId
  );

  if (!schedulesPage?.hasNextPage) {
    return;
  }

  try {
    const data: GraphQLPage<ISchedule> = yield call(
      api.schedules.getStreamingSchedulesBySourceId,
      apiConfigs,
      schedulesPage?.offset,
      sourceId
    );

    yield put(actions.viewStreams.schedulesFetchSucceeded({ sourceId, data }));
  } catch (error: any) {
    const errorMessage = error?.message || '';
    yield put(
      actions.viewStreams.schedulesFetchFailed({
        sourceId,
        error: errorMessage,
      })
    );
  }
}

export function* fetchTdos(action: ReturnType<typeof actions.viewStreams.tdosFetchStart>) {
  const scheduleId = action.payload;
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const tdoPage: ReturnType<typeof selectors.viewStreams.selectPageTdosByScheduleId> = yield select(
    selectors.viewStreams.selectPageTdosByScheduleId,
    scheduleId
  );

  if (!tdoPage?.hasNextPage) {
    return;
  }

  try {
    const data: GraphQLPage<ITdo> = yield call(
      api.tdos.getTdosByScheduleId,
      apiConfigs,
      tdoPage.offset,
      scheduleId
    );
    yield put(actions.viewStreams.tdosFetchSucceeded({ scheduleId, data }));
  } catch (error: any) {
    const errorMessage = error?.message || '';
    yield put(actions.viewStreams.tdosFetchFailed({ scheduleId, error: errorMessage }));
  }
}

export function* fetchRootFolder() {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const currentView: ReturnType<typeof selectCurrentView> = yield select(selectCurrentView);

  try {
    let rootFolder: IFolder | undefined;
    const existingRootFolder: IFolder | undefined = yield call(api.folders.getOrgCmsRootFolder, apiConfigs);
    if (!existingRootFolder) {
      // try to create a new one
      rootFolder = yield call(api.folders.createOrgCmsRootFolder, apiConfigs);
    } else {
      rootFolder = existingRootFolder;
    }
    if (!rootFolder) {
      /**
       * This would only happen if there was no existing root folder,
       * AND the logged in user didn't have permissions to create one.
       * It's an edge case, but in that case, there's nothing for
       * datacenter to show and we should throw an error.
       */
      throw new Error('No root folder found, and unable to create one');
    }
    yield put(
      actions.viewMyFiles.rootFolderFetchSucceeded({
        rootFolder,
        viewType: currentView,
      })
    );
  } catch (error) {
    yield put(actions.viewMyFiles.rootFolderFetchFailed());
  }
}

export function* fetchFolderOrFiles(action: ReturnType<typeof actions.viewMyFiles.foldersOrFilesFetchStart>) {
  const parentFolderId = action.payload;
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const folderDetails: ReturnType<typeof selectors.viewMyFiles.selectFolderDetails> = yield select(
    selectors.viewMyFiles.selectFolderDetails,
    parentFolderId
  );
  const currentView: ReturnType<typeof selectors.uiState.selectCurrentView> = yield select(
    selectors.uiState.selectCurrentView
  );

  if (!folderDetails?.hasMore) {
    yield put(actions.viewMyFiles.foldersOrFilesFetchSucceeded({ parentFolderId }));
    return;
  }

  try {
    let folders: GraphQLPage<IFolder> | undefined = undefined;
    let files: GraphQLPage<IFolder> | undefined = undefined;

    if (folderDetails?.folders.hasNextPage) {
      folders = yield call(
        api.folders.getChildFoldersByParentFolderId,
        apiConfigs,
        folderDetails.folders.offset,
        parentFolderId
      );
    }

    // Check to see if all the folders have been fetched
    // If they have, fetch tdos (aka files)
    if (
      currentView !== DataCenterView.folders &&
      ((!folderDetails.folders.hasNextPage && folderDetails.files.hasNextPage) ||
        (folders && (folders as GraphQLPage<IFolder>).count < LIMIT))
    ) {
      const rootFolderId: ReturnType<typeof selectRootFolderId> = yield select(selectRootFolderId);

      if (rootFolderId === parentFolderId) {
        const conditions = [
          {
            operator: 'exists',
            name: 'time-slice.parentTreeObjectIds',
            not: true,
          },
        ];
        files = yield call(api.tdos.searchTdos, apiConfigs, conditions, LIMIT, folderDetails.files.offset);
      } else {
        files = yield call(
          api.tdos.getChildTdosByParentFolderId,
          apiConfigs,
          folderDetails.files.offset,
          parentFolderId
        );
      }

      if (files && files.count > 0) {
        // get the engines via search
        if (parentFolderId !== rootFolderId) {
          const { records = [] } = yield call(
            api.tdos.searchTdos,
            apiConfigs,
            [
              {
                operator: 'terms',
                field: 'recordingId',
                values: [...files.records.map((file: ITdo) => file.id)],
              },
            ],
            files.count,
            0
          );
          const entities = keyBy(records, 'id');
          files.records = files.records.map((file: ITdo) => ({
            ...file,
            ...entities[file.id],
          }));
        } else {
          const fileList: TdoId[] = files.records.map(file => file.id);
          const TDOs: ITdo = yield call(api.tdos.getTdos, apiConfigs, fileList);
          // there are files returned in elastic that do not exist in graphql - we are removing them with filter below
          files.records = files.records
            .map((file: ITdo) => {
              if (!(TDOs as any)[`tdo${file.id}`]) {
                return null;
              }
              return {
                ...(TDOs as any)[`tdo${file.id}`],
                ...file,
              };
            })
            .filter(file => {
              if (file) {
                return file;
              }
            });
        }
      }
    }

    yield put(
      actions.viewMyFiles.foldersOrFilesFetchSucceeded({
        parentFolderId,
        folders,
        files,
      })
    );
  } catch (error) {
    yield put(actions.viewMyFiles.foldersOrFilesFetchFailed(parentFolderId));
  }
}

export function* openAddNewFolder(action: ReturnType<typeof actions.panelAddFolder.openAddNewFolder>) {
  yield put(actions.panelAddFolder.openAddNewFolder(action.payload));
}

function* createNewFolderAndExit(parentFolderId: string, name: string, permissions?: any) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const locale: ReturnType<typeof preferredLanguageSelector> = yield select(preferredLanguageSelector);
  const folderEntities: ReturnType<typeof selectors.entities.selectFolderEntities> = yield select(
    selectors.entities.selectFolderEntities
  );
  const parentFolder = folderEntities[parentFolderId];

  const formatMessage = AIWareFormatMessage(locale || 'en');

  try {
    const data: IFolder = yield call(api.folders.createCmsFolder, apiConfigs, {
      parentFolderId: parentFolderId,
      parentTreeObjectId: parentFolder!.treeObjectId,
      name,
    });
    yield put(
      actions.panelAddFolder.createNewFolderSucceeded({
        parentFolderId: parentFolderId,
        newFolder: data,
      })
    );
    if (permissions) {
      yield call(applyPermissionsToFolder, data.id, permissions);
    }
    yield put(actions.panelAddFolder.closeAddNewFolder());
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderCreateSuccess',
        defaultMessage: 'Folder successfully created.',
        description: 'The success message pops up when folder is created.',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  } catch (error) {
    yield put(actions.panelAddFolder.createNewFolderFailed());
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderCreateError',
        defaultMessage: 'There was an error creating the folder',
        description: 'The success message pops up when folder is not created.',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

export function* createNewFolderInCurrentParent(
  action: ReturnType<typeof actions.panelAddFolder.createNewFolderInCurrentParent>
) {
  const addNewFolder: TDynamicObject = yield select(selectors.panelAddFolder.selectAddNewFolder);
  const parentFolderId: string = addNewFolder.parentFolderId;

  const permissions = addNewFolder?.permissions;

  const payload = action.payload;

  yield call(createNewFolderAndExit, parentFolderId, payload.name, permissions);
}

export function* createNewFolder(action: ReturnType<typeof actions.panelAddFolder.createNewFolderStart>) {
  const payload = action.payload;

  yield call(createNewFolderAndExit, payload.parentFolderId, payload.name);
}

export function* editFolder(action: ReturnType<typeof actions.panelEditFolder.editFolderStart>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const locale: ReturnType<typeof preferredLanguageSelector> = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale || 'en');

  try {
    const data: IFolder = yield call(api.folders.updateCmsFolder, apiConfigs, action.payload);
    yield put(actions.panelEditFolder.editFolderSucceeded(data));
    yield put(actions.panelEditFolder.closeEditFolder());
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderEditSuccess',
        defaultMessage: 'Folder successfully edited.',
        description: 'The success message pops up when folder is edited.',
      }),
      severity: MessageSeverity.Info,
    };
    yield put(showMessage(message));
  } catch (error) {
    yield put(actions.panelEditFolder.editFolderFailed());
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderEditError',
        defaultMessage: 'There was an error editing the folder.',
        description: 'The error message pops up when folder edit fails.',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

export function* applyPermissionsToFolder(folderId: string, permissions: any) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const locale: ReturnType<typeof preferredLanguageSelector> = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale || 'en');

  const permissionsWithTdoIds: TAddACLsInput = {
    ...permissions.permissions,
    ids: [folderId],
    type: 'Folder',
  };

  try {
    yield call(setACLsOnResources, apiConfigs, permissionsWithTdoIds);
  } catch (error) {
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderPermissionsError',
        defaultMessage: 'There was an error adding permissions to the folder.',
        description: 'The error message pops up when permissions fail to get applied to folder',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

export function* deleteTdo(action: ReturnType<typeof actions.tdos.deleteTdoStart>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const tdoEntities: ReturnType<typeof selectors.entities.selectTdoEntities> = yield select(
    selectors.entities.selectTdoEntities
  );
  const rootFolderId: ReturnType<typeof selectRootFolderId> = yield select(selectRootFolderId);
  const locale: ReturnType<typeof preferredLanguageSelector> = yield select(preferredLanguageSelector);
  const tdoId = action.payload;
  const { parentFolderId, scheduleId } = tdoEntities[tdoId]!;
  const formatMessage = AIWareFormatMessage(locale || 'en');

  try {
    yield call(api.tdos.deleteTdo, apiConfigs, tdoId);
    yield put(
      actions.tdos.deleteTdoSucceeded({
        tdoId,
        parentFolderId: parentFolderId || rootFolderId,
        parentScheduleId: scheduleId,
      })
    );

    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderDeleteSuccess',
        defaultMessage: 'File successfully deleted.',
        description: 'The success message pops up when folder is deleted',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  } catch (error) {
    yield put(actions.tdos.deleteTdoFailed(tdoId));
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.folderDeleteError',
        defaultMessage: 'There was an error deleting the file.',
        description: 'The error message pops up when folder delete fails.',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

export function* processTdo(action: ReturnType<typeof actions.tdos.process>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const tdoId = action.payload;
  const microFrontend = {
    name: AvailableComponents.DATA_CENTER_PROCESS_AI,
    config: {
      name: 'DATA CENTER PROCESS AI',
      tdoId,
    },
  };

  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    size: EPanelSize.small,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };
  yield put(
    mountPanel({
      panelId: AvailableComponents.DATA_CENTER_PROCESS_AI,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export function* viewTdoInfo(action: ReturnType<typeof actions.tdos.viewInfo>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const tdoId = action.payload;

  const microFrontend = {
    name: AvailableComponents.DATA_CENTER_FILE_INFO_PANEL,
    config: {
      entityId: tdoId,
      fileName: 'filename',
    },
  };

  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    size: EPanelSize.small,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };
  yield put(
    mountPanel({
      panelId: AvailableComponents.DATA_CENTER_FILE_INFO_PANEL,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export const updateTdoChannel = channel();
export function* handleUpdateTdoChannel(action: Action<ReturnType<typeof actions.entities.addTdoImported>>) {
  yield put(action as Action);
}

export function* editTdoMetadata(action: ReturnType<typeof actions.tdos.editMetadata>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const tdoId = action.payload;

  const microFrontend = {
    name: AvailableComponents.DATA_CENTER_EDIT_METADATA_PANEL,
    config: {
      tdoId,
      onSave: (tdo: ITdo) => {
        updateTdoChannel.put(
          actions.entities.addTdoImported({
            file: mapTdoToGraphQL(tdo),
          })
        );
      },
    },
  };

  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    size: EPanelSize.small,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };
  yield put(
    mountPanel({
      panelId: AvailableComponents.DATA_CENTER_EDIT_METADATA_PANEL,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export function* downloadTdo(action: ReturnType<typeof actions.tdos.download>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const tdoId = action.payload;
  const tdoInfo: ITdo | undefined = yield select(selectors.entities.selectTdoEntityById(tdoId));
  downloadTdoByEntityId(apiConfigs.apiRoot, tdoId, tdoInfo?.name);
}

export function* editSourceInfo(action: ReturnType<typeof actions.sources.editSource>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const sourceId = action.payload;

  const microFrontend = {
    name: AvailableComponents.PROCESSING_CENTER_SOURCES,
    config: {
      sourceId: sourceId,
    },
  };
  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    size: EPanelSize.large,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };

  yield put(
    mountPanel({
      panelId: AvailableComponents.PROCESSING_CENTER_SOURCES,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}
export function* viewSourceInfo(action: ReturnType<typeof actions.sources.viewSource>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const sourceId = action.payload;
  const sources: Record<string, ISource> = yield select(selectors.entities.selectSourceEntities);

  // find the source by id
  const source = sources[sourceId];

  // if the source is not found, return
  if (!source) {
    return;
  }

  const microFrontend = {
    name: AvailableComponents.DATA_CENTER_SOURCE_INFO_PANEL,
    config: {
      source,
    },
  };
  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    size: EPanelSize.small,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };

  yield put(
    mountPanel({
      panelId: AvailableComponents.DATA_CENTER_SOURCE_INFO_PANEL,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export function* viewScheduleInfo(action: ReturnType<typeof actions.schedules.viewSchedule>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const scheduleId = action.payload;
  const schedules: Record<string, ISchedule> = yield select(selectors.entities.selectScheduleEntities);
  const sources: Record<string, ISource> = yield select(selectors.entities.selectSourceEntities);

  // find the schedule by id
  const schedule = schedules[scheduleId];

  // if the schedule is not found, return
  if (!schedule) {
    return;
  }

  let source: ISource | undefined;
  // find the source by id
  if (schedule.sourceId) {
    source = sources[schedule.sourceId];
  }

  const microFrontend = {
    name: AvailableComponents.DATA_CENTER_SCHEDULE_INFO_PANEL,
    config: {
      schedule,
      source,
    },
  };
  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    size: EPanelSize.small,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };

  yield put(
    mountPanel({
      panelId: AvailableComponents.DATA_CENTER_SCHEDULE_INFO_PANEL,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export function* editScheduleInfo(action: ReturnType<typeof actions.schedules.editSchedule>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const scheduledJobId = action.payload;

  const microFrontend = {
    name: AvailableComponents.PROCESSING_CENTER_INGESTION,
    config: {
      name: 'New Ingestion',
      isFullScreen: isFullScreen,
      scheduledJobId,
    },
  };

  const panelConfig = {
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    size: EPanelSize.large,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    parentPanelId: AvailableComponents.DATA_CENTER,
    dimmed: 0,
    dimmedStatus: 'dimParent',
    borderBottom: true,
  };

  yield put(
    mountPanel({
      panelId: AvailableComponents.PROCESSING_CENTER_INGESTION,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export function* activateSchedule(action: ReturnType<typeof actions.schedules.activateSchedule>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const scheduleId = action.payload;
  const schedules: Record<string, ISchedule> = yield select(selectors.entities.selectScheduleEntities);
  const schedule = schedules[scheduleId];

  try {
    yield call(api.schedules.updateScheduledJobStatus, apiConfigs, scheduleId, true);
    yield put(
      actions.entities.toggleScheduleStatus({
        scheduleId,
      })
    );
    yield put(
      showMessage({
        content: `Schedule ${schedule?.name} has been activated.`,
        severity: MessageSeverity.Success,
      })
    );
  } catch (error) {
    yield put(
      showMessage({
        content: `There was an error activating schedule ${schedule?.name}.`,
        severity: MessageSeverity.Error,
      })
    );
  }
}

export function* deactivateSchedule(action: ReturnType<typeof actions.schedules.deactivateSchedule>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const scheduleId = action.payload;
  const schedules: Record<string, ISchedule> = yield select(selectors.entities.selectScheduleEntities);
  const schedule = schedules[scheduleId];

  try {
    yield call(api.schedules.updateScheduledJobStatus, apiConfigs, scheduleId, false);
    yield put(
      actions.entities.toggleScheduleStatus({
        scheduleId,
      })
    );
    yield put(
      showMessage({
        content: `Schedule ${schedule?.name} has been deactivated.`,
        severity: MessageSeverity.Success,
      })
    );
  } catch (error) {
    yield put(
      showMessage({
        content: `There was an error deactivating schedule ${schedule?.name}.`,
        severity: MessageSeverity.Error,
      })
    );
  }
}

export function* returnFoldersAndFilesPanelResult(
  action: ReturnType<typeof actions.viewMyFiles.panelResultSelected>
) {
  const folderEntities: ReturnType<typeof selectors.entities.selectFolderEntities> = yield select(
    selectors.entities.selectFolderEntities
  );

  const rootFolderId: string = yield select(selectors.viewMyFiles.selectRootFolderId);

  const folder: IFolderVm = {
    type: 'folder',
    folder: folderEntities[action.payload]!,
  };

  yield put({
    type: 'DATA_CENTER_FOLDERS_AND_FILES_RESPONSE',
    payload: {
      // we need to pass isRoot bool here to display "Home" text on the importer/Location
      // component based on this bool
      // see libs/os/data-center/importer/src/lib/components/LocationFolder/LocationFolder.tsx:64
      folder: { ...folder.folder, isRoot: folder.folder.id === rootFolderId },
    },
  });
}

export function* moveTdo(action: ReturnType<typeof actions.tdos.moveTdoStart>) {
  const isFullScreen: boolean = yield select(selectIsFullscreenEnabled);
  const tdoId = action.payload;
  yield put(actions.viewMyFiles.toggleFileOrFolderSelected(null));
  const microFrontend = {
    name: AvailableComponents.DATA_CENTER_FOLDERS_AND_FILES,
    config: {
      viewType: DataCenterView.folders,
      tdoId,
    },
  };

  const panelConfig = {
    panelId: AvailableComponents.DATA_CENTER_FOLDERS_AND_FILES,
    disableHide: false,
    parentPanelId: AvailableComponents.DATA_CENTER,
    type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
    size: EPanelSize.small,
    marginTop: isFullScreen ? 0 : 55,
    marginStart: isFullScreen ? 0 : 80,
    height: 600,
    header: {
      title: 'Choose a Location',
    },
    dimmed: 0,
    dimmedStatus: 'dimParent',
  };
  yield put(
    mountPanel({
      panelId: AvailableComponents.DATA_CENTER_FOLDERS_AND_FILES,
      microFrontend: microFrontend,
      panelConfig: panelConfig,
    })
  );
}

export function* moveTdoNewFolder(action: ReturnType<typeof actions.tdos.moveTdoNewFolder>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const locale: ReturnType<typeof preferredLanguageSelector> = yield select(preferredLanguageSelector);
  const rootFolderId: ReturnType<typeof selectRootFolderId> = yield select(selectRootFolderId);
  const tdo = action.payload.tdo;
  const tdoId = tdo.id;
  let parentFolderId = tdo.parentFolderId;
  const newFolderId = action.payload.selectedFolderId || rootFolderId;
  const formatMessage = AIWareFormatMessage(locale || 'en');
  // the parent folder id is null when we are moving a file from the root
  let tdoIsInRootFolder = !parentFolderId;
  // there is an odd edge case where a file that originally was in the root - moved out and moved back into root will show no parentId - but it will have a folder path
  // so it needs to use the moveTDOFromParentFolder call even though it is in root

  if (tdoIsInRootFolder && tdo.folders && tdo.folders.length > 0) {
    tdoIsInRootFolder = false;
    parentFolderId = rootFolderId;
  }

  try {
    if (tdoIsInRootFolder) {
      yield call(api.tdos.moveTdoFromRootFolder, apiConfigs, tdoId, newFolderId);
    } else {
      yield call(api.tdos.moveTdoFromParentFolder, apiConfigs, tdoId, parentFolderId, newFolderId);
    }

    // Elastic does not update fast enough to fetch the files for the root  - so if there are any updates to the root folder we are just swapping the folders the tdo's are in
    // For the other cases(ie.. non root to non root) we are refetching the folder data - since that folders files may not even be registered in the store yet
    if (rootFolderId && rootFolderId === newFolderId) {
      yield put(actions.viewMyFiles.addTdoFolder({ tdoId: tdoId, folderId: newFolderId }));
    }
    if (tdoIsInRootFolder || rootFolderId === parentFolderId) {
      if (parentFolderId === undefined) {
        parentFolderId = rootFolderId;
      }
      yield put(actions.viewMyFiles.removeTdoFolder({ tdoId: tdoId, folderId: parentFolderId }));
    }
    if (newFolderId && newFolderId !== rootFolderId) {
      yield put(actions.viewMyFiles.refreshFiles(newFolderId));
      yield put(actions.viewMyFiles.foldersOrFilesFetchStart(newFolderId));
    }

    if (parentFolderId && parentFolderId !== rootFolderId) {
      yield put(actions.viewMyFiles.refreshFiles(parentFolderId));
      yield put(actions.viewMyFiles.foldersOrFilesFetchStart(parentFolderId));
    }

    yield put(
      actions.tdos.moveTdoNewFolderSucceeded({
        tdoId,
        newParentFolderId: newFolderId,
      })
    );
    if (newFolderId && newFolderId !== rootFolderId) {
      yield put(
        actions.viewMyFiles.goToMyFilesBreadCrumb({
          isRoot: false,
          id: newFolderId,
          entity: EntityType.Folders,
        })
      );
    }
    // Clear the selected state
    yield put(actions.viewMyFiles.resetSelectedItems());

    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.fileMoveSuccess',
        defaultMessage: 'The file was successfully moved.',
        description: 'The success message pops up when file is moved',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  } catch (error) {
    if (parentFolderId && parentFolderId !== rootFolderId) {
      yield put(
        actions.viewMyFiles.goToMyFilesBreadCrumb({
          isRoot: false,
          id: parentFolderId,
          entity: EntityType.Folders,
        })
      );
    }
    const message: any = {
      content: formatMessage({
        id: 'os-data-center-browse.snackbar.fileMoveError',
        defaultMessage: 'There was an error moving the file.',
        description: 'The error message pops up when file move fails.',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

export function* moveMultipleTDOsNewFolder(
  action: ReturnType<typeof actions.tdos.moveMultipleTDOsNewFolder>
) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const locale: ReturnType<typeof preferredLanguageSelector> = yield select(preferredLanguageSelector);
  const rootFolderId: ReturnType<typeof selectRootFolderId> = yield select(selectRootFolderId);
  const newFolderId: ReturnType<typeof selectSelectedFolderId> = yield select(selectSelectedFolderId);
  const { tdos: TDOsToMove } = action.payload;

  const tdoIds = TDOsToMove.map((tdo: ITdo) => tdo.id);

  const message: any = {
    content: `Moving ${TDOsToMove.length} Files...`,
    severity: MessageSeverity.Info,
  };
  yield put(showMessage(message));

  // Create an object that tracks each TDO, their parent folder, and the success status
  const parentFolderTDOLocations = TDOsToMove.map((tdo: ITdo) => {
    let parentFolderId = tdo.parentFolderId;
    const tdoIsInRootFolder = !parentFolderId;
    if (tdoIsInRootFolder && tdo.folders && tdo.folders.length > 0) {
      parentFolderId = rootFolderId;
    }
    if (!parentFolderId) {
      parentFolderId = rootFolderId || '';
    }

    return {
      id: tdo.id,
      parentFolderId,
      success: false,
    };
  });

  // Iterate over each TDO and perform the moveTDO API call
  for (let i = 0; i < TDOsToMove.length; i++) {
    const tdo = TDOsToMove[i];

    try {
      let parentFolderId = tdo?.parentFolderId;
      let tdoIsInRootFolder = !parentFolderId;
      if (tdoIsInRootFolder && tdo?.folders && tdo?.folders.length > 0) {
        tdoIsInRootFolder = false;
        parentFolderId = rootFolderId;
      }

      // Perform the API call to move the files, which is different if they are within the Root
      if (tdoIsInRootFolder) {
        yield call(api.tdos.moveTdoFromRootFolder, apiConfigs, tdo?.id, newFolderId);
      } else {
        yield call(api.tdos.moveTdoFromParentFolder, apiConfigs, tdo?.id ?? '', parentFolderId, newFolderId);
      }

      // Update the success status
      parentFolderTDOLocations[i]!.success = true;
    } catch (error) {
      console.log('Attempted to Move a TDO but received an error.', tdo);
      console.error(error);
    }
  }
  // Clear the Selected Items state
  yield put(actions.viewMyFiles.resetSelectedItems());

  // Move the files internally on the UI
  yield put(actions.viewMyFiles.moveTDOsToNewLocation({ newFolderId, tdoIds, parentFolderTDOLocations }));
  yield put(actions.viewMyFiles.refreshFiles(newFolderId ?? ''));
  yield put(actions.viewMyFiles.foldersOrFilesFetchStart(newFolderId ?? ''));

  let successCount = 0;
  let errorCount = 0;
  parentFolderTDOLocations.forEach(({ success }) => {
    if (success) {
      successCount++;
    } else {
      errorCount++;
    }
  });

  let content = '';
  let severity = MessageSeverity.Success;

  if (errorCount === 0) {
    content = `Moved ${TDOsToMove.length} files.`;
  } else if (successCount === 0) {
    content = `Error occurred while moving all files.`;
    severity = MessageSeverity.Error;
  } else if (successCount && errorCount) {
    content = `Moved ${successCount} file${successCount === 1 ? '' : 's'}. Error moving ${errorCount} file${
      errorCount === 1 ? '' : 's'
    }.`;
    severity = MessageSeverity.Warning;
  }

  // Notify the user
  yield put(
    showMessage({
      content,
      severity,
    })
  );
}

export function* fetchFileInfo(action: ReturnType<typeof actions.fileInfo.fetchFileInfoStart>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  try {
    /**
     * If we eventually need to fetch data from more than 1 source for
     * the file info panel, we can do that in this saga and combine the
     * data into a single type to be stored in redux. For now, it's just
     * an ITdo
     */
    const fileInfo: ITdo = yield call(api.tdos.getTdo, apiConfigs, action.payload.entityId);
    yield put(actions.fileInfo.fetchFileInfoSucceeded({ fileInfo }));
  } catch (error) {
    yield put(actions.fileInfo.fetchFileInfoFailed());
  }
}
