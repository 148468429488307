import { createReducer } from '@reduxjs/toolkit';

import {
  subscriberSuccess,
  subscriberStart,
  subscriberError,
  setMailboxData,
  updateUnseenCount,
  markAllNotificationsSeenRequest,
  markAllNotificationsSeenSuccess,
  markAllNotificationsSeenFail,
} from './action';
import { ISubscriptionModule } from './interface';
// allow notification type
// notification module
export const initialState: ISubscriptionModule = {
  mailBoxIds: [] as ISubscriptionModule['mailBoxIds'],
  mailbox: [] as ISubscriptionModule['mailbox'],
  status: 'idle',
  failureMessage: '',
};

export const mailboxReducer = createReducer(initialState, builder => {
  builder
    .addCase(subscriberStart, state => ({
      ...state,
      status: 'loading',
    }))
    .addCase(subscriberSuccess, state => ({
      ...state,
      status: 'subscribing',
    }))
    .addCase(setMailboxData, (state, action) => ({
      ...state,
      mailbox: [...action.payload],
      mailBoxIds: [...action.payload.map(mailbox => mailbox.id)],
    }))
    .addCase(subscriberError, (state, action) => ({
      ...state,
      status: 'failure',
      failureMessage: action.payload,
    }))
    .addCase(updateUnseenCount, (state, action) => ({
      ...state,
      mailbox: state.mailbox.map(box => {
        return {
          ...box,
          unseenCount:
            action.payload.notificationMailboxes?.find(item => item.id === box.id)?.unseenCount ?? 0,
        };
      }),
    }))
    .addCase(markAllNotificationsSeenRequest, state => {
      return {
        ...state,
      };
    })
    .addCase(markAllNotificationsSeenSuccess, state => {
      return {
        ...state,
        mailbox: state.mailbox.map(box => ({
          ...box,
          unseenCount: 0,
        })),
      };
    })
    .addCase(markAllNotificationsSeenFail, state => {
      return {
        ...state,
        status: 'failure',
      };
    });
});
