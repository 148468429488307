import { IntlShape } from 'react-intl';

export const formattedMessages = (intl: IntlShape, days?: number) => ({
  clearButtonLabel: intl.formatMessage({
    id: `reusable-utils.filters.clear-button-label`,
    defaultMessage: 'Clear',
    description: "'Clear' label",
  }),
  statusFilterLabel: intl.formatMessage({
    id: `reusable-utils.filters.status-label`,
    defaultMessage: 'Status',
    description: "'Status' label",
  }),
  allLabel: intl.formatMessage({
    id: `reusable-utils.filters.all-placeholder`,
    defaultMessage: 'All',
    description: "'All' label",
  }),
  okButtonLabel: intl.formatMessage({
    id: 'reusable-utils.filters.ok-button-label',
    defaultMessage: 'OK',
    description: "'Ok' label",
  }),
  dateRangeFilterDefaultValueLabel: intl.formatMessage(
    {
      id: 'reusable-utils.filters.any-time-label',
      defaultMessage: '{days} days',
      description: "'Any Time' label",
    },
    { days: days ? days : undefined }
  ),
  userFilterLabel: intl.formatMessage({
    id: 'reusable-utils.filters.jobs.user-label',
    defaultMessage: 'Email',
    description: 'label for the jobs filter user',
  }),
});
