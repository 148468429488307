import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    border: '0.5px solid #D5DFE9!important',
    boxShadow: 'none!important',
    borderRadius: '4px!important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 32,
  },
  focusedCard: {
    backgroundColor: '#D5DFE9',
    border: '0.5px solid #D5DFE9!important',
    boxShadow: 'none!important',
    borderRadius: '4px!important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 32,
  },
  uploadIcon: {
    fontSize: '30px',

    '&:hover': {
      color: 'inherit',
    },
  },
  iconCircle: {
    color: theme.palette.secondary.main,
    backgroundColor: '#ffffff !important',
    fontSize: '30px !important',
    width: '70px !important',
    height: '70px !important',
    border: '6px solid #E8E8E8',
  },
  selectMessage: {
    fontWeight: 600,
    marginBlockStart: '12px',
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
  },
  fileFormatsButton: {
    color: '#6098D1',
    fontSize: '10px',
    marginTop: 10,
  },
}));

export default useStyles;
