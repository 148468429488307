import { ISagaModule } from 'redux-dynamic-modules-saga';
import permissionsReducer, { namespace, actions } from './slices';
import { rootSaga } from './sagas';
import * as selectors from './selectors';

export function getPermissionsModule(): ISagaModule<typeof permissionsReducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: permissionsReducer,
    },
    sagas: [rootSaga],
    initialActions: [],
  };
}

export { actions, selectors };

export type { TPermissionsEntity } from './slices';
