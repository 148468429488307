import { useState, useEffect, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { FormattedMessage } from 'react-intl';
import AppIcon from '../../atoms/AppIcon';
import { IApplication } from '../../../types';
import useStyles from './app-list.styles';
import classNames from 'classnames';

interface IAppListProps {
  selectedAppId: string | number | null;
  onSelectApplication: (id: string | number) => void;
  title: ReactElement | string;
  applications: IApplication[];
  useGrid: boolean;
  row?: number;
  type?: string;
}

function AppList({
  title,
  selectedAppId,
  onSelectApplication,
  applications = [],
  useGrid = false,
  row = 0,
}: IAppListProps) {
  const { classes } = useStyles({ row });
  const [currentSelected, setSelected] = useState(selectedAppId);
  const [sortedApplication, setApplication] = useState<IApplication[]>([]);
  useEffect(() => {
    if (applications.length > 0) {
      const shallowCpApp = [...applications];
      const processedApp = shallowCpApp.sort(sortAlphabetically);
      setApplication(processedApp);
    }
  }, [applications]);
  useEffect(() => {
    if (selectedAppId !== currentSelected) {
      setSelected(selectedAppId);
    }
  }, [selectedAppId, currentSelected]);

  function handleSelectApplication(id: string | number) {
    setSelected(id);
    onSelectApplication(id);
  }

  function sortAlphabetically(a: { name: string }, b: { name: string }) {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  }

  return (
    <div className={classNames({ [classes.gridTitle]: useGrid }, classes.tabsContainer)}>
      <div className={classNames({ [classes.padding]: !useGrid }, classes.noSelect)}>
        <Typography variant="h3" sx={{ marginBottom: '10px' }}>
          {title}
        </Typography>
      </div>
      {useGrid && (
        <div className={classes.gridContainer}>
          <Grid container>
            {applications.length === 0 ? (
              <Typography variant="body2">
                <FormattedMessage
                  id="os-app-bar-panel.Xzhf45"
                  defaultMessage="No Applications"
                  description="the label no app will show if we have no application for grid view"
                />
              </Typography>
            ) : (
              sortedApplication.length > 0 &&
              sortedApplication.map((item, index) => {
                const { id = index, iconUrl, name } = item;
                return (
                  <Grid item key={id} xs={4}>
                    <div className={classes.gridItem}>
                      <AppIcon
                        type="grid"
                        selected={id === currentSelected}
                        onClick={handleSelectApplication}
                        iconUrl={iconUrl}
                        id={id}
                        appName={name}
                      />
                    </div>
                  </Grid>
                );
              })
            )}
          </Grid>
        </div>
      )}
      {!useGrid && (
        <div className={classes.listContainer}>
          {applications.length === 0 ? (
            <div className={classes.noMoreApps}>
              <Typography variant="body2">
                <FormattedMessage
                  id="os-app-bar-panel.dyhBih"
                  defaultMessage="No Applications"
                  description="the label no app will show if we have no application for list view"
                />
              </Typography>
            </div>
          ) : (
            <div className={classes.moreAppContainer}>
              {applications.length > 0 &&
                applications.sort(sortAlphabetically).map((item, index) => {
                  const { id = index, iconUrl, name } = item;
                  return (
                    <div key={`${id}_${index}`} className={classes.relativeParent}>
                      <AppIcon
                        type="list"
                        selected={id === currentSelected}
                        onClick={handleSelectApplication}
                        iconUrl={iconUrl}
                        id={id}
                        appName={name}
                      />
                      <Divider className={classes.appItemDivider} />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AppList;
