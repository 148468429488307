import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

import * as uiState from './ui-state';
import * as rootFolderSelectors from './root-folder-state';
import * as filesSelectedState from './files-selected-state';

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

export { uiState, rootFolderSelectors, filesSelectedState };
