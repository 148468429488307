const getACLsQuery = (orgFilter: string) => `
    query getACLs ( $resourceType: AuthResourceType!, $ids: [ID]! ){
        getACLForResources (
          resourceType: $resourceType,
          ids: $ids
          ${orgFilter}
        ){
        records {
          id
          objectID
          objectType
          permissionSet {
            permissionId: id
            permissionName: name
            permissionDescription: description
          }
          createdAt
          member {
            ... on User {
              id
              email: name
              firstName
              lastName
              memberType: __typename
            }
            ... on AuthGroup {
              id
              name
              description
              memberCount
              memberType: __typename
              members {
                count
                records {
                  member {
                    ... on User {
                      id
                      email: name
                      firstName
                      lastName
                    }
                    ... on AuthGroup {
                      id
                      name
                      description
                      members {
                        count
                        records {
                          member {
                            ... on User {
                              id
                              name: email
                              firstName
                              lastName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

const setACLsMutation = (orgFilter: string) => `
    addACEsToResources (
      resourceType: $resourceType
      ids: $ids,
      entries: $entries
      ${orgFilter}
    ){
    records {
      id
      objectID
      objectType
      permissionSet {
        permissionId: id
        permissionName: name
        permissionDescription: description
      }
      member {
        ... on User {
          id
          email: name
          firstName
          lastName
          memberType: __typename
        }
        ... on AuthGroup {
          id
          name
          description
          memberType: __typename
          members {
            count
            records {
              member {
                ... on User {
                  id
                  email: name
                  firstName
                  lastName
                }
                ... on AuthGroup {
                  id
                  name
                  description
                  members {
                    count
                    records {
                      member {
                        ... on User {
                          id
                          name: email
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
    }
  }
`;

const removeACLsMutation = (orgFilter: string) => `
  removeACEsFromResource (
    resourceType: $resourceType
    ids: $aceIDsToRemove
    ${orgFilter}
  ){
    records {
      id
      objectID
      objectType
      permissionSet {
        permissionId: id
        permissionName: name
        permissionDescription: description
      }
      member {
        ... on User {
          id
          email: name
          firstName
          lastName
          memberType: __typename
        }
        ... on AuthGroup {
          id
          name
          description
          memberType: __typename
          members {
            count
            records {
              member {
                ... on User {
                  id
                  email: name
                  firstName
                  lastName
                }
                ... on AuthGroup {
                  id
                  name
                  description
                  members {
                    count
                    records {
                      member {
                        ... on User {
                          id
                          name: email
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
    }
  }
`;

export { setACLsMutation, removeACLsMutation, getACLsQuery };
