import { ISagaModule } from 'redux-dynamic-modules-saga';
import { SLICE_NAME } from '../types';
import { rootReducer } from './slices';
import { rootSaga } from './sagas';

export function getDataCenterImporterModule(): ISagaModule<typeof rootReducer> {
  return {
    id: SLICE_NAME,
    reducerMap: {
      [SLICE_NAME]: rootReducer,
    },
    sagas: [rootSaga],
    initialActions: [],
  };
}
