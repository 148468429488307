import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createOrganizationInvitesSaga,
  updateOrganizationInvitesSaga,
  reRequestOrganizationInvitesSaga,
  fetchPendingOrganizationInvitesSaga,
  updatePendingInviteStatusSaga,
} from './';
import { actions } from '../../slices';

export function* createOrganizationInvitesWatcher() {
  yield takeEvery(
    actions.organizationInvitesState.createOrganizationInviteStart.type,
    createOrganizationInvitesSaga
  );
}

export function* updateOrganizationInvitesWatcher() {
  yield takeEvery(
    actions.organizationInvitesState.updateOrganizationInviteStart.type,
    updateOrganizationInvitesSaga
  );
}

export function* reRequestOrganizationInvitesWatcher() {
  yield takeEvery(
    actions.organizationInvitesState.reRequestOrganizationInviteStart.type,
    reRequestOrganizationInvitesSaga
  );
}
export function* pendingOrganizationInvitesWatcher() {
  yield takeLatest(
    actions.organizationInvitesState.fetchPendingInvitesStart.type,
    fetchPendingOrganizationInvitesSaga
  );
}
export function* updatePendingInviteStatusWatcher() {
  yield takeLatest(
    actions.organizationInvitesState.updatePendingInviteStatus.type,
    updatePendingInviteStatusSaga
  );
}
