import { LoadingStateRow } from '../../helpers';
import { SourceItem } from './SourceItem';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ISource } from '../../../../../../../../types';

export const SourceInfiniteList = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  selectedDataSet,
  onClickExpand,
  onClearAll,
  onAddSource,
  onRemoveSource,
  expandedSourceId,
}: {
  hasNextPage: any;
  isNextPageLoading: any;
  items: ISource[];
  loadNextPage: any;
  selectedDataSet: any;
  onClickExpand: (event: React.MouseEvent<HTMLButtonElement>, sourceID: string) => void;
  onClearAll: (sourceId: string | number) => void;
  onAddSource: (source: ISource) => void;
  onRemoveSource: (source: ISource) => void;
  expandedSourceId: string | number;
}) => {
  // INFINITE LOADING
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading
    ? () => {
        // TODO: Implement Redux Sagas fetch more sources
      }
    : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  /** Infinite List Item Component - required to inject the styles to position the element. This
   * component wraps the Schedule and Loading Indicator and will conditionally render based on
   * the isItemLoaded() return value. **/
  const Item = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = <LoadingStateRow />;
    } else {
      const source = items[index]!;
      content = (
        <SourceItem
          source={source}
          key={index}
          isExpanded={expandedSourceId === source.id}
          onClickExpand={onClickExpand}
          onClearAll={onClearAll}
          onAddSource={onAddSource}
          onRemoveSource={onRemoveSource}
          selectedDataSet={selectedDataSet}
        />
      );
    }

    return <div style={style}>{content}</div>;
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <List
          height={230}
          itemCount={itemCount}
          itemSize={60}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={'100%'}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
};
