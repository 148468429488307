import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  authSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

import * as organizationSelectors from './organizations.selector';
import * as scimIdSelectors from './scimIds.selector';
import * as organizationInvitesSelectors from './organizationInvites.selector';

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);
// eslint-disable-next-line
export const selectUserId: (state: unknown) => string = createSelector(
  authSelector,
  (authInfo: { userId: string }) => authInfo.userId
) as (state: unknown) => string;

export { organizationSelectors, scimIdSelectors, organizationInvitesSelectors };
