import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import flatten from 'lodash/flatten';
import { fileTypeFilterValues } from '../../constants/aiSearchFilter.constants';
import { ISource } from '../../../../types';

export type AiSearchFilterType = {
  [filterType: string]: {
    filterType: string;
    values: string[];
  };
};

export type AiSearchSelectedFilterValuesType = {
  [filterType: string]: {
    values?: string[];
  };
};

export const namespace = 'aiSearchFilter';

export const initialState: State = {
  isVisible: false,
  selectedValues: {},
  filters: {
    fileType: {
      filterType: 'fileType',
      values: [],
    },
    engineType: {
      filterType: 'engineType',
      values: [],
    },
    programId: {
      filterType: 'programId',
      values: [],
    },
    mediaSourceId: {
      filterType: 'mediaSourceId',
      values: [],
    },
    absoluteStartTimeMs: {
      filterType: 'absoluteStartTimeMs',
      values: [],
    },
  },
  dateRangeType: 'any',
  sourceSearchTerm: '',
  searchedSources: [],
  isLoading: false,
  error: null,
  hasMore: false,
  offset: 0,
};

export interface State {
  isVisible: boolean;
  selectedValues: AiSearchSelectedFilterValuesType;
  filters: AiSearchFilterType;
  dateRangeType: string;
  sourceSearchTerm: string;
  searchedSources: Array<ISource>;
  isLoading: boolean;
  error: Error | null;
  hasMore: boolean;
  offset: number;
}

export const aiSearchFilterSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setSearchFilters: (state, { payload }: PayloadAction<any>) => {
      state.filters[payload.filterType] = payload;
    },
    setAiSearchFilterPanelIsVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isVisible = payload;
    },
    setDateRangeType: (state, { payload }: PayloadAction<string>) => {
      state.dateRangeType = payload;
    },
    resetSelectedFilterValues: state => {
      state.selectedValues = initialState.selectedValues;
    },
    resetSearchFilters: state => {
      state.filters = initialState.filters;
    },
    resetDateRangeType: state => {
      state.dateRangeType = initialState.dateRangeType;
    },
    setSelectedFilterValues: (state, { payload }: PayloadAction<any>) => {
      state.selectedValues[payload.filterType] = state.selectedValues[payload.filterType] || {};
      state.selectedValues[payload.filterType]!.values = payload?.value || [];
    },
    setFileTypeFilter: (state, { payload }: PayloadAction<any>) => {
      const filterValues = payload;

      const fileTypeValues = filterValues.map((filterValue: any) => {
        return (fileTypeFilterValues as any)[filterValue]?.values;
      });

      state.filters.fileType!.values = flatten(fileTypeValues);
    },
    sourcesSearchStart: state => {
      if (state.offset === 0) {
        state.isLoading = true;
      }
    },
    sourcesSearchSucceeded: (state, { payload }: PayloadAction<[ISource]>) => {
      state.searchedSources = [...state.searchedSources, ...payload];
      state.error = null;
      state.isLoading = false;
    },

    resetSourcesSearch: state => {
      state.searchedSources = [];
      state.isLoading = false;
      state.offset = 0;
      state.hasMore = false;
      state.error = null;
    },
    setOffset: (state, { payload }: PayloadAction<number>) => {
      state.offset = payload;
    },
    setHasMore: (state, { payload }: PayloadAction<boolean>) => {
      state.hasMore = payload;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setSourceSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.sourceSearchTerm = payload;
    },
  },
});

export const actions = aiSearchFilterSlice.actions;
export default aiSearchFilterSlice.reducer;
