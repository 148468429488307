import { DataCenterState } from '../../../../redux/slices';
import { ICriterion } from '../../types/aiAutocomplete.types';

export const selectSearchFilesState = (state: DataCenterState) => {
  return state.dataCenter.aiSearch?.files;
};

export const selectLastColumn = (state: DataCenterState) => {
  return state.dataCenter.aiSearch.lastColumn;
};

export const selectLastColumnEnabled = (state: DataCenterState) => {
  return state.dataCenter.aiSearch.lastColumn.enabled;
};

export const selectSearchActiveCriteria = (state: DataCenterState) => {
  return state.dataCenter.aiSearch.activeCriteria;
};

export const selectIsLoadingStatus = (state: DataCenterState): boolean => {
  return state.dataCenter.aiSearch.isLoading;
};

export const selectSearchHasMore = (state: DataCenterState): boolean => {
  return state.dataCenter.aiSearch.hasMore;
};

export const selectSearchOffset = (state: DataCenterState): number => {
  return state.dataCenter.aiSearch.offset;
};

export const selectTotalResultsAmount = (state: DataCenterState): number => {
  return state.dataCenter.aiSearch.totalResults;
};

export const selectUniqueTdos = (state: DataCenterState): string[] => {
  return state.dataCenter.aiSearch.uniqueTdos;
};

export const selectSelectedCriteria = (state: DataCenterState): ICriterion[] => {
  return state.dataCenter.aiSearch.searchCriteria;
};
