import { useState, MouseEvent, ChangeEvent, ReactNode } from 'react';
import {
  Paper,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Stepper,
  Step,
  StepLabel,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles()(() => ({
  paper: {
    padding: '20px',
  },
  spacer: {
    height: '5px',
    width: '100%',
  },
}));

const getSteps = () => {
  return ['Step 1', 'Step 2 description', 'Last step description'];
};

const getStepContent = (step: number) => {
  switch (step) {
    case 0:
      return 'Step 1 - do something...';
    case 1:
      return 'Step 2 - is optional?';
    case 2:
      return 'step 3 - finish';
    default:
      return 'Unknown step';
  }
};

export const DefaultThemesTabsStepsMenu = () => {
  const { classes } = useStyles();
  const [tabValue, setTabValue] = useState(2);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const steps = getSteps();

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenu2 = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorEl2(null);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleTabChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <Paper className={classes.paper}>
      <Typography variant="h1">Tabs</Typography>
      <div className={classes.spacer} />
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="disabled tabs example">
        <Tab label="Tab Label" />
        <Tab label="Tab Label" />
        <Tab label="Tab Label" />
      </Tabs>

      <div className={classes.spacer} />
      <Typography variant="h1">Stepper</Typography>
      <div className={classes.spacer} />
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: ReactNode } = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="body2">All steps completed - you are finished</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button variant="contained" color="secondary" onClick={handleSkip}>
                  Skip
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ height: '20px', width: '100%' }} />
      <Typography variant="h1">Menu</Typography>
      <div className={classes.spacer} />
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickMenu}>
        Open Menu
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={handleCloseMenu}>settings</MenuItem>
        <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
        <MenuItem onClick={handleCloseMenu}>Logout</MenuItem>
      </Menu>
      <Button aria-controls="simple-menu2" aria-haspopup="true" onClick={handleClickMenu2}>
        Open Menu
      </Button>
      <Menu
        id="simple-menu2"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl2}
        open={Boolean(anchorEl2)}
        onClose={handleCloseMenu2}
      >
        <MenuItem onClick={handleCloseMenu2}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send mail" />
        </MenuItem>
        <MenuItem onClick={handleCloseMenu2}>
          <ListItemIcon>
            <SaveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="do something" />
        </MenuItem>
        <MenuItem onClick={handleCloseMenu2}>
          <ListItemIcon>
            <SaveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Save" />
        </MenuItem>
      </Menu>
    </Paper>
  );
};
