import { ISagaModule } from 'redux-dynamic-modules-saga';
import appGroupReducer from './slice';
import appGroupSaga from './saga';
import { namespace } from './slice';

export function getAppGroupModule(): ISagaModule<typeof appGroupReducer> {
  return {
    id: 'user-app-group',
    reducerMap: {
      [namespace]: appGroupReducer,
    },
    sagas: [appGroupSaga],
    initialActions: [],
  };
}

export * from './type';
export * from './slice';
export * from './selector';
