import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { getDataCenterBrowseModule } from '../../../../redux';
import { MainLayout } from './MainLayout';
import { ITdo } from '../../../../types';

export const EditMetadataPanel = (props: { tdoId: string; onSave: (tdo: ITdo) => void }) => (
  <AIWareThemeProvider>
    <DynamicModuleLoader modules={[getDataCenterBrowseModule()]}>
      <AIWareIntlProvider>
        <MainLayout {...props} />
      </AIWareIntlProvider>
    </DynamicModuleLoader>
  </AIWareThemeProvider>
);
