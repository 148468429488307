import React, { useState } from 'react';
import { Tooltip, Typography, TypographyProps as TProps, IconProps, useTheme } from '@mui/material';
import { CheckCircle, CopyAll } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { devErrorLogger } from '../../dev-error-logger';

export enum TCopyIconPosition {
  Left = 'left',
  Right = 'right',
}

export type TCopyTextProps = {
  id: string;
  value: string;
  displayValue?: string;
  description?: string;
  sx?: IconProps['sx'];
  variant?: TProps['variant'];
  iconSx?: IconProps['sx'];
  iconPosition?: TCopyIconPosition.Left | TCopyIconPosition.Right;
  TypographyProps?: Omit<TProps, 'sx' | 'variant'>;
};

export const CopyText: React.FC<TCopyTextProps> = ({
  id,
  value,
  displayValue,
  description = 'Copy text',
  sx = {},
  variant = 'body2',
  iconSx = {},
  iconPosition = TCopyIconPosition.Right,
  TypographyProps = {},
}) => {
  const theme = useTheme();
  const [isCopied, setIsCopied] = useState(false);
  const [failed, setFailed] = useState(false);

  const iconStyle = {
    marginLeft: iconPosition === TCopyIconPosition.Right ? '5px' : 0,
    marginRight: iconPosition === TCopyIconPosition.Left ? '5px' : 0,
    fontSize: '18px!important',
    verticalAlign: 'middle',
    '&:active': {
      color: theme.palette.action.active,
    },
    '&:hover': {
      color: theme.palette.action.active,
      cursor: 'pointer',
    },
    ...iconSx,
  };

  const handleOnCopy = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    navigator.clipboard
      .writeText(value)
      .then(() => {
        setIsCopied(true);
      })
      .catch(error => {
        setFailed(true);
        devErrorLogger(`Error writing text to clipboard: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        if (!failed) {
          setTimeout(() => setIsCopied(false), 500);
        } else {
          setTimeout(() => setFailed(false), 500);
        }
      });
  };

  const text = (
    <Tooltip title={value}>
      <Typography {...TypographyProps} data-test={id + '.text'} noWrap variant={variant} sx={sx}>
        {displayValue || value}
      </Typography>
    </Tooltip>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
      {iconPosition === TCopyIconPosition.Right && text}
      {value && (
        <Tooltip
          title={
            <FormattedMessage
              id="copy-text-tooltip"
              defaultMessage="{description}"
              description="Tooltip message for copy text button"
              values={{ description: isCopied ? 'Copied' : description }}
            />
          }
          disableInteractive
        >
          <div onClick={handleOnCopy}>
            {!isCopied && <CopyAll sx={iconStyle} />}
            {isCopied && <CheckCircle sx={iconStyle} />}
          </div>
        </Tooltip>
      )}
      {iconPosition === TCopyIconPosition.Left && text}
    </div>
  );
};
