import { FunctionComponent } from 'react';
import SchedulesTableFluffyRow from './SchedulesTableFluffyRow';
import SchedulesTableRow from './SchedulesTableRow';
import Divider from '@mui/material/Divider';
import { useStyles } from '../../useStyles';
import { ISchedule, InfiniteWrapperItemType } from '../../../../types';

const SchedulesRows: FunctionComponent<{
  index: number;
  items: InfiniteWrapperItemType;
  rowHeight: number;
  isFluffy: boolean;
}> = ({ index, items, rowHeight, isFluffy }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.row}>
      {isFluffy ? (
        <SchedulesTableFluffyRow item={(items as ISchedule[])[index]!} rowHeight={rowHeight} />
      ) : (
        <SchedulesTableRow item={(items as ISchedule[])[index]!} rowHeight={rowHeight} />
      )}
      <Divider className={classes.divider} />
    </div>
  );
};
export default SchedulesRows;
