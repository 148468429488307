import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useCardStyles } from './useStyles';
import Divider from '@mui/material/Divider';
import { SHARED_TEXT } from '../../helpers/shared-text';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { mountPanel } from '@aiware/js/panel';
import { actions } from '../../redux/slices';
import { AvailableComponents, AvailablePanels } from '@aiware/js/interfaces';
import { TInvitee } from '../../types';
import { EUserStatus } from './user-lookup';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

type TCardProps = TInvitee & { showAdminData: boolean };

type UserCardActionsProps = {
  disableAdd: boolean;
  hasApps: number;
  applicationsAction: () => void;
  removeUserAction: (email: string) => void;
  email: string;
};

const UserCardActions = ({
  disableAdd,
  applicationsAction,
  removeUserAction,
  hasApps,
  email,
}: UserCardActionsProps) => {
  const { classes } = useCardStyles();
  return (
    <CardActions>
      <div className={classes.actionButtonWrapper}>
        <Button
          disabled={disableAdd}
          startIcon={hasApps ? <EditOutlinedIcon /> : <AddOutlinedIcon />}
          onClick={applicationsAction}
          data-testid="user-card-add-edit-apps"
        >
          {SHARED_TEXT.addButtonText} {SHARED_TEXT.applications}
        </Button>
      </div>
      <div className={classes.actionButtonWrapper}>
        <Button
          startIcon={<DeleteOutlineOutlinedIcon />}
          className={classes.removeUserButton}
          onClick={() => removeUserAction(email)}
          data-testid="user-card-remove-user"
        >
          {SHARED_TEXT.removeUserButton}
        </Button>
      </div>
    </CardActions>
  );
};

export const UserCard = ({
  avatar,
  firstName,
  lastName,
  email,
  status,
  applicationRoles = [],
  showAdminData,
}: TCardProps) => {
  const { classes } = useCardStyles();
  const isNew = status === EUserStatus.new;
  const avatarChildren = isNew ? '' : firstName!.substring(0, 1) + lastName!.substring(0, 1);
  const hasApps = applicationRoles.length;
  const moreThanOne = applicationRoles.length > 1;
  const isPending = status === 'pending';
  const formattedEmail = isNew ? email.charAt(0).toUpperCase() + email.slice(1) : email;
  const isPanelFullScreen = useSelector(selectIsFullscreenEnabled);

  const dispatch = useDispatch();

  const handleClickAddApplications = () => {
    dispatch(actions.organizationsState.setCurrentInvitee(email));
    const microFrontend = {
      name: AvailableComponents.ADD_APPLICATIONS,
      config: {
        name: 'Add Applications',
      },
    };

    const panelConfig = {
      type: AvailablePanels.APP_BAR_PANEL_TEMPLATE,
      marginTop: isPanelFullScreen ? 0 : 55,
      marginStart: isPanelFullScreen ? 0 : 80,
      size: 'medium',
      parentPanelId: 'INVITE_USERS_PANEL',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: AvailableComponents.ADD_APPLICATIONS,
        microFrontend,
        panelConfig,
      })
    );
  };

  const userApplications_notPending = hasApps ? (
    <>
      {SHARED_TEXT.accessTo} {hasApps} {moreThanOne ? SHARED_TEXT.applications : SHARED_TEXT.application}
    </>
  ) : (
    SHARED_TEXT.noApplicationsText
  );

  const userApplications = isPending ? SHARED_TEXT.hasPendingInvite : userApplications_notPending;

  const userHasAppsClasses = hasApps ? classes.hasApplications : classes.noApplications;

  const userAppClasses = isPending ? classes.isPending : userHasAppsClasses;

  const removeUser = () => dispatch(actions.organizationsState.removeInvitee(email));

  return (
    <Card className={classes.root} data-testid="multi-org-user-card">
      <CardContent>
        <div className={classes.userCardUserInfoRow}>
          <div className={classes.flexRow}>
            {!isNew && showAdminData && (
              <Avatar src={avatar} data-testid="multi-org-user-avatar">
                {avatarChildren}
              </Avatar>
            )}
            <div
              data-testid="multi-org-user-info"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {!isNew && showAdminData && (
                <p className={classes.userCardName}>
                  {firstName} {lastName}
                </p>
              )}
              <p className={!isNew ? classes.userCardEmail : classes.userCardName}>{formattedEmail}</p>
              <p className={userAppClasses}>{userApplications}</p>
              {applicationRoles?.length > 0 && (
                <AvatarGroup
                  max={5}
                  sx={{
                    transform: 'scale(0.45)',
                    transformOrigin: 'left top',
                    mt: '5px',
                  }}
                >
                  {applicationRoles.map(appRole => {
                    return (
                      <Tooltip
                        title={
                          <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                            <>
                              {SHARED_TEXT.appText}: {appRole.application?.name}
                              <br /> {SHARED_TEXT.roleText}: {appRole.role}
                            </>
                          </Typography>
                        }
                      >
                        <Avatar src={appRole.application?.iconUrl} data-testid="multi-org-user-avatar" />
                      </Tooltip>
                    );
                  })}
                </AvatarGroup>
              )}
            </div>
          </div>
        </div>
      </CardContent>
      <Divider />
      {!isPending && (
        <UserCardActions
          disableAdd={isPending}
          removeUserAction={removeUser}
          hasApps={hasApps}
          email={email}
          data-testid="multi-org-user-card-actions"
          applicationsAction={handleClickAddApplications}
        />
      )}
    </Card>
  );
};
