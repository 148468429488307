import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { selectTableDataSettings } from '../../redux/selectors/ui-state';
import Typography from '@mui/material/Typography';
import { TABLE_DATA_NAMES, TableDataKeys } from '../../types/entity-data-settings';
import { useStyles } from '../components/useStyles';

const GetRowItemHeader: FunctionComponent<{
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
  item: TableDataKeys;
}> = ({ item, xs, itemType }) => {
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const { classes } = useStyles();
  const hasColumn = (item: TableDataKeys) => {
    return columns.indexOf(item) >= 0;
  };

  if (!hasColumn(item)) {
    return null;
  }
  const className =
    item === 'fileType' || item === 'sourceTypeId' || item === 'scheduleType' ? classes.headerType : '';

  if (item === 'name') {
    return (
      <Grid item xs>
        <Typography
          data-test={`${itemType}-th-item-${item}`}
          noWrap
          variant="caption"
          color="textSecondary"
          className={classes.headerItem}
        >
          {TABLE_DATA_NAMES[item]?.()}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item className={className} xs={xs}>
      <Typography
        data-test={`${itemType}-th-item-${item}`}
        variant="caption"
        color="textSecondary"
        className={classes.headerItem}
      >
        {TABLE_DATA_NAMES[item]?.()}
      </Typography>
    </Grid>
  );
};

export default GetRowItemHeader;
