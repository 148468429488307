import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  container: {
    '&:first-of-type': {
      marginTop: '10px',
    },
    '&:last-of-type': {
      marginBottom: '10px',
    },
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '30px',
    borderBottom: '0.5px solid #D5DFE9',
    '&:hover': {
      backgroundColor: '#EEF3F9',
      cursor: 'pointer',
    },
  },
  isActive: {
    backgroundColor: '#EEF3F9',
  },
  iconContainer: {
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& img': {
      width: '50%',
      height: '50%',
      objectFit: 'cover',
      borderRadius: '50%',
      border: '1px solid #D5DFE9',
      backgroundColor: '#fff',
    },
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '70%',
    gap: '5px',
  },
}));
