import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {},
  orgContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  clickDisabled: {
    cursor: 'not-allowed',
  },
  defaultOrgBadge: {
    borderRadius: '2px',
    height: '16px',
    backgroundColor: '#F4F4F4',
    border: '0.5px solid #9CA8B4',
    color: '#5C6269',
    marginLeft: theme.spacing(2.8),
    padding: theme.spacing(0.2, 2),

    '& >span': {
      padding: 0,
    },
  },
  organizationName: {
    display: 'inline-block',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '12px',
    width: '280px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  organizationSubtitle: {
    lineHeight: '16px',
    opacity: 0.7,
  },
  textContainer: {
    display: 'flex',
    [`& > .Sdk-MuiListItemText-root`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
