import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  tabPanelContainer: {
    '&:not([hidden])': {
      overflow: 'hidden',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      maxHeight: '100%',
    },
    '&.tab-panel::-webkit-scrollbar': {
      width: '0px!important',
    },
    '&.tab-panel:hover::-webkit-scrollbar': {
      width: '8px!important',
    },
  },
  tabContainer: {
    padding: '0 30px',
    borderBottom: '0.5px solid #D5DFE9',
    '& .Sdk-Mui-selected': {
      color: 'black!important',
    },
    '& .Sdk-MuiTabs-indicator': {
      backgroundColor: 'black!important',
    },
  },
}));
