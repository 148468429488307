import createSvgIcon from '../createSvgIcon';

export const SpeechWhite = createSvgIcon(
  <svg width="17" height="17" viewBox="-1 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 3V12.8775L14.1225 12H3V3H15ZM15 1.5H3C2.175 1.5 1.5 2.175 1.5 3V12C1.5 12.825 2.175 13.5 3 13.5H13.5L16.5 16.5V3C16.5 2.175 15.825 1.5 15 1.5ZM13.5 9H4.5V10.5H13.5V9ZM13.5 6.75H4.5V8.25H13.5V6.75ZM13.5 4.5H4.5V6H13.5V4.5Z"
      fill="white"
    />
  </svg>,
  'SpeechWhite'
);
