import { useDispatch, useSelector } from 'react-redux';
import { mountPanel } from '@aiware/js/panel';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { tableInfiniteScroll } from '@aiware/shared/reusable-utils';
import GroupsTableHeader, { HEADER_HEIGHT } from './GroupsTableHeader';
import { ADMIN_CENTER_GROUP_DETAILS_PANEL, PARENT_PANEL_ID, TUserGroup } from '../../../../types';
import {
  selectOrgUserGroups,
  selectOrgUserGroupsStatus,
  selectUserGroupsPagination,
} from '../../../../redux/selectors';
import { actions } from '../../../../redux';
import { GroupsTableRow, ITEM_HEIGHT } from './GroupsTableRow';
import { SHARED_TEXT } from '../../../../helper/shared-text';

const {
  jsx: { TableInfiniteScroll },
} = tableInfiniteScroll;

const GroupsTable = () => {
  const dispatch = useDispatch();
  const isPanelFullScreen = useSelector(selectIsFullscreenEnabled);

  // Table State
  const loadingStatus = useSelector(selectOrgUserGroupsStatus);
  const items = useSelector(selectOrgUserGroups);
  const { hasMore, offset } = useSelector(selectUserGroupsPagination);
  const handleFetchGroups = () => {
    dispatch(actions.adminCenterGroups.fetchUserGroupsStart());
  };

  const handleOpenGroupsDetailPanel = (id: string) => {
    const group = items.find(group => group?.id === id);

    const microFrontend = {
      name: ADMIN_CENTER_GROUP_DETAILS_PANEL,
      config: {
        name: 'Group Details',
        id: id,
        isProtected: group?.isProtected,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isPanelFullScreen ? 0 : 55,
      marginStart: isPanelFullScreen ? 0 : 80,
      size: 'large',
      parentPanelId: PARENT_PANEL_ID,
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };

    dispatch(
      mountPanel({
        panelId: ADMIN_CENTER_GROUP_DETAILS_PANEL,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  const Row = ({ item, index }: { item: TUserGroup; index: number }) => (
    <GroupsTableRow item={item} index={index} onClick={() => handleOpenGroupsDetailPanel(item.id)} />
  );

  return (
    <TableInfiniteScroll
      testId={'os-admin-center-groups-table'}
      items={items}
      offset={offset}
      hasMore={hasMore}
      fetchMore={handleFetchGroups}
      fetchOnMount={items.length === 0}
      isLoading={loadingStatus === 'pending'}
      error={loadingStatus === 'failure'}
      itemHeight={ITEM_HEIGHT}
      adjustTableHeight={-HEADER_HEIGHT}
      TableHeaderComponent={<GroupsTableHeader />}
      TableRowComponent={Row}
      MessageEmptyState={SHARED_TEXT.groupsTable.emptyState.title}
      MessageErrorState={SHARED_TEXT.groupsTable.errorState.title}
      DescriptionEmptyState={SHARED_TEXT.groupsTable.emptyState.description}
      DescriptionErrorState={SHARED_TEXT.groupsTable.errorState.description}
    />
  );
};
export default GroupsTable;
