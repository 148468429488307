import { Button, Stack, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsInputComplete,
  selectIsMatchValueValid,
} from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { actions as structuredDataActions } from '../../../../../state/aiStructuredData/aiStructuredData.slice';
import { actions as autocompleteActions } from '../../../../../state/aiAutocomplete/aiAutocomplete.slice';
import { actions as searchActions } from '../../../../../state/aiSearch/aiSearch.slice';

import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { useAiAutocompleteContext } from '../../../AiAutocomplete.context';

const translations = {
  cancel: () => (
    <FormattedMessage
      id="dc-structured-data-search-buttons-cancel"
      defaultMessage="Cancel"
      description="Cancel"
    />
  ),
  search: () => (
    <FormattedMessage
      id="dc-structured-data-search-buttons-search"
      defaultMessage="Search"
      description="Search"
    />
  ),
};

export const SearchActions = () => {
  const dispatch = useDispatch();
  const isValidFields = useSelector(selectIsMatchValueValid);
  const isInputComplete = useSelector(selectIsInputComplete);
  const { actions } = useAiAutocompleteContext();

  const handleCancelClick = () => {
    dispatch(structuredDataActions.reset());
    dispatch(autocompleteActions.setIsOpen(false));
  };

  const handleSearchClick = () => {
    const id: string = uuidv4();
    const label = 'AiStructuredData';
    const entityId = '';
    const capabilityType = 'AiStructuredData';
    const imageUrl = ''; // need an image url for structured data logo;

    dispatch(searchActions.resetSearchResults());
    actions.onSuggestionClick({ id, entityId, label, capabilityType, imageUrl });
    dispatch(searchActions.setActiveCriteria({ id, entityId, label, capabilityType }));
    dispatch(searchActions.setSearchCriteria([{ id, entityId, label, capabilityType }]));
    // search automatically fires with above actions
    dispatch(autocompleteActions.setIsOpen(false));
  };

  const isSaveDisabled = !isInputComplete || !isValidFields;

  const getTooltip = () => {
    if (!isValidFields) {
      return (
        <FormattedMessage
          id="dc-browse-structured-data.properties.default.tooltip-invalid-fields"
          defaultMessage="Some fields are invalid"
          description="tooltip for invalid fields"
        />
      );
    }
    if (!isInputComplete) {
      return (
        <FormattedMessage
          id="dc-browse-structured-data.properties.default.tooltip-incomplete-fields"
          defaultMessage="Please complete all fields"
          description="tooltip for incomplete fields"
        />
      );
    }
    return '';
  };

  return (
    <Stack direction="row" justifyContent="flex-end" gap={theme => theme.spacing(4)}>
      <Button
        data-testid={'dc-structured-data-search.cancel-btn'}
        sx={{
          color: theme => theme.palette.text.primary,
        }}
        onClick={handleCancelClick}
      >
        {translations.cancel()}
      </Button>
      <Tooltip title={getTooltip()} disableInteractive>
        <span>
          <Button
            data-testid={'dc-structured-data-search.search-btn'}
            variant="contained"
            disabled={isSaveDisabled}
            onClick={handleSearchClick}
          >
            {translations.search()}
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};
