import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { FormattedMessage } from 'react-intl';
import { Hide } from '@aiware/shared/icons';
import styles from './styles';

const ClosePanelMessage = () => (
  <FormattedMessage
    id="os-admin-center-groups.close-panel"
    defaultMessage="Close Panel"
    description="Instructions for closing the New Group panel"
  />
);

export const PanelHeader: FC<{ onPanelClose: () => void }> = ({ onPanelClose }) => {
  return (
    <header data-testid="new-group-panel-header" data-test="new-group-panel-header">
      <Box sx={styles['header']}>
        <Typography
          variant="h1"
          data-testid="new-group-panel-header-title"
          data-test="new-group-panel-header-title"
          sx={styles['title']}
        >
          <FormattedMessage
            id="os-admin-center-groups.new-group-title"
            defaultMessage="New Group"
            description="Title for the New Group panel"
          />
        </Typography>
        <Box sx={styles['headerActionContainer']}>
          <Typography style={visuallyHidden} id="new-group-panel-close">
            <ClosePanelMessage />
          </Typography>
          <Tooltip title={<ClosePanelMessage />}>
            <IconButton
              onClick={onPanelClose}
              aria-labelledby="new-group-panel-close"
              data-testid="new-group-panel-close-button"
              data-test="new-group-panel-close-button"
              size="large"
            >
              <Hide />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </header>
  );
};

export default PanelHeader;
