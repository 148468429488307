import { filePicker } from './utility-logic';

export const errors = {
  childCantSend: {
    id: 'aiware-desktop.frame-link.child-cannot-send',
    defaultMessage: 'Child frame cannot send this type of message',
    description: 'Child frame is not allowed to send this type of message',
  },
  parentCantSend: {
    id: 'aiware-desktop.frame-link.parent-cannot-send',
    defaultMessage: 'Desktop App cannot send this type of message to child frame',
    description: 'Desktop App is not allowed to send this type of message',
  },
  childAppNotResponding: {
    id: 'aiware-desktop.frame-link.child-heartbeat-check-failed',
    defaultMessage: 'Child frame appears to be frozen',
    description: 'Child app failed to send a heartbeat to report healthy status',
  },
  unsupportedUtility: {
    id: 'aiware-desktop.frame-link.unsupported-utility',
    defaultMessage: 'Cannot find the requested utility',
    description: 'Error when child app is requesting a use of unsupported utility',
  },
  operationCancelled: {
    id: 'aiware-desktop.frame-link.operation-cancelled',
    defaultMessage: 'Operation cancelled',
    description: 'Error message when the operation requested by child frame is cancelled',
  },
};

export type TContext = {
  environment?: string | null;
  baseUrl: string | null;
  authToken: string | null;
  theme?: string | null;
  language: string | null;
  userId: string | null;
  organizationId: string | null;
};

export enum EMessageTypes {
  getInitContext = 'getInitContext',
  initContext = 'initContext',
  updateContext = 'updateContext',
  reportActivity = 'reportActivity',
  response = 'response',
  utility = 'utility',
  error = 'error',
  heartbeat = 'heartbeat',
}

export type TResponse<D = Record<string, unknown> | null, E = Record<string, unknown> | null> = {
  data: D;
  error: E;
};

export const Utilities = {
  filePicker,
};

export type TUtilityMessagePayload<M> = {
  utility: keyof typeof Utilities;
  data: { [key: string]: M };
};

export enum EFrameLinkEvents {
  init = 'init',
}

export type Callback = (error?: Error | null, ...args: unknown[]) => void;
