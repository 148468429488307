import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveFolder,
  selectMyFilesBreadCrumbActive,
  selectMyFilesBreadCrumbsViewModel,
  selectRootFolderId,
} from '../../../redux/selectors/view-my-files';
import { selectors, actions as permissionsActions, EAuthResourceType } from '@aiware/shared/permissions';
import { actions } from '../../../redux/slices';
import ContentLayout from '../ContentLayout';
import PathPicker from '../PathPicker';
import ActionBar from '../ActionToolbar';
import FoldersAndFiles from './FoldersAndFiles';
import { BreadCrumbSkeleton, ActionBarSkeleton, TableSkeleton } from '../SkeletonLoader';
import { EntityType } from '@aiware/js/interfaces';

const MyFilesView: FunctionComponent = () => {
  const dispatch = useDispatch();
  const rootFolderId = useSelector(selectRootFolderId);
  const breadCrumbs = useSelector(selectMyFilesBreadCrumbsViewModel);
  const activeCrumb = useSelector(selectMyFilesBreadCrumbActive);
  const activeFolder = useSelector(selectActiveFolder);
  const activeFolderPermissions = useSelector(
    selectors.selectPermissionsByEntity(EAuthResourceType.Folder, activeFolder?.id)
  );

  useEffect(() => {
    if (!rootFolderId) {
      dispatch(actions.viewMyFiles.rootFolderFetchStart());
    }
  }, [rootFolderId, dispatch]);

  useEffect(() => {
    // Load permissions for the active folder into redux
    if (
      activeFolder?.id &&
      activeFolderPermissions?.status !== 'success' &&
      activeFolderPermissions?.status !== 'failure'
    ) {
      dispatch(
        permissionsActions.fetchObjectPermissionsStart({
          entityType: EAuthResourceType.Folder,
          entityId: activeFolder.id,
        })
      );
    }
  }, [activeFolder?.id, activeFolderPermissions?.status, dispatch]);

  if (!rootFolderId) {
    return (
      <ContentLayout
        breadcrumbs={<BreadCrumbSkeleton />}
        actions={<ActionBarSkeleton />}
        table={<TableSkeleton />}
      />
    );
  }

  const parentFolderId = activeCrumb.isRoot ? rootFolderId : (activeCrumb.id as string);

  return (
    <ContentLayout
      dataCenterView={true}
      breadcrumbs={
        <PathPicker
          pathList={breadCrumbs}
          goToCrumbActionCreator={actions.viewMyFiles.goToMyFilesBreadCrumb}
        />
      }
      actions={<ActionBar entityId={parentFolderId} entityType={EntityType.Folders} />}
      table={<FoldersAndFiles dataCenterView={true} parentFolderId={parentFolderId} />}
    />
  );
};

export default MyFilesView;
