import createSvgIcon from '../createSvgIcon';

export const MostlyNetworkIsolated = createSvgIcon(
  <svg width="20" height="20" viewBox="3 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.68508 3C7.55055 3.58173 5.73113 4.94795 4.52445 6.78152H7.65246C7.83313 6.17531 8.0442 5.59911 8.28483 5.08274C8.6675 4.26123 9.139 3.54315 9.68508 3ZM14.3149 3C14.861 3.54315 15.3325 4.26123 15.7152 5.08274C15.9558 5.59911 16.1669 6.17531 16.3475 6.78152H19.4755C18.2689 4.94795 16.4494 3.58173 14.3149 3ZM11.2773 3.63995C10.6902 3.95516 10.0936 4.6391 9.58343 5.73433C9.43568 6.05151 9.30197 6.41098 9.17691 6.78152H11.2773V3.63995ZM12.7227 3.63995V6.78152H14.8231C14.698 6.41098 14.5643 6.05151 14.4166 5.73433C13.9064 4.6391 13.3098 3.95516 12.7227 3.63995ZM3.72271 8.27085C3.32985 9.19609 3.08094 10.1964 3 11.2495H6.9636C7.00046 10.204 7.09828 9.20523 7.27979 8.27085H3.72271ZM8.77038 8.27085C8.57431 9.18512 8.45066 10.1883 8.40903 11.2495H11.2773V8.27085H8.77038ZM12.7227 8.27085V11.2495H15.591C15.5493 10.1883 15.4257 9.18512 15.2296 8.27085H12.7227ZM16.7202 8.27085C16.9017 9.20523 16.9995 10.204 17.0364 11.2495H21C20.9191 10.1964 20.6702 9.19609 20.2773 8.27085H16.7202ZM3 12.7389C3.08094 13.7919 3.32985 14.7923 3.72271 15.7175H7.27979C7.09832 14.7857 7.00043 13.7834 6.9636 12.7389H3ZM8.40903 12.7389C8.45011 13.7992 8.57507 14.8035 8.77038 15.7175H11.2773V12.7389H8.40903ZM12.7227 12.7389V15.7175H15.2296C15.4249 14.8035 15.5499 13.7992 15.591 12.7389H12.7227ZM17.0364 12.7389C16.9996 13.7834 16.9017 14.7857 16.7202 15.7175H20.2773C20.6702 14.7923 20.9191 13.7919 21 12.7389H17.0364ZM4.52445 17.2068C5.73366 19.0443 7.55601 20.4204 9.69636 21C9.14287 20.4523 8.67152 19.7241 8.28483 18.894C8.04388 18.3768 7.83331 17.8128 7.65246 17.2068H4.52445ZM9.1656 17.2068C9.292 17.5827 9.4338 17.9328 9.58343 18.254C10.0936 19.3493 10.6902 20.0448 11.2773 20.3601V17.2068H9.1656ZM12.7227 17.2068V20.3601C13.3098 20.0448 13.9064 19.3493 14.4166 18.254C14.5662 17.9328 14.708 17.5827 14.8344 17.2068H12.7227ZM16.3475 17.2068C16.1667 17.8128 15.9561 18.3768 15.7152 18.894C15.3285 19.7241 14.8571 20.4523 14.3036 21C16.444 20.4204 18.2663 19.0443 19.4755 17.2068H16.3475Z"
      fill="#555F7C"
    />
  </svg>,
  'MostlyNetworkIsolated'
);
