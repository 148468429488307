import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { EUserStatus } from './user-lookup';

const useStyles = makeStyles()(() => ({
  root: {
    textTransform: 'uppercase',
    fontSize: '9px',
    padding: '3px 10px',
    lineHeight: '12px',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    display: 'inline-block',
    borderRadius: '2px',
  },
  new: {
    background: '#B9E0BB',
    color: '#16961B',
  },
  existing: {
    background: 'rgba(96, 152, 209, 0.4)',
    color: '#477DB4',
  },
  pending: {
    background: 'rgba(255, 187, 10, 0.4)',
    color: '#CA9200',
  },
}));

export const UserVariant = {
  new: (
    <FormattedMessage
      id="organization-panel.new-user"
      defaultMessage="New User"
      description="multi-org-new-user"
    />
  ),
  existing: (
    <FormattedMessage
      id="organization-panel.existing-user"
      defaultMessage="Existing User"
      description="multi-org-existing-user"
    />
  ),
  pending: (
    <FormattedMessage
      id="organization-panel.Pending-user"
      defaultMessage="Pending Invitation"
      description="multi-org-pending-invitation"
    />
  ),
};

export const UserStatusBadge = ({ variant }: { variant: EUserStatus }) => {
  const { classes } = useStyles();
  if (!UserVariant[variant]) return null;
  return (
    <div
      className={classNames(classes.root, classes[variant])}
      data-testid={`multi-org-user-status-${variant}`}
    >
      {UserVariant[variant]}
    </div>
  );
};
