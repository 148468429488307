import { makeStyles } from 'tss-react/mui';
import { VeritoneTheme } from '@aiware/shared/theme';

const useStyles = makeStyles()((theme: VeritoneTheme) => ({
  heading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  divider: {
    opacity: 0.9,
    width: '100%',
    margin: '20px auto',
  },
  member: {
    display: 'flex',
  },
  memberAvatar: {
    border: '2px solid #FFFFFF',
    height: '40px',
    width: '40px',
    marginRight: '14px',
  },
  currentMember: {
    display: 'flex',
    alignItems: 'center',
    color: theme?.palette?.button?.disabledColor,
    paddingRight: theme.spacing(2),
    lineHeight: '20px',
  },
  membersEmail: {
    color: ' #5C6269',
    fontWeight: 400,
  },
  memberSince: {
    color: '#2A323C',
    fontWeight: 400,
  },
  createdDate: {
    fontSize: '12px',
    lineHeight: '5px',
    fontWeight: 400,
    color: '#2A323C',
  },
  buttons: {
    padding: '8px 15px',
  },
}));

export default useStyles;
