import { EntityType } from '@aiware/js/interfaces';
import EmptyState from '../../../shared/EmptyState';
import { SHARED_TEXT } from '../helpers/shared-text';

export const ErrorState = () => {
  return (
    <EmptyState
      emptyType={EntityType.Tdos}
      title={SHARED_TEXT.errorStateTitle}
      subtitle={SHARED_TEXT.errorStateSubtitle}
    />
  );
};
