export const SLICE_NAME = 'processingCenterContentTemplates';

export type GraphQLPage<T> = {
  records: T[];
  count?: number;
  offset?: number;
  limit?: number;
};

export type ISchemaId = string;

export type ISchemas = Record<ISchemaId, ISchemaVersion>;

export interface ISchema {
  id: string;
  name: string;
  description?: string;
  schemas?: {
    count: number;
    records?: ISchemaVersion[];
  };
}

export interface ISchemaVersion {
  name?: string;
  id: ISchemaId;
  status: string;
  definition: {
    [key: string]: any;
    properties: {
      [key: string]: {
        [key: string]: any;
        type: string;
        required: boolean;
      };
    };
  };
  majorVersion: number;
  minorVersion: number;
  validActions: string[];
}

export interface ISchemasState {
  [key: string]: ISchemaVersion;
}

export interface ICurrentSchemaListState {
  id: string;
  name: string;
}

export interface IContentTemplate {
  schemaId: string;
  data: {
    [key: string]: string | number | boolean | null;
  };
}

export interface IContentTemplateValidation extends IContentTemplate {
  touched: boolean;
  submitted: boolean;
  errors: boolean;
}

export interface IContentTemplatesToSaveState {
  [key: string]: IContentTemplateValidation;
}
