import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void>()(() => ({
  title: {
    color: '#2A323C',
  },
  tittleDescription: {
    color: '#5C6269',
    userSelect: 'none',
  },
  newName: {
    width: '100%',
    height: '52px',
    margin: '20px auto',
    paddingRight: '20px',
  },
  descriptionContainer: {
    position: 'relative',
    marginBottom: '19px',
    marginRight: '20px',
  },
  basicDescription: {
    width: '100%',
  },
  descriptionCharCount: {
    position: 'absolute',
    bottom: '8px',
    right: '11px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9CA8B4',
  },
}));
