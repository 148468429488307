import { Button } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const Controls: FC<{ disabled: boolean; onSave: () => void }> = ({ disabled, onSave }) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onSave}
      data-testid="admin-center.permission-set-details.save-btn"
      data-test="admin-center.permission-set-details.save-btn"
    >
      <FormattedMessage
        id="admin-center.permission-set-details.save-btn-label"
        defaultMessage="Save Changes"
        description="Save Changes label"
      />
    </Button>
  );
};

export default Controls;
