import { CallEffect, PutEffect, SelectEffect, call, put, select } from 'redux-saga/effects';
import { selectApiConfigs } from '@aiware/shared/redux';
import { actions } from '../slices';
import * as api from '../../api';
import { TDataRegistriesQueryInput, TDataRegistry, TSDOsPagination } from '../../types';
import { TGraphQLPage } from '@aiware/js/interfaces';
import { devErrorLogger } from '@aiware/shared/reusable-utils';
import { selectDataRegistriesPagination } from '../selectors';

export function* fetchDataRegistriesSaga(
  action: ReturnType<typeof actions.dataRegistry.fetchSDOsStart>
): Generator<SelectEffect | PutEffect | TSDOsPagination | CallEffect, void> {
  try {
    const _queryParams = action.payload || null;
    const apiConfigs = yield select(selectApiConfigs);
    const pagination = yield select(selectDataRegistriesPagination);
    const { offset, limit } = pagination as TSDOsPagination;
    const SDOs = yield call(api.fetchDataRegistries, apiConfigs as ReturnType<typeof selectApiConfigs>, {
      ...((_queryParams ?? {}) as TDataRegistriesQueryInput),
      offset,
      limit,
      filterByOwnership: 'mine',
    });
    yield put(
      actions.dataRegistry.fetchSDOsSuccess({
        SDOs: (SDOs as TGraphQLPage<TDataRegistry>).records,
        offset: offset + limit,
        hasMore: (SDOs as TGraphQLPage<TDataRegistry>).records.length >= limit,
      })
    );
  } catch (e) {
    devErrorLogger(e);
    yield put(actions.dataRegistry.fetchSDOsFailure());
  }
}

export function* fetchSDOViewerDataRegistrySaga(
  action: ReturnType<typeof actions.dataRegistry.fetchDataRegistryStart>
): Generator<SelectEffect | PutEffect | TSDOsPagination | CallEffect, void> {
  try {
    const apiConfigs = yield select(selectApiConfigs);
    const dataRegistries = yield call(
      api.fetchDataRegistries,
      apiConfigs as ReturnType<typeof selectApiConfigs>,
      {
        id: action.payload,
      }
    );

    yield put(
      actions.dataRegistry.fetchDataRegistrySuccess(
        (dataRegistries as TGraphQLPage<TDataRegistry>).records[0]!
      )
    );
  } catch (e) {
    devErrorLogger(e);
    yield put(actions.dataRegistry.fetchDataRegistryFailure());
  }
}
