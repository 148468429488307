import { veritoneApiClient as apiClient } from './api-client';
import { baseGraphQLApiWithError, baseGraphQLApi } from '@aiware/shared/redux';
import {
  LIMIT,
  GraphQLPage,
  SearchPage,
  ScheduleId,
  ITdoSearchResult,
  ITdo,
  FolderId,
  ITdoGraphQL,
  TdoId,
} from '../types';
import { selectApiConfigs } from '../redux/selectors';
import { mapTdoFromGraphQL, mapTdoFromSearchResult } from '../helpers/tdoHelper';

export async function searchTdos(
  { apiRoot, token }: ReturnType<typeof selectApiConfigs>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conditions: any[],
  limit: number,
  offset = 0
): Promise<GraphQLPage<ITdo>> {
  const payload = {
    offset,
    limit,
    index: ['mine'],
    query: {
      operator: 'and',
      conditions: [...conditions],
    },
    select: ['veritone-job', 'veritone-file'],
  };

  const results = await apiClient<SearchPage<ITdoSearchResult>>({
    apiRoot,
    token,
    uri: 'api/search/file_search_authtoken',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return {
    records: results.records?.map(mapTdoFromSearchResult),
    count: results.returnRecords,
    limit,
  };
}

export async function getTdosByScheduleId(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset = 0,
  scheduleId: ScheduleId
): Promise<GraphQLPage<ITdo>> {
  const conditions = [
    {
      operator: 'term',
      field: 'programId',
      value: scheduleId,
    },
  ];

  return await searchTdos(apiConfigs, conditions, LIMIT, offset);
}

export async function getChildTdosByParentFolderId(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset = 0,
  parentFolderId: FolderId
) {
  const operationName = 'getChildTdosByParentFolderId';
  const name = 'folder';
  const property = 'childTDOs';
  const query = `
    query ${operationName} ($id: ID!, $limit: Int, $offset: Int) {
      ${name}(id: $id) {
        ${property}(limit: $limit, offset: $offset) {
          count
          records {
            id
            name
            modifiedDateTime
            primaryAsset(assetType: "media") {
              contentType
              signedUri
            }
            folders{
              folderPath{
                name
                id
              }
            }
            sourceData {
              source {
                id
                sourceTypeId
              }
            }
            thumbnailUrl
          }
        }
      }
    }
  `;
  const variables = {
    id: parentFolderId,
    limit: LIMIT,
    offset,
  };
  const result = await baseGraphQLApiWithError<{
    [name]: {
      [property]: GraphQLPage<ITdoGraphQL>;
    };
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  const tdoPage = result[name]?.[property];

  return {
    count: tdoPage.count,
    records: tdoPage.records.map(mapTdoFromGraphQL).map(tdo => ({
      ...tdo,
      parentFolderId,
    })),
  };
}

export async function getTdo(apiConfigs: ReturnType<typeof selectApiConfigs>, tdoId: TdoId): Promise<ITdo> {
  const operationName = 'getTdo';
  const name = 'temporalDataObject';
  const query = `
    query ${operationName} ($id: ID!){
      ${name}(id: $id) {
        id
        name
        details
        modifiedDateTime
        createdDateTime
        primaryAsset(assetType: "media") {
          contentType
          signedUri
        }
        folders{
          folderPath{
            name
            id
          }
        }
        sourceData {
          source {
            id
            sourceTypeId
          }
        }
        thumbnailUrl
        startDateTime
        stopDateTime
        jobs {
          records {
            status
          }
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: ITdoGraphQL;
  }>({
    query,
    operationName,
    variables: {
      id: tdoId,
    },
    ...apiConfigs,
  });

  return mapTdoFromGraphQL(result[name]);
}

// allows you to put in a list of tdos for graphql to make one call
export async function getTdos(apiConfigs: ReturnType<typeof selectApiConfigs>, tdoIds: TdoId[]) {
  const tdoGenerator = (id: TdoId) => {
    return `
        tdo${id}: temporalDataObject(id: ${id}) {
        id
        name
        details
        modifiedDateTime
        primaryAsset(assetType: "media") {
          contentType
          signedUri
        }
        folders{
          folderPath{
            name
            id
          }
        }
        sourceData {
          source {
            id
            sourceTypeId
          }
        }
        thumbnailUrl
      }
        `;
  };
  const tdoQueries = tdoIds.map((tdoId: TdoId) => tdoGenerator(tdoId));
  const query = `
        query getTdos {
            ${tdoQueries.join('\r\n')}
        }
    `;

  const graphEndpoint = apiConfigs.graphQLEndpoint;
  const token = apiConfigs.token;
  const operationName = 'getTdos';
  const result = await baseGraphQLApi({
    query,
    operationName,
    graphEndpoint,
    token,
  });
  if (result.errors) {
    console.error('some tdos were not found', result.errors);
  }
  return result.data;
}

export async function deleteTdo(apiConfigs: ReturnType<typeof selectApiConfigs>, tdoId: TdoId) {
  const operationName = 'deleteTDO';
  const name = 'deleteTDO';
  const query = `
    mutation ${operationName} ($id: ID!){
      ${name}(id: $id) {
        id
        message
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: {
      id: string;
    };
  }>({
    query,
    operationName,
    variables: {
      id: tdoId,
    },
    ...apiConfigs,
  });

  return {
    ...result[name],
  };
}

export async function updateTdoMetadata(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  tdoId: string,
  tdoName: string,
  tdoDetails: any,
  thumbnailImage?: string
): Promise<ITdo> {
  const operationName = 'updateTDO';
  const name = 'updateTDO';
  const query = `
    mutation ${operationName} ($tdoId: ID!, $tdoName: String, $tdoDetails: JSONData${
    thumbnailImage ? ', $thumbnail: String' : ''
  }){
      ${name}(input: {id: $tdoId, name: $tdoName, details: $tdoDetails${
    thumbnailImage ? ', thumbnailUrl: $thumbnail' : ''
  }}) {
        id
        name
        details
        modifiedDateTime
        createdDateTime
        primaryAsset(assetType: "media") {
          contentType
          signedUri
        }
        folders{
          folderPath{
            name
            id
          }
        }
        sourceData {
          source {
            id
            sourceTypeId
          }
        }
        thumbnailUrl
        startDateTime
        stopDateTime
        jobs {
          records {
            status
          }
        }
      }
    }
  `;
  const variables = {
    tdoId,
    tdoName,
    tdoDetails,
  } as any;
  if (thumbnailImage) {
    variables.thumbnail = thumbnailImage;
  }
  const result = await baseGraphQLApiWithError<{
    [name]: ITdoGraphQL;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return mapTdoFromGraphQL(result[name]);
}

export async function moveTdoFromRootFolder(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  tdoId: TdoId | null | undefined,
  newId: FolderId | null | undefined
): Promise<{ id: string }> {
  const operationName = 'fileTDO';
  const name = 'fileTemporalDataObject';
  const query = `
       mutation ${operationName}($input: FileTemporalDataObject!) {
      ${name}(input: $input) {
        id
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: {
      id: string;
    };
  }>({
    query,
    operationName,
    variables: {
      input: {
        tdoId: tdoId,
        folderId: newId,
      },
    },
    ...apiConfigs,
  });

  return {
    ...result[name],
  };
}

export async function moveTdoFromParentFolder(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  tdoId: TdoId,
  parentId: FolderId | null | undefined,
  newId: FolderId | null | undefined
) {
  const operationName = 'moveTDO';
  const name = 'moveTemporalDataObject';
  const query = `
       mutation ${operationName}($input: MoveTemporalDataObject!) {
      ${name}(input: $input) {
        id
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: {
      id: string;
    };
  }>({
    query,
    operationName,
    variables: {
      input: {
        tdoId: tdoId,
        oldFolderId: parentId,
        newFolderId: newId,
      },
    },
    ...apiConfigs,
  });

  return {
    ...result[name],
  };
}
