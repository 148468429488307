import createSvgIcon from '../createSvgIcon';

export const FlowTemplate2 = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F2F7FE" />
    <path
      d="M24.6197 23.1618H23.8027V22.2059H20.1426V20.4902H20.9596V21.3889H24.6197V23.1618Z"
      fill="#0D47A1"
    />
    <path
      d="M26.8907 26.6017H21.5312V22.8926H26.8907V26.6017ZM23.1652 24.9677H25.2567V24.5266H23.1652V24.9677Z"
      fill="#0D47A1"
    />
    <path
      d="M23.2306 20.6613H17.8711V16.9521H23.2306V20.6613ZM19.5051 19.0273H21.5966V18.5861H19.5051V19.0273Z"
      fill="#0D47A1"
    />
    <path
      d="M13.7775 24.7464H12.1436V11.4131H24.2105V13.9458H22.5766V13.0471H13.7775V24.7464Z"
      fill="#0D47A1"
    />
    <path
      d="M29.2928 28.7583H15.4121V14.8857H29.2928V28.7583ZM17.0543 27.1243H27.6588V16.5197H17.0461V27.1243H17.0543Z"
      fill="#0D47A1"
    />
  </svg>,
  'FlowTemplate2'
);
