import { Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material/';
import { FC, useCallback } from 'react';
import { sharedStyles, muiSelectStyleOverrides } from '../../shared/aiSearchFilter.styles';
import { AiSearchText } from '../../../../helpers/aiSearch.text';

type FileTypeFilterItem = {
  value: string;
  label: string;
};

interface IFileTypeFilter {
  id: string;
  label: JSX.Element;
  items: FileTypeFilterItem[];
  allowMultiple?: boolean;
  selectedValue: string[];
  onChange: (event: any) => void;
}

export const FileTypeFilter: FC<IFileTypeFilter> = ({
  id,
  label,
  items,
  allowMultiple = false,
  selectedValue,
  onChange,
}) => {
  const { classes: sharedClasses } = sharedStyles();

  const handleClear = () => {
    const e = {
      target: {
        value: [],
      },
    };
    handleChange(e as unknown as SelectChangeEvent<string[]>);
  };

  const handleChange = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = e;
      if (onChange) onChange(typeof value === 'string' ? value.split(',') : value);
    },
    [onChange]
  );

  return (
    <FormControl size="small" className={sharedClasses.formControl}>
      <label htmlFor={id} className={sharedClasses.label}>
        {label}{' '}
        {selectedValue.length > 0 && (
          <span onClick={handleClear} className={sharedClasses.clearBtn}>
            {AiSearchText.clearLabelText()}
          </span>
        )}
      </label>
      <Select
        id={id}
        sx={muiSelectStyleOverrides}
        variant="outlined"
        value={selectedValue}
        multiple={allowMultiple}
        displayEmpty
        className={sharedClasses.field}
        onChange={handleChange}
        renderValue={selected => {
          if (selected.length === 0) {
            return <div>All</div>;
          }
          return selected.join(', ');
        }}
      >
        {items.map(item => {
          return (
            <MenuItem
              data-test={`dc-search-filter-fileType-${item.value}`}
              key={item.value}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
