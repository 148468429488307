import { makeStyles } from 'tss-react/mui';
import { VeritoneTheme } from '@aiware/shared/theme';

export const useStyles = makeStyles<void>()((theme: VeritoneTheme, props) => ({
  root: {
    '& .Sdk-MuiAutocomplete-tag': {
      fontWeight: 500,
      background: '#FFFFFF',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.15)',
      height: '30px',
      border: 'none',
    },
    '& .Sdk-MuiChip-deleteIcon': {
      fill: '#555F7C',
      width: '15px',
      height: '15px',
    },
  },
  renderMember: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
  },
  renderAvatar: {
    height: '20px',
    width: '20px',
    marginRight: '5px',
    fontSize: '10px',
  },
  groupMembersTitle: {
    color: '#2A323C',
  },
  groupMembersDescription: {
    color: '#5C6269',
    userSelect: 'none',
  },
  groupMembersName: {
    width: '100%',
    height: '52px',
    margin: '20px auto',
  },
  memberInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    marginBottom: '13px',
    height: '30px !important',
  },
  member: {
    display: 'flex',
  },
  memberAvatar: {
    border: '2px solid #FFFFFF',
    height: '35px',
    width: '35px',
    marginRight: '14px',
  },
  currentMember: {
    display: 'flex',
    alignItems: 'center',
    color: theme?.palette?.button?.disabledColor,
    paddingRight: theme.spacing(2),
    lineHeight: '20px',
  },
}));
