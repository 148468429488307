import createSvgIcon from '../createSvgIcon';
export const Schema = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.63636C7.93317 5.63636 4.63636 8.93317 4.63636 13C4.63636 17.0668 7.93317 20.3636 12 20.3636C16.0668 20.3636 19.3636 17.0668 19.3636 13C19.3636 8.93317 16.0668 5.63636 12 5.63636ZM3 13C3 8.02943 7.02943 4 12 4C16.9705 4 21 8.02943 21 13C21 17.9705 16.9705 22 12 22C7.02943 22 3 17.9705 3 13Z"
      fill="#555F7C"
    />
    <path
      d="M15 5C15 6.65685 13.6569 8 12 8C10.3431 8 9 6.65685 9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5Z"
      fill="#555F7C"
    />
    <path
      d="M7 16C7 17.6569 5.65685 19 4 19C2.34315 19 1 17.6569 1 16C1 14.3431 2.34315 13 4 13C5.65685 13 7 14.3431 7 16Z"
      fill="#555F7C"
    />
    <path
      d="M23 16C23 17.6569 21.6569 19 20 19C18.3431 19 17 17.6569 17 16C17 14.3431 18.3431 13 20 13C21.6569 13 23 14.3431 23 16Z"
      fill="#555F7C"
    />
  </svg>,
  'Schema'
);
