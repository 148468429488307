import { INotificationDetail, IPanelActionButton } from '@aiware/js/interfaces';
import { createAction } from '@reduxjs/toolkit';
import {
  IAppNotificationSetting,
  IGeneralInterface,
  INotificationModule,
  INotificationType,
  ISettingModule,
  IUtilityNotificationSetting,
  INotificationAction,
  INotificationFlag,
} from '../interfaces/notificationInterfaces';
export const GET_NOTIFICATION = 'os-notification-panel/GET_NOTIFICATION';
export const GET_NOTIFICATION_START =
  'os-notification-panel/GET_NOTIFICATION_START';
export const GET_NOTIFICATION_SUCCESS =
  'os-notification-panel/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR =
  'os-notification-panel/GET_NOTIFICATION_ERROR';

export const GET_NOTIFICATION_ACTION_REQUEST =
  'os-notification-panel/GET_NOTIFICATION_ACTION_REQUEST';
export const GET_NOTIFICATION_ACTION_SUCCESS =
  'os-notification-panel/GET_NOTIFICATION_ACTION_SUCCESS';
export const GET_NOTIFICATION_ACTION_ERROR =
  'os-notification-panel/GET_NOTIFICATION_ACTION_ERROR';
export const UPDATE_FILTER = 'os-notification-panel/UPDATE_FILTER';

export const UPDATE_APP_ACTION_ALLOW_NOTIFICATION =
  'os-notification-panel/UPDATE_APP_ACTION_ALLOW_NOTIFICATION';

export const UPDATE_ALLOW_NOTIFICATION =
  'os-notification-panel/UPDATE_ALLOW_NOTIFICATION';

export const ALLOW_NOTIFICATION_TYPE =
  'os-notification-panel/ALLOW_NOTIFICATION_TYPE';
export const DISALLOW_NOTIFICATION_TYPE =
  'os-notification-panel/DISALLOW_NOTIFICATION_TYPE';
export const UPDATE_BANNER_STYLE = 'os-notification-panel/UPDATE_BANNER_STYLE';

export const UPDATE_APPLICATION_SETTING =
  'os-notification-panel/UPDATE_APPLICATION_SETTING';

export const UPDATE_APP_ALLOW_NOTIFICATION =
  'os-notification-panel/UPDATE_APP_ALLOW_NOTIFICATION';

export const GET_NOTIFICATION_LIST_REQUEST =
  'os-notification-panel/GET_NOTIFICATION_LIST_REQUEST';
export const GET_NOTIFICATION_LIST_SUCCESS =
  'os-notification-panel/GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAIL =
  'os-notification-panel/GET_NOTIFICATION_LIST_FAIL';
export const UPDATE_NOTIFICATION_LIST =
  'os-notification-panel/UPDATE_NOTIFICATION_LIST';

export const UPDATE_NOTIFICATION_READ_REQUEST =
  'os-notification-panel/UPDATE_NOTIFICATION_READ_REQUEST';
export const UPDATE_NOTIFICATION_READ_SUCCESS =
  'os-notification-panel/UPDATE_NOTIFICATION_READ_SUCCESS';
export const UPDATE_NOTIFICATION_READ_FAIL =
  'os-notification-panel/UPDATE_NOTIFICATION_READ_FAIL';
export const ON_NOTIFICATION_ACTION_CLICKED =
  'os-notification-panel/ON_NOTIFICATION_ACTION_CLICKED';

export const INIT_NOTIFICATION_ACTION =
  'os-notification-panel/INIT_NOTIFICATION_ACTION';

export const MARK_ALL_READ_NOTIFICATION_REQUEST =
  'os-notification-panel/MARK_ALL_READ_NOTIFICATION_REQUEST';
export const MARK_ALL_READ_NOTIFICATION_SUCCESS =
  'os-notification-panel/MARK_ALL_READ_NOTIFICATION_SUCCESS';
export const MARK_ALL_READ_NOTIFICATION_FAIL =
  'os-notification-panel/MARK_ALL_READ_NOTIFICATION_FAIL';

export const getNotification = createAction(
  GET_NOTIFICATION,
  function prepare(offset: number, limit: number, callback?: () => void) {
    return {
      payload: { limit, offset, callback },
    };
  }
);

export const getNotificationStart = createAction(
  GET_NOTIFICATION_START,
  function prepare(offset: number, limit: number) {
    return {
      payload: { limit, offset },
    };
  }
);

export const initNotificationAction = createAction(
  INIT_NOTIFICATION_ACTION,
  function prepare(callback: (data: unknown) => void) {
    return {
      payload: callback,
    };
  }
);

export const getNotificationSuccess = createAction(
  GET_NOTIFICATION_SUCCESS,
  function prepare(data: INotificationDetail[]) {
    return {
      payload: data,
    };
  }
);
export const getNotificationError = createAction(
  GET_NOTIFICATION_ERROR,
  function prepare(message: string) {
    return {
      payload: message,
    };
  }
);
export const getNotificationAction = createAction(
  GET_NOTIFICATION_ACTION_REQUEST,
  function prepare(notificationId: string) {
    return { payload: notificationId };
  }
);
export const getNotificationActionSuccess = createAction(
  GET_NOTIFICATION_ACTION_SUCCESS,
  function prepare(
    notificationId: string,
    notificationActions: INotificationAction[]
  ) {
    return {
      payload: { notificationId, notificationActions },
    };
  }
);
export const getNotificationActionError = createAction(
  GET_NOTIFICATION_ACTION_ERROR
);

export const updateFilterValue = createAction(
  UPDATE_FILTER,
  function prepare(value: INotificationModule['filter']) {
    return {
      payload: value,
    };
  }
);
export const updateAppAllowNotification = createAction(
  UPDATE_APP_ALLOW_NOTIFICATION,
  function prepare(appId: string, isAllowed: boolean) {
    return { payload: { appId, isAllowed } };
  }
);

export const updateBannerStyle = createAction(
  UPDATE_BANNER_STYLE,
  function prepare(style: string) {
    return { payload: style };
  }
);

export const updateUtilitySetting = createAction(
  'os-notification-panel/UPDATE_UTILITY_SETTING',
  function prepare(utilitySetting: IUtilityNotificationSetting[]) {
    return {
      payload: utilitySetting,
    };
  }
);

export const updateApplicationSetting = createAction(
  UPDATE_APPLICATION_SETTING,
  function prepare(
    applicationSetting: IGeneralInterface<IAppNotificationSetting>
  ) {
    return {
      payload: applicationSetting,
    };
  }
);

export const updateNotificationSetting = createAction(
  'os-notification-panel/UPDATE_NOTI_SETTING',
  function prepare(notificationSetting: ISettingModule) {
    return {
      payload: notificationSetting,
    };
  }
);

export const updateAppActionAllowNotification = createAction(
  'UPDATE_APP_ACTION_ALLOW_NOTIFICATION',
  function prepare(
    appId: string,
    id: string,
    value: INotificationType[] | boolean,
    key: string
  ) {
    return {
      payload: {
        appId,
        id,
        value,
        key,
      },
    };
  }
);

export const getNotificationListByMailBoxRequest = createAction(
  GET_NOTIFICATION_LIST_REQUEST,
  function prepare(mailBoxIds: string[]) {
    return {
      payload: {
        mailBoxIds,
      },
    };
  }
);

export const getNotificationListByMailBoxSuccess = createAction(
  GET_NOTIFICATION_LIST_SUCCESS,
  function prepare(data) {
    return {
      payload: data,
    };
  }
);

export const getNotificationListByMailBoxFail = createAction(
  GET_NOTIFICATION_LIST_FAIL
);

export const updateNotificationList = createAction(
  UPDATE_NOTIFICATION_LIST,
  function prepare(data: INotificationDetail) {
    return { payload: data };
  }
);

export const updateNotificationReadRequest = createAction(
  UPDATE_NOTIFICATION_READ_REQUEST,
  function prepare(notificationId: string) {
    return { payload: notificationId };
  }
);
export const updateNotificationReadSuccess = createAction(
  UPDATE_NOTIFICATION_READ_SUCCESS,
  function prepare(data: INotificationFlag) {
    return {
      payload: data,
    };
  }
);
export const updateNotificationReadFail = createAction(
  UPDATE_NOTIFICATION_READ_FAIL
);

export const onNotificationActionClicked = createAction(
  ON_NOTIFICATION_ACTION_CLICKED,
  function prepare(notificationAction: IPanelActionButton) {
    return { payload: notificationAction };
  }
);
export const markAllReadNotificationRequest = createAction(
  MARK_ALL_READ_NOTIFICATION_REQUEST
);
export const markAllReadNotificationSuccess = createAction(
  MARK_ALL_READ_NOTIFICATION_SUCCESS
);
export const markAllReadNotificationFail = createAction(
  MARK_ALL_READ_NOTIFICATION_FAIL
);
