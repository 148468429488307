import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
  Skeleton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles()((_theme: Theme) => {
  return {
    loadingText: {
      display: 'flex',
      transform: 'translateX(-20px)',
    },
  };
});
const LoaderSkeletonItem = () => {
  const { classes } = useStyles();
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular" width={25} height={25} />
      </ListItemAvatar>
      <ListItemText className={classes.loadingText}>
        <Skeleton variant="text" width={150} height={21} />
      </ListItemText>
      <ListItemSecondaryAction>
        <Skeleton variant="rectangular" width={40} height={30} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const OrganizationItemLoaderSkeleton = () => {
  return (
    <List>
      <LoaderSkeletonItem />
      <Divider />
      <LoaderSkeletonItem />
    </List>
  );
};

export default OrganizationItemLoaderSkeleton;
