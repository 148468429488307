import { generateState, GeneratedState } from '@aiware/shared/reusable-utils';
import { PAGE_LIMIT, TStructuredDataObject } from '../../types';
import { fetchStructuredDataObjects } from '../../api';
import { SLICE_NAME } from '../../constants';

export const sdoNamespace = 'structuredDataObjects';

export type StructuredDataObjectState = GeneratedState<TStructuredDataObject>;

export const { slice, selectors, sagas, initialState } = generateState<TStructuredDataObject>({
  sliceName: SLICE_NAME,
  namespace: sdoNamespace,
  dedupeResponse: true,
  dedupeIdProp: 'id',
  apiCalls: {
    read: fetchStructuredDataObjects,
    pageSize: PAGE_LIMIT,
    getAdditionalSelectors: () => ({
      schemaId: (state: any) => {
        return state.dataCenterDataRegistry?.dataRegistry?.sdoViewerPanel?.activeSchemaId || '';
      },
    }),
  },
});
