import createSvgIcon from '../createSvgIcon';
export const UpDown = createSvgIcon(
  <svg width="19" height="19" viewBox="0 -1 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5575 5.55762L5 2.12262L8.4425 5.55762L9.5 4.50012L5 0.000116432L0.500002 4.50012L1.5575 5.55762Z"
      fill="#555F7C"
    />
    <path
      d="M8.4425 9.44238L5 12.8774L1.5575 9.44238L0.5 10.4999L5 14.9999L9.5 10.4999L8.4425 9.44238Z"
      fill="#555F7C"
    />
  </svg>,
  'UpDown'
);
