import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  searchContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #E1E6ED',
    borderRadius: '6px',
    margin: '10px 0',
    padding: '8px',
  },
  searchInput: {
    width: '100%',
    border: '0',
    outline: 'none',
    fontFamily: "'Nunito'",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
  formControl: {
    marginRight: '20px',
    width: '100%',
  },
  removePadding: {
    padding: 0,
  },
  field: {
    width: '100%',
  },
  dropdownContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  propertyList: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    padding: '0 15px 0 15px',
    boxSizing: 'border-box',
  },
  property: {
    padding: '5px',
  },
  selected: {
    borderRadius: '6px',
    backgroundColor: 'rgba(228, 240, 252, 0.8)',
  },
}));
