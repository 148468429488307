import { baseGraphQLApiWithError, baseGraphQLApi, gqlIntrospection } from '@aiware/shared/redux';
import {
  TGetACLsInput,
  GraphQLPage,
  TAuthACE,
  TAddACLsInput,
  TFunctionalPermission,
  TAuthPermissionCheck,
  THasPermissionsInput,
  TPermissionSetInput,
  TPermissionSet,
  TAuthPermissionSetUpdateInput,
} from '../types';
import { selectApiConfigs } from '../redux/selectors';
import { setACLsMutation, removeACLsMutation, getACLsQuery } from './ops';

export async function getACLs(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { resourceType, ids }: TGetACLsInput,
  orgId?: string
): Promise<GraphQLPage<TAuthACE>> {
  const operationName = 'getACLs';
  const name = 'getACLForResources';
  const variables = {
    resourceType,
    ids,
  };

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TAuthACE>;
  }>({
    query: getACLsQuery(orgFilter),
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function getACLsPaginated(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { resourceType, ids, limit = 30, offset }: TGetACLsInput,
  orgId?: string
): Promise<GraphQLPage<TAuthACE>> {
  const operationName = 'getACLs';
  const name = 'getACLForResources';
  const variables = {
    resourceType,
    ids,
    offset,
    limit,
  };

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TAuthACE>;
  }>({
    query: getACLsQuery(orgFilter),
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function setACLsOnResources(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { type, ids, entries }: TAddACLsInput,
  orgId?: string
): Promise<GraphQLPage<TAuthACE>> {
  const operationName = 'setACLs';
  const name = 'addACEsToResources';
  const variables = {
    resourceType: type,
    ids,
    entries,
  };

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';
  const query = `
  mutation ${operationName} ($resourceType: AuthResourceType!, $ids: [ID!]!, $entries: [AuthACEPermissionInput!]!){
    ${setACLsMutation(orgFilter)}
  }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TAuthACE>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function removeACLsOnResource(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { ids: aceIDsToRemove, type: resourceType }: Pick<TAddACLsInput, 'ids' | 'type'>,
  orgId?: string
): Promise<GraphQLPage<TAuthACE>> {
  const operationName = 'removeACLsOnResource';
  const name = 'removeACEsFromResource';
  const variables = {
    aceIDsToRemove,
    resourceType,
  };
  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';
  const query = `
  mutation ${operationName} ( $resourceType: AuthResourceType! $aceIDsToRemove: [ID!]!) {
    ${removeACLsMutation(orgFilter)}
  }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TAuthACE>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function getOrgPermissionSets(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { orgId, offset, limit = 300 }: { orgId?: string; offset: number; limit?: number }
) {
  const operationName = 'getOrgPermissionSets';
  const name = 'authPermissionSets';

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';

  const query = `
    query ${operationName} {
      ${name} (
        offset: ${offset},
        limit: ${limit},
        ${orgFilter}
        authClass: [System, Standard, Application]
      ) {
        records {
          id
          name
          isProtected
          description
          permissions
          createdAt
          authClass
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TAuthACE>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  const records = result[name].records;
  return { records };
}

export async function getOrgFunctionalPermissions(
  apiConfigs: ReturnType<typeof selectApiConfigs>
): Promise<GraphQLPage<TFunctionalPermission>> {
  const operationName = 'getOrgFunctionalPermissions';
  const name = 'permissions';
  const query = `
    query ${operationName} {
      ${name} (limit: 300) {
        records {
          permissionId: id
          permissionName: name
          permissionDescription: description
        }
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TFunctionalPermission>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  return result[name];
}

export async function hasPermissions(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { resourceType, ids }: THasPermissionsInput
): Promise<TAuthPermissionCheck> {
  const variables = {
    resourceType,
    ids,
    permissions: resourceType === 'TDO' ? 'AIWARE_TDO_UPDATE' : 'AIWARE_FOLDER_UPDATE',
  };
  const operationName = 'hasPermissions';
  const name = 'hasPermissions';
  const query = `
    query ${operationName} ( $resourceType: AuthResourceType! $ids: [ID!]! $permissions: [AuthPermissionType!]! ) {
      ${name} (resourceType: $resourceType ids: $ids permissions: $permissions) {
          hasPermission
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: TAuthPermissionCheck;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function changeAccessLevel(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  { remove, add }: { remove: Pick<TAddACLsInput, 'ids'>; add: TAddACLsInput },
  orgId?: string
): Promise<GraphQLPage<TFunctionalPermission>> {
  const { ids: aceIDsToRemove } = remove;
  const { type, ids, entries } = add;
  const variables = {
    aceIDsToRemove,
    resourceType: type,
    ids,
    entries,
  };
  const operationName = 'changeACEsOnResource';
  const name = 'addACEsToResources';

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';
  const query = `
  mutation ${operationName} ( $aceIDsToRemove: [ID!]! $resourceType: AuthResourceType!, $ids: [ID!]!, $entries: [AuthACEPermissionInput!]!) {
    ${removeACLsMutation(orgFilter)}
    ${setACLsMutation(orgFilter)}
  }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<TFunctionalPermission>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function createPermissionSet(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: TPermissionSetInput,
  orgId?: string
): Promise<TPermissionSet> {
  const operationName = 'createPermissionSet';
  const name = 'authPermissionSetCreate';
  const variables = {
    input,
  };

  if (orgId) {
    variables.input.organizationID = `${orgId}`;
  }

  const query = `
    mutation ${operationName} ($input: AuthPermissionSetInput! ) {
      ${name} (input: $input) {
        id
        name
        description
        permissions
        createdAt
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: TPermissionSet;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function checkPermissionSetName(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: string,
  orgId?: string
) {
  const operationName = 'authPermissionSets';
  const name = 'authPermissionSets';
  const variables = {
    nameRegex: `^${input}$`,
  };

  const orgFilter = orgId ? `ownerOrganization: ${orgId}` : '';

  const query = `
    query ${operationName} ($nameRegex: String){
      ${name} (
        nameRegex: $nameRegex
        ${orgFilter}
      ) {
        records {
            name
        }
      }
    }
  `;

  const token = apiConfigs.token;
  const graphEndpoint = apiConfigs.graphQLEndpoint;
  try {
    return await baseGraphQLApi({
      query,
      operationName,
      variables,
      graphEndpoint,
      token,
    });
  } catch (e) {
    console.log(e);
  }
}

export async function updatePermissionSet(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  input: TAuthPermissionSetUpdateInput,
  orgId?: string
): Promise<TPermissionSet> {
  const operationName = 'updatePermissionSet';
  const name = 'authPermissionSetUpdate';
  const { name: pName, description, permissions } = input;
  if (!pName && !description && !permissions)
    throw new Error('No new details were provided for the authPermissionSetUpdate operation');

  const variables = {
    input,
  };

  if (orgId) {
    variables.input.ownerOrganization = `${orgId}`;
  }

  const query = `
    mutation ${operationName} ($input: AuthPermissionSetUpdateInput! ) {
      ${name} (input: $input) {
        id
        name
        description
        permissions
        createdAt
      }
    }
  `;

  const result = await baseGraphQLApiWithError<{
    [name]: TPermissionSet;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  return result[name];
}

export async function fetchFunctionalPermissions(
  apiConfigs: ReturnType<typeof selectApiConfigs>
): Promise<string[]> {
  const { graphQLEndpoint, token } = apiConfigs;
  return gqlIntrospection.getEnumValues(graphQLEndpoint, token, 'AuthPermissionType') as unknown as string[];
}
