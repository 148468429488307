import { isEmpty } from 'lodash';
import { memo, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import rowHeight from '../../../helpers/rowHeight';
import { SHARED_TEXT } from '../../../helpers/shared-text';
import { selectCurrentView, selectIsFluffy, selectPanelMode } from '../../../redux/selectors/ui-state';
import { selectCurrentFolderDetails, selectRootFolderId } from '../../../redux/selectors/view-my-files';
import { IFileOrFolderVm } from '../../../types';
import { DataCenterView } from '@aiware/shared/redux';
import EmptyState from '../../shared/EmptyState';
import FoldersAndFilesTable from './FoldersAndFilesTable';
import FoldersAndFilesTableHeader from './FoldersAndFilesTableHeader';

const FoldersAndFiles: FunctionComponent<{
  parentFolderId: string;
  dataCenterView?: boolean;
}> = ({ parentFolderId, dataCenterView = false }) => {
  const rootFolderId = useSelector(selectRootFolderId);
  const panelMode = useSelector(selectPanelMode);
  const currentFolderAndFiles = useSelector(selectCurrentFolderDetails);
  const isFluffy = useSelector(selectIsFluffy);
  const currentView = useSelector(selectCurrentView);
  const folders = currentFolderAndFiles?.folders || [];
  const foldersChunked = currentFolderAndFiles?.foldersChunked || [];
  const files = currentFolderAndFiles?.files || [];

  // eslint-disable-next-line
  const items: IFileOrFolderVm[] =
    (currentView === DataCenterView.folders && [...folders]) ||
    (isFluffy && [...foldersChunked, ...files]) || [...folders, ...files] ||
    [];

  if (parentFolderId !== currentFolderAndFiles?.parentFolderId && !dataCenterView && panelMode) {
    return null;
  }

  return (
    <ContentMemo
      parentFolderId={parentFolderId}
      items={items}
      getRowHeight={rowHeight(isFluffy)}
      panelMode={panelMode}
      dataCenterView={dataCenterView}
      currentFolderAndFiles={currentFolderAndFiles}
      rootFolderId={rootFolderId}
    />
  );
};

const ContentMemo = memo(
  ({
    parentFolderId,
    items,
    getRowHeight,
    dataCenterView,
    currentFolderAndFiles,
    rootFolderId,
  }: {
    parentFolderId: string;
    items: IFileOrFolderVm[];
    getRowHeight: number;
    dataCenterView: boolean;
    currentFolderAndFiles: any;
    rootFolderId: string | null;
    panelMode: boolean;
  }) => {
    if (isEmpty(items) && currentFolderAndFiles?.hasMore === false && rootFolderId === parentFolderId) {
      return (
        <EmptyState
          emptyType={EntityType.Tdos}
          title={SHARED_TEXT.emptyRootFolderTitle()}
          subtitle={SHARED_TEXT.emptyRootFolderSubtitle()}
        />
      );
    }

    if (isEmpty(items) && currentFolderAndFiles?.hasMore === false) {
      return (
        <EmptyState
          emptyType={EntityType.Folders}
          title={SHARED_TEXT.emptyChildFolderTitle()}
          subtitle={SHARED_TEXT.emptyChildFolderSubtitle()}
        />
      );
    }

    return (
      <>
        {dataCenterView && <FoldersAndFilesTableHeader />}
        <FoldersAndFilesTable
          parentFolderId={parentFolderId}
          items={items}
          rowHeight={getRowHeight}
          dataCenterView={dataCenterView}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps.panelMode && nextProps.dataCenterView) {
      return true;
    }
    return false;
  }
);

export default FoldersAndFiles;
