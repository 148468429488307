import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { SHARED_TEXT } from '../../../../helper/shared-text';

export const HEADER_HEIGHT = 32;

const GroupsTableHeader = () => {
  const {
    groupsTable: { header },
  } = SHARED_TEXT;
  return (
    <>
      <Grid
        data-test={'ac-groups-table-header'}
        sx={{ height: HEADER_HEIGHT, pl: 3 }}
        alignItems="center"
        display="flex"
        container
      >
        <Grid item xs={7}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ cursor: 'default' }}
            data-testid="os-admin-center-groups-table-header-groups-name"
            data-test="os-admin-center-groups-table-header-groups-name"
          >
            {header.groupName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ cursor: 'default' }}
            data-testid="os-admin-center-groups-table-header-created-date"
            data-test="os-admin-center-groups-table-header-created-date"
          >
            {header.createdDate}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ cursor: 'default' }}
            data-testid="os-admin-center-groups-table-header-members"
            data-test="os-admin-center-groups-table-header-members"
          >
            {header.members}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ opacity: 0.9 }} />
    </>
  );
};

export default GroupsTableHeader;
