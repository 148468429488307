import { EAuthResourceType, EAuthSubResourceType, EPermissionAction, TPermissions } from '../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'permissions';

export type TPermissionsEntity = {
  status: LoadingStatus;
  source?: 'OLP' | 'rights';
  permissions?: {
    [key in EPermissionAction]?: boolean;
  };
};
export type State = {
  permissionsByObject: {
    [key in EAuthResourceType]: {
      [entityId: string]: {
        [key in EAuthResourceType]?: TPermissionsEntity;
      };
    };
  };
  rights: {
    status: LoadingStatus;
    myRights: string[];
  };
};

export type PermissionsState = {
  [namespace]: State;
};

export const initialState = {
  permissionsByObject: {},
  rights: {
    status: 'idle',
  },
} as State;

const slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    fetchObjectPermissionsStart(
      state,
      action: PayloadAction<{
        entityType: EAuthResourceType;
        entityId: string;
      }>
    ) {
      const { entityType, entityId } = action.payload;
      if (!Object.prototype.hasOwnProperty.call(state.permissionsByObject, entityType)) {
        // We haven't fetched permissions for any of this type of object yet
        state.permissionsByObject[entityType] = {};
      }
      if (!Object.prototype.hasOwnProperty.call(state.permissionsByObject[entityType], entityId)) {
        // We haven't fetched any permissions for this entityId yet
        state.permissionsByObject[entityType][entityId] = {};
      }
      if (
        !Object.prototype.hasOwnProperty.call(state.permissionsByObject[entityType][entityId], entityType)
      ) {
        // We haven't this permission type on that entityId yet
        state.permissionsByObject[entityType][entityId]![entityType] = {
          status: 'idle',
        };
      }
    },
    fetchObjectPermissionsStarted(
      state,
      action: PayloadAction<{
        entityType: EAuthResourceType;
        entityId: string;
      }>
    ) {
      const { entityId, entityType } = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.permissionsByObject[entityType][entityId]![entityType]!.status = 'pending';
    },
    fetchObjectPermissionsSucceeded(
      state,
      action: PayloadAction<{
        entityType: EAuthResourceType;
        entityId: string;
        permissions: TPermissions;
        source: 'OLP' | 'rights';
      }>
    ) {
      const { entityType, entityId, permissions, source } = action.payload;
      const permissionTypesFetched = Object.keys(permissions) as (EAuthResourceType | EAuthSubResourceType)[];
      permissionTypesFetched.forEach(permissionTypeFetched => {
        (state.permissionsByObject as any)[entityType][entityId][permissionTypeFetched] = {
          status: 'success',
          source,
          permissions: permissions[permissionTypeFetched],
        };
      });
    },
    fetchObjectPermissionsFailed(
      state,
      action: PayloadAction<{
        entityType: EAuthResourceType;
        entityId: string;
      }>
    ) {
      const { entityType, entityId } = action.payload;
      state.permissionsByObject[entityType][entityId]![entityType] = {
        status: 'failure',
      };
    },
    fetchMyRightsStarted(state) {
      state.rights.status = 'pending';
    },
    fetchMyRightsSucceeded(state, action: PayloadAction<string[]>) {
      state.rights.status = 'success';
      state.rights.myRights = action.payload;
    },
    fetchMyRightsFailed(state) {
      state.rights.status = 'failure';
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
