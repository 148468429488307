import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';

const Cancel = () => (
  <FormattedMessage
    id="os-data-center-browse.NiSAz+"
    defaultMessage="Cancel"
    description="Close Folder Panel"
  />
);

const MoveToHome = () => (
  <FormattedMessage
    id="os-data-center-browse.v3CR56"
    defaultMessage="Move to Home"
    description="Select root directory"
  />
);

const ChooseLocation = () => (
  <FormattedMessage
    id="os-data-center-browse.J3etGv"
    defaultMessage="Choose Location"
    description="Choose folder"
  />
);

const useStyles = makeStyles()((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'end',
    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));

interface IProps {
  onClickPrimaryBtn: () => void;
  onClosePanel?: () => void;
  isFolderSelected: boolean;
  selectHomeText?: JSX.Element;
  selectItemText?: JSX.Element;
  disablePrimaryBtn: boolean;
}

const Footer = ({
  onClickPrimaryBtn,
  onClosePanel,
  isFolderSelected,
  selectHomeText,
  selectItemText,
  disablePrimaryBtn,
}: IProps) => {
  const { classes } = useStyles();

  const homeButtonText = selectHomeText || <MoveToHome />;
  const selectedButtonText = selectItemText || <ChooseLocation />;
  return (
    <div className={classes.footer}>
      <Button data-testid="folder-cancel-button" className={classes.cancelButton} onClick={onClosePanel}>
        <Cancel />
      </Button>
      <Button
        disabled={disablePrimaryBtn}
        data-testid="folder-location-select-button"
        onClick={onClickPrimaryBtn}
        variant="contained"
        color="primary"
        type="submit"
      >
        {isFolderSelected ? selectedButtonText : homeButtonText}
      </Button>
    </div>
  );
};

export default Footer;
