import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ appListV2: boolean; type: string }, 'appName'>()(
  (theme: Theme, { appListV2, type }, classes) => ({
    listItem: {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      height: appListV2 ? 'unset' : 70,
      width: '100%',
      padding: appListV2 ? 0 : theme.spacing(0, 6),
      '&:hover': {
        backgroundColor: '#EEF3F9',
      },
      [`& .${classes.appName}`]: {
        marginLeft: theme.spacing(3),
      },
    },
    iconButton: {
      borderRadius: 4,
      textAlign: 'center',
      width: '94px',
      height: '110px',
      cursor: 'pointer',
      [`& .${classes.appName}`]: {
        marginBottom: theme.spacing(3),
      },
    },
    appName: {
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textTransform: 'capitalize',
      width: type === 'switcherGrid' ? '80%' : 'unset',
      margin: type === 'switcherGrid' ? 'auto' : 'unset',
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: appListV2 ? '10px' : theme.spacing(3.8, 0),
    },
    bounding: {
      padding: '17px 27px 19px 27px',
    },
    img: {
      width: 40,
      height: 40,
    },
    switcherGrid: {
      borderRadius: 4,
      textAlign: 'center',
      padding: '20px 0 20px 0',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EEF3F9',
      },
    },
    dialogTitle: {
      fontSize: '18px',
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '-1.30385e-09px',
      fontWeight: 400,
      cursor: 'default',
    },
  })
);
export default useStyles;
