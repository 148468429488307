import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  supportedFilesTypeHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 10px',
    marginBottom: 20,
  },
  supportedFilesTypeWrapper: {
    padding: '8px 20px 20px 20px',
  },
  fileTypeItem: {
    border: '1px solid #D5DFE9',
    borderRadius: 5,
    marginBottom: 20,
    overflow: 'hidden',
  },
  fileTypeItemHeader: {
    padding: 18,
    borderBottom: '1px solid #D5DFE9',
    background: '#FAFAFA',
    display: 'flex',
    alignItems: 'center',
  },
  fileTypeItemBody: {
    display: 'flex',
    alignItems: 'center',
    padding: '26px 0',
    flexWrap: 'wrap',
  },
  fileTypeItemExtension: {
    width: '50%',
    padding: '4px 0 4px 54px',
  },
  fileTypeItemExtensionFullWidth: {
    width: '100%',
  },
  fileItemTypeName: {
    marginLeft: 12,
  },
}));

export default useStyles;
