import { FunctionComponent, useState } from 'react';
import IconButton from '@mui/material/IconButton';

import { MoreVert } from '@aiware/shared/icons';
import { EntityContextMenu } from '../../types';
import ContextMenu from './ContextMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectPanelMode } from '../../redux/selectors/ui-state';
import { actions, EAuthResourceType } from '@aiware/shared/permissions';

const ContextMenuRowButton: FunctionComponent<{
  contextMenu: EntityContextMenu;
  entityId: string;
  type?: EAuthResourceType;
  disabled?: boolean;
}> = ({ contextMenu, entityId, type, disabled = false }) => {
  const [contextMenuEl, setContextMenuEl] = useState<HTMLButtonElement | null>(null);
  const contextMenuId = contextMenuEl ? `contextMenuId-${entityId}` : undefined;
  const panelMode = useSelector(selectPanelMode);
  const dispatch = useDispatch();
  const fetchPermissions = () => {
    if (entityId && type) {
      dispatch(
        actions.fetchObjectPermissionsStart({
          entityType: type,
          entityId,
        })
      );
    }
  };
  if (!panelMode) {
    return (
      <>
        <IconButton
          disabled={disabled}
          className="context-menu-row-button"
          data-test={`context-menu-row-button-${entityId}`}
          onClick={e => {
            e.stopPropagation();
            setContextMenuEl(e.currentTarget);
          }}
          onMouseEnter={fetchPermissions}
          size="small"
        >
          <MoreVert fontSize="small" />
        </IconButton>

        <ContextMenu
          anchorEl={contextMenuEl}
          id={contextMenuId}
          open={!!contextMenuEl}
          onClose={() => setContextMenuEl(null)}
          contextMenu={contextMenu}
          entityId={entityId}
          type={type}
        />
      </>
    );
  } else {
    return null;
  }
};

export default ContextMenuRowButton;
