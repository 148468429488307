import { Typography } from '@mui/material';

type TProps = {
  isDragging: boolean;
  className: string;
  groupName: string;
  dragHandleProps: any;
};

export const GroupDraggingPlaceholder = ({ isDragging, className, groupName, dragHandleProps }: TProps) => {
  if (isDragging) {
    return (
      <Typography variant="body2" className={className} {...dragHandleProps}>
        {groupName}
      </Typography>
    );
  }
  return null;
};
