import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ currentWallpaper: any }>()((_theme, { currentWallpaper }) => ({
  logoContainer: {
    height: 'calc(100vh - 55px)',
    backgroundImage: `url(${currentWallpaper})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  error: {
    margin: '2em',
  },
  loadingOverlay: {
    position: 'fixed',
    height: 'calc(100vh - 55px)',
    background: 'white',
    width: '100vw',
    '& button': {
      marginTop: '20px',
    },
  },
  loadingMessage: {
    width: '600px',
    height: '300px',
    position: 'absolute',
    left: 'calc(50% - 300px)',
    top: 'calc(50% - 150px)',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: '20px',
  },
}));
export { useStyles };
