import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export interface IConfirmationModal {
  showConfirmationModal: boolean;
  closeConfirmationModal: () => void | void;
  onClosePanel: () => void | void;
  classes?: any; // FIXME: mui5 ClassNameMap imported from mui/styles is a legacy type
}

export const ConfirmationModal = ({
  showConfirmationModal,
  closeConfirmationModal,
  onClosePanel,
  classes,
}: IConfirmationModal) => {
  const { classes: defaultClasses } = makeStyles()((theme: Theme) => ({
    dialogTitle: {
      padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(1)}px !important `,
    },
    dialogContent: {
      padding: `${theme.spacing(1)} ${theme.spacing(6)} !important `,
    },
    dialogTitleText: {
      fontWeight: 600,
    },
    dialogAction: {
      padding: `${theme.spacing(4)} !important`,
    },
  }))();

  const modalClasses = { ...defaultClasses, ...classes };

  return (
    <Dialog
      open={showConfirmationModal}
      onClose={closeConfirmationModal}
      maxWidth="xs"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle
        id="dialog-title"
        data-test="data-center-importer-dialog-title"
        className={modalClasses?.dialogTitle}
      >
        <Typography variant="h2" className={modalClasses?.dialogTitleText}>
          <FormattedMessage
            id="ui.LHrD7k"
            defaultMessage="Confirm Close Panel"
            description="title for close confirm panel"
          />
        </Typography>
      </DialogTitle>
      <DialogContent className={modalClasses?.dialogContent}>
        <DialogContentText id="dialog-description">
          <FormattedMessage
            id="ui.nVK3ww"
            defaultMessage="Are you sure you want to close the panel and lose your data?"
            description="description for confirm close panel"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={modalClasses?.dialogAction}>
        <Button
          onClick={closeConfirmationModal}
          variant="text"
          size="large"
          data-test="data-center-importer-dialog-back-btn"
        >
          <FormattedMessage
            id="ui.bGsCYS"
            defaultMessage="Back"
            description="button text for cancelling close the panel"
          />
        </Button>
        <Button
          onClick={onClosePanel}
          size="large"
          variant="contained"
          color="primary"
          data-test="data-center-importer-dialog-confirm-close-btn"
        >
          <FormattedMessage
            id="ui.UYo+Qn"
            defaultMessage="Close Panel"
            description="button text for confirmation close panel"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
