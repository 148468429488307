import { useForm, type UseFormProps, type SubmitHandler } from 'react-hook-form';
import type { FormGeneratorData } from '../types';

export interface UseFormGeneratorProps {
  formOptions?: Omit<UseFormProps<FormGeneratorData>, 'defaultValues'>;
  defaultValues?: UseFormProps<FormGeneratorData>['defaultValues'];
}

export const useFormGenerator = ({ formOptions = {}, defaultValues }: UseFormGeneratorProps = {}) => {
  const methods = useForm<FormGeneratorData>({
    mode: 'onChange',
    ...formOptions,
    ...(defaultValues && { defaultValues }),
  });

  const handleSubmit = (onSubmit: SubmitHandler<FormGeneratorData>) => {
    return methods.handleSubmit((data: FormGeneratorData) => {
      onSubmit(data);
    });
  };

  return {
    ...methods,
    handleSubmit,
  };
};
