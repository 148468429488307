import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICriterion } from '../../types/aiAutocomplete.types';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';

import {
  getFileType,
  getId,
  getFileName,
  getCreatedTime,
  getCreatedDate,
} from '../../helpers/aiSearch.helpers';

export const namespace = 'aiSearch';

const DEFAULT_LAST_COLUMN_STATE = {
  enabled: true,
  value: 'Matches',
};

export const initialState: State = {
  searchCriteria: [],
  lastColumn: Object.freeze(DEFAULT_LAST_COLUMN_STATE),
  files: [
    // ...mockSearchResultsCms
  ],
  isLoading: false,
  error: null,
  activeCriteria: [],
  hasMore: false,
  offset: 0,
  uniqueTdos: [],
  totalResults: 0,
};

export interface State {
  searchCriteria: ICriterion[];
  lastColumn: {
    enabled: boolean;
    value: string;
    config?: {
      [key: string]: string | number | boolean;
    };
  };
  files: Array<any>;
  isLoading: boolean;
  error: Error | null;
  activeCriteria: any[];
  hasMore: boolean;
  offset: number;
  uniqueTdos: string[];
  totalResults: number;
}

export const fileSearchSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    filesSearchStart: state => {
      if (state.offset === 0) {
        state.isLoading = true;
      }
    },
    filesSearchSucceeded: (state, { payload }: PayloadAction<any>) => {
      const { files, capability } = payload?.data ?? {};

      const unSanitizedfiles = files;
      const sanitizedFiles = filter(unSanitizedfiles, (file: any) => file !== null && !isArray(file));
      const mappedFiles = sanitizedFiles.map((file: any) => {
        return {
          ...file,
          capabilityType: capability,
          fileType: getFileType(file),
          id: getId(file),
          name: getFileName(file),
          createdDate: getCreatedDate(file),
          createdTime: getCreatedTime(file),
        };
      });

      state.isLoading = false;
      if (state.offset > 0) {
        state.files = [...state.files, ...mappedFiles];
      } else {
        state.files = mappedFiles;
      }
    },
    filesSearchFailed: (state, { payload }: PayloadAction<Error | undefined>) => {
      state.files = [];
      state.error = payload ? payload : null;
      state.isLoading = false;
    },
    setLastColumn: (state, { payload }: PayloadAction<Partial<State['lastColumn']>>) => {
      if (payload.enabled) {
        state.lastColumn.enabled = payload.enabled;
      }
      if (payload.value) {
        state.lastColumn.value = payload.value;
      }
      if (payload.config) {
        state.lastColumn.config = payload.config;
      }
    },
    setActiveCriteria: (state, { payload }: PayloadAction<any>) => {
      state.activeCriteria = payload;
      state.files = [];
      state.offset = 0;
      state.hasMore = false;
    },
    setHasMore: (state, { payload }: PayloadAction<boolean>) => {
      state.hasMore = payload;
      if (!payload && state.totalResults >= 0) {
        state.totalResults = state.uniqueTdos.length;
      }
    },
    setOffset: (state, { payload }: PayloadAction<number>) => {
      state.offset = payload;
    },
    setUniqueTdos: (state, { payload }: PayloadAction<Array<string>>) => {
      state.uniqueTdos = payload;
    },
    setTotalResults: (state, { payload }: PayloadAction<number>) => {
      state.totalResults = payload;
    },
    setSearchCriteria: (state, { payload }: PayloadAction<ICriterion[]>) => {
      state.searchCriteria = payload;
    },
    resetSearchResults: state => {
      state.files = [];
      state.offset = 0;
      state.hasMore = false;
      state.uniqueTdos = [];
      state.totalResults = 0;
      state.activeCriteria = [];
      state.lastColumn = DEFAULT_LAST_COLUMN_STATE;
    },
  },
});

export const actions = fileSearchSlice.actions;
export default fileSearchSlice.reducer;
