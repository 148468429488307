import createSvgIcon from '../createSvgIcon';

export const ChunkWhite = createSvgIcon(
  <svg width="19" height="19" viewBox="1 1 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2308 2L3 7V17L12.2308 22L21.4615 17V7L12.2308 2Z"
      stroke="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4917 11.9703L3.03757 16.9462L3.03757 6.99438L12.4917 11.9703Z"
      fill="white"
    />
  </svg>,
  'ChunkWhite'
);
