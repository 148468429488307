import createSvgIcon from '../createSvgIcon';
export const RadioSource = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#1873E8" />
    <path
      d="M16.6667 8.49998C16.9761 8.49998 17.2728 8.6229 17.4916 8.84169C17.7104 9.06048 17.8333 9.35723 17.8333 9.66665V16.6666C17.8333 16.9761 17.7104 17.2728 17.4916 17.4916C17.2728 17.7104 16.9761 17.8333 16.6667 17.8333H7.33332C7.0239 17.8333 6.72716 17.7104 6.50837 17.4916C6.28957 17.2728 6.16666 16.9761 6.16666 16.6666V9.66665C6.16666 9.17081 6.47582 8.74498 6.91332 8.57581L14.1642 5.58331L14.6075 6.65081L10.1508 8.49998H16.6667ZM16.6667 9.66665H7.33332V12H14.3333V10.8333H15.5V12H16.6667V9.66665ZM9.08332 13.1666C8.61919 13.1666 8.17408 13.351 7.84589 13.6792C7.5177 14.0074 7.33332 14.4525 7.33332 14.9166C7.33332 15.3808 7.5177 15.8259 7.84589 16.1541C8.17408 16.4823 8.61919 16.6666 9.08332 16.6666C9.54745 16.6666 9.99257 16.4823 10.3208 16.1541C10.6489 15.8259 10.8333 15.3808 10.8333 14.9166C10.8333 14.4525 10.6489 14.0074 10.3208 13.6792C9.99257 13.351 9.54745 13.1666 9.08332 13.1666Z"
      fill="white"
    />
  </svg>,
  'RadioSource'
);
