import {
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsMatchValueValid,
  selectMatchValueOne,
  selectMatchValueTwo,
  selectOperator,
  selectSelectedProperty,
} from '../../../../../state/aiStructuredData/aiStructuredData.selectors';
import { EPropertyType } from './MatchValueContainer';
import * as React from 'react';
import { EJSONSchemaStringFormat } from '../../../../../types/aiStructuredData.types';
import { actions } from '../../../../../state/aiStructuredData/aiStructuredData.slice';
import { FunctionComponent, useEffect } from 'react';
import { injectIntl, WithIntlProps } from 'react-intl';

const TrueFalseInput = ({
  label,
  onChange,
}: {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <FormControl>
      <FormLabel
        sx={{ textTransform: 'capitalize' }}
        id="structured-data-true-false-radio-buttons-group-label"
      >
        {label}:
      </FormLabel>
      <RadioGroup
        onChange={onChange}
        row
        aria-labelledby="structured-data-true-false-radio-buttons-group-label"
        name="data-center.sds.true-or-false"
        data-testid={'dc-sds-true-false.radio-group'}
      >
        <FormControlLabel
          data-testid={'dc-sds-true-false-option.true'}
          value="true"
          control={<Radio />}
          label="True"
        />
        <FormControlLabel
          data-testid={'dc-sds-true-false-option.false'}
          value="false"
          control={<Radio />}
          label="False"
        />
      </RadioGroup>
    </FormControl>
  );
};

export interface IValueInputRenderProps {
  propertyDefinition: {
    type: string;
    format?: EJSONSchemaStringFormat;
  };
  intl: any;
}

export enum EInputElementTypes {
  text = 'text',
  number = 'number',
  datetimeLocal = 'datetime-local',
  date = 'date',
  url = 'url',
  time = 'time',
}

const castType = (value: string, inputType: EInputElementTypes) => {
  if (inputType === EInputElementTypes.number) {
    return Number(value);
  }
  return value;
};

const invalidStyles = {
  '& input': {
    color: 'red',
  },
  '& fieldset': {
    borderColor: 'red!important',
  },
};

const ValueInputRenderWrapped: React.FC<IValueInputRenderProps> = ({ propertyDefinition, intl }) => {
  const dispatch = useDispatch();
  const operator = useSelector(selectOperator);
  const property = useSelector(selectSelectedProperty);
  const isRange = operator === 'range';
  const isValidFields = useSelector(selectIsMatchValueValid);
  const matchValueOne = useSelector(selectMatchValueOne);
  const matchValueTwo = useSelector(selectMatchValueTwo);

  const handleValueChange = (value: string | number | boolean | null, valueId: 1 | 2) => {
    dispatch(actions.setMatchValue({ valueId, value }));
  };

  const getInputField = (inputType: EInputElementTypes) => {
    const getPlaceholder = () => {
      switch (inputType) {
        case EInputElementTypes.time:
          return `${intl.formatMessage({
            id: 'os-dc-sds.input-placeholder.choose-time',
            defaultMessage: 'Choose a time',
            description: 'Input placeholder text: Choose a time',
          })}`;
        case EInputElementTypes.datetimeLocal:
          return `${intl.formatMessage({
            id: 'os-dc-sds.input-placeholder.choose-date',
            defaultMessage: 'Choose a date',
            description: 'Input placeholder text: Choose a date',
          })}`;
        case EInputElementTypes.number:
          return `${intl.formatMessage({
            id: 'os-dc-sds.input-placeholder.choose-number',
            defaultMessage: 'Enter a number',
            description: 'Input placeholder text: Enter a number',
          })}`;
        case EInputElementTypes.text:
        default: {
          return `${intl.formatMessage({
            id: 'os-dc-sds.input-placeholder.choose-value',
            defaultMessage: 'Enter a value',
            description: 'Input placeholder text: Enter a value',
          })}`;
        }
      }
    };

    return (
      <TextField
        data-testid={'dc-sds-value-input'}
        sx={{ width: '50%', ...(isValidFields ? {} : invalidStyles) }}
        fullWidth
        autoFocus
        label={'Value'}
        value={matchValueOne === null ? '' : matchValueOne}
        placeholder={getPlaceholder()}
        type={inputType}
        size={'small'}
        onChange={({ target }) => handleValueChange(castType(target.value, inputType), 1)}
      />
    );
  };

  const getRangeField = (inputType: EInputElementTypes) => {
    const isDate = inputType === 'datetime-local';

    return (
      <Stack
        direction="row"
        sx={{
          width: '82%',
          display: 'flex',
          maxWidth: '100%',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <TextField
          data-testid={'dc-sds-value-input-range-start'}
          autoFocus
          sx={{ ...(isValidFields ? {} : invalidStyles) }}
          value={matchValueOne === null ? '' : matchValueOne}
          placeholder={'Enter a value'}
          type={inputType}
          size={'small'}
          label={isDate ? 'Start Date' : 'Start'}
          onChange={({ target }) => handleValueChange(castType(target.value, inputType), 1)}
        />
        <Typography>to</Typography>
        <TextField
          data-testid={'dc-sds-value-input-range-end'}
          sx={{ ...(isValidFields ? {} : invalidStyles) }}
          value={matchValueTwo === null ? '' : matchValueTwo}
          placeholder={'Enter a value'}
          type={inputType}
          size={'small'}
          label={isDate ? 'End Date' : 'End'}
          onChange={({ target }) => handleValueChange(castType(target.value, inputType), 2)}
        />
      </Stack>
    );
  };

  const getInputElement = () => {
    switch (propertyDefinition.type) {
      case EPropertyType.string: {
        if (
          propertyDefinition?.format &&
          Object.values(EJSONSchemaStringFormat).includes(propertyDefinition.format)
        ) {
          switch (propertyDefinition.format) {
            case EJSONSchemaStringFormat.date: {
              if (isRange) {
                return getRangeField(EInputElementTypes.date);
              } else {
                return getInputField(EInputElementTypes.date);
              }
            }
            case EJSONSchemaStringFormat.dateTime: {
              if (isRange) {
                return getRangeField(EInputElementTypes.datetimeLocal);
              } else {
                return getInputField(EInputElementTypes.datetimeLocal);
              }
            }
            case EJSONSchemaStringFormat.time: {
              if (isRange) {
                return getRangeField(EInputElementTypes.time);
              } else {
                return getInputField(EInputElementTypes.time);
              }
            }
            case EJSONSchemaStringFormat.uri: {
              return getInputField(EInputElementTypes.url);
            }
            default: {
              return getInputField(EInputElementTypes.text);
            }
          }
        }
        return getInputField(EInputElementTypes.text);
      }
      case EPropertyType.number:
      case EPropertyType.integer: {
        if (isRange) {
          return getRangeField(EInputElementTypes.number);
        } else {
          return getInputField(EInputElementTypes.number);
        }
      }
      case EPropertyType.boolean: {
        return (
          <TrueFalseInput
            onChange={({ target }) => handleValueChange(target.value === 'true', 1)}
            label={property.path}
          />
        );
      }

      default: {
        return null;
      }
    }
  };
  useEffect(() => {
    // Clear the value when switching away from range operator
    if (!isRange) {
      handleValueChange(null, 2);
    }
  }, [isRange]);

  useEffect(() => {
    // Reset the values when the property changes
    handleValueChange(null, 1);
    handleValueChange(null, 2);
  }, [property]);

  if (!operator) return null;
  return <>{getInputElement()}</>;
};

export const ValueInputRender: FunctionComponent<
  WithIntlProps<{ intl: any; propertyDefinition: { type: string; format?: EJSONSchemaStringFormat } }>
> = injectIntl(ValueInputRenderWrapped);
