import { FunctionComponent } from 'react';
import { emptyFiles, emptyFolders, emptySources, emptyJobs } from '@aiware/shared/assets';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { EntityType } from '@aiware/js/interfaces';
import { useSelector } from 'react-redux';
import { selectPanelMode } from '../../redux/selectors/ui-state';

const useStyles = makeStyles()(theme => ({
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(18),
    width: '100%',
  },
  emptyImage: {
    display: 'flex',
    textAlign: 'center',
    width: '235px',
    height: '235px',
    marginBottom: theme.spacing(2),
  },
  message: {
    userSelect: 'none',
    paddingBottom: theme.spacing(2),
  },
  messageLocationFolder: {
    userSelect: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const EmptyState: FunctionComponent<{
  emptyType: EntityType;
  title: JSX.Element;
  subtitle: JSX.Element;
}> = ({ emptyType, title, subtitle }) => {
  const panelMode = useSelector(selectPanelMode);
  const { classes } = useStyles();
  let value;

  if (emptyType === EntityType.Schedules) {
    value = emptySources;
  }
  if (emptyType === EntityType.Sources) {
    value = emptySources;
  }
  if (emptyType === EntityType.Tdos) {
    value = emptyFiles;
  }
  if (emptyType === EntityType.Folders) {
    value = emptyFolders;
  }
  if (emptyType === EntityType.Jobs) {
    value = emptyJobs;
  }
  return (
    <div className={classes.emptyStateContainer} data-test={`empty-state-container`}>
      <img data-test={`empty-state-image`} alt={emptyType} className={classes.emptyImage} src={value} />
      <Typography
        data-test={`empty-state-title`}
        className={classes.message}
        variant="h2"
        color="textPrimary"
      >
        {title}
      </Typography>
    </div>
  );
};

export default EmptyState;
