import createSvgIcon from '../createSvgIcon';
export const Pause = createSvgIcon(
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.83333 13.4168H11.5V0.583496H7.83333V13.4168ZM0.5 13.4168H4.16667V0.583496H0.5V13.4168Z"
      fill="#48516A"
    />
  </svg>,
  'Pause'
);
