import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { Hide } from '@aiware/shared/icons';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { hidePanel } from '@aiware/js/panel';
import { inviteUsersPanel, useStyles } from '../OrganizationPanel/PanelHeader/PanelHeader';

const Header: FunctionComponent<{
  height: number;
}> = ({ height }) => {
  const { classes } = useStyles({ height });
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hidePanel(inviteUsersPanel));
  };

  return (
    <header className={classes.header} data-testid="invitation-panel-header">
      <Typography variant="h1" data-testid="org-title" className={classes.title}>
        {SHARED_TEXT.inviteUsers}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Typography style={visuallyHidden} id="org-close-panel">
          {SHARED_TEXT.closePanel}
        </Typography>
        <Tooltip title={SHARED_TEXT.closePanel}>
          <IconButton
            onClick={handleClose}
            aria-labelledby="invite-users-close-panel"
            data-testid="invite-users-close-panel"
            size="large"
          >
            <Hide />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
