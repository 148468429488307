import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { FolderFilled } from '@aiware/shared/icons';
import { useDoubleClick } from '@aiware/js/function';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { EntityType } from '@aiware/js/interfaces';

import { useStyles } from '../useStyles';
import { actions } from '../../../redux/slices';
import { IFolderVm } from '../../../types';
import ContextMenuRowButton from '../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../types/entity-context-menus';
import { selectCurrentView } from '../../../redux/selectors/ui-state';
import { selectSelectedItemId, selectSelectedItems } from '../../../redux/selectors/view-my-files';

const folderContextMenu = ENTITY_CONTEXT_MENU[EntityType.Folders];

const FoldersTableFluffyRow: FunctionComponent<{
  items: IFolderVm[];
  rowHeight: number;
}> = ({ items, rowHeight }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentView = useSelector(selectCurrentView);
  const selectedItemId = useSelector(selectSelectedItemId);
  const selectedItems = useSelector(selectSelectedItems);
  const isSelectingFiles = selectedItems.length > 0;

  const triggerClick = useDoubleClick<IFolderVm>((isDoubleClick: boolean, item?: IFolderVm) => {
    if (isDoubleClick) {
      dispatch(
        actions.viewMyFiles.addToMyFilesBreadCrumbs({
          breadcrumb: {
            entity: EntityType.Folders,
            id: item?.folder?.id,
          },
          viewType: currentView,
        })
      );
    } else {
      dispatch(actions.viewMyFiles.toggleFileOrFolderSelected(item?.folder?.id || null));
    }
  });

  const handleFolderClick = (item: IFolderVm) => () => triggerClick(item);

  const folderItems = () => {
    return items.map((item: IFolderVm) => {
      return (
        <Grid
          key={item.folder.id}
          data-test={`${EntityType.Folders}-folder-row-fluffy-${item.folder.id}`}
          className={classes.folderContainer}
          item
          container
          justifyContent="center"
          xs={2}
        >
          <Grid
            className={classNames(classes.folder, {
              selected: item.folder.id === selectedItemId,
            })}
            item
            xs
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <div>
              <div className={classes.folderMoreIcon}>
                <ContextMenuRowButton
                  disabled={isSelectingFiles}
                  contextMenu={folderContextMenu}
                  entityId={item.folder.id}
                  type={EAuthResourceType.Folder}
                />
              </div>
            </div>
            <Icon
              data-test={'item-folderIcon-fluffy'}
              className={classes.sourceIcon}
              component={FolderFilled}
              color="primary"
              fontSize="large"
              sx={{
                fontSize: '36px !important',
              }}
              onClick={handleFolderClick(item)}
            />
            <Tooltip title={item.folder.name} placement="bottom">
              <Typography
                data-test={'item-name-fluffy'}
                noWrap
                className={classes.folderName}
                variant="caption"
                color="textPrimary"
              >
                {item.folder.name}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid
      data-test={`${EntityType.Folders}-folders-row-fluffy`}
      className={classes.header}
      style={{ height: rowHeight }}
      container
    >
      {folderItems()}
    </Grid>
  );
};
export default FoldersTableFluffyRow;
