import { LoadingStatus } from '@aiware/js/interfaces';
import * as actions from './action';
import { IApplication } from '../../../types';

export interface ApplicationState {
  application: Array<unknown>;
  message: string;
  status: LoadingStatus;
  selectedApp: IApplication;
}

const initialState = {
  application: [],
  message: '',
  status: 'idle',
  selectedApp: null,
};

interface IApplicationPayload {
  data: unknown;
  message: string;
}

export function applicationReducer<T extends IApplicationPayload>(
  state: ApplicationState,
  action: { type: string; payload: T }
) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actions.GET_APPLICATION_V1_START: {
      return {
        ...state,
        status: 'loading',
      };
    }

    case actions.GET_APPLICATION_V1_SUCCESS: {
      return {
        ...state,
        application: action.payload.data,
        status: 'success',
      };
    }

    case actions.GET_APPLICATION_V1_ERROR: {
      return {
        ...state,
        message: action.payload.message,
        status: 'failure',
      };
    }

    case actions.SET_APPLICATION_IN_STORE: {
      return {
        ...state,
        selectedApp: action.payload.data,
      };
    }

    default:
      return state;
  }
}
