import { Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material/';
import { FC, useCallback, useState, useEffect } from 'react';
import { useStyles } from './dateRangeFilter.styles';
import { Divider, TextField } from '@mui/material';
import { format, subDays } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import EventAvailable from '@mui/icons-material/EventAvailable';
import { sharedStyles, muiSelectStyleOverrides } from '../../shared/aiSearchFilter.styles';
import { dateMenuItemIntl, AiSearchText } from '../../../../helpers/aiSearch.text';

export const DateRangeFilter: FC<any> = ({ id, label, onChange, dateRangeType }) => {
  const { classes } = useStyles();
  const { classes: sharedClasses } = sharedStyles();
  const [modifiedSelection, setModifiedSelection] = useState(dateRangeType);

  // Custom date range
  const [startDate, setStartDate] = useState(format(subDays(new Date(), 1), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const isCustomSelected = modifiedSelection?.includes('custom::');
  const isCustomDateInputsProvided = startDate.length > 0 && endDate.length > 0;

  useEffect(() => {
    setModifiedSelection(dateRangeType);
  }, [dateRangeType]);

  const getSearchFilterObject = (value: string) => {
    const obj = {
      field: 'absoluteStartTimeMs',
      gte: '', // Start date
      lte: '', // End date
      operator: 'range',
    };

    if (value.includes('custom::')) {
      const [start, end] = value.split('custom::')[1]!.split('to');
      obj.gte = start ?? '';
      obj.lte = end ?? '';
      return obj;
    }

    const currentDate = format(new Date(), 'yyyy-MM-dd');
    obj.lte = currentDate;

    switch (value) {
      case 'any':
        break;
      case 'today':
        obj.gte = currentDate;
        break;
      case '24hour':
        obj.gte = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        break;
      case '7day':
        obj.gte = format(subDays(new Date(), 7), 'yyyy-MM-dd');
        break;
      case '30day':
        obj.gte = format(subDays(new Date(), 30), 'yyyy-MM-dd');
        break;
    }

    return obj;
  };

  const handleDateChange = ({ target }: any) => {
    const { value, name } = target;
    if (name === 'custom-from-date') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const handleClear = () => {
    const e = { target: { value: 'any' } };
    handleChange(e as unknown as SelectChangeEvent<string>);
  };

  const handleChange = useCallback(
    (ev: SelectChangeEvent<string>) => {
      const { value } = ev.target;
      const filterObject = getSearchFilterObject(value);

      if (onChange) onChange(filterObject, value);
    },
    [onChange]
  );

  const renderSelectValue = (value: string) => {
    if (value.includes('custom::')) {
      const [start, end] = value.split('custom::')[1]!.split('to');
      const parseDayString = (s: string) => {
        const [year, month, day] = s.split('-');
        return `${month?.startsWith('0') ? month?.slice(1) : month}/${
          day?.startsWith('0') ? day?.slice(1) : day
        }/${year}`;
      };
      return (
        <span className={classes.customRangeRendered}>
          <EventAvailable
            sx={{
              fontSize: '16px',
              fill: '#555F7C',
              marginRight: '3px',
            }}
          />
          <span>{parseDayString(start ?? '')}</span> <span className={classes.dateRangeDivider}>─</span>{' '}
          <span>{parseDayString(end ?? '')}</span>
        </span>
      );
    } else {
      return (dateMenuItemIntl as any)[value];
    }
  };

  return (
    <FormControl size="small" className={sharedClasses.formControl}>
      <label htmlFor={id} className={sharedClasses.label}>
        {label}{' '}
        {modifiedSelection !== 'any' && (
          <span onClick={handleClear} className={sharedClasses.clearBtn}>
            {AiSearchText.clearLabelText()}
          </span>
        )}
      </label>
      <Select
        sx={isCustomSelected ? { minWidth: '220px', ...muiSelectStyleOverrides } : muiSelectStyleOverrides}
        id={id}
        variant="outlined"
        value={modifiedSelection}
        className={sharedClasses.field}
        defaultValue={modifiedSelection}
        onChange={handleChange}
        renderValue={renderSelectValue}
      >
        {Object.keys(dateMenuItemIntl).map(itemKey => (
          <MenuItem data-test={`dc-search-date-filter-${itemKey}`} key={itemKey} value={itemKey}>
            {(dateMenuItemIntl as any)[itemKey]}
          </MenuItem>
        ))}
        <Divider />
        <div className={classes.dateRangeContainer}>
          <div className={classes.dateRangeInputs}>
            <TextField
              id="custom-from"
              label="From"
              variant="outlined"
              type="date"
              name="custom-from-date"
              value={startDate}
              onChange={handleDateChange}
              inputProps={endDate.length > 0 ? { max: endDate } : { max: format(new Date(), 'yyyy-MM-dd') }}
              size="small"
            />
            <span className={classes.dateRangeSpacer}></span>
            <TextField
              sx={{ marginTop: '10px' }}
              id="custom-to"
              label="To"
              size="small"
              name="custom-to-date"
              variant="outlined"
              type="date"
              value={endDate}
              onChange={handleDateChange}
              inputProps={{ min: startDate, max: format(new Date(), 'yyyy-MM-dd') }}
            />
          </div>
        </div>
        <MenuItem
          sx={{ textTransform: 'none', justifyContent: 'center' }}
          value={`custom::${startDate}to${endDate}`}
          disabled={!isCustomDateInputsProvided}
          data-test="dc-search-date-filter-custom-range-button"
        >
          <FormattedMessage
            id="os-data-center-browse.search-filter.modified-date-selection.use-custom-btn"
            defaultMessage="Use Custom Range"
            description="Search Filter by Date, Use Custom Range button"
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
