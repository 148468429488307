import { AiAutocompleteContainer } from './AiAutocomplete.container';
import { ErrorBoundary } from '@aiware/ui';
import FallbackUI from './components/ErrorBoundaryFallbackUI';

export type TProps = {
  placeholder?: string;
};

export const AiAutocomplete = ({ placeholder }: TProps) => {
  return (
    <ErrorBoundary fallbackUI={<FallbackUI />}>
      <AiAutocompleteContainer placeholder={placeholder} />
    </ErrorBoundary>
  );
};
