export const formattedMessages = (intl: any) => ({
  permissionsPanelHeader: intl.formatMessage({
    id: `permissions-panel.header.title`,
    defaultMessage: 'Permissions',
    description: 'Permissions panel header title',
  }),
  groupInfoPanelHeader: intl.formatMessage({
    id: `group-info-panel.header.title`,
    defaultMessage: 'Group Info',
    description: 'Group Info panel header title',
  }),
  permissionsPanelClose: intl.formatMessage({
    id: `permissions-panel.header.close-button`,
    defaultMessage: 'Close Panel',
    description: 'Panel Close button',
  }),
  membersLabel: intl.formatMessage({
    id: `permissions-panel.group-members.label`,
    defaultMessage: 'members',
    description: 'Permissions panel group members',
  }),
  memberLabel: intl.formatMessage({
    id: `permissions-panel.group-member.label`,
    defaultMessage: 'member',
    description: 'Permissions panel group member',
  }),
  addedLabel: intl.formatMessage({
    id: `permissions-panel.group-members.added-date`,
    defaultMessage: 'Added',
    description: "Permissions panel group's added_at date",
  }),
  removeAccess: intl.formatMessage({
    id: `permissions-panel.group-members.remove-access`,
    defaultMessage: 'Remove Access',
    description: "Permissions panel remove member's access",
  }),
  removeBtn: intl.formatMessage({
    id: `permissions-panel.group-members.remove-access-factory`,
    defaultMessage: 'Remove',
    description: "Permissions panel remove member's access",
  }),
  lookupTitle: intl.formatMessage({
    id: `permissions-panel.member-lookup.label-people-or-group`,
    defaultMessage: 'Grant access to this object to specific people or groups',
    description: 'Permissions panel member lookup instructions',
  }),
  lookupTitleMVP: intl.formatMessage({
    id: `permissions-panel.member-lookup.label-people`,
    defaultMessage: 'Grant access to this object to specific groups',
    description: 'Permissions panel member lookup instructions',
  }),
  lookupLabel: intl.formatMessage({
    id: `ac-permissions-panel.member-lookup.placeholder-people-or-groups`,
    defaultMessage: 'Enter names of people or groups',
    description: 'Member lookup input placeholder',
  }),
  lookupLabelMVP: intl.formatMessage({
    id: `ac-permissions-panel.member-lookup.placeholder-groups`,
    defaultMessage: 'Enter names of groups',
    description: 'Member lookup input placeholder',
  }),
  currentLabel: intl.formatMessage({
    id: `permissions-panel.member-lookup.current-member-label`,
    defaultMessage: 'Current',
    description: 'Current member of a permission set',
  }),
  panelControlsDoneBtn: intl.formatMessage({
    id: `permissions-panel.controls.done-btn`,
    defaultMessage: 'Done',
    description: 'Done button',
  }),
  panelControlsSendBtn: intl.formatMessage({
    id: `permissions-panel.controls.send-btn`,
    defaultMessage: 'Send',
    description: 'Send button',
  }),
  panelControlsBackBtn: intl.formatMessage({
    id: `permissions-panel.controls.back-btn`,
    defaultMessage: 'Back',
    description: 'Back button',
  }),
  panelControlsSaveBtn: intl.formatMessage({
    id: `permissions-panel.controls.save-btn`,
    defaultMessage: 'Save',
    description: 'Save button',
  }),
  panelControlsUpdateBtn: intl.formatMessage({
    id: `permissions-panel.controls.update-btn`,
    defaultMessage: 'Add',
    description: 'Update button',
  }),
  removeGroupAccessModalTitle: intl.formatMessage({
    id: `permissions-panel.revoke-access.modal-title`,
    defaultMessage: 'Remove Group Access?',
    description: 'Revoke group access to an object',
  }),
  removeGroupAccessModalMessage: intl.formatMessage({
    id: `permissions-panel.revoke-access.modal-message`,
    defaultMessage:
      'Removing this group from this Permission Set, will affect all users of this group removing their permission to perform system level functionality. Would you like to continue?',
    description: 'Revoke group access to an object confirmation',
  }),
  cancelBtn: intl.formatMessage({
    id: `permissions-panel.controls.cancel-btn`,
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  }),
  confirmBtn: intl.formatMessage({
    id: `permissions-panel.controls.confirm-btn`,
    defaultMessage: 'Confirm',
    description: 'Confirm button',
  }),
  newPermissionsPanelHeader: intl.formatMessage({
    id: `new-permissions-panel.header.title`,
    defaultMessage: 'Create new permission set',
    description: 'Create new permission set header',
  }),
  permissionSetDetailsPanelHeader: intl.formatMessage({
    id: `permission-set-details-panel.header.title`,
    defaultMessage: 'Permission Set Details',
    description: 'Permission set details header',
  }),
  permissionSetDetailsPanelDescription: intl.formatMessage({
    id: `permission-set-details-panel.description`,
    defaultMessage:
      'Control what users can do within this Organization when they have access to this Permission Set.',
    description: 'Permission set details description',
  }),
  tabTitle_basic: intl.formatMessage({
    id: `tab-title.basic`,
    defaultMessage: 'Basic',
    description: '"Basic" tab title',
  }),
  tabTitle_permissions: intl.formatMessage({
    id: `tab-title.permissions`,
    defaultMessage: 'Permissions',
    description: '"Permissions" tab title',
  }),
  tabTitle_settings: intl.formatMessage({
    id: `tab-title.settings`,
    defaultMessage: 'Settings',
    description: '"Settings" tab title',
  }),
  basicTabHeader: intl.formatMessage({
    id: `ac-permission-basic-title.header-information`,
    defaultMessage: 'Information',
    description: '"Information" tab header',
  }),
  settingsTabHeader: intl.formatMessage({
    id: `ac-permission-basic-title.header-general`,
    defaultMessage: 'General',
    description: '"General" tab header',
  }),
  permissionSetNameLabel: intl.formatMessage({
    id: `permissionSetNameLabel`,
    defaultMessage: 'Permission Name',
    description: 'Permission Set Name label',
  }),
  createdAtLabel: intl.formatMessage({
    id: `createdAtLabel`,
    defaultMessage: 'Created',
    description: 'Permission Set Created label',
  }),
  permissionSetDescriptionLabel: intl.formatMessage({
    id: `permissionSetDescriptionLabel`,
    defaultMessage: 'Description',
    description: 'Permission set Description label',
  }),
});
