import { DataCenterState } from '../../../../redux/slices';
import some from 'lodash/some';
import { EActiveView, ISuggestionResult } from '../../types/aiAutocomplete.types';
import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  sessionTokenSelector,
  graphEndpointSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

export const selectIsLoadingStatus = (state: DataCenterState): boolean => {
  const isLoading = some(state.dataCenter.aiAutocomplete.suggestionResult, { isLoading: true });

  return isLoading;
};

export const selectSuggestionResult = (state: DataCenterState): ISuggestionResult => {
  return state.dataCenter.aiAutocomplete.suggestionResult;
};

export const selectIsOpen = ({ dataCenter }: DataCenterState): boolean => {
  return dataCenter.aiAutocomplete.isOpen;
};

export const selectIsTyping = ({ dataCenter }: DataCenterState): boolean => {
  return dataCenter.aiAutocomplete.isTyping;
};

export const selectSearchTerm = (state: DataCenterState): string => {
  return state.dataCenter.aiAutocomplete.searchTerm;
};

export const selectRawSearchTerm = (state: DataCenterState): string => {
  return state.dataCenter.aiAutocomplete.rawSearchTerm;
};

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

export const selectActiveView = (state: DataCenterState): EActiveView => {
  return state.dataCenter.aiAutocomplete.activeView;
};
