import createSvgIcon from '../createSvgIcon';

export const Batch = createSvgIcon(
  <svg width="19" height="19" viewBox="2 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9358 3.11108L4.125 7.4954V16.264L11.9358 20.6484L19.7467 16.264V7.4954L11.9358 3.11108Z"
      fill="#555F7C"
      stroke="#555F7C"
    />
  </svg>,
  'Batch'
);
