import { Stack, Typography } from '@mui/material';
import { SHARED_TEXT } from '../helpers/shared-text';
import { sourceNullState } from '@aiware/shared/assets';

const EmptyStatePullAndPush: React.FC<{
  activeTab: number;
}> = ({ activeTab }) => {
  return (
    <Stack sx={{ mt: 16, alignItems: 'center', justifyContent: 'center', gap: '10px' }} alignItems={'center'}>
      <img
        data-test="os-data-center-browse.select-import-source.engine-null-state"
        alt="error"
        src={sourceNullState}
      />
      <Typography variant={'body2'} sx={{ width: '205px', textAlign: 'center', fontWeight: 700, mt: 2 }}>
        {activeTab === 0 ? SHARED_TEXT.engineList.pullEmptyState() : SHARED_TEXT.engineList.pushEmptyState()}
      </Typography>
    </Stack>
  );
};

export default EmptyStatePullAndPush;
