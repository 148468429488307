import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  fileItemListContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: 300,
    overflowY: 'scroll',
    marginRight: 5,
    marginTop: 5,

    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D5DFE9',
      padding: 5,
      borderRadius: 50,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#919ca8',
    },
  },
  shadowGradient: {
    display: 'block',
    position: 'absolute',
    top: -1,
    left: 0,
    width: '100%',
    height: 28,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    transform: 'translateY(-100%)',
  },
  footerWrapper: {
    borderTop: '0.5px solid #D5DFE9',
    position: 'relative',
    padding: '13px 30px',
  },
  viewFormatsBtn: {
    cursor: 'pointer',
    color: '#6098D1',
    fontSize: 14,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  retryButton: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    fontSize: '12px!important',
    background: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    padding: '6px 15px!important',
  },
  refreshIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,

    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  addFilesIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  addFilesBtn: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  addFilesLabel: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  addIcon: {
    display: 'block',
    marginRight: theme.spacing(1),
  },
  filePickerInput: {
    display: 'none',
  },
}));
export default useStyles;
