import { userReducer } from './reducer';

export {
  getCurrentUser,
  resetBiometric,
  resetPassword,
  updateProfile,
  uploadAvatar,
  logoutUser,
  logoutUserSuccess,
  logoutUserFail,
  AuthStatus,
} from './action';
export { namespace, userSelector, currentUserSelector, userAuthStatusSelector } from './selector';

export default userReducer;
