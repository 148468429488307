import { call, put, select } from 'redux-saga/effects';
import { selectApiConfigs } from '../aiAutocomplete/aiAutocomplete.selectors';
import { actions } from './aiSearchFilter.slice';

import { getSourcesBySearchTerm } from '../../api/queries/getSourceSearch.query';

import { selectOffset, selectSearchTerm } from './aiSearchFilter.selectors';

export function* sourcesSearchTermSaga() {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const limit = 30;
    const offset: number = yield select(selectOffset);

    const searchTerm: string = yield select(selectSearchTerm);

    //@ts-ignore
    const sources = yield call(getSourcesBySearchTerm, apiConfigs, offset, searchTerm, limit);
    yield put(actions.sourcesSearchSucceeded(sources.records));
    if (sources.count === limit) {
      yield put(actions.setHasMore(true));
    } else {
      yield put(actions.setHasMore(false));
    }
    yield put(actions.setOffset(offset + limit));
  } catch (err) {
    yield put(actions.setError(err as Error));
  }
}
