import createSvgIcon from '../createSvgIcon';

export const SchemaFilled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F2F7FE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14.167C16.2721 14.167 13.25 17.1891 13.25 20.917C13.25 24.6449 16.2721 27.667 20 27.667C23.7279 27.667 26.75 24.6449 26.75 20.917C26.75 17.1891 23.7279 14.167 20 14.167ZM11.75 20.917C11.75 16.3606 15.4436 12.667 20 12.667C24.5563 12.667 28.25 16.3606 28.25 20.917C28.25 25.4733 24.5563 29.167 20 29.167C15.4436 29.167 11.75 25.4733 11.75 20.917Z"
      fill="#0D47A1"
    />
    <path
      d="M22.75 13.583C22.75 15.1018 21.5188 16.333 20 16.333C18.4812 16.333 17.25 15.1018 17.25 13.583C17.25 12.0642 18.4812 10.833 20 10.833C21.5188 10.833 22.75 12.0642 22.75 13.583Z"
      fill="#0D47A1"
    />
    <path
      d="M15.417 23.667C15.417 25.1858 14.1858 26.417 12.667 26.417C11.1482 26.417 9.91699 25.1858 9.91699 23.667C9.91699 22.1482 11.1482 20.917 12.667 20.917C14.1858 20.917 15.417 22.1482 15.417 23.667Z"
      fill="#0D47A1"
    />
    <path
      d="M30.083 23.667C30.083 25.1858 28.8518 26.417 27.333 26.417C25.8142 26.417 24.583 25.1858 24.583 23.667C24.583 22.1482 25.8142 20.917 27.333 20.917C28.8518 20.917 30.083 22.1482 30.083 23.667Z"
      fill="#0D47A1"
    />
  </svg>,
  'SchemaFilled'
);
