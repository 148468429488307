import { FunctionComponent, ChangeEvent, useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { UploadButton, uploadButtonStyles } from '../shared';
import useStyles from './useStyles';

type UploadHandler = (files: File[]) => void;

interface ILocalFileUpload {
  onUpload: UploadHandler;
  onViewSupportedFiles: () => void;
}

const LocalFileUpload: FunctionComponent<ILocalFileUpload> = ({ onUpload, onViewSupportedFiles }) => {
  const [focused, setFocused] = useState(false);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const { classes: uploadButtonClasses } = uploadButtonStyles();

  // handle dropping files after drag
  useEffect(() => {
    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      onUpload(Array.from(event.dataTransfer!.files));
      setFocused(false);
    };
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('drop', handleDrop);
    };
  }, [setFocused, onUpload]);

  // handle dragging files into the drop zone
  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      setFocused(true);
    };
    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      setFocused(false);
    };
    if (dropZoneRef.current) {
      dropZoneRef.current.addEventListener('dragover', handleDragOver);
      dropZoneRef.current.addEventListener('dragleave', handleDragLeave);
    }

    return () => {
      try {
        dropZoneRef.current!.removeEventListener('dragover', handleDragOver);
        dropZoneRef.current!.removeEventListener('dragleave', handleDragLeave);
      } catch (e) {
        // ts-ignore
      }
    };
  }, [setFocused]);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    onUpload(Array.from(event.target.files!));
  };

  return (
    <Card
      data-test="data-center-importer-local-upload-card"
      //@ts-ignore
      ref={(el: HTMLDivElement) => (dropZoneRef.current = el)}
      className={focused ? classes.focusedCard : classes.card}
      sx={{
        mt: 6,
      }}
    >
      <Avatar className={classes.iconCircle}>
        <UploadIcon fontSize="inherit" className={classes.uploadIcon} />
      </Avatar>
      <Typography variant="body1" className={classes.selectMessage}>
        <FormattedMessage
          id="os-data-center-importer.vKgOOW"
          defaultMessage="Select a file from your computer"
          description="Local Machine File Importer: Select a file from your computer to be uploaded into the Data Center"
        />
      </Typography>
      <Typography variant="body2" className={classes.subtitle}>
        <FormattedMessage
          id="os-data-center-importer.9cCClM"
          defaultMessage="or drag and drop your files here"
          description="Local Machine File Importer: files can be dragged and dropped here to upload them"
        />
      </Typography>
      <UploadButton
        id="local-file-upload-upload-btn"
        onUpload={handleUpload}
        render={({ handleButtonClick }) => (
          <Button
            data-test="data-center-importer-local-upload-button"
            variant="outlined"
            className={uploadButtonClasses.fileButton}
            style={{ marginTop: '15px' }}
            onClick={handleButtonClick}
          >
            <FormattedMessage
              id="os-data-center-importer.261ANF"
              defaultMessage="Browse Files"
              description="Local Machine File Importer: Click here to select the files to upload"
            />
          </Button>
        )}
      />

      <Button
        data-test="data-center-importer-local-supported-file-formats-button"
        variant="text"
        className={classes.fileFormatsButton}
        onClick={onViewSupportedFiles}
      >
        <FormattedMessage
          id="os-data-center-importer.OVCv+Y"
          defaultMessage="View Supported File Formats"
          description="Local Machine File Importer: View a list of all the File Formats that can be uploaded"
        />
      </Button>
    </Card>
  );
};

export default LocalFileUpload;
