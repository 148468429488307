import React, { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Box, Grid } from '@mui/material';
import { TableDebugMenu } from './table-debug-menu';
import { TableLoadingState, TableRowLoader } from './table-row-loader';
import { TableEmptyState } from './table-empty-state';
import { TableErrorState } from './table-error-state';
import { ITableInfiniteScrollProps } from '../types';
import debounce from 'lodash/debounce';
import { DEFAULT_HEADER_HEIGHT, TableHeader } from './table-header';

export function TableInfiniteScroll<T = any>({
  hasMore,
  fetchMore,
  fetchOnMount = false,
  offset,
  adjustTableHeight = 0,
  adjustTableWidth = 0,
  items,
  itemHeight,
  isLoading,
  error,
  onRetry,
  headerItems,
  headerHeight = 0,
  TableHeaderComponent,
  TableRowComponent,
  TableEmptyStateComponent,
  TableErrorStateComponent,
  TableRowLoadingComponent,
  sx = {},
  testId = '',
  showDebug = false,
  FixedSizeListProps = {},
  AutoSizerProps = {},
  InfiniteLoaderProps = {},
  ContainerProps = {},
  RowContainerProps = {},
  threshold = 15,
  className = '',
  MessageEmptyState,
  MessageErrorState,
  DescriptionEmptyState,
  DescriptionErrorState,
  CustomEmptyStateImage,
  listRef,
}: ITableInfiniteScrollProps<T>) {
  const infiniteLoaderRef = useRef<{ _listRef: FixedSizeList }>(null);
  const [currentScrollStartIndex, setCurrentScrollStartIndex] = useState(
    FixedSizeListProps?.initialScrollOffset ? FixedSizeListProps?.initialScrollOffset : 0
  );
  const itemCount = hasMore ? items.length + 1 : items.length;
  const isItemLoaded = (index: number) => !hasMore || index < items.length;

  const loadMoreItems = () => {
    if (isLoading) {
      // do nothing
      return;
    }
    fetchMore();
  };

  const handleRetry = () => {
    if (onRetry) {
      onRetry();
    } else {
      fetchMore();
    }
  };

  const handleScrollIndexChange = (value: number) => {
    setCurrentScrollStartIndex(value);
  };

  const debouncedHandleScrollIndexChange = debounce(handleScrollIndexChange, 500);

  const additionalOnItemsRendered = (index: number) => {
    if (!isLoading) {
      debouncedHandleScrollIndexChange(index);
    }
  };

  const TableRow: React.FC<{ index: number; style: React.CSSProperties; isScrolling: boolean }> = ({
    index,
    style,
    isScrolling,
  }) => {
    const item = items[index]!;

    if (!isItemLoaded(index)) {
      return (
        <Box
          data-testid={`sdk-table-infinite-scroller-row-loading-${index + 1}`}
          style={style}
          {...RowContainerProps}
        >
          <TableRowLoader height={itemHeight} LoadingComponent={TableRowLoadingComponent} />
        </Box>
      );
    } else {
      return (
        <Box
          key={index}
          data-testid={`sdk-table-infinite-scroller-row-${index + 1}`}
          style={style}
          {...RowContainerProps}
        >
          <TableRowComponent item={item} index={index} isScrolling={isScrolling} />
        </Box>
      );
    }
  };

  useEffect(() => {
    if (fetchOnMount) fetchMore();
  }, []);

  useEffect(() => {
    // Below is necessary to preserve the current scrolled position
    // when toggling itemHeight / Row sizes, for example fluffy -> condensed etc.
    const newIndex = currentScrollStartIndex;
    setTimeout(() => {
      return infiniteLoaderRef && infiniteLoaderRef.current?._listRef.scrollToItem(newIndex, 'start');
    }, 0);
  }, [itemHeight]);

  // Sync refs if one was passed in
  useEffect(() => {
    if (listRef) {
      (listRef as unknown as { current: FixedSizeList<any> | undefined }).current =
        infiniteLoaderRef.current?._listRef;
    }
  }, [infiniteLoaderRef.current?._listRef, listRef]);

  const RenderedTableHeader = headerItems ? (
    <TableHeader items={headerItems} testId={testId} height={headerHeight === 0 ? undefined : headerHeight} />
  ) : (
    TableHeaderComponent
  );

  const _adjustTableHeight = getAdjustedTableHeight(adjustTableHeight, headerHeight, !!headerItems);

  const DebugMenu = () => (
    <TableDebugMenu
      adjustTableHeight={_adjustTableHeight}
      adjustTableWidth={adjustTableWidth}
      hasMore={hasMore}
      hasHeader={!!RenderedTableHeader}
      isLoading={isLoading}
      items={items}
      itemHeight={itemHeight}
      fetchMore={fetchMore}
      offset={offset}
    />
  );

  // Error & Retry State
  if (error) {
    return (
      <>
        <TableErrorState
          ErrorStateComponent={TableErrorStateComponent}
          onRetry={handleRetry}
          CustomMessage={MessageErrorState}
          CustomDescription={DescriptionErrorState}
        />
        {showDebug && <DebugMenu />}
      </>
    );
  }

  // Loading State
  if (isLoading && !error && items.length === 0) {
    return <TableLoadingState itemHeight={itemHeight} LoadingComponent={TableRowLoadingComponent} />;
  }

  // Empty State
  if (!isLoading && !error && items.length === 0) {
    return (
      <>
        <TableEmptyState
          EmptyStateComponent={TableEmptyStateComponent}
          CustomMessage={MessageEmptyState}
          CustomDescription={DescriptionEmptyState}
          CustomEmptyStateImage={CustomEmptyStateImage}
        />
        {showDebug && <DebugMenu />}
      </>
    );
  }

  return (
    <Grid
      data-testid={testId ? testId : 'sdk-table-infinite-scroll-container'}
      sx={{ position: 'relative', height: '100%', overflow: 'hidden', ...sx }}
      className={className}
      {...ContainerProps}
    >
      {RenderedTableHeader && <>{RenderedTableHeader}</>}
      <AutoSizer {...AutoSizerProps}>
        {({ height, width }: { height: number; width: number }) => {
          return (
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              threshold={threshold}
              {...InfiniteLoaderProps}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  ref={ref}
                  height={height + _adjustTableHeight}
                  width={width + adjustTableWidth}
                  itemCount={items.length}
                  itemSize={itemHeight}
                  onItemsRendered={props => {
                    onItemsRendered(props);
                    additionalOnItemsRendered(props.visibleStartIndex);
                  }}
                  initialScrollOffset={0}
                  {...FixedSizeListProps}
                >
                  {TableRow}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
      {showDebug && <DebugMenu />}
    </Grid>
  );
}

function getAdjustedTableHeight(
  adjustTableHeight: number,
  headerHeight: number,
  isHeaderItemsProvided: boolean
) {
  let result = 0;

  if (adjustTableHeight) {
    result = adjustTableHeight;
  }

  if (headerHeight) {
    result = -1 * headerHeight;
  }

  if (isHeaderItemsProvided && !headerHeight) {
    result = -1 * DEFAULT_HEADER_HEIGHT;
  }
  return result;
}
