import { IUser } from '@aiware/js/interfaces';
import { TUserGroup } from '@aiware/os/admin-center/groups';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SLICE_NAME,
  TState,
  TGetACLsInput,
  TAuthACE,
  GraphQLPage,
  TAddACLsInput,
  IMemberType,
  TFunctionalPermission,
  TResource,
  TPermissionSet,
  TPermissionSetInput,
  TAuthPermissionSetUpdateInput,
} from '../../types';

export const namespace = 'adminCenterPermissions';

export const initialState: TState = {
  selectedResource: null,
  status: 'idle',
  foundMembers: [],
  permissions: [],
  permissionSets: null,
  permissionSetsLoadingStatus: 'idle',
  permissionSetsPagination: {
    hasMore: true,
    offset: 0,
  },
  accessControlLoadingStatus: 'idle',
  accessControlPagination: {
    hasMore: true,
    offset: 0,
  },
  orgAccessControls: [],
  functionalPermissions: {},
  // this mapping it to help convert OLP permissions to the "entity:action" format supported by the backend
  functionalPermissionsMapping: {},
};

export const adminCenterSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchResoucesACLStart(state, action: PayloadAction<TGetACLsInput>) {
      state.status = 'loading';
    },
    fetchResoucesACLSuccess(state, action: PayloadAction<GraphQLPage<TAuthACE>>) {
      state.status = 'success';
      state.selectedResource!.records! = action.payload.records;
    },
    fetchResoucesACLFailure(state) {
      state.status = 'failure';
    },
    addACLsToResoucesStart(state, action: PayloadAction<TAddACLsInput>) {
      state.status = 'loading';
    },
    addACLsToResoucesSuccess(state, action: PayloadAction<GraphQLPage<TAuthACE>>) {
      state.status = 'success';
      state.selectedResource!.records! = action.payload.records;
    },
    addACLsToResoucesFailure(state) {
      state.status = 'failure';
    },
    removeACLsFromResoucesStart(state, action: PayloadAction<Pick<TAddACLsInput, 'ids' | 'type'>>) {
      state.status = 'loading';
    },
    removeACLsFromResoucesSuccess(state, action: PayloadAction<GraphQLPage<TAuthACE>>) {
      state.status = 'success';
      state.selectedResource!.records! = action.payload.records;
    },
    removeACLsFromResoucesFailure(state) {
      state.status = 'failure';
    },
    setSelectedResource(state, action: PayloadAction<Partial<TResource> | null>) {
      state.selectedResource = action.payload;
    },
    lookupMembersStart(state, action: PayloadAction<string>) {
      state.status = 'loading';
    },
    lookupMembersSuccess(state, action: PayloadAction<(TUserGroup & IUser & IMemberType)[]>) {
      state.status = 'success';
      state.foundMembers = action.payload;
    },
    lookupMembersFailure(state) {
      state.status = 'failure';
    },
    fetchOrgFuncPermissionsStart(state) {
      state.status = 'loading';
    },
    fetchOrgFuncPermissionsSuccess(state, action: PayloadAction<GraphQLPage<TFunctionalPermission>>) {
      const accessPermissions = action.payload.records.filter(
        permission =>
          permission.permissionName.includes('tdo') || permission.permissionName.includes('folder')
      );
      state.status = 'success';
      state.permissions = accessPermissions;
    },
    fetchOrgFuncPermissionsFailure(state) {
      state.status = 'failure';
    },
    changeACLsOnResoucesStart(
      state,
      action: PayloadAction<{ remove: Pick<TAddACLsInput, 'ids'>; add: TAddACLsInput }>
    ) {
      state.status = 'loading';
    },
    changeACLsOnResoucesSuccess(state, action: PayloadAction<GraphQLPage<TAuthACE>>) {
      state.status = 'success';
      state.selectedResource!.records! = action.payload.records;
    },
    changeACLsOnResoucesFailure(state) {
      state.status = 'failure';
    },
    fetchOrgPermissionSetsStart(state) {
      state.permissionSetsLoadingStatus = 'loading';
    },
    fetchOrgPermissionSetsSuccess(
      state,
      action: PayloadAction<{
        permissionSets: TPermissionSet[];
        hasMore: boolean;
        offset: number;
      }>
    ) {
      const { permissionSets, hasMore, offset } = action.payload;
      state.status = 'success';
      state.permissionSetsLoadingStatus = 'success';
      state.permissionSets = permissionSets;
      state.permissionSetsPagination = {
        hasMore,
        offset,
      };
    },
    fetchOrgPermissionSetsReset(state) {
      state.permissionSetsLoadingStatus = initialState.permissionSetsLoadingStatus;
      state.permissionSets = initialState.permissionSets;
      state.permissionSetsPagination = initialState.permissionSetsPagination;
    },
    fetchOrgPermissionSetsFailure(state) {
      state.permissionSetsLoadingStatus = 'failure';
    },
    resetLoadingState(state) {
      state.status = 'success';
      state.permissionSetsLoadingStatus = 'success';
    },
    createPermissionSetStart(state, action: PayloadAction<TPermissionSetInput>) {
      state.status = 'loading';
    },
    createPermissionSetSuccess(state, action: PayloadAction<TPermissionSet>) {
      state.status = 'success';
      if (!state.permissionSets) {
        state.permissionSets = [];
      }
      state.permissionSets.push(action.payload);
    },
    createPermissionSetFailure(state) {
      state.status = 'failure';
    },
    fetchOrgAccessControlStart(state, action: PayloadAction<number | undefined>) {
      state.accessControlLoadingStatus = 'loading';
    },
    fetchOrgAccessControlSuccess(
      state,
      action: PayloadAction<{
        accessControls: GraphQLPage<TAuthACE>;
        hasMore: boolean;
        offset: number;
      }>
    ) {
      const { accessControls, hasMore, offset } = action.payload;
      state.accessControlLoadingStatus = 'success';
      state.orgAccessControls = [...state.orgAccessControls, ...accessControls.records];
      state.accessControlPagination = {
        hasMore,
        offset,
      };
    },
    fetchOrgAccessControlFailure(state) {
      state.accessControlLoadingStatus = 'failure';
    },
    fetchOrgAccessControlReset(state) {
      state.accessControlLoadingStatus = 'success';
    },
    resetOrgAccessControl(state) {
      state.orgAccessControls = [];
      state.accessControlPagination = {
        hasMore: true,
        offset: 0,
      };
    },
    refreshAccessControls(state) {
      state.orgAccessControls = [];
      state.accessControlPagination = {
        hasMore: true,
        offset: 0,
      };
    },
    updatePermissionSetStart(state, _action: PayloadAction<TAuthPermissionSetUpdateInput>) {
      state.permissionSetsLoadingStatus = 'loading';
    },
    updatePermissionSetSuccess(state, action: PayloadAction<TPermissionSet>) {
      state.permissionSetsLoadingStatus = 'success';
      const updatedSetIndex = state.permissionSets?.findIndex(pS => pS.id === action.payload.id);
      if (updatedSetIndex !== -1 && state.permissionSets) {
        state.permissionSets[updatedSetIndex!] = action.payload;
      }
    },
    updatePermissionSetFailure(state) {
      state.permissionSetsLoadingStatus = 'failure';
    },
    fetchFunctionalPermissionsStart(state) {
      state.status = 'loading';
    },
    fetchFunctionalPermissionsSuccess(state, action: PayloadAction<string[]>) {
      state.status = 'success';
      const formattedFuncPermissions: { [key: string]: string } = {};
      const formattedFuncPermissionsMapping: { [key: string]: string } = {};
      action.payload.forEach(permission => {
        const value = permission.substring(0, 1) + permission.substring(1).toLowerCase().replace(/_/g, ':');
        formattedFuncPermissions[permission] = value;
        formattedFuncPermissionsMapping[value] = permission;
      });
      state.functionalPermissions = formattedFuncPermissions;
      state.functionalPermissionsMapping = formattedFuncPermissionsMapping;
    },
    fetchFunctionalPermissionsFailure(state) {
      state.status = 'failure';
    },
  },
});

export const actions = adminCenterSlice.actions;
export default adminCenterSlice.reducer;
