import { ISagaModule } from 'redux-dynamic-modules-saga';
import { combineReducers } from 'redux';
import { store } from '@aiware/shared/store';
import { nameSpace } from './reducer';
import rootSaga from './rootSaga';
import userReducer, { getCurrentUser } from './modules/user';
import activityReducer, { getActivity } from './modules/activity';
import applicationReducer, { requestGetApplications } from './modules/application';

export function getAppbarPanelModule(): ISagaModule<unknown> {
  return {
    id: 'os-appbar-panel',
    reducerMap: {
      [nameSpace]: combineReducers({
        users: userReducer,
        activities: activityReducer,
        applications: applicationReducer,
      }),
    },
    sagas: [rootSaga],
    initialActions: [getActivity(), getCurrentUser(), requestGetApplications()],
    retained: true,
  };
}

export default store;
export { selectAppFromStore } from './modules/application';
export { logoutUser } from './modules/user';
