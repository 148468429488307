import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createNewFolder,
  createNewFolderInCurrentParent,
  deleteTdo,
  editFolder,
  fetchFolderOrFiles,
  fetchRootFolder,
  fetchSchedules,
  fetchSources,
  fetchTdos,
  initDataCenter,
  openAddNewFolder,
  processTdo,
  viewTdoInfo,
  moveTdo,
  moveTdoNewFolder,
  moveMultipleTDOsNewFolder,
  returnFoldersAndFilesPanelResult,
  fetchFileInfo,
  editSourceInfo,
  editScheduleInfo,
  downloadTdo,
  editTdoMetadata,
  updateTdoChannel,
  handleUpdateTdoChannel,
  viewSourceInfo,
  viewScheduleInfo,
  activateSchedule,
  deactivateSchedule,
} from './browse.saga';
import { actions } from '../slices';

export function* watchDataCenterInit() {
  yield takeLatest(actions.uiState.init.type, initDataCenter);
}

export function* watchFetchSources() {
  yield takeEvery(actions.viewStreams.sourcesFetchStart.type, fetchSources);
}

export function* watchFetchSchedules() {
  yield takeEvery(actions.viewStreams.schedulesFetchStart.type, fetchSchedules);
}

export function* watchFetchTdos() {
  yield takeEvery(actions.viewStreams.tdosFetchStart.type, fetchTdos);
}

export function* watchFetchRootFolder() {
  yield takeEvery(actions.viewMyFiles.rootFolderFetchStart.type, fetchRootFolder);
}

export function* watchFetchFolderOrFiles() {
  yield takeEvery(actions.viewMyFiles.foldersOrFilesFetchStart.type, fetchFolderOrFiles);
}

export function* watchCreateNewFolderInCurrentParent() {
  yield takeEvery(actions.panelAddFolder.createNewFolderInCurrentParent.type, createNewFolderInCurrentParent);
}

export function* watchOpenAddNewFolder() {
  yield takeEvery('dataCenter/ADD_NEW_FOLDER', openAddNewFolder);
}

export function* watchCreateNewFolder() {
  yield takeEvery(actions.panelAddFolder.createNewFolderStart.type, createNewFolder);
}

export function* watchEditFolder() {
  yield takeEvery(actions.panelEditFolder.editFolderStart.type, editFolder);
}

export function* watchDeleteTdo() {
  yield takeEvery(actions.tdos.deleteTdoStart.type, deleteTdo);
}

export function* watchMoveTdo() {
  yield takeEvery(actions.tdos.moveTdoStart.type, moveTdo);
}

export function* watchMoveTdoNewFolder() {
  yield takeEvery(actions.tdos.moveTdoNewFolder.type, moveTdoNewFolder);
}

export function* watchMoveMultipleTDOsNewFolder() {
  yield takeEvery(actions.tdos.moveMultipleTDOsNewFolder.type, moveMultipleTDOsNewFolder);
}

export function* watchProcessTdo() {
  yield takeEvery(actions.tdos.process.type, processTdo);
}

export function* watchViewTdoInfo() {
  yield takeEvery(actions.tdos.viewInfo.type, viewTdoInfo);
}

export function* watchEditTdoMetadata() {
  yield takeEvery(actions.tdos.editMetadata.type, editTdoMetadata);
}

export function* watchDownloadTdo() {
  yield takeEvery(actions.tdos.download.type, downloadTdo);
}

export function* watchEditSourceInfo() {
  yield takeEvery(actions.sources.editSource.type, editSourceInfo);
}

export function* watchViewSourceInfo() {
  yield takeEvery(actions.sources.viewSource.type, viewSourceInfo);
}
export function* watchViewScheduleInfo() {
  yield takeEvery(actions.schedules.viewSchedule.type, viewScheduleInfo);
}

export function* watchEditScheduleInfo() {
  yield takeEvery(actions.schedules.editSchedule.type, editScheduleInfo);
}
export function* watchActivateSchedule() {
  yield takeEvery(actions.schedules.activateSchedule.type, activateSchedule);
}
export function* watchDeactivateSchedule() {
  yield takeEvery(actions.schedules.deactivateSchedule.type, deactivateSchedule);
}

export function* watchFoldersAndFilesPanelResult() {
  yield takeEvery(actions.viewMyFiles.panelResultSelected.type, returnFoldersAndFilesPanelResult);
}

export function* watchFileInfo() {
  yield takeEvery(actions.fileInfo.fetchFileInfoStart.type, fetchFileInfo);
}

export function* watchUpdateTdoChannel() {
  yield takeEvery(updateTdoChannel, handleUpdateTdoChannel);
}
