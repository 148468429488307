import createSvgIcon from '../createSvgIcon';
export const ScheduleJob = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1923 21.5001C15.9436 21.5001 14.8814 21.0623 14.0058 20.1866C13.1301 19.311 12.6923 18.2488 12.6923 17.0001C12.6923 15.7514 13.1301 14.6892 14.0058 13.8136C14.8814 12.938 15.9436 12.5001 17.1923 12.5001C18.441 12.5001 19.5032 12.938 20.3788 13.8136C21.2544 14.6892 21.6922 15.7514 21.6922 17.0001C21.6922 18.2488 21.2544 19.311 20.3788 20.1866C19.5032 21.0623 18.441 21.5001 17.1923 21.5001ZM18.8577 19.2886L19.4807 18.6655L17.6346 16.8193V14.0578H16.75V17.1809L18.8577 19.2886ZM5.3077 20.5001C4.80898 20.5001 4.38302 20.3235 4.02982 19.9703C3.67661 19.6171 3.5 19.1911 3.5 18.6924V5.30784C3.5 4.80912 3.67661 4.38316 4.02982 4.02996C4.38302 3.67674 4.80898 3.50014 5.3077 3.50014H9.71348C9.85194 3.01297 10.135 2.60914 10.5625 2.28864C10.9901 1.96812 11.4692 1.80786 12 1.80786C12.5512 1.80786 13.0381 1.96812 13.4605 2.28864C13.883 2.60914 14.1634 3.01297 14.3019 3.50014H18.6922C19.191 3.50014 19.6169 3.67674 19.9701 4.02996C20.3233 4.38316 20.5 4.80912 20.5 5.30784V11.4424C20.2641 11.2899 20.0243 11.1594 19.7807 11.0511C19.5371 10.9428 19.2769 10.8463 19 10.7617V5.30784C19 5.2309 18.9679 5.16038 18.9038 5.09626C18.8397 5.03216 18.7692 5.00011 18.6922 5.00011H16.5V7.61546H7.5V5.00011H5.3077C5.23077 5.00011 5.16024 5.03216 5.09612 5.09626C5.03202 5.16038 4.99997 5.2309 4.99997 5.30784V18.6924C4.99997 18.7821 5.02882 18.8559 5.08652 18.9136C5.14422 18.9713 5.21795 19.0001 5.3077 19.0001H11.0212C11.1058 19.277 11.2039 19.5389 11.3154 19.7857C11.4269 20.0325 11.5654 20.2706 11.7308 20.5001H5.3077ZM12 5.11551C12.2577 5.11551 12.4727 5.0293 12.6452 4.85686C12.8176 4.68443 12.9038 4.46936 12.9038 4.21166C12.9038 3.95398 12.8176 3.73892 12.6452 3.56649C12.4727 3.39405 12.2577 3.30784 12 3.30784C11.7423 3.30784 11.5272 3.39405 11.3548 3.56649C11.1823 3.73892 11.0961 3.95398 11.0961 4.21166C11.0961 4.46936 11.1823 4.68443 11.3548 4.85686C11.5272 5.0293 11.7423 5.11551 12 5.11551Z"
      fill="#555F7C"
    />
  </svg>,
  'ScheduleJob'
);
