import { makeStyles } from 'tss-react/mui';
import { Header } from './Header';
import { Thumbnail } from './Thumbnail';
import { TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../redux/slices/edit-metadata';
import { ChangeEvent, useEffect, useState } from 'react';
import { selectFilename, selectLoadingStatus, selectTags } from '../../../../redux/selectors/edit-metadata';
import { Controls } from './Controls';
import { SHARED_TEXT } from '../helpers/shared-text';
import { LoadingState } from './LoadingState';
import { ErrorState } from './ErrorState';
import { ITdo } from '../../../../types';

const useStyles = makeStyles()(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '64px',
  },
  content: {
    padding: theme.spacing(2, 7, 4, 7),
    flexGrow: 1,
  },
  editableItem: {
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

export const MainLayout = ({ tdoId, onSave }: { tdoId: string; onSave?: (tdo: ITdo) => void }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const filename = useSelector(selectFilename);
  const tagsExisting = useSelector(selectTags);
  const loadingStatus = useSelector(selectLoadingStatus);
  const [tags, setTags] = useState(tagsExisting.join(', '));

  // fetch TDO info on mount
  useEffect(() => {
    if (tdoId) {
      dispatch(actions.fetchTdoStart(tdoId));
    }
  }, [tdoId, dispatch]);

  useEffect(() => {
    setTags(tagsExisting.join(', '));
  }, [tagsExisting]);

  const handleNameChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    dispatch(actions.setFilename(e.target.value));
  };

  const handleTagsChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    setTags(e.target.value);
  };

  const handleBlurTagsChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(actions.setTags(e.target.value.split(',').map(tag => tag.trim())));
  };

  return (
    <div className={classes.container}>
      <Header />
      {loadingStatus !== 'success' && loadingStatus !== 'failure' && <LoadingState />}
      {loadingStatus === 'failure' && <ErrorState />}
      {loadingStatus === 'success' && (
        <div className={classes.content}>
          <div className={classes.editableItem}>
            <Thumbnail />
          </div>
          <div className={classes.editableItem}>
            <TextField
              label={SHARED_TEXT.fields.filename.label()}
              variant="outlined"
              onChange={handleNameChange}
              value={filename}
              fullWidth
            />
          </div>
          {/* <div className={classes.editableItem}>
                        <Typography variant="body2">{SHARED_TEXT.fields.time.label()}</Typography>
                        <Typography variant="subtitle1">{SHARED_TEXT.fields.time.description()}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker value={dateTime} onChange={(newDate) => newDate && setDateTime(newDate)} renderInput={(props) => <TextField {...props} />} />
                        </LocalizationProvider>
                    </div> */}
          <div className={classes.editableItem}>
            <Typography variant="body2">{SHARED_TEXT.fields.tags.label()}</Typography>
            <Typography variant="subtitle1">{SHARED_TEXT.fields.tags.description()}</Typography>
            <TextField
              label="Tags"
              variant="outlined"
              onChange={handleTagsChange}
              onBlur={handleBlurTagsChange}
              fullWidth
              value={tags}
              sx={theme => ({
                margin: theme.spacing(4, 0, 0, 0),
              })}
            />
          </div>
        </div>
      )}
      <Controls onSave={onSave} />
    </div>
  );
};
