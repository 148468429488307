import { slice, uniq } from '@aiware/js/function';
import {
  getApplicationsApi,
  getApplicationLogo,
  rootApiSelector,
  switchApiSelector,
  sessionTokenSelector,
  graphEndpointSelector,
  getConfigSuccess,
} from '@aiware/shared/redux';
import { all, call, put, select, takeEvery, takeLatest, take } from 'redux-saga/effects';
import { userSelector } from '../user';
import * as actions from './action';

interface IApp {
  applicationId: string;
  applicationName: string;
  applicationKey: string;
  signedApplicationIconUrl: string;
  applicationIconUrl: string;
  applicationIconSvg: string;
  applicationDescription: string;
  headerbarEnabled?: boolean;
  applicationUrl?: string;
}

// we cannot define typeof action
// eslint-disable-next-line @typescript-eslint/no-explicit-any
//TODO need to remove this one
export function* handleClickAppSaga(action: any) {
  const id = action.payload;
  yield call(storeRecentApplications, id);
}

type TDynamicObject = {
  [x: string]: any;
};

interface IApplications {
  from?: number;
  to?: number;
  totalResults?: number;
  results?: IApp[];
}

export function* getApplicationsSaga() {
  const baseUrl: string = yield select(rootApiSelector);
  const token: string = yield select(sessionTokenSelector);
  if (!baseUrl) {
    yield take(getConfigSuccess.type);
    yield put(actions.requestGetApplications());
  } else {
    yield put(actions.getApplicationStart());
    //Rest api response
    const CHUNK_SIZE = 200;
    let offset = 0;
    const limit = CHUNK_SIZE;
    let data: IApplications = { totalResults: 999, results: [] };
    let chunk: IApplications = { results: [{ applicationId: 'init value for while loop' } as IApp] };
    while ((data?.results?.length || 0) < (data?.totalResults || 0) && chunk?.results?.length) {
      chunk = yield call(getApplicationsApi, baseUrl, token, offset, limit);
      if (!data?.to) {
        data = chunk;
      } else if (data?.results?.length) {
        data.results = data.results.concat(chunk.results || []);
      }
      offset += CHUNK_SIZE;
    }

    const graphEndpoint: string = yield select(graphEndpointSelector);
    //Graphql api response
    const applicationLogo: TDynamicObject = yield call(getApplicationLogo, graphEndpoint, token);
    const applicationRecords = applicationLogo?.data?.applications?.records || [];
    const result = applicationRecords.reduce((obj: any, cur: any) => ({ ...obj, [cur.id]: cur }), {});

    if (!data || !data.results) {
      yield put(actions.getApplicationError('something wrong'));
    } else {
      const applicationsData = (data?.results || []).map((item: IApp) => {
        // signedIconUrl is signed for some apps and unsigned for others
        let signedIconUrl: string = result[item.applicationId]?.signedIconUrl ?? '';
        const isPresignedUrl = /([?&])X-Amz-Signature=/.test(signedIconUrl);

        signedIconUrl = isPresignedUrl ? signedIconUrl : handleUnsignedUrl(signedIconUrl);
        signedIconUrl = signedIconUrl.replace(/&amp;/g, '&');
        signedIconUrl = signedIconUrl.replace(/host\?/g, 'host&');

        return {
          id: item.applicationId,
          name: item.applicationName,
          key: item.applicationKey,
          iconUrl:
            signedIconUrl ||
            result[item.applicationId]?.signedIconSvg ||
            item.applicationIconUrl ||
            item.applicationIconSvg ||
            item.signedApplicationIconUrl,
          description: item.applicationDescription,
          headerbarEnabled: item?.headerbarEnabled || false,
          applicationUrl: item?.applicationUrl || '',
        };
      });
      yield put(actions.getApplicationSuccess(applicationsData));
    }
  }
}
//TODO Need to remove this one
export function* storeRecentApplications(id: string) {
  const user: TDynamicObject = yield select(userSelector);
  const userId = user?.currentUser?.id || '';
  const recentApplications = JSON.parse(localStorage.getItem('recentApplicationWithUserId') || '{}');
  const recentApplicationIds = recentApplications?.[userId] || [];
  // TODO have to use the new one
  if (recentApplicationIds) {
    const newIds = slice(uniq([id, ...recentApplicationIds]), 0, 6);
    localStorage.setItem(
      'recentApplicationWithUserId',
      JSON.stringify({
        ...recentApplications,
        [userId]: newIds,
      })
    );
  } else {
    localStorage.setItem(
      'recentApplicationWithUserId',
      JSON.stringify({
        ...recentApplications,
        [userId]: [id],
      })
    );
  }

  const switchAppUrl: string = yield select(switchApiSelector);
  window.open(switchAppUrl + '/' + id);
}

export default function* applicationSaga() {
  yield all([
    //TODO Need to remove this one
    takeEvery(actions.HANDLE_CLICK_APP, handleClickAppSaga),
    takeLatest(actions.REQUEST_GET_APPLICATION_V1, getApplicationsSaga),
  ]);
}

function isEncodedUrl(url: string) {
  return /%[0-9A-F]{2}/i.test(url);
}

function handleUnsignedUrl(url: string): string {
  return isEncodedUrl(url) ? decodeURIComponent(decodeURI(url)) : url;
}
