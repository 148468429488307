import { baseGraphQLApiWithError, selectApiConfigs } from '@aiware/shared/redux';
import { TGraphQLPage } from '@aiware/js/interfaces';
import {
  TDataRegistriesQueryInput,
  TDataRegistry,
  queryInputGqlTyping,
  TStructuredDataObject,
} from '../types';

export async function fetchDataRegistries(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  queryParams?: TDataRegistriesQueryInput
): Promise<TGraphQLPage<TDataRegistry>> {
  let typedParams = '';
  let assembledParams = '';

  if (queryParams) {
    Object.keys(queryParams).forEach(key => {
      typedParams += `$${key}: ${queryInputGqlTyping[key as keyof typeof queryInputGqlTyping]} `;
      assembledParams += `${key}: $${key} `;
    });
    typedParams = `(${typedParams})`;
    assembledParams = `(${assembledParams})`;
  }
  // TODO: assemble query params
  const operationName = 'fetchDataRegistries';
  const name = 'dataRegistries';
  const query = `
    query ${operationName} ${typedParams} {
      ${name} ${assembledParams} {
        records {
            id
            name
            description
            source
            schemas {
              records {
                id
                definition
                majorVersion
                minorVersion
                status
                validActions
                organizationId
              }
            }
            organizationId
            organization {
              id
              name
              guid
            }
            createdBy {
              id
              name
            }
            modifiedBy {
              id
              name
            }
            createdDateTime
            modifiedDateTime
            publishedSchema {
              id
              definition
              majorVersion
              minorVersion
              status
              validActions
              organizationId
            }
            ingestionToken
            isPublic
          }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: TGraphQLPage<TDataRegistry>;
  }>({
    query,
    operationName,
    ...apiConfigs,
    variables: queryParams,
  });

  return result[name];
}

interface IFetchStructuredDataObjectsParams {
  apiConfigs: ReturnType<typeof selectApiConfigs>;
  limit: number;
  offset: number;
  additionalSelectors?: { [key: string]: unknown };
}

export async function fetchStructuredDataObjects({
  apiConfigs,
  limit,
  offset,
  additionalSelectors,
}: IFetchStructuredDataObjectsParams): Promise<TStructuredDataObject[]> {
  let schemaId = '';

  if (additionalSelectors!['schemaId']) {
    schemaId = additionalSelectors!['schemaId'] as string;
  }

  const operationName = 'fetchStructuredData';
  const name = 'structuredDataObjects';
  const query = `
    query ${operationName} {
      ${name} (limit: ${limit}, offset: ${offset}, schemaId: "${schemaId}"){
        records {
          id
          data
          createdDateTime
          modifiedDateTime
          dataString
          schemaId
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: TGraphQLPage<TStructuredDataObject>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });

  return result[name]?.records || [];
}
