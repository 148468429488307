import createSvgIcon from '../createSvgIcon';
export const ProcessingDetail = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="inherit"
      d="M6 17V12H11V17H6ZM7 13V16H10V13H7ZM13 6.5H18V8.5H13V6.5ZM5 20C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V4C3 3.46957 3.21071 2.96086 3.58579 2.58579C3.96086 2.21071 4.46957 2 5 2H19C19.5304 2 20.0391 2.21071 20.4142 2.58579C20.7893 2.96086 21 3.46957 21 4V10.17C20.5 10.06 20 10 19.5 10H19V4H5V18H13.17C13.34 18.72 13.63 19.39 14 20H5ZM11 5V10H6V5H11ZM10 9V6H7V9H10ZM19 11V12.5C20.0609 12.5 21.0783 12.9214 21.8284 13.6716C22.5786 14.4217 23 15.4391 23 16.5C23 17.32 22.75 18.08 22.33 18.71L21.24 17.62C21.41 17.28 21.5 16.9 21.5 16.5C21.5 15.837 21.2366 15.2011 20.7678 14.7322C20.2989 14.2634 19.663 14 19 14V15.5L16.75 13.25L19 11ZM19 22V20.5C17.9391 20.5 16.9217 20.0786 16.1716 19.3284C15.4214 18.5783 15 17.5609 15 16.5C15 15.68 15.25 14.92 15.67 14.29L16.76 15.38C16.59 15.72 16.5 16.1 16.5 16.5C16.5 17.163 16.7634 17.7989 17.2322 18.2678C17.7011 18.7366 18.337 19 19 19V17.5L21.25 19.75L19 22Z"
    />
  </svg>,
  'ProcessingDetail'
);
