import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import { InfiniteWrapperItemType } from '../../../types';
import { selectIsFluffy } from '../../../redux/selectors/ui-state';
import SourcesRows from './sources/SourcesRows';
import SchedulesRows from './schedules/SchedulesRows';
import TdoRows from './tdos/TdoRows';

const SourcesSchedulesTdoRowGen: FunctionComponent<{
  index: number;
  items: InfiniteWrapperItemType;
  rowHeight: number;
  rowType: EntityType;
}> = ({ index, items, rowHeight, rowType }) => {
  const isFluffy = useSelector(selectIsFluffy);

  const row = () => {
    if (rowType === EntityType.Sources) {
      return <SourcesRows index={index} items={items} rowHeight={rowHeight} isFluffy={isFluffy} />;
    }
    if (rowType === EntityType.Schedules) {
      return <SchedulesRows index={index} items={items} rowHeight={rowHeight} isFluffy={isFluffy} />;
    }
    if (rowType === EntityType.Tdos) {
      return <TdoRows index={index} items={items} rowHeight={rowHeight} isFluffy={isFluffy} />;
    }
  };
  return <>{row()}</>;
};

export default SourcesSchedulesTdoRowGen;
