import { call, put, select, take } from 'redux-saga/effects';
import {
  selectApiConfigs,
  selectIsOLPEnabled,
  graphEndpointSelector,
  sessionTokenSelector,
} from '@aiware/shared/redux';

import { actions } from '../';
import * as api from '../../api';
import { selectMyRights, selectPermissionsByEntity } from '../selectors';
import { TPermissionsEntity } from '../slices';
import { EPermissionAction, EAuthSubResourceType, TPermissions, EAuthResourceType } from '../../types';
import { convertPermissionsToRights, getPermissionTypesToQuery } from '../../lib/permissionsHelper';

/**
 * When OLP is enabled, most object's still aren't actually supported. The logic below forces
 * specific EAuthResourceTypes to use Rights instead of Object Level Permissions even if OLP
 * is turned on for the org, unless they appear in this list. Add a new EAuthResourceType to
 * this array when it gets support from GraphQL. OBSOTOLP...BSAJRI
 */
const olpAllowedResourceTypes = [EAuthResourceType.Folder, EAuthResourceType.TDO];

export function* fetchObjectPermissions(action: ReturnType<typeof actions.fetchObjectPermissionsStart>) {
  const { entityId, entityType } = action.payload;
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const graphEndpoint: string = yield select(graphEndpointSelector);
  const token: string = yield select(sessionTokenSelector);
  const isOLPEnabled: boolean = yield select(selectIsOLPEnabled);
  const existingPermissions: TPermissionsEntity = yield select(
    selectPermissionsByEntity(entityType, entityId)
  );
  if (
    !existingPermissions ||
    (existingPermissions.status !== 'success' && existingPermissions.status !== 'pending')
  ) {
    yield put(
      actions.fetchObjectPermissionsStarted({
        entityId,
        entityType,
      })
    );
    try {
      let permissions: TPermissions = {};
      let source: 'OLP' | 'rights' = 'rights';
      if (isOLPEnabled && olpAllowedResourceTypes.includes(entityType)) {
        source = 'OLP';
        permissions = yield call(api.getObjectPermissions, graphEndpoint, token, {
          entityType,
          entityId,
        });
      } else {
        let existingRights: ReturnType<typeof selectMyRights> = yield select(selectMyRights);
        if (existingRights.status !== 'success' && existingRights.status !== 'pending') {
          yield put(actions.fetchMyRightsStarted());
          try {
            const rights: string[] = yield call(api.getMyRights, apiConfigs);
            yield put(actions.fetchMyRightsSucceeded(rights));
            // now reselect so we can have consistent logic below
            existingRights = yield select(selectMyRights);
          } catch (error) {
            yield put(actions.fetchMyRightsFailed);
            // rethrow for the nested try/catch below
            throw error;
          }
        } else if (existingRights.status === 'pending') {
          // wait for the call to finish and reselect
          yield take([actions.fetchMyRightsFailed, actions.fetchMyRightsSucceeded]);
          existingRights = yield select(selectMyRights);
        }
        const { myRights } = existingRights;
        const typesToQuery = getPermissionTypesToQuery(entityType);
        typesToQuery.forEach(type => {
          const { action, resource } = type.targetActions.reduce(
            (acc, current) => {
              (acc.action as any)[current.targetAction as EPermissionAction] = convertPermissionsToRights(
                current.resource ? (current.resource as EAuthSubResourceType) : type.targetObjectType,
                current.targetAction as EPermissionAction,
                myRights
              );
              acc.resource = current.resource as EAuthSubResourceType;
              return acc;
            },
            { action: {} } as { action: EPermissionAction; resource: EAuthSubResourceType | undefined }
          );
          permissions[resource || type.targetObjectType] = action;
        });
      }
      yield put(
        actions.fetchObjectPermissionsSucceeded({
          entityId,
          entityType,
          source,
          permissions,
        })
      );
    } catch (error) {
      yield put(
        actions.fetchObjectPermissionsFailed({
          entityId,
          entityType,
        })
      );
    }
  }
}
