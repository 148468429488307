import createSvgIcon from '../createSvgIcon';
export const Explore = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M16.5001 3.66671H1.83341V1.83337H16.5001V3.66671ZM11.9534 9.16671H2.78675L3.33675 6.41671H14.9967L15.3634 8.25004C16.0417 8.28671 16.6926 8.42421 17.3159 8.67171L16.5001 4.58337H1.83341L0.916748 9.16671V11H1.83341V16.5H9.62508C9.32258 15.7667 9.16675 14.9875 9.16675 14.2084V14.6667H3.66675V11H9.16675V14.2084C9.16675 12.6867 9.75341 11.1559 10.9084 9.99171C11.2384 9.67087 11.5867 9.39587 11.9534 9.16671ZM21.4409 19.25L20.1667 20.5242L17.3067 17.71C16.6742 18.1042 15.9226 18.3334 15.1251 18.3334C12.8334 18.3334 11.0001 16.5 11.0001 14.2084C11.0001 11.9167 12.8334 10.0834 15.1251 10.0834C17.4167 10.0834 19.2501 11.9167 19.2501 14.2084C19.2501 15.015 19.0209 15.7759 18.6176 16.4084L21.4409 19.25ZM17.4167 14.2084C17.4167 12.9434 16.3901 11.9167 15.1251 11.9167C13.8601 11.9167 12.8334 12.9434 12.8334 14.2084C12.8334 15.4734 13.8601 16.5 15.1251 16.5C16.3901 16.5 17.4167 15.4734 17.4167 14.2084Z"
      fill="#555F7C"
    />
  </svg>,
  'Explore'
);
