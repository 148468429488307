import { FC, useState } from 'react';
import { Box, Button, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import BasicTab from '../basic-tab';
import Permission from '../permission';
import styles from './panel-body.styles';
import useStepData, { BASIC_STEP, PERMISSION_STEP } from '../helpers/useStepData';
import { TPermissionSet } from '../../../types';

const steps: string[] = ['Basic', 'Permissions'];

interface StepProps {
  step: number;
  isValid: boolean;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
  onFinish: () => void;
}

const StepButtons: FC<StepProps> = ({ step, isValid, onCancel, onBack, onNext, onFinish }) => (
  <Box sx={styles['stepButtonsContainer']}>
    <Button
      color="inherit"
      onClick={() => {
        step === 0 ? onCancel() : onBack();
      }}
      sx={styles['stepButton']}
    >
      {step === 0 ? (
        <FormattedMessage
          id="os-admin-panel-basic-new-permission-set-cancel-button"
          defaultMessage="Cancel"
          description="Cancel button in New permission set panel"
        />
      ) : (
        <FormattedMessage
          id="os-admin-panel-basic.new-permission-set-back-button"
          defaultMessage="Back"
          description="Back button to previous step in New Permission set panel"
        />
      )}
    </Button>
    <Button
      color="primary"
      variant="contained"
      disabled={!isValid}
      onClick={() => {
        step === 0 ? onNext() : onFinish();
      }}
      sx={styles['stepButton']}
    >
      {step === 0 ? (
        <FormattedMessage
          id="os-admin-panel-new-permission-set.basic-next-button"
          defaultMessage="Next"
          description="Next button in New Permission set panel"
        />
      ) : (
        <FormattedMessage
          id="os-admin-panel-new-permission-set.save-button"
          defaultMessage="Save"
          description="Save button in New Permission set panel"
        />
      )}
    </Button>
  </Box>
);

interface PanelBodyProps {
  onCancel: () => void;
  onSave: (permissionSet: Partial<TPermissionSet>) => void;
  initPermissionSet: Partial<TPermissionSet>;
}

const PanelBody: FC<PanelBodyProps> = ({ onCancel, onSave, initPermissionSet }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { permissionSet, setName, setDescription, setFuncPermissions, validateStep } =
    useStepData(initPermissionSet);
  const [nameError, setNameError] = useState(false);

  const nameExistsCheck = (value: boolean) => {
    setNameError(value);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSave = () => {
    onSave(permissionSet);
  };

  return (
    <Box sx={styles['stepBodyContainer']} data-test="os-admin-center.permissions.new-permission-set.panel">
      <Stepper
        data-test="os-admin-center.permissions.new-permission-set.panel.stepper"
        activeStep={activeStep}
        connector={<StepConnector sx={styles['stepConnector']} />}
        sx={styles['stepper']}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label} sx={styles['step']}>
              <StepLabel
                StepIconProps={
                  index <= activeStep ? { sx: styles['basicLabel'] } : { sx: styles['permissionLabel'] }
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={styles['stepBody']} data-test="os-admin-center.permissions.new-permission-set.panel.step-body">
        {activeStep === BASIC_STEP && (
          <BasicTab
            permissionSet={permissionSet}
            onNameChange={setName}
            onDescriptionChange={setDescription}
            nameExistsCheck={nameExistsCheck}
          />
        )}
        {activeStep === PERMISSION_STEP && <Permission onPermissionChange={setFuncPermissions} />}
      </Box>
      <StepButtons
        data-test="os-admin-center.permissions.new-permission-set.panel.step-actions"
        step={activeStep}
        isValid={validateStep(activeStep) && !nameError}
        onCancel={onCancel}
        onBack={handleBack}
        onNext={handleNext}
        onFinish={handleSave}
      />
    </Box>
  );
};

export default PanelBody;
