import { IOrganization } from '@aiware/js/interfaces';
import { IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';
import { INotificationModule } from '@aiware/os/notification-panel';

export const namespace = 'users';

interface IRoot {
  // [key: string]: Array<unknown> | undefined | number;
  panels: Array<IVeritoneAppbarPanelConfig>;
}

interface IUser {
  aiWareHeaderBar: {
    currentUser: {
      organization: IOrganization;
    };
    appIcon: string;
  };
}

interface INotification {
  osNotificationPanel: INotificationModule;
}

export const userSelector = (state: IUser) => state?.aiWareHeaderBar;
export const rootSelector = (state: IRoot) => state;
export const notificationStatusSelector = (state: INotification) =>
  state?.osNotificationPanel ? state?.osNotificationPanel.status : '';
