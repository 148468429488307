import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  panelContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  panelHeaderContainer: {
    display: 'flex',
    width: '100%',
  },
  panelMainContentContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  panelFooterContainer: {
    width: '100%',
    alignSelf: 'flex-end',
    marginTop: 'auto',
  },
  panelButtons: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    Button: {
      color: theme.palette.grey[700],
    },
  },
}));
