import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    userSelect: 'none',
  },
  subheader: {
    userSelect: 'none',
  },
  locationFolderWrapper: {
    width: '100%',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    border: '1px solid #D5DFE9',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  locationFolderWrapperDisabled: {
    opacity: 0.7,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  spacer: {
    display: 'block',
    flex: 1,
  },
  folderIcon: {
    marginRight: theme.spacing(2),
  },
  folderLabel: {
    fontFamily: 'Nunito',
    display: 'block',
    color: '#5C6269',
    fontSize: 12,
    position: 'absolute',
    top: -10,
    left: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: '#fff',
  },
  folderInputLabel: {
    fontFamily: 'Nunito',
    cursor: 'pointer',
    color: '#6098D1',
    fontSize: 14,

    '&:hover': {
      color: '#1871E8',
    },
  },
}));
export default useStyles;
