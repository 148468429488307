import { ChangeEvent } from 'react';
import { TextField, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type textFieldVariant = 'outlined' | 'filled' | 'standard';

interface ICharCountTextFieldProps {
  handleChange: (value: string) => void;
  variant?: textFieldVariant;
  shrinkAlways?: boolean;
  lines?: number;
  maxChar?: number;
  error?: boolean | undefined;
  label?: JSX.Element | string;
  defaultValue?: string;
  id?: string;
  testId?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
}

const useStyles = makeStyles()(_theme => ({
  characterCount: {
    position: 'absolute',
    margin: 0,
    bottom: 4,
    right: 8,
    fontSize: 12,
    fontWeight: 400,
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
}));

export const CharacterCountTextField: React.FC<ICharCountTextFieldProps> = ({
  handleChange,
  variant = 'outlined',
  shrinkAlways = false,
  lines = 5,
  maxChar = 300,
  error = false,
  label = '',
  id,
  testId,
  required = false,
  disabled = false,
  value = '',
}) => {
  const { classes } = useStyles();
  const characterCount = value.length;

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value.length > maxChar) return;

    handleChange(event.target.value);
  };

  return (
    <Box className={classes.wrapper}>
      <TextField
        multiline
        label={label}
        rows={lines}
        value={value}
        onChange={handleDescriptionChange}
        data-test={`reusable-char-count-text-${testId}`}
        id={id}
        variant={variant}
        fullWidth
        error={error}
        required={required}
        InputLabelProps={value.length > 0 || shrinkAlways ? { shrink: true } : {}}
        disabled={disabled}
      />
      <div data-test={`reusable-char-count-text-count-${testId}`} className={classes.characterCount}>
        {characterCount}/{maxChar}
      </div>
    </Box>
  );
};
