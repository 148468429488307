import { FunctionComponent, useState, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FormattedMessage } from 'react-intl';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropUp';
import TableHead from '@mui/material/TableHead';
import { useStyles } from './useStyles';
import { SortDirection } from './types';
import startCase from 'lodash/startCase';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

interface Props {
  columnNames: Array<string>;
  defaultOrderBy?: string;
  onRequestSort?: (columnName: string, direction: SortDirection) => void;
  idPrefix: string;
  isSortable?: boolean;
}

const DynamicTableHead: FunctionComponent<Props> = ({
  columnNames,
  defaultOrderBy,
  onRequestSort,
  idPrefix,
  isSortable = true,
}: Props) => {
  const [direction, setDirection] = useState<SortDirection>('asc');
  const [selectedColumn, setSelectedColumn] = useState(defaultOrderBy || columnNames[0]!);
  const { classes } = useStyles();

  const handleSort = (columnName: string) => {
    const isAsc = selectedColumn === columnName && direction === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSelectedColumn(columnName);
  };

  useEffect(() => {
    onRequestSort && onRequestSort(selectedColumn, direction);
  }, [direction, selectedColumn]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {columnNames.map((columnName, index) =>
          isNull(columnName) || isEmpty(columnName) ? (
            <TableCell
              data-test={`${idPrefix}-table-heading.${index}`}
              key={`action-column-${index}`}
              className={`${classes.borderBottomLineHeaderAction}`}
            />
          ) : (
            <TableCell
              data-test={`${idPrefix}-table-heading.${index}`}
              key={`${columnName}-${index}`}
              className={`${classes.borderBottomLineHeader}`}
            >
              <FormattedMessage
                id="{idPrefix}-table-column-heading.{id}"
                defaultMessage="{columnName}"
                description="{columnName}"
                values={{
                  idPrefix: idPrefix,
                  id: `${columnName}-${index}`,
                  columnName: startCase(columnName),
                }}
              />
              {isSortable && (
                <TableSortLabel
                  data-test={`${idPrefix}-table-sort-label.${index}`}
                  active={columnName === selectedColumn}
                  IconComponent={ArrowDropDownIcon}
                  direction={columnName === selectedColumn ? direction : 'asc'}
                  onClick={() => handleSort(columnName)}
                  classes={{ icon: classes.sortIcon }}
                />
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default DynamicTableHead;
