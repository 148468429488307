import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux';
import { GraphQLPage } from '../types';
export async function getSchemas(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'getSchemas';
  const query = `
      query ${operationName}($limit: Int, $filterByOwnership: SchemaOwnership){
        dataRegistries(limit:$limit, filterByOwnership: $filterByOwnership, orderBy: name, orderDirection: asc ) {
          count
          records {
            id
            name
            description
            schemas {
              count
              records {
                id
                status
                definition
                majorVersion
                minorVersion
                validActions
              }
            }
          }
        }
      }
    `;
  const variables = {
    limit: 1000,
    filterByOwnership: 'mine',
  };

  const result = await baseGraphQLApiWithError<{
    dataRegistries: GraphQLPage<any>;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });
  return result.dataRegistries.records;
}
