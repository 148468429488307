import { Dispatch } from 'react';
import { EUserStatus } from '../lib/invite-panel';

export interface IInviteItem {
  applicationRoles: any;
  email?: string;
  expirationDate: Date;
  message?: string;
  organizationId: string;
  organizationInviteId: string;
  status: string;
  organizationName?: string;
  organizationLogo?: string;
}

export interface IMyOrganization {
  id: number;
  name: string;
  imageUrl: string;
  guid?: string;
  priority: number;
}

export interface IMyOrganizationResponse extends IMyOrganization {
  myOrganizations: GraphQLPage<IMyOrganization>;
}

export interface IScimConnectIdsResponse {
  scimConnectIds: string[];
}

export type TRecord = {
  applicationName: string;
  applicationDescription: string;
  applicationIconUrl: string;
  applicationIconSvg: string;
  applicationId: string;
  providedRole?: string;
  applicationKey?: string;
};

type TApplications = {
  records: TRecord[];
};

export type TApplicationsRestResponse = {
  results?: TRecord[];
};

export interface IOrgAppsAndRoles {
  applications: TApplications;
  roles: TRole[];
}

export type GraphQLPage<T> = {
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export type TApplicationCard = {
  card: TRecord;
};

export type TRole = {
  name: string;
  description: string;
  appName?: string;
  id: string;
};

export type TOrgRoles = {
  roles: Array<TRole>;
};

export type TIntl = {
  intl: any;
};

export type TRoleAction = {
  manageRoles: Dispatch<any>;
};

export type TApplicationInviteRole = {
  application?: TApplication;
  role?: TRole;
  applicationId: string;
  roleId: string;
};

export type TApplication = {
  id: string;
  name: string;
  iconUrl: string;
};

export type TInvitee = {
  firstName?: string;
  lastName?: string;
  name?: string;
  imageUrl?: string | null;
  email: string;
  avatar?: string;
  applicationRoles?: TApplicationInviteRole[];
  status?: EUserStatus;
  organizationGuids?: string[];
  organizationInvites?: TOrganizationInvite[];
};

export type TInviteesResponse = {
  records: TInvitee[];
};

export type TOrgSwitchPayload = {
  userName: string;
  organizationGuid: string;
};

export type TOrgSwitchResponse = {
  token: string;
  organization: {
    id: string;
    name: string;
  };
};
export type TOrgInviteeInput = {
  email: string;
  applicationRoles: TApplicationInviteRole[];
};

export enum EOrganizationInviteStatus {
  submitted = 'submitted',
  approved = 'approved',
  completed = 'completed',
  rejected = 'rejected',
  expired = 'expired',
  deleted = 'deleted',
}

//This for input
export enum EOrganizationInviteAction {
  submit = 'submit',
  approve = 'approve',
  complete = 'complete',
  reject = 'reject',
  delete = 'delete',
  request = 'request',
}

export type TOrganizationInviteAuthor = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string | null;
};

export type TOrganizationInviteOrgDetails = {
  id: string;
  name: string;
  imageUrl: string | null;
};

export type TOrganizationInvite = {
  organizationInviteId: string;
  organizationId: string;
  email: string;
  applicationRoles: TApplicationInviteRole[];
  status: EOrganizationInviteStatus;
  expirationDate: string;
  message?: string;
  organizationName?: string; // Name and logo shouldn't be optional once we can get them from gql
  organizationLogo?: string; // Name and logo shouldn't be optional once we can get them from gql
  createdBy?: TOrganizationInviteAuthor;
  organization?: TOrganizationInviteOrgDetails;
  invitee: TInvitee;
};

type TApplicationRoles = {
  applicationId: string;
  roleId: string;
};

export type TCreateOrganizationInviteInput = {
  organizationId: string;
  email: string;
  applicationRoles: TApplicationInviteRole[];
  message?: string;
};

export type TUpdateOrganizationInviteInput = {
  organizationInviteId: string;
  applicationRoles: TApplicationRoles[];
  action?: EOrganizationInviteAction;
  message?: string;
};
export type TDeleteOrganizationInvite = {
  organizationInviteId: string;
};

export interface IOrgApp {
  applicationId: string;
  applicationName: string;
  applicationKey: string;
  signedApplicationIconUrl: string;
  applicationIconUrl: string;
  applicationIconSvg: string;
  applicationDescription: string;
  headerbarEnabled?: boolean;
  applicationUrl?: string;
}

export interface IOrgApplications {
  from?: number;
  to?: number;
  totalResults?: number;
  results?: IOrgApp[];
}
