export const BOOTING = 'aiware-header-bar/booting';
export const BOOTED = 'aiware-header-bar/booted';

export const boot = () => ({
  type: BOOTING,
});

export const onBooted = () => ({
  type: BOOTED,
});
