const nameHelper = (id?: number | string, time?: string) => {
  if (!id) {
    id = '';
  }
  if (!time) {
    time = '';
  }

  return `${id}-${time}`;
};

export default nameHelper;
