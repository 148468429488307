import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from '@mui/material';
import { hidePanel } from '@aiware/js/panel';
import { ComponentLoader } from '../components/ComponentLoader/ComponentLoader';
import { appIdSelector, selectApiConfigs } from '@aiware/shared/redux';
import { FieldValue } from '../../types';
import { useStyles } from './user-application-settings-panel.styles';
import { formattedMessages } from '../helpers/formatted-messages';
import { actions } from '../../redux/slices';
import { selectApplicationConfig } from '../../redux/selectors';
import { getApplicationName } from '../../api/application-config.api';

export const PANEL_ID = 'USER_APPLICATION_SETTINGS_PANEL';

export const UserApplicationSettingsPanel = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [fieldValues, setFieldValues] = useState<Partial<FieldValue>>({});
  const { cancelButton, saveSettingsButton } = formattedMessages(intl);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [appName, setAppName] = useState<string>('');
  const appId = useSelector(appIdSelector);
  const result = useSelector(selectApplicationConfig);
  const apiConfigs = useSelector(selectApiConfigs);

  useEffect(() => {
    const id = appId;
    const fetchAppName = async () => {
      const list = await getApplicationName(apiConfigs, appId);
      setAppName(list.data.applications.records[0].name);
    };
    fetchAppName().catch(console.error);
  }, []);

  const handleFieldChange = (field: FieldValue) => {
    setFieldValues(prevState => ({
      ...prevState,
      [field.fieldName]: field.fieldValue,
    }));
  };

  const toggleSaveButton = (toggle: boolean) => {
    setBtnDisabled(toggle);
  };

  const mappedFields = result?.map(field => {
    const mutable = { ...field };
    mutable.value = fieldValues[field.configKey as keyof Partial<FieldValue>];
    return mutable;
  });

  const setDefaultFields = () => {
    const defaults: Partial<FieldValue> = {};
    if (result?.length) {
      result.forEach(field => {
        const value = field.value;
        defaults[field.configKey as keyof Partial<FieldValue>] = value;
      });
    }

    setFieldValues(defaults);
  };

  useEffect(() => {
    setDefaultFields();
  }, [result.length]);

  const handleCancel = () => {
    dispatch(hidePanel(PANEL_ID));
  };

  const handleSave = () => {
    const input: any = [];
    Object.entries(fieldValues).forEach(([key, value]) => {
      input.push({
        configKey: key,
        configValue: value,
      });
    });

    dispatch(
      actions.userApplicationSettings.updateApplicationConfigStart({
        appId,
        configs: input,
      })
    );
    dispatch(hidePanel(PANEL_ID));
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        className={classes.subtitle}
        data-testid="os-admin-center-user-application-settings-subtitle"
        data-test="os-admin-center-user-application-settings-subtitle"
      >
        Set your individual settings for your account for the <b>{appName}</b>.
      </Typography>
      <main className={classes.settingsList}>
        <ComponentLoader
          fields={mappedFields}
          onChange={handleFieldChange}
          toggleSaveButton={toggleSaveButton}
        />
      </main>
      <div className={classes.footerBtnContainer}>
        <Button
          color="inherit"
          data-testid="os-admin-center-user-application-settings-cancel-button"
          data-test="os-admin-center-user-application-settings-cancel-button"
          onClick={handleCancel}
        >
          {cancelButton}
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid="os-admin-center-user-application-settings-save-settings-button-text"
          data-test="os-admin-center-user-application-settings-save-settings-button-text"
          onClick={handleSave}
          sx={{ width: '128px' }}
          disabled={btnDisabled}
        >
          {saveSettingsButton}
        </Button>
      </div>
    </>
  );
};
