import { EngineItem } from '../EngineItem/EngineItem';
import { useStyles } from './EngineItemList.styles';
import { useSelector } from 'react-redux';
import { importSourcePanelSelectors } from '../../../../../redux/selectors/panel-select-import-source';
import { Button, CircularProgress, Typography } from '@mui/material';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { IEngineAdapter } from '../../../../../redux/slices/panel-select-import-source';
import EmptyStatePullAndPush from '../EmptyStatePullAndPush';

interface IProps {
  engines: IEngineAdapter[];
  onClickEngine: (engine: IEngineAdapter) => void;
  onRetry: () => void;
  isLoading: boolean;
  isError: boolean;
  activeTab: number;
}

export const EngineItemList: React.FC<IProps> = ({
  engines,
  onClickEngine,
  onRetry,
  isLoading,
  isError,
  activeTab,
}) => {
  const { classes } = useStyles();
  const activeEngineId = useSelector(importSourcePanelSelectors.selectActiveEngineId);

  const renderEngines = () =>
    engines.map((engine, _index) => (
      <EngineItem
        engineImportItem={engine}
        key={engine?.id}
        onClick={() => onClickEngine(engine)}
        isActive={engine?.id === activeEngineId}
      />
    ));

  return (
    <div className={classes.container}>
      {!isError && renderEngines()}
      {isError && <ErrorState onRetry={onRetry} />}
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {engines.length === 0 && !isLoading && !isError && <EmptyStatePullAndPush activeTab={activeTab} />}
    </div>
  );
};

const ErrorState: React.FC<{ onRetry: () => void }> = ({ onRetry }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.emptyState}>
      <Typography>{SHARED_TEXT.engineList.errorStateTitle()}</Typography>
      <Button onClick={onRetry}>{SHARED_TEXT.engineList.errorStateRetry()}</Button>
    </div>
  );
};
