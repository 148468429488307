import { FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';
import { IntlShape } from 'react-intl';
import { Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

export const formatTime = (date: Date) => {
  const timeSegments: (string | string[])[] = date.toLocaleTimeString().split(' ');
  timeSegments[0] = (timeSegments[0] as string).split(':');
  timeSegments[0].pop();
  timeSegments[0] = timeSegments[0].join(':');
  return timeSegments[0] ? timeSegments[0] + ' ' + (timeSegments[1] || '') : '';
};

export const truncatedString = (str: string | undefined, maxLen: number) => {
  if (str && str.length > maxLen) {
    return (
      <Tooltip title={str} arrow>
        <span>{str.slice(0, maxLen).trim()}...</span>
      </Tooltip>
    );
  }
  return <>{str}</>;
};

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const getTimeDifference = (isoString: string): number => {
  const currentTime = new Date().getTime();
  const pastTime = new Date(isoString).getTime();
  return (pastTime - currentTime) / 1000 / 60;
};

export const totalRunTime = (start: string, stop: string, intl: IntlShape): string => {
  const startTime = new Date(start).getTime();
  const stopTime = new Date(stop).getTime();
  let diff = (stopTime - startTime) / 1000;
  let timeString: string | number = '';

  const day = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time-day',
    defaultMessage: 'day',
    description: 'TotalRunTime day',
  });
  const days = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time.days',
    defaultMessage: 'days',
    description: 'TotalRunTime days',
  });
  const hour = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time.hour',
    defaultMessage: 'hour',
    description: 'TotalRunTime hour',
  });
  const hours = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time.hours',
    defaultMessage: 'hours',
    description: 'TotalRunTime hours',
  });
  const minute = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time.minute',
    defaultMessage: 'minute',
    description: 'TotalRunTime minute',
  });
  const minutes = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time.minutes',
    defaultMessage: 'minutes',
    description: 'default placeholder for minutes',
  });
  const justNow = intl.formatMessage({
    id: 'processing-center-overview-tab.total-run-time.just-now',
    defaultMessage: 'Just Now',
    description: 'TotalRunTime Just Now',
  });

  const dd = Math.floor(diff / (3600 * 24));
  if (dd) {
    diff -= dd * 3600 * 24;
    timeString += `${dd} ${dd === 1 ? day : days} `;
  }
  const hh = Math.floor(diff / 3600);
  if (hh) {
    diff -= hh * 3600;
    timeString += `${hh} ${hh === 1 ? hour : hours} `;
  }
  const mm = Math.floor(diff / 60);
  if (mm) {
    timeString += `${mm} ${mm === 1 ? minute : minutes}`;
  }

  if (!timeString) {
    timeString = justNow;
  }

  return timeString;

  // timeString should contain days, hours, and minutes
};

export const dateHelper = (value?: string | undefined) => {
  const dateObject = {
    day: 'N/A',
    time: 'N/A',
    full: 'N/A',
  };
  if (value === undefined) {
    return dateObject;
  }
  const date = DateTime.fromISO(value.split(' ').join(''));
  const day = date.toFormat('LL/dd/yyyy');
  const time = date.toFormat('t');

  if (day !== 'Invalid DateTime') {
    dateObject.day = day;
  }
  if (time !== 'Invalid DateTime') {
    dateObject.time = time;
  }
  dateObject.full = `${dateObject.day} ${dateObject.time}`;

  return dateObject;
};

export const DateFormatter: FunctionComponent<{
  value: string | undefined;
  className?: string;
  removeTime?: boolean;
  testIdDate?: string;
  testIdTime?: string;
  rawDateValue?: string;
  rawTimeValue?: string;
  orientation?: 'vertical' | 'horizontal';
}> = ({
  value,
  removeTime,
  className,
  testIdDate = 'date',
  testIdTime = 'time',
  rawDateValue = '',
  rawTimeValue = '',
  orientation = 'vertical',
}) => {
  const date = dateHelper(value);
  const time = date.time === 'N/A' ? '' : date.time;
  const day = date.day;

  const Content = () => (
    <>
      <Typography
        sx={{ mt: 1 }}
        data-test={'day'}
        variant="body2"
        color="textPrimary"
        className={className}
        data-testid={testIdDate}
      >
        {rawDateValue || day}
      </Typography>
      {!removeTime && (
        <Typography data-test={'time'} variant="caption" color="textSecondary" data-testid={testIdTime}>
          {rawTimeValue || time}
        </Typography>
      )}
    </>
  );
  if (orientation === 'horizontal') {
    return (
      <Stack direction="row" gap={'10px'} sx={{ alignItems: 'center', '& > p': { margin: 0 } }}>
        <Content />
      </Stack>
    );
  }

  return <Content />;
};

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },
  tableWrapper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    overflowY: 'hidden',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const DataCenterSharedContentLayout: FunctionComponent<{
  breadcrumbs?: JSX.Element;
  actions?: JSX.Element;
  table?: JSX.Element | null;
  tableWrapperStyles?: React.CSSProperties;
}> = ({ breadcrumbs, actions, table, tableWrapperStyles = {} }) => {
  const { classes } = useStyles();

  return (
    <section className={classes.root}>
      <header className={classes.header}>
        <div className={classes.toolbar}>{breadcrumbs}</div>
        <div className={classes.toolbar}>{actions}</div>
      </header>
      <div className={classes.tableWrapper} style={tableWrapperStyles}>
        {table}
      </div>
    </section>
  );
};

export const mockHelper = {
  getRandom: (seed = '') => {
    function simpleHash(str: string): number {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    }

    if (seed) {
      const hash = simpleHash(seed);
      const seedDecimal = hash / 0xffffffff;
      return seedDecimal < 0 ? -seedDecimal : seedDecimal;
    }

    return Math.random();
  },
  randomFromArr: (arr: any[], seed = '') => {
    if (!arr?.length) return null;
    const amount = arr.length - 1;
    return arr[Math.floor(mockHelper.getRandom(seed) * (amount + 1))];
  },
  wait: async (milliseconds: number): Promise<void> => {
    return new Promise<void>(resolve => setTimeout(resolve, milliseconds));
  },
  getId: () => uuid(),
  getProfileImageURL: (seed = '') =>
    `https://api.dicebear.com/7.x/${mockHelper.randomFromArr(
      ['miniavs', 'open-peeps', 'pixel-art', 'icons', 'adventurer', 'big-smile'],
      seed
    )}/svg${seed ? `?seed="${seed}"` : ''}`,
  getImageURL: (seed = '') => {
    const resolution = ['300', '350', '400', '450', '500', '550', '600', '650', '700', '750', '800', '850'];
    const category = [
      'city',
      'nature',
      'sunrise',
      'beach',
      'landscape',
      'car',
      'food',
      'sky',
      'architecture',
    ];
    return `https://source.unsplash.com/random/${mockHelper.randomFromArr(
      resolution,
      seed
    )}x${mockHelper.randomFromArr(resolution)}/?${mockHelper.randomFromArr(category, seed)}`;
  },
  getDate: (minYear = 2018, maxYear = new Date().getFullYear(), seed = '') => {
    function randomNumberBetween(min: number, max: number): string {
      const randomNum = Math.floor(mockHelper.getRandom(seed) * (max - min + 1) + min);
      return `${randomNum.toString().length === 1 ? '0' : ''}${randomNum}`;
    }

    const day = randomNumberBetween(1, 28);
    const month = randomNumberBetween(1, 12);
    const year = randomNumberBetween(minYear, maxYear);
    const hour = randomNumberBetween(0, 23);
    const minute = randomNumberBetween(0, 59);

    const randomDateInitializer = `${year}-${month}-${day} ${hour}:${minute}:00`;

    return new Date(randomDateInitializer);
  },
};
