import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void>()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 55px - 5px)',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  panelTitle: {
    padding: theme.spacing(0, 6, 3, 6),
  },
  title: {
    color: '#2A323C',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '-1.30385e-09px',
  },
  subTitle: {
    color: '#2A323C',
    fontWeight: 600,
  },
  titleDescription: {
    color: '#5C6269',
    fontWeight: 400,
    maxWidth: '372px',
    marginTop: theme.spacing(1),
  },
  divider: {
    opacity: 0.9,
  },
  tabsContainer: {
    '& .Sdk-Mui-selected': {
      color: 'black',
    },
    '& .Sdk-MuiTabs-indicator': {
      backgroundColor: '#000',
    },
  },
  tabsParent: {
    padding: theme.spacing(0, 3, 0, 3),
    '& div': {
      borderBottom: 'none',
    },
    '& div button': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      flex: '0 1 auto !important',
      minWidth: 'unset',
    },
  },
  secondBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 6, 0, 6),
    flexGrow: 1,
  },
  controlsContainer: {
    padding: '30px',
    '& button': {
      float: 'right',
    },
  },
}));

export default useStyles;

export const tabStyles = makeStyles<void>()((theme: Theme) => ({
  root: {
    paddingTop: '35px',
  },
  title: {
    fontWeight: 'bold',
  },
  gridItem: {
    paddingTop: '20px',
  },
}));
