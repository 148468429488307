import { call, put, select } from 'redux-saga/effects';
import { ECapabilityTypes, ISuggestion } from '../../types/aiAutocomplete.types';
import { ILibrary } from '../../types/getLibraryIds.types';
import { aiAutocompleteSagaConfig } from './aiAutocomplete.saga.config';
import { actions } from './aiAutocomplete.slice';
import { suggestionResultKey } from '../../components/AIAutocomplete/aiAutocomplete.constants';
import { selectApiConfigs } from './aiAutocomplete.selectors';
import { selectLibraries } from '../getLibraryIds/getLibraryIds.selectors';
import { root } from '../../api/queries';

export function* getTagsSuggestions({ payload }: ReturnType<typeof actions.setSearchTerm>) {
  yield* baseGetSuggestionsSaga(payload, suggestionResultKey.tags);
}

export function* getObjectSuggestions({ payload }: ReturnType<typeof actions.setSearchTerm>) {
  yield* baseGetSuggestionsSaga(payload, suggestionResultKey.objects);
}

export function* getLogoSuggestions({ payload }: ReturnType<typeof actions.setSearchTerm>) {
  yield* baseGetSuggestionsSaga(payload, suggestionResultKey.logos);
}

export function* getFaceSuggestions({ payload }: ReturnType<typeof actions.setSearchTerm>) {
  yield* faceGetSuggestionsSaga(payload, suggestionResultKey.facial);
}

export function* abortQueries() {
  try {
    root.abortControllers[ECapabilityTypes.logo].abort();
    root.abortControllers[ECapabilityTypes.object].abort();
    root.abortControllers[ECapabilityTypes.tags].abort();
    root.abortControllers[ECapabilityTypes.facial].abort();
  } catch (err) {
    /** TODO: This error happens constantly when Data Center is interacted with
     * we should figure out why its always running even when autocomplete is not rendered. */
    //console.log(err);
  }
  yield put(actions.setIsLoading({ key: suggestionResultKey.logos, isLoading: false }));
  yield put(actions.setIsLoading({ key: suggestionResultKey.objects, isLoading: false }));
  yield put(actions.setIsLoading({ key: suggestionResultKey.tags, isLoading: false }));
  yield put(actions.setIsLoading({ key: suggestionResultKey.facial, isLoading: false }));
}

/**
 *
 * @param payload searchTerm
 * @param suggestionResultKey  (people, logo, tags, etc..)
 * @description This is the base saga that will be used to fetch suggestions.
 * Firstly, configure your api call in the aiAutocompleteSagaConfig object (state/aiAutocomplete.saga.config.ts).
 * Then, just pass in the payload and the suggestionResultKey. That's it!
 */

function* baseGetSuggestionsSaga(payload: string, suggestionResultKey: string) {
  const { setSuggestionResult, setIsLoading, setError } = actions;
  const { label, query, error } = (aiAutocompleteSagaConfig as any)[suggestionResultKey];

  try {
    yield put(setIsLoading({ key: suggestionResultKey, isLoading: true }));

    const { apiRoot, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const input = {
      endpoint: apiRoot,
      token,
      text: payload || '',
    };

    const suggestions: ISuggestion[] = yield call(query, input);

    yield put(
      setSuggestionResult({
        key: suggestionResultKey,
        label: label,
        suggestions: suggestions,
        isLoading: false,
      })
    );

    yield put(setError({ key: suggestionResultKey, error: null }));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    const isAborted = errorMessage?.toLowerCase().includes('canceled');
    const friendlyMessage = isAborted ? 'The query was aborted.' : error.friendlyMessage;

    yield put(
      setError({
        key: suggestionResultKey,
        error: { title: error.title, errorMessage: errorMessage, friendlyMessage: friendlyMessage },
      })
    );
  } finally {
    yield put(setIsLoading({ key: suggestionResultKey, isLoading: false }));
  }
}

export function* faceGetSuggestionsSaga(payload: string, suggestionResultKey: string) {
  const { setSuggestionResult, setIsLoading, setError } = actions;
  const { label, query, error } = (aiAutocompleteSagaConfig as any)[suggestionResultKey];

  try {
    yield put(setIsLoading({ key: suggestionResultKey, isLoading: true }));

    const { apiRoot, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const libIds: ILibrary[] = yield select(selectLibraries);
    const input = {
      endpoint: apiRoot,
      token,
      text: payload || '',
      libIds: libIds || [],
    };

    const suggestions: ISuggestion[] = yield call(query, input);

    yield put(
      setSuggestionResult({
        key: suggestionResultKey,
        label: label,
        suggestions: suggestions,
        isLoading: false,
      })
    );

    yield put(setError({ key: suggestionResultKey, error: null }));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    const isAborted = errorMessage?.toLowerCase().includes('canceled');
    const friendlyMessage = isAborted ? 'The query was aborted.' : error.friendlyMessage;

    yield put(
      setError({
        key: suggestionResultKey,
        error: { title: error.title, errorMessage: errorMessage, friendlyMessage: friendlyMessage },
      })
    );
  } finally {
    yield put(setIsLoading({ key: suggestionResultKey, isLoading: false }));
  }
}

function getErrorMessage(error: unknown): string {
  if (error instanceof Error) return error.message;

  return String(error);
}
