import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import { NewFolder } from '@aiware/shared/icons';

import { actions } from '../../../redux/slices';

const NewFolderMessage = () => (
  <FormattedMessage
    id="os-data-center-browse.gDDtUB"
    defaultMessage="New Folder"
    description="Create a new folder in the currently selected folder"
  />
);

export interface INewFolderAction {
  entityId: string;
}

export const NewFolderAction: FunctionComponent<INewFolderAction> = ({ entityId }) => {
  const dispatch = useDispatch();
  const handleAction = () => {
    entityId && dispatch(actions.panelAddFolder.openAddNewFolder(entityId));
  };
  return (
    <>
      <Typography id="folders-files-panel-new-folder-action" style={visuallyHidden}>
        <NewFolderMessage />
      </Typography>
      <Tooltip title={<NewFolderMessage />}>
        <IconButton
          aria-labelledby="folders-files-panel-new-folder-action"
          data-testid="folders-files-panel-new-folder-action"
          disableRipple
          onClick={handleAction}
          size="large"
        >
          <NewFolder />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default NewFolderAction;
