import { useCallback, useRef } from 'react';
import { INotificationDetail } from '@aiware/js/interfaces';
import NotificationItem from '../NotificationItem';
import CircularProgress from '@mui/material/CircularProgress';
import EmptyView from '../EmptyView';
import useStyles from './useStyles';
import { hidePanel, mountPanel } from '@aiware/js/panel';
import { useDispatch } from 'react-redux';

interface INotificationListProps {
  title: string;
  data: INotificationDetail[];
  onOpenNotificationDetail: (item: INotificationDetail) => void;
  handleGetNotification: () => void;
  loading: boolean;
  setPanelIsOpenState?: React.Dispatch<React.SetStateAction<boolean>>;
}

function NotificationList({
  title,
  data,
  loading,
  handleGetNotification,
  onOpenNotificationDetail,
  setPanelIsOpenState,
}: INotificationListProps) {
  const { classes } = useStyles();
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0]?.isIntersecting) {
          handleGetNotification();
        }
      });
      if (node) observer.current.observe(node);
    },
    [handleGetNotification, loading]
  );
  const dispatch = useDispatch();
  const openVersionPanel = () => {
    setPanelIsOpenState && setPanelIsOpenState(false);
    dispatch(hidePanel('NOTIFICATION_PANEL_ID'));
    dispatch(
      mountPanel({
        panelId: 'CHANGELOG_PANEL',
        microFrontend: {
          name: 'CHANGELOG_PANEL',
          config: {},
        },
        panelConfig: {
          type: 'APP_BAR_PANEL_TEMPLATE',
          marginTop: 55,
          marginStart: 0,
          size: 'small',
          dimmed: 0,
          borderBottom: true,
        },
      })
    );
  };

  return (
    <>
      <div className={classes.header}>{title.toUpperCase()}</div>
      <div className={classes.scrollContent}>
        {data?.length > 0 ? (
          data.map((item, index) => {
            const isNewVersionNotification = !!(item.eventNames || []).find(
              (name: string) => name === 'NewVersionInstalled'
            );
            if (data.length === index + 1) {
              return (
                <NotificationItem
                  ref={lastElementRef}
                  onViewDetail={
                    isNewVersionNotification
                      ? () => openVersionPanel()
                      : () => onOpenNotificationDetail?.(item)
                  }
                  key={index}
                  data={item}
                />
              );
            }
            return (
              <NotificationItem
                onViewDetail={
                  isNewVersionNotification ? () => openVersionPanel() : () => onOpenNotificationDetail?.(item)
                }
                key={index}
                data={item}
              />
            );
          })
        ) : (
          <EmptyView msg={true} />
        )}
        {loading && (
          <div className={classes.infinityLoading}>
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </>
  );
}

export default NotificationList;
