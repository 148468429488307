import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  customRangeRendered: {
    fontSize: '12px',
    borderRadius: '5px',
    padding: '0px 7px',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dateRangeDivider: {
    margin: '0px 5px',
    opacity: 0.25,
  },
  dateRangeContainer: {
    padding: '0px 20px 15px 20px',
  },
  dateRangeInputs: {
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    paddingTop: '20px',
    justifyContent: 'space-between',
  },
  dateRangeSpacer: {
    width: '25px',
    display: 'inline-block',
  },
}));
