import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';
import { namespace, IState } from './type';
import { LoadingStatus } from '@aiware/js/interfaces';
export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

export const selectLibraries = (state: IState) => state[namespace]?.libraries;

export const selectLoadingStatus = (state: IState): LoadingStatus => state[namespace]?.status;
