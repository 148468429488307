export const formattedMessages = (intl: any) => ({
  cancelButton: intl.formatMessage({
    id: `os-admin-center-user-application-settings-cancel-button-text`,
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  }),
  saveSettingsButton: intl.formatMessage({
    id: `os-admin-center-user-application-settings-save-settings-button-text`,
    defaultMessage: 'Save Settings',
    description: 'Save Settings button',
  }),
});
