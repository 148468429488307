import { FormattedMessage } from 'react-intl';
import { Button, CircularProgress, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { ECapabilityTypes, CapabilityType, ISuggestion } from '../../../../types/aiAutocomplete.types';
import { useAiAutocompleteContext } from '../../AiAutocomplete.context';
import { AiSuggestionItem } from '../AiSuggestionItem';
import { useStyles } from './aiSuggestions.styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as autocompleteActions } from '../../../../state/aiAutocomplete/aiAutocomplete.slice';
import {
  selectIsTyping,
  selectRawSearchTerm,
} from '../../../../state/aiAutocomplete/aiAutocomplete.selectors';

export const AiSuggestions = () => {
  const { suggestionResult, isLoading, actions } = useAiAutocompleteContext();
  const isTyping = useSelector(selectIsTyping);
  const rawSearchTerm = useSelector(selectRawSearchTerm);
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const MAX_LENGTH = 120;
  const termText =
    rawSearchTerm.length > MAX_LENGTH ? rawSearchTerm.slice(0, MAX_LENGTH) + '...' : rawSearchTerm;

  const LoadingBar = () => {
    return (
      <div
        id="aiAutocomplete-suggestions-loading-bar"
        data-test-id="aiAutocomplete-suggestions-loading-bar-test-id"
        className={classes.loadingBarContainer}
      >
        <CircularProgress size={20} />
        <Typography variant="caption" sx={{ marginLeft: '15px', fontWeight: 400 }}>
          <FormattedMessage
            id="os-data-center-browse.search-autocomplete-loader-msg"
            defaultMessage="Searching for Matches"
            description="Label of the data center search autocomplete is loading message"
          />
          ...
        </Typography>
      </div>
    );
  };
  return (
    <div
      id="aiAutocomplete-suggestions-container"
      data-test-id="aiAutocomplete-suggestions-container-test-id"
    >
      <dl className={classes.suggestionList}>
        <dt className={classes.suggestionTitle}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <FormattedMessage
              id="data-center-search.suggestions-title.keywords"
              defaultMessage="Keywords"
              description="Data Center Search suggetions title, keywords"
            />
          </Typography>
        </dt>
        <dd className={classes.suggestionItem}>
          <Button
            id="aiAutocomplete-suggestion-keywords-button"
            data-test-id="aiAutocomplete-suggestion-keywords-button-test-id"
            variant="text"
            onClick={() => handleTextCapabilityClick(ECapabilityTypes.keyword)}
          >
            "{termText}"
          </Button>
        </dd>

        <dt className={classes.suggestionTitle}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <FormattedMessage
              id="data-center-search.suggestions-title.recognized-text"
              defaultMessage="Recognized Text"
              description="Data Center Search suggetions title, recognized text"
            />
          </Typography>
        </dt>
        <dd className={classes.suggestionItem}>
          <Button
            id="aiAutocomplete-suggestion-recogizedText-button"
            data-test-id="aiAutocomplete-suggestion-recognizedText-button-test-id"
            variant="text"
            onClick={() => handleTextCapabilityClick(ECapabilityTypes.recognizedText)}
          >
            "{termText}"
          </Button>
        </dd>

        <dt className={classes.suggestionTitle}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <FormattedMessage
              id="data-center-search.suggestions-title.file-name"
              defaultMessage="File Name"
              description="Data Center Search suggetions title, file name"
            />
          </Typography>
        </dt>
        <dd className={classes.suggestionItem}>
          <Button
            id="aiAutocomplete-suggestion-fileName-button"
            data-test-id="aiAutocomplete-suggestion-fileName-button-test-id"
            variant="text"
            onClick={() => handleTextCapabilityClick(ECapabilityTypes.fileName)}
          >
            "{termText}"
          </Button>
        </dd>

        <dt className={classes.suggestionTitle}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <FormattedMessage
              id="data-center-search.suggestions-title.data-registry"
              defaultMessage="Data Registries"
              description="Data Center Search suggetions for data registries"
            />
          </Typography>
        </dt>
        <dd className={classes.suggestionItem}>
          <Button
            id="aiAutocomplete-suggestion-dataRegistry-button"
            data-test-id="aiAutocomplete-suggestion-dataRegistry-button-test-id"
            variant="text"
            onClick={() => handleTextCapabilityClick(ECapabilityTypes.dataRegistry)}
          >
            "{termText}"
          </Button>
        </dd>

        <dt className={classes.suggestionTitle}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            <FormattedMessage
              id="data-center-search.suggestions-title.source"
              defaultMessage="Sources"
              description="Data Center Search suggetions for sources"
            />
          </Typography>
        </dt>
        <dd className={classes.suggestionItem}>
          <Button
            id="aiAutocomplete-suggestion-source-button"
            data-test-id="aiAutocomplete-suggestion-source-button-test-id"
            variant="text"
            onClick={() => handleTextCapabilityClick(ECapabilityTypes.source)}
          >
            "{termText}"
          </Button>
        </dd>

        {Object.keys(suggestionResult)?.map(key => {
          const { label, suggestions, error } = suggestionResult[key]!;
          const shouldShowSuggestion = suggestions?.length > 0 && Object.keys(error || {}).length === 0;

          return (
            shouldShowSuggestion && (
              <AiSuggestionItem
                key={key}
                label={label}
                onClick={handleOnSuggestionClick}
                suggestions={suggestions}
              />
            )
          );
        })}
      </dl>
      {(isLoading || isTyping) && <LoadingBar />}
    </div>
  );

  function handleTextCapabilityClick(capabilityType: CapabilityType): void {
    const id: string = uuidv4();
    const label = `${rawSearchTerm}`;
    const entityId = '';

    actions.onTextBasedCapabilityClick(id, entityId, label, capabilityType);
    dispatch(autocompleteActions.clearSearchTerm());
  }

  function handleOnSuggestionClick(suggestion: ISuggestion): void {
    actions.onSuggestionClick(suggestion);
    dispatch(autocompleteActions.clearSearchTerm());
  }
};
