import { all } from 'redux-saga/effects';
import * as sagas from './index';

import { sagas as schemaPropertiesSagas } from '../../lib/Search/state/aiStructuredData/schemaProperties.redux';

import { sagas as schemaSearch } from '../../lib/Search/state/schemaSearch.redux';

export function* rootSaga() {
  yield all([
    sagas.watchDataCenterInit(),
    sagas.watchFetchSources(),
    sagas.watchFetchSchedules(),
    sagas.watchFetchTdos(),
    sagas.watchFetchRootFolder(),
    sagas.watchFetchFolderOrFiles(),
    sagas.watchCreateNewFolderInCurrentParent(),
    sagas.watchOpenAddNewFolder(),
    sagas.watchCreateNewFolder(),
    sagas.watchEditFolder(),
    sagas.watchMoveTdo(),
    sagas.watchMoveTdoNewFolder(),
    sagas.watchMoveMultipleTDOsNewFolder(),
    sagas.watchDeleteTdo(),
    sagas.watchProcessTdo(),
    sagas.watchViewTdoInfo(),
    sagas.watchEditSourceInfo(),
    sagas.watchViewSourceInfo(),
    sagas.watchViewScheduleInfo(),
    sagas.watchEditScheduleInfo(),
    sagas.watchActivateSchedule(),
    sagas.watchDeactivateSchedule(),
    sagas.watchFoldersAndFilesPanelResult(),
    sagas.watchFileInfo(),
    sagas.watchEditTdoMetadata(),
    sagas.watchDownloadTdo(),
    sagas.getTagsSuggestionsWatcher(),
    sagas.getObjectSuggestionsWatcher(),
    sagas.getLogoSuggestionsWatcher(),
    sagas.getFaceSuggestionsWatcher(),
    sagas.getSourcesSearchTermWatcher(),
    sagas.getFilesSearchWatcher(),
    sagas.abortQueriesWatcher(),
    sagas.getLibraryIdsWatcher(),
    sagas.watchFetchEngineAdaptersStart(),
    sagas.watchEditMetadataFetchTdo(),
    sagas.watchEditMetadataSave(),
    sagas.watchUpdateTdoChannel(),

    schemaPropertiesSagas.watchCreate(),
    schemaPropertiesSagas.watchRead(),
    schemaPropertiesSagas.watchUpdate(),
    schemaPropertiesSagas.watchDelete(),

    schemaSearch.watchCreate(),
    schemaSearch.watchRead(),
    schemaSearch.watchUpdate(),
    schemaSearch.watchDelete(),
  ]);
}
