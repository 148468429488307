const downloadTdoByEntityId = (apiRoot: string, entityId: string, fileName?: string) => {
  // Lifted and adapted from veritone-react-components
  const element = document.createElement('a');
  const id = entityId;
  const href = `${apiRoot}/media-streamer/download/tdo/${id}`;

  element.href = href;
  element.download = fileName || entityId;
  element.click();
};

export { downloadTdoByEntityId };
