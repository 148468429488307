import { combineReducers } from 'redux';
import { SLICE_NAME } from '../../types';
import * as adminCenterState from './admin-center-groups.state';

export const namespace = SLICE_NAME;

export const initialState = {
  [adminCenterState.namespace]: adminCenterState.initialState,
};

export const actions = {
  [adminCenterState.namespace]: adminCenterState.actions,
};

export const rootReducer = combineReducers({
  [adminCenterState.namespace]: adminCenterState.default,
});
