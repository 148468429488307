import { LoadingStatus } from '@aiware/js/interfaces';
import { IOpenIdConnector } from '../../../types';

export const namespace = 'openId';

interface IOpenIdSelector {
  [namespace]: {
    openIdConnectors: IOpenIdConnector[];
    connectedOpenIds: IOpenIdConnector[];
    messages: string;
    status: LoadingStatus;
  };
}
export const selectOpenIdConnectors = (state: IOpenIdSelector): IOpenIdConnector[] => {
  return state?.[namespace]?.openIdConnectors;
};

export const selectConnectedOpenIds = (state: IOpenIdSelector): IOpenIdConnector[] => {
  return state?.[namespace]?.connectedOpenIds;
};

export const selectLoadingStatus = (state: IOpenIdSelector): LoadingStatus => {
  return state?.[namespace]?.status;
};
