import { ITdo, ITdoGraphQL, ITdoSearchResult, MimeType } from '../types';

export function mapTdoFromGraphQL(tdoGraphQL: ITdoGraphQL): ITdo {
  const {
    id,
    name,
    modifiedDateTime,
    engineRuns,
    primaryAsset,
    sourceData,
    thumbnailUrl,
    startDateTime,
    stopDateTime,
    createdDateTime,
    folders,
    jobs,
  } = tdoGraphQL;

  return {
    id,
    name,
    modifiedDateTime,
    engineIds: engineRuns?.records.map(run => run.engine.id) || [],
    engineCategoryIds: engineRuns?.records.map(run => run.engine.categoryId) || [],
    fileType: primaryAsset?.contentType,
    sourceId: sourceData?.source?.id,
    sourceTypeId: sourceData?.source?.sourceTypeId,
    scheduleId: sourceData?.scheduledJobId,
    thumbnailUrl: thumbnailUrl || '',
    details: tdoGraphQL.details || {},
    startDateTime,
    stopDateTime,
    createdDateTime,
    folders,
    jobs,
    primaryAsset,
  };
}

export function mapTdoToGraphQL(tdo: ITdo): ITdoGraphQL {
  const {
    id,
    name,
    modifiedDateTime,
    primaryAsset,
    thumbnailUrl,
    startDateTime,
    stopDateTime,
    createdDateTime,
    folders,
    jobs,
    details,
    engineCategoryIds,
    engineIds,
    sourceId,
    sourceTypeId,
    scheduleId,
  } = tdo;

  return {
    id,
    name,
    modifiedDateTime,
    createdDateTime,
    engineRuns: {
      records: (engineIds ?? []).map((engineId, idx) => ({
        engine: {
          id: engineId,
          categoryId: engineCategoryIds ? engineCategoryIds[idx]! : '',
        },
      })),
      count: engineIds?.length || 0,
    },
    folders,
    primaryAsset: {
      contentType: (primaryAsset?.contentType || '') as MimeType,
      signedUri: primaryAsset?.signedUri || '',
    },
    sourceData: {
      source: {
        id: sourceId || '',
        sourceTypeId: sourceTypeId || '',
      },
      scheduledJobId: scheduleId || '',
    },
    thumbnailUrl: thumbnailUrl || '',
    details,
    startDateTime,
    stopDateTime,
    jobs,
  };
}

export function mapTdoFromSearchResult(tdoSearchResult: ITdoSearchResult): ITdo {
  const {
    fileType,
    mediaId,
    mediaSourceId,
    mediaSourceTypeId,
    mediaStartTime,
    programId,
    programLiveImage,
    metadata = {},
  } = tdoSearchResult;
  return {
    id: mediaId.toString(),
    name:
      (metadata as any)['veritone-file']?.filename ||
      (metadata as any)['veritone-file']?.fileName ||
      mediaId.toString(),
    engineIds: (metadata as any)['veritone-job']?.engines || [],
    engineCategoryIds: (metadata as any)['veritone-job']?.engineCategories || [],
    fileType,
    mediaStartTime,
    sourceId: mediaSourceId?.toString(),
    sourceTypeId: mediaSourceTypeId?.toString(),
    scheduleId: programId?.toString(),
    thumbnailUrl: programLiveImage,
  };
}
