import { FunctionComponent, useEffect, useRef, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ActionPanel from '../shared/ActionPanel';
import { actions } from '../../redux/slices';
import { selectAddNewFolder } from '../../redux/selectors/panel-add-folder';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { selectIsOLPEnabled } from '@aiware/shared/redux';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { EResourceType, PermissionsWidget } from '@aiware/os/admin-center/permissions';

const useStyles = makeStyles()((theme: Theme) => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    padding: '15px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  permissionsContainer: {
    marginTop: '30px',
    marginBottom: 'auto',
  },
  description: {
    fontWeight: 400,
    marginBlockStart: '16px',
    userSelect: 'none',
  },
}));

const AddNewFolder: FunctionComponent<{
  className: string;
}> = props => {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>();
  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const addFolderPanel = useSelector(selectAddNewFolder);
  const { status, parentFolderId } = addFolderPanel;
  const isOLPEnabled = useSelector(selectIsOLPEnabled);
  const [permissionsPayload, setPermissionsPayload] = useState(null);

  const handleChangePermissions = (payload: any) => {
    setPermissionsPayload(payload);
  };

  useEffect(() => {
    if (!permissionsPayload) return;
    // @ts-ignore
    if (!permissionsPayload?.entries?.length) {
      // Reset the payload to null if no groups or users are selected.
      setPermissionsPayload(null);
      return;
    }

    dispatch(actions.panelAddFolder.setPermissionsPayload({ permissions: permissionsPayload }));
  }, [permissionsPayload, dispatch]);

  useEffect(() => {
    if (isActive) {
      inputRef.current && inputRef.current.focus();

      if (status === 'failure') {
        setError('Error creating folder.  Please try again later.');
      }

      if (status === 'idle') {
        setName('');
      }
    } else {
      setName('');
      setError('');
    }
  }, [parentFolderId, status, isActive]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (name && parentFolderId) {
      dispatch(
        actions.panelAddFolder.createNewFolderInCurrentParent({
          name,
        })
      );
    } else {
      setError('Name required');
    }
  };

  const handleClose = () => {
    dispatch(actions.panelAddFolder.closeAddNewFolder());
  };

  return (
    <ActionPanel
      isOpen={!!parentFolderId}
      panelTitle={SHARED_TEXT.newFolder()}
      onClose={handleClose}
      setIsActive={setIsActive}
      {...props}
    >
      <>
        {isActive && (
          <form
            autoComplete="off"
            noValidate
            className={classes.formRoot}
            onSubmit={handleSubmit}
            data-test="dc-add-new-folder-form"
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              error={!!error}
              id="newFolderName"
              label={SHARED_TEXT.folderName()}
              variant="outlined"
              value={name}
              onChange={e => {
                setName(e.target.value);
                setError('');
              }}
              inputRef={inputRef}
              helperText={error}
            />
            {isOLPEnabled && (
              <div className={classes.permissionsContainer}>
                <Typography data-testid="new-folder-ui-permissions-widget-title" variant="h2">
                  <FormattedMessage
                    id="ui-permissions-widget-title-new-folder"
                    defaultMessage="Permissions"
                    description="Permissions widget title"
                  />
                </Typography>
                <Typography
                  data-test="data-center-new-folder-description"
                  variant="body2"
                  paragraph
                  className={classes.description}
                >
                  <FormattedMessage
                    id="ui-permissions-widget-description"
                    defaultMessage="Manage who can access and the permissions for this folder."
                    description="Permissions widget description"
                  />
                </Typography>
                <PermissionsWidget
                  onGetPermissionPayload={handleChangePermissions}
                  permissionType={'TDO'}
                  parentPanelId={'DATA_CENTER'}
                  preloadPermissionsFrom={{
                    resourceType: EResourceType.Folder,
                    resourceId: parentFolderId || '',
                  }}
                />
              </div>
            )}
            <div className={classes.buttonGroup}>
              <Button data-testid="folders-files-panel-new-folder-cancel" onClick={handleClose}>
                {SHARED_TEXT.cancel()}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={status === 'pending' || !name}
              >
                {SHARED_TEXT.create()}
              </Button>
            </div>
          </form>
        )}
      </>
    </ActionPanel>
  );
};

export default AddNewFolder;
