import { all, call, put, select } from 'redux-saga/effects';
import { actions } from '../../slices';
import * as api from '../../../api';
import { rootFolderSelectors, selectApiConfigs } from '../../selectors';
import { ICreateTdoResponse } from '../../../types';
import { FileItemField } from '../../slices/files-selected-state';
import { SdkError, sdkEventManager } from '@aiware/js/sdk';
import { SdkEvents } from '@aiware/js/interfaces';
import { hidePanel, panelsSelector } from '@aiware/js/panel';
import { createThumbnailJob } from '../../../api/jobs';
import { selectPermissions, selectUploadedFilesTdoIds } from '../../selectors/files-selected-state';
import { TAddACLsInput, setACLsOnResources } from '@aiware/os/admin-center/permissions';

import {
  MessageSeverity,
  showMessage,
  preferredLanguageSelector,
  setDuration,
  selectIsOLPEnabled,
} from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { safeFormatMessage } from '../../../helpers/message.helpers';
import { getCmsRootFolder } from '../../../api';

export function* createTDOs(action: ReturnType<typeof actions.uiState.tdoCreateStart>) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  //@ts-ignore
  const locale = yield select(preferredLanguageSelector);
  const formatMessage = safeFormatMessage(AIWareFormatMessage(locale));
  //@ts-ignore
  const isOLPEnabled = yield select(selectIsOLPEnabled);

  // may add this back in the future
  //@ts-ignore
  const dataCenterRootFolder = yield call(getCmsRootFolder, apiConfigs);

  const rootFolder: ReturnType<typeof rootFolderSelectors.rootFolderSelector> = yield select(
    rootFolderSelectors.rootFolderSelector
  );

  const files = action.payload.filesSelected;
  const updateFile = (id: string, values: FileItemField, data?: ICreateTdoResponse) => {
    put(
      actions.filesSelectedState.updateFile({
        id,
        ...values,
      })
    );
    return {
      values,
      data,
    };
  };

  const handleThumbnailGeneration = (tdoId: string) => {
    try {
      createThumbnailJob(apiConfigs, tdoId).catch(
        er => new Error(er?.message || 'Error while executing createThumbnailJob function')
      );
    } catch (e) {
      console.log('Error while launching thumbnail generation job', e);
    }
  };

  try {
    const createTDOPromises = files.reduce((accumulator, file) => {
      // a microscopic check if file is not loading or failed
      if (file.status === 'idle') {
        //@ts-ignore
        accumulator[file.id] = call(api.createTDOWithAsset, apiConfigs, {
          contentType: file.type,
          name: file.name,
          uri: file.unsignedUrl,
          parentFolderId: action.payload.useDefaultRootFolder ? dataCenterRootFolder?.id : rootFolder!.id,
          startDateTime: new Date().toISOString(),
          size: file.size,
          meta: file?.meta,
          contentTemplates: file.contentTemplates,
        });
      }

      return accumulator;
    }, {});
    //@ts-ignore
    const responses = yield all(createTDOPromises);
    const permissionsTdoIds: string[] = [];

    const updateTDOOnFilesPromises = Object.keys(responses).map(fileId => {
      const tdoId = responses[fileId]?.data?.id;
      const data = responses[fileId]?.data;
      const errors = responses[fileId].errors;

      if (data?.details) {
        data.name = data.details?.veritoneFile?.filename || data.details?.veritoneFile?.fileName || data.name;
      }

      if (!tdoId || errors) {
        sdkEventManager.dispatch(SdkEvents.tdoCreated, new SdkError(errors || 'TDO creation failed!'), {
          fileId,
          tdoId: tdoId,
        });

        return updateFile(fileId, { tdoStatus: 'failure' });
      }

      // Fire the thumbnail engine
      //
      // doing it outside of the scope so that we can handle
      // errors separately and not fail the whole process
      !action.payload?.skipThumbnail && handleThumbnailGeneration(tdoId);

      sdkEventManager.dispatch(SdkEvents.tdoCreated, null, {
        fileId,
        tdoId,
      });

      return updateFile(fileId, { tdoId, tdoStatus: 'idle' }, data);
    });

    //@ts-ignore
    const createdTDOs = yield all(updateTDOOnFilesPromises);
    const newFilesCreated = [];

    for (let x = 0; x < createdTDOs.length; x++) {
      if (createdTDOs[x].values.tdoStatus === 'idle') {
        const tdoId = createdTDOs[x].values.tdoId;
        newFilesCreated.push({ tdoId: tdoId, file: createdTDOs[x].data, folderId: rootFolder!.id });
        permissionsTdoIds.push(tdoId);
      }
    }
    yield put({ type: 'DATA_CENTER_IMPORTER_RESPONSE', payload: newFilesCreated });

    // Add tdoIds to state
    yield put(actions.filesSelectedState.setUploadedFilesTdoIds({ tdoIds: permissionsTdoIds }));

    // Kick off the permissions call and pass in the tdoIds
    if (isOLPEnabled) {
      yield call(applyPermissionsToTDOs);
    }

    yield put(actions.uiState.tdoCreateSucceed());
    yield put(setDuration(3000));

    const message = formatMessage({
      id: 'os-data-center-importer.snackbar.importerSuccess',
      defaultMessage: 'Your file was successfully uploaded!',
      description: 'The success message pops up when file upload succeeds.',
    });

    yield put(
      showMessage({
        content: message,
        severity: MessageSeverity.Success,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.uiState.tdoCreateFailed());
  }
}

export function* closeImporterPanel() {
  //@ts-ignore
  const panels = yield select(panelsSelector);
  const importerPanel = panels.find((p: any) => p.microFrontend.name === 'DATA_CENTER_IMPORTER');
  if (importerPanel && importerPanel.panelId && !importerPanel?.microFrontend?.config?.requestId) {
    const { panelId } = importerPanel;
    yield put(hidePanel(panelId));
  }
}

export function* getUrlBuckets(numberOfFiles: number) {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

  //@ts-ignore
  const buckets = yield call(api.getSignedWritableUrls, apiConfigs, numberOfFiles);

  return buckets;
}

export function* applyPermissionsToTDOs() {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  //@ts-ignore
  const tdoIds = yield select(selectUploadedFilesTdoIds);
  //@ts-ignore
  const permissions = yield select(selectPermissions);

  const permissionsWithTdoIds: TAddACLsInput = {
    ...permissions,
    ids: tdoIds,
    type: 'TDO',
  };

  try {
    yield call(setACLsOnResources, apiConfigs, permissionsWithTdoIds);

    yield put(actions.uiState.tdoPermissionsSucceeded());
  } catch (error) {
    yield put(actions.uiState.tdoPermissionsFailed());
  }
}
