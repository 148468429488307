import createSvgIcon from '../createSvgIcon';
export const FlowCenter2 = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.8335 1.83325V3.66659H6.41683V7.33325H1.8335V9.16658H6.41683C7.43433 9.16658 8.25016 8.35075 8.25016 7.33325V6.41659H12.8335V15.5833H8.25016V14.6666C8.25016 13.6491 7.43433 12.8333 6.41683 12.8333H1.8335V14.6666H6.41683V18.3333H1.8335V20.1666H6.41683C7.43433 20.1666 8.25016 19.3508 8.25016 18.3333V17.4166H12.8335C13.851 17.4166 14.6668 16.6008 14.6668 15.5833V11.9166H20.1668V10.0833H14.6668V6.41659C14.6668 5.39909 13.851 4.58325 12.8335 4.58325H8.25016V3.66659C8.25016 2.64909 7.43433 1.83325 6.41683 1.83325H1.8335Z"
      fill="#555F7C"
    />
  </svg>,
  'FlowCenter2'
);
