import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Avatar, AvatarGroup, Badge, Button, Popper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { mountPanel } from '@aiware/js/panel';
import { Group } from '@mui/icons-material';
import { actions } from '../../redux/slices';
import {
  EMemberType,
  EResourceType,
  TACEMember,
  TAddACLsInput,
  TEntry,
  TPermissionOptionSDK,
} from '../../types';
import { selectExistingPermissionsByResource, selectPermissionSets } from '../../redux';
import { TFactoryMember } from '../permissions-panel';
import { TUserGroup } from '@aiware/os/admin-center/groups';
import { BrowseModule, selectIsFullscreenEnabled } from '@aiware/shared/redux';

const useStyles = makeStyles()((_theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    marginTop: '20px',
  },
  description: {
    fontWeight: 400,
    marginBlockStart: '16px',
    userSelect: 'none',
  },
  tooltip: {
    background: 'rgba(0,0,0,0.6)',
    padding: '3px 8px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 0 #000',
    transform: 'translateY(8px)',
  },
}));

export const PermissionsWidget: FunctionComponent<{
  style?: React.CSSProperties;
  onGetPermissionPayload: (payload: any) => void;
  permissionType: string;
  parentPanelId: string;
  preloadPermissionsFrom?: {
    resourceType: EResourceType;
    resourceId: string;
  };
  browseComponent?: BrowseModule;
}> = ({
  style = {},
  onGetPermissionPayload,
  permissionType,
  parentPanelId,
  preloadPermissionsFrom,
  browseComponent,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [groupsDataArray, setGroupsDataArray] = useState<TACEMember[]>([]);
  const [generatedPayload, setGeneratedPayload] = useState<TAddACLsInput | null>(null);
  const [permissionOptions, setPermissionOptions] = useState<TPermissionOptionSDK[] | null>(null);
  const [preloadId, setPreloadId] = useState<string | undefined>(undefined);
  const [preloadType, setPreloadType] = useState<EResourceType | undefined>(undefined);
  const preloadedPermissions = useSelector(selectExistingPermissionsByResource(preloadPermissionsFrom));
  const permissionSets = useSelector(selectPermissionSets) || [];
  const isFullScreen = useSelector(selectIsFullscreenEnabled);

  useEffect(() => {
    onGetPermissionPayload(generatedPayload);
  }, [generatedPayload]);

  useEffect(() => {
    /**
     * If the component that includes PermissionsWidget creates the preloadPermissionsFrom
     * object on the fly, this effect gets called on every rerender. Tracking the type and
     * id in local state so we can compare and only fetch new permissions when the type or
     * id changes, instead of on every rerender.
     */
    if (
      preloadPermissionsFrom?.resourceType !== preloadType ||
      preloadPermissionsFrom?.resourceId !== preloadId
    ) {
      // Resource changed. Reset state and replace preloaded permissions.
      setPreloadId(preloadPermissionsFrom?.resourceId);
      setPreloadType(preloadPermissionsFrom?.resourceType);
      setGeneratedPayload(null);
      setPermissionOptions(null);
      setGroupsDataArray([]);
      if (preloadPermissionsFrom) {
        if (!preloadedPermissions) {
          dispatch(actions.permissionsWidget.fetchEntriesStart(preloadPermissionsFrom));
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadPermissionsFrom]);

  useEffect(() => {
    if (preloadedPermissions && preloadedPermissions.status === 'success') {
      const permissionSetIds: string[] = [];
      const groups: TFactoryMember[] = [];
      const entries: TEntry[] = [];
      preloadedPermissions.entries?.forEach(entry => {
        if (groups.findIndex(m => m.id === entry.member.id) === -1) {
          groups.push(entry.member as TFactoryMember);
        }
        if (!permissionSetIds.includes(entry.permissionSet.permissionId)) {
          permissionSetIds.push(entry.permissionSet.permissionId);
        }
        entries.push({
          member: {
            id: entry.member.id || '',
            memberType:
              entry.member.memberType === 'AuthGroup'
                ? EMemberType.Group
                : (entry.member.memberType as EMemberType),
          },
          permissionSetID: entry.permissionSet.permissionId,
        });
      });
      setGeneratedPayload({
        entries,
        ids: [],
        type: 0,
      });
      setGroupsDataArray(groups);
      const options = permissionSetIds
        .map(id => {
          const permissionSet = permissionSets.find(ps => ps.id === id);
          if (!permissionSet) {
            return null;
          }
          return {
            label: permissionSet.name,
            value: permissionSet.id,
            permissions: permissionSet.permissions,
          };
        })
        .filter(ps => !!ps);
      setPermissionOptions(options as TPermissionOptionSDK[]);
    }
  }, [preloadedPermissions]);

  const handleClickOpenPanel = () => {
    dispatch(actions.adminCenterPermissions.fetchOrgPermissionSetsStart());

    const handleGeneratePermissionsObject = (payload: any, members: any[], permissionObj: any[]) => {
      setGroupsDataArray(members);
      setGeneratedPayload(payload);
      setPermissionOptions(permissionObj);
    };

    const microFrontend = {
      name: 'PERMISSIONS_PANEL',
      config: {
        name: 'Permissions',
        permissionType: permissionType,
        onGeneratePermissionsObject: handleGeneratePermissionsObject,
        prefillMembers: groupsDataArray,
        prefillPayload: generatedPayload,
        isFullScreen: isFullScreen,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen || browseComponent === BrowseModule.Investigate ? 0 : 80,
      size: 'medium',
      height: 'calc(100vh - 55px)',
      parentPanelId: parentPanelId,
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: 'PERMISSIONS_PANEL',
        microFrontend,
        panelConfig,
      })
    );
  };

  // Avatar Group Tooltips
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const leaveTimer = useRef<undefined | number>();
  const leaveDelay = 300;

  const handleLeave = () => {
    clearTimeout(leaveTimer.current);
    // @ts-ignore
    leaveTimer.current = setTimeout(() => {
      setAnchorEl(null);
    }, leaveDelay);
  };

  const handleEnter = (event: React.MouseEvent<Element, MouseEvent>) => {
    clearTimeout(leaveTimer.current);
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  const renderAvatars = () => {
    if (!groupsDataArray.length) return <></>;
    const avatars = [];
    for (const group of groupsDataArray) {
      const avatar = {
        displayType: 'group',
        parentGroup: null,
        initials: '',
        fullName: (group as TUserGroup).name,
        permission: null as string | null | undefined,
        groupCount: (group as TUserGroup).memberCount,
        imageUrl: null,
        id: group.id,
      };

      // Search the payload for the matching group
      for (const entry of generatedPayload?.entries ?? []) {
        if (entry?.member?.id === avatar.id) {
          const { permissionSetID } = entry;
          const permissionSet = permissionOptions?.find((a: any) => a?.value === permissionSetID);
          avatar.permission = permissionSet?.label;
        }
      }
      avatars.push(avatar);

      for (const { member } of (group as TUserGroup).members.records) {
        const avatar = { ...member } as any;
        avatar.displayType = 'user';
        avatar.parentGroup = (group as TUserGroup).name;
        avatar.initials = avatar.firstName[0] + avatar.lastName[0];
        avatar.fullName = avatar.firstName + ' ' + avatar.lastName;
        avatar.groupCount = (group as TUserGroup).memberCount;
        // TODO: Update this once individual person sharing is available.
        //avatars.push(avatar);
      }
    }

    const stringToColor = (string: string) => {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = '#';

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    };

    const getNameString = (name: string) => {
      if (typeof name !== 'string' || !name) return '';
      if (name.split(' ').length === 1) {
        return name[0]?.[0]?.toUpperCase();
      } else {
        return `${name.split(' ')[0]?.[0]?.toUpperCase()}${name.split(' ')[1]?.[0]?.toUpperCase()}`;
      }
    };

    return avatars.map((person, index) => {
      const hasImage = !!person.imageUrl;
      if (person.displayType === 'group') {
        return (
          <Badge
            data-test="os-admin-center.permissions.permissions-widget"
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Group
                sx={{
                  fontSize: '14px',
                  background: 'white',
                  borderRadius: '50%',
                  padding: '3px',
                  boxSizing: 'content-box',
                  boxShadow: '1px 2px 3px rgb(0 0 0 / 31%)',
                  pointerEvents: 'none',
                }}
              />
            }
          >
            <Avatar
              alt={person.fullName}
              src={person?.imageUrl ? person.imageUrl : undefined}
              onMouseEnter={handleEnter}
              onMouseLeave={handleLeave}
              data-name={person.fullName}
              data-permission={person.permission}
              data-display={person.displayType}
              data-count={person.groupCount}
              data-parent={person.parentGroup}
            >
              {getNameString(person.fullName)}
            </Avatar>
          </Badge>
        );
      } else {
        return (
          <Avatar
            alt={person.fullName}
            src={person?.imageUrl ? person.imageUrl : undefined}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            data-name={person.fullName}
            data-permission={person.permission}
            data-display={person.displayType}
            data-count={person.groupCount}
            data-parent={person.parentGroup}
            sx={{ cursor: 'pointer', bgcolor: hasImage ? '' : stringToColor(person.fullName) }}
            children={!hasImage && getNameString(person.fullName)}
          />
        );
      }
    });
  };

  const capString = (str: string) => {
    return (
      str
        ?.split(' ')
        .map(char => {
          return char[0]?.toUpperCase() + char.slice(1);
        })
        .join(' ') ?? ''
    );
  };
  const renderTooltip = () => {
    // @ts-ignore
    if (!anchorEl?.dataset) return <></>;

    // @ts-ignore
    const { dataset } = anchorEl;

    // @ts-ignore
    const { name, permission, parent, display, count } = dataset;

    let tooltipString = '';

    if (display === 'group') {
      tooltipString = `${capString(name)}: ${count} users assigned permission set "${capString(permission)}"`;
    } else {
      tooltipString = `${name} will receive access via group ${parent}`;
    }
    if (!name) {
      return <></>;
    }
    return (
      <Typography variant={'caption'} sx={{ color: '#fff', fontSize: '11px' }}>
        {tooltipString}
      </Typography>
    );
  };
  return (
    <div className={classes.root} style={style}>
      {groupsDataArray.length > 0 && (
        <div>
          <Popper
            open={open}
            anchorEl={anchorEl}
            onMouseEnter={() => {
              clearTimeout(leaveTimer.current);
            }}
            onMouseLeave={handleLeave}
          >
            <div className={classes.tooltip}>{renderTooltip()}</div>
          </Popper>
          <AvatarGroup max={5} spacing={-15} sx={{ justifyContent: 'flex-end', marginBottom: '15px' }}>
            {renderAvatars()}
          </AvatarGroup>
        </div>
      )}

      <Button onClick={handleClickOpenPanel}>
        <FormattedMessage
          id="ui-permissions-widget-button"
          defaultMessage="Manage Permissions"
          description="Permissions widget description"
        />
      </Button>
    </div>
  );
};
