import { FunctionComponent } from 'react';
import { useStyles } from '../../useStyles';
import { IFileVm, InfiniteWrapperItemType } from '../../../../types';
import Divider from '@mui/material/Divider';
import TdoTableFluffyRow from './TdoTableFluffyRow';
import TdoTableRow from './TdoTableRow';

const TdoRows: FunctionComponent<{
  index: number;
  items: InfiniteWrapperItemType;
  rowHeight: number;
  isFluffy: boolean;
}> = ({ index, items, rowHeight, isFluffy }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.row}>
      {isFluffy ? (
        <TdoTableFluffyRow item={(items as IFileVm[])[index]!.file} rowHeight={rowHeight} />
      ) : (
        <TdoTableRow item={(items as IFileVm[])[index]!.file} rowHeight={rowHeight} />
      )}
      <Divider className={classes.divider} />
    </div>
  );
};

export default TdoRows;
