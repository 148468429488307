import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux/selectors';

const THUMBNAIL_ENGINE_ID = 'fd891cb5-ff80-48ae-b5cd-ac3c7d190d55';

export async function createThumbnailJob(apiConfigs: ReturnType<typeof selectApiConfigs>, tdoId: string) {
  const operationName = 'createThumbailJob';
  const name = 'createJob';
  const query = `
    mutation ${operationName}($input: CreateJob) {
      ${name}(input: $input) {
        id
      }
    }
  `;

  const variables = {
    input: {
      targetId: tdoId,
      tasks: [
        {
          engineId: THUMBNAIL_ENGINE_ID,
        },
      ],
    },
  };
  const result = await baseGraphQLApiWithError<{
    [name]: {
      id: string;
    };
  }>({
    query,
    variables,
    operationName,
    ...apiConfigs,
  });

  return result[name]?.id;
}
