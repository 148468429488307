import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFile, IFilesSelected, SLICE_NAME, IFileMeta, IContentTemplate } from '../../types';

export const namespace = 'filesSelectedState';

export type State = {
  files: IFile[];
  tdoIds: string[];
  permissions: unknown;
};

export const initialState: State = {
  files: [],
  tdoIds: [],
  permissions: {},
};

export type FileItemField = { [key in keyof IFile]?: IFile[key] };

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    setFilesSelected(state, { payload }: PayloadAction<{ filesSelected: IFilesSelected; sdkFiles: File[] }>) {
      state.files = [...state.files, ...payload.filesSelected];
    },
    deleteFileSelected(state, { payload }: PayloadAction<{ id: string }>) {
      const newFiles = state.files.filter(file => {
        return file.id !== payload.id;
      });
      state.files = newFiles;
    },
    deleteFailedFiles(state) {
      const newFiles = state.files.filter(file => {
        return file.status !== 'failure';
      });
      state.files = newFiles;
    },
    clearFilesSelected(state) {
      state.files = [];
    },
    updateFile(state, { payload }: PayloadAction<FileItemField>) {
      const targetIndex = state.files.findIndex(file => file.id === payload.id);

      if (targetIndex !== -1) {
        Object.keys(payload).forEach(key => {
          //@ts-ignore
          state.files[targetIndex][key] = payload[key];
        });
      }
    },
    retryFileUpload(state, { payload }: PayloadAction<{ filesSelected: IFilesSelected }>) {
      console.log('Retrying the files:', payload.filesSelected.map(file => file.id).join('/n'));
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abortFileUpload(state, action: PayloadAction<IFile>) {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addMetadataAction(
      state,
      action: PayloadAction<{ id: string; meta: IFileMeta; contentTemplates?: IContentTemplate[] }>
    ) {
      console.log('nothing');
    },
    addMetadataToSelectedFile(
      state,
      action: PayloadAction<{ id: string; meta: IFileMeta; contentTemplates?: IContentTemplate[] }>
    ) {
      const payload = action.payload;
      const id = payload.id;
      const meta = payload.meta;
      const contentTemplates = payload.contentTemplates || [];

      for (const file of state.files) {
        if (file.id === id) {
          file.meta = meta;
          file.contentTemplates = contentTemplates;
        }
      }
    },
    setUploadedFilesTdoIds(state, action: PayloadAction<{ tdoIds: string[] }>) {
      const { tdoIds } = action.payload;

      state.tdoIds = tdoIds;
    },
    setPermissionsPayload(state, action: PayloadAction<{ permissions: unknown }>) {
      const permissions = action.payload;

      state.permissions = permissions;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
