// TODO: will refactor this and Importer Mime Type constants to a common place
export const fileTypeFilterValues = {
  audio: {
    values: [
      'audio/mp4',
      'audio/mpeg',
      'audio/wav',
      'audio/x-wav',
      'audio/midi',
      'audio/webm',
      'audio/aac',
      'audio/flac',
    ],
  },
  video: {
    values: [
      'video/quicktime',
      'video/mp4',
      'video/wmv',
      'video/mpeg',
      'video/ogg',
      'video/webm',
      'video/x-m4v',
      'video/x-ms-wmv',
      'video/x-msvideo',
    ],
  },
  image: {
    values: ['image/jpeg', 'image/png', 'image/tiff', 'image/gif'],
  },
  text: {
    values: ['text/plain', 'text/html', 'text/csv', 'text/plain; charset=utf-8'],
  },
  other: {
    values: [
      'application/json',
      'application/xml',
      'application/pdf',
      'application/rtf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/smil+xml',
      'application/ttml+xml',
      'application/vnd.veritone.aion+json',
      'application/vnd.ms-outlook',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/x-flv',
      'application/x-www-form-urlencoded',
      'message/rfc822',
    ],
  },
};
