export enum EStatusPillType {
  engine = 'engine',
  engineBuild = 'engineBuild',
  schema = 'schema',
  application = 'application',
  library = 'library',
  flow = 'flow',
  applicationContentMenuExtension = 'applicationContentMenuExtension',
  applicationView = 'applicationView',
  package = 'package',
  sdo = 'sdo',
  tdo = 'tdo',
  scheduledJob = 'scheduledJob',
  dagTemplate = 'dagTemplate',
  nodeRedPalette = 'nodeRedPalette',
  enabledStatus = 'enabledStatus',
  orgStatus = 'orgStatus',
}

export const STATUS_COLORS = {
  [EStatusPillType.orgStatus]: {
    active: {
      text: 'rgba(56, 142, 60, 1)',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    deleted: {
      text: 'rgba(33, 33, 33, 1)',
      background: 'rgba(33, 33, 33, 0.1)',
    },
  },
  [EStatusPillType.enabledStatus]: {
    enabled: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    disabled: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
  },
  [EStatusPillType.engine]: {
    active: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    disabled: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
    pending: {
      text: '#F57C00',
      background: 'rgba(249, 168, 37, 0.2)',
    },
    deleted: {
      text: '#B71C1C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    draft: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
    ready: {
      text: '#0D62D2',
      background: 'rgba(13, 98, 210, 0.2)',
    },
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.engineBuild]: {
    fetching: {},
    invalid: {
      text: '#B71C1C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    available: {
      text: '#388E3C',
      background: 'rgba(96, 125, 180, 0.3)',
    },
    pending: {
      text: '#F57C00',
      background: 'rgba(249, 168, 37, 0.2)',
    },
    disapproved: {
      text: '#212121',
      background: 'rgba(255, 161, 104, 0.4)',
    },
    approved: {
      text: '#0D62D2',
      background: 'rgba(13, 98, 210, 0.2)',
    },
    deploying: {
      text: '#F57C00',
      background: 'rgba(20, 96, 121, 0.2)',
    },
    deployed: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.schema]: {
    published: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    inactive: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
    deleted: {
      text: '#B71C1C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    draft: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
    _default: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
  },
  [EStatusPillType.application]: {
    active: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    draft: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
    deleted: {
      text: '#B71C1C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    pending: {
      text: '#F57C00',
      background: 'rgba(249, 168, 37, 0.2)',
    },
    rejected: {
      text: '#388E3C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    approved: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    disabled: {
      text: '#B71C1C',
      background: 'rgba(33, 33, 33, 0.1)',
    },
    _default: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
  },
  [EStatusPillType.flow]: {
    active: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    disabled: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
    pending: {
      text: '#F57C00',
      background: 'rgba(249, 168, 37, 0.2)',
    },
    deleted: {
      text: '#B71C1C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    draft: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
    ready: {
      text: '#0D62D2',
      background: 'rgba(13, 98, 210, 0.2)',
    },
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.applicationContentMenuExtension]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.package]: {
    active: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    approved: {
      text: '#0D62D2',
      background: 'rgba(13, 98, 210, 0.2)',
    },
    published: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    pending: {
      text: '#F57C00',
      background: 'rgba(249, 168, 37, 0.2)',
    },
    deactivated: {
      text: '#B71C1C',
      background: 'rgba(183, 28, 28, 0.2)',
    },
    draft: {
      text: '#4F5863',
      background: '#F7F7F7',
    },
    _default: {
      text: '#212121',
      background: 'rgba(33, 33, 33, 0.1)',
    },
  },
  [EStatusPillType.applicationView]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.scheduledJob]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.dagTemplate]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.nodeRedPalette]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.library]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.tdo]: {
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
  [EStatusPillType.sdo]: {
    published: {
      text: '#388E3C',
      background: 'rgba(27, 94, 32, 0.2)',
    },
    draft: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
    _default: {
      text: '#4F5863',
      background: '#FFFFFF',
    },
  },
};

export const DEFAULT_STATUS_COLOR = {
  text: '#4F5863',
  background: '#FFFFFF',
};
