import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import * as Sentry from '@sentry/react';
import { bootstrapSdk, mountWidget } from '@aiware/js/sdk';
import { AvailableWidgets } from '@aiware/js/interfaces';
import { SdkEvents } from '@aiware/js/interfaces';
import { AiwareFrameLink, EFrameLinkEvents } from '@aiware/js/frame-link';
import { store } from '@aiware/shared/store';
import { desktopAiwareLogo } from '@aiware/shared/assets';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { MessageSeverity, showMessage } from '@aiware/shared/redux';
import {
  fetchTokenFromSession,
  stashTokenInSession,
  userSelector,
  authStatusSelector,
} from '@aiware/shared/redux';
import { applicationSelector, selectAppFromStore, setApplicationInStore } from '@aiware/os/app-bar-panel';
import { InvalidAppModal } from './invalid-app-modal';
import { WallpaperManagement } from '../components/wallpaper-management';
import { useStyles } from './useStyles';
import { getAiWareDesktopModule } from '../redux';

export const desktopIconsColor = '#555F7C';

// lazy load the registry and then bootstrap
const loadSdkTools = async () => {
  const { registry, registryLookup } = await import('@aiware/js/registry');
  return bootstrapSdk({
    registry,
    registryLookup,
  });
};

function usePrevious(value: any): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

type TProps = {
  appSlug?: string;
  baseUrl?: string;
};

interface Window {
  analytics?: {
    identify?: (id: string, traits: object) => void;
    group?: (id: string | number, traits: object) => void;
  };
}

export const App = ({ appSlug, baseUrl }: TProps) => {
  const selectedAppInStore = useSelector(selectAppFromStore);
  const prevSelectedAppInStore = usePrevious(selectedAppInStore);
  const appSlice = useSelector(applicationSelector);
  const iframeRef = useRef(null);
  const navigate = useNavigate();
  const [initted, setInitted] = useState(false);
  const [invalidAppSlug, setInvalidAppSlug] = useState(false);
  const [frameLink, setFrameLink] = useState<AiwareFrameLink | null>(null);
  const intl = useIntl();
  const user = useSelector(userSelector);
  const [loadingError, setLoadingError] = useState<number>(0);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const prevReloadTrigger = usePrevious(reloadTrigger);
  const goHome = () => {
    dispatch(setApplicationInStore(null));
    navigate('/');
  };

  const authStatus = useSelector(authStatusSelector);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const authToken = fetchTokenFromSession() || query.get('authToken');
    const port = window.location.port ? `:${window.location.port}` : '';
    const oAuthRedirectUri = `${window.location.protocol}//${window.location.hostname}${port}`;

    loadSdkTools().then(({ init, aiwareEvents }) => {
      init(
        {
          applicationId: 'e4739d44-53d2-4153-b55f-5e246fc989b1',
          baseUrl: baseUrl ?? '',
          authToken: authToken ?? '',
          handleAuth: false,
          oAuthRedirectUri,
          knowledgeBaseUrl: 'https://support.veritone.com/s/topic/0TO4U000000bwFLWAY/',
          logoutRedirectUrl: '/ui/auth/login',
          disableAppUnavailableModal: true,
        },
        err => {
          if (err) {
            setSdkError(err as string);
          }
          setInitted(true);

          document.title = `aiWARE Desktop`;
          aiwareEvents.on(SdkEvents.openSupport, async () => {
            try {
              const { initChatWithSupport, chatWithSupport } = await import(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                /* webpackIgnore: true */ 'https://get.aiware.com/veritone-support/latest/2/index.js'
              );
              await initChatWithSupport();
              await chatWithSupport();
            } catch (err) {
              console.log(err);
              Sentry.captureException(err);
              Sentry.captureMessage('Our chat service is currently unavailable');
              dispatch(
                showMessage({
                  content: isEqual(process.env.NODE_ENV, 'development')
                    ? 'Oops! Our chat service is currently unavailable. Please contact us at support@veritone.com for assistance. Thank you for your understanding!'
                    : intl.formatMessage({
                        id: 'desktop-chat-service-error-part1',
                        defaultMessage:
                          'Oops! Our chat service is currently unavailable. Please contact us at support@veritone.com for assistance. Thank you for your understanding! ',
                        description: 'Chat service error message',
                      }),
                  severity: MessageSeverity.Error,
                })
              );
            }
          });
        }
      );
    });

    // stash the oauth session token
    const stashFunc = () => {
      if (authToken) {
        stashTokenInSession(authToken);
      }
    };
    window.addEventListener('beforeunload', stashFunc);

    return () => {
      stashFunc();
      window.removeEventListener('beforeunload', stashFunc);
    };
  }, []);

  useEffect(() => {
    // if auth fails, redirect to login page
    if (authStatus === 'failure') {
      navigate('/ui/auth/login');
      // only loads app bar if auth is successful
    } else if (authStatus === 'success') {
      loadSdkTools().then(({ aiwareEvents }) => {
        mountWidget(
          {
            name: AvailableWidgets.APP_BAR,
            elementId: 'app-bar',
            config: {
              logoSrc: desktopAiwareLogo,
              onClickLogo: goHome,
              backgroundColor: 'white',
              iconColor: desktopIconsColor,
              help: true,
              showDashboardIcon: false,
              disableGoHome: true,
            },
          },
          () => {
            aiwareEvents.on(SdkEvents.tokenUpdated, tokenErr => {
              if (tokenErr) {
                setSdkError(tokenErr.message);
              }
            });
          }
        );
      });
    }
  }, [authStatus]);

  useEffect(() => {
    const userId = user?.id || user?.userId;
    if (typeof window !== 'undefined' && userId) {
      const visitor = {
        id: userId,
        email: user?.email || (user as any)?.userName || '',
        name: `${user?.firstName || (user as any)?.kvp?.firstName} ${
          user?.lastName || (user as any)?.kvp?.lastName
        }`,
      };

      const account = {
        id: user?.organization?.organizationId || '',
        name: user?.organization?.name || user?.organization?.organizationName || '',
      };

      if (visitor.id && account.id) {
        (window as Window)['analytics']?.identify?.(visitor.id, {
          id: visitor.id,
          name: visitor.name,
          email: visitor.email,
        });

        (window as Window)['analytics']?.group?.(account.id, {
          id: account.id,
          name: account.name,
          organizationName: account.name,
        });
      }

      Sentry.configureScope(scope => {
        scope.setUser({
          id: visitor.id,
          email: visitor.email,
          organizationId: account?.id,
          organization: account?.name,
        });
      });
    }
  }, [user]);

  useEffect(() => {
    if (appSlug && !initted) {
      window.sessionStorage.setItem('appSlug', appSlug);
    } else {
      const slug = window.sessionStorage.getItem('appSlug');
      if (slug) {
        window.sessionStorage.setItem('appSlug', '');
        navigate(`/ui/app/${slug}`);
      }
    }
  }, [initted]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (appSlice && appSlice.application && appSlice.application.length && appSlug && !selectedAppInStore) {
      const appInURL = appSlice.application.find(app => app.key === appSlug || app.id === appSlug);
      if (appInURL) {
        dispatch(setApplicationInStore(appInURL));
      } else {
        setInvalidAppSlug(true);
      }
    }
  }, [appSlice]);

  const onReload = () => {
    setReloadTrigger(prev => !prev);
  };

  useEffect(() => {
    if (
      (selectedAppInStore &&
        selectedAppInStore.applicationUrl &&
        selectedAppInStore.applicationUrl !== (prevSelectedAppInStore || {})['applicationUrl']) ||
      prevReloadTrigger !== reloadTrigger
    ) {
      // THIS IS AN EXAMPLE ERROR ACTION
      const errorAction = (message: string) => {
        dispatch(
          showMessage({
            content: message,
            severity: MessageSeverity.Error,
          })
        );
      };
      AiwareFrameLink.init({
        origin: selectedAppInStore?.applicationUrl,
        errorAction,
        intl,
        store,
        subscribers: [],
      }).then(link => {
        link.on(EFrameLinkEvents.init, () => {
          // err => {
          // if (err) {
          //   setLoadingError(prev => {
          //     if (prev < 2) {
          //       return prev + 1;
          //     }
          //     return prev;
          //   });
          // } else {
          setLoadingError(0);
          setFrameLink(link);
          // }
        });
      });
    }
  }, [selectedAppInStore, prevSelectedAppInStore, reloadTrigger]);

  const [currentWallpaper, setCurrentWallpaper] = useState<string>();
  const [sdkError, setSdkError] = useState<string>();
  const { classes } = useStyles({ currentWallpaper });

  const closeInvalidAppModal = () => {
    setInvalidAppSlug(false);
    if (window.sessionStorage.getItem('appSlug')) {
      // wipe out invalid slug
      window.sessionStorage.setItem('appSlug', '');
    }
    navigate('/');
  };

  const reloadButton = (
    <Button variant="contained" onClick={onReload}>
      <FormattedMessage
        id="desktop.loading-child-frame.reload"
        defaultMessage="Reload"
        description="Reload label"
      />
    </Button>
  );

  if (sdkError) {
    return <div className={classes.error}>{sdkError}</div>;
  }

  const renderApp = () => {
    if (selectedAppInStore && selectedAppInStore.applicationUrl) {
      return (
        <>
          {!frameLink && (
            <Box className={classes.loadingOverlay}>
              <Box className={classes.loadingMessage}>
                <CircularProgress size={36} />

                {loadingError === 0 && (
                  <Typography variant="body1" className={classes.loadingText}>
                    <FormattedMessage
                      id="desktop.loading-child-frame.loading"
                      defaultMessage="Loading {appName}application..."
                      description="Loading application message"
                      values={{
                        appName: selectedAppInStore.name ? selectedAppInStore.name + ' ' : '',
                      }}
                    />
                  </Typography>
                )}
                {loadingError === 1 && (
                  <Box>
                    <Typography variant="body1" className={classes.loadingText}>
                      <FormattedMessage
                        id="desktop.loading-child-frame.error-1"
                        defaultMessage="Looks like we are having problems opening this application, please try again"
                        description="Error Loading message - 1st error"
                      />
                      {reloadButton}
                    </Typography>
                  </Box>
                )}
                {loadingError > 1 && (
                  <Box>
                    <Typography variant="body1" className={classes.loadingText}>
                      <FormattedMessage
                        id="desktop.loading-child-frame.error-2"
                        defaultMessage="Looks like we are having problems opening this application, please try again. If this problem persists, please reach out to aiWARE customer support"
                        description="Error Loading message - 2nd error"
                      />
                    </Typography>
                    {reloadButton}
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <iframe
            src={selectedAppInStore.applicationUrl}
            style={{
              width: '100%',
              height: 'calc(100vh - 59px)',
              overflow: 'hidden',
              border: 'none',
            }}
            ref={iframeRef}
          />
        </>
      );
    }
    // TODO: get a mockup or error message when child application does not have a URL to load in the iframe
  };

  return (
    <DynamicModuleLoader modules={[getAiWareDesktopModule()]}>
      <div className={`${classes.logoContainer} app`} data-testid="aiware-desktop.main-container">
        {invalidAppSlug && <InvalidAppModal onClose={closeInvalidAppModal} />}
        {selectedAppInStore ? (
          renderApp()
        ) : (
          <WallpaperManagement
            setCurrentWallpaper={setCurrentWallpaper}
            currentWallpaper={currentWallpaper!}
          />
        )}
      </div>
    </DynamicModuleLoader>
  );
};

export default App;
