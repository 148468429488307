import { Typography, IconButton, Tooltip } from '@mui/material';
import { Hide, Close } from '@aiware/shared/icons';
import { useStyles } from './header.styles';

const iconTypes = {
  close: <Close />,
  hide: <Hide />,
};

const Header = ({
  panelId,
  permissionsPanelHeader,
  permissionsPanelClose,
  dataTestIdPrefix,
  iconType = 'hide',
  handleClose,
  unstyledHeader,
  className,
}: {
  panelId: string;
  permissionsPanelHeader: any;
  permissionsPanelClose: any;
  iconType: keyof typeof iconTypes;
  dataTestIdPrefix?: string;
  handleClose: (panelId: string) => void;
  unstyledHeader?: boolean;
  className?: string;
}) => {
  const { classes } = useStyles({ unstyledHeader });
  return (
    <header
      className={className ? className : classes.header}
      data-testid={`${dataTestIdPrefix || 'default-panel'}-header-testid`}
      data-test={`${dataTestIdPrefix || 'default-panel'}-header-testid`}
    >
      <Typography
        variant="h1"
        data-testid="permissions-panel-header-title"
        data-test="permissions-panel-header-title"
        className={classes.title}
      >
        {permissionsPanelHeader}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Tooltip title={permissionsPanelClose}>
          <IconButton
            onClick={() => handleClose(panelId)}
            aria-labelledby="permissions-panel-close-button"
            data-testid={`${dataTestIdPrefix || 'default-panel'}-close-button`}
            data-test={`${dataTestIdPrefix || 'default-panel'}-close-button`}
            size="large"
          >
            {iconTypes[iconType]}
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
