import createSvgIcon from '../createSvgIcon';
export const MarketplaceTwo = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.58747 2.52938C9.23301 2.34229 8.79409 2.46729 8.59138 2.81306L4.96415 9H2C1.45 9 1 9.45 1 10C1 10.09 1.01 10.18 1.04 10.27L3.58 19.54C3.81 20.38 4.58 21 5.5 21H18.5C19.42 21 20.19 20.38 20.43 19.54L22.97 10.27L23 10C23 9.45 22.55 9 22 9H19.4368L15.5643 2.8596C15.3496 2.51914 14.9042 2.40909 14.5557 2.61035C14.1873 2.82303 14.0696 3.29934 14.2965 3.65914L17.6647 9H17.21H15.5L12 9.00003L9 9H6.79H6.7012L9.8841 3.57094C10.1 3.20265 9.96503 2.72865 9.58747 2.52938ZM18.5 19L5.51 19.01L3.31 11H20.7L18.5 19ZM7.75 12C7.33579 12 7 12.3358 7 12.75V17.25C7 17.6642 7.33579 18 7.75 18C8.16421 18 8.5 17.6642 8.5 17.25V12.75C8.5 12.3358 8.16421 12 7.75 12ZM15 12.75C15 12.3358 15.3358 12 15.75 12C16.1642 12 16.5 12.3358 16.5 12.75V17.25C16.5 17.6642 16.1642 18 15.75 18C15.3358 18 15 17.6642 15 17.25V12.75ZM11.75 12C11.3358 12 11 12.3358 11 12.75V17.25C11 17.6642 11.3358 18 11.75 18C12.1642 18 12.5 17.6642 12.5 17.25V12.75C12.5 12.3358 12.1642 12 11.75 12Z"
    />
  </svg>,
  'MarketplaceTwo'
);
