import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/slices/aiware-desktop.state';

type TProps = {
  payload: { key: string; value: string };
  currentWallpaper: any;
  setCurrentWallpaper: (image: any) => void;
};

export const ImagePreview = ({ payload, currentWallpaper, setCurrentWallpaper }: TProps) => {
  const dispatch = useDispatch();
  return (
    <Box
      data-testid={`aiware-desktop.settings.image-preview`}
      sx={{
        border: payload.value === currentWallpaper ? '2px solid #43A047' : '1px solid #DADFE8',
        borderRadius: '4px',
        width: '140px',
        height: '80px',
        backgroundImage: `url(${payload.value})`,
        backgroundSize: 'cover',
        cursor: 'pointer',
      }}
      onClick={() => {
        setCurrentWallpaper(payload.value);
        dispatch(actions.setCurrentWallpaperStart(payload.key));
      }}
    ></Box>
  );
};
