import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  cardContainer: {
    width: '100%',
    '&:hover': {
      backgroundColor: '#EEF3F9',
    },
  },
  rootCard: {
    padding: theme.spacing(4, 6),
    display: 'flex',
    cursor: 'pointer',
  },
  unreadCard: {
    padding: theme.spacing(4, 6),
    display: 'flex',
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
  },
  // START Card
  iconCard: {
    marginTop: theme.spacing(-0.4),
  },
  leftContent: {
    width: '345px',
    padding: theme.spacing(0, 6, 0, 3),
  },
  titleCard: {
    display: 'flex',
  },
  appsCard: {
    fontSize: '14px',
    fontWeight: 600,
  },
  timestampCard: {
    fontSize: '12px',
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  contentCard: {
    lineHeight: '150%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
  },
  dividerCard: {
    margin: theme.spacing(0, 6),
    height: '0.5px',
  },
  notificationIcon: {
    width: 24,
    height: 24,
  },
  unread: {
    width: 'auto',
    marginRight: theme.spacing(2.4),
    fontSize: 'unset',
  },
  unreadIcon: {
    width: 8,
    height: 8,
    color: theme.palette.primary.main,
  },
  appName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 85px)',
  },
}));

export default useStyles;
