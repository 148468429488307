import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@aiware/shared/icons';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { mountPanel } from '@aiware/js/panel';
import { selectAdminCenterOrgId } from '@aiware/os/admin-center/shared';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { Theme } from '@mui/material/styles';
import { actions } from '../../redux/slices';
import AccessControlTable from './AccessControlTable';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    height: 102,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },
  tableWrapper: {
    flexGrow: 1,
    overflowY: 'hidden',
  },
}));

export const AccessControl = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const orgId: number = useSelector(selectAdminCenterOrgId);
  const isPanelFullScreen = useSelector(selectIsFullscreenEnabled);

  useEffect(() => {
    dispatch(actions.adminCenterPermissions.fetchOrgAccessControlStart(orgId));
    dispatch(actions.adminCenterPermissions.fetchOrgPermissionSetsStart());

    return () => {
      dispatch(actions.adminCenterPermissions.resetOrgAccessControl());
    };
  }, []);

  const loadAccessControls = () => {
    dispatch(actions.adminCenterPermissions.fetchOrgAccessControlStart(orgId));
  };

  const refreshAndLoadAccessControls = () => {
    dispatch(actions.adminCenterPermissions.refreshAccessControls());
    dispatch(actions.adminCenterPermissions.fetchOrgAccessControlStart(orgId));
  };

  const openPermissionsPanel = () => {
    if (orgId) {
      dispatch(
        actions.adminCenterPermissions.setSelectedResource({
          id: orgId.toString(),
          // @ts-ignore
          type: 'Organization',
        })
      );
      const microFrontend = {
        name: 'PERMISSIONS_PANEL',
        config: {
          name: 'Permissions',
          permissionType: 'Organization',
          refreshAndLoadAccessControls,
        },
      };

      const panelConfig = {
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: isPanelFullScreen ? 0 : 55,
        marginStart: isPanelFullScreen ? 0 : 80,
        size: 'medium',
        height: 'calc(100vh - 55px)',
        parentPanelId: 'ADMIN_CENTER',
        dimmed: 0,
        dimmedStatus: 'dimParent',
      };
      dispatch(
        mountPanel({
          panelId: 'PERMISSIONS_PANEL',
          microFrontend,
          panelConfig,
        })
      );
    }
  };

  return (
    <section className={classes.root}>
      <header className={classes.header}>
        <Box sx={{ mr: 15 }}>
          <Typography
            variant="h5"
            data-testid="org-access-control.header"
            data-test="org-access-control.header"
          >
            <FormattedMessage
              id="os-admin-center-panel.system-access.table.header"
              defaultMessage="System Access"
              description="System Access Header"
            />
          </Typography>
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            data-testid="org-access-control.description"
            data-test="org-access-control.description"
          >
            <FormattedMessage
              id="os-admin-center-panel.system-access.table.description"
              defaultMessage="Manage the system permissions for groups of users of this Organization."
              description="System Access Description"
            />
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={openPermissionsPanel}
            sx={{ whiteSpace: 'nowrap' }}
            color="primary"
            variant="contained"
            data-testid="org-access-control.manage-permissions-button"
            data-test="org-access-control.manage-permissions-button"
          >
            <Edit sx={{ mr: 1.3 }} fontSize="small" />
            <FormattedMessage
              id="os-admin-center-panel.manage.permissions.button"
              defaultMessage="Manage Permissions"
              description="Manage Permissions Button Button"
            />
          </Button>
        </Box>
      </header>

      <div className={classes.tableWrapper}>
        <AccessControlTable loadAccessControls={loadAccessControls} />
      </div>
    </section>
  );
};

export default AccessControl;
