import { call, put, select } from 'redux-saga/effects';
import { selectApiConfigs } from '@aiware/shared/redux';
import * as api from '../../api';
import { actions } from '../slices/aiware-desktop.state';
import { TAssetRecord, TWallpaperAppConfig } from '../../types';
import { selectUserWallpapers } from '../selectors';
import { devErrorLogger } from '@aiware/shared/reusable-utils';

export function* fetchUserWallpapersSaga(action: ReturnType<typeof actions.fetchUserWallpapersStart>) {
  try {
    const { graphQLEndpoint, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const wallpapers: TWallpaperAppConfig[] = yield call(
      api.getWallpapersApplicationConfig,
      action.payload,
      graphQLEndpoint,
      token
    );
    if (wallpapers) {
      yield put(actions.fetchUserWallpapersSuccess(wallpapers));
    } else {
      yield put(actions.fetchUserWallpapersFailure());
    }
  } catch (e) {
    devErrorLogger(e);

    yield put(actions.fetchUserWallpapersFailure());
  }
}

export function* fetchUserCurrentWallpaperSaga(
  action: ReturnType<typeof actions.fetchUserCurrentWallpaperTDOStart>
) {
  try {
    const { graphQLEndpoint, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const { records, error }: { records?: TAssetRecord[]; error?: boolean } = yield call(
      api.TDOhelper,
      action.payload,
      graphQLEndpoint,
      token
    );
    if (records && !error) {
      yield put(actions.fetchUserCurrentWallpaperTDOSuccess(records[0]!.signedUri!));
    } else {
      yield put(actions.fetchUserCurrentWallpaperTDOFailure());
    }
  } catch (e) {
    devErrorLogger(e);

    yield put(actions.fetchUserCurrentWallpaperTDOFailure());
  }
}

export function* getTDOAssetsSaga(action: ReturnType<typeof actions.getTDOAssetsStart>) {
  try {
    const { graphQLEndpoint, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const { records, error }: { records?: TAssetRecord[]; error?: boolean } = yield call(
      api.TDOhelper,
      action.payload,
      graphQLEndpoint,
      token
    );
    if (error) {
      yield put(actions.getTDOAssetsFailure());
    }
    if (records) {
      yield put(actions.getTDOAssetsSuccess({ records, tdoToFetch: action.payload }));
    }
  } catch (e) {
    devErrorLogger(e);
    yield put(actions.getTDOAssetsFailure());
  }
}

export function* handleSetCustomWallpaperSaga(action: ReturnType<typeof actions.setCustomWallpaperStart>) {
  try {
    const { graphQLEndpoint, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const { wallpaperId, tdoID, create } = action.payload;
    if (create) {
      yield call(
        api.createWallpaperApplicationConfig,
        `wallpaperCustom_${wallpaperId}`,
        graphQLEndpoint,
        token
      );
    }
    const response: TWallpaperAppConfig = yield call(
      api.setWallpaperApplicationConfig,
      `wallpaperCustom_${wallpaperId}`,
      tdoID,
      graphQLEndpoint,
      token
    );
    if (response) {
      yield put(actions.setCustomWallpaperSuccess(response));
    } else {
      yield put(actions.setCustomWallpaperFailure());
    }
  } catch (e) {
    devErrorLogger(e);
    yield put(actions.setCustomWallpaperFailure());
  }
}

export function* setCurrentWallpaperSaga(action: ReturnType<typeof actions.setCurrentWallpaperStart>) {
  try {
    const { graphQLEndpoint, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const wallpapers: ReturnType<typeof selectUserWallpapers> = yield select(selectUserWallpapers);
    const settingExists = !!wallpapers?.find(wallpaper => wallpaper.key === 'wallpaperCurrent');
    if (!settingExists) {
      yield call(api.createWallpaperApplicationConfig, action.payload, graphQLEndpoint, token);
    }
    const response: TWallpaperAppConfig = yield call(
      api.setWallpaperApplicationConfig,
      'wallpaperCurrent',
      action.payload,
      graphQLEndpoint,
      token
    );
    if (!response) {
      yield put(actions.setCurrentWallpaperFailure());
    } else {
      yield put(actions.setCurrentWallpaperSuccess(action.payload));
      yield put(actions.fetchUserCurrentWallpaperTDOStart(action.payload));
    }
  } catch (e) {
    devErrorLogger(e);
    yield put(actions.setCurrentWallpaperFailure());
  }
}

export function* removeCustomWallpaperSaga(action: ReturnType<typeof actions.removeCustomWallpaperStart>) {
  const { id } = action.payload;
  try {
    const { graphQLEndpoint, token }: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const response: TWallpaperAppConfig = yield call(
      api.setWallpaperApplicationConfig,
      `wallpaperCustom_${id}`,
      'none',
      graphQLEndpoint,
      token
    );
    if (!response) {
      yield put(actions.removeCustomWallpaperFailure());
    } else {
      yield put(actions.removeCustomWallpaperSuccess());
    }
  } catch (e) {
    devErrorLogger(e);
    yield put(actions.removeCustomWallpaperFailure());
  }
}
