import { takeEvery } from 'redux-saga/effects';
import { updateOrganizationInviteSaga } from './';
import { actions } from '../../slices';

export function* updateOrganizationInviteWatcher() {
  yield takeEvery(
    actions.reviewRequestState.updateOrganizationInviteStart.type,
    updateOrganizationInviteSaga
  );
}
