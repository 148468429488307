import { PlaceholderElement } from '@aiware/shared/reusable-utils';
import { Stack } from '@mui/material';

export const EditForm = () => {
  return (
    <Stack spacing={2}>
      <PlaceholderElement label={'Name Input'} height={65} ticketId={12058} />
      <PlaceholderElement label={'Description Input'} height={110} ticketId={12058} />
    </Stack>
  );
};
