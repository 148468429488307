import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  modalContainer: {
    '& .Sdk-MuiDialog-container .Sdk-MuiPaper-root': {
      margin: '0',
    },
    '& button': {
      height: '36px',
    },
  },
  modalTitle: {
    padding: '30px 30px 10px 30px',
  },
  modalContent: {
    padding: '0 30px 30px 30px',
  },
  modalActions: {
    padding: '0 30px 30px 30px',
  },
  cancelButton: {
    color: 'black',
  },
}));
