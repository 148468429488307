import createSvgIcon from '../createSvgIcon';
export const ImportantFilled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.075 19.075C20.8848 17.2651 22 14.7605 22 12C22 9.23949 20.8848 6.73492 19.075 4.92505C17.2651 3.11517 14.7605 2 12 2C9.23949 2 6.73492 3.11517 4.92505 4.92505C3.11517 6.73492 2 9.23949 2 12C2 14.7605 3.11517 17.2651 4.92505 19.075C6.73492 20.8848 9.23949 22 12 22C14.7605 22 17.2651 20.8848 19.075 19.075ZM13.3528 15.8574C13.3528 15.1079 12.7495 14.5046 12 14.5046C11.2505 14.5046 10.6289 15.1079 10.6289 15.8574C10.6289 16.6069 11.2505 17.2285 12 17.2285C12.7495 17.2285 13.3528 16.6069 13.3528 15.8574ZM12 13.1335C11.5064 13.1335 11.1408 12.7313 11.0859 12.2194L10.6289 8.1426C10.5558 7.42962 11.3053 6.77148 12 6.77148C12.6947 6.77148 13.4442 7.42962 13.3528 8.1426L12.9141 12.2194C12.8592 12.7313 12.4936 13.1335 12 13.1335V13.1335Z"
    />
  </svg>,
  'ImportantFilled'
);
