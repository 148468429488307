import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useStyles } from '../useStyles';
import {
  IFileVm,
  ITdoVm,
  IFolderVm,
  IFolder,
  InfiniteWrapperItemType,
  IFileOrFolderVm,
} from '../../../types';
import FoldersTableRow from './FoldersTableRow';
import FilesTableRow from './FilesTableRow';
import Divider from '@mui/material/Divider';
import { selectIsFluffy, selectIsItemsDisabled } from '../../../redux/selectors/ui-state';
import { selectSelectedItemId } from '../../../redux/selectors/view-my-files';
import FoldersTableFluffyRow from './FoldersTableFluffyRow';
import FilesTableFluffyRow from './FilesTableFluffyRow';

const FoldersAndFilesRowGen: FunctionComponent<{
  index: number;
  items: InfiniteWrapperItemType;
  rowHeight: number;
  dataCenterView?: boolean;
}> = ({ index, items, rowHeight, dataCenterView }) => {
  const { classes } = useStyles();
  const isFluffy = useSelector(selectIsFluffy);
  // Check if File Picking is enabled
  const disableItems = useSelector(selectIsItemsDisabled);

  const selectedItemId = useSelector(selectSelectedItemId);
  const type = (items as IFolderVm[] | IFileVm[])[index]!.type;

  if (!isFluffy) {
    const item = (items as IFileOrFolderVm[])[index];
    const folder: IFolder | null = (type === 'folder' && (item as IFolderVm).folder) || null;
    const file: ITdoVm | null = (type === 'file' && (item as IFileVm).file) || null;
    return (
      <div
        className={classNames(
          classes.row,
          {
            selected: folder?.id === selectedItemId || file?.id === selectedItemId,
          },
          `table-row-item-${file ? 'file' : 'folder'}`
        )}
      >
        {folder && <FoldersTableRow dataCenterView={dataCenterView} item={folder} rowHeight={rowHeight} />}
        {file && <FilesTableRow disableItems={disableItems} item={file} rowHeight={rowHeight} />}
        <Divider className={classes.divider} />
      </div>
    );
  }

  const fluffyFile = Array.isArray(items[index]) ? null : (items as IFileVm[])[index]!.file;

  return fluffyFile ? (
    <div
      className={classNames({
        [classes.selected]: fluffyFile?.id === selectedItemId,
      })}
    >
      <FilesTableFluffyRow item={fluffyFile} rowHeight={rowHeight} />
      <Divider className={classes.divider} />
    </div>
  ) : (
    <div>
      <FoldersTableFluffyRow items={(items as IFolderVm[][])[index]!} rowHeight={rowHeight} />
    </div>
  );
};

export default FoldersAndFilesRowGen;
