import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITdo, SLICE_NAME } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'editMetadata';

export type State = {
  loadingStatus: LoadingStatus;
  saveStatus: LoadingStatus;
  tdo?: ITdo;
  edits: {
    imageUnsignedUrl?: string;
    imageSignedUrl?: string;
    filename?: string;
    displayTime?: string;
    tags?: string[];
  };
};

export const initialState = {
  loadingStatus: 'idle',
  saveStatus: 'idle',
  edits: {},
} as State;

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchTdoStart(state, _action: PayloadAction<string>) {
      state.loadingStatus = 'pending';
    },
    fetchTdoSucceeded(state, action: PayloadAction<ITdo>) {
      state.loadingStatus = 'success';
      state.tdo = action.payload;
    },
    fetchTdoFailed(state) {
      state.loadingStatus = 'failure';
      delete state.tdo;
    },
    saveMetadataStart(state) {
      state.saveStatus = 'pending';
    },
    saveMetadataSucceeded(state) {
      state.saveStatus = 'success';
      state.edits = {};
    },
    saveMetadataFailed(state) {
      state.saveStatus = 'failure';
    },
    setImageUnsignedUrl(state, action: PayloadAction<string>) {
      state.edits.imageUnsignedUrl = action.payload;
    },
    setImageSignedUrl(state, action: PayloadAction<string>) {
      state.edits.imageSignedUrl = action.payload;
    },
    setFilename(state, action: PayloadAction<string>) {
      state.edits.filename = action.payload;
    },
    setDisplayTime(state, action: PayloadAction<string>) {
      state.edits.displayTime = action.payload;
    },
    setTags(state, action: PayloadAction<string[]>) {
      state.edits.tags = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
