import { all, take, takeEvery, fork } from 'redux-saga/effects';
import applicationSaga from './modules/application/saga';
import userSaga from './modules/user/saga';
import activitySaga from './modules/activity/saga';
import { BOOTING, BOOTED } from './actions';

function* bootFunction() {
  yield take(BOOTED);
}

function* booting() {
  yield takeEvery(BOOTING, bootFunction);
}

function* rootSaga() {
  yield all([fork(applicationSaga), fork(userSaga), fork(booting), fork(activitySaga)]);
}
export { booting, bootFunction };
export default rootSaga;
