import { useState, ReactNode, useEffect } from 'react';
import { listenLogout, appSwitcherSetupListener } from '@aiware/js/constants';
import { hidePanel, mountPanel, requestUserAlertConfirmation } from '@aiware/js/panel';
import { IMicroFrontend, IVeritoneAppbarPanelConfig, TabId } from '@aiware/js/interfaces';
import { FormattedMessage } from 'react-intl';

import { openInNewIcon } from '@aiware/shared/assets';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import {
  unseenNotificationSelector,
  getNotificationModule,
  markAllNotificationsSeenRequest,
} from '@aiware/os/notification-subscription';
import {
  getUserApplicationSettingsModule,
  actions,
  selectApplicationConfig,
  TApplicationConfig,
} from '@aiware/os/admin-center/user-application-settings';
import { initNotificationAction } from '@aiware/os/notification-panel';
import { getAppbarPanelModule, applicationSelector } from '@aiware/os/app-bar-panel';
import {
  authSelector,
  betaFeaturesSelector,
  getEventsModule,
  appIdSelector,
  gqlIntrospection,
  selectHasUserCreateRootFolderPermission,
} from '@aiware/shared/redux';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import 'cropperjs/dist/cropper.css';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import Appbar, {
  HELP_PANEL_ID,
  NOTIFICATION_PANEL_ID,
  ORGANIZATION_PANEL_ID,
  VERITONE_PANEL_ID,
  APPLICATION_PANEL_ID,
  USER_APPLICATION_SETTINGS_PANEL,
} from '../components/organisms/Appbar';
import '../global/css/style.css';
import { getAppbarModule } from '../store';
import {
  getCurrentAppIcon,
  getCurrentUser,
  rootSelector,
  userSelector,
  notificationStatusSelector,
} from './../store/modules/user';
import { onBooted } from '../store/actions';
import { EMessageTypes, errors } from '@aiware/js/frame-link';
import { Stack } from '@mui/material';

interface IAppProps {
  onLogout: () => void;
  onAppSwitch: () => void;
  enableCustomSettingPanel: boolean;
  onShowSettingPanel: () => void;
  onClickOrgIcon: () => void;
  onClickHelpIcon: () => void;
  showNotifications: () => void;
  children: ReactNode;
  applicationId?: string;
  searchBarMountId?: string;
  enableBroadcast?: boolean;
  onClickNotificationAction: (data: unknown) => void;
  renderRightActionsOnly?: boolean;
  allowedActions?: SDK_HEADER_BUTTON[];
}

interface IAuthSelector {
  user: {
    roles: string[];
  };
}

function App({
  onLogout,
  onClickNotificationAction,
  onAppSwitch,
  onShowSettingPanel,
  onClickOrgIcon,
  onClickHelpIcon,
  showNotifications,
  applicationId,
  searchBarMountId,
  renderRightActionsOnly = false,
  ...remains
}: IAppProps) {
  const isEnableOrgPanel = false;
  const dispatch = useDispatch();
  const [openPanel, setOpenPanel] = useState(false);
  const [openHelpPanel, setOpenHelpPanel] = useState(false);
  const [openAppSwitchPanel, setOpenAppSwitchPanel] = useState(false);
  const [openOrgSwitchPanel, setOpenOrgSwitchPanel] = useState(false);
  const [openNotificationPanel, setOpenNotificationPanel] = useState(false);
  const [openUserApplicationSettingsPanel, setOpenUserApplicationSettingsPanel] = useState(false);
  const { currentUser } = useSelector(userSelector) || {};
  const { panels = [] }: { panels: Array<IVeritoneAppbarPanelConfig> } = useSelector(rootSelector);
  const unseenCount: number = useSelector(unseenNotificationSelector);
  const notificationStatus = useSelector(notificationStatusSelector);
  const betaFeatures = useSelector(betaFeaturesSelector);
  const { organization } = currentUser || {};
  const { user } = useSelector(authSelector) as IAuthSelector;
  const { application = [] } = useSelector(applicationSelector) || {};
  const [invitePanelOpen, setInvitePanelOpen] = useState(false);
  const showDashboardIcon = betaFeatures;
  const appId = useSelector(appIdSelector);
  const userApplicationSettingsConfig: TApplicationConfig[] = useSelector(selectApplicationConfig);
  const [userApplicationSettingsIcon, setUserApplciationSettingsIcon] = useState(false);
  const hasUserCreateRootFolderPermission = useSelector(selectHasUserCreateRootFolderPermission);
  const showAppGroups =
    gqlIntrospection.isOperationValid('fileApplication', 'mutation') && hasUserCreateRootFolderPermission;

  useEffect(() => {
    const { search } = window.location;

    dispatch(
      actions.userApplicationSettings.fetchApplicationConfigStart({
        appId,
      })
    );

    if (search === '?panel=invite-requests' && !invitePanelOpen) {
      setInvitePanelOpen(true);
      const microFrontend = {
        name: 'INVITE_REQUESTS_PANEL',
        config: {
          name: 'Invite Requests',
        },
      };

      const panelConfig = {
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        marginStart: 0,
        size: 'medium',
        parentPanelId: null,
        dimmed: 0,
        dimmedStatus: 'dimParent',
        header: {
          title: 'INVITE REQUESTS',
        },
      };
      dispatch(
        mountPanel({
          panelId: 'INVITE_REQUESTS_PANEL',
          microFrontend,
          panelConfig,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userApplicationSettingsConfig?.length) {
      setUserApplciationSettingsIcon(true);
    }
  }, [userApplicationSettingsConfig]);

  useEffect(() => {
    if (notificationStatus === 'success') {
      dispatch(markAllNotificationsSeenRequest());
    }
  }, [notificationStatus, dispatch]);

  useEffect(() => {
    dispatch(getCurrentUser());
    if (applicationId) {
      dispatch(getCurrentAppIcon({ applicationId: applicationId }));
    }
  }, [dispatch, applicationId]);

  useEffect(() => {
    dispatch({
      type: listenLogout().type,
      payload: onLogout,
    });
  }, [dispatch, onLogout]);

  useEffect(() => {
    if (onClickNotificationAction) {
      dispatch(initNotificationAction(onClickNotificationAction));
    }
  }, [dispatch, onClickNotificationAction]);

  useEffect(() => {
    dispatch({
      type: appSwitcherSetupListener().type,
      payload: onAppSwitch,
    });
  }, [dispatch, onAppSwitch]);

  useEffect(() => {
    dispatch(onBooted());
  }, [dispatch]);

  function setAllClose() {
    setOpenPanel(false);
    setOpenAppSwitchPanel(false);
    setOpenOrgSwitchPanel(false);
    setOpenHelpPanel(false);
    setOpenNotificationPanel(false);
    setOpenUserApplicationSettingsPanel(false);
  }

  function unmountAllPanel() {
    panels?.forEach((panel: IVeritoneAppbarPanelConfig) => {
      if (panel?.microFrontend?.config?.requestId) {
        panel?.microFrontend?.config?.frameLink &&
          panel?.microFrontend?.config?.frameLink.postMessage(EMessageTypes.error, {
            requestId: panel?.microFrontend?.config?.requestId,
            error: errors.operationCancelled,
          });
      }
      dispatch(hidePanel(panel.panelId));
    });
  }

  useEffect(() => {
    if (!panels || panels.length === 0) {
      setAllClose();
    }
  }, [panels]);

  function handleOpenSetting() {
    const { userDidConfirm } = requestUserAlertConfirmation();
    if (!userDidConfirm) {
      return;
    }

    if (openPanel) {
      setOpenPanel(false);
      unmountAllPanel();
    } else {
      setAllClose();
      setOpenPanel(true);
      unmountAllPanel();
      const appBarMicroFrontend: IMicroFrontend<unknown> = {
        name: 'APP_BAR_PANEL',
        config: {
          name: 'Veritone',
          dataId: 'dataId',
          type: 'APP_BAR_PANEL_TEMPLATE',
        },
      };
      const veritonePanelConfig: IVeritoneAppbarPanelConfig = {
        panelId: VERITONE_PANEL_ID,
        type: 'TAB_TEMPLATE',
        marginTop: 55,
        withTab: true,
        width: 80,
        userRoles: user?.roles,
        header: {
          divider: true,
          title: '',
        },
        footer: {
          logo: true,
        },
        size: 'xlarge',
        fullScreen: false,
      };
      dispatch(
        mountPanel({
          panelId: VERITONE_PANEL_ID,
          microFrontend: appBarMicroFrontend,
          panelConfig: veritonePanelConfig,
        })
      );
    }
  }

  function handleOpenHelpCenter() {
    if (openHelpPanel) {
      setOpenHelpPanel(false);
      unmountAllPanel();
    } else {
      setAllClose();
      unmountAllPanel();
      setOpenHelpPanel(true);
      const helpPanelMicroFrontend: IMicroFrontend<unknown> = {
        name: 'HELP_PANEL',
        config: {
          name: 'Help panel',
          dataId: 'helpDataId',
          type: 'SIMPLE_PANEL',
        },
      };
      const helpPanelConfig: IVeritoneAppbarPanelConfig = {
        panelId: HELP_PANEL_ID,
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        size: 'large',
        header: {
          divider: true,
          title: (
            <FormattedMessage
              id="os-app-bar.headerTitle.helpCenter"
              defaultMessage="Help Center"
              description="The header title for the help center."
            />
          ),
          actions: [
            {
              iconUrl: openInNewIcon,
              action: {
                type: 'OPEN_HELP_LINK',
              },
            },
          ],
        },
      };
      dispatch(
        mountPanel({
          panelId: HELP_PANEL_ID,
          microFrontend: helpPanelMicroFrontend,
          panelConfig: helpPanelConfig,
        })
      );
    }
  }

  function handleOpenNotification() {
    const { userDidConfirm } = requestUserAlertConfirmation();
    if (!userDidConfirm) {
      return;
    }

    showNotifications?.();
    if (openNotificationPanel) {
      setOpenNotificationPanel(false);
      unmountAllPanel();
    } else {
      setAllClose();
      unmountAllPanel();
      setOpenNotificationPanel(true);
      const notificationPanelMicroFrontend: IMicroFrontend<unknown> = {
        name: 'NOTIFICATION_PANEL',
        config: {
          name: 'Notification Panel',
          dataId: 'notificationDataId',
          type: 'APP_BAR_PANEL_TEMPLATE',
          setPanelIsOpenState: setOpenNotificationPanel,
        },
      };
      const notificationPanelConfig: unknown = {
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        width: 413,
        header: {
          divider: true,
          title: (
            <FormattedMessage
              id="os-app-bar.panel.notifications"
              defaultMessage="Notifications Center"
              description="Title of the notifications center panel"
            />
          ),
          actions: [],
        },
      };
      dispatch(
        mountPanel({
          panelId: NOTIFICATION_PANEL_ID,
          microFrontend: notificationPanelMicroFrontend,
          panelConfig: notificationPanelConfig,
        })
      );
    }
  }

  function handleOpenOrgPanel() {
    const { userDidConfirm } = requestUserAlertConfirmation();
    if (!userDidConfirm) {
      return;
    }

    onClickOrgIcon?.();
    if (openOrgSwitchPanel) {
      setOpenOrgSwitchPanel(false);
      unmountAllPanel();
    } else {
      setAllClose();
      unmountAllPanel();
      setOpenOrgSwitchPanel(true);
      const appBarMicroFrontend: IMicroFrontend<unknown> = {
        name: 'ORGANIZATION_PANEL',
        config: {
          name: 'Veritone',
          tabId: TabId.ORGANIZATION_PANEL,
          dataId: 'dataId',
          type: 'APP_BAR_PANEL_TEMPLATE',
        },
      };
      const veritonePanelConfig: IVeritoneAppbarPanelConfig = {
        panelId: ORGANIZATION_PANEL_ID,
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        width: 420,
        header: {
          divider: true,
          title: 'ORGANIZATIONS',
        },
      };
      dispatch(
        mountPanel({
          panelId: ORGANIZATION_PANEL_ID,
          microFrontend: appBarMicroFrontend,
          panelConfig: veritonePanelConfig,
        })
      );
    }
  }

  const _appPanelV2 = true;

  function handleOpenAppSwitch() {
    const { userDidConfirm } = requestUserAlertConfirmation();
    if (!userDidConfirm) {
      return;
    }

    if (openAppSwitchPanel) {
      setOpenAppSwitchPanel(false);
      unmountAllPanel();
    } else {
      setAllClose();
      unmountAllPanel();
      setOpenAppSwitchPanel(true);
      const appBarMicroFrontend: IMicroFrontend<unknown> = {
        name: 'APP_BAR_PANEL',
        config: {
          name: 'APP_BAR_PANEL',
          tabId: TabId.APP_PANEL,
          dataId: 'dataId',
          type: 'APP_BAR_PANEL_TEMPLATE',
        },
      };
      const veritonePanelConfig: IVeritoneAppbarPanelConfig = {
        panelId: APPLICATION_PANEL_ID,
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        width: 720,
        header: {
          divider: true,
          title: (
            <FormattedMessage
              id="os-app-bar.header.panelTitle-app-switcher"
              defaultMessage="App Switcher"
              description="Title of applications panel list"
            />
          ),
          actions: showAppGroups
            ? [
                {
                  action: {
                    type: 'user-app-group/createNewAppGroupPlaceholder',
                  },
                  label: (
                    <FormattedMessage
                      id="os-app-bar.header-action.new-app-group"
                      defaultMessage="New App Group"
                      description="New App Group action button"
                    />
                  ) as unknown as string,
                  variant: 'contained',
                  size: 'large',
                  color: 'primary',
                  submit: true,
                },
              ]
            : undefined,
        },
      };
      dispatch(
        mountPanel({
          panelId: APPLICATION_PANEL_ID,
          microFrontend: appBarMicroFrontend,
          panelConfig: veritonePanelConfig,
        })
      );
    }
  }

  function handleOpenUserApplicationSettings() {
    const { userDidConfirm } = requestUserAlertConfirmation();
    if (!userDidConfirm) {
      return;
    }

    if (openUserApplicationSettingsPanel) {
      setOpenUserApplicationSettingsPanel(false);
      unmountAllPanel();
    } else {
      setAllClose();
      unmountAllPanel();
      setOpenUserApplicationSettingsPanel(true);
      const appBarMicroFrontend: IMicroFrontend<unknown> = {
        name: 'USER_APPLICATION_SETTINGS_PANEL',
        config: {
          name: 'User application settings',
          dataId: 'userApplicationSettingsDataId',
          type: 'APP_BAR_PANEL_TEMPLATE',
        },
      };
      const userApplicationSettingsPanelConfig: IVeritoneAppbarPanelConfig = {
        panelId: USER_APPLICATION_SETTINGS_PANEL,
        type: 'APP_BAR_PANEL_TEMPLATE',
        marginTop: 55,
        width: 320,
        header: {
          divider: false,
          title: (
            <FormattedMessage
              id="os-app-bar.header.panelTitle.user-application-name-settings"
              defaultMessage="Application Settings"
              description="Title of user application name settings panel"
            />
          ),
        },
      };
      dispatch(
        mountPanel({
          panelId: USER_APPLICATION_SETTINGS_PANEL,
          microFrontend: appBarMicroFrontend,
          panelConfig: userApplicationSettingsPanelConfig,
        })
      );
    }
  }

  function handleCloseSetting() {
    const { userDidConfirm } = requestUserAlertConfirmation();
    if (!userDidConfirm) {
      return;
    }

    unmountAllPanel();
    // dispatch(hidePanel());
  }

  function handleDashboardClick() {
    console.log('HANDLE DASHBOARD CLICK!');
  }

  return (
    <AIWareIntlProvider>
      <AIWareThemeProvider>
        <DynamicModuleLoader
          modules={[
            getAppbarModule(),
            getAppbarPanelModule(),
            getNotificationModule(),
            getEventsModule(),
            getUserApplicationSettingsModule(),
          ]}
        >
          <div className="App" id="aiware-app-bar" data-testid="aiware-app-bar">
            <Appbar
              isEnableOrgPanel={isEnableOrgPanel}
              onCloseSetting={handleCloseSetting}
              onOpenSetting={handleOpenSetting}
              onOpenHelpCenter={handleOpenHelpCenter}
              onOpenNotification={handleOpenNotification}
              onOpenUserApplicationSettings={handleOpenUserApplicationSettings}
              onDashboardClick={handleDashboardClick}
              organization={organization}
              onClickOrgIcon={handleOpenOrgPanel}
              panels={panels}
              searchBarMountId={searchBarMountId}
              openSetting={{
                openHelpPanel: false,
                openNotificationPanel: false,
                openAppSwitchPanel: false,
                openOrgSwitchPanel: false,
                openVeritonePanel: false,
                openUserApplicationSettingsPanel: false,
              }}
              unseenCount={unseenCount}
              onClickAppSwitchIcon={handleOpenAppSwitch}
              applications={application}
              showDashboardIcon={showDashboardIcon}
              userApplicationSettingsIcon={userApplicationSettingsIcon}
              renderRightActionsOnly={renderRightActionsOnly}
              {...remains}
            />
          </div>
        </DynamicModuleLoader>
      </AIWareThemeProvider>
    </AIWareIntlProvider>
  );
}

enum SDK_HEADER_BUTTON {
  HELP = 'HELP',
  NOTIFICATIONS = 'NOTIFICATIONS',
  UTILITIES = 'UTILITIES',
  DASHBOARD = 'DASHBOARD',
  ORGANIZATIONS = 'ORGANIZATIONS',
  APP_SETTINGS = 'APP_SETTINGS',
  APP_SWITCHER = 'APP_SWITCHER',
  GENERATED_ACTIONS = 'GENERATED_ACTIONS',
}

const SDK_HEADER_BUTTON_IDS = {
  [SDK_HEADER_BUTTON.HELP]: 'veritone-sdk-help-button',
  [SDK_HEADER_BUTTON.NOTIFICATIONS]: 'veritone-sdk-notification-button',
  [SDK_HEADER_BUTTON.UTILITIES]: 'veritone-sdk-utilities-button',
  [SDK_HEADER_BUTTON.DASHBOARD]: 'veritone-sdk-dashboard-button',
  [SDK_HEADER_BUTTON.ORGANIZATIONS]: 'veritone-sdk-organizations-button',
  [SDK_HEADER_BUTTON.APP_SETTINGS]: 'veritone-sdk-application-settings-button',
  [SDK_HEADER_BUTTON.APP_SWITCHER]: 'veritone-sdk-app-switcher-button',
  [SDK_HEADER_BUTTON.GENERATED_ACTIONS]: 'veritone-sdk-generated-actions',
};

const getAllowedActions = (actions: SDK_HEADER_BUTTON[]) => {
  const allowAll = actions.length === 0;

  const cssRules = Object.keys(SDK_HEADER_BUTTON_IDS).map(item => {
    const isAllowed = allowAll || actions.includes(item as SDK_HEADER_BUTTON);
    const cssSelector = `#${(SDK_HEADER_BUTTON_IDS as any)[item]}`;
    return {
      [cssSelector]: { display: isAllowed ? undefined : 'none' },
    };
  });

  const combined = {};

  cssRules.forEach(rule => {
    Object.assign(combined, rule);
  });

  return combined;
};

export const VeritoneHeaderActions = (props: IAppProps) => {
  const { allowedActions = [] } = props;

  const style = {
    '#veritone-sdk-header-actions': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    ...getAllowedActions(allowedActions),
  };
  return (
    <Stack sx={style} direction={'row'}>
      <App {...props} renderRightActionsOnly={true}>
        <></>
      </App>
    </Stack>
  );
};

export { App };
export default App;
