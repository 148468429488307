import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles<void>()((theme: Theme, props) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'stretch',
  },
  addMembers: {
    padding: theme.spacing(0, 6),
  },
  titleDescription: {
    color: '#5C6269',
    fontWeight: 400,
    maxWidth: '463px',
    marginTop: theme.spacing(1),
  },
  groupMembersTitle: {
    fontWeight: 400,
    color: '#2A323C',
    padding: theme.spacing(4, 0, 1),
  },
  addMembersSubTitle: {
    color: '#5C6269',
    fontWeight: 400,
  },
  secondBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 6, 0, 6),
  },
  btnContainer: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginRight: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  buttons: {
    marginRight: '10px',
  },
}));

export const headerStyles = makeStyles<{ height: string | number }>()((theme: Theme, { height }) => ({
  headerActionContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'flex-end',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height,
    padding: theme.spacing(0, 0, 0, 6),
  },
  title: {
    color: '#2A323C',
  },
}));
