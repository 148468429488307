import { DataCenterState, namespace } from '../slices';

export const selectImageUnsigned = (state: DataCenterState) => {
  const tdoValue = state[namespace].editMetadata.tdo?.thumbnailUrl;
  const updatedValue = state[namespace].editMetadata.edits.imageUnsignedUrl;
  return updatedValue === undefined ? tdoValue : updatedValue;
};

export const selectImageSigned = (state: DataCenterState) => {
  const tdoValue = state[namespace].editMetadata.tdo?.thumbnailUrl;
  const updatedValue = state[namespace].editMetadata.edits.imageSignedUrl;
  return updatedValue === undefined ? tdoValue : updatedValue;
};

export const selectFilename = (state: DataCenterState) => {
  const tdoValue =
    state[namespace].editMetadata.tdo?.details.veritoneFile.filename ||
    state[namespace].editMetadata.tdo?.details.veritoneFile.fileName ||
    state[namespace].editMetadata.tdo?.id;
  const updatedValue = state[namespace].editMetadata.edits.filename;
  return updatedValue === undefined ? tdoValue : updatedValue;
};

export const selectTags = (state: DataCenterState) => {
  const tdoValue = state[namespace].editMetadata.tdo?.details.tags?.map(
    (tag: { value: string }) => tag.value
  ) as string[] | undefined;
  const updatedValue = state[namespace].editMetadata.edits.tags;
  return (updatedValue || tdoValue || []) as string[];
};

export const selectTdo = (state: DataCenterState) => {
  return state[namespace].editMetadata.tdo;
};

export const selectSaveStatus = (state: DataCenterState) => {
  return state[namespace].editMetadata.saveStatus;
};

export const selectLoadingStatus = (state: DataCenterState) => {
  return state[namespace].editMetadata.loadingStatus;
};
