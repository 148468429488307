import { Stack, Typography, Box, Grid } from '@mui/material';
import emptyStateImage from '../assets/empty-state.svg';
import { FormattedMessage } from 'react-intl';

export type TTableEmptyStateProps = {
  EmptyStateComponent?: JSX.Element;
  CustomMessage?: JSX.Element | string;
  CustomDescription?: JSX.Element | string;
  CustomEmptyStateImage?: string;
};
export const TableEmptyState: React.FC<TTableEmptyStateProps> = ({
  EmptyStateComponent,
  CustomMessage,
  CustomDescription,
  CustomEmptyStateImage,
}) => {
  const style = {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '80px',
  };

  if (EmptyStateComponent) {
    return <>{EmptyStateComponent}</>;
  }

  const EmptyState = () => {
    const message = CustomMessage ? (
      <>{CustomMessage}</>
    ) : (
      <FormattedMessage
        id="shared-utils.table-infinite-scroll.empty-state-message"
        defaultMessage="No items found"
        description="Table empty state, nothing found message"
      />
    );

    return (
      <Stack
        spacing={2}
        display={'flex'}
        alignItems={'center'}
        data-testid={'shared-reusable-utils-empty-state'}
      >
        <img
          width={'270px'}
          draggable={'false'}
          src={CustomEmptyStateImage ? CustomEmptyStateImage : emptyStateImage}
          alt={'empty state'}
        />
        <Typography
          component={'div'}
          fontWeight={'strong'}
          data-testid={'shared-reusable-utils-empty-state-message'}
        >
          {message}
        </Typography>
        {CustomDescription && (
          <Typography
            sx={{ opacity: 0.5 }}
            variant={'caption'}
            data-testid={'shared-reusable-utils-empty-state-description'}
          >
            {CustomDescription}
          </Typography>
        )}
      </Stack>
    );
  };

  return (
    <Grid sx={{ position: 'relative', height: '99%', overflow: 'hidden' }}>
      <Box sx={{ ...style }}>
        <EmptyState />
      </Box>
    </Grid>
  );
};
