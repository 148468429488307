import { Paper, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  paper: {
    padding: '20px',
  },
  spacer: {
    height: '5px',
    width: '100%',
  },
  iconWhite: {
    //color:'#fff'
  },
}));
const buttonSizes = ['large', 'medium', 'small'] as const;

export const DefaultThemesButtons = () => {
  const { classes } = useStyles();

  const generateButtons = (sizes: typeof buttonSizes) => {
    return sizes.map((size, idx) => {
      return (
        <div key={idx}>
          <Typography variant="h1">{size} buttons</Typography>
          <div className={classes.spacer} />
          <Button variant="contained" size={size}>
            Default Cont
          </Button>
          <Button variant="contained" size={size} color="primary">
            Primary Cont
          </Button>
          <Button variant="contained" size={size} color="secondary">
            Secondary Cont
          </Button>
          <Button variant="contained" size={size} disabled>
            Disabled Cont
          </Button>
          <Button size={size}>Default</Button>
          <Button color="primary" size={size}>
            Primary
          </Button>
          <Button color="secondary" size={size}>
            Secondary
          </Button>
          <Button disabled size={size}>
            Disabled
          </Button>
          <Button variant="outlined" size={size}>
            Default Outlined
          </Button>
          <Button variant="outlined" size={size} color="primary">
            Primary Out
          </Button>
          <Button variant="outlined" size={size} color="secondary">
            Secondary Out
          </Button>
          <Button variant="outlined" size={size} disabled>
            Disabled Out
          </Button>
          <div className={classes.spacer} />
        </div>
      );
    });
  };

  return <Paper className={classes.paper}>{generateButtons(buttonSizes)}</Paper>;
};
