import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux/selectors';
import {
  GraphQLPage,
  ISource,
  ISourceType,
  LIMIT,
  SourceAggregationBucket,
  SourceAggregations,
  SourceId,
} from '../types';

export async function getStreamingSources(apiConfigs: ReturnType<typeof selectApiConfigs>, offset = 0) {
  const searchLimit = LIMIT;
  const operationName = 'getSources';
  const name = 'sources';
  const query = `
    query ${operationName} ($offset: Int, $limit: Int ) {
      ${name}(offset: $offset, limit: $limit, includePublic: false) {
        limit
        count
        records {
          id
          name
          createdDateTime
          modifiedDateTime
          sourceTypeId
          thumbnailUrl
          permission
        }
      }
    }
  `;
  const variables = {
    offset: offset,
    limit: searchLimit,
  };
  let result;

  try {
    result = await baseGraphQLApiWithError<{
      [name]: {
        limit: number;
        count: number;
        records: [ISource];
      };
    }>({
      query,
      variables,
      operationName,
      ...apiConfigs,
    });
    return result[name];
  } catch (e) {
    return {};
  }
}

// TODO fix this when search aggregation API is updated from core-search
// LIMIT and OFFSET
export async function getStreamingSource(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  sourceId: string
): Promise<any> {
  function getSearchQuery(sourceId: string) {
    return {
      index: ['mine'],
      query: {
        operator: 'and',
        conditions: [
          {
            operator: 'term',
            field: 'mediaSourceId',
            value: sourceId,
          },
        ],
      },
      aggregate: [
        {
          name: 'mediaSources',
          field: 'mediaSourceId',
          operator: 'term',
          aggregate: [
            {
              name: 'sourceTypeId',
              operator: 'term',
              field: 'mediaSourceTypeId',
            },
            {
              name: 'scheduleCount',
              operator: 'count',
              field: 'programId',
            },
          ],
          sort: {
            field: 'programId',
            criteria: 'count',
          },
        },
        {
          operator: 'count',
          field: 'mediaSourceId',
          name: 'mediaSourcesTotal',
        },
      ],
    };
  }
  const operationName = 'getSourcesSearch';
  const name = 'searchMedia';
  const query = `
    query ${operationName}($search: JSONData!) {
      ${name}(search: $search) {
        jsondata
      }
    }
  `;

  const variables = {
    search: getSearchQuery(sourceId),
  };

  let result;

  try {
    result = await baseGraphQLApiWithError<{
      [name]: {
        jsondata: {
          aggregations?: SourceAggregations;
        };
      };
    }>({
      query,
      variables,
      operationName,
      ...apiConfigs,
      rethrowOriginalError: true,
    });
  } catch (err) {
    return result;
  }

  const buckets = result[name].jsondata.aggregations?.mediaSources?.buckets ?? [];

  return {
    records: mapSourceSearch(buckets),
  };
}

function mapSourceSearch(buckets: SourceAggregationBucket[] = []): ISource[] {
  return buckets.map(source => {
    return {
      id: source.key,
      name: '',
      sourceTypeId: source.sourceTypeId.buckets[0]!.key,
      scheduleCount: source.scheduleCount.value,
      modifiedDateTime: '',
      createdDateTime: '',
      thumbnailUrl: '',
    };
  });
}

export async function getSourceTypes(apiConfigs: ReturnType<typeof selectApiConfigs>) {
  const operationName = 'getSourceTypes';
  const name = 'sourceTypes';
  const query = `
    query ${operationName} {
      ${name}(offset: 0, limit: 50) {
        count
        records {
          id
          name
          iconClass
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [name]: GraphQLPage<ISourceType>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  return result[name];
}

export async function getSource(apiConfigs: ReturnType<typeof selectApiConfigs>, sourceId: SourceId) {
  const operationName = 'getSource';
  const name = 'source';
  const query = `
    query ${operationName} ($id: ID!) {
      ${name}(id: $id) {
        id
        name
        createdDateTime
        modifiedDateTime
        sourceTypeId
        thumbnailUrl
        permission
      }
    }
  `;
  const variables = {
    id: sourceId,
  };

  let result: { [name]: ISource };
  try {
    result = await baseGraphQLApiWithError<{ [name]: ISource }>({
      query,
      variables,
      operationName,
      ...apiConfigs,
    });
    return result[name];
  } catch (e) {
    return {};
  }
}
