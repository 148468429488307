import createSvgIcon from '../createSvgIcon';

export const NonNetworkIsolatedWhite = createSvgIcon(
  <svg width="20" height="20" viewBox="3 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8157 5.61609H12.2498V3H11.7498V5.61609H9.18015V3H8.68015V5.61609H7.09332C6.27761 5.61609 5.61609 6.27761 5.61609 7.09332V8.68015H3V9.18015H5.61609V11.7498H3V12.2498H5.61609V14.8157H3V15.3157H5.61609V16.9112C5.61609 17.7269 6.27761 18.3884 7.09332 18.3884H8.68015V21.0007H9.18015V18.3884H11.7498V21.0007H12.2498V18.3884H14.8157V21.0007H15.3157V18.3884H16.9112C17.7269 18.3884 18.3884 17.7269 18.3884 16.9112V15.3157H21.0007V14.8157H18.3884V12.2498H21.0007V11.7498H18.3884V9.18015H21.0007V8.68015H18.3884V7.09332C18.3884 6.27761 17.7269 5.61609 16.9112 5.61609H15.3157V3H14.8157V5.61609ZM7.09332 6.11609C6.55375 6.11609 6.11609 6.55375 6.11609 7.09332V16.9112C6.11609 17.4508 6.55375 17.8884 7.09332 17.8884H16.9112C17.4508 17.8884 17.8884 17.4508 17.8884 16.9112V7.09332C17.8884 6.55375 17.4508 6.11609 16.9112 6.11609H7.09332ZM14.8 16C15.4626 16 16 15.4626 16 14.8V9.2C16 8.5374 15.4626 8 14.8 8H9.2C8.5374 8 8 8.5374 8 9.2V14.8C8 15.4626 8.5374 16 9.2 16H14.8Z"
      fill="white"
    />
  </svg>,
  'NonNetworkIsolatedWhite'
);
