import { FC, useEffect, PropsWithChildren } from 'react';
import Portal from '@mui/material/Portal';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: 1020,
    position: 'relative',
  },
  panel: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    height: '100%',
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#FFF',
  },
  overlay: {
    backgroundColor: theme.palette.grey[50],
    opacity: `0.5 !important`,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

export const FullScreenContainer: FC<
  PropsWithChildren<{
    fullScreen: boolean;
    preventRerender?: boolean;
    containerClassName?: string;
    dimmed?: boolean;
    dataTest: string;
  }>
> = ({ fullScreen, preventRerender = false, containerClassName = '', dimmed, dataTest, children }) => {
  const { classes } = useStyles();
  const toggleAppBar = (hideAppBar: boolean) => {
    try {
      const appBar = document.querySelector<HTMLDivElement>('#VERITONE_PANEL_ID-root.aiware-el');
      if (appBar) {
        appBar.setAttribute('style', hideAppBar ? 'opacity: 0; pointer-events: none;' : '');
      }
    } catch (e) {
      // TS Ignore
    }
  };
  useEffect(() => {
    if (preventRerender) {
      if (fullScreen) {
        toggleAppBar(true);
      }
    }
    return () => toggleAppBar(false);
  }, [preventRerender, fullScreen]);
  if (preventRerender) {
    return <div className={`${fullScreen ? classes.fullScreen : ''} ${containerClassName}`}>{children}</div>;
  }
  return fullScreen ? (
    <Portal>
      <div
        role="presentation"
        data-test={dataTest}
        className={classNames(
          classes.root,
          classes.panel,
          classes.fullScreen,
          containerClassName ? containerClassName : ''
        )}
      >
        {dimmed && <div className={classes.overlay}></div>}
        {children}
      </div>
    </Portal>
  ) : (
    <div className={containerClassName}>{children}</div>
  );
};
