import { DateTime } from 'luxon';

const dateHelper = (value?: string | undefined) => {
  const dateObject = {
    day: 'N/A',
    time: 'N/A',
    full: 'N/A',
  };
  if (value === undefined) {
    return dateObject;
  }
  const date = DateTime.fromISO(value.split(' ').join(''));
  const day = date.toFormat('LL/dd/yyyy');
  const time = date.toFormat('t');

  if (day !== 'Invalid DateTime') {
    dateObject.day = day;
  }
  if (time !== 'Invalid DateTime') {
    dateObject.time = time;
  }
  dateObject.full = `${dateObject.day} ${dateObject.time}`;

  return dateObject;
};

export default dateHelper;
