import { FC } from 'react';
import { ECapabilityTypes, ISuggestion } from '../../../../types/aiAutocomplete.types';
import { useStyles } from './aiSuggestionItem.styles';
import { AiAvatar } from '../../../AIAvatar';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../state/aiAutocomplete/aiAutocomplete.slice';
import { Typography } from '@mui/material';

interface IAiSuggestionItem {
  label: string;
  suggestions: ISuggestion[];
  onClick: (suggestion: ISuggestion) => void;
}

export const AiSuggestionItem: FC<IAiSuggestionItem> = ({ suggestions, label, onClick }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleClick = (suggestion: ISuggestion) => {
    dispatch(actions.clearSearchTerm());
    onClick(suggestion);
  };

  return (
    <>
      {suggestions?.length > 0 && (
        <dt className={classes.suggestionTitle}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            {label}
          </Typography>
        </dt>
      )}
      {suggestions?.map(suggestion => (
        <dd
          id={`aiAutocomplete-suggestion-item-${label}-${suggestion.id}`}
          data-test-id={`aiAutocomplete-suggestion-item-${label}-${suggestion.id}-test-id`}
          className={classes.suggestionContainer}
          key={suggestion?.id}
          onClick={() => handleClick(suggestion)}
        >
          {/* Render Tags */}
          {suggestion?.capabilityType === ECapabilityTypes.tags && (
            <span>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {suggestion?.label}
              </Typography>
            </span>
          )}

          {/* Render Capabilities other than Tags */}
          {suggestion?.capabilityType !== ECapabilityTypes.tags && (
            <>
              <AiAvatar
                id={`aiAvatar-id-${suggestion?.id}`}
                avatarUrl={suggestion?.imageUrl}
                capabilityType={suggestion.capabilityType}
              />{' '}
              <span style={{ marginLeft: '6px' }}>
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  {suggestion?.label}
                </Typography>
              </span>
            </>
          )}
        </dd>
      ))}
    </>
  );
};
