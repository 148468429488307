import { LoadingStatus } from '@aiware/js/interfaces';
export const namespace = 'libraries';

export type GraphQLPage<T> = {
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export interface ILibrary {
  id: string;
  name: string;
  organizationId: string;
  engineModels: {
    records: EngineModel[];
  };
}

export interface EngineModel {
  id: string;
  engineId: string;
  trainStatus: string;
}

export interface ILibraryList {
  libraries: ILibrary[];
}

export interface IState {
  [namespace]: {
    status: LoadingStatus;
    libraries: ILibrary[];
  };
}
