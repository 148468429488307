import { SxProps } from '@mui/system';

export const styles: Record<string, SxProps> = {
  stepBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
  },
  stepper: { padding: '10px 20px 20px 30px', borderBottom: '0.5px solid #D5DFE9' },
  step: {
    minWidth: '90px',
    padding: 0,
    '&:last-child': {
      minWidth: '350px',
      marginLeft: '10px',
    },
  },
  stepConnector: {
    '.Sdk-MuiStepConnector-line': {
      borderTop: '1px solid #D5DFE9',
    },
  },
  basicLabel: { fill: '#555F7C', fontWeight: 'bold', height: '35px', width: '35px' },
  permissionLabel: { height: '35px', width: '35px', fill: '#9ca8b47a' },
  stepBody: { flexGrow: 1, padding: '10px 10px 30px 30px' },
  stepButtonsContainer: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginRight: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  stepButton: {
    mr: 2,
  },
};

export default styles;
