import SearchVirtualTableHeader from './SearchVirtualTableHeader';
import SearchVirtualTableContent from './SearchVirtualTableContent';
import { EResultsViewStyle } from '../../AISearchResultsView';
import { AiSearchResultsCleared } from './EmptyState/AiSearchResultsCleared';

interface IProps {
  results: any[];
  hasMore: boolean;
  loadResults: () => void;
  viewStyle: EResultsViewStyle;
}

const SearchVirtualTable = ({ results, hasMore, loadResults, viewStyle }: IProps) => {
  const status = 'success';

  const renderTableContent = () => {
    // @ts-ignore
    if (status === 'failure') {
      // TODO: Implement error state and retry method
      return null;
    } else if (status === 'success' && results.length === 0) {
      return <AiSearchResultsCleared />;
    } else {
      return (
        <SearchVirtualTableContent
          results={results}
          hasMore={hasMore}
          loadResults={loadResults}
          viewStyle={viewStyle}
        />
      );
    }
  };
  return (
    <>
      {viewStyle === EResultsViewStyle.COMPRESSED && results.length > 0 && (
        <SearchVirtualTableHeader scrollBarOffset={results.length > 12} />
      )}
      {renderTableContent()}
    </>
  );
};

export default SearchVirtualTable;
