import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  footer: {
    position: 'absolute',
    right: theme.spacing(6),
    bottom: theme.spacing(6),
  },
  closeButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  dialogTitle: {
    padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(1)}px !important `,
  },
  dialogContent: {
    padding: `${theme.spacing(1)} ${theme.spacing(6)} !important `,
  },
  dialogTitleText: {
    fontWeight: 600,
  },
  dialogAction: {
    padding: `${theme.spacing(4)} !important`,
  },
}));
export default useStyles;
