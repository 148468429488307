import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../constants';
import {
  IMyOrganization,
  IOrgAppsAndRoles,
  TInvitee,
  TApplicationInviteRole,
  TOrganizationInvite,
} from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'organizationsState';

export interface IOrganizationsState {
  status: LoadingStatus;
  inviteFetchStatus: LoadingStatus;
  inviteeFetchStatus: LoadingStatus;
  switchStatus: LoadingStatus;
  organizations: IMyOrganization[];
  currentOrg: IOrgAppsAndRoles;
  currentInvitee: string;
  invitees: TInvitee[];
  organizationInvites: TOrganizationInvite[];
  activeOrganizationInviteId: string | null;
}

export const initialState: IOrganizationsState = {
  status: 'idle',
  inviteFetchStatus: 'idle',
  inviteeFetchStatus: 'idle',
  switchStatus: 'idle',
  organizations: [],
  currentOrg: {
    applications: {
      records: [],
    },
    roles: [],
  },
  currentInvitee: '',
  invitees: [],
  organizationInvites: [],
  activeOrganizationInviteId: null,
};

export const organizationsSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    organizationsFetchStart(state) {
      state.status = 'pending';
    },
    organizationsFetchSucceed(state, action: PayloadAction<IMyOrganization[]>) {
      state.status = 'success';
      state.organizations = action.payload;
    },
    organizationsFetchFailed(state) {
      state.status = 'failure';
    },
    currentOrganizationFetchStart(state, _action: PayloadAction<string>) {
      state.status = 'pending';
    },
    currentOrganizationFetchSucceed(state, action: PayloadAction<IOrgAppsAndRoles>) {
      state.status = 'success';
      state.currentOrg = action.payload;
    },
    currentOrganizationFetchFailed(state) {
      state.status = 'failure';
    },
    fetchInviteeStart(state, _action: PayloadAction<string>) {
      state.inviteeFetchStatus = 'pending';
    },
    fetchInviteeSucceed(state, action: PayloadAction<TInvitee>) {
      state.invitees.push(action.payload);
      state.inviteeFetchStatus = 'success';
    },
    fetchInviteeFailed(state) {
      state.inviteeFetchStatus = 'failure';
    },
    setCurrentInvitee(state, action: PayloadAction<string>) {
      state.currentInvitee = action.payload;
    },
    addInviteeRoles(state, action: PayloadAction<TApplicationInviteRole[]>) {
      const inviteeIndex = state.invitees.findIndex(invitee => invitee.email === state.currentInvitee);
      if (inviteeIndex !== -1) {
        state.invitees[inviteeIndex] = {
          ...state.invitees[inviteeIndex]!,
          applicationRoles: action.payload,
        };
        state.currentInvitee = '';
      }
    },
    removeInvitee(state, action: PayloadAction<string>) {
      state.invitees = state.invitees.filter(invitee => invitee.email !== action.payload);
      state.currentInvitee = '';
    },
    switchUserToOrganizationStart(
      state,
      _action: PayloadAction<{ organizationGuid: string; userName: string }>
    ) {
      // status flow: idle -> loading -> pending -> success
      state.switchStatus = 'loading';
    },
    switchUserToOrganizationSucceed(state) {
      // we need to receive 2 success dispatches - 1 from the message animator and 1 from the saga
      if (state.switchStatus === 'loading') {
        state.switchStatus = 'pending';
      } else if (state.switchStatus === 'pending') {
        state.switchStatus = 'success';
        window.location.reload();
      }
    },
    switchUserToOrganizationFailed(state) {
      state.switchStatus = 'failure';
      state.status = 'failure';
    },
    clearInvitees(state) {
      state.invitees = [];
      state.currentInvitee = '';
    },
    fetchOrganizationInvitesStart(state) {
      state.inviteFetchStatus = 'pending';
    },
    fetchOrganizationInvitesSucceed(state, action: PayloadAction<TOrganizationInvite[]>) {
      state.organizationInvites = action.payload;
      state.inviteFetchStatus = 'success';
    },
    fetchOrganizationInvitesFailed(state) {
      state.inviteFetchStatus = 'failure';
    },
    setActiveOrganizationInviteId(state, action: PayloadAction<string>) {
      state.activeOrganizationInviteId = action.payload;
    },
    clearActiveOrganizationInviteId(state) {
      state.organizationInvites = state.organizationInvites.filter(
        invite => invite.organizationInviteId !== state.activeOrganizationInviteId
      );
      state.activeOrganizationInviteId = null;
    },
    setUserDefaultOrg(state, _action: PayloadAction<number>) {
      return state;
    },
  },
});

export const actions = organizationsSlice.actions;
export default organizationsSlice.reducer;
