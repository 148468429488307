import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type TProps = {
  onClose: () => void;
};

export const InvalidAppModal = ({ onClose }: TProps) => {
  return (
    <Dialog open={true}>
      <DialogTitle>
        <Typography
          data-test="aiware-desktop.application-not-available.title"
          variant="h1"
          sx={{ pt: '10px' }}
        >
          <FormattedMessage
            id="aiware-desktop.application-not-available.title"
            defaultMessage="Application Not Available"
            description="Application Not Available label"
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <FormattedMessage
            id="aiware-desktop.application-not-available.message"
            defaultMessage="Looks like the application does not either exist or you don't have the right permissions to access it"
            description="Description current application not available"
          />
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: '20px', pb: '20px' }}>
        <Button onClick={onClose} variant="contained" sx={{ p: '10px 20px' }}>
          <FormattedMessage
            id="aiware-desktop.application-not-available.close-btn"
            defaultMessage="Close"
            description="Close label"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
