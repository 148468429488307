import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import { useStyles } from '../../useStyles';
import { useDispatch } from 'react-redux';
import { EAuthResourceType } from '@aiware/shared/permissions';
import { EntityType } from '@aiware/js/interfaces';
import { actions } from '../../../../redux/slices';
import { ISchedule } from '../../../../types';
import ContextMenuRowButton from '../../ContextMenuRowButton';
import { ENTITY_CONTEXT_MENU } from '../../../../types/entity-context-menus';
import GetRowItem from '../../../shared/GetRowItem';

const scheduleContextMenu = ENTITY_CONTEXT_MENU[EntityType.Schedules];

const SchedulesTableFluffyRow: FunctionComponent<{
  item: ISchedule;
  rowHeight: number;
}> = ({ item, rowHeight }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid data-test={`${EntityType.Schedules}-row-fluffy-${item.id}`} style={{ height: rowHeight }} container>
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignContent="space-around"
        container
        item
        xs={11}
        onClick={() =>
          dispatch(
            actions.viewStreams.addToSourceTreeBreadCrumbs({
              entity: EntityType.Schedules,
              id: item.id,
            })
          )
        }
      >
        <GetRowItem rowName={'thumbnail'} item={item} xs={2} itemType={EntityType.Schedules} />
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="space-around"
          xs={10}
          className={classes.paddingLeft}
        >
          <Grid item container alignContent="center" xs={12}>
            <GetRowItem rowName={'name'} item={item} itemType={EntityType.Schedules} />
            <GetRowItem rowName={'scheduleType'} item={item} xs={1} itemType={EntityType.Schedules} />
          </Grid>

          <Grid item container direction="row" alignItems="center" justifyContent="flex-start" xs={12}>
            <GetRowItem rowName={'createdDateTime'} item={item} xs={6} itemType={EntityType.Schedules} />
            <GetRowItem rowName={'modifiedDateTime'} item={item} xs={6} itemType={EntityType.Schedules} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={1}
        className={classes.moreIcon}
        item
        container
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <ContextMenuRowButton
          contextMenu={scheduleContextMenu}
          entityId={item.id}
          type={EAuthResourceType.Schedule}
        />
      </Grid>
    </Grid>
  );
};
export default SchedulesTableFluffyRow;
