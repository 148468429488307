import createSvgIcon from '../createSvgIcon';

export const DAGTemplate = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F2F7FE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0002 9.2002C19.0061 9.2002 18.2002 10.0061 18.2002 11.0002V11.7002H14.0002C11.9015 11.7002 10.2002 13.4015 10.2002 15.5002V17.0002C10.2002 19.0989 11.9015 20.8002 14.0002 20.8002H17.108C17.4581 22.0687 18.6204 23.0002 20.0002 23.0002C21.38 23.0002 22.5423 22.0687 22.8923 20.8002H26.0002C27.2152 20.8002 28.2002 21.7852 28.2002 23.0002V24.0002C28.2002 25.2152 27.2152 26.2002 26.0002 26.2002H21.8002V26.0002C21.8002 25.0061 20.9943 24.2002 20.0002 24.2002H12.0002C11.0061 24.2002 10.2002 25.0061 10.2002 26.0002V29.0002C10.2002 29.9943 11.0061 30.8002 12.0002 30.8002H20.0002C20.9943 30.8002 21.8002 29.9943 21.8002 29.0002V27.8002H26.0002C28.0989 27.8002 29.8002 26.0989 29.8002 24.0002V23.0002C29.8002 20.9015 28.0989 19.2002 26.0002 19.2002H22.8923C22.5423 17.9317 21.38 17.0002 20.0002 17.0002C18.6204 17.0002 17.4581 17.9317 17.108 19.2002H14.0002C12.7852 19.2002 11.8002 18.2152 11.8002 17.0002V15.5002C11.8002 14.2852 12.7852 13.3002 14.0002 13.3002H18.2002V14.0002C18.2002 14.9943 19.0061 15.8002 20.0002 15.8002H28.0002C28.9943 15.8002 29.8002 14.9943 29.8002 14.0002V11.0002C29.8002 10.0061 28.9943 9.2002 28.0002 9.2002H20.0002ZM19.8002 11.0002C19.8002 10.8897 19.8897 10.8002 20.0002 10.8002H28.0002C28.1107 10.8002 28.2002 10.8897 28.2002 11.0002V14.0002C28.2002 14.1107 28.1107 14.2002 28.0002 14.2002H20.0002C19.8897 14.2002 19.8002 14.1107 19.8002 14.0002V11.0002ZM11.8002 26.0002C11.8002 25.8897 11.8897 25.8002 12.0002 25.8002H20.0002C20.1107 25.8002 20.2002 25.8897 20.2002 26.0002V29.0002C20.2002 29.1107 20.1107 29.2002 20.0002 29.2002H12.0002C11.8897 29.2002 11.8002 29.1107 11.8002 29.0002V26.0002Z"
      fill="#0A499D"
    />
  </svg>,
  'DAGTemplate'
);
