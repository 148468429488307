import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  firstLoadApplicationModalDesc: () => (
    <FormattedMessage
      id="os-admin-center-orgs.first-load-application-modal-description"
      defaultMessage="Select the first application that will be loaded when a user logs into aiWARE."
      description="First Load Application Modal Description"
    />
  ),
  doneBtn: () => (
    <FormattedMessage
      id="os-admin-center-orgs.org-settings-step.done-btn"
      defaultMessage="Done"
      description="First Load Application modal done button"
    />
  ),
};
