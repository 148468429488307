import { FunctionComponent } from 'react';
import { Download, Fullscreen, FullscreenExit, Hide, Information, Process } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { useStyles } from './useStyles';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { TPermissionsEntity, EPermissionAction } from '@aiware/shared/permissions';
import { SHARED_TEXT as DC_TEXT } from '../../../../../helpers/shared-text';
import { FileUploadOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  betaFeaturesSelector,
  createExportEngineAssetsInit,
  selectIsFullscreenEnabled,
  setIsFullscreenEnabled,
} from '@aiware/shared/redux';
import { selectPanelByPanelName, updatePanelConfig } from '@aiware/js/panel';
import { IPanel, IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';

export { useStyles };

const DATA_CENTER_MDP_IFRAME_PANEL = 'DATA_CENTER_MDP_IFRAME_PANEL';

const PanelHeader: FunctionComponent<{
  entityId: string;
  height: number;
  onProcessAi: () => void;
  onDownloadAsset: () => void;
  onViewInfo: () => void;
  onPanelClose: () => void;
  entityPermissions: TPermissionsEntity | undefined;
  isOLPEnabled: boolean | unknown;
  isPermissionsLoading: boolean;
}> = ({
  entityId,
  height,
  onPanelClose,
  onProcessAi,
  onDownloadAsset,
  onViewInfo,
  entityPermissions,
  isOLPEnabled,
  isPermissionsLoading,
}) => {
  const { classes } = useStyles({ height });
  const dispatch = useDispatch();
  const betaFeatures = useSelector(betaFeaturesSelector);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const mdpPanel = useSelector(selectPanelByPanelName(DATA_CENTER_MDP_IFRAME_PANEL)) as unknown as IPanel<
    unknown,
    IVeritoneAppbarPanelConfig
  >;

  const handleToggleFullScreen = () => {
    dispatch(setIsFullscreenEnabled(!isFullScreen));

    const { panelConfig } = mdpPanel;
    const marginStart = isFullScreen ? '80px' : 0;
    const marginTop = isFullScreen ? '55px' : 0;

    dispatch(
      updatePanelConfig({
        panelId: DATA_CENTER_MDP_IFRAME_PANEL,
        panelConfig: {
          ...panelConfig,
          marginStart,
          marginTop,
        },
      })
    );
  };

  const hasOLPPermission = (action: EPermissionAction) => {
    if (!isOLPEnabled) {
      return true;
    }
    if (!entityPermissions) {
      return false;
    }
    if (entityPermissions.status === 'failure') {
      // If the call fails, show all options to be safe
      return true;
    }
    return (
      entityPermissions &&
      entityPermissions.status === 'success' &&
      entityPermissions.permissions &&
      entityPermissions.permissions[action]
    );
  };

  const isPermissionRestricted = (action: EPermissionAction) => {
    if (!isOLPEnabled) return false;
    if (isPermissionsLoading) return true;
    if (hasOLPPermission(action)) return false;

    // OLP enabled and they do NOT have permission
    return true;
  };

  const tooltipHelper = (action: EPermissionAction, tooltip: JSX.Element) => {
    if (!isOLPEnabled) return <>{tooltip}</>;
    if (isPermissionsLoading) return <>{DC_TEXT.checkingPermissionsTooltip()}</>;
    if (isPermissionRestricted(action)) return <>{DC_TEXT.noPermissionsTooltip()}</>;

    return <>{tooltip}</>;
  };

  return (
    <header className={classes.header} data-testid="dc-media-details-iframe-header">
      <Typography variant="h1" data-testid="dc-media-details-iframe-title" className={classes.title}>
        {SHARED_TEXT.panelHeaderTitle()}
      </Typography>
      <div className={classes.headerActionContainer}>
        <Typography style={visuallyHidden} id="dc-mdp-iframe-process-file">
          {SHARED_TEXT.iconProcessAi()}
        </Typography>
        <Tooltip title={tooltipHelper(EPermissionAction.Update, SHARED_TEXT.iconProcessAi())}>
          <span>
            <IconButton
              disabled={isPermissionRestricted(EPermissionAction.Update)}
              onClick={onProcessAi}
              aria-labelledby="dc-mdp-iframe-process-file"
              data-test="dc-mdp-iframe-process-file"
              size="large"
            >
              <Process />
            </IconButton>
          </span>
        </Tooltip>
        <Typography style={visuallyHidden} id="dc-mdp-iframe-download">
          {SHARED_TEXT.iconDownload()}
        </Typography>
        <Tooltip title={tooltipHelper(EPermissionAction.Update, SHARED_TEXT.iconDownload())}>
          <span>
            <IconButton
              disabled={isPermissionRestricted(EPermissionAction.Update)}
              onClick={onDownloadAsset}
              aria-labelledby="dc-mdp-iframe-download"
              data-test="dc-mdp-iframe-download"
              size="large"
            >
              <Download />
            </IconButton>
          </span>
        </Tooltip>
        <Typography style={visuallyHidden} id="dc-mdp-iframe-view-file-info">
          {SHARED_TEXT.iconInfo()}
        </Typography>
        <Tooltip title={tooltipHelper(EPermissionAction.Update, SHARED_TEXT.iconInfo())}>
          <span>
            <IconButton
              disabled={isPermissionRestricted(EPermissionAction.Read)}
              onClick={onViewInfo}
              aria-labelledby="dc-mdp-iframe-view-file-info"
              data-test="dc-mdp-iframe-file-info-button"
              size="large"
            >
              <Information />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={tooltipHelper(EPermissionAction.Update, SHARED_TEXT.iconExportEngineAssets())}>
          <span>
            <IconButton
              disabled={isPermissionRestricted(EPermissionAction.Read)}
              aria-labelledby="dc-mdp-iframe-export-engine-assets"
              data-test="dc-mdp-iframe-export-engine-assets-button"
              size="large"
              onClick={() => {
                dispatch(
                  createExportEngineAssetsInit({
                    includeMedia: true,
                    includeAllEngineAssets: true,
                    outputConfigurations: [],
                    tdoData: [
                      {
                        tdoId: entityId,
                      },
                    ],
                  })
                );
              }}
            >
              <FileUploadOutlined />
            </IconButton>
          </span>
        </Tooltip>
        <div className={classes.verticalDivider}></div>
        <Typography style={visuallyHidden} id="dc-mdp-iframe-fullscreen">
          {isFullScreen ? SHARED_TEXT.iconLeaveFullScreen() : SHARED_TEXT.iconEnterFullScreen()}
        </Typography>
        <Tooltip title={isFullScreen ? SHARED_TEXT.iconLeaveFullScreen() : SHARED_TEXT.iconEnterFullScreen()}>
          <IconButton
            onClick={handleToggleFullScreen}
            aria-labelledby="dc-mdp-iframe-fullscreen"
            data-test="dc-mdp-iframe-fullscreen-toggle"
            size="large"
          >
            {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
        </Tooltip>
        <Typography style={visuallyHidden} id="dc-media-details-iframe-close-panel">
          {SHARED_TEXT.closePanel()}
        </Typography>
        <Tooltip title={SHARED_TEXT.closePanel()}>
          <IconButton
            onClick={onPanelClose}
            aria-labelledby="dc-media-details-iframe-close-panel"
            data-testid="dc-media-details-iframe-close-panel"
            size="large"
          >
            <Hide />
          </IconButton>
        </Tooltip>
      </div>
    </header>
  );
};

export default PanelHeader;
