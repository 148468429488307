import { mountPanel } from '@aiware/js/panel';
import { IMicroFrontend } from '@aiware/js/interfaces';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import NotificationSettingItem from '../../components/commons/NotifcationSettingItem';
import NotificationType from '../../components/commons/NotificationType';
import { updateBannerStyle } from '../../store/actions';
import {
  IGeneralInterface,
  IAppNotificationSetting,
  ISettingModule,
  INotificationType,
  IUtilityNotificationSetting,
} from '../../interfaces/notificationInterfaces';

import {
  notificationUtilitiesSelector,
  notificationApplicationsSelector,
  notificationSettingSelector,
} from '../../store/selector';
import { useStyles } from './useStyles';
import { updateNotificationSetting } from '../../store/actions';

export default function NotificationSetting() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isEnableUtilityNotification, setEnableUtilityNotification] = useState(true);
  const [isEnableAppNotification, setEnableAppNotification] = useState(true);
  const notificationSettings = useSelector(notificationSettingSelector) as ISettingModule;
  const [bannerStyle, setBannerStyle] = useState(notificationSettings.bannerStyle);
  const notificationUtilities = useSelector(notificationUtilitiesSelector);
  const notificationApplications = useSelector(
    notificationApplicationsSelector
  ) as IGeneralInterface<IAppNotificationSetting>;
  const [enableAllowType, setEnableAllowType] = useState(notificationSettings?.allow);
  const [notificationSetting, setNotificationSetting] = useState(notificationSettings);
  const isAllowBannerType =
    notificationSetting?.allowType?.findIndex(item => item === INotificationType.BANNER) !== -1;
  const [enableBannerType, setEnableBannerType] = useState(isAllowBannerType);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBannerStyle(e.target.value);
    dispatch(updateBannerStyle(e.target.value));
  };

  function handleOpenNotificationUtility(panelId: IUtilityNotificationSetting['id']) {
    const notificationFinderMicroFrontend: IMicroFrontend<unknown> = {
      name: 'NOTIFICATION_UTILITY',
      config: {
        mode: 'actionable',
        onClose: () => {
          console.log('onClose');
        },
      },
    };

    const notificationFinderConfig: unknown = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: 472,
      width: 413,
      header: {
        divider: true,
        title: `finder notifications`,
      },
    };
    dispatch(
      mountPanel({
        panelId: panelId || '',
        microFrontend: notificationFinderMicroFrontend,
        panelConfig: notificationFinderConfig,
      })
    );
  }

  function handleOpenApplicationNotification(panelId?: string) {
    const appNotificationMicroFrontend: IMicroFrontend<unknown> = {
      name: 'NOTIFICATION_APPLICATION',
      config: {
        mode: 'actionable',
        onClose: () => {
          console.log('onClose');
        },
      },
    };
    const notificationAppConfig: unknown = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      marginStart: 472,
      width: 413,
      header: {
        divider: true,
        title: `app notifications`,
      },
    };
    dispatch(
      mountPanel({
        panelId: panelId || '',
        microFrontend: appNotificationMicroFrontend,
        panelConfig: notificationAppConfig,
      })
    );
  }

  const handleChangeUtilityEnable = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setEnableUtilityNotification(checked);
  };

  const handleChangeAppEnable = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setEnableAppNotification(checked);
  };

  function handleChangeAllowType(isAllow: boolean) {
    // handle change data
    setEnableAllowType(isAllow);
    if (!isAllow) {
      setEnableUtilityNotification(isAllow);
      setEnableAppNotification(isAllow);
    }
    setNotificationSetting(preState => {
      const allowType = isAllow ? [...preState.allowType] : [];
      return {
        ...preState,
        allow: isAllow,
        allowType,
      };
    });
  }

  function handleSelectNotificationType(type: INotificationType, isSelected: boolean) {
    if (type === INotificationType.BANNER) {
      setEnableBannerType(isSelected);
    }
    setNotificationSetting(preState => {
      const allowType = [...preState.allowType];
      const index = allowType.indexOf(type);
      if (index !== -1 && !isSelected) {
        allowType.splice(index, 1);
      } else {
        allowType.push(type);
      }
      return {
        ...preState,
        allowType,
      };
    });
  }

  useEffect(() => {
    dispatch(updateNotificationSetting(notificationSetting));
  }, [notificationSetting, dispatch]);

  return (
    <AIWareIntlProvider>
      <div className={classes.notificationSetting}>
        <NotificationType
          allowTypes={notificationSetting?.allowType}
          allowNotification={notificationSetting?.allow}
          onChangeAllowType={handleChangeAllowType}
          hideDivider={enableBannerType}
          onChangeNotificationType={handleSelectNotificationType}
        />
        {enableAllowType && enableBannerType && (
          <div>
            <Typography variant="body2" children="Banner Style" />
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="bannerStyle"
                name="bannerStyle"
                value={bannerStyle}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="temporary"
                  control={<Radio />}
                  label={
                    <span>
                      <Typography variant="body2" children="Temporary" />
                    </span>
                  }
                />
                <FormControlLabel
                  value="persistent"
                  control={<Radio />}
                  label={
                    <span>
                      <Typography variant="body2" children="Persistent" />
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
            <Divider className={classes.dividerBigMargin} />
          </div>
        )}

        <div>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h3"
              children={
                <FormattedMessage
                  id="os-notification-panel.qJ0Sxo"
                  defaultMessage="Allow Utility Notifications"
                  description="allow-utility-notification"
                />
              }
            />
            <Switch
              checked={isEnableUtilityNotification}
              onChange={handleChangeUtilityEnable}
              color="primary"
              name="enableUtilityNotification"
              disabled={!enableAllowType}
            />
          </Box>
          <List className={classes.notificationSettingItem}>
            {notificationUtilities?.map(utility => {
              return (
                <NotificationSettingItem
                  key={utility.id}
                  imgSrc={utility.iconUrl}
                  isHover
                  primaryText={
                    <FormattedMessage
                      id="os-notification-panel.tadsU0"
                      defaultMessage="{defaultMessage}"
                      description="title of notification utility:  {defaultMessage}"
                      values={{
                        id: utility.id,
                        defaultMessage: utility.title,
                      }}
                    />
                  }
                  secondaryText={
                    <FormattedMessage
                      id="os-notification-panel.AgAM0k"
                      defaultMessage="{defaultMessage}"
                      description="allowed types of notification utility:  {defaultMessage}"
                      values={{
                        id: utility.id,
                        defaultMessage: utility.allowType.join(', '),
                      }}
                    />
                  }
                  onOpenUtility={handleOpenNotificationUtility}
                  utilityId={utility.id}
                  utilityTitle={utility.title}
                  disabled={!isEnableUtilityNotification}
                />
              );
            })}
          </List>
        </div>

        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.appAllowTitle}
          >
            <Typography
              variant="h3"
              children={
                <FormattedMessage
                  id="os-notification-panel.swIpqY"
                  defaultMessage="Allow Application Notifications"
                  description="allow-application-notification"
                />
              }
            />
            <Switch
              checked={isEnableAppNotification}
              onChange={handleChangeAppEnable}
              color="primary"
              name="checkedB"
              disabled={!enableAllowType}
            />
          </Box>
          <List className={classes.notificationSettingItem}>
            {notificationApplications?.allIds?.map(id => {
              return (
                <NotificationSettingItem
                  key={id}
                  imgSrc={notificationApplications?.byId[id]?.iconUrl ?? ''}
                  isHover
                  primaryText={
                    <FormattedMessage
                      id="os-notification-panel.JvsYLn"
                      defaultMessage="{defaultMessage}"
                      description="title of notification application:  {defaultMessage}"
                      values={{
                        idMessage: id,
                        defaultMessage: notificationApplications?.byId[id]?.name,
                      }}
                    />
                  }
                  secondaryText={
                    <FormattedMessage
                      id="os-notification-panel.AgAM0k"
                      defaultMessage="{defaultMessage}"
                      description="allowed types of notification utility:  {defaultMessage}"
                      values={{
                        id: id,
                        defaultMessage: notificationApplications?.byId[id]?.allowType.join(', '),
                      }}
                    />
                  }
                  onOpenApplication={handleOpenApplicationNotification}
                  applicationId={id}
                  applicationTitle={notificationApplications?.byId[id]?.name}
                  disabled={!isEnableAppNotification}
                />
              );
            })}
          </List>
        </div>
      </div>
    </AIWareIntlProvider>
  );
}
