import { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import map from 'lodash/map';
import slice from 'lodash/slice';
import ProgressStep from './ProgressStep';

interface Props {
  steps: Array<string>;
  stepIconSize: number;
  visited: Array<boolean>;
  start: number;
  end: number;
  invertOverlap?: boolean;
  onClick: (index: number) => void;
  nonLinear?: boolean;
  idPrefix?: string;
}

const OverlappingSteps: FunctionComponent<Props> = ({
  steps,
  stepIconSize,
  visited,
  start,
  end,
  invertOverlap,
  onClick,
  nonLinear,
  idPrefix,
}: Props) => {
  const stepIconOverlapMarginFactor = 0.85;
  const totalSteps = end - start;

  const columnSize = stepIconSize + totalSteps * (stepIconSize * stepIconOverlapMarginFactor);

  const mapSteps = () =>
    map(slice(steps, start, end), (label, offset) => {
      const index = start + offset;
      return (
        <Box
          key={label}
          gridColumn={`${offset + 1} / span 1`}
          gridRow={'1'}
          zIndex={invertOverlap ? totalSteps - offset : offset + 1}
        >
          <ProgressStep
            step={index + 1}
            size={stepIconSize}
            visited={visited[index]}
            label={label}
            onClick={onClick}
            nonLinear={nonLinear}
            isFirstOrOnly={offset === 0 || totalSteps === 1}
            idPrefix={idPrefix}
          />
        </Box>
      );
    });

  return (
    <Box
      display="grid"
      sx={{ marginLeft: `${invertOverlap ? 10 : 0}px` }}
      gridTemplateColumns={`auto fit-content(${columnSize}px) auto`}
    >
      {mapSteps()}
    </Box>
  );
};

export default OverlappingSteps;
