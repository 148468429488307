import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

import { SLICE_NAME, TUserGroup } from '../../types';
import { IAdminCenterState, namespace } from '../slices/admin-center-groups.state';

interface IState {
  [SLICE_NAME]: {
    [namespace]: IAdminCenterState;
  };
}

export const selectOrgUserGroups = (state: IState): TUserGroup[] =>
  state?.[SLICE_NAME]?.[namespace]?.userGroups;

export const selectOrgUserGroupsStatus = (state: IState) => state?.[SLICE_NAME]?.[namespace]?.status;

export const selectUserGroupsPagination = (state: IState) =>
  state?.[SLICE_NAME]?.[namespace]?.userGroupPagination;

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);
