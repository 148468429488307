import { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import { selectCurrentTdoTableDetails } from '../../../../redux/selectors/view-streams';
import { ScheduleId } from '../../../../types';
import TdoTable from './TdoTable';
import EmptyState from '../../../shared/EmptyState';
import { SHARED_TEXT } from '../../../../helpers/shared-text';

const Tdo: FunctionComponent<{
  parentScheduleId: ScheduleId;
}> = ({ parentScheduleId }) => {
  const tdoPage = useSelector(selectCurrentTdoTableDetails);
  const items = tdoPage?.tdos || [];

  if (!tdoPage || parentScheduleId !== tdoPage?.parentScheduleId) {
    return null;
  }

  if (isEmpty(items) && tdoPage?.hasNextPage === false) {
    return (
      <EmptyState
        emptyType={EntityType.Tdos}
        title={SHARED_TEXT.emptyTdosTitle()}
        subtitle={SHARED_TEXT.emptyTdosSubtitle()}
      />
    );
  }

  return <TdoTable parentScheduleId={parentScheduleId} items={items} />;
};
export default Tdo;
