import { useEffect, useState, FC, ChangeEvent, KeyboardEvent } from 'react';
import { useLookupStyles } from './useStyles';
import { TextField, Button, Divider } from '@mui/material';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { injectIntl, WrappedComponentProps, WithIntlProps } from 'react-intl';
import { UserCard } from './user-card';
import { NonAdminInviteMessage } from './non-admin-invite-message';
import { useSelector } from 'react-redux';
import { selectIsUserOrgAdmin } from '@aiware/shared/redux';
import { invitees } from '../../redux/selectors/organizations.selector';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/slices';
import { organizationInvitesSelectors } from '../../redux/selectors';
import { TInvitee, TOrgInviteeInput } from '../../types';
import { hidePanel } from '@aiware/js/panel';

export enum EUserStatus {
  new = 'new',
  existing = 'existing',
  pending = 'pending',
}

export type TUser = {
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  userStatus: EUserStatus;
  applications?: Array<any>;
};

export const panelId = 'INVITE_USERS_PANEL';

function UserLookup({ intl }: WrappedComponentProps) {
  const [userEmail, setUserEmail] = useState('');
  const [errorState, setErrorState] = useState(false);
  const [inviteMessage, setInviteMessage] = useState('');
  const messageMaxChars = 350;

  const organizationId: string = useSelector(organizationInvitesSelectors.selectOrganizationId);
  const isAdmin = useSelector(selectIsUserOrgAdmin);

  const myInvitees = useSelector(invitees) || [];

  const dispatch = useDispatch();

  const { classes } = useLookupStyles();
  const placeholder = intl.formatMessage({
    id: `organization-panel.add-email-address`,
    defaultMessage: 'Add an email address',
    description: 'Multi-Org user lookup placeholder',
  });
  const lookupLabel = intl.formatMessage({
    id: `organization-panel.email-address-label`,
    defaultMessage: 'Email Address',
    description: 'Multi-Org user lookup help text',
  });

  const lookupLabelUserAlreadySelected = intl.formatMessage({
    id: `organization-panel.taken-email-address`,
    defaultMessage: 'This Email Has Been Added Already',
    description: 'Multi-Org user duplicate email entry',
  });

  const lookupErrorLabel = intl.formatMessage({
    id: `organization-panel.invalid-email-address`,
    defaultMessage: 'Please Enter a Valid Email Address',
    description: 'Multi-Org user lookup error help text',
  });

  const optionalMessageLabel = intl.formatMessage({
    id: `organization-panel.optional-message-label`,
    defaultMessage: 'Optional Message',
    description: 'Multi-Org user invite optional message',
  });

  const optionalMessagePlaceholder = intl.formatMessage({
    id: `organization-panel.optional-message-placeholder`,
    defaultMessage: 'Add an optional message to be sent to the invitee...',
    description: 'Multi-Org user invite optional message placeholder',
  });

  const validateEmail = (email: string) => {
    return email.match(
      /* eslint-disable-next-line */
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateAndSetUserEmail = () => {
    if (validateEmail(userEmail) && !enteredEmailAlreadySelected) {
      dispatch(actions.organizationsState.fetchInviteeStart(userEmail.toLowerCase()));
      setUserEmail('');
      setErrorState(false);
    } else {
      !enteredEmailAlreadySelected && setErrorState(true);
    }
  };

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const email = ev.target.value;
    if (errorState) {
      setErrorState(false);
    }
    setUserEmail(email);
  };

  const handleKeyDown = (ev: KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      validateAndSetUserEmail();
    }
  };

  const handleMessageChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const message = ev.target.value;
    if (message.length <= 350) {
      setInviteMessage(message);
    }
  };

  const enteredEmailAlreadySelected = myInvitees.find(
    user => user.email.toLowerCase() === (userEmail || '').toLowerCase()
  );

  const noErrorLabelText = enteredEmailAlreadySelected ? lookupLabelUserAlreadySelected : lookupLabel;

  const labelText = errorState ? lookupErrorLabel : noErrorLabelText;

  const onlyPendingUsers = !myInvitees.find(user => user.status !== 'pending');

  const disableSend = () => {
    let disabled = false;
    if (!myInvitees.length) {
      disabled = true;
    } else if (onlyPendingUsers) {
      disabled = true;
    } else if (
      myInvitees.length &&
      myInvitees.find(
        invitee => (invitee?.applicationRoles || []).length === 0 && invitee?.status !== 'pending'
      )
    ) {
      disabled = true;
    }
    return disabled;
  };

  const onSend = () => {
    const invitees = myInvitees.map(invitee => ({
      email: invitee.email,
      applicationRoles: invitee.applicationRoles,
    }));
    dispatch(
      actions.organizationInvitesState.createOrganizationInviteStart({
        invitees: invitees.filter(inv => (inv.applicationRoles ?? []).length) as TOrgInviteeInput[],
        organizationId,
        message: inviteMessage,
      })
    );
    setInviteMessage('');
    dispatch(hidePanel(panelId));
  };

  return (
    <div className={classes.root} data-testid="multi-org-user-invite-panel">
      <div className={classes.lookupCardsContainer}>
        <p className={classes.addUserText}>{SHARED_TEXT.addNewUsersText}</p>
        {!isAdmin && <NonAdminInviteMessage />}
        <div className={classes.lookupContainer}>
          <TextField
            id="multi-org-user-lookup"
            data-testid="multi-org-user-lookup"
            label={labelText}
            InputLabelProps={{
              shrink: true,
            }}
            value={userEmail}
            className={classes.lookupField}
            placeholder={placeholder}
            onChange={handleChange}
            error={!!errorState || !!enteredEmailAlreadySelected}
            onKeyDown={handleKeyDown}
            disabled={!!(!isAdmin && myInvitees.length)}
          />
          <Button
            variant="outlined"
            disabled={!userEmail || !!enteredEmailAlreadySelected}
            onClick={validateAndSetUserEmail}
            data-testid="add-user-button"
          >
            {SHARED_TEXT.addButtonText}
          </Button>
        </div>
        <div className={classes.cardsContainer}>
          {myInvitees.length
            ? myInvitees.map((user: TInvitee) => {
                const props = { ...user };
                return <UserCard {...props} key={user.email} showAdminData={isAdmin} />;
              })
            : null}
        </div>
      </div>
      <div className={classes.whiteBackgroupContainer}>
        <Divider className={classes.lookupDivider} />
        <div className={classes.messageContainer}>
          <TextField
            id="multi-org-invite-message"
            data-testid="multi-org-invite-message"
            label={optionalMessageLabel}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={4}
            placeholder={optionalMessagePlaceholder}
            className={classes.messageField}
            inputProps={{ maxLength: messageMaxChars }}
            value={inviteMessage}
            onChange={handleMessageChange}
          />
          <div className={classes.messageCharCount} data-testid="multi-org-invite-message-char-count">
            {inviteMessage.length}/{messageMaxChars}
          </div>
        </div>
        <Button
          variant="contained"
          disabled={disableSend()}
          className={classes.sendButton}
          onClick={onSend}
          data-testid="multi-org-send-invitation"
        >
          {SHARED_TEXT.sendInvitationButtonText}
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(UserLookup) as FC<WithIntlProps<unknown>>;
