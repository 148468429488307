import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { ChangeEvent, ReactElement } from 'react';
import { MultiSelectList, SelectList, TxtField } from './fields';
import { makeStyles } from 'tss-react/mui';
import { EngineFieldType, IEngineFieldValue, FieldType } from './engineComponentLoader.types';
import { formatLabel, getFieldErrorMessage } from './engineComponentLoader.helpers';

export const useStyles = makeStyles()(theme => ({
  formControlWrapper: {
    marginTop: 15,
  },
  inputLabel: {
    fontFamily: 'Nunito',
    fontWeight: 300,
    display: 'block',
    fontSize: 14,
    color: '#5C6269',
    marginBottom: 6,
  },
  inputLabelError: {
    color: theme.palette.error.main,
  },
}));

export const EngineComponentLoader = ({
  fields,
  onChange,
  showErrors = false,
}: {
  fields: FieldType[];
  onChange: (field: IEngineFieldValue) => void;
  showErrors: boolean;
}) => {
  const { classes } = useStyles();

  return <>{renderComponents(fields)}</>;

  function renderComponents(fields: FieldType[]) {
    return fields?.map((field, idx) => {
      const label = formatLabel(field?.name || '');
      const fieldId = `engine-configuration-input--${field?.name}-idx-${idx}`;

      const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({
          fieldName: field?.name,
          fieldValue: event.target.value,
        });
      };

      let inputComp: ReactElement;
      const hasErrors = !!getFieldErrorMessage(field) && showErrors;

      switch (field?.type) {
        case EngineFieldType.Picklist:
          inputComp = (
            <SelectList
              id={fieldId}
              label={label}
              required={field?.required}
              options={field?.options}
              value={field?.value}
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EngineFieldType.Text:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              value={field?.value || ''}
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EngineFieldType.Number:
          inputComp = (
            <TxtField
              id={fieldId}
              label={label}
              required={field?.required}
              value={field?.value || ''}
              type="number"
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;
        case EngineFieldType.MultiPicklist:
          inputComp = (
            <MultiSelectList
              id={fieldId}
              label={field?.name}
              required={field?.required}
              options={field?.options}
              values={field?.values || []}
              onChange={handleInputOnChange}
              error={hasErrors}
            />
          );
          break;

        default:
          return null;
      }

      return (
        <FormControl error={hasErrors} size="small" className={classes.formControlWrapper} key={fieldId}>
          <label
            htmlFor={fieldId}
            className={`${classes.inputLabel} ${hasErrors && classes.inputLabelError}`}
          >
            {label}
          </label>
          {inputComp}
          {hasErrors && <FormHelperText>{getFieldErrorMessage(field)}</FormHelperText>}
        </FormControl>
      );
    });
  }
};
