import { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { FormattedMessage } from 'react-intl';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { langs } from '@aiware/os/helpers';
import SHARED_TEXT from '../../../../../helper/shared-text';
import useStyles from './profileSettings.styles';

interface IProfileSettings {
  onPreferredLanguageChange: (language: string) => void;
  selectedLanguage: string;
}

export const ProfileSettings = ({ onPreferredLanguageChange, selectedLanguage }: IProfileSettings) => {
  const [preferredLanguage, setPreferredLanguage] = useState('');
  const { classes } = useStyles();

  useEffect(() => {
    setPreferredLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const options = langs.map(({ locale, name }) => ({
    value: locale,
    name,
  }));

  function handleOnChange(e: SelectChangeEvent<string>) {
    const selectedLanguage = e.target.value as string;
    // optimistic UI for dropdown
    setPreferredLanguage(selectedLanguage);
    onPreferredLanguageChange(selectedLanguage);
  }

  return (
    <>
      <Typography variant="body1" className={classes.settingsHeader}>
        {SHARED_TEXT.settingsHeader()}
      </Typography>
      <Typography variant="body2">{SHARED_TEXT.settingsSubHeader()}</Typography>
      <FormControl className={`${classes.fullWidth} ${classes.topMargin}`}>
        <label className={classes.inputLabel} htmlFor="personal-profile-settings-preferred-language">
          <FormattedMessage
            id="os-app-bar-panel.preferredLanguage"
            defaultMessage="Preferred Language"
            description="Language selection label"
          />
        </label>
        <Select
          id="personal-profile-settings-preferred-language"
          className={`${classes.fullWidth}`}
          value={preferredLanguage}
          onChange={handleOnChange}
        >
          {options?.map(option => {
            return <MenuItem value={option?.value}>{option?.name}</MenuItem>;
          })}
        </Select>
        <Typography className={classes.disclaimerText} variant="subtitle1">
          <FormattedMessage
            id="os-app-bar-panel.preferredLanguageDisclaimer"
            defaultMessage="Not all products may support your preferred language selection. If you choose a primary language that isn't available, you may not see it reflected in the product you are currently viewing."
            description="Disclaimer text for preferred language"
          />
        </Typography>
      </FormControl>
    </>
  );
};
