import { put, select } from 'redux-saga/effects';
import adminCenterApi from '../../api';
import { actions } from '../slices';
import { TUserGroup, GraphQLPage, PAGE_LIMIT } from '../../types';
import { selectApiConfigs, selectUserGroupsPagination } from '../selectors';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { showMessage, MessageSeverity, preferredLanguageSelector } from '@aiware/shared/redux';

import { selectAdminCenterOrgGuidParameter } from '@aiware/os/admin-center/shared';

export function* createUserGroupSaga(
  action: ReturnType<typeof actions.adminCenterGroups.createUserGroupStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgGuid: string | undefined = yield select(selectAdminCenterOrgGuidParameter);

    const newUserGroup: TUserGroup = yield adminCenterApi.userGroupsApi.createUserGroup(
      apiConfigs,
      action.payload,
      orgGuid
    );

    yield put(actions.adminCenterGroups.createUserGroupSuccess(newUserGroup));
    yield put(
      showMessage({
        content: formatMessage({
          id: 'os-admin-center-groups.create-user-group-success-message',
          defaultMessage: 'The group was successfully created',
          description: 'Confirmation message when a group is successfully created',
        })!,
        severity: MessageSeverity.Success,
      })
    );
  } catch (e) {
    yield put(actions.adminCenterGroups.createUserGroupFailure());
    console.log(e);
  }
}

export function* fetchOrganizationUserGroupsSaga() {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const { offset, hasMore } = yield select(selectUserGroupsPagination);
    const orgGuid: string | undefined = yield select(selectAdminCenterOrgGuidParameter);

    if (hasMore) {
      const orgUserGroups: GraphQLPage<TUserGroup> = yield adminCenterApi.userGroupsApi.fetchUserGroups(
        apiConfigs,
        offset,
        PAGE_LIMIT,
        orgGuid
      );
      const filteredGroups = orgUserGroups?.records?.filter(group => !group?.name.includes('APITOKEN:'));

      yield put(
        actions.adminCenterGroups.fetchUserGroupsSuccess({
          userGroups: filteredGroups,
          hasMore: PAGE_LIMIT === orgUserGroups.records.length,
          nextOffset: offset + PAGE_LIMIT,
        })
      );
    }
  } catch (e) {
    yield put(actions.adminCenterGroups.fetchUserGroupsFailure());
    console.log(e);
  }
}

export function* updateUserGroupsSaga(
  action: ReturnType<typeof actions.adminCenterGroups.updateUserGroupsStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgGuid: string | undefined = yield select(selectAdminCenterOrgGuidParameter);

    const updatedGroup: TUserGroup = yield adminCenterApi.userGroupsApi.updateUserGroup(
      apiConfigs,
      action,
      orgGuid
    );
    yield put(actions.adminCenterGroups.updateUserGroupsSuccess(updatedGroup));
    yield put(
      showMessage({
        content: formatMessage({
          id: 'os-admin-center-groups.update-user-group-success-message',
          defaultMessage: 'The group was successfully updated',
          description: 'Confirmation message when a group is successfully updated',
        })!,
        severity: MessageSeverity.Success,
      })
    );
  } catch (e) {
    yield put(actions.adminCenterGroups.updateUserGroupsFailure());
    console.log(e);
  }
}

export function* addMembersToUserGroupsSaga(
  action: ReturnType<typeof actions.adminCenterGroups.addMembersToUserGroupStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgGuid: string | undefined = yield select(selectAdminCenterOrgGuidParameter);

    const updatedGroup: TUserGroup = yield adminCenterApi.userGroupsApi.addMembersToUserGroup(
      apiConfigs,
      action,
      orgGuid
    );
    yield put(actions.adminCenterGroups.addMembersToUserGroupSuccess(updatedGroup));
  } catch (e) {
    yield put(actions.adminCenterGroups.addMembersToUserGroupFailure());
    console.log(e);
  }
}

export function* removeMembersFromUserGroupsSaga(
  action: ReturnType<typeof actions.adminCenterGroups.removeMembersFromUserGroupStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgGuid: string | undefined = yield select(selectAdminCenterOrgGuidParameter);

    const deletedGroupId: TUserGroup = yield adminCenterApi.userGroupsApi.removeMembersFromUserGroup(
      apiConfigs,
      action,
      orgGuid
    );
    yield put(actions.adminCenterGroups.removeMembersFromUserGroupSuccess(deletedGroupId));
  } catch (e) {
    if (e instanceof Error) {
      console.log(e);
      yield put(
        showMessage({
          content: e.message,
          severity: MessageSeverity.Error,
        })
      );
    }
  }
}
export function* deleteUserGroupsSaga(
  action: ReturnType<typeof actions.adminCenterGroups.deleteUserGroupStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgGuid: string | undefined = yield select(selectAdminCenterOrgGuidParameter);

    const deletedGroupId: { id: string } = yield adminCenterApi.userGroupsApi.deleteUserGroup(
      apiConfigs,
      action,
      orgGuid
    );
    yield put(actions.adminCenterGroups.deleteUserGroupSuccess(deletedGroupId));
    yield put(
      showMessage({
        content: formatMessage({
          id: 'os-admin-center-groups.delete-user-group-success-message',
          defaultMessage: 'The group was successfully deleted',
          description: 'Confirmation message when a group is successfully deleted',
        })!,
        severity: MessageSeverity.Success,
      })
    );
  } catch (e) {
    yield put(actions.adminCenterGroups.deleteUserGroupFailure());
    console.log(e);
  }
}
