import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import { selectIsFluffy } from '../../../redux/selectors/ui-state';
import { selectCurrentFolderDetails } from '../../../redux/selectors/view-my-files';
import { actions } from '../../../redux/slices';
import { IFileOrFolderVm, InfiniteWrapperItemType } from '../../../types';
import InfiniteWrapper from '../../shared/InfiniteWrapper';

const FoldersAndFilesTable: FunctionComponent<{
  parentFolderId: string;
  items: IFileOrFolderVm[];
  rowHeight: number;
  dataCenterView?: boolean;
}> = ({ parentFolderId, items, rowHeight, dataCenterView }) => {
  const currentFolderAndFiles = useSelector(selectCurrentFolderDetails);
  const isFluffy = useSelector(selectIsFluffy);
  const hasNextPage = currentFolderAndFiles?.hasMore;

  return (
    <InfiniteWrapper
      id={isFluffy ? 'fluffy' : 'condensed'}
      items={items as InfiniteWrapperItemType}
      hasNextPage={hasNextPage}
      itemSize={rowHeight}
      loadAction={actions.viewMyFiles.foldersOrFilesFetchStart(parentFolderId)}
      status={currentFolderAndFiles?.status}
      rowType={EntityType.Folders}
      parentEntityId={parentFolderId}
      heightAdjust={isFluffy ? 40 : 0}
      dataCenterView={dataCenterView}
    />
  );
};

export default FoldersAndFilesTable;
