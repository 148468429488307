import { useState, useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import BasicInfo from '../../components/BasicInfo';
import ButtonBar from './ButtonBar';
import DeleteButton from './DeleteButton';
import useStyles from './useStyles';
import { actions } from '../../../redux/slices';
import { TUserGroup } from '../../../types';

interface IProps {
  group: TUserGroup;
  onClose: () => void;
}

const checkChanges = (name: string, description: string, group: TUserGroup) =>
  (!!name && name !== group.name) || (!!description && description !== group.description);

export const SettingsTab: FC<IProps> = ({ group, onClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [nameError, setNameError] = useState(false);
  const dispatch = useDispatch();
  const isGroupProtected = group?.isProtected;

  const nameExistsCheck = (value: boolean) => {
    setNameError(value);
  };

  const { classes } = useStyles();

  const handleCancel = () => {
    setName('');
    setDescription('');
    onClose();
  };

  const handleSave = useCallback(() => {
    dispatch(
      actions.adminCenterGroups.updateUserGroupsStart({
        id: group.id,
        name,
        description,
      })
    );
  }, [name, description, group, dispatch]);

  const handleDelete = useCallback(() => {
    dispatch(actions.adminCenterGroups.deleteUserGroupStart(group.id));
    onClose();
  }, [group, dispatch, onClose]);

  const isDirty = checkChanges(name, description, group);

  return (
    <Box className={classes.container} data-test="user-group-details.settings-tab-container">
      <div className={classes.body}>
        <BasicInfo
          group={group}
          onNameChange={setName}
          onDescriptionChange={setDescription}
          nameExistsCheck={nameExistsCheck}
        />
        {!isGroupProtected && <DeleteButton onDelete={handleDelete} />}
      </div>
      <ButtonBar isValid={isDirty && !nameError} onCancel={handleCancel} onSave={handleSave} />
    </Box>
  );
};

export default SettingsTab;
