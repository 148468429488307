import { EngineFieldType, FieldType } from './engineComponentLoader.types';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import startCase from 'lodash/startCase';

export const formatLabel = (label = '') => startCase(label);

export const getFieldErrorMessage = (field: FieldType): string => {
  const { required, value, min, max, type, values } = field;

  if (isEmpty(type === EngineFieldType.MultiPicklist ? values : value)) {
    return required ? "This field can't be blank!" : '';
  }

  if (type === EngineFieldType.Number) {
    if (isNumber(min) && Number(value) < min) {
      return `The value can't be lower than ${min.toFixed(2)}`;
    }
    if (isNumber(max) && Number(value) > max) {
      return `The value can't be higher than ${max.toFixed(2)}`;
    }
  }

  return '';
};
