import IconMimeHelper from '../../../../shared/IconMimeHelper';

/** This function takes a time formatted 12:00:00 PM and
 * removes the seconds, for example this will return 12:00 PM */
export const removeTimeStringSeconds = (timeString: string) => {
  const [time, AMPM] = timeString.split(' ');
  const timeWithoutSeconds = time?.slice(0, -3);
  return `${timeWithoutSeconds} ${AMPM}`;
};

const fileTypes = ['image', 'video'];
export const isFileTypeVideoOrImage = (fileType: string): boolean => {
  if (!fileType) return false;
  for (const extension of fileTypes) {
    if (fileType?.includes(extension)) {
      return true;
    }
  }
  return false;
};

export const renderFileTypeIcon = (fileType: string): JSX.Element => {
  return <IconMimeHelper value={fileType} />;
};
