import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME, EResourceType, TAuthACE } from '../../types';
import { LoadingStatus } from '@aiware/js/interfaces';

export const namespace = 'permissionsWidget';

export type TPermissionsWidgetState = {
  existingEntriesByResource: {
    [resourceType in EResourceType]?: {
      [resourceId: string]: {
        status: LoadingStatus;
        entries?: TAuthACE[];
      };
    };
  };
};

export interface IFetchEntriesPayload {
  resourceType: EResourceType;
  resourceId: string;
}

export interface IFetchEntriesSuccessPayload extends IFetchEntriesPayload {
  entries: TAuthACE[];
}

export const initialState: TPermissionsWidgetState = {
  existingEntriesByResource: {},
};

export const adminCenterSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    fetchEntriesStart(state, action: PayloadAction<IFetchEntriesPayload>) {
      if (!state.existingEntriesByResource[action.payload.resourceType]) {
        state.existingEntriesByResource[action.payload.resourceType] = {};
      }
      state.existingEntriesByResource[action.payload.resourceType]![action.payload.resourceId] = {
        status: 'loading',
      };
    },
    fetchEntriesSuccess(state, action: PayloadAction<IFetchEntriesSuccessPayload>) {
      state.existingEntriesByResource[action.payload.resourceType]![action.payload.resourceId]!.status =
        'success';
      state.existingEntriesByResource[action.payload.resourceType]![action.payload.resourceId]!.entries =
        action.payload.entries;
    },
    fetchEntriesFailure(state, action: PayloadAction<IFetchEntriesPayload>) {
      state.existingEntriesByResource[action.payload.resourceType]![action.payload.resourceId]!.status =
        'failure';
    },
  },
});

export const actions = adminCenterSlice.actions;
export default adminCenterSlice.reducer;
