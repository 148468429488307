import { Email, HelpCenter, Banner } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import { useEffect, useState, ReactElement } from 'react';

import { FormattedMessage } from 'react-intl';
import { INotificationType } from '../../../interfaces/notificationInterfaces';
import { NotificationTypeItem } from '../NotificationTypeItem';
import useStyles from './useStyles';

const defaultNotificationTypeList = [
  {
    id: INotificationType.EMAIL,
    notificationTypeIcon: <Email />,
    notificationTypeName: (
      <FormattedMessage
        id="os-notification-panel.rTFvFj"
        defaultMessage="Email"
        description="allow notification types: email"
      />
    ),
    isSelected: true,
  },
  {
    id: INotificationType.NOTIFICATION_CENTER,
    notificationTypeIcon: <HelpCenter />,
    notificationTypeName: (
      <FormattedMessage
        id="os-notification-panel.SmyvFq"
        defaultMessage="Notification Center"
        description="allow notification types: Notification Center"
      />
    ),
    isSelected: true,
  },
  {
    id: INotificationType.BANNER,
    notificationTypeIcon: <Banner />,
    notificationTypeName: (
      <FormattedMessage
        id="os-notification-panel.r7Whww"
        defaultMessage="Banners"
        description="allow notification types: Banners"
      />
    ),
    isSelected: true,
  },
];

export interface INotificationTypeProps {
  notificationTypeTitle?: string;
  children?: ReactElement;
  description?: ReactElement;
  allowTypes?: INotificationType[];
  allowNotification?: boolean;
  onChangeAllowType?: (value: boolean) => void;
  hideDivider?: boolean;
  onChangeNotificationType: (type: INotificationType, isSelected: boolean) => void;
  disabledTypes?: INotificationType[];
}

export function NotificationType(props: INotificationTypeProps) {
  const {
    notificationTypeTitle,
    children,
    description,
    allowTypes = [],
    allowNotification,
    onChangeAllowType,
    hideDivider,
    onChangeNotificationType,
    disabledTypes = [],
  } = props;
  const [enableNotification, setEnableNotification] = useState(!!allowNotification);
  const [notificationTypeList, setNotificationTypeList] = useState(defaultNotificationTypeList);
  const { classes } = useStyles();
  const allowTypeDependency = [...allowTypes];
  const [allowedTypeList, setAllowedTypeList] = useState(allowTypes);

  const title = notificationTypeTitle ? notificationTypeTitle : 'Allow Notifications';

  useEffect(() => {
    const currentList = defaultNotificationTypeList.map(item => {
      const isSelected = allowedTypeList.findIndex(type => type === item.id) !== -1;
      const isDisabled = disabledTypes.findIndex(type => type === item.id) !== -1;
      return {
        ...item,
        isSelected,
        isDisabled,
      };
    });
    setNotificationTypeList(currentList);
  }, [allowTypeDependency.join(', '), allowedTypeList]);

  function handleChangeAllowNotificationType() {
    setEnableNotification(preState => !preState);
  }

  useEffect(() => {
    onChangeAllowType?.(enableNotification);
    if (!enableNotification) {
      setAllowedTypeList([]);
    } else if (allowedTypeList.length === 0 && enableNotification) {
      setAllowedTypeList([INotificationType.EMAIL]);
    }
  }, [enableNotification]);

  return (
    <div className={classes.notificationTypeBox}>
      <div className={classes.notificationTypeHeader}>
        <Typography
          variant="h3"
          children={
            <FormattedMessage
              id="os-notification-panel.8Z0yyD"
              defaultMessage="{title}"
              description="notification setting: allow notification types"
              values={{
                title,
              }}
            />
          }
        />
        <Switch
          checked={enableNotification}
          onChange={handleChangeAllowNotificationType}
          color="primary"
          name="notificationTypeChecked"
          data-testid="notification-type-switch"
        />
      </div>
      {children}
      <div className={classes.notificationList}>
        {notificationTypeList.map(item => {
          const isDisabled = disabledTypes.findIndex(type => type === item.id) !== -1 || !enableNotification;
          return (
            <div key={`${item.id}-${item.isSelected}`}>
              <NotificationTypeItem
                notificationTypeIcon={item.notificationTypeIcon}
                notificationTypeName={item.notificationTypeName}
                defaultSelected={item.isSelected}
                onSelectNotificationType={onChangeNotificationType}
                notificationTypeId={item.id}
                isDisabled={isDisabled}
              />
            </div>
          );
        })}
      </div>
      {description}
      {!hideDivider && (
        <Divider className={description ? classes.divider : classes.dividerWithoutDescription} />
      )}
    </div>
  );
}

export default NotificationType;
