import { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import { selectPageSchedulesBySourceId } from '../../../../redux/selectors/view-streams';
import { selectScheduleEntities } from '../../../../redux/selectors/entities';
import { DataCenterState } from '../../../../redux/slices';
import { SourceId } from '../../../../types';
import EmptyState from '../../../shared/EmptyState';
import { SHARED_TEXT } from '../../../../helpers/shared-text';
import SchedulesTable from './SchedulesTable';

const Schedules: FunctionComponent<{ parentSourceId: SourceId }> = ({ parentSourceId }) => {
  const scheduleEntities = useSelector(selectScheduleEntities);
  const schedulesPage = useSelector(state =>
    selectPageSchedulesBySourceId(state as DataCenterState, parentSourceId)
  );

  const items = schedulesPage?.ids.map((scheduleId: string | number) => scheduleEntities[scheduleId]!) || [];

  if (!schedulesPage) {
    return null;
  }

  if (isEmpty(items) && schedulesPage?.hasNextPage === false) {
    return (
      <EmptyState
        emptyType={EntityType.Schedules}
        title={SHARED_TEXT.emptySchedulesTitle()}
        subtitle={SHARED_TEXT.emptySchedulesSubtitle()}
      />
    );
  }

  return <SchedulesTable parentSourceId={parentSourceId} items={items} />;
};

export default Schedules;
