import { IOpenIdConnector } from '../../../types';
import { namespace } from './selector';

export const GET_OPEN_ID_CONNECTORS = `aiware/${namespace}/get open id connectors`;
export const GET_OPEN_ID_SUCCESS = `aiware/${namespace}/get open id connectors success`;
export const GET_CONNECTED_OPEN_ID_SUCCESS = `aiware/${namespace}/get connected open id connectors success`;
export const GET_OPEN_ID_ERROR = `aiware/${namespace}/get open id connectors error`;

export function getOpenIdConnectors() {
  return {
    type: GET_OPEN_ID_CONNECTORS,
  };
}

export function getOpenIdConnectorSuccess(data: IOpenIdConnector[]) {
  return {
    type: GET_OPEN_ID_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getConnectedOpenIdsSuccess(data: IOpenIdConnector[]) {
  return {
    type: GET_CONNECTED_OPEN_ID_SUCCESS,
    payload: {
      data,
    },
  };
}
export function getOpenIdConnectorError(message: string) {
  return {
    type: GET_OPEN_ID_ERROR,
    payload: {
      message,
    },
  };
}
