import Box from '@mui/material/Box';
import SHARED_TEXT from '../../../helper/shared-text';
import useStyles from './useStyles';
import { AlarmTwo } from '@aiware/shared/icons';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

export interface IErrorStatePros {
  onRetry: () => void;
}

const ErrorState = ({ onRetry }: IErrorStatePros) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Icon className={classes.icon}>
        <AlarmTwo fontSize="large" />
      </Icon>
      <Typography variant="body2" className={classes.text} data-test="account-linking-error-message">
        {SHARED_TEXT.errorState()}
      </Typography>
      <Box mt={4} />
      <Button variant="text" size="large" data-test="account-linking-retry-btn" onClick={onRetry}>
        {SHARED_TEXT.retry()}
      </Button>
    </div>
  );
};

export default ErrorState;
