import { PanelTableLoadingState, PanelLoadingState } from './components/panel-content';

export { PanelHeader } from './components/panel-header';
export { PanelContainer } from './components/panel-container';
export { PanelContent, PanelErrorState } from './components/panel-content';
export { PanelStepperTabs } from './components/panel-stepper-tabs';
export { PanelModal } from './components/panel-modal';
export { FullscreenPortal } from './components/fullscreen-portal';

export const SkeletonLoadingStates = {
  PanelLoadingState: PanelLoadingState,
  TableLoadingState: PanelTableLoadingState,
};
