import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Tooltip, Typography, IconButton } from '@mui/material';
import { Hamburger, Information, List, Settings, MoveFolder } from '@aiware/shared/icons';
import { selectCurrentView, selectIsFluffy } from '../../redux/selectors/ui-state';
import { selectSelectedItems } from '../../redux/selectors/view-my-files';
import { actions } from '../../redux/slices';
import { EntityTypesWithActions } from '../../types';
import { DataCenterView } from '@aiware/shared/redux';
import { ENTITY_TOOLBAR_ACTIONS } from '../../types/entity-toolbar-actions';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { visuallyHidden } from '@mui/utils';
import { FormattedMessage } from 'react-intl';
import {
  EPermissionAction,
  TPermissionsEntity,
  selectors,
  convertEntityToAuthResource,
  hasPermission,
  isPermissionLoading,
} from '@aiware/shared/permissions';
import { SelectedItemsActionButton } from './SelectedItemsActionButton';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  verticalDivider: {
    borderRight: `.5px solid ${theme.palette.divider}`,
    height: '70%',
    margin: `0 ${theme.spacing(1)}`,
  },
  hide: {
    display: 'none',
  },
}));

const ActionBar: FunctionComponent<{
  entityType: EntityTypesWithActions;
  entityId?: string;
}> = ({ entityType, entityId }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isFluffy = useSelector(selectIsFluffy);
  const entityActions = ENTITY_TOOLBAR_ACTIONS[entityType] || [];
  const authResourceType = convertEntityToAuthResource(entityType);
  const entityPermissions: TPermissionsEntity | undefined = useSelector(
    selectors.selectPermissionsByEntity(authResourceType, entityId)
  );

  const isAllowed = !!hasPermission(entityPermissions, EPermissionAction.Update);
  const isDisabled = isPermissionLoading(entityPermissions) || !isAllowed;

  const handleAction = (action: ActionCreatorWithPayload<string>) => {
    !!action && !!entityId && dispatch(action(entityId));
  };

  const currentView = useSelector(selectCurrentView);
  const id: string | null = useSelector(selectSelectedItems)[0] || '';
  const selectedItems = useSelector(selectSelectedItems);
  const isSelectingItems = !!selectedItems.length;

  const moveTo = (count: number) => (
    <FormattedMessage
      id="os-data-center-browse.search-results-view-actions.move-to"
      defaultMessage="Move {count}"
      values={{
        count: count === 1 ? '1 File' : count + ' Files',
      }}
      description="Move TDO to new folder"
    />
  );

  const renderWithPermissions = (itemProps: any) => {
    const { name, action, testLabel, Icon, index, permissionRequired } = itemProps;
    const isAllowed = !permissionRequired || !!hasPermission(entityPermissions, permissionRequired);
    const isDisabled = isPermissionLoading(entityPermissions) || !isAllowed;
    const tooltipText = isPermissionLoading(entityPermissions)
      ? SHARED_TEXT.checkingPermissionsTooltip()
      : isAllowed
      ? undefined
      : SHARED_TEXT.noPermissionsTooltip();
    const id = `toolbar-action-${index}`;
    const toRender = (
      <span key={index}>
        <Typography style={visuallyHidden} id={id}>
          {name()}
        </Typography>
        <IconButton
          onClick={() => handleAction(action)}
          aria-labelledby={id}
          data-test={`dc-action-btn-${testLabel}`}
          size="large"
          disabled={isDisabled}
        >
          <Icon />
        </IconButton>
      </span>
    );

    return (
      <Tooltip key={id} title={tooltipText || name()}>
        {toRender}
      </Tooltip>
    );
  };

  const handleClickMove = () => {
    dispatch(actions.tdos.moveTdoStart(id));
  };

  return (
    <>
      {entityActions.map((itemProps, index) => {
        // Disable new folder icon when selecting files
        if (itemProps.testLabel === 'new-folder' && isSelectingItems) return null;

        return renderWithPermissions({ ...itemProps, index });
      })}
      {currentView === DataCenterView.myFiles && isSelectingItems && (
        <>
          <SelectedItemsActionButton />
          <Tooltip title={moveTo(selectedItems.length)}>
            <span>
              <Typography style={visuallyHidden} id={id}>
                Move
              </Typography>
              <IconButton
                onClick={handleClickMove}
                aria-labelledby={id}
                disabled={isDisabled}
                data-test={`dc-action-btn-moveTdo-${id}`}
                size="large"
              >
                <MoveFolder />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
      {!!entityActions.length && <div className={classes.verticalDivider}></div>}

      <Typography style={visuallyHidden} id="dc-toolbar-open-table-settings">
        {SHARED_TEXT.tableDataSettings()}
      </Typography>
      <Tooltip title={SHARED_TEXT.tableDataSettings()}>
        <IconButton
          onClick={() => dispatch(actions.uiState.toggleTableDataSettings(entityType))}
          data-test="dc-open-table-settings"
          aria-labelledby="dc-toolbar-open-table-settings"
          size="large"
        >
          <Settings />
        </IconButton>
      </Tooltip>

      <Typography style={visuallyHidden} id="dc-toolbar-toggle-view">
        {SHARED_TEXT.toggleView()}
      </Typography>
      <Tooltip title={SHARED_TEXT.toggleView()}>
        <IconButton
          onClick={() => dispatch(actions.uiState.toggleFluffyView())}
          data-test="dc-fluffy-view-icon"
          aria-labelledby="dc-toolbar-toggle-view"
          size="large"
        >
          {isFluffy ? <Hamburger /> : <List />}
        </IconButton>
      </Tooltip>

      <Typography style={visuallyHidden} id="dc-toolbar-toggle-info-panel">
        {SHARED_TEXT.toggleInfoPanel()}
      </Typography>
      <Tooltip title={SHARED_TEXT.toggleInfoPanel()}>
        <IconButton
          onClick={() => dispatch(actions.uiState.toggleInfo())}
          data-test="dc-info-icon"
          aria-labelledby="dc-toolbar-toggle-info-panel"
          className={classes.hide}
          size="large"
        >
          <Information />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ActionBar;
