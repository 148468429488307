import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  notificationSetting: {
    padding: theme.spacing(3, 6, 4.4),
    overflowY: 'scroll',
    height: '100%',
  },
  appAllowTitle: {
    paddingTop: theme.spacing(2.4),
  },
  dividerBigMargin: {
    marginTop: theme.spacing(2.4),
    marginBottom: theme.spacing(3),
  },
  notificationSettingItem: {
    padding: 0,
  },
  bannerStyleLabel: {
    fontWeight: 'normal',
  },
}));
