import { ECapabilityTypes } from '../../types/aiAutocomplete.types';

export const suggestionResultKey = {
  objects: ECapabilityTypes.object,
  logos: ECapabilityTypes.logo,
  tags: ECapabilityTypes.tags,
  facial: ECapabilityTypes.facial,
  fileNames: ECapabilityTypes.fileName,
  recognizedText: ECapabilityTypes.recognizedText,
};
