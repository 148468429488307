import { TPermissionsEntity } from '../redux/slices';
import { EAuthResourceType, EAuthSubResourceType, EPermissionAction } from '../types';
import { EntityType } from '@aiware/js/interfaces';

type TPermissionActionPairs = Partial<Record<EPermissionAction, string>>;

type TPermissionDetailPairs = Partial<Record<EAuthSubResourceType, TPermissionActionPairs>>;

type TPermissionCategory = EPermissionAction | EAuthSubResourceType;

type TPermissionPairs = Partial<Record<EAuthResourceType, TPermissionActionPairs | TPermissionDetailPairs>>;

type TPermissionQuery = {
  targetObjectType: EAuthResourceType;
  targetActions: {
    targetAction: string;
    resource?: string;
    serverName: string;
  }[];
};

type TActionRights = Partial<Record<EPermissionAction, string[]>>;

type TResourceRights = Partial<Record<EAuthResourceType | EAuthSubResourceType, TActionRights>>;

const orgRightsByObjectPermission: TResourceRights = {
  [EAuthResourceType.TDO]: {
    create: ['cms.media.create', 'recording.create'],
    read: ['cms.media.read', 'recording.read'],
    update: ['cms.media.update', 'recording.update'],
    delete: ['cms.media.delete', 'recording.delete'],
  },
  [EAuthResourceType.Folder]: {
    create: ['cms.media.create', 'recording.create'],
    read: ['cms.media.read', 'recording.read'],
    update: ['cms.media.update', 'recording.update'],
    delete: ['cms.media.delete', 'recording.delete'],
    file: ['cms.media.create', 'recording.create'],
  },
  [EAuthResourceType.Source]: {
    create: ['cms.media.create', 'recording.create'],
    read: ['cms.media.read', 'recording.read'],
    update: ['cms.media.update', 'recording.update', 'source.update'],
    delete: ['cms.media.delete', 'recording.delete'],
  },
  [EAuthResourceType.Job]: {
    customerService: ['cms.customerservice'],
    create: ['job.create'],
    read: ['job.read'],
    update: ['job.update'],
    delete: ['job.delete'],
  },
  [EAuthResourceType.Schedule]: {
    customerService: ['cms.customerservice'],
    create: ['job.create'],
    read: ['job.read'],
    update: ['job.update'],
    delete: ['job.delete'],
  },
  [EAuthSubResourceType.User]: {
    create: ['admin.user.create'],
    delete: ['admin.user.delete'],
  },
  [EAuthSubResourceType.Group]: {
    create: ['admin.group.create'],
    delete: ['admin.group.delete'],
  },
  [EAuthSubResourceType.Package]: {
    update: ['developer.build.update']
  },
};

const permissionActionsToFetchByObjectType: TPermissionPairs = {
  [EAuthResourceType.TDO]: {
    create: 'AIWARE_TDO_CREATE',
    read: 'AIWARE_TDO_READ',
    update: 'AIWARE_TDO_UPDATE',
    delete: 'AIWARE_TDO_DELETE',
  },
  [EAuthResourceType.Folder]: {
    create: 'AIWARE_FOLDER_CREATE',
    read: 'AIWARE_FOLDER_READ',
    update: 'AIWARE_FOLDER_UPDATE',
    delete: 'AIWARE_FOLDER_DELETE',
    file: 'AIWARE_FOLDER_FILE',
  },
  [EAuthResourceType.Source]: {
    create: 'AIWARE_SOURCES_CREATE',
    read: 'AIWARE_SOURCES_READ',
    update: 'AIWARE_SOURCES_UPDATE',
    delete: 'AIWARE_SOURCES_DELETE',
  },
  [EAuthResourceType.Job]: {
    customerService: 'CMS_CUSTOMERSERVICE',
    create: 'AIWARE_JOB_CREATE',
    read: 'AIWARE_JOB_READ',
    update: 'AIWARE_JOB_UPDATE',
    delete: 'AIWARE_JOB_DELETE',
  },
  [EAuthResourceType.Schedule]: {
    customerService: 'CMS_CUSTOMERSERVICE',
    create: 'AIWARE_JOB_CREATE',
    read: 'AIWARE_JOB_READ',
    update: 'AIWARE_JOB_UPDATE',
    delete: 'AIWARE_JOB_DELETE',
  },
  [EAuthResourceType.Organization]: {
    [EAuthSubResourceType.Group]: {
      create: 'AIWARE_GROUP_CREATE',
      delete: 'AIWARE_GROUP_DELETE',
    },
    [EAuthSubResourceType.Package]: {
      create: 'AIWARE_PACKAGE_CREATE',
      read: 'AIWARE_PACKAGE_READ',
      update: 'AIWARE_PACKAGE_UPDATE',
      delete: 'AIWARE_PACKAGE_DELETE',
    },
    [EAuthSubResourceType.User]: {
      create: 'AIWARE_USER_CREATE',
      delete: 'AIWARE_USER_DELETE',
    },
    [EAuthSubResourceType.ResourceCenter]: {
      access: 'AIWARE_RESOURCE_CENTER_ACCESS',
    },
  },
};

const permissionTypeToFetchByEntityType: {
  [entityType in EAuthResourceType]: EAuthResourceType[];
} = {
  [EAuthResourceType.TDO]: [EAuthResourceType.TDO],
  [EAuthResourceType.Folder]: [EAuthResourceType.Folder],
  [EAuthResourceType.Source]: [EAuthResourceType.Source],
  [EAuthResourceType.Job]: [EAuthResourceType.Job],
  [EAuthResourceType.Schedule]: [EAuthResourceType.Schedule],
  [EAuthResourceType.Organization]: [
    EAuthResourceType.Organization,
    EAuthResourceType.TDO,
    EAuthResourceType.Folder,
    EAuthResourceType.Source,
    EAuthResourceType.Schedule,
    EAuthResourceType.Job,
  ],
};

const authResourcesByEntityType = {
  [EntityType.Folders]: EAuthResourceType.Folder,
  [EntityType.Tdos]: EAuthResourceType.TDO,
  [EntityType.Sources]: [EAuthResourceType.Source],
  [EntityType.Schedules]: [EAuthResourceType.Schedule],
};

export const convertEntityToAuthResource = (entityType: EntityType) =>
  (authResourcesByEntityType as any)[entityType];

export const convertPermissionsToRights = (
  entityType: EAuthResourceType | EAuthSubResourceType,
  action: EPermissionAction,
  myRights: string[]
) => {
  const requiredRights: string[] = orgRightsByObjectPermission[entityType]?.[action] || [];
  const matchingRights = myRights.filter(myRight => requiredRights && requiredRights.includes(myRight));
  return matchingRights.length > 0;
};

const permissionDetailIsActionPair = (
  permissionDetail: TPermissionActionPairs | TPermissionDetailPairs
): permissionDetail is TPermissionActionPairs => {
  return (Object.keys(permissionDetail) as TPermissionCategory[]).some(key =>
    Object.values(EPermissionAction).includes(key as EPermissionAction)
  );
};

const getQueryActions = (
  targetObjectType: EAuthResourceType,
  actions: TPermissionActionPairs,
  subGroup?: EAuthSubResourceType
): TPermissionQuery => {
  return {
    targetObjectType,
    targetActions: Object.keys(actions).map(targetAction => ({
      targetAction,
      serverName: (actions as any)[targetAction],
      resource: subGroup,
    })),
  };
};

const getPermissionActions = (
  permissionDetail: TPermissionActionPairs | TPermissionDetailPairs,
  targetObjectType: EAuthResourceType
): TPermissionQuery[] => {
  if (permissionDetailIsActionPair(permissionDetail)) {
    return [getQueryActions(targetObjectType, permissionDetail)];
  } else {
    const subGroups = Object.keys(permissionDetail) as EAuthSubResourceType[];
    return subGroups.map(key => {
      return getQueryActions(targetObjectType, permissionDetail[key] || {}, key);
    });
  }
};

export const getPermissionTypesToQuery = (entityType: EAuthResourceType): TPermissionQuery[] => {
  const permissionTypesToFetch = permissionTypeToFetchByEntityType[entityType] || [];
  return permissionTypesToFetch.flatMap(targetObjectType =>
    getPermissionActions(permissionActionsToFetchByObjectType[targetObjectType] || {}, targetObjectType)
  );
};

export const isPermissionLoading = (entityPermissions: TPermissionsEntity | undefined) =>
  !entityPermissions || entityPermissions.status === 'pending' || entityPermissions.status === 'idle';

export const hasPermission = (
  entityPermissions: TPermissionsEntity | undefined,
  action: EPermissionAction
) => {
  if (!entityPermissions) {
    return false;
  }
  if (entityPermissions.status === 'failure') {
    // If the call fails, show all options to be safe
    return true;
  }
  return (
    entityPermissions &&
    entityPermissions.status === 'success' &&
    entityPermissions.permissions &&
    entityPermissions.permissions[action]
  );
};
