import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';

import { DateTime } from 'luxon';
import { Fragment, ReactNode } from 'react';

const useStyles = makeStyles()(() => ({
  root: {
    padding: '15px 30px',
  },
  title: {
    display: 'flex',
  },
  action: {
    fontWeight: 600,
  },
  time: {
    fontSize: '12px',
    paddingLeft: '15px',
    lineHeight: '21px',
  },
  description: {
    lineHeight: '150%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  divider: {
    margin: '0 30px',
    backgroundColor: '#D5DFE9',
    height: '0.5px',
  },
}));

export interface IActivity {
  createdDateTime?: string;
  event?: ReactNode;
  description?: string;
}
interface Props {
  data: IActivity;
  longFormatTime: boolean;
}

const ActivityCard = ({ longFormatTime = false, data: { createdDateTime, event, description } }: Props) => {
  const { classes } = useStyles();
  return (
    <Fragment>
      <div className={classes.root} data-testid="activity-card">
        <div className={classes.title}>
          <Typography variant="body1" className={classes.action}>
            {event}
          </Typography>
          <Typography variant="body2" className={classes.time}>
            {!longFormatTime
              ? DateTime.fromISO(createdDateTime || '').toFormat('tt')
              : DateTime.fromISO(createdDateTime || '').toFormat('F')}
          </Typography>
        </div>
        <div>
          <Tooltip placement="top-start" title={description || ''}>
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <Divider className={classes.divider} />
    </Fragment>
  );
};

export default ActivityCard;
