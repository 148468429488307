import { ReactNode, forwardRef, memo } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const propConstructor = (_name: string) => {
  const additionalProps: any = {};
  switch (_name) {
    case 'NoApps':
      additionalProps.viewBox = '0 0 160 160';
      break;
    case 'SortArrow':
      additionalProps.viewBox = '0 0 30 30';
      break;
  }
  return additionalProps;
};

// the createSvgIcon imported from @mui/material ignores our default theme overrides
const createSvgIcon = (path: ReactNode, _name: string) => {
  const Component = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon ref={ref} {...props} {...propConstructor(_name)}>
      {path}
    </SvgIcon>
  ));

  return memo(Component);
};

export default createSvgIcon;
