import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    opacity: 0.9,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3.5, 6, 4, 6),
    position: 'sticky',
    width: 'fit-content',
    fontSize: '14px',
    fontWeight: 400,
    top: 0,
  },
  scrollContent: {
    overflow: 'hidden',
  },
  infinityLoading: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}));

export default useStyles;
