import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { AIWareCacheProvider } from '@aiware/shared/theme';
import { MainLayout } from './main-layout';
import { ISource } from '../../../types';

export type TSourceInfoProps = {
  source: ISource;
};

export const SourceInfoPanel: React.FC<TSourceInfoProps> = props => {
  return (
    <AIWareThemeProvider>
      <AIWareIntlProvider>
        <AIWareCacheProvider>
          <MainLayout {...props} />
        </AIWareCacheProvider>
      </AIWareIntlProvider>
    </AIWareThemeProvider>
  );
};

export default SourceInfoPanel;
