import createSvgIcon from '../createSvgIcon';

export const BiometricsWhite = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.6875 9.75C7.6875 10.2675 7.2675 10.6875 6.75 10.6875C6.2325 10.6875 5.8125 10.2675 5.8125 9.75C5.8125 9.2325 6.2325 8.8125 6.75 8.8125C7.2675 8.8125 7.6875 9.2325 7.6875 9.75ZM11.25 8.8125C10.7325 8.8125 10.3125 9.2325 10.3125 9.75C10.3125 10.2675 10.7325 10.6875 11.25 10.6875C11.7675 10.6875 12.1875 10.2675 12.1875 9.75C12.1875 9.2325 11.7675 8.8125 11.25 8.8125ZM16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9ZM7.995 3.09C9.045 4.83 10.95 6 13.125 6C13.47 6 13.8075 5.9625 14.13 5.91C13.08 4.17 11.175 3 9 3C8.655 3 8.3175 3.0375 7.995 3.09ZM3.315 7.1025C4.5975 6.375 5.5875 5.19 6.06 3.7725C4.7775 4.5 3.7875 5.685 3.315 7.1025ZM15 9C15 8.415 14.91 7.8525 14.7525 7.32C14.2275 7.4325 13.6875 7.5 13.125 7.5C10.7775 7.5 8.685 6.42 7.305 4.7325C6.5175 6.6525 4.95 8.16 3 8.895C3.0075 8.925 3 8.9625 3 9C3 12.3075 5.6925 15 9 15C12.3075 15 15 12.3075 15 9Z"
      fill="white"
    />
  </svg>,
  'BiometricsWhite'
);
