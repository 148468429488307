import { LoadingStatus } from '@aiware/js/interfaces';
import type { IContentTemplate } from '@aiware/os/processing-center/content-templates';
export const SLICE_NAME = 'dataCenterImporter';

export enum ImporterView {
  browseFiles,
  filesSelected,
  folders,
}

export interface IFile {
  id: string;
  fileUrl: string;
  name: string;
  size: number;
  type: string;
  status?: LoadingStatus;
  percentComplete?: number;
  expiresInSeconds?: number;
  getUrl?: string;
  key?: string;
  unsignedUrl?: string;
  url?: string;
  tdoId?: string;
  tdoStatus?: LoadingStatus;
  meta?: IFileMeta;
  contentTemplates?: IContentTemplate[];
}

export interface IS3Bucket {
  bucket: string;
  expiresInSeconds: number;
  getUrl: string;
  key: string;
  unsignedUrl: string;
  url: string;
}

export interface IRootFolder {
  id: string;
  name: string;
  modifiedDateTime: string;
  treeObjectId: string;
  ownerId: string | null;
  isRoot?: boolean;
}

export type ErrorResponseType = IErrorResponse[] | string | null;

export interface IErrorResponse {
  extensions?: {
    code: string;
  };
  locations?: { line: number; column: number }[];
  message: string;
}

export interface ICreateTdoResponse {
  name: string;
  details: {
    veritoneFile?: {
      filename: string;
      fileName: string;
    };
  };
  modifiedDateTime?: string;
  folders: [
    {
      folderPath?: {
        name?: string;
        id?: string;
      };
    }
  ];
  primaryAsset?: {
    contentType: MimeType;
    signedUri: string;
  };
  sourceData?: {
    source: {
      id: string;
      sourceTypeId: string;
    };
    scheduledJobId: string;
  };
  thumbnailUrl: string;
}

export type IFilesSelected = IFile[];

export interface ITag {
  value: string;
}
export interface IFileMeta {
  fileId: string;
  fileName: string;
  runDate: string;
  runTime: string;
  thumbnailImg: IFile;
  startDateTime?: string;
  thumbnailUrl?: string;
  tags: Array<ITag>;
}

export enum FileItemTypeNames {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Text = 'text',
}

export enum MimeType {
  ApplicationJson = 'application/json',
  ApplicationMsWord = 'application/msword',
  ApplicationPdf = 'application/pdf',
  ApplicationRtf = 'application/rtf',
  ApplicationSmil = 'application/smil+xml',
  ApplicationTtml = 'application/ttml+xml',
  ApplicationVeritoneAion = 'application/vnd.veritone.aion+json',
  ApplicationMsOutlook = 'application/vnd.ms-outlook',
  ApplicationMsPowerpoint = 'application/vnd.ms-powerpoint',
  ApplicationDocument = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ApplicationSpreadsheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ApplicationPresentation = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ApplicationOasisDoc = 'application/vnd.oasis.opendocument.text',
  ApplicationOasisSpreadsheet = 'application/vnd.oasis.opendocument.spreadsheet',
  ApplicationXFlv = 'application/x-flv',
  ApplicationXml = 'application/xml',
  ApplicationFormUrlEncoded = 'application/x-www-form-urlencoded',
  ImageJpeg = 'image/jpeg',
  ImagePng = 'image/png',
  ImageTiff = 'image/tiff',
  ImageGig = 'image/gif',
  TextPlain = 'text/plain',
  TextHtml = 'text/html',
  TextCsv = 'text/csv',
  TextPlainCharset = 'text/plain; charset=utf-8',
  AudioAac = 'audio/aac',
  AudioFlac = 'audio/flac',
  AudioMidi = 'audio/midi',
  AudioMp4 = 'audio/mp4',
  AudioMpeg = 'audio/mpeg',
  AudioWav = 'audio/wav',
  AudioXWav = 'audio/x-wav',
  AudioWebm = 'audio/webm',
  VideoMp4 = 'video/mp4',
  VideoMpeg = 'video/mpeg',
  VideoOgg = 'video/ogg',
  VideoQuicktime = 'video/quicktime',
  VideoWebm = 'video/webm',
  VideoM4v = 'video/x-m4v',
  VideoMsWmv = 'video/x-ms-wmv',
  VideoMsvideo = 'video/x-msvideo',
  Message = 'message/rfc822',
}

export const DEFAULT_ACCEPTABLE_FILE_TYPES = [
  {
    name: 'Image',
    type: FileItemTypeNames.Image,
    extensions: [MimeType.ImageJpeg, MimeType.ImagePng, MimeType.ImageTiff, MimeType.ImageGig],
  },
  {
    name: 'Video',
    type: FileItemTypeNames.Video,
    extensions: [
      MimeType.VideoMp4,
      MimeType.VideoMpeg,
      MimeType.VideoOgg,
      MimeType.VideoQuicktime,
      MimeType.VideoWebm,
      MimeType.VideoM4v,
      MimeType.VideoMsWmv,
      MimeType.VideoMsvideo,
    ],
  },
  {
    name: 'Audio',
    type: FileItemTypeNames.Audio,
    extensions: [
      MimeType.AudioAac,
      MimeType.AudioFlac,
      MimeType.AudioMidi,
      MimeType.AudioMp4,
      MimeType.AudioMpeg,
      MimeType.AudioWav,
      MimeType.AudioXWav,
      MimeType.AudioWebm,
    ],
  },
  {
    name: 'Documents',
    type: FileItemTypeNames.Text,
    extensions: [
      MimeType.TextPlain,
      MimeType.TextHtml,
      MimeType.TextCsv,
      MimeType.TextPlainCharset,
      MimeType.ApplicationJson,
      MimeType.ApplicationMsWord,
      MimeType.ApplicationPdf,
      MimeType.ApplicationRtf,
      MimeType.ApplicationSmil,
      MimeType.ApplicationTtml,
      MimeType.ApplicationVeritoneAion,
      MimeType.ApplicationMsOutlook,
      MimeType.ApplicationMsPowerpoint,
      MimeType.ApplicationDocument,
      MimeType.ApplicationSpreadsheet,
      MimeType.ApplicationPresentation,
      MimeType.ApplicationOasisDoc,
      MimeType.ApplicationOasisSpreadsheet,
      MimeType.ApplicationXFlv,
      MimeType.ApplicationXml,
      MimeType.ApplicationFormUrlEncoded,
      MimeType.Message,
    ],
  },
];

export type { IContentTemplate };
