import {
  IUtilityNotificationSetting,
  INotificationType,
} from '../interfaces/notificationInterfaces';
import { engineToolkit, finder, importerTool } from '@aiware/shared/assets';

export const utilityNotificationSettingData: IUtilityNotificationSetting[] = [
  {
    id: 'notification-finder',
    type: 'finder',
    title: 'Data Center',
    iconUrl: finder,
    allow: true,
    allowType: [INotificationType.EMAIL, INotificationType.NOTIFICATION_CENTER],
    action: [
      {
        id: 'shared-file',
        title: 'Shared File',
        allow: true,
        allowType: [],
      },
      {
        id: 'shared-source',
        title: 'Shared Source or Stream',
        allow: false,
        allowType: [],
      },
      {
        id: 'shared-folder',
        title: 'Shared Folder',
        allow: false,
        allowType: [],
      },
    ],
  },
  {
    id: 'notification-importer',
    type: 'importer_tool',
    title: 'Importer Tool',
    iconUrl: importerTool,
    allow: true,
    allowType: [INotificationType.EMAIL, INotificationType.BANNER],
    action: [
      {
        id: 'shared-file',
        title: 'Shared File',
        allow: true,
        allowType: [],
      },
      {
        id: 'shared-source',
        title: 'Shared Source or Stream',
        allow: true,
        allowType: [],
      },
      {
        id: 'shared-folder',
        title: 'Shared Folder',
        allow: true,
        allowType: [],
      },
    ],
  },
  {
    id: 'notification-engine',
    type: 'engine_toolkit',
    title: 'Engine Toolkit',
    iconUrl: engineToolkit,
    allow: false,
    allowType: [],
    action: [
      {
        id: 'shared-file',
        title: 'Shared File',
        allow: false,
        allowType: [],
      },
      {
        id: 'shared-source',
        title: 'Shared Source or Stream',
        allow: false,
        allowType: [],
      },
      {
        id: 'shared-folder',
        title: 'Shared Folder',
        allow: true,
        allowType: [],
      },
    ],
  },
];
