import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

import * as entities from './entities';
import * as uiState from './ui-state';
import * as viewMyFiles from './view-my-files';
import * as viewStreams from './view-streams';
import * as panelAddFolder from './panel-add-folder';
import * as panelEditFolder from './panel-edit-folder';
import * as fileInfo from './file-info';
import * as editMetadata from './edit-metadata';

import * as schemaProperties from '../../lib/Search/state/aiStructuredData/schemaProperties.selectors';

import { selectors as schemaSearch } from '../../lib/Search/state/schemaSearch.redux';

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

export {
  entities,
  uiState,
  viewMyFiles,
  viewStreams,
  panelAddFolder,
  panelEditFolder,
  fileInfo,
  schemaProperties,
  schemaSearch,
  editMetadata,
};
