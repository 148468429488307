import { FC, useState } from 'react';
import { ErrorBoundary } from '@aiware/ui';
import FallbackUI from '../AIAutocomplete/components/ErrorBoundaryFallbackUI';
import { useStyles } from './searchResults.styles';
import { SearchResultsHeader } from './components/Header/SearchResultsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFluffy } from '../../../../redux/selectors/ui-state';
import { actions } from '../../../../redux/slices';
import {
  selectIsLoadingStatus,
  selectTotalResultsAmount,
  selectSearchFilesState,
} from '../../state/aiSearch/aiSearch.selectors';
import { SearchVirtualTableContainer } from './components/SearchVirtualTable';
import { AiSearchFilterPanel } from '../AiSearchFilter/AiSearchFilterPanel.container';
import { selectIsSearchFilterPanelVisible } from '../../state/aiSearchFilter/aiSearchFilter.selectors';

export enum EResultsViewStyle {
  COMPRESSED = 'COMPRESSED',
  FLUFFY = 'FLUFFY',
}

export enum EResultsViewAction {
  SORT = 'SORT',
  FILTER = 'FILTER',
  VIEW = 'VIEW',
  INFO = 'INFO',
}

export enum EResultsSortMethod {
  DEFAULT = 'DEFAULT',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
}
interface ISearchResultsView {
  results?: any[];
  hideHeaderActions: EResultsViewAction[];
}

/** This react component renders the search results. You can disable toolbar actions
 * by passing any EResultsViewAction inside the array prop hideHeaderActions. */
export const AISearchResultsView: FC<ISearchResultsView> = ({ results, hideHeaderActions }) => {
  const { classes } = useStyles();
  const isFluffy = useSelector(selectIsFluffy);
  const isLoading = useSelector(selectIsLoadingStatus);
  const isPanelVisible = useSelector(selectIsSearchFilterPanelVisible);

  const [viewStyle, setViewStyle] = useState(
    isFluffy ? EResultsViewStyle.FLUFFY : EResultsViewStyle.COMPRESSED
  );
  const dispatch = useDispatch();
  const totalResultCount = useSelector(selectTotalResultsAmount);

  const handleChangeSortMethod = () => {
    // TODO: Implement Sorting via backend
  };

  const handleChangeViewStyle = () => {
    dispatch(actions.uiState.toggleFluffyView());
    setViewStyle(prevValue => {
      if (prevValue === EResultsViewStyle.COMPRESSED) {
        return EResultsViewStyle.FLUFFY;
      } else {
        return EResultsViewStyle.COMPRESSED;
      }
    });
  };

  return (
    <ErrorBoundary fallbackUI={<FallbackUI />}>
      <div
        id="dc-search-results-view-container"
        className={classes.container}
        data-testid="dc-search-results-view-container"
      >
        <SearchResultsHeader
          resultCount={totalResultCount}
          onViewStyleChange={handleChangeViewStyle}
          onSortMethodChange={handleChangeSortMethod}
          viewStyle={viewStyle}
          hideHeaderActions={hideHeaderActions}
          isLoading={isLoading}
        />

        <AiSearchFilterPanel
          id="dc-ai-search-results-filter-panel"
          data-testid="dc-ai-search-results-filter-panel-test-id"
          isVisible={isPanelVisible}
        />

        <SearchVirtualTableContainer viewStyle={viewStyle} isLoading={isLoading} />
      </div>
    </ErrorBoundary>
  );
};
