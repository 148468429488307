import { put, select, delay, call } from 'redux-saga/effects';
import {
  showMessage,
  MessageSeverity,
  preferredLanguageSelector,
  showGlobalMessage,
  getApplicationsApi,
  selectIsUserOrgAdmin,
} from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { organizationInviteApi, myOrganizationsApis } from '../../../api';
import { IOrgAppsAndRoles, TInvitee, IOrgApplications, IOrgApp } from '../../../types';
import { selectApiConfigs, organizationInvitesSelectors } from '../../selectors';
import { actions } from '../../slices';
import { EUserStatus } from '../../../lib/invite-panel';
import {
  postNotificationCommand,
  notificationCommands,
  aiwareEvent,
  updateTokenSuccess,
} from '@aiware/shared/redux';

export function* fetchOrganizationsSaga(): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;

    const myOrganizations = (yield myOrganizationsApis.fetchMyOrganizations(apiConfigs)) as Awaited<
      ReturnType<typeof myOrganizationsApis.fetchMyOrganizations>
    >;

    yield put(actions.organizationsState.organizationsFetchSucceed(myOrganizations.records));
  } catch (e) {
    yield put(actions.organizationsState.organizationsFetchFailed());
    console.log(e);
  }
}

export function* fetchCurrentOrgSaga(
  action: ReturnType<typeof actions.organizationsState.currentOrganizationFetchStart>
): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const isAdmin = (yield select(selectIsUserOrgAdmin)) as ReturnType<typeof selectIsUserOrgAdmin>;
    let currentOrg: IOrgAppsAndRoles;
    if (isAdmin) {
      currentOrg = (yield myOrganizationsApis.fetchOrgAppsAndRoles(apiConfigs, action.payload)) as Awaited<
        ReturnType<typeof myOrganizationsApis.fetchOrgAppsAndRoles>
      >;
    } else {
      currentOrg = (yield myOrganizationsApis.fetchOrgAppsAndRolesForNonAdmin(apiConfigs)) as Awaited<
        ReturnType<typeof myOrganizationsApis.fetchOrgAppsAndRolesForNonAdmin>
      >;
    }
    currentOrg.applications.records = (yield call(fetchCurrentOrgApplicationsSaga)) as Awaited<
      ReturnType<typeof fetchCurrentOrgApplicationsSaga>
    > as any;

    yield put(actions.organizationsState.currentOrganizationFetchSucceed(currentOrg));
  } catch (e) {
    yield put(actions.organizationsState.currentOrganizationFetchFailed());
    console.log(e);
  }
}

const CHUNK_SIZE = 200;
function* fetchCurrentOrgApplicationsSaga(): Generator {
  const { apiRoot, token } = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
  let offset = 0;
  const limit = CHUNK_SIZE;
  let applications: IOrgApplications = { totalResults: 999, results: [] };
  let chunk: IOrgApplications = { results: [{ applicationId: 'init value for while loop' } as IOrgApp] };
  while ((applications.results!.length || 0) < (applications.totalResults || 0) && chunk?.results?.length) {
    chunk = (yield call(getApplicationsApi, apiRoot, token, offset, limit)) as Awaited<
      ReturnType<typeof getApplicationsApi>
    > as any;
    if (!applications?.to) {
      applications = chunk;
    } else if (applications?.results?.length) {
      applications.results = applications.results.concat(chunk.results || []);
    }
    offset += CHUNK_SIZE;
  }
  return applications.results;
}

export function* fetchInviteeSaga(
  action: ReturnType<typeof actions.organizationsState.fetchInviteeStart>
): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const email = action.payload;
    const orgGuid = (yield select(organizationInvitesSelectors.selectOrganizationGuid)) as ReturnType<
      typeof organizationInvitesSelectors.selectOrganizationGuid
    >;

    const locale = (yield select(preferredLanguageSelector)) as ReturnType<typeof preferredLanguageSelector>;
    const formatMessage = AIWareFormatMessage(locale);
    const isAdmin = (yield select(selectIsUserOrgAdmin)) as ReturnType<typeof selectIsUserOrgAdmin>;
    let invitee: TInvitee = {
      email: action.payload,
      applicationRoles: [],
      status: EUserStatus.new,
      organizationGuids: [],
    };
    if (isAdmin) {
      invitee = (yield myOrganizationsApis.fetchInvitee(apiConfigs, {
        email,
        userOrgGuid: orgGuid,
      })) as Awaited<ReturnType<typeof myOrganizationsApis.fetchInvitee>>;
    }

    if (!(invitee.organizationGuids ?? []).includes(orgGuid)) {
      yield put(actions.organizationsState.fetchInviteeSucceed(invitee));
    } else {
      yield put(
        showMessage({
          content: formatMessage({
            id: 'os-organization-panel.snackbar.warning.user-invitee-already-present-in-org',
            defaultMessage: 'The user you requested is already a member of the organization.',
            description: 'Warning message that the user attempted to invite is already in the organization',
          }),
          severity: MessageSeverity.Info,
        })
      );
    }
  } catch (e) {
    yield put(actions.organizationsState.fetchInviteeFailed());
    console.log(e);
  }
}

export function* switchOrgSaga(
  action: ReturnType<typeof actions.organizationsState.switchUserToOrganizationStart>
): Generator {
  try {
    const locale = (yield select(preferredLanguageSelector)) as ReturnType<typeof preferredLanguageSelector>;
    const formatMessage = AIWareFormatMessage(locale);
    // will dispatch the success action after the animation has finished if there was no failure dispatched
    yield put(
      showGlobalMessage({
        message:
          formatMessage({
            id: 'os-organization-panel.organizations-switching-loader-message',
            defaultMessage: 'Changing your Organization',
            description: 'organizations switching loader message',
          }) || 'Changing your Organization',
        followUpActionType: actions.organizationsState.switchUserToOrganizationSucceed.type,
      })
    );
    yield delay(100);
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const { sessionToken: token } = (yield myOrganizationsApis.switchUserToOrganization(
      apiConfigs,
      action.payload
    )) as Awaited<ReturnType<typeof myOrganizationsApis.switchUserToOrganization>>;
    yield delay(100);
    yield put(updateTokenSuccess({ token }));
    yield put(
      postNotificationCommand({
        eventType: aiwareEvent,
        command: notificationCommands.refreshPage,
        value: true,
        ephemeral: true,
        contentType: 'json',
      })
    );
    yield put(actions.organizationsState.switchUserToOrganizationSucceed());
  } catch (e) {
    yield put(actions.organizationsState.switchUserToOrganizationFailed());
    console.log(e);
  }
}

export function* fetchUserOrganizationInvitesSaga(): Generator {
  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;

    const userInvites = (yield organizationInviteApi.fetchOrganizationInvites(apiConfigs)) as Awaited<
      ReturnType<typeof organizationInviteApi.fetchOrganizationInvites>
    >;

    yield put(actions.organizationsState.fetchOrganizationInvitesSucceed(userInvites));
  } catch (e) {
    yield put(actions.organizationsState.fetchOrganizationInvitesFailed());
    console.log(e);
  }
}

export function* setUserDefaultOrgSaga(
  action: ReturnType<typeof actions.organizationsState.setUserDefaultOrg>
): Generator {
  const locale = (yield select(preferredLanguageSelector)) as ReturnType<typeof preferredLanguageSelector>;
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs = (yield select(selectApiConfigs)) as ReturnType<typeof selectApiConfigs>;
    const orgId = action.payload;
    yield myOrganizationsApis.setUserDefaultOrganizationApi(apiConfigs, orgId);
    // fetch organizations after setting default org
    yield put(actions.organizationsState.organizationsFetchStart());
  } catch (e) {
    yield put(
      showMessage({
        content: formatMessage({
          id: 'organization-panel.snackbar.setUserDefaultError',
          defaultMessage: 'Something went wrong while setting default organization.',
          description: 'The error message pops up when something goes wrong when setting default org',
        }),
        severity: MessageSeverity.Error,
      })
    );
    console.log(e);
  }
}
