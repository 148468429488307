import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../redux/slices';
import { tableInfiniteScroll } from '@aiware/shared/reusable-utils';
import { mountPanel } from '@aiware/js/panel';

import {
  selectDataRegistries,
  selectDataRegistriesPagination,
  selectStatus,
} from '../../../../redux/selectors';
import { TDataRegistry } from '../../../../types';
import { DATA_REGISTRY_TABLE_ITEM_HEIGHT, SDO_VIEWER_PANEL_ID } from '../../../../constants';
import { DataRegistryTableHeader } from './components/table-header';
import { DataRegistryTableRow } from './components/table-row';
import { SHARED_TEXT } from '../../../../helpers/shared-text';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

const {
  jsx: { TableInfiniteScroll },
} = tableInfiniteScroll;

export const DataRegistryTable = () => {
  const dispatch = useDispatch();

  const handleFetchItems = () => {
    dispatch(actions.dataRegistry.fetchSDOsStart());
  };

  // Required props
  const items: TDataRegistry[] = useSelector(selectDataRegistries);

  const { hasMore, offset, limit } = useSelector(selectDataRegistriesPagination);
  const loadingStatus = useSelector(selectStatus);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const isLoading = loadingStatus === 'loading';
  const isErrored = loadingStatus === 'failure';

  const MessageEmptyState = () => <>{SHARED_TEXT.dataRegistryBrowser.emptyStateMessage()}</>;
  const MessageErrorState = () => <>{SHARED_TEXT.dataRegistryBrowser.errorStateMessage()}</>;

  const handleClickDataRegistry = (id?: string) => () => {
    const microFrontend = {
      name: SDO_VIEWER_PANEL_ID,
      config: {
        dataRegistryId: id,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'large',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: SDO_VIEWER_PANEL_ID,
        microFrontend,
        panelConfig,
      })
    );
  };

  const Row = ({ item, index }: { item: { id?: string }; index: number }) => (
    <DataRegistryTableRow item={item} index={index} onClick={handleClickDataRegistry(item?.id)} />
  );

  return (
    <TableInfiniteScroll
      hasMore={hasMore}
      fetchMore={handleFetchItems}
      fetchOnMount
      offset={offset}
      items={items}
      itemHeight={DATA_REGISTRY_TABLE_ITEM_HEIGHT}
      isLoading={isLoading}
      error={isErrored}
      TableRowComponent={Row}
      TableHeaderComponent={<DataRegistryTableHeader />}
      adjustTableHeight={-40}
      MessageEmptyState={<MessageEmptyState />}
      MessageErrorState={<MessageErrorState />}
    />
  );
};
