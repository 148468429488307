import { useLookupStyles } from './useStyles';
import { SHARED_TEXT } from '../../helpers/shared-text';

export const NonAdminInviteMessage = () => {
  const { classes } = useLookupStyles();
  return (
    <div className={classes.nonAdminInviteMessageContainer} data-testid="non-admin-invite-disclaimer">
      <div className={classes.nonAdminInviteMessageTitle}>{SHARED_TEXT.adminApprovalRequired}</div>
      <div className={classes.nonAdminInviteMessageText}>{SHARED_TEXT.adminApprovalRequiredMessage}</div>
    </div>
  );
};
