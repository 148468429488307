import { FunctionComponent, ReactNode } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useStyles } from './useStyles';
import DynamicTableHead from './DynamicTableHead';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import EmptyTableRow from './EmptyTableRow';
import { SortDirection } from './types';

interface Props {
  columnNames: Array<string>;
  rows?: Array<ReactNode>;
  onRequestSort?: (columnName: string, direction: SortDirection) => void;
  idPrefix: string;
  isSortable?: boolean;
}

const DynamicTable: FunctionComponent<Props> = ({
  columnNames,
  rows,
  onRequestSort,
  idPrefix,
  isSortable = true,
}: Props) => {
  const { classes } = useStyles();

  return (
    <TableContainer className="shared-list-field_table-container">
      <Table data-test={`${idPrefix}-table`} className={classes.table}>
        <DynamicTableHead
          idPrefix={idPrefix}
          columnNames={columnNames}
          onRequestSort={onRequestSort}
          isSortable={isSortable}
        />
        <TableBody data-test={`${idPrefix}-table-body`}>
          {isNull(rows) || isEmpty(rows) ? (
            <EmptyTableRow data-test={`${idPrefix}-table-empty-row`} numColumns={size(columnNames)} />
          ) : (
            rows
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { DynamicTable };

export default DynamicTable;
