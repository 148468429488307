import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  title: (
    <FormattedMessage
      id="os-admin-center-panel.groups.table.header"
      defaultMessage="Groups"
      description="Groups Panel Header"
    />
  ),
  createGroup: (
    <FormattedMessage
      id="os-admin-center-panel.groups.create.button"
      defaultMessage="Create Group"
      description="Create Group Button"
    />
  ),
  groupDetails: (
    <FormattedMessage
      id="admin-center-group-details-panel.header"
      defaultMessage="Group Details"
      description="Group Details Header"
    />
  ),
  addMemberTitle: (
    <FormattedMessage
      id="admin-center-add-member-panel.header"
      defaultMessage="ADD MEMBERS"
      description="Add Members Header"
    />
  ),
  addMembersBtn: (
    <FormattedMessage
      id="admin-center-group-details-panel.add-members-btn"
      defaultMessage="Add Members"
      description="Add Members btn"
    />
  ),
  ClosePanelMessage: (
    <FormattedMessage
      id="os-admin-center-groups-details.close-panel"
      defaultMessage="Close Panel"
      description="Instructions for closing the Group Details panel"
    />
  ),
  subTitle: (
    <FormattedMessage
      id="admin-center-data-editors-sub-title-message"
      defaultMessage="Source: Active Directory"
      description="Data editors sub title"
    />
  ),
  settingsTab: (
    <FormattedMessage
      id="admin-center-group-details-panel.tabs.settings"
      defaultMessage="Settings"
      description="user's group settings"
    />
  ),
  memberSince: (
    <FormattedMessage
      id="admin-center-group-details-panel.member-since"
      defaultMessage="Member since:"
      description="Member since: list"
    />
  ),
  removeMember: (
    <FormattedMessage
      id="admin-center-group-details-panel.remove-member-button"
      defaultMessage="Remove from Group"
      description="Remove from Group btn"
    />
  ),
  cancelButton: (
    <FormattedMessage
      id="os-admin-center-add-member-cancel-button"
      defaultMessage="Cancel"
      description="Cancel button in add members panel"
    />
  ),
  addMembersSubTitle: (
    <FormattedMessage
      id="admin-center-add-member-panel-sub-title"
      defaultMessage="Members gain access to this groups shared data."
      description="Add members sub title"
    />
  ),
  groupMembersTitle: (
    <FormattedMessage
      id="admin-center-add-member-panel-group-members-title"
      defaultMessage="Group Members"
      description="Add members group members title"
    />
  ),
  titleDescription: (
    <FormattedMessage
      id="admin-center-add-member-panel-group-members-title-description"
      defaultMessage="Add users to this group if you want them to have control over Data Center objects."
      description="Add members group members title description"
    />
  ),
  groupsTable: {
    rowItem: {
      protected: (
        <FormattedMessage
          id="os-admin-center-protected.icon-5tad2"
          defaultMessage="This group is protected and can't be modified"
          description="tooltip for protected groups and permission"
        />
      ),
    },
    header: {
      groupName: (
        <FormattedMessage
          id="os-ac-groups-table-header-group-name"
          defaultMessage="Group Name"
          description="Admin center groups table name header"
        />
      ),
      createdDate: (
        <FormattedMessage
          id="os-ac-groups-table-header-created.date"
          defaultMessage="Created Date"
          description="Admin center groups table created date"
        />
      ),
      members: (
        <FormattedMessage
          id="os-ac-groups-table-header-members-name"
          defaultMessage="Members"
          description="Admin center groups table group members header header"
        />
      ),
    },
    emptyState: {
      title: (
        <FormattedMessage
          id="os-admin-center-groups.null-state-header"
          defaultMessage="You have no groups"
          description="Admin center group no groups header"
        />
      ),
      description: (
        <FormattedMessage
          id="os-admin-center-groups.null-state-description"
          defaultMessage="Please create a new group by clicking the Create Group button above"
          description="Admin center group no groups description"
        />
      ),
    },
    errorState: {
      title: (
        <FormattedMessage
          id="os-admin-center-groups.error-message"
          defaultMessage="Something went wrong while loading your groups."
          description="Organizations groups error state"
        />
      ),
      description: (
        <FormattedMessage
          id="os-admin-center-groups.error-message-description"
          defaultMessage="Please refresh or try again later."
          description="Organizations groups error state description"
        />
      ),
    },
  },
};
