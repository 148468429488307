import { createSelector } from '@reduxjs/toolkit';
import nameHelper from '../../helpers/nameHelper';

import { FolderId, IFileVm, IFolderVm, IFolder, Breadcrumb } from '../../types';
import { DataCenterState, namespace } from '../slices';
import {
  selectFolderEntities,
  selectTdoEntities,
  selectEngineCategoryEntities,
  selectEngineEntities,
} from './entities';
import { mapTdoListToViewModels } from './helpers';
import { LoadingStatus, EntityType } from '@aiware/js/interfaces';

export const selectMyFilesBreadCrumbs = (state: DataCenterState) => state[namespace].viewMyFiles.breadCrumbs;

export const selectMyFilesBreadCrumbActive: (state: DataCenterState) => Breadcrumb = createSelector(
  [selectMyFilesBreadCrumbs],
  breadCrumbs => {
    return breadCrumbs[breadCrumbs.length - 1]!;
  }
);

export const selectMyFilesBreadCrumbsViewModel: (state: DataCenterState) => Breadcrumb[] = createSelector(
  [selectMyFilesBreadCrumbs, selectFolderEntities],
  (breadCrumbs, folderEntities) => {
    return breadCrumbs.map(crumb => {
      if (crumb.id && crumb.entity === EntityType.Folders) {
        const folder = folderEntities[crumb.id];

        return {
          ...crumb,
          name: folder?.name || nameHelper(folder?.id, folder!.modifiedDateTime),
        };
      }

      return crumb;
    });
  }
);

export const selectRootFolderId = (state: DataCenterState) => state[namespace].viewMyFiles.rootFolderId;

export const selectFolderDetails = (state: DataCenterState, folderId: FolderId) => {
  return state[namespace].viewMyFiles.foldersAndFiles[folderId];
};

export const selectUiFoldersAndFiles = (state: DataCenterState) => {
  return state[namespace].viewMyFiles.foldersAndFiles;
};

export const selectActiveFolder: (state: DataCenterState) => IFolder | undefined = createSelector(
  [selectMyFilesBreadCrumbActive, selectRootFolderId, selectFolderEntities],
  (activeCrumb, rootFolderId, folderEntities) => {
    if (activeCrumb.isRoot && rootFolderId) {
      return {
        ...folderEntities[rootFolderId]!,
        isRoot: true,
      };
    }

    if (activeCrumb.id) {
      return {
        ...folderEntities[activeCrumb.id]!,
        isRoot: false,
      };
    }
  }
);

export const selectCurrentFolderDetails: (state: DataCenterState) => {
  status: LoadingStatus;
  hasMore: boolean;
  folders: IFolderVm[];
  files: IFileVm[];
  parentFolderId: string;
  foldersChunked: IFolderVm[][];
} | null = createSelector(
  [
    selectMyFilesBreadCrumbActive,
    selectRootFolderId,
    selectUiFoldersAndFiles,
    selectFolderEntities,
    selectTdoEntities,
    selectEngineCategoryEntities,
    selectEngineEntities,
  ],
  (
    activeCrumb,
    rootFolderId,
    foldersAndFiles,
    folderEntities,
    fileEntities,
    engineCategoryEntities,
    engineEntities
  ) => {
    const parentFolderId: string = activeCrumb.isRoot ? rootFolderId! : activeCrumb.id ?? '';
    const page = parentFolderId && foldersAndFiles[parentFolderId]!;

    if (page) {
      const folderIds = page.folders.ids;
      const folders: IFolderVm[] = folderIds
        .map(id => {
          const folder = folderEntities[id] || <IFolder>{};
          return {
            type: 'folder',
            folder,
          };
        })
        .sort((a, b) => {
          if (!a.folder.name && !b.folder.name) {
            return 0;
          }
          if (!a.folder.name) {
            return -1;
          }
          if (!b.folder.name) {
            return 1;
          }
          if (a.folder.name.toLowerCase() < b.folder.name.toLowerCase()) {
            return -1;
          }
          if (a.folder.name.toLowerCase() > b.folder.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

      const foldersChunked: IFolderVm[][] = [];
      let newChunk: IFolderVm[] = [];
      if (folders.length && folders.length < 6) {
        newChunk = [...folders];
        foldersChunked.push(newChunk);
      } else {
        folders.forEach(folder => {
          newChunk.push(folder);
          if (newChunk.length === 6) {
            foldersChunked.push(newChunk);
            newChunk = [];
          }
        });
        if (newChunk.length && newChunk.length < 6) {
          foldersChunked.push(newChunk);
        }
      }
      const fileIds = page.files.ids;
      const files: IFileVm[] = mapTdoListToViewModels(
        fileIds,
        fileEntities,
        engineEntities,
        engineCategoryEntities
      );

      return { ...page, parentFolderId, files, folders, foldersChunked };
    }

    return null;
  }
);

export const selectSelectedItemId = (state: DataCenterState) => {
  return state[namespace].viewMyFiles.selectedItemId;
};

export const selectSelectedFolderId = (state: DataCenterState) => {
  return state[namespace].viewMyFiles.selectedFolderId;
};

export const selectSelectedItems = (state: DataCenterState): string[] => {
  return state[namespace].viewMyFiles.selectedItems;
};
