import createSvgIcon from '../createSvgIcon';
export const TVSource = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#335B89" />
    <path
      d="M9.75999 6.75L8.93749 7.5725L10.4483 9.08333H7.33332C6.68582 9.08333 6.16666 9.6025 6.16666 10.25V16.0833C6.16666 16.7308 6.68582 17.25 7.33332 17.25H16.6667C17.3142 17.25 17.8333 16.7308 17.8333 16.0833V10.25C17.8333 9.6025 17.3142 9.08333 16.6667 9.08333H13.5517L15.0625 7.5725L14.24 6.75L12 8.99L9.75999 6.75ZM7.33332 10.25H14.9167V16.0833H7.33332V10.25ZM16.375 10.25C16.5297 10.25 16.6781 10.3115 16.7875 10.4209C16.8969 10.5303 16.9583 10.6786 16.9583 10.8333C16.9583 10.988 16.8969 11.1364 16.7875 11.2458C16.6781 11.3552 16.5297 11.4167 16.375 11.4167C16.2203 11.4167 16.0719 11.3552 15.9625 11.2458C15.8531 11.1364 15.7917 10.988 15.7917 10.8333C15.7917 10.6786 15.8531 10.5303 15.9625 10.4209C16.0719 10.3115 16.2203 10.25 16.375 10.25ZM16.375 12C16.5297 12 16.6781 12.0615 16.7875 12.1709C16.8969 12.2803 16.9583 12.4286 16.9583 12.5833C16.9583 12.738 16.8969 12.8864 16.7875 12.9958C16.6781 13.1052 16.5297 13.1667 16.375 13.1667C16.2203 13.1667 16.0719 13.1052 15.9625 12.9958C15.8531 12.8864 15.7917 12.738 15.7917 12.5833C15.7917 12.4286 15.8531 12.2803 15.9625 12.1709C16.0719 12.0615 16.2203 12 16.375 12Z"
      fill="white"
    />
  </svg>,
  'TVSource'
);
