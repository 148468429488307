import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  panelHeaderTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.title"
      defaultMessage="File Info"
      description="Panel header for the Data Center File Info Panel"
    />
  ),
  closePanel: () => (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.close-panel"
      defaultMessage="Close"
      description="Close panel icon for the Data Center Media Details Panel"
    />
  ),
  errorStateTitle: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.error-not-found"
      defaultMessage="Media not found"
      description="Data Center File Info Panel Error media not found message"
    />
  ),
  errorStateSubtitle: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.error-not-found-subtitle"
      defaultMessage="We couldn't find this file or you may not have permission to access this file."
      description="Data Center File Info Panel Error media not found message"
    />
  ),
};

export const FILE_INFO_TABS = {
  fileInfo: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.tab-header.file-info"
      defaultMessage="File Info"
      description="File Info Panel Tab Header for File Info"
    />
  ),
  contentTemplates: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.tab-header.content-templates"
      defaultMessage="Content Templates"
      description="File Info Panel Tab Header for Content Templates"
    />
  ),
  activity: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.tab-header.activity"
      defaultMessage="Activity"
      description="File Info Panel Tab Header for Activity"
    />
  ),
};

export const FILE_INFO_MENU_TEXT = {
  editAttributes: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.header-menu.edit-attributes"
      defaultMessage="Edit Attributes"
      description="File Info Panel, header menu item for edit attributes panel"
    />
  ),
  moveTo: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.header-menu.move-to"
      defaultMessage="Move to..."
      description="File Info Panel, header menu item for move item panel"
    />
  ),
  process: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.header-menu.process"
      defaultMessage="Process with AI"
      description="File Info Panel, header menu item for process with AI"
    />
  ),
  download: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.header-menu.download"
      defaultMessage="Download"
      description="File Info Panel, header menu item for download"
    />
  ),
  delete: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.header-menu.delete"
      defaultMessage="Delete"
      description="File Info Panel, header menu item for delete item"
    />
  ),
};
export const FILE_INFO_LABEL_TEXT = {
  id: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.id"
      defaultMessage="TDO Id"
      description="File Info Panel, File info data type TDO ID"
    />
  ),
  name: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.name"
      defaultMessage="Name"
      description="File Info Panel, File info data type name"
    />
  ),
  location: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.location"
      defaultMessage="Location"
      description="File Info Panel, File info data type location"
    />
  ),
  url: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.url"
      defaultMessage="URL"
      description="File Info Panel, File info data type url"
    />
  ),
  fileType: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.file-type"
      defaultMessage="File Type"
      description="File Info Panel, File info data type file type"
    />
  ),
  fileSize: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.file-size"
      defaultMessage="File Size"
      description="File Info Panel, File info data type file size"
    />
  ),
  duration: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.duration"
      defaultMessage="Duration"
      description="File Info Panel, File info data type duration"
    />
  ),
  dateCreated: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.date-created"
      defaultMessage="Date Created"
      description="File Info Panel, File info data type date created"
    />
  ),
  dateUploaded: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.date-uploaded"
      defaultMessage="Date Uploaded"
      description="File Info Panel, File info data type date uploaded"
    />
  ),
  fileStatus: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.file-status"
      defaultMessage="Processing Status"
      description="File Info Panel, File info data type file status"
    />
  ),
  tags: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.tags"
      defaultMessage="Tags"
      description="File Info Panel, File info data type tags"
    />
  ),
  copyTdo: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.copy.tdo"
      defaultMessage="Copy TDO ID "
      description="File Info Panel, Copy Tdo id - tooltip"
    />
  ),
  copied: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.copied.tdo"
      defaultMessage="Copied!"
      description="File Info Panel, Copied - tooltip"
    />
  ),
  aiEngines: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.ai.engines"
      defaultMessage="AI Engines"
      description="File Info Panel, File Info AI Engines"
    />
  ),
  otherEngines: (
    <FormattedMessage
      id="os-data-center-browse.file-info-panel.file-info-data-type.other.engines"
      defaultMessage="Other Engines"
      description="File Info Panel, File Info Other Engines"
    />
  ),
};
