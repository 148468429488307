import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import { panelComponents } from '@aiware/shared/reusable-utils';
import { Button, Stack, Typography } from '@mui/material';
import { SHARED_TEXT } from '../../../../helpers/shared-text';
import { EditForm } from './edit-form';
import { IEditDataRegistryProps } from './index';
import { EDIT_DATA_REGISTRY_PANEL_ID } from '../../../../constants';

const { header: PanelHeader, container: PanelContainer, content: PanelContent } = panelComponents;

export const EditDataRegistryMainLayout: React.FC<IEditDataRegistryProps> = ({ dataRegistryId }) => {
  const dispatch = useDispatch();
  const _loadingStatus = 'idle'; //useSelector(selectLoadingStatus);
  const isLoading = false; //loadingStatus === 'pending';
  const isErrored = false; //loadingStatus === 'failure';

  const handleClose = () => {
    dispatch(hidePanel(EDIT_DATA_REGISTRY_PANEL_ID));
  };

  const handleSaveChanges = () => {
    dispatch(hidePanel(EDIT_DATA_REGISTRY_PANEL_ID));
  };

  const handleFetchDataRegistry = () => {
    // TODO: Get the Data Registry
    console.log('TODO: Get the Data Registry', dataRegistryId);
    // Save it to redux
  };

  useEffect(() => {
    handleFetchDataRegistry();
  }, []);

  return (
    <PanelContainer panelId={EDIT_DATA_REGISTRY_PANEL_ID}>
      <PanelHeader
        title={SHARED_TEXT.editDataRegistry.headerTitle()}
        testId={'edit-data-registry-panel'}
        onPanelClose={handleClose}
      />
      <PanelContent
        isLoading={isLoading}
        isErrored={isErrored}
        onRetry={handleFetchDataRegistry}
        style={{ padding: '30px 30px 0px 30px' }}
      >
        <Stack gap="20px">
          <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
            {SHARED_TEXT.editDataRegistry.headerDescription()}
          </Typography>
          <EditForm />
          <Stack sx={{ position: 'absolute', bottom: '0', right: '0', padding: '30px' }}>
            <Button
              onClick={handleSaveChanges}
              variant="contained"
              data-testid="edit-data-registry-panel-save-btn"
            >
              {SHARED_TEXT.editDataRegistry.saveButton()}
            </Button>
          </Stack>
        </Stack>
      </PanelContent>
    </PanelContainer>
  );
};
