import type { JSONSchema } from '../../form-builder';
import type { FormGeneratorFieldWithKey } from '../types';

export const getValidationRules = (field: FormGeneratorFieldWithKey, required: string[]) => {
  const isRequired = required?.includes(field.fieldKey) ?? false;
  const rules: Record<string, unknown> = {
    required: isRequired ? `${field.title} is required` : false,
  };

  switch (field.type) {
    case 'email':
      rules.pattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      };
      break;
    case 'number':
      rules.valueAsNumber = true;
      if (field.min !== undefined) {
        rules.min = {
          value: field.min,
          message: `Minimum value is ${field.min}`,
        };
      }
      if (field.max !== undefined) {
        rules.max = {
          value: field.max,
          message: `Maximum value is ${field.max}`,
        };
      }
      break;
  }

  return rules;
};
