import createSvgIcon from '../createSvgIcon';
export const Cancel = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 0.833496C15.0418 0.833496 19.1668 4.9585 19.1668 10.0002C19.1668 15.0418 15.0418 19.1668 10.0002 19.1668C4.9585 19.1668 0.833496 15.0418 0.833496 10.0002C0.833496 4.9585 4.9585 0.833496 10.0002 0.833496ZM10.0002 2.66683C8.2585 2.66683 6.70016 3.21683 5.5085 4.22516L15.7752 14.4918C16.6918 13.2085 17.3335 11.6502 17.3335 10.0002C17.3335 5.96683 14.0335 2.66683 10.0002 2.66683ZM14.4918 15.7752L4.22516 5.5085C3.21683 6.70016 2.66683 8.2585 2.66683 10.0002C2.66683 14.0335 5.96683 17.3335 10.0002 17.3335C11.7418 17.3335 13.3002 16.7835 14.4918 15.7752Z"
      fill="#48516A"
    />
  </svg>,
  'Cancel'
);
