import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule } from '../../../types';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import classnames from 'classnames';

type RowItemTypes = ISchedule;

const useStyles = makeStyles()(theme => ({
  badge: {
    borderRadius: '40px',
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: '9px',
    width: 'fit-content',
    padding: '0 10px',
    color: '#FFFFFF',
  },
  active: { background: '#086501' },
  ready: { background: '#215E73' },
  deactivated: { background: '#423E3E' },
}));

type BadgeStatus = 'active' | 'ready' | 'deactivated';

interface BadgeProps {
  status: BadgeStatus;
}

const Badge: FunctionComponent<BadgeProps> = ({ status }) => {
  const { classes } = useStyles();
  return (
    <div className={classnames(classes[status], classes.badge)} data-test="os-data-center-schedule-status">
      {status.toUpperCase()}
    </div>
  );
};

interface StatusBadgeProps {
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}

const determineStatus = (item: ISchedule): BadgeStatus => {
  if (!item.isActive) return 'deactivated';
  const isJobsRunning = item.jobs?.records?.length ?? 0;
  if (isJobsRunning > 0) return 'active';
  return 'ready';
};

const StatusBadge: FunctionComponent<StatusBadgeProps> = ({ item, xs, itemType }) => {
  const status = determineStatus(item);

  return (
    <Grid item container data-test="schedule-status-badge" xs={xs}>
      <Badge status={status} />
    </Grid>
  );
};

export default StatusBadge;
