import {
  listenLogout,
  logout,
  logoutUser,
  appSwitcherSetupListener,
  appSwitcherHandleClick,
  appSwitcherHandleClickAppNatively,
} from '@aiware/js/constants';
import { all, call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { stashTokenInSession, SESSION_TOKEN_PREVENT_STASH_VALUE } from '@aiware/shared/redux';
import { onNotificationActionClicked, initNotificationAction } from '@aiware/os/notification-panel';
import { BOOTED, BOOTING } from './actions';
import userSaga from './modules/user/saga';

export function* bootFunction() {
  yield take(BOOTED);
}

export function* booting() {
  yield takeEvery(BOOTING, bootFunction);
}

export function* logoutListener(action: { payload?: () => void }) {
  const onLogout = action.payload;
  while (true) {
    yield call(stashTokenInSession, SESSION_TOKEN_PREVENT_STASH_VALUE);
    yield take(logout);
    if (onLogout) {
      onLogout?.();
    } else {
      yield put(logoutUser());
    }
  }
}

export function* notificationActionClickedSaga(outerAction: { payload?: (callback: unknown) => void }) {
  const onClickNotificationCallback = outerAction.payload;
  yield takeEvery(onNotificationActionClicked, function* (action: { type: string; payload: unknown }) {
    if (onClickNotificationCallback) yield call(onClickNotificationCallback, action.payload);
  });
}

export function* handleAppSwitchListenerSetup(action: any) {
  const appSwitchCallback = action.payload;

  yield takeEvery(appSwitcherHandleClick, handleAppSwitcherClick);

  function* handleAppSwitcherClick(action: any) {
    const appId = action.payload;

    // Use callback if provided
    if (appSwitchCallback) {
      appSwitchCallback(appId);
    } else {
      // If not, let aiwareJS handle click app
      yield put(appSwitcherHandleClickAppNatively(appId));
    }
  }
}

export function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(booting),
    takeLatest(listenLogout, logoutListener),
    takeLatest(appSwitcherSetupListener, handleAppSwitchListenerSetup),
    takeLatest(initNotificationAction, notificationActionClickedSaga),
  ]);
}
export default rootSaga;
