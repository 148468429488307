import { useEffect, useState, useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import {
  sessionTimeoutSelector,
  loginUrlSelector,
  isIdleSelector,
  callLogoutSelector,
  clickedLogoutSelector,
  callResetIdleTimerSelector,
  postNotificationCommand,
  notificationCommands,
  aiwareEvent,
  resetIdleTimerCallCounterSelector,
  userSelector,
  setIsIdle,
  extendTokenRequest,
  logoutRequest,
  sessionTokenSelector,
  reloadPageSelector,
} from '@aiware/shared/redux';
import { useSelector, useDispatch } from 'react-redux';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';
import { getSessionTimeout } from '@aiware/shared/redux';

interface SessionTimeoutProps {
  test?: boolean;
}

const LOGOUT_CALL_LIMIT = 1;
let logoutCallCounter = 0;
const RESET_IDLE_TIMER_CALL_LIMIT = 1;
const MINUTES_BEFORE_SESSION_EXPIRATION = 3;
let timer: unknown;

const SessionTimeout = (props: SessionTimeoutProps) => {
  const { test } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const isIdle = useSelector(isIdleSelector);
  const user = useSelector(userSelector);
  const token = useSelector(sessionTokenSelector);
  const loginUrl = useSelector(loginUrlSelector);
  const calLogout = useSelector(callLogoutSelector);
  const clickedLogout = useSelector(clickedLogoutSelector);
  const sessionExpiration = user?.tokenExpiration;
  const callResetIdleTimer = useSelector(callResetIdleTimerSelector);
  const sessionTimeoutTimer = useSelector(sessionTimeoutSelector) || 60; // minutes
  const shouldReloadPage = useSelector(reloadPageSelector);
  const resetIdleTimerCallCounter = useSelector(resetIdleTimerCallCounterSelector);
  const [signOutTimer, setSignOutTimer] = useState(!test ? 60 : 5); // seconds
  const [currentTime, setCurrentTime] = useState(new Date());
  const [sessionExpirationWarningTime, setSessionExpirationWarningTime] = useState<Date | undefined>();
  const [sessionExpirationTime, setSessionExpirationTime] = useState<Date | undefined>();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isSessionExpiringSoon, setIsSessionExpiringSoon] = useState<boolean>(false);

  const handleSetIsIdle = useCallback(
    (newState: boolean): void => {
      dispatch(
        postNotificationCommand({
          eventType: aiwareEvent,
          command: notificationCommands.setIsIdle,
          value: newState,
          ephemeral: true,
          contentType: 'json',
        })
      );
      dispatch(setIsIdle(newState));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getSessionTimeout(token));
  }, []);

  useEffect(() => {
    if (shouldReloadPage) {
      // @ts-ignore
      window.location.replace('/');
    }
  }, [shouldReloadPage]);

  const setCallLogout = useCallback(
    (newState: boolean): void => {
      dispatch(
        postNotificationCommand({
          eventType: aiwareEvent,
          command: notificationCommands.setCallLogout,
          value: newState,
          ephemeral: true,
          contentType: 'json',
        })
      );
    },
    [dispatch]
  );

  const setClickedLogout = useCallback(
    (newState: boolean) => {
      dispatch(
        postNotificationCommand({
          eventType: aiwareEvent,
          command: notificationCommands.setClickedLogout,
          value: newState,
          ephemeral: true,
          contentType: 'json',
        })
      );
    },
    [dispatch]
  );

  const setCallResetIdleTimer = useCallback(
    (newState: boolean): void => {
      dispatch(
        postNotificationCommand({
          eventType: aiwareEvent,
          command: notificationCommands.setCallResetIdleTimer,
          value: newState,
          ephemeral: true,
          contentType: 'json',
        })
      );
    },
    [dispatch]
  );

  const handleLogOut = useCallback((): void => {
    logoutCallCounter++;
    setCallLogout(true);
    const redirectTimer = clickedLogout ? 500 : 1000;
    const redirectTimerTest = 500;

    dispatch(
      logoutRequest({
        token: token,
      })
    );

    setTimeout(
      () => {
        window.location.href = `${loginUrl}/#/?redirect=${window.location.href}`;
      },
      !test ? redirectTimer : redirectTimerTest
    );
  }, [setCallLogout, dispatch, token, loginUrl, clickedLogout]);

  const handleClickLogOut = useCallback((): void => {
    logoutCallCounter++;
    handleSetIsIdle(false);
    setTimeout(() => {
      setCallLogout(true);
      setClickedLogout(true);
      dispatch(
        logoutRequest({
          token: token,
        })
      );
    }, 500);

    setTimeout(() => {
      window.location.href = `${loginUrl}/#/?redirect=${window.location.href}`;
    }, 500);
  }, [setCallLogout, dispatch, token, loginUrl, setClickedLogout, handleSetIsIdle]);

  const setTimerValue = () => {
    clearInterval(timer as number);
    timer = setInterval(() => {
      setSignOutTimer(signOutTimer => signOutTimer - 1);
    }, 1000);
  };

  const handleOnIdle = (): void => {
    handleSetIsIdle(true);
    setTimerValue();
  };

  const handleOnAction = (): void => {
    if (!isIdle && resetIdleTimerCallCounter < RESET_IDLE_TIMER_CALL_LIMIT) {
      setCallResetIdleTimer(true);
    }
  };

  const idleTimerConfig = {
    timeout: 1000 * 60 * sessionTimeoutTimer, // sessionTimeoutTimer is minutes
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 1000,
    crossTab: true,
    syncTimers: 200,
  };

  const idleTimerTestConfig = {
    timeout: 5000,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 1000, // debounce only affects the onAction handler
    crossTab: true,
    syncTimers: 200,
  };

  const { reset } = useIdleTimer(!test ? idleTimerConfig : idleTimerTestConfig);

  const resetIdleTimer = useCallback((): void => {
    setCallResetIdleTimer(false);
    reset();
  }, [setCallResetIdleTimer]);

  const handleClose = useCallback((): void => {
    if (isSessionExpiringSoon) {
      dispatch(
        extendTokenRequest({
          token: token,
        })
      );
      setIsSessionExpiringSoon(false);
    }
    handleSetIsIdle(false);
    resetIdleTimer();
    clearInterval(timer as number);
    setTimeout(() => {
      setSignOutTimer(!test ? 60 : 5);
    }, 500);
  }, [handleSetIsIdle, resetIdleTimer, isSessionExpiringSoon, dispatch, token]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(new Date());
    }, 1000);
  }, [currentTime]);

  useEffect(() => {
    if (sessionExpiration) {
      // set the session expiration warning time to (sessionExpiration - MINUTES_BEFORE_SESSION_EXPIRATION)
      const sessionExpirationWarningTime = new Date(sessionExpiration);
      sessionExpirationWarningTime.setMinutes(
        sessionExpirationWarningTime.getMinutes() - MINUTES_BEFORE_SESSION_EXPIRATION
      );

      setSessionExpirationTime(new Date(sessionExpiration));
      setSessionExpirationWarningTime(sessionExpirationWarningTime);
    }
  }, [sessionExpiration]);

  useEffect(() => {
    if (sessionExpirationTime) {
      if (sessionExpirationTime.getTime() < currentTime.getTime()) {
        setIsSessionExpired(true);
      }
    }
    if (sessionExpirationWarningTime) {
      if (sessionExpirationWarningTime.getTime() < currentTime.getTime()) {
        setIsSessionExpiringSoon(true);
      }
    }
  }, [sessionExpirationTime, sessionExpirationWarningTime, currentTime]);

  useEffect(() => {
    if (isSessionExpired) {
      handleLogOut();
    } else if (isSessionExpiringSoon) {
      handleOnIdle();
    }
  }, [
    sessionExpirationTime,
    sessionExpirationWarningTime,
    isSessionExpiringSoon,
    isSessionExpired,
    handleLogOut,
    handleSetIsIdle,
  ]);

  useEffect(() => {
    if (calLogout && logoutCallCounter < LOGOUT_CALL_LIMIT) {
      handleLogOut();
    }
  }, [calLogout, handleLogOut]);

  useEffect(() => {
    if (isIdle && signOutTimer === 0) {
      clearInterval(timer as number);
      setTimeout(() => {
        setSignOutTimer(10);
      }, 500);
      handleLogOut();
    }
  }, [signOutTimer, handleLogOut, isIdle]);

  useEffect(() => {
    if (!isIdle && callResetIdleTimer && resetIdleTimerCallCounter === RESET_IDLE_TIMER_CALL_LIMIT) {
      resetIdleTimer();
    }
  }, [callResetIdleTimer, isIdle, resetIdleTimerCallCounter, resetIdleTimer]);

  return (
    <AIWareThemeProvider>
      <AIWareIntlProvider>
        <Dialog
          open={isIdle && !clickedLogout}
          aria-labelledby="session timeout dialog"
          aria-describedby="session timeout dialog"
          disableEscapeKeyDown={true}
          maxWidth="xs"
          data-test="session-timeout-dialog"
        >
          <DialogTitle id="session timeout dialog title" className={classes.title}>
            <FormattedMessage
              id="os-app-bar.JEUssf"
              defaultMessage="Session Timeout"
              description="label session timeout dialog"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="session timeout dialog text" className={classes.contentText}>
              {!calLogout ? (
                isSessionExpiringSoon ? (
                  <FormattedMessage
                    id="os-app-bar.bDessH"
                    defaultMessage="Your session will expire soon. For your security, we'll automatically sign you out in {signOutTimer} seconds. Choose 'Stay Signed In' to continue or 'Sign Out' if you're done."
                    description="label for idle warning expiring soon"
                    values={{
                      signOutTimer: signOutTimer,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="os-app-bar.Ca6Qq0"
                    defaultMessage="You've been inactive for a while. For your security, we'll automatically sign you out in {signOutTimer} seconds. Choose 'Stay Signed In' to continue or 'Sign Out' if you're done."
                    description="label for idle warning inactive for a while"
                    values={{
                      signOutTimer: signOutTimer,
                    }}
                  />
                )
              ) : clickedLogout ? null : (
                <FormattedMessage
                  id="os-app-bar.l85HEz"
                  defaultMessage="You have been signed due to inactivity or due to your session being expired."
                  description="signed out message"
                />
              )}
            </DialogContentText>
          </DialogContent>
          {!calLogout ? (
            <DialogActions className={classes.dialogActions}>
              <Button
                onClick={handleClickLogOut}
                color="primary"
                variant="outlined"
                className={classes.signOutButton}
                data-test="sign-out-button"
              >
                <FormattedMessage
                  id="os-app-bar.jN92ox"
                  defaultMessage="Sign Out"
                  description="label for sign out button"
                />
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                className={classes.staySignedInButton}
                data-test="stay-signed-in-button"
              >
                <FormattedMessage
                  id="os-app-bar.WvKhS2"
                  defaultMessage="Stay Signed In"
                  description="label for stay signed in button"
                />
              </Button>
            </DialogActions>
          ) : null}
        </Dialog>
      </AIWareIntlProvider>
    </AIWareThemeProvider>
  );
};

export default SessionTimeout;
