export const EmptyFormBuilderSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="147" height="159" viewBox="0 0 147 159" fill="none">
      <g clip-path="url(#clip0_64202_17714)">
        <path
          d="M73 131C107.493 131 135.455 103.018 135.455 68.5C135.455 33.9822 107.493 6 73 6C38.507 6 10.5449 33.9822 10.5449 68.5C10.5449 103.018 38.507 131 73 131Z"
          fill="#EAEEF9"
        />
        <g filter="url(#filter0_d_64202_17714)">
          <path
            d="M119.659 125.562H27.3544C24.5191 125.562 22.1562 123.276 22.1562 120.36V16.6395C22.1562 13.8022 24.4403 11.5166 27.3544 11.5166H119.659C122.494 11.5166 124.857 13.8022 124.857 16.7183V120.36C124.857 123.197 122.573 125.562 119.659 125.562Z"
            fill="url(#paint0_linear_64202_17714)"
          />
        </g>
        <path
          d="M113.909 42.8061H32.4729C31.5278 42.8061 30.7402 42.0179 30.7402 41.0722V25.6245C30.7402 24.6787 31.5278 23.8906 32.4729 23.8906H113.909C114.854 23.8906 115.641 24.6787 115.641 25.6245V40.9933C115.72 42.0179 114.932 42.8061 113.909 42.8061Z"
          fill="#EAEEF9"
        />
        <path
          d="M39.2475 35.7132C40.5524 35.7132 41.6102 34.6546 41.6102 33.3488C41.6102 32.0429 40.5524 30.9844 39.2475 30.9844C37.9426 30.9844 36.8848 32.0429 36.8848 33.3488C36.8848 34.6546 37.9426 35.7132 39.2475 35.7132Z"
          fill="#AAB2C5"
        />
        <path
          d="M97.3704 34.9248H49.5643C49.1705 34.9248 48.8555 34.6095 48.8555 34.2155V32.4027C48.8555 32.0086 49.1705 31.6934 49.5643 31.6934H97.3704C97.7642 31.6934 98.0792 32.0086 98.0792 32.4027V34.2155C98.0792 34.6095 97.7642 34.9248 97.3704 34.9248Z"
          fill="#AAB2C5"
        />
        <path
          d="M113.909 67.0808H32.4729C31.5278 67.0808 30.7402 66.2927 30.7402 65.3469V49.978C30.7402 49.0323 31.5278 48.2441 32.4729 48.2441H113.909C114.854 48.2441 115.641 49.0323 115.641 49.978V65.3469C115.72 66.2927 114.932 67.0808 113.909 67.0808Z"
          fill="#EAEEF9"
        />
        <path
          d="M39.2475 59.9877C40.5524 59.9877 41.6102 58.9291 41.6102 57.6233C41.6102 56.3174 40.5524 55.2588 39.2475 55.2588C37.9426 55.2588 36.8848 56.3174 36.8848 57.6233C36.8848 58.9291 37.9426 59.9877 39.2475 59.9877Z"
          fill="#AAB2C5"
        />
        <path
          d="M97.3704 59.2783H49.5643C49.1705 59.2783 48.8555 58.9631 48.8555 58.569V56.7563C48.8555 56.3622 49.1705 56.0469 49.5643 56.0469H97.3704C97.7642 56.0469 98.0792 56.3622 98.0792 56.7563V58.569C98.0792 58.9631 97.7642 59.2783 97.3704 59.2783Z"
          fill="#AAB2C5"
        />
        <path
          d="M113.909 115.71H32.4729C31.5278 115.71 30.7402 114.922 30.7402 113.976V98.6069C30.7402 97.6611 31.5278 96.873 32.4729 96.873H113.909C114.854 96.873 115.641 97.6611 115.641 98.6069V113.976C115.72 114.922 114.932 115.71 113.909 115.71Z"
          fill="#EAEEF9"
        />
        <path
          d="M39.2475 108.616C40.5524 108.616 41.6102 107.558 41.6102 106.252C41.6102 104.946 40.5524 103.888 39.2475 103.888C37.9426 103.888 36.8848 104.946 36.8848 106.252C36.8848 107.558 37.9426 108.616 39.2475 108.616Z"
          fill="#AAB2C5"
        />
        <path
          d="M97.3704 107.828H49.5643C49.1705 107.828 48.8555 107.513 48.8555 107.119V105.306C48.8555 104.912 49.1705 104.597 49.5643 104.597H97.3704C97.7642 104.597 98.0792 104.912 98.0792 105.306V107.119C98.0792 107.513 97.7642 107.828 97.3704 107.828Z"
          fill="#AAB2C5"
        />
        <path
          d="M132.102 90.3562H50.6663C50.2741 90.3562 49.9336 90.0165 49.9336 89.6223V74.2535C49.9336 73.8593 50.2741 73.5195 50.6663 73.5195H132.102C132.494 73.5195 132.835 73.8593 132.835 74.2535V89.6223C132.835 90.0165 132.494 90.3562 132.102 90.3562Z"
          fill="#D6DCE8"
          stroke="#AAB2C5"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M57.4409 84.3422C58.7458 84.3422 59.8036 83.2836 59.8036 81.9777C59.8036 80.6719 58.7458 79.6133 57.4409 79.6133C56.136 79.6133 55.0781 80.6719 55.0781 81.9777C55.0781 83.2836 56.136 84.3422 57.4409 84.3422Z"
          fill="#AAB2C5"
        />
        <path
          d="M115.564 83.5537H67.7577C67.3639 83.5537 67.0488 83.2384 67.0488 82.8443V81.0316C67.0488 80.6375 67.3639 80.3223 67.7577 80.3223H115.564C115.958 80.3223 116.273 80.6375 116.273 81.0316V82.8443C116.273 83.2384 115.958 83.5537 115.564 83.5537Z"
          fill="#AAB2C5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_64202_17714"
          x="0.15625"
          y="0.516602"
          width="146.701"
          height="158.045"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_64202_17714" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_64202_17714" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_64202_17714"
          x1="73.473"
          y1="8.8786"
          x2="73.473"
          y2="126.791"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDFEFF" />
          <stop offset="0.9964" stop-color="#ECF0F5" />
        </linearGradient>
        <clipPath id="clip0_64202_17714">
          <rect width="147" height="159" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyFormBuilderSvg;
