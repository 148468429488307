import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '@aiware/js/interfaces';
import {
  selectSourceTreeBreadCrumbActive,
  selectSourceTreeBreadCrumbsViewModel,
} from '../../../redux/selectors/view-streams';
import { actions } from '../../../redux/slices';
import { Breadcrumb, EntityTypesWithActions } from '../../../types';
import ActionBar from '../ActionToolbar';
import ContentLayout from '../ContentLayout';
import PathPicker from '../PathPicker';
import Schedules from './schedules/Schedules';
import Sources from './sources/Sources';
import Tdo from './tdos/Tdo';

const getActiveTable = (activeCrumb: Breadcrumb): [EntityTypesWithActions, JSX.Element] | null => {
  if (activeCrumb.isRoot) {
    return [EntityType.Sources, <Sources />];
  }

  if (activeCrumb.entity === EntityType.Sources && activeCrumb.id) {
    return [EntityType.Schedules, <Schedules parentSourceId={activeCrumb.id} />];
  }

  if (activeCrumb.entity === EntityType.Schedules && activeCrumb.id) {
    return [EntityType.Tdos, <Tdo parentScheduleId={activeCrumb.id} />];
  }

  return null;
};

const SourcesView: FunctionComponent = () => {
  const breadCrumbs = useSelector(selectSourceTreeBreadCrumbsViewModel);
  const activeCrumb = useSelector(selectSourceTreeBreadCrumbActive);
  const [entityType, tableInstance] = getActiveTable(activeCrumb) || [];

  return (
    <ContentLayout
      breadcrumbs={
        <PathPicker
          pathList={breadCrumbs}
          goToCrumbActionCreator={actions.viewStreams.goToSourceTreeBreadCrumb}
        />
      }
      table={tableInstance}
      actions={entityType && <ActionBar entityType={entityType} />}
    />
  );
};

export default SourcesView;
