import React, { FunctionComponent } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import CircularProgress from '@mui/material/CircularProgress';
import {
  betaFeaturesSelector,
  configSelector,
  selectIsFullscreenEnabled,
  userSelector,
} from '@aiware/shared/redux';
import { mountPanel } from '@aiware/js/panel';
import { makeStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DateFormatter from '../../../../../shared/DateFormatter';
import IconMimeHelper from '../../../../../shared/IconMimeHelper';
import { EResultsViewStyle } from '../../AISearchResultsView';
import { AiSearchRowItemFluffy } from './FluffyRow/AiSearchRowItemFluffy';
import { useSelector, useDispatch } from 'react-redux';
import { selectSearchActiveCriteria, selectLastColumn } from '../../../../state/aiSearch/aiSearch.selectors';
import { Tooltip } from '@mui/material';
import { AiAvatar } from '../../../AIAvatar';
import { AiSearchResultItemContextMenu } from './ContextMenu/AiSearchResultItemContextMenu';
import { ECapabilityTypes } from '../../../../types/aiAutocomplete.types';
import { AvailableComponents } from '@aiware/js/interfaces';
import { DataRegistry, Source } from '@aiware/shared/icons';
import { StatusPill } from '@aiware/shared/reusable-utils';
import { ITdoVm } from '../../../../../../types';

const useStyles = makeStyles()(theme => ({
  row: {
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#EEF3F9',
      cursor: 'pointer',
    },
  },
  loader: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
  divider: {
    opacity: 0.9,
  },
  sourceIcon: {
    verticalAlign: 'middle',
  },
}));

interface IProps {
  results: any[];
  hasMore: boolean;
  loadResults: () => void;
  viewStyle: EResultsViewStyle;
}

const SearchVirtualTableContent = ({ results, hasMore, loadResults, viewStyle }: IProps) => {
  const dispatch = useDispatch();
  const configs = useSelector(configSelector);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const criteria: any[] = useSelector(selectSearchActiveCriteria);
  const lastColumn = useSelector(selectLastColumn);
  const betaFeatures = useSelector(betaFeaturesSelector);
  const loadMoreResults = loadResults;

  const itemCount = hasMore ? results.length + 1 : results.length;
  const isItemLoaded = (index: number) => !hasMore || index < results.length;
  const itemVerticalHeight = viewStyle === EResultsViewStyle.FLUFFY ? 115 : 48;
  const containerHeightOffset = isFullScreen ? 40 : 60;
  const user = useSelector(userSelector);

  const isNewDataDetails = (item: ITdoVm) => {
    // ONLY if the content type is application/json, AND...
    if (item?.primaryAsset?.contentType === 'application/json') {
      // ...beta features are on, use new panel
      if (betaFeatures) {
        return true;
      }

      // ...it's the Veritone org, use new panel
      if (
        user?.organization?.organizationId === 7682 &&
        user?.organization?.organizationName === 'Veritone, Inc.'
      ) {
        return true;
      }
    }
    return false;
  };

  const handleOpenMediaDetailsPanel = (item: ITdoVm, url: string) => {
    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'xlarge',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };

    if (isNewDataDetails(item)) {
      const microFrontend = {
        name: 'DATA_CENTER_DATA_DETAILS_PANEL',
        config: {
          tdoId: item.id,
          fileName: item.details?.filename || item.details?.fileName || item.name,
        },
      };

      dispatch(
        mountPanel({
          panelId: 'DATA_CENTER_DATA_DETAILS_PANEL',
          microFrontend: microFrontend,
          panelConfig: panelConfig,
        })
      );
    } else {
      const microFrontend = {
        name: AvailableComponents.DATA_CENTER_MDP_IFRAME_PANEL,
        config: {
          entityId: item.id,
          iframeUrl: url,
        },
      };

      dispatch(
        mountPanel({
          panelId: AvailableComponents.DATA_CENTER_MDP_IFRAME_PANEL,
          microFrontend: microFrontend,
          panelConfig: panelConfig,
        })
      );
    }
  };

  const handleOpenStructuredDataViewerPanel = (item: any) => {
    const microFrontend = {
      name: AvailableComponents.DATA_CENTER_SDO_VIEWER,
      config: {
        dataRegistryId: item?.id,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'large',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: AvailableComponents.DATA_CENTER_SDO_VIEWER,
        microFrontend,
        panelConfig,
      })
    );
  };

  const handleOpenEditSourceInfo = (item: any) => {
    const microFrontend = {
      name: AvailableComponents.PROCESSING_CENTER_SOURCES,
      config: {
        sourceId: item?.id,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'large',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };

    dispatch(
      mountPanel({
        panelId: AvailableComponents.PROCESSING_CENTER_SOURCES,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  const Row: FunctionComponent<ListChildComponentProps> = ({ index, style }) => {
    let rowItem;
    const { classes } = useStyles();

    if (!isItemLoaded(index)) {
      rowItem = (
        <div data-testid={'os-dc-search-results-table-row-loader'} className={classes.loader}>
          <CircularProgress size={36} />
        </div>
      );
    } else {
      const item = results[index];
      const mediaDetailsUrl = `${configs.cmsAppUrl}/embed/#/media-details/${item?.id}`;

      const getIcon = () => {
        switch (item?.capabilityType) {
          case ECapabilityTypes.dataRegistry:
            return <Icon className={classes.sourceIcon} component={DataRegistry} />;
          case ECapabilityTypes.source:
            return <Icon className={classes.sourceIcon} component={Source} />;
          default:
            return (
              <IconMimeHelper className={classes.sourceIcon} value={item?.details?.veritoneFile?.mimetype} />
            );
        }
      };

      const handleClickItem = () => {
        switch (item?.capabilityType) {
          case ECapabilityTypes.dataRegistry:
            handleOpenStructuredDataViewerPanel(item);
            break;
          case ECapabilityTypes.source:
            handleOpenEditSourceInfo(item);
            break;
          default:
            handleOpenMediaDetailsPanel(item, mediaDetailsUrl);
            break;
        }
      };

      const renderMatchesColumn = () => {
        return (
          <>
            {criteria.map((criterion, idx) => (
              <Tooltip title={criterion?.label} key={idx}>
                <span style={{ width: '30px' }}>
                  <AiAvatar
                    id={`aiAvatar-id-${criterion?.id}`}
                    avatarUrl={criterion?.imageUrl}
                    capabilityType={criterion.capabilityType}
                    sx={criterion?.imageUrl ? {} : { borderRadius: '6px!important', width: '100%' }}
                  />
                </span>
              </Tooltip>
            ))}
            <span>
              <AiSearchResultItemContextMenu viewStyle={EResultsViewStyle.COMPRESSED} entityId={item?.id} />
            </span>
          </>
        );
      };

      const renderDataRegistryColumn = (): JSX.Element => {
        const dataRegistryColumn = () => {
          let schemaRow;

          if (item?.schemas?.records?.length > 0) {
            schemaRow = item?.schemas?.records[0];
          }

          if (item?.publishedSchema?.id) {
            schemaRow = item?.publishedSchema;
          }

          if (!schemaRow) {
            schemaRow = {
              majorVersion: 0,
              minorVersion: 0,
              status: 'Draft',
            };
          }

          return (
            <Stack direction="row" justifyContent="space-between">
              {schemaRow.majorVersion}.{schemaRow.minorVersion}
              <StatusPill
                statusType="sdo"
                data-test="dc-search-items-results-schema-status"
                status={schemaRow?.status || 'Draft'}
                ContainerProps={{ sx: { mr: 1 } }}
              />
            </Stack>
          );
        };

        return (
          <Typography
            data-test="dc-search-items-results-schema-version"
            variant="body2"
            sx={{ fontWeight: 400, width: '100%' }}
            color="textPrimary"
          >
            {dataRegistryColumn()}
          </Typography>
        );
      };

      const renderSourcesColumn = (): JSX.Element => {
        return (
          <Typography
            data-test="dc-search-items-results-source-type"
            variant="body2"
            sx={{ fontWeight: 400 }}
            color="textPrimary"
          >
            {item?.sourceType?.name}
          </Typography>
        );
      };

      const renderLastColumn = (): JSX.Element => {
        switch (item?.capabilityType) {
          case ECapabilityTypes.dataRegistry:
            return renderDataRegistryColumn();
          case ECapabilityTypes.source:
            return renderSourcesColumn();
          default:
            return renderMatchesColumn();
        }
      };

      if (viewStyle === EResultsViewStyle.FLUFFY) {
        rowItem = <AiSearchRowItemFluffy item={item} onClick={handleClickItem} />;
      } else {
        rowItem = (
          <React.Fragment>
            <Grid
              style={{ height: itemVerticalHeight, flexGrow: 1 }}
              container
              className={classes.row}
              onClick={handleClickItem}
            >
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                container
                xs={12}
                sx={{ pl: 3 }}
              >
                <Grid item xs={1}>
                  {getIcon()}
                </Grid>
                <Grid item xs={lastColumn.enabled ? 6 : 9}>
                  <Typography
                    data-test="dc-search-items-results-name"
                    variant="body2"
                    sx={{ fontWeight: 400, marginRight: '30px' }}
                    noWrap
                    color="textPrimary"
                  >
                    {item?.name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <DateFormatter value={new Date(item?.modifiedDateTime).toISOString()} />
                </Grid>
                {lastColumn.enabled && (
                  <Grid display="flex" justifyContent="space-between" alignItems="center" item xs={3}>
                    {renderLastColumn()}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </React.Fragment>
        );
      }
    }

    return (
      <div key={index} style={{ ...style }}>
        {rowItem}
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => {
        return (
          <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreResults}>
            {({ onItemsRendered, ref }) => (
              <div>
                <List
                  height={height - containerHeightOffset}
                  itemCount={itemCount}
                  itemSize={itemVerticalHeight}
                  initialScrollOffset={0}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {Row}
                </List>
              </div>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default SearchVirtualTableContent;
