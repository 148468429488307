import { Stack } from '@mui/material';
import { panelComponents } from '@aiware/shared/reusable-utils';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel, mountPanel } from '@aiware/js/panel';
import React, { useEffect } from 'react';
import { SHARED_TEXT } from '../../../helpers/shared-text';
import { SDO_VIEWER_PANEL_ID } from '../../../constants';
import { TStructuredDataViewerProps } from '../index';
import { HeaderActions } from './header-actions';
import { SchemaDropdown } from './schema-dropdown';
import { actions } from '../../../redux/slices';
import {
  _selectViewerPanelDataRegistry,
  selectViewerPanelDataRegistryStatus,
} from '../../../redux/selectors';
import { SDOTable } from './sdo-table';
import { selectIsFullscreenEnabled, setIsFullscreenEnabled } from '@aiware/shared/redux';

const { header: PanelHeader, container: PanelContainer, content: PanelContent } = panelComponents;

export const MainLayout: React.FC<TStructuredDataViewerProps> = props => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectViewerPanelDataRegistryStatus);
  const isLoading = loadingStatus === 'pending';
  const isErrored = loadingStatus === 'failure';
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const dataRegistry = useSelector(_selectViewerPanelDataRegistry);

  const handleClose = () => {
    dispatch(hidePanel(SDO_VIEWER_PANEL_ID));
  };

  const onClickInfo = () => {
    dispatch(
      mountPanel({
        panelId: 'DATA_CENTER_DATA_REGISTRY_INFO',
        microFrontend: {
          name: 'DATA_CENTER_DATA_REGISTRY_INFO',
          config: {
            dataRegistry: dataRegistry,
          },
        },
        panelConfig: {
          type: 'APP_BAR_PANEL_TEMPLATE',
          marginTop: isFullScreen ? 0 : 55,
          marginStart: isFullScreen ? 0 : 80,
          size: 'small',
          parentPanelId: SDO_VIEWER_PANEL_ID,
          dimmed: 0,
          dimmedStatus: 'dimParent',
        },
      })
    );
  };

  const handleFetchDataRegistry = () => {
    dispatch(actions.dataRegistry.fetchDataRegistryStart(props.dataRegistryId));
  };

  const handleResetSDOList = () => {
    dispatch(actions.structuredDataObjects['resetList']!(null));
    dispatch(actions.dataRegistry.resetDataRegistry());
  };

  useEffect(() => {
    handleFetchDataRegistry();

    return handleResetSDOList;
  }, []);

  return (
    <PanelContainer panelId={SDO_VIEWER_PANEL_ID} withRenderFullScreenControl preventFullScreenRerender>
      <PanelHeader
        title={SHARED_TEXT.sdoViewer.sdoPanelTitle()}
        testId={'sdo-viewer-panel-panel'}
        onPanelClose={handleClose}
        PanelActions={<HeaderActions onClickInfo={onClickInfo} />}
      />
      <PanelContent
        LoadingStateElement={<></>}
        isLoading={isLoading}
        isErrored={isErrored}
        onRetry={handleFetchDataRegistry}
        style={{ padding: '30px', overflowY: 'hidden' }}
      >
        <Stack height={'100%'} gap="20px">
          <SchemaDropdown />
          <SDOTable />
        </Stack>
      </PanelContent>
    </PanelContainer>
  );
};
