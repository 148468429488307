import Checkbox from '@mui/material/Checkbox';
import { ReactNode, useState } from 'react';
import useStyles from './useStyles';
import { unCheckIcon, checkedIcon } from '@aiware/shared/assets';
import { INotificationType } from '../../../interfaces/notificationInterfaces';
import classNames from 'classnames';

interface INotificationTypeItem {
  notificationTypeIcon: ReactNode;
  notificationTypeName: ReactNode;
  defaultSelected?: boolean;
  onSelectNotificationType?: (type: INotificationType, isSelected: boolean) => void;
  notificationTypeId: INotificationType;
  isDisabled?: boolean;
}

export function NotificationTypeItem(props: INotificationTypeItem) {
  const {
    notificationTypeIcon,
    notificationTypeName,
    defaultSelected,
    onSelectNotificationType,
    notificationTypeId,
    isDisabled,
  } = props;
  const [selectedAction, setSelectedAction] = useState(defaultSelected);
  const { classes } = useStyles();
  function onChangeSelectedAction() {
    setSelectedAction(preState => {
      onSelectNotificationType?.(notificationTypeId, !preState);
      return !preState;
    });
  }

  return (
    <div
      className={classNames(classes.notificationTypeItemBox, isDisabled ? classes.disableType : '')}
      data-testid="notification-type-item"
    >
      <div>
        {typeof notificationTypeIcon === 'string' ? (
          <img src={notificationTypeIcon} alt="icon" draggable="false" />
        ) : (
          notificationTypeIcon
        )}
      </div>
      <div>{notificationTypeName}</div>
      <div>
        <Checkbox
          icon={<img src={unCheckIcon} alt="uncheck icon" draggable="false" />}
          checkedIcon={<img src={checkedIcon} alt="checked icon" draggable="false" />}
          checked={selectedAction}
          onChange={onChangeSelectedAction}
          disabled={!!isDisabled}
        />
      </div>
    </div>
  );
}

export default NotificationTypeItem;
