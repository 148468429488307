import { FormattedMessage } from 'react-intl';

type Message = 'Select' | 'Clear';

export const renderSelectAllText = (type: Message, dataTestPrefix?: string) => {
  return (
    <FormattedMessage
      id="{dataTestPrefix}-permission-set-{lowerCaseType}-all-message"
      data-test={`${dataTestPrefix}-permission-set-${type.toLowerCase()}-all-message`}
      defaultMessage="{type} All"
      description="{type} All button message"
      values={{ type, lowerCaseType: type.toLowerCase(), dataTestPrefix }}
    />
  );
};
