import createSvgIcon from '../createSvgIcon';
export const Packages = createSvgIcon(
  <svg viewBox="0 0 20 22" fill="none">
    <path
      d="M19.8 15.95C19.8 16.368 19.569 16.731 19.217 16.918L10.527 21.802C10.351 21.934 10.131 22 9.9 22C9.669 22 9.449 21.934 9.273 21.802L0.583 16.918C0.231 16.731 0 16.368 0 15.95V6.05C0 5.632 0.231 5.269 0.583 5.082L9.273 0.198C9.449 0.0660001 9.669 0 9.9 0C10.131 0 10.351 0.0660001 10.527 0.198L19.217 5.082C19.569 5.269 19.8 5.632 19.8 6.05V15.95ZM9.9 2.365L7.821 3.542L14.3 7.271L16.456 6.05L9.9 2.365ZM3.344 6.05L9.9 9.735L12.056 8.525L5.588 4.785L3.344 6.05ZM2.2 15.301L8.8 19.019V11.638L2.2 7.931V15.301ZM17.6 15.301V7.931L11 11.638V19.019L17.6 15.301Z"
      fill="#555F7C"
    />
  </svg>,
  'Packages'
);
