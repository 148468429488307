import { Stack, CircularProgress } from '@mui/material';

export const Loader = () => {
  return (
    <Stack
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
      }}
    >
      <CircularProgress />
    </Stack>
  );
};
