import { Fragment, useState, useEffect } from 'react';
import { IMyOrganization } from '../../../types';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LoadingStatus } from '@aiware/js/interfaces';
import ErrorState from '../shared/ErrorState';
import OrganizationItem from '../OrganizationItem/OrganizationItem';
import TabHeader from '../TabHeader/TabHeader';
import { userSelector, globalMessageSelector } from '@aiware/shared/redux';
import { useSelector } from 'react-redux';
import type { IUser } from '@aiware/js/interfaces';
import { FormattedMessage } from 'react-intl';
import OrganizationItemLoaderSkeleton from '../shared/OrganizationItemLoaderSkeleton';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/slices';
import { selectIsScimUser } from '../../../redux/selectors/scimIds.selector';

import useStyles from './useStyles';
interface IMyOrganizationsProps {
  organizations: IMyOrganization[];
  uiStatus: LoadingStatus;
  onRetry: () => void;
  onSetUserDefaultOrg: (orgId: number) => void;
}
export default function MyOrganizations({
  organizations,
  uiStatus,
  onRetry,
  onSetUserDefaultOrg,
}: IMyOrganizationsProps) {
  const { classes } = useStyles();
  const [selectedOrgId, setSelectedOrgId] = useState(0);
  const [defaultOrgId, setDefaultOrgId] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const user = useSelector(userSelector) as IUser;
  const isScimUser = useSelector(selectIsScimUser);

  const { email, organization } = user;

  const { organizationId } = organization || {};

  const dispatch = useDispatch();

  const globalMessage = useSelector(globalMessageSelector);
  const setOrg = (guid: string) => {
    if (!globalMessage) {
      dispatch(
        actions.organizationsState.switchUserToOrganizationStart({
          organizationGuid: guid,
          userName: email!,
        })
      );
    }
  };

  const isCurrentOrg = (id: string | number) => (organizationId || '').toString() === (id || '').toString();

  useEffect(() => {
    if (organizations?.length > 0) {
      let minPriority = Infinity;
      let orgId = 0;
      // default org has lowest priority
      // find default org
      for (const org of organizations) {
        if (org.priority < minPriority) {
          minPriority = org.priority;
          orgId = org.id;
        }
      }
      setDefaultOrgId(orgId);
    }
  }, [organizations]);

  const handleOpenOrgDefaultSettingsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOrgDefaultSettings = () => {
    setAnchorEl(null);
  };
  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setSelectedOrgId(0);
    setOpenConfirmationModal(false);
    handleCloseOrgDefaultSettings();
  };

  const handleSetDefaultOrg = () => {
    onSetUserDefaultOrg(selectedOrgId);
    handleCloseConfirmationModal();
  };

  const renderAction = (id: number) => {
    if (id === defaultOrgId || organizations.length === 1) return true;
    if (isScimUser !== false) return true;
    return false;
  };

  const renderOrganizationItems = () => {
    if (uiStatus === 'pending') {
      return <OrganizationItemLoaderSkeleton />;
    } else if (uiStatus === 'failure') {
      return (
        <ErrorState
          onRetry={onRetry}
          errorMessage={
            <FormattedMessage
              id="os-organization-panel.organizations-tab-error-message"
              defaultMessage="Something went wrong while loading your organizations."
              description="my organizations error state"
            />
          }
        />
      );
    } else if (uiStatus === 'success' && organizations?.length) {
      return (
        <List className={classes.orgList}>
          {organizations.map((org, index) => (
            <OrganizationItem
              organizationName={org.name}
              guid={org.guid}
              showDivider={index !== organizations.length - 1}
              key={org.id}
              organizationLogo={org.imageUrl}
              isDefault={org.id === defaultOrgId}
              Action={
                renderAction(org.id) ? null : (
                  <IconButton
                    aria-controls="org-menu"
                    aria-haspopup="true"
                    onClick={e => {
                      setSelectedOrgId(org.id);
                      handleOpenOrgDefaultSettingsMenu(e);
                    }}
                    data-test="organization-panel-menu-button"
                  >
                    <MoreVertIcon />
                  </IconButton>
                )
              }
              onClick={isCurrentOrg(org.id) ? undefined : () => setOrg(org.guid!)}
            />
          ))}
        </List>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <TabHeader tab="my-organizations" />
      {renderOrganizationItems()}
      <Menu
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="org-default-settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseOrgDefaultSettings}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={handleOpenConfirmationModal}
          className={classes.menuItem}
          data-test="organization-panel-make-default-button"
        >
          <Typography variant="body2">
            <FormattedMessage
              id="os-organization-panel.my.organizations.make.default"
              defaultMessage="Make Default"
              description="organization panel: make organization default button"
            />
          </Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={openConfirmationModal}
        maxWidth="xs"
        onClose={handleCloseConfirmationModal}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle
          id="confirmation-dialog-title"
          data-test="org-confirmation-dialog-title"
          className={classes.dialogTitle}
        >
          <Typography variant="h2" className={classes.dialogTitleText}>
            <FormattedMessage
              id="org.confirm.dialog.title"
              defaultMessage="Change Default Org?"
              description="title of confirmation modal "
            />
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="confirmation-dialog-description">
            <FormattedMessage
              id="org.confirm.dialog.content"
              defaultMessage="Current authentication methods may not be supported with this new organization."
              description="content of confirmation modal "
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleCloseConfirmationModal}
            variant="text"
            size="large"
            className={`${classes.dialogBtn} ${classes.cancelButton}`}
            data-test="org-confirmation-dialog-cancel-btn"
          >
            <FormattedMessage
              id="org.confirm.dialog.cancel.btn"
              defaultMessage="Cancel"
              description="cancel button "
            />
          </Button>
          <Button
            onClick={handleSetDefaultOrg}
            size="large"
            variant="contained"
            color="primary"
            className={classes.dialogBtn}
            data-test="org-confirmation-dialog-confirm-btn"
          >
            <FormattedMessage
              id="org.confirm.dialog.confirm.btn"
              defaultMessage="Confirm"
              description="confirm button "
            />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
