import { takeEvery } from 'redux-saga/effects';
import {
  fetchUserWallpapersSaga,
  getTDOAssetsSaga,
  setCurrentWallpaperSaga,
  removeCustomWallpaperSaga,
  handleSetCustomWallpaperSaga,
  fetchUserCurrentWallpaperSaga,
} from './sagas';
import { actions } from '../slices/aiware-desktop.state';

export function* fetchUserWallpapersWatcher() {
  yield takeEvery(actions.fetchUserWallpapersStart.type, fetchUserWallpapersSaga);
}

export function* getTDOAssetsWatcher() {
  yield takeEvery(actions.getTDOAssetsStart.type, getTDOAssetsSaga);
}

export function* handleSetCustomWallpaperWatcher() {
  yield takeEvery(actions.setCustomWallpaperStart.type, handleSetCustomWallpaperSaga);
}

export function* setCurrentWallpaperWatcher() {
  yield takeEvery(actions.setCurrentWallpaperStart.type, setCurrentWallpaperSaga);
}

export function* removeCustomWallpaperWatcher() {
  yield takeEvery(actions.removeCustomWallpaperStart.type, removeCustomWallpaperSaga);
}

export function* fetchUserCurrentWallpaperTDOWatcher() {
  yield takeEvery(actions.fetchUserCurrentWallpaperTDOStart.type, fetchUserCurrentWallpaperSaga);
}
