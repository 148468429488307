import createSvgIcon from '../createSvgIcon';

export const PrivateWhite = createSvgIcon(
  <svg width="40" height="40" viewBox="7 7 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="1870E8" />
    <path
      d="M26 16H25V14C25 11.24 22.76 9 20 9C17.24 9 15 11.24 15 14V16H14C12.9 16 12 16.9 12 18V28C12 29.1 12.9 30 14 30H26C27.1 30 28 29.1 28 28V18C28 16.9 27.1 16 26 16ZM20 25C18.9 25 18 24.1 18 23C18 21.9 18.9 21 20 21C21.1 21 22 21.9 22 23C22 24.1 21.1 25 20 25ZM23.1 16H16.9V14C16.9 12.29 18.29 10.9 20 10.9C21.71 10.9 23.1 12.29 23.1 14V16Z"
      fill="white"
      stroke="white"
    />
  </svg>,
  'PrivateWhite'
);
