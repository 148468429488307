import { ReactElement, Fragment } from 'react';
import { useStyles } from './useStyles';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { IUtilityNotificationSetting } from '../../../interfaces/notificationInterfaces';
import classNames from 'classnames';

export interface INotificationSettingItem {
  imgSrc: string;
  primaryText: ReactElement | string;
  secondaryText: ReactElement | string;
  bigSize?: boolean;
  onOpenUtility?: (
    panelId: IUtilityNotificationSetting['id'],
    panelTitle: IUtilityNotificationSetting['title']
  ) => void;
  onOpenApplication?: (panelId?: string, panelTitle?: string) => void;
  hideDivider?: boolean;
  utilityId?: IUtilityNotificationSetting['id'];
  utilityTitle?: IUtilityNotificationSetting['title'];
  applicationId?: string;
  applicationTitle?: string;
  disabled?: boolean;
  isHover?: boolean;
}

export default function NotificationSettingItem(props: INotificationSettingItem) {
  const {
    imgSrc,
    primaryText,
    secondaryText,
    bigSize,
    onOpenUtility,
    onOpenApplication,
    hideDivider,
    applicationId,
    applicationTitle,
    utilityId = '',
    utilityTitle = 'Finder',
    disabled,
    isHover,
  } = props;
  const { classes } = useStyles();
  function openNotificationPanel() {
    onOpenUtility?.(utilityId, utilityTitle);
    onOpenApplication?.(applicationId, applicationTitle);
  }

  return (
    <Fragment>
      <ListItem
        className={classNames(classes.utilityItem, isHover ? classes.utilityItemHover : '')}
        onClick={openNotificationPanel}
        disabled={disabled}
        data-testid="notification-setting-item"
      >
        <ListItemAvatar>
          <Avatar
            style={{
              width: bigSize ? 60 : 40,
              height: bigSize ? 60 : 40,
              borderRadius: '50%',
              marginRight: 19,
            }}
            src={imgSrc}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant={bigSize ? 'h3' : 'body2'} children={primaryText} />}
          secondary={<Typography variant={bigSize ? 'body2' : 'caption'} children={secondaryText} />}
        />
      </ListItem>
      {!hideDivider && <Divider className={isHover ? '' : classes.dividerSmallMargin} />}
    </Fragment>
  );
}
