import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { IUser } from '@aiware/js/interfaces';
import AutoComplete from './AutoComplete';
import { useStyles } from './useStyles';
import { TUserGroup } from '../../../types';

export type TProps = {
  group: Partial<TUserGroup>;
  onAddMember: (value: IUser) => void;
  onRemoveMember: (value: IUser) => void;
};

const GroupMembers = ({ group, onAddMember, onRemoveMember }: TProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Typography
        variant={'h2'}
        className={classes.groupMembersTitle}
        data-testid="admin-center-group-members-title"
      >
        <FormattedMessage
          id="admin-center-group-members-title"
          defaultMessage="Group Members"
          description="Group Members title"
        />
      </Typography>
      <Typography
        data-testid="admin-center-group-members-description"
        variant={'body1'}
        className={classes.groupMembersDescription}
      >
        <FormattedMessage
          id="admin-center-group-members-description"
          defaultMessage="Members gain access to this groups shared data."
          description="Group Members description"
        />
      </Typography>
      <AutoComplete group={group} onAddMember={onAddMember} onRemoveMember={onRemoveMember} />
    </>
  );
};

export default GroupMembers;
