import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  panelTitle: (msg?: string) => (
    <FormattedMessage
      id="os-data-center-importer.UQliO+"
      defaultMessage="{message}"
      description="Local Machine Importer"
      values={{ message: msg ?? 'Local Machine Importer' }}
    />
  ),

  closePanel: () => (
    <FormattedMessage
      id="os-data-center-importer.kOsSpC"
      defaultMessage="Close Panel"
      description="Close Panel"
    />
  ),

  title: (msg?: string) => (
    <FormattedMessage
      id="os-data-center-importer.ylbMu5"
      defaultMessage="{message}"
      description="Local Machine File Importer: Local Machine"
      values={{ message: msg ?? 'Local Machine' }}
    />
  ),

  titleSubText: (msg?: string) => (
    <FormattedMessage
      id="os-data-center-importer.OcKW8X"
      defaultMessage="{message}"
      description="Local Machine File Importer: Choose files from your computer to import into your account."
      values={{
        message: msg ?? 'Choose files from your computer to import into your account.',
      }}
    />
  ),

  locationFolderTitle: (msg?: string) => (
    <FormattedMessage
      id="os-data-center-importer.UR2j6X"
      defaultMessage="{message}"
      description="Local Machine File Importer: Location Folder"
      values={{ message: msg ?? 'Location Folder' }}
    />
  ),

  locationFolderSubText: (msg?: string) => (
    <FormattedMessage
      id="os-data-center-importer.zEHvrp"
      defaultMessage="{message}"
      description="Local Machine File Importer: Choose the Data Center folder location where this content will be stored."
      values={{ message: msg ?? 'Choose the Data Center folder location where this content will be stored.' }}
    />
  ),

  locationFolderLabel: (msg?: string) => (
    <FormattedMessage
      id="os-data-center-importer.UR2j6Z"
      defaultMessage="{message}"
      description="Local Machine File Importer: Folder Input Label"
      values={{ message: msg ?? 'Folder' }}
    />
  ),

  locationFolderReselect: () => (
    <FormattedMessage id="os-data-center-importer.gtjP09" defaultMessage="Change" description="Change" />
  ),

  editFileMetadataHeader: () => (
    <FormattedMessage
      id="os-data-center-importer.WUHjl4"
      defaultMessage="Edit Metadata"
      description="Edit Metadata"
    />
  ),

  editFileMetadataCancelBtn: () => (
    <FormattedMessage id="os-data-center-importer.6PdOcy" defaultMessage="Cancel" description="Cancel" />
  ),

  editFileMetadataSaveBtn: () => (
    <FormattedMessage
      id="os-data-center-importer.kxBKrN"
      defaultMessage="Save Changes"
      description="Save file metadata changes"
    />
  ),

  editFileMetadataAddBtn: () => (
    <FormattedMessage
      id="os-data-center-importer.rntBSe"
      defaultMessage="Add"
      description="Add tags to metadata"
    />
  ),

  editFileMetadataTagsHeading: () => (
    <FormattedMessage id="os-data-center-importer.fXL811" defaultMessage="Tags" description="Tags heading" />
  ),

  editFileMetadataTagsSubHeading: () => (
    <FormattedMessage
      id="os-data-center-importer.WFj+x5"
      defaultMessage="Add searchable tags to better organize this data. Separate each tag with a comma."
      description="Tags sub heading"
    />
  ),

  editFileMetadataDisplayTimeHeading: () => (
    <FormattedMessage
      id="os-data-center-importer.8aqtJF"
      defaultMessage="Media Display Time"
      description="Media Display Time heading"
    />
  ),

  editFileMetadataDisplayTimeSubHeading: () => (
    <FormattedMessage
      id="os-data-center-importer.mhUvxM"
      defaultMessage="The actual time the content occurred."
      description="Media Display Time sub heading"
    />
  ),
  supportedFilesTitle: () => (
    <FormattedMessage
      id="os-data-center-importer.1riZqU"
      defaultMessage="Supported File Types"
      description="The title for the container of supported files"
    />
  ),
};
