import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '@aiware/shared/redux';
import { IImageGetUrlReturnType, IImageUrlsType } from '../types';

export async function getImageURL(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  file: File
): Promise<IImageGetUrlReturnType> {
  const operationName = 'getUrl';
  const query = `
    query ${operationName}($name: String!){
      getSignedWritableUrl(key: $name) {
        bucket
        key
        expiresInSeconds
        expiresAtDateTime
        url
        unsignedUrl
        getUrl
      }
    }
  `;

  const variables = {
    name: file.name,
  };

  const result = await baseGraphQLApiWithError<{
    getSignedWritableUrl: IImageUrlsType;
  }>({
    query,
    operationName,
    variables,
    ...apiConfigs,
  });

  const { url } = result.getSignedWritableUrl;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'X-Veritone-Application': 'aiware-sdk',
    },
    body: file,
  };

  try {
    await fetch(url, options);
    return result.getSignedWritableUrl;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
