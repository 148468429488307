import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';
import { FolderId, IFolder, SLICE_NAME } from '../../types';

export const namespace = 'panelAddFolder';

export type State = {
  status: LoadingStatus;
  parentFolderId: FolderId | null;
  permissions: any;
};

export const initialState = {
  status: 'idle',
  parentFolderId: null,
  permissions: null,
} as State;

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    openAddNewFolder(state, { payload }: PayloadAction<FolderId>) {
      state.status = 'idle';
      state.parentFolderId = payload;
    },
    closeAddNewFolder(state) {
      state.status = 'idle';
      state.parentFolderId = null;
      state.permissions = null;
    },
    createNewFolderInCurrentParent(state, action: PayloadAction<{ name: string }>) {
      state.status = 'pending';
    },
    createNewFolderStart(
      state,
      action: PayloadAction<{
        parentFolderId: FolderId;
        name: string;
      }>
    ) {
      state.status = 'pending';
    },
    createNewFolderSucceeded(state, action: PayloadAction<{ parentFolderId: FolderId; newFolder: IFolder }>) {
      state.status = 'idle';
    },
    createNewFolderFailed(state) {
      state.status = 'failure';
    },
    setPermissionsPayload(state, action: PayloadAction<{ permissions: any }>) {
      const permissions = action.payload;

      state.permissions = permissions;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
