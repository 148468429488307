import { Box, Grid, Skeleton } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';

export const TableLoadingState: React.FC<{ itemHeight: number; LoadingComponent?: () => JSX.Element }> = ({
  itemHeight,
  LoadingComponent,
}) => {
  const style = {
    overflow: 'hidden',
    width: '100%',
  };

  const LoadingState: React.FC<{ containerHeight: number }> = ({ containerHeight }) => {
    const amountToRender = Math.ceil(containerHeight / itemHeight);

    return (
      <>
        {new Array(amountToRender).fill(null).map((_item, index) => {
          return <TableRowLoader LoadingComponent={LoadingComponent} height={itemHeight} key={index} />;
        })}
      </>
    );
  };

  return (
    <Grid sx={{ position: 'relative', height: '99%', overflow: 'hidden' }}>
      <AutoSizer style={{ height: 'auto', width: 'auto' }}>
        {({ height, width: _width }: { height: number; width: number }) => {
          return (
            <Box sx={{ ...style, height }}>
              <LoadingState containerHeight={height} />
            </Box>
          );
        }}
      </AutoSizer>
    </Grid>
  );
};

export const TableRowLoader: React.FC<{ height: number; LoadingComponent?: () => JSX.Element }> = ({
  height,
  LoadingComponent,
}) => {
  const style = {
    height,
    display: 'grid',
    placeItems: 'center',
    overflow: 'hidden',
  };
  return (
    <Box sx={style}>
      {!LoadingComponent && (
        <Skeleton animation={'wave'} sx={{ transform: 'none' }} width={'99.5%'} height={'90%'} />
      )}
      {LoadingComponent && <LoadingComponent />}
    </Box>
  );
};
