import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import { useStyles } from '../../components/useStyles';
import IconSourceHelper from '../IconSourceHelper';
import { useSelector } from 'react-redux';
import { selectTableDataSettings } from '../../../redux/selectors/ui-state';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const SourceTypeId: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const { classes } = useStyles();

  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showSources = columns.indexOf('sourceTypeId') >= 0;

  return (
    <>
      {showSources && (
        <Grid data-test={'item-sourceType'} item xs={xs} className={classes.type}>
          <IconSourceHelper sourceTypeId={(item as ISource).sourceTypeId} className={classes.sourceIcon} />
        </Grid>
      )}
    </>
  );
};

export default SourceTypeId;
