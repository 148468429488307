import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import cx from 'classnames';
import useStyles from './useStyles';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { UserStatusBadge } from '../invite-panel/user-status-badge';
import { EUserStatus } from '../invite-panel';
import { EOrganizationInviteAction, TApplicationInviteRole } from '../../types';

export enum InviteRequestListItemStatus {
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending',
}

interface InviteRequestListItemProps {
  id: string;
  avatar: string;
  name: string;
  email: string;
  requestor: string;
  userStatus: EUserStatus;
  itemStatus: EOrganizationInviteAction;
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  requestedApps: TApplicationInviteRole[];
}

const InviteRequestListItem: FunctionComponent<InviteRequestListItemProps> = ({
  id,
  avatar,
  name,
  email,
  requestor,
  userStatus,
  requestedApps,
  itemStatus,
  onApprove,
  onReject,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.listItemRoot}>
      <Box p={4}>
        <Box alignItems="flex-start" display="flex">
          <Avatar
            data-testid="request-list-item-user-avatar"
            alt={name}
            sx={{ mr: 4, width: 40, height: 40 }}
            src={avatar}
          />
          <Box flex={1}>
            <Typography
              data-testid="request-list-item-user-name"
              variant={'body1'}
              className={classes.userName}
            >
              {name}
            </Typography>
            <Typography
              data-testid="request-list-item-user-email"
              variant={'body2'}
              className={classes.email}
            >
              {email}
            </Typography>

            <Typography
              data-testid="request-list-item-requestor"
              variant={'body2'}
              className={classes.requestor}
            >
              {SHARED_TEXT.requestor}: {requestor}
            </Typography>
          </Box>

          <UserStatusBadge variant={userStatus} />
        </Box>

        <Box p={4} pt={2} mt={3} bgcolor="#F2F7FE" borderRadius={1}>
          <Box mb={2} display="flex">
            <Typography flex={2} variant={'body2'} className={classes.subheading}>
              {SHARED_TEXT.accessRequest}
            </Typography>
            <Typography flex={1} variant={'body2'} className={classes.subheading}>
              {SHARED_TEXT.role}
            </Typography>
          </Box>

          {requestedApps.map((appRole, index) => (
            <Box
              className={classes.appListItem}
              data-testid="request-list-item-app-item"
              key={`request-list-item-${index}`}
            >
              <Box flex={2} display="flex" alignItems={'center'}>
                <Avatar
                  alt={appRole?.application?.name}
                  sx={{ mr: 4, width: 40, height: 40 }}
                  src={appRole?.application?.iconUrl}
                />
                <Typography variant={'body1'} className={classes.userName}>
                  {appRole?.application?.name}
                </Typography>
              </Box>
              <Typography flex={1} variant={'body1'} className={classes.userName}>
                {appRole?.role?.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className={classes.actionButtons}>
        <Button
          className={cx(classes.actionButton, {
            [classes.actionButtonApproved]: itemStatus === EOrganizationInviteAction.approve,
          })}
          onClick={() => onApprove(id)}
          data-testid="request-list-item-approve-btn"
        >
          <CheckCircleOutline sx={{ mr: 2 }} fontSize="small" />
          {SHARED_TEXT.approveRequest}
        </Button>

        <Button
          className={cx(classes.actionButton, {
            [classes.actionButtonRejected]: itemStatus === EOrganizationInviteAction.reject,
          })}
          onClick={() => onReject(id)}
          data-testid="request-list-item-reject-btn"
        >
          <HighlightOffOutlinedIcon sx={{ mr: 2 }} fontSize="small" />
          {SHARED_TEXT.rejectRequest}
        </Button>
      </Box>
    </div>
  );
};

export default InviteRequestListItem;
