import React, { ErrorInfo, FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from '../../error-boundary/error-boundary';

export const PanelTableLoadingState: React.FC = () => {
  return (
    <Stack sx={{ padding: '30px 30px 0px 30px', gap: '10px' }}>
      <Stack gap={'5px'}>
        <Stack width={'100%'} gap={'10px'} direction={'row'}>
          <Skeleton animation="wave" width="24%" sx={{ transform: 'none' }} />
          <Skeleton animation="wave" width="24%" sx={{ transform: 'none' }} />

          <Skeleton animation="wave" width="24%" sx={{ transform: 'none' }} />

          <Skeleton animation="wave" width="24%" sx={{ transform: 'none' }} />
        </Stack>

        <Stack gap={'5px'}>
          {new Array(16).fill(null).map((_, index) => (
            <Skeleton key={index} animation="wave" sx={{ transform: 'none' }} height={'24px'} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const PanelLoadingState: React.FC = () => {
  return (
    <Stack sx={{ padding: '30px 30px 0px 30px', gap: '20px' }}>
      <Stack gap={'5px'}>
        <Skeleton animation="wave" sx={{ transform: 'none' }} />
        <Skeleton animation="wave" sx={{ transform: 'none' }} />

        <Stack gap={'5px'} sx={{ marginTop: '35px' }}>
          <Skeleton animation="wave" sx={{ transform: 'none' }} width={'80px'} />
          <Skeleton animation="wave" sx={{ transform: 'none' }} height={'60px'} />
          <Skeleton animation="wave" sx={{ transform: 'none' }} height={'60px'} />
          <Skeleton animation="wave" sx={{ transform: 'none' }} height={'60px'} />
          <Skeleton animation="wave" sx={{ transform: 'none' }} height={'60px'} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const PanelErrorState: React.FC<{ onRetry?: () => void }> = ({ onRetry }) => {
  return (
    <Stack sx={{ display: 'grid', placeItems: 'center', padding: '30px', gap: '10px' }}>
      <Typography fontWeight={'bold'}>
        <FormattedMessage
          id="shared-panel-components.error-state.title"
          defaultMessage="Something went wrong"
          description="Panel Component, error state, title"
        />
      </Typography>
      <Typography variant="caption">
        <FormattedMessage
          id="shared-panel-components.error-state.subtitle"
          defaultMessage="Please refresh or try again later."
          description="Panel Component, error state, subtitle"
        />
      </Typography>
      {onRetry && (
        <Button variant={'contained'} onClick={onRetry}>
          <FormattedMessage
            id="shared-panel-components.error-state.retry-btn"
            defaultMessage="Retry"
            description="Panel Component, error state, retry button"
          />
        </Button>
      )}
    </Stack>
  );
};

export interface IPanelContentProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  isLoading?: boolean;
  isErrored?: boolean;
  LoadingStateElement?: JSX.Element;
  ErrorStateElement?: JSX.Element;
  fallbackUI?: React.ReactNode;
  onDidCatchError?: (error: Error, errorInfo: ErrorInfo) => void;
  testId?: string;
  onRetry?: () => void;
}

export const PanelContent: FC<PropsWithChildren<IPanelContentProps>> = ({
  children,
  style = {},
  isLoading = false,
  isErrored = false,
  LoadingStateElement = null,
  ErrorStateElement = null,
  fallbackUI = null,
  onDidCatchError,
  testId = 'panel-content',
  onRetry,
}) => {
  const renderChildren = () => {
    if (!isLoading && !isErrored) {
      return children;
    }

    if (isLoading && !isErrored) {
      if (LoadingStateElement) {
        return LoadingStateElement;
      } else {
        return <PanelLoadingState />;
      }
    }

    if (isErrored) {
      if (ErrorStateElement) {
        return ErrorStateElement;
      } else {
        return <PanelErrorState onRetry={onRetry} />;
      }
    }
  };

  return (
    <Box
      data-testid={testId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 auto',
        boxSizing: 'border-box',
        overflowY: 'auto',
        ...style,
      }}
    >
      <ErrorBoundary fallbackUI={fallbackUI} onDidCatchError={onDidCatchError}>
        {renderChildren()}
      </ErrorBoundary>
    </Box>
  );
};
