import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(4, 4),
      textAlign: 'center',
      border: '0.5px solid #D5DFE9',
      borderRadius: theme.spacing(2),
    },
    text: {
      marginTop: theme.spacing(3),
    },
    icon: {
      display: 'inline !important',
    },
    loadingText: {
      display: 'flex',
      transform: 'translateX(-20px)',
    },
    menuItem: {
      margin: theme.spacing(1),
      '&:hover': {
        backgroundColor: '#EEF3F9',
      },
    },
    dialogBtn: {
      textTransform: 'capitalize',
    },
    dialogTitle: {
      padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(2)}`,
    },
    dialogContent: {
      padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
    },
    dialogTitleText: {
      fontWeight: 600,
    },
    dialogAction: {
      padding: theme.spacing(4),
    },
    cancelButton: {
      color: '#2A323C',
    },
    orgList: {
      overflowY: 'auto',
      marginTop: '10px',
      height: 'calc(100vh - 470px)',
    },
  };
});
export default useStyles;
