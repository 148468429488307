import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../../constants';
import { LoadingStatus } from '@aiware/js/interfaces';
import { PAGE_LIMIT, TDataRegistriesQueryInput, TDataRegistry } from '../../types';

export const namespace = 'dataRegistry';

export type UIState = {
  status: LoadingStatus;
  SDOs: TDataRegistry[];
  hasMore: boolean;
  offset: number;
  limit: number;
  sdoViewerPanel: {
    dataRegistry: {
      status: LoadingStatus;
      id: string;
      dataRegistryObj: TDataRegistry | null;
    };
    activeSchemaId: string;
  };
  [key: string]: any;
};

export const initialState: UIState = {
  status: 'loading',
  SDOs: [],
  hasMore: true,
  offset: 0,
  limit: PAGE_LIMIT,
  sdoViewerPanel: {
    dataRegistry: {
      status: 'pending',
      id: '',
      dataRegistryObj: null,
    },
    activeSchemaId: '',
  },
};

export const sdoSlice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    setActiveSchemaId: (state, action: PayloadAction<string>) => {
      state.sdoViewerPanel.activeSchemaId = action.payload;
    },
    fetchDataRegistryStart(state, action: PayloadAction<string>) {
      state.sdoViewerPanel.dataRegistry.status = 'pending';
      state.sdoViewerPanel.dataRegistry.id = action.payload;
    },
    fetchDataRegistrySuccess(state, { payload }: PayloadAction<TDataRegistry>) {
      if (payload) {
        state.sdoViewerPanel.dataRegistry.dataRegistryObj = payload;
        state.sdoViewerPanel.dataRegistry.status = 'success';

        if (state.sdoViewerPanel.dataRegistry?.dataRegistryObj?.schemas?.records?.length) {
          state.sdoViewerPanel.activeSchemaId =
            state.sdoViewerPanel.dataRegistry.dataRegistryObj.schemas?.records[0]?.id || '';
        }
      }
    },
    resetDataRegistry(state) {
      state.sdoViewerPanel.dataRegistry = { ...initialState.sdoViewerPanel.dataRegistry };
      state.sdoViewerPanel.activeSchemaId = '';
    },
    fetchDataRegistryFailure(state) {
      state.sdoViewerPanel.dataRegistry.status = 'failure';
    },
    fetchSDOsStart(state) {
      state.status = 'loading';
    },
    fetchSDOsSuccess(
      state,
      action: PayloadAction<{ SDOs: TDataRegistry[]; hasMore: boolean; offset: number }>
    ) {
      const { SDOs, hasMore, offset } = action.payload;
      state.status = 'success';
      state.SDOs = [...state.SDOs, ...SDOs];
      state.hasMore = hasMore;
      state.offset = offset;
    },
    fetchSDOsFailure(state) {
      state.status = 'failure';
    },
  },
});

export const actions = sdoSlice.actions;
export default sdoSlice.reducer;
