// import facebookIcon from '../../../resources/images/company_facebook.png';
// import googleIcon from '../../../resources/images/company_google.png';
// import spotifyIcon from '../../../resources/images/company_spotify.png';
// import images from '../../../resources/imagePath';

export const mockOrganizations = [
  // {
  //   title: 'Apple, Inc.',
  //   srcImg: images.appleCompanyIcon,
  // },
  // {
  //   title: 'Cisco Systems',
  //   srcImg: images.ciscoCompanyIcon,
  // },
];

export const mockPendingOrganizations = [
  // {
  //   title: 'Organization Name Four',
  //   srcImg: facebookIcon,
  // },
  // {
  //   title: 'Organization Name Five',
  //   srcImg: googleIcon,
  // },
  // {
  //   title: 'Organization Name Six',
  //   srcImg: spotifyIcon,
  // },
];
