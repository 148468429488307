import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void>()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px',
  },
  image: {
    maxWidth: '50%',
    marginBottom: '40px',
    marginTop: '25px',
    userSelect: 'none',
    pointerEvents: 'none',
    userDrag: 'none',
  },
  header: {
    fontWeight: 600,
    marginBottom: '12px',
    cursor: 'default',
  },
  description: {
    opacity: 0.8,
  },
}));

export default useStyles;
