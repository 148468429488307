import { useState, useEffect, FC, CSSProperties } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { FormattedMessage, injectIntl, WithIntlProps } from 'react-intl';
import AppIcon from '../../atoms/AppIcon';
import { useStyles } from './app-list-dnd.styles';
import { Grid, TextField, IconButton, InputAdornment, Tooltip, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { SortArrow } from '@aiware/shared/icons';
import { translatableTextProps } from '../../../helper/shared-text';
import { IApplication } from '../../../types';

import {
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import NaturalDragAnimation from 'natural-drag-animation-rbdnd';

interface IAppListProps {
  intl: any;
  selectedAppId: string | number | null;
  onSelectApplication: (id: string | number) => void;
  title: string;
  applications: IApplication[];
  row?: number;
  type?: string;
  showAppGroups: boolean;
}

export type TSortOrder = 'asc' | 'desc';

function AppListDnD({
  intl,
  title,
  selectedAppId,
  onSelectApplication,
  applications = [],
  showAppGroups = false,
  row = 0,
}: IAppListProps) {
  const [currentSelected, setSelected] = useState(selectedAppId);
  const [hashMap, setHashMap] = useState<{ [key: string]: IApplication[] }>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('asc');
  const hasApps = applications.length !== 0;

  const onSearchChange = (ev: any) => {
    setSearchTerm(ev.target.value);
  };

  useEffect(() => {
    if (applications.length > 0) {
      const shallowCpApp = [...applications];
      const processedApps = shallowCpApp.sort(sortAlphabetically);
      const sortedHash: { [key: string]: IApplication[] } = {};
      for (const processedApp of processedApps) {
        const group = processedApp.name.charAt(0).toUpperCase();
        if (!sortedHash[group]) {
          sortedHash[group] = [processedApp];
        } else {
          sortedHash[group] = [...sortedHash[group]!, processedApp];
        }
      }
      setHashMap(sortedHash);
    }
  }, [applications]);
  useEffect(() => {
    if (selectedAppId !== currentSelected) {
      setSelected(selectedAppId);
    }
  }, [selectedAppId, currentSelected]);

  function handleSelectApplication(id: string | number) {
    setSelected(id);
    onSelectApplication(id);
  }

  function sortAlphabetically(a: { name: string }, b: { name: string }) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  }

  const { searchLabel } = translatableTextProps(intl);

  const switchSortOrder = (order: TSortOrder) => {
    if (order !== sortOrder) {
      setSortOrder(order);
      const reversedKeys = Object.keys(hashMap).reverse();
      const reversedHash = {};
      reversedKeys.forEach(key => ((reversedHash as any)[key] = hashMap[key]!.reverse()));
      setHashMap(reversedHash);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const sortMenuOpen = (event: any) => {
    if (!applications.length) return;
    setAnchorEl(event.currentTarget);
  };
  const sortMenuClose = (order: TSortOrder) => {
    switchSortOrder(order);
    setAnchorEl(null);
  };

  const { classes } = useStyles({ sortOrder, hasApps, menuIsOpen: open });

  const sortMenu = (
    <Menu id="app-switcher.sort-menu" anchorEl={anchorEl} open={open} onClose={sortMenuClose}>
      <MenuItem onClick={() => sortMenuClose('asc')} className={classes.sortMenu}>
        A - Z
      </MenuItem>
      <MenuItem onClick={() => sortMenuClose('desc')} className={classes.sortMenu}>
        Z - A
      </MenuItem>
    </Menu>
  );

  const draggableClone = (id: string | number, iconUrl: string, name: string) => (
    <AppIcon
      type="list"
      selected={id === currentSelected}
      onClick={handleSelectApplication}
      iconUrl={iconUrl}
      id={id}
      appName={name}
      appListV2={true}
    />
  );

  const draggableItem = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    style: DraggingStyle | NotDraggingStyle | CSSProperties | undefined,
    id: string | number,
    iconUrl: string,
    name: string
  ) => (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className={snapshot.isDragging ? classes.airborne : ''}
      style={style}
    >
      {draggableClone(id, iconUrl, name)}
    </div>
  );

  return (
    <div className={classes.root} data-testid="os-app-bar-panel.app-switcher.applications-container">
      <div className={classes.header}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography variant="body2" className={classes.title}>
              <FormattedMessage
                id="os-app-bar-panel.app-switcher.applications-title"
                defaultMessage="Applications"
                description="Applications list title"
              />
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.searchBox}>
            <TextField
              data-testid="os-app-bar-panel.app-switcher.search"
              onChange={onSearchChange}
              placeholder={searchLabel}
              InputProps={{
                classes: {
                  root: classes.searchField,
                  input: classes.searchInput,
                  notchedOutline: classes.searchOutline,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.filterIcon}>
            <Tooltip
              title={
                <FormattedMessage
                  id="os-app-bar-panel.app-switcher.sort-btn"
                  defaultMessage="Sort"
                  description="Application sort button"
                />
              }
            >
              <div className={classes.filterIconWrapper} onClick={sortMenuOpen}>
                <div className={classes.filterIcon}>
                  <SortArrow data-testid="os-app-bar-panel.app-switcher.sort" />
                </div>
              </div>
            </Tooltip>
            {sortMenu}
          </Grid>
        </Grid>
      </div>
      <div className={classes.applicationList}>
        {applications.length === 0 ? (
          <div>
            <Typography variant="body2">
              <FormattedMessage
                id="os-app-bar-panel.dyhBih"
                defaultMessage="No Applications"
                description="the label no app will show if we have no application for list view"
              />
            </Typography>
          </div>
        ) : (
          <Droppable droppableId="app-list">
            {(provided: DroppableProvided) => (
              <div className={classes.groupContainer} {...provided.droppableProps} ref={provided.innerRef}>
                {Object.keys(hashMap).length > 0 &&
                  Object.entries(hashMap).map(([key, entry]) =>
                    entry.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .length ? (
                      <div key={key}>
                        <div
                          className={classes.groupTitle}
                          data-testid="os-app-bar-panel.app-switcher.group-title"
                        >
                          <Typography variant="overline">{key}</Typography>
                        </div>
                        {entry
                          .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                          .map((item, index) => {
                            const { id = index, iconUrl, name } = item;
                            return (
                              <div key={id} data-testid="os-app-bar-panel.app-switcher.app-row">
                                {showAppGroups ? (
                                  <Draggable draggableId={id.toString()} index={index} key={index}>
                                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                      <NaturalDragAnimation
                                        style={provided.draggableProps.style}
                                        snapshot={snapshot}
                                      >
                                        {(
                                          style: DraggingStyle | NotDraggingStyle | undefined | CSSProperties
                                        ) => draggableItem(provided, snapshot, style, id, iconUrl, name)}
                                      </NaturalDragAnimation>
                                    )}
                                  </Draggable>
                                ) : (
                                  draggableClone(id, iconUrl, name)
                                )}
                                <Divider />
                              </div>
                            );
                          })}
                      </div>
                    ) : null
                  )}
              </div>
            )}
          </Droppable>
        )}
      </div>
    </div>
  );
}

export default injectIntl(AppListDnD) as FC<WithIntlProps<IAppListProps>>;
