import createSvgIcon from '../createSvgIcon';
export const ScheduleBulk = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4423 18.727V15.9617H17.5577V19.0848L19.65 21.1771L20.2731 20.5541L18.4423 18.727ZM11.8462 4.08676L5.82498 7.56947L12 11.1387L18.175 7.56947L12.1539 4.08676C12.1026 4.05471 12.0513 4.03869 12 4.03869C11.9487 4.03869 11.8974 4.05471 11.8462 4.08676ZM3.50003 15.7925V8.20792C3.50003 7.87971 3.57912 7.57912 3.73731 7.30614C3.89547 7.03314 4.11766 6.81322 4.40388 6.64639L11.0962 2.79449C11.25 2.71116 11.3979 2.6493 11.5399 2.60892C11.6819 2.56853 11.8351 2.54834 11.9995 2.54834C12.1639 2.54834 12.3215 2.56853 12.4721 2.60892C12.6228 2.6493 12.7667 2.71116 12.9039 2.79449L19.5961 6.64639C19.8823 6.81322 20.1045 7.03314 20.2627 7.30614C20.4209 7.57912 20.5 7.87971 20.5 8.20792V11.9233H19V8.81174L11.975 12.8617L5.00001 8.81174V15.7771C5.00001 15.8284 5.01283 15.8765 5.03848 15.9214C5.06411 15.9662 5.10257 16.0047 5.15386 16.0368L11.3077 19.6079V21.3213L4.40388 17.354C4.11766 17.1872 3.89547 16.9673 3.73731 16.6943C3.57912 16.4213 3.50003 16.1207 3.50003 15.7925ZM18 23.3752C16.7513 23.3752 15.6891 22.9374 14.8135 22.0617C13.9378 21.1861 13.5 20.1239 13.5 18.8752C13.5 17.6265 13.9378 16.5643 14.8135 15.6887C15.6891 14.8131 16.7513 14.3752 18 14.3752C19.2487 14.3752 20.3109 14.8131 21.1865 15.6887C22.0622 16.5643 22.5 17.6265 22.5 18.8752C22.5 20.1239 22.0622 21.1861 21.1865 22.0617C20.3109 22.9374 19.2487 23.3752 18 23.3752Z"
      fill="#555F7C"
    />
  </svg>,
  'ScheduleBulk'
);
