import { ReactElement } from 'react';

export enum DistributionType {
  Private = 'private',
  OrgLocked = 'org_locked',
  Sharable = 'sharable',
  Public = 'public',
  Marketplace = 'marketplace',
  InstanceLocked = 'instance_locked',
}

export type selectionShape = {
  name: string;
  formattedName?: string | ReactElement;
  shortDescription?: string | ReactElement;
  description: string | ReactElement;
  capabilities?: Array<capabilitiesShape>;
  iconClass?: string;
  iconName?: string;
  selectedIconName?: string;
  value?: string;
  link?: string;
  formName?: string;
};

export type capabilitiesShape = {
  name: string;
  id: string;
  description: string;
};
