import cx from 'classnames';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import useStyles from './useStyles';

export interface IColumnType {
  value: string | number;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  label: string;
}

interface IData {
  name: string;
  engineId: string;
  jobId: string;
  status: string;
  duration: string;
}

interface Props {
  columns: IColumnType[];
  data: Array<Array<IData>>;
}

export default function MuiTable({ columns = [], data = [] }: Props) {
  const { classes } = useStyles();

  return (
    <div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((item: IColumnType, key) => (
              <TableCell key={key} classes={{ root: classes.SdkTableCellHeader }} align={item.align}>
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowKey) => (
            <TableRow key={rowKey}>
              {columns.map((item: IColumnType, colIndex) => {
                if (item.value === 'avatar') {
                  return (
                    <TableCell
                      key={colIndex}
                      padding="checkbox"
                      classes={{
                        root: cx(classes.SdkTableCellData, classes.SdkTableCellAvatar),
                      }}
                      align="right"
                    >
                      <Avatar alt="" src={(row as any)[item.value]} className={classes.smallAvatar} />
                    </TableCell>
                  );
                }
                if (item.value === 'status') {
                  return (
                    <TableCell
                      key={colIndex}
                      padding="checkbox"
                      classes={{ root: classes.SdkTableCellData }}
                      align={item.align}
                    >
                      <span
                        className={
                          (row as any)[item.value] === 'deactived' || (row as any)[item.value] === 'failed'
                            ? classes.SdkTableCellStatusDeactived
                            : classes.SdkTableCellStatus
                        }
                      >
                        {(row as any)[item.value]}
                      </span>
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={colIndex} classes={{ root: classes.SdkTableCellData }} align={item.align}>
                    {(row as any)[item.value]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
