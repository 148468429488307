import { TabPanel } from '@aiware/ui';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import useStyles from './useStyles';
import { FormattedMessage } from 'react-intl';
import Header from '../../header/components/Header';
import { useEffect, useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Fullscreen, FullscreenExit } from '@aiware/shared/icons';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import InputTypeMarkdownControlled from './InputTypeMarkdownControlled';
import ReactMarkdown from 'react-markdown';

interface Props {
  onValidate?: (isValid: boolean) => void;
  data: string;
  idPrefix: string;
  title: string;
  description: string;
  handleChange: React.EventHandler<ChangeEvent<HTMLTextAreaElement>>;
}

export const Markdown = ({ onValidate, data, idPrefix, title, description, handleChange }: Props) => {
  const { classes } = useStyles();
  const [selectedId, setSelectedId] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    onValidate && onValidate(true);
  }, []);

  const onChangeTab = (_event: React.SyntheticEvent, id: number) => {
    setSelectedId(Number(id));
  };
  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };
  const UseCaseComponent = () => {
    return (
      <div className={classes.container} data-test={`${idPrefix}.use-cases-container`}>
        <Box padding={5} paddingBottom={0}>
          <Header
            title={
              <FormattedMessage
                id="markdown.use-cases-title"
                defaultMessage="{title}"
                description="label for use case title"
                values={{ title }}
              />
            }
            description={
              <FormattedMessage
                id="markdown.use-cases-description"
                defaultMessage="{description}"
                description="label for use case description"
                values={{ description }}
              />
            }
            testName={`${idPrefix}.use-cases`}
          />
        </Box>
        <Box data-test={`${idPrefix}-tabs-list`} padding={4} paddingTop={0} paddingBottom={1}>
          <Tabs
            className={classes.tabs}
            value={selectedId}
            onChange={onChangeTab}
            aria-label="icon label tabs example"
          >
            <Tab
              data-test={`${idPrefix}.use-cases-step-markdown-tab`}
              label={
                <FormattedMessage
                  id="markdown.use-cases-step-markdown-tab-label"
                  defaultMessage="Markdown"
                  description="tab label: Markdown"
                />
              }
            />
            <Tab
              data-test={`${idPrefix}.use-cases-step-preview-tab`}
              label={
                <FormattedMessage
                  id="markdown.use-cases-step-preview-tab-label"
                  defaultMessage="Preview"
                  description="tab label: Preview"
                />
              }
            />
          </Tabs>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip
              title={
                isFullScreen ? (
                  <FormattedMessage
                    id="markdown-use-cases-exit-fullscreen"
                    defaultMessage="Exit Fullscreen"
                    description="Exit Fullscreen"
                  />
                ) : (
                  <FormattedMessage
                    id="markdown-use-cases-enter-fullscreen"
                    defaultMessage="Enter Fullscreen"
                    description="Enter Fullscreen"
                  />
                )
              }
            >
              <IconButton
                onClick={handleFullScreenToggle}
                className={classes.fullScreenButton}
                data-test={`${idPrefix}.use-cases-step-fullscreen-toggle`}
                size="large"
              >
                {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box flexGrow={1}>
          <TabPanel value={selectedId} index={0}>
            <div className={classes.markdown}>
              <InputTypeMarkdownControlled value={data} onChange={handleChange} />
            </div>
          </TabPanel>
          <TabPanel value={selectedId} index={1}>
            <div className={classes.previewMarkdown}>
              <div className={!data || data.length <= 0 ? '' : classes.previewMarkdownContent}>
                <ReactMarkdown children={data} />
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    );
  };
  return (
    <div
      data-test={`${idPrefix}.use-cases-step-parent-container`}
      className={isFullScreen ? classNames(classes.root, classes.panel, classes.fullScreen) : ''}
      style={{ height: '100%' }}
    >
      {UseCaseComponent()}
    </div>
  );
};
