import { DateTime } from 'luxon';
import { GraphQLPage, TAuthGroupMember, TAuthGroupMemberEntry, TUser, TUserGroup } from '../types';

export const dateHelper = (value?: string | undefined) => {
  const dateObject = {
    day: 'N/A',
    time: 'N/A',
    full: 'N/A',
  };
  if (value === undefined) {
    return dateObject;
  }
  const date = DateTime.fromISO(value.split(' ').join(''));
  const day = date.toFormat('LL/dd/yyyy');
  const time = date.toFormat('t');

  if (day !== 'Invalid DateTime') {
    dateObject.day = day;
  }
  if (time !== 'Invalid DateTime') {
    dateObject.time = time;
  }
  dateObject.full = `${dateObject.day} ${dateObject.time}`;

  return dateObject;
};

export const memberIsDefined = (member: TAuthGroupMemberEntry | undefined): member is TAuthGroupMemberEntry =>
  !!member;

const memberIsUser = (member: TAuthGroupMemberEntry): member is TUser => 'email' in member;

export const filterDefinedMemberRecords = (group: Partial<TUserGroup> | undefined): TAuthGroupMemberEntry[] =>
  group?.members?.records.map(record => record.member).filter(memberIsDefined) ||
  ([] as TAuthGroupMemberEntry[]);

export const filterUserRecords = (members: GraphQLPage<TAuthGroupMember>): TUser[] =>
  members.records
    .map(record => record.member)
    .filter(memberIsDefined)
    .filter(memberIsUser) || [];

export const mockUserGroups = [
  {
    id: '12345',
    name: 'mock test group',
    description: 'this is not a real group, these are not real members',
    createdAt: '2022-08-09T21:37:33.711Z',
    members: {
      count: 2,
      records: [
        {
          member: {
            id: '111',
            name: 'sumi@veritone.com',
            email: 'sumi@veritone.com',
            firstName: 'Sumi',
            lastName: 'Viswanathan',
            imageUrl: null,
            createdDateTime: '2022-06-10T17:18:45.000Z',
          },
        },
        {
          member: {
            id: '222',
            name: 'rvance@veritone.com',
            email: 'rvance@veritone.com',
            firstName: 'Richard',
            lastName: 'Vance',
            imageUrl: null,
            createdDateTime: '2022-06-10T17:18:45.000Z',
          },
        },
      ],
    },
  } as unknown as TUserGroup,
];
