import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  avatarLogo: {
    width: '24px',
    height: '24px',
  },
  avatarNoLogo: {
    backgroundColor: '#F2F7FE',
    borderRadius: '50%',
    padding: '2px',
  },
  avatarLogoBadge: {
    background: '#F2F7FE',
    color: '#555F7C',
    boxSizing: 'border-box',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    padding: '2px',
    position: 'relative',
    top: '-5px',
    verticalAlign: 'middle',
  },
}));
