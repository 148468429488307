import { Box, BoxProps, Button, Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

export interface IPlaceholderProps {
  ticketId: number;
  label: string;
  boxProps?: BoxProps;
  height?: string | number;
  width?: string | number;
}

export const PlaceholderElement: FC<IPlaceholderProps> = ({
  ticketId,
  boxProps = {},
  height = undefined,
  width = undefined,
  label,
}) => {
  const getDimension = (dimension: string | number | undefined) => {
    if (!dimension) return undefined;
    if (typeof dimension === 'number') return `${dimension}px`;
    return dimension;
  };

  return (
    <Box
      style={{
        background: '#f7f7f7',
        border: '1px solid #ebebeb',
        borderRadius: '10px',
        margin: '5px',
        padding: '5px 0px',
        height: getDimension(height),
        width: getDimension(width),
      }}
      {...boxProps}
    >
      <Stack height={'100%'} gap={'2px'} alignItems={'center'} justifyContent={'center'}>
        <Stack gap={'5px'} alignItems={'center'} justifyContent={'center'} direction={'row'}>
          <Typography
            sx={{
              color: '#0052cc',
              fontWeight: '500',
              fontFamily: 'system-ui',
            }}
            align={'center'}
            variant={'subtitle2'}
          >
            {label}
          </Typography>
          <TodoBadge />
        </Stack>
        <Tooltip title={'Open Ticket in JIRA'}>
          <Button
            target={'_blank'}
            href={`https://veritone.atlassian.net/browse/AWT-${ticketId}`}
            size={'small'}
          >
            <Stack gap={'5px'} alignItems={'center'} justifyContent={'center'} direction={'row'}>
              <StorySVG />
              <Typography
                sx={{ fontFamily: 'system-ui', fontWeight: '500' }}
                variant={'caption'}
                align={'center'}
              >
                AWT-{ticketId}
              </Typography>
            </Stack>
          </Button>
        </Tooltip>
      </Stack>
    </Box>
  );
};

const TodoBadge = () => (
  <div>
    <span
      style={{
        fontSize: '10px',
        fontWeight: 'bold',
        background: '#dfe1e6',
        color: '#42526e',
        borderRadius: '3px',
        padding: '0px 5px',
        pointerEvents: 'none',
        userSelect: 'none',
        fontFamily: 'system-ui',
      }}
    >
      TODO
    </span>
  </div>
);

const StorySVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(1.000000, 1.000000)">
          <rect fill="#63BA3C" x="0" y="0" width="14" height="14" rx="2" />
          <path
            d="M9,3 L5,3 C4.448,3 4,3.448 4,4 L4,10.5 C4,10.776 4.224,11 4.5,11 C4.675,11 4.821,10.905 4.91,10.769 L4.914,10.77 L6.84,8.54 C6.92,8.434 7.08,8.434 7.16,8.54 L9.086,10.77 L9.09,10.769 C9.179,10.905 9.325,11 9.5,11 C9.776,11 10,10.776 10,10.5 L10,4 C10,3.448 9.552,3 9,3"
            id="Page-1"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
);
