import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityType } from '@aiware/js/interfaces';
import {
  Breadcrumb,
  IPageDetail,
  TdoId,
  defaultPage,
  GraphQLPage,
  ITdo,
  LIMIT,
  SourceId,
  ScheduleId,
  ISource,
  ISchedule,
  SLICE_NAME,
} from '../../types';
import { actions as tdoActions } from './common-actions/tdos';

export const namespace = 'viewStreams';

export type State = {
  breadCrumbs: Breadcrumb[];
  sources: IPageDetail<SourceId>;
  schedulesBySource: Record<SourceId, IPageDetail<ScheduleId>>;
  tdosBySchedule: Record<ScheduleId, IPageDetail<TdoId>>;
};

export const initialState: State = {
  breadCrumbs: [{ isRoot: true, id: 'sourcesRoot', name: 'Sources' }],
  sources: { ...defaultPage },
  schedulesBySource: {},
  tdosBySchedule: {},
};

const slice = createSlice({
  name: `${SLICE_NAME}/${namespace}`,
  initialState,
  reducers: {
    addScheduleBySource(state, { payload }: PayloadAction<{ sourceId: string; scheduleId: string }>) {
      const { sourceId, scheduleId } = payload;

      if (state.schedulesBySource[sourceId]) {
        state.schedulesBySource[sourceId]!.ids.push(scheduleId);
      } else {
        state.schedulesBySource[sourceId] = {
          ids: [scheduleId],
          hasNextPage: false,
          offset: 0,
          status: 'idle',
        };
      }
    },
    addToSourceTreeBreadCrumbs(state, { payload }: PayloadAction<Breadcrumb>) {
      state.breadCrumbs.push(payload);

      const { entity, id } = payload;
      if (entity === EntityType.Sources && id && !state.schedulesBySource[id]) {
        state.schedulesBySource[id] = {
          ...defaultPage,
        };
      }

      if (entity === EntityType.Schedules && id && !state.tdosBySchedule[id]) {
        state.tdosBySchedule[id] = {
          ...defaultPage,
        };
      }
    },
    goToSourceTreeBreadCrumb(state, { payload }: PayloadAction<Breadcrumb>) {
      const breadCrumbs = state.breadCrumbs;
      const targetIdx = breadCrumbs.findIndex(breadCrumb => {
        return (
          (payload.isRoot && breadCrumb.isRoot) ||
          (payload.entity === breadCrumb.entity && payload.id === breadCrumb.id)
        );
      });
      if (targetIdx !== -1) {
        state.breadCrumbs.splice(targetIdx + 1);
      }
    },
    sourcesFetchStart(state) {
      if (state.sources.hasNextPage) {
        state.sources.status = 'pending';
      }
    },
    sourcesFetchSucceeded(state, action: PayloadAction<GraphQLPage<ISource>>) {
      const { count, limit = LIMIT, records } = action.payload;
      const ids = records.map(source => source.id);

      state.sources = {
        ...state.sources,
        offset: state.sources.offset + limit,
        hasNextPage: count === limit,
        ids: [...state.sources.ids, ...ids],
        status: 'idle',
      };
    },
    sourcesFetchFailed(state) {
      state.sources.status = 'failure';
    },
    refreshSourcesSchedulesTdos(state) {
      state.breadCrumbs = [{ isRoot: true, id: 'sourcesRoot', name: 'Sources' }];
      state.sources = { ...defaultPage };
      state.schedulesBySource = {};
      state.tdosBySchedule = {};
    },
    schedulesFetchStart(state, { payload }: PayloadAction<SourceId>) {
      const sourceId = payload;

      if (!state.schedulesBySource[sourceId]) {
        state.schedulesBySource[sourceId] = {
          ...defaultPage,
        };
      }

      if (state.schedulesBySource[sourceId]?.hasNextPage) {
        state.schedulesBySource[sourceId]!.status = 'pending';
      }
    },
    schedulesFetchSucceeded(
      state,
      { payload }: PayloadAction<{ sourceId: SourceId; data: GraphQLPage<ISchedule> }>
    ) {
      const {
        sourceId,
        data: { count, records, limit = LIMIT },
      } = payload;
      const ids = records.map(schedule => schedule.id);
      const { schedulesBySource } = state;

      schedulesBySource[sourceId] = {
        ...schedulesBySource[sourceId],
        offset: schedulesBySource[sourceId]!.offset + limit,
        hasNextPage: count === limit,
        ids: [...schedulesBySource[sourceId]!.ids, ...ids],
        status: 'idle',
      };
    },
    schedulesFetchFailed(state, { payload }: PayloadAction<{ sourceId: SourceId; error: string }>) {
      state.schedulesBySource[payload.sourceId]!.status = 'failure';
    },
    removeScheduleSource(state, { payload }: PayloadAction<{ sourceId: SourceId; scheduleId: ScheduleId }>) {
      if (payload.sourceId) {
        if (state.schedulesBySource[payload.sourceId]) {
          const newScheduleIds = state.schedulesBySource[payload.sourceId]!.ids.filter(
            id => id !== payload.scheduleId
          );
          state.schedulesBySource[payload.sourceId]!.ids = newScheduleIds;
        }
      }
    },
    tdosFetchStart(state, { payload }: PayloadAction<ScheduleId>) {
      const scheduleId = payload;

      if (!state.tdosBySchedule[scheduleId]) {
        state.tdosBySchedule[scheduleId] = {
          ...defaultPage,
        };
      }
      if (state.tdosBySchedule[scheduleId]!.hasNextPage) {
        state.tdosBySchedule[scheduleId]!.status = 'pending';
      }
    },
    tdosFetchSucceeded(
      state,
      { payload }: PayloadAction<{ scheduleId: ScheduleId; data: GraphQLPage<ITdo> }>
    ) {
      const {
        scheduleId,
        data: { count, records },
      } = payload;
      const ids = records.map(tdo => tdo.id);
      const { tdosBySchedule } = state;

      tdosBySchedule[scheduleId] = {
        ...tdosBySchedule[scheduleId],
        offset: tdosBySchedule[scheduleId]!.offset + LIMIT,
        hasNextPage: count === LIMIT,
        ids: [...tdosBySchedule[scheduleId]!.ids, ...ids],
        status: 'idle',
      };
    },
    tdosFetchFailed(state, { payload }: PayloadAction<{ scheduleId: ScheduleId; error: string }>) {
      state.tdosBySchedule[payload.scheduleId]!.status = 'failure';
    },
  },
  extraReducers: builder => {
    builder.addCase(tdoActions.deleteTdoSucceeded, (state, { payload }) => {
      const scheduleId = payload.parentScheduleId;

      if (scheduleId && state.tdosBySchedule[scheduleId]) {
        state.tdosBySchedule[scheduleId] = {
          ...defaultPage,
        };
      }
    });
  },
});

export const actions = slice.actions;
export default slice.reducer;
