import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FC } from 'react';
import { EngineFieldPicklistOptionType } from '../engineComponentLoader.types';

interface ISelectList {
  id: string;
  label: string;
  value?: string;
  required: boolean;
  options?: EngineFieldPicklistOptionType[];
  onChange: (value: any) => void;
  error?: boolean;
}

export const SelectList: FC<ISelectList> = ({ id, value, required, options, onChange, error = false }) => {
  return (
    <Select
      id={id}
      required={required}
      value={value ?? null}
      onChange={onChange}
      variant="outlined"
      style={{ width: '100%' }}
      error={error}
      inputProps={{
        name: id,
        id,
      }}
      sx={{
        width: '100%',
      }}
    >
      {options?.map(option => {
        return (
          <MenuItem key={option?.value} value={option?.value}>
            {option?.key}
          </MenuItem>
        );
      })}
    </Select>
  );
};
