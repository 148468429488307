import { memo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { Divider, DividerProps as DProps, CircularProgress } from '@mui/material';
import { FixedSizeList as List, FixedSizeListProps as ListProps, areEqual } from 'react-window';

export interface VirtualFixedSizeChildProps<T = any> {
  item: T;
  index: number;
  isLast: boolean;
}

export interface VirtualFixedSizeListProps<T = any> {
  testId?: string;
  items: T[];
  memoized?: boolean;
  divider?: boolean | DProps;
  itemSize: ListProps['itemSize'];
  children: ({ index, item, isLast }: VirtualFixedSizeChildProps<T>) => React.ReactNode;
  ListProps?: Omit<
    React.ComponentProps<typeof List>,
    'itemSize' | 'itemCount' | 'itemData' | 'height' | 'width'
  >;
  InfiniteLoaderProps?:
    | false
    | (Omit<
        React.ComponentProps<typeof InfiniteLoader>,
        'children' | 'itemCount' | 'isItemLoaded' | 'loadMoreItems'
      > & {
        hasMore: boolean;
        loading: boolean;
        loadMoreItems: () => void;
      });
  AutoSizerProps?: React.ComponentProps<typeof AutoSizer>;
}

export function VirtualFixedSizeList<T = any>({
  testId,
  items,
  itemSize,
  children,
  divider = false,
  memoized = false,
  InfiniteLoaderProps = false,
  ...props
}: Readonly<VirtualFixedSizeListProps<T>>) {
  const testIdProp = testId || 'virtual-list';

  const infiniteLoaderRef = useRef<{ _listRef: List }>(null);
  const [currentScrollStartIndex, setCurrentScrollStartIndex] = useState(0);
  const isItemLoaded = (index: number) =>
    InfiniteLoaderProps && (!InfiniteLoaderProps.hasMore || index < items.length);

  const AutoSizerProps = props?.AutoSizerProps ?? {};
  const ListProps = props?.ListProps ?? {};

  const DividerProps = typeof divider === 'object' ? divider : {};
  const dividerEnabled = typeof divider === 'boolean' ? divider : true;

  const debouncedHandleScrollIndexChange = debounce(setCurrentScrollStartIndex, 500);

  const additionalOnItemsRendered = (index: number) => {
    if (InfiniteLoaderProps && !InfiniteLoaderProps.loading) {
      debouncedHandleScrollIndexChange(index);
    }
  };

  const Item = ({ index, data, style }: { index: number; data: T[]; style: React.CSSProperties }) => {
    return (
      <div
        data-test={`${testIdProp}-row-${index + 1}`}
        data-testid={`${testIdProp}-row-${index + 1}`}
        style={{ ...style }}
      >
        {InfiniteLoaderProps && !isItemLoaded(index) ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          children({
            index,
            item: data[index]!,
            isLast: index === data.length - 1,
          })
        )}
        {dividerEnabled && index !== data.length - 1 && <Divider {...DividerProps} />}
      </div>
    );
  };

  const RenderList = ({ width, height }: Size) => {
    const RegularList = () => (
      <List
        {...ListProps}
        height={height}
        width={width}
        itemData={items}
        itemSize={itemSize}
        itemCount={items.length}
      >
        {memoized ? memo(Item, areEqual) : Item}
      </List>
    );

    if (InfiniteLoaderProps) {
      return (
        <InfiniteLoader
          {...InfiniteLoaderProps}
          // @ts-ignore
          ref={infiniteLoaderRef}
          isItemLoaded={isItemLoaded}
          itemCount={InfiniteLoaderProps.hasMore ? items.length + 1 : items.length}
          threshold={InfiniteLoaderProps?.threshold ?? 15}
          loadMoreItems={() => {
            if (InfiniteLoaderProps.hasMore && !InfiniteLoaderProps.loading) {
              InfiniteLoaderProps.loadMoreItems();
            }
          }}
        >
          {({ onItemsRendered, ref }) => (
            <List
              {...ListProps}
              ref={ref}
              height={height}
              width={width}
              itemData={items}
              itemSize={itemSize}
              itemCount={items.length}
              initialScrollOffset={0}
              onItemsRendered={props => {
                onItemsRendered(props);
                additionalOnItemsRendered(props.visibleStartIndex);
              }}
            >
              {memoized ? memo(Item, areEqual) : Item}
            </List>
          )}
        </InfiniteLoader>
      );
    }

    return <RegularList />;
  };

  return (
    <AutoSizer
      data-test={testIdProp}
      data-testid={testIdProp}
      {...AutoSizerProps}
      onScroll={() =>
        infiniteLoaderRef &&
        infiniteLoaderRef.current?._listRef.scrollToItem(currentScrollStartIndex, 'start')
      }
    >
      {RenderList}
    </AutoSizer>
  );
}
