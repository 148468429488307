import { call, put, select } from 'redux-saga/effects';
import { actions } from '../../slices';
import * as api from '../../../api';
import { selectApiConfigs } from '../../selectors';
import { ErrorResponseType, IRootFolder } from '../../../types';
import { showMessage, MessageSeverity, preferredLanguageSelector } from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';

export function* rootFolderFetch() {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  //@ts-ignore
  const locale = yield select(preferredLanguageSelector);

  const formatMessage = AIWareFormatMessage(locale);

  try {
    const { orgRootFolder, errors }: { errors: ErrorResponseType; orgRootFolder: IRootFolder } = yield call(
      api.getOrgCmsRootFolder,
      apiConfigs
    );

    if (orgRootFolder) {
      console.log('orgRootFolder', orgRootFolder);
      orgRootFolder.isRoot = true;
      yield put(
        actions.rootFolderState.rootFolderFetchSucceed({
          rootFolder: orgRootFolder,
        })
      );
    }

    if (errors) {
      const errorMessages = Array.isArray(errors)
        ? errors?.map(error => error.message).join('\n')
        : formatMessage({
            id: 'os-data-center-importer.snackbar.locationFolderFetchError',
            defaultMessage: 'Error during the root folder fetch!',
            description: 'The error message pops up when location folder fetch fails',
          });

      yield put(
        showMessage({
          content: errorMessages,
          severity: MessageSeverity.Error,
        })
      );
      yield put(actions.rootFolderState.rootFolderFetchFailed());
    }
  } catch (error) {
    yield put(actions.rootFolderState.rootFolderFetchFailed());
  }
}
