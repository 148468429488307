import { Close } from '@aiware/shared/icons';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import { SHARED_TEXT } from '../../../helpers/shared-text';
import useStyles from './useStyles';
import FileItemType, { IFileTypeItem } from './FileTypeItem';

interface Props {
  fileTypes: IFileTypeItem[];
  hidePanel?: () => void;
}

const SupportedFileTypesContent: FunctionComponent<Props> = ({ fileTypes, hidePanel }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.supportedFilesTypeWrapper}>
      <div className={classes.supportedFilesTypeHeader}>
        <Typography variant="h1" color="secondary" data-test="data-center-importer-supported-files-title">
          {SHARED_TEXT.supportedFilesTitle()}
        </Typography>

        <Tooltip title={SHARED_TEXT.closePanel()}>
          <IconButton
            onClick={hidePanel}
            aria-labelledby="data-center-importer-close-panel"
            data-test="data-center-importer-types-close-panel"
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      </div>

      {fileTypes && fileTypes.map((fileType, i) => <FileItemType key={i} fileItemType={fileType} />)}
    </div>
  );
};

export default SupportedFileTypesContent;
