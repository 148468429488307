import { Stack, Button, Box, TextField, FormControl } from '@mui/material';
import { AuditLogWidgetHeader } from './components/Header';
import AuditLogsTable from './components/table/table';
import { FC, useEffect, useState } from 'react';
import { dropdownFilter, dateRangeFilter } from '@aiware/shared/reusable-utils';
import { actions } from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, MessageSeverity } from '@aiware/shared/redux';
import { EAuditEventName, EAuditLogFilterType } from '../types/auditLog.types';
import { preferredLanguageSelector, selectApiConfigs, userSelector } from '@aiware/shared/redux';
import { selectAdminCenterOrgIdString } from '@aiware/os/admin-center/shared';
import { makeStyles } from 'tss-react/mui';
import { selectors as auditLogSelectors } from '../redux/auditLog.redux';
import { SHARED_TEXT } from './helpers/shared-text';
import { toTitleCase } from './helpers';
import {
  fetchEventNames,
  createAuditLogExportRequest,
  getAuditLogExportRequest,
} from './api/queries/auditLog.query';
import ExportLogsSnackbar from './components/ExportLogsSnackbar';

interface IAuditLogWidgetContainerProps {
  isSingleUserMode?: boolean;
}

export type Filter = {
  [key in EAuditLogFilterType]?: any;
};

export const filterStyles = makeStyles()(_theme => ({
  label: {
    fontWeight: 700,
    fontSize: 14,
    color: '#2A323C',
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const isValidEmail = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const FilterGroup = ({
  isSingleUserMode,
  currentOrgId,
}: {
  isSingleUserMode?: boolean;
  currentOrgId: string;
}) => {
  const { classes } = filterStyles();
  const dispatch = useDispatch();
  const locale: string = useSelector(preferredLanguageSelector);
  const loggedInUser = useSelector(userSelector);
  const apiConfigs = useSelector(selectApiConfigs);
  const [filterNames, setFilterNames] = useState<string[]>([
    'All',
    ...Object.values(EAuditEventName).map(toTitleCase).sort(),
  ]);
  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    const fetchAvailableEventNames = async () => {
      try {
        const dynamicEventNames = await fetchEventNames(apiConfigs);
        if (dynamicEventNames && dynamicEventNames.length > 0) {
          const formattedEventNames = dynamicEventNames.map(toTitleCase).sort((a, b) => a.localeCompare(b));
          setFilterNames(['All', ...formattedEventNames]);
        }
      } catch (e) {
        console.error('Error fetching event names', e);
      }
    };

    fetchAvailableEventNames();
  }, []);

  const getDefaultDateRange = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    return {
      fromDateTime: startDate.toISOString(),
      toDateTime: endDate.toISOString(),
      field: 'createdDateTime',
    };
  };

  const defaultFilters = {
    [EAuditLogFilterType.EventName]: 'All',
    [EAuditLogFilterType.User]: isSingleUserMode ? loggedInUser.userName : '',
    [EAuditLogFilterType.DateTime]: getDefaultDateRange(),
  };

  const [localFilters, setLocalFilters] = useState<Filter>(defaultFilters);

  const handleSelectFilter = (value: any, field: string) => {
    setLocalFilters(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleApplyFilters = (updatedFilters = localFilters) => {
    if (updatedFilters[EAuditLogFilterType.User]) {
      if (!isValidEmail(updatedFilters[EAuditLogFilterType.User])) {
        setEmailError(true);
        return;
      }
      setEmailError(false);
    }

    dispatch(actions.auditLog['clearFilters']!(null));
    Object.keys(updatedFilters).forEach(filterType => {
      if (updatedFilters[filterType as EAuditLogFilterType]) {
        if (
          !(
            (filterType as EAuditLogFilterType) === EAuditLogFilterType.EventName &&
            updatedFilters[filterType as EAuditLogFilterType] === 'All'
          )
        ) {
          dispatch(
            actions.auditLog['setFilter']!({
              type: filterType as EAuditLogFilterType,
              values: updatedFilters[filterType as EAuditLogFilterType],
            })
          );
        }
      }
    });

    dispatch(actions.auditLog['resetList']!(null));
    dispatch(actions.auditLog['readStart']!({ organizationId: currentOrgId }));
  };

  const handleClearFilters = () => {
    setLocalFilters(defaultFilters);
    handleApplyFilters(defaultFilters); //  to set the default filters, if any (single user mode), and refetch
  };

  return (
    <Box
      data-test="audit-log-filters-section"
      sx={{
        flexDirection: 'column',
        gap: '15px',
        borderBottom: '1px solid #E0E0E0',
        padding: '20px',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" data-test="audit-log-filters.group">
        <FormControl sx={{ marginRight: '12px' }}>
          <label htmlFor="audit-log-filters-user-input" className={classes.label}>
            {SHARED_TEXT.table.header.user}
          </label>
          <TextField
            id="audit-log-filters-user-input"
            type="email"
            value={localFilters[EAuditLogFilterType.User] || ''}
            onChange={e => handleSelectFilter(e.target.value, EAuditLogFilterType.User)}
            error={emailError}
            helperText={emailError ? 'Please enter a valid email address' : ''}
            inputProps={{
              style: { padding: '2px 7px', fontSize: '10px', height: '26px', width: '200px' },
              placeholder: 'Enter email address',
            }}
            FormHelperTextProps={{
              sx: {
                marginLeft: 0,
                marginTop: '3px',
              },
            }}
            disabled={!!isSingleUserMode}
          />
        </FormControl>
        <Box
          sx={{
            '& .Sdk-MuiFormControl-root .Sdk-MuiInputBase-root': {
              width: '180px',
            },
          }}
        >
          {dropdownFilter.jsx(
            localFilters[EAuditLogFilterType.EventName] || '',
            EAuditLogFilterType.EventName,
            SHARED_TEXT.table.header.eventType as unknown as string,
            filterNames,
            handleSelectFilter
          )}
        </Box>

        <dateRangeFilter.jsx.DateRangeFilter
          dateTimeFilter={{
            fromDateTime: localFilters[EAuditLogFilterType.DateTime]?.fromDateTime,
            toDateTime: localFilters[EAuditLogFilterType.DateTime]?.toDateTime,
            field: 'createdDateTime',
          }}
          setFilterValue={({ value }) => {
            handleSelectFilter(value, EAuditLogFilterType.DateTime);
          }}
          locale={locale}
          shouldDispatchActions={false}
          clearAndApply={() => {
            handleSelectFilter({}, EAuditLogFilterType.DateTime);
          }}
          dateRangeLimit={365}
        />
      </Stack>
      <Box sx={{ display: 'flex', gap: '15px', marginTop: '10px' }}>
        <Button
          variant="outlined"
          onClick={() => handleApplyFilters()}
          data-test="audit-logs.apply-filters.btn"
        >
          {SHARED_TEXT.filters.apply}
        </Button>
        <Button data-test="audit-logs.clear-filters.btn" onClick={handleClearFilters}>
          {SHARED_TEXT.filters.clear}
        </Button>
      </Box>
    </Box>
  );
};

export const AuditLogWidgetContainer: FC<IAuditLogWidgetContainerProps> = ({ isSingleUserMode }) => {
  const [filtersSegmentOpen, setFiltersSegmentOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const loggedInUser = useSelector(userSelector);
  const filters = useSelector(auditLogSelectors.selectFilters);
  const orgId = useSelector(selectAdminCenterOrgIdString);
  const apiConfigs = useSelector(selectApiConfigs);
  const [exportLogsSnackbarOpen, setExportLogsSnackbarOpen] = useState<boolean>(false);
  const currentOrgId = orgId || loggedInUser?.organization?.id?.toString() || '';

  const downloadFile = (url: string, name: string) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          link.href = objectUrl;
          link.download = name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const handleClickDownload = async () => {
    const res = await createAuditLogExportRequest({
      apiConfigs,
      filters,
      organizationId: isSingleUserMode ? loggedInUser?.organization?.id?.toString() || orgId : '',
    });
    setExportLogsSnackbarOpen(true);

    if (!res || !res.id) {
      console.error('Failed to create export request');
      return;
    }
    // start a loop to check if the export is ready using getAuditLogExportRequest API
    const checkExportStatus = async (id: string) => {
      const response = await getAuditLogExportRequest({
        apiConfigs,
        id,
      });

      // if the export is completed and the downloadUrl is not the default s3 url, then download the file
      if (
        response.status === 'COMPLETED' &&
        response.downloadUrl &&
        response.downloadUrl !== 'https://s3.amazonaws.com/'
      ) {
        try {
          await downloadFile(response.downloadUrl, 'audit-log.csv');
        } catch (error) {
          console.error('Failed to download file', error);
        }

        setExportLogsSnackbarOpen(false);
        dispatch(
          showMessage({
            content: 'Your download is ready',
            severity: MessageSeverity.Success,
          })
        );
      } else if (response.status === 'FAILED' || (response.status === 'COMPLETED' && !response.downloadUrl)) {
        setExportLogsSnackbarOpen(false);
        dispatch(
          showMessage({
            content: 'Your download failed',
            severity: MessageSeverity.Error,
          })
        );
      } else {
        setTimeout(() => checkExportStatus(id), 3000); // check again after 3 seconds
      }
    };

    await checkExportStatus(res.id);
  };

  useEffect(() => {
    if (isSingleUserMode && loggedInUser) {
      dispatch(
        actions.auditLog['setFilter']!({
          type: EAuditLogFilterType.User,
          values: loggedInUser.userName,
        })
      );
      dispatch(actions.auditLog['resetList']!(null));
    }
  }, [isSingleUserMode, loggedInUser]);
  return (
    <Stack height={'100%'}>
      <AuditLogWidgetHeader
        isSingleUserMode={isSingleUserMode}
        filterSegmentOpen={filtersSegmentOpen}
        setFiltersSegmentOpen={setFiltersSegmentOpen}
        onClickDownload={handleClickDownload}
      />
      {filtersSegmentOpen && <FilterGroup isSingleUserMode={isSingleUserMode} currentOrgId={currentOrgId} />}
      {(!isSingleUserMode || (isSingleUserMode && loggedInUser && filters.length > 0)) && (
        <AuditLogsTable currentOrgId={currentOrgId} />
      )}
      <ExportLogsSnackbar open={exportLogsSnackbarOpen} />
    </Stack>
  );
};
