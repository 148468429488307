import createSvgIcon from '../createSvgIcon';
export const InformationFilled = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM13.674 16.5561L13.8146 15.9808C13.7422 16.0149 13.625 16.0543 13.4631 16.0991C13.3011 16.1438 13.1562 16.1662 13.0284 16.1662C12.7557 16.1662 12.5639 16.1214 12.4531 16.0319C12.3423 15.9425 12.2869 15.7741 12.2869 15.527C12.2869 15.429 12.304 15.2841 12.3381 15.0923C12.3722 14.9006 12.4105 14.7301 12.4531 14.5809L12.9773 12.7209C13.0284 12.5504 13.0636 12.3629 13.0827 12.1584C13.1019 11.9538 13.1115 11.8111 13.1115 11.7301C13.1115 11.3381 12.9741 11.0195 12.6992 10.7745C12.4244 10.5295 12.0334 10.407 11.5263 10.407C11.245 10.407 10.9467 10.457 10.6314 10.5572C10.316 10.6573 9.9858 10.7777 9.64062 10.9183L9.5 11.4936C9.60227 11.4552 9.72479 11.4148 9.86754 11.3722C10.0103 11.3295 10.1499 11.3082 10.2862 11.3082C10.5632 11.3082 10.7507 11.3551 10.8487 11.4489C10.9467 11.5426 10.9957 11.7088 10.9957 11.9474C10.9957 12.0795 10.9798 12.2255 10.9478 12.3853C10.9158 12.5451 10.8764 12.7145 10.8295 12.8935L10.3054 14.7599C10.2585 14.9559 10.2244 15.1317 10.2031 15.2873C10.1818 15.4428 10.1712 15.5952 10.1712 15.7443C10.1712 16.1278 10.3129 16.4442 10.5962 16.6935C10.8796 16.9428 11.277 17.0675 11.7883 17.0675C12.1207 17.0675 12.4126 17.0238 12.6641 16.9364C12.9155 16.8491 13.2521 16.7223 13.674 16.5561ZM13.5813 9.0039C13.8263 8.77592 13.9489 8.5 13.9489 8.17613C13.9489 7.85227 13.8263 7.57528 13.5813 7.34517C13.3363 7.11506 13.0412 7 12.696 7C12.3508 7 12.0547 7.11506 11.8075 7.34517C11.5604 7.57528 11.4368 7.85227 11.4368 8.17613C11.4368 8.5 11.5604 8.77592 11.8075 9.0039C12.0547 9.23189 12.3508 9.34588 12.696 9.34588C13.0412 9.34588 13.3363 9.23189 13.5813 9.0039Z"
    />
  </svg>,
  'InformationFilled'
);
