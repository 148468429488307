import * as actions from './action';

interface InitState {
  currentUser: unknown;
  fetched: boolean;
  fetching: boolean;
  error: boolean;
}

export const initialState = {
  currentUser: {},
  fetched: false,
  fetching: false,
  error: false,
};

export interface IAction {
  type: string;
  payload: unknown;
}

export function userReducer(
  state: InitState,
  action: { type: string; payload: unknown }
) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actions.GET_CURRENT_USER_FAIL:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true,
      };
    case actions.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        fetching: false,
        fetched: true,
        error: false,
      };
    case actions.GET_CURRENT_APP_ICON_SUCCESS:
      return {
        ...state,
        appIcon: action.payload,
      };
    default:
      return state;
  }
}
