import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiAutocomplete } from './AIAutocomplete';
import {
  selectActiveView,
  selectIsLoadingStatus,
  selectIsOpen,
  selectSearchTerm,
  selectSuggestionResult,
} from '../../state/aiAutocomplete/aiAutocomplete.selectors';
import { actions } from '../../state/aiAutocomplete/aiAutocomplete.slice';
import { actions as searchActions } from '../../state/aiSearch/aiSearch.slice';
import { EActiveView, ICriterion } from '../../types/aiAutocomplete.types';
import useOnClickOutside from 'use-onclickoutside';
import { TProps } from '.';
import { Box, Grow } from '@mui/material';

export const AiAutocompleteContainer = ({ placeholder }: TProps) => {
  const dispatch = useDispatch();
  const suggestionResult = useSelector(selectSuggestionResult);
  const isLoading = useSelector(selectIsLoadingStatus);
  const isOpen = useSelector(selectIsOpen);
  const searchTerm = useSelector(selectSearchTerm);
  const [shouldClearSearchBar, setShouldClearSearchBar] = useState(false);
  const ref = useRef(null);
  const activeView = useSelector(selectActiveView);

  useOnClickOutside(ref, handleOnOutsideClickDispatch);

  return (
    <div ref={ref}>
      <AiAutocomplete
        suggestionsResult={suggestionResult}
        isLoading={isLoading}
        isOpen={isOpen}
        searchTerm={searchTerm}
        shouldClearSearchBar={shouldClearSearchBar}
        onResetShouldClearSearchBar={() => setShouldClearSearchBar(false)}
        onChange={handleOnChangeDispatch}
        onSearchCallback={handleOnSearchCallback}
      >
        {/* @ts-ignore */}
        <AiAutocomplete.AiSearchBar placeholder={placeholder} />
        <Grow style={{ transformOrigin: 'top center' }} in={isOpen} {...(isOpen ? { timeout: 500 } : {})}>
          <Box
            sx={{
              height: 'auto',
              backgroundColor: '#fff',
              width: '570px',
              border: '1px solid #F2F9FF',
              borderRadius: '4px',
              position: 'absolute',
              top: '60px !important',
              zIndex: 99999,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25) !important',
            }}
          >
            {activeView === EActiveView.suggestions && <AiAutocomplete.AiSuggestions />}
            {activeView === EActiveView.structuredData && <AiAutocomplete.AiStructuredData />}
          </Box>
        </Grow>
      </AiAutocomplete>
    </div>
  );

  function handleOnChangeDispatch(searchTerm: string): void {
    if (searchTerm.trim()) {
      dispatch(actions.setSearchTerm(searchTerm.trim()));
    } else {
      dispatch(actions.clearSuggestionResult());
    }
  }

  function handleOnOutsideClickDispatch(): void {
    if (!isOpen) return;

    dispatch(actions.clearSearchTerm());
    dispatch(actions.clearSuggestionResult());
    dispatch(actions.abortQueries());

    setShouldClearSearchBar(true);
  }

  function handleOnSearchCallback(criteria: ICriterion[]): void {
    if (!criteria || criteria.length === 0) {
      return;
    }
    dispatch(searchActions.setSearchCriteria(criteria));
    dispatch(actions.abortQueries());
    dispatch(searchActions.filesSearchStart());
  }
};
