import { SLICE_NAME, TState, TWallpaper } from '../../types';
import { namespace } from '../slices/aiware-desktop.state';

export interface IState {
  [SLICE_NAME]: {
    [namespace]: TState;
  };
}
export const selectLoadingStatus = (state: IState) => state?.[SLICE_NAME]?.[namespace]?.status;

export const selectUserWallpapers = (state: IState): TWallpaper[] | null =>
  state?.[SLICE_NAME]?.[namespace]?.wallpapers;

export const selectCurrentWallpaper = (state: IState): string =>
  state?.[SLICE_NAME]?.[namespace]?.currentWallpaper ?? '';
