import { LoadingStatus } from '@aiware/js/interfaces';
import { IOrganizationInvitesState, namespace } from '../slices/organizationInvites.state';
import { TOrganizationInvite } from '../../types';
import { SLICE_NAME } from '../../constants';

export const selectPendingOrgInvites = (state: IOrganizationInvitesState): TOrganizationInvite[] => {
  return ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationInvitesState)?.pendingInvites;
};
export const selectPendingOrgInvitesStatus = (state: IOrganizationInvitesState): LoadingStatus => {
  return ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationInvitesState)?.pendingInviteStatus;
};

export const selectOrganizationId = (state: IOrganizationInvitesState): string => {
  return ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationInvitesState)?.organizationId;
};

export const selectOrganizationGuid = (state: IOrganizationInvitesState): string => {
  return ((state as any)?.[SLICE_NAME]?.[namespace] as IOrganizationInvitesState)?.organizationGuid;
};
