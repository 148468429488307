import createSvgIcon from '../createSvgIcon';
export const Process = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.36 2.64C20 2.64 21.36 4 21.36 5.64C21.36 7.29 20 8.64 18.36 8.64C16.71 8.64 15.36 7.29 15.36 5.64C15.36 5.34 15.41 5.06 15.5 4.8C14.43 4.29 13.25 4 12 4C9.87827 4 7.84344 4.84286 6.34315 6.34315C4.84286 7.84344 4 9.87827 4 12L4.04 12.84L2.05 13.05L2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C13.69 2 15.28 2.42 16.67 3.16C17.16 2.83 17.74 2.64 18.36 2.64ZM18.36 4.64C18.0948 4.64 17.8404 4.74536 17.6529 4.93289C17.4654 5.12043 17.36 5.37478 17.36 5.64C17.36 5.90522 17.4654 6.15957 17.6529 6.34711C17.8404 6.53464 18.0948 6.64 18.36 6.64C18.92 6.64 19.36 6.19 19.36 5.64C19.36 5.08 18.92 4.64 18.36 4.64ZM5.64 15.36C7.29 15.36 8.64 16.71 8.64 18.36C8.64 18.66 8.59 18.94 8.5 19.2C9.57 19.71 10.75 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12L19.96 11.16L21.95 10.95L22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.31 22 8.72 21.58 7.33 20.84C6.84 21.17 6.26 21.36 5.64 21.36C4 21.36 2.64 20 2.64 18.36C2.64 16.71 4 15.36 5.64 15.36ZM5.64 17.36C5.08 17.36 4.64 17.81 4.64 18.36C4.64 18.92 5.08 19.36 5.64 19.36C5.90522 19.36 6.15957 19.2546 6.34711 19.0671C6.53464 18.8796 6.64 18.6252 6.64 18.36C6.64 18.0948 6.53464 17.8404 6.34711 17.6529C6.15957 17.4654 5.90522 17.36 5.64 17.36Z" />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M9.23333 9C8.90623 9 8.59253 9.12994 8.36124 9.36123C8.12994 9.59253 8 9.90623 8 10.2333V15.1667H9.23333V12.7H10.4667V15.1667H11.7V10.2333C11.7 9.90623 11.5701 9.59253 11.3388 9.36123C11.1075 9.12994 10.7938 9 10.4667 9H9.23333ZM9.23333 10.2333H10.4667V11.4667H9.23333V10.2333Z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M15.4003 9V10.2333H14.7836V13.9333H15.4003V15.1667H12.9336V13.9333H13.5503V10.2333H12.9336V9H15.4003Z"
    />
  </svg>,
  'Process'
);
