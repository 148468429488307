import { FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './sourceScheduleFilter.styles';
import { muiSelectStyleOverrides, sharedStyles } from '../../shared/aiSearchFilter.styles';
import { AiSearchText, sourceScheduleFilterText } from '../../../../helpers/aiSearch.text';
import {
  Divider,
  Button,
  Popover,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Box,
  Stack,
} from '@mui/material';
import { ScheduleInfiniteList } from './components/ScheduleInfiniteList/ScheduleInfiniteList';
import { SourceSearchInput } from './components/SourceSearchInput/SourceSearchInput';
import { cloneDeep } from 'lodash';
import { getInitialDataSet, LoadingStateList, renderSourceScheduleValue } from './helpers';
import { SourceInfiniteList } from './components/SourceInfiniteList/SourceInfiniteList';
import { TFilterSourceScheduleDataSet } from '../../../../types/aiSearch.types';
import { useDispatch, useSelector } from 'react-redux';
import { selectScheduleEntities, selectSourceEntities } from '../../../../../../redux/selectors/entities';
import {
  selectPageSchedulesBySourceId,
  selectPageSources,
} from '../../../../../../redux/selectors/view-streams';
import {
  selectSearchedSources,
  selectSearchTerm,
  selectHasMore,
  selectIsLoading,
  selectError,
  selectSearchFilters,
} from '../../../../state/aiSearchFilter/aiSearchFilter.selectors';
import { actions, DataCenterState } from '../../../../../../redux/slices';
import { ISource, ISchedule } from '../../../../../../types';

interface ISourceScheduleFilter {
  id: string;
  label: JSX.Element;
  onSaveParentDataSet: (dataset: TFilterSourceScheduleDataSet) => void;
  onClear: () => void;
  parentDataSet: TFilterSourceScheduleDataSet;
}

export const SourceScheduleFilter: FC<ISourceScheduleFilter> = ({
  id,
  label,
  parentDataSet,
  onClear,
  onSaveParentDataSet,
}) => {
  const { classes } = useStyles();
  const { classes: sharedClasses } = sharedStyles();
  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);
  const selectRef = useRef(null);
  // Filter Component State
  const [isLoading, setIsLoading] = useState(true);
  const [childDataSet, setChildDataSet] = useState<TFilterSourceScheduleDataSet>(getInitialDataSet());
  const isSelectionDefault = () => {
    return Object.keys(parentDataSet['SCHEDULES']).length === 0 && parentDataSet['SOURCES'].length === 0;
  };
  useEffect(() => {
    dispatch(actions.viewStreams.sourcesFetchStart());
  }, []);

  // This clears the values when we clear search - because we already have the search filters in redux -
  // when the form is reset these values are then empty so we can set the parent set back to initial state
  // without having to save the childDataSet in redux also
  useEffect(() => {
    if (searchFilters.mediaSourceId?.values.length === 0 && searchFilters.programId?.values.length === 0) {
      setChildDataSet(getInitialDataSet());
    }
  }, [searchFilters.mediaSourceId?.values, searchFilters.programId?.values]);

  /** This function handles closing the dropdown, and is fired whenever the dropdown closes. It
   * will persist the state and save it to the parent container. **/
  const handleSaveDataSet = () => {
    onSaveParentDataSet(childDataSet);
    dispatch(actions.aiSearchFilter.setSourceSearchTerm(''));
  };

  /** This function handles saving the Schedule selection after the user browses the Schedule
   * List and closes that Popper **/
  const handleSaveScheduleSelection = (schedules: ISchedule[]) => {
    // Update the filter component's data set
    const newDataSet = cloneDeep(childDataSet);
    newDataSet['SCHEDULES'][expandedSourceId] = schedules;
    if (newDataSet['SCHEDULES'][expandedSourceId]?.length === 0) {
      delete newDataSet['SCHEDULES'][expandedSourceId];
    }
    // Check if the Source (all checkbox) has been checked
    for (const { id } of newDataSet['SOURCES']) {
      if (id === expandedSourceId && newDataSet['SCHEDULES'][expandedSourceId]?.length) {
        newDataSet['SOURCES'] = newDataSet['SOURCES'].filter((source: ISource) => {
          return source.id !== expandedSourceId;
        });
      }
    }
    // Save the new data set for the Dropdown
    setChildDataSet(newDataSet);
    // Reset the dropdown state to remove the schedule list
    setExpandedSourceId('');
    setAnchorEl(null);
  };

  /** This function handles checking a Source in the list and
   * then adding it to the dataset object. **/
  const handleAddSourceSelection = (source: ISource) => {
    const newDataSet = cloneDeep(childDataSet);
    newDataSet['SOURCES'].push(source);
    setChildDataSet(newDataSet);
  };

  /** This function handles un-checking ( removing ) a Source in the list and
   * thus removing it from the dataset object. **/
  const handleRemoveSourceSelection = (source: ISource) => {
    const newDataSet = cloneDeep(childDataSet);
    newDataSet['SOURCES'] = newDataSet['SOURCES'].filter((sourceObj: ISource) => {
      return sourceObj.id !== source.id;
    });
    setChildDataSet(newDataSet);
  };

  /** This function handles removing a Selection of Schedules from the dataset.
   * Schedules are indexed by ID so we delete that key from the dataset. **/
  const clearSelectedDataSetBySourceId = (sourceId: string | number) => {
    const newDataSet = cloneDeep(childDataSet);
    delete newDataSet['SCHEDULES'][sourceId];
    setChildDataSet(newDataSet);
  };

  // Sources searched Infinite Loader State
  const searchTerm = useSelector(selectSearchTerm);
  const searchedSources = useSelector(selectSearchedSources);
  const sourcesSearchedHasNextPage = useSelector(selectHasMore);
  const sourcesSearchedHasError = useSelector(selectError);
  const sourcesSearchedIsNextPageLoading = useSelector(selectIsLoading);
  const sourcesSearchedLoadNextPage = () => {
    dispatch(actions.aiSearchFilter.sourcesSearchStart());
  };

  // Sources Infinite Loader State
  const sourceEntities = useSelector(selectSourceEntities);
  const sourcePage = useSelector(selectPageSources);
  const sources = sourcePage.ids.map(sourceId => sourceEntities[sourceId]!) || [];
  const sourcesHasError = sourcePage.status === 'failure';
  const sourcesHasNextPage = sourcePage.hasNextPage;
  const sourcesIsNextPageLoading = sourcePage.status === 'pending';
  const sourcesLoadNextPage = () => {
    dispatch(actions.viewStreams.sourcesFetchStart());
  };

  // Popper Menu State for Schedule Selection
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isPopperMenuOpen = Boolean(anchorEl);
  const [expandedSourceId, setExpandedSourceId] = useState<string>('');
  const menuRef = useRef(null);

  // Schedules Infinite Loader State
  const scheduleEntities = useSelector(selectScheduleEntities);
  const schedulesPage = useSelector(state =>
    selectPageSchedulesBySourceId(state as DataCenterState, expandedSourceId)
  );
  const schedulesHasNextPage = schedulesPage?.hasNextPage;
  const schedules =
    schedulesPage?.ids.map((scheduleId: string | number) => scheduleEntities[scheduleId]!) || [];
  const schedulesHasError = schedulesPage?.status === 'failure';
  const schedulesIsNextPageLoading = schedulesPage?.status === 'pending';
  const schedulesLoadNextPage = () => {
    dispatch(actions.viewStreams.schedulesFetchStart(expandedSourceId));
  };
  useEffect(() => {
    if (expandedSourceId) {
      dispatch(actions.viewStreams.schedulesFetchStart(expandedSourceId));
    }
  }, [expandedSourceId]);

  const handleExpandSource = (event: React.MouseEvent<HTMLButtonElement>, sourceId: string) => {
    sourceId && setAnchorEl(menuRef.current);
    setExpandedSourceId(sourceId);
  };

  const handleChangeSearch = (event: any) => {
    const value = event.target.value;
    dispatch(actions.aiSearchFilter.resetSourcesSearch());
    dispatch(actions.aiSearchFilter.setSourceSearchTerm(value));
    dispatch(actions.aiSearchFilter.sourcesSearchStart());
  };

  /** This function resets the dropdown input to default (all) state. This
   * will call the onClear callback. **/
  const handleClear = () => {
    const newDataSet = cloneDeep(getInitialDataSet());
    dispatch(actions.aiSearchFilter.setSourceSearchTerm(''));
    setChildDataSet(newDataSet);
    onClear();
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      <FormControl size="small" className={sharedClasses.formControl}>
        <label htmlFor={id} className={sharedClasses.label}>
          {label}{' '}
          {!isSelectionDefault() && (
            <span onClick={handleClear} className={sharedClasses.clearBtn}>
              {AiSearchText.clearLabelText()}
            </span>
          )}
        </label>
        <Select
          id={id}
          ref={selectRef}
          sx={
            !isSelectionDefault()
              ? { minWidth: '180px', ...muiSelectStyleOverrides }
              : muiSelectStyleOverrides
          }
          variant="outlined"
          multiple={false}
          displayEmpty
          onClick={() => {
            setIsDropdownOpen(prev => !prev);
          }}
          open={false}
          className={sharedClasses.field}
          onOpen={() => {
            setIsLoading(sourcesIsNextPageLoading);
          }}
          renderValue={() => renderSourceScheduleValue(parentDataSet)}
          MenuProps={{
            sx: {
              padding: 0,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            classes: {
              list: classes.removePadding,
              paper: classes.removePadding,
            },
          }}
        ></Select>
      </FormControl>
      <Popover
        elevation={3}
        open={isDropdownOpen}
        anchorEl={selectRef.current}
        onClose={() => {
          if (isPopperMenuOpen) return;

          handleSaveDataSet();
          setIsDropdownOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box>
          <Stack direction={'row'}>
            <Box>
              <div className={classes.dropdownContainer} ref={menuRef}>
                <div className={classes.sourceList}>
                  <MenuItem
                    disableRipple
                    focusRipple={false}
                    sx={{
                      backgroundColor: 'transparent !important',
                      '&:hover': {
                        backgroundColor: 'transparent !important',
                      },
                      padding: 0,
                    }}
                    dense
                    onKeyDown={e => e.stopPropagation()}
                  >
                    <SourceSearchInput onChange={handleChangeSearch} value={searchTerm} />
                  </MenuItem>
                  <Divider />
                  {/*sources with search section*/}
                  {searchTerm && sourcesSearchedHasError && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography align={'center'} variant={'caption'} sx={{ marginTop: '15px' }}>
                        {sourceScheduleFilterText.sourcesErrorMessageText}
                      </Typography>
                      <Button
                        onClick={() => {
                          dispatch(actions.aiSearchFilter.sourcesSearchStart());
                        }}
                        sx={{ marginTop: '20px' }}
                        variant="contained"
                      >
                        {sourceScheduleFilterText.retryButtonText}
                      </Button>
                    </div>
                  )}
                  {searchTerm && !sourcesSearchedHasError && (
                    <div className={`aiware-el `}>
                      <SourceInfiniteList
                        hasNextPage={sourcesSearchedHasNextPage}
                        isNextPageLoading={sourcesSearchedIsNextPageLoading}
                        items={searchedSources}
                        loadNextPage={sourcesSearchedLoadNextPage}
                        selectedDataSet={childDataSet}
                        onClickExpand={handleExpandSource}
                        onClearAll={clearSelectedDataSetBySourceId}
                        onAddSource={handleAddSourceSelection}
                        onRemoveSource={handleRemoveSourceSelection}
                        expandedSourceId={expandedSourceId}
                      />
                    </div>
                  )}
                  {/*sources without search section*/}
                  {!isLoading && !searchTerm && sourcesHasError && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography align={'center'} variant={'caption'} sx={{ marginTop: '15px' }}>
                        {sourceScheduleFilterText.sourcesErrorMessageText}
                      </Typography>
                      <Button
                        onClick={() => {
                          dispatch(actions.viewStreams.sourcesFetchStart());
                        }}
                        sx={{ marginTop: '20px' }}
                        variant="contained"
                      >
                        {sourceScheduleFilterText.retryButtonText}
                      </Button>
                    </div>
                  )}
                  {!isLoading && !searchTerm && !sourcesHasError && (
                    <div className={`aiware-el `}>
                      <SourceInfiniteList
                        hasNextPage={sourcesHasNextPage}
                        isNextPageLoading={sourcesIsNextPageLoading}
                        items={sources}
                        loadNextPage={sourcesLoadNextPage}
                        selectedDataSet={childDataSet}
                        onClickExpand={handleExpandSource}
                        onClearAll={clearSelectedDataSetBySourceId}
                        onAddSource={handleAddSourceSelection}
                        onRemoveSource={handleRemoveSourceSelection}
                        expandedSourceId={expandedSourceId}
                      />
                    </div>
                  )}
                  {isLoading && (
                    <LoadingStateList
                      text={sourceScheduleFilterText.loadingSources}
                      style={{ width: '100%' }}
                    />
                  )}
                  {!!expandedSourceId && <div className={classes.overlay}></div>}
                </div>
              </div>

              <MenuItem
                disabled={!!expandedSourceId || isLoading}
                onClick={() => {
                  handleSaveDataSet();
                  setIsDropdownOpen(false);
                }}
                sx={{
                  justifyContent: 'center',
                  marginTop: '10px',
                  marginRight: '10px',
                  marginLeft: '10px',
                  marginBottom: '5px',
                }}
                value={'custom'}
              >
                {sourceScheduleFilterText.saveSelection}
              </MenuItem>
            </Box>
            {isPopperMenuOpen && (
              <div className={classes.scheduleList}>
                {/*schedules section*/}
                {schedulesHasError && (
                  <div
                    style={{ width: '200px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                  >
                    <Typography align={'center'} variant={'caption'} sx={{ marginTop: '15px' }}>
                      {sourceScheduleFilterText.schedulesErrorMessageText}
                    </Typography>
                    <Button
                      onClick={() => {
                        dispatch(actions.viewStreams.schedulesFetchStart(expandedSourceId));
                      }}
                      sx={{ marginTop: '20px' }}
                      variant="contained"
                    >
                      {sourceScheduleFilterText.retryButtonText}
                    </Button>
                    <Button
                      onClick={() => {
                        setExpandedSourceId('');
                        setAnchorEl(null);
                      }}
                      sx={{ marginTop: '20px' }}
                      variant="contained"
                    >
                      Close
                    </Button>
                  </div>
                )}
                {!schedulesHasError && (
                  <ScheduleInfiniteList
                    hasNextPage={schedulesHasNextPage}
                    isNextPageLoading={schedulesIsNextPageLoading}
                    items={schedules}
                    loadNextPage={schedulesLoadNextPage}
                    onSave={handleSaveScheduleSelection}
                    sourceId={expandedSourceId}
                    selectedDataSet={childDataSet}
                  />
                )}
              </div>
            )}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
