import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import { Close } from '@aiware/shared/icons';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

import { SHARED_TEXT } from '../../helpers/shared-text';
import { useSelector } from 'react-redux';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

const useStyles = makeStyles()(theme => ({
  root: {
    width: 400,
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    '&$fullScreen': {
      height: '100vh',
    },
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 12px',
  },
  fullScreen: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&.with-secondary-title': {
      alignItems: 'flex-start',
    },
  },
  body: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  overlay: {
    backgroundColor: theme.palette.grey[50],
    opacity: `0.5 !important`,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}));

const ActionPanel: FC<
  PropsWithChildren<{
    isOpen: boolean;
    panelTitle: JSX.Element;
    panelTitleSecondary?: JSX.Element;
    className?: string;
    onClose: () => void;
    setIsActive: (isActive: boolean) => void;
  }>
> = ({ isOpen, panelTitle, panelTitleSecondary, className = '', onClose, children, setIsActive }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const fullScreen = useSelector(selectIsFullscreenEnabled);

  return (
    <>
      <Fade in={isOpen} mountOnEnter unmountOnExit>
        <div className={classes.overlay}></div>
      </Fade>
      <Slide
        direction="left"
        in={isOpen}
        mountOnEnter
        unmountOnExit
        onEntered={() => {
          setIsActive(true);
        }}
        onExited={() => setIsActive(false)}
      >
        <div
          className={classNames(className, classes.root, {
            [classes.fullScreen]: fullScreen,
          })}
          data-test="dc-action-panel"
        >
          <div className={classes.container}>
            <div
              className={classNames(classes.header, {
                'with-secondary-title': !!panelTitleSecondary,
              })}
            >
              <div
                style={{
                  marginTop: panelTitleSecondary ? theme.spacing(2.5) : 0,
                }}
              >
                <Typography variant="h1">{panelTitle}</Typography>
                {panelTitleSecondary && (
                  <div style={{ marginTop: theme.spacing(1) }}>
                    <Typography variant="body2">{panelTitleSecondary}</Typography>
                  </div>
                )}
              </div>
              <Typography style={visuallyHidden} id="dc-close-action-panel">
                {SHARED_TEXT.closePanel()}
              </Typography>
              <Tooltip title={SHARED_TEXT.closePanel()}>
                <IconButton
                  onClick={onClose}
                  aria-labelledby="dc-close-action-panel"
                  data-test="dc-close-action-panel"
                  size="large"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </div>

            <div className={classes.body}>{children}</div>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default ActionPanel;
