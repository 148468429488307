import { ISagaModule } from 'redux-dynamic-modules-saga';
import { SLICE_NAME } from '../types';
import { rootReducer } from './slices';
import { rootSaga } from './sagas/rootSaga.saga';

export function getDataCenterBrowseModule(): ISagaModule<typeof rootReducer> {
  return {
    id: SLICE_NAME,
    reducerMap: {
      [SLICE_NAME]: rootReducer,
    },
    sagas: [rootSaga],
    initialActions: [],
  };
}
