import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { emailIcon, notificationBannerIcon, notificationCenterIcon } from '@aiware/shared/assets';
import { FormattedMessage } from 'react-intl';
import NotificationAllowRow from '../NotificationAllowRow';
import { useStyles } from './useStyles';
import {
  IGeneralInterface,
  IAppNotificationAction,
  IUtilityAction,
} from '../../interfaces/notificationInterfaces';
import classNames from 'classnames';

export interface INotificationAllowTable {
  action?: IGeneralInterface<IAppNotificationAction>;
  appId: string;
  utilityActions?: IUtilityAction[];
}

export default function NotificationAllowTable(props: INotificationAllowTable) {
  const { action, appId } = props;
  const { classes } = useStyles();

  const firstColumnClassNames = classNames(classes.tableCell, classes.firstColumn);
  const secondColumnClassNames = classNames(classes.tableCell, classes.secondColumn);
  const thirdColumnClassNames = classNames(classes.tableCell, classes.thirdColumn);

  return (
    <TableContainer>
      <Table aria-label="notification-table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Typography
                variant="h3"
                children={
                  <FormattedMessage
                    id="os-notification-panel.KVIpaj"
                    defaultMessage="Events"
                    description="allow-notification"
                  />
                }
              />
            </TableCell>
            <TableCell align="center" className={firstColumnClassNames}>
              <img src={emailIcon} alt="img-icon" />
            </TableCell>
            <TableCell align="center" className={secondColumnClassNames}>
              <img src={notificationCenterIcon} alt="img-icon" />
            </TableCell>
            <TableCell align="center" className={thirdColumnClassNames}>
              <img src={notificationBannerIcon} alt="img-icon" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {action?.allIds?.map(id => {
            return (
              <NotificationAllowRow
                key={id}
                allowNotificationTitle={action?.byId[id]?.title ?? ''}
                checked={action?.byId[id]?.allow ?? false}
                allowType={action?.byId[id]?.allowType ?? []}
                appId={appId}
                id={id}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
