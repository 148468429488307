import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { GraphQLPage, ILibraryList } from './type';
import { selectApiConfigs } from './selectors';

export async function fetchLibraries(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  offset: number,
  limit: number
) {
  const operationName = 'libraries';
  const query = `
    query ${operationName} {
      ${operationName}(offset: ${offset}, limit: ${limit}) {
        count
        offset
        limit
        records {
          id
          name
          organizationId
          engineModels {
            records {
              id
              engineId
              trainStatus
            }
          }
        }
      }
    }
  `;
  const result = await baseGraphQLApiWithError<{
    [operationName]: GraphQLPage<ILibraryList>;
  }>({
    query,
    operationName,
    ...apiConfigs,
  });
  return result[operationName];
}
