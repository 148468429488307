import { useDispatch, useSelector } from 'react-redux';
import { Stack, Button } from '@mui/material';
import { mountPanel } from '@aiware/js/panel';
import { centerComponents } from '@aiware/shared/reusable-utils';
import { AddGroup } from '@aiware/shared/icons';
import {
  EAuthSubResourceType,
  EPermissionAction,
  useOrganizationPermissions,
} from '@aiware/shared/permissions';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { ADMIN_CENTER_NEW_GROUP_PANEL, PARENT_PANEL_ID, TEST_ID } from '../../../types';
import GroupsTable from './GroupsTable/GroupsTable';
import { SHARED_TEXT } from '../../../helper/shared-text';

const { CenterTabHeading, CenterTabContainer, CenterTabContent } = centerComponents;

export const GroupsPanel = () => {
  return (
    <CenterTabContainer testId={TEST_ID}>
      <CenterTabHeading
        title={SHARED_TEXT.title}
        subtitle={''}
        testId={TEST_ID}
        HeadingActions={<HeadingActions />}
      />
      <CenterTabContent testId={TEST_ID}>
        <GroupsTable />
      </CenterTabContent>
    </CenterTabContainer>
  );
};

const HeadingActions = () => {
  const dispatch = useDispatch();
  const isPanelFullScreen = useSelector(selectIsFullscreenEnabled);
  const groupPerms = useOrganizationPermissions(EAuthSubResourceType.Group);
  const isCreateGroupAllowed = groupPerms.hasPermission(EPermissionAction.Create);

  const handleOpenNewGroupPanel = () => {
    const microFrontend = {
      name: ADMIN_CENTER_NEW_GROUP_PANEL,
      config: {
        name: 'New Group',
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isPanelFullScreen ? 0 : 55,
      marginStart: isPanelFullScreen ? 0 : 80,
      size: 'medium',
      parentPanelId: PARENT_PANEL_ID,
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: ADMIN_CENTER_NEW_GROUP_PANEL,
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  return (
    <Stack direction="row">
      <Button
        startIcon={<AddGroup />}
        color="primary"
        variant="contained"
        size={'small'}
        onClick={handleOpenNewGroupPanel}
        disabled={!isCreateGroupAllowed}
        data-testid="os-admin-center-header-create-group-button"
        data-test="os-admin-center-panel.groups.create-group-button"
      >
        {SHARED_TEXT.createGroup}
      </Button>
    </Stack>
  );
};

export default GroupsPanel;
