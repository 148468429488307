export const BOOTING = 'APP_BAR/booting';
export const BOOTED = 'APP_BAR/booted';

export const boot = () => ({
  type: BOOTING,
});

export const onBooted = () => ({
  type: BOOTED,
});
