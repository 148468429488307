import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(_theme => ({
  suggestionList: {
    maxHeight: '500px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0px 10px 0px 15px',
    margin: '0',
    marginRight: '5px',
    marginTop: '15px',
    marginBottom: '5px',
  },
  suggestionTitle: {
    fontWeight: 600,
    color: '#5C6269',
    margin: '0px',
    position: 'sticky',
    top: 0,
    background: '#FFF',
    paddingBottom: '5px',
  },
  suggestionItem: {
    marginLeft: 0,
    marginBottom: '10px',
    '& button': {
      overflowWrap: 'anywhere',
      textAlign: 'left',
    },
  },
  loadingBarContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F7F7F7CC',
    width: '100%',
    padding: '10px 0px 10px 20px',
  },
}));
