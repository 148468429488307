import { FC, ChangeEvent, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { selectApiConfigs } from '../../../redux/selectors';
import { checkAuthGroupName } from '../../../api/user-groups.api';
import { TUserGroup } from '../../../types';
import { useStyles } from './useStyles';
import { selectAdminCenterOrgGuidParameter } from '@aiware/os/admin-center/shared';

export type TProps = {
  group: Partial<TUserGroup>;
  onNameChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  nameExistsCheck: (value: boolean) => void;
};

const BasicInfo: FC<TProps> = ({ group, onNameChange, onDescriptionChange, nameExistsCheck }) => {
  const messageMaxChars = 300;
  const apiConfigs = useSelector(selectApiConfigs);
  const [nameExists, setNameExists] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const originalName = useRef(group.name || '');
  const { classes } = useStyles();
  const intl = useIntl();
  const isGroupProtected = group.isProtected;
  const orgGuid = useSelector(selectAdminCenterOrgGuidParameter);

  const newGroupNameMessage = intl.formatMessage({
    id: `group-name-message-label`,
    defaultMessage: 'Group Name',
    description: 'Group Name placeholder',
  });

  const newGroupDescriptionMessage = intl.formatMessage({
    id: `group-description-message-label`,
    defaultMessage: 'Group Description',
    description: 'Group Description placeholder',
  });

  const descriptionErrorMsg = intl.formatMessage({
    id: `group-name-error-message`,
    defaultMessage: 'Group name already exists',
    description: 'Group Name placeholder',
  });

  const descriptionLabelErrorMsg = intl.formatMessage({
    id: `group-description-error-message`,
    defaultMessage: 'Please add a description',
    description: 'Group Name placeholder',
  });

  const nameLabel = nameExists ? descriptionErrorMsg : newGroupNameMessage;
  const descriptionLabel = descriptionError ? descriptionLabelErrorMsg : newGroupDescriptionMessage;

  const handleGroupNameChange = async (ev: ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.value.trim();
    const result = await checkAuthGroupName(apiConfigs, name, orgGuid);
    if (result && result?.data?.authGroup && name !== originalName.current) {
      setNameExists(true);
      onNameChange(name);
      nameExistsCheck(true);
    } else {
      setNameExists(false);
      nameExistsCheck(false);
      onNameChange(name);
    }
  };

  const handleGroupDescriptionChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const desc = ev.target.value;
    if (desc) {
      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }
    onDescriptionChange(desc);
  };

  const debouncedNameChange = debounce(handleGroupNameChange, 300);

  return (
    <>
      <Typography
        variant={'h2'}
        className={classes.title}
        data-testid="admin-center-basic-info-title"
        data-test="os-admin-center.groups.basic-info-title"
      >
        <FormattedMessage
          id="admin-center-basic-info-title-message"
          defaultMessage="Basic Info"
          description="Basic Info title"
        />
      </Typography>
      <Typography
        data-testid="basic-info-description"
        data-test="os-admin-center.groups.basic-info-description"
        variant={'body1'}
        className={classes.tittleDescription}
      >
        <FormattedMessage
          id="admin-center-basic-info-title-description"
          defaultMessage="Groups allow you to control data access to multiple aiWARE users quickly and efficiently."
          description="Basic Info title description"
        />
      </Typography>
      <TextField
        id="admin-center-new-group-name"
        data-testid="admin-center-new-group-name"
        data-test="os-admin-center.groups.new-group-name"
        InputLabelProps={{
          shrink: true,
        }}
        label={nameLabel}
        defaultValue={group.name || ''}
        placeholder={newGroupNameMessage}
        className={classes.groupName}
        onChange={debouncedNameChange}
        error={nameExists}
        disabled={isGroupProtected}
      />
      <div className={classes.descriptionContainer}>
        <TextField
          id="admin-center-new-group-description"
          data-testid="admin-center-new-group-description"
          data-test="os-admin-center.groups.new-group-description"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={4}
          defaultValue={group.description || ''}
          label={descriptionLabel}
          placeholder={newGroupDescriptionMessage}
          inputProps={{ maxLength: messageMaxChars, 'data-testid': 'group-description-textarea' }}
          onChange={handleGroupDescriptionChange}
          error={descriptionError}
          className={classes.groupDescription}
          disabled={isGroupProtected}
        />
        <div
          className={classes.descriptionCharCount}
          data-testid="admin-center-new-group-description-char-count"
          data-test="os-admin-center.groups.new-group.char-count"
        >
          {group.description?.length || 0}/{messageMaxChars}
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
