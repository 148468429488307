import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { getDataCenterImporterModule } from '@aiware/os/data-center/importer';
import { getDataCenterBrowseModule } from '@aiware/os/data-center/browse';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { defaultImages } from './helper';
import { ImagePreview } from './image-preview';
import { FormattedMessage } from 'react-intl';
import { WallpaperUploader } from './wallpaper-uploader';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserWallpapers } from '../../redux';

type TProps = {
  refProp: React.MutableRefObject<any>;
  currentWallpaper: any;
  setCurrentWallpaper: (image: any) => void;
};

export const BackgroundSelection = ({ refProp, currentWallpaper, setCurrentWallpaper }: TProps) => {
  const [customWallpaperSequentialNumber, setCustomWallpaperSequentialNumber] = useState<number | null>(null);

  const userWallpapers = useSelector(selectUserWallpapers);
  return (
    <DynamicModuleLoader modules={[getDataCenterImporterModule(), getDataCenterBrowseModule()]}>
      <Box
        data-testid={`aiware-desktop.settings.background-selection.container`}
        ref={refProp}
        sx={{
          boxSizing: 'border-box',
          '& *': {
            boxSizing: 'border-box',
          },
          position: 'fixed',
          width: '460px',
          height: '308px',
          left: '262px',
          bottom: '75px',
          background: 'rgba(255, 255, 255, 0.85)',
          border: '1px solid #DADFE8',
          boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', mb: '10px' }}
          data-testid={`aiware-desktop.settings.background-selection.first-row`}
        >
          {defaultImages.slice(0, 3).map((obj: { key: string; value: string }, num: number) => (
            <ImagePreview
              key={num}
              payload={obj}
              currentWallpaper={currentWallpaper}
              setCurrentWallpaper={setCurrentWallpaper}
            />
          ))}
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', mb: '7px' }}
          data-testid={`aiware-desktop.settings.background-selection.second-row`}
        >
          {defaultImages.slice(3).map((obj: { key: string; value: string }, num: number) => (
            <ImagePreview
              key={num}
              payload={obj}
              currentWallpaper={currentWallpaper}
              setCurrentWallpaper={setCurrentWallpaper}
            />
          ))}
        </Box>
        <Grid container sx={{ alignItems: 'center', mb: '7px' }}>
          <Grid item xs={4}>
            <Divider sx={{ borderColor: '#DADFE8', mr: '5px' }} />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: '10px', color: '#000000' }}>
              <FormattedMessage
                id="aiware-desktop.settings.upload-wallpaper"
                defaultMessage="Upload your own wallpaper."
                description="Upload your own wallpaper label"
              />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Divider sx={{ borderColor: '#DADFE8', ml: '5px' }} />
          </Grid>
        </Grid>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          data-testid={`aiware-desktop.settings.background-selection.custom-images`}
        >
          {[1, 2, 3].map(num => (
            <WallpaperUploader
              key={num}
              id={num}
              currentWallpaper={currentWallpaper}
              setCurrentWallpaper={setCurrentWallpaper}
              uploadNumber={customWallpaperSequentialNumber}
              setUploadNumber={setCustomWallpaperSequentialNumber}
              wallpaper={
                (userWallpapers || []).find(wallpaper => wallpaper.key === `wallpaperCustom_${num}`)!
              }
            />
          ))}
        </Box>
      </Box>
    </DynamicModuleLoader>
  );
};
