import { Controller } from 'react-hook-form';
import { MenuItem, TextField as MuiTextField } from '@mui/material';

import type { FormGeneratorFieldRenderProps } from '../types';
import type { SelectField as SelectFormField } from '../../form-builder';
import { getValidationRules } from '../utils/validations';

/**
 * Generates common props shared across form field components.
 */
const getCommonFieldProps = (props: FormGeneratorFieldRenderProps) => {
  const { name, field, required, errors } = props;

  return {
    fullWidth: true,
    label: field.title,
    error: !!errors[name],
    helperText: errors[name]?.message as string,
    required: required?.includes(field.fieldKey) ?? false,
    placeholder: field.placeholder,
  };
};

/**
 * TextField component for rendering text input fields in the form generator.
 * Supports text, email, and number input types through the field.type property.
 * Uses react-hook-form Controller for form state management.
 */
export const TextField: React.FC<FormGeneratorFieldRenderProps> = props => {
  const { name, field, control, required } = props;
  const commonProps = getCommonFieldProps(props);

  return (
    <Controller
      name={name}
      control={control}
      rules={getValidationRules(field, required ?? [])}
      render={({ field: { onChange, value } }) => (
        <MuiTextField
          {...commonProps}
          type={field.type}
          value={value ?? ''}
          data-testid={`os-auth-register-form-generator-${name}`}
          onChange={onChange}
        />
      )}
    />
  );
};

/**
 * SelectField component for rendering dropdown select fields in the form generator.
 * Displays a list of options defined in the field.enum property.
 * Includes an empty option at the top of the list.
 */
export const SelectField: React.FC<FormGeneratorFieldRenderProps> = props => {
  const { name, field, control, required } = props;
  const commonProps = getCommonFieldProps(props);
  const selectField = field as SelectFormField;

  return (
    <Controller
      name={name}
      control={control}
      rules={getValidationRules(field, required ?? [])}
      render={({ field: { onChange, value } }) => (
        <MuiTextField
          {...commonProps}
          select
          value={value ?? ''}
          onChange={onChange}
          data-testid={`os-auth-register-form-generator-${name}`}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {selectField.enum.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </MuiTextField>
      )}
    />
  );
};

/**
 * DateField component for rendering date input fields in the form generator.
 * Uses the native date input type with Material-UI TextField.
 * Automatically shrinks the label to accommodate the date picker.
 */
export const DateField: React.FC<FormGeneratorFieldRenderProps> = props => {
  const { name, field, control, required } = props;
  const commonProps = getCommonFieldProps(props);

  return (
    <Controller
      name={name}
      control={control}
      rules={getValidationRules(field, required ?? [])}
      render={({ field: { onChange, value } }) => (
        <MuiTextField
          {...commonProps}
          type="date"
          value={value ?? ''}
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          data-testid={`os-auth-register-form-generator-${name}`}
        />
      )}
    />
  );
};
