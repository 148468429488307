import { createSelector } from '@reduxjs/toolkit';
import {
  rootApiSelector,
  graphEndpointSelector,
  sessionTokenSelector,
  TApiConfigsSelector,
} from '@aiware/shared/redux';

import { IMemberType, SLICE_NAME, TFunctionalPermission, TPermissionSet, TResource } from '../../types';
import { namespace } from '../slices/admin-center-permissions.state';
import { TState, TAuthACE } from '../../types';
import { IUser, LoadingStatus } from '@aiware/js/interfaces';
import { TUserGroup } from '@aiware/os/admin-center/groups';

export interface IState {
  [SLICE_NAME]: {
    [namespace]: TState;
  };
}

export const selectCurrentResource = (state: IState): Partial<TResource> | null =>
  state?.[SLICE_NAME]?.[namespace]?.selectedResource;

export const selectFoundMembers = (state: IState): (TUserGroup & IUser & IMemberType)[] =>
  state?.[SLICE_NAME]?.[namespace]?.foundMembers;

export const selectLoadingStatus = (state: IState): LoadingStatus => state?.[SLICE_NAME]?.[namespace]?.status;
export const selectPermissions = (state: IState): TFunctionalPermission[] =>
  state?.[SLICE_NAME]?.[namespace]?.permissions;

export const selectPermissionSets = (state: IState): TPermissionSet[] | null =>
  state?.[SLICE_NAME]?.[namespace]?.permissionSets;

export const selectPermissionSetById = (id: string) => (state: IState) =>
  (state?.[SLICE_NAME]?.[namespace]?.permissionSets || []).find(pS => pS.id === id);

export const selectPermissionSetsLoadingStatus = (state: IState): LoadingStatus =>
  state?.[SLICE_NAME]?.[namespace]?.permissionSetsLoadingStatus;

export const selectPermissionSetsPagination = (state: IState) =>
  state?.[SLICE_NAME]?.[namespace]?.permissionSetsPagination;

export const selectOrgAccessControls = (state: IState): TAuthACE[] =>
  state?.[SLICE_NAME]?.[namespace]?.orgAccessControls;

export const selectOrgAccessControlLoadingStatus = (state: IState): LoadingStatus =>
  state?.[SLICE_NAME]?.[namespace]?.accessControlLoadingStatus;

export const selectOrgAccessControlPagination = (state: IState) =>
  state?.[SLICE_NAME]?.[namespace]?.accessControlPagination;

export const selectApiConfigs: TApiConfigsSelector = createSelector(
  [
    // eslint-disable-next-line
    rootApiSelector as (state: any) => string,
    // eslint-disable-next-line
    graphEndpointSelector as (state: any) => string,
    sessionTokenSelector,
  ],
  (apiRoot, graphQLEndpoint, token) => ({
    apiRoot,
    graphQLEndpoint,
    token,
  })
);

export const selectFuncPermissions = (state: IState) =>
  state?.[SLICE_NAME]?.[namespace]?.functionalPermissions;

export const selectFuncPermissionsMapping = (state: IState) =>
  state?.[SLICE_NAME]?.[namespace]?.functionalPermissionsMapping;

export * from './permissions-widget.selectors';
