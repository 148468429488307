import { memo, FunctionComponent } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { useSelector } from 'react-redux';
import { selectPanelMode } from '../../redux/selectors/ui-state';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `0.5px solid ${theme.palette.divider}`,
  },
  tableWrapper: {
    padding: theme.spacing(2),
    flexGrow: 1,
    overflowY: 'hidden',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ContentLayout: FunctionComponent<{
  breadcrumbs?: JSX.Element;
  actions?: JSX.Element;
  table?: JSX.Element | null;
  dataCenterView?: boolean;
  tableWrapperStyles?: React.CSSProperties;
}> = ({
  breadcrumbs = null,
  actions = null,
  table = null,
  dataCenterView = false,
  tableWrapperStyles = {},
}) => {
  const panelMode = useSelector(selectPanelMode);

  return (
    <ContentLayoutMemo
      breadcrumbs={breadcrumbs}
      actions={actions}
      table={table}
      dataCenterView={dataCenterView}
      panelMode={panelMode}
      tableWrapperStyles={tableWrapperStyles}
    />
  );
};

const ContentLayoutMemo = memo(
  ({
    breadcrumbs,
    actions,
    table,
    tableWrapperStyles = {},
  }: {
    breadcrumbs?: JSX.Element | null;
    actions?: JSX.Element | null;
    table?: JSX.Element | null;
    dataCenterView?: boolean;
    panelMode?: boolean;
    tableWrapperStyles?: React.CSSProperties;
  }) => {
    const { classes } = useStyles();

    return (
      <section className={classes.root}>
        <header className={classes.header}>
          <div className={classes.toolbar}>{breadcrumbs}</div>
          <div className={classes.toolbar}>{actions}</div>
        </header>
        <div className={classes.tableWrapper} style={tableWrapperStyles}>
          {table}
        </div>
      </section>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps.panelMode && nextProps.dataCenterView) {
      return true;
    }
    return false;
  }
);

export default ContentLayout;
