import { IntlShape } from 'react-intl';
import { IUser, LoadingStatus } from '@aiware/js/interfaces';
import { TUserGroup } from '@aiware/os/admin-center/groups';
import { TAuthResourceMember } from './auth-resource-member';

export const SLICE_NAME = 'adminCenterPermissions';

export enum EResourceType {
  TDO,
  SDO,
  Folder,
  Source,
  Organization,
  Engine,
  Library,
  Dataset,
  Application,
}

export type THasPermissionsInput = {
  resourceType: string;
  ids: string[];
};

export type TGetACLsInput = {
  resourceType: EResourceType;
  ids: string[];
  limit?: number;
  offset?: number;
};

export enum EMemberType {
  User = 'User',
  Group = 'Group',
  AuthGroup = 'AuthGroup',
}

export type TEntry = {
  member: {
    memberType: EMemberType;
    id: string;
  };
  permissionSetID: string | undefined;
};

export type TAddACLsInput = {
  type: EResourceType;
  ids: string[];
  entries: TEntry[];
};

export type GraphQLPage<T> = {
  type?: EResourceType;
  id?: string;
  object?: TAuthResourceMember;
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export type TMemberType = {
  memberType: string;
};

export type TACEMember = (IUser & TMemberType) | (TUserGroup & TMemberType);

export type TFunctionalPermission = {
  permissionId: string;
  permissionName: string;
  permissionDescription: string;
};

export type TAuthACE = {
  id: string;
  objectType?: EResourceType;
  objectID: string;
  object?: TAuthResourceMember;
  member: TACEMember;
  permissionSet: TFunctionalPermission;
  createdAt: string;
};

export type TResource = {
  type: EResourceType;
  id: string;
  object: TAuthResourceMember;
  records: TAuthACE[];
};

export enum TAuthClass {
  Internal = 'Internal',
  System = 'System',
  Application = 'Application',
  Standard = 'Standard',
}

export type TPermissionSet = {
  id: string;
  name: string;
  isProtected?: boolean;
  description: string;
  permissions: string[];
  createdAt: string;
  authClass?: TAuthClass;
};

export type TState = {
  selectedResource: Partial<TResource> | null;
  status: LoadingStatus;
  foundMembers: (TUserGroup & IUser & IMemberType)[];
  permissions: TFunctionalPermission[];
  permissionSets: TPermissionSet[] | null;
  permissionSetsLoadingStatus: LoadingStatus;
  permissionSetsPagination: {
    hasMore: boolean;
    offset: number;
  };
  accessControlLoadingStatus: LoadingStatus;
  accessControlPagination: {
    hasMore: boolean;
    offset: number;
  };
  orgAccessControls: TAuthACE[];
  functionalPermissions: { [key: string]: string };
  functionalPermissionsMapping: { [key: string]: string };
};

export type TIntlProps = {
  intl: IntlShape;
};

export type TMemberCardProps = {
  member: IGroupMemberGroup | IGroupMemberUser;
};

export interface IMemberType {
  memberType: string;
  current?: boolean;
}

export interface IGroupMemberUser extends IMemberType {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  imageUrl: string | null;
}

export interface IGroupMemberGroup extends IMemberType {
  id: string;
  name: string;
  description: string;
  memberCount: number;
  members: Partial<GraphQLPage<IGroupMemberUser | IGroupMemberGroup>>;
  memberType: string;
}

export type TAuthPermissionCheck = {
  id?: string;
  hasPermission: boolean;
};

export type TPermissionOptionSDK = {
  value: string;
  label: string;
  permissions: string[];
};

export type TPermissionSetInput = {
  name: string;
  description: string;
  permissions: string[];
  organizationID?: string;
  applicationID?: string;
  roleID?: string;
};

export type TAuthPermissionSetUpdateInput = {
  id: string;
  name?: string;
  ownerOrganization?: string;
  description?: string;
  permissions?: string[];
};
