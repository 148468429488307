import { Grid, Skeleton, Stack } from '@mui/material';
import { Fragment } from 'react';
const LoadingSkeleton = () => {
  return (
    <Fragment>
      {[1, 2].map(i => {
        return (
          <>
            <Skeleton variant="rectangular" width={345} height={50} />
            <Grid key={i} container sx={{ paddingLeft: '10px', position: 'relative', marginBottom: '15px' }}>
              {[1, 2, 3].map(i => {
                return (
                  <Grid key={i} item xs={4} sx={{ padding: 0 }}>
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center', padding: '20px 0px' }}>
                      <Skeleton sx={{ m: 2 }} variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: '10px',
                        }}
                        width={30}
                      />
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </>
        );
      })}
    </Fragment>
  );
};
export default LoadingSkeleton;
