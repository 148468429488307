import { Avatar, Typography } from '@mui/material';
import { IGroupMemberGroup, IGroupMemberUser } from '../../types';
import { useStyles } from '../components/permissions-member-card/member-card.styles';
export const GroupMember = ({ member }: { member: IGroupMemberUser | IGroupMemberGroup }) => {
  const { classes } = useStyles();
  const initials =
    (member as IGroupMemberUser)?.firstName?.substring(0, 1)?.toUpperCase() +
    (member as IGroupMemberUser)?.lastName?.substring(0, 1)?.toUpperCase();
  return (
    <div
      className={classes.groupMemberContainer}
      data-testid="group-info-panel.member-card"
      data-test="group-info-panel.member-card"
    >
      <div className={classes.flexContainer}>
        <div className={classes.memberAvatar}>
          <Avatar
            src={(member as IGroupMemberUser).imageUrl || ''}
            className={classes.avatar}
            data-testid="group-info-panel.member-card.avatar"
            data-test="group-info-panel.member-card.avatar"
          >
            {initials}
          </Avatar>
        </div>
        <div>
          <div data-testid="group-info-panel.member-card.name" data-test="group-info-panel.member-card.name">
            <Typography variant="h3">
              {(member as IGroupMemberUser)?.firstName} {(member as IGroupMemberUser)?.lastName}
            </Typography>
          </div>
          <div
            className={classes.userEmailContainer}
            data-testid="group-info-panel.member-card.email"
            data-test="group-info-panel.member-card.email"
          >
            <Typography variant="body2">{(member as IGroupMemberUser).email}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
