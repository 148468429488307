import { Activity } from './Activity';
import { AddFilled } from './AddFilled';
import { ApplicationTabIcon } from './ApplicationTabIcon';
import { API } from './API';
import { Add } from './Add';
import { AdminTabIcon } from './AdminTabIcon';
import { AddOne } from './AddOne';
import { AddGroup } from './AddGroup';
import { AddToCart } from './AddToCart';
import { AddUser } from './AddUser';
import { AddUserTwo } from './AddUserTwo';
import { AiPackage } from './AiPackage';
import { Alarm } from './Alarm';
import { AlarmAdd } from './AlarmAdd';
import { AlarmOn } from './AlarmOn';
import { AlarmTwo } from './AlarmTwo';
import { Applications } from './Applications';
import { ApplicationsCircles } from './ApplicationsCircles';
import { Archive } from './Archive';
import { ArrowBack } from './ArrowBack';
import { ArrowDown } from './ArrowDown';
import { ArrowForward } from './ArrowForward';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { ArrowUp } from './ArrowUp';
import { Article } from './Article';
import { ArticleFilled } from './ArticleFilled';
import { Assignment } from './Assignment';
import { AssignmentFilled } from './AssignmentFilled';
import { Attachment } from './Attachment';
import { AutomateNode } from './AutomateNode';
import { Banner } from './Banner';
import { Benchmark } from './Benchmark';
import { Bolt } from './Bolt';
import { BoltFilled } from './BoltFilled';
import { Bookmark } from './Bookmark';
import { Bullet } from './Bullet';
import { Calendar } from './Calendar';
import { CalendarFilled } from './CalendarFilled';
import { Camera } from './Camera';
import { CameraFilled } from './CameraFilled';
import { Campaign } from './Campaign';
import { CampaignFilled } from './CampaignFilled';
import { Cart } from './Cart';
import { CartFilled } from './CartFilled';
import { Category } from './Category';
import { CategoryFilled } from './CategoryFilled';
import { Chart } from './Chart';
import { ChartFilled } from './ChartFilled';
import { Chat } from './Chat';
import { ChatFilled } from './ChatFilled';
import { Check } from './Check';
import { CheckmarkFilled } from './CheckmarkFilled';
import { ChevronLeft } from './ChevronLeft';
import { ChevronRight } from './ChevronRight';
import { Class } from './Class';
import { ClassFilled } from './ClassFilled';
import { Close } from './Close';
import { Cloud } from './Cloud';
import { CloudFilled } from './CloudFilled';
import { CmsTabIcon } from './CmsTabIcon';
import { Comfy } from './Comfy';
import { Compare } from './Compare';
import { Copy } from './Copy';
import { CoronaVirus } from './CoronaVirus';
import { CurlyBraces } from './CurlyBraces';
import { Cut } from './Cut';
import { DAG } from './DAG';
import { DAGTemplate } from './DAGTemplate';
import { DagBuilder } from './DagBuilder';
import { Delete } from './Delete';
import { DeleteFilled } from './DeleteFilled';
import { Description } from './Description';
import { DeveloperBoard } from './DeveloperBoard';
import { DeviceFilled } from './DeviceFilled';
import { Download } from './Download';
import { DownloadFilled } from './DownloadFilled';
import { Edit } from './Edit';
import { EditFilled } from './EditFilled';
import { EditAttributes } from './EditAttributes';
import { Email } from './Email';
import { EmailFilled } from './EmailFilled';
import { EngineFilled } from './EngineFilled';
import { EngineBuild } from './EngineBuild';
import { Engines } from './Engines';
import { Engines2 } from './Engines2';
import { EnginesMarketplace } from './EnginesMarketplace';
import { Expand } from './Expand';
import { ExpandLess } from './ExpandLess';
import { ExpandMore } from './ExpandMore';
import { Export } from './Export';
import { Favorite } from './Favorite';
import { FavoriteFilled } from './FavoriteFilled';
import { FileFilled } from './FileFilled';
import { Filter } from './Filter';
import { FilterRemove } from './FilterRemove';
import { FireFilled } from './FireFilled';
import { FiveG } from './FiveG';
import { Flag } from './Flag';
import { FlowCenter } from './FlowCenter';
import { Folder } from './Folder';
import { FolderAddFilled } from './FolderAddFilled';
import { FolderFilled } from './FolderFilled';
import { FolderOpen } from './FolderOpen';
import { FolderSpecialFilled } from './FolderSpecialFilled';
import { Forum } from './Forum';
import { ForumFilled } from './ForumFilled';
import { FourK } from './FourK';
import { Fullscreen } from './Fullscreen';
import { FullscreenExit } from './FullscreenExit';
import { GridIcon } from './GridIcon';
import { GridFilled } from './GridFilled';
import { Group } from './Group';
import { Hamburger } from './Hamburger';
import { Headset } from './Headset';
import { HeadsetFilled } from './HeadsetFilled';
import { HelpCenter } from './HelpCenter';
import { HelpFilled } from './HelpFilled';
import { Hide } from './Hide';
import { HideLeft } from './HideLeft';
import { History } from './History';
import { Home } from './Home';
import { HomeFilled } from './HomeFilled';
import { Human } from './Human';
import { HumanFilled } from './HumanFilled';
import { Illuminate } from './Illuminate';
import { Image } from './Image';
import { ImageFilled } from './ImageFilled';
import { ImportantFilled } from './ImportantFilled';
import { Information } from './Information';
import { InformationFilled } from './InformationFilled';
import { Input } from './Input';
import { Key } from './Key';
import { KeyFilled } from './KeyFilled';
import { List } from './List';
import { Load } from './Load';
import { Location } from './Location';
import { LocationFilled } from './LocationFilled';
import { LocationOne } from './LocationOne';
import { Lock } from './Lock';
import { LockFilled } from './LockFilled';
import { Marketplace } from './Marketplace';
import { MarketplaceFilled } from './MarketplaceFilled';
import { MarketplaceTwo } from './MarketplaceTwo';
import { Merge } from './Merge';
import { Mode } from './Mode';
import { Mode2 } from './Mode2';
import { MoreHor } from './MoreHor';
import { MoreVert } from './MoreVert';
import { MoveFolder } from './MoveFolder';
import { NearMe } from './NearMe';
import { NearMeFilled } from './NearMeFilled';
import { NewFolder } from './NewFolder';
import { NoLink } from './NoLink';
import { NotInterested } from './NotInterested';
import { Notifications } from './Notifications';
import { NotificationsActive } from './NotificationsActive';
import { NotificationsActiveFilled } from './NotificationsActiveFilled';
import { NotificationsFilled } from './NotificationsFilled';
import { NotificationsOff } from './NotificationsOff';
import { NotificationsOffFilled } from './NotificationsOffFilled';
import { OpenWindow } from './OpenWindow';
import { OrgFilled } from './OrgFilled';
import { Organization } from './Organization';
import { OrganizationTabIcon } from './OrganizationTabIcon';
import { Packages } from './Packages';
import { PersonRemove } from './PersonRemove';
import { PreferenceTabIcon } from './PreferenceTabIcon';
import { PersonalProfileTabIcon } from './PersonalProfileTabIcon';
import { Pin } from './Pin';
import { Play } from './Play';
import { PlayBack } from './PlayBack';
import { PlaySlomo } from './PlaySlomo';
import { PodcastSource } from './PodcastSource';
import { PostAdd } from './PostAdd';
import { Preview } from './Preview';
import { Print } from './Print';
import { PrintFilled } from './PrintFilled';
import { Process } from './Process';
import { ProcessingDetail } from './ProcessingDetail';
import { Radio } from './Radio';
import { RadioSource } from './RadioSource';
import { Refresh } from './Refresh';
import { Registered } from './Registered';
import { Remove } from './Remove';
import { RemoveFilled } from './RemoveFilled';
import { RemoveOne } from './RemoveOne';
import { Replay } from './Replay';
import { ReplayFive } from './ReplayFive';
import { ReplayTen } from './ReplayTen';
import { ReplayThirty } from './ReplayThirty';
import { ReplyFilled } from './ReplyFilled';
import { Reprocess } from './Reprocess';
import { ReprocessOne } from './ReprocessOne';
import { Resources } from './Resources';
import { Satisfied } from './Satisfied';
import { SatisfiedFilled } from './SatisfiedFilled';
import { Save } from './Save';
import { SaveDown } from './SaveDown';
import { ScheduleJob } from './ScheduleJob';
import { ScheduleBulk } from './ScheduleBulk';
import { Schema } from './Schema';
import { SchemaFilled } from './SchemaFilled';
import { Search } from './Search';
import { SearchImage } from './SearchImage';
import { SearchOff } from './SearchOff';
import { SearchPerson } from './SearchPerson';
import { SearchStructuredData } from './SearchStructuredData';
import { SearchedFor } from './SearchedFor';
import { SecurityGroup } from './SecurityGroup';
import { Send } from './Send';
import { SendFilled } from './SendFilled';
import { Settings } from './Settings';
import { SettingsFilled } from './SettingsFilled';
import { Share } from './Share';
import { ShowSelected } from './ShowSelected';
import { SixFeetApart } from './SixFeetApart';
import { Smart } from './Smart';
import { Sort } from './Sort';
import { Sound } from './Sound';
import { SoundFilled } from './SoundFilled';
import { Source } from './Source';
import { SourceThree } from './SourceThree';
import { SourceTwo } from './SourceTwo';
import { SpecialFolder } from './SpecialFolder';
import { Speech } from './Speech';
import { SpeechFilled } from './SpeechFilled';
import { SpeechTwo } from './SpeechTwo';
import { Star } from './Star';
import { StarFilled } from './StarFilled';
import { StarHalf } from './StarHalf';
import { Subject } from './Subject';
import { Sunny } from './Sunny';
import { SunnyFilled } from './SunnyFilled';
import { Swap } from './Swap';
import { SwapVert } from './SwapVert';
import { SystemNotification } from './SystemNotification';
import { TVOff } from './TVOff';
import { TVOn } from './TVOn';
import { TVSource } from './TVSource';
import { Tag } from './Tag';
import { TagFilled } from './TagFilled';
import { Time } from './Time';
import { TimeBack } from './TimeBack';
import { TimeFilled } from './TimeFilled';
import { TimeUpdate } from './TimeUpdate';
import { Tune } from './Tune';
import { Undefined } from './Undefined';
import { UnknownDocument } from './UnknownDocument';
import { UpDown } from './UpDown';
import { Upload } from './Upload';
import { UploadFilled } from './UploadFilled';
import { User } from './User';
import { UserAddFilled } from './UserAddFilled';
import { UserAddTwoFilled } from './UserAddTwoFilled';
import { UserFilled } from './UserFilled';
import { Users } from './Users';
import { UsersFilled } from './UsersFilled';
import { Verified } from './Verified';
import { VeritoneLogo } from './VeriLogo';
import { VerifiedFilled } from './VerifiedFilled';
import { VideoCamera } from './VideoCamera';
import { VideoCameraFilled } from './VideoCameraFilled';
import { View } from './View';
import { VisibilityFilled } from './VisibilityFilled';
import { Voice } from './Voice';
import { VoiceFilled } from './VoiceFilled';
import { Widgets } from './Widgets';
import { WidgetsFilled } from './WidgetsFilled';
import { YoutubeFilled } from './YoutubeFilled';
import { YoutubeSource } from './YoutubeSource';
import { ZoomOut } from './ZoomOut';
import { AdminCenter } from './AdminCenter';
import { DeveloperCenter } from './DeveloperCenter';
import { DataCenter } from './DataCenter';
import { Organizations } from './Organizations';
import { PersonalProfile } from './PersonalProfile';
import { FlowCenter2 } from './FlowCenter2';
import { LibraryCenter } from './LibraryCenter';
import { AppBarArticleIcon } from './AppBarArticleIcon';
import { AppBarInAppGuidance } from './AppBarInAppGuidance';
import { TopQueue } from './TopQueue';
import { BottomQueue } from './BottomQueue';
import { Pause } from './Pause';
import { Cancel } from './Cancel';
import { Hidden } from './Hidden';
import { PackagesAvatar } from './PackagesAvatar';
import { FlowTemplate } from './FlowTemplate';
import { FlowTemplate2 } from './FlowTemplate2';
import { Flows } from './Flows';
import { Explore } from './Explore';
import { DataRegistry } from './DataRegistry';
import { OrgSettings } from './OrgSettings';
import { RetentionPolicy } from './RetentionPolicy';
import { ToolsDrawer } from './ToolsDrawer';
import { ImpersonateIcon } from './ImpersonateIcon';

interface IStandardIcons {
  [x: string]: any;
}

export const StandardIcons: IStandardIcons = {
  AutomateNode,
  TopQueue,
  BottomQueue,
  Pause,
  Cancel,
  Activity,
  AddFilled,
  ApplicationTabIcon,
  API,
  Add,
  AdminTabIcon,
  AddOne,
  AddGroup,
  AddToCart,
  AddUser,
  AddUserTwo,
  AiPackage,
  Alarm,
  AlarmAdd,
  AlarmOn,
  AlarmTwo,
  Applications,
  ApplicationsCircles,
  Archive,
  ArrowBack,
  ArrowDown,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Article,
  ArticleFilled,
  Assignment,
  AssignmentFilled,
  Attachment,
  Banner,
  Benchmark,
  Bolt,
  BoltFilled,
  Bookmark,
  Bullet,
  Calendar,
  CalendarFilled,
  Camera,
  CameraFilled,
  Campaign,
  CampaignFilled,
  Cart,
  CartFilled,
  Category,
  CategoryFilled,
  Chart,
  ChartFilled,
  Chat,
  ChatFilled,
  Check,
  CheckmarkFilled,
  ChevronLeft,
  ChevronRight,
  Class,
  ClassFilled,
  Close,
  Cloud,
  CloudFilled,
  CmsTabIcon,
  Comfy,
  Compare,
  Copy,
  CoronaVirus,
  CurlyBraces,
  Cut,
  DataRegistry,
  DAG,
  DAGTemplate,
  DagBuilder,
  Delete,
  DeleteFilled,
  Description,
  DeveloperBoard,
  DeviceFilled,
  Download,
  DownloadFilled,
  Edit,
  EditFilled,
  EditAttributes,
  Email,
  EmailFilled,
  EngineFilled,
  EngineBuild,
  Engines,
  Engines2,
  EnginesMarketplace,
  Expand,
  ExpandLess,
  ExpandMore,
  Export,
  Favorite,
  FavoriteFilled,
  FileFilled,
  Filter,
  FilterRemove,
  FireFilled,
  FiveG,
  Flag,
  FlowCenter,
  Flows,
  FlowTemplate2,
  FlowTemplate,
  Folder,
  FolderAddFilled,
  FolderFilled,
  FolderOpen,
  FolderSpecialFilled,
  Forum,
  ForumFilled,
  FourK,
  Fullscreen,
  FullscreenExit,
  GridIcon,
  GridFilled,
  Group,
  Hamburger,
  Headset,
  HeadsetFilled,
  HelpCenter,
  HelpFilled,
  Hide,
  Hidden,
  HideLeft,
  History,
  Home,
  HomeFilled,
  Human,
  HumanFilled,
  Illuminate,
  Image,
  ImageFilled,
  ImportantFilled,
  Information,
  InformationFilled,
  Input,
  Key,
  KeyFilled,
  List,
  Load,
  Location,
  LocationFilled,
  LocationOne,
  Lock,
  LockFilled,
  Marketplace,
  MarketplaceFilled,
  MarketplaceTwo,
  Merge,
  Mode,
  Mode2,
  MoreHor,
  MoreVert,
  MoveFolder,
  NearMe,
  NearMeFilled,
  NewFolder,
  NoLink,
  NotInterested,
  Notifications,
  NotificationsActive,
  NotificationsActiveFilled,
  NotificationsFilled,
  NotificationsOff,
  NotificationsOffFilled,
  OpenWindow,
  OrgFilled,
  OrgSettings,
  Organization,
  OrganizationTabIcon,
  Packages,
  PackagesAvatar,
  PersonRemove,
  PreferenceTabIcon,
  PersonalProfileTabIcon,
  Pin,
  Play,
  PlayBack,
  PlaySlomo,
  PodcastSource,
  PostAdd,
  Preview,
  Print,
  PrintFilled,
  Process,
  ProcessingDetail,
  Radio,
  RadioSource,
  Resources,
  Refresh,
  Registered,
  Remove,
  RemoveFilled,
  RemoveOne,
  Replay,
  ReplayFive,
  ReplayTen,
  ReplayThirty,
  ReplyFilled,
  Reprocess,
  ReprocessOne,
  RetentionPolicy,
  Satisfied,
  SatisfiedFilled,
  Save,
  SaveDown,
  ScheduleJob,
  ScheduleBulk,
  Schema,
  SchemaFilled,
  Search,
  SearchImage,
  SearchOff,
  SearchPerson,
  SearchedFor,
  SecurityGroup,
  Send,
  SendFilled,
  Settings,
  SettingsFilled,
  Share,
  ShowSelected,
  SixFeetApart,
  Smart,
  Sort,
  Sound,
  SoundFilled,
  Source,
  SourceThree,
  SourceTwo,
  SpecialFolder,
  Speech,
  SpeechFilled,
  SpeechTwo,
  Star,
  StarFilled,
  StarHalf,
  SearchStructuredData,
  Subject,
  Sunny,
  SunnyFilled,
  Swap,
  SwapVert,
  SystemNotification,
  TVOff,
  TVOn,
  TVSource,
  Tag,
  TagFilled,
  Time,
  TimeBack,
  TimeFilled,
  TimeUpdate,
  Tune,
  Undefined,
  UnknownDocument,
  UpDown,
  Upload,
  UploadFilled,
  User,
  UserAddFilled,
  UserAddTwoFilled,
  UserFilled,
  Users,
  UsersFilled,
  Verified,
  VeritoneLogo,
  VerifiedFilled,
  VideoCamera,
  VideoCameraFilled,
  View,
  VisibilityFilled,
  Voice,
  VoiceFilled,
  Widgets,
  WidgetsFilled,
  YoutubeFilled,
  YoutubeSource,
  ZoomOut,
  AdminCenter,
  DeveloperCenter,
  DataCenter,
  Organizations,
  PersonalProfile,
  FlowCenter2,
  LibraryCenter,
  AppBarArticleIcon,
  AppBarInAppGuidance,
  Explore,
  ToolsDrawer,
  Impersonate: ImpersonateIcon,
};
