import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  memberLookupContainer: {
    padding: '30px 30px 20px 30px',
    borderBottom: '1px solid rgb(219, 226, 234)',
    '& .Sdk-MuiChip-root': {
      background: 'white',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '100px',
      border: 'none',
      marginRight: '5px',
      marginBottom: '2px',
    },
  },
  autoCompleteContainer: {
    display: 'flex',
    width: '100%',
  },
  autoComplete: {
    width: '100%',
  },
  membersName: {
    width: '100%',
    minHeight: '52px',
    margin: '20px auto',
  },
  memberInfoContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    margin: '0 6px',
    borderRadius: '4px',
    '&:hover': {
      background: '#F2F7FE',
    },
    '& *': {
      cursor: 'pointer',
    },
  },
  currentMemberInfoContainer: {
    cursor: 'not-allowed',
    '& *': {
      cursor: 'not-allowed',
    },
  },
  member: {
    display: 'flex',
  },
  memberAvatar: {
    letterSpacing: '-1px',
    height: '40px',
    width: '40px',
    border: '2px solid #FFF',
    marginRight: '14px',
    color: 'black',
    fontSize: '14px',
    background: 'rgb(248,248,248)',
    '& svg': {
      fill: 'rgb(87,95,122)',
    },
  },
  chipMember: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
  },
  chipAvatar: {
    letterSpacing: '-1px',
    height: '20px',
    width: '20px',
    marginRight: '5px',
    fontSize: '10px',
    color: 'black',
    background: 'rgb(248,248,248)',
    '& svg': {
      fill: 'rgb(87,95,122)',
      height: '20px',
      width: '20px',
    },
  },
  chipContainer: {
    paddingLeft: '8px',
    '& .Sdk-MuiChip-label': {
      paddingLeft: '0',
    },
    '& .Sdk-MuiChip-deleteIcon': {
      height: '14px',
      width: '14px',
      fill: 'black',
      marginRight: '8px',
    },
  },
  permissionSelectContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  permissionsSelect: {
    color: 'black',
    fontWeight: 400,
    fontSize: '16px',
    maxHeight: '58px',
    minWidth: '100px',
    paddingTop: '1px',
    marginLeft: '20px',
    '& fieldset': {
      border: '1px solid #D5DFE9',
    },
  },
}));
