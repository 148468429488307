import { takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from '../../slices';
import { closeImporterPanel, createTDOs } from './common.saga';

export function* watchTDOCreateStart() {
  yield takeLatest(actions.uiState.tdoCreateStart.type, createTDOs);
}

export function* watchRetryTDOCreation() {
  yield takeEvery(actions.uiState.retryTDOCreateForFiles.type, createTDOs);
}

export function* watchTdoCreateSuccess() {
  yield takeEvery(actions.uiState.tdoCreateSucceed.type, closeImporterPanel);
}
