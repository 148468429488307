import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  graphEndpointSelector,
  MessageSeverity,
  preferredLanguageSelector,
  sessionTokenSelector,
  showMessage,
} from '@aiware/shared/redux';

import { AIWareFormatMessage } from '@aiware/os/helpers';

import {
  addAppToGroup,
  createNewAppGroupStart,
  deleteAppGroup,
  editAppGroup,
  fetchUserAppGroupsStart,
  removeAppFromGroup,
  fetchUserAppGroupsRootFolderIdSuccess,
  fetchUserAppGroupSuccess,
  fetchUserAppGroupsFailed,
  updateGroupPriority,
} from './slice';

import { TUserAppGroup, TUserAppGroupApiResponse } from './type';
import {
  addAppToUserGroupApi,
  createUserGroupApi,
  deleteAppGroupApi,
  editAppGroupApi,
  fetchUserAppGroupsApi,
  fetchUserAppGroupsRootFolderIdApi,
  updateGroupPriorityApi,
  removeAppFromGroupApi,
} from './api';

import { selectUserAppGroupsRootFolderId, selectUserAppGroups } from './selector';
import { validate } from 'uuid';
import isEqual from 'lodash/isEqual';
import { devErrorLogger } from '@aiware/shared/reusable-utils';

function* fetchUserGroupsSaga() {
  try {
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    let appGroupRootFolderId: string = yield select(selectUserAppGroupsRootFolderId);

    if (!appGroupRootFolderId) {
      const rootFolderIds: { id: string }[] = yield call(
        fetchUserAppGroupsRootFolderIdApi,
        token,
        graphEndpoint
      );

      if (!rootFolderIds || rootFolderIds.length < 2 || !rootFolderIds[1]?.id) {
        yield put(fetchUserAppGroupsFailed());
        devErrorLogger(`Something went wrong while creating root folder for applications`);
        return;
      }
      appGroupRootFolderId = rootFolderIds[1]?.id;
    }

    const res: TUserAppGroupApiResponse = yield call(
      fetchUserAppGroupsApi,
      token,
      graphEndpoint,
      appGroupRootFolderId
    );

    const payload: TUserAppGroup[] =
      res?.childFolders?.records?.map(el => {
        return {
          groupName: el.name,
          id: el.id,
          treeObjectId: el.treeObjectId,
          apps: el?.childApplications?.records.map(app => app.id),
          priority: el?.orderIndex,
          parentTreeObjectId: el?.parent?.treeObjectId,
          isNew: false,
          expanded: false,
          isEdited: false,
          isExpanded: true,
        };
      }) || [];

    // sort by priority
    payload.sort((a, b) => a?.priority - b?.priority);

    yield put(fetchUserAppGroupsRootFolderIdSuccess(appGroupRootFolderId));
    yield put(fetchUserAppGroupSuccess(payload));
  } catch (e) {
    yield put(fetchUserAppGroupsFailed());
    devErrorLogger(`Something went wrong while creating root folder for applications: ${JSON.stringify(e)}`);
  }
}

function* createNewAppGroupStartSaga(action: ReturnType<typeof createNewAppGroupStart>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    const parentFolderId: string = yield select(selectUserAppGroupsRootFolderId);
    const groupName = action.payload;
    const userAppGroups: TUserAppGroup[] = yield select(selectUserAppGroups);

    const newGroup = userAppGroups.find(app => app.isNew === true);
    const appIds = newGroup?.apps || [];
    const groupId: string = yield call(createUserGroupApi, token, graphEndpoint, groupName, parentFolderId);

    if (groupId && appIds.length > 0) {
      yield all(appIds.map(id => call(addAppToUserGroupApi, token, graphEndpoint, id, groupId)));
    }

    // refresh user's app groups
    yield put(fetchUserAppGroupsStart());
  } catch (e) {
    devErrorLogger(`Something went wrong while creating application group: ${JSON.stringify(e)}`);
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong while creating your application group'
        : formatMessage({
            id: 'user-app-groups.create-app-error-dsa123',
            defaultMessage: 'Something went wrong while creating your application group',
            description: 'Error message when something went wrong creating application group',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

function* addAppToGroupSaga(action: ReturnType<typeof addAppToGroup>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const groupId = action.payload.destination;
    const appId = action.payload.appId;

    // validate ids
    if (!validate(groupId) || !validate(appId)) {
      return;
    }

    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);

    yield call(addAppToUserGroupApi, token, graphEndpoint, appId, groupId);
  } catch (e) {
    devErrorLogger(`Something went wrong while adding app to application group: ${JSON.stringify(e)}`);
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong while adding application to group'
        : formatMessage({
            id: 'user-app-groups.add-app-error-ada534',
            defaultMessage: 'Something went wrong while adding application to group',
            description: 'Error message when something went wrong while adding application to group',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

function* removeAppFromGroupSaga(action: ReturnType<typeof removeAppFromGroup>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const groupId = action.payload.source;
    const appId = action.payload.appId;
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);

    yield call(removeAppFromGroupApi, token, graphEndpoint, appId, groupId);
  } catch (e) {
    devErrorLogger(`Something went wrong while removing application from group: ${JSON.stringify(e)}`);
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong while removing application from group'
        : formatMessage({
            id: 'user-app-groups.remove-app-error-fsa817',
            defaultMessage: 'Something went wrong while removing application from group',
            description: 'Error message when something went wrong while removing application from group',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

function* deleteAppGroupSaga(action: ReturnType<typeof deleteAppGroup>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const groupId = action.payload;
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    const userAppGroups: TUserAppGroup[] = yield select(selectUserAppGroups) || [];

    const appGroup = userAppGroups.find(group => group.id === groupId);

    if (appGroup && appGroup.treeObjectId) {
      yield call(deleteAppGroupApi, token, graphEndpoint, appGroup.treeObjectId, appGroup.priority);
      // refresh user's app groups
      yield put(fetchUserAppGroupsStart());
    }
  } catch (e) {
    devErrorLogger(`Something went wrong while deleting application group: ${JSON.stringify(e)}`);
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong while deleting application group'
        : formatMessage({
            id: 'user-app-groups.delete-app-group-error-kio912',
            defaultMessage: 'Something went wrong while deleting application group',
            description: 'Error message when something went wrong while deleting application group',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

function* editAppGroupSaga(action: ReturnType<typeof editAppGroup>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const groupId = action.payload.groupId;
    const newGroupName = action.payload.newGroupName;
    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    if (groupId && newGroupName) {
      yield call(editAppGroupApi, token, graphEndpoint, groupId, newGroupName);
    }
  } catch (e) {
    devErrorLogger(`Something went wrong while editing application group: ${JSON.stringify(e)}`);
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong while editing application group'
        : formatMessage({
            id: 'user-app-groups.edit-app-group-error-ias109',
            defaultMessage: 'Something went wrong while editing application group',
            description: 'Error message when something went wrong while editing application group',
          }),
      severity: MessageSeverity.Error,
    };

    yield put(showMessage(message));
  }
}

function* updateGroupPrioritySaga(action: ReturnType<typeof updateGroupPriority>) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const groupId = action.payload.groupId;
    const newPriority = action.payload.newPriority;
    const prevPriority = action.payload.prevPriority;
    const userAppGroups: TUserAppGroup[] = yield select(selectUserAppGroups) || [];

    const graphEndpoint: string = yield select(graphEndpointSelector);
    const token: string = yield select(sessionTokenSelector);
    const appGroup = userAppGroups.find(group => group.id === groupId);

    if (appGroup && newPriority !== undefined && prevPriority !== undefined) {
      yield call(updateGroupPriorityApi, token, graphEndpoint, newPriority, prevPriority, appGroup);
    }
  } catch (e) {
    devErrorLogger(`Something went wrong while updating application group order: ${JSON.stringify(e)}`);
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong while updating application group order'
        : formatMessage({
            id: 'user-app-groups.reoder-app-group-error-uas989',
            defaultMessage: 'Something went wrong while updating application group order',
            description: 'Error message when something went wrong while reordering application group',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(fetchUserAppGroupsStart.type, fetchUserGroupsSaga),
    takeLatest(createNewAppGroupStart.type, createNewAppGroupStartSaga),
    takeLatest(addAppToGroup.type, addAppToGroupSaga),
    takeLatest(removeAppFromGroup.type, removeAppFromGroupSaga),
    takeLatest(deleteAppGroup.type, deleteAppGroupSaga),
    takeLatest(editAppGroup.type, editAppGroupSaga),
    takeLatest(updateGroupPriority.type, updateGroupPrioritySaga),
  ]);
}
