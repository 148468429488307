import { Fragment } from 'react';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Delete, NotInterested, Edit } from '@aiware/shared/icons';
import { formatBytes } from '@aiware/js/function';
import useStyles from './useStyles';
import FileItemIcon from './FileItemIcon';
import { IFile } from '../../../types';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import { LoadingStatus } from '@aiware/js/interfaces';

export interface FileItemProps {
  item: IFile;
  edit: boolean;
  showDivider?: boolean;
  onDelete?: (id: string) => void;
  onRetry?: (file: IFile) => void;
  onAbort?: (file: IFile) => void;
  onEdit?: (file: IFile) => void;
  uiStateStatus?: LoadingStatus;
}

function FileItem({
  item,
  edit,
  onDelete,
  onRetry,
  onAbort,
  onEdit,
  showDivider = true,
  uiStateStatus,
}: FileItemProps) {
  const { id, percentComplete, status, tdoStatus, size, type, meta } = item;
  let { name } = item;
  if (meta && meta.fileName) {
    name = meta.fileName;
  }

  const { classes } = useStyles();

  const failed = [status, tdoStatus].includes('failure');

  return (
    <Fragment>
      <ListItem data-test="dc-file-importer-file-upload-row" className={classes.listItem}>
        <ListItemAvatar>
          {failed ? (
            <Avatar data-test="file-item-failed-icon" className={classes.errorIcon}>
              <ErrorOutline color="error" />
            </Avatar>
          ) : (
            <Avatar data-test="file-item-avatar" className={classes.avatar}>
              <FileItemIcon filetype={type} />
            </Avatar>
          )}
        </ListItemAvatar>

        <ListItemText
          primary={
            <Tooltip title={name.length > 30 ? name : ''} placement="bottom-end">
              <Typography data-test="file-item-title" className={classes.filename} variant="body1">
                {name.length > 30 ? name.substring(0, 30) + '...' : name}
              </Typography>
            </Tooltip>
          }
          secondary={
            <Typography data-test="file-item-size" variant="caption" className={classes.caption}>
              {formatBytes(size)}
            </Typography>
          }
        />

        <ListItemSecondaryAction data-test="file-button-actions" className={classes.secondaryAction}>
          {failed && (
            <Tooltip title="Retry uploading">
              <IconButton data-test="file-item-retry" onClick={() => onRetry && onRetry(item)} size="large">
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
          )}

          {status === 'idle' && (
            <IconButton
              data-test="file-item-edit"
              disabled={!edit}
              onClick={() => onEdit && onEdit(item)}
              size="large"
            >
              <Edit />
            </IconButton>
          )}

          {(['failure', 'idle'].includes(status!) || uiStateStatus === 'failure') && (
            <IconButton data-test="file-item-delete" onClick={() => onDelete && onDelete(id)} size="large">
              <Delete />
            </IconButton>
          )}

          {status === 'pending' && (
            <>
              <LinearProgress
                data-test="file-item-progress"
                className={classes.progress}
                variant="determinate"
                value={percentComplete}
              />

              <IconButton
                data-test="file-item-abort"
                title="Cancel Import"
                onClick={() => onAbort && onAbort(item)}
                size="large"
              >
                <NotInterested />
              </IconButton>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      {showDivider && <Divider />}
    </Fragment>
  );
}

export default FileItem;
