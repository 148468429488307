import { makeStyles } from 'tss-react/mui';

export const useLookupStyles = makeStyles<void>()((theme, _props) => ({
  root: {
    fontFamily: 'Nunito',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 74px)',
    padding: theme.spacing(5, 6, 0, 6),
    [`& button`]: {
      textTransform: 'none',
      fontFamily: 'Nunito',
    },
    [`& input`]: {
      fontFamily: 'Nunito',
    },
    [`& textarea`]: {
      fontFamily: 'Nunito',
    },
    [`& fieldset`]: {
      borderColor: '#D5DFE9 !important',
    },
    [`&:placeholder`]: {
      color: '#9CA8B4 !important',
    },
  },
  addUserText: {
    marginBottom: '35px',
    cursor: 'text',
  },
  lookupDivider: {
    marginBottom: '30px !important',
  },
  lookupField: {
    width: '100%',
  },
  lookupContainer: {
    position: 'relative',
    [`& button`]: {
      position: 'absolute',
      right: '20px',
      top: '14px',
      fontSize: '12px',
      height: '28px',
      width: '53px',
      fontWeight: 600,
      color: '#1871E8',
      borderColor: '#1871E8',
      minWidth: 'unset !important',
    },
  },
  messageContainer: {
    position: 'relative',
    marginBottom: '19px',
  },
  messageField: {
    width: '100%',
  },
  messageCharCount: {
    position: 'absolute',
    bottom: '8px',
    right: '11px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9CA8B4',
  },
  sendButton: {
    float: 'right',
    [`&:disabled`]: {
      color: '#9CA8B4 !important',
      background: '#D5DFE9 !important',
    },
  },
  cardsContainer: {
    overflowY: 'scroll',
    height: 'calc(100% - (95px + 56px))',
    marginTop: '20px',
  },
  whiteBackgroupContainer: {
    background: 'white',
  },
  lookupCardsContainer: {
    height: 'calc(100% - 212px - 54px)',
  },
  nonAdminInviteMessageContainer: {
    color: 'white',
    background: 'rgb(107,155,232)',
    padding: '10px',
    borderLeft: '5px solid #1772E8',
    marginBottom: '25px',
    marginTop: '-21px',
  },
  nonAdminInviteMessageTitle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  nonAdminInviteMessageText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    cursor: 'text',
  },
}));

const applicationsText = {
  fontSize: '10px',
  lineHeight: '16px',
  marginTop: '14px',
  fontWeight: 600,
  marginBottom: '0',
};

export const useCardStyles = makeStyles<void>()((_theme, _props) => ({
  root: {
    marginBottom: '20px',
    width: '100%',
    borderRadius: '4px',
    border: '0.5px solid rgba(213, 223, 233, 1)',
    boxShadow: 'none !important',
    [`& .Sdk-MuiCardContent-root`]: {
      padding: '20px',
      boxShadow: 'none',
    },
    [`& button`]: {
      textTransform: 'none',
    },
    [`& .Sdk-MuiDivider-root`]: {
      borderWidth: '0.5px',
      borderBottom: '0',
      borderColor: 'rgb(213, 223, 233)',
    },
    [`& .Sdk-MuiCardActions-root`]: {
      display: 'flex',
      padding: '15px',
      justifyContent: 'center',
      [`& button`]: {
        padding: 0,
      },
    },
  },
  userCardUserInfo: {
    display: 'flex',
  },
  userCardUserInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexRow: {
    display: 'flex',
    [`& .Sdk-MuiAvatar-root`]: {
      marginRight: '12px',
      background: 'rgba(0, 0, 0, 0.05)',
      color: 'black',
      fontSize: '16px',
    },
  },
  userCardName: {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#2A323C',
    margin: 0,
  },
  userCardEmail: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#5C6269',
    margin: 0,
  },
  badgeContainer: {
    [`& div`]: {
      float: 'right',
    },
  },
  noApplications: {
    color: '#9CA8B4',
    ...applicationsText,
  },
  hasApplications: {
    color: '#2A323C',
    ...applicationsText,
  },
  isPending: {
    color: 'rgba(92, 98, 105, 1)',
    ...applicationsText,
  },
  actionButtonWrapper: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    [`& button`]: {
      fontSize: '12px',
    },
    [`& svg`]: {
      fontSize: '16px !important',
    },
  },
  removeUserButton: {
    color: '#2A323C !important',
  },
}));
