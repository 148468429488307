import { createSelector } from '@reduxjs/toolkit';
import { LoadingStatus } from '@aiware/js/interfaces';
import type { IApplication, IApplications } from '../../../types';
import {
  selectUserOrgHideCmsFromAppList,
  selectUserOrgHideAdminFromAppList,
  AuthState,
} from '@aiware/shared/redux';
import { hubRoleSelector, TState } from '../user/selector';

export const namespace = 'applications';

interface IAppSelector {
  aiWareHeaderBarPanel: {
    applications: {
      application: IApplication[];
      status: LoadingStatus;
      message: string;
      selectedApp: IApplication;
    };
  };
}

export const applicationSelector: (state: IAppSelector) => IApplications = state =>
  state?.aiWareHeaderBarPanel?.[namespace];

export const visibleApplicationsSelector: (state: IAppSelector & AuthState & TState) => IApplication[] =
  createSelector(
    [
      applicationSelector,
      selectUserOrgHideCmsFromAppList,
      selectUserOrgHideAdminFromAppList,
      hubRoleSelector,
    ],
    ({ application }, hideCmsFromAppList, hideAdminAppFromAppList, hubRole) =>
      (application || []).filter(
        app =>
          !(hideCmsFromAppList && app.key === 'cms') &&
          !(hideAdminAppFromAppList && app.key === 'admin') &&
          !(hubRole?.name !== 'Hub Admin' && app.key === 'hubcentral')
      )
  );

export const selectAppFromStore: (state: IAppSelector) => any = state =>
  state?.aiWareHeaderBarPanel?.[namespace].selectedApp;
