import { selectApiConfigs } from '../aiAutocomplete/aiAutocomplete.selectors';
import { put, select } from 'redux-saga/effects';
import { GraphQLPage, ILibrary } from '../../types/getLibraryIds.types';
import { getLibraryIdsQuery } from '../../api/queries';
import { getLibraryIdsActions } from './getLibraryIds.slice';

export function* getLibraryIdsSaga() {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const limit = 100;
    let offset = 0;
    let libraryIds = [] as ILibrary[];

    // until res count is less than limit
    // increase offset by limit and fetch libraries

    while (true) {
      const res: GraphQLPage<ILibrary> = yield getLibraryIdsQuery(apiConfigs, offset, limit);
      libraryIds = [...libraryIds, ...(res?.records ?? [])];
      if (res.count !== limit) {
        break;
      }

      offset += limit;
    }

    yield put(getLibraryIdsActions.getLibraryIdsSuccess({ libraryIds }));
  } catch (e) {
    yield put(getLibraryIdsActions.getLibraryIdsFailed());
    console.log(e);
  }
}
