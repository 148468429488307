import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  tabsParent: {
    paddingLeft: '7px',
    borderBottom: '1px solid rgb(219, 226, 234)',
    '& div': {
      padding: theme.spacing(0, 0, 0, 1.6),
      borderBottom: 'none',
    },
    '& div button': {
      flex: '0 1 auto !important',
    },
    '& .Sdk-MuiTabs-root': {
      paddingLeft: 0,
    },
    '& .Sdk-MuiTabs-fixed': {
      paddingLeft: 0,
    },
    '& .Sdk-MuiTabs-flexContainer': {
      paddingLeft: 0,
    },
  },
  tabsContainer: {
    '& .Sdk-Mui-selected': {
      color: 'black',
    },
  },
  tabContent: {
    padding: '0 30px',
    maxHeight: 'calc(100vh - 415px)',
    overflowY: 'scroll',
  },
  groupMemberCardsContainer: {
    padding: '0 20px',
    overflowY: 'scroll',
    '& > div': {
      borderBottom: '1px solid #D5DFE9',
    },
    height: 'calc(100vh - 55px - 150px - 55px)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  groupInfoContainer: {
    padding: '20px 30px',
  },
}));
