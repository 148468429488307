import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  panelHeaderTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.edit-metadata-panel.title"
      defaultMessage="Edit Metadata"
      description="Panel header for the Data Center Edit Metadata Panel"
    />
  ),
  closePanel: () => (
    <FormattedMessage
      id="os-data-center-browse.edit-metadata-panel.close-panel"
      defaultMessage="Close"
      description="Close panel icon for the Data Center Edit Metadata Panel"
    />
  ),
  cancel: () => (
    <FormattedMessage
      id="os-data-center-browse.edit-metadata-panel.cancel"
      defaultMessage="Cancel"
      description="Close button on the panel controls"
    />
  ),
  saveChanges: () => (
    <FormattedMessage
      id="os-data-center-browse.edit-metadata-panel.saveChasnges"
      defaultMessage="Save Changes"
      description="Save button on the panel controls"
    />
  ),
  panelTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.edit-metadata-panel.title"
      defaultMessage="Edit Metadata"
      description="Title for the edit metadata panel"
    />
  ),
  errorStateTitle: (
    <FormattedMessage
      id="os-data-center-browse.edit-metadaata-panel.error-title"
      defaultMessage="Media not found"
      description="Data Center File Info Panel Error media not found message"
    />
  ),
  errorStateSubtitle: (
    <FormattedMessage
      id="os-data-center-browse.edit-metadata-panel.error-subtitle"
      defaultMessage="We couldn't find this file or you may not have permission to access this file."
      description="Data Center File Info Panel Error media not found message"
    />
  ),
  fields: {
    filename: {
      label: () => (
        <FormattedMessage
          id="os-data-center-browse.edit-metadata-panel.fields.filename.title"
          defaultMessage="File name"
          description="Label for the file name field"
        />
      ),
    },
    thumbnail: {
      label: () => (
        <FormattedMessage
          id="os-data-center-browse.edit-metadata-panel.fields.thumbnail.title"
          defaultMessage="Thumbnail Image"
          description="Label for the thumbnail image field"
        />
      ),
    },
    tags: {
      label: () => (
        <FormattedMessage
          id="os-data-center-browse.edit-metadata-panel.fields.tags.title"
          defaultMessage="Tags"
          description="Label for the tags field"
        />
      ),
      description: () => (
        <FormattedMessage
          id="os-data-center-browse.edit-metadata-panel.fields.tags.description"
          defaultMessage="Add searchable tags to better organize this data. Separate each tag with a comma."
          description="Description for the tags field"
        />
      ),
    },
    time: {
      label: () => (
        <FormattedMessage
          id="os-data-center-browse.edit-metadata-panel.fields.time.title"
          defaultMessage="Media Display Time"
          description="Label for the time field"
        />
      ),
      description: () => (
        <FormattedMessage
          id="os-data-center-browse.edit-metadata-panel.fields.time.description"
          defaultMessage="The actual time the content occured."
          description="Description for the time field"
        />
      ),
    },
  },
};
