import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import { EntityType } from '@aiware/js/interfaces';

import { actions } from '../../../redux/slices';
import {
  selectCurrentView,
  selectFilePickerFiles,
  selectIsFilePickerMode,
  selectIsFluffy,
  selectUIStateStatus,
} from '../../../redux/selectors/ui-state';
import { selectApiConfigs } from '../../../redux/selectors';
import {
  selectActiveFolder,
  selectMyFilesBreadCrumbActive,
  selectMyFilesBreadCrumbsViewModel,
  selectRootFolderId,
  selectSelectedFolderId,
  selectSelectedItemId,
  selectSelectedItems,
} from '../../../redux/selectors/view-my-files';
import { selectTdoEntities } from '../../../redux/selectors/entities';
import { EAllowTDOSelection, ITdo, ITdoVm } from '../../../types';
import { DataCenterView } from '@aiware/shared/redux';
import ContentLayout from '../ContentLayout';
import PathPicker from '../PathPicker';
import FoldersAndFiles from '../my-files-view/FoldersAndFiles';
import { AddNewFolder } from '../../index';
import NewFolderAction from './NewFolderAction';
import { getDataCenterBrowseModule } from '../../../redux';
import Footer from './Footer';
import { selectAddNewFolder } from '../../../redux/selectors/panel-add-folder';
import { Box } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  panel: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    height: '100%',
  },
  wrapper: {
    height: 'calc(100% - 50px)',
  },
}));

interface IContent {
  hideNewFolderButton?: boolean;
  panelRendered: boolean;
  viewType: number;
  hidePanel?: () => void;
  tdoId?: string;
  onSelect?: (selectedContent: string | ITdoVm | ITdoVm[]) => void;
  selectHomeText?: JSX.Element;
  selectItemText?: JSX.Element;
  allowTDOSelection: EAllowTDOSelection;
}

export const Content: FunctionComponent<IContent> = ({
  hideNewFolderButton,
  panelRendered,
  viewType,
  hidePanel,
  tdoId,
  onSelect,
  selectHomeText,
  selectItemText,
  allowTDOSelection,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { apiRoot, graphQLEndpoint, token } = useSelector(selectApiConfigs);
  const breadCrumbs = useSelector(selectMyFilesBreadCrumbsViewModel);
  const activeCrumb = useSelector(selectMyFilesBreadCrumbActive);

  // Selected TDOs State
  const rootFolderId = useSelector(selectRootFolderId);
  const allTDOs = useSelector(selectTdoEntities);
  const addFolderPanel = useSelector(selectAddNewFolder);
  const selectedFolderId = useSelector(selectSelectedFolderId);
  const filePickerFiles = useSelector(selectFilePickerFiles);
  const selectedItems = useSelector(selectSelectedItems);

  // UI State
  const activeFolder = useSelector(selectActiveFolder);
  const [originalActiveFolder, setOriginalActiveFolder] = useState(activeFolder?.id);
  const uiStateStatus = useSelector(selectUIStateStatus);
  const currentView = useSelector(selectCurrentView);
  const isFilePickerMode = useSelector(selectIsFilePickerMode);
  const isFluffy = useSelector(selectIsFluffy);
  const isMultipleFilesSelected = selectedItems.length > 1;
  const isMoveTDOMode = !!tdoId;

  const getTDOsById = (ids: string[]) => {
    const result: ITdo[] = [];
    ids.forEach(id => {
      allTDOs[id] && result.push(allTDOs[id]!);
    });
    return result;
  };

  const handleMoveTDOs = () => {
    if (isMultipleFilesSelected) {
      // Move multiple TDOs
      const tdoArray = getTDOsById(selectedItems);
      console.log('UI: selectedFolderId - ', selectedFolderId);
      dispatch(actions.tdos.moveMultipleTDOsNewFolder({ tdos: tdoArray, selectedFolderId }));
    } else {
      if (tdoId) {
        // Move a Single TDO
        const tdo = allTDOs[tdoId]!;
        dispatch(actions.tdos.moveTdoNewFolder({ tdo, selectedFolderId }));
      }
    }
  };

  // We need to remember what the original folder the User is browsing
  // when they open the panel, to prevent Data Center from switching to the
  // folder the User browses to within the panel. This is an edge case, if they hit "cancel"
  // after navigating within Data Center, the view changes to that folder. This will prevent that.
  useEffect(() => {
    setOriginalActiveFolder(activeFolder?.id);
  }, []);
  useEffect(() => {
    if (allowTDOSelection !== EAllowTDOSelection.none) {
      dispatch(actions.uiState.setPanelAllowTdoSelection(allowTDOSelection));
    }
  }, [allowTDOSelection]);

  useEffect(() => {
    if (currentView !== viewType) {
      dispatch(
        actions.uiState.setPanelView({
          view: DataCenterView.folders,
          panelMode: true,
        })
      );
      if (isFluffy) {
        dispatch(actions.uiState.toggleFluffyView());
      }
    }
  }, [dispatch, viewType, currentView]);

  useEffect(() => {
    // this only runs on mount - which is important for being able to reset view on unmount to the view when this panel was opened.
    const dataCenterViewOnOpen = currentView;
    return () => {
      dispatch(
        actions.uiState.setPanelView({
          view: dataCenterViewOnOpen,
          panelMode: false,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (!rootFolderId) {
      dispatch(actions.viewMyFiles.rootFolderFetchStart());
    }
  }, [rootFolderId, dispatch]);

  useEffect(() => {
    if (uiStateStatus === 'pending' && apiRoot && graphQLEndpoint && token) {
      dispatch(actions.uiState.init());
    }
  }, [uiStateStatus, apiRoot, graphQLEndpoint, token, dispatch]);

  if (!rootFolderId) {
    return null;
  }

  if (!apiRoot || !graphQLEndpoint || !token || !panelRendered) {
    return null;
  }

  const parentFolderId = activeCrumb.isRoot ? rootFolderId : activeCrumb.id || '';

  const resetPanelView = () => {
    dispatch(
      actions.uiState.setPanelView({
        view: DataCenterView.myFiles,
        panelMode: false,
      })
    );
  };

  const isFolderSelected = !!selectedFolderId;

  const isButtonDisabled = () => {
    if (isFilePickerMode) {
      return filePickerFiles?.length === 0;
    }
    return false;
  };

  const handlePrimaryButtonClick = () => {
    // Handle File Picker Panel
    if (isFilePickerMode) {
      if (onSelect) {
        if (filePickerFiles?.length === 0) return;

        const isSingleMode = allowTDOSelection === EAllowTDOSelection.single;

        // Allow only a single file if in single mode
        onSelect(isSingleMode ? filePickerFiles[0]! : filePickerFiles);

        // Clear the files
        dispatch(actions.uiState.clearFileInPickerArray());
        dispatch(actions.viewMyFiles.resetSelectedItems());
        dispatch(actions.uiState.setPanelAllowTdoSelection(EAllowTDOSelection.none));
      }

      return;
    }

    if (onSelect) {
      onSelect(selectedFolderId || rootFolderId);
    } else if (isMoveTDOMode) {
      if (selectedFolderId === null) {
        dispatch(actions.viewMyFiles.setSelectedFolder(rootFolderId));
      }
      handleMoveTDOs();
      handleHidePanel();
    } else {
      resetPanelView();
      dispatch(actions.viewMyFiles.panelResultSelected(selectedFolderId || rootFolderId));
    }
  };

  const handleHidePanel = () => {
    resetPanelView();
    hidePanel && hidePanel();

    // Here we reset Data Center to the original breadcrumb when closing the panel.
    dispatch(
      actions.viewMyFiles.goToMyFilesBreadCrumb({
        isRoot: false,
        id: originalActiveFolder,
        entity: EntityType.Folders,
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          '& .folder-table-header-whitespace': {
            display: 'none',
          },
          '& .select-file-checkbox-container': isFilePickerMode
            ? {}
            : {
                display: 'none',
              },
          '& .table-row-item-file': isFilePickerMode
            ? {}
            : {
                pointerEvents: 'none',
              },
        }}
        className={classes.wrapper}
      >
        <ContentLayout
          breadcrumbs={
            <PathPicker
              pathList={breadCrumbs}
              goToCrumbActionCreator={actions.viewMyFiles.goToMyFilesBreadCrumb}
            />
          }
          actions={hideNewFolderButton ? <div /> : <NewFolderAction entityId={parentFolderId} />}
          table={<FoldersAndFiles parentFolderId={parentFolderId} />}
        />
        <AddNewFolder className={classes.panel} />
      </Box>
      {/* if add folder panel is open then hide footer*/}
      {!addFolderPanel?.parentFolderId && (
        <Footer
          disablePrimaryBtn={isButtonDisabled()}
          onClickPrimaryBtn={handlePrimaryButtonClick}
          onClosePanel={handleHidePanel}
          isFolderSelected={isFolderSelected}
          selectHomeText={selectHomeText}
          selectItemText={selectItemText}
        />
      )}
    </>
  );
};

export interface IFoldersAndFilesPanel {
  dataId: string;
  panelRendered: boolean;
  hidePanel?: () => void;
  viewType: number;
  tdoId?: string;
  onSelect?: (selectedContent: string | ITdoVm | ITdoVm[]) => void;
  selectHomeText: JSX.Element;
  selectItemText: JSX.Element;
  hideNewFolderButton?: boolean;
  allowTDOSelection?: EAllowTDOSelection;
}

export const FoldersAndFilesPanel: FunctionComponent<IFoldersAndFilesPanel> = ({
  dataId,
  panelRendered,
  hidePanel,
  viewType,
  tdoId,
  onSelect,
  selectHomeText,
  selectItemText,
  hideNewFolderButton,
  allowTDOSelection = EAllowTDOSelection.none,
}) => {
  return (
    <DynamicModuleLoader modules={[getDataCenterBrowseModule()]}>
      <AIWareIntlProvider style={{ height: '100%' }}>
        <Content
          hideNewFolderButton={hideNewFolderButton}
          panelRendered={panelRendered}
          tdoId={tdoId}
          viewType={viewType}
          hidePanel={hidePanel}
          onSelect={onSelect}
          selectHomeText={selectHomeText}
          selectItemText={selectItemText}
          allowTDOSelection={allowTDOSelection}
        />
      </AIWareIntlProvider>
    </DynamicModuleLoader>
  );
};

export default FoldersAndFilesPanel;
