import createSvgIcon from '../createSvgIcon';

export const SharableWhite = createSvgIcon(
  <svg width="40" height="40" viewBox="7 7 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="1870E8" />
    <path
      d="M22 17V13L29 20L22 27V22.9C17 22.9 13.5 24.5 11 28C12 23 15 18 22 17Z"
      fill="white"
      stroke="white"
    />
  </svg>,
  'SharableWhite'
);
