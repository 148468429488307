import { createSelector } from '@reduxjs/toolkit';
import nameHelper from '../../helpers/nameHelper';
import { EntityType } from '@aiware/js/interfaces';
import { Breadcrumb, IFileVm, TDODetails, ScheduleId, SourceId } from '../../types';
import { DataCenterState, namespace } from '../slices';
import {
  selectSourceEntities,
  selectScheduleEntities,
  selectTdoEntities,
  selectEngineCategoryEntities,
  selectEngineEntities,
} from './entities';
import { mapTdoListToViewModels } from './helpers';

export const selectPageSources = (state: DataCenterState) => state[namespace].viewStreams.sources;

export const selectPageSchedulesBySourceId = (state: DataCenterState, sourceId: SourceId) =>
  state[namespace].viewStreams.schedulesBySource[sourceId];

export const selectPageTdosByScheduleId = (state: DataCenterState, scheduleId: ScheduleId) =>
  state[namespace].viewStreams.tdosBySchedule[scheduleId];

export const selectPageTdos = (state: DataCenterState) => state[namespace].viewStreams.tdosBySchedule;

export const selectSourceTreeBreadCrumbs = (state: DataCenterState) =>
  state[namespace].viewStreams.breadCrumbs;

export const selectSourceTreeBreadCrumbsViewModel: (state: DataCenterState) => Breadcrumb[] = createSelector(
  [selectSourceTreeBreadCrumbs, selectSourceEntities, selectScheduleEntities],
  (breadCrumbs, sourceEntities, scheduleEntities) => {
    return breadCrumbs.map(crumb => {
      if (crumb.id && crumb.entity === EntityType.Sources) {
        const source = sourceEntities[crumb.id];

        return {
          ...crumb,
          name: source?.name || nameHelper(source?.id, source?.modifiedDateTime),
        };
      }

      if (crumb.id && crumb.entity === EntityType.Schedules) {
        const schedule = scheduleEntities[crumb.id];

        return {
          ...crumb,
          name: schedule?.name || nameHelper(schedule?.id, schedule?.modifiedDateTime),
        };
      }

      return crumb;
    });
  }
);

export const selectSourceTreeBreadCrumbActive: (state: DataCenterState) => Breadcrumb = createSelector(
  [selectSourceTreeBreadCrumbs],
  breadCrumbs => {
    return breadCrumbs[breadCrumbs.length - 1]!;
  }
);

export const selectCurrentTdoTableDetails: (state: DataCenterState) => TDODetails<string> | null =
  createSelector(
    [
      selectSourceTreeBreadCrumbActive,
      selectPageTdos,
      selectTdoEntities,
      selectEngineCategoryEntities,
      selectEngineEntities,
    ],
    (activeCrumb, pageTdo, tdoEntities, engineCategoryEntities, engineEntities) => {
      const parentScheduleId = activeCrumb.entity === EntityType.Schedules ? activeCrumb.id : null;
      const tdoPage = parentScheduleId && pageTdo[parentScheduleId];

      if (tdoPage) {
        const tdoIds = tdoPage.ids;
        const tdos: IFileVm[] = mapTdoListToViewModels(
          tdoIds,
          tdoEntities,
          engineEntities,
          engineCategoryEntities
        );

        return { ...tdoPage, parentScheduleId, tdos };
      }

      return null;
    }
  );
