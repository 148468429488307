import createSvgIcon from '../createSvgIcon';

export const FullyNetworkIsolatedWhite = createSvgIcon(
  <svg width="20" height="20" viewBox="3 2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.42857 20.3571V20.9999H4.92857V20.3571H9.42857ZM6.85714 19.7142V18.7499H7.5V19.7142H6.85714ZM11.3571 17.9335C11.3571 17.9795 11.3389 18.0237 11.3063 18.0562C11.2738 18.0888 11.2296 18.1071 11.1836 18.1071H3.17357C3.12754 18.1071 3.08339 18.0888 3.05084 18.0562C3.01829 18.0237 3 17.9795 3 17.9335V16.8214H11.3571V17.9335ZM3 16.1785V11.2092C3 11.1632 3.01829 11.119 3.05084 11.0865C3.08339 11.0539 3.12754 11.0356 3.17357 11.0356H11.1836C11.2296 11.0356 11.2738 11.0539 11.3063 11.0865C11.3389 11.119 11.3571 11.1632 11.3571 11.2092V16.1785H3Z"
      fill="white"
    />
    <path
      d="M19.0714 12.3214V12.9643H14.5714V12.3214H19.0714ZM16.5 11.6786V10.7143H17.1429V11.6786H16.5ZM21 9.89786C21 9.94389 20.9817 9.98804 20.9492 10.0206C20.9166 10.0531 20.8725 10.0714 20.8264 10.0714H12.8164C12.7704 10.0714 12.7262 10.0531 12.6937 10.0206C12.6611 9.98804 12.6429 9.94389 12.6429 9.89786V8.78571H21V9.89786ZM12.6429 8.14286V3.17357C12.6429 3.12754 12.6611 3.08339 12.6937 3.05084C12.7262 3.01829 12.7704 3 12.8164 3H20.8264C20.8725 3 20.9166 3.01829 20.9492 3.05084C20.9817 3.08339 21 3.12754 21 3.17357V8.14286H12.6429Z"
      fill="white"
    />
    <path d="M7.17856 4.9285H10.3928V4.28564H6.53571V9.74993H7.17856V4.9285Z" fill="white" />
    <path d="M16.8214 18.7501H12.6429V19.393H17.4643V14.5715H16.8214V18.7501Z" fill="white" />
  </svg>,
  'FullyNetworkIsolatedWhite'
);
