import { takeEvery } from 'redux-saga/effects';
import { fetchApplicationConfigSaga, updateApplicationConfigSaga } from './application-config.saga';
import { actions } from '../slices';

export function* fetchApplicationConfigWatcher() {
  yield takeEvery(
    actions.userApplicationSettings.fetchApplicationConfigStart.type,
    fetchApplicationConfigSaga
  );
}

export function* updateApplicationConfigWatcher() {
  yield takeEvery(
    actions.userApplicationSettings.updateApplicationConfigStart.type,
    updateApplicationConfigSaga
  );
}
