import TextField from '@mui/material/TextField';
import { ChangeEvent, FC } from 'react';
import { useStyles } from '../fields.styles';

interface ITxtField {
  id: string;
  label: string;
  required?: boolean;
  type?: string;
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  multiline?: boolean;
}

export const TxtField: FC<ITxtField> = ({
  id,
  type,
  required,
  value,
  onChange,
  error = false,
  multiline = false,
}) => {
  const { classes } = useStyles();

  return (
    <TextField
      id={id}
      data-testid={id}
      data-test="os-admin-center.user-application-settings.input-element"
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
      required={required}
      value={value}
      type={type}
      rows={4}
      className={classes.field}
      onChange={onChange}
      size="small"
      multiline={multiline}
      error={error}
    />
  );
};
