import { logout, appSwitcherHandleClick } from '@aiware/js/constants';
import { IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';
import { AIWareThemeProvider } from '@aiware/shared/theme';
import { AIWareCacheProvider } from '@aiware/shared/theme';
import { AIWareIntlProvider } from '@aiware/shared/intl';
import 'cropperjs/dist/cropper.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicModuleLoader } from '@aiware/shared/dynamic-modules';
import SettingPanel from '../components/organisms/SettingPanel';
import { FormStateInterface } from '../components/organisms/SettingPanel/PersonalProfile';
import { getAppbarPanelModule } from '../store';
import { activitySelector, getActivity, updateActivityFilter } from '../store/modules/activity';
import { allActivitySeletor } from '../store/modules/activity/selector';
import { visibleApplicationsSelector, requestGetApplications } from '../store/modules/application';
import {
  getCurrentUser,
  resetBiometric,
  resetPassword,
  updateProfile,
  uploadAvatar,
  currentUserSelector,
} from '../store/modules/user';
import { getOpenIdModule } from '../store/modules/openId';
import {
  userRecentApplicationsSelector,
  updateUserRecentApplicationsStart,
  appIdSelector,
  selectDesktopUrl,
  showMessage,
  MessageSeverity,
  betaFeaturesSelector,
} from '@aiware/shared/redux';
import { FormattedMessage } from 'react-intl';
import { hidePanel } from '@aiware/js/panel';
import { setApplicationInStore } from '../store/modules/application/action';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

export interface SettingPanelComponentProps extends IVeritoneAppbarPanelConfig {
  name?: string;
  tabId: string | number;
  dataId?: string;
}

interface IAppItem {
  id: string | number;
  name: string;
  iconUrl: string;
  description: string;
  headerbarEnabled: boolean;
  applicationUrl: string;
}

function SettingPanelComponent_({ tabId }: SettingPanelComponentProps) {
  const dispatch = useDispatch();
  const applications = useSelector(visibleApplicationsSelector);
  const currentUser = useSelector(currentUserSelector)!;
  const { avatarHistory = [] } = currentUser || {};
  const activities = useSelector(activitySelector) || [];
  const { filter: activityFilter } = useSelector(allActivitySeletor);
  const [selectedTabId, setSelectedTabId] = useState<string | number>(0);
  const [recentApps, setRecentApp] = useState<Array<IAppItem>>([]);
  const [recentAppIds, setRecentAppIds] = useState<unknown[]>([]);

  const recentApplications: string[] = useSelector(userRecentApplicationsSelector);
  const desktopAppId = 'e4739d44-53d2-4153-b55f-5e246fc989b1';
  const isDesktop = useSelector(appIdSelector) === desktopAppId;
  const desktopUrl = useSelector(selectDesktopUrl);

  useEffect(() => {
    setRecentAppIds(recentApplications as unknown[]);
  }, [recentApplications]);

  useEffect(() => {
    dispatch(requestGetApplications());
    dispatch(getActivity());
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    setSelectedTabId(tabId || 0);
  }, [tabId]);

  useEffect(() => {
    if (recentAppIds.length > 0 && applications.length > 0) {
      const arrApps: IAppItem[] = [];
      for (const id of recentAppIds) {
        const app = applications.find(item => item.id === id) as IAppItem;
        if (app) {
          arrApps.push(app);
        }
      }
      setRecentApp(arrApps);
    }
  }, [recentAppIds, applications]);

  function onChangeActivityFilter(value: string) {
    dispatch(updateActivityFilter({ filter: value }));
  }

  function handleResetBiometric() {
    dispatch(resetBiometric());
  }

  function handleUpdateProfile(data: FormStateInterface) {
    dispatch(updateProfile(data));
  }

  function handleResetPassword(email: string) {
    dispatch(resetPassword({ email }));
  }

  function openAppInNewTab(id: string | number) {
    dispatch(appSwitcherHandleClick(`${id}`));
    dispatch(updateUserRecentApplicationsStart(`${id}`));
  }

  const errors = {
    invalidUrl: isEqual(process.env.NODE_ENV, 'development') ? (
      'Selected Application does not have a valid destination URL'
    ) : (
      <FormattedMessage
        id="os-app-bar-panel.desktop-app.switcher-error.invalid-url"
        defaultMessage="Selected Application does not have a valid destination URL. Application URL needs to be updated in Developer - Application Details"
        description="Error message when onboarded app does not have a valid URL"
      />
    ),
    urlValidationError: isEqual(process.env.NODE_ENV, 'development') ? (
      'Application loading has failed. Please try again later'
    ) : (
      <FormattedMessage
        id="os-app-bar-panel.desktop-app.switcher-error.url-validation-error"
        defaultMessage="Application loading has failed. Please try again later"
        description="Error message when onboarded app url validation does not pass"
      />
    ),
  };

  function validateUrl(appUrl: string): { error: boolean; message?: string | JSX.Element } {
    let url;

    try {
      url = new URL(appUrl);
    } catch (_) {
      return { error: true, message: errors.invalidUrl };
    }

    if (url.protocol === 'https:' || url.protocol === 'http:') {
      return { error: false };
    } else {
      return { error: true, message: errors.urlValidationError };
    }
  }

  const navigate = useNavigate();
  const betaFeatures = useSelector(betaFeaturesSelector);

  // TODO: this should be used in the desktop app at the /app/:id route
  function openAppInIframe(id: string | number, applicationUrl: string) {
    const { error, message } = validateUrl(applicationUrl);
    if (error && message) {
      dispatch(showMessage({ content: message as string, severity: MessageSeverity.Error }));
    } else {
      dispatch(hidePanel('APPLICATION_PANEL_ID'));
      const appToRender = applications.find(app => app.id === id);
      navigate(`/ui/app/${appToRender?.key}`);
      dispatch(setApplicationInStore(appToRender));
    }
  }

  function handleClickApplication(id: string | number) {
    const { headerbarEnabled, applicationUrl = '' } = applications.find(app => app.id === id) || {};
    if (isDesktop) {
      if (headerbarEnabled) {
        // new flow for the desktop app
        openAppInIframe(id, applicationUrl);
      } else {
        // legacy new-tab app opening
        openAppInNewTab(id);
      }
    } else if (headerbarEnabled && desktopUrl && betaFeatures) {
      const { key } = applications.find(app => app.id === id) || {};
      let destination = '';
      if (key) {
        destination = (desktopUrl.endsWith('/') ? '' : '/') + `ui/app/${key}`;
      } else {
        destination = (desktopUrl.endsWith('/') ? '' : '/') + `ui/app/${id}`;
      }
      window.open(desktopUrl + destination);
    } else {
      openAppInNewTab(id);
    }
  }

  function handleUploadAvatar(file: unknown) {
    dispatch(uploadAvatar(file));
  }
  function handleLogout() {
    dispatch(logout());
  }

  return (
    <AIWareCacheProvider>
      <AIWareThemeProvider>
        <AIWareIntlProvider>
          <DynamicModuleLoader modules={[getAppbarPanelModule(), getOpenIdModule()]}>
            <SettingPanel
              tabId={selectedTabId}
              currentUser={currentUser}
              activities={activities}
              activityFilter={activityFilter as string}
              onChangeActivityFilter={onChangeActivityFilter}
              handleResetBiometric={handleResetBiometric}
              handleResetPassword={handleResetPassword}
              handleLogout={handleLogout}
              handleUpdateProfile={handleUpdateProfile}
              organizationRole={currentUser?.organizationRole as { role: string; organizationId: string }}
              recentApp={recentApps}
              applications={applications}
              onClickApp={handleClickApplication}
              handleUploadAvatar={handleUploadAvatar}
              avatarHistory={avatarHistory}
            />
          </DynamicModuleLoader>
        </AIWareIntlProvider>
      </AIWareThemeProvider>
    </AIWareCacheProvider>
  );
}

function SettingPanelComponent(props: SettingPanelComponentProps) {
  return (
    <BrowserRouter>
      <SettingPanelComponent_ {...props} />
    </BrowserRouter>
  );
}

export { SettingPanelComponent };

export default SettingPanelComponent;
