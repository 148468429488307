import { createContext, useContext } from 'react';
import { TPermissionsEntity } from '@aiware/shared/permissions';

interface ISecureMenuContext {
  permissions: TPermissionsEntity | undefined;
  isLoadingPermissions: boolean;
  disabled?: boolean;
}

export const SecureMenuContext = createContext<ISecureMenuContext | undefined>(undefined);

export const SecureMenuProvider = SecureMenuContext.Provider;

export const useSecureMenuContext = (): ISecureMenuContext => {
  const context = useContext(SecureMenuContext);

  if (!context) {
    throw new Error('useSecureMenuContext must be used within the SecureMenuProvider');
  }

  return context;
};
