// fetches all available open id connectors
export const getAllOpenIdConnectorApi = async (
  API_URL: string,
  username: string,
  token?: string
): Promise<unknown> => {
  try {
    const res = await fetch(`${API_URL}/api/admin/openid/org/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      credentials: 'include',
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

// fetches only open id connectors that the user is linked his/her account to
export const getConnectedOpenIdConnectorApi = async (
  API_URL: string,
  username: string,
  token: string
): Promise<unknown> => {
  try {
    const res = await fetch(`${API_URL}/api/admin/openid/user/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      credentials: 'include',
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};
