import { DataCenterState } from '../../../../redux/slices';
import { EJSONSchemaStringFormat } from '../../types/aiStructuredData.types';

export const selectIsAllSchemasActive = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.isAllSchemasActive;
};

export const selectIsMatchValueValid = (state: DataCenterState) => {
  // Get operator
  const { matchValueOne, matchValueTwo, operator, selectedProperty } = state.dataCenter.aiStructuredData;

  const isFormComplete = (matchValueOne || matchValueOne === 0) && (matchValueTwo || matchValueTwo === 0);

  // Check if range
  if (operator === 'range' && selectedProperty && isFormComplete) {
    if (selectedProperty?.propertyDefinition?.type && selectedProperty.propertyDefinition.type === 'number') {
      // Check if invalid range

      return matchValueOne < matchValueTwo;
    }

    if (selectedProperty?.propertyDefinition?.format) {
      switch (selectedProperty.propertyDefinition.format) {
        // Check if Date one is behind date two
        case EJSONSchemaStringFormat.date:
        case EJSONSchemaStringFormat.dateTime: {
          return new Date(matchValueTwo as string) > new Date(matchValueOne as string);
        }
        default: {
          return true;
        }
      }
    }
  }
  return true;
};

export const selectSchemaSearchValue = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.schemaSearchValue;
};

export const selectSelectedSchema = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.selectedSchema;
};

export const selectSelectedSchemaPropertyApiPayload = (state: DataCenterState) => {
  if (!state.dataCenter.aiStructuredData.selectedSchema.dataRegistryId) {
    return null;
  }
  return {
    id: state.dataCenter.aiStructuredData.selectedSchema.dataRegistryId,
    majorVersion: parseInt(state.dataCenter.aiStructuredData.selectedSchema.majorVersion || '1'),
  };
};

export const selectPropertySearchValue = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.propertiesSearchValue;
};

export const selectSelectedProperty = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.selectedProperty;
};

export const selectOperator = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.operator;
};

export const selectMatchValueOne = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.matchValueOne;
};

export const selectMatchValueTwo = (state: DataCenterState) => {
  return state.dataCenter.aiStructuredData.matchValueTwo;
};

export const selectIsInputComplete = (state: DataCenterState) => {
  const { matchValueOne, matchValueTwo } = state.dataCenter.aiStructuredData;
  if (state.dataCenter.aiStructuredData.operator === 'range') {
    return (
      (matchValueOne || matchValueOne === 0 || matchValueOne === false) &&
      (matchValueTwo || matchValueTwo === 0)
    );
  } else {
    return !!(matchValueOne || matchValueOne === 0 || matchValueOne === false);
  }
};

export const selectStructuredDataInput = (state: DataCenterState) => {
  return {
    searchPath: state.dataCenter.aiStructuredData.selectedProperty.searchPath,
    propertyDefinition: state.dataCenter.aiStructuredData.selectedProperty.propertyDefinition,
    operator: state.dataCenter.aiStructuredData.operator,
    matchValueOne: state.dataCenter.aiStructuredData.matchValueOne?.toString() || '',
    matchValueTwo: state.dataCenter.aiStructuredData.matchValueTwo?.toString() || '',
  };
};
