import { Chip, SxProps, Typography, TypographyProps } from '@mui/material';

export const TableSearchBannerChip: React.FC<{
  label: string | JSX.Element;
  onDelete: () => void;
  sx?: SxProps;
  TypographyProps?: TypographyProps;
}> = ({ label, onDelete, sx = {}, TypographyProps = {} }) => {
  return (
    <Chip
      sx={{
        backgroundColor: 'rgb(255 255 255)',
        border: '1px solid #d5dfe9!important',
        ...sx,
      }}
      label={
        <Typography variant="caption" fontWeight="bold" {...TypographyProps}>
          "{label}"
        </Typography>
      }
      onDelete={onDelete}
    />
  );
};
