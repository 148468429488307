import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { Delete, MoveFolder, UserAddFilled } from '@aiware/shared/icons';
import { EntityContextMenu } from '../../types';
import { DataCenterView } from '@aiware/shared/redux';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { configSelector, selectIsOLPEnabled, selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { selectCurrentView } from '../../redux/selectors/ui-state';
import { injectIntl, WithIntlProps, WrappedComponentProps } from 'react-intl';
import { mountPanel } from '@aiware/js/panel';
import {
  actions as adminCenterActions,
  EResourceType,
  selectPermissionSets,
  selectPermissionSetsLoadingStatus,
} from '@aiware/os/admin-center/permissions';
import {
  actions as permissionsActions,
  selectors,
  hasPermission,
  EAuthResourceType,
  EPermissionAction,
} from '@aiware/shared/permissions';
import { selectSourceEntities, selectScheduleEntities } from '../../redux/selectors/entities';

const menuTypesWithPermissions: EAuthResourceType[] = [EAuthResourceType.Folder, EAuthResourceType.TDO];

const useStyles = makeStyles()(theme => ({
  menuList: {
    width: theme.spacing(34),
    padding: theme.spacing(1.5),
  },
  menuItem: {
    borderRadius: 4,
    padding: theme.spacing(1),
    '& > svg': {
      marginRight: theme.spacing(2),
    },
    textTransform: 'none',
  },
  divider: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
}));

type TProps = {
  anchorEl: HTMLButtonElement | null;
  anchorOriginX?: 'right' | 'left';
  id?: string;
  open: boolean;
  onClose: () => void;
  contextMenu: EntityContextMenu;
  entityId: string;
  type?: EAuthResourceType;
} & WrappedComponentProps;

const ContextMenu: FunctionComponent<TProps> = ({
  intl,
  contextMenu,
  anchorEl,
  anchorOriginX = 'left',
  id,
  open,
  onClose,
  entityId,
  type,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const configs = useSelector(configSelector);
  const baseUrl = configs.cmsAppUrl;
  const fullScreen = useSelector(selectIsFullscreenEnabled);
  const currentView = useSelector(selectCurrentView);
  const permissionSets = useSelector(selectPermissionSets);
  const permissionSetsLoadingStatus = useSelector(selectPermissionSetsLoadingStatus);
  const [permissionSetsFetchStarted, setPermissionSetsFetchStarted] = useState(false);
  const [appendedContextMenu, setAppendedContextMenu] = useState([...contextMenu.actions] as any[]);
  const [permissionsAppended, setPermissionsAppended] = useState(false);
  const isOLPEnabled = useSelector(selectIsOLPEnabled);
  const entityPermissions = useSelector(selectors.selectPermissionsByEntity(type, entityId));
  const Sources = useSelector(selectSourceEntities);
  const Schedules = useSelector(selectScheduleEntities);

  const isLoading =
    type &&
    entityId &&
    (!entityPermissions || entityPermissions.status === 'pending' || entityPermissions.status === 'idle');

  const isSourceDisabled = () => {
    if (type === EAuthResourceType.Source) {
      return Sources[entityId]!.permission === 'viewer';
    } else {
      return false;
    }
  };

  const isScheduleDisabled = () => {
    if (type === EAuthResourceType.Schedule) {
      // sometimes this comes back as empty string for schedules that can't be found because of aggregation and mapping
      return Schedules[entityId]?.permission === 'viewer' || Schedules[entityId]?.permission === '';
    } else {
      return false;
    }
  };

  useEffect(() => {
    /**
     * Permissions should already be loaded from the mouse enter
     * handler on the context menu button, but if they're not for
     * some reason, load them now
     */
    if (open && type && entityId && !entityPermissions) {
      dispatch(
        permissionsActions.fetchObjectPermissionsStart({
          entityType: type,
          entityId,
        })
      );
    }
  }, [dispatch, entityId, entityPermissions, open, type, isOLPEnabled]);

  useEffect(() => {
    if (permissionSetsLoadingStatus === 'idle' && !permissionSetsFetchStarted && isOLPEnabled) {
      dispatch(adminCenterActions.adminCenterPermissions.fetchOrgPermissionSetsStart());
      setPermissionSetsFetchStarted(true);
    } else if (permissionSets && type && menuTypesWithPermissions.includes(type)) {
      const relevantSets = permissionSets.filter(pS =>
        pS.permissions.find(p => p.includes(type.toUpperCase()))
      );
      if (
        relevantSets.length &&
        isOLPEnabled &&
        hasPermission(entityPermissions, EPermissionAction.Update) &&
        !permissionsAppended
      ) {
        setPermissionsAppended(true);
        const contextMenuWithPermissions = [...appendedContextMenu];
        contextMenuWithPermissions.push({
          name: () =>
            intl.formatMessage({
              id: `context-menu-permissions-item`,
              defaultMessage: 'Permissions',
              description: 'Permissions label in context menu',
            }),
          action: openPermissionsPanel,
          actionType: 'CALLBACK',
          Icon: UserAddFilled,
          getRedirectUri: null,
        });
        setAppendedContextMenu(contextMenuWithPermissions);
      }
    }
  }, [
    appendedContextMenu,
    entityPermissions,
    permissionSets,
    permissionSetsFetchStarted,
    permissionSetsLoadingStatus,
    permissionsAppended,
  ]);

  // FIXME: the following needs proper user permissions check before rendering Permissions Panel
  const openPermissionsPanel = () => {
    onClose();

    if (type) {
      dispatch(
        adminCenterActions.adminCenterPermissions.setSelectedResource({
          id: entityId,
          type: type as unknown as EResourceType,
        })
      );
    }
    const microFrontend = {
      name: 'PERMISSIONS_PANEL',
      config: {
        name: 'Permissions',
        permissionType: type,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: fullScreen ? 0 : 55,
      marginStart: fullScreen ? 0 : 80,
      size: 'medium',
      height: 'calc(100vh - 55px)',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: 'PERMISSIONS_PANEL',
        microFrontend,
        panelConfig,
      })
    );
  };

  const renderMenuItemWithPermissions = (itemProps: any) => {
    const { name, action, actionType, Icon, getRedirectUri, permissionRequired, key, testLabel } = itemProps;
    const isAllowed = !permissionRequired || !!hasPermission(entityPermissions, permissionRequired);
    const isDisabled = isLoading || !isAllowed || isSourceDisabled() || isScheduleDisabled();
    const tooltipText = isLoading
      ? SHARED_TEXT.checkingPermissionsTooltip()
      : isAllowed
      ? ''
      : SHARED_TEXT.noPermissionsTooltip();
    const onClick = isDisabled
      ? // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      : () => {
          if (getRedirectUri) {
            window.open(`${baseUrl}${getRedirectUri(entityId)}`, '_blank');
          } else if (!actionType || actionType === 'ACTION_CREATOR') {
            dispatch(action(entityId));
          } else {
            action(entityId);
          }
          onClose();
        };
    const menuItem = (
      <MenuItem
        key={key}
        onClick={onClick}
        className={classes.menuItem}
        data-test="context-menu-item"
        data-testid={`context-menu-item-${testLabel}`}
        disabled={isDisabled}
      >
        <Icon fontSize="small" />
        {name()}
      </MenuItem>
    );
    return tooltipText ? (
      <Tooltip key={key} title={tooltipText}>
        <span>{menuItem}</span>
      </Tooltip>
    ) : (
      menuItem
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: anchorOriginX,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
    >
      <MenuList
        role="menu"
        className={classes.menuList}
        onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
      >
        {(appendedContextMenu || []).map((menuItem, idx) => {
          return renderMenuItemWithPermissions({ ...menuItem, key: idx });
        })}

        {contextMenu.moveAction && !!contextMenu.actions.length && currentView === DataCenterView.myFiles && (
          <Divider className={classes.divider} />
        )}

        {contextMenu.moveAction &&
          currentView === DataCenterView.myFiles &&
          renderMenuItemWithPermissions({
            name: SHARED_TEXT.move,
            key: 'Move',
            action: contextMenu.moveAction,
            Icon: MoveFolder,
            permissionRequired: EPermissionAction.Update,
          })}
        {contextMenu.deleteAction && !!contextMenu.actions.length && <Divider className={classes.divider} />}
        {contextMenu.deleteAction &&
          renderMenuItemWithPermissions({
            name: SHARED_TEXT.delete,
            key: 'Delete',
            action: contextMenu.deleteAction,
            Icon: Delete,
            permissionRequired: EPermissionAction.Delete,
          })}
      </MenuList>
    </Popover>
  );
};

export default injectIntl(ContextMenu) as FunctionComponent<WithIntlProps<TProps>>;
