import createSvgIcon from '../createSvgIcon';
export const BottomQueue = createSvgIcon(
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8335 0.583496H19.1668V2.41683H11.8335V0.583496ZM11.8335 5.62516H19.1668V7.4585H11.8335V5.62516ZM11.8335 10.6668H19.1668V12.5002H11.8335V10.6668ZM0.833496 6.54183C0.833496 9.8235 3.51016 12.5002 6.79183 12.5002H7.25016V14.3335L10.0002 11.5835L7.25016 8.8335V10.6668H6.79183C4.50016 10.6668 2.66683 8.8335 2.66683 6.54183C2.66683 4.25016 4.50016 2.41683 6.79183 2.41683H10.0002V0.583496H6.79183C3.51016 0.583496 0.833496 3.26016 0.833496 6.54183Z"
      fill="#48516A"
    />
  </svg>,
  'BottomQueue'
);
