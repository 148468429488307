import { hidePanel, mountPanel } from '@aiware/js/panel';
import type { IMicroFrontend, IVeritoneAppbarPanelConfig } from '@aiware/js/interfaces';
import {
  hidePasswordResetSelector,
  preferredLanguageSelector,
  updateUserLanguageStart,
} from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { editIcon, resetIcon } from '@aiware/shared/assets';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { TabPanel, Tabs } from '@aiware/ui';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AvatarProfile from '../../../molecules/AvatarProfile';
import AccountLinking from './AccountLinking';
import useStyles from './useStyles';
import SHARED_TEXT from '../../../../helper/shared-text';
import { ProfileSettings } from './Settings/ProfileSettings';
import { userAuthStatusSelector, AuthStatus } from '../../../../store/modules/user';
import { userSelector } from '@aiware/shared/redux';
import { selectOpenIdConnectors } from '../../../../store/modules/openId/selector';
import * as actions from '../../../../store/modules/openId/action';
import { AuditLogWidget } from '@aiware/os/audit-log/widget';

export interface ICurrentUser {
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  userSettings?: { key: string; value: string }[];
  email?: string;
  lastUpdated?: string;
  avatarHistory?: string[];
  userId: string;
  id?: string;
  organizationRole?: { role: string; organizationId: string };
}

export interface FormStateInterface {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

type IWidgetConfig = Record<string, unknown>;

interface MyWindow extends Window {
  aiware: {
    mountWidget: (widgetConfig: IWidgetConfig) => void;
  };
}

interface Props {
  currentUser: ICurrentUser;
  handleResetBiometric: () => void;
  handleResetPassword: (email: string) => void;
  handleLogout: () => void;
  handleUpdateProfile: (formData: FormStateInterface) => void;
  handleUploadAvatar: (file: File) => void;
  avatarHistory: string[];
  enableResetBiometric?: boolean;
  loadingStatus?: string;
}

const PersonalProfile = ({
  currentUser,
  handleResetBiometric,
  handleResetPassword,
  handleLogout,
  handleUpdateProfile,
  handleUploadAvatar,
  avatarHistory = [],
  enableResetBiometric = false,
  loadingStatus,
}: Props) => {
  const panelId = 'EDIT_PROFILE_PICTURE';
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const scimConnectId = user?.scimConnectId;
  const fedEnvs = ['us-fed-1', 'us-fed-2'];
  const isFed = fedEnvs.find(env => window.location.href.includes(env));
  const openIdConnectors = useSelector(selectOpenIdConnectors) || [];
  const isAnyOpenIdRequired = openIdConnectors.some(connector => connector.required);
  const hidePasswordReset =
    useSelector(hidePasswordResetSelector) || isFed || scimConnectId || isAnyOpenIdRequired || false;
  const preferredLanguage: string = useSelector(preferredLanguageSelector) || 'en';
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });
  const [errorForm, setErrorForm] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isResetBiometric, setIsResetBiometric] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(0);
  const userAuthStatus = useSelector(userAuthStatusSelector) as AuthStatus;

  const formatMessage = AIWareFormatMessage(preferredLanguage);

  const onChangeTab = (id: number) => {
    setSelectedTabId(id);
  };

  useEffect(() => {
    if (currentUser) {
      fillData(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(actions.getOpenIdConnectors());
  }, [dispatch]);

  const tabsList = [
    {
      label: SHARED_TEXT.basicInfoTabLabel(),
      disabled: false,
      id: 'Basic Info',
    },
    {
      label: SHARED_TEXT.accountLinkingTabLabel(),
      disabled: false,
      id: 'Account Linking',
    },
    {
      label: SHARED_TEXT.activityTabLabel(),
      disabled: false,
      id: 'Activity',
    },
    {
      label: SHARED_TEXT.settingsTabLabel(),
      disabled: false,
      id: 'Settings',
    },
  ];

  const fillData = (currentUserInfo: ICurrentUser) => {
    const { firstName, lastName, imageUrl, userSettings } = currentUserInfo;
    const settingData = userSettings?.find(
      (item: { key: string; value: string }) => item.key === 'phoneNumber'
    );

    setFormData({
      firstName: firstName || '',
      lastName: lastName || '',
      phoneNumber: settingData?.value || '',
      email: '',
    });
    if (imageUrl) {
      setAvatar(imageUrl);
    }
  };

  const handleClickEdit = () => {
    setIsResetBiometric(false);
    setIsResetPassword(false);
    setIsEdit(true);
  };

  const handleClickPwReset = () => {
    setIsResetBiometric(false);
    setIsResetPassword(true);
    setIsEdit(false);
  };

  const handleClickResetBiometric = () => {
    setIsResetBiometric(true);
    setIsResetPassword(false);
    setIsEdit(false);
  };

  const handleCancelResetBiometric = () => {
    setIsResetBiometric(false);
  };

  const onResetBiometric = () => {
    setIsResetBiometric(false);
    handleResetBiometric();
  };

  const onResetPassword = useCallback(() => {
    if (currentUser?.email) {
      setIsResetPassword(false);
      handleResetPassword(currentUser?.email);
    }
  }, [currentUser, handleResetPassword]);

  const validForm = () => {
    let flag = true;
    if (!formData.firstName) {
      setErrorForm({ ...errorForm, firstName: 'This field is required.' });
      flag = false;
    }
    if (!formData.lastName) {
      setErrorForm({ ...errorForm, lastName: 'This field is required.' });
      flag = false;
    }
    if (formData.phoneNumber && !/^\+?(\d){6,}$/.test(formData.phoneNumber.toLowerCase())) {
      setErrorForm({ ...errorForm, phoneNumber: 'Invalid phone number.' });
      flag = false;
    }
    return flag;
  };

  function handleSaveEdit() {
    if (validForm()) {
      handleUpdateProfile(formData);
      setIsEdit(false);
    }
  }

  const handleCancelEdit = () => {
    if (currentUser) {
      fillData(currentUser);
    }
    setIsEdit(false);
  };

  const handleCancelPwReset = () => {
    setIsResetPassword(false);
  };

  const handleChangeForm = (type: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData(state => ({ ...state, [type]: value }));
    setErrorForm(state => ({ ...state, [type]: '' }));
  };

  const handleClickEditAvatar = () => {
    const appBarMicroFrontend: IMicroFrontend<unknown> = {
      name: 'EDIT_PROFILE_PICTURE',
      config: {
        name: 'Veritone',
        dataId: 'dataId',
        type: 'APP_BAR_PANEL_TEMPLATE',
        onClose: handleCloseEditAvatar,
        onSaveAvatar: onSaveAvatar,
        avatarHistory: avatarHistory,
      },
    };
    const veritonePanelConfig: IVeritoneAppbarPanelConfig = {
      panelId,
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: 55,
      withTab: false,
      marginStart: 490,
      width: 450,
      siblingPanelId: 'PERSONAL_PANEL',
      parentPanelId: 'PERSONAL_PANEL',
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };
    dispatch(
      mountPanel({
        panelId: panelId,
        microFrontend: appBarMicroFrontend,
        panelConfig: veritonePanelConfig,
      })
    );
  };

  const handleCloseEditAvatar = () => {
    dispatch(hidePanel(panelId));
  };

  const onSaveAvatar = (file: File) => {
    handleUploadAvatar(file);
    setTimeout(() => {
      dispatch(hidePanel(panelId));
    }, 500);
  };

  const handleUpdatePreferredLanguage = (language: string) => {
    dispatch(updateUserLanguageStart(language));
  };

  const loggingOut = [AuthStatus.LOGGING_OUT, AuthStatus.LOGGED_OUT].includes(userAuthStatus);

  return (
    <div className={classes.personalProfileContainer}>
      <div className={classes.firstBox}>
        <AvatarProfile
          avatar={avatar}
          sortName={`${formData.firstName?.substr(0, 1) || ''}${formData.lastName?.substr(0, 1) || ''}`}
          // setAvatar={setAvatar}
          onEditAvatarClick={handleClickEditAvatar}
          loadingStatus={loadingStatus}
        />
        <div className={classes.loggedBox}>
          <Typography variant="body2" className={classes.currentlyLoggedIn}>
            <FormattedMessage
              id="os-app-bar-panel.ch1heq"
              defaultMessage="Currently logged in as:"
              description="personal profile panel currently logged in label"
            />{' '}
            <span data-test="Currently-logged-in-as-email" className={classes.email}>
              {currentUser?.email}
            </span>
          </Typography>
        </div>
        <div className={classes.logoutBox}>
          <LoadingButton
            onClick={handleLogout}
            variant="outlined"
            color="primary"
            data-test="dt-button-logout"
            className={classes.btnLogin}
            loading={loggingOut}
          >
            <FormattedMessage
              id="os-app-bar-panel.bRcFCr"
              defaultMessage="Logout"
              description="label of logout button on profile panel"
            />
          </LoadingButton>
        </div>
        <div className={classes.tabsParent}>
          <Tabs selectedId={selectedTabId} onChangeTab={onChangeTab} tabsList={tabsList} />
        </div>
      </div>
      <div className={classes.secondBox}>
        {/*basic info tab*/}
        <TabPanel value={selectedTabId} index={0}>
          <>
            <div className={classes.headerBox}>
              <div>
                <Typography variant="body1" className={classes.manageBasicInfoLabel}>
                  <FormattedMessage
                    id="os-app-bar-panel.5oScQa"
                    defaultMessage="Manage Your Basic Information"
                    description="label of manage basic infomation container"
                  />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage
                    id="os-app-bar-panel.DjVGBt"
                    defaultMessage="Basic info, like your name and photo, that you use in your account."
                    description="description of manage basic infomation container"
                  />
                </Typography>
              </div>
              <div className={classes.editIconBox}>
                {!isEdit && (
                  <IconButton id="button-edit" onClick={handleClickEdit} size="large">
                    <img src={editIcon} alt="" />
                  </IconButton>
                )}
              </div>
            </div>
            <div className={classes.formBox}>
              {isEdit ? (
                <>
                  <div>
                    <TextField
                      id="firstName"
                      label={formatMessage({
                        id: 'os-app-bar-panel.firstName',
                        defaultMessage: 'First Name',
                        description: 'The label for first name',
                      })}
                      variant="outlined"
                      className={classes.textField}
                      value={formData?.firstName}
                      onChange={handleChangeForm('firstName')}
                      margin="normal"
                      error={errorForm.firstName !== ''}
                      helperText={errorForm.firstName}
                      InputProps={{
                        classes: {
                          input: classes.inputBase,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    <TextField
                      id="lastName"
                      label={formatMessage({
                        id: 'os-app-bar-panel.lastName',
                        defaultMessage: 'Last Name',
                        description: 'The label for last name',
                      })}
                      variant="outlined"
                      className={classes.textField}
                      value={formData?.lastName}
                      onChange={handleChangeForm('lastName')}
                      margin="normal"
                      error={errorForm.lastName !== ''}
                      helperText={errorForm.lastName}
                      InputProps={{
                        classes: {
                          input: classes.inputBase,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                    <TextField
                      id="phoneNumber"
                      label={formatMessage({
                        id: 'os-app-bar-panel.phone',
                        defaultMessage: 'Phone',
                        description: 'The label for phone',
                      })}
                      variant="outlined"
                      className={classes.textField}
                      value={formData?.phoneNumber}
                      onChange={handleChangeForm('phoneNumber')}
                      margin="normal"
                      error={errorForm.phoneNumber !== ''}
                      helperText={errorForm.phoneNumber}
                      InputProps={{
                        classes: {
                          input: classes.inputBase,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </div>
                  <DialogActions className={classes.actionForm}>
                    <Button
                      onClick={handleCancelEdit}
                      color="primary"
                      id="button-cancel-edit"
                      className={cx(classes.btnAction, classes.btnActionCancel)}
                    >
                      <FormattedMessage
                        id="os-app-bar-panel.qpPFGp"
                        defaultMessage="Cancel"
                        description="label of cancel button on profile panel"
                      />
                    </Button>

                    <Button
                      onClick={handleSaveEdit}
                      data-test="button-save-edit"
                      data-testid="button-save-edit"
                      color="primary"
                      variant="contained"
                      className={classes.btnAction}
                    >
                      <FormattedMessage
                        id="os-app-bar-panel.C1oyBr"
                        defaultMessage="Save"
                        description="label of save button on profile panel"
                      />
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <div className={classes.formItem}>
                    <Typography variant="body2" className={classes.titleItem}>
                      <FormattedMessage
                        id="os-app-bar-panel.a5aLpI"
                        defaultMessage="Name"
                        description="label of name field on profile panel"
                      />
                    </Typography>
                    <Typography data-test="profile-panel-name" variant="body1" className={classes.valueItem}>
                      {`${formData?.firstName || ''} ${formData?.lastName || ''}`}
                    </Typography>
                  </div>
                  {formData?.phoneNumber && (
                    <div className={classes.formItem}>
                      <Typography variant="body2" className={classes.titleItem}>
                        <FormattedMessage
                          id="os-app-bar-panel.73xPqY"
                          defaultMessage="Phone"
                          description="label of phone field on profile panel"
                        />
                      </Typography>
                      <Typography
                        data-test="profile-panel-phone"
                        variant="body1"
                        className={classes.valueItem}
                      >
                        {formData?.phoneNumber}
                      </Typography>
                    </div>
                  )}
                </>
              )}

              <div className={classes.formItem}>
                <Typography variant="body2" className={classes.titleItem}>
                  <FormattedMessage
                    id="os-app-bar-panel.zieKdP"
                    defaultMessage="Email"
                    description="label of email field on profile panel"
                  />
                </Typography>
                <Typography
                  data-test="profile-panel-email"
                  variant="body1"
                  className={cx(classes.valueItem, classes.disabledText)}
                >
                  {currentUser?.email}
                </Typography>
              </div>
              {isResetPassword ? (
                <>
                  <div>
                    <Typography variant="subtitle1" className={classes.pwResetTitle}>
                      <FormattedMessage
                        id="os-app-bar-panel.I7feCi"
                        defaultMessage="Password Reset"
                        description="reset password label on profile panel"
                      />
                    </Typography>
                    <Typography variant="body2" className={classes.pwResetDes1}>
                      <FormattedMessage
                        id="os-app-bar-panel.Zcj+4v"
                        defaultMessage="Veritone will send a reset password link via the email on your account."
                        description="reset password description on profile panel"
                      />
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage
                        id="os-app-bar-panel.kk8TY8"
                        defaultMessage="Would you like to continue?"
                        description="confirm message of reset biometric on profile panel"
                      />
                    </Typography>
                  </div>
                  <DialogActions className={classes.actionForm}>
                    <Button
                      onClick={handleCancelPwReset}
                      color="primary"
                      data-test="button-cancel-reset-password"
                      data-testid="button-cancel-reset-password"
                      className={cx(classes.btnAction, classes.btnActionCancel)}
                    >
                      <FormattedMessage
                        id="os-app-bar-panel.hdbMmi"
                        defaultMessage="Cancel"
                        description="the label of cancel button"
                      />
                    </Button>
                    <Button
                      onClick={onResetPassword}
                      data-test="button-reset-password"
                      data-testid="button-reset-password"
                      color="primary"
                      variant="contained"
                      className={classes.btnAction}
                    >
                      <FormattedMessage
                        id="os-app-bar-panel.ZXvsuR"
                        defaultMessage="Reset"
                        description="the label of reset button"
                      />
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  {!hidePasswordReset && (
                    <div className={cx(classes.formItem, classes.formItemPw)}>
                      <Typography variant="body2" className={classes.titleItem}>
                        <FormattedMessage
                          id="os-app-bar-panel.uIEJAQ"
                          defaultMessage="Password"
                          description="label of pasword field on profile panel"
                        />
                      </Typography>
                      <div className={classes.passwordBox}>
                        <Typography variant="body1" className={classes.valueItem}>
                          ********
                        </Typography>
                        {!hidePasswordReset && (
                          <IconButton
                            id="btn-click-pw-reset"
                            className={classes.iconResetPw}
                            onClick={handleClickPwReset}
                            size="large"
                          >
                            <img src={resetIcon} alt="" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  )}

                  <div>
                    <Typography variant="subtitle1" className={classes.lastUpdate}>
                      <FormattedMessage
                        id="os-app-bar-panel.qTqazR"
                        defaultMessage="Last updated"
                        description="last updated text label text on profile panel"
                      />{' '}
                      {currentUser?.lastUpdated
                        ? DateTime.fromISO(currentUser?.lastUpdated).toFormat('LLL dd, yyyy')
                        : DateTime.local().toFormat('LLL dd, yyyy')}
                    </Typography>
                  </div>
                </>
              )}
            </div>
            {isResetBiometric ? (
              <>
                <div className={classes.resetBiometric}>
                  <Typography variant="subtitle1" className={classes.pwResetTitle}>
                    <FormattedMessage
                      id="os-app-bar-panel.+DTt+G"
                      defaultMessage="Biometric Reset"
                      description="label of reset biometric container on profile panel"
                    />
                  </Typography>
                  <Typography variant="body1" className={classes.pwResetDes1}>
                    <FormattedMessage
                      id="os-app-bar-panel.nzzfrY"
                      defaultMessage="Your Biometric Profile will be reset."
                      description="description of reset biometric container on profile panel"
                    />
                  </Typography>
                  <Typography variant="body1">
                    <FormattedMessage
                      id="os-app-bar-panel.kk8TY8"
                      defaultMessage="Would you like to continue?"
                      description="confirm message of reset biometric on profile panel"
                    />
                  </Typography>
                </div>
                <DialogActions className={classes.actionForm}>
                  <Button
                    onClick={handleCancelResetBiometric}
                    data-test="button-cancel-reset-biometric"
                    color="primary"
                    className={cx(classes.btnAction, classes.btnActionCancel)}
                  >
                    <FormattedMessage
                      id="os-app-bar-panel.hdbMmi"
                      defaultMessage="Cancel"
                      description="the label of cancel button"
                    />
                  </Button>
                  <Button
                    onClick={onResetBiometric}
                    color="primary"
                    variant="contained"
                    data-test="button-reset-biometric"
                    className={classes.btnAction}
                  >
                    <FormattedMessage
                      id="os-app-bar-panel.ZXvsuR"
                      defaultMessage="Reset"
                      description="the label of reset button"
                    />
                  </Button>
                </DialogActions>
              </>
            ) : (
              !isResetPassword &&
              enableResetBiometric && (
                <div id="click-reset-biometric" onClick={handleClickResetBiometric}>
                  <Typography variant="body2" className={classes.reset}>
                    <FormattedMessage
                      id="os-app-bar-panel.OlJtmF"
                      defaultMessage="Reset Biometric Profile"
                      description="label of Reset Biometric Profile on profile panel"
                    />
                  </Typography>
                </div>
              )
            )}
          </>
        </TabPanel>
        {/*account linking tab*/}
        <TabPanel value={selectedTabId} index={1}>
          <AccountLinking openIdConnectors={openIdConnectors} />
        </TabPanel>
        <TabPanel value={selectedTabId} index={2}>
          <Stack sx={{ height: '500px' }}>
            <AuditLogWidget isSingleUserMode={true} />
          </Stack>
        </TabPanel>
        <TabPanel value={selectedTabId} index={3}>
          <ProfileSettings
            selectedLanguage={preferredLanguage}
            onPreferredLanguageChange={handleUpdatePreferredLanguage}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default PersonalProfile;
