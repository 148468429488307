import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FC } from 'react';
import { EngineFieldPicklistOptionType } from '../engineComponentLoader.types';

interface IMultiSelectList {
  id: string;
  label: string;
  values?: string[];
  required: boolean;
  options?: EngineFieldPicklistOptionType[];
  onChange: (value: any) => void;
  error?: boolean;
}

export const MultiSelectList: FC<IMultiSelectList> = ({
  id,
  values,
  required,
  options,
  onChange,
  error = false,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Select
      id={id}
      required={required}
      value={values}
      multiple
      onChange={onChange}
      variant="outlined"
      error={error}
      input={<Input id={`select-multiple-chip-${id}`} />}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderValue={(selected: any[]) => (
        <div>
          {selected?.map(value => (
            <Chip style={{ marginRight: 6 }} key={value} label={value} />
          ))}
        </div>
      )}
      MenuProps={MenuProps}
    >
      {options?.map(option => {
        return (
          <MenuItem key={option?.key} value={option?.value}>
            {option?.key}
          </MenuItem>
        );
      })}
    </Select>
  );
};
