import { FC } from 'react';
import Divider from '@mui/material/Divider';
import { useStyles } from './secureMenu.styles';

interface ISecureMenuItemDivider {
  visible?: boolean;
}

export const SecureMenuItemDivider: FC<ISecureMenuItemDivider> = ({ visible = true }) => {
  const { classes } = useStyles();

  if (!visible) return null;

  return <Divider className={classes.divider} />;
};
