import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  selectApiConfigs,
  showMessage,
  MessageSeverity,
  // preferredLanguageSelector,
} from '@aiware/shared/redux';
import {
  IApiCalls,
  IApiSingleCalls,
  ISelectors,
  ISingleSelectors,
  ISlice,
  ISingleSlice,
  IToastMessages,
} from './types';

export interface IProps<T> {
  slice: ISlice<T>;
  selectors: ISelectors<T>;
  apiCalls: IApiCalls<T>;
  toast?: IToastMessages;
}

export interface ISingleProps<T, A extends Record<string, unknown>> {
  slice: ISingleSlice<T, A>;
  selectors: ISingleSelectors<T, A>;
  apiCalls: IApiSingleCalls<T>;
  toast?: IToastMessages;
}

export const generateSagas = <T>(props: IProps<T>) => {
  const { slice, selectors, apiCalls } = props;
  const pageSize = apiCalls.pageSize || 30;
  const toast = props?.toast ?? false;

  const createSaga = function* (action: ReturnType<NonNullable<typeof slice.actions.createStart>>) {
    if (!apiCalls.create) {
      throw new Error('No apiCalls.create was defined for this redux saga');
    }
    try {
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const newItem: T = yield call(apiCalls.create as unknown as any, {
        apiConfigs,
        itemToCreate: action.payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.createSucceeded!(newItem));
      if (toast) {
        yield put(
          showMessage({
            content: toast.create.success,
            severity: MessageSeverity.Success,
          })
        );
      }
    } catch (err) {
      yield put(slice.actions.createFailed!(null));
      if (toast) {
        yield put(
          showMessage({
            content: toast.create.error,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  };

  const readSaga = function* (action: { type: string; payload: any }) {
    if (!apiCalls.read) {
      throw new Error('No apiCalls.read was defined for this redux saga');
    }
    try {
      const payload = action.payload;
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
      const offset: number = yield select(selectors.selectOffset);
      const searchValue: string = yield select(selectors.selectSearchValue);

      // Add filters
      const additionalSelectedData: any = {};
      const filters: any[] = yield select(selectors.selectFilters);
      additionalSelectedData['filters'] = filters;

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        additionalSelectedData[key] = selectedData;
      }

      const items: T[] = yield call(apiCalls.read as unknown as any, {
        apiConfigs,
        offset,
        limit: pageSize,
        payload,
        searchValue,
        additionalSelectors: additionalSelectedData,
      });

      // Filter out nulls that result from API errors
      yield put(slice.actions.readSucceeded!(items.filter(item => item !== null)));

      if (items.length >= pageSize) {
        yield put(slice.actions.setHasMore!(true));
      } else {
        yield put(slice.actions.setHasMore!(false));
      }

      yield put(slice.actions.setOffset!(offset + pageSize));
    } catch (err) {
      yield put(slice.actions.readFailed!(null));
    }
  };

  const updateSaga = function* (action: ReturnType<NonNullable<typeof slice.actions.updateStart>>) {
    if (!apiCalls.update) {
      throw new Error('No apiCalls.update was defined for this redux saga');
    }
    try {
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const updatedItem: T = yield call(apiCalls.update as unknown as any, {
        apiConfigs,
        itemToUpdate: action.payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.updateSucceeded!(updatedItem));
      if (toast) {
        yield put(
          showMessage({
            content: toast.update.success,
            severity: MessageSeverity.Success,
          })
        );
      }
    } catch (err) {
      yield put(slice.actions.updateFailed!(null));
      if (toast) {
        yield put(
          showMessage({
            content: toast.update.error,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  };

  const deleteSaga = function* (action: ReturnType<NonNullable<typeof slice.actions.deleteStart>>) {
    if (!apiCalls.delete) {
      throw new Error('No apiCalls.delete was defined for this redux saga');
    }
    try {
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const deletedItem: T = yield call(apiCalls.delete as unknown as any, {
        apiConfigs,
        itemToDelete: action.payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.deleteSucceeded!(deletedItem));
      yield put(slice.actions.resetList!(null));
      yield put(slice.actions.readStart!(null));
      if (toast) {
        yield put(
          showMessage({
            content: toast.delete.success,
            severity: MessageSeverity.Success,
          })
        );
      }
    } catch (err) {
      yield put(slice.actions.deleteFailed!(null));
      if (toast) {
        yield put(
          showMessage({
            content: toast.delete.error,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  };

  return {
    watchCreate: function* () {
      yield takeEvery(slice.actions.createStart!, createSaga);
    },
    watchRead: function* () {
      yield takeEvery(slice.actions.readStart!, readSaga);
    },
    watchUpdate: function* () {
      yield takeEvery(slice.actions.updateStart!, updateSaga);
    },
    watchDelete: function* () {
      yield takeEvery(slice.actions.deleteStart!, deleteSaga);
    },
  };
};

export const generateSingleSagas = <T, A extends Record<string, unknown> = Record<string, never>>(
  props: ISingleProps<T, A>
) => {
  const { slice, apiCalls } = props;
  const toast = props?.toast || false;

  const createSaga = function* (action: ReturnType<typeof slice.actions.createStart>) {
    if (!apiCalls.create) {
      throw new Error('No apiCalls.create was defined for this redux saga');
    }
    try {
      // const locale: string = yield select(preferredLanguageSelector);
      // const formatMessage = AIWareFormatMessage(locale)
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const newItem: T = yield call(apiCalls.create as unknown as any, {
        apiConfigs,
        item: action.payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.createSucceeded(newItem));
      if (toast) {
        yield put(
          showMessage({
            content: toast.create.success,
            severity: MessageSeverity.Success,
          })
        );
      }
    } catch (err) {
      yield put(slice.actions.createFailed());
      if (toast) {
        yield put(
          showMessage({
            content: toast.create.error,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  };

  const readSaga = function* (action: { type: string; payload: any }) {
    if (!apiCalls.read) {
      throw new Error('No apiCalls.read was defined for this redux saga');
    }
    try {
      const payload = action.payload;
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const item: T = yield call(apiCalls.read as unknown as any, {
        apiConfigs,
        payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.readSucceeded(item));
    } catch (err) {
      yield put(slice.actions.readFailed());
    }
  };

  const updateSaga = function* (action: ReturnType<typeof slice.actions.updateStart>) {
    if (!apiCalls.update) {
      throw new Error('No apiCalls.update was defined for this redux saga');
    }
    try {
      // const locale: string = yield select(preferredLanguageSelector);
      // const formatMessage = AIWareFormatMessage(locale)
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const updatedItem: T = yield call(apiCalls.update as unknown as any, {
        apiConfigs,
        item: action.payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.updateSucceeded(updatedItem));
      if (toast) {
        yield put(
          showMessage({
            content: toast.update.success,
            severity: MessageSeverity.Success,
          })
        );
      }
    } catch (err) {
      yield put(slice.actions.updateFailed());
      if (toast) {
        yield put(
          showMessage({
            content: toast.update.error,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  };

  const deleteSaga = function* (action: ReturnType<typeof slice.actions.deleteStart>) {
    if (!apiCalls.delete) {
      throw new Error('No apiCalls.delete was defined for this redux saga');
    }
    try {
      const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);

      // Add custom selectors
      const additionalSelectors = apiCalls.getAdditionalSelectors ? apiCalls.getAdditionalSelectors() : {};
      const additionalSelectedData = {};
      const additionalSelectorKeys = Object.keys(additionalSelectors);
      for (const key of additionalSelectorKeys) {
        // @ts-ignore
        const selectedData = yield select(additionalSelectors[key]);
        // @ts-ignore
        additionalSelectedData[key] = selectedData;
      }

      const deletedItem: T = yield call(apiCalls.delete as unknown as any, {
        apiConfigs,
        item: action.payload,
        additionalSelectors: additionalSelectedData,
      });

      yield put(slice.actions.deleteSucceeded(deletedItem));
      if (toast) {
        yield put(
          showMessage({
            content: toast.delete.success,
            severity: MessageSeverity.Success,
          })
        );
      }
    } catch (err) {
      yield put(slice.actions.deleteFailed(null));
      if (toast) {
        yield put(
          showMessage({
            content: toast.delete.error,
            severity: MessageSeverity.Error,
          })
        );
      }
    }
  };

  return {
    watchCreate: function* () {
      yield takeLatest(slice.actions.createStart, createSaga);
    },
    watchRead: function* () {
      yield takeLatest(slice.actions.readStart, readSaga);
    },
    watchUpdate: function* () {
      yield takeLatest(slice.actions.updateStart, updateSaga);
    },
    watchDelete: function* () {
      yield takeLatest(slice.actions.deleteStart, deleteSaga);
    },
  };
};
