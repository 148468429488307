import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme: Theme) => ({
  notiDetailContainer: {},
  root: {
    textAlign: 'left',
    position: 'relative',
  },
  header: {
    margin: '20px 30px',
  },
  mainTitle: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionIcons: {
    margin: theme.spacing(-2.5),
  },
  actionableBtnContainer: {
    padding: theme.spacing(4.8, 6, 0, 6),
    textAlign: 'justify',
  },
  actionableBtn: {
    textTransform: 'none',
    color: '#2A323C',
    fontSize: theme.spacing(2.8),
    marginRight: theme.spacing(2),

    '&:hover': {
      color: '#1B1D1F',
      backgroundColor: '#F2F5F9',

      '& > span': {
        color: '#1B1D1F',
      },
    },

    '& > span': {
      color: '#555F7C',
    },
  },
  textTitle: {
    color: '#2A323C',
    fontWeight: 400,
  },
  content: {
    padding: theme.spacing(0, 6),
    textAlign: 'justify',
  },
  actiondiv: {
    display: 'flex',
    margin: theme.spacing(1.4, 6, 5, 6),
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(4, 6, 0, 6),
    height: '0.5px',
  },
  appName: {
    padding: theme.spacing(0, 2.4),
  },
  appNameTitle: {
    color: theme.palette.text.primary,
  },
  deleteIcon: {
    padding: theme.spacing(1.6),
    marginLeft: theme.spacing(2),
  },
  gradient: {
    width: 'calc(100% - 30px)',
    height: '111px',
    position: 'absolute',
    bottom: 0,
  },
  iconImg: {
    width: 24,
    height: 24,
  },
}));

export { useStyles };
