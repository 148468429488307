import React, { ErrorInfo, useEffect, useRef, useState } from 'react';
import { Paper, Stack, TableContainer, Typography } from '@mui/material';
import { ErrorBoundary } from '../error-boundary/error-boundary';
import {
  TableEmptyState,
  TTableEmptyStateProps,
} from '../table-infinite-scroll/components/table-empty-state';
import {
  TableErrorState,
  TTableErrorStateProps,
} from '../table-infinite-scroll/components/table-error-state';
import { JSONTableContent } from './components/json-table-content';
import { JSONTablePagination, TJSONTablePaginationProps } from './components/json-table-pagination';
import { jsonTableStyles } from './helpers/json-table-styles';
import { JSON_TABLE_DEFAULT_PAGE_SIZES } from './helpers/json-table-helpers';
import { jsonTableText } from './helpers/json-table-text';

export type TJSONTableProps = {
  data: any;
  testId: string;
  withPagination?: boolean;
  pageSizes?: number[];
  isLoading: boolean;
  isErrored: boolean;
  hasMore: boolean;
  fetchOnMount?: boolean;
  fetchMore: () => void;
  TableErrorStateProps?: TTableErrorStateProps;
  TableEmptyStateProps?: TTableEmptyStateProps;
};

const JSONTableRender: React.FC<TJSONTableProps> = props => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {
    testId,
    data,
    withPagination: isPaginationEnabled,
    pageSizes = JSON_TABLE_DEFAULT_PAGE_SIZES,
    fetchOnMount,
    fetchMore,
    hasMore,
    isLoading,
    isErrored,
    TableErrorStateProps = {},
    TableEmptyStateProps = {},
  } = props;

  const isEmptyStateRendered = !isLoading && !hasMore && data.length === 0;

  // Local State
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [pageSize, _setPageSize] = useState<number>(pageSizes[0] ?? 0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleGoToPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleScroll = () => {
    if (tableContainerRef.current) {
      setScrollOffset(tableContainerRef.current.scrollTop);
    }
  };

  // Pagination State
  const totalRows = !hasMore ? data?.length : undefined;
  const lastPage = Math.ceil(data?.length / pageSize);
  const isOnLastPage = currentPage === lastPage;
  const isNextLastPage = currentPage === lastPage - 1;
  const isLastPageItemCountOffset = data?.length % pageSize != 0;

  const paginationProps: TJSONTablePaginationProps = {
    currentPage,
    goToPage: handleGoToPage,
    pageSize,
    tableProps: props,
    totalRows,
    isOnLastPage,
    isNextLastPage,
    isLastPageItemCountOffset,
    lastPage,
  };

  useEffect(() => {
    if (fetchOnMount) {
      fetchMore();
    }

    if (tableContainerRef.current) {
      tableContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainerRef.current) {
        tableContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  if (isErrored) {
    return <TableErrorState onRetry={fetchMore} {...TableErrorStateProps} />;
  }

  if (isEmptyStateRendered) {
    return <TableEmptyState {...TableEmptyStateProps} />;
  }
  return (
    <>
      {isPaginationEnabled && <JSONTablePagination {...paginationProps} />}
      <TableContainer
        sx={{
          ...jsonTableStyles.tableStyles.container(scrollOffset > 10),
          ...jsonTableStyles.paper,
        }}
        component={Paper}
        ref={tableContainerRef}
        data-testid={`${testId}-table-container`}
      >
        <JSONTableContent
          tableProps={props}
          paginationProps={isPaginationEnabled ? paginationProps : undefined}
        />
      </TableContainer>
      {isPaginationEnabled && <JSONTablePagination {...paginationProps} />}
    </>
  );
};

const RenderErrorMessage = () => (
  <Stack sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
    <Typography variant={'h3'}>{jsonTableText.errors.tableErrorTitle}</Typography>
    <Typography variant={'caption'}>{jsonTableText.errors.tableErrorSubTitle}</Typography>
  </Stack>
);

export const JSONTable: React.FC<TJSONTableProps> = props => {
  const handleCatchCellError = (error: Error, errorInfo: ErrorInfo) => {
    console.warn('JSON Table - Error occurred when attempting to render table contents.', error, errorInfo);
    console.log('JSON Table Props: ', props);
    console.log('Error: ', error);
    console.log('Error Info: ', errorInfo);
  };

  return (
    <ErrorBoundary
      fallbackUI={<TableErrorState CustomMessage={<RenderErrorMessage />} />}
      onDidCatchError={handleCatchCellError}
    >
      {<JSONTableRender {...props} />}
    </ErrorBoundary>
  );
};
