import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  Stack,
  Grid,
} from '@mui/material';
import { panelComponents, CopyText } from '@aiware/shared/reusable-utils';
import { useDispatch, useSelector } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import { selectApiConfigs } from '@aiware/shared/redux';
import React, { useEffect, useState } from 'react';
import { TScheduleInfoProps } from './index';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { TooltipOnTextOverflow } from '../../shared/TooltipOnTextOverflow';
import { formatFileInfoDate } from '../../../helpers/fileInfoHelpers';
import StatusBadge from '../../shared/rowItems/StatusBadge';
import { EntityType, AvailableComponents } from '@aiware/js/interfaces';
import { IFolder, ISchedulePart } from '../../../types';
import { getFolder } from '../../../api/folders';
import { updateScheduledJobStatus } from '../../../api/schedules';
import { actions } from '../../../redux/slices';

export const SHARED_TEXT = {
  scheduleInfo: {
    title: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-header"
        defaultMessage="Schedule Info"
        description="data schedule info pabel header"
      />
    ),
    name: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-name-label"
        defaultMessage="Name"
        description="Name Label"
      />
    ),
    location: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-location-label"
        defaultMessage="Location"
        description="Location Label"
      />
    ),
    id: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-id-label"
        defaultMessage="ID"
        description="ID Label"
      />
    ),
    created: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-created-label"
        defaultMessage="Created Date"
        description="Created Date Label"
      />
    ),
    modified: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-modified-label"
        defaultMessage="Modified Date"
        description="Modified Date Label"
      />
    ),
    sourceName: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-source-name-label"
        defaultMessage="Source"
        description="Source Name Label"
      />
    ),
    startDate: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-start-date-label"
        defaultMessage="Schedule Start"
        description="Schedule Start Date Label"
      />
    ),
    endDate: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-end-date-label"
        defaultMessage="Schedule End"
        description="Schedule End Date Label"
      />
    ),
    type: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-type-label"
        defaultMessage="Schedule Type"
        description="Schedule Type Label"
      />
    ),
    frequency: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-frequency-label"
        defaultMessage="Frequency"
        description="Frequency Label"
      />
    ),
    interval: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-interval-label"
        defaultMessage="Interval"
        description="Interval Label"
      />
    ),
    time: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-time-label"
        defaultMessage="Time"
        description="Time Label"
      />
    ),
    deactivateSchedule: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-deactivate-schedule"
        defaultMessage="Deactivate Schedule"
        description="Deactivate Schedule"
      />
    ),
    activateSchedule: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-activate-schedule"
        defaultMessage="Activate Schedule"
        description="Activate Schedule"
      />
    ),
    noLocationFound: () => (
      <FormattedMessage
        id="os-data-center.schedule-info-no-location-found"
        defaultMessage="No Location Found"
        description="No Location Found"
      />
    ),
  },
};

const days = [
  {
    value: 'Sunday',
    label: 'S',
  },
  {
    value: 'Monday',
    label: 'M',
  },
  {
    value: 'Tuesday',
    label: 'T',
  },
  {
    value: 'Wednesday',
    label: 'W',
  },
  {
    value: 'Thursday',
    label: 'T',
  },
  {
    value: 'Friday',
    label: 'F',
  },
  {
    value: 'Saturday',
    label: 'Sa',
  },
];

const { header: PanelHeader, container: PanelContainer, content: PanelContent } = panelComponents;

export const MainLayout: React.FC<TScheduleInfoProps> = ({ schedule, source }) => {
  const dispatch = useDispatch();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isScheduleActive, setIsScheduleActive] = useState(schedule?.isActive || false);

  const folderId = schedule?.allJobTemplates?.records?.[0].jobConfig?.createTDOInput?.parentFolderId;
  const [folder, setFolder] = useState<IFolder>();
  const apiConfigs = useSelector(selectApiConfigs);

  useEffect(() => {
    if (folderId) {
      const fetchFolder = async () => {
        try {
          const folder = await getFolder(apiConfigs, folderId);
          setFolder(folder);
        } catch (e) {
          console.error('Error fetching folder', e);
        }
      };
      fetchFolder();
    }
  }, [folderId]);

  const { classes } = makeStyles()(_theme => ({
    labelFont: {
      fontWeight: '400',
      color: '#5C6269',
    },
    timeLabel: {
      color: '#000000',
    },
  }))();

  if (!schedule) return null;
  const handleClose = () => {
    dispatch(hidePanel(AvailableComponents.DATA_CENTER_SCHEDULE_INFO_PANEL));
  };

  const isDayActive = (val: string) => {
    const activeDays = schedule?.parts?.map(p => p.scheduledDay);
    return activeDays?.includes(val);
  };

  // format 24 hour to 12 hour
  const formatTime = (time: string) => {
    const [hoursStr, minutes] = time.split(':');
    const hours = parseInt(hoursStr ?? '', 10);
    const ampm = hours >= 12 ? 'pm' : 'am';
    return `${hours % 12}:${minutes} ${ampm}`;
  };

  const sortSchedulePartsByDay = (scheduleParts: ISchedulePart[]) => {
    const clonedScheduleParts = [...scheduleParts];

    return clonedScheduleParts.sort((a: ISchedulePart, b: ISchedulePart) => {
      return (
        days.findIndex(day => day?.value === a?.scheduledDay) -
        days.findIndex(day => day?.value === b?.scheduledDay)
      );
    });
  };

  const sortedParts = sortSchedulePartsByDay(schedule?.parts ?? []);

  const onScheduleStatusUpdate = async () => {
    if (isScheduleActive) {
      setConfirmationOpen(true);
    } else {
      handleScheduleUpdate();
    }
  };

  const handleScheduleUpdate = async () => {
    try {
      const updatedScheduleId = await updateScheduledJobStatus(apiConfigs, schedule.id, !isScheduleActive);
      if (updatedScheduleId) {
        // toggle schedule status
        setIsScheduleActive(!isScheduleActive);
        setConfirmationOpen(false);
        dispatch(actions.entities.toggleScheduleStatus({ scheduleId: schedule.id }));
      }
    } catch (error) {
      console.error('Error updating schedule status', error);
    }
  };

  return (
    <PanelContainer panelId={AvailableComponents.DATA_CENTER_SCHEDULE_INFO_PANEL}>
      <PanelHeader
        title={SHARED_TEXT.scheduleInfo.title()}
        testId={'schedule-viewer-panel-header'}
        onPanelClose={handleClose}
        PanelActions={<></>}
      />
      <PanelContent>
        <Grid container p={6} rowSpacing={4}>
          <Grid item xs={12}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.name()}
              </Typography>
              <div>
                <TooltipOnTextOverflow title={schedule?.name} maxCharLength={18}>
                  <Typography
                    data-test={`dc-schedule-panel-info-panel-item-name`}
                    noWrap={true}
                    variant="body2"
                  >
                    {schedule?.name}
                  </Typography>
                </TooltipOnTextOverflow>
                <Box sx={{ mt: 2 }}>
                  <StatusBadge
                    item={{ ...schedule, isActive: isScheduleActive }}
                    itemType={EntityType.Schedules}
                    xs="auto"
                  />
                </Box>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.id()}
              </Typography>
              <Box>
                <CopyText
                  id={`dc-schedule-panel-info-panel-item-id`}
                  value={schedule?.id}
                  description="Copy Schedule Id"
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.created()}
              </Typography>
              <Box>
                <Typography
                  data-test={`dc-schedule-panel-info-panel-item-created-time`}
                  noWrap={true}
                  variant="body2"
                >
                  {schedule?.createdDateTime && formatFileInfoDate(new Date(schedule?.createdDateTime))}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.modified()}
              </Typography>
              <Box>
                <Typography
                  data-test={`dc-schedule-panel-info-panel-item-modifiedTime`}
                  noWrap={true}
                  variant="body2"
                >
                  {schedule?.modifiedDateTime && formatFileInfoDate(new Date(schedule?.modifiedDateTime))}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.sourceName()}
              </Typography>
              <Box>
                <Typography
                  data-test={`dc-schedule-panel-info-panel-item-sourceName`}
                  noWrap={true}
                  variant="body2"
                >
                  <TooltipOnTextOverflow title={source?.name} maxCharLength={18}>
                    <Typography
                      data-test={`dc-source-panel-info-panel-item-name`}
                      noWrap={true}
                      variant="body2"
                    >
                      {source?.name}
                    </Typography>
                  </TooltipOnTextOverflow>
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container p={6} rowSpacing={4}>
          <Grid item xs={6}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel-start-time`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.startDate()}
              </Typography>
              <Box>
                <Typography
                  data-test={`dc-schedule-panel-info-panel-item-start-time`}
                  noWrap={true}
                  variant="body2"
                >
                  {schedule?.startDateTime && formatFileInfoDate(new Date(schedule?.startDateTime))}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel-end-time`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.endDate()}
              </Typography>
              <Box>
                <Typography
                  data-test={`dc-schedule-panel-info-panel-item-end-time`}
                  noWrap={true}
                  variant="body2"
                >
                  {schedule?.stopDateTime
                    ? formatFileInfoDate(new Date(schedule?.stopDateTime))
                    : 'No End Date'}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel-type`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.type()}
              </Typography>
              <Box>
                {' '}
                <Typography
                  data-test={`dc-schedule-panel-info-panel-item-type`}
                  noWrap={true}
                  variant="body2"
                >
                  {schedule?.runMode ?? 'No Type Found'}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography
                data-test={`dc-schedule-panel-info-panel-frequency`}
                variant="caption"
                className={classes.labelFont}
              >
                {SHARED_TEXT.scheduleInfo.frequency()}
              </Typography>
              <Typography
                data-test={`dc-schedule-panel-info-panel-item-frequency`}
                noWrap={true}
                variant="body2"
              >
                {schedule?.parts?.[0]?.repeatIntervalUnit === 'Weeks'
                  ? `Weekly`
                  : schedule?.parts?.[0]?.repeatIntervalUnit}
              </Typography>
            </Stack>
          </Grid>
          {schedule?.parts?.[0]?.repeatIntervalUnit === 'Weeks' && (
            <>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  {days.map(day => (
                    <Box
                      sx={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '50%',
                        backgroundColor: isDayActive(day.value) ? '#1565C0' : '#F2EEEB',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: isDayActive(day.value) ? '#FFFFFF' : '#000000',
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{day.label} </Typography>
                    </Box>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <Typography
                    data-test={`dc-schedule-panel-info-panel-time`}
                    variant="caption"
                    className={classes.labelFont}
                  >
                    {SHARED_TEXT.scheduleInfo.time()}
                  </Typography>
                  {sortedParts.map(part => (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', width: '100%' }}>
                      <Typography variant="body2" sx={{ width: '30%', color: '#000000' }}>
                        {part.scheduledDay}
                      </Typography>
                      <Typography variant="body2">
                        <span className={classes.timeLabel}>{formatTime(part.startTime)} </span>
                        {part.stopTime && (
                          <>
                            {' to '}
                            <span className={classes.timeLabel}>{formatTime(part.stopTime)}</span>
                          </>
                        )}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Grid>
            </>
          )}
          {schedule?.parts?.[0]?.repeatIntervalUnit === 'Hours' && (
            <IntervalChildItem interval={schedule?.parts?.[0]?.repeatInterval || 1} classProps={classes} />
          )}
          {schedule?.parts?.[0]?.repeatIntervalUnit === 'Days' && (
            <>
              <IntervalChildItem interval={schedule?.parts?.[0]?.repeatInterval || 1} classProps={classes} />
              <Grid item xs={6}>
                <Typography
                  data-test={`dc-schedule-panel-info-panel-time`}
                  variant="caption"
                  className={classes.labelFont}
                >
                  {SHARED_TEXT.scheduleInfo.time()}
                </Typography>
                <Typography variant="body2">
                  <span className={classes.timeLabel}>{formatTime(schedule?.parts?.[0]?.startTime)} </span>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />
        <Box
          sx={{
            flexDirection: 'column',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '30px',
          }}
        >
          <Box>
            <Typography
              data-test={`dc-schedule-panel-info-panel`}
              variant="caption"
              className={classes.labelFont}
            >
              {SHARED_TEXT.scheduleInfo.location()}
            </Typography>
            <span>
              {folderId && folder ? (
                <TooltipOnTextOverflow title={folder?.name} maxCharLength={35}>
                  <Typography
                    data-test={`dc-schedule-panel-info-panel-folder-name`}
                    noWrap={true}
                    variant="body2"
                  >
                    {folder?.name}
                  </Typography>
                </TooltipOnTextOverflow>
              ) : (
                SHARED_TEXT.scheduleInfo.noLocationFound()
              )}
            </span>
          </Box>
          <Button
            onClick={onScheduleStatusUpdate}
            variant="text"
            data-testid="admin-center-custom-login--cancel-btn"
            sx={{
              width: '130px',
              padding: '0',
              whiteSpace: 'nowrap',
            }}
          >
            {isScheduleActive
              ? SHARED_TEXT.scheduleInfo.deactivateSchedule()
              : SHARED_TEXT.scheduleInfo.activateSchedule()}
          </Button>
        </Box>
      </PanelContent>

      <ConfirmDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleScheduleUpdate}
      />
    </PanelContainer>
  );
};

const IntervalChildItem = ({
  interval,
  classProps,
}: {
  interval: number;
  classProps: Record<string, string>;
}) => {
  return (
    <Grid item xs={6}>
      <Stack>
        <Typography
          data-test={`dc-schedule-panel-info-panel-interval`}
          variant="caption"
          className={classProps.labelFont}
        >
          {SHARED_TEXT.scheduleInfo.interval()}
        </Typography>
        <Typography data-test={`dc-schedule-panel-info-panel-item-interval`} noWrap={true} variant="body2">
          {interval}
        </Typography>
      </Stack>
    </Grid>
  );
};

export const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.40)' } }}
    >
      <Box sx={{ padding: '24px 32px 24px 16px' }}>
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontSize: 22,
              fontWeight: '700',
            }}
          >
            <FormattedMessage
              id="os-data-center.deactivate-schedule-confirmation-title"
              defaultMessage="Deactivate Schedule?"
              description="Title of thed deactivation schedule"
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontSize: '14px', fontWeight: '400' }}>
            <FormattedMessage
              id="os-data-center.deactivate-schedule-confirmation-message"
              defaultMessage="Are you sure you want to Deactivate this Schedule, no additional content will be ingested."
              description="This message is shown when prompting the user to confirm the deactivation of a schedule"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mt: 4 }}>
          <Button
            data-test="os-data-center.deactivate-schedule-confirmation-cancel-btn"
            color="secondary"
            onClick={onClose}
            sx={{ mr: 3 }}
          >
            <FormattedMessage
              id="os-data-center.deactivate-schedule-confirmation-cancel-button"
              defaultMessage="Cancel"
              description="Cancel button on deactivate schedule"
            />
          </Button>
          <Button
            data-test="os-data-center.deactivate-schedule-confirmation-confirm-btn"
            variant="contained"
            color="error"
            onClick={onConfirm}
            sx={{
              borderRadius: 20,
              padding: '10px 24px',
              backgroundColor: '#B71C1C',
            }}
          >
            <FormattedMessage
              id="os-data-center.deactivate-schedule-confirmation-delete-button"
              defaultMessage="Deactivate Schedule"
              description="Confirm button on deactivate schedule"
            />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
