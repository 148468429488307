import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { FileItemTypeNames } from '../../../types';
import FileItemIcon from '../FileItem/FileItemIcon';
import useStyles from './useStyles';

export interface IFileTypeItem {
  name: string;
  type: string;
  extensions: string[];
}

interface Props {
  fileItemType: IFileTypeItem;
}

const FileTypeItem: FunctionComponent<Props> = ({ fileItemType }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.fileTypeItem} data-test="data-center-importer-supported-file-item">
      <header className={classes.fileTypeItemHeader}>
        <span data-test="data-center-importer-file-item-icon">
          <FileItemIcon filetype={fileItemType.type} />
        </span>
        <Typography
          variant="h2"
          color="textPrimary"
          className={classes.fileItemTypeName}
          data-test="data-center-importer-file-type-name"
        >
          {fileItemType.name}
        </Typography>
      </header>

      <div className={classes.fileTypeItemBody} data-test="data-center-importer-extension-list">
        {fileItemType?.extensions?.map((extension, i) => (
          <Typography
            variant="body2"
            color="textPrimary"
            key={i}
            className={classNames(classes.fileTypeItemExtension, {
              [classes.fileTypeItemExtensionFullWidth]: fileItemType.type === FileItemTypeNames.Text,
            })}
          >
            .{extension}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default FileTypeItem;
