import { call, put, select } from 'redux-saga/effects';
import { selectApiConfigs } from '../selectors';
import { actions } from '../slices';
import * as api from '../../api';
import { selectUserOrgEdgeVersion } from '@aiware/shared/redux';
import { IEngineAdapter } from '../slices/panel-select-import-source';

export function* fetchEngineAdapters(
  action: ReturnType<typeof actions.panelSelectImportSource.fetchEngineAdapterStart>
): any {
  const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
  const edgeVersion: number = yield select(selectUserOrgEdgeVersion);

  // CMS hardcoded these filters for the adapters.
  const DL_ENGINE_IDS = [
    'download-file',
    'download-file-container',
    '54ed2111-4430-8032-2cdb-bb39bc5eab68',
    'adf1bbd2-c4b3-328e-5213-84c5b400ba32',
  ];
  const SDO_CATEGORY_ID = '5e82fab8-c845-4f9c-b7fc-92dfb26e6a3e';

  const filterEngines = (engine: IEngineAdapter) =>
    DL_ENGINE_IDS.indexOf(engine.id) === -1 && engine.categoryId !== SDO_CATEGORY_ID;

  const filterByEdgeVersion = (engine: IEngineAdapter) => {
    if (!edgeVersion || edgeVersion < 3) {
      return true;
    }
    return engine.edgeVersion === edgeVersion;
  };

  try {
    const engineAdapters = yield call(api.engines.getEngineAdapters, apiConfigs);

    const payload = {
      pull: engineAdapters['pull'].records.filter(filterEngines).filter(filterByEdgeVersion),
      push: engineAdapters['push'].records.filter(filterEngines).filter(filterByEdgeVersion),
    };
    yield put(
      actions.panelSelectImportSource.fetchEngineAdapterSucceeded({
        engineAdapters: payload,
      })
    );
  } catch (err) {
    console.log('Error fetching Engine Adapters: ', err);
    yield put(actions.panelSelectImportSource.fetchEngineAdapterFailed());
  }
}
