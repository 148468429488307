import { baseGraphQLApi, selectApiConfigs } from '@aiware/shared/redux';
import { EAuditLogFilterType, IAuditLog, IAuditLogQueryInput } from '../../../types/auditLog.types';
import { toCamelCase } from '../../helpers';

export const getAuditLogQuery = async ({
  apiConfigs,
  offset,
  limit,
  additionalSelectors,
  payload,
}: {
  apiConfigs: ReturnType<typeof selectApiConfigs>;
  limit: number;
  offset: number;
  additionalSelectors?: { [key: string]: unknown };
  payload: unknown;
}): Promise<IAuditLog[]> => {
  const { graphQLEndpoint, token } = apiConfigs;
  const operationName = 'getInstanceAuditLog';
  const name = 'instanceAuditLog';
  const query = `
    query ${operationName} ($input: InstanceAuditLogInput) {
      ${name} (input: $input) {
        records {
          id
          eventId
          organizationId
          organizationGuid
          organizationName
          userId
          userName
          clientIpAddress
          clientUserAgent
          description
          createdDateTime
          eventName
          targetType
          objectId
          actionResult
          actionName
          originatorApplication
          originatorService
          impersonatorUserId
          impersonatorUserName
        }
      }
    }
    `;

  const organizationId = (payload as { organizationId: string }).organizationId;

  const variables: { input: IAuditLogQueryInput } = {
    input: {
      offset,
      limit,
      ...(organizationId && organizationId !== '0' && { organizationId }),
    },
  };
  // process filters
  if (additionalSelectors && Array.isArray(additionalSelectors['filters'])) {
    const filters = additionalSelectors['filters'] as { type: EAuditLogFilterType; values: any }[];
    filters.forEach(filter => {
      switch (filter.type) {
        case EAuditLogFilterType.EventName: {
          variables.input.eventName = toCamelCase(filter.values);
          break;
        }
        case EAuditLogFilterType.User: {
          variables.input.userName = filter.values;
          break;
        }
        case EAuditLogFilterType.DateTime: {
          if (filter.values.fromDateTime) {
            variables.input.fromDateTime = filter.values.fromDateTime;
          }
          if (filter.values.toDateTime) {
            variables.input.toDateTime = filter.values.toDateTime;
          }
          break;
        }
        default:
          break;
      }
    });
  }

  const result: { data?: { [name]?: { records?: IAuditLog[] } } } = await baseGraphQLApi<{
    [name]: { records: IAuditLog[] };
  }>({
    query,
    operationName,
    graphEndpoint: graphQLEndpoint,
    token,
    variables,
  });

  const userIds: string[] = [];
  result?.data?.instanceAuditLog?.records?.forEach(event => {
    if (event.userId && !userIds.includes(event.userId)) {
      userIds.push(event.userId);
    }
  });

  const userOperationName = 'getUserInfo';
  const avatarQuery = `
  query ${userOperationName} ($userIds: [ID], $offset: Int, $limit: Int) {
    users(ids: $userIds, offset: $offset, limit: $limit) {
      records {
        id
        imageUrl
      }
    }
  }`;

  const userInfo: { data?: { users?: { records?: { id: string; imageUrl: string }[] } } } =
    await baseGraphQLApi<{
      ['users']: { records: { id: string; imageUrl: string }[] };
    }>({
      query: avatarQuery,
      operationName: userOperationName,
      graphEndpoint: graphQLEndpoint,
      token,
      variables: {
        offset: 0,
        limit: userIds.length,
        userIds,
      },
    });

  return (result?.data?.instanceAuditLog?.records || []).map(result => ({
    ...result,
    userAvatar: userInfo.data?.users?.records?.find(user => user.id === result.userId)?.imageUrl,
  }));
};

export const fetchEventNames = async (apiConfigs: ReturnType<typeof selectApiConfigs>): Promise<string[]> => {
  const { graphQLEndpoint, token } = apiConfigs;
  const operationName = 'getEventNames';
  const name = '__type';
  const query = `
    query ${operationName} {
     ${name}(name: "EventNameEnum") {
      enumValues {
        name
        description
        isDeprecated
      }
    }
    }
    `;

  const result: { data?: { [name]?: { enumValues?: { name: string; isDeprecated: boolean }[] } } } =
    await baseGraphQLApi<{
      [name]: { enumValues: { name: string }[] };
    }>({
      query,
      operationName,
      graphEndpoint: graphQLEndpoint,
      token,
    });

  // filter out deprecated event names
  return (
    result?.data?.[name]?.enumValues?.filter(event => !event.isDeprecated).map(event => event.name) || []
  );
};
export const createAuditLogExportRequest = async ({
  apiConfigs,
  filters,
  organizationId,
}: {
  apiConfigs: ReturnType<typeof selectApiConfigs>;
  filters: { type: EAuditLogFilterType; values: any }[];
  organizationId: string;
}): Promise<{ downloadUrl: string; id: string }> => {
  const { graphQLEndpoint, token } = apiConfigs;
  const operationName = 'createAuditLogExportRequest';
  const mutation = `
    mutation ${operationName}($filters: AuditLogExportRequestFilterInput) {
      createAuditLogExportRequest(filters: $filters) {
        downloadUrl
        id
      }
    }
  `;

  // Start with the required organizationId.
  interface AuditLogExportFilter {
    organizationId?: string;
    dateTimeFilter?: {
      fromDateTime?: string;
      toDateTime?: string;
    };
    eventNames?: string[];
    userNames?: string[];
  }

  const gqlFilters: AuditLogExportFilter = {};

  if (organizationId && organizationId !== '0') {
    gqlFilters.organizationId = organizationId;
  }

  // Process the dateTime filter
  const dateTimeFilterRaw = filters.find(filter => filter.type === EAuditLogFilterType.DateTime)?.values;
  if (dateTimeFilterRaw && typeof dateTimeFilterRaw === 'object') {
    const { field, ...dateTimeFilter } = dateTimeFilterRaw;
    // Only add if dateTimeFilter has any keys (i.e. at least one of fromDateTime or toDateTime exists).
    if (Object.keys(dateTimeFilter).length > 0) {
      gqlFilters.dateTimeFilter = dateTimeFilter;
    }
  } else {
    // Set default date range for last 30 days if no datetime filter
    const toDateTime = new Date().toISOString();
    const fromDateTime = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
    gqlFilters.dateTimeFilter = { fromDateTime, toDateTime };
  }

  // Process eventNames: filter and transform them, and only add if not empty.
  const eventNames = filters
    .filter(filter => filter.type === EAuditLogFilterType.EventName)
    .map(filter => toCamelCase(filter.values));
  if (eventNames.length > 0) {
    gqlFilters.eventNames = eventNames;
  }

  // Process userNames: filter them, and only add if not empty.
  const userNames = filters
    .filter(filter => filter.type === EAuditLogFilterType.User)
    .map(filter => filter.values);
  if (userNames.length > 0) {
    gqlFilters.userNames = userNames;
  }

  const variables = { filters: gqlFilters };

  const result: {
    data?: { createAuditLogExportRequest: { downloadUrl: string; id: string } };
  } = await baseGraphQLApi<{
    createAuditLogExportRequest: { downloadUrl: string; id: string };
  }>({
    query: mutation,
    operationName,
    graphEndpoint: graphQLEndpoint,
    token,
    variables,
  });

  return result?.data?.createAuditLogExportRequest || { downloadUrl: '', id: '' };
};

export const getAuditLogExportRequest = async ({
  apiConfigs,
  id,
}: {
  apiConfigs: ReturnType<typeof selectApiConfigs>;
  id: string;
}): Promise<{ downloadUrl: string; status: string }> => {
  const { graphQLEndpoint, token } = apiConfigs;
  const operationName = 'auditLogExportReq';
  const query = `
    query ${operationName}($id: ID!) {
      auditLogExportRequest(id: $id) {
        downloadUrl
        status
      }
    }
  `;
  const variables = { id };

  const result: {
    data?: { auditLogExportRequest?: { downloadUrl: string; status: string } };
  } = await baseGraphQLApi<{ auditLogExportRequest: { downloadUrl: string; status: string } }>({
    query,
    operationName,
    graphEndpoint: graphQLEndpoint,
    token,
    variables,
  });

  return result?.data?.auditLogExportRequest || { downloadUrl: '', status: '' };
};
