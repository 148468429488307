import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  titleContainer: {
    backgroundColor: '#F2F7FE',
    borderRadius: '4px',
    height: '41px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  mainTitle: {
    fontWeight: 600,
  },
  selectBtn: {
    fontSize: '12px',
    color: '#0C53B0',
  },
  permissionsRow: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '0.5px solid #D5DFE9',
    '&:last-child': {
      marginBottom: '10px',
    },
  },
  formLabelStyle: {
    height: '46px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 0,
    marginLeft: '10px',
  },
  checkboxLabel: {
    fontWeight: 600,
  },
  searchField: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
