import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  checked: {
    color: '#6098D1',
  },
  rootSelectedForm: {
    padding: '0px !important',
  },
  rootSelectForm: {
    padding: '10px !important',
    '&:hover': {
      background: '#F7F7F7',
    },
  },
  iconStyle: {
    marginRight: '5px',
  },
  iconComponent: {
    top: '20px',
  },
  iconBackground: {
    display: 'inline-block',
    height: '32px',
    borderRadius: '32px',
    width: '32px',
    background: '#F2F5F9',
    marginRight: '15px',
    paddingLeft: '8px',
    paddingTop: '6px',
    marginLeft: '5px',
  },
  itemIcons: {
    verticalAlign: 'middle',
  },
  itemIconsRow: {
    position: 'absolute',
    top: '0px',
  },
  iconBackgroundSelected: {
    display: 'inline-block',
    height: '25px',
    borderRadius: '25px',
    width: '25px',
    minHeight: '25px',
    minWidth: '25px',
    background: theme.palette.primary.main,
    marginRight: '15px',
    paddingLeft: '7px',
    paddingTop: '7px',
    marginLeft: '15px',
  },
  helperDescription: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  menuItem: {
    width: '958px',
    padding: '10px',
    whiteSpace: 'initial',
    height: 'fit-content',
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    borderRadius: '0px',
  },
  optionName: {
    marginLeft: '0px',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  optionNameRow: {
    marginLeft: '50px',
  },
  summary: {
    fontSize: 11,
    textTransform: 'none',
    width: '900px',
  },
  selectForm: {
    width: '250px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  selectedForm: {
    background: '#FAFAFA',
    width: '960px !important',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  selectedName: {
    textTransform: 'capitalize',
    background: '#EEF3F9',
    width: 'auto',
    height: '56px',
    lineHeight: '56px',
    fontWeight: 'bold',
  },
  ul: {
    listStyle: 'none',
  },
  selectedDescription: {
    fontSize: '12px',
    marginBottom: '10px',
  },
  classSelectedBox: {
    padding: '20px 32px',
    textOverflow: 'wrap',
    fontSize: '12px',
    whiteSpace: 'initial',
    wordWrap: 'break-word',
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
    lineHeight: '16px',
  },
  inputSelectCaption: {
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '16px',
    marginBottom: '11px',
  },
  inputSelect: {
    width: '228px',
    fontWeight: 400,
    fontSize: '14px',
  },
  textInput: {
    paddingLeft: '15px',
    paddingTop: '12px',
    paddingBottom: '12px',
    fontWeight: 400,
    fontSize: '14px',
  },
  selectionName: {
    marginLeft: '20px',
  },
  selectionDescription: {
    paddingLeft: '60px',
    marginTop: '-10px',
    fontSize: '12px',
  },
  icon: {
    textAlign: 'center',
    height: '1rem !important',
    margin: '0 3px',
  },
}));
export default useStyles;
