import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  panelHeaderTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.select-import-source.title"
      defaultMessage="Select Import Source"
      description="Panel header for Select Import Source"
    />
  ),
  closePanel: () => (
    <FormattedMessage
      id="os-data-center-browse.select-import-source.close-panel"
      defaultMessage="Close"
      description="Close panel icon for the Data Center Select Import Source"
    />
  ),
  tabs: {
    pull: () => (
      <FormattedMessage
        id="os-data-center-browse.select-import-source.tab.pull"
        defaultMessage="Pull"
        description="Pull Tab title for Select Import Source"
      />
    ),
    push: () => (
      <FormattedMessage
        id="os-data-center-browse.select-import-source.tab.push"
        defaultMessage="Push"
        description="Push Tab title for Select Import Source"
      />
    ),
  },
  engineList: {
    errorStateTitle: () => (
      <FormattedMessage
        id="os-data-center-browse.select-import-source.engine-list.error-title"
        defaultMessage="Error Loading Engines"
        description="Error title for loading Engines List title for Select Import Source"
      />
    ),
    errorStateRetry: () => (
      <FormattedMessage
        id="os-data-center-browse.select-import-source.engine-list.error-retry"
        defaultMessage="Retry"
        description="Error retry button for loading Engines List title for Select Import Source"
      />
    ),
    pullEmptyState: () => (
      <FormattedMessage
        id="os-data-center-browse.select-import-source.engine-pull-list.empty"
        defaultMessage="You have no sources available in the Pull category"
        description="Empty Engines Pull List title for Select Import Source"
      />
    ),
    pushEmptyState: () => (
      <FormattedMessage
        id="os-data-center-browse.select-import-source.engine-push-list.empty"
        defaultMessage="You have no sources available in the Push category"
        description="Empty Engines Push List title for Select Import Source"
      />
    ),
  },
};
