import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'folderMoreIcon'>()((theme, _props, classes) => ({
  root: {
    flexGrow: 1,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  gridName: {
    minHeight: 0,
    minWidth: 0,
  },
  divider: {
    opacity: 0.9,
  },
  name: {
    fontWeight: 400,
    maxWidth: '100%',
  },
  popper: {
    height: '100px',
  },
  headerType: {
    maxWidth: theme.spacing(12),
  },
  headerItem: {
    cursor: 'default',
  },
  type: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    maxWidth: theme.spacing(12),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  padding: {
    paddingRight: theme.spacing(1),
  },
  moreIcon: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  sourceIcon: {
    verticalAlign: 'middle',
  },
  fluffyNameContainer: {
    paddingTop: theme.spacing(0.4),
    maxWidth: '90%',
    paddingRight: theme.spacing(0.6),
  },
  header: {
    height: 40,
  },
  folderContainer: {
    position: 'relative',
  },
  folderName: {
    fontWeight: 400,
    width: '100%',
    textAlign: 'center',
  },
  folderMoreIcon: {},
  folder: {
    position: 'relative',
    cursor: 'pointer',
    maxWidth: '120px',
    width: '120px !important',
    height: '100px',
    padding: theme.spacing(2),
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#EEF3F9',
      [`& .${classes.folderMoreIcon}`]: {
        visibility: 'visible',
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
      },
    },

    [`& .${classes.folderMoreIcon}`]: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      visibility: 'hidden',
    },
    '&.selected': {
      backgroundColor: '#F2F7FE',
    },
  },
  hideMoreIcon: {
    display: 'none',
  },
  titleFluffy: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(0.6),
  },
  timeFluffy: {
    paddingLeft: theme.spacing(0.6),
  },
  thumbnailContainer: {
    paddingLeft: theme.spacing(1),
    maxWidth: '125px',
  },
  thumbnail: {
    width: 115,
    height: 70,
    borderRadius: 2,
    border: `.5px solid ${theme.palette.divider}`,
    objectFit: 'cover',
    verticalAlign: 'middle',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#EEF3F9',
      [`& .${classes.folderMoreIcon}`]: {
        visibility: 'visible',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '& #engineIconContainer': {
        background: '#fff',
      },
    },
    [`& .${classes.folderMoreIcon}`]: {
      visibility: 'hidden',
    },
    '&.selected': {
      backgroundColor: '#F2F7FE',
    },
  },
  unselectable: {
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  },
  selected: {
    backgroundColor: '#F2F7FE',
  },
  rowLoading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'white',
    cursor: 'not-allowed',
    boxSizing: 'border-box',
    border: '2px solid white',
    pointerEvents: 'all',
  },
}));
export { useStyles };
