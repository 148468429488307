import { AiwareFrameLink, EMessageTypes, errors } from '@aiware/js/frame-link';
import { guid } from '@aiware/js/function';
import { mountPanel, mountPanelForResponse } from '@aiware/js/panel';
import { EResourceType, PermissionsWidget } from '@aiware/os/admin-center/permissions';
import { AIWareFormatMessage, EnumHelpers } from '@aiware/os/helpers';
import {
  authSelector,
  BrowseModule,
  DataCenterView,
  initialConfigSelector,
  MessageSeverity,
  preferredLanguageSelector,
  selectIsFullscreenEnabled,
  showMessage,
} from '@aiware/shared/redux';
import { panelComponents, useConfirmClose } from '@aiware/shared/reusable-utils';
import { store } from '@aiware/shared/store';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SHARED_TEXT } from '../../helpers/shared-text';
import { selectSelectedFiles, selectUploadedFilesTdoIds } from '../../redux/selectors/files-selected-state';
import { rootFolderSelector } from '../../redux/selectors/root-folder-state';
import { selectCurrentView, selectUIStateStatus } from '../../redux/selectors/ui-state';
import { actions } from '../../redux/slices';
import { validateMimeTypes } from '../../services/validation.service';
import { DEFAULT_ACCEPTABLE_FILE_TYPES, IFile, ImporterView, IRootFolder, MimeType } from '../../types';
import { FileItemList } from './FileItemList';
import Footer from './Footer/Footer';
import LocalFileUpload from './LocalFileUpload/LocalFileUpload';
import { LocationFolder } from './LocationFolder';
import useStyles from './useStyles';

const DEFAULT_MAX_FILE_COUNT = 100;
const MAX_VERITONE_FILE_COUNT_ALLOWED = 1000; // We limit the maxium file count on our backend to 1000

const { header: PanelHeader, container: PanelContainer, content: PanelContent } = panelComponents;

export interface DataCenterImporterConfigProps {
  dataId: string;
  hidePanel?: () => void;
  fileLimit?: number;
  fileEditMetadata?: boolean;
  requestId?: string;
  frameLink?: AiwareFrameLink;
  browseComponent?: BrowseModule;
  activeFolder?: IRootFolder;
  panelTitle?: string;
  title?: string;
  titleSubText?: string;
  locationFolderTitle?: string;
  locationFolderSubText?: string;
  locationFolderInputLabel?: string;
}

const MainLayout: FunctionComponent<DataCenterImporterConfigProps> = ({
  hidePanel,
  activeFolder,
  fileLimit = DEFAULT_MAX_FILE_COUNT,
  fileEditMetadata = true,
  requestId,
  frameLink,
  browseComponent,
  panelTitle,
  title,
  titleSubText,
  locationFolderTitle,
  locationFolderSubText,
  locationFolderInputLabel,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [permissionsPayload, setPermissionsPayload] = useState(null);
  const locale = useSelector(preferredLanguageSelector);
  const initConfig = useSelector(initialConfigSelector);
  const isFullScreen = useSelector(selectIsFullscreenEnabled);
  const files = useSelector(selectSelectedFiles);
  const uiStateStatus = useSelector(selectUIStateStatus);
  const view = useSelector(selectCurrentView);
  const rootFolder = useSelector(rootFolderSelector);
  const auth = useSelector(authSelector);
  const tdosCreated = useSelector(selectUploadedFilesTdoIds);

  // Prompt user before closing
  useConfirmClose('Are you sure you want to leave the File Importer? \n\nUnsaved progress will be lost.');

  useEffect(() => {
    if (tdosCreated && tdosCreated.length > 0) {
      frameLink &&
        frameLink.postMessage(EMessageTypes.response, { requestId, data: { tdoIds: tdosCreated } });
      hidePanel && hidePanel();
    }
  }, [tdosCreated]);

  useEffect(() => {
    if (browseComponent === BrowseModule.Investigate) {
      dispatch(actions['uiState'].setBrowseComponent(BrowseModule.Investigate));
    }
  }, [browseComponent]);

  const handlePanelClose = useCallback(() => {
    hidePanel && hidePanel();
    requestId &&
      frameLink &&
      frameLink.postMessage(EMessageTypes.error, { requestId, error: errors.operationCancelled });
  }, [hidePanel]);

  const formatMessage = AIWareFormatMessage(locale);
  const [isOLPEnabledForOrg, setIsOLPEnabledForOrg] = useState(false);

  useEffect(() => {
    if (auth) {
      // @ts-ignore
      if (auth?.user?.organization?.kvp?.features) {
        // @ts-ignore
        const { features } = auth.user.organization.kvp;
        if (features['enableRBACFeature'] === 'enabled') {
          setIsOLPEnabledForOrg(true);
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    if (files && files.length) {
      dispatch(actions.uiState.setCurrentView(ImporterView.filesSelected));
    } else {
      dispatch(actions.uiState.setCurrentView(ImporterView.browseFiles));
    }
  }, [dispatch, files]);

  useEffect(() => {
    if (initConfig.baseUrl) {
      if (activeFolder && activeFolder.id) {
        dispatch(
          actions.rootFolderState.updateRootFolder({
            activeFolder,
          })
        );
        return;
      }
      dispatch(actions.rootFolderState.startRootFolderFetch());
    }
  }, [initConfig, activeFolder, dispatch]);

  const handleUpload = (fileList: File[]) => {
    let filesToUpload = fileList;

    // Get and validate mimeTypes of uploadedFiles
    const allowedMimeTypes = EnumHelpers.getNames(MimeType);
    const disallowedFiles = validateMimeTypes(fileList, allowedMimeTypes);

    // Check for invalid mimeTypes and notify the user
    if (disallowedFiles?.length > 0) {
      dispatch(
        showMessage({
          content: formatMessage(
            {
              id: 'os-data-center-importer.snackbar.disallowedFilesMessage',
              defaultMessage: 'The following file types are not allowed: {disallowedFiles}',
              description: 'The error message pops up when disallowed file is added',
            },
            {
              disallowedFiles: Array.from(disallowedFiles.values()).join(','),
            }
          ) as string,
          severity: MessageSeverity.Warning,
        })
      );

      // Filter out files that include forbidden mimetypes
      filesToUpload = fileList.filter(f => f.type && !disallowedFiles.includes(f.type));
    }

    // Check the max file limit
    if (fileList.length > fileLimit) {
      dispatch(
        showMessage({
          content: formatMessage(
            {
              id: 'os-data-center-importer.snackbar.fileLimitError',
              defaultMessage:
                'You can only upload files up to {fileLimit} files at once! Only the first {fileLimit} files will be uploaded.',
              description: 'The error message pops up when file limit is reached.',
            },
            {
              fileLimit: `${fileLimit}`,
            }
          ) as string,
          severity: MessageSeverity.Warning,
        })
      );

      filesToUpload = fileList.slice(0, fileLimit);
    } else if (fileList.length > MAX_VERITONE_FILE_COUNT_ALLOWED) {
      dispatch(
        showMessage({
          content: formatMessage(
            {
              id: 'os-data-center-importer.snackbar.fileCountError',
              defaultMessage:
                'You have exceeded the maximum allowable files per upload. Only the first {maxFileCount} files will be uploaded.',
              description: 'The error message pops up when file count is reached.',
            },
            {
              maxFileCount: `${MAX_VERITONE_FILE_COUNT_ALLOWED}`,
            }
          ) as string,
          severity: MessageSeverity.Warning,
        })
      );

      filesToUpload = fileList.slice(0, MAX_VERITONE_FILE_COUNT_ALLOWED);
    }

    // Get files to upload and map them into correct format
    const uploadedFiles = filesToUpload.map(file => {
      const url = URL.createObjectURL(file);

      return {
        id: guid(),
        fileUrl: url,
        name: file.name,
        size: file.size,
        type: file.type,
        percentComplete: 0,
      };
    });

    // Upload the files
    dispatch(
      actions.filesSelectedState.setFilesSelected({
        filesSelected: uploadedFiles,
        sdkFiles: filesToUpload,
      })
    );
  };

  const deleteFile = (id: string) => {
    dispatch(
      actions.filesSelectedState.deleteFileSelected({
        id: id,
      })
    );
  };

  const onRetryFailedsClick = () => {
    const failedFiles = files.filter(file => file.status === 'failure');
    dispatch(
      actions.filesSelectedState.retryFileUpload({
        filesSelected: failedFiles,
      })
    );
  };

  const onRetryClick = (file: IFile) => {
    if (file.status === 'failure') {
      dispatch(
        actions.filesSelectedState.retryFileUpload({
          filesSelected: [file],
        })
      );
    } else if (file.tdoStatus === 'failure') {
      dispatch(
        actions.uiState.retryTDOCreateForFiles({
          filesSelected: [file],
        })
      );
    }
  };

  const onImport = () => {
    dispatch(
      actions.uiState.tdoCreateStart({
        filesSelected: files,
      })
    );
  };

  const onAbortClick = (file: IFile) => {
    dispatch(actions.filesSelectedState.abortFileUpload(file));
  };

  const deleteFailedFiles = () => {
    dispatch(actions.filesSelectedState.deleteFailedFiles());
  };

  const handleIgnoreAndContinue = () => {
    deleteFailedFiles();
    onImport();
  };

  const handleClosePanel = () => {
    if (files.length) {
      // abort files in progress on close panel
      for (const file of files) {
        if (file.status === 'pending') {
          dispatch(actions.filesSelectedState.abortFileUpload(file));
        }
      }
    }

    handlePanelClose();
  };

  const showSupportedFiles = () => {
    const microFrontend = {
      name: 'SUPPORTED_FILE_TYPES',
      config: {
        name: 'Local Importer',
        fileTypes: DEFAULT_ACCEPTABLE_FILE_TYPES,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen || browseComponent === BrowseModule.Investigate ? 0 : 80,
      size: 'medium',
      parentPanelId: 'DATA_CENTER_IMPORTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };

    dispatch(
      mountPanel({
        panelId: 'SUPPORTED_FILE_TYPES_PANEL',
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  const handleChangeFolder = async () => {
    const panelId =
      browseComponent === BrowseModule.Investigate
        ? 'INVESTIGATE_BROWSE_FOLDERS_AND_FILES'
        : 'DATA_CENTER_FOLDERS_AND_FILES';

    try {
      await mountPanelForResponse(
        {
          panelId: panelId,
          microFrontend: {
            name: panelId,
            config: {
              viewType: DataCenterView.folders,
              isFullScreen,
            },
          },
          panelConfig: {
            panelId: panelId,
            disableHide: false,
            parentPanelId: 'DATA_CENTER_IMPORTER',
            type: 'APP_BAR_PANEL_TEMPLATE',
            size: 'small',
            marginTop: isFullScreen ? 0 : 55,
            marginStart: isFullScreen || browseComponent === BrowseModule.Investigate ? 0 : 80,
            height: 600,
            header: {
              title: (
                <FormattedMessage
                  id="os-data-center-importer.headerTitle.chooseLocation"
                  defaultMessage="Choose a Location"
                  description="The title for the choose location panel"
                />
              ),
            },
            dimmed: 0,
            dimmedStatus: 'dimParent',
          },
        },
        store
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = (file: IFile) => {
    const microFrontend = {
      name: 'EDIT_FILE_METADATA',
      config: {
        name: 'Local Importer',
        file: file,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen || browseComponent === BrowseModule.Investigate ? 0 : 80,
      size: 'medium',
      parentPanelId: 'DATA_CENTER_IMPORTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
    };

    dispatch(
      mountPanel({
        panelId: 'EDIT_FILE_METADATA',
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  interface IPermissionsPayload {
    [x: string]: unknown;
  }

  const handleChangePermissions = (payload: IPermissionsPayload) => {
    // @ts-ignore
    setPermissionsPayload(payload);
  };

  useEffect(() => {
    if (!permissionsPayload) return;
    //@ts-ignore
    if (!permissionsPayload?.entries?.length) {
      // Reset the payload to null if no groups or users are selected.
      setPermissionsPayload(null);
      return;
    }

    dispatch(actions.filesSelectedState.setPermissionsPayload(permissionsPayload));
  }, [permissionsPayload, dispatch]);

  return (
    <PanelContainer panelId={'DATA_CENTER_IMPORTER'} testId={'data-center-importer-widget'}>
      <PanelHeader
        title={SHARED_TEXT.panelTitle(panelTitle)}
        testId={'data-center-importer-title'}
        onPanelClose={handlePanelClose}
      />
      <PanelContent>
        <Box sx={{ padding: '0 35px', paddingBottom: '20px', paddingTop: '20px' }}>
          {uiStateStatus === 'failure' && (
            <Box data-test="importer-error-upload-message" mb={4}>
              <Alert variant="outlined" severity="error" className={classes.alert}>
                Error while uploading your file(s).
              </Alert>
            </Box>
          )}
          <Typography data-test="data-center-importer-local-upload-title" variant="h2" color="textPrimary">
            {SHARED_TEXT.title(title)}
          </Typography>
          <Typography
            data-test="data-center-importer-local-upload-description"
            variant="body2"
            color="textSecondary"
          >
            {SHARED_TEXT.titleSubText(titleSubText)}
          </Typography>
          {view === ImporterView.browseFiles && (
            <Box sx={{ position: 'relative' }}>
              <LocalFileUpload onViewSupportedFiles={showSupportedFiles} onUpload={handleUpload} />
            </Box>
          )}
          {view === ImporterView.filesSelected && (
            <>
              <FileItemList
                onUpload={handleUpload}
                fileItems={files}
                onDelete={deleteFile}
                onRetryFailedsClick={onRetryFailedsClick}
                onRetry={file => onRetryClick(file)}
                onAbort={file => onAbortClick(file)}
                edit={fileEditMetadata}
                onEdit={file => onEdit(file)}
                uiStateStatus={uiStateStatus}
              />
              <div className={classes.spacer} />
              <LocationFolder
                folder={rootFolder!}
                locationFolderTitle={locationFolderTitle}
                locationFolderSubText={locationFolderSubText}
                locationFolderInputLabel={locationFolderInputLabel}
                onChangeBtnClick={handleChangeFolder}
              />
            </>
          )}
          {view === ImporterView.filesSelected && isOLPEnabledForOrg && (
            <div className={classes.permissionsContainer}>
              <Typography data-testid="ui-permissions-widget-title" variant="h2">
                <FormattedMessage
                  id="ui-permissions-widget-title"
                  defaultMessage="Permissions"
                  description="Permissions widget title"
                />
              </Typography>
              <Typography
                data-test="data-center-importer-local-upload-description"
                variant="body2"
                paragraph
                className={classes.description}
              >
                <FormattedMessage
                  id="os-data-center-importer-ui-permissions-widget-description"
                  defaultMessage="Manage who can access and permissions to these objects "
                  description="Permissions widget description"
                />
              </Typography>
              <PermissionsWidget
                onGetPermissionPayload={handleChangePermissions}
                permissionType={'TDO'}
                parentPanelId={'DATA_CENTER_IMPORTER'}
                preloadPermissionsFrom={{
                  resourceType: EResourceType.Folder,
                  resourceId: rootFolder!.id,
                }}
                browseComponent={browseComponent}
              />
            </div>
          )}
        </Box>
      </PanelContent>
      <Box
        sx={{
          height: '90px',
          marginTop: 'auto',
          borderTop: '0.5px solid #D5DFE9',
          display: 'flex',
          flexShrink: 0,
        }}
      >
        <Footer
          uiStateStatus={uiStateStatus}
          fileItems={files}
          onClosePanel={handleClosePanel}
          onImportFiles={onImport}
          onIgnoreAndContinue={handleIgnoreAndContinue}
          onRetry={onRetryFailedsClick}
        />
      </Box>
    </PanelContainer>
  );
};

export default MainLayout;
