import { useState, useCallback } from 'react';
import { IUser } from '@aiware/js/interfaces';
import { TUserGroup, GraphQLPage, TAuthGroupMember } from '../../../types';
import { filterDefinedMemberRecords } from '../../../helper';

type Group = Partial<TUserGroup> & Pick<TUserGroup, 'name' | 'description'>;

type Member = Partial<IUser>;

export const BASIC_INFO_STEP = 0;
export const GROUP_MEMBERS_STEP = 1;

export const useStepData = (initGroup: Partial<TUserGroup> | undefined) => {
  const [group, setGroup] = useState<Group>({
    name: '',
    description: '',
    ...initGroup,
  });
  const [members, setMembers] = useState<Member[]>(filterDefinedMemberRecords(initGroup));

  const setName = useCallback((value: string) => {
    setGroup(previousGroup => ({
      ...previousGroup,
      name: value,
    }));
  }, []);

  const setDescription = useCallback((value: string) => {
    setGroup(previousGroup => ({
      ...previousGroup,
      description: value,
    }));
  }, []);

  const addMember = useCallback((value: IUser) => {
    setMembers(previousMembers => [...previousMembers.filter(member => member.email !== value.email), value]);
  }, []);

  const removeMember = useCallback((value: IUser) => {
    setMembers(previousMembers => previousMembers.filter(member => member.email !== value.email));
  }, []);

  const validateStep = useCallback(
    (index: number) => {
      if (index === BASIC_INFO_STEP) {
        return !!(group.name?.length && group.description?.length);
      }
      if (index === GROUP_MEMBERS_STEP) {
        // we aren't requiring that members be added to the group being created
        return true;
      }
      return false;
    },
    [group]
  );

  return {
    group: {
      ...group,
      members: {
        records: members.map(member => ({ member })),
        count: members.length,
      } as GraphQLPage<TAuthGroupMember>,
    },
    setName,
    setDescription,
    addMember,
    removeMember,
    validateStep,
  };
};

export default useStepData;
