import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUIStateStatus } from '../../../redux/selectors/ui-state';
import { actions } from '../../../redux/slices';
import { IFile, IFileMeta } from '../../../types';
import { FileItemMeta } from './FileItemMeta';

import { ContentTemplates } from '@aiware/os/processing-center/content-templates';
import { panelComponents } from '@aiware/shared/reusable-utils';
import { ITabProps, TabPanel, Tabs } from '@aiware/ui';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useStyles } from './fileItemMeta.styles';
import { selectContentTemplatesToSave } from '@aiware/os/processing-center/content-templates';

import SHARED_TEXT from '../../helpers/shared-text';

const { header: PanelHeader, content: PanelContent } = panelComponents;

const TEST_ID = (id: string) => `file-item-meta-${id}`;

export const FileItemMetaContainer: FC<{
  file: IFile;
  dataId: string;
  hidePanel?: () => void;
}> = ({ hidePanel, file }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const fileMetaItemRef = useRef(null);
  const uiStateStatus = useSelector(selectUIStateStatus);
  const contentTemplates = useSelector(selectContentTemplatesToSave);
  const contentTHasErrors =
    Object.values(contentTemplates ?? {}).filter(data => data?.errors === true || data?.submitted === false)
      .length > 0;

  const [tabIdx, setTabIdx] = useState(0);
  const [metaData, setMetaData] = useState<IFileMeta>({
    ...((file?.meta ?? {}) as IFileMeta),
    fileName: file?.name ?? '',
  });

  const tabs: ITabProps[] = [
    {
      id: 'file-metadata',
      label: SHARED_TEXT.FILE_ITEM_METADATA.TAB.FILE_METADATA(),
      disabled: false,
    },
    {
      id: 'content-templates',
      label: SHARED_TEXT.FILE_ITEM_METADATA.TAB.CONTENT_TEMPLATES(),
      disabled: false,
    },
  ];

  const onChangeTab = (index: number) => {
    setTabIdx(index);
  };

  function handleOnSave() {
    if (!metaData) return;

    dispatch(
      actions.filesSelectedState.addMetadataAction({
        id: file?.id,
        meta: metaData,
        contentTemplates:
          Object.values(contentTemplates)?.map(data => ({ schemaId: data.schemaId, data: data.data })) ?? [],
      })
    );

    // Reset the child form
    //@ts-ignore
    fileMetaItemRef.current?.resetForm();
  }

  return (
    <>
      <PanelHeader
        disableBorder
        testId={TEST_ID('header')}
        title={SHARED_TEXT.FILE_ITEM_METADATA.PANEL_TITLE()}
        onPanelClose={hidePanel}
      />
      <PanelContent testId={TEST_ID('panel-content')} isLoading={uiStateStatus === 'loading'}>
        <Box className={classes.tabsParent}>
          <Tabs selectedId={tabIdx} tabsList={tabs} onChangeTab={onChangeTab} />
        </Box>
        <Divider />
        <TabPanel className={classes.tabPanelContainer} value={tabIdx} index={0}>
          <FileItemMeta
            ref={fileMetaItemRef}
            file={file}
            metaData={metaData}
            onCommit={meta => {
              setMetaData(meta);
            }}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanelContainer} value={tabIdx} index={1}>
          <ContentTemplates existingTemplates={[]} />
        </TabPanel>
      </PanelContent>
      <footer className={classes.footer}>
        <Button
          id="file-item-meta-cancel-btn"
          data-test={TEST_ID('cancel-btn')}
          onClick={hidePanel}
          variant="text"
          size="large"
          className={classes.cancelBtn}
        >
          {SHARED_TEXT.FILE_ITEM_METADATA.CANCEL()}
        </Button>
        <Button
          id="file-item-meta-save-btn"
          data-test={TEST_ID('save-btn')}
          onClick={handleOnSave}
          size="large"
          variant="contained"
          color="primary"
          disabled={contentTHasErrors}
        >
          {SHARED_TEXT.FILE_ITEM_METADATA.SAVE()}
        </Button>
      </footer>
    </>
  );
};
