import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconButton, Modal, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

export type TPanelModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  sx?: CSSProperties;
  testId?: string;
  labeledBy?: string;
  describedBy?: string;
  ContainerProps?: StackProps;
};

const DEFAULT_STYLES = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  maxHeight: '50vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  borderRadius: '15px',
};

export const PanelModal: React.FC<TPanelModalProps> = ({
  isOpen,
  onClose,
  title = '--',
  content = '--',
  sx = {},
  testId = 'shared-panel-modal',
  labeledBy = 'shared-reusable-modal-title',
  describedBy = 'shared-reusable-modal-description',
  ContainerProps = {},
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby={labeledBy} aria-describedby={describedBy}>
      <Stack
        gap={'10px'}
        sx={{
          ...DEFAULT_STYLES,
          ...sx,
        }}
      >
        <Stack
          sx={{ borderBottom: '1px solid #d5dfe9', padding: '8px 30px', paddingRight: '10px' }}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              margin: '0',
              fontSize: '18px',
              fontFamily: 'Dosis',
              fontStyle: 'normal',
              lineHeight: '22px',
              letterSpacing: '-1.30385e-09px',
              fontWeight: '600',
              cursor: 'default',
              textTransform: 'uppercase',
              userSelect: 'none',
              color: '#5C6269',
            }}
            id={labeledBy}
            variant="h1"
            component="h2"
            data-testid={`${testId}-title`}
          >
            {title}
          </Typography>
          <Tooltip
            title={
              <FormattedMessage
                id="shared-reusable-utils.panel-modal.close-modal"
                defaultMessage="Close"
                description="Close modal tooltip"
              />
            }
            disableInteractive
          >
            <IconButton onClick={onClose} data-testid={`${testId}-header-close`}>
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack
          sx={{ width: '100%', padding: '30px', boxSizing: 'border-box', overflow: 'hidden' }}
          {...ContainerProps}
          data-testid={`${testId}-content`}
        >
          {content}
        </Stack>
      </Stack>
    </Modal>
  );
};
