import { FormattedMessage } from 'react-intl';

export const structuredDataText = {
  title: () => (
    <FormattedMessage
      id="data-center-search.structured-data.title"
      defaultMessage="Search Structured Data"
      description="Data Center Search structured data title"
    />
  ),
  subtitle: () => (
    <FormattedMessage
      id="data-center-search.structured-data.subtitle"
      defaultMessage="Choose the Schema, Property and Values you would like to search for."
      description="Data Center Search structured data title"
    />
  ),
};
