import { FunctionComponent } from 'react';
import { EntityType } from '@aiware/js/interfaces';
import { ISchedule, ISource, ITdoVm, IFolder } from '../../../types';
import Grid from '@mui/material/Grid';
import DateFormatter from '../DateFormatter';
import { useSelector } from 'react-redux';
import { selectIsFluffy, selectTableDataSettings } from '../../../redux/selectors/ui-state';
import Typography from '@mui/material/Typography';
import { useStyles } from '../../components/useStyles';
import { TABLE_DATA_NAMES } from '../../../types/entity-data-settings';

type RowItemTypes = ITdoVm | ISource | ISchedule | IFolder;

const CreatedDateTime: FunctionComponent<{
  item: RowItemTypes;
  xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  itemType: EntityType;
}> = ({ item, xs, itemType }) => {
  const columns = (useSelector(selectTableDataSettings).settings as any)[itemType];
  const showCreated = columns.indexOf('createdDateTime') >= 0;
  const isFluffy = useSelector(selectIsFluffy);
  const { classes } = useStyles();

  return (
    <>
      {showCreated && !isFluffy && (
        <Grid data-test={'item-createdDateTime'} item xs={xs}>
          <DateFormatter value={(item as ISource | ISchedule).createdDateTime} />
        </Grid>
      )}
      {showCreated && isFluffy && (
        <Grid data-test={'item-createdDateTime-fluffy'} item container direction="row" xs={xs}>
          <Typography
            data-test={'createdDateTime-title'}
            className={classes.titleFluffy}
            variant="caption"
            color="textSecondary"
          >
            {TABLE_DATA_NAMES.createdDateTime?.()}:
          </Typography>
          <Typography data-test={'createdDateTime-date'} variant="caption" color="textSecondary">
            <DateFormatter
              value={(item as ISource | ISchedule).createdDateTime}
              isFluffy
              className={classes.timeFluffy}
            />
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default CreatedDateTime;
