export function toCamelCase(str: string): string {
  if (!str) return '';
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(' ') // Split the string by spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(''); // Join all words back together without spaces
}

export function toTitleCase(str: string): string {
  if (!str) return '';
  return str
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Insert space before each uppercase letter
    .replace(/^./, firstLetter => firstLetter.toUpperCase()); // Capitalize the first letter
}
