import { IconButton, Stack, Tooltip, Popover, MenuList, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';
import { SHARED_TEXT } from '../../../../../helpers/shared-text';
import { EDIT_DATA_REGISTRY_PANEL_ID, SDO_VIEWER_PANEL_ID } from '../../../../../constants';
import { mountPanel } from '@aiware/js/panel';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';

interface DataRegistryContextMenuProps {
  dataRegistryId: string;
}

export const DataRegistryContextMenu = ({ dataRegistryId }: DataRegistryContextMenuProps) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const contextMenuId = anchorEl ? `contextMenuId-sdo` : undefined;
  const isOpen = Boolean(anchorEl);
  const isEditDisabled = false;
  const isFullScreen = useSelector(selectIsFullscreenEnabled);

  const handleClose = () => setAnchorEl(null);

  const handleClickContextMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickEdit = () => {
    handleClose();
    const microFrontend = {
      name: EDIT_DATA_REGISTRY_PANEL_ID,
      config: {
        dataRegistryId,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      marginTop: isFullScreen ? 0 : 55,
      marginStart: isFullScreen ? 0 : 80,
      size: 'small',
      parentPanelId: 'DATA_CENTER',
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: EDIT_DATA_REGISTRY_PANEL_ID,
        microFrontend,
        panelConfig,
      })
    );
  };

  const menuItems = [
    {
      id: 'edit',
      label: SHARED_TEXT.dataRegistryBrowser.contextMenu.edit(),
      icon: <Edit fontSize="small" />,
      onClick: handleClickEdit,
      isDisabled: isEditDisabled,
    },
  ];

  return (
    <Stack
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <IconButton onClick={handleClickContextMenu} size={'small'}>
        <MoreVert fontSize="small" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        id={contextMenuId}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
      >
        <MenuList
          sx={theme => ({ width: theme.spacing(34), padding: theme.spacing(1.5) })}
          role="menu"
          onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.id}
              sx={theme => ({
                borderRadius: 1,
                padding: theme.spacing(1),
                '& > svg': {
                  marginRight: theme.spacing(2),
                },
                textTransform: 'none',
              })}
              onClick={item.onClick}
              data-testid={`dc-sdo-context-menu-item-${item.id}`}
              disabled={item.isDisabled}
            >
              {item.icon}
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Stack>
  );
};
