import { FormattedMessage } from 'react-intl';

export const SHARED_TEXT = {
  panelHeaderTitle: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.title"
      defaultMessage="Data Detail"
      description="Panel header for the Data Center Media Details Panel"
    />
  ),
  iconProcessAi: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.process-ai"
      defaultMessage="Run Cognition on this file"
      description="Open Process Ai icon for the Data Center Media Details Panel"
    />
  ),
  iconDownload: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.download"
      defaultMessage="Download"
      description="Download icon for the Data Center Media Details Panel"
    />
  ),
  iconInfo: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.info"
      defaultMessage="View file info"
      description="View info icon for the Data Center Media Details Panel"
    />
  ),
  iconExportEngineAssets: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.export-engine-assets"
      defaultMessage="Export Engine Assets"
      description="Export Engine Assets icon for the Data Center Media Details Panel"
    />
  ),
  iconEnterFullScreen: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.fullscreen-go"
      defaultMessage="Enter Fullscreen"
      description="Enter full screen icon for the Data Center Media Details Panel"
    />
  ),
  iconLeaveFullScreen: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.fullscreen-leave"
      defaultMessage="Leave Fullscreen"
      description="Leave full screen icon for the Data Center Media Details Panel"
    />
  ),
  closePanel: () => (
    <FormattedMessage
      id="os-data-center-browse.media-details-panel.close-panel"
      defaultMessage="Close"
      description="Close panel icon for the Data Center Media Details Panel"
    />
  ),
};
