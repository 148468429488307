import { FunctionComponent, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { FormattedMessage } from 'react-intl';

interface Props {
  step: number;
  size: number;
  active?: boolean;
  visited?: boolean;
  alwaysShowLabel?: boolean;
  label: string;
  onClick: (index: number) => void;
  nonLinear?: boolean;
  isFirstOrOnly?: boolean;
  idPrefix?: string;
}

const ProgressStep: FunctionComponent<Props> = ({
  step,
  size,
  active,
  visited,
  alwaysShowLabel,
  label,
  onClick,
  nonLinear,
  isFirstOrOnly,
  idPrefix,
}: Props) => {
  const [showLabel, setShowLabel] = useState(alwaysShowLabel);
  const [hovered, setHovered] = useState(false);

  const overlapMarginLeft = -size * 0.2;

  const style = {
    background: active ? '#1871E8' : visited ? '#555F7C' : '#F2F5F9',
    marginLeft: `${alwaysShowLabel ? 0 : overlapMarginLeft}px`,
    width: size,
    height: size,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontSize: '14px',
    fontFamily: 'Nunito',
    color: active || visited || hovered ? '#FFFFFF' : '#2A323C',
    lineHeight: '20px',
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
    cursor: 'default',
    userSelect: 'none',
    '&:hover':
      !nonLinear && visited
        ? {
            marginLeft: `${isFirstOrOnly ? overlapMarginLeft : 0}px`,
            opacity: [0.9, 0.8, 0.7],
            cursor: 'pointer',
          }
        : {},
  };

  const handleMouseOver = () => {
    if (nonLinear || !visited) {
      return;
    }

    setHovered(true);

    if (alwaysShowLabel) {
      return;
    }

    setShowLabel(true);
  };

  const handleMouseOut = () => {
    if (nonLinear || !visited) {
      return;
    }

    setHovered(false);

    if (alwaysShowLabel) {
      return;
    }

    setShowLabel(false);
  };

  const handleClick = () => {
    if (!nonLinear && visited) {
      onClick(step - 1);
    }
  };

  return (
    <Box display={'flex'} sx={{ flexGrow: 1 }} alignItems={'center'}>
      <Box
        sx={style}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
        data-test={`${idPrefix}-progress-stepper-icon-step-${step}`}
      >
        {step}
      </Box>
      <Collapse in={showLabel} orientation={'horizontal'} mountOnEnter unmountOnExit>
        <Typography
          sx={{
            marginLeft: '10px',
            marginRight: `${alwaysShowLabel ? 10 : 20}px`,
            userSelect: 'none',
          }}
          noWrap
          data-test={`${idPrefix}-progress-stepper-label-step-${step}`}
        >
          <FormattedMessage
            id="{idPrefix}-progress-stepper.label-step-{step}"
            defaultMessage="{label}"
            description="Step {step}: {label}"
            values={{
              idPrefix: idPrefix,
              label: label,
              step: step,
            }}
          />
        </Typography>
      </Collapse>
    </Box>
  );
};

export default ProgressStep;
