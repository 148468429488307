import createSvgIcon from '../createSvgIcon';
export const LibraryCenter = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2325 9.07495C17.6458 9.07495 17.1325 9.37744 16.8392 9.83578H12.5308L12.5767 7.72745H16.3533V5.35328C16.8117 5.05078 17.105 4.53745 17.105 3.95995C17.105 3.03411 16.3533 2.28245 15.4275 2.28245C14.5017 2.28245 13.75 3.03411 13.75 3.95995C13.75 4.54661 14.0525 5.05995 14.5108 5.35328V5.89411H12.6133L12.6592 3.83161L12.6867 2.40161L11.3025 2.02578C10.8167 1.89745 10.3767 1.83328 9.93667 1.83328C7.095 1.82411 4.77583 4.14328 4.77583 6.99411C4.77583 7.05828 4.77583 7.12245 4.77583 7.19578C3.52917 8.15828 2.75 9.66161 2.75 11.2841C2.75 12.7691 3.40083 14.1716 4.50083 15.1433C4.96833 17.5174 7.0675 19.3141 9.57917 19.3141C10.0467 19.3141 10.5325 19.2408 11.055 19.0849L12.3383 18.6999L12.3658 17.3616L12.4117 15.4366H14.52V16.0783C14.0617 16.3808 13.7592 16.8941 13.7592 17.4716C13.7592 18.3974 14.5108 19.1491 15.4367 19.1491C16.3625 19.1491 17.1142 18.3974 17.1142 17.4716C17.1142 16.8849 16.8117 16.3716 16.3625 16.0783V13.6033H12.4575L12.5033 11.6508H16.8575C17.16 12.1091 17.6733 12.4116 18.2508 12.4116C19.1767 12.4116 19.9283 11.6599 19.9283 10.7341C19.9283 9.80828 19.1767 9.05661 18.2508 9.05661L18.2325 9.07495ZM9.57917 17.4899C7.73667 17.4899 6.2425 15.9958 6.2425 14.1533C5.2525 13.5758 4.58333 12.5124 4.58333 11.2841C4.58333 10.4866 4.8675 9.76245 5.335 9.19411C5.71083 9.72578 6.30667 10.2849 7.16833 10.4958L7.59917 8.71745C7.21417 8.62578 6.9575 8.33245 6.81083 8.11245C6.6825 7.76411 6.6 7.38828 6.6 6.99411C6.6 5.15161 8.09417 3.65745 9.93667 3.65745C10.2392 3.65745 10.5325 3.71245 10.8167 3.78578L10.6608 10.9999H10.6333C10.5417 11.0824 10.1567 11.1558 9.89083 11.1374L9.80833 12.9708C9.80833 12.9708 9.89083 12.9708 9.955 12.9708C10.1108 12.9708 10.3583 12.9524 10.6242 12.9066L10.5233 17.3341C10.2208 17.4258 9.90917 17.4899 9.57917 17.4899V17.4899Z"
      fill="#555F7C"
    />
  </svg>,
  'LibraryCenter'
);
