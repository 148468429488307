import { FormattedMessage } from 'react-intl';
import { EntityType } from '@aiware/js/interfaces';
import { ISource, ISchedule, ITdo, IFolder } from './';

export type SourceHeaders = (keyof ISource)[];
export type ScheduleHeaders = (keyof ISchedule)[];
export type TdoHeaders = (keyof ITdo)[];
export type FolderFileHeaders = (keyof (IFolder & ITdo))[];

export type TableDataSettingsState = {
  isOpen: boolean;
  entityType?: EntityType;
  settings: {
    [EntityType.Sources]: SourceHeaders;
    [EntityType.Schedules]: ScheduleHeaders;
    [EntityType.Tdos]: TdoHeaders;
    [EntityType.Folders]: FolderFileHeaders;
  };
};

type TableDataSettings<T> = {
  required: (keyof T)[];
  canToggle: (keyof T)[];
};

export const SOURCE_HEADERS: SourceHeaders = [
  'name',
  'sourceTypeId',
  'scheduleCount',
  'modifiedDateTime',
  'createdDateTime',
];

export const SCHEDULE_HEADERS: ScheduleHeaders = [
  'scheduleType',
  'name',
  'modifiedDateTime',
  'createdDateTime',
  'isActive',
];

export const TDO_HEADERS: TdoHeaders = ['fileType', 'name', 'sourceTypeId', 'mediaStartTime', 'engineIds'];

export const FOLDER_FILE_HEADERS: FolderFileHeaders = ['fileType', 'name', 'modifiedDateTime', 'engineIds'];

export const initialTableDataSettings: TableDataSettingsState = {
  isOpen: false,
  settings: {
    [EntityType.Sources]: SOURCE_HEADERS,
    [EntityType.Schedules]: SCHEDULE_HEADERS,
    [EntityType.Tdos]: TDO_HEADERS,
    [EntityType.Folders]: FOLDER_FILE_HEADERS,
  },
};

export type TableDataKeys = keyof (ISource & ISchedule & ITdo & IFolder);

export const TABLE_DATA_NAMES: {
  [key in TableDataKeys]?: () => JSX.Element;
} = {
  name: () => <FormattedMessage id="os-data-center-browse.+fYwFL" defaultMessage="Name" description="Name" />,
  scheduleType: () => (
    <FormattedMessage id="os-data-center-browse.7N3VsD" defaultMessage="Type" description="Type" />
  ),
  sourceTypeId: () => (
    <FormattedMessage id="os-data-center-browse.7N3VsD" defaultMessage="Type" description="Type" />
  ),
  fileType: () => (
    <FormattedMessage id="os-data-center-browse.7N3VsD" defaultMessage="Type" description="Type" />
  ),
  scheduleCount: () => (
    <FormattedMessage id="os-data-center-browse.Reu+b6" defaultMessage="Schedules" description="Schedules" />
  ),
  modifiedDateTime: () => (
    <FormattedMessage id="os-data-center-browse.uf3R+i" defaultMessage="Modified" description="Modified" />
  ),
  createdDateTime: () => (
    <FormattedMessage id="os-data-center-browse.M+cvKX" defaultMessage="Created" description="Created" />
  ),
  mediaStartTime: () => (
    <FormattedMessage
      id="os-data-center-browse.udtp3p"
      defaultMessage="Start Time"
      description="Start Time"
    />
  ),
  isActive: () => (
    <FormattedMessage id="os-data-center-browse.adg31p" defaultMessage="Status" description="Status" />
  ),
  engineIds: () => (
    <FormattedMessage id="os-data-center-browse.0kP8Pz" defaultMessage="AI Engines" description="Engines" />
  ),
};

const SOURCE_TABLE_DATA_SETTINGS: TableDataSettings<ISource> = {
  required: ['name', 'sourceTypeId'],
  canToggle: ['createdDateTime', 'modifiedDateTime', 'scheduleCount'],
};

const SCHEDULE_TABLE_DATA_SETTINGS: TableDataSettings<ISchedule> = {
  required: ['scheduleType', 'name'],
  canToggle: ['createdDateTime', 'modifiedDateTime'],
};

const TDO_TABLE_DATA_SETTINGS: TableDataSettings<ITdo> = {
  required: ['name', 'fileType'],
  canToggle: ['mediaStartTime', 'engineIds'],
};

const FOLDER_TABLE_DATA_SETTINGS: TableDataSettings<IFolder & ITdo> = {
  required: ['name', 'fileType'],
  canToggle: ['modifiedDateTime', 'engineIds'],
};

export const TABLE_DATA_SETTINGS = {
  [EntityType.Sources]: SOURCE_TABLE_DATA_SETTINGS,
  [EntityType.Schedules]: SCHEDULE_TABLE_DATA_SETTINGS,
  [EntityType.Tdos]: TDO_TABLE_DATA_SETTINGS,
  [EntityType.Folders]: FOLDER_TABLE_DATA_SETTINGS,
};
