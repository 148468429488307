import { FunctionComponent, ReactNode, useEffect, useState, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import size from 'lodash/size';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { hidePanel } from '@aiware/js/panel';
import OverlappingSteps from './components/OverlappingSteps';
import VisibleSteps from './components/VisibleSteps';
import TransitionWrapper from './components/TransitionWrapper';
import CancelModal from './components/CancelModal';
import ResumeSessionModal from './components/ResumeSessionModal';
import { Tab, Tabs, tabsClasses } from '@mui/material';

const stepIconSize = 36;
const stepperSectionHeight = 86;
const buttonSectionHeight = 86;

const tabsStyle = {
  '& .Sdk-MuiButtonBase-root.Sdk-MuiTab-root.Sdk-MuiTab-textColorPrimary.Sdk-Mui-selected': {
    color: '#2A323C!important',
  },
  '& .Sdk-MuiTabs-indicator': {
    backgroundColor: '#2A323C',
  },
};

type SlideDirection = 'left' | 'right';

interface Props {
  panelName: string;
  sessionKey: string;
  entityId?: string;
  enableTabs?: boolean;
  onResumeSession?: (session: any) => void;
  steps: Array<string>;
  stepContent: Array<ReactNode>;
  maxVisibleFutureSteps?: number;
  nextButtonDisabled?: boolean;
  onActiveStepChange?: (activeStep: number) => void;
  nonLinear?: boolean;
  idPrefix?: string;
  isSkipButtonEnabled?: boolean;
  onStepChange?: (currentStep: number, nextStep: number) => boolean | Promise<boolean>;
  onCancelClick?: () => void;
  onFinalStepButtonClick?: () => void;
  finalStepButtonLabel?: string;
  BodySx?: BoxProps['sx'];
  FooterSx?: BoxProps['sx'];
  customFinalStepButtons?: ReactNode | Array<ReactNode>;
  showCancel?: boolean;
}

const ProgressStepper: FunctionComponent<Props> = ({
  panelName,
  sessionKey,
  entityId,
  onResumeSession,
  steps,
  stepContent,
  maxVisibleFutureSteps = 2,
  nextButtonDisabled,
  onActiveStepChange,
  nonLinear,
  enableTabs = false,
  idPrefix = 'ui',
  isSkipButtonEnabled = false,
  onStepChange,
  onCancelClick,
  onFinalStepButtonClick,
  finalStepButtonLabel = 'Submit',
  BodySx = {},
  FooterSx = {},
  customFinalStepButtons,
  showCancel = true,
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [visited, setVisited] = useState<Array<boolean>>(map(steps, () => false));
  const [slideDirections, setSlideDirections] = useState<Array<SlideDirection>>(
    map(stepContent, () => 'right')
  );
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const totalSteps = size(steps);

  if (maxVisibleFutureSteps < 0) {
    maxVisibleFutureSteps = 0;
  }

  useEffect(() => {
    onActiveStepChange?.(activeStep);
  }, [activeStep]);

  // IntialRender
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, []);

  const updateVisited = (index: number) => {
    const updatedVisited = visited;
    updatedVisited[index] = true;
    setVisited(updatedVisited);
  };

  const updateSlideDirections = (newStep: number) => {
    const updatedSlideDirections = slideDirections;
    if (activeStep < newStep) {
      updatedSlideDirections[activeStep] = 'right';
      updatedSlideDirections[newStep] = 'left';
    } else if (newStep < activeStep) {
      updatedSlideDirections[activeStep] = 'left';
      updatedSlideDirections[newStep] = 'right';
    } else {
      return;
    }

    setSlideDirections(updatedSlideDirections);
  };

  const handleClick = () => {
    if (activeStep === totalSteps - 1 || enableTabs) {
      onFinalStepButtonClick?.();
      return;
    }
    handleNextStep();
  };

  const handleNextStep = () => {
    updateVisited(activeStep);

    const nextStep = activeStep + 1;
    if (nextStep < totalSteps) {
      updateSlideDirections(nextStep);
      setActiveStep(nextStep);
    }
  };

  const handlePreviousStep = () => {
    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      updateVisited(activeStep);
      updateSlideDirections(prevStep);
      setActiveStep(prevStep);
    }
  };

  const handleTabChange = async (newValue: number) => {
    if (onStepChange) {
      const shouldChange = await Promise.resolve(onStepChange(activeStep, newValue));
      if (!shouldChange) {
        return;
      }
    }

    setActiveStep(newValue);
  };

  const handleStep = async (index: number) => {
    if (visited[index]) {
      if (onStepChange) {
        const shouldChange = await Promise.resolve(onStepChange(activeStep, index));
        if (!shouldChange) {
          return;
        }
      }

      updateVisited(activeStep);
      updateSlideDirections(index);
      setActiveStep(index);
    }
  };

  const session = localStorage.getItem(sessionKey);

  const handleConfirmCancel = () => {
    setOpenCancelModal(false);
    localStorage.removeItem(sessionKey);
    onCancelClick?.();
    dispatch(hidePanel(panelName));
  };

  const openCancelDialog = () => {
    if (session) {
      setOpenCancelModal(true);
      return;
    }
    onCancelClick?.();
    dispatch(hidePanel(panelName));
  };

  const activeStepIsLast = () => activeStep === totalSteps - 1 || enableTabs;

  const getNavButtons = () =>
    activeStepIsLast() && !isNil(customFinalStepButtons)
      ? customFinalStepButtons
      : (!activeStepIsLast() || !isNil(onFinalStepButtonClick)) && (
          <>
            {!activeStepIsLast() && isSkipButtonEnabled && (
              <Button
                variant={'outlined'}
                color={'secondary'}
                data-test={`${idPrefix}-progress-stepper-skip-button`}
                onClick={handleClick}
                disabled={nextButtonDisabled}
              >
                <FormattedMessage
                  id="{idPrefix}-progress-stepper.skip-button-label"
                  defaultMessage="{message}"
                  description="{description} Button"
                  values={{
                    idPrefix: idPrefix,
                    message: 'Skip',
                    description: 'Skip Step',
                  }}
                />
              </Button>
            )}
            {!isSkipButtonEnabled && (
              <Button
                variant={'contained'}
                color={'primary'}
                data-test={`${idPrefix}-progress-stepper-next-button`}
                onClick={handleClick}
                disabled={nextButtonDisabled}
              >
                <FormattedMessage
                  id="{idPrefix}-progress-stepper.next-button-label"
                  defaultMessage="{message}"
                  description="{description} Button"
                  values={{
                    idPrefix: idPrefix,
                    message: activeStepIsLast() ? finalStepButtonLabel : 'Next',
                    description: activeStepIsLast() ? finalStepButtonLabel : 'Next Step',
                  }}
                />
              </Button>
            )}
          </>
        );

  return (
    <Box height={'100%'}>
      <Grid
        container
        direction={'column'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        height={`calc(100% - ${buttonSectionHeight}px)`}
      >
        {!enableTabs ? (
          <Grid item padding={5} height={stepperSectionHeight} width={'100%'}>
            <Box display={'flex'}>
              <OverlappingSteps
                steps={steps}
                stepIconSize={stepIconSize}
                visited={visited}
                start={0}
                end={activeStep}
                onClick={handleStep}
                nonLinear={nonLinear}
                idPrefix={idPrefix}
              />
              <VisibleSteps
                steps={steps}
                activeStep={activeStep}
                maxVisibleFutureSteps={maxVisibleFutureSteps}
                stepIconSize={stepIconSize}
                visited={visited}
                totalSteps={totalSteps}
                onClick={handleStep}
                nonLinear={nonLinear}
                idPrefix={idPrefix}
              />
              <OverlappingSteps
                steps={steps}
                stepIconSize={stepIconSize}
                visited={visited}
                start={activeStep + maxVisibleFutureSteps + 1}
                end={totalSteps}
                invertOverlap
                onClick={handleStep}
                nonLinear={nonLinear}
                idPrefix={idPrefix}
              />
            </Box>
          </Grid>
        ) : (
          <Box
            sx={{
              width: '100%',
              padding: theme => theme.spacing(2, 6, 0, 6),
            }}
          >
            <Tabs
              value={activeStep}
              onChange={(_, newValue) => handleTabChange(newValue)}
              variant="scrollable"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Sdk-Mui-disabled': { opacity: 0.3 },
                },
                ...tabsStyle,
              }}
            >
              {steps.map((step, index) => {
                return (
                  <Tab
                    key={index}
                    label={step}
                    value={index}
                    data-testid={`${idPrefix}-progess-stepper-tabs-tab-${index}`}
                  />
                );
              })}
            </Tabs>
          </Box>
        )}
        <Grid item width={'100%'}>
          <Divider />
        </Grid>
        <Grid
          container
          item
          xs
          overflow={'auto'}
          width={'100%'}
          ref={containerRef}
          sx={{ ...BodySx, overflowX: 'hidden' }}
        >
          <Box id="body-container" height={'100%'} width={'100%'}>
            {map(stepContent, (content, index) => (
              <Slide
                key={index}
                container={containerRef.current}
                direction={slideDirections[index]}
                in={activeStep === index}
                mountOnEnter
                unmountOnExit
                timeout={isInitialRender || enableTabs ? 0 : undefined}
              >
                <TransitionWrapper component={content} step={index + 1} idPrefix={idPrefix} />
              </Slide>
            ))}
          </Box>
        </Grid>
        {!enableTabs && (
          <Grid item width={'100%'}>
            <Divider />
          </Grid>
        )}
      </Grid>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={5}
        height={buttonSectionHeight}
        sx={FooterSx}
      >
        <Box id="footer-container">
          {activeStep !== 0 && !enableTabs && (
            <Button
              variant={'text'}
              data-test={`${idPrefix}-progress-stepper-back-button`}
              onClick={handlePreviousStep}
              sx={{ color: '#2A323C' }}
            >
              <FormattedMessage
                id="{idPrefix}-progress-stepper.back-button-label"
                defaultMessage="Back"
                description="Previous Step Button"
                values={{
                  idPrefix: idPrefix,
                }}
              />
            </Button>
          )}
          {showCancel && (
            <Button
              variant={'text'}
              data-test={`${idPrefix}-progress-stepper-cancel-button`}
              onClick={openCancelDialog}
              sx={{ color: '#2A323C' }}
            >
              <FormattedMessage
                id="{idPrefix}-progress-stepper.cancel-button-label"
                defaultMessage="Cancel"
                description="Cancel Button"
              />
            </Button>
          )}
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={3}>
          {getNavButtons()}
        </Box>
      </Box>
      <CancelModal
        open={openCancelModal}
        handleConfirm={handleConfirmCancel}
        handleClose={() => setOpenCancelModal(false)}
        idPrefix={idPrefix}
      />
      <ResumeSessionModal sessionKey={sessionKey} onResumeSession={onResumeSession} entityId={entityId} />
    </Box>
  );
};

export { ProgressStepper };

export default ProgressStepper;
