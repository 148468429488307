import { useApplicationCardStyles } from './useStyles';
import { Avatar, Switch, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import { injectIntl, WithIntlProps } from 'react-intl';
import { useEffect, useState, FC, ChangeEvent } from 'react';
import Divider from '@mui/material/Divider';
import { reducerActions } from '.';
import { TApplicationCard, TOrgRoles, TIntl, TRoleAction, TRole } from '../../types';
import { emptyAppAvatar } from '@aiware/shared/assets';
import { HIDDEN_APP_ROLE_IDS } from '../../constants';

type TProps = TApplicationCard & TOrgRoles & TIntl & TRoleAction;

const ApplicationCard = ({ intl, card, manageRoles, roles = [] }: TProps) => {
  const [access, setAccess] = useState(false);
  const [role, setRole] = useState('');
  const [roleId, setRoleId] = useState('');
  const {
    applicationName,
    applicationDescription,
    applicationIconUrl,
    applicationId,
    providedRole,
    applicationKey,
  } = card;
  const { classes } = useApplicationCardStyles();
  const [selectedAppName, setSelectedAppName] = useState<string | null>(null);
  const [roleTypeLists, setRoleTypeLists] = useState<TRole[]>([]);

  useEffect(() => {
    if (providedRole) {
      setAccess(true);
      setSelectedAppName(applicationKey ?? null);
    }
  }, [providedRole]);

  useEffect(() => {
    if (roles && selectedAppName) {
      const rolesList = roles.filter(
        (item: TRole) => item.appName === selectedAppName && !HIDDEN_APP_ROLE_IDS.includes(item.id)
      );
      rolesList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      setRoleTypeLists(rolesList);
    }
  }, [selectedAppName]);

  const handleChange = () => {
    if (access) {
      setRole('');
      manageRoles({
        type: reducerActions.REMOVE_ROLE,
        payload: { applicationId },
      });
      setSelectedAppName('');
    } else {
      setSelectedAppName(applicationKey?.toLowerCase() ?? null);
    }
    setAccess(!access);
  };

  const handleRoleChange = (ev: SelectChangeEvent) => {
    setRole(ev.target.value);
    const id = roleTypeLists.find(role => role.name === ev.target.value)!.id;
    setRoleId(id);
  };

  const label = intl.formatMessage({
    id: `organization-panel.user-application-role`,
    defaultMessage: 'Role Type',
    description: 'Multi-Org user application role',
  });

  useEffect(() => {
    if (role && roleId) {
      manageRoles({
        type: reducerActions.SET_ROLE,
        payload: {
          applicationId,
          role,
          roleId,
          application: {
            name: applicationName,
            iconUrl: applicationIconUrl,
          },
        },
      });
    }
  }, [roleId]);

  useEffect(() => {
    if (roleTypeLists.length > 0 && selectedAppName && !providedRole) {
      setRole(roleTypeLists[0]!.name);
      setRoleId(roleTypeLists[0]!.id);
    } else if (roleTypeLists.length > 0 && selectedAppName && providedRole) {
      const roleObject = roleTypeLists.find(role => role.id === providedRole);
      if (roleObject) {
        setRole(roleObject.name);
        setRoleId(roleObject.id);
      }
    }
  }, [roleTypeLists, providedRole]);

  return (
    <div className={classes.root} data-testid="multi-org-app-card">
      <div className={classes.appInfo}>
        <div className={classes.flexContainer}>
          <div className={classes.avatarCol}>
            <Avatar
              src={applicationIconUrl || emptyAppAvatar}
              data-testid="multi-org-app-card-avatar"
              className={classes.avatar}
            ></Avatar>
          </div>
          <div>
            <div className={classes.cardTitle}>{applicationName}</div>
            <div className={classes.cardText}>{applicationDescription}</div>
          </div>
        </div>
        <div className={classes.toggleCol}>
          <Switch
            data-testid="multi-org-app-card-access-toggle"
            value={access}
            checked={access}
            onChange={handleChange}
            className={access ? classes.toggleOn : classes.toggleOff}
            disabled={!roles.length}
          />
        </div>
      </div>
      <div className={classes.selectCol}>
        {access && (
          <FormControl>
            <InputLabel id="multi-org-role-type">{label}</InputLabel>
            <Select
              labelId="multi-org-role-type"
              data-testid="application-card-user-role"
              value={role}
              label={label}
              onChange={handleRoleChange}
              className={classes.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              {roleTypeLists &&
                roleTypeLists.map((role: TRole) => (
                  <MenuItem
                    value={role.name}
                    key={role.id}
                    className={classes.menuItem}
                    data-testid={`multi-org-application-role-${role.id}`}
                  >
                    <div className={classes.optionTitle}>{role.name}</div>
                    <div className={classes.optionText}>{role.description}</div>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default injectIntl(ApplicationCard) as FC<WithIntlProps<TProps>>;
