import { baseGraphQLApiWithError } from '@aiware/shared/redux';
import { selectApiConfigs } from '../redux/selectors';
import { ErrorResponseType, ICreateTdoResponse, IFileMeta, IContentTemplate } from '../types';

interface ITDOAssetVariables {
  name: string;
  uri: string;
  parentFolderId: string;
  startDateTime: string;
  contentType: string;
  size: number;
  meta?: IFileMeta;
  contentTemplates?: IContentTemplate[];
}

export async function createTDOWithAsset(
  apiConfigs: ReturnType<typeof selectApiConfigs>,
  assetVariables: ITDOAssetVariables
): Promise<{
  data: ICreateTdoResponse | null;
  errors: ErrorResponseType;
}> {
  try {
    const name = 'createTDOWithAsset';
    const query = `mutation ($input: CreateTDOWithAsset!) {
        ${name}(input: $input) {
          id
          name
          details
          modifiedDateTime
          primaryAsset(assetType: "media") {
            contentType
            signedUri
          }
          folders{
            folderPath{
              name
              id
            }
          }
          sourceData {
            source {
              id
              sourceTypeId
            }
          }
          thumbnailUrl
        }
      }
    `;

    const startDateTime = assetVariables?.meta?.startDateTime
      ? assetVariables?.meta?.startDateTime
      : assetVariables.startDateTime;
    const variables = {
      input: {
        addToIndex: true,
        contentType: assetVariables.contentType,
        name: assetVariables.name,
        uri: assetVariables.uri,
        parentFolderId: assetVariables.parentFolderId,
        startDateTime,
        stopDateTime: startDateTime,
        assetType: 'media',
        ...(assetVariables?.meta?.thumbnailUrl ? { thumbnailUrl: assetVariables?.meta?.thumbnailUrl } : {}),
        ...(assetVariables?.contentTemplates?.length && assetVariables?.contentTemplates?.length > 0
          ? { contentTemplates: assetVariables.contentTemplates }
          : {}),
        details: {
          veritoneFile: {
            filename: assetVariables?.meta?.fileName,
            mimetype: assetVariables.contentType,
            size: assetVariables.size,
          },
          tags: assetVariables?.meta?.tags,
        },
      },
    };

    const result = await baseGraphQLApiWithError<{
      [name]: ICreateTdoResponse;
      errors?: ErrorResponseType;
    }>({
      query,
      variables,
      ...apiConfigs,
    });

    return {
      data: result[name],
      errors: result.errors || null,
    };
  } catch (e) {
    return {
      data: null,
      //@ts-ignore
      errors: e.message || 'Unable to create TDO',
    };
  }
}
