import { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { FormattedMessage } from 'react-intl';

const styles: Record<string, SxProps> = {
  buttonBar: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginRight: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  button: {
    mr: 2,
  },
};

interface IProps {
  isValid: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export const ButtonBar: FC<IProps> = ({ isValid, onCancel, onSave }) => (
  <Box sx={styles['buttonBar']} data-test="user-group-details.settings-tab.buttons">
    <Button
      color="inherit"
      onClick={onCancel}
      sx={styles['button']}
      data-testid="os-admin-panel-groups.settings-tab-cancel-btn"
      data-test="os-admin-panel-groups.settings-tab-cancel-btn"
    >
      <FormattedMessage
        id="os-admin-panel-groups.group-details-settings-cancel-button"
        defaultMessage="Cancel"
        description="Cancel button in Group Details panel Settings tab"
      />
    </Button>
    <Button
      color="primary"
      variant="contained"
      disabled={!isValid}
      onClick={onSave}
      sx={styles['button']}
      data-testid="os-admin-panel-groups.settings-tab-save-btn"
      data-test="os-admin-panel-groups.settings-tab-save-btn"
    >
      <FormattedMessage
        id="os-admin-panel-groups.group-details-settings-save-button"
        defaultMessage="Save Changes"
        description="Create Group button in Group Details panel Settings tab"
      />
    </Button>
  </Box>
);

export default ButtonBar;
