import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  notificationTypeItemBox: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 1, 1),
    height: '100%',
    fontSize: 10,
    lineHeight: '16px',
    justifyContent: 'center',
  },
  disableType: {
    color: theme.palette.grey[500],
  },
}));

export default useStyles;
