import { makeStyles } from 'tss-react/mui';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Badge,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Clear, Search } from '@mui/icons-material';

const useStyles = makeStyles()(_theme => ({
  searchInput: {
    opacity: 0,
    width: '0px',
    MozTransition: 'all 300ms ease',
    WebkitTransition: 'all 300ms ease',
    '&.is-visible': {
      width: '400px',
      opacity: 1,
      marginRight: '5px',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 -1px 1px rgba(255, 255, 255, 0.25), 0 1px 5px rgba(0, 0, 0, 0.15)',
    },
  },
  searchContainer: { display: 'flex' },
}));

export const TableSearchInputButton = ({
  onChange,
  onClear,
  onToggleSearch,
  testId,
  placeholderText,
  searchValue,
  inputValue,
  isSearching,
  isSearchVisible,
  searchItemTooltipText = '',
  searchTooltipTextOverride,
  disabled = false,
  disableAutoFocus = false,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onToggleSearch?: () => void;
  testId: string;
  placeholderText: string;
  searchValue: string;
  inputValue: string;
  isSearching: boolean;
  isSearchVisible: boolean;
  searchItemTooltipText?: string;
  searchTooltipTextOverride?: string | JSX.Element;
  disabled?: boolean;
  disableAutoFocus?: boolean;
}) => {
  const { classes } = useStyles();
  const showSearchBadge = !!searchValue && !isSearchVisible;

  const getButtonTooltip = () => {
    if (disabled)
      return (
        <FormattedMessage
          id="table-search-components.search-btn.search-disabled"
          defaultMessage="Search is not available"
          description="tooltip message on the table data search"
        />
      );

    if (isSearchVisible)
      return (
        <FormattedMessage
          id="table-search-components.search-btn.hide-search"
          defaultMessage="Hide Search"
          description="tooltip message on the table data search"
        />
      );

    if (searchValue)
      return (
        <>
          <FormattedMessage
            id="table-search-components.search-btn.searching-for"
            defaultMessage="Searching for "
            description="tooltip message on the table data search"
          />{' '}
          {`"${searchValue}"`}
        </>
      );

    if (searchTooltipTextOverride) return <>{searchTooltipTextOverride}</>;

    return (
      <>
        <FormattedMessage
          id="table-search-components.search-btn.search-text"
          defaultMessage="Search"
          description="tooltip message on the table data search"
        />
        {searchItemTooltipText && (
          <span style={{ textTransform: 'capitalize' }}>{` ${searchItemTooltipText}`}</span>
        )}
      </>
    );
  };

  return (
    <>
      <TextField
        autoFocus={disableAutoFocus ? false : isSearchVisible}
        inputRef={input => {
          if (input !== null) {
            isSearchVisible && !disableAutoFocus && input.focus();
          }
        }}
        data-test={`table-search-components.search-label.${testId}`}
        onClick={e => e.stopPropagation()}
        className={`${classes.searchInput} ${isSearchVisible ? 'is-visible' : ''}`}
        onChange={isSearchVisible ? onChange : undefined}
        value={inputValue}
        placeholder={isSearchVisible ? placeholderText : ''}
        InputProps={{
          style: {
            borderRadius: '50px', // Updated border radius value
            height: '35px',
          },
          endAdornment: (
            <InputAdornment position="end">
              {isSearching && <CircularProgress size={20} thickness={5} />}
              {!isSearching && inputValue && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id="table-search-components.clear-search.label"
                      defaultMessage="Clear Search"
                      description="Label for the button to clear the filter"
                    />
                  }
                >
                  <IconButton size={'small'} onClick={onClear}>
                    <Clear />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
      {onToggleSearch && (
        <Tooltip title={getButtonTooltip()}>
          <Badge
            invisible={!showSearchBadge}
            badgeContent={
              <Typography fontWeight="bold" color="white" variant="caption">
                <FormattedMessage
                  id="table-search-components.search-btn.active"
                  defaultMessage="Active"
                  description="badge message on the table data search"
                />
              </Typography>
            }
            color="primary"
          >
            <IconButton
              onClick={onToggleSearch}
              data-testid={`table-search-components.search-icon.${testId}`}
              aria-labelledby={`table-search-components.search-label.${testId}`}
              disabled={disabled}
              size="small"
            >
              <Search
                sx={{
                  ...(showSearchBadge
                    ? {
                        color: '#1871E8',
                      }
                    : {}),
                }}
              />
            </IconButton>
          </Badge>
        </Tooltip>
      )}
    </>
  );
};
