import { FunctionComponent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import useStyles from './useStyles';
import { SHARED_TEXT } from '../../helpers/shared-text';
import InviteRequestsListActions from './InviteRequestsListActions';
import InviteRequestsListItem from './InviteRequestsListItem';
import { organizationInvitesSelectors } from '../../redux/selectors';
import ErrorState from '../OrganizationPanel/shared/ErrorState';
import NullState from '../OrganizationPanel/InvitesTab/EmptyStateInvites';
import { actions } from '../../redux/slices/organizationInvites.state';
import { EOrganizationInviteAction, TUpdateOrganizationInviteInput } from '../../types';
import { LoadingButton } from '@mui/lab';

const InviteRequests: FunctionComponent<{
  onPanelClose: () => void;
}> = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = useState<{ [id: string]: EOrganizationInviteAction }>({});

  const [confirmClicked, setConfirmClicked] = useState(false);

  const pendingOrgInvites = useSelector(organizationInvitesSelectors.selectPendingOrgInvites);
  const uiStatus = useSelector(organizationInvitesSelectors.selectPendingOrgInvitesStatus);
  const setStatus = (id: string, value: EOrganizationInviteAction) => {
    setSelectedItems(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };

  const approveAll = () => {
    pendingOrgInvites.forEach(({ organizationInviteId }) =>
      setStatus(organizationInviteId, EOrganizationInviteAction.approve)
    );
  };

  const rejectAll = () => {
    pendingOrgInvites.forEach(({ organizationInviteId }) =>
      setStatus(organizationInviteId, EOrganizationInviteAction.reject)
    );
  };

  const onApproveRequest = (id: string) => {
    setStatus(id, EOrganizationInviteAction.approve);
  };

  const onRejectRequest = (id: string) => {
    setStatus(id, EOrganizationInviteAction.reject);
  };

  const getItemStatus = (id: string) => {
    return selectedItems[id];
  };

  const onConfirm = () => {
    if (!confirmClicked) {
      // format payload from selected invites
      const payload: TUpdateOrganizationInviteInput[] = [];
      for (const invite of pendingOrgInvites) {
        if (invite.organizationInviteId in selectedItems) {
          const appAndRoleIds = invite.applicationRoles.map(({ application, role }) => ({
            applicationId: application!.id,
            roleId: role!.id,
          }));

          payload.push({
            organizationInviteId: invite.organizationInviteId,
            applicationRoles: appAndRoleIds,
            action: selectedItems[invite.organizationInviteId],
          });
        }
      }

      payload.length > 0 && dispatch(actions.updatePendingInviteStatus(payload));
      setConfirmClicked(true);
    }
  };

  const canConfirm = Object.keys(selectedItems).length > 0;

  const handleRetry = () => {
    // TODO: check if isAdmin
    dispatch(actions.fetchPendingInvitesStart());
  };

  const renderInviteRequest = () => {
    if (uiStatus === 'failure') {
      return (
        <ErrorState
          onRetry={handleRetry}
          errorMessage={
            <FormattedMessage
              id="os-organization-panel.invites.request-tab.error-message"
              defaultMessage="Something went wrong while loading your organization invite requests."
              description="my organizations invite request error state"
            />
          }
        />
      );
    } else if (uiStatus === 'success' && pendingOrgInvites.length === 0) {
      return <NullState type="inviteRequest" />;
    } else if (uiStatus === 'success' && pendingOrgInvites.length > 0) {
      return (
        <>
          <InviteRequestsListActions onApproveAll={approveAll} onRejectAll={rejectAll} />
          {pendingOrgInvites?.map((invite, i) => (
            <InviteRequestsListItem
              key={i}
              avatar={invite.invitee.imageUrl ?? ''}
              email={invite.invitee.email}
              name={invite.invitee.name ?? ''}
              requestor={invite.createdBy?.firstName + ' ' + invite.createdBy?.lastName}
              userStatus={invite.invitee.status!}
              requestedApps={invite.applicationRoles}
              onApprove={onApproveRequest}
              onReject={onRejectRequest}
              id={invite.organizationInviteId}
              itemStatus={getItemStatus(invite.organizationInviteId)!}
            />
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <div className={classes.inviteRequestsRoot}>
      <div className={classes.headerDescription}>
        <Typography variant="body2">{SHARED_TEXT.inviteRequestsDescription}</Typography>
      </div>

      <div className={classes.inviteRequestsListRoot}>{renderInviteRequest()}</div>

      <Box display="flex" justifyContent="flex-end" p={6}>
        <LoadingButton
          loading={confirmClicked}
          onClick={onConfirm}
          disabled={!canConfirm || confirmClicked}
          variant="contained"
          size="large"
        >
          {SHARED_TEXT.confirm}
        </LoadingButton>
      </Box>
    </div>
  );
};

export default InviteRequests;
export { InviteRequests as InviteRequestsPanel };
