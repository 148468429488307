import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { ExpandLess, ExpandMore, TableView } from '@mui/icons-material';

export const TableDebugMenu = (props: any) => {
  const [expanded, setExpanded] = useState(true);
  const styles = {
    position: 'absolute',
    bottom: '0',
    right: '0',
    border: '2px solid #d7d7d7',
    margin: '25px',
    marginTop: '0',
    marginRight: '50px',
    boxSizing: 'border-box',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 30px',
  };
  const Prop = (props: any) => (
    <Stack direction="row" spacing={1} justifyContent={'space-between'}>
      <Stack sx={{ width: '50%' }}>
        <Typography sx={{ fontWeight: 'bold' }}>{props?.label}</Typography>
        <Typography variant={'caption'}>{props?.description}</Typography>
      </Stack>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#0c53b0',
        }}
      >
        {props?.value}
      </Box>
    </Stack>
  );

  const ToggleButton = () => (
    <Box
      onClick={() => setExpanded(prev => !prev)}
      sx={{
        width: '40px',
        display: 'grid',
        placeItems: 'center',
        color: 'gray',
        '&:hover': { background: '#f7f7f7', cursor: 'pointer', borderRadius: '5px', color: 'black' },
      }}
    >
      {!expanded && <ExpandLess />}
      {expanded && <ExpandMore />}
    </Box>
  );

  const DebugMenuTitle = () => (
    <Stack
      sx={{
        alignItems: 'center',
        background: '#e9f3fd',
        padding: '5px',
        borderRadius: '7px',
        color: '#0b53b0',
        gap: '5px',
        paddingRight: '10px',
      }}
      direction={'row'}
    >
      <TableView />
      <Typography align={'center'} variant={'h2'}>
        {`TableInfiniteScroll`}
      </Typography>
    </Stack>
  );

  return (
    <Box sx={styles}>
      <Stack width={'100%'} spacing={2} divider={<Divider />}>
        <Stack
          direction={'row'}
          sx={{ gap: '10px' }}
          pt={'10px'}
          pb={'10px'}
          justifyContent={'space-between'}
        >
          <DebugMenuTitle />
          <LoadingButton variant={'outlined'} onClick={props?.fetchMore} loading={props?.isLoading}>
            Fetch More Items
          </LoadingButton>
          <ToggleButton />
        </Stack>
        {expanded && (
          <>
            <Prop
              label="isLoading"
              value={props?.isLoading ? <Typography color={'green'}>Loading More</Typography> : 'false'}
            />
            <Prop
              label="hasMore"
              description={'Are there more items to fetch from the server?'}
              value={props?.hasMore ? 'true' : 'false'}
            />
            <Prop
              label="offset"
              description={'This is the next GraphQL page starting offset'}
              value={props?.offset}
            />
            <Prop
              label="items.length"
              description={'Total amount of items fetched'}
              value={props?.items.length}
            />
            <Prop
              label="itemHeight"
              description={'This is the Pixel height of each row'}
              value={`${props?.itemHeight}px`}
            />
            <Prop
              label="Header Component"
              description={'Is a component passed as a header?'}
              value={props?.hasHeader ? 'true' : 'false'}
            />
            {props?.hasHeader && (
              <Prop
                label="adjustTableHeight"
                description={'If including a header, you need to adjust the container height with this prop.'}
                value={`${props?.adjustTableHeight}px`}
              />
            )}
            {!!props?.adjustTableWidth && (
              <Prop
                label="adjustTableWidth"
                description={'You can adjust the container width with this prop.'}
                value={`${props?.adjustTableWidth}px`}
              />
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};
