import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import { Divider, Grid, Tooltip, Typography, CircularProgress } from '@mui/material';
import { selectIsFullscreenEnabled } from '@aiware/shared/redux';
import { Utils } from '@aiware/shared/reusable-utils';
import { mountPanel } from '@aiware/js/panel';
import { Lock } from '@aiware/shared/icons';
import {
  actions,
  selectPermissionSets,
  selectPermissionSetsLoadingStatus,
  selectPermissionSetsPagination,
} from '../../redux';

interface IProps {
  loadPermissionSets: () => void;
}

const tableContentStyles = makeStyles()(theme => ({
  row: {
    '&:hover': {
      borderRadius: 4,
      backgroundColor: '#EEF3F9',
    },
  },
  loader: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
  divider: {
    opacity: 0.9,
  },
}));

const DESCRIPTION_LENGTH = 240;
const NAME_LENGTH = 50;

// TODO: Refactor this component to use TableInfiniteScroll from shared-reusable-utils
const PermissionSetsTable = ({ loadPermissionSets }: IProps) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    dispatch(actions.adminCenterPermissions.fetchOrgPermissionSetsStart());
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);
  const permissionSets = useSelector(selectPermissionSets) || [];
  const status = useSelector(selectPermissionSetsLoadingStatus);
  const { hasMore } = useSelector(selectPermissionSetsPagination);
  const isPanelFullScreen = useSelector(selectIsFullscreenEnabled);

  const loadMorePermissionSets = status === 'loading' ? () => ({}) : loadPermissionSets;
  const itemCount = hasMore ? (permissionSets || []).length + 1 : (permissionSets || []).length;
  const isItemLoaded = (index: number) => !hasMore || index < (permissionSets || []).length;
  const dispatch = useDispatch();
  const PARENT_PANEL_ID = 'ADMIN_CENTER';
  const handleOpenPermissionSetDetailPanel = (id: string) => {
    const microFrontend = {
      name: 'PERMISSION_SET_DETAILS_PANEL',
      config: {
        name: 'Permission Set Details',
        id,
      },
    };

    const panelConfig = {
      type: 'APP_BAR_PANEL_TEMPLATE',
      size: 'medium',
      marginTop: isPanelFullScreen ? 0 : 55,
      marginStart: isPanelFullScreen ? 0 : 80,
      parentPanelId: PARENT_PANEL_ID,
      dimmed: 0,
      dimmedStatus: 'dimParent',
      borderBottom: true,
    };
    dispatch(
      mountPanel({
        panelId: 'PERMISSION_SET_DETAILS_PANEL',
        microFrontend: microFrontend,
        panelConfig: panelConfig,
      })
    );
  };

  const rowHeight =
    permissionSets && permissionSets.map(item => (item?.description?.length <= 120 ? 60 : 80));
  const getItemSize = (index: number) => rowHeight[index]!;

  const Row: FunctionComponent<ListChildComponentProps> = ({ index, style }) => {
    let rowItem;
    const { classes } = tableContentStyles();

    if (!isItemLoaded(index)) {
      rowItem = (
        <div data-testid={'table-row-loader'} className={classes.loader}>
          <CircularProgress size={36} />
        </div>
      );
    } else {
      rowItem = (
        <>
          <Grid
            style={{
              flexGrow: 1,
              height: `${(permissionSets?.[index]?.description ?? '').length <= 120 ? '60px' : '80px'}`,
            }}
            container
            data-testid="admin-center-permission-set-name"
            data-test="admin-center-permission-set-name"
          >
            <Grid
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
              container
              xs={12}
              sx={{ pl: 3, cursor: 'pointer', pointerEvents: 'auto' }}
              onClick={() => handleOpenPermissionSetDetailPanel(permissionSets[index]!.id)}
              data-testid="admin-center-permission-set-details-panel-opener"
              data-test="admin-center-permission-set-details-panel-opener"
            >
              <Grid item xs={10} data-testid={`permission-set-row`} data-test={`permission-set-row`}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  data-testid={`permission-set-${permissionSets?.[index]?.name}`}
                  data-test={`permission-set-${permissionSets?.[index]?.name}`}
                >
                  {Utils.truncatedString(permissionSets?.[index]?.name, NAME_LENGTH)}
                  {permissionSets?.[index]?.isProtected && <ProtectedIcon />}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  data-testid={`permission-set-${permissionSets?.[index]?.description}`}
                  data-test={`permission-set-${permissionSets?.[index]?.description}`}
                >
                  {Utils.truncatedString(permissionSets?.[index]?.description, DESCRIPTION_LENGTH)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      );
    }

    return (
      <div key={index} style={{ ...style }}>
        {rowItem}
      </div>
    );
  };

  return isMounted ? (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => {
        return (
          <InfiniteLoader
            key={Math.random()}
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMorePermissionSets}
          >
            {({ onItemsRendered, ref }) => (
              <div>
                <List
                  height={height - 40}
                  itemCount={itemCount}
                  itemSize={getItemSize}
                  initialScrollOffset={0}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {Row}
                </List>
              </div>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  ) : null;
};

const ProtectedIcon = () => {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="os-admin-center-protected.icon"
          defaultMessage="This group is protected and can't be modified"
          description="tooltip for protected groups and permission"
        />
      }
    >
      <Lock
        sx={{ ml: 1, transform: 'translate(-2px, 4px) scale(0.8)' }}
        fontSize="small"
        data-test="os-admin-center-protected-group"
      />
    </Tooltip>
  );
};

export default PermissionSetsTable;
