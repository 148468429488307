import { all } from 'redux-saga/effects';
import {
  fetchResoucesACLWatcher,
  addACLsToResoucesWatcher,
  removeACLsFromResoucesWatcher,
  lookupMembersWatcher,
  getOrgFunctionalPermissionsWatcher,
  changeACLsToResoucesWatcher,
  fetchOrgPermissionSetsWatcher,
  createPermissionSetWatcher,
  updatePermissionSetWatcher,
  fetchOrgAccessControlsWatcher,
  fetchFunctionalPermissionsWacher,
} from './admin-center-permissions.watcher';
import { fetchExistingPermissionsWatcher } from './permissions-widget.saga';

export function* rootSaga() {
  yield all([
    fetchResoucesACLWatcher(),
    addACLsToResoucesWatcher(),
    removeACLsFromResoucesWatcher(),
    lookupMembersWatcher(),
    getOrgFunctionalPermissionsWatcher(),
    changeACLsToResoucesWatcher(),
    fetchOrgPermissionSetsWatcher(),
    createPermissionSetWatcher(),
    updatePermissionSetWatcher(),
    fetchOrgAccessControlsWatcher(),
    fetchFunctionalPermissionsWacher(),
    fetchExistingPermissionsWatcher(),
  ]);
}
