import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  root: {
    padding: '15px',
  },
  groupNameInput: {
    height: '36px',
    '& input': {
      width: '100% !important',
      height: '36px',
      padding: '0 0 0 15px',
      color: 'rgba(92, 98, 105, 1)',
      borderRadius: '4px',
      border: 'none',
    },
    marginBottom: '10px',
    width: '100% !important',
    background: '#F5FDFF',
  },
  notInterested: {
    height: '18px',
    '& svg': {
      height: '18px',
      cursor: 'pointer',
    },
  },
  confirm: {
    height: '20px',
    cursor: 'pointer',
  },
  newGroupApps: {
    // marginTop: '11px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    height: '101px',
    background: '#FAFAFA',
    borderRadius: '4px',
  },
  landingText: {
    color: 'rgba(156, 168, 180, 1)',
  },
  relativeContainer: {
    position: 'relative',
  },
  fullWidthRunway: {
    top: 0,
    width: '100%',
  },
}));
