import { put, select } from 'redux-saga/effects';
import api from '../../api';
import { actions } from '../slices';
import {
  EResourceType,
  GraphQLPage,
  IMemberType,
  TACEMember,
  TAuthACE,
  TFunctionalPermission,
  TPermissionSet,
} from '../../types';
import { PermissionChangedAction, sdkEventManager } from '@aiware/js/sdk-event-manager';
import { EMemberType, TUserGroup } from '@aiware/os/admin-center/groups';
import { IUser, SdkEvents } from '@aiware/js/interfaces';
import {
  selectApiConfigs,
  showMessage,
  MessageSeverity,
  preferredLanguageSelector,
} from '@aiware/shared/redux';
import { AIWareFormatMessage } from '@aiware/os/helpers';
import { selectOrgAccessControlPagination, selectPermissionSetsPagination } from '../selectors';
import isEqual from 'lodash/isEqual';
import { devErrorLogger } from '@aiware/shared/reusable-utils';
import { selectAdminCenterOrgParameter } from '@aiware/os/admin-center/shared';

const { permissionsAPI, memberLookupAPI } = api;

const memberIsGroup = (member: TACEMember): member is TUserGroup & IMemberType => 'members' in member;

function* triggerACLEvents(acls: GraphQLPage<TAuthACE>, action: PermissionChangedAction) {
  const aces = acls.records;
  aces.forEach(ace => {
    const member: { type: string; id: string | undefined } = memberIsGroup(ace.member)
      ? { type: EMemberType.Group.toString(), id: ace.member.id }
      : { type: EMemberType.User.toString(), id: ace.member.id };
    sdkEventManager.dispatch(SdkEvents.permissionChanged, null, {
      action,
      objectId: ace.objectID,
      permissionId: ace.permissionSet.permissionId,
      member,
      modifiedAt: new Date().toISOString(),
    });
  });
  yield aces;
}

export function* fetchResoucesACLSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.fetchResoucesACLStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string = yield select(selectAdminCenterOrgParameter);

    const ACLs: GraphQLPage<TAuthACE> = yield permissionsAPI.getACLs(apiConfigs, action.payload, orgId);
    yield put(actions.adminCenterPermissions.fetchResoucesACLSuccess(ACLs));
  } catch (e) {
    yield put(actions.adminCenterPermissions.fetchResoucesACLFailure());
    devErrorLogger(e);
  }
}

export function* addACLsToResoucesSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.addACLsToResoucesStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string = yield select(selectAdminCenterOrgParameter);

    const ACLs: GraphQLPage<TAuthACE> = yield permissionsAPI.setACLsOnResources(
      apiConfigs,
      action.payload,
      orgId
    );
    yield* triggerACLEvents(ACLs, PermissionChangedAction.GRANTED);
    yield put(actions.adminCenterPermissions.addACLsToResoucesSuccess(ACLs));
  } catch (e) {
    yield put(actions.adminCenterPermissions.addACLsToResoucesFailure());
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong. ACL was not updated'
        : formatMessage({
            id: 'ac-permission-panel.acl-update.failure',
            defaultMessage: 'Something went wrong. ACL was not updated',
            description: 'Error toast when adding a group to ACL fails',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
    devErrorLogger(e);
  }
}

export function* removeACLsFromResoucesSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.removeACLsFromResoucesStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string = yield select(selectAdminCenterOrgParameter);

    const ACLs: GraphQLPage<TAuthACE> = yield permissionsAPI.removeACLsOnResource(
      apiConfigs,
      action.payload,
      orgId
    );
    yield* triggerACLEvents(ACLs, PermissionChangedAction.REVOKED);
    yield put(actions.adminCenterPermissions.removeACLsFromResoucesSuccess(ACLs));
  } catch (e) {
    yield put(actions.adminCenterPermissions.removeACLsFromResoucesFailure());
    const message: any = {
      content: isEqual(process.env.NODE_ENV, 'development')
        ? 'Something went wrong. ACL was not updated'
        : formatMessage({
            id: 'permission-panel-something-went-wrong.acl-update.failure',
            defaultMessage: 'Something went wrong. ACL was not updated',
            description: 'Error toast when removing a group from ACL fails',
          }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
    devErrorLogger(e);
  }
}

export function* lookupMembersSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.lookupMembersStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string | undefined = yield select(selectAdminCenterOrgParameter);

    const foundMembers: (TUserGroup & IUser & IMemberType)[] =
      yield memberLookupAPI.fetchPermissionSetMembers(apiConfigs, action.payload, orgId);
    yield put(actions.adminCenterPermissions.lookupMembersSuccess(foundMembers));
  } catch (e) {
    yield put(actions.adminCenterPermissions.lookupMembersFailure());
    devErrorLogger(e);
  }
}

export function* getOrgFunctionalPermissionsSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.fetchOrgFuncPermissionsStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgPermissions: GraphQLPage<TFunctionalPermission> =
      yield permissionsAPI.getOrgFunctionalPermissions(apiConfigs);
    yield put(actions.adminCenterPermissions.fetchOrgFuncPermissionsSuccess(orgPermissions));
  } catch (e) {
    yield put(actions.adminCenterPermissions.fetchOrgFuncPermissionsFailure());
    devErrorLogger(e);
  }
}

export function* changeACLsToResoucesSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.changeACLsOnResoucesStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string | undefined = yield select(selectAdminCenterOrgParameter);

    const ACLs: GraphQLPage<TAuthACE> = yield permissionsAPI.changeAccessLevel(
      apiConfigs,
      action.payload,
      orgId
    );
    // TODO: permissionChanged SDK event here
    yield put(actions.adminCenterPermissions.changeACLsOnResoucesSuccess(ACLs));
  } catch (e) {
    yield put(actions.adminCenterPermissions.changeACLsOnResoucesFailure());
    devErrorLogger(e);
  }
}

export function* fetchOrgPermissionSetsSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.fetchOrgPermissionSetsStart>
) {
  try {
    const defaultLimit = 300;
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const { offset, hasMore } = yield select(selectPermissionSetsPagination);
    const orgId: string | undefined = yield select(selectAdminCenterOrgParameter);

    if (hasMore) {
      const permissionSets: GraphQLPage<TPermissionSet> = yield permissionsAPI.getOrgPermissionSets(
        apiConfigs,
        { orgId, offset }
      );

      yield put(
        actions.adminCenterPermissions.fetchOrgPermissionSetsSuccess({
          permissionSets: permissionSets.records,
          hasMore: defaultLimit === permissionSets.records.length,
          offset: offset + defaultLimit,
        })
      );
    } else {
      yield put(actions.adminCenterPermissions.resetLoadingState());
    }
  } catch (e) {
    yield put(actions.adminCenterPermissions.fetchOrgPermissionSetsFailure());
    devErrorLogger(e);
  }
}

export function* createPermissionSetSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.createPermissionSetStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string | undefined = yield select(selectAdminCenterOrgParameter);

    const newPermissionSet: TPermissionSet = yield permissionsAPI.createPermissionSet(
      apiConfigs,
      action.payload,
      orgId
    );
    yield put(actions.adminCenterPermissions.createPermissionSetSuccess(newPermissionSet));
    const message: any = {
      content: formatMessage({
        id: 'os-admin-center-permissions.create-permission-set-success-message',
        defaultMessage: 'The permission set was successfully created',
        description: 'Confirmation message when a permission set is successfully created',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  } catch (e) {
    yield put(actions.adminCenterPermissions.createPermissionSetFailure());
    const message: any = {
      content: formatMessage({
        id: 'os-admin-center-permissions.create-permission-set-error-message',
        defaultMessage: 'Something went wrong while creating the permission set',
        description: 'Confirmation message when a permission set is not successfully created',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
    devErrorLogger(e);
  }
}

export function* updatePermissionSetSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.updatePermissionSetStart>
) {
  const locale: string = yield select(preferredLanguageSelector);
  const formatMessage = AIWareFormatMessage(locale);

  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const orgId: string | undefined = yield select(selectAdminCenterOrgParameter);

    const updatedPermissionSet: TPermissionSet = yield permissionsAPI.updatePermissionSet(
      apiConfigs,
      action.payload,
      orgId
    );
    yield put(actions.adminCenterPermissions.updatePermissionSetSuccess(updatedPermissionSet));
    const message: any = {
      content: formatMessage({
        id: 'os-admin-center-permissions.update-permission-set-success-message',
        defaultMessage: 'The permission set was successfully updated',
        description: 'Confirmation message when a permission set is successfully updated',
      }),
      severity: MessageSeverity.Success,
    };
    yield put(showMessage(message));
  } catch (e) {
    yield put(actions.adminCenterPermissions.updatePermissionSetFailure());
    const message: any = {
      content: formatMessage({
        id: 'os-admin-center-permissions.update-permission-set-error-message',
        defaultMessage: 'Something went wrong while updated selected permission set',
        description: 'Confirmation message when a permission set is not successfully updated',
      }),
      severity: MessageSeverity.Error,
    };
    yield put(showMessage(message));
    devErrorLogger(e);
  }
}

export function* fetchOrgAccessControlSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.fetchOrgAccessControlStart>
) {
  try {
    const limit = 30;
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const { offset, hasMore } = yield select(selectOrgAccessControlPagination);
    const orgId = action.payload;
    const adminOrgId: string | undefined = yield select(selectAdminCenterOrgParameter);

    if (hasMore && orgId) {
      const accessControls: GraphQLPage<TAuthACE> = yield permissionsAPI.getACLsPaginated(
        apiConfigs,
        {
          offset,
          limit,
          resourceType: 'Organization' as unknown as EResourceType,
          ids: [orgId.toString()],
        },
        adminOrgId
      );
      yield put(
        actions.adminCenterPermissions.fetchOrgAccessControlSuccess({
          accessControls,
          hasMore: limit === accessControls.records.length,
          offset: offset + limit,
        })
      );
    } else {
      yield put(actions.adminCenterPermissions.fetchOrgAccessControlReset());
    }
  } catch (e) {
    yield put(actions.adminCenterPermissions.fetchOrgAccessControlFailure());
    devErrorLogger(e);
  }
}

export function* fetchFunctionalPermissionsSaga(
  action: ReturnType<typeof actions.adminCenterPermissions.fetchFunctionalPermissionsStart>
) {
  try {
    const apiConfigs: ReturnType<typeof selectApiConfigs> = yield select(selectApiConfigs);
    const funcPermissionsFetched: string[] = yield permissionsAPI.fetchFunctionalPermissions(apiConfigs);
    yield put(actions.adminCenterPermissions.fetchFunctionalPermissionsSuccess(funcPermissionsFetched));
  } catch (e) {
    yield put(actions.adminCenterPermissions.fetchFunctionalPermissionsFailure());
  }
}
