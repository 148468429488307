import { AIWareIntlProvider } from '@aiware/shared/intl';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { INotificationType } from '../../interfaces/notificationInterfaces';
import { useStyles } from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  notificationApplicationsSelector,
  notificationSelector,
  notificationApplicationSelector,
} from '../../store/selector';
import { IGeneralInterface, IAppNotificationSetting } from '../../interfaces/notificationInterfaces';
import { NotificationType } from '../../components/commons/NotificationType';
import NotificationSettingItem from '../../components/commons/NotifcationSettingItem';
import NotificationAllowTable from '../../components/NotificationAllowTable';
import { updateAppAllowNotification, updateApplicationSetting } from '../../store/actions';
import { CustomWorkFlow } from '../../components/commons/CustomWorkFlow';

interface INotificationApplication {
  appNotificationImg: string;
  dataId: string;
  hidePanel: () => void;
  mode: string;
  onClose: () => void;
  tabId: string;
  primaryText: string;
  secondaryText: string;
}

export default function NotificationApplication(props: INotificationApplication) {
  const { dataId } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notificationApplications = useSelector(
    notificationApplicationsSelector
  ) as IGeneralInterface<IAppNotificationSetting>;
  const [applicationSetting, setApplicationSetting] = useState(notificationApplications);
  const notificationSetting = useSelector(notificationSelector).settings;
  const notificationApp = useSelector(
    notificationApplicationSelector(dataId)
  ) as IAppNotificationSetting['id'];

  function handleSelectNotificationType(type: INotificationType, isSelected: boolean) {
    setApplicationSetting(preState => {
      const allowType = [...(preState.byId[dataId]?.allowType ?? [])];
      const index = allowType.indexOf(type);
      if (isSelected && index === -1) {
        allowType.push(type);
      } else {
        allowType.splice(index, 1);
      }
      return {
        ...preState,
        byId: {
          ...preState.byId,
          [dataId]: {
            ...preState.byId[dataId]!,
            allowType: allowType,
          },
        },
      };
    });
  }

  useEffect(() => {
    dispatch(updateApplicationSetting(applicationSetting));
  }, [applicationSetting]);

  const defaultAllowTypes = [
    INotificationType.EMAIL,
    INotificationType.NOTIFICATION_CENTER,
    INotificationType.BANNER,
  ];

  const disabledTypes: INotificationType[] = [];
  defaultAllowTypes.forEach(item => {
    if (notificationSetting?.allowType.findIndex((type: INotificationType) => type === item) === -1) {
      disabledTypes.push(item);
    }
  });

  const handleChangeAllowType = (isAllow: boolean) => {
    dispatch(updateAppAllowNotification(dataId, isAllow));
  };

  return (
    <AIWareIntlProvider>
      <div className={classes.appNoti}>
        <div>
          <NotificationType
            notificationTypeTitle={'Allow Notifications'}
            allowNotification={notificationApplications?.byId[dataId]?.allow}
            allowTypes={notificationApplications?.byId[dataId]?.allowType}
            disabledTypes={disabledTypes}
            children={
              <NotificationSettingItem
                imgSrc={notificationApplications?.byId[dataId]?.iconUrl || ''}
                primaryText={
                  <FormattedMessage
                    id="os-notification-panel.OR1ivL"
                    defaultMessage="{text}"
                    description="notification-app-id"
                    values={{
                      text: notificationApp?.name,
                    }}
                  />
                }
                secondaryText={
                  <FormattedMessage
                    id="os-notification-panel.QgzNQa"
                    defaultMessage="{text}"
                    description="notification application detail text"
                    values={{
                      text: notificationApp?.description,
                    }}
                  />
                }
                bigSize
              />
            }
            description={
              <Typography variant="caption" children={'Banner previews are being set at the parent level'} />
            }
            onChangeNotificationType={handleSelectNotificationType}
            onChangeAllowType={handleChangeAllowType}
          />
        </div>
        <div>
          <NotificationAllowTable action={notificationApplications?.byId[dataId]?.action} appId={dataId} />
          <Divider style={{ marginBottom: 20, marginTop: 15 }} />
        </div>
        <CustomWorkFlow />
      </div>
    </AIWareIntlProvider>
  );
}
