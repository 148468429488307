import { IconButton, Tooltip, Typography } from '@mui/material';
import { Export, Information } from '@aiware/shared/icons';
import { visuallyHidden } from '@mui/utils';
import { SHARED_TEXT } from '../../../helpers/shared-text';
import { betaFeaturesSelector } from '@aiware/shared/redux';
import { useSelector } from 'react-redux';

export const HeaderActions = ({ onClickInfo }: { onClickInfo: () => void }) => {
  const isBetaFeaturesEnabled = useSelector(betaFeaturesSelector);

  const handleClickExport = () => {
    // TODO: Implement export
  };

  return (
    <>
      <Typography style={visuallyHidden} id={`data-center-sdo.sdo-viewer.help-icon`}>
        {SHARED_TEXT.sdoViewer.headerHelp()}
      </Typography>
      <Tooltip title={SHARED_TEXT.sdoViewer.headerHelp()} data-testid="data-center-sdo.sdo-viewer.help-icon">
        <IconButton
          onClick={onClickInfo}
          aria-labelledby={`data-center-sdo.sdo-viewer.help-icon`}
          data-testid={`data-center-sdo.sdo-viewer.help-icon.button`}
          size="large"
        >
          <Information />
        </IconButton>
      </Tooltip>

      {isBetaFeaturesEnabled && (
        <>
          <Typography style={visuallyHidden} id={`data-center-sdo.sdo-viewer.export-icon`}>
            {SHARED_TEXT.sdoViewer.headerExport()}
          </Typography>
          <Tooltip
            title={SHARED_TEXT.sdoViewer.headerExport()}
            data-testid="data-center-sdo.sdo-viewer.export-icon"
          >
            <IconButton
              disabled
              onClick={handleClickExport}
              size="large"
              aria-labelledby={`data-center-sdo.sdo-viewer.export-icon`}
              data-testid={`data-center-sdo.sdo-viewer.export-icon.button`}
            >
              <Export />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};
