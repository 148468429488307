import createSvgIcon from '../createSvgIcon';
export const Organization = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0727 4.01834H10.9273V5.98166H13.0727V4.01834ZM15 2V8H12.3V10H19.7H20H20.3V14.2757L21.5757 13.0757C21.6929 12.9586 21.8828 12.9586 22 13.0757C22.1172 13.1929 22.1172 13.3828 22 13.5L20.2121 15.2121C20.095 15.3293 19.905 15.3293 19.7879 15.2121L18 13.5C17.8828 13.3828 17.8828 13.1929 18 13.0757C18.1172 12.9586 18.3071 12.9586 18.4243 13.0757L19.7 14.2757V10.5H12.3V14.2757L13.5445 13.0757C13.6616 12.9586 13.8516 12.9586 13.9688 13.0757C14.0859 13.1929 14.0859 13.3828 13.9688 13.5L12.2121 15.2121C12.095 15.3293 11.905 15.3293 11.7879 15.2121L10 13.5C9.88284 13.3828 9.88284 13.1929 10 13.0757C10.1172 12.9586 10.3071 12.9586 10.4243 13.0757L11.7 14.2757V10.5H4.3V14.2757L5.57574 13.0757C5.69289 12.9586 5.88284 12.9586 6 13.0757C6.11716 13.1929 6.11716 13.3828 6 13.5L4.21213 15.2121C4.09497 15.3293 3.90503 15.3293 3.78787 15.2121L2 13.5C1.88284 13.3828 1.88284 13.1929 2 13.0757C2.11716 12.9586 2.30711 12.9586 2.42426 13.0757L3.7 14.2757V10H4H4.3H11.7L11.7 8H9V2H15ZM10.9274 19.9817V18.0183H13.0726V19.9817H10.9274ZM15 22V16H9V22H15ZM21.0727 18.0183V19.9817H18.9273V18.0183H21.0727ZM23 22V16H17V22H23ZM2.92736 18.0183H5.07264V19.9817H2.92736V18.0183ZM7 22V16H1V22H7Z"
    />
  </svg>,
  'Organization'
);
