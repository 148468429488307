import { createContext, useContext } from 'react';
import { CapabilityType, ICriterion, ISuggestion, ISuggestionResult } from '../../types/aiAutocomplete.types';

interface IAutocompleteContext {
  isOpen: boolean;
  isLoading: boolean;
  suggestionResult: ISuggestionResult;
  searchTerm: string;
  shouldClearSearchTerm: boolean;
  criteria: ICriterion[];
  actions: {
    onChange: (value: string) => void;
    onOutsideClick: () => void;
    onSuggestionClick: (suggestion: ISuggestion) => void;
    onDeleteCriterionClick: (criterion: ICriterion) => void;
    onTextBasedCapabilityClick: (
      id: string,
      entityId: string,
      label: string,
      capabilityType: CapabilityType
    ) => void;
    onEnterClick: () => void;
    onResetShouldClearSearchTerm: () => void;
  };
}

export const AiAutocompleteContext = createContext<IAutocompleteContext | null>(null);

export const AiAutocompleteProvider = AiAutocompleteContext.Provider;

export const useAiAutocompleteContext = (): IAutocompleteContext => {
  const context = useContext(AiAutocompleteContext);

  if (!context) {
    throw new Error('useAiAutocompleteContext must be used within the AiAutocompleteProvider');
  }

  return context;
};
