import { namespace } from './selector';

export const GET_CURRENT_USER = `os-app-bar-panel/${namespace}/get current user`;
export const GET_CURRENT_USER_SUCCESS = `os-app-bar-panel/${namespace}/get current user success`;
export const GET_CURRENT_USER_FAIL = `os-app-bar-panel/${namespace}/get current user fail`;

export const RESET_BIOMETRIC = `os-app-bar-panel/${namespace}/reset biometric`;
export const RESET_BIOMETRIC_SUCCESS = `os-app-bar-panel/${namespace}/reset biometric success`;
export const RESET_BIOMETRIC_FAIL = `os-app-bar-panel/${namespace}/reset biometric fail`;

export const RESET_PASSWORD = `os-app-bar-panel/${namespace}/reset password`;
export const RESET_PASSWORD_SUCCESS = `os-app-bar-panel/${namespace}/reset password success`;
export const RESET_PASSWORD_FAIL = `os-app-bar-panel/${namespace}/reset password fail`;

export const UPDATE_PROFILE = `os-app-bar-panel/${namespace}/update profile`;
export const UPDATE_PROFILE_SUCCESS = `os-app-bar-panel/${namespace}/update profile success`;
export const UPDATE_PROFILE_FAIL = `os-app-bar-panel/${namespace}/update profile fail`;
export const UPLOAD__AVATAR = `os-app-bar-panel/${namespace}/upload avatar`;
export const UPDATE__AVATAR = `os-app-bar-panel/${namespace}/update avatar`;

export const LOGOUT_USER = `os-app-bar-panel/${namespace}/logout`;
export const LOGOUT_SUCCESS = `os-app-bar-panel/${namespace}/logout success`;
export const LOGOUT_FAIL = `os-app-bar-panel/${namespace}/logout fail`;
export const UPLOADING_AVATAR = `os-app-bar-panel/${namespace}/uploading`;

export const UPDATE_ACTIVITY = `os-app-bar-panel/${namespace}/updateActivityStatus`;

export const SET_HUB_ROLE = `os-app-bar-panel/${namespace}/set hub role`;

export enum AuthStatus {
  AUTHENTICATED = 'authenticated',
  LOGGING_OUT = 'logging_out',
  LOGGED_OUT = 'logged_out',
}

export function getCurrentUser() {
  return {
    type: GET_CURRENT_USER,
  };
}

export function getCurrentUserFail() {
  return {
    type: GET_CURRENT_USER_FAIL,
  };
}

export function getCurrentUserSuccess(data: unknown) {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: data,
  };
}

export function resetBiometric() {
  return {
    type: RESET_BIOMETRIC,
  };
}

export function resetBiometricFail() {
  return {
    type: RESET_BIOMETRIC_FAIL,
  };
}

export function resetBiometricSuccess(data: unknown) {
  return {
    type: RESET_BIOMETRIC_SUCCESS,
    payload: data,
  };
}
export function resetPassword(data: { email: string }) {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
}

export function resetPasswordFail() {
  return {
    type: RESET_PASSWORD_FAIL,
  };
}

export function resetPasswordSuccess(data: unknown) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function updateProfile(data: { email: string }) {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
}

export function updateProfileFail() {
  return {
    type: UPDATE_PROFILE_FAIL,
  };
}

export function updateProfileSuccess(data: unknown) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  };
}

export function uploadAvatar(data: unknown) {
  return {
    type: UPLOAD__AVATAR,
    payload: data,
  };
}

export function updateAvatar(data: unknown) {
  return {
    type: UPDATE__AVATAR,
    payload: data,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER,
  };
}

export function logoutUserSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function logoutUserFail() {
  return {
    type: LOGOUT_FAIL,
  };
}

export function uploadingAvatar(status: string) {
  return {
    type: UPLOADING_AVATAR,
    payload: status,
  };
}

export function setHubRole(data: unknown) {
  return {
    type: SET_HUB_ROLE,
    payload: data,
  };
}
