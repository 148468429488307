import { FC, useState } from 'react';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { SortByAlpha } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface IAiSearchHeaderSortMenu {
  label: string | JSX.Element;
  onSortMethodChange: () => void;
  [x: string]: unknown;
}

export const AiSearchHeaderSortMenu: FC<IAiSearchHeaderSortMenu> = ({ label, onSortMethodChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionMenuOpen = Boolean(anchorEl);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleClickSort = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickSortMethod = () => {
    handleCloseMenu();
    onSortMethodChange();
  };

  return (
    <>
      <Typography id="search-results-view-action-change-sort" style={visuallyHidden}>
        {label}
      </Typography>
      <Tooltip title={label}>
        <IconButton
          aria-labelledby="search-results-view-action-change-sort"
          onClick={handleClickSort}
          size="large"
        >
          <SortByAlpha />
        </IconButton>
      </Tooltip>
      <Menu
        data-testid="dc-search-results-view-search-item-context-menu"
        open={isActionMenuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleClickSortMethod()}>Default</MenuItem>
        <MenuItem onClick={() => handleClickSortMethod()}>
          <FormattedMessage
            id="os-data-center-browse.search-results-sort-method--filename-a-z"
            defaultMessage="Filename A → Z"
            description="Search results sort method alphabetical a to z"
          />
        </MenuItem>
        <MenuItem onClick={() => handleClickSortMethod()}>
          <FormattedMessage
            id="os-data-center-browse.search-results-sort-method--filename-z-a"
            defaultMessage="Filename Z → A"
            description="Search results sort method reverse alphabetical z to a"
          />
        </MenuItem>
        <MenuItem onClick={() => handleClickSortMethod()}>
          <FormattedMessage
            id="os-data-center-browse.search-results-sort-method--newest-oldest"
            defaultMessage="Newest → Oldest"
            description="Search results sort method newest to oldest"
          />
        </MenuItem>
        <MenuItem onClick={() => handleClickSortMethod()}>
          <FormattedMessage
            id="os-data-center-browse.search-results-sort-method--oldest-newest"
            defaultMessage="Oldest → Newest"
            description="Search results sort method oldest to newest"
          />
        </MenuItem>
      </Menu>
    </>
  );
};
