import { ProcessingCenterContentTemplatesState, namespace } from '../slices';

export const selectFetchSchemaStatus = (state: ProcessingCenterContentTemplatesState) => {
  return state[namespace]?.contentTemplates?.schemaStatus;
};

export const selectCurrentSchemaList = (state: ProcessingCenterContentTemplatesState) => {
  return state[namespace]?.contentTemplates?.currentSchemaList;
};

export const selectAllSchemas = (state: ProcessingCenterContentTemplatesState) => {
  return state[namespace]?.contentTemplates?.schemas;
};

export const selectContentTemplatesToSave = (state: ProcessingCenterContentTemplatesState) => {
  return state[namespace]?.contentTemplates?.contentTemplatesToSave;
};
