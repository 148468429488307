import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Search } from '@mui/icons-material';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { useStyles } from './aiSearchBar.styles';
import { useAiAutocompleteContext } from '../../AiAutocomplete.context';
import debounce from 'lodash/debounce';
import { AiTag } from '../../../AiTag';
import { useDispatch, useSelector } from 'react-redux';
import { actions as aiSearchFilterActions } from '../../../../state/aiSearchFilter/aiSearchFilter.slice';
import { actions as reduxActions } from '../../../../state/aiSearch/aiSearch.slice';
import { actions as autocompleteActions } from '../../../../state/aiAutocomplete/aiAutocomplete.slice';
import {
  selectActiveView,
  selectRawSearchTerm,
} from '../../../../state/aiAutocomplete/aiAutocomplete.selectors';
import { TProps } from '../../';
import { Box, IconButton, Tooltip } from '@mui/material';
import { SearchStructuredData } from '@aiware/shared/icons';
import { EActiveView } from '../../../../types/aiAutocomplete.types';

import { uiState } from '../../../../../../redux/selectors';

export type IAiSearchBar = WrappedComponentProps & TProps;

const AiSearchBarRender = ({ intl, placeholder: customPlaceholder }: WrappedComponentProps & TProps) => {
  const { criteria, shouldClearSearchTerm, actions, isOpen } = useAiAutocompleteContext();
  const { classes } = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const dataCenterActiveTab = useSelector(uiState.selectActiveTab);

  const inputValue = useSelector(selectRawSearchTerm);

  useEffect(() => {
    // Clear the search term from the input when the user clicks on a different tab
    if (typeof dataCenterActiveTab === 'number') {
      actions.onOutsideClick();
      clearReduxState();
    }
  }, [dataCenterActiveTab]);

  useEffect(() => {
    if (!shouldClearSearchTerm) return;

    if (ref.current != null) {
      ref.current.value = '';
      actions.onResetShouldClearSearchTerm();
    }
  }, [shouldClearSearchTerm, actions]);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        actions.onEnterClick();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [actions]);

  useEffect(() => {
    if (criteria && criteria.length) {
      dispatch(reduxActions.setActiveCriteria([...criteria]));
    }
  }, [criteria]);

  const searchInputContainerClass = () => {
    if (!isHovered && !isFocused) {
      return classes.searchInputContainerDefault;
    }

    if (isHovered && !isFocused) {
      return classes.searchInputContainerHover;
    }

    if (isFocused) {
      return classes.searchInputContainerActive;
    }
  };

  const placeholder = intl.formatMessage({
    id: `os-data-center-browse.search-input-placeholder`,
    defaultMessage: 'Search for file names, sources, data registries, or cognition',
    description: 'Search bar placeholder text',
  });

  const debouncedSearch = useRef(debounce(actions.onChange, 1000)).current;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouncedSearch(value);
    dispatch(autocompleteActions.setRawSearchTerm(value));
  };

  const clearReduxState = () => {
    dispatch(autocompleteActions.clearSearchTerm());
    dispatch(reduxActions.setSearchCriteria([]));
    dispatch(reduxActions.setActiveCriteria([]));
    dispatch(reduxActions.resetSearchResults());
    dispatch(aiSearchFilterActions.resetSearchFilters());
    dispatch(aiSearchFilterActions.resetDateRangeType());
    dispatch(aiSearchFilterActions.resetSelectedFilterValues());
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <div
        className={`${classes.searchInputContainer} ${searchInputContainerClass()}`}
        style={criteria.length ? { paddingTop: '1px', paddingBottom: '1px' } : {}}
      >
        <div className={classes.searchIconContainer}>
          <Search fontSize={'small'} sx={{ color: isHovered || isFocused ? '#555F7C' : '#555F7C99' }} />
        </div>

        {criteria.map(criterion => {
          return (
            <span className={classes.criteria} key={criterion.id}>
              <AiTag
                id={criterion.id}
                label={criterion.label || ''}
                capabilityType={criterion.capabilityType}
                onDelete={() => {
                  actions.onDeleteCriterionClick(criterion);
                  clearReduxState();
                }}
              />
            </span>
          );
        })}
        <input
          id="aiAutocomplete-search-bar"
          data-test="aiAutocomplete-search-bar-input"
          ref={ref}
          style={{ marginLeft: criteria.length > 0 ? '' : '15px' }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={classes.searchInput}
          type="text"
          placeholder={criteria.length > 0 ? '' : customPlaceholder ? customPlaceholder : placeholder}
          onChange={handleInputChange}
          maxLength={200}
          value={inputValue}
          autoComplete="none"
          name="data center search value"
        />
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            right: '8px',
            height: '100%',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <StructuredDataButton isOpen={isOpen} />
        </Box>
      </div>
    </div>
  );
};

const StructuredDataButton = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useDispatch();
  const isActive = useSelector(selectActiveView) === EActiveView.structuredData && isOpen;

  const handleClick = () => {
    // open structured data autocomplete
    dispatch(autocompleteActions.clearSearchTerm());
    dispatch(autocompleteActions.abortQueries());
    dispatch(autocompleteActions.clearSuggestionResult());
    dispatch(autocompleteActions.setActiveView(EActiveView.structuredData));
    dispatch(autocompleteActions.setIsOpen(true));
  };
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="os-data-center-browse.search.structured-data-tooltip"
          defaultMessage="Search Structured Data"
          description="Tooltip for structured data icon"
        />
      }
    >
      <IconButton
        data-testid="dc-browse-search-structured-data-button"
        sx={{
          pointerEvents: isActive ? 'none' : undefined,
          backgroundColor: isActive ? '#F2F7FE' : 'transparent',
          '&:hover': { backgroundColor: '#F2F7FE' },
          '&:hover svg': { fill: '#0C53B0' },
        }}
        onClick={handleClick}
        size="small"
      >
        <SearchStructuredData
          sx={{ height: '18px', width: '18px', fill: isActive ? '#0C53B0' : undefined }}
          viewBox="0 0 15 15"
        />
      </IconButton>
    </Tooltip>
  );
};

export const AiSearchBar: FunctionComponent<WrappedComponentProps & TProps> = injectIntl(AiSearchBarRender);
