import { useStyles } from '../../sourceScheduleFilter.styles';
import { useEffect, useState } from 'react';
import { Badge, Checkbox, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { sourceScheduleFilterText } from '../../../../../../helpers/aiSearch.text';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { TFilterSourceScheduleDataSet } from '../../../../../../types/aiSearch.types';
import { ISchedule, ISource } from '../../../../../../../../types';

export const SourceItem = ({
  source,
  onClickExpand,
  isExpanded,
  selectedDataSet,
  onClearAll,
  onAddSource,
  onRemoveSource,
}: {
  source: ISource;
  onClickExpand: (event: React.MouseEvent<HTMLButtonElement>, sourceID: string) => void;
  isExpanded: boolean;
  selectedDataSet: TFilterSourceScheduleDataSet;
  onClearAll: (sourceId: string | number) => void;
  onAddSource: (source: ISource) => void;
  onRemoveSource: (source: ISource) => void;
}) => {
  const { classes } = useStyles();
  const { id } = source;

  // Check if the Source is already checked inside the Dropdown Dataset
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    let alreadyChecked = false;
    if (selectedDataSet['SOURCES']?.length && !selectedDataSet['SCHEDULES'][id]?.length) {
      for (const sourceObj of selectedDataSet['SOURCES']) {
        if (sourceObj.id === id) {
          alreadyChecked = true;
        }
      }
    }
    alreadyChecked && setIsChecked(alreadyChecked);
  }, []);

  const handleExpandSchedules = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isExpanded) {
      onClickExpand(event, '');
    } else {
      onClickExpand(event, source.id);
    }
  };

  const handleClickCheckbox = () => {
    if (selectedDataSet['SCHEDULES'][id]) {
      onClearAll(id);
      setIsChecked(false);
    } else {
      setIsChecked(prevState => {
        if (!prevState) {
          onAddSource(source);
        } else {
          onRemoveSource(source);
        }
        return !prevState;
      });
    }
  };

  const TooltipContent = () => {
    return (
      <div>
        <Typography variant={'body1'}>Schedules:</Typography>
        {selectedDataSet['SCHEDULES'][id]?.map((item: ISchedule, _index: number) => {
          return (
            <div>
              <Typography>{item.name ? item.name : item.id}</Typography>
            </div>
          );
        })}
      </div>
    );
  };

  const ScheduleAmount = () => {
    if (!selectedDataSet['SCHEDULES'][id]) return <></>;
    return (
      <Tooltip
        title={<TooltipContent />}
        PopperProps={{
          sx: { pointerEvents: 'none' },
        }}
      >
        <Badge
          sx={{ marginLeft: '15px' }}
          badgeContent={selectedDataSet['SCHEDULES'][id]?.length}
          color="primary"
        />
      </Tooltip>
    );
  };

  return (
    <>
      <div className={classes.source}>
        <Tooltip
          title={
            Boolean(selectedDataSet['SCHEDULES'][id]) || isChecked
              ? sourceScheduleFilterText.removeSchedules
              : sourceScheduleFilterText.selectAllSchedules
          }
          PopperProps={{
            sx: { pointerEvents: 'none' },
          }}
        >
          <Checkbox
            checked={isChecked}
            onClick={handleClickCheckbox}
            indeterminate={Boolean(selectedDataSet['SCHEDULES'][id]?.length)}
          />
        </Tooltip>

        <Typography sx={{ marginRight: 'auto' }} noWrap>
          {source.name ? source.name : source.id}
        </Typography>
        <ScheduleAmount />
        <Tooltip
          title={isExpanded ? sourceScheduleFilterText.hideSchedules : sourceScheduleFilterText.showSchedules}
          PopperProps={{
            sx: { pointerEvents: 'none' },
          }}
        >
          <IconButton onClick={handleExpandSchedules}>
            {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
    </>
  );
};
