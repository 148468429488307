import { FC } from 'react';
import { useStyles } from './searchResultsHeader.styles';
import { HeaderActions } from './HeaderActions';
import { Typography } from '@mui/material';
import { EResultsViewAction, EResultsViewStyle } from '../../AISearchResultsView';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSearchHasMore } from '../../../../state/aiSearch/aiSearch.selectors';

interface ISearchResultsHeader {
  resultCount: number;
  onViewStyleChange: () => void;
  onSortMethodChange: () => void;
  viewStyle: EResultsViewStyle;
  hideHeaderActions: EResultsViewAction[];
  isLoading: boolean;
}

export const SearchResultsHeader: FC<ISearchResultsHeader> = ({
  resultCount = 0,
  onViewStyleChange,
  onSortMethodChange,
  viewStyle,
  hideHeaderActions,
  isLoading,
}) => {
  const { classes } = useStyles();
  const hasMore = useSelector(selectSearchHasMore);

  const HeaderResultCount = (): JSX.Element => {
    if (isLoading) {
      return (
        <Typography className={classes.headerLabel} variant={'h3'}>
          <FormattedMessage
            id="os-data-center-browse.search-results-view-header.searching-text"
            defaultMessage="Searching..."
            description="Search results header displaying actively searching text."
          />
        </Typography>
      );
    }

    const getResultCount = () => {
      if (hasMore) {
        return Number(Math.ceil(resultCount / 10) * 10).toLocaleString('en-us');
      } else {
        return Number(resultCount).toLocaleString('en-us');
      }
    };
    const getSearchResultString = (amount: number) => {
      if (amount !== 0) {
        return (
          <FormattedMessage
            id="os-data-center-browse.search-results-view-header.search-results-found"
            defaultMessage="Search Results"
            description="Search results header number displaying a number of results found, results word plural."
          />
        );
      } else {
        return (
          <FormattedMessage
            id="os-data-center-browse.search-results-view-header.search-results--one-found"
            defaultMessage="Search Result"
            description="Search results header number displaying 1 of result found, result word singular."
          />
        );
      }
    };

    return (
      <Typography className={classes.headerLabel} variant={'h3'}>
        {resultCount !== 0 && (
          <>
            {resultCount >= 0 && (
              <>
                {hasMore && (
                  <>
                    <FormattedMessage
                      id="os-data-center-browse.search-results-view-header.search-results-about"
                      defaultMessage="About"
                      description="Search results header number displaying the word about, before the search results."
                    />{' '}
                  </>
                )}
                {getResultCount()}
              </>
            )}{' '}
            {getSearchResultString(resultCount)}
          </>
        )}
        {resultCount === 0 && (
          <FormattedMessage
            id="os-data-center-browse.search-results-view-header.search-results-nothing-found"
            defaultMessage="No Search Results"
            description="Search results header number displaying no results found."
          />
        )}
      </Typography>
    );
  };

  return (
    <header id={'dc-search-results-header-container'} className={classes.container}>
      <HeaderResultCount />
      {resultCount > 0 && (
        <HeaderActions
          onViewStyleChange={onViewStyleChange}
          onSortMethodChange={onSortMethodChange}
          viewStyle={viewStyle}
          hideHeaderActions={hideHeaderActions}
        />
      )}
    </header>
  );
};
