import createSvgIcon from '../createSvgIcon';
export const DeveloperCenter = createSvgIcon(
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1668 8.25V6.41667H18.3335V4.58333C18.3335 3.575 17.5085 2.75 16.5002 2.75H3.66683C2.6585 2.75 1.8335 3.575 1.8335 4.58333V17.4167C1.8335 18.425 2.6585 19.25 3.66683 19.25H16.5002C17.5085 19.25 18.3335 18.425 18.3335 17.4167V15.5833H20.1668V13.75H18.3335V11.9167H20.1668V10.0833H18.3335V8.25H20.1668ZM16.5002 17.4167H3.66683V4.58333H16.5002V17.4167ZM5.50016 11.9167H10.0835V15.5833H5.50016V11.9167ZM11.0002 6.41667H14.6668V9.16667H11.0002V6.41667ZM5.50016 6.41667H10.0835V11H5.50016V6.41667ZM11.0002 10.0833H14.6668V15.5833H11.0002V10.0833Z"
      fill="#555F7C"
    />
  </svg>,
  'DeveloperCenter'
);
