function SystemNotification() {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 .02C4.49.02.02 4.49.02 10c0 5.51 4.47 9.98 9.98 9.98 5.51 0 9.98-4.47 9.98-9.98 0-5.51-4.47-9.98-9.98-9.98zm0 17.96c-4.4 0-7.98-3.58-7.98-7.98S5.6 2.02 10 2.02 17.98 5.6 17.98 10 14.4 17.98 10 17.98zM10.75 3l-4.5 8.5h3.14V17l4.36-8.5h-3V3z"
        fill="#E7A600"
      />
    </svg>
  );
}

export { SystemNotification };
