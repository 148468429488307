import createSvgIcon from '../createSvgIcon';

export const PackagesAvatar = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#F2F7FE" />
    <path
      d="M18 15.875C18 16.16 17.86 16.4075 17.6467 16.535L12.38 19.865C12.2733 19.955 12.14 20 12 20C11.86 20 11.7267 19.955 11.62 19.865L6.35333 16.535C6.14 16.4075 6 16.16 6 15.875V9.125C6 8.84 6.14 8.5925 6.35333 8.465L11.62 5.135C11.7267 5.045 11.86 5 12 5C12.14 5 12.2733 5.045 12.38 5.135L17.6467 8.465C17.86 8.5925 18 8.84 18 9.125V15.875ZM12 6.6125L10.74 7.415L14.6667 9.9575L15.9733 9.125L12 6.6125ZM8.02667 9.125L12 11.6375L13.3067 10.8125L9.38667 8.2625L8.02667 9.125ZM7.33333 15.4325L11.3333 17.9675V12.935L7.33333 10.4075V15.4325ZM16.6667 15.4325V10.4075L12.6667 12.935V17.9675L16.6667 15.4325Z"
      fill="#0C53B0"
    />
  </svg>,
  'PackagesAvatar'
);
